import './polyfills';

// import 'hammerjs';
import { ApplicationRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DateTime, Integer } from '@plano/shared/api/base/generated-types.ag.js';
import axios from 'axios';
import { AppModule } from './app.module';
import { Config } from './shared/core/config';

interface TimeContext {
	id : Integer;
	paused : boolean;
	currentTime : DateTime;
}

const startApp = async () : Promise<void> => {
	const startAngularApp = () : void => {
		// Set ngZoneEventCoalescing to "merge" multiple change detection cycles. My tests did not result
		// in significant performance boosts but it should not hurt.
		// See https://github.com/angular/angular/pull/30533/files
		void platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
			// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
			.then((moduleRef) => {
				if (!Config.DEBUG) return;

				// allows to run debug tools like `ng.profiler.timeChangeDetection();`
				const applicationRef = moduleRef.injector.get(ApplicationRef);
				const componentRef = applicationRef.components[0];
				enableDebugTools(componentRef);
			});
	};

	if (Config.DEBUG || Config.APPLICATION_MODE === 'GHERKIN') {
		// NOTE: We explicitly use require instead of an import, as the import already overrides the "Date" object.
		// To avoid that, we only import the package in DEBUG mode.
		// eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module -- This disable-line description has been added when we enabled 'eslint-comments/require-description', This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const timeMachine = require('@alexfr26/timemachine'); // cSpell:ignore timemachine, alexfr
		// Debug -> Set the current time-context and then start the angular app.
		// By following this order, we ensure that the whole Angular App and all its services will work with the same time-context.
		try {
			const timeContextId = localStorage.getItem('timeContextId');
			const timeContextResponse = await axios.get<TimeContext>(`${Config.BACKEND_URL}/time_context`, {params: {id: timeContextId}});
			const timeContext = timeContextResponse.data;

			timeMachine.config({
				timestamp: timeContext.currentTime,
				tick: !timeContext.paused,
			});
		} catch (error) {
			// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			console.warn('Frontend could not sync time with backend. Possibly backend is not started?', error);
			timeMachine.config({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- need to access OriginalDate
				timestamp: new Date((Date as any).OriginalDate()).valueOf(),
				tick: true,
			});
		}

		startAngularApp();
	} else {
		// Production -> Just start the angular app
		startAngularApp();
	}
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Remove this before you work here.
startApp();
