import { ApiBase } from '@plano/shared/api/base/api-base/api-base';
import { ApiDataWrapperBase } from '@plano/shared/api/base/api-data-wrapper-base';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';

/**
 * Base class for arguments defining the logic of an attribute-info.
 */
export interface ApiAttributeInfoArgsBase<ParentType extends ApiDataWrapperBase> {
	apiObjWrapper : ParentType;

	/**
	 * Condition which defines when this value is available by business logic.
	 * See {@link ApiAttributeInfoBase.isAvailableByBusinessLogic}.
	 */
	isAvailableByBusinessLogic ?: ((this : ParentType) => boolean | undefined);

	/**
	 * Condition which defines when this value is editable according to business logic.
	 *
	 * Meaning of different return types:
	 * - `boolean`: Can attribute be set now?
	 * - `PDictionarySource`: The attribute cannot be set now. The return value indicates the message to be shown to user as explanation why it cannot be edited.
	 * - `undefined`: Currently, it cannot be decided if the attribute can be set or not (e.g. because needed apis are not loaded).
	 */
	canSetByBusinessLogic ?: ((this : ParentType) => boolean | PDictionarySource | undefined);
}

/**
 * Base class for attribute-info classes
 */
export abstract class ApiAttributeInfoBase<ParentType extends ApiDataWrapperBase, ArgsType
	extends ApiAttributeInfoArgsBase<ParentType>> {

	constructor(protected args : ArgsType) {
	}

	/**
	 * The wrapper object to which this attribute-info belongs.
	 */
	public get apiObjWrapper() : ParentType {
		return this.args.apiObjWrapper;
	}

	/**
	 * The api object.
	 */
	public get api() : ApiBase | null {
		return this.args.apiObjWrapper.api;
	}

	/**
	 * @returns Is the wrapper of this attribute-info a new item?
	 */
	public get isNewItem() : boolean {
		return this.args.apiObjWrapper.isNewItem;
	}

	/**
	 * @example Example code how to visualize an attribute with the name "value" with a loading skeleton:
	 *
	 * <div *ngIf="attributeInfoValue.isAvailable !== false">
	 * 		{{attributeInfoValue.isAvailable === undefined ? '<loading-skeleton>' : value}}
	 * </div>
	 *
	 * @returns Is the data-set for this attribute currently available? Following values can be returned:
	 * - `true`: The data-set is available. Any component visualizing the attribute can be shown now.
	 * - `false`: The data-set is not available. Any component visualizing the attribute should be hidden.
	 * - `undefined`: The data-set is in a loading process as it is currently not available but an api load is running.
	 * 		In this case you should either not show any component visualizing the attribute or alternatively show a loading
	 * 		skeleton.
	 */
	public get isAvailable() : boolean | undefined {
		return this.isAvailableByBusinessLogic;
	}

	/**
	 * @returns Should this attribute be available according to business logic? This returns the result of the conditions
	 * in `<get><if-business>…</if-business></get>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute is currently available includes further criteria.
	 * See {@link isAvailable}.
	 */
	protected get isAvailableByBusinessLogic() : boolean | undefined {
		return this.args.isAvailableByBusinessLogic ? this.args.isAvailableByBusinessLogic.call(this.args.apiObjWrapper) : true;
	}

	/**
	 * @returns Can this attribute currently be set?
	 */
	public get canSet() : boolean {
		return this.canSetByBusinessLogic === true;
	}

	/**
	 * @returns Can this attribute be set according to business logic? This returns the result of the conditions
	 * in `<set><if-business>…</if-business></set>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute can be set now, includes further criteria. See {@link canSet}.
	 */
	protected get canSetByBusinessLogic() : boolean | undefined {
		return this.args.canSetByBusinessLogic ? (this.args.canSetByBusinessLogic.call(this.args.apiObjWrapper) === true) : true;
	}

	/**
	 * Message to user why this attribute cannot currently be set. Note, that you should not store this value permanently as it might change.
	 */
	public get cannotSetHint() : PDictionarySource | null {
		// "cannotSetHint" from "canSetByBusinessLogic"
		const canSetByBusinessLogic = this.args.canSetByBusinessLogic ? this.args.canSetByBusinessLogic.call(this.args.apiObjWrapper) : undefined;
		if (typeof canSetByBusinessLogic !== 'boolean' && canSetByBusinessLogic !== undefined)
			return canSetByBusinessLogic;

		// no cannotSetHint
		return null;
	}
}
