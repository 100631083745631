<button class="d-flex justify-content-between align-items-center clickable btn-secondary btn text-start {{triggerBtnClasses?triggerBtnClasses:''}}"
	[class.border-0]="collapsed || !hasContent"
	[class.border-top-0]="!collapsed && hasContent"
	[class.border-start-0]="!collapsed && hasContent"
	[class.border-end-0]="!collapsed && hasContent"
	[class.rounded]="!hasContent"
	[class.text-danger]="hasDanger"
	[class.collapsed]="collapsed"
	[class.uncollapsed]="!collapsed"
	[class.has-content]="hasContent"
	[class.active]="!collapsed && hasContent"
	[class.card-header]="size==='lg'"
	[class.btn-sm]="size==='sm'"
	[class.btn-lg]="size==='lg'"
	[attr.aria-expanded]="!collapsed"
	(click)="toggle($event)"
	#collapsibleButton
	[id]="headerHtmlId"
	pAutoFocus
>
	<ng-content select="[trigger]" />
	<div><fa-icon [class]="'ms-3 ' + (iconClasses ? iconClasses : '')" [size]="size" [icon]="collapsed ? enums.PlanoFaIconContextPool.COLLAPSIBLE_CLOSE : enums.PlanoFaIconContextPool.COLLAPSIBLE_OPEN" /></div>
</button>
<div [@slideOpen]="!collapsed" style="height: 0; overflow: hidden;">
	<ng-container #ngContentContainer>
		<ng-content select="[content]" />
	</ng-container>
</div>
