/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint-disable max-lines -- This disable-line description has been added when we enabled 'eslint-comments/require-description', Solve the remaining cases please. */
import { ApiBase, ApiDataWrapperBase, ApiLists, ApiObjectWrapper, SortOptions } from '@plano/shared/api';
import { SortedByFnArray, SortedBySortingCriteria, SortFnArray, SortSortingCriteria } from '@plano/shared/api/base/api-list-wrapper/api-list-wrapper.type';
import { Integer } from '@plano/shared/api/base/generated-types.ag';
import { IdBase } from '@plano/shared/api/base/id/id-base';
import { Meta } from '@plano/shared/api/base/meta';
import { ObjectWithRawData } from '@plano/shared/api/base/object-with-raw-data';
import * as _ from 'underscore';

/**
 * A wrapper class for api list data. T is the type of the object being saved by the list.
 */
export abstract class ApiListWrapper<T> extends ApiDataWrapperBase {

	/**
	 * @param api The api instance.
	 * @param parent The parent of this list. This is normally the object which contains this list.
	 * @param isView Is this list’s purpose to be viewed only? If set to true, it will not trigger any
	 * {@link ApiBase#onChanges} events, nor will it change its children’s parent when they are added or removed.
	 * @param removeDestroyedItems The list wrappers automatically are "updated" when an item is removed.
	 * This is not the case for externally hold lists. These lists can pass "true" so they remain valid when
	 * an item was removed.
	 * @param propertyName The name of the property which will passed to api.onChange.subscribe(THIS_VARIABLE => {…}).
	 */
	constructor(
		api : ApiBase,
		parent : ApiDataWrapperBase | null,
		protected isView : boolean,
		removeDestroyedItems : boolean,
		private readonly propertyName : string | null = null,
	) {
		super(api, parent);

		this.removeDestroyedItems = removeDestroyedItems && !this.containsPrimitives();

		// empty list
		this._updateRawData([true], true);
	}

	/**
	 * Should destroyed items be automatically be removed from this list?
	 */
	private removeDestroyedItems : boolean = false;

	/*
	Typescript supports now defining custom iterables. So, we would not need to always write for(let item of …iterable())
	But Unfortunately ngFor does not support it :(

	[Symbol.iterator](): Iterator<T, any, undefined> {
		return this.dataUser.values();
	}
	*/

	/**
	 * @returns An Array which can be used in for-each loops.
	 * You should not modify this array directly. Instead use the modification methods provided in this class.
	 */
	public iterable() : readonly T[] {
		return this.dataUser;
	}

	/**
	 * Find item in list by provided `fn`. The first item for which `fn` returns `true` is returned.
	 * Btw… instead of !!foo.findBy(item > item.hasFoo) you should write foo.some(item > item.hasFoo)
	 * @param fn The function which is used to find the desired item.
	 */
	public findBy(
		fn : (item : T) => boolean,
	) : T | null {
		for (const item of this.dataUser) {
			if (fn(item)) return item;
		}
		return null;
	}

	/**
	 * Filter the list by a given filter function.
	 * This method is not modifying the list and therefore does not trigger {@link ApiBase#onChange}
	 *
	 * @param filterFunction The function which is used to filter the list.
	 * @returns Returns a copy of the list containing all items for which `filterFunction` returns `true`.
	 */
	public filterBy(filterFunction : (item : T) => boolean) : this {
		const result = this.createInstance(this.parent, true, false);

		for (const item of this.iterable()) {
			if (filterFunction(item)) {
				result.push(item);
			}
		}

		return result;
	}

	/**
	 * @param item The item we are looking for.
	 * @returns Does `item` exist in this list?
	 */
	public includes(
		item : T,
	) : boolean {
		return this.dataUser.includes(item);
	}

	/**
	 * @see Array#some()
	 */
	public some(predicate : (value : T, index : number, array : T[]) => unknown, thisArg ?: any) : boolean {
		// eslint-disable-next-line unicorn/no-array-method-this-argument, unicorn/no-array-callback-reference -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.dataUser.some(predicate, thisArg);
	}

	/**
	 * @see Array#every()
	 */
	public every(predicate : (value : T, index : number, array : T[]) => unknown, thisArg ?: any) : boolean {
		// eslint-disable-next-line unicorn/no-array-method-this-argument, unicorn/no-array-callback-reference -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.dataUser.every(predicate, thisArg);
	}

	/**
	 * @see Array#find()
	 */
	public find(
		predicate : (value : T, index : number, obj : T[]) => unknown,
	) : T | null {
		// eslint-disable-next-line unicorn/no-array-callback-reference -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const result = this.dataUser.find(predicate);
		return result ?? null;
	}

	/**
	 * @see Array#slice()
	 */
	public slice(start ?: number, end ?: number) : T[] {
		return this.dataUser.slice(start, end);
	}

	/**
	 * @see Array#map()
	 */
	public map<U>(
		callbackfn : (value : T, index : number, array : T[]) => U,
	) : U[] {
		// eslint-disable-next-line unicorn/no-array-callback-reference -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.dataUser.map(callbackfn);
	}

	/**
	 * @returns Returns a copy of the data as an array object.
	 */
	public asArray() : T[] {
		return [...this.dataUser];
	}

	/**
	 * @returns The id of this list which is currently always `null`.
	 */
	public get id() : null {
		// currently list have no ids.
		return null;
	}

	/**
	 * The data in the representation seen by the user. This is used by angular repeat commands.
	 * Furthermore, it is important that when this class contains wrapper classes then the wrapper objects
	 * are kept and not recreated the whole time. Thus, we keep them in this list.
	 *
	 * Note that when implementing this list's methods we must maintain both the rawData
	 * and this representation for the user.
	 */
	protected dataUser : T[] = [];

	/**
	 * @param v The index of the item or its id.
	 * @returns The desired item. When `v` is an id and no item with that id was found `null` is returned.
	 */
	public get(v : number | IdBase<any> | null) : T | null {
		if (v === null)
			return null;

		// searching for id?
		if (v instanceof IdBase) {
			for (const objectWrapper of this.dataUser) {
				if (Meta.isSameId((objectWrapper as unknown as ApiDataWrapperBase).id!.rawData, v.rawData)) {
					return objectWrapper;
				}
			}

			// id was not found
			return null;
		} else {
			// Otherwise v is the index.
			// In case v would be outside the array range this would return "undefined". Make sure it will be "null".
			return this.dataUser[v] ?? null;
		}
	}

	/**
	 * Returns the number of items in this list.
	 */
	public get length() : number {
		return this.dataUser.length;
	}

	/**
	 * Returns the first item in this list.
	 */
	public get first() : T | null {
		return this.dataUser[0] ?? null;
	}

	/**
	 * Returns the last item in this list.
	 */
	public get last() : T | null {
		if (this.dataUser.length === 0) return null;
		return this.dataUser[this.dataUser.length - 1] ?? null;
	}

	/**
	 * As the API data tree is also holding a reference to data,
	 * we would override it here if we used normal reference assignment,
	 * as the data tree would still hold the old reference.
	 */
	private copyDataUserToRawData() : void {
		for (const [i, item] of this.dataUser.entries()) {
			this.data[i + 1] = (item instanceof ObjectWithRawData) ? item.rawData : item;
		}
	}

	/**
	 * Take the provided sort options and apply them to the list.
	 *
	 * @param items The items to be sorted
	 *
	 * @param options We can pass 3 keys inside the options object:
	 * @param options.inPlace - boolean for if the sorting should occur inside the list or in a copy. (default: true)
	 * @param options.reverse - boolean to reverse order after applying sorting (default: false)
	 * @param options.removeDestroyedItems - remove the destroyed items if any (default: false)
	 * @returns the sorted list, either the list itself or a copy, if inPlace is set to false.
	 */
	// NOTE: The docs of ApiListWrapper#sort, ApiListWrapper#sortedBy and ApiListWrapper#applySortOptions need to be aligned with each other.
	private applySortOptions(items : T[], {inPlace = true, reverse = false, removeDestroyedItems = false} : SortOptions = {}) : this {
		if (inPlace) {
			this.dataUser = items;
			this.copyDataUserToRawData();
			if (reverse) items.reverse();
			if (!this.isView) {
				this.api.changed(this.propertyName);
			}
			return this;
		} else {
			const copiedList = this.createInstance(this.parent, true, removeDestroyedItems);
			copiedList.dataUser = [...items];
			copiedList.copyDataUserToRawData();

			// TODO: PLANO-184171 Switch from [...array].reverse() to ES2023’s array.toReversed()
			if (reverse) copiedList.dataUser.reverse();

			return copiedList;
		}
	}

	/**
	 * Sort the list according to a compare function that takes two items and returns a number.
	 *
	 * @example
	 * list.sort((member1,member2) => member1.age - member2.age) // will order the list based on members age
	 *
	 * @param compareFn Compare function used in Array.sort() @see http://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort . Alternatively you can pass
	 * an array of compareFns, where the importance of the criteria goes from highest to lowest.
	 *
	 * @param options We can pass 3 keys inside the options object:
	 * @param options.inPlace - boolean for if the sorting should occur inside the list or in a copy. (default: true)
	 * @param options.reverse - boolean to reverse order after applying sorting (default: false)
	 * @param options.removeDestroyedItems - remove the destroyed items if any (default: false)
	 * @returns the sorted list, either the list itself or a copy, if inPlace is set to false.
	 */
	// NOTE: The docs of ApiListWrapper#sort, ApiListWrapper#sortedBy and ApiListWrapper#applySortOptions need to be aligned with each other.
	public sort(compareFn : (SortSortingCriteria<T> | SortFnArray<T>), options : SortOptions = {} ) : this {

		// create a copy of this.dataUser
		const items = this.dataUser.slice();

		if (Array.isArray(compareFn)) {
			// TODO: PLANO-184171 Switch from [...array].reverse() to ES2023’s array.toReversed()
			for (const fn of [...compareFn].reverse()) {
				items.sort(fn);
			}
		} else items.sort(compareFn as SortSortingCriteria<T>);

		return this.applySortOptions(items, options);
	}

	/**
	 * @param compareFn The original compare method
	 * @returns A new compare method, that will ensure that if the compared value is a string, to do the comparison case-insensitive
	 * TODO: PLANO-187682 Check if this can be removed
	 */
	private ensureSortedByMethodIsCaseInsensitive(compareFn : SortedBySortingCriteria<T>) : ReturnType<typeof compareFn> {
		return (item : T) => {
			const value = compareFn(item);
			if (typeof value === 'string') {
				return value.toLowerCase();
			}
			return value;
		};
	}

	/**
	 * Sort the list according to iteratees that take an item and return any value to sort by.
	 *
	 * @example
	 * list.sortedBy(member => member.firstName) // will order the list based on the fist name of each item
	 *
	 * @param iteratee Can be one of these:
	 * 	- a function that takes the item and returns a property
	 * 	- an array, where the importance of the criteria goes from highest to lowest.
	 *
	 * @param options We can pass 3 keys inside the options object:
	 * @param options.inPlace - boolean for if the sorting should occur inside the list or in a copy. (default: false)
	 * @param options.reverse - boolean to reverse order after applying sorting (default: false)
	 * @param options.removeDestroyedItems - remove the destroyed items if any (default: false)
	 * @returns the sorted list, either the list itself or a copy, if inPlace is set to false.
	 */
	// NOTE: The docs of ApiListWrapper#sort, ApiListWrapper#sortedBy and ApiListWrapper#applySortOptions need to be aligned with each other.
	public sortedBy(iteratee : SortedBySortingCriteria<T> | SortedByFnArray<T>, options : SortOptions = {} ) : this {

		// create a copy of this.dataUser
		let items : T[] = this.dataUser.slice();

		if (iteratee instanceof Function) {
			items = _.sortBy(items, this.ensureSortedByMethodIsCaseInsensitive(iteratee));
		} else {
			if (Array.isArray(iteratee)) {
				// Create a copy of the iteratees, because .reverse() is in place and would change the content of the passed
				// iteratees if they are stored in a variable, const or property.
				// TODO: PLANO-184171 Switch from [...array].reverse() to ES2023’s array.toReversed()
				for (const element of [...iteratee].reverse()) {
					items = _.sortBy(items, this.ensureSortedByMethodIsCaseInsensitive(element));
				}
			}
		}
		if (options.inPlace === undefined) {
			options.inPlace = false;
		}

		return this.applySortOptions(items, options);
	}

	/**
	 * Returns a list of list where each inner list contains the values being equal. The outer lists
	 * are sorted according to the given ordering.
	 * @param compareFn A compare function defining the ordering of the items. See Array.prototype.sort() for a
	 * 		documentation of this function. All items where this method returns `0` will be put in the same group.
	 * @param removeDestroyedItems removeDestroyedItems value which is used to create the inner and outer lists.
	 * @param reverse Reverses the result
	 */
	public groupedBy(
		compareFn : (a : T, b : T) => number,
		removeDestroyedItems : boolean,
		reverse ?: boolean,
	) : ApiLists<this> {
		// override compareFn to include "reverse" parameter
		if (reverse) {
			const origCompareFn = compareFn;
			compareFn = (a : T, b : T) : number => {
				return -origCompareFn(a, b);
			};
		}

		// create sorted copy of items
		const items = this.dataUser.slice();
		items.sort(compareFn);

		//
		// 	Put all items with the same value in the same "group"
		//
		const result = new ApiLists<this>(this.api, null, true, removeDestroyedItems);

		/**
		 * An item of the group which we are currently processing
		 */
		let currGroupItem : T | null = null;

		for (const currItem of items) {
			// current item belongs to current group?
			if (currGroupItem !== null && compareFn(currItem, currGroupItem) === 0) {
				// then add current item to current group
				result.last!.dataUser.push(currItem);
			} else {
				// Otherwise create new group
				currGroupItem = currItem;

				const newGroup = this.createInstance(this.parent, true, removeDestroyedItems);

				// To not trigger a ApiBase#onChange we need to bypass ApiListWrapper‘s push method and operate directly on the dataUser array.
				// Note that this bypass only works in combination with the .copyDataUserToRawData() call after we have processes the list.
				newGroup.push(currItem);
				result.push(newGroup);
			}
		}

		return result;
	}

	/**
	 * Compare the items in this list vs the items in wrapper
	 * @param wrapper - Items to be compared with
	 */
	public equals(wrapper : this) : boolean {
		// same size
		if (wrapper.length !== this.length) {
			return false;
		}

		// iteriere listen
		for (let i = 0; i < wrapper.length; i++) {
			// innere liste index i ist gleich shiftModel
			if (wrapper.get(i) !== this.get(i)) {
				return false;
			}
		}
		return true;
	}

	/**
	 * @returns Does `this` and `wrapper` have the same items ignoring the order of the items?
	 */
	public equalsIgnoringOrder(wrapper : ApiListWrapper<T> | readonly T[]) : boolean {
		// same size
		if (wrapper.length !== this.length) {
			return false;
		}

		const items = wrapper instanceof ApiListWrapper ? wrapper.iterable() : wrapper;

		// "this" contains all items of "wrapper"?
		for (const item of items) {
			if (!this.contains(item))
				return false;
		}

		// "wrapper" contains all items of "list"?
		for (const item of this.iterable()) {
			if (!items.includes(item))
				return false;
		}

		return true;
	}

	/**
	 * @returns Returns a shallow copy of this list.
	 */
	public shallowCopy() : this {
		const copy = this.createInstance(this.parent, true, false);

		for (const item of this.iterable()) {
			copy.push(item);
		}

		return copy;
	}

	/**
	 * Removes the item with the index "index".
	 */
	public remove(index : number) : void {
		this.onItemRemove(this.dataUser[index]);

		this.data.splice(index + 1, 1);
		this.dataUser.splice(index, 1);
	}

	/**
	 * Removes all items of the list.
	 */
	public clear() : void {
		for (const item of this.dataUser)
			this.onItemRemove(item);

		this.data.splice(1, this.data.length - 1);
		this.dataUser = [];
	}

	/**
	 * Returns the index of item "item".
	 * @param item The item we are looking for.
	 * @returns Index of item. -1 is returned if item was not found.
	 */
	public indexOf(item : T | IdBase<any>) : number {
		if (item instanceof IdBase) {
			for (let i = 0; i < this.dataUser.length; ++i) {
				if ((this.dataUser[i] as unknown as ApiDataWrapperBase).id!.equals(item))
					return i;
			}

			return -1;
		} else {
			return this.dataUser.indexOf(item);
		}
	}

	/**
	 * Does the list contains a given item?
	 * @param item The item we are looking for.
	 */
	public contains(item : T | IdBase<any>) : boolean {
		// eslint-disable-next-line unicorn/prefer-includes -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.indexOf(item) >= 0;
	}

	/**
	 * Removes the item "item" if it is present in the list.
	 * @param item The item to remove
	 */
	public removeItem(item : T | IdBase<any>) : void {
		const index = this.indexOf(item);

		if (index >= 0)
			this.remove(index);
	}

	/**
	 * Removes the items "items".
	 * @param items The items to remove
	 */
	public removeItems(items : this) : void {
		for (const item of items.dataUser) {
			this.removeItem(item);
		}
	}

	/**
	 * Pushes a new item to the end of the list.
	 * @param item The new item.
	 * the parent of the item but still want to add it to a new list
	 */
	public push(item : T) : void {
		// get raw data of new item
		const itemRaw = item instanceof ObjectWithRawData ? item.rawData : item;

		// push
		this.data.push(itemRaw);
		this.dataUser.push(item);
		this.subscribeItemDestroyed(item);

		this.onItemAdded(item);
	}

	/**
	 * Pushes all items of `items` to the end of the list.
	 * @param items List containing the items which should be pushed.
	 */
	public pushItems(items : this) : void {
		for (const item of items.dataUser) {
			this.push(item);
		}
	}

	/**
	 * Toggle a value in the list.
	 * @param valueToToggle The value to Toggle.
	 */
	public toggleItem(valueToToggle : T) : void {
		const addItem = this.contains(valueToToggle);
		if (addItem) {
			this.push(valueToToggle);
		} else {
			this.removeItem(valueToToggle);
		}
	}

	/**
	 * Inserts `item` at position `index`. It will not replace any old item. Instead it moves all previous items from that
	 * position to the next index.
	 * @param index The (0-based) index where the item should be inserted.
	 * @param item The item to be inserted.
	 */
	public insert(index : Integer, item : T) : void {
		// get raw data of new item
		const itemRaw = item instanceof ObjectWithRawData ? item.rawData : item;

		// insert
		this.data.splice(index + 1, 0, itemRaw);
		this.dataUser.splice(index, 0, item);
		this.subscribeItemDestroyed(item);

		this.onItemAdded(item);
	}

	/**
	 * Unshift a new item to the start of the list.
	 * @param item The new item.
	 */
	public unshift(item : T) : void {
		// get raw data of new item
		const itemRaw = item instanceof ObjectWithRawData ? item.rawData : item;

		// push
		this.data.splice(1, 0, itemRaw);
		this.dataUser.unshift(item);
		this.subscribeItemDestroyed(item);

		this.onItemAdded(item);
	}

	private subscribeItemDestroyed(item : T) : void {
		if (this.removeDestroyedItems) {
			// TODO: Not completely sure if this is correct here:
			// Currently we do not have a unsubscribe method because this would require us to hold
			// a list of all subscriptions.
			// Now, but as removed wrappers normally are also destroyed they would anyways not emit destroy event.
			// If they would, should not be a problem. Right? ;)
			// eslint-disable-next-line rxjs/no-ignored-subscription -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			(item as unknown as ApiDataWrapperBase).destroyed.subscribe((listItem : ApiDataWrapperBase) => {
				this.removeItem(listItem as unknown as T);
			});
		}
	}

	/**
	 * Does this list contain primitives?
	 */
	protected abstract containsPrimitives() : boolean;

	/**
	 * Creates a new instance of the sub-class.
	 */
	protected abstract createInstance(parent : ApiDataWrapperBase | null, isView : boolean, removeDestroyedItems : boolean) : this;

	/**
	 * Creates a new item and adds it to the end of the list.
	 *
	 * In case, this list contains object-wrappers, the new item will have the following creation life-cycle:
	 * 1. The object-wrapper is created with an empty raw-data array. No raw-data is generated for any sub-items.
	 * 2. `preInitCode` is called which can be used to set dynamically any attributes required by `attributeInfo.show`.
	 * 		Here, you should not modify any sub-items as they will be overridden in the next step. Furthermore, you should
	 * 		prefer to use `tsDefaultValue` in the `<api>.xml` files as it will be executed always when creating an item of
	 * 		this type. This method should only be used when the initial value is passed in from outside.
	 * 3. All attributes and sub-items which are still not initialized will then be initialized by
	 * 		`attributeInfo.defaultValue` if `attributeInfo.show` is `true`.
	 *
	 * @param _preInitCode See above explained creation life-cycle. This code will be executed without checking
	 * `attributeInfo.canSet`, as this might fail with not properly initialized items.
	 * @param _id The database-id of the newly created item. Normally, you dont want to assign here any value as
	 * the database-id will be provided by backend.
	 * @returns The newly created item is returned.
	 */
	public createNewItem(
		_preInitCode : ((item : T) => void) | null = null,
		_id : IdBase<any> | null = null,
	) : T {
		throw new Error('Implemented in sub-classes.');
	}

	/**
	 * Does this list contains a new item? Currently, this only works for `ApiObjectWrapper`
	 * as we are checking `ApiObjectWrapper.isNewItem`.
	 */
	public get containsANewItem() : boolean {
		for (const item of this.dataUser) {
			if (item instanceof ApiObjectWrapper && item.isNewItem)
				return true;
		}

		return false;
	}

	/**
	 * This method creates a wrapper for existing raw-data.
	 * Has to be implemented by inherited classes.
	 * @param _itemRawData Items raw data
	 */
	protected wrapRawData(_itemRawData : any[]) : T { throw new Error('Not implemented.'); }

	/**
	 * Does this list contain ids?
	 */
	protected abstract containsIds() : boolean;

	/**
	 * Sets this list as the parent of the new item.
	 * @param newItem The newly created item
	 */
	private setParent(newItem : T) : void {
		if (newItem instanceof ApiDataWrapperBase)
			newItem.parent = this;
	}

	/**
	 * Handler being called after an operation which adds a new item to the list.
	 * @param newItem The new item.
	 */
	protected onItemAdded(newItem : T) : void {
		if (!this.isView) {
			this.setParent(newItem);
			this.api.changed(this.propertyName);
		}
	}

	/**
	 * Handler being called before an operation which removes an item from the list.
	 * @param removedItem The item that will be removed.
	 */
	protected onItemRemove(removedItem : T) : void {
		if (!this.isView) {
			if (removedItem instanceof ApiDataWrapperBase)
				removedItem.parent = null;

			this.api.changed(this.propertyName);
		}
	}

	/**
	 * INTERNAL METHOD!
	 *
	 * Replaces in this list and all items ids given in `idReplacements`.
	 * @param idReplacements All ids to be replaced.
	 */
	public _fixIds(idReplacements : Map<any, number>) : void {
		if (!this.data)
			return;

		if (this.containsIds()) {
			// So, we create new wrappers for each item with the replaced id.
			this.dataUser = [];

			for (let i = 1; i < this.data.length; ++i) {
				const newIdRaw = Meta.getReplacedId(this.data[i], idReplacements);

				this.data[i] = newIdRaw;
				this.dataUser.push(this.wrapRawData(newIdRaw));
			}
		} else if (!this.containsPrimitives()) {
			for (const item of this.dataUser) {
				(item as unknown as ApiDataWrapperBase)._fixIds(idReplacements);
			}
		}
	}

	public override get isNewItem() : boolean {
		// Currently, lists dont have a database-id. So, we cannot use it to check if this a new item or not.
		// For the moment we just check if the parent is a new item. This logic is not completely bullet-proof.
		return (this.parent instanceof ApiObjectWrapper) ? this.parent.isNewItem : false;
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		if (this.removeDestroyedItems && data && data.length > 1)
			throw new Error('This method currently does not support automatic item removal.');

		//
		// 	Updates users view
		//
		if (data) {
			if (this.containsIds()) {
				// We cannot call _updateRawData() for ids. See Id._updateRawData() for more info.
				// So, we create new wrappers for each item
				this.dataUser = [];

				for (let i = 1; i < data.length; ++i) {
					const itemRaw = data[i];

					this.dataUser.push(this.wrapRawData(itemRaw));
				}
			} else if (this.containsPrimitives()) {
				// just copy data removing meta
				this.dataUser = data.slice(1);
			} else {
				// this list contains wrapper classes.
				const oldDataUser = this.dataUser as unknown as ApiDataWrapperBase[];

				// create new list of wrapper classes
				const newDataUser : T[] = [];

				// methods to return index from current "oldDataUser" object.
				// Note that these methods explicitly only uses rawData and not the id wrappers
				// because they might not be up-to-date.
				const indexOf = (idRaw : any) : number => {
					for (const [i, element] of oldDataUser.entries()) {
						if (Meta.isSameId(idRaw, Meta.getBackendId(element.rawData)))
							return i;
					}

					return -1;
				};

				const indexOfByNewItemId = (newItemId : number | null) : number => {
					for (const [i, element] of oldDataUser.entries()) {
						if (Meta.getNewItemId(element.rawData) === newItemId)
							return i;
					}

					return -1;
				};

				for (let i = 1; i < data.length; ++i) {
					const itemRaw = data[i];

					// is there an old wrapper?
					const idRaw = Meta.getBackendId(itemRaw);
					const newItemId = Meta.getNewItemId(itemRaw);
					let oldWrapperIndex = -1;

					if (newItemId === null) {
						oldWrapperIndex = indexOf(idRaw);
					} else {
						oldWrapperIndex = indexOfByNewItemId(newItemId);
					}

					// Note that we explicitly don’t reset newItemId to 0. This is important if a multiple api.save() command are
					// executed (before the first backend response arrives) then resetting the newItemId would result that
					// only for the first backend response the client would be able to find the new-item wrapper.

					// old wrapper found!
					if (oldWrapperIndex >= 0) {
						// add wrapper with update raw data to new list
						const oldWrapper = oldDataUser[oldWrapperIndex];
						oldWrapper._updateRawData(itemRaw, generateMissingData);
						newDataUser.push(oldWrapper as unknown as T);

						// remove it from old list
						oldDataUser.splice(oldWrapperIndex, 1);
					} else {
						// no old wrapper was found?
						// Then we need to create a new one
						const newWrapper = this.wrapRawData(itemRaw);

						this.setParent(newWrapper);

						newDataUser.push(newWrapper);
					}
				}

				// all remaining wrapper objects in the old list are invalid now.
				for (const oldWrapper of oldDataUser) {
					oldWrapper._updateRawData(null, false);
					oldWrapper.parent = null;
				}

				// new list is final
				this.dataUser = newDataUser;
			}
		} else {
			// remove all old wrappers
			if (!this.containsPrimitives() && !this.containsIds() && this.data) {
				for (const item of this.dataUser) {
					(item as unknown as ApiDataWrapperBase)._updateRawData(null, false);
					(item as unknown as ApiDataWrapperBase).parent = null;
				}

			}

			this.dataUser = [];
		}

		//
		// 	Update raw data
		//
		this.data = data;
	}
}
