<!-- .obscure-block-in-percy does not work on img tags, because img can not have ::after or ::before. So we need to wrap it.  -->
<div class="obscure-block-in-percy">
	<img
		[src]="imageUrl"
		alt="YouTube-Thumbnail" i18n-alt
		(click)="onImageClick()"
		tabindex="0"
	/>
</div>
<fa-icon [icon]="enums.PlanoFaIconContextPool.BRAND_YOUTUBE" />

<ng-template #videoModalContent="pModalTemplateExportAsRef" (handleModalOpen)="onImageClick()" [pModalId]="'youtube'+key" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-content
		modalTitle="Tutorial-Video" i18n-modalTitle
		closeBtnLabel="Schließen" i18n-closeBtnLabel
		[hideDismissBtn]="true"
		(onDismiss)="d($event);"
		(onClose)="c($event);"
		size="frameless"
	>
		<p-modal-content-body>
			<ng-container *ngTemplateOutlet="iFrameTemplate" />
		</p-modal-content-body>
	</p-modal-content>
</ng-template>

<ng-template #iFrameTemplate>
	<div class="video-container">
		<iframe [src]="iFrameUrl" frameborder="0" allowfullscreen></iframe>
	</div>
</ng-template>
