import { IdBase } from '@plano/shared/api/base/id/id-base';
import { NonZeroInteger } from '@plano/shared/core/utils/typescript-utils-types';

/**
 * The id to a centrally defined label.
 */
export class LabelId extends IdBase<number> {
	/**
	 * @param value The raw-data of the label-id or a negative new-item-id.
	 */
	public static create<T extends number>(value : NonZeroInteger<T>) : LabelId {
		return new LabelId(value);
	}
}
