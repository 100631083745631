/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
// cSpell:ignore kolkov
import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

type ValueType = string;

/**
 * <p-text-editor> creates an angular editor with all the options for pEditables.
 * @example
 * 	<p-text-editor
 * 		[(ngModel)]="member.lastName"
 * 	></p-textarea>
 */
@Component({
	selector: 'p-text-editor',
	templateUrl: './p-text-editor.component.html',
	styleUrls: ['./p-text-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PTextEditorComponent),
			multi: true,
		},
	],
})
export class PTextEditorComponent extends ControlWithEditableDirective implements ControlValueAccessor,
	AfterContentInit, EditableControlInterface, PFormControlComponentInterface, AfterContentChecked {

	@ViewChild('editor') private wysiwygEditor ?: AngularEditorComponent;

	@HostBinding('class.flex-grow-1')
	protected readonly _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private placeholder : string | null = null;

	/** @see PFormControlComponentInterface#size */
	@Input() public set size(_input : PFormControlComponentInterface['size']) {
		throw new Error('Not implemented yet');
	}

	@Input('disabled') public override set disabled(input : boolean) {
		this.setDisabledState(input);
		this._disabled = input;
		super.disabled = input;
	}
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}

	@Input('formControl') public override control : PFormControl | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();

	// eslint-disable-next-line @angular-eslint/no-output-native -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	@Output() public blur = new EventEmitter<FocusEvent>();

	/** Event emitter for change event. */
	// eslint-disable-next-line @angular-eslint/no-output-native -- We do this for all our inputs
	@Output() protected change = new EventEmitter<Event>();

	constructor(
		protected override console : LogService,
		protected override pFormsService : PFormsService,
		private toastsService : ToastsService,
		private localize : LocalizePipe,
	) {
		super(false, pFormsService, console);
	}

	private hasPasteListener : boolean = false;

	/**
		 * Add the paste listener to the editor
		 */
	public addPasteListener() : void {
		if (this.hasPasteListener) return;
		requestAnimationFrame(() => {
			const element : HTMLElement = this.wysiwygEditor!.editorWrapper.nativeElement;
			this.hasPasteListener = true;
			element.addEventListener('paste', (event : ClipboardEvent) => {
				event.preventDefault();
				event.stopPropagation();

				// On windows the first item can be undefined
				const firstItemOfClipboard = event.clipboardData!.items[0] as DataTransferItem | undefined;
				if (!firstItemOfClipboard) return;

				if (firstItemOfClipboard.type === 'text/plain') {
					const pastedText = event.clipboardData!.getData('text/plain');
					// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					document.execCommand('insertText', false, pastedText);
				} else {
					this.toastsService.addToast({
						theme: this.enums.PThemeEnum.WARNING,
						title: this.localize.transform('Einfügen nicht möglich'),
						content: this.localize.transform('Es kann nur Text eingefügt werden, aber deine Zwischenablage enthält nicht nur Text.'),
					});
				}
			});
		});
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initOptions();
		if (this.cannotSetHint !== null) throw new Error('cannotSetHint not implemented yet in this component.');
		return super.ngAfterContentInit();
	}

	/**
		 * cSpell:ignore tiptap
		 * NOTE: Before you do fancy stuff here, think about creating a component with tiptap
		 * PLANO-36640
		 */
	public editorConfig : AngularEditorConfig = {
		editable: true,
		spellcheck: false,
		height: 'auto',
		minHeight: '0',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: '',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
		],
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			[
				'undo',
				'redo',
				'strikeThrough',
				'subscript',
				'superscript',
				'justifyLeft',
				'justifyCenter',
				'justifyRight',
				'justifyFull',
				'indent',
				'outdent',
				'heading',
				'fontName',
			],
			[
				'fontSize',
				'textColor',
				'backgroundColor',
				'customClasses',

				'insertImage',
				'insertVideo',
				'toggleEditorMode',
			],
		],
	};

	private initOptions() : void {
		this.editorConfig.placeholder = this.placeholder ?? '';
	}

	public _disabled : boolean = false;

	/**
		 * This is the minimum code that is required for a custom control in Angular.
		 * Its necessary to set this if you want to use [(ngModel)] AND [formControl] together.
		 */

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	private _value : ValueType | null = null;
	public override _onChange : (value : ValueType | null) => void = () => {};

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void {
		this._onChange((event.target as HTMLTextAreaElement).value);
		this.keyup.emit(event);
	}

	/** Get blur event from inside this component, and pass it on. */
	public onBlur(event : FocusEvent) : void {
		this.onTouched(event);
		this.blur.emit(event);
	}

	/**
	 * Get change event from inside this component, and pass it on.
	 * @param event The event that triggered the change
	 */
	public onChange(event : Event) : void {
		this._onChange((event.target as HTMLTextAreaElement).value);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = (_event : Event) : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (this._value === value) return;

		this._value = value;

		// TODO: PLANO-184271 - Still necessary? p-input don’t has this
		if (this.control) {
			this.control.markAsTouched();
			this.control.markAsDirty();
			this.control.updateValueAndValidity();
		}

		this._onChange(value);
	}

	/**
		 * Should the editable be started?
		 */
	public preventDefaultTriggerEditable(event : Event) : boolean {
		if (this.disabled || this.readMode) return true;
		const selection = window.getSelection();

		// allow text selection (copy)
		if (selection && selection.type === 'Range') return true;

		// don't start editable on link clicks
		if (event.target instanceof HTMLAnchorElement) return true;
		return false;
	}

	/**
		 * Handle click to start editing
		 */
	public handleClickToEdit(event : MouseEvent) : void {
		if (this.preventDefaultTriggerEditable(event)) return;
		this.addPasteListener();
		this.setFocus();
	}

	private setFocus() : void {
		window.setTimeout(() => {
			const element = this.wysiwygEditor!.textArea.nativeElement as HTMLTextAreaElement | undefined ?? null;
			if (element === null) {
				this.console.error('can not set focus to element', element);
				return;
			}
			element.focus();
		}, 10);
	}

	/**
		 * Write a new value to the element.
		 * This happens when the model that is bound to this component changes.
		 * @see ControlValueAccessor#writeValue
		 * @param value The new value for the element
		 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
	}

	/**
		 * @see ControlValueAccessor#registerOnChange
		 *
		 * Note that registerOnChange() only gets called if a formControl is bound.
		 * @param fn Accepts a callback function which you can call when changes happen,
		 * so that you can notify the outside world that
		 * the data model has changed.
		 * Note that you call it with the changed data model value.
		 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
		 * @see ControlValueAccessor#registerOnTouched
		 * Set the function to be called when the control receives a touch event.
		 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	// eslint-disable-next-line jsdoc/require-param -- False-positive. Interface defines the params.
	/** @see ControlValueAccessor#setDisabledState */
	public setDisabledState(isDisabled : boolean) : void {
		if (this._disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this._disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			// make sure the formControl value is up-to-date with the AI value
			if (!this.disabled && this.attributeInfo) this.refreshValue();
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/** @see PFormsService.visibleErrors */
	public get visibleErrors() : VisibleErrorsType {
		return this.pFormsService.visibleErrors(this.control!);
	}
}
