/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable, NgZone } from '@angular/core';

@Injectable({providedIn:'root'})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PClipboardService {
	constructor(
		private zone : NgZone,
	) {}

	private timeout : number | null = null;
	public copiedToClipboard : string | number | null = null;

	/**
     * Method to copy a given string to the clipboard
     */
	public copy(input : string | number) : void {
		window.clearTimeout(this.timeout ?? undefined);

		// Create a dummy input to copy the string array inside it
		const dummy = document.createElement('input');

		// Output the array into it
		dummy.value = typeof input === 'number' ? input.toString() : input;

		// Add it to the document
		document.body.appendChild(dummy);

		// Set its ID
		dummy.setAttribute('id', 'dummy_id');

		// Select it
		dummy.select();

		// Copy its contents
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		document.execCommand('copy');

		// Remove it as its not needed anymore
		document.body.removeChild(dummy);

		this.copiedToClipboard = input;
		this.zone.runOutsideAngular(() => {
			this.timeout = window.setTimeout(() => {
				this.zone.run(() => {
					if (this.copiedToClipboard !== null) {
						this.copiedToClipboard = null;
					}
				});
			}, 1000);
		});
	}

}
