/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint-disable max-classes-per-file -- FIXME: Remove this before you work here., Solve the remaining cases please. */
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { SchedulingApiLeave, SchedulingApiMember, SchedulingApiShiftAssignableMembers, SchedulingApiShiftExchangeBase, SchedulingApiShiftExchangeCommunicationAction, SchedulingApiShiftExchangeCommunicationBase, SchedulingApiShiftExchangeCommunicationRequesterRole, SchedulingApiShiftExchangeCommunicationsBase, SchedulingApiShiftExchangeCommunicationState, SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiShiftExchangeCommunicationSwapOffersBase, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefsBase, SchedulingApiShiftExchangesBase, SchedulingApiShiftExchangeShiftRef, SchedulingApiShiftExchangeShiftRefsBase, SchedulingApiShiftExchangeState, SchedulingApiShiftModel, SchedulingApiShifts, ShiftId } from '@plano/shared/api';
import { Date, DateExclusiveEnd, DateTime, Days } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftExchanges extends SchedulingApiShiftExchangesBase {

	/**
	 * Get a shift-exchange item by provided member-id and shift
	 */
	public getByShiftAndMember(shiftId : ShiftId, memberId : Id) : SchedulingApiShiftExchange | undefined {
		for (const shiftExchange of this.iterable()) {
			if (!shiftExchange.indisposedMemberId.equals(memberId)) continue;
			if (!shiftExchange.shifts.contains(shiftId)) continue;
			return shiftExchange;
		}
		return undefined;
	}

}

/**
 * Its possible that one illness report has more than one shiftRef.
 * E.g. all shiftRefs of the three days the user is ill.
 * How many ShiftExchanges should be generated then?
 */

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum GenerateShiftExchangesMode {

	/**
	 * Create one shiftExchange for each shift that is included in the shiftRefs of the original shiftExchange
	 */
	ONE_SHIFT_EXCHANGE_FOR_EACH,

	/**
	 * Create one shiftExchange for each shift that is included in the shiftRefs of the original shiftExchange, but
	 * shifts that are part of a package should result in one new generated shiftExchange
	 */
	ONE_SHIFT_EXCHANGE_FOR_EACH_PACKAGE,
}

/**
 * These are all the settings that can be made to generate new Leaves from a illness-shiftExchange
 */

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class GenerateShiftExchangesOptions {
	public mode : GenerateShiftExchangesMode | null = null;
	public daysBefore : Days | null = null;
	public deadline : DateExclusiveEnd | null = null;

	/**
	 * Reset all values
	 */
	public reset() : void {
		this.mode = null;
		this.daysBefore = null;
		this.deadline = null;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftExchange extends SchedulingApiShiftExchangeBase {

	/**
	 * Managers can generate new shiftExchanges from a illness report if they want to.
	 * Managers get the option to generate them in the following cases:
	 * - manager confirms a shiftExchange
	 * - manager creates a illness shiftExchange for another user
	 * - manager turns a non-illness shiftExchange of another user into illness
	 * Only set this if new items should be generated. Otherwise set it to undefined
	 */
	public generateShiftExchangesOptions : GenerateShiftExchangesOptions = new GenerateShiftExchangesOptions();

	/**
	 * Managers can generate new leaves from a illness report if they want to.
	 * Managers get the option to generate them in the following cases:
	 * - manager confirms a shiftExchange
	 * - manager creates a illness shiftExchange for another user
	 * - manager turns a non-illness shiftExchange of another user into illness
	 */
	public generateLeavesOptions = false;

	private _behavesAsNewItem : boolean | null = null;

	/**
	 * When a shiftExchange gets re-opened, the form should look and behave nearly the same as a
	 * form for a completely new item. In this case we need to set
	 * shiftExchange.behavesAsNewItem = true
	 */
	public get behavesAsNewItem() : boolean {
		if (this._behavesAsNewItem !== null) return this._behavesAsNewItem;
		return this.isNewItem;
	}

	public set behavesAsNewItem(value : boolean) {
		// this.api.changed();
		this._behavesAsNewItem = value;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get indisposedMember() : SchedulingApiMember | null {
		// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition -- remove this if you don’t find  a sentry entry for it
		if (this.indisposedMemberId === null) {
			this.api.console.error('indisposedMemberId is null. Type wrong?');
			return null;
		}
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return null;
		return this.api.data.members.get(this.indisposedMemberId);
	}

	/**
	 * Get the id of the responsible person. This can be the indisposed member or a admin if the admin created it
	 * for a member, or responded to a members illness.
	 */
	public get responsibleMemberId() : Id | null {
		if (this.isNewItem) return this.indisposedMemberId;
		if (!this.isIllness) return this.indisposedMemberId;
		if (!this.aiCommunications.isAvailable) return null;
		const actionEnum = SchedulingApiShiftExchangeCommunicationAction;

		const response = this.communications.findBy((item) => {
			switch (item.lastAction) {
				case actionEnum.A_REPORTED_ILLNESS:
				case actionEnum.ILLNESS_DECLINED_A_ACCEPT_WITH_SHIFT_EXCHANGE:
				case actionEnum.ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITH_SHIFT_EXCHANGE:
				case actionEnum.ILLNESS_CONFIRMED_WITHOUT_SHIFT_EXCHANGE_A_START_SHIFT_EXCHANGE:
				case actionEnum.ILLNESS_DECLINED_A_ACCEPT_WITHOUT_SHIFT_EXCHANGE:
				case actionEnum.ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITHOUT_SHIFT_EXCHANGE:
				case actionEnum.ILLNESS_NEEDS_CONFIRMATION_A_DECLINED:

					return true;
				default :
					return false;
			}
		});
		if (response) return response.communicationPartnerId;
		return null;
	}

	/**
	 * Get the member based on .responsibleMemberId
	 */
	public get responsibleMember() : SchedulingApiMember | null {
		if (this.responsibleMemberId === null) return null;
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return null;
		return this.api.data.members.get(this.responsibleMemberId);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get shiftModel() : SchedulingApiShiftModel | null | undefined {
		if (this.shiftRefs.length === 0) return undefined;
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return undefined;

		const firstShiftRef = this.shiftRefs.get(0);
		if (!firstShiftRef) throw new Error('Could not get first shift ref');
		return this.api.data.shiftModels.get(firstShiftRef.id.shiftModelId);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get newAssignedMember() : SchedulingApiMember | null {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return null;
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.newAssignedMemberId, 'newAssignedMemberId');
		return this.api.data.members.get(this.newAssignedMemberId);
	}

	private _shifts = new Data<SchedulingApiShifts>(this.api);
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get shifts() : SchedulingApiShifts {
		return this._shifts.get(() => {
			return this.api.data.shifts.filterBy((item) => this.shiftRefs.contains(item.id));
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get swappedShifts() : SchedulingApiShifts {
		return this.api.data.shifts.filterBy((item) => this.swappedShiftRefs.contains(item.id));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isClosed() : boolean {
		// If a shiftExchange gets re-opened then this is possible:
		if (this.behavesAsNewItem) return false;

		if (this.state === SchedulingApiShiftExchangeState.ACTIVE) return false;
		if (this.state === SchedulingApiShiftExchangeState.ILLNESS_NEEDS_CONFIRMATION) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isSwappedSuccessful() : boolean {
		return this.state === SchedulingApiShiftExchangeState.SWAP_SUCCESSFUL;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isBasedOnIllness() : boolean {
		if (!this.isIllness) return false;
		if (this.state === SchedulingApiShiftExchangeState.ACTIVE) return true;
		if (this.state === SchedulingApiShiftExchangeState.TAKE_SUCCESSFUL) return true;
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get relatedLeave() : SchedulingApiLeave | null {
		return this.api.data.leaves.get(this.leaveId);
	}

	/**
	 * NOTE: This is a re-implementation of logic from backend. So, keep them synchronized.
	 * @returns Should the comment of the indisposed member be shown?
	 */
	public hasPermissionToSeeIndisposedMemberComment() : boolean {
		const pPermissionsService = this.api.pPermissionsService;

		return !this.isIllness ||
			pPermissionsService.loggedInMember === this.indisposedMember ||
			pPermissionsService.hasManagerPermissionForShiftExchange(this);
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftExchangeCommunications extends SchedulingApiShiftExchangeCommunicationsBase {

	/**
	 * returns the item where a admin responded to a members illness report. No matter if declined or confirmed.
	 */
	public get managerResponseCommunication() : SchedulingApiShiftExchangeCommunication | null {
		return this.findBy((item) => {
			return item.lastActionIsAIllnessReview;
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get reactionsForList() : this {
		return this.filterBy((item) => {
			if (item.communicationState === SchedulingApiShiftExchangeCommunicationState.CP_NOT_RESPONDED) return false;
			if (item.communicationState === SchedulingApiShiftExchangeCommunicationState.CP_CANNOT_SHIFT_EXCHANGE) return false;
			return true;
		});
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftExchangeCommunication extends SchedulingApiShiftExchangeCommunicationBase {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get indisposedMembersSelectedSO() : SchedulingApiShiftExchangeCommunicationSwapOffer | null {
		if (this.swapOffers.length === 0) return null;
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.indisposedMembersSelectedSOId, 'indisposedMembersSelectedSOId');
		return this.swapOffers.get(this.indisposedMembersSelectedSOId);
	}

	/**
	 * It is possible that indisposed member changed mind over time. So here is a getter that sometimes needs to be
	 * checked in combination with or before checking shiftExchange.indisposedMemberPrefersSwapping.
	 */
	public get iMChangedMindWantsSwap() : boolean {
		switch (this.lastAction) {
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_ACCEPT :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_CANNOT :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_IM_DECLINE_SWAP :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_IM_CHANGE_SWAPPED_SHIFT :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MATCH :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MISMATCH :
			case SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_SWAP_IM_SWAP_SHIFT :
				return true;
			default :
				return false;
		}
	}

	/**
	 * It is possible that indisposed member changed mind over time. So here is a getter that sometimes needs to be
	 * checked in combination with or before checking shiftExchange.indisposedMemberPrefersSwapping.
	 */
	public get iMChangedMindWantsTake() : boolean {
		switch (this.lastAction) {
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_CP_ACCEPT :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_CP_CANNOT :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_CP_SWAP_SHIFT :
			case SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_IM_DECLINE_TAKE :
			case SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_TAKE_IM_TAKE_SHIFT :
				return true;
			default :
				return false;
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get communicationPartner() : SchedulingApiMember | null {
		// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.communicationPartnerId === null) {
			this.api.console.error('communicationPartnerId is null. Type wrong?');
			return null;
		}

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return null;
		return this.api.data.members.get(this.communicationPartnerId);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get lastActionIsAIllnessReview() : boolean {
		switch (this.lastAction) {
			case SchedulingApiShiftExchangeCommunicationAction.A_REPORTED_ILLNESS :
			case SchedulingApiShiftExchangeCommunicationAction.ILLNESS_NEEDS_CONFIRMATION_A_DECLINED :
			case SchedulingApiShiftExchangeCommunicationAction.ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITH_SHIFT_EXCHANGE :
			case SchedulingApiShiftExchangeCommunicationAction.ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITHOUT_SHIFT_EXCHANGE :
			case SchedulingApiShiftExchangeCommunicationAction.ILLNESS_CONFIRMED_WITHOUT_SHIFT_EXCHANGE_A_START_SHIFT_EXCHANGE :
				return true;
			default :
				return false;
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get lastActionIsAGeneratedIndisposedAction() : boolean {
		switch (this.lastAction) {
			case SchedulingApiShiftExchangeCommunicationAction.CP_IS_ILL :
			case SchedulingApiShiftExchangeCommunicationAction.CP_IS_ABSENT :
			case SchedulingApiShiftExchangeCommunicationAction.CP_ASSIGNED_SAME_TIME :
			case SchedulingApiShiftExchangeCommunicationAction.CP_ASSIGNED_SAME_SHIFT :
				return true;
			default :
				return false;
		}
	}

	/**
	 * NOTE: This is a re-implementation of logic from backend. So, keep them synchronized.
	 * @returns Should the last action comment be shown?
	 */
	public hasPermissionToSeeLastActionComment() : boolean {
		const shiftExchange = this.parent!.parent!;
		const pPermissionsService = this.api.pPermissionsService;
		const loggedInMember = pPermissionsService.loggedInMember;

		return shiftExchange.indisposedMember === loggedInMember ||
			this.communicationPartner === loggedInMember ||
			(this.isIllnessReviewCommunication && pPermissionsService.hasManagerPermissionForShiftExchange(shiftExchange));
	}

	/**
	 * NOTE: This is a re-implementation of logic from backend. So, keep them synchronized.
	 * @returns Can the logged in user edit the last-action comment?
	 */
	public hasPermissionToEditLastActionComment() : boolean {
		const shiftExchange = this.parent!.parent!;
		const pPermissionsService = this.api.pPermissionsService;
		const loggedInMember = pPermissionsService.loggedInMember;

		const lastAction = this.lastAction;
		const lastActionData = this.api.shiftExchangeConceptService.getActionData(lastAction);
		const expectedRequesterRole = lastActionData.requesterRole;

		if (expectedRequesterRole === SchedulingApiShiftExchangeCommunicationRequesterRole.IM) {
			return loggedInMember === shiftExchange.indisposedMember;
		} else {
			return loggedInMember === this.communicationPartner;
		}

	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftExchangeCommunicationSwapOffers extends
	SchedulingApiShiftExchangeCommunicationSwapOffersBase {

	/**
	 * Check each shiftRef if its the provided shiftId
	 */
	public containsShiftId(shiftId : ShiftId) : boolean {
		for (const item of this.iterable()) {
			if (!item.shiftRefs.contains(shiftId)) continue;
			return true;
		}
		return false;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs extends
	SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefsBase {

	/**
	 * Get the earliest shiftRef
	 */
	public get earliestStart() : number | null {
		if (!this.length) return null;
		const firstShiftRef = this.get(0);
		if (!firstShiftRef) throw new Error('Could not get first shift ref');
		let result = firstShiftRef.id.start;

		for (const shiftRef of this.iterable()) {
			const shiftStart = shiftRef.id.start;

			if (result <= shiftStart) continue;
			result = shiftStart;
		}
		return result;
	}
}

// export class SchedulingApiShiftExchangeShiftRefs extends
// 	SchedulingApiShiftExchangeShiftRefsBase {
// 	constructor(
// 		public api : SchedulingApiServiceBase | null,
// 		removeDestroyedItems : boolean,
// 	) {
// 		super(api, removeDestroyedItems);
// 	}
// }

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftExchangeShiftRefs extends SchedulingApiShiftExchangeShiftRefsBase {

	private memberIsAssignableToEachShiftRef(memberId : Id) : boolean {
		if (this.length === 1) return true;
		for (const item of this.iterable()) {
			const shift = this.api.data.shifts.get(item.id)!;
			if (!shift.assignableMembers.contains(memberId)) return false;

			// NOTE: We don’t filter assigned members. We show an alert in frontend if user selects a assigned member.
			// if (shift.assignedMemberIds.contains(memberId)) return false;

		}
		return true;
	}

	/**
	 * A list of all assignable members for this ShiftRef
	 * Assignable are members that are assignable to each and every of the provided shiftRefs
	 */
	public get assignableMembers() : SchedulingApiShiftAssignableMembers {
		const result = new SchedulingApiShiftAssignableMembers(this.api, null, true);
		if (!this.length) return result;
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return result;

		const firstShiftRef = this.get(0);
		if (!firstShiftRef) throw new Error('Could not get first shift ref');
		const firstShift = this.api.data.shifts.get(firstShiftRef.id);
		if (!firstShift) return result;

		return firstShift.assignableMembers.filterBy((item) => this.memberIsAssignableToEachShiftRef(item.memberId));
	}

	private getEarliestDateTime(property : 'start' | 'end') : DateTime | null {
		if (!this.length) return null;

		const firstShiftRef = this.get(0);
		if (!firstShiftRef) throw new Error('Could not get first shift ref');

		const getShiftRef = (shiftRef : SchedulingApiShiftExchangeShiftRef) : DateTime => {
			// If the shiftRef is new - it has no defined [property]. Taking it from shiftRef.id is a (hacky) workaround
			// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const result = shiftRef[property] ? shiftRef[property] : shiftRef.id[property];
			// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
			assumeDefinedToGetStrictNullChecksRunning(result, 'result');
			return result;
		};

		let result : number = getShiftRef(firstShiftRef);
		for (const shiftRef of this.iterable()) {
			// If the shiftFef is new - it has no defined [property]. Taking it from shiftRef.id is a (hacky) workaround
			const tempResult = getShiftRef(shiftRef);
			if (result <= tempResult) continue;
			result = tempResult;
		}
		return result;
	}

	/**
	 * Get the earliest shift start
	 */
	public get earliestStart() : DateTime | null {
		return this.getEarliestDateTime('start');
	}

	/**
	 * Get the earliest end
	 */
	public get earliestEnd() : DateTime | null {
		return this.getEarliestDateTime('end');
	}

	/** Get end DateTime of latest shift in these shiftRefs as timestamp */
	public get latestEndDateTime() : DateTime | null {
		if (!this.length) return null;
		let result : number | null = null;

		// Ret the latest end
		for (const shiftRef of this.iterable()) {
			const end = shiftRef.end;
			if (result && result >= end) continue;
			result = end;
		}
		assumeNonNull(result);
		return result;
	}

	/** Get end date of latest shift in these shiftRefs as timestamp */
	public get latestEnd() : Date | null {
		if (!this.length) return null;
		const result = this.latestEndDateTime;
		assumeNonNull(result);
		const pMoment = new PMomentService(Config.LOCALE_ID);
		return +pMoment.m(result).startOf('day');
	}

	/** Get start DateTime of latest shift in these shiftRefs as timestamp */
	public get latestStartDateTime() : DateTime | null {
		if (!this.length) return null;
		let result : number | null = null;

		// Ret the latest end
		for (const shiftRef of this.iterable()) {
			const end = shiftRef.start;
			if (result && result >= end) continue;
			result = end;
		}
		assumeNonNull(result);
		return result;
	}
}
