/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

import { AccountApiRoot } from '@plano/shared/api';


/**
 * This service enables access to the api "account".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	COMPANY_NAME = 1;
	LOCATION_NAME = 2;
	LOCATION_STREET_AND_HOUSE_NUMBER = 3;
	LOCATION_POSTAL_CODE = 4;
	LOCATION_CITY = 5;
	LOCATION_COUNTRY = 6;
	LOCATION_HOMEPAGE = 7;
	CEO_FIRST_NAME = 8;
	CEO_LAST_NAME = 9;
	BILLING = 10;
	HRM_CONTACT_FIRST_NAME = 11;
	HRM_CONTACT_LAST_NAME = 12;
	HRM_CONTACT_EMAIL = 13;
	HRM_CONTACT_PHONE = 14;
	BOOKING_SYSTEM_CONTACT_FIRST_NAME = 15;
	BOOKING_SYSTEM_CONTACT_LAST_NAME = 16;
	BOOKING_SYSTEM_CONTACT_EMAIL = 17;
	BOOKING_SYSTEM_CONTACT_PHONE = 18;
	ACCOUNTING_PERIOD_START_DAY = 19;
	TERMS_URL = 20;
	DATA_PROTECTION_URL = 21;
	TERMS_OF_PARTICIPATION_URL = 22;
	CANCELLATION_POLICY_URL = 23;
	BOOKABLE_MAILS_SENDER_NAME = 24;
	AUTOMATIC_BOOKABLE_ANONYMIZATION_AFTER_PERIOD = 25;
	NEXT_AUTOMATIC_BOOKABLE_ANONYMIZATION = 26;
	AUTOMATIC_BOOKABLE_ANONYMIZATION_EXPORT_PASSWORD = 27;
	PAYOUT_NOTIFICATION_EXPORT_PASSWORD = 28;
	TYPE = 29;
	CUSTOMER_ID = 30;
	POS_SYSTEM_PASSWORD = 31;
	DISCOUNT_CODE = 32;
	BILLING_BANK_ACCOUNT_OWNER = 1;
	BILLING_BANK_ACCOUNT_IBAN = 2;
	BILLING_BANK_ACCOUNT_BIC = 3;
	BILLING_VAT_NUMBER = 4;
	BILLING_CONTACT_FIRST_NAME = 5;
	BILLING_CONTACT_LAST_NAME = 6;
	BILLING_BILL_ADDRESS_IS_LOCATION_ADDRESS = 7;
	BILLING_CONTACT_EMAIL = 8;
	BILLING_CONTACT_PHONE = 9;
	BILLING_STREET_AND_HOUSE_NUMBER = 10;
	BILLING_POSTAL_CODE = 11;
	BILLING_CITY = 12;
	BILLING_COUNTRY = 13;
	BILLING_ACTIVE_MEMBERS_COUNT_FOR_NEXT_PRICING = 14;
	BILLING_BILLOMAT_CUSTOMER_PORTAL_URL = 15;
}


@Injectable({
  providedIn: 'root',
})
export class AccountApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'account');
	}

	consts = new Consts();

	protected version() : string {
		return 'e2b21e85dc36774a9b6b706b25ba9e74,aff730c602f2598e245993440c9aaccd';
	}

	private dataWrapper = new AccountApiRoot(this);

	get data() : AccountApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : AccountApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new AccountApiRoot(this);
	}
}

		 
export class AccountApiRootBase extends ApiObjectWrapper<AccountApiRoot>
{
	constructor(override readonly api : AccountApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AccountApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, AccountApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AccountApiRoot, AccountApiRoot> = new ApiAttributeInfo<AccountApiRoot, AccountApiRoot>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			defaultValue: function(this : AccountApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiCompanyName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'companyName',
			nodeName: 'COMPANY_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(100, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiLocationName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'locationName',
			nodeName: 'LOCATION_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(100, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiLocationStreetAndHouseNumber : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'locationStreetAndHouseNumber',
			nodeName: 'LOCATION_STREET_AND_HOUSE_NUMBER',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
		if(((this.type === AccountApiType.PAID || this.type === AccountApiType.TERMINATED || this.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
					() => {
						return this.api.validators.maxLength(200, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiLocationPostalCode : ApiAttributeInfo<AccountApiRoot, PostalCode> = new ApiAttributeInfo<AccountApiRoot, PostalCode>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'locationPostalCode',
			nodeName: 'LOCATION_POSTAL_CODE',
			type: PApiType.PostalCode,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
		if(((this.type === AccountApiType.PAID || this.type === AccountApiType.TERMINATED || this.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.PostalCode, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiLocationCity : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'locationCity',
			nodeName: 'LOCATION_CITY',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
		if(((this.type === AccountApiType.PAID || this.type === AccountApiType.TERMINATED || this.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
					() => {
						return this.api.validators.maxLength(100, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiLocationCountry : ApiAttributeInfo<AccountApiRoot, AccountApiCountry> = new ApiAttributeInfo<AccountApiRoot, AccountApiCountry>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'locationCountry',
			nodeName: 'LOCATION_COUNTRY',
			type: PApiType.Enum,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiLocationHomepage : ApiAttributeInfo<AccountApiRoot, Url> = new ApiAttributeInfo<AccountApiRoot, Url>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'locationHomepage',
			nodeName: 'LOCATION_HOMEPAGE',
			type: PApiType.Url,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
						return this.api.validators.maxLength(1024, PApiType.Url, undefined);
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiCeoFirstName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'ceoFirstName',
			nodeName: 'CEO_FIRST_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
		if(((this.type === AccountApiType.PAID || this.type === AccountApiType.TERMINATED || this.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
					() => {
						return this.api.validators.maxLength(100, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiCeoLastName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'ceoLastName',
			nodeName: 'CEO_LAST_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
		if(((this.type === AccountApiType.PAID || this.type === AccountApiType.TERMINATED || this.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiHrmContactFirstName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'hrmContactFirstName',
			nodeName: 'HRM_CONTACT_FIRST_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiHrmContactLastName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'hrmContactLastName',
			nodeName: 'HRM_CONTACT_LAST_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiHrmContactEmail : ApiAttributeInfo<AccountApiRoot, Email> = new ApiAttributeInfo<AccountApiRoot, Email>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'hrmContactEmail',
			nodeName: 'HRM_CONTACT_EMAIL',
			type: PApiType.Email,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiHrmContactPhone : ApiAttributeInfo<AccountApiRoot, Tel> = new ApiAttributeInfo<AccountApiRoot, Tel>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'hrmContactPhone',
			nodeName: 'HRM_CONTACT_PHONE',
			type: PApiType.Tel,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 14,
		});
	aiBookingSystemContactFirstName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'bookingSystemContactFirstName',
			nodeName: 'BOOKING_SYSTEM_CONTACT_FIRST_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiBookingSystemContactLastName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'bookingSystemContactLastName',
			nodeName: 'BOOKING_SYSTEM_CONTACT_LAST_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiBookingSystemContactEmail : ApiAttributeInfo<AccountApiRoot, Email> = new ApiAttributeInfo<AccountApiRoot, Email>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'bookingSystemContactEmail',
			nodeName: 'BOOKING_SYSTEM_CONTACT_EMAIL',
			type: PApiType.Email,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	aiBookingSystemContactPhone : ApiAttributeInfo<AccountApiRoot, Tel> = new ApiAttributeInfo<AccountApiRoot, Tel>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'bookingSystemContactPhone',
			nodeName: 'BOOKING_SYSTEM_CONTACT_PHONE',
			type: PApiType.Tel,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 18,
		});
	aiAccountingPeriodStartDay : ApiAttributeInfo<AccountApiRoot, Days> = new ApiAttributeInfo<AccountApiRoot, Days>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'accountingPeriodStartDay',
			nodeName: 'ACCOUNTING_PERIOD_START_DAY',
			type: PApiType.Days,
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Days, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Days, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(28, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiTermsUrl : ApiAttributeInfo<AccountApiRoot, Url> = new ApiAttributeInfo<AccountApiRoot, Url>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'termsUrl',
			nodeName: 'TERMS_URL',
			type: PApiType.Url,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 20,
		});
	aiDataProtectionUrl : ApiAttributeInfo<AccountApiRoot, Url> = new ApiAttributeInfo<AccountApiRoot, Url>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'dataProtectionUrl',
			nodeName: 'DATA_PROTECTION_URL',
			type: PApiType.Url,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 21,
		});
	aiTermsOfParticipationUrl : ApiAttributeInfo<AccountApiRoot, Url> = new ApiAttributeInfo<AccountApiRoot, Url>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'termsOfParticipationUrl',
			nodeName: 'TERMS_OF_PARTICIPATION_URL',
			type: PApiType.Url,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 22,
		});
	aiCancellationPolicyUrl : ApiAttributeInfo<AccountApiRoot, Url> = new ApiAttributeInfo<AccountApiRoot, Url>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'cancellationPolicyUrl',
			nodeName: 'CANCELLATION_POLICY_URL',
			type: PApiType.Url,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 23,
		});
	aiBookableMailsSenderName : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'bookableMailsSenderName',
			nodeName: 'BOOKABLE_MAILS_SENDER_NAME',
			type: PApiType.string,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiAutomaticBookableAnonymizationAfterPeriod : ApiAttributeInfo<AccountApiRoot, Months> = new ApiAttributeInfo<AccountApiRoot, Months>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'automaticBookableAnonymizationAfterPeriod',
			nodeName: 'AUTOMATIC_BOOKABLE_ANONYMIZATION_AFTER_PERIOD',
			type: PApiType.Months,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.min(2, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiNextAutomaticBookableAnonymization : ApiAttributeInfo<AccountApiRoot, DateTime> = new ApiAttributeInfo<AccountApiRoot, DateTime>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'nextAutomaticBookableAnonymization',
			nodeName: 'NEXT_AUTOMATIC_BOOKABLE_ANONYMIZATION',
			type: PApiType.DateTime,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 26,
		});
	aiAutomaticBookableAnonymizationExportPassword : ApiAttributeInfo<AccountApiRoot, Password> = new ApiAttributeInfo<AccountApiRoot, Password>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'automaticBookableAnonymizationExportPassword',
			nodeName: 'AUTOMATIC_BOOKABLE_ANONYMIZATION_EXPORT_PASSWORD',
			type: PApiType.Password,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 27,
		});
	aiPayoutNotificationExportPassword : ApiAttributeInfo<AccountApiRoot, Password> = new ApiAttributeInfo<AccountApiRoot, Password>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'payoutNotificationExportPassword',
			nodeName: 'PAYOUT_NOTIFICATION_EXPORT_PASSWORD',
			type: PApiType.Password,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			isAvailableByBusinessLogic: function(this : AccountApiRoot) {
				return (((this.api.schedulingApi.data.aiAdyenAccount.isAvailable &&
						this.api.schedulingApi.data.adyenAccount.aiAccountHolderState.isAvailable) ?
						this.api.schedulingApi.data.adyenAccount.accountHolderState !== SchedulingApiAccountHolderState.NOT_INITIALIZED :
						undefined)&&((this.api.schedulingApi.data.aiAdyenAccount.isAvailable &&
						this.api.schedulingApi.data.adyenAccount.aiAccountHolderState.isAvailable) ?
						this.api.schedulingApi.data.adyenAccount.accountHolderState !== SchedulingApiAccountHolderState.INITIAL_ONBOARDING :
						undefined));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 28,
		});
	aiType : ApiAttributeInfo<AccountApiRoot, AccountApiType> = new ApiAttributeInfo<AccountApiRoot, AccountApiType>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'type',
			nodeName: 'TYPE',
			type: PApiType.Enum,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});
	aiCustomerId : ApiAttributeInfo<AccountApiRoot, Integer> = new ApiAttributeInfo<AccountApiRoot, Integer>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'customerId',
			nodeName: 'CUSTOMER_ID',
			type: PApiType.Integer,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 30,
		});
	aiPosSystemPassword : ApiAttributeInfo<AccountApiRoot, Password> = new ApiAttributeInfo<AccountApiRoot, Password>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'posSystemPassword',
			nodeName: 'POS_SYSTEM_PASSWORD',
			type: PApiType.Password,
			hasPermissionToGet: function(this : AccountApiRoot) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 31,
		});
	aiDiscountCode : ApiAttributeInfo<AccountApiRoot, string> = new ApiAttributeInfo<AccountApiRoot, string>({
			apiObjWrapper: this as any as AccountApiRoot,
			name: 'discountCode',
			nodeName: 'DISCOUNT_CODE',
			type: PApiType.string,
			hasPermissionToSet: function(this : AccountApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiRoot) {
				return [
					() => {
						return this.api.validators.maxLength(50, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 32,
		});

	/**
	 * Name of the company
	 *
	 * @type {string}
	 */
	get companyName() : string {
		this.getterDebugValidations(this.aiCompanyName, false);
		return this.data[1];
	}

	set companyName(v : string) {
        this.setterImpl(1, v, 'companyName', false, null, null);
	}

	/**
	 * Name of the location
	 *
	 * @type {string}
	 */
	get locationName() : string {
		this.getterDebugValidations(this.aiLocationName, false);
		return this.data[2];
	}

	set locationName(v : string) {
        this.setterImpl(2, v, 'locationName', false, null, null);
	}

	/**
	 * Street and house number of the location
	 *
	 * @type {string}
	 */
	get locationStreetAndHouseNumber() : string | null {
		this.getterDebugValidations(this.aiLocationStreetAndHouseNumber, false);
		return this.data[3];
	}

	set locationStreetAndHouseNumber(v : string | null) {
        this.setterImpl(3, v, 'locationStreetAndHouseNumber', false, null, null);
	}

	/**
	 * Postal code of the location
	 *
	 * @type {PostalCode}
	 */
	get locationPostalCode() : PostalCode | null {
		this.getterDebugValidations(this.aiLocationPostalCode, false);
		return this.data[4];
	}

	set locationPostalCode(v : PostalCode | null) {
        this.setterImpl(4, v, 'locationPostalCode', false, null, null);
	}

	/**
	 * City of the location
	 *
	 * @type {string}
	 */
	get locationCity() : string | null {
		this.getterDebugValidations(this.aiLocationCity, false);
		return this.data[5];
	}

	set locationCity(v : string | null) {
        this.setterImpl(5, v, 'locationCity', false, null, null);
	}

	/**
	 * location country
	 *
	 * @type {AccountApiCountry}
	 */
	get locationCountry() : AccountApiCountry {
		this.getterDebugValidations(this.aiLocationCountry, false);
		return this.data[6];
	}

	set locationCountryTestSetter(v : AccountApiCountry) {
        this.setterImpl(6, v, 'locationCountry', true, null, null);
	}

	/**
	 * Homepage-URL of the location/company
	 *
	 * @type {Url}
	 */
	get locationHomepage() : Url | null {
		this.getterDebugValidations(this.aiLocationHomepage, false);
		return this.data[7];
	}

	set locationHomepage(v : Url | null) {
        this.setterImpl(7, v, 'locationHomepage', false, null, null);
	}

	/**
	 * First name of the CEO (Name being printed on the bill).
	 *
	 * @type {string}
	 */
	get ceoFirstName() : string | null {
		this.getterDebugValidations(this.aiCeoFirstName, false);
		return this.data[8];
	}

	set ceoFirstName(v : string | null) {
        this.setterImpl(8, v, 'ceoFirstName', false, null, null);
	}

	/**
	 * Last name of the CEO (Name being printed on the bill).
	 *
	 * @type {string}
	 */
	get ceoLastName() : string | null {
		this.getterDebugValidations(this.aiCeoLastName, false);
		return this.data[9];
	}

	set ceoLastName(v : string | null) {
        this.setterImpl(9, v, 'ceoLastName', false, null, null);
	}

	private billingWrapper : AccountApiBilling = new AccountApiBilling(this.api,
		this as unknown as AccountApiRoot);
	public aiBilling = this.billingWrapper.aiThis;

	/**
	 * Object containing billing information.
	 */
	get billing() : AccountApiBilling {
		this.getterDebugValidations(this.aiBilling, false);
		return this.billingWrapper;
	}

	set billingTestSetter(v : AccountApiBilling) {
        this.setterImpl(10, v.rawData, 'billing', true, null, function(this : AccountApiRoot) {this.billingWrapper = v;});
	}

	/**
	 * First name of the person we can contact concerning staff scheduling.
	 *
	 * @type {string}
	 */
	get hrmContactFirstName() : string {
		this.getterDebugValidations(this.aiHrmContactFirstName, false);
		return this.data[11];
	}

	set hrmContactFirstName(v : string) {
        this.setterImpl(11, v, 'hrmContactFirstName', false, null, null);
	}

	/**
	 * Last name of the person we can contact concerning staff scheduling.
	 *
	 * @type {string}
	 */
	get hrmContactLastName() : string {
		this.getterDebugValidations(this.aiHrmContactLastName, false);
		return this.data[12];
	}

	set hrmContactLastName(v : string) {
        this.setterImpl(12, v, 'hrmContactLastName', false, null, null);
	}

	/**
	 * Email of the person we can contact concerning staff scheduling.
	 *
	 * @type {Email}
	 */
	get hrmContactEmail() : Email {
		this.getterDebugValidations(this.aiHrmContactEmail, false);
		return this.data[13];
	}

	set hrmContactEmail(v : Email) {
        this.setterImpl(13, v, 'hrmContactEmail', false, null, null);
	}

	/**
	 * Phone of the person we can contact concerning staff scheduling.
	 *
	 * @type {Tel}
	 */
	get hrmContactPhone() : Tel | null {
		this.getterDebugValidations(this.aiHrmContactPhone, false);
		return this.data[14];
	}

	set hrmContactPhone(v : Tel | null) {
        this.setterImpl(14, v, 'hrmContactPhone', false, null, null);
	}

	/**
	 * First name of the person we can contact concerning the booking system.
	 *
	 * @type {string}
	 */
	get bookingSystemContactFirstName() : string {
		this.getterDebugValidations(this.aiBookingSystemContactFirstName, false);
		return this.data[15];
	}

	set bookingSystemContactFirstName(v : string) {
        this.setterImpl(15, v, 'bookingSystemContactFirstName', false, null, null);
	}

	/**
	 * Last name of the person we can contact concerning the booking system.
	 *
	 * @type {string}
	 */
	get bookingSystemContactLastName() : string {
		this.getterDebugValidations(this.aiBookingSystemContactLastName, false);
		return this.data[16];
	}

	set bookingSystemContactLastName(v : string) {
        this.setterImpl(16, v, 'bookingSystemContactLastName', false, null, null);
	}

	/**
	 * Email of the person we can contact concerning the booking system.
	 *
	 * @type {Email}
	 */
	get bookingSystemContactEmail() : Email {
		this.getterDebugValidations(this.aiBookingSystemContactEmail, false);
		return this.data[17];
	}

	set bookingSystemContactEmail(v : Email) {
        this.setterImpl(17, v, 'bookingSystemContactEmail', false, null, null);
	}

	/**
	 * Phone of the person we can contact concerning the booking system.
	 *
	 * @type {Tel}
	 */
	get bookingSystemContactPhone() : Tel | null {
		this.getterDebugValidations(this.aiBookingSystemContactPhone, false);
		return this.data[18];
	}

	set bookingSystemContactPhone(v : Tel | null) {
        this.setterImpl(18, v, 'bookingSystemContactPhone', false, null, null);
	}

	/**
	 * The start day of the accounting period. Should be a value in range [1, 28].
	 *
	 * @type {Days}
	 */
	get accountingPeriodStartDay() : Days {
		this.getterDebugValidations(this.aiAccountingPeriodStartDay, false);
		return this.data[19];
	}

	set accountingPeriodStartDay(v : Days) {
        this.setterImpl(19, v, 'accountingPeriodStartDay', false, null, null);
	}

	/**
	 * Client's terms page url.
	 *
	 * @type {Url}
	 */
	get termsUrl() : Url | null {
		this.getterDebugValidations(this.aiTermsUrl, false);
		return this.data[20];
	}

	set termsUrl(v : Url | null) {
        this.setterImpl(20, v, 'termsUrl', false, null, null);
	}

	/**
	 * Client's data protection page url.
	 *
	 * @type {Url}
	 */
	get dataProtectionUrl() : Url | null {
		this.getterDebugValidations(this.aiDataProtectionUrl, false);
		return this.data[21];
	}

	set dataProtectionUrl(v : Url | null) {
        this.setterImpl(21, v, 'dataProtectionUrl', false, null, null);
	}

	/**
	 * Client's terms of participation page url.
	 *
	 * @type {Url}
	 */
	get termsOfParticipationUrl() : Url | null {
		this.getterDebugValidations(this.aiTermsOfParticipationUrl, false);
		return this.data[22];
	}

	set termsOfParticipationUrl(v : Url | null) {
        this.setterImpl(22, v, 'termsOfParticipationUrl', false, null, null);
	}

	/**
	 * Client's cancellation policy page url.
	 *
	 * @type {Url}
	 */
	get cancellationPolicyUrl() : Url | null {
		this.getterDebugValidations(this.aiCancellationPolicyUrl, false);
		return this.data[23];
	}

	set cancellationPolicyUrl(v : Url | null) {
        this.setterImpl(23, v, 'cancellationPolicyUrl', false, null, null);
	}

	/**
	 * Sender name to be set for bookable emails.
	 *
	 * @type {string}
	 */
	get bookableMailsSenderName() : string {
		this.getterDebugValidations(this.aiBookableMailsSenderName, false);
		return this.data[24];
	}

	set bookableMailsSenderName(v : string) {
        this.setterImpl(24, v, 'bookableMailsSenderName', false, null, null);
	}

	/**
	 * After which period should a bookable be anonymized? The relevant date is for bookings the last shift of a course or for gift cards the expiration date (if one exists).
	 *
	 * @type {Months}
	 */
	get automaticBookableAnonymizationAfterPeriod() : Months | null {
		this.getterDebugValidations(this.aiAutomaticBookableAnonymizationAfterPeriod, false);
		return this.data[25];
	}

	set automaticBookableAnonymizationAfterPeriod(v : Months | null) {
        this.setterImpl(25, v, 'automaticBookableAnonymizationAfterPeriod', false, null, null);
	}

	/**
	 * When will automatic bookable anonymization be executed next time?
	 *
	 * @type {DateTime}
	 */
	get nextAutomaticBookableAnonymization() : DateTime | null {
		this.getterDebugValidations(this.aiNextAutomaticBookableAnonymization, false);
		return this.data[26];
	}

	set nextAutomaticBookableAnonymizationTestSetter(v : DateTime | null) {
        this.setterImpl(26, v, 'nextAutomaticBookableAnonymization', true, null, null);
	}

	/**
	 * Password for opening the automatic bookable anonymization exports.
	 *
	 * @type {Password}
	 */
	get automaticBookableAnonymizationExportPassword() : Password {
		this.getterDebugValidations(this.aiAutomaticBookableAnonymizationExportPassword, false);
		return this.data[27];
	}

	set automaticBookableAnonymizationExportPasswordTestSetter(v : Password) {
        this.setterImpl(27, v, 'automaticBookableAnonymizationExportPassword', true, null, null);
	}

	/**
	 * Password for opening the payout-export in an payout-success-mail.
	 *
	 * @type {Password}
	 */
	get payoutNotificationExportPassword() : Password {
		this.getterDebugValidations(this.aiPayoutNotificationExportPassword, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[28] === undefined) {
			this.data[28] = this.aiPayoutNotificationExportPassword.defaultValue;
			
		}
		return this.data[28];
	}

	set payoutNotificationExportPasswordTestSetter(v : Password) {
        this.setterImpl(28, v, 'payoutNotificationExportPassword', true, null, null);
	}

	/**
	 * The type of this client. You can also use this to transform a test into a paid account.
	 *
	 * @type {AccountApiType}
	 */
	get type() : AccountApiType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[29];
	}

	set type(v : AccountApiType) {
        this.setterImpl(29, v, 'type', false, null, null);
	}

	/**
	 * The unique customer id. Read-only value.
	 *
	 * @type {Integer}
	 */
	get customerId() : Integer {
		this.getterDebugValidations(this.aiCustomerId, false);
		return this.data[30];
	}

	set customerIdTestSetter(v : Integer) {
        this.setterImpl(30, v, 'customerId', true, null, null);
	}

	/**
	 * Password to be used by the POS system for the Dr. Plano POS api. Read-only.
	 *
	 * @type {Password}
	 */
	get posSystemPassword() : Password {
		this.getterDebugValidations(this.aiPosSystemPassword, false);
		return this.data[31];
	}

	set posSystemPasswordTestSetter(v : Password) {
        this.setterImpl(31, v, 'posSystemPassword', true, null, null);
	}

	/**
	 * Discount code which can only be set when transforming account to a paid account.
	 *
	 * @type {string}
	 */
	get discountCode() : string | null {
		this.getterDebugValidations(this.aiDiscountCode, false);
		return this.data[32];
	}

	set discountCode(v : string | null) {
        this.setterImpl(32, v, 'discountCode', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.billingWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.billingWrapper._updateRawData(data && data[10] !== undefined ? data[10] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : AccountApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

export enum AccountApiCountry {
	GERMANY = 1,
	AUSTRIA = 2,
	SWITZERLAND = 3,
	ITALY = 4,
	ROMANIA = 5,
	NETHERLANDS = 6,
	BELGIUM = 7,
	UNITED_KINGDOM = 8,
	CZECH_REPUBLIC = 9,
	SWEDEN = 10,
	LUXEMBOURG = 11,
}
		 
export class AccountApiBilling extends ApiObjectWrapper<AccountApiBilling>
{
	constructor(override readonly api : AccountApiService,
		override readonly parent : AccountApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AccountApiBilling> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AccountApiBilling as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AccountApiBilling, AccountApiBilling> = new ApiAttributeInfo<AccountApiBilling, AccountApiBilling>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'billing',
			nodeName: 'BILLING',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : AccountApiBilling) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : AccountApiBilling) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : AccountApiBilling, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 10,
		});
	aiBankAccountOwner : ApiAttributeInfo<AccountApiBilling, string> = new ApiAttributeInfo<AccountApiBilling, string>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'bankAccountOwner',
			nodeName: 'BILLING_BANK_ACCOUNT_OWNER',
			type: PApiType.string,
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiBankAccountIban : ApiAttributeInfo<AccountApiBilling, Iban> = new ApiAttributeInfo<AccountApiBilling, Iban>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'bankAccountIban',
			nodeName: 'BILLING_BANK_ACCOUNT_IBAN',
			type: PApiType.Iban,
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.Iban, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiBankAccountBic : ApiAttributeInfo<AccountApiBilling, Bic> = new ApiAttributeInfo<AccountApiBilling, Bic>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'bankAccountBic',
			nodeName: 'BILLING_BANK_ACCOUNT_BIC',
			type: PApiType.Bic,
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.Bic, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiVatNumber : ApiAttributeInfo<AccountApiBilling, string> = new ApiAttributeInfo<AccountApiBilling, string>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'vatNumber',
			nodeName: 'BILLING_VAT_NUMBER',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : AccountApiBilling) {
				return ((this.country !== AccountApiCountry.GERMANY));
			},
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.country !== AccountApiCountry.GERMANY)&&(this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiContactFirstName : ApiAttributeInfo<AccountApiBilling, string> = new ApiAttributeInfo<AccountApiBilling, string>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'contactFirstName',
			nodeName: 'BILLING_CONTACT_FIRST_NAME',
			type: PApiType.string,
			validations: function(this : AccountApiBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiContactLastName : ApiAttributeInfo<AccountApiBilling, string> = new ApiAttributeInfo<AccountApiBilling, string>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'contactLastName',
			nodeName: 'BILLING_CONTACT_LAST_NAME',
			type: PApiType.string,
			validations: function(this : AccountApiBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiBillAddressIsLocationAddress : ApiAttributeInfo<AccountApiBilling, boolean> = new ApiAttributeInfo<AccountApiBilling, boolean>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'billAddressIsLocationAddress',
			nodeName: 'BILLING_BILL_ADDRESS_IS_LOCATION_ADDRESS',
			type: PApiType.boolean,
			validations: function(this : AccountApiBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			defaultValue: function(this : AccountApiBilling, _nodeId : string) {return false;},
			rawDataIndex: 7,
		});
	aiContactEmail : ApiAttributeInfo<AccountApiBilling, Email> = new ApiAttributeInfo<AccountApiBilling, Email>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'contactEmail',
			nodeName: 'BILLING_CONTACT_EMAIL',
			type: PApiType.Email,
			validations: function(this : AccountApiBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiContactPhone : ApiAttributeInfo<AccountApiBilling, Tel> = new ApiAttributeInfo<AccountApiBilling, Tel>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'contactPhone',
			nodeName: 'BILLING_CONTACT_PHONE',
			type: PApiType.Tel,
			rawDataIndex: 9,
		});
	aiStreetAndHouseNumber : ApiAttributeInfo<AccountApiBilling, string> = new ApiAttributeInfo<AccountApiBilling, string>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'streetAndHouseNumber',
			nodeName: 'BILLING_STREET_AND_HOUSE_NUMBER',
			type: PApiType.string,
			canSetByBusinessLogic: function(this : AccountApiBilling) {
				{const conditionValue = !this.billAddressIsLocationAddress; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiPostalCode : ApiAttributeInfo<AccountApiBilling, PostalCode> = new ApiAttributeInfo<AccountApiBilling, PostalCode>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'postalCode',
			nodeName: 'BILLING_POSTAL_CODE',
			type: PApiType.PostalCode,
			canSetByBusinessLogic: function(this : AccountApiBilling) {
				{const conditionValue = !this.billAddressIsLocationAddress; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.PostalCode, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiCity : ApiAttributeInfo<AccountApiBilling, string> = new ApiAttributeInfo<AccountApiBilling, string>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'city',
			nodeName: 'BILLING_CITY',
			type: PApiType.string,
			canSetByBusinessLogic: function(this : AccountApiBilling) {
				{const conditionValue = !this.billAddressIsLocationAddress; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiCountry : ApiAttributeInfo<AccountApiBilling, AccountApiCountry> = new ApiAttributeInfo<AccountApiBilling, AccountApiCountry>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'country',
			nodeName: 'BILLING_COUNTRY',
			type: PApiType.Enum,
			canSetByBusinessLogic: function(this : AccountApiBilling) {
				{const conditionValue = !this.billAddressIsLocationAddress; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : AccountApiBilling) {
				return [
					() => {
		if(((this.parent!.type === AccountApiType.PAID || this.parent!.type === AccountApiType.TERMINATED ||
							this.parent!.transformingToPaidAccount.value)))
		{
			return this.api.validators.required(PApiType.Enum, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : AccountApiBilling) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiActiveMembersCountForNextPricing : ApiAttributeInfo<AccountApiBilling, Integer> = new ApiAttributeInfo<AccountApiBilling, Integer>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'activeMembersCountForNextPricing',
			nodeName: 'BILLING_ACTIVE_MEMBERS_COUNT_FOR_NEXT_PRICING',
			type: PApiType.Integer,
			hasPermissionToSet: () => false,
			rawDataIndex: 14,
		});
	aiBillomatCustomerPortalUrl : ApiAttributeInfo<AccountApiBilling, Url> = new ApiAttributeInfo<AccountApiBilling, Url>({
			apiObjWrapper: this as any as AccountApiBilling,
			name: 'billomatCustomerPortalUrl',
			nodeName: 'BILLING_BILLOMAT_CUSTOMER_PORTAL_URL',
			type: PApiType.Url,
			hasPermissionToSet: () => false,
			rawDataIndex: 15,
		});

	/**
	 * Owner name of the bank account
	 *
	 * @type {string}
	 */
	get bankAccountOwner() : string | null {
		this.getterDebugValidations(this.aiBankAccountOwner, false);
		return this.data[1];
	}

	set bankAccountOwner(v : string | null) {
        this.setterImpl(1, v, 'bankAccountOwner', false, null, null);
	}

	/**
	 * IBAN of the Bank account
	 *
	 * @type {Iban}
	 */
	get bankAccountIban() : Iban | null {
		this.getterDebugValidations(this.aiBankAccountIban, false);
		return this.data[2];
	}

	set bankAccountIban(v : Iban | null) {
        this.setterImpl(2, v, 'bankAccountIban', false, null, null);
	}

	/**
	 * BIC of the Bank account
	 *
	 * @type {Bic}
	 */
	get bankAccountBic() : Bic | null {
		this.getterDebugValidations(this.aiBankAccountBic, false);
		return this.data[3];
	}

	set bankAccountBic(v : Bic | null) {
        this.setterImpl(3, v, 'bankAccountBic', false, null, null);
	}

	/**
	 * VAT Number of the company
	 *
	 * @type {string}
	 */
	get vatNumber() : string | null {
		this.getterDebugValidations(this.aiVatNumber, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiVatNumber.defaultValue;
			
		}
		return this.data[4];
	}

	set vatNumber(v : string | null) {
        this.setterImpl(4, v, 'vatNumber', false, null, null);
	}

	/**
	 * First name of the person we can contact concerning bills.
	 *
	 * @type {string}
	 */
	get contactFirstName() : string {
		this.getterDebugValidations(this.aiContactFirstName, false);
		return this.data[5];
	}

	set contactFirstName(v : string) {
        this.setterImpl(5, v, 'contactFirstName', false, null, null);
	}

	/**
	 * Last name of the person we can contact concerning bills.
	 *
	 * @type {string}
	 */
	get contactLastName() : string {
		this.getterDebugValidations(this.aiContactLastName, false);
		return this.data[6];
	}

	set contactLastName(v : string) {
        this.setterImpl(6, v, 'contactLastName', false, null, null);
	}

	/**
	 * true if the bill address is equal the location address.
	 *
	 * @type {boolean}
	 */
	get billAddressIsLocationAddress() : boolean {
		this.getterDebugValidations(this.aiBillAddressIsLocationAddress, false);
		return this.data[7];
	}

	set billAddressIsLocationAddress(v : boolean) {
        this.setterImpl(7, v, 'billAddressIsLocationAddress', false, null, function(this : AccountApiBilling) {if ((this.api.data.type === AccountApiType.TEST || this.api.data.type === AccountApiType.TEST_EXPIRED) && !v) {
						this.api.data.billing.postalCode = null;
						this.api.data.billing.streetAndHouseNumber = null;
						this.api.data.billing.city = null;
					}});
	}

	/**
	 * Email of the person we can contact concerning bills.
	 *
	 * @type {Email}
	 */
	get contactEmail() : Email {
		this.getterDebugValidations(this.aiContactEmail, false);
		return this.data[8];
	}

	set contactEmail(v : Email) {
        this.setterImpl(8, v, 'contactEmail', false, null, null);
	}

	/**
	 * Phone of the person we can contact concerning bills.
	 *
	 * @type {Tel}
	 */
	get contactPhone() : Tel | null {
		this.getterDebugValidations(this.aiContactPhone, false);
		return this.data[9];
	}

	set contactPhone(v : Tel | null) {
        this.setterImpl(9, v, 'contactPhone', false, null, null);
	}

	/**
	 * Street and house number of the bill recipient
	 *
	 * @type {string}
	 */
	get streetAndHouseNumber() : string | null {
		if(this.billAddressIsLocationAddress) return this.parent!.locationStreetAndHouseNumber;

		this.getterDebugValidations(this.aiStreetAndHouseNumber, false);
		return this.data[10];
	}

	set streetAndHouseNumber(v : string | null) {
        this.setterImpl(10, v, 'streetAndHouseNumber', false, null, null);
	}

	/**
	 * Postal code of the bill recipient
	 *
	 * @type {PostalCode}
	 */
	get postalCode() : PostalCode | null {
		if(this.billAddressIsLocationAddress) return this.parent!.locationPostalCode;

		this.getterDebugValidations(this.aiPostalCode, false);
		return this.data[11];
	}

	set postalCode(v : PostalCode | null) {
        this.setterImpl(11, v, 'postalCode', false, null, null);
	}

	/**
	 * City of the bill recipient
	 *
	 * @type {string}
	 */
	get city() : string | null {
		if(this.billAddressIsLocationAddress) return this.parent!.locationCity;

		this.getterDebugValidations(this.aiCity, false);
		return this.data[12];
	}

	set city(v : string | null) {
        this.setterImpl(12, v, 'city', false, null, null);
	}

	/**
	 * bill recipient country
	 *
	 * @type {AccountApiCountry}
	 */
	get country() : AccountApiCountry | null {
		if(this.billAddressIsLocationAddress) return this.parent!.locationCountry;

		this.getterDebugValidations(this.aiCountry, false);
		return this.data[13];
	}

	set country(v : AccountApiCountry | null) {
        this.setterImpl(13, v, 'country', false, null, null);
	}

	/**
	 * The next count of non-trashed members to reach the next pricing-level. "null" is returned when the billed members-count is capped and thus increasing the members would not result in higher pricing. Read-only.
	 *
	 * @type {Integer}
	 */
	get activeMembersCountForNextPricing() : Integer | null {
		this.getterDebugValidations(this.aiActiveMembersCountForNextPricing, false);
		return this.data[14];
	}

	set activeMembersCountForNextPricingTestSetter(v : Integer | null) {
        this.setterImpl(14, v, 'activeMembersCountForNextPricing', true, null, null);
	}

	/**
	 * Url for Billomat's customer portal to access all invoices. Read-only.
	 *
	 * @type {Url}
	 */
	get billomatCustomerPortalUrl() : Url | null {
		this.getterDebugValidations(this.aiBillomatCustomerPortalUrl, false);
		return this.data[15];
	}

	set billomatCustomerPortalUrlTestSetter(v : Url | null) {
        this.setterImpl(15, v, 'billomatCustomerPortalUrl', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('BILLING should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '11';
	}

	static async loadDetailed(	api : AccountApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '11', { success: success, error: error, searchParams: searchParams});
	}
}

export enum AccountApiType {
	TEST_NEED_VERIFICATION = 1,
	TEST = 2,
	TEST_EXPIRED = 3,
	PAID = 4,
	TERMINATED = 5,
}


