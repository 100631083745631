/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "dr_plano_holidays_database_subdivisions".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	SUBDIVISION_NAME = 1;
	SUBDIVISION_CODE = 2;
}


@Injectable({
  providedIn: 'root',
})
export class DrPlanoHolidaysDatabaseSubdivisionsApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'dr_plano_holidays_database_subdivisions');
	}

	consts = new Consts();

	protected version() : string {
		return '80af567c98029bef998ed567e1903e86,a50f08252b0111f09b8ef991dbefc15f';
	}

	private dataWrapper = new DrPlanoHolidaysDatabaseSubdivisionsApiRoot(this, false, false);

	get data() : DrPlanoHolidaysDatabaseSubdivisionsApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : DrPlanoHolidaysDatabaseSubdivisionsApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new DrPlanoHolidaysDatabaseSubdivisionsApiRoot(this, false, false);
	}
}

		 export class DrPlanoHolidaysDatabaseSubdivisionsApiRoot extends ApiListWrapper<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision>
{
	constructor(override readonly api : DrPlanoHolidaysDatabaseSubdivisionsApiService,
		
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, null, isView, removeDestroyedItems, '');
	}

	override wrapRawData(itemRawData : any) : DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision {
		return new DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision(this.api, this as unknown as DrPlanoHolidaysDatabaseSubdivisionsApiRoot, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ApiDataWrapperBase | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new DrPlanoHolidaysDatabaseSubdivisionsApiRoot(this.api, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '1';
	}

	override createNewItem(_initCode : ((newItem : DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision) => void) | null = null, _backendId : Id | null = null) : DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision {
		const newItem = new DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision(this.api, this as unknown as DrPlanoHolidaysDatabaseSubdivisionsApiRoot, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiRoot, DrPlanoHolidaysDatabaseSubdivisionsApiRoot> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiRoot, DrPlanoHolidaysDatabaseSubdivisionsApiRoot>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseSubdivisionsApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : DrPlanoHolidaysDatabaseSubdivisionsApiRoot, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			
		});
}

				 
export class DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision extends ApiObjectWrapper<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision>
{
	constructor(override readonly api : DrPlanoHolidaysDatabaseSubdivisionsApiService,
		override readonly parent : DrPlanoHolidaysDatabaseSubdivisionsApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision, DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision, DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision,
			name: 'subdivision',
			nodeName: 'SUBDIVISION',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision, string> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision, string>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision,
			name: 'name',
			nodeName: 'SUBDIVISION_NAME',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiCode : ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision, string> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision, string>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseSubdivisionsApiSubdivision,
			name: 'code',
			nodeName: 'SUBDIVISION_CODE',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});

	/**
	 * The human readable name of the subdivision.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set nameTestSetter(v : string) {
        this.setterImpl(1, v, 'name', true, null, null);
	}

	/**
	 * Code of the subdivision according to http://www.statoids.com/ihasc.html.
	 *
	 * @type {string}
	 */
	get code() : string {
		this.getterDebugValidations(this.aiCode, false);
		return this.data[2];
	}

	set codeTestSetter(v : string) {
        this.setterImpl(2, v, 'code', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SUBDIVISION should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : DrPlanoHolidaysDatabaseSubdivisionsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}



