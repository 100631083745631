import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { BOOTSTRAP_HORIZONTAL_MARGIN_CLASSES_REGEX } from '@plano/shared/core/utils/regex-utils';

/**
 * A grid component which is used to display a grid of columns.
 * It makes sure that all children have the necessary (col, col-*) classes.
 *
 * <p-grid> syntax is deprecated. Please use <div class="row"> instead.
 */
@Component({
	selector: 'p-grid,.row',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'pGrid',
	standalone: true,
	imports: [ PCommonModule ],
})
export class GridComponent implements PComponentInterface, AfterContentInit {
	@HostBinding('class.row')
	protected readonly _alwaysTrue = true;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public justifyContent : 'center' | 'stretch' | null = null;

	@HostBinding('class.align-items-center') private get _hasClassAlignItemsCenter() : boolean {
		return this.justifyContent === 'center';
	}
	@HostBinding('class.align-items-stretch') private get _hasClassAlignItemsStretch() : boolean {
		return this.justifyContent === 'stretch';
	}

	constructor(
		public elementRef : ElementRef<HTMLElement>,
		private console : LogService,
	) {
	}

	public colsElements : HTMLElement [] = [];

	public ngAfterContentInit() : void {
		for (const child of this.elementRef.nativeElement.children) {
			this.colsElements.push(child as HTMLElement);
		}

		if (Config.DEBUG) this.validateContent();
	}

	/**
	 * Make sure the content of the grid is valid.
	 * A grid only allows col-* elements as children.
	 */
	private validateContent() : void {
		const tagIsUsed = this.elementRef.nativeElement.tagName === 'P-GRID';
		if (tagIsUsed) {
			// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			this.console.deprecated('p-grid is deprecated. Please use <div class="row"> instead.');
		}

		this.validateColClassesOnRow(tagIsUsed);
		this.validateMarginClassesOnRow();
		this.validateColClassesOnChildren();
	}

	private validateColClassesOnChildren() : void {
		for (const child of this.elementRef.nativeElement.children) {
			if (child.classList.contains('col')) continue;
			let debugHint = child.textContent ?? child.classList.value;
			const hint = child.innerHTML;
			if (!debugHint) debugHint = `${hint.slice(0, 100)}${hint.length > 100 ? '…' : ''}`;
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, require-unicode-regexp -- FIXME: Remove this before you work here.
			if (child.classList.value.match(/col-(?:1-9|10|11)/) || child.classList.value.match(/col-[A-Za-z]*-\d{1,2}/)) {
				if (child.classList.contains('col-12')) this.console.error(`col-12 is not necessary. col-* already adds the necessary base styles. This may help to find it: »${debugHint}«`);
				continue;
			}
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (child.classList.value.match(/col-*/)) continue;
		}
	}

	private validateColClassesOnRow(
		tagIsUsed : boolean,
	) : void {
		const classListAsArray = [...this.elementRef.nativeElement.classList];
		const colClassOnHost = classListAsArray.find(item => {
			return (item === 'col' || item.startsWith('col-')) && !item.includes('gap');
		});
		if (colClassOnHost) {
			this.console.error(`Don’t use class .${colClassOnHost} ${tagIsUsed ? 'on a p-grid tag' : 'on the same element which has class .row'}`, this.elementRef.nativeElement.classList);
		}
	}

	/**
	 * Make sure there are no margin classes that change the start or end margin on the element that has a row class.
	 */
	private validateMarginClassesOnRow() : void {
		if (Config.DEBUG && this.elementRef.nativeElement.className.match(BOOTSTRAP_HORIZONTAL_MARGIN_CLASSES_REGEX)) {
			this.console.error(`Please do not use bootstrap classes that change the start or end margin on the same element that has a .row class. It would break the concept of the .row class.`, this.elementRef.nativeElement.classList);
		}
	}

}
