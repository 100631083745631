/**
 * Check if the given element is at least partially on the viewport
 * @param element Element to check
 */
export function isElementPartiallyInViewport(element : HTMLElement) : boolean {
	const { top, left, bottom, right } : {top : number, left : number, bottom : number, right : number} = element.getBoundingClientRect();
	const { innerHeight, innerWidth } : {innerHeight : number, innerWidth : number } = window;
	return ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth));
}

/**
 * Check if the given element is fully on the viewport
 * @param element Element to check
 */
export function isElementFullyInViewport(element : HTMLElement) : boolean {
	const rect = element.getBoundingClientRect();
	return (
		rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

/**
 * Get the visible text of an element.
 * This is an alternative to element.textContent as textContent would not take into account the visibility of the element.
 * @param htmlElement The element to get the visible text from
 */
export const getVisibleText = (htmlElement : HTMLElement) : string => {
	if (htmlElement.nodeType === Node.TEXT_NODE) return htmlElement.textContent ?? '';
	let text = '';
	for (const childNode of htmlElement.childNodes as NodeListOf<HTMLElement>) {

		// Don’t include hidden elements
		if (childNode.hidden) continue;

		// Don’t include hidden elements
		text += getVisibleText(childNode);
	}
	return text;
};
