import { USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';

export const filterServiceIndexedDBKeyPrefixDataTypeArray = ['SchedulingFilterService', 'PShiftSelection', 'PShiftExchanges', 'POfferPicker', 'PLeaveDaysCalendar', 'PHolidays'] as const;
export type FilterServiceIndexedDBKeyPrefixDataType = typeof filterServiceIndexedDBKeyPrefixDataTypeArray[number];

export type FilterServiceIndexedDBKeyDataType = {
	database : 'user';
	store : typeof USER_STORE_NAME;
	prefix : FilterServiceIndexedDBKeyPrefixDataType,
	name : typeof FILTER_SERVICE_INDEXED_DB_HIDDEN_MEMBERS_KEY.name |
		typeof FILTER_SERVICE_INDEXED_DB_HIDDEN_SHIFT_MODELS_KEY.name |
		typeof FILTER_SERVICE_INDEXED_DB_HIDDEN_ACTIVITY_AREAS_KEY.name;
};

export const FILTER_SERVICE_INDEXED_DB_HIDDEN_MEMBERS_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hiddenMembers',
} as const;

export const FILTER_SERVICE_INDEXED_DB_HIDDEN_SHIFT_MODELS_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hiddenShiftModels',
} as const;

export const FILTER_SERVICE_INDEXED_DB_HIDDEN_ACTIVITY_AREAS_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hiddenActivityAreas',
} as const;

/**
 * This is a special case. In these keys, we have multiple with the same name, that can be grouped in different
 * prefixes. We usually set the which prefix should be used centrally before calling this method, allowing us to
 * avoid having a lot of very similar keys.
 *
 * @param key The key to be used in the indexedDB without the prefix
 * @param prefix The prefix which separates the different keys
 * @returns The key with the prefix
 */
export const filterServiceIndexedDBKeyWithPrefix =
	(key : FilterServiceIndexedDBKeys, prefix : FilterServiceIndexedDBKeyPrefixDataType) : FilterServiceIndexedDBKeyDataType => {
		return {...key, prefix};
	};

type FilterServiceIndexedDBKeys =
	typeof FILTER_SERVICE_INDEXED_DB_HIDDEN_MEMBERS_KEY |
	typeof FILTER_SERVICE_INDEXED_DB_HIDDEN_SHIFT_MODELS_KEY |
	typeof FILTER_SERVICE_INDEXED_DB_HIDDEN_ACTIVITY_AREAS_KEY;
