/* eslint-disable import/no-named-as-default-member -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { Injectable } from '@angular/core';
import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs-pro';
import { makeQueryablePromise, QueryablePromise } from './queryable-promise';

/**
 * Service to determine unique device fingerprint (so called "visitorId").
 */
@Injectable( { providedIn: 'root' } )
export class PFingerprintService {
	private fingerprintAgent : QueryablePromise<Agent> | null = null;

	private _visitorId : string | undefined = undefined;
	private _visitorIdPromise : QueryablePromise<string> | null = null;

	/**
	 * Returns the unique visitor-id of this device. `undefined` is returned when the
	 * id has not been determined yet.
	 *
	 * Call this method only if you need it because it causes costs at www.fingerprintjs.com.
	 */
	public get visitorId() : string | undefined {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Remove this before you work here.
		this.getVisitorIdPromise();
		return this._visitorId;
	}

	/**
	 * Ensures that the Fingerprint agent is loaded. The agent is needed to identify an device by a visitor id.
	 * See www.fingerprintjs.com.
	 */
	private async ensureAgent() : Promise<Agent> {
		if (this.fingerprintAgent === null) {
			this.fingerprintAgent = makeQueryablePromise(new Promise<Agent>(resolve => {
				// we experienced app freezes because of fingerprint. Loading the agent deferred seem to reduce the problem.
				// So, we use a timeout.
				window.setTimeout(() => {
					// eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Remove this before you work here.
					FingerprintJS.load({
						apiKey: '1vrkqOkin1vheD7P2yWa', // cSpell:disable-line
						region: 'eu',

						// Note that both API communication and script-download are going through our own server/domain.
						// This prevents that ad blocker will block them. See also https://drplano.atlassian.net/browse/PLANO-168973
						endpoint: 'https://fp.dr-plano.com',
						scriptUrlPattern: '/allowed-time-stamp-devices-cdn/v<version>/<apiKey>/loader_v<loaderVersion>.js', // This downloads the agent script from fingerprint-js CDN proxied by our webserver. The path intentionally does not include any name from fingerprint as anything which sounds like fingerprinting might be blocked by add-blockers.
					// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
					}).then(agent => resolve(agent));
				}, 2000);
			}));
		}

		return this.fingerprintAgent;
	}

	/**
	 * Is any finger-print operation running now?
	 */
	public get isLoading() : boolean {
		const agentPending = this.fingerprintAgent?.isPending() ?? false;
		const visitorIdPending = this._visitorIdPromise?.isPending() ?? false;

		return agentPending || visitorIdPending;
	}

	/**
	 * Returns `visitorId` as a promise.
	 *
	 * Call this method only if you need it because it causes costs at www.fingerprintjs.com.
	 */
	public async getVisitorIdPromise() : Promise<string> {
		if (!this._visitorIdPromise) {
			this._visitorIdPromise = makeQueryablePromise(
				this.ensureAgent()
					// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
					.then(async fp => fp.get())
					// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
					.then(result => this._visitorId = result.visitorId),
			);
		}

		return this._visitorIdPromise;
	}
}
