/* eslint max-lines: ["error", 170] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { IconDirective } from '@plano/client/shared/p-icon/p-icon.directive';
import { CustomDatepickerI18n } from '@plano/datepicker-i18n.service';
import { getPopoverConfig, getTooltipConfig } from '@plano/ngx-bootstrap.config';
import { ScrollBoxComponent } from '@plano/shared/core/component/scroll-box/scroll-box.component';
import { RouterStorageDirective } from '@plano/shared/core/directive/anchor-url-storage.directive';
import { AsyncSaveButtonDirective } from '@plano/shared/core/directive/async-save-button.directive';
import { BadgeDirective, DontUseBadgeClassDirective } from '@plano/shared/core/directive/badge.directive';
import { PCloseOnEscDirective } from '@plano/shared/core/directive/close-on-esc.directive';
import { PCropOnOverflowDirective } from '@plano/shared/core/directive/crop-on-overflow.directive';
import { ForceStickyDirective } from '@plano/shared/core/directive/force-sticky.directive';
import { IconListDirective } from '@plano/shared/core/directive/icon-list.directive';
import { NgVarDirective } from '@plano/shared/core/directive/ng-var.directive';
import { OddEvenDirective } from '@plano/shared/core/directive/odd-even-parent.directive';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { PExternalLinkComponent } from '@plano/shared/core/external-link/p-external-link.component';
import { IgnoreGutterDirective } from '@plano/shared/core/ignore-gutter.directive';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { TabIndexDirective } from '@plano/shared/core/tabindex.directive';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPrintModule } from 'ngx-print';
import { CoreComponentsModule } from './component/core-components.module';
import { ChangeDetectionDirective } from './directive/change-detection.directive';
import { RowIsMissingDirective } from './directive/deprecated-row.directive';
import { ExternalLinkDirective } from './directive/external-link.directive';
import { PModalModule } from './p-modal/modal.module';
import { CorePipesModule } from './pipe/core-pipes.module';
import { PTranslateEnumPipe } from './pipe/p-translate-enum.pipe';

@NgModule({
	imports: [
		AsyncSaveButtonDirective,
		BadgeDirective,
		CoreComponentsModule,
		CorePipesModule,
		DontUseBadgeClassDirective,
		ExternalLinkDirective,
		FaIconComponent,
		ForceStickyDirective,
		FormsModule,
		HttpClientModule,
		IconListDirective,
		IgnoreGutterDirective,
		NgVarDirective,
		NgxPrintModule,
		OddEvenDirective,
		PCloseOnEscDirective,
		PCommonModule,
		PCropOnOverflowDirective,
		PModalModule,
		TooltipModule.forRoot(),
		PopoverModule,
		PTooltipDirective,
		ReactiveFormsModule,
		RouterStorageDirective,
		ScrollBoxComponent,
		TabIndexDirective,
	],
	declarations: [
		ChangeDetectionDirective,
		IconDirective,
		PExternalLinkComponent,
		RowIsMissingDirective,
	],
	providers: [

		// TODO: Move to another module with all api-related stuff(PApiModule?)
		// ApiErrorService,
		{
			provide: TooltipConfig,
			useFactory: getTooltipConfig,
		},
		{
			provide: PopoverConfig,
			useFactory: getPopoverConfig,
		},
		{
			provide: NgbDatepickerI18n,
			useClass: CustomDatepickerI18n,
		},
	],
	exports: [
		AsyncSaveButtonDirective,
		BadgeDirective,
		ChangeDetectionDirective,
		CoreComponentsModule,
		CorePipesModule,
		DontUseBadgeClassDirective,
		ExternalLinkDirective,
		FaIconComponent,
		ForceStickyDirective,
		FormsModule,
		HttpClientModule,
		IconDirective,
		IconListDirective,
		IgnoreGutterDirective,
		NgVarDirective,
		NgxPrintModule,
		OddEvenDirective,
		PCloseOnEscDirective,
		PCommonModule,
		PCropOnOverflowDirective,
		PExternalLinkComponent,
		PModalModule,
		PopoverModule,
		PTooltipDirective,
		PTranslateEnumPipe,
		ReactiveFormsModule,
		RouterStorageDirective,
		RowIsMissingDirective,
		ScrollBoxComponent,
		TabIndexDirective,
		TooltipModule,
	],
})
export class CoreModule {}
