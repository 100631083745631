import { AnimationAnimateMetadata, AnimationBuilder, AnimationStyleMetadata } from '@angular/animations';
import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DISMISS_ICON_EFFECT_ANIMATION } from '@plano/animations';
import { PSimpleChanges } from '@plano/shared/api';
import { CloseOnEscapeStore } from '@plano/shared/core/directive/close-on-esc.directive.store';
import { PGlobalEventListenersService } from '@plano/shared/core/p-global-event-listeners.service';
import { Subscription } from 'rxjs';

/**
 * A place to store all instances of the pCloseOnEsc directive.
 */
export const store = new CloseOnEscapeStore();

/**
 * Is a close-on-esc process running?
 * There can only be one close process at a time.
 */
let closeOnEscapeProcessRunning = false;

/**
 * All types of UI elements that the PCloseOnEscDirective can be attached to
 */
type CloseOnEscapeType = 'tooltip' | 'modal' | 'wish-picker-mode' | 'early-bird-mode' | 'toast' | 'typeahead' | 'emoji-picker' | 'dropdown';

/**
 * Event object that gets passed when the pCloseOnEscTrigger event gets triggered.
 */
export type PCloseOnEscTriggerEvent = CustomEvent<{ type : CloseOnEscapeType }>;

/**
 * This is a directive that listens to the esc key, and if pressed, it animates the close button it is attached to,
 * before it triggers a click on that button.
 * So the element it gets attached to is usually a icon inside a button.
 *
 * @example
 * ```html
 * <button
 * 	title="Schließen"
 * 	(click)="onDismiss()"
 * >
 * 	<fa-icon
 * 		[pCloseOnEsc]="true"
 * 		[icon]="enums.PlanoFaIconContextPool.DISMISS"
 * 	/>
 * </button>
 */
@Directive({
	selector: '[pCloseOnEsc]',
	standalone: true,
	providers: [BrowserAnimationsModule],
})
export class PCloseOnEscDirective implements OnDestroy, OnChanges, OnInit {
	/**
	 * Should the close on escape mechanism be enabled?
	 * This can be used if the usage of this directive is conditional.
	 * @example `[pCloseOnEsc]="keyboardShortcutsEnabled"`
	 */
	@Input({ required: true }) public pCloseOnEsc ! : boolean;

	/**
	 * Should the close button animate when the esc key is pressed?
	 * Note that currently the animation only works for icons.
	 * So this input should be set to false in other cases.
	 */
	@Input() private animate = true;

	/**
	 * Is the element responsible for closing the UI element pCloseOnEscTriggerVisible?
	 * This should be used when the element that does it doesn't get destroyed when closed.
	 */
	@Input() public pCloseOnEscTriggerVisible = true;

	/**
	 * What should be closed when the close action gets triggered?
	 */
	@Input({required: true}) private pCloseOnEscType ! : CloseOnEscapeType;

	constructor(
		private builder : AnimationBuilder,
		private buttonRef : ElementRef<HTMLButtonElement>,
		private pGlobalEventListenersService : PGlobalEventListenersService,
	) {
	}

	private subscriptions : Subscription [] = [];

	public ngOnInit() : void {
		this.subscriptions.push(this.pGlobalEventListenersService.globalKeyDownEvent.subscribe((event) => {
			if (event.key === 'Escape') {
				if (this.blockEscListener) return;

				closeOnEscapeProcessRunning = true;

				if (this.escKeyIsDown) {
					// If this is the same instance, then the user still holds down the esc key
					this.setFreshTimeout();
				} else {
					// Set a flag to remember if the key is pressed
					this.escKeyIsDown = true;
				}

				event.preventDefault();
				event.stopPropagation();

				if (this.animate) {
					const factory = this.builder.build(this.closeAnimation());
					const player = factory.create(this.buttonRef.nativeElement);

					player.onDone(() => {
						this.startIntervalToTryClose();
					});

					player.play();
				} else {
					this.startIntervalToTryClose();
				}
			}
		}));

		this.subscriptions.push(this.pGlobalEventListenersService.globalKeyUpEvent.subscribe((event) => {
			if (event.key === 'Escape') {
				this.escKeyIsDown = false;
			}
		}));
	}

	public ngOnChanges(changes : PSimpleChanges<PCloseOnEscDirective>) : void {
		if ('pCloseOnEscTriggerVisible' in changes || 'pCloseOnEsc' in changes) {
			this.toggleStoreEntry(this.pCloseOnEscTriggerVisible && this.pCloseOnEsc);
		}
	}

	private toggleStoreEntry(input : boolean) : void {
		if (input) {
			this.addInstanceToStore();
		} else {
			this.finishClosing();
		}
	}

	/**
	 * There should be only one PCloseOnEscDirective instance, that takes care of this event,
	 * but there are multiple instances, that receive this event. So we need to determine those instances that should be
	 * blocked.
	 */
	private get blockEscListener() : boolean {
		// If one process is already running, wait for it to finish.
		if (closeOnEscapeProcessRunning) return true;

		// Only one of the instances in the store should take care of this escape event
		if (store.last !== this.buttonRef.nativeElement) return true;

		return false;
	}

	private interval : number | null = null;

	/** Starts interval that checks if the esc key has been released and triggers the close event */
	private startIntervalToTryClose() : void {
		this.interval = window.setInterval(() => {
			// If user released the esc key, close it.
			if (!this.escKeyIsDown) {
				this.close();
				if (this.interval) window.clearInterval(this.interval);
				this.interval = null;
			}
		}, 100);
	}

	private timeout : number | null = null;

	/** Sets a timeout to stop the interval in order to prevent memory leaks */
	private setFreshTimeout() : void {
		if (this.timeout) {
			window.clearTimeout(this.timeout);
			this.timeout = null;
		}

		// If time is up, stop interval and close it.
		this.timeout = window.setTimeout(() => {
			this.close();
			if (this.interval) window.clearInterval(this.interval);
			this.interval = null;
		}, 30000);
	}

	private escKeyIsDown = false;

	private close() : void {
		closeOnEscapeProcessRunning = false;
		this.buttonRef.nativeElement.dispatchEvent(new CustomEvent('pCloseOnEscTrigger', { bubbles: true, detail: { type: this.pCloseOnEscType }}));
	}

	private finishClosing() : void {
		closeOnEscapeProcessRunning = false;
		store.remove(this.buttonRef.nativeElement);
		if (this.interval) window.clearInterval(this.interval);
		if (this.timeout) window.clearTimeout(this.timeout);
	}

	public ngOnDestroy() : void {
		this.finishClosing();
		for (const subscription of this.subscriptions) subscription.unsubscribe();
	}

	private addInstanceToStore() : void {
		store.push(this.buttonRef.nativeElement);
	}

	/**
	 * Returns the animation that will be used to animate the close button.
	 */
	private closeAnimation() : (AnimationStyleMetadata | AnimationAnimateMetadata)[] {
		return DISMISS_ICON_EFFECT_ANIMATION;
	}
}
