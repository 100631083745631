<div class="card m-0">
	<div
		class="card-header"
		[class.bg-warning]="severityLevel !== PSeverity.FATAL"
	>
		<h3 id="error-modal-header" class="text-white my-2 text-center" i18n>Etwas ist schiefgelaufen <span class="nowrap">:(</span></h3>
	</div>
	<div class="card-body" aria-labelledby="error-modal-header">
		<div>
			<p class="mb-2 fw-bold" i18n>Der Fehler tut uns leid. Das Neu-Laden der Seite könnte helfen.</p>
			<!--
				HACK: This <ng-container> is needed here, because:
				We had cases where some js errors caused the error-modal to be messed-up because all the
				angular-rendering related part of this modal was broken.
				In these cases only the html part of the error-modal worked.
				So with this ng-container we make sure, that if something is wrong with the angular part, it is
				just not visible. And we made sure that the html part works in the ui, even without any angular
				logic.

				NOTE: Previously it was <ng-container *ngIf="true">. I changed that for type error reasons. I hope
					the hack still works.
			-->
			<ng-container>
				<p-callout theme="info" *ngIf="severityLevel !== PSeverity.FATAL">
					<p i18n>Interne Info für Dr.&nbsp;Plano-Mitarbeitende: Dieses Error-Modal wird nur im Entwickler-Modus angezeigt. Auf »Production« wird dieser Fehler im Hintergrund eine Nachricht an unser <a externalLink="https://dr-plano.sentry.io/issues/?project=5187494" target="_blank" rel="noopener">Error-Tracking-Tool</a> senden. Unsere User bekommen davon nichts mit.</p>
					<h4>Error message:</h4>
					<div class="card card-body bg-light" [innerHTML]="errorObj.message | pSafeHtml"></div>
					<h4>Error stack:</h4>
					<div class="card card-body bg-light" [innerHTML]="(backslashNToBR(errorObj.stack) ?? '') | pSafeHtml"></div>
				</p-callout>

				<div *ngIf="sentryIsBlocked">
					<p class="mb-2" i18n>Unterstütze uns gerne bei der Fehlersuche und schicke uns die Fehlermeldung unten per Email zu. <a externalLink="mailto:service@dr-plano.com?subject={{errorModalEmailSubject}}&body={{errorModalEmailBody('\n\n'+errorObj.message+'\n'+errorObj.stack)}}" class="nowrap">Klicke dazu einfach hier →</a></p>
					<p i18n>Wenn du keine Emails senden kannst, kopiere bitte die Fehlermeldung und melde dich damit in unserem Support-Chat 🙏</p>
					<h6>Fehlermeldung für die Developer von Dr.&nbsp;Plano:</h6>
					<div class="card card-body bg-light">{{errorObj.message}}<br /><br />{{errorObj.stack}}</div>
				</div>

				<div [hidden]="sentryIsBlocked">
					<div class="mb-2" i18n>Unterstütze uns gerne bei der Fehlersuche mit einer kurzen Beschreibung, was du eben angeklickt oder geladen hast:</div>
					<textarea
						id="errorModalUserMessage"
						class="form-control w-100 mb-2"
						[(ngModel)]="userMessage"
						rows="2"
						minlength="4"
						[maxlength]="USER_MESSAGE_LENGTH_LIMIT"
						#textareaRef
					></textarea>
					<div class="d-flex justify-content-between">
						<ng-template [ngIf]="textareaRef.value.length >= textareaRef.minLength">
							<p i18n>Danke 🙏 Dein <a href="#team" target="_blank" rel="noreferrer">Team von Dr.&nbsp;Plano</a></p>
						</ng-template>
						<p
							*ngIf="lengthLimitState($any(textareaRef.value)?.length)"
							class="form-text float-right min-w-fit-content text-{{lengthLimitState($any(textareaRef.value)?.length)}}"
						>{{textareaRef.value.length}}/{{USER_MESSAGE_LENGTH_LIMIT}}</p>
					</div>
					<p i18n class="mb-0 text-muted small">Falls sich das wiederholt und es dir unmöglich ist weiterzuarbeiten, kontaktiere uns gerne im Chat!</p>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="card-footer">
		<button
			type="button"
			name="button"
			class="btn btn-primary w-100"
			(click)="onClose()"
			[disabled]="!!textareaRef.value && textareaRef.value.length < textareaRef.minLength"
		>
			<ng-template [ngIf]="!!userMessage">
				<span i18n>Nachricht senden &&nbsp;</span>
			</ng-template>
			<span i18n>Seite neu laden</span>
		</button>
	</div>
</div>
