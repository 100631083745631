/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/**
 * A promise whose state can be queried synchronously.
 */
export interface QueryablePromise<T> extends Promise<T> {

	/**
	 * Has the result successfully been fulfilled?
	 */
	isFulfilled() : boolean;

	/**
	 * Is the result pending?
	 */
	isPending() : boolean;

	/**
	 * Has there been an error and the result been rejected?
	 */
	isRejected() : boolean;
}

/**
 * @returns Transforms a normal promise to a `QueryablePromise`.
 */
// eslint-disable-next-line @typescript-eslint/promise-function-async -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export const makeQueryablePromise = <T>(promise : Promise<T>) : QueryablePromise<T> => {
	// Set initial state
	let isPending = true;
	let isRejected = false;
	let isFulfilled = false;

	// Observe the promise, saving the fulfillment in a closure scope.
	// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
	const result = promise.then(
		(v) => {
			isFulfilled = true;
			isPending = false;
			return v;
		},
		// eslint-disable-next-line promise/prefer-await-to-callbacks -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		(error) => {
			isRejected = true;
			isPending = false;
			throw error;
		},
	) as QueryablePromise<T>;

	result.isFulfilled = () => { return isFulfilled; };
	result.isPending = () => { return isPending; };
	result.isRejected = () => { return isRejected; };

	return result;
};
