import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Config } from '@plano/shared/core/config';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	selector: '[externalLink]',
	standalone: true,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ExternalLinkDirective {
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private externalLink ! : string;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	@HostListener('click') public onClick() : void {
		// let app handle this?
		if (Config.platform === 'appAndroid' || Config.platform === 'appIOS') {
			window.nsWebViewInterface.emit('externalLink', this.externalLink);
		} else {
			// otherwise just open
			if (this.externalLink.startsWith('mailto:'))
				window.location.href = this.externalLink;
			else
				window.open(this.externalLink, '_blank');
		}
	}

	/**
	 * Add the class 'link' to the element to have the correct styles.
	 */
	@HostBinding('class.link')
	@HostBinding('class.external-link')
	private readonly _alwaysTrue = true;

	@HostBinding('attr.rel') private readonly _rel = 'noopener';
}
