import { defaultSortForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { defaultSortingForPermissionGroupShiftModelPermissions } from '@plano/client/scheduling/shared/api/scheduling-api-permissions';
import { SchedulingApiActivityArea, SchedulingApiMembers, SchedulingApiPermissionGroupBase, SchedulingApiPermissionGroupRole, SchedulingApiPermissionGroupShiftModelPermissionBase, SchedulingApiPermissionGroupShiftModelPermissionsBase, SchedulingApiShiftModel } from '@plano/shared/api';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Data } from '@plano/shared/core/data/data';
import { notNull, notUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

/** @see SchedulingApiPermissionGroupBase */
export class SchedulingApiPermissionGroup extends SchedulingApiPermissionGroupBase {
	private _isLastAdminPermissionGroup = new Data<boolean | null>(this.api);

	/**
	 * Check if this is the last group with role admin which has members assigned.
	 * Returns null if this is not a admin group.
	 */
	public get isLastAdminPermissionGroup() : boolean | null {
		return this._isLastAdminPermissionGroup.get(() => {
			// If this group is not an admin group, it can not be the 'last admin group'
			if (this.role !== SchedulingApiPermissionGroupRole.CLIENT_OWNER) return null;
			return this.api.data.permissionGroups.every(permissionGroup => {
				return (

					// It’s a non-admin group
					permissionGroup.role === SchedulingApiPermissionGroupRole.CLIENT_DEFAULT ||

					// It’s the current group
					permissionGroup.id.equals(this.id) ||

					// There are no members assigned
					permissionGroup.assignedUsers.length === 0
				);
			});
		});
	}

	private _isOnlyAdminGroupCurrentMemberIsAssignedTo = new Data<boolean | null>(this.api);

	/**
	 * Check if this is the last admin group, the current user is assigned to.
	 * This can be used to prevent an admin to loose it’s admin permissions by changing a group setting.
	 * It returns null if the current group is not an admin group or the current user is not assigned to this group.
	 */
	public get isOnlyAdminGroupCurrentMemberIsAssignedTo() : boolean | null {
		return this._isOnlyAdminGroupCurrentMemberIsAssignedTo.get(() => {
			if (this.role !== SchedulingApiPermissionGroupRole.CLIENT_OWNER) return null;

			const loggedInMember = notUndefined(this.api.pPermissionsService.loggedInMember);
			const adminIsAssignedToThisPermissionGroup = loggedInMember.permissionGroupIds.some((item) => {
				return item.id.equals(this.id);
			});
			if (!adminIsAssignedToThisPermissionGroup) return null;

			return loggedInMember.permissionGroupIds.every((item) => {
				const permissionGroup = notNull(this.api.data.permissionGroups.get(item));
				return (

					// Is it the current group?
					permissionGroup.id.equals(this.id) ||

					// or is this group a non-admin group?
					permissionGroup.role !== SchedulingApiPermissionGroupRole.CLIENT_OWNER
				);
			});
		});
	}

	/**
	 * Check if there are permission groups with the same name
	 */
	public checkPermissionGroupsWithSameName() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_PERMISSION_GROUP_NAME, fn: (_control) => {
			// If there is no parent, there cant be duplicates in it.
			if (!this.parent) return null;

			// If there is no name, then there is nothing to be checked against.
			if (!this.aiName.value) return null;

			const labelNames = this.parent.iterable().filter(label => {
				if (label.aiName.value === null) return false;
				if (label.name.trim() === '') return false;
				return true;
			}).map(label => label.name.toLowerCase());
			const allItemsWithSameName = labelNames.filter((item) => item.toLowerCase() === this.name.toLowerCase());
			if (allItemsWithSameName.length <= 1) return null;

			return { [PPossibleErrorNames.DUPLICATE_PERMISSION_GROUP_NAME]: {
				name: PPossibleErrorNames.DUPLICATE_PERMISSION_GROUP_NAME,
				type: PApiType.ApiList,
			}};
		}});
	}

	private _assignedUsers = new Data<SchedulingApiMembers>(this.api);

	/**
	 * Sorted list of the users that are assigned to the permission group.
	 */
	public get assignedUsers() : SchedulingApiMembers {
		return this._assignedUsers.get(() => {
			return this.api.data.members
				.filterBy(item => {
					if (item.trashed) return false;
					return item.permissionGroupIds.some(id => id.equals(this.id));
				})
				.sort(defaultSortForMembers);
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public canGetManagerNotificationByItem(input : SchedulingApiShiftModel | SchedulingApiActivityArea) : boolean {
		const shiftModelPermission = this.shiftModelPermissions.getByItem(input);

		if (shiftModelPermission) return shiftModelPermission.canGetManagerNotifications;

		return this.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER;
	}
}

/**
 * @see SchedulingApiPermissionGroupShiftModelPermissionsBase
 */
export class SchedulingApiPermissionGroupShiftModelPermissions extends SchedulingApiPermissionGroupShiftModelPermissionsBase {

	/**
	 * Gets shiftModelPermission for `input`.
	 * @param input The shiftModel or activity-area.
	 */
	public getByItem(input : SchedulingApiShiftModel | SchedulingApiActivityArea) : SchedulingApiPermissionGroupShiftModelPermission | null {
		if (input instanceof SchedulingApiShiftModel) {
			return this.getByShiftModel(input) ?? this.getByActivityAreaId(input.activityAreaId);
		} else if (input instanceof SchedulingApiActivityArea) {
			return this.getByActivityAreaId(input.id);
		} else {
			throw new TypeError('Unsupported case');
		}
	}

	/**
	 * Gets shiftModelPermission by shiftModel or id of shiftModel
	 * @param input The shiftModel or id of the shiftModel.
	 */
	public getByShiftModel(input : SchedulingApiShiftModel | Id) : SchedulingApiPermissionGroupShiftModelPermission | null {
		const id = input instanceof Id ? input : input.id;
		for (const shiftModelPermission of this.iterable()) {
			if (shiftModelPermission.shiftModelId?.equals(id)) {
				return shiftModelPermission;
			}
		}
		return null;
	}

	/**
	 * Gets shiftModelPermission by activity-area.
	 * @param activityAreaId The id of the activity-area.
	 */
	public getByActivityAreaId(activityAreaId : Id) : SchedulingApiPermissionGroupShiftModelPermission | null {
		return this.find(item => activityAreaId.equals(item.activityAreaId));
	}

}

/** @see SchedulingApiPermissionGroupShiftModelPermissionBase */
export class SchedulingApiPermissionGroupShiftModelPermission extends SchedulingApiPermissionGroupShiftModelPermissionBase {
	/**
	 * The activity which is linked to this item.
	 */
	public get activity() : SchedulingApiShiftModel | null {
		return this.api.data.shiftModels.get(this.shiftModelId);
	}

	/**
	 * The activity-area which is linked to this item.
	 */
	public get activityArea() : SchedulingApiActivityArea | null {
		return this.api.data.activityAreas.get(this.activityAreaId);
	}

	/**
	 * All shift-model-permissions which have this item as parent.
	 * If this item does not represent an activity-area, this method will throw an error.
	 */
	public get childShiftModelPermissions() : SchedulingApiPermissionGroupShiftModelPermissions {
		if (this.activityAreaId === null)
			throw new Error('This method should only be called for items which represent an activity-area.');

		const activityAreaId = this.activityAreaId;
		return this.parent!
			.filterBy(item => activityAreaId.equals(item.activity?.activityAreaId ?? null))
			.sort(defaultSortingForPermissionGroupShiftModelPermissions);
	}
}
