/* eslint-disable @typescript-eslint/naming-convention --  Intended use of timestamp string as key */
// cSpell:ignore IDBP
import { REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY, REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY } from '@plano/client/report/report-filter.service.indexeddb.type';
import { SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY, schedulingFilterServiceIndexedDBKeyPrefixDataTypeArray, schedulingFilterServiceIndexedDBKeyWithPrefix } from '@plano/client/scheduling/scheduling-filter.service.indexeddb.type';
import { USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';
import { MigrationScript, TimestampString } from '@plano/shared/core/indexed-db/migration/migration-scripts.types';
import { PMigrationUtils } from '@plano/shared/core/indexed-db/migration/migration-utils';

/**
 * Migration scripts for the user indexedDB.
 */
export const drPlanoUserMigrationScripts : { [key : TimestampString] : MigrationScript } = {
	// eslint-disable-next-line @typescript-eslint/require-await -- Migration scripts need to be async
	'2022.08.01.09.00.00': async (database) => {
		database.createObjectStore(USER_STORE_NAME);
	},
	'2024.09.18.15.26.04': async (_database, transaction) => {
		// cSpell:ignore Absences
		await PMigrationUtils.renameKey(transaction, 'showAbsences', REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY);
		await PMigrationUtils.renameKey(transaction, 'showUnpaidAbsences', REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY);

		// Rename "hideAllAbsences" to "hideAllLeaves"
		for (const prefix of schedulingFilterServiceIndexedDBKeyPrefixDataTypeArray) {
			const key = schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY, prefix);
			const oldKey = prefix ? `${prefix}_hideAllAbsences` : 'hideAllAbsences';

			await PMigrationUtils.renameKey(transaction, oldKey, key);
		}
	},
};
