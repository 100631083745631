/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { SchedulingApiBookingState, SchedulingApiGiftCardStatus, SchedulingApiTransactionPaymentMethodType, SchedulingApiTransactionType } from '@plano/shared/api';
import { ClientCurrency } from '@plano/shared/api/base/generated-types.ag';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { PMath } from '@plano/shared/core/utils/math-utils';
import { SchedulingApiBooking } from './scheduling-api-booking.service';
import { SchedulingApiTransaction } from './scheduling-api-transactions.service';
import { SchedulingApiGiftCard } from './scheduling-api.service';
import { PPaymentStatusEnum } from './scheduling-api.utils';

type SchedulingApiBookableType = SchedulingApiBooking | SchedulingApiGiftCard;

/**
 * The backend has the concept of 'bookable'. A bookable can be a SchedulingApiBooking or SchedulingApiGiftCard.
 * Frontend should implement this too. Implementing this will cause huge changes.
 * Until then, we gather their logic here.
 * Inside SchedulingApiBooking and SchedulingApiGiftCard we can than pick static methods from SchedulingApiBookable
 * in order to not have to write duplicate code.
 */
export class SchedulingApiBookable {
	/**
	 * Status of payment
	 */
	public static paymentStatus(bookable : SchedulingApiBookableType) : PPaymentStatusEnum | null {
		const openAmount = bookable.getOpenAmount();
		const amountToPay = bookable.amountToPay;

		if (openAmount === null || amountToPay === null)
			return null;

		// payed
		if (openAmount === 0) {
			if (bookable.price === 0)
				return PPaymentStatusEnum.PAID_FREE_BOOKABLE;
			else if (amountToPay === 0)
				return PPaymentStatusEnum.PAID_NO_AMOUNT_TO_PAY;
			else
				return PPaymentStatusEnum.PAID;
		}

		// needs refund
		if (openAmount < 0)
			return PPaymentStatusEnum.REFUND_NEEDED;

		// needs payment
		if (openAmount < amountToPay)
			return PPaymentStatusEnum.PARTIALLY_PAID;
		else
			return PPaymentStatusEnum.UNPAID;
	}

	/**
	 * Should the faq-button be visible?
	 */
	public static showFaqBtn(bookable : SchedulingApiBookableType | null, isOnlinePaymentAvailable : boolean) : boolean {
		if (bookable === null) return false;
		if (isOnlinePaymentAvailable) return true;
		if (bookable.transactions.length === 0) return false;
		return bookable.transactions.some(item => item.paymentMethodType === SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT);
	}

	/**
	 * Get a fitting close button label, based on params from the form.
	 */
	public static newTransactionFormCloseBtnLabel(
		bookable : SchedulingApiBookableType | null,
		transaction : SchedulingApiTransaction | null,
		console : LogService,
	) : PDictionarySourceString {
		if (!bookable) {
			console.error('Can not determine the correct text for button');
			return 'Speichern';
		}
		if (bookable.transactions.length === 0) return 'Speichern';
		if (!transaction) return 'Speichern';
		if (transaction.rawData && transaction.type === SchedulingApiTransactionType.REFUND) {
			if (transaction.paymentMethodType === SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT) return 'Rückerstattung veranlassen';
			if (transaction.paymentMethodType === SchedulingApiTransactionPaymentMethodType.GIFT_CARD) return 'Rückerstattung ausführen';
			return 'Rückerstattung erfassen';
		}
		return 'Einzahlung erfassen';
	}

	/**
	 * How much has the shopper currently paid for `bookable` excluding the last created new transaction?
	 */
	public static calculateCurrentlyPaidWithoutLatestCreatedTransaction<T extends SchedulingApiBookableType>(
		bookable : T,
		superCurrentlyPaid : ClientCurrency,
	) : ClientCurrency {
		if (bookable.api.currentlyDetailedLoaded === bookable || bookable.isNewItem) {
			let currentlyPaid = 0;

			const transactions = bookable.transactions;
			const lastTransaction = transactions.last;
			const lastCreatedTransaction = lastTransaction?.isNewItem ? lastTransaction : null;

			for (const transaction of transactions.iterable()) {
				if (transaction.affectsBookableCurrentlyPaid && transaction !== lastCreatedTransaction && transaction.amount)
					currentlyPaid += transaction.amount;
			}

			if (bookable.paidBeforeTransactionListIntroduction)
				currentlyPaid += bookable.paidBeforeTransactionListIntroduction;

			return currentlyPaid;
		} else {
			return superCurrentlyPaid;
		}
	}

	/**
	 * How much has the shopper currently paid for `bookable`? When the transaction list of this bookable is available,
	 * this method calculates the value based on the list to include any newly created transactions.
	 */
	public static calculateCurrentlyPaid<T extends SchedulingApiBookableType>(
		bookable : T,
		superCurrentlyPaid : ClientCurrency,
	) : ClientCurrency {
		if (bookable.api.currentlyDetailedLoaded === bookable || bookable.isNewItem) {
			let currentlyPaid = 0;

			for (const transaction of bookable.transactions.iterable()) {
				if (transaction.affectsBookableCurrentlyPaid && transaction.amount)
					currentlyPaid += transaction.amount;
			}

			if (bookable.paidBeforeTransactionListIntroduction)
				currentlyPaid += bookable.paidBeforeTransactionListIntroduction;

			return currentlyPaid;
		} else {
			return superCurrentlyPaid;
		}
	}

	/**
	 * @param bookable The bookable for which the label should be generated
	 * @param currentlyPaid Pass here an alternative `currentlyPaid` value. If nothing is passed here the current
	 * 	`currentlyPaid` value of the bookable will be used.
	 * @returns Whats the current open amount for this bookable?
	 * 	Positive means the booking person has to pay something. Negative means the booking person needs to get money back.
	 */
	public static getOpenAmount<T extends SchedulingApiBookableType>(bookable : T, currentlyPaid : ClientCurrency | null = null) : ClientCurrency | null {
		if (bookable.amountToPay === null) return null;

		if (currentlyPaid === null)
			currentlyPaid = bookable.currentlyPaid;

		let result = PMath.subtractCurrency(bookable.amountToPay, currentlyPaid);

		// for cancelled gift-cards the redeemed amount should not be refunded anymore
		if (bookable instanceof SchedulingApiGiftCard && bookable.status === SchedulingApiGiftCardStatus.CANCELED)
			result = PMath.subtractCurrency(result, bookable.redeemedAmount);

		return result;
	}

	/**
	 * get label for close button for cancellation fee modal
	 * @param bookable The bookable for which the label should be generated
	 * @param initialState The initial state of the bookable
	 * @param transaction The transaction that the user is about to create
	 */
	public static cancellationFeeFormCloseBtnLabel(
		bookable : SchedulingApiBookableType,
		initialState : SchedulingApiBookingState | SchedulingApiGiftCardStatus | null = null,
		transaction : SchedulingApiTransaction | null = null,
	) : PDictionarySource {
		const isCanceled = bookable.isCanceled;
		let result : PDictionarySourceString;

		const canceledExistingBookable = initialState !== null && initialState !== bookable.state && isCanceled;
		if (canceledExistingBookable) {
			result = bookable instanceof SchedulingApiBooking ? 'Buchung stornieren' : 'Gutschein stornieren';
		} else {
			result = 'Stornogebühr speichern';
		}

		if (transaction) {
			switch (transaction.paymentMethodType) {
				case SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT:
					return {
						sourceString: '${action} & Rückerstattung veranlassen',
						params: { action: result },
					};
				case SchedulingApiTransactionPaymentMethodType.MISC:
				case SchedulingApiTransactionPaymentMethodType.POS:
					return {
						sourceString: '${action} & Rückerstattung erfassen',
						params: { action: result },
					};
				case SchedulingApiTransactionPaymentMethodType.GIFT_CARD:
					return {
						sourceString: '${action} & Rückerstattung ausführen',
						params: { action: result },
					};
				default:
					if (transaction.type === SchedulingApiTransactionType.REFUND) {
						return {
							sourceString: '${action} & Rückerstattung erfassen',
							params: { action: result },
						};
					}
			}
		}

		const openAmount = bookable.getOpenAmount();
		if (openAmount !== null && openAmount >= 0) return result;

		return {
			sourceString: '${action} & keine Rückerstattung',
			params: { action: result },
		};
	}

	/**
	 * We do not:
	 * Remove the transaction inside the form inside the modal, since the form does not know why it gets destroyed through
	 * dismiss or close.
	 *
	 * We do not:
	 * Create the transaction outside the form, since some transaction have to be created, when the modal is already open.
	 *
	 * So we have to reload the api to clean up any relicts.
	 * This would be a job for our EditableDirective / api.dismissDataCopy(), but in case of a modal for
	 * changing the cancellation fee or the status we do not have an active EditableDirective and have to do some things
	 * manually.
	 *
	 * @param bookable The bookable for which the transactions should be cleaned up
	 */
	public static cleanUpTransactions(
		bookable : SchedulingApiBookableType,
	) : void {
		// No transactions created? ➡ Nothing to clean up ツ
		if (bookable.transactions.length === 0) return;

		const latestTransaction = bookable.api.data.transactions.get(bookable.transactions.length - 1);

		// There is a transaction attached to the booking - so there is no way that this transaction is not available in the api
		if (latestTransaction === null) throw new Error('Could not get latestTransaction');

		// If the last transaction is not a new item, something went wrong
		if (!latestTransaction.isNewItem) throw new Error('Last transaction is not a new item');

		bookable.api.data.transactions.removeItem(latestTransaction);
	}

	/**
	 * Find the transaction that just has been created.
	 * @param bookable The bookable that contains the transaction
	 */
	public static newTransaction(
		bookable : SchedulingApiBookableType,
	) : SchedulingApiTransaction | null {
		if (bookable.transactions.length === 0) return null;
		const transaction = bookable.transactions.findBy(
			item => item.isNewItem,
		);
		return transaction ?? null;
	}

}
