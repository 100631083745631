import { AfterContentInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';

/**
 * A directive to narrow down the options to configure the bootstrap card.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- Bootstrap defines the class name
	selector: '.card',
	standalone: true,
})
export class PCardDirective extends PBaseClass implements PComponentInterface, AfterContentInit {
	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	/**
	 * The size of the border radius
	 */
	@Input() public radiusSize ?: typeof this.enums.BootstrapSize.LG | typeof this.enums.BootstrapSize.XL;

	@HostBinding('class.rounded-lg') private get _hasClassBorderLG() : boolean {
		return this.radiusSize === this.enums.BootstrapSize.LG;
	}

	@HostBinding('class.rounded-xl') private get _hasClassBorderXL() : boolean {
		return this.radiusSize === this.enums.BootstrapSize.XL;
	}

	constructor(
		private elementRef : ElementRef<HTMLElement>,
		private console : LogService,
	) {
		super();
	}

	public ngAfterContentInit() : void {
		if (Config.DEBUG) this.validateContent();
	}
	private validateContent() : void {
		if (this.elementRef.nativeElement.children.length === 0) return;
		if (this.elementRef.nativeElement.classList.value.match(/rounded-(?:xs|sm|md|lg|xl)/u)) {
			this.console.error(`Please dont use rounded-* classes on a card. Use [radiusSize]="enums.BootstrapSize.LG" instead.`, this.elementRef.nativeElement);
		}
	}
}
