import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY, REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY, REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_USERS_WITHOUT_ENTRIES_KEY, REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_FORECAST_KEY, REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_KEY } from '@plano/client/report/report-filter.service.indexeddb.type';
import { ReportUrlParamsService } from '@plano/client/report/report-url-params.service';
import { FilterServiceInterface } from '@plano/client/shared/filter.service';
import { SchedulingApiLeave, SchedulingApiLeaveDay, SchedulingApiLeaveDayPaymentType, SchedulingApiLeaveDays, SchedulingApiWorkingTime } from '@plano/shared/api';
import { DataInput } from '@plano/shared/core/data/data-input';
import { PIndexedDBService, PServiceWithIndexedDBInterface } from '@plano/shared/core/indexed-db/p-indexed-db.service';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ReportFilterService extends DataInput
	implements OnDestroy, PServiceWithIndexedDBInterface, FilterServiceInterface {

	constructor(
		private pIndexedDBService : PIndexedDBService,
		protected override zone : NgZone,
		private reportUrlParamsService : ReportUrlParamsService,
	) {
		super(zone);
	}

	private _showWorkingTimes : boolean | null = null;
	private _showWorkingTimesForecast : boolean | null = null;
	private _showLeaves : boolean | null = null;
	private _showUnpaidLeaves : boolean | null = null;
	private _showUsersWithoutEntries : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showLeaves() : boolean {
		return this._showLeaves!;
	}
	public set showLeaves(value : boolean) {
		this.pIndexedDBService.set(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY, value);
		if (!value) this.showUnpaidLeaves = false;
		this._showLeaves = value;
		this.changed(undefined);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showUnpaidLeaves() : boolean {
		return this._showUnpaidLeaves!;
	}
	public set showUnpaidLeaves(value : boolean) {
		this.pIndexedDBService.set(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY, value);
		this._showUnpaidLeaves = value;
		this.changed(undefined);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showUsersWithoutEntries() : boolean {
		return this._showUsersWithoutEntries!;
	}
	public set showUsersWithoutEntries(value : boolean) {
		this.pIndexedDBService.set(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_USERS_WITHOUT_ENTRIES_KEY, value);
		this._showUsersWithoutEntries = value;
		this.changed(undefined);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showWorkingTimes() : boolean {
		return this._showWorkingTimes!;
	}
	public set showWorkingTimes(value : boolean) {
		this.pIndexedDBService.set(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_KEY, value);
		if (!value) this.showWorkingTimesForecast = false;
		this._showWorkingTimes = value;
		this.changed(undefined);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showWorkingTimesForecast() : boolean {
		return this._showWorkingTimesForecast!;
	}
	public set showWorkingTimesForecast(value : boolean) {
		this.pIndexedDBService.set(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_FORECAST_KEY, value);
		this._showWorkingTimesForecast = value;
		this.changed(undefined);
	}

	/**
	 * Get the filtered leave days on the given leave days
	 * @param leaveDays The leave days to filter
	 */
	public filteredLeaveDays(leaveDays : SchedulingApiLeaveDays) : SchedulingApiLeaveDays {
		const min = this.reportUrlParamsService.urlParam.start ?? Number.NEGATIVE_INFINITY;
		const max = this.reportUrlParamsService.urlParam.end ?? Number.POSITIVE_INFINITY;
		return leaveDays.filterBy(
			leaveDay => this.isVisible(leaveDay) && leaveDay.start >= min && leaveDay.end <= max,
		);
	}

	/**
	 * Read values from indexedDB if available
	 */
	public readIndexedDB() : void {
		if (this.pIndexedDBService.has(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_KEY)) {
			this.showWorkingTimes = this.pIndexedDBService.get(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_KEY) === 'true';
		}
		if (this.pIndexedDBService.has(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_FORECAST_KEY)) {
			this.showWorkingTimesForecast = this.pIndexedDBService.get(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_FORECAST_KEY) === 'true';
		}
		if (this.pIndexedDBService.has(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_USERS_WITHOUT_ENTRIES_KEY)) {
			this.showUsersWithoutEntries = this.pIndexedDBService.get(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_USERS_WITHOUT_ENTRIES_KEY) === 'true';
		}
		if (this.pIndexedDBService.has(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY)) {
			this.showLeaves = this.pIndexedDBService.get(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY) === 'true';
		}
		if (this.pIndexedDBService.has(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY)) {
			this.showUnpaidLeaves = this.pIndexedDBService.get(REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY) === 'true';
		}
	}

	/**
	 * Init all necessary values for this class
	 */
	public initValues() : void {
		if (this._showWorkingTimes === null) this._showWorkingTimes = true;
		if (this._showWorkingTimesForecast === null) this._showWorkingTimesForecast = true;
		if (this._showLeaves === null) this._showLeaves = true;
		if (this._showUnpaidLeaves === null) this._showUnpaidLeaves = true;
		if (this._showUsersWithoutEntries === null) this._showUsersWithoutEntries = true;
	}

	public ngOnDestroy() : void {
		this.unload();
	}

	/**
	 * Clear all stored values of this service
	 */
	public unload() : void {
		this.unloadFilters();
	}

	/**
	 * Reset all filters to default
	 */
	public unloadFilters() : void {
		this._showWorkingTimes = null;
		this._showWorkingTimesForecast = null;
		this._showLeaves = null;
		this._showUnpaidLeaves = null;
		this._showUsersWithoutEntries = null;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public isVisible(input : SchedulingApiLeave | SchedulingApiWorkingTime | SchedulingApiLeaveDay) : boolean {
		if (input instanceof SchedulingApiLeave) {
			return this.isVisibleLeave(input);
		}
		if (input instanceof SchedulingApiWorkingTime) {
			return this.isVisibleWorkingtime(input);
		}
		if (input instanceof SchedulingApiLeaveDay) {
			return this.isVisibleLeaveDay(input);
		}
		throw new Error('unexpected instance of input');
	}

	private isVisibleLeave(input : SchedulingApiLeave) : boolean {
		if (!this.showLeaves) return false;

		// If any leave day is visible, the leave is visible
		if (input.leaveDays.some(leaveDay => this.isVisibleLeaveDay(leaveDay))) return true;

		return false;
	}

	private isVisibleLeaveDay(leaveDay : SchedulingApiLeaveDay) : boolean {
		if (!this.showLeaves) return false;

		// Don't show unpaid leaves? Then hide them
		if (!this.showUnpaidLeaves && leaveDay.aiPaymentType.isAvailable && leaveDay.paymentType === SchedulingApiLeaveDayPaymentType.UNPAID) return false;

		return true;
	}

	private isVisibleWorkingtime(input : SchedulingApiWorkingTime) : boolean {
		if (!this.showWorkingTimes) return false;

		// Is this a paid leave?
		if (!input.isExpectedWorkingTime) return true;

		// User wants to see leaves even if unpaid?
		if (this.showWorkingTimesForecast) return true;

		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isSetToShowAll() : boolean {
		if (!this.showLeaves) return false;
		if (!this.showUnpaidLeaves) return false;
		if (!this.showUsersWithoutEntries) return false;
		if (!this.showWorkingTimes) return false;
		if (!this.showWorkingTimesForecast) return false;
		return true;
	}

}
