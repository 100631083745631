import { SchedulingApiBookingParticipant, SchedulingApiMember } from '@plano/shared/api';
import { SortedByFnArray, SortFnArray } from '@plano/shared/api/base/api-list-wrapper/api-list-wrapper.type';
import { stringCompare } from '@plano/shared/core/utils/sorting-utils';

/**
 * The default to apply to all sortedBy method calls for lists of {@link SchedulingApiMember}
 * @param item The {@link SchedulingApiMember} to sort
 * @deprecated Use the sort function {@link defaultSortForMembers} instead to be able to use stringCompare as that gives better results than sorting someString.toLowerCase()
 * TODO: PLANO-187682 Remove this
 */
export const defaultSortingForMembers : SortedByFnArray<SchedulingApiMember | SchedulingApiBookingParticipant> = [
	(item) => {
		if (!item.firstName) return;
		return item.firstName.toLowerCase();
	},
	(item) => {
		if (!item.lastName) return;
		return item.lastName.toLowerCase();
	},
];

/**
 * The default sorting fn to apply to all sort() method calls for lists of {@link SchedulingApiMember}
 * @param a The first item to compare
 * @param b The second item to compare
 */
export const defaultSortForMembers : SortFnArray<SchedulingApiMember | SchedulingApiBookingParticipant> = [
	(a, b) => {
		if (!a.firstName || !b.firstName) return 0;
		if (!a.lastName || !b.lastName) return 0;
		const sortByFirstName = stringCompare(a.firstName, b.firstName);
		if (sortByFirstName !== 0) return sortByFirstName;
		return stringCompare(a.lastName, b.lastName);
	},
];
