/**
 * A place to store all instances of the pCloseOnEsc directive.
 */
export class CloseOnEscapeStore {
	private allInstances : HTMLElement[] = [];

	private get allInstancesInDom() : HTMLElement[] {
		return this.allInstances.filter((instance) => document.contains(instance));
	}

	/**
	 * Get the last added instance
	 */
	public get last() : HTMLElement | null {
		return this.allInstancesInDom.at(-1) ?? null;
	}

	/**
	 * Remove element from the store.
	 * @param element the button element to remove
	 * @returns true if the element was found and removed, false otherwise.
	 */
	public remove(element : HTMLElement) : boolean {
		const storeEntry = this.allInstances.find((item) => item === element);
		if (!storeEntry) {
			return false;
		}
		const index = this.allInstances.indexOf(storeEntry);
		this.allInstances.splice(index, 1);
		return true;
	}

	/**
   * It is possible that the {@link CloseOnEscapeStore.allInstances} array clutters up with 'dead' elements.
	 * This method takes care that we dont get a memory leak because of these elements.
	 *
	 * The reason for dead elements:
	 * Thats because some elements will be removed from the DOM at a certain point in time, but they are not destroyed and
	 * can be added back later to the DOM. As they dont get destroyed, we don’t have a hook (like `ngOnDestroy(…)`) to
	 * remove them from the store.
	 *
	 * After any navigation in the app, possible issues in the UI vanish. But the elements will still be in the store.
	 *
	 * That case happened to e.g. some elements inside tooltips.
   */
	private cleanUpRelicts() : void {
		// We dont want to store to clutter up. So we cut all entries above the count of 10.
		if (this.allInstances.length > 10) {
			this.allInstances.splice(0, this.allInstances.length - 10);
		}
	}

	/**
	 * Add an element to the store.
	 * @param element the button element to add
	 */
	public push(element : HTMLElement) : void {
		this.allInstances.push(element);
		this.cleanUpRelicts();
	}
}
