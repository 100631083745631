/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { SchedulingApiWarnings } from '@plano/shared/api';

@Component({
	selector: 'p-warnings',
	templateUrl: './warnings.component.html',
	styleUrls: ['./warnings.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_ON_NGIF_TRIGGER],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class WarningsComponent implements AfterContentInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input({required: true}) public warnings ! : SchedulingApiWarnings;
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private disableAnimation : boolean | null = null;

	// TODO: This makes it possible to use class .rounded-bottom on p-warnings to map the wanted styles to
	// the p-warnings template
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private class ?: string;

	constructor(
		private elementRef : ElementRef<HTMLElement>,
		private renderer : Renderer2,
	) {
	}

	public ngAfterContentInit() : void {
		this.setAnimation();
	}

	private setAnimation() : void {
		if (this.disableAnimation) return;

		// get overlay container to set property that disables animations
		const overlayContainerElement : HTMLElement = this.elementRef.nativeElement;

		// angular animations renderer hooks up the logic to disable animations into setProperty
		this.renderer.setProperty( overlayContainerElement, '@slideVertical', true );
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get hasClassRoundedBottom() : boolean {
		return !!this.class?.includes('rounded-bottom');
	}

	/**
	 * Warnings sorted by severity
	 */
	public get sortedWarnings() : SchedulingApiWarnings {
		return this.warnings.sortedBy(warning => warning.severity, {removeDestroyedItems: true});
	}
}
