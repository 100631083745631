/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { DecimalPipe } from '@angular/common';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PBackgroundColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { PSimpleChanges } from '@plano/shared/api';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { CorePipesModule } from '@plano/shared/core/pipe/core-pipes.module';
import { DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS } from '@plano/shared/core/pipe/decimal-pipe.consts';
import { notUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { PBadgeAlign, PBadgeComponentInterface, PBadgeContent } from './p-badge.types';

/**
 * The user has the possibility to show an empty badge. In these cases we use &nbsp; to make the badge show up but be
 * empty.
 */
const EMPTY_BADGE_VALUE = Symbol('&nbsp;');

/**
 * A badge to show some important information like a status or a todo indicator in a compact way.
 * It can be attached to the top corner of an element.
 *
 * @example
 * ```html
 * 	<p-badge [content]="5"></p-badge>
 * ```
 */
@Component({
	selector: 'p-badge',
	templateUrl: './p-badge.component.html',
	styleUrls: ['./p-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [
		PCommonModule,
		CorePipesModule,
		FaIconComponent,
	],
})
export class PBadgeComponent extends PBaseClass implements PBadgeComponentInterface, OnInit, AfterViewInit, PComponentInterface, OnChanges, AfterContentInit {
	@HostBinding('class.border')
	@HostBinding('class.badge')
	protected readonly _alwaysTrue = true;
	@HostBinding('role') private role = 'status';

	/** @see PComponentInterface#isLoading */
	@HostBinding('class.text-skeleton-animated')
	@Input() public isLoading : PComponentInterface['isLoading'] = null;

	/**
	 * If a routerLink is provided to the badge it will be clickable and wrapped in an anchor tag
	 */
	@HostBinding('class.clickable')
	@Input() public badgeRouterLink : string | null = null;

	/**
	 * fragment to be added to the router link of the badge
	 */
	@Input() public badgeRouterFragment : string | null = null;

	/**
	 * Icon to be displayed inside the badge
	 */
	@Input('icon') private iconInput : PlanoFaIconPoolKeys | null = null;

	/**
	 * Is this badge disabled?
	 */
	@HostBinding('class.disabled')
	@Input() public isDisabled : boolean = false;

	/**
	 * The badge title
	 */
	@HostBinding('attr.title')
	@Input() public badgeTitle : string = '';

	/**
	 * @see PBadgeComponentInterface#badgeAttachTopPosition
	 */
	@Input() public badgeAttachTopPosition : PBadgeAlign = null;

	@Input() public theme : PThemeEnum | 'reverse-primary' = this.enums.PThemeEnum.DANGER;

	/** @see PBadgeComponentInterface#content */
	@Input() public content : PBadgeContent = null;

	/** Size of the badge */
	@Input('size') public _size : PFormControlComponentInterface['size'] = null;

	/** @see PBadgeComponentInterface#renderZero */
	@Input() public renderZero : boolean = false;

	/** Classes to be added to the badge */
	@Input() public badgeClasses : string | null = null;

	/**
	 * Should the text of the badge be hidden below a certain breakpoint?
	 */
	@Input() private hideTextBelowBreakpoint : typeof this.enums.BootstrapSize.XXL | null = null;

	/**
	 * Should the badge be attached to the top of another element?
	 */
	@HostBinding('class.attached-badge')
	private get isAttachedBadge() : boolean {
		return this.badgeAttachTopPosition !== null;
	}

	@HostBinding('class.small') private get sizeIsSmall() : boolean {
		return this.size === this.enums.BootstrapSize.SM;
	}

	@HostBinding('class.large-badge-padding') private get hasLargeBadgePadding() : boolean {
		return this.size === this.enums.BootstrapSize.LG;
	}

	@HostBinding('class.empty') private get isEmpty() : boolean {
		return this.content === null || this.content === true;
	}

	/**
	 * If the badge has no visible content and is not attached to an element, we show the badge as a dot.
	 */
	@HostBinding('class.show-as-dot') private get showAsDot() : boolean {
		return !this.hasVisibleContent && !this.isAttachedBadge;
	}

	/**
	 * If there is no content, this ui element should be hidden
	 */
	@HostBinding('class.d-none') private get _isInvisible() : boolean {
		// If the content is set to true, we want to show an empty badge. It indicates a todo.
		if (this.content === true) return false;

		return !this.hasVisibleContent;
	}

	/**
	 * Does the badge have any content?
	 * It is possible that a badge is visible but does not have visible content.
	 * E.g. attached to another element to indicate that there is something todo for the user.
	 */
	@HostBinding('class.has-visible-content')
	private get hasVisibleContent() : boolean {
		if (this.text !== null && this.text !== EMPTY_BADGE_VALUE) return true;
		if (this.icon !== null) return true;
		if (this.hasNgContent) return true;
		return false;
	}

	@ViewChild('ngContent', { static: false }) private ngContent : ElementRef<HTMLElement> | null = null;

	constructor(
		private renderer : Renderer2,
		public element : ElementRef<HTMLElement>,
		private decimalPipe : DecimalPipe,
		private console : LogService,
		public changeDetectorRef : ChangeDetectorRef,
	) {
		super();
	}

	/**
	 * Return the badgeRouterLink to be used in the template.
	 *
	 * If a fragment is passed but no routerLink, it will assume '.' as the default.
	 */
	protected get badgeRouterLinkForTemplate() : string | null {
		if (this.badgeRouterLink === null && !!this.badgeRouterFragment) {
			return '.';
		} else return this.badgeRouterLink;
	}

	public ngOnInit() : void {
		if (this.badgeClasses) {
			for (const badgeClass of this.badgeClasses.split(' '))
				this.renderer.addClass(this.element.nativeElement, badgeClass);
		}
	}

	public ngOnChanges(changes : PSimpleChanges<PBadgeComponent>) : void {
		if (changes.theme) {
			const previousTheme = changes.theme.previousValue;
			this.applyThemeClasses(null, previousTheme);
		}
		if (changes.badgeClasses) {
			if (changes.badgeClasses.previousValue) {
				for (const badgeClass of changes.badgeClasses.previousValue.split(' '))
					this.renderer.removeClass(this.element.nativeElement, badgeClass);
			}
			if (changes.badgeClasses.currentValue) {
				for (const badgeClass of changes.badgeClasses.currentValue.split(' '))
					this.renderer.addClass(this.element.nativeElement, badgeClass);
			}
		}
	}

	/**
	 * Apply all classes related to the theme of the badge
	 */
	public applyThemeClasses(newTheme : PThemeEnum | 'reverse-primary' | null = null, previousTheme : PThemeEnum | 'reverse-primary' | null = null) : void {
		if (previousTheme) {
			this.renderer.removeClass(this.element.nativeElement,`bg-${this.backgroundColor(previousTheme)}`);
			this.renderer.removeClass(this.element.nativeElement,`border-${this.borderColor(previousTheme)}`);
			this.renderer.removeClass(this.element.nativeElement,`text-${this.textColor(previousTheme)}`);
		}
		if (newTheme) this.theme = newTheme;
		if (!this.element.nativeElement.classList.contains(`bg-${this.backgroundColor(this.theme)}`)) {
			this.renderer.addClass(this.element.nativeElement, `bg-${this.backgroundColor(this.theme)}`);
		}

		if (!this.element.nativeElement.classList.contains(`border-${this.borderColor(this.theme)}`)) {
			this.renderer.addClass(this.element.nativeElement, `border-${this.borderColor(this.theme)}`);
		}

		if (this.textColor(this.theme) && !this.element.nativeElement.classList.contains(`text-${this.textColor(this.theme)}`)) {
			this.renderer.addClass(this.element.nativeElement, `text-${this.textColor(this.theme)}`);
		}

		if (this.textColor(this.theme) === this.theme) {
			// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const content = this.content ? `Content: »${JSON.stringify(this.content)}«` : '';
			this.console.error(`p-badge: Icon color AND theme is: »${JSON.stringify(this.icon)}«. Icon is probably invisible. ${content}`);
		}
	}

	public ngAfterContentInit() : void {
		// Check for misusage of css classes in debug mode
		if (
			Config.DEBUG &&
			(
				this.element.nativeElement.classList.contains(`align-right`) ||
				this.element.nativeElement.classList.contains(`align-left`) ||
				this.element.nativeElement.classList.contains(`align-center`)
			)
		) {
			throw new Error(`Instead of using a »align-*« css class on a badge, please use the attribute »badgeAttachTopPosition="…"«.`);
		}
	}

	public ngAfterViewInit() : void {
		this.applyThemeClasses();
		if (this.badgeAttachTopPosition !== null) {
			this.renderer.addClass(this.element.nativeElement, `align-${this.badgeAttachTopPosition}`);
		}
	}

	private borderColor(theme : typeof this.theme) : PThemeEnum | PBackgroundColorEnum.WHITE {
		if (theme === 'danger') return this.enums.PThemeEnum.DANGER;
		if (theme === 'reverse-primary') return this.enums.PThemeEnum.PRIMARY;
		if (JSON.stringify(this.icon) === JSON.stringify(this.enums.PlanoFaIconContextPool.DISMISS)) return this.enums.PThemeEnum.DANGER;
		if (this.icon === 'fa-check fa-duotone') return this.enums.PThemeEnum.SUCCESS;
		return theme;
	}

	private textColor(theme : typeof this.theme) : PThemeEnum | undefined {
		if (theme === 'reverse-primary') return this.enums.PThemeEnum.PRIMARY;
		const borderColor = this.borderColor(theme);
		if (this.icon && borderColor !== theme && borderColor !== PBackgroundColorEnum.WHITE) return borderColor;
		return undefined;
	}

	private backgroundColor(theme : typeof this.theme) : PThemeEnum | PBackgroundColorEnum.WHITE {
		if (theme === 'reverse-primary') return PBackgroundColorEnum.WHITE;
		return theme;
	}

	private stringIsOneOfTheSupportedIcons(input : PBadgeContent) : input is PlanoFaIconPoolKeys {
		if (this.content === null) return false;
		if (typeof this.content === 'number') {
			return false;
		}
		if (typeof this.content === 'boolean') return false;

		return (
			input === this.enums.PlanoFaIconContextPool.CANCELED ||
			input === this.enums.PlanoFaIconContextPool.SUCCESS ||
			input === this.enums.PlanoFaIconContextPool.QUESTION ||
			input === this.enums.PlanoFaIconContextPool.EDIT
		);
	}

	/**
	 * Icon to be shown inside the badge
	 */
	public get icon() : PlanoFaIconPoolKeys | null {
		if (this.iconInput) return this.iconInput;
		if (this.stringIsOneOfTheSupportedIcons(this.content)) {
			return this.content;
		}
		return null;
	}

	/**
	 * Turn input to a string.
	 * @param input The string or symbol to turn into a string.
	 */
	protected stringOrSymbolToString(input : string | symbol) : string {
		// If it is a symbol, we need to convert it to a string
		if (typeof input === 'symbol') return notUndefined(input.description);
		return input;
	}

	/**
	 * Text to be shown inside the badge
	 */
	protected get text() : string | typeof EMPTY_BADGE_VALUE | null {
		if (this.content === null) return null;
		if (typeof this.content === 'boolean') return this.content ? EMPTY_BADGE_VALUE : null;
		if (typeof this.content === 'number') {
			if (!this.renderZero && this.content === 0) return null;
			return this.decimalPipe.transform(this.content, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS);
		}
		if (this.stringIsOneOfTheSupportedIcons(this.content)) return null;
		if (typeof this.content === 'string') return this.content;
		return null;
	}

	/**
	 * Does the badge have any ng-content?
	 */
	protected get hasNgContent() : boolean {
		if (!this.ngContent) return false;
		return !!this.ngContent.nativeElement.textContent?.length;
	}

	/**
	 * Should the text of the badge be hidden below a certain breakpoint?
	 * If so, this is the method to get the classes for it.
	 */
	protected get breakpointClasses() : string {
		if (this.hideTextBelowBreakpoint === null) return '';
		return `d-none d-${this.hideTextBelowBreakpoint}-inline-block`;
	}

	private get size() : PFormControlComponentInterface['size'] {
		if (this._size !== null) return this._size;
		return this.badgeAttachTopPosition === null ? null : this.enums.BootstrapSize.SM;
	}
}
