import { AfterViewInit, Directive, EventEmitter, Input, NgZone, OnChanges, Optional, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PSimpleChanges } from '@plano/shared/api';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { waitUntil } from '@plano/shared/core/utils/async-await-utils';

/**
 * This directive is used to add a modal id to a template. It is used in conjunction with the `handleModalOpen` output.
 * If the modal id is present in the URL, the `handleModalOpen` event is emitted,
 * and the component can handle the modal opening itself.
 */
@Directive({
	selector: '[pModalId][handleModalOpen]',
	standalone: true,
})
export class PModalIdDirective implements AfterViewInit, OnChanges {

	/**
	 * @see PModalTemplateDirective#pModalId
	 */
	@Input({required: true}) public pModalId : PModalTemplateDirective['pModalId'] = null;

	/**
	 * Does the pModalId change for the same template?
	 */
	@Input() public canChangePModalId = false;

	/**
	 * Event emitted to the outside so the component can handle the modal opening itself
	 */
	@Output() public handleModalOpen : EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private activatedRoute : ActivatedRoute,
		private zone : NgZone,
		private router : Router,
		private modalService : ModalService,
		@Optional() public template ?: PModalTemplateDirective,
	) {
	}

	public ngOnChanges(changes : PSimpleChanges<PModalIdDirective>) : void {
		if (this.canChangePModalId && changes.pModalId && !changes.pModalId.isFirstChange() && changes.pModalId.currentValue !== null) {
			void this.notifyModalToOpen();
		}
	}

	public ngAfterViewInit() : void {
		void this.notifyModalToOpen();
	}

	/**
	 * Goes through the URL query parameters and checks if the modal id is present.
	 * If it is, it waits for the correspondent index, assuring that potential previous modals
	 * are open, and then emits the event to open the modal.
	 *
	 * In case the passed modal id is an array, it will check if any of the ids are present in the URL,
	 * and if so assign the present one to the pModalId of the template so it can later be
	 * used to be added/removed from the url.
	 */
	private async notifyModalToOpen() : Promise<void> {
		if (this.pModalId === null) return;
		await this.zone.runOutsideAngular(async () => {
			let i = 0;

			// go through all the possible modal ids
			while (this.activatedRoute.snapshot.queryParams[`modal${i}`] !== undefined) {

				let modalIsPresentInUrl = false;

				// if the pModalId is an array,
				// we need to see if any of the strings present in the array matches the modal id on the url
				if (Array.isArray(this.pModalId)) {
					modalIsPresentInUrl = this.pModalId.map((id : string) => id.toLowerCase()).includes(this.activatedRoute.snapshot.queryParams[`modal${i}`]);
					this.pModalId = this.activatedRoute.snapshot.queryParams[`modal${i}`];
					if (this.template)
						this.template.pModalId = this.pModalId ?? null;
				} else {
					modalIsPresentInUrl = this.pModalId === this.activatedRoute.snapshot.queryParams[`modal${i}`];
				}
				if (modalIsPresentInUrl) {
					await waitUntil(this.zone, () => this.modalService.numberOfOpenModals === i);

					// if any navigation is happening, we need to wait, before telling the modal to open
					await waitUntil(this.zone, () => !this.router.getCurrentNavigation(), 10);
					this.handleModalOpen.emit();

					break;
				}
				i++;
			}
		});
	}

}
