
<div class="w-100 progress" #progressElement>
	<div
		role="progressbar"
		[class.progress-bar-striped]="striped"
		[class.muted]="striped"
		style="width: {{(value / maxValue) * 100}}%;"
		class="progress-bar bg-{{theme}} position-relative"
		[attr.aria-valuenow]="value"
		[attr.aria-valuemin]="0"
		[attr.aria-valuemax]="maxValue">
		<div [style.width.px]="increaseProgressBarWidth * progressElement.offsetWidth" class="increase-progressbar"></div>
		<div [style.width.px]="decreaseProgressBarWidth * progressElement.offsetWidth" class="decrease-progressbar"></div>
	</div>
</div>
@if (startTemplate !== null || endTemplate !== null) {
	<p-grid class="mt-3">
		<div class="col-6 text-start">
			<ng-container *ngTemplateOutlet="startTemplate" />
		</div>
		<div class="col-6 text-end">
			<ng-container *ngTemplateOutlet="endTemplate" />
		</div>
	</p-grid>
}
<span hidden> progress-bar({{value}}/{{maxValue}}) </span>
