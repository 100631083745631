/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint max-lines: ["error", 830] -- FIXME: Remove this before you work here. */
import { HttpParams } from '@angular/common/http';
import { SchedulingFilterService } from '@plano/client/scheduling/scheduling-filter.service';
import { SchedulingApiShiftModel } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { FilterService, IndexedDBListOfDataWrappers } from '@plano/client/shared/filter.service';
import { PMoment, PMomentService } from '@plano/client/shared/p-moment.service';
import { SchedulingApiActivityArea, SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessState, SchedulingApiAssignmentProcessType, SchedulingApiBookingDesiredDateSetting, SchedulingApiMember, SchedulingApiMembers, SchedulingApiShiftAssignableMember, SchedulingApiShiftAssignableMembersBase, SchedulingApiShiftBase, SchedulingApiShiftChangeSelectorBase, SchedulingApiShiftExchanges, SchedulingApiShiftMemberPrefValue, SchedulingApiShiftModelBase, SchedulingApiShiftRepetitionType, SchedulingApiShiftsBase } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

/** @see SchedulingApiShiftBase */
export class SchedulingApiShift extends SchedulingApiShiftBase {

	private _selected = false;

	/** Is this item selected? */
	public get selected() {
		return this._selected;
	}

	public set selected(value : boolean) {
		if (this._selected === value) return;
		this._selected = value;
		this.api.changed('selected');
	}

	public wiggle : boolean = false;

	/**
	 * Get the scrollToFragment of a shift.
	 * If the shift is a new item, the scrollToFragment is the id of the new item.
	 * If the shift is not a new item, the scrollToFragment is the id of the shift, using the method
	 * toPrettyString() to get the id in a readable format, which is the reason why we override the method.
	 */
	public override get scrollToFragment() : string {
		if (this.newItemId === null)
			return `${this.id.toPrettyString()}`;
		else return this.newItemId.toString();
	}

	/**
	 * Does this item belong to a packet?
	 * Note that this also returns false, if all other related shifts have been deleted.
	 */
	public get isPacket() : boolean | null {
		if (this.isNewItem) {
			if (!this.repetition.rawData) return null;
			return this.repetition.packetRepetition.type !== SchedulingApiShiftRepetitionType.NONE;
		}

		return this.packetShifts.length > 0;
	}

	public set isPacket(input : boolean | null) {
		if (!this.isNewItem) throw new Error('Can not set isPacket on existing shift');
		switch (input) {
			case true:
				this.repetition.packetRepetition.type = SchedulingApiShiftRepetitionType.EVERY_X_WEEKS;
				break;
			case false:
				this.repetition.packetRepetition.type = SchedulingApiShiftRepetitionType.NONE;
				break;
			default:
				throw new Error('Invalid value for isPacket');
		}
	}

	/**
	 * Does this shift belong to an interval?
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get hasRepetition() : boolean | null {
		if (this.repetition.aiType.value === null) return false;
		return this.repetition.type !== SchedulingApiShiftRepetitionType.NONE;
	}

	public set hasRepetition(value : boolean | null) {
		if (!value) {
			this.clearRepetitionType();
		} else if (
			this.repetition.aiType.value === SchedulingApiShiftRepetitionType.NONE ||
			this.repetition.aiType.value === null
		) {
			if (
				this.repetition.packetRepetition.aiType.value === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS
			) {
				this.initRepetitionTypeMonth();
			} else {
				this.initRepetitionTypeWeek();
			}
		}
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private initRepetitionTypeMonth() : void {
		if (this.repetition.aiType.value !== SchedulingApiShiftRepetitionType.EVERY_X_MONTHS) {
			this.repetition.aiType.value = SchedulingApiShiftRepetitionType.EVERY_X_MONTHS;
		}
		this.repetition.aiX.value = 1;
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private initRepetitionTypeWeek() : void {
		if (this.repetition.aiType.value !== SchedulingApiShiftRepetitionType.EVERY_X_WEEKS) {
			this.repetition.aiType.value = SchedulingApiShiftRepetitionType.EVERY_X_WEEKS;
		}
		this.repetition.aiX.value = 1;
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private clearRepetitionType() : void {
		this.repetition.aiType.value = SchedulingApiShiftRepetitionType.NONE;
	}

	/**
	 * does the item overlap with interval?
	 */
	public overlaps(min : number, max : number) : boolean {
		const intervalIsBefore = max <= this.start;
		const intervalIsAfter = min >= this.end;
		return !intervalIsBefore && !intervalIsAfter;
	}

	private _shiftExchanges = new Data<SchedulingApiShiftExchanges>(this.api);
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get shiftExchanges() : SchedulingApiShiftExchanges {
		return this._shiftExchanges.get(() => {
			return this.api.data.shiftExchanges.filterBy(item => item.shiftRefs.contains(this.id));
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isCourseFullyBooked() : boolean {
		if (this.currentCourseParticipantCount > 0 && this.model.onlyWholeCourseBookable) return true;

		const maxParticipantCount = this.maxCourseParticipantCount;
		if (maxParticipantCount && this.currentCourseParticipantCount >= maxParticipantCount) return true;

		return false;
	}

	private _model : Data<SchedulingApiShiftModel | null> = new Data<SchedulingApiShiftModel>(this.api);

	/**
	 * shorthand that returns the related model
	 */
	public get model() : SchedulingApiShiftModel {
		// NOTE: This methods exists on multiple classes:
		// TimeStampApiShift
		// SchedulingApiShift
		// SchedulingApiBooking
		// SchedulingApiTodaysShiftDescription
		// SchedulingApiWorkingTime
		const SHIFT_MODEL = this._model.get(() => {
			// The following happened in PLANO-173061
			assumeNonNull(this.shiftModelId as Id | null, 'shiftModelId', 'Asked for the model too early?');
			return this.api.data.shiftModels.get(this.shiftModelId);
		});
		assumeNonNull(SHIFT_MODEL, 'SHIFT_MODEL');

		return SHIFT_MODEL;
	}

	/**
	 * shorthand that returns the related model.color
	 */
	public get color() : SchedulingApiShiftModel['color'] {
		return this.model.color;
	}

	/**
	 * Get the name based on the linked shiftModel
	 */
	public get name() : SchedulingApiShiftModel['name'] {
		// NOTE: This methods exists on multiple classes:
		// SchedulingApiRoot
		// TimeStampApiRoot
		if (!this.model.rawData) throw new Error('Can not get shift name. ShiftModel is lost [PLANO-FE-2TT]');
		return this.model.name;
	}

	/** @see SchedulingApiShiftModel#isCourse */
	public get isCourse() : SchedulingApiShiftModel['isCourse'] {
		return this.model.isCourse;
	}

	private _assignmentProcess = new Data<SchedulingApiAssignmentProcess | null>(this.api);

	/**
	 * @returns Returns the assignment process to which this shift currently belongs. "null" is returned if none exists.
	 * Note that a shift can be part of maximal one process at the same time.
	 */
	public get assignmentProcess() : SchedulingApiAssignmentProcess | null {
		return this._assignmentProcess.get(() => {
			// TODO: PLANO-156519
			if (!this.api.data.aiAssignmentProcesses.isAvailable) return null;
			for (const assignmentProcess of this.api.data.assignmentProcesses.iterable()) {
				if (assignmentProcess.shiftRefs.contains(this.id)) return assignmentProcess;
			}

			return null;
		});
	}

	private _assignedMembers = new Data<SchedulingApiMembers>(this.api);

	/** @see SchedulingApiShiftModel.assignedMembers */
	public get assignedMembers() : SchedulingApiMembers {
		return this._assignedMembers.get(() => {
			return SchedulingApiShiftModel.assignedMembers(this.api, this.aiAssignedMemberIds);
		});
	}

	/**
	 * Calculate how many members can be assigned till this shift is saturated
	 */
	public get emptyMemberSlots() : number {
		if (this.aiAssignedMemberIds.isAvailable !== true) return this.neededMembersCount;

		let result : number;
		if (!this.rawData) throw new Error('Cannot get emptyMemberSlots. Shift is lost [PLANO-FE-S6]');
		const amountOfEmptyBadges = this.neededMembersCount - this.assignedMemberIds.length;
		if (amountOfEmptyBadges >= 0) {
			result = amountOfEmptyBadges;
		} else {
			result = 0;
		}
		return result;
	}

	/**
	 * Check if shift is created by the given activity
	 * or
	 * Check if the activity the shift is based on is part of the provided activity area
	 * or
	 * Check if given member is assigned to shift
	 * or
	 * Check if shift is part of given assignmentProcess
	 */
	public relatesTo(
		item : SchedulingApiShiftModel | SchedulingApiActivityArea | SchedulingApiMember | SchedulingApiAssignmentProcess,
	) : boolean {
		let result = false;

		// If assigned members ids are not available, we can not check if the shift is related to the member
		if (!this.aiAssignedMemberIds.isAvailable) return false;

		if (item instanceof SchedulingApiMember) {
			for (const id of this.assignedMemberIds.iterable()) {
				if (id.equals(item.id)) {
					result = true;
				}
			}
		} else if (item instanceof SchedulingApiShiftModel) {
			if (this.shiftModelId.equals(item.id)) {
				result = true;
			}
		} else if (item instanceof SchedulingApiActivityArea) {
			if (this.model.activityAreaId.equals(item.id)) {
				result = true;
			}
		} else if (item.shiftRefs.get(this.id) !== null) {
			result = true;
		}
		return result;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public animateShift() : void {
		this.wiggle = true;
	}

	/**
	 * Copies all values from given shift model which are shared by shift instances. Note that this method is also used
	 * for the diff-process when creating a new shift to decide which values are send to backend.
	 */
	public copyCommonValues(shiftModel : SchedulingApiShiftModel) : void {
		this.time.start = shiftModel.time.start;
		this.time.end = shiftModel.time.end;
		this.description = shiftModel.description;
		this.workingTimeCreationMethod = shiftModel.workingTimeCreationMethod;

		if (this.isCourse) {
			this.rawData[this.api.consts.SHIFT_MIN_COURSE_PARTICIPANT_COUNT] = shiftModel.minCourseParticipantCount;
			this.rawData[this.api.consts.SHIFT_MAX_COURSE_PARTICIPANT_COUNT] = shiftModel.maxCourseParticipantCount;

			// NOTE: If user wants to create a shift and model is ONLY_DESIRED_DATES, then isCourseOnline is always false,
			// because the booking person will never see shifts in the plugin.
			if (
				shiftModel.aiBookingDesiredDateSetting.isAvailable &&
				shiftModel.bookingDesiredDateSetting === SchedulingApiBookingDesiredDateSetting.ONLY_DESIRED_DATES
			) {
				this.rawData[this.api.consts.SHIFT_IS_COURSE_ONLINE] = false;
			} else if (shiftModel.aiIsCourseOnline.value !== null) {
				this.rawData[this.api.consts.SHIFT_IS_COURSE_ONLINE] = shiftModel.isCourseOnline;
			}
		}

		this.neededMembersCountConf.neededMembersCount = shiftModel.neededMembersCountConf.neededMembersCount;
		if (shiftModel.isCourse) {
			this.neededMembersCountConf.perXParticipants = shiftModel.neededMembersCountConf.perXParticipants;
		}
		this.neededMembersCountConf.isZeroNotReachedMinParticipantsCount = (
			shiftModel.neededMembersCountConf.isZeroNotReachedMinParticipantsCount
		);

		const assignableMembersCopy = this.copyRawData(shiftModel.assignableMembers.rawData);
		this.rawData[this.api.consts.SHIFT_ASSIGNABLE_MEMBERS] = assignableMembersCopy;
		this.assignableMembers._updateRawData(assignableMembersCopy, false);

		const assignedMemberIdsCopy = this.copyRawData(shiftModel.assignedMemberIds.rawData);
		this.rawData[this.api.consts.SHIFT_ASSIGNED_MEMBER_IDS] = assignedMemberIdsCopy;
		this.assignedMemberIds._updateRawData(assignedMemberIdsCopy, false);

		const repetitionCopy = this.copyRawData(shiftModel.repetition.rawData);
		this.rawData[this.api.consts.SHIFT_REPETITION] = repetitionCopy;
		this.repetition._updateRawData(repetitionCopy, false);
		if (shiftModel.repetition.aiRepetitionEndMode.isAvailable) {
			this.repetition.repetitionEndMode = shiftModel.repetition.repetitionEndMode;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	private copyRawData(rawData : any[]) : any[] {
		return structuredClone(rawData);
	}

	/**
	 * Are there any members available that can be assigned?
	 * Owners can always assign at least themselves, so this is always true for them.
	 * Members with the write permission can assign members, if an admin has already set some members as assignable.
	 * So there is nothing to do for a member if there are no assignable members.
	 *
	 * Note that there might be more restrictions set on SHIFT_ASSIGNED_MEMBER_IDS
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get assignableMembersAvailableForAssignment() : boolean {
		return this.api.pPermissionsService.isOwner === true || this.assignableMembers.length > 0;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShifts extends SchedulingApiShiftsBase {

	/**
	 * get shifts between two timestamps
	 * Does not return shifts that start or end outside the defined min and max – therefore use .overlaps(min, max).
	 * @param min - Start date in milliseconds
	 * @param max - End date in milliseconds
	 */
	public between(min : number, max : number) : this {
		return this.filterBy((shift : SchedulingApiShift) => {
			if (!shift.rawData) throw new Error('Shift is lost');
			return min <= shift.start && max > shift.start;
		});
	}

	/**
	 * Create a list of shifts that are relevant (depending on the user request).
	 * From the list, get the shifts that are visible according to the filter service.
	 * If some of the shifts are hidden due to filter conditions, a toast is added
	 * informing the user that some shifts are hidden.
	 */
	public visibleShiftsToProcessAccordingToFilterService(
		filterService : FilterService,
		toastsService : ToastsService,
		localize : LocalizePipe,
	) : this {
		const visibleShifts = this.filterBy(shift => filterService.isVisible(shift));
		const invisibleShifts = this.filterBy(shift => !filterService.isVisible(shift));
		if (invisibleShifts.length > 0) {
			if (visibleShifts.length === 0) {
				toastsService.addToast({
					content: localize.transform('Die angefragte Schicht ist aktuell ausgeblendet. Ändere deine Filter-Einstellungen, falls du sie anzeigen möchtest.'),
					theme: enumsObject.PThemeEnum.WARNING,
					visibilityDuration : 'infinite',
				});
			} else {
				toastsService.addToast({
					content: localize.transform('Manche der angefragten Schichten sind aktuell ausgeblendet. Ändere deine Filter-Einstellungen, falls du sie anzeigen möchtest.'),
					theme: enumsObject.PThemeEnum.INFO,
					visibilityDuration : 'infinite',
				});
			}
		}

		return visibleShifts;
	}

	/**
	 * get shifts that overlaps with provided shifts
	 */
	public getOverlappingShifts(shifts : SchedulingApiShifts | null) : SchedulingApiShifts {
		const result : SchedulingApiShifts = new SchedulingApiShifts(this.api, null, true);
		if (!shifts) return result;
		if (!(shifts instanceof SchedulingApiShifts)) return result;

		return this.filterBy(item => this.overlaps(item.start, item.end));
	}

	/**
	 * check if shifts overlap with interval
	 * @param min - start of interval in milliseconds
	 * @param max - start of interval in milliseconds
	 */
	public overlaps(min : number, max : number) : boolean {
		return this.some((item : SchedulingApiShift) => item.overlaps(min, max));
	}

	/**
	 * get shifts of day
	 * @param dayStart - timestamp of the desired day
	 */
	public getByDay(dayStart : number) : this {
		Assertions.ensureIsDayStart(dayStart);

		const dayEnd = +(new PMomentService(Config.LOCALE_ID).m(dayStart).add(1, 'day'));

		// Some experiments that can be removed if not used
		// const offsetStart = new PMomentService(Config.LOCALE_ID).m(dayStart).utcOffset();
		// const offsetEnd = new PMomentService(Config.LOCALE_ID).m(dayEnd).utcOffset();
		// const offsetDiffMinutes = offsetStart - offsetEnd;
		// if (offsetDiffMinutes) dayEnd = dayEnd + (offsetDiffMinutes * 60 * 1000);
		Assertions.ensureIsDayStart(dayEnd);

		return this.between(dayStart, dayEnd);
	}

	private hasToDo(shift : SchedulingApiShift ,process : SchedulingApiAssignmentProcess) : boolean {
		if (process.type === SchedulingApiAssignmentProcessType.EARLY_BIRD) {
			return shift.aiEarlyBirdAssignToMe.canSet;
		} else return shift.aiMyPref.canSet && shift.myPref === null;
	}

	/**
	 * Selects the shifts that are related to the provided item and not selected yet.
	 * Possibly gives the user a hint (toast) if less obvious behavior occurs.
	 *
	 * @param item The item that the shifts should be related to.
	 * @param onlyShiftsWithTodos If true, only shifts with todos will be selected.
	 * @returns boolean Has some selection changed?
	 */
	public selectByItem(
		item : SchedulingApiShiftModel | SchedulingApiMember | SchedulingApiAssignmentProcess | SchedulingApiActivityArea,
		onlyShiftsWithTodos : boolean = false,
	) : boolean {
		const shiftsToToggle = this.filterBy(shift => {
			if (!shift.relatesTo(item)) return false;
			if (item instanceof SchedulingApiAssignmentProcess && onlyShiftsWithTodos && !this.hasToDo(shift, item)) return false;
			return true;
		});

		shiftsToToggle.setSelected(true);

		if (shiftsToToggle.length > 0) {
			if (item instanceof SchedulingApiActivityArea) {
				// If not all activities have shifts that can be selected, it might be confusing for the user that not all
				// desired activities got marked as selected. So if that happens, we show a hint to clarify.
				const allActivitiesRelatedToTheShiftsToToggle = new Set<SchedulingApiShiftModel>(shiftsToToggle.map(shift => shift.model));
				if (allActivitiesRelatedToTheShiftsToToggle.size !== item.relatedActivities.length) {
					this.api.toasts.addToast({
						content: this.api.localizePipe.transform('In der aktuellen Ansicht gibt es nur für manche Tätigkeiten des gewählten Tätigkeitsbereichs passende Einträge – diese wurden selektiert.'),
						theme: enumsObject.PThemeEnum.INFO,
					});
				}
			}
			return true;
		}

		return false;
	}

	/**
	 * Check if at least one item of this list is selected
	 */
	public get hasSelectedItem() : boolean {
		return this.some(item => item.selected);
	}

	/**
	 * All selected items
	 */
	public get selectedItems() : this {
		return this.filterBy(item => item.selected);
	}

	/**
	 * Set the .selected property of each shift, animate each shift if necessary.
	 * If no shift could be selected a toast will show up.
	 * @param value the new value the shift's selected state should be set to.
	 *
	 * @return Has something been changed?
	 */
	public setSelected(value : boolean = true) : boolean {
		if (value && this.length === 0) {
			this.api.toasts.addToast({
				content: this.api.localizePipe.transform('In der aktuellen Ansicht gibt es keine passenden Einträge, die selektiert werden können.'),
				theme: enumsObject.PThemeEnum.INFO,
			});
			return false;
		}

		const shiftsToToggle = this.filterBy(item => item.selected !== value);
		if (value && shiftsToToggle.length === 0) {
			this.api.toasts.addToast({
				content: this.api.localizePipe.transform('<p>Die gewünschten Einträge sind bereits selektiert.</p> Falls du sie wieder abwählen möchtest, geht es am schnellsten, wenn du oberhalb vom Kalender auf <mark>»deselektieren«</mark> klickst.'),
				theme: enumsObject.PThemeEnum.INFO,
			});
			return false;
		}

		for (const shift of shiftsToToggle.iterable()) {
			shift.selected = value;
			if (shift.selected) shift.animateShift();
		}

		return shiftsToToggle.length > 0;
	}

	/**
	 * Get pref if all items have the same pref
	 */
	public get myPref() : SchedulingApiShiftMemberPrefValue | null {
		if (!this.length) return null;

		const firstItem = this.get(0);
		if (firstItem === null) throw new Error('Could not get first item');
		const pref = firstItem.myPref;
		for (const shift of this.iterable()) {
			if (shift.myPref !== pref) return null;
		}
		return pref;
	}

	/**
	 * Hides the Shifts that are related to the provided list of ShiftModels.
	 * @param shiftModels - List of shiftModels that should be checked if related to the shift
	 * @deprecated Use shifts.filterBy(item => this.filterService.isVisible(item)) instead if possible
	 * TODO: PLANO-174196 Remove this method
	 */
	public withoutShiftModels(shiftModels : IndexedDBListOfDataWrappers<SchedulingApiShiftModel>) : this {
		if (shiftModels.length === 0) {
			return this;
		} else {
			return this.filterBy(shift => {
				return !shiftModels.contains(shift.shiftModelId);
			});
		}
	}

	private matchesAllMembersOfShift(
		shift : SchedulingApiShift,
		members : IndexedDBListOfDataWrappers<SchedulingApiMember>,
	) : boolean {
		let matchesAllMembers = true;
		for (const assignedMemberId of shift.assignedMemberIds.iterable()) {
			if (!members.contains(assignedMemberId)) {
				matchesAllMembers = false;
			}
		}
		return matchesAllMembers;

	}

	/**
	 * Return a list of shifts without the Shifts that are related to the provided list of Members.
	 * @param members - List of members that should be checked if assigned to the shift
	 * @param options - Options to control the filtering
	 * TODO: This method should be private. The only reason why its public is that it gets used in some spec files.
	 * @deprecated Use shifts.filterBy(item => this.filterService.isVisible(item)) instead if possible
	 * TODO: PLANO-174196 Remove this method
	 */
	public withoutAssignedMembers(
		members : IndexedDBListOfDataWrappers<SchedulingApiMember>,
		options : SchedulingFilterService | null,
	) : this {
		const isVisible = (shift : SchedulingApiShift) : boolean => {
			const hasEmptySlots = !!shift.emptyMemberSlots;

			if (!!options?.showItemsWithEmptyMemberSlot && hasEmptySlots) return true;
			if (!hasEmptySlots && shift.assignedMemberIds.length === 0) return true;
			if (!this.matchesAllMembersOfShift(shift, members)) return true;
			return false;
		};
		return this.filterBy(shift => {
			if (options?.hideAllShiftsNotAssignedToMe && shift.neededMembersCount === 0) return false;
			return isVisible(shift);
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public filterByAssignmentProcess(
		assignmentProcess : SchedulingApiAssignmentProcess,
	) : SchedulingApiShifts {
		const result = new SchedulingApiShifts(this.api, null, false);
		for (const shiftIdObj of assignmentProcess.shiftRefs.iterable()) {
			const shift = this.get(shiftIdObj.id);
			if (shift) {
				result.push(shift);
			}
		}
		return result;
	}

	/**
	 * Get a list of all shifts where a given shiftModel was used as template
	 * or where a given member is assigned.
	 */
	public getItemsRelatedTo( filterItem : SchedulingApiShiftModel | SchedulingApiMember ) : SchedulingApiShifts {
		let result : SchedulingApiShifts = new SchedulingApiShifts(this.api, null, true);
		if (filterItem instanceof SchedulingApiShiftModel) {
			result = this.filterBy(shift => shift.shiftModelId.equals(filterItem.id));
		} else if (filterItem instanceof SchedulingApiMember) {
			result = this.filterBy(item => item.assignedMemberIds.contains(filterItem.id));
		}
		return result;
	}

	/**
	 * Filters a list of Shifts by multiple filter settings in a filterService.
	 * @param filterService : FilterService
	 * @deprecated Use shifts.filterBy(item => this.filterService.isVisible(item)) instead if possible
	 * TODO: PLANO-174196 Remove this method
	 */
	public filterByFilterService( filterService : FilterService ) : SchedulingApiShifts {
		// TODO: This should be replaced by
		// this.api.data.shifts.filterBy((item) => this.filterService.isVisible(item));
		let result : SchedulingApiShifts;
		if (filterService.isSetToShowAll) {
			return this;
		} else {
			return this
				// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				.withoutShiftModels(filterService.hiddenItems['activities'])
				// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				.withoutAssignedMembers(filterService.hiddenItems['members'], filterService.schedulingFilterService)
				.filterBy(item => {

					// If no filter active - nothing to do
					if (
						!filterService.isOnlyEarlyBirdAssignmentProcesses && !filterService.isOnlyWishPickerAssignmentProcesses
					) return true;

					// If filter active and shift has no assignmentProcess - bad.
					if (!item.assignmentProcess) return false;

					if (
						filterService.isOnlyEarlyBirdAssignmentProcesses &&
						item.assignmentProcess.state === SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING &&
						item.assignmentProcess.type === SchedulingApiAssignmentProcessType.EARLY_BIRD
					) {
						return true;
					}
					if (filterService.isOnlyWishPickerAssignmentProcesses &&
					item.assignmentProcess.state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES
					) {
						return true;
					}
					return false;
				});
		}
		return result;
	}

	/**
	 * @deprecated Please use {@link createNewShift()} instead
	*/
	public override createNewItem() : SchedulingApiShift {
		throw new Error('Please use createNewShift() instead.');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public async createNewShift(
		shiftModel : SchedulingApiShiftModel,
		seriesStart : PMoment.Moment,
		searchParams : HttpParams | null = null,
	) : Promise<SchedulingApiShift> {

		// create new shift
		const newShift = super.createNewItem((item) => {
			// now add unique changes for the new shift
			item.shiftModelId = shiftModel.id;
			item.start = seriesStart.valueOf();
		});

		// Load all shift-model data and copy common values into the shift.
		// We do this from a copy of the shift-model so the copied data will have new-item-ids,
		// so "newShift.<copied-attribute>.isNewItem" will return true.
		// See ticket https://drplano.atlassian.net/browse/PLANO-157529
		await shiftModel.loadDetailed({searchParams : searchParams});
		newShift.copyCommonValues(shiftModel.copy());

		this.showCopyHints(shiftModel);

		// We want to send further changes to backend. So, store the current state in data-source.
		const shiftsRawDataSource : unknown[][] = this.api.dataStack.getDataSource()![this.aiThis.rawDataIndex!];
		shiftsRawDataSource.push(structuredClone(newShift.rawData));

		// HACK:  We need to initialize those attributes in initCode,
		// because there are attributInfos with show logic that needs that.
		//        Here we remove them from source again so they are not included in the diff calculation.
		const newShiftRawData = shiftsRawDataSource[shiftsRawDataSource.length - 1];
		newShiftRawData[newShift.aiShiftModelId.rawDataIndex!] = undefined;
		newShiftRawData[newShift.aiStart.rawDataIndex!] = undefined;

		return newShift;
	}

	/**
	 * Give the user some hint that the form has been filled with data from the related shiftModel.
	 *
	 * @param originalShiftModel shiftmodel from which to copy the information
	 */
	public showCopyHints(originalShiftModel : SchedulingApiShiftModelBase) : void {
		let title : string | null = null;
		title = this.api.localizePipe.transform('Daten erfolgreich kopiert');
		const description = this.api.localizePipe.transform({
			sourceString: 'Das Formular wurde mit den Daten aus der Tätigkeit »${name}« vorausgefüllt.',
			params: {name: originalShiftModel.name},
		});
		this.api.toasts.addToast({
			title: title,
			content: description,
			visibilityDuration: 'infinite',
			theme: enumsObject.PThemeEnum.INFO,
		});
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftChangeSelector extends SchedulingApiShiftChangeSelectorBase {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isChangingShifts() : boolean {
		return 	!!this.shiftsOfShiftModelId	||
			!!this.aiShiftsOfSeriesId.isAvailable && !!this.shiftsOfSeriesId	||
			!!this.aiShiftsOfPacketIndex.isAvailable && !!this.shiftsOfPacketIndex	||
			!!this.aiStart.isAvailable && !!this.start	||
			!!this.aiEnd.isAvailable && !!this.end;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftAssignableMembers extends SchedulingApiShiftAssignableMembersBase {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getByMemberId(id : Id) : SchedulingApiShiftAssignableMember | null {
		return this.findBy(item => item.memberId.equals(id));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public containsMemberId(item : Id) : boolean {
		for (const assignableMember of this.iterable()) {
			if (assignableMember.memberId.equals(item)) {
				return true;
			}
		}
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getByMember(item : SchedulingApiMember) : SchedulingApiShiftAssignableMember | null {
		return this.getByMemberId(item.id);
	}

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => {
			const member = this.api.data.members.get(item.memberId);
			return !member!.trashed;
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public containsMember(item : SchedulingApiMember) : boolean {
		return !!this.getByMember(item);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public addNewMember(
		member : SchedulingApiMember,
		earning : number = 0,
	) : void {
		if (this.containsMember(member)) return;

		const tempAssignableMember = this.createNewItem();
		tempAssignableMember.hourlyEarnings = earning;
		tempAssignableMember.memberId = member.id;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public removeMember(item : SchedulingApiMember) : void {
		const assignableMember = this.getByMember(item);
		if (assignableMember) {
			this.removeItem(assignableMember);
		}
	}
}
