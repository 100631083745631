<div class="overlay"

	[class.has-fixed-footer]="!!showFooterAsFixed"
	[class.no-fixed-footer]="!showFooterAsFixed"

	[class.bg-darker]="backgroundColor==='darker'"
	[class.bg-dark]="backgroundColor==='dark'"
	[class.bg-light-cold]="backgroundColor==='light'"
	[class.bg-primary]="backgroundColor==='primary'"
>
	<div class="content p-scroll-parent"
		#contentElement
		[class.disable-scrollbar-gutter]="disableScrollbarGutter"
		[class.always-show-scrollbar]="alwaysShowScrollbar"
		[style]="contentContainerStyles"
	>
		<div class="content-inner" #innerContentElement>
			<ng-container *ngTemplateOutlet="contentTemplate" />
			<ng-template [ngIf]="!showFooterAsFixed && !!fixedFooterTemplate">
				<ng-container *ngTemplateOutlet="fixedFooterTemplate" />
			</ng-template>
			@if (!showFooterAsFixed && !noShadows) {
				<!-- If any of the classes in this file change, please check their usage throughout the app -->
				<div
					class="shadow-container"
				>
					<div class="radial-shadow-top"></div>
					<div class="radial-shadow-bottom"></div>
				</div>
			}
		</div>
	</div>
	<ng-template [ngIf]="showFooterAsFixed">
		<div class="flex-grow-1 flex-shrink-1">
			<ng-container *ngTemplateOutlet="fixedFooterTemplate" />
		</div>
	</ng-template>
</div>

<ng-template #contentTemplate>
	<ng-content />
</ng-template>
