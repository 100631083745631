import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { PBaseClass } from '@plano/shared/base';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

@Component({
	selector: 'p-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		PCommonModule,
		FaIconComponent,
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SpinnerComponent extends PBaseClass {
	@HostBinding('class.align-items-center')
	@HostBinding('class.justify-content-center')
	@HostBinding('class.h-100')
	protected readonly _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : PFormControlComponentInterface['size'] = null;

	constructor(
		public elementRef : ElementRef<HTMLElement>,
	) {
		super();
	}
}
