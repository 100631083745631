/*
 * eslint @typescript-eslint/no-restricted-imports: ["error",{"patterns": ["*"]}] -- All imports are restricted.
 * This file gets imported into our cucumber files.
 * Having imports here, broke cucumber. https://drplano.atlassian.net/browse/PLANO-143376?focusedCommentId=26375
 */

import { TemplateRef } from '@angular/core';

/**
 * Typescripts Extract<T, U> does not check if T is present inside U.
 * ExtractFromUnion checks that and is therefore more type-save.
 * @example
 *   type AllSizes = 'sm' | 'md' | 'lg' | 'xl';
 *   type ExtractedType = ExtractFromUnion<'sm' | 'xl', AllSizes>; // => 'sm' | 'xl'
 */
export type ExtractFromUnion<T extends U, U> = U extends T ? T : never;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type KebabCaseToCamelCase<S extends string> =
	S extends `${infer T}-${infer U}` ?
	`${T}${Capitalize<KebabCaseToCamelCase<U>>}` :
	S;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type CamelCaseToKebabCase<S extends string> =
	S extends `${infer T}${infer U}` ?
	// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	`${T extends Capitalize<T> ? '-' : ''}${Lowercase<T>}${CamelCaseToKebabCase<U>}` :
	S;

// eslint-disable-next-line literal-blacklist/literal-blacklist -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
type Character = '!' | '#' | '$' | '%' | '&' | '(' | ')' | '*' | '+' | ',' | '-' | '–' | '.' | '/' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | ':' | ';' | '<' | '=' | '>' | '?' | '@' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z' | 'Ä' | 'Ü' | 'Ö' | '[' | '\\' | ']' | '^' | '_' | '`' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y' | 'z' | 'ä' | 'ü' | 'ö' | '{' | '|' | '}' | '~' | '»' | '«' | '…';

/**
 * Make some properties of an object optional.
 * @example
 * type TUser = {
 * 	name : string;
 * 	age : number;
 * };
 * type TUserPartial = PartialBy<TUser, 'age'>; // => { name: string; age?: number; }
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type NonEmptyString = `${Character}${string}`;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type NonZeroInteger<T extends number> =
	`${T}` extends `${0}` | `${number}.${number}`
			? never : T;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type NegativeInteger<T extends number> =
`${T}` extends `${number}` | `${number}.${number}`
		? never : T;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PartialNull<T> = {
	[P in keyof T] : T[P] | null
};
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type RequiredNonNull<T> = {
	[P in keyof T] : T[P] extends null | infer R ? R : T[P]
};

/**
 * If you want to create a component, which gets extended by other components,
 * you might want to make sure that the child-classes call the super methods that
 * Angular calls 'Life Cycle Hooks'.
 * https://angular.io/guide/lifecycle-hooks
 *
 * Therefore you can use this typo as the return type of the parent component’s class.
 */
export type TypeToEnsureLifecycleHooksHaveBeenCalled = 'TypeToEnsureLifecycleHooksHaveBeenCalled';

/**
 * Get the length(s) of a Array
 *
 * @example when only one length is possible
 * const users = ['Nils', 'Rui'] as const;
 * type TUsersLength = ArrayLength<typeof users>;
 * // Type of TUsersLength is `2`
 *
 * @example when multiple lengths are possible
 * const users : ['Nils', 'Rui'] | ['Nils', 'Rui', 'Milad'];
 * type TUsersLength = ArrayLength<typeof users>;
 * // Type of TUsersLength is `2 | 3`
 */
export type ArrayLength<Type extends readonly unknown[]> = Type['length'];

/**
 * Create a type for an array with a limited size.
 *
 * @example
 * type TUsers = string[];
 * type TwoOrThreeUsers = LimitedSizeArray<2 | 3, TUsers>;
 *
 * const goodUsers : TwoOrThreeUsers = ['Nils', 'Rui'] as const;
 * // Thats fine.
 *
 * const badUsers : TwoOrThreeUsers = ['Voldemort', 'Joker', 'HAL', 'Agent Smith'] as const;
 * // Error:	Types of property 'length' are incompatible.
 * // 				Type '4' is not assignable to type '3'.
 */
export type LimitedSizeArray<
	N extends number,
	ArrayType extends readonly unknown[],
	ArrayChild = ArrayType[number],
> = N extends 0 ? never[] : {
	// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	0 : ArrayChild;
	length : N;
} & ReadonlyArray<ArrayChild>;

/**
 * Turn a string with whitespace included into a whitespace-less type
 * @example Trim<'John Doe'> will be 'JohnDoe'
 */
export type Trim<T extends string, Accumulator extends string = ''> =
	(T extends `${infer Char}${infer Rest}`
		? (Char extends ' '
			? Trim<Rest, Accumulator>
			: Trim<Rest, `${Accumulator}${Char}`>)
		: (T extends ''
			? Accumulator
			: never)
	);

/** A type that contains all js primitives */
export type Primitive = number | string | boolean | bigint | symbol | null | undefined;

/**
 * Throws if the value is an empty string.
 * The type will be changed to NonEmptyString in control flow.
 * @param value The value to check
 * @param varOrExpression The name of the variable or expression
 * @param reason The reason why the value should not be empty
 */
export const notEmptyString = (
	value : string,
	varOrExpression ?: string,
	reason ?: string,
) : NonEmptyString => {
	if (!isNonEmptyString(value)) {
		const expressionString = varOrExpression ?? 'value';
		const reasonString = reason ? `Reason: »${reason}«` : '';
		throw new TypeError(`${expressionString} should not be an empty string here. ${reasonString}`);
	}

	return value;
};

/**
 * Checks if the value is an empty string.
 * The type will be changed to NonEmptyString in control flow.
 * @param value The value to check
 */
export const isNonEmptyString = (value : string) : value is NonEmptyString => {
	return value !== '';
};

/**
 * Checks if the value is an object.
 * Not that a simple `typeof value === 'object'` would also return true for arrays and null, which is unexpected.
 * @param value The value to check
 */
export const isObject = (value : unknown) : value is Record<string, unknown> => {
	return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

/**
 * Type to be used to wrap an attribute that can either be a value or a function that returns a value.
 */
export type ValueOrFunction<T> = T | (() => T);

/**
 * Type to be used to wrap an attribute that can either be a value or an array of values of the same type as the single value.
 */
export type ValueOrArray<T> = T | readonly T[];

/**
 * Is the given input a TemplateRef?
 * @param input - The input to check
 */
export const typeIsTemplateRef = (input : unknown) : input is TemplateRef<Element> => {
	if (input === null) return false;
	return typeof input === 'string' ? false : true;
};
