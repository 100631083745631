/* eslint-disable @typescript-eslint/naming-convention -- Intended use of timestamp string as key */
// cSpell:ignore IDBP
import { AUTHENTICATION_STORE_NAME, USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';
import { MigrationScript, TimestampString } from '@plano/shared/core/indexed-db/migration/migration-scripts.types';
import { openDB } from 'idb';
import { CookieService } from 'ngx-cookie-service';

/**
 * Migration scripts for the general indexedDB.
 */
export const drPlanoGeneralMigrationScripts : { [key : TimestampString] : MigrationScript } = {
	'2022.08.01.09.00.00': async (database, transaction, injector) => {
		const cookieService = injector.get(CookieService);

		// Store the global authentication in the database.
		database.createObjectStore(AUTHENTICATION_STORE_NAME);
		if (cookieService.check('s')) {
			await transaction.objectStore(AUTHENTICATION_STORE_NAME).put(cookieService.get('s'), 's');
			cookieService.delete('s', '/');
		}

		const allCookies = cookieService.getAll();
		for (const key of Object.keys(allCookies).filter((cookieKey) => cookieKey.startsWith('drp_'))) {
			// Get the id of the user from the current cookie key. In the past,
			// the cookie was structured like 'drp_userId_restOfTheKey'.
			const userId = key.split('_')[1];

			// Need to ensure the userDB is created without any versioning, to allow migration scripts for it to run.
			const userDB = await openDB(`dr-plano-user-${userId}`, 0, {
				/**
				 * Create the database for the member
				 * @param memberDatabase - The database object being created for each member.
				 */
				// eslint-disable-next-line @typescript-eslint/typedef -- false-positive. Its already typed by openDB.
				upgrade(memberDatabase) {
					memberDatabase.createObjectStore(USER_STORE_NAME);
				},
			});

			const value = cookieService.get(key);

			// get the key without the user id
			const newKey = key.split('_').slice(2).join('_');

			await userDB.transaction(USER_STORE_NAME, 'readwrite').objectStore(USER_STORE_NAME).put(value, newKey);
			cookieService.delete(key, '/');
		}
	},

	// eslint-disable-next-line @typescript-eslint/require-await -- Needs to match the type.
	'2024.12.20.09.00.00': async (database) => {
		database.createObjectStore('cookies');
	},
};
