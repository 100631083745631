import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { PCropOnOverflowDirective } from '@plano/shared/core/directive/crop-on-overflow.directive';
import { ExternalLinkDirective } from '@plano/shared/core/directive/external-link.directive';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/**
 * <p-todo> is a placeholder for work-in-progress stuff
 */
@Component({
	selector: 'p-todo',
	templateUrl: './p-todo.component.html',
	styleUrls: ['./p-todo.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [
		PCommonModule,
		FaIconComponent,
		PCropOnOverflowDirective,
		PTooltipDirective,
		ExternalLinkDirective,
	],
})
export class PTodoComponent extends PBaseClass {
	/** The jira id of the related ticket */
	@Input() protected jira ! : `PLANO-${number}`;

	/**
	 * Icon to be displayed
	 */
	@Input() public icon : PlanoFaIconPoolKeys | null = null;

	@ViewChild('ngContent', { static: false }) private ngContent : ElementRef<HTMLElement> | null = null;

	protected readonly Config = Config;

	/**
	 * Does the badge have any ng-content?
	 */
	protected get hasNgContent() : boolean {
		if (!this.ngContent) return false;
		return !!this.ngContent.nativeElement.textContent?.length;
	}

}
