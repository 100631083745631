import { Pipe, PipeTransform } from '@angular/core';
import { AccountApiCountry, ClientsApiCountry, RegisterTestAccountApiCountry } from '@plano/shared/api';
import { PSupportedCountryCodes, PSupportedLanguageCodes } from '@plano/shared/api/base/generated-types.ag';
import { LocalizePipe } from './localize.pipe';

type TranslatableEnum = AccountApiCountry | PSupportedLanguageCodes | RegisterTestAccountApiCountry | ClientsApiCountry | PSupportedCountryCodes;

/** @see PTranslateEnumPipe#transform */
@Pipe({
	name: 'pTranslateEnum',
	standalone: true,
})
export class PTranslateEnumPipe implements PipeTransform {
	constructor(
		private localizePipe : LocalizePipe,
	) {
	}

	public transform(value : TranslatableEnum) : string;
	public transform(value : TranslatableEnum | null) : string | null;

	// TODO: Get rid of the type 'string' in 'value : string | AccountApiCountry'
	/**
	 * Transform a enum value to a human readable text.
	 *
	 * @example
	 * 	MyCountry.CZ -> 'Tschechien'
	 * 	MyLanguage.EN -> 'English'
	 *
	 * @param value The enum value to transform.
	 */
	// eslint-disable-next-line complexity -- Switches are often ok because straightforward to read.
	public transform(
		value : TranslatableEnum | null,
	) : string | null {
		if (value === null) return null;
		switch (value) {
			case AccountApiCountry.GERMANY :
			case RegisterTestAccountApiCountry.GERMANY:
			case PSupportedCountryCodes.DE :
			case ClientsApiCountry.GERMANY :
				return this.localizePipe.transform('Deutschland');

			case AccountApiCountry.SWITZERLAND :
			case RegisterTestAccountApiCountry.SWITZERLAND :
			case PSupportedCountryCodes.CH :
			case ClientsApiCountry.SWITZERLAND :
				return this.localizePipe.transform('Schweiz');

			case AccountApiCountry.AUSTRIA :
			case RegisterTestAccountApiCountry.AUSTRIA :
			case PSupportedCountryCodes.AT :
			case ClientsApiCountry.AUSTRIA :
				return this.localizePipe.transform('Österreich');

			case AccountApiCountry.ITALY :
			case RegisterTestAccountApiCountry.ITALY :
			case PSupportedCountryCodes.IT :
			case ClientsApiCountry.ITALY :
				return this.localizePipe.transform('Italien');

			case AccountApiCountry.ROMANIA :
			case RegisterTestAccountApiCountry.ROMANIA :
			case PSupportedCountryCodes.RO :
			case ClientsApiCountry.ROMANIA :
				return this.localizePipe.transform('Rumänien');

			case AccountApiCountry.NETHERLANDS :
			case RegisterTestAccountApiCountry.NETHERLANDS :
			case PSupportedCountryCodes.NL :
			case ClientsApiCountry.NETHERLANDS :
				return this.localizePipe.transform('Niederlande');

			case AccountApiCountry.BELGIUM :
			case RegisterTestAccountApiCountry.BELGIUM :
			case PSupportedCountryCodes.BE :
			case ClientsApiCountry.BELGIUM :
				return this.localizePipe.transform('Belgien');

			case AccountApiCountry.UNITED_KINGDOM :
			case RegisterTestAccountApiCountry.UNITED_KINGDOM :
			case PSupportedCountryCodes.GB :
			case ClientsApiCountry.UNITED_KINGDOM :
				return this.localizePipe.transform('Vereinigtes Königreich');

			case AccountApiCountry.CZECH_REPUBLIC :
			case RegisterTestAccountApiCountry.CZECH_REPUBLIC :
			case PSupportedCountryCodes.CZ :
			case ClientsApiCountry.CZECH_REPUBLIC :
				return this.localizePipe.transform('Tschechien');

			case AccountApiCountry.SWEDEN :
			case RegisterTestAccountApiCountry.SWEDEN :
			case PSupportedCountryCodes.SE :
			case ClientsApiCountry.SWEDEN :
				return this.localizePipe.transform('Schweden');

			case AccountApiCountry.LUXEMBOURG :
			case RegisterTestAccountApiCountry.LUXEMBOURG :
			case PSupportedCountryCodes.LU :
			case ClientsApiCountry.LUXEMBOURG :
				return this.localizePipe.transform('Luxemburg');

			case PSupportedLanguageCodes.de :
				return this.localizePipe.transform('Deutsch');

			case PSupportedLanguageCodes.en :
				return this.localizePipe.transform('Englisch');
		}
	}
}
