/**
 * When extending an api-data-wrapper class by further attributes you can use this helper class to ensure that
 * the api's data-copy behavior will work for your attribute.
 *
 * `public foo = 5;` will become `public foo = new ApiDataCopyAttribute(5);`.
 *
 * Note that this class does a deep copy of the value by calling `structuredClone()`.
 *
 * @deprecated Our current strategy is to not use this class anymore as it seemed that getting all the same
 * behavior for this class compared to generated attributes would be a difficult task.
 * Instead, for the future, we decided to use the normal api-generator to also generate frontend-attributes.
 * See e.g.`SHIFT_REPETITION_REPETITION_END_MODE`.
 * If the new strategy works out, we should remove this class completely (PLANO-161893).
 */
export class ApiDataCopyAttribute<T> {
	/**
	 * @param initialValue The initial value of this attribute.
	 */
	constructor(initialValue : T) {
		this._value = initialValue;
		this.valueCopy = initialValue;
	}

	/**
	 * The current value which is visible to outside.
	 */
	private _value : T;

	/**
	 * The copy of the value which will override `value` when `dismissDataCopy()` is called.
	 */
	private valueCopy : T;

	/**
	 * This handler is called when a copy of the data should be created.
	 */
	public createDataCopy() : void {
		this.valueCopy = structuredClone(this.value);
	}

	/**
	 * This handler is called when current value should be reset to previous value before `createDataCopy()` was called.
	 */
	public dismissDataCopy() : void {
		this._value = this.valueCopy;
	}

	/**
	 * This handler is called when current value should be reset to previous value before `createDataCopy()` was called.
	 */
	public mergeDataCopy() : void {
		this.valueCopy = structuredClone(this.value);
	}

	/**
	 * The current value.
	 */
	public get value() : T {
		return this._value;
	}

	public set value(newValue : T) {
		this._value = newValue;
	}
}
