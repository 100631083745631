/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';

@Component({
	selector: 'p-youtube',
	templateUrl: './p-youtube.component.html',
	styleUrls: ['./p-youtube.component.scss'],
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PYoutubeComponent extends PBaseClass implements OnChanges {
	@HostBinding('attr.role') private _role = 'button';
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input({required: true}) public key ! : string;

	/**
	 * We want to enforce the dev to set a title.
	 * We do this by Angular’s feature of required inputs.
	 * So the property is not meant to be used in the component.
	 */
	@Input({required: true}) private title ! : string;

	/**
	 * The youtube thumbnail quality values.
	 * Possible values are "default", "mqdefault", "hqdefault", "sddefault" and "maxresdefault". cSpell:disable-line
	 * Source: https://stackoverflow.com/a/2068371
	 */
	// cSpell:ignore mqdefault hqdefault sddefault
	@Input() public quality : 'default' | 'mqdefault' | 'hqdefault' | 'sddefault' | 'maxresdefault' | null = 'maxresdefault';

	@ViewChild('videoModalContent') private videoModalContent ! : PModalTemplateDirective;

	constructor(
		public modalService : ModalService,
		private sanitizer : DomSanitizer,
	) {
		super();
	}

	public ngOnChanges() : void {
		if (!this.key) {
			throw new Error(`"key" is a required value.`);
		}

		if (!this.quality) {
			throw new Error(`"quality" is a required value.`);
		}

		this._imageUrl = this.sanitizer.
			bypassSecurityTrustUrl(`https://img.youtube.com/vi/${this.key}/${this.quality}.jpg`);

		// cSpell:ignore showinfo nocookie
		// By using the "nocookie" domain we can prevent cookies from being sent to YouTube, unless the user clicks on the video or is logged into their Google account.
		// The player parameter "showinfo" has been deprecated in 2018. Therefore the video title will always be shown in embedded videos. For more info about available parameters head over to https://developers.google.com/youtube/player_parameters
		this._iFrameUrl = this.sanitizer.
			bypassSecurityTrustResourceUrl(`https://www.youtube-nocookie.com/embed/${this.key}?rel=0`);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public onImageClick() : void {
		// On ios app the webview could not show youtube iframe. So, we let the app open the video in external browser.
		if (Config.platform === 'appIOS') {
			window.nsWebViewInterface.emit('openYoutube', this.key);
		} else {
			// Otherwise open our own modal with embedded video
			this.modalService.openModal(this.videoModalContent, {size: this.enums.BootstrapSize.LG, windowClass: 'no-gutter-body'});
		}
	}

	private _imageUrl : SafeUrl | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get imageUrl() : SafeUrl | null {
		return this._imageUrl;
	}

	private _iFrameUrl : SafeResourceUrl | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get iFrameUrl() : SafeResourceUrl | null {
		return this._iFrameUrl;
	}
}
