import { SchedulingApiShiftModelCoursePaymentMethod } from '@plano/shared/api';
import { SortedByFnArray } from '@plano/shared/api/base/api-list-wrapper/api-list-wrapper.type';

/**
 * The default to apply to all sortedBy method calls for lists of {@link SchedulingApiShiftModelCoursePaymentMethods}
 * @param item The {@link SchedulingApiShiftModelCoursePaymentMethod} to sort
 * @deprecated Use the sort function instead to be able to use stringCompare as that gives better results than sorting someString.toLowerCase()
 * TODO: PLANO-187682 Remove this
 */
export const defaultSortingForShiftModelCoursePaymentMethods : SortedByFnArray<SchedulingApiShiftModelCoursePaymentMethod> = [
	(item) => item.aiName.value?.toLowerCase(),
];
