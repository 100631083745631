<div
	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit()"
	(editMode)="editMode.emit($event)"
>
	<div class="input-group" [disabled]="disabled">
		<div pHiddenInEditMode class="w-100 card border-0 rounded mb-0">
			<div
				role="button"
				type="button"
				(click)="handleClickToEdit($event)"
				[preventDefault]="preventDefaultTriggerEditable.bind(this)"
				pEditableTriggerClickable
				triggerType="click"
				title="{{disabled || readMode ? '' : ('Bearbeiten' | localize)}}"
				[class.text-editor-cursor]="!disabled && !readMode"
				class="card-body action-box-wrapper btn btn-light border form-control w-100 text-end"
				[disabled]="control ? control.disabled : disabled"
			><div *ngIf="!disabled" class="action-indicator-wrapper"><fa-icon [icon]="enums.PlanoFaIconContextPool.TEXT_AREA" /></div>
				<ng-container *ngTemplateOutlet="innerTextArea" />
			</div>
		</div>
		<div
			pVisibleInEditMode
			*ngIf="!(disabled || readMode); else disabledNonEditable"
			class="w-100"
			[class.has-danger]="control && control.touched && control.invalid"
		>
			<ng-container *ngTemplateOutlet="prependItem" />
			<angular-editor
				#editor
				class="w-100 p-0 form-control email-and-booking-plugin-styles border-0"
				[(ngModel)]="value"
				[config]="editorConfig"
				[class.required]="hasRequiredError"
				[class.has-danger]="hasDanger"
				[class.has-warning]="hasWarning"
				[class.ng-untouched]="control && control.untouched"
				[class.ng-pristine]="control && control.pristine"
			/>
			<ng-container *ngTemplateOutlet="appendItem" />
		</div>
		<div class="w-100 d-flex justify-content-between align-items-center pt-2 gap-2">
			<button
				class="w-100"
				pEditableDismissButton
			><fa-icon [icon]="enums.PlanoFaIconContextPool.UNDO" /></button>
			<button
				class="w-100"
				pEditableSuccessButton
			><fa-icon [class.text-warning]="hasWarning" [icon]="successBtnIcon" [spin]="successBtnIconSpin" /></button>
		</div>
	</div>
</div>

<ng-template [ngIf]="!!control">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="control"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[checkTouched]="checkTouched"
	/>
</ng-template>

<ng-template #innerTextArea>
	<!--eslint-disable no-restricted-syntax -->
	<div
		[innerHTML]="(value === null ? '' : value)"
		class="text-start email-and-booking-plugin-styles w-100"
		[class.rounded-bottom-only]="!disabled"
		[class.rounded]="disabled"
		[disabled]="disabled"
		[class.font-tabular-nums]="disabled && !readMode"
	></div>
	<!--eslint-enable no-restricted-syntax -->
</ng-template>

<ng-template #disabledNonEditable>
	<div class="w-100 rounded card border-0 mb-0" *ngIf="!pEditable">
			<button
				type="button"
				class="card-body action-box-wrapper btn btn-light border form-control w-100 text-end"
				[disabled]="true"
			><ng-container *ngTemplateOutlet="innerTextArea" /></button>
		</div>
</ng-template>

<ng-template #prependItem>
	<ng-content select=".input-group-prepend" />
</ng-template>
<ng-template #appendItem>
	<ng-content select=".input-group-append" />
</ng-template>
