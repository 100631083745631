/**
 * Angular's pre-defined format options for the {@link DatePipe} that are supported by our own date pipe {@link PDatePipe}.
 * See https://angular.dev/api/common/DatePipe#pre-defined-format-options
 */
export enum AngularDatePipeFormat {

	/** E.g. `14.04.20, 19:52` */
	SHORT = 'short',

	// Not supported by our own date pipe
	// /** E.g. `13.09.2021, 16:00:00` */
	// MEDIUM = 'medium',
	// LONG = 'long',
	// FULL = 'full',

	/** E.g. `13.09.21` */
	SHORT_DATE = 'shortDate',

	/** E.g. `13.09.2021` */
	MEDIUM_DATE = 'mediumDate',

	/** E.g. `13. October 2021` */
	LONG_DATE = 'longDate',

	/** E.g. `Montag, 13. October 2021` */
	// FULL_DATE = 'fullDate',

	/** E.g. `16:00 Uhr` */
	SHORT_TIME = 'shortTime',

	/** E.g. `9:30:59` */
	MEDIUM_TIME = 'mediumTime',

	// Not supported by our own date pipe
	// LONG_TIME = 'longTime',
	// FULL_TIME = 'fullTime',
}

/**
 * Additional pre-defined formats for the {@link PDatePipe} besides
 * Angular's pre-defined format options ({@link AngularDatePipeFormat}).
 */
export enum PDateFormat {

	/** E.g. `13.9.` */
	MINIMAL_DATE = 'minimalDate',

	/** E.g. `13.09.21` */
	VERY_SHORT_DATE = 'veryShortDate',

	/** E.g. `16:00` */
	VERY_SHORT_TIME = 'veryShortTime',

	/** E.g. `Do, 27.02. um 10:10 Uhr` */
	DATE_AT_TIME = 'dateAtTime',

	/**
	 * E.g. `Mo. 13. October 2021`
	 *
	 * Note that there is also {@link AngularDatePipeFormat.LONG_DATE} which results in `13. October 2021`.
	 * And there is also {@link AngularDatePipeFormat.FULL_DATE} which results in `Montag, 13. October 2021`.
	 * But often we want to reduce the length of the weekday, so we have our own format for it.
	 */
	LONG_DATE_WITH_WEEKDAY = 'longDateWithWeekday',
}
