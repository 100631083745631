// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- enumsObject is supposed to be imported here once
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

/**
 * Base class for all components/services/directives.
 * It provides common properties.
 */
export class PBaseClass {
	protected readonly enums = enumsObject;
}
