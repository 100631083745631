import { Date, DateTime } from '@plano/shared/api/base/generated-types.ag';

/**
 * If we have a route with a date param (`…/:date/…`), then the date param value will be a timestamp as string.
 * If we want to navigate to a route with NO value for :date, we dont want to write a string like 'undefined' or 'null'
 * into the url for esthetic reasons.
 *
 * @example Given a route contains a date and another param like `/my-calendar/:date/:my-other-param`, and we want to
 * navigate to `my-calendar` but with NO value for :date, we dont want to navigate to
 * `/my-calendar/undefined/value-for-my-other-param`, but instead use another value like `0`, which gives:
 * `/my-calendar/0/value-for-my-other-param`.
 *
 * Therefore we use a replacement value for nullish date values.
 */
const URL_PARAM_FOR_NULLISH_DATE = '0';

/**
 * This function helps us to transform a date value into a url param value.
 * @see URL_PARAM_FOR_NULLISH_DATE
 * @param date The date that should be transformed into a url param value.
 * @returns The date value that should be used in the url.
 */
export const turnDateIntoUrlParamValue = (date : Date | DateTime | null) : string => {
	return `${date ?? URL_PARAM_FOR_NULLISH_DATE}`;
};

/**
 * This function helps us to transform a url param value for a date into a date value.
 * @see URL_PARAM_FOR_NULLISH_DATE
 * @param dateParamValue The value for the param that is used in the url.
 * @returns The transformed date value.
 */
export const turnUrlParamValueIntoDate = (dateParamValue : string) : string | null => {
	return dateParamValue === URL_PARAM_FOR_NULLISH_DATE ? null : dateParamValue;
};
