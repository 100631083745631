<span *ngIf="isLoading" class="badge-content">{{!!content ? content : '██'}}</span>
<a
	*ngIf="badgeRouterLinkForTemplate; else isLoadedTemplate"
	class="navigate-inside-app"
	[routerLink]="badgeRouterLinkForTemplate"
	[fragment]="badgeRouterFragment!">
	<ng-container *ngTemplateOutlet="isLoadedTemplate" />
</a>
<ng-template #isLoadedTemplate>
	<fa-icon *ngIf="!!icon" [fixedWidth]="false" class="block-rotation" [icon]="icon" />
	<span *ngIf="!!text" class="badge-content {{breakpointClasses}}" [innerHTML]="stringOrSymbolToString(text) | pSafeHtml"></span>
	<div #ngContent [class.d-none]="!hasNgContent" class="nowrap">
		<ng-content />
	</div>
</ng-template>
