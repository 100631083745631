import { Directive, ElementRef } from '@angular/core';

/**
 * Marker where the anchor indicator should be placed.
 * @see AnchorLinkDirective
 */
@Directive({
	selector: '[pAnchorIndicator]',
	standalone: true,
})
export class AnchorIndicatorDirective {
	constructor(public elementRef : ElementRef<HTMLElement>) {}
}
