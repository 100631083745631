import { USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';

export type PDropdownLabelsFilterIndexedDBKeyPrefixFilterType = 'modal' | 'scheduling' | 'pShiftSelection' | 'pShiftExchanges' | 'pOfferPicker' | 'pLeaveDaysCalendar';

export type PDropdownLabelsFilterIndexedDBKeyPrefixHolidaysType = 'HolidaysTypePublicHolidays' | 'HolidaysTypeSchoolHolidays';

export type PDropdownLabelsFilterIndexedDBKeyPrefixDataType = `${PDropdownLabelsFilterIndexedDBKeyPrefixFilterType}${PDropdownLabelsFilterIndexedDBKeyPrefixHolidaysType}`;

export type PDropdownLabelsFilterIndexedDBKeyDataType = {
	database : 'user';
	store : typeof USER_STORE_NAME;
	prefix : PDropdownLabelsFilterIndexedDBKeyPrefixDataType,
	name : typeof P_DROPDOWN_LABELS_FILTER_CHECKED_LABEL_IDS.name |
        typeof P_DROPDOWN_LABELS_FILTER_POSITIVE_FILTER.name |
		typeof P_DROPDOWN_LABELS_FILTER_FILTER_NO_LABEL.name;
};

export const P_DROPDOWN_LABELS_FILTER_CHECKED_LABEL_IDS = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'toggledLabelIds',
} as const;

export const P_DROPDOWN_LABELS_FILTER_POSITIVE_FILTER = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'positiveFilter',
} as const;

export const P_DROPDOWN_LABELS_FILTER_FILTER_NO_LABEL = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'filterNoLabel',
} as const;

/**
 * This is a special case. In these keys, we have multiple with the same name, that can be grouped in different
 * prefixes. We usually set the which prefix should be used centrally before calling this method, allowing us to
 * avoid having a lot of very similar keys.
 *
 * @param key The key to be used in the indexedDB without the prefix
 * @param prefix The prefix which separates the different keys
 * @returns The key with the prefix
 */
export const pDropdownLabelsFilterIndexedDBKeyWithPrefix =
	(key : PDropdownLabelsFilterIndexedDBKeys, prefix : PDropdownLabelsFilterIndexedDBKeyPrefixDataType) : PDropdownLabelsFilterIndexedDBKeyDataType => {
		return {...key, prefix};
	};

type PDropdownLabelsFilterIndexedDBKeys =
    typeof P_DROPDOWN_LABELS_FILTER_CHECKED_LABEL_IDS | typeof P_DROPDOWN_LABELS_FILTER_POSITIVE_FILTER | typeof P_DROPDOWN_LABELS_FILTER_FILTER_NO_LABEL;
