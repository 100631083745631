import { AfterViewChecked, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Config } from '@plano/shared/core/config';

/**
 * Add the class.ignore-gutter to every child of div with the .content class,
 * which has a width of 100% and a background color
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	selector: '.content',
	standalone: true,
})
export class IgnoreGutterDirective implements AfterViewChecked, OnDestroy {

	constructor(private elementRef : ElementRef<HTMLElement>) {

	}

	public Config = Config;

	private addNecessaryGutterClasses(element : Element, comparableWidth : number) : void {
		// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
		if (Config.IS_MOBILE) return;
		const widthOfScrollbar = 12;
		for (const elementChild of element.children) {
			const elementChildWidth = elementChild.getBoundingClientRect().width;

			// is the element the size of the container minus the size of the scrollbar?
			if (elementChildWidth === 0 || elementChildWidth < comparableWidth - widthOfScrollbar) {
				continue;
			} else if ([...elementChild.classList].some(classOfChild => classOfChild.includes('bg-'))) {
				elementChild.classList.add('ignore-gutter');
			} else {
				this.addNecessaryGutterClasses(elementChild, comparableWidth);
			}
		}
	}

	private mutationObserver : MutationObserver | null = null;
	private resizeObserver : ResizeObserver | null = null;

	public ngAfterViewChecked() : void {
		// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
		if (Config.IS_MOBILE) return;
		if (!this.mutationObserver) {
			this.mutationObserver = new MutationObserver(_mutations => {
				this.addNecessaryGutterClasses(this.elementRef.nativeElement, this.elementRef.nativeElement.getBoundingClientRect().width);
			});
			const contentInner = this.elementRef.nativeElement.querySelector('.content-inner');
			if (contentInner)
				this.mutationObserver.observe(contentInner, {childList: true});
		}
		if (!this.resizeObserver) {
			this.resizeObserver = new ResizeObserver(_entries => {
				const clientWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
				if (clientWidth > 0) {
					this.addNecessaryGutterClasses(this.elementRef.nativeElement, clientWidth);
				}
			});
			this.resizeObserver.observe(this.elementRef.nativeElement);
		}
	}

	public ngOnDestroy() : void {
		this.mutationObserver?.disconnect();
		this.resizeObserver?.disconnect();
	}
}
