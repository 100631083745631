// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Only place where it’s allowed
import { CurrencyPipe, DatePipe, DecimalPipe, KeyValuePipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { PContrastPipe } from '@plano/shared/core/contrast.pipe';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';
import { PDurationHighestGroupHoursPipe, PDurationOnlyAsSingleUnitsPipe, PDurationTimePipe } from './duration-time.pipe';
import { LocalizeFilePipe } from './localize-file.pipe';
import { LocalizePipe } from './localize.pipe';
import { PCurrencyPipe } from './p-currency.pipe';
import { PDatePipe } from './p-date.pipe';
import { PTranslateEnumPipe } from './p-translate-enum.pipe';
import { PUrlForHumansPipe } from './url-for-humans.pipe';

@NgModule({
	imports: [
		DatePipe,
		LocalizeFilePipe,
		LocalizePipe,
		PCommonModule,
		PContrastPipe,
		PCurrencyPipe,
		PDatePipe,
		PDurationHighestGroupHoursPipe,
		PDurationOnlyAsSingleUnitsPipe,
		PDurationTimePipe,
		PTranslateEnumPipe,
		PUrlForHumansPipe,
		SafeHtmlPipe,
	],
	providers: [
		CurrencyPipe,
		DatePipe,
		DecimalPipe,
		KeyValuePipe,
		LocalizeFilePipe,
		LocalizePipe,
		PContrastPipe,
		PCurrencyPipe,
		PDatePipe,
		PDurationHighestGroupHoursPipe,
		PDurationOnlyAsSingleUnitsPipe,
		PDurationTimePipe,
		PercentPipe,
		PTranslateEnumPipe,
		PUrlForHumansPipe,
		SafeHtmlPipe,
	],
	exports: [
		LocalizeFilePipe,
		LocalizePipe,
		PContrastPipe,
		PCurrencyPipe,
		PDatePipe,
		PDurationHighestGroupHoursPipe,
		PDurationOnlyAsSingleUnitsPipe,
		PDurationTimePipe,
		PTranslateEnumPipe,
		PUrlForHumansPipe,
		SafeHtmlPipe,
	],
})
export class CorePipesModule {}
