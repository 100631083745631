import { ApiDataWrapperBase } from '@plano/shared/api/base/api-data-wrapper-base';
import { ApiAttributeInfoArgsBase, ApiAttributeInfoBase } from '@plano/shared/api/base/attribute-info/api-attribute-info-base';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { Integer, PApiType } from '@plano/shared/api/base/generated-types.ag';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { NonNullAndNonUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PValidatorObject } from '@plano/shared/core/validators.types';

/**
 * Note that the passed methods have a "this" parameter which has the correct Wrapper type (not the base wrapper type).
 */
interface ApiAttributeInfoArgs<ParentType extends ApiDataWrapperBase, ValueType> extends ApiAttributeInfoArgsBase<ParentType> {
	apiObjWrapper : ParentType;

	/**
	 * The api attribute name.
	 */
	name : string;

	/**
	 * The api node-name. It is unique in the given api.
	 */
	nodeName : string;

	/**
	 * The type of the attributeInfo's value.
	 */
	type : PApiType | (() => PApiType);

	/**
	 * For attribute-infos of type `PApiType.ApiList` this will return the type of the items in the list.
	 */
	listItemType ?: PApiType;

	/**
	 * Is this a detailed attribute? If so, it is only sent when `loadDetailed` is called.
	 */
	isDetailedAttribute ?: boolean;

	/**
	 * Set this to ignore the parent set conditions.
	 */
	skipParentSetConditions ?: boolean;

	/**
	 * Does current user generally have the permission to get this value?
	 * See {@link ApiAttributeInfo.hasPermissionToGet}.
	 */
	hasPermissionToGet ?: ((this : ParentType) => boolean | undefined);

	/**
	 * Does current user generally have the permission to set this value? This returns the result of the conditions
	 * in `<set><if-permission>…</if-permission></set>`.
	 *
	 * Meaning of different return types:
	 * - `boolean`: Can attribute be set now?
	 * - `PDictionarySource`: The attribute cannot be set now. The return value indicates the message to be shown to user as explanation why it cannot be edited.
	 * - `undefined`: Currently, it cannot be decided if the attribute can be set or not (e.g. because needed apis are not loaded).
	 */
	hasPermissionToSet ?: ((this : ParentType) => boolean | PDictionarySource | undefined);

	/**
	 * A list of synchron validators.
	 */
	validations ?: ((this : ParentType) => (() => PValidatorObject | null)[]);

	/**
	 * A list of async validators. See `AsyncValidatorsService`.
	 */
	asyncValidations ?: ((this : ParentType) => (() => PValidatorObject<'async'> | null)[]);

	/**
	 * A method which returns the raw-data default value for this attribute.
	 * If no value is set than it means that the default value will be `null`.
	 */
	defaultValue ?: (this : ParentType, nodeId : string) => any;

	/**
	 * The raw-data index of this attribute.
	 */
	rawDataIndex ?: Integer;

	/**
	 * Attribute-infos for possible values of this attribute.
	 */
	attributeValueInfos ?: Map<ValueType, ApiAttributeValueInfo<ParentType>>;
}

/**
 * An object of this class contains meta information about an api attribute. It contains the business logic of an attribute
 * and by "plugging" it into the component, the component will have the desired business functionality. The logic of the
 * attribute-infos is automatically generated from our API XML files.
 */
export class ApiAttributeInfo<ParentType extends ApiDataWrapperBase, ValueType>
	extends ApiAttributeInfoBase<ParentType, ApiAttributeInfoArgs<ParentType, ValueType>> {
	/**
	 * The name of the attribute represented by this attribute-info object.
	 */
	public get name() : string {
		return this.args.name;
	}

	/**
	 * The api node-name. It is unique in the given api.
	 */
	public get nodeName() : string {
		return this.args.nodeName;
	}

	/**
	 * A unique id for the attribute-info. If it is part of a list, the id will be adjusted so it remains unique.
	 */
	public get id() : string {
		let id = this.nodeName;

		let objectWrapper = this.apiObjWrapper;

		let uniqueId = objectWrapper.id;

		while (uniqueId === null && objectWrapper.parent) {
			objectWrapper = objectWrapper.parent as ParentType;
			uniqueId = objectWrapper.id;
		}

		if (objectWrapper.id !== null)
			id += `_${objectWrapper.id.toString()}`;

		return id;
	}

	/**
	 * The type of this attribute-info.
	 */
	public get type() : PApiType {
		if (typeof this.args.type !== 'function') return this.args.type as any ?? null;
		return this.args.type.call(this.args.apiObjWrapper) as any;
	}

	/**
	 * For attribute-infos of type `PApiType.ApiList` this will return the type of the items in the list.
	 */
	public get listItemType() : PApiType | null {
		return this.args.listItemType ?? null;
	}

	/**
	 * Is this a detailed attribute? If so, it is only sent when `loadDetailed` is called.
	 */
	public get isDetailedAttribute() : boolean {
		return this.args.isDetailedAttribute ?? false;
	}

	/**
	 * @returns Returns the value.
	 */
	public get value() : NonNullAndNonUndefined<ValueType> | null {
		return this.isWrapper ? this.args.apiObjWrapper : (this.args.apiObjWrapper as any)[this.args.name];
	}

	/**
	 * Sets the value.
	 */
	public set value(value : NonNullAndNonUndefined<ValueType> | null) {
		if (this.isWrapper)
			throw new Error('Currently it is not supported to set a wrapper using the attribute-info. If you think you really need this, we need to look in more detail into it.');

		(this.args.apiObjWrapper as any)[this.args.name] = value;
	}

	/**
	 * The raw-data index of this attribute.
	 */
	public get rawDataIndex() : Integer | null {
		return this.args.rawDataIndex ?? null;
	}

	public override get isAvailable() : boolean | undefined {
		if (this.api !== null) {
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (!this.api.isLoaded())
				return undefined;

			const rawDataIndex = this.rawDataIndex;
			if (rawDataIndex) {

				// a primitive whose object-wrapper has no raw-data should never been shown
				const objectWrapperRawData = this.apiObjWrapper.rawData;

				if (!this.isWrapper && !objectWrapperRawData)
					return false;

				// For items coming from backend (not new-items) when load operation is running and currently the desired data
				// is not available we cannot answer if this attribute should be shown or not.
				const rawData = this.isWrapper ? objectWrapperRawData : objectWrapperRawData[rawDataIndex];
				if (!this.apiObjWrapper.isNewItem && rawData === undefined && this.api.isBackendOperationRunning)
					return undefined;

				// return false when this is a detailed field but the wrapper is not loaded detailed
				// and also this is not a newly created item which would also contain any detailed fields.
				if (this.isDetailedAttribute && !this.isLoadedDetailed && !this.isNewItem)
					return false;
			}
		}

		// otherwise check show logic
		return this.hasPermissionToGet && this.isAvailableByBusinessLogic;
	}

	/**
	 * Is this object-wrapper or one of its ancestors loaded detailed?
	 */
	private get isLoadedDetailed() : boolean {
		let objectWrapper : ApiDataWrapperBase | null = this.apiObjWrapper;
		while (objectWrapper) {
			if (objectWrapper.isDetailedLoaded)
				return true;

			objectWrapper = objectWrapper.parent;
		}

		return false;
	}

	/**
	 * @returns Has user generally the permission to get this attribute? This returns the result of the conditions
	 * in `<get><if-permission>…</if-permission></get>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute is currently available includes further criteria.
	 * See {@link isAvailable}.
	 */
	public get hasPermissionToGet() : boolean | undefined {
		// check parent show state
		const parent = this.parentAttributeInfo;
		if (parent && !parent.hasPermissionToGet)
			return parent.hasPermissionToGet;

		// check show state of this attribute-info
		return this.args.hasPermissionToGet ? this.args.hasPermissionToGet.call(this.args.apiObjWrapper) : true;
	}

	protected override get isAvailableByBusinessLogic() : boolean | undefined {
		// check parent show state
		const parent = this.parentAttributeInfo;
		if (parent && !parent.isAvailableByBusinessLogic)
			return parent.isAvailableByBusinessLogic;

		// check show state of this attribute-info
		return this.args.isAvailableByBusinessLogic ? this.args.isAvailableByBusinessLogic.call(this.args.apiObjWrapper) : true;
	}

	public override get canSet() : boolean {
		return this.isAvailable === true && this.hasPermissionToSet === true && this.canSetByBusinessLogic === true;
	}

	/**
	 * @returns Has user generally the permission to set this attribute? This returns the result of the conditions
	 * in `<set><if-permission>…</if-permission></set>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute can be set now, includes further criteria. See {@link canSet}.
	 */
	public get hasPermissionToSet() : boolean | undefined {
		// check parent logic
		const parent = this.parentAttributeInfo;
		if (this.args.skipParentSetConditions !== true && parent && !parent.hasPermissionToSet)
			return parent.hasPermissionToSet;

		// check logic of this attribute-info
		if (this.args.hasPermissionToSet) {
			const hasPermissionToSetValue = this.args.hasPermissionToSet.call(this.args.apiObjWrapper);
			if (hasPermissionToSetValue === undefined) return undefined;
			return hasPermissionToSetValue === true;
		}

		return true;
	}

	protected override get canSetByBusinessLogic() : boolean | undefined {
		// check parent logic
		const parent = this.parentAttributeInfo;
		if (this.args.skipParentSetConditions !== true && parent && !parent.canSetByBusinessLogic)
			return parent.canSetByBusinessLogic;

		// check logic of this attribute-info
		if (this.args.canSetByBusinessLogic) {
			const canSetByBusinessLogicValue = this.args.canSetByBusinessLogic.call(this.args.apiObjWrapper);
			if (canSetByBusinessLogicValue === undefined) return undefined;
			return canSetByBusinessLogicValue === true;
		}

		return true;
	}

	/**
	 * @returns Is the value of this attribute generally not editable for current user? Then it
	 * is in read-mode and it will be visualized more like a label.
	 */
	public get readMode() : boolean {
		return !this.hasPermissionToSet;
	}

	/**
	 * @returns Returns a list of validators for this attribute.
	 */
	public get validations() : (() => PValidatorObject | null)[] {
		return this.args.validations ? this.args.validations.call(this.args.apiObjWrapper) : [];
	}

	/**
	 * @returns Returns a list of async validators for this attribute. See `AsyncValidatorsService`.
	 */
	public get asyncValidations() : (() => PValidatorObject<'async'> | null)[] {
		return this.args.asyncValidations ? this.args.asyncValidations.call(this.args.apiObjWrapper) : [];
	}

	/**
	 * The raw-data default value for this attribute.
	 */
	public get defaultValue() : any {
		if (this.args.defaultValue === undefined) {
			return null;
		} else {
			return this.args.defaultValue.call(this.args.apiObjWrapper, this.id);
		}
	}

	private get parentAttributeInfo() : ApiAttributeInfo<any, any> | null {
		// Is this the attribute-info of the object wrapper?
		if (this.args.apiObjWrapper.aiThis === this) {
			// Then return the attribute info of parent
			const parent = this.args.apiObjWrapper.parent;
			return parent ? parent.aiThis : null;
		} else {
			// otherwise it a primitive type attribute-info.
			return this.args.apiObjWrapper.aiThis;
		}
	}

	/**
	 * @param value The value for which the attribute-value-info should be returned.
	 * @returns The attribute-value-info for desired `value`. `null` will
	 * be returned when no logic is defined for desired `value` in the API XML file.
	 */
	public getAttributeValueInfo(value : ValueType) : ApiAttributeValueInfo<ParentType> | null {
		return this.args.attributeValueInfos?.get(value) ?? null;
	}

	/**
	 * Does this attribute-info represent an object or list wrapper?
	 */
	public get isWrapper() : boolean {
		return this.type === PApiType.ApiObject || this.type === PApiType.ApiList;
	}

	public override get cannotSetHint() : PDictionarySource | null {
		// "cannotSetHint" from super
		const superResult = super.cannotSetHint;
		if (superResult !== null)
			return superResult;

		// "cannotSetHint" from "hasPermissionToSet"
		const hasPermissionToSet = this.args.hasPermissionToSet ? this.args.hasPermissionToSet.call(this.args.apiObjWrapper) : undefined;
		if (typeof hasPermissionToSet !== 'boolean' && hasPermissionToSet !== undefined)
			return hasPermissionToSet;

		// "cannotSetHint" from parent
		const parent = this.parentAttributeInfo;
		const parentCannotSetHint = parent ? parent.cannotSetHint : null;
		if (parentCannotSetHint !== null)
			return parentCannotSetHint;

		// no cannotSetHint
		return null;
	}
}
