/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { SchedulingApiShiftModelRepetitionBase, SchedulingApiShiftModelRepetitionPacket, SchedulingApiShiftRepetitionBase, SchedulingApiShiftRepetitionPacket, SchedulingApiShiftRepetitionType } from '@plano/shared/api';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

/**
 * Custom extensions of the API class.
 */
export class SchedulingApiShiftModelRepetition extends SchedulingApiShiftModelRepetitionBase {

	/**
	 * Throws a validation error if repetition type is `EVERY_X_WEEKS` and not at least one week-day is selected.
	 */
	public static checkEveryXWeeksRepetitionOneWeekDayIsSelected(
		item : SchedulingApiShiftModelRepetition | SchedulingApiShiftModelRepetitionPacket |
			SchedulingApiShiftRepetition | SchedulingApiShiftRepetitionPacket,
	) : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.ONE_WEEK_DAY_IS_SELECTED, fn: (_control) => {
			if (item.type !== SchedulingApiShiftRepetitionType.EVERY_X_WEEKS) return null;

			if (item.isRepeatingOnMonday ||
				item.isRepeatingOnTuesday ||
				item.isRepeatingOnWednesday ||
				item.isRepeatingOnThursday ||
				item.isRepeatingOnFriday ||
				item.isRepeatingOnSaturday ||
				item.isRepeatingOnSunday
			) {
				return null;
			}

			return { [PPossibleErrorNames.ONE_WEEK_DAY_IS_SELECTED]: {
				name: PPossibleErrorNames.ONE_WEEK_DAY_IS_SELECTED,
				type: null,
			}};
		}});
	}

	/**
	 * Throws a validation error if repetition type is `EVERY_X_WEEKS` and more than the maximum week-days have been selected.
	 */
	public static checkIfEveryXWeeksRepetitionAndWeekDaysSelectionFitsTogether(
		item : SchedulingApiShiftModelRepetition | SchedulingApiShiftModelRepetitionPacket |
			SchedulingApiShiftRepetition | SchedulingApiShiftRepetitionPacket,
	) : PValidatorObject {
		return new PValidatorObject({
			name: PPossibleErrorNames.MAX,
			fn: (_control) => {
				if (item.type !== SchedulingApiShiftRepetitionType.EVERY_X_WEEKS) return null;

				const arrayOfWeekDayValues = [
					item.isRepeatingOnMonday,
					item.isRepeatingOnTuesday,
					item.isRepeatingOnWednesday,
					item.isRepeatingOnThursday,
					item.isRepeatingOnFriday,
					item.isRepeatingOnSaturday,
					item.isRepeatingOnSunday,
				];

				const selectedWeekDaysCount = arrayOfWeekDayValues.filter(value => value).length;

				if (
					item.aiEndsAfterRepetitionCount.isAvailable &&
				// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition -- it happened
				item.endsAfterRepetitionCount !== null &&
				selectedWeekDaysCount > item.endsAfterRepetitionCount
				) {
					const errorText = (() => {
						if (item instanceof SchedulingApiShiftModelRepetition || item instanceof SchedulingApiShiftRepetition) {
							return 'Die gewünschte Anzahl an Wiederholungen passt nicht zu der Anzahl der ausgewählten Wochentage.';
						}
						return 'Die Anzahl der gewählten Wochentage und die Anzahl an Schichten, aus denen dieses Paket besteht, passen nicht zusammen.';
					})();
					return { max: {
						name: PPossibleErrorNames.MAX,
						type: null,
						max: item.endsAfterRepetitionCount,
						actual: selectedWeekDaysCount,
						errorText: () => errorText,
					} };
				}

				return null;
			},
		});
	}

}

/**
 * Custom extensions of the API class.
 */
export class SchedulingApiShiftRepetition extends SchedulingApiShiftRepetitionBase {

}
