/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint max-lines: ["error", 2000] -- Dont make this file even bigger. Invest some time to cleanup/split into several files */
/* eslint max-classes-per-file: ["error", 30] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { SchedulingApiShiftModel, SchedulingApiShiftModels } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { SchedulingApiShifts } from '@plano/client/scheduling/shared/api/scheduling-api-shift.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { ApiErrorHandler, ApiLoadArgs, ApiSaveArgs, SchedulingApiAccountingPeriodBase, SchedulingApiAccountingPeriodExpectedMemberDataBase, SchedulingApiAccountingPeriodExpectedMemberDataItem, SchedulingApiAccountingPeriodsBase, SchedulingApiAssignmentProcessBase, SchedulingApiAssignmentProcessesBase, SchedulingApiBookableCreatedBy, SchedulingApiGiftCardBase, SchedulingApiGiftCardOverrideWaysToRedeem, SchedulingApiGiftCardsBase, SchedulingApiGiftCardStatus, SchedulingApiHolidaysHolidayItemBase, SchedulingApiHolidaysHolidayItemsBase, SchedulingApiHolidaysLabelsBase, SchedulingApiLeaveBase, SchedulingApiLeaveDayBase, SchedulingApiLeaveDayDurationType, SchedulingApiLeaveDayPaymentType, SchedulingApiLeaveDaysBase, SchedulingApiLeaveDayType, SchedulingApiLeavesBase, SchedulingApiMember, SchedulingApiMemberAssignableShiftModelBase, SchedulingApiMemberAssignableShiftModelsBase, SchedulingApiMembersBase, SchedulingApiMemo, SchedulingApiMemosBase, SchedulingApiMessages, SchedulingApiOnlineRefundInfo, SchedulingApiPermissionGroupsBase, SchedulingApiPosSystem, SchedulingApiRootBase, SchedulingApiServiceBase, SchedulingApiShiftExchanges, SchedulingApiTodaysShiftDescriptionBase, SchedulingApiTodaysShiftDescriptionsBase, SchedulingApiTransactions, SchedulingApiWarningSeverity, ShiftId } from '@plano/shared/api';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { ClientCurrency, DateExclusiveEnd, DateTime, Duration, PApiType } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { NOT_CHANGED } from '@plano/shared/api/base/object-diff/object-diff';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { ModalRef, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe, PDictionarySourceStringAndParams } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { errorTypeUtils } from '@plano/shared/core/utils/error-type-utils';
import { assumeNonNull, notNull } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- All classes already extend the base classes
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';
import { SchedulingApiBookable } from './scheduling-api-bookable.service';
import { PPaymentStatusEnum } from './scheduling-api.utils';

@Injectable({
	providedIn: 'root',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiService extends SchedulingApiServiceBase {
	constructor(
		h : HttpClient,
		router : Router,
		apiE : ApiErrorService,
		zone : NgZone,
		injector : Injector,
	) {
		super(h, router, apiE, zone, injector);

		this.initListenersForToasts();
	}

	/**
	 * Initialize listeners that trigger toasts.
	 */
	private initListenersForToasts() : void {
		// Every time a notification has been sent to users, and a comment has been added, we show a toast.
		this.onDataSaveStart.subscribe((result) => {
			if (result === NOT_CHANGED) return;
			if (this.data.notificationsConfig.send && this.data.notificationsConfig.comment) {
				this.toasts.addToast({
					title: null,
					content: this.localizePipe.transform('Nachricht inklusive Kommentar verschickt.'),
					theme: enumsObject.PThemeEnum.SUCCESS,
				});
			}
		});
	}

	private automaticWarningsUpdateForChanges : string[] | null = null;

	private updateWarningsTimeout : number | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public enableAutomaticWarningsUpdateOnChange(forChanges : string[]) : void {
		this.automaticWarningsUpdateForChanges = forChanges;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public disableAutomaticWarningsUpdateOnChange() : void {
		this.automaticWarningsUpdateForChanges = null;
	}

	public override changed(change : string) : void {
		// automatic update enabled and we are supposed to update for current change?
		// eslint-disable-next-line sonarjs/no-collapsible-if -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (	this.automaticWarningsUpdateForChanges?.includes(change)) {
			// update warnings lazy.
			// E.g. when several api changes happen at the same time
			// updateWarnings() should be called only once.
			if (!this.updateWarningsTimeout) {
				this.zone.runOutsideAngular(() => {
					this.updateWarningsTimeout = window.setTimeout(() => {
						this.zone.run(() => {
							this.updateWarnings();
							this.updateWarningsTimeout = null;
						});
					});
				});
			}
		}

		// super
		// Hack to improve text dialogs: we ignore comment properties changes so the
		// Data properties are not recalculated
		if (	change !== 'message' 	&&
		change !== 'description' 	&&
		change !== 'illnessResponderCommentToMembers' 	&&
		change !== 'indisposedMemberComment' 	&&
		change !== 'performActionComment') {
			super.changed(change);
		}
	}

	public override async save({
		success = null,
		error = null,
		additionalSearchParams = null,
		saveEmptyData = false,
		sendRootMetaOnEmptyData = false,
		onlySavePath = null,
	} : ApiSaveArgs = {}) : Promise<HttpResponse<unknown>> {
		return super.save(
			{
				success: (response, savedData) => {
					if (this.data.aiMessages.isAvailable)
						this.showBackendMessageToasts(this.data.messages, savedData);

					if (success)
						success(response, savedData);
				},
				error: error,
				additionalSearchParams: additionalSearchParams,
				saveEmptyData: saveEmptyData,
				sendRootMetaOnEmptyData: sendRootMetaOnEmptyData,
				onlySavePath: onlySavePath,
			},
		);
	}

	public override async load({
		success = undefined,
		error = undefined,
		searchParams = new HttpParams(),
	} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		// if automaticWarningsUpdateOnChange is enabled then add current changes to search params
		if (this.automaticWarningsUpdateForChanges) {
			const currentChanges = this.dataStack.getDataToSave(null);

			if (currentChanges !== NOT_CHANGED) {

				if (!searchParams) {
					searchParams = new HttpParams();
				}

				searchParams = searchParams.set('warningsChanges', encodeURIComponent(JSON.stringify(currentChanges)));
			}
		}

		// super
		const promise = super.load({
			success : success ?? null,
			error : error ?? null,
			searchParams : searchParams,
		});

		// "warningsChanges" should only be send for this api call.
		if (this.automaticWarningsUpdateForChanges) {
			this.removeParamFromLastLoadSearchParams('warningsChanges');
		}

		return promise;
	}

	private runningUpdateWarningsApiCallCount = 0;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public updateWarnings() : void {
		// no need to update something if no data is loaded

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.isLoaded() || !this.lastExecutedLoadSearchParams)
			return;

		// tell backend that we are only interested in warnings
		let searchParams = this.lastExecutedLoadSearchParams
			.set('onlyWarnings', 'true');

		// send current changes data to backend
		const currentChanges = this.dataStack.getDataToSave(null);

		if (currentChanges !== NOT_CHANGED) {
			searchParams = searchParams.set('warningsChanges', encodeURIComponent(JSON.stringify(currentChanges)));
		}

		// don’t show stale warnings
		this.data.warnings._updateRawData([], true);

		// execute
		++this.runningUpdateWarningsApiCallCount;

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		this.http.get(this.apiUrl, this.getRequestOptions(searchParams)).subscribe(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			(response : HttpResponse<any>) => {
				--this.runningUpdateWarningsApiCallCount;

				// we expect to receive only warnings data.
				// So update them manually
				const newData = response.body;
				const warningsNewData = newData[this.consts.WARNINGS];
				this.data.warnings._updateRawData(warningsNewData, false);
			},
			(response : unknown) => {
				--this.runningUpdateWarningsApiCallCount;
				this.onError(null, response);
			},
		);
	}

	private _modalService : ModalService | null = null;

	/**
	 * Get the `ModalService` if needed.
	 */
	private get modalService() : ModalService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._modalService)
			this._modalService = this.injector.get(ModalService);

		return this._modalService;
	}

	private _schedulingService : SchedulingService | null = null;

	/**
	 * Get the `ModalService` if needed.
	 */
	private get schedulingService() : SchedulingService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._schedulingService)
			this._schedulingService = this.injector.get(SchedulingService);

		return this._schedulingService;
	}

	private _pRouterService : PRouterService | null = null;

	/**
	 * Get the `PRouterService` if needed.
	 */
	private get pRouterService() : PRouterService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._pRouterService)
			this._pRouterService = this.injector.get(PRouterService);

		return this._pRouterService;
	}

	/** Open a modal which tells the user about our calendar-limit */
	private async openCalendarRangeModal(
		modalService : ModalService,
		successLink : string,
		text : PDictionarySourceStringAndParams,
		limit : number,
		pRouterService : PRouterService,
	) : ModalRef['result'] {
		const isOnDetailPage = !this.pRouterService.url.startsWith('/client/scheduling');
		const modalRef = modalService.warn({
			icon: 'fa-ghost fa-duotone',
			description: `<p>${this.localizePipe.transform(text)}</p>`,
			modalTitle: null,
			closeBtnLabel: isOnDetailPage ? this.localizePipe.transform('OK') : this.localizePipe.transform({
				sourceString: 'Zum ${limit}',
				params: {
					limit: this.datePipe.transform(limit),
				},
			}),
			dismissBtnLabel: this.localizePipe.transform('Abbrechen'),
			hideDismissBtn: isOnDetailPage || pRouterService.historyIsEmpty,
		}, enumsObject.BootstrapSize.SM);
		// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
		return modalRef.result.then(value => {
			if (value.action === 'success') {
				// If we are on the detail page the calendar is usually inside a modal and we can not navigate somewhere to
				// change the calendar-view. So we do nothing. The user have to be smart enough to nav back.
				if (isOnDetailPage) return value;

				// It‘s important to provide this navigation, because a reload would
				// load the same time-range again which would lead to an endless loop of error modals.
				window.location = successLink as unknown as Location;
			} else {
				// If we are on the detail page the calendar is usually inside a modal and we can not navigate somewhere to
				// change the calendar-view. So we do nothing. The user have to be smart enough to nav back.
				if (isOnDetailPage) return value;

				if (pRouterService.historyIsEmpty) return value;
				pRouterService.navBack();

			}
			return value;
		});
	}

	private async handleCalendarRangeError() : ModalRef['result'] {

		// Get start and end from search params
		const start = this.lastExecutedLoadSearchParams ? +this.lastExecutedLoadSearchParams.get('start')! : null;
		const end = this.lastExecutedLoadSearchParams ? +this.lastExecutedLoadSearchParams.get('end')! : null;

		// Determine if the requested date-range is in the past or future
		const reachedLimitDirection : 'past' | 'future' | null = (() => {
			const now = Date.now();
			if (start && start > now) return 'future';
			if (end && end < now) return 'past';
			throw new Error('reachedLimitDirection could not be determined');
		})();

		let limit : number;
		const text : PDictionarySourceStringAndParams = (() => {
			if (reachedLimitDirection === 'past') {
				limit = +this.pMoment.m('2017').startOf('year');
				const requestedDate = +this.pMoment.m(start).startOf('day');
				return {
					sourceString: '${requestedDate} liegt zu weit in der Vergangenheit. Du kannst maximal bis zum ${limit} navigieren.',
					params: {
						requestedDate: `<mark>${this.datePipe.transform(requestedDate)}</mark>`,
						limit: `<mark>${this.datePipe.transform(limit)}</mark>`,
					},
				};
			} else {
				// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				limit = +this.pMoment.m().endOf('month').add('years', 3);
				const requestedDate = +this.pMoment.m(end).endOf('day');
				return {
					sourceString: '${requestedDate} liegt zu weit in der Zukunft. Du kannst maximal bis zum ${limit} navigieren.',
					params: {
						requestedDate: `<mark>${this.datePipe.transform(requestedDate)}</mark>`,
						limit: `<mark>${this.datePipe.transform(limit)}</mark>`,
					},
				};
			}
		})();

		const calendarMode = (
			this.schedulingService.urlParam.calendarMode === CalendarModes.WEEK ? CalendarModes.DAY : this.schedulingService.urlParam.calendarMode
		);

		return this.openCalendarRangeModal(
			this.modalService,
			`${Config.FRONTEND_URL_LOCALIZED}/client/scheduling/${calendarMode}/${limit}`,
			text,
			limit,
			this.pRouterService,
		);

	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	protected override onError(handler : ApiErrorHandler | null, response : any) : void {
		// we are not interested in errors which are not concerned api
		if (!errorTypeUtils.isTypeHttpErrorResponse(response))
			throw response;

		if (response.status === this.consts.SCHEDULING_API_INVALID_TIME_RANGE) {
			void this.handleCalendarRangeError();
			return;
		}

		super.onError(handler, response);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isUpdatingWarnings() : boolean {
		return this.runningUpdateWarningsApiCallCount > 0;
	}

	/**
	 * Deselect all shifts.
	 */
	// TODO: PLANO-188689 Remove .deselectAllSelections() everywhere if it is obsolete.
	public deselectAllSelections() : void {
		if (this.data.aiShifts.isAvailable)
			this.data.shifts.setSelected(false);
	}

	/**
	 * Check if any shift is selected.
	 */
	public get hasSelectedItems() : boolean {
		if (this.data.aiShifts.isAvailable && this.data.shifts.hasSelectedItem) return true;
		return false;
	}

	/**
	 * Shows toasts based on messages send from backend.
	 * @param messages Messages from backend.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	private showBackendMessageToasts(messages : SchedulingApiMessages, savedData : any[] | typeof NOT_CHANGED) : void {
		if (!messages.rawData)
			return;

		const localizePipe = this.injector.get<LocalizePipe>(LocalizePipe);

		// removedDuplicateReCaptchaWhiteListedHostName
		if (messages.removedDuplicateReCaptchaWhiteListedHostName) {
			this.toasts.addToast({
				content: localizePipe.transform('Die angegebene Domain war bereits vorhanden und wurde daher automatisch gelöscht.'),
				theme: enumsObject.PThemeEnum.WARNING,
				visibilityDuration: 'long',
			});
		}

		// sentRequestorReportAboutRemovedAssignments
		if (messages.sentRequestorReportAboutRemovedAssignments) {
			this.toasts.addToast({
				title: localizePipe.transform('Vorhandene Schichtbesetzung entfernt'),
				content: localizePipe.transform({
					sourceString: 'Deine Übertragung hat bereits vorhandene Schichtbesetzungen entfernt. Eine genaue Auflistung haben wir an deine Email geschickt <mark>${email}</mark>',
					params: {email : this.me.data.email},
				}),
				theme: enumsObject.PThemeEnum.WARNING,
				visibilityDuration: 'infinite',
			});
		}

		// onlineRefundInfo
		const onlineRefundInfo = messages.onlineRefundInfo;

		switch (onlineRefundInfo) {
			case SchedulingApiOnlineRefundInfo.ONLINE_REFUND_SUCCESSFUL:
				this.toasts.addToast({
					content: localizePipe.transform('Online-Rückerstattung erfolgreich veranlasst.<br>Das Geld sollte in wenigen Werktagen beim Kunden ankommen.'),
					theme: enumsObject.PThemeEnum.SUCCESS,
					visibilityDuration: 'long',
				});
				break;
			case SchedulingApiOnlineRefundInfo.ONLINE_REFUND_PARTIALLY:
				this.toasts.addToast({
					content: localizePipe.transform('Die Rückerstattung benötigte mehrere Teilzahlungen. Leider konnten nicht alle davon erfolgreich veranlasst werden. Für mehr Infos siehe »Zahlungen« in der Buchung.'),
					theme: enumsObject.PThemeEnum.DANGER,
					visibilityDuration: 'infinite',
				});
				break;
			case SchedulingApiOnlineRefundInfo.ONLINE_REFUND_FAILED:
				this.toasts.addToast({
					content: localizePipe.transform('Die Online-Rückerstattung konnte leider nicht veranlasst werden. Bitte versuche es etwas später erneut.'),
					theme: enumsObject.PThemeEnum.DANGER,
					visibilityDuration: 'infinite',
				});
				break;
			default:
				break;
		}

		// customBookableMailsInfo
		const customBookableMailsInfo = messages.customBookableMailsInfo;

		if (savedData !== NOT_CHANGED && customBookableMailsInfo.eventTriggered) {
			// email was send?
			if (customBookableMailsInfo.emailSentToBookingPerson || customBookableMailsInfo.emailSentToParticipants) {
				let text : PDictionarySourceString;
				if (customBookableMailsInfo.emailSentToBookingPerson) {
					if (customBookableMailsInfo.emailSentToParticipants) {
						text = 'An buchende Person und Teilnehmende';
					} else {
						text = 'An buchende Person';
					}
				} else {
					text = 'An Teilnehmende';
				}

				this.toasts.addToast({
					title: this.localizePipe.transform(`Email an Kunden verschickt`),
					content: this.localizePipe.transform(text),
					icon: enumsObject.PlanoFaIconContextPool.EMAIL_NOTIFICATION,
					theme: enumsObject.PThemeEnum.INFO,
				});
			} else {
				// Inform that no email was send
				let toastContent = '';

				if (customBookableMailsInfo.emailNotSentBecauseOfMissingEmail) {
					toastContent = this.localizePipe.transform('Die Email-Adresse der buchenden Person fehlt.');
				} else if (customBookableMailsInfo.affectedShiftModelId) {
					toastContent = this.localizePipe.transform({
						// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
						sourceString: 'Entsprechend deiner <a href="client/shiftmodel/${shiftModelId}/bookingsettings#automatic-emails" target="_blank">Einstellungen</a> in der Tätigkeit.',
						params: {shiftModelId: customBookableMailsInfo.affectedShiftModelId.toString()},
					});
				}

				this.toasts.addToast({
					title: this.localizePipe.transform('Keine Email an Kunden verschickt'),
					content: toastContent,
					icon: enumsObject.PlanoFaIconContextPool.EMAIL_NOTIFICATION,
					visibilityDuration: 'long',
					theme: enumsObject.PThemeEnum.WARNING,
				});
			}
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get hasFatalApiWarning() : boolean {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.isLoaded()) return false;
		if (this.isUpdatingWarnings) return false;
		const fatalApiWarnings = this.data.warnings.filterBy(item => {
			return item.severity === SchedulingApiWarningSeverity.FATAL;
		});
		if (fatalApiWarnings.length === 0) return false;
		return true;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiRoot extends SchedulingApiRootBase {
	/**
	 * Get a label for the `posSystem` attribute.
	 */
	public get posSystemLabel() : string | null {
		switch (this.posSystem) {
			case SchedulingApiPosSystem.BOULDERADO :
				return 'Boulderado';
			case SchedulingApiPosSystem.FREECLIMBER :
				return 'Freeclimber';
			case SchedulingApiPosSystem.BETA_7 :
				return 'BETA7';
			case null :
				return null;
		}
	}
}

/** @see SchedulingApiHolidaysHolidayItemBase */
export class SchedulingApiHolidaysHolidayItem extends SchedulingApiHolidaysHolidayItemBase {

	/**
	 * Get the icon for a given duration.
	 * @param duration The duration to get the icon for.
	 */
	public icon(duration : SchedulingApiLeaveDayDurationType | null) : PlanoFaIconPoolKeys | null {
		if (duration === null) return null;
		switch (duration) {
			case SchedulingApiLeaveDayDurationType.WHOLE_DAY:
				return 'fa-circle fa-solid';
			case SchedulingApiLeaveDayDurationType.HALF_DAY:
				return 'fa-circle-half fa-duotone';
			case SchedulingApiLeaveDayDurationType.QUARTER_DAY:
				return 'fa-circle-quarter fa-duotone';
			case SchedulingApiLeaveDayDurationType.THREE_QUARTER_DAY:
				return 'fa-circle-three-quarters fa-duotone';
		}
	}

	/**
	 * Get the duration label for a given duration.
	 * @param duration The duration to get the label for.
	 */
	public durationLabel(duration : SchedulingApiLeaveDayDurationType | null) : PDictionarySourceString | null {
		if (duration === null) return null;
		switch (duration) {
			case SchedulingApiLeaveDayDurationType.WHOLE_DAY:
				return '1 Tag';
			case SchedulingApiLeaveDayDurationType.HALF_DAY:
				// eslint-disable-next-line literal-blacklist/literal-blacklist -- need to set a class with normal quotes.
				return '<span class="font-diagonal-fractions">1/2 Tag</span>';
			case SchedulingApiLeaveDayDurationType.QUARTER_DAY:
				// eslint-disable-next-line literal-blacklist/literal-blacklist -- need to set a class with normal quotes.
				return '<span class="font-diagonal-fractions">1/4 Tag</span>';
			case SchedulingApiLeaveDayDurationType.THREE_QUARTER_DAY:
				// eslint-disable-next-line literal-blacklist/literal-blacklist -- need to set a class with normal quotes.
				return '<span class="font-diagonal-fractions">3/4 Tag</span>';
		}
	}
}

/** @see SchedulingApiHolidaysHolidayItemsBase */
export class SchedulingApiHolidaysHolidayItems extends SchedulingApiHolidaysHolidayItemsBase {
	/**
	 * @param dayStart - timestamp of the desired day
	 * @returns A list of holiday-items intersecting `dayStart`.
	 */
	public getByDay(dayStart : number) : this {
		Assertions.ensureIsDayStart(dayStart);

		const dayEnd = +(new PMomentService(Config.LOCALE_ID).m(dayStart).add(1, 'day'));
		Assertions.ensureIsDayStart(dayEnd);

		return this.filterBy(item => {
			if (dayStart >= item.end) return false;
			if (dayEnd <= item.start) return false;
			return true;
		});
	}
}

/** @see SchedulingApiHolidaysLabelsBase */
export class SchedulingApiHolidaysLabels extends SchedulingApiHolidaysLabelsBase {

	/**
	 * Check if there are labels with the same name
	 */
	public checkLabelsWithSameName() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_LABELS, fn: (_control) => {
			const labelNames = this.iterable().filter(label => {
				if (label.aiName.value === null) return false;
				if (label.name.trim() === '') return false;
				return true;
			}).map(label => label.name.toLowerCase());
			const uniqueLabelNames = new Set(labelNames);
			const hasLabelsWithSameName = labelNames.length !== uniqueLabelNames.size;

			if (!hasLabelsWithSameName) return null;

			return { [PPossibleErrorNames.DUPLICATE_LABELS]: {
				name: PPossibleErrorNames.DUPLICATE_LABELS,
				type: PApiType.ApiList,
			}};
		}});
	}

}

/** @see SchedulingApiLeaveBase */
export class SchedulingApiLeave extends SchedulingApiLeaveBase {

	public isHovered : boolean = false;

	/**
	 * The member to which this leave belongs.
	 */
	public get member() : SchedulingApiMember | null {
		return this.api.data.members.get(this.memberId);
	}

	/**
	 * The start of the leave. This value is calculated based on the existing leave-day items.
	 */
	public get start() : DateTime {
		return this.leaveDays.get(0)!.start;
	}

	/**
	 * The end of the leave. This value is calculated based on the existing leave-day items.
	 */
	public get end() : DateExclusiveEnd {
		return this.leaveDays.last!.end;
	}

	/**
	 * Get the leave days present in this leave sorted accordingly to the given type.
	 * The sorting criteria are:
	 * 1. The amount of days on which the type occurs
	 * 2. The amount of total hours of the type
	 * 3. The different options of the type
	 * @param type The type to sort the leave days by
	 * @returns a map containing the sorted leave days by the given type
	 */
	public sortedMappedLeaveDaysByType(type : 'type' | 'paymentType' | 'durationType') : Map<SchedulingApiLeaveDayType | SchedulingApiLeaveDayPaymentType | SchedulingApiLeaveDayDurationType, SchedulingApiLeaveDays> {
		return this.leaveDays.sortedMapByType(type);
	}

	/**
	 * Get the leave days present in this leave sorted accordingly to the given type.
	 * The sorting criteria are:
	 * 1. The amount of days on which the type occurs
	 * 2. The amount of total hours of the type
	 * 3. The different options of the type
	 *
	 * @param type The type to sort the leave days by
	 */
	public sortedLeaveDaysByType(type : 'type' | 'paymentType' | 'durationType') : SchedulingApiLeaveDays {
		const sortedMap = this.sortedMappedLeaveDaysByType(type);
		const returnList = new SchedulingApiLeaveDays(this.api, null, true);
		for (const item of sortedMap.entries()) {
			returnList.pushItems(item[1]);
		}
		return returnList;
	}

	/**
	 * Payroll duration
	 */
	public get duration() : number {
		let result = 0;
		for (const leaveDay of this.leaveDays.iterable()) {
			if (leaveDay.durationType === SchedulingApiLeaveDayDurationType.WHOLE_DAY && leaveDay.duration !== null)
				result += leaveDay.duration;
			else if (leaveDay.aiStartTime.isAvailable && leaveDay.aiEndTime.isAvailable && leaveDay.startTime !== null && leaveDay.endTime !== null)
				result += leaveDay.endTime - leaveDay.startTime;
		}
		return result;
	}

	/**
	 * @param min The leave time to be clamped by this min date-time. If `null` is passed here, instead the leave
	 * 	time is clamped by `this.start`.
	 * @param max The leave time to be clamped by this max date-time. If `null` is passed here, instead the leave
	 * 	time is clamped by `this.end`.
	 * @returns Payroll duration for this leave clamped by `min` and `max`.
	 */
	public durationBetween(min : DateTime | null = null, max : DateTime | null = null) : Duration {
		if (min === null && max === null) return this.duration;

		if (min === null)
			min = this.start;

		if (max === null)
			max = this.end;

		let returnDuration = 0;
		for (const leaveDay of this.leaveDays.iterable()) {
			if (leaveDay.start >= min && leaveDay.end <= max) {
				returnDuration += leaveDay.entireDuration;
			}
		}
		return returnDuration;
	}

	/**
	 * The shift-exchange items associated with this leave.
	 */
	public get relatedShiftExchanges() : SchedulingApiShiftExchanges {
		return this.api.data.shiftExchanges.filterBy((shiftExchange) => {
			return this.id.equals(shiftExchange.leaveId);
		});
	}

	/**
	 * Get calculated total earnings of a leave entry
	 */
	public get totalEarnings() : number {
		let result = 0;
		for (const leaveDay of this.leaveDays.iterable()) {
			if (leaveDay.paymentType === SchedulingApiLeaveDayPaymentType.PAID_WITH_HOURLY_WAGE)
				result += (leaveDay.hourlyWage ?? 0) * (leaveDay.duration ?? 0);
		}
		return result;
	}

	/**
	 * Get calculated total earnings of a leave entry between two timestamps
	 */
	public totalEarningsBetween(min : DateTime | null = null, max : DateTime | null = null) : number | null {
		const pMoment = new PMomentService(Config.LOCALE_ID);
		return this.leaveDays.filterBy(
			item =>
				(min === null || pMoment.m(item.start).startOf('day').valueOf() >= min) &&
				(max === null || pMoment.m(item.end).startOf('day').valueOf() <= max),
		).totalEarnings;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get totalDays() : number {
		return this.totalDaysBetween(this.start, this.end);
	}

	/**
	 * @param min The starting time from which to start counting days. If `null` is passed here, instead the starting
	 * 	time is clamped by `this.start`.
	 * @param max The ending time at which to stop counting days. If `null` is passed here, instead the ending time is
	 * 	clamped by `this.end`.
	 * @returns The amount of days between the given range
	 */
	public totalDaysBetween(min : DateTime | null = null, max : DateTime | null = null) : number {
		if (!this.rawData) throw new Error('SchedulingApiLeave.time is not defined [PLANO-19822]');
		const START = (min && min > this.start) ? min : this.start;
		const END = (max && max < this.end) ? max : this.end;

		const pMoment = new PMomentService(Config.LOCALE_ID);
		const startMoment = pMoment.m(END);
		const endMoment = pMoment.m(START);

		const days = startMoment.diff(endMoment, 'days', true);

		if (days < 0) return 0;

		return Math.round(days * 100) / 100;
	}
}

/** @see SchedulingApiLeavesBase */
export class SchedulingApiLeaves extends SchedulingApiLeavesBase {

	/**
	 * get leaves of day
	 * This includes al leaves that start at, end at oder happen during the provided day.
	 * @param dayStart - timestamp of the desired day
	 */
	public getByDay(dayStart : number) : this {
		Assertions.ensureIsDayStart(dayStart);

		const dayEnd = +(new PMomentService(Config.LOCALE_ID).m(dayStart).add(1, 'day'));
		Assertions.ensureIsDayStart(dayEnd);

		return this.filterBy(item => {
			if (dayStart >= item.end) { return false; }
			if (dayEnd <= item.start) { return false; }
			return true;
		});
	}

	/**
	 * Get sum of total earnings of all contained leaves
	 */
	public get totalEarnings() : number {
		let result : number = 0;
		for (const leave of this.iterable()) {
			result += leave.totalEarnings;
		}
		return result;
	}

	/**
	 * Get sum of partial earnings of all contained leaves
	 */
	public totalEarningsBetween(min : DateTime | null = null, max : DateTime | null = null) : number {
		let result : number = 0;
		for (const leave of this.iterable()) {
			const totalEarnings = leave.totalEarningsBetween(min, max);
			assumeNonNull(totalEarnings, 'totalEarnings');
			result += totalEarnings;
		}
		return result;
	}

	/**
	 * Sum of payroll durations
	 */
	public get duration() : number {
		let result : number = 0;
		for (const leave of this.iterable()) {
			result += leave.duration;
		}
		return result;
	}

	/**
	 * Sum of partial payroll durations
	 */
	public durationBetween(min : DateTime | null = null, max : DateTime | null = null) : Duration {
		let result : number = 0;
		for (const leave of this.iterable()) {
			result += leave.durationBetween(min, max);
		}
		return result;
	}

	/**
	 * Get leaves by Member in a new ListWrapper
	 */
	public getByMember( member : SchedulingApiMember ) : this {
		return this.filterBy(item => {
			if (!item.memberId.equals(member.id)) return false;
			return true;
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get commentAmount() : number {
		return this.filterBy(item => !!item.internalNotes?.length).length;
	}
}

/** @see SchedulingApiLeaveDaysBase */
export class SchedulingApiLeaveDays extends SchedulingApiLeaveDaysBase {

	/**
	 * Get the total earnings of all leave-days on the list.
	 */
	public get totalEarnings() : number {
		let result = 0;
		for (const leaveDay of this.iterable()) {
			if (leaveDay.paymentType === SchedulingApiLeaveDayPaymentType.PAID_WITH_HOURLY_WAGE) {
				if (leaveDay.durationType === SchedulingApiLeaveDayDurationType.WHOLE_DAY)
					result += (leaveDay.hourlyWage ?? 0) * PMomentService.d(leaveDay.duration).asHours();
				else if (leaveDay.aiStartTime.isAvailable && leaveDay.aiEndTime.isAvailable && leaveDay.startTime !== null && leaveDay.endTime !== null)
					result += (leaveDay.hourlyWage ?? 0) * PMomentService.d(leaveDay.endTime - leaveDay.startTime).asHours();
			}
		}
		return Math.round(result * 100) / 100;
	}

	/**
	 * Sum of leave days.
	 */
	public get sumOfLeaveDays() : number {
		return this.map(leaveDay => {
			switch (leaveDay.durationType) {
				case SchedulingApiLeaveDayDurationType.WHOLE_DAY:
					return 1;
				case SchedulingApiLeaveDayDurationType.HALF_DAY:
					return 0.5;
				case SchedulingApiLeaveDayDurationType.QUARTER_DAY:
					return 0.25;
				case SchedulingApiLeaveDayDurationType.THREE_QUARTER_DAY:
					return 0.75;
				default:
					return 0;
			}
		}).reduce((accumulator : number, value) => accumulator + value, 0);
	}

	/**
	 * Sum of leave days in hours.
	 */
	public get sumOfLeaveDaysInHours() : number {
		if (this.length === 0) return 0;
		return this.map(leaveDay => {
			if (leaveDay.aiDuration.isAvailable)
				return leaveDay.duration ?? 0;
			if (leaveDay.startTime !== null && leaveDay.endTime !== null)
				return (leaveDay.endTime - leaveDay.startTime);
			return 0;
		}).reduce((accumulator : number, value) => accumulator + value, 0);
	}

	/**
	 * Sort the leave days by the given type and return a map matching each different type to a list of leave days.
	 * @param type The type to sort the leave days by.
	 * @param [sortBy='amountOfLeaveDays'] The property to sort the leave days by first, it will sort by the total amount of leave days (calendar days) if nothings is passed,
	 * 	otherwise it will sort by the sum of the amount of days.
	 */
	public sortedMapByType(type : 'type' | 'durationType' | 'paymentType', sortBy : 'amountOfLeaveDays' | 'sumAmountOfDays' = 'amountOfLeaveDays') : Map<SchedulingApiLeaveDayType | SchedulingApiLeaveDayPaymentType | SchedulingApiLeaveDayDurationType, SchedulingApiLeaveDays> {
		const leaveDaysMap = new Map<SchedulingApiLeaveDayType | SchedulingApiLeaveDayPaymentType | SchedulingApiLeaveDayDurationType, SchedulingApiLeaveDays>();
		for (const leaveDay of this.iterable()) {
			let aiValue : SchedulingApiLeaveDayType | SchedulingApiLeaveDayPaymentType | SchedulingApiLeaveDayDurationType | null;
			switch (type) {
				case 'type':
					aiValue = leaveDay.aiType.value;
					break;
				case 'durationType':
					aiValue = leaveDay.aiDurationType.value;
					break;
				case 'paymentType':
					aiValue = leaveDay.aiPaymentType.value;
					break;
			}
			if (aiValue === null) continue;
			if (!leaveDaysMap.has(aiValue))
				leaveDaysMap.set(aiValue, new SchedulingApiLeaveDays(this.api, null, true));
			leaveDaysMap.get(aiValue)!.push(leaveDay);
		}
		const sortedMapArray = Array.from(leaveDaysMap.entries()).sort((a, b) => {

			// First sort by total amount of leave days or sum of the amount of days
			if (sortBy === 'amountOfLeaveDays' && b[1].length !== a[1].length) {
				return b[1].length - a[1].length;
			}
			if (sortBy === 'sumAmountOfDays' && b[1].sumOfLeaveDays !== a[1].sumOfLeaveDays) {
				return b[1].sumOfLeaveDays - a[1].sumOfLeaveDays;
			}

			// Sort by total hours
			if (b[1].sumOfLeaveDaysInHours !== a[1].sumOfLeaveDaysInHours) return b[1].sumOfLeaveDaysInHours - a[1].sumOfLeaveDaysInHours;

			// Sort by the different options of the type
			return b[0] - a[0];
		});

		const returnMap = new Map<SchedulingApiLeaveDayType | SchedulingApiLeaveDayPaymentType | SchedulingApiLeaveDayDurationType, SchedulingApiLeaveDays>();
		for (const item of sortedMapArray) {
			returnMap.set(item[0], item[1]);
		}
		return returnMap;
	}
}

/** @see SchedulingApiLeaveDayBase */
export class SchedulingApiLeaveDay extends SchedulingApiLeaveDayBase {
	/**
	 * Is the leave-day selected? This is used to edit leaves in the leave detail view.
	 */
	public selected = false;

	/**
	 * The start of the leave-day. If the leave-day provides {@link startTime} this will return the exact start-time. Otherwise it will return the start of the day.
	 */
	public get start() : DateTime {
		const pMoment = new PMomentService(Config.LOCALE_ID);
		if (this.aiStartTime.isAvailable && this.startTime !== null)
			return pMoment.getDateWithLocalTime(this.dayStart, this.startTime);
		else
			return this.dayStart;
	}

	/**
	 * The end of the leave-day. If the leave-day provides {@link endTime} this will return the exact end-time. Otherwise it will return the end of the day.
	 */
	public get end() : DateTime {
		const pMoment = new PMomentService(Config.LOCALE_ID);
		if (this.aiEndTime.isAvailable && this.endTime !== null)
			return pMoment.getDateWithLocalTime(this.dayStart, this.endTime);
		else
			return this.api.pMoment.m(this.dayStart).add(1, 'day').valueOf();
	}

	/**
	 * Get a icon name for the type
	 */
	public get typeIconName() : (
		typeof enumsObject.PlanoFaIconContextPool.ITEMS_LEAVE_ILLNESS |
		typeof enumsObject.PlanoFaIconContextPool.ITEMS_LEAVE_VACATION |
		'fa-ellipsis fa-duotone'
	) {
		return SchedulingApiLeaveDay.getTypeIconName(this.type);
	}

	/**
	 * Get the icon matching the type as a static method that takes the type as a parameter.
	 *
	 * @param type The type of the leave-day.
	 */
	public static getTypeIconName(type : SchedulingApiLeaveDayType) : PlanoFaIconPoolKeys {
		switch (type) {
			case SchedulingApiLeaveDayType.ILLNESS:
				return enumsObject.PlanoFaIconContextPool.ITEMS_LEAVE_ILLNESS;
			case SchedulingApiLeaveDayType.VACATION:
				return enumsObject.PlanoFaIconContextPool.ITEMS_LEAVE_VACATION;
			case SchedulingApiLeaveDayType.MISCELLANEOUS:
				return 'fa-ellipsis fa-duotone';
			case SchedulingApiLeaveDayType.COMPENSATORY_TIME_OFF:
				return 'fa-right-left fa-duotone';
			case SchedulingApiLeaveDayType.PUBLIC_HOLIDAY:
				return 'fa-section fa-solid';
		}
	}

	/**
	 * Get the icon matching the payment type as a static method that takes the type as a parameter.
	 *
	 * @param type The payment type of the leave-day.
	 */
	public static getPaymentTypeIconName(type : SchedulingApiLeaveDayPaymentType) : PlanoFaIconPoolKeys {
		switch (type) {
			case SchedulingApiLeaveDayPaymentType.PAID_WITH_HOURLY_WAGE:
				return 'fa-coins fa-solid';
			case SchedulingApiLeaveDayPaymentType.UNPAID:
				return 'fa-light-coins-slash fa-kit';
			case SchedulingApiLeaveDayPaymentType.PAID_WITHOUT_HOURLY_WAGE:
				return 'fa-coins fa-duotone';
		}
	}

	/**
	 * Get the label of the duration type linked to this leave day
	 */
	public get durationTypeLabel() : PDictionarySourceString {
		return SchedulingApiLeaveDay.getDurationLabel(this.durationType);
	}

	/**
	 * Get the label of the payment type linked to this leave day
	 */
	public get paymentTypeLabel() : PDictionarySourceString {
		return SchedulingApiLeaveDay.getPaymentTypeLabel(this.paymentType);
	}

	/**
	 * Get the payment type as human readable string
	 *
	 * @param type The payment type of the leave-day.
	 */
	public static getPaymentTypeLabel(type : SchedulingApiLeaveDayPaymentType) : PDictionarySourceString {
		switch (type) {
			case SchedulingApiLeaveDayPaymentType.PAID_WITH_HOURLY_WAGE:
				return 'Bezahlt mit Stundenlohn';
			case SchedulingApiLeaveDayPaymentType.UNPAID:
				return 'Unbezahlt';
			case SchedulingApiLeaveDayPaymentType.PAID_WITHOUT_HOURLY_WAGE:
				return 'Bezahlt ohne Stundenlohn';
		}
	}

	/**
	 * Get the icon for the duration type
	 *
	 * @param duration The duration type
	 */
	public static getDurationIconName(duration : SchedulingApiLeaveDayDurationType) : PlanoFaIconPoolKeys {
		switch (duration) {
			case SchedulingApiLeaveDayDurationType.WHOLE_DAY:
				return 'fa-circle fa-solid';
			case SchedulingApiLeaveDayDurationType.HALF_DAY:
				return 'fa-circle-half fa-duotone';
			case SchedulingApiLeaveDayDurationType.QUARTER_DAY:
				return 'fa-circle-quarter fa-duotone';
			case SchedulingApiLeaveDayDurationType.THREE_QUARTER_DAY:
				return 'fa-circle-three-quarters fa-duotone';
		}
	}

	/**
	 * Get the readable string for the duration type
	 */
	public static getDurationLabel(duration : SchedulingApiLeaveDayDurationType) : PDictionarySourceString {
		switch (duration) {
			case SchedulingApiLeaveDayDurationType.WHOLE_DAY:
				return 'Ganztägig';
			case SchedulingApiLeaveDayDurationType.HALF_DAY:
				// eslint-disable-next-line literal-blacklist/literal-blacklist -- need to set a class with normal quotes.
				return '<span class="font-diagonal-fractions">1/2 Tag</span>';
			case SchedulingApiLeaveDayDurationType.QUARTER_DAY:
				// eslint-disable-next-line literal-blacklist/literal-blacklist -- need to set a class with normal quotes.
				return '<span class="font-diagonal-fractions">1/4 Tag</span>';
			case SchedulingApiLeaveDayDurationType.THREE_QUARTER_DAY:
				// eslint-disable-next-line literal-blacklist/literal-blacklist -- need to set a class with normal quotes.
				return '<span class="font-diagonal-fractions">3/4 Tag</span>';
		}
	}

	/**
	 * Get the icon for the duration type
	 */
	public get durationIconName() : PlanoFaIconPoolKeys {
		return SchedulingApiLeaveDay.getDurationIconName(this.durationType);
	}

	/**
	 * Get a icon icon for the payment type
	 */
	public get paymentTypeIconName() : PlanoFaIconPoolKeys {
		return SchedulingApiLeaveDay.getPaymentTypeIconName(this.paymentType);
	}

	/**
	 * Get the type as human readable string
	 */
	public get title() : PDictionarySourceString {
		return SchedulingApiLeaveDay.getTypeTitle(this.type);
	}

	/**
	 * Get the type as human readable string to a given type
	 */
	public static getTypeTitle(type : SchedulingApiLeaveDayType) : PDictionarySourceString {
		switch (type) {
			case SchedulingApiLeaveDayType.ILLNESS:
				return 'Krankheit';
			case SchedulingApiLeaveDayType.VACATION:
				return 'Urlaub';
			case SchedulingApiLeaveDayType.MISCELLANEOUS:
				return 'Sonstiges';
			case SchedulingApiLeaveDayType.COMPENSATORY_TIME_OFF:
				return 'Freizeitausgleich';
			case SchedulingApiLeaveDayType.PUBLIC_HOLIDAY:
				return 'Gesetzlicher Feiertag';
		}
	}

	/**
	 * Get the entire duration of the leave day,
	 * if the duration is not available, the difference between the start and end time is returned.
	 */
	public get entireDuration() : Duration {
		if (this.aiDuration.isAvailable) return this.duration ?? 0;
		if (this.aiStartTime.isAvailable && this.aiEndTime.isAvailable)
			return (this.endTime ?? 0) - (this.startTime ?? 0);
		return 0;
	}
}

/** @see SchedulingApiMembersBase */
export class SchedulingApiMembers extends SchedulingApiMembersBase {
	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => !item.trashed);
	}

	/**
	 * Search these members by a given term.
	 * @param term The string to search for. Can be a string that a user typed into an input element.
	 */
	public search(term : Parameters<SchedulingApiMember['fitsSearch']>[0]) : this {
		return this.filterBy(item => item.fitsSearch(term));
	}
}

/** @see SchedulingApiAssignmentProcessBase */
export class SchedulingApiAssignmentProcess extends SchedulingApiAssignmentProcessBase {
	public collapsed : boolean = true;

	/**
	 * Check if there are any shifts selected which are related to this assignment process.
	 */
	public get relatedShiftsSelected() : boolean {
		return this.api.data.shifts.selectedItems.some(item => item.relatesTo(this));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public containsAnyShift(shifts : SchedulingApiShifts) : boolean {
		return !!this.shiftRefs.containsAnyShift(shifts);
	}

	/**
	 *  The number of shifts for which the user has to do something.
	 */
	public get todoShiftsCountTotal() : number {
		return (
			this.todoShiftsCountCurrentView +
			this.todoShiftsCountRightView +
			this.todoShiftsCountLeftView
		);
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignmentProcesses extends SchedulingApiAssignmentProcessesBase {
	/**
	 * Get the assignmentProcess where the provided shift is contained
	 */
	public getByShiftId(id : ShiftId) : SchedulingApiAssignmentProcess | null {
		for (const assignmentProcess of this.iterable()) {
			if (assignmentProcess.shiftRefs.contains(id)) {
				return assignmentProcess;
			}
		}
		return null;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public containsAnyShift(shifts : SchedulingApiShifts) : boolean {
		return this.some(item => item.containsAnyShift(shifts));
	}

	private getTotalOfNumberProperty(propertyName : keyof SchedulingApiAssignmentProcess) : number {
		const todoCountArray = this.iterable()
			.map(item => item[propertyName] as number);
		if (todoCountArray.length === 0) return 0;
		return todoCountArray.reduce((a, b) => a + b);
	}

	/**
	 * The number of shifts for which the user has to do something on the left side of current view. Read-only.
	 */
	public get todoShiftsCountLeftView() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountLeftView');
	}

	/**
	 * The number of shifts for which the user has to do something on the right side of current view. Read-only.
	 */
	public get todoShiftsCountRightView() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountRightView');
	}

	/**
	 * The number of shifts for which the user has to do something on the current view. Read-only.
	 */
	public get todoShiftsCountCurrentView() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountCurrentView');
	}

	/**
	 * The number of shifts for which the user has to do something in total. Read-only.
	 */
	public get todoShiftsCountTotal() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountTotal');
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiPermissionGroups extends SchedulingApiPermissionGroupsBase {
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAccountingPeriodExpectedMemberData
	extends SchedulingApiAccountingPeriodExpectedMemberDataBase {

	/**
	 * NOTE: getByMember() is faster
	 */
	public getByMemberId(item : Id) : SchedulingApiAccountingPeriodExpectedMemberDataItem | null {
		const member = this.api.data.members.get(item);
		return this.getByMember(notNull(member));
	}

	/**
	 * Gets by member. Doesn’t work if logged in user is not owner.
	 */
	public getByMember(item : SchedulingApiMember) : SchedulingApiAccountingPeriodExpectedMemberDataItem | null {
		return this.get(item.id);
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAccountingPeriod extends SchedulingApiAccountingPeriodBase {

}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAccountingPeriods extends SchedulingApiAccountingPeriodsBase {
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignableShiftModel extends SchedulingApiMemberAssignableShiftModelBase {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get shiftModel() : SchedulingApiShiftModel {
		return this.api.data.shiftModels.get(this.shiftModelId)!;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignableShiftModels extends SchedulingApiMemberAssignableShiftModelsBase {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getByShiftModel(input : SchedulingApiShiftModel) : SchedulingApiAssignableShiftModel | undefined {
		for (const assignableShiftModel of this.iterable()) {
			if (assignableShiftModel.shiftModelId.equals(input.id)) {
				return assignableShiftModel;
			}
		}
		return undefined;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get shiftModels() : SchedulingApiShiftModels {
		const result = new SchedulingApiShiftModels(this.api, null, true);
		for (const assignableShiftModel of this.iterable()) {
			const shiftModelToPush = assignableShiftModel.shiftModel;
			result.push(shiftModelToPush);
		}
		return result;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public containsShiftModel(item : SchedulingApiShiftModel) : boolean {
		return !!this.getByShiftModel(item);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public addNewShiftModel(
		shiftModel : SchedulingApiShiftModel,
		earning : number = 0,
	) : void {
		// NOTE: duplicate! This method exists here:
		// SchedulingApiAssignableShiftModels
		// SchedulingApiShiftModelAssignableMembers

		if (this.containsShiftModel(shiftModel)) return;

		const assignableShiftModel = this.createNewItem();
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		assignableShiftModel.hourlyEarnings = earning ? earning : 0;
		assignableShiftModel.shiftModelId = shiftModel.id;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public removeShiftModel(item : SchedulingApiShiftModel) : void {
		const assignableShiftModel = this.getByShiftModel(item);
		if (!assignableShiftModel) throw new Error('Could not get assignableShiftModel');
		this.removeItem(assignableShiftModel);
	}

}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiMemos extends SchedulingApiMemosBase {

	/**
	 * Get the Memo where the start is at the same day as the day of the provided timestamp
	 */
	public getByDay(dayStart : number) : SchedulingApiMemo | null {
		Assertions.ensureIsDayStart(dayStart);
		if (!dayStart) throw new Error('Can not get Memo. Timestamp is not defined.');

		for (const memo of this.iterable()) {
			// We assume that memo.start is start of day
			Assertions.ensureIsDayStart(memo.start);

			if (memo.start === dayStart) return memo;
		}
		return null;
	}

}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiTodaysShiftDescriptions extends SchedulingApiTodaysShiftDescriptionsBase {

}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiTodaysShiftDescription extends SchedulingApiTodaysShiftDescriptionBase {

	private _model : Data<SchedulingApiShiftModel | null> = new Data<SchedulingApiShiftModel>(this.api);

	/**
	 * shorthand that returns the related model
	 */
	public get model() : SchedulingApiShiftModel {
		// NOTE: This methods exists on multiple classes:
		// TimeStampApiShift
		// SchedulingApiShift
		// SchedulingApiBooking
		// SchedulingApiTodaysShiftDescription
		// SchedulingApiWorkingTime
		const SHIFT_MODEL = this._model.get(() => {
			return this.api.data.shiftModels.get(this.id.shiftModelId);
		});
		assumeNonNull(SHIFT_MODEL, 'SHIFT_MODEL');

		return SHIFT_MODEL;
	}

	/**
	 * Get the name based on the linked shiftModel
	 */
	public get name() : SchedulingApiShiftModel['name'] {
		// NOTE: This methods exists on multiple classes:
		// SchedulingApiRoot
		// TimeStampApiRoot
		return this.model.name;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isRequesterAssigned() : boolean {
		for (const assignedMemberId of this.assignedMemberIds.iterable()) {
			if (this.api.pPermissionsService.isMe(assignedMemberId))
				return true;
		}

		return false;
	}

	private _assignedMembers = new Data<SchedulingApiMembers>(this.api);

	/** @see SchedulingApiShiftModel.assignedMembers */
	public get assignedMembers() : SchedulingApiMembers {
		return this._assignedMembers.get(() => {
			return SchedulingApiShiftModel.assignedMembers(this.api, this.aiAssignedMemberIds);
		});
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiGiftCards extends SchedulingApiGiftCardsBase {
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiGiftCard extends SchedulingApiGiftCardBase {

	/**
	 * Setting this to `true` will activate min/max validators for `expirationDate` depending on `status`.
	 */
	public forceExpirationDateBeAlignedWithStatus = false;

	/**
	 * @returns All transactions belonging to this giftCard.
	 */
	public get transactions() : SchedulingApiTransactions {

		// TODO: PLANO-156519
		if (!this.api.data.aiTransactions.isAvailable) return new SchedulingApiTransactions(this.api, null, true, false);
		return this.api.data.transactions.filterBy(item => this.id.equals(item.giftCardId));
	}

	/**
	 * The full name of the booking person. If the name is not known `-` is returned.
	 */
	public get bookingPersonName() : string {
		if (!this.firstName && !this.lastName)
			return '–';

		return `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
	}

	/**
	 * Does the shopper need to pay {@link price}?
	 */
	public get shopperNeedsToPayPrice() : boolean {
		return this.status === SchedulingApiGiftCardStatus.BOOKED || this.status === SchedulingApiGiftCardStatus.EXPIRED;
	}

	/**
	 * How much needs to be paid for this gift-card overall. This value is independent of how much has been paid already
	 * (i.e. `currentlyPaid`).
	 */
	public get amountToPay() : ClientCurrency | null {
		if (
			// eslint-disable-next-line unicorn/prefer-number-properties -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			isNaN(this.cancellationFee) ||
			this.cancellationFee < 0
		) return null;
		let amountToPay = this.shopperNeedsToPayPrice ? this.price : 0;
		amountToPay += this.cancellationFee;
		return amountToPay;
	}

	/**
	 * Overall amount which can be refunded.
	 */
	public get refundableAmount() : ClientCurrency {
		return this.currentlyPaid;
	}

	/**
	 * Should we show the user that the current gift-card value is unknown?
	 * We dont show it when the user explicitly chose to ignore that warning.
	 */
	public get visualizeCurrentValueUnknown() : boolean {
		return !this.isCurrentValueKnown &&
			(!this.aiOverrideWaysToRedeem.isAvailable || this.overrideWaysToRedeem !== SchedulingApiGiftCardOverrideWaysToRedeem.IGNORE_WARNINGS);
	}

	/**
	 * @see SchedulingApiBookingBase#currentlyPaidWithoutLatestCreatedTransaction
	 */
	public get currentlyPaidWithoutLatestCreatedTransaction() : ClientCurrency {
		return SchedulingApiBookable.calculateCurrentlyPaidWithoutLatestCreatedTransaction(this, super.currentlyPaid);
	}

	/**
	 * @see SchedulingApiBookingBase#currentlyPaid
	 */
	public override get currentlyPaid() : ClientCurrency {
		return SchedulingApiBookable.calculateCurrentlyPaid(this, super.currentlyPaid);
	}

	/**
	 * @see SchedulingApiBookable.getOpenAmount
	 */
	public getOpenAmount(currentlyPaid : ClientCurrency | null = null) : ClientCurrency | null {
		return SchedulingApiBookable.getOpenAmount(this, currentlyPaid);
	}

	/** @see SchedulingApiBookable.paymentStatus */
	public get paymentStatus() : PPaymentStatusEnum | null {
		return SchedulingApiBookable.paymentStatus(this);
	}

	/**
	 * Is this gift-card canceled?
	 */
	public get isCanceled() : boolean {
		return this.status === SchedulingApiGiftCardStatus.CANCELED;
	}

	/**
	 * Was the gift-card created via a marketing gift-card?
	 */
	public get isMarketingGiftCard() : boolean {
		return this.createdBy === SchedulingApiBookableCreatedBy.MARKETING_GIFT_CARD;
	}

	/**
	 * Is the gift-card a refunded gift-card?
	 */
	public get isRefundGiftCard() : boolean {
		return this.createdBy === SchedulingApiBookableCreatedBy.MANUAL_REFUND_VIA_GIFT_CARD ||
			this.createdBy === SchedulingApiBookableCreatedBy.ONLINE_CANCELLATION_REFUND_VIA_GIFT_CARD;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- TODO: PLANO-167746 remove this
	public get state() : SchedulingApiGiftCardStatus { return this.status; }
	public set state(value : SchedulingApiGiftCardStatus) { this.status = value; }
}
