/* eslint max-lines: ["error", 2100] -- A lot of JS stuff is happening in this file which takes a lot of lines */
/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { AfterViewInit, ContentChild, Directive, ElementRef, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { PSimpleChanges } from '@plano/shared/api';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assume, assumeNonNull, NonUndefined } from '@plano/shared/core/utils/null-type-utils';
import { BOOTSTRAP_PADDING_CLASSES_REGEX } from '@plano/shared/core/utils/regex-utils';
import { typeUtils } from '@plano/shared/core/utils/typescript-utils';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { NgxFloatUiContentComponent, NgxFloatUiOptions, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { Subject, Subscription } from 'rxjs';

const documentObserver = new MutationObserver(() => {
	documentChanged.next();
});

let documentObserverConnected = false;

const documentChanged = new Subject<void>();

/**
 * Directive to mark an element as the reference object for a tooltip.
 * This way the tooltip will be placed relative to this element.
 */
@Directive({
	selector: '[pTooltipReferenceObject]',
	exportAs: 'pTooltipReferenceObject',
	standalone: true,
})
export class PTooltipReferenceObjectDirective extends PBaseClass {
	constructor(
		public elementRef : ElementRef<HTMLElement>,
	) {
		super();
	}
}

/**
 * Directive that adds a tooltip to an element.
 * The tooltip can be a simple string or a {@link TemplateRef}.
 * The tooltip can be shown on hover or it’s visibility gets decided by a bound boolean value.
 *
 * @example
 * ```html
 * <button pTooltip="This is a string, shown as a tooltip">Hover me</button>
 * ```
 *
 * ```html
 * <button pTooltip="pTooltipTemplateRef">Hover me</button>
 * <ng-template #pTooltipTemplateRef>
 *  <div class="p-2">
 * 		<span>This is the content of a <strong>ng-template</strong>.<br>
 *   	It can contain html but also angular components. <fa-icon [icon]="['fas', 'thumb-up']" /></span>
 * 	</div>
 * </ng-template>
 * ```
 */
@Directive({
	selector: '[pTooltip], [pTooltipOnOverflow], [pTooltipOnHide]',
	exportAs: 'pTooltip',
	standalone: true,
})
export class PTooltipDirective extends PBaseClass implements OnDestroy, OnChanges, AfterViewInit, OnInit {
	/**
	 * String, html element or template ref that should be shown for this tooltip.
	 * If you pass a string, the tooltip will have some default padding.
	 * If you pass a TemplateRef or HTMLElement, you have to take care of the padding.
	 */
	@Input() public pTooltip : string | TemplateRef<unknown> | HTMLElement | null = null;

	/**
	 * Property that tells this directive to create a tooltip from the content of the element if the content of the
	 * element is cropped and if a tooltip should be shown in that case.
	 * Note that this is not related to cropping mechanism of the tooltip content itself.
	 */
	@Input() private pTooltipOnOverflow = false;

	/**
	 * On most cases, we can't to separate the tooltips nodes by a space, however, if we use
	 * multiple nodes to write one single string that might not make sense, in such cases,
	 * this boolean can be set to false to prevent the default behavior.
	 */
	@Input() private pTooltipSeparateNodesBySpace = true;

	/**
	 * Property that tells this directive to create a tooltip from the content of the element if the content of the
	 * element is hidden.
	 */
	@Input() private pTooltipOnHide = false;

	/**
	 * Headline of the content of this tooltip.
	 */
	@Input() public pTooltipHeadline : string | TemplateRef<unknown> | null = null;

	/**
	 * Default placement for the tooltip. It takes a member of NgxFloatUiPlacements, to access it
	 * in the component templates we need to make sure that NgxFloatUiPlacements is declared in the component.
	 *
	 * To do so, add:
	 *
	 * public NgxFloatUiPlacements = NgxFloatUiPlacements;
	 *
	 * And make sure that NgxFloatUiPlacements gets imported.
	 */
	@Input() public pTooltipPlacement : NgxFloatUiPlacements = NgxFloatUiPlacements.TOP;

	/**
	 * By default the tooltip will close immediately after the mouse left, but if necessary we should set
	 * this boolean to true to make it hoverable.
	 */
	@Input() public pTooltipHover : boolean = true;

	/**
	 * Tooltip theme. By default dark
	 */
	@Input() public pTooltipTheme : PThemeEnum | null = this.enums.PThemeEnum.DARK;

	/**
	 * Tooltip border theme.
	 */
	@Input() private pTooltipBorderTheme : PThemeEnum | null = null;

	/**
	 * Additional styles for the tooltip.
	 * Gets added to the wrapper element, which also adds the card styles.
	 */
	@Input() private pTooltipWrapperStyle : string = '';

	/**
	 * Maximum width of the tooltip.
	 * @default 250px
	 */
	@Input() private pTooltipMaxWidth : `${number}px` | `${number}ch` = '250px';

	/**
	 * Minimum width of the tooltip.
	 * @default 0px
	 */
	@Input() private pTooltipMinWidth : `${number}px` | `${number}ch` = '0px';

	/**
	 * Shadow of the tooltip
	 */
	@Input() private pTooltipShadow : boolean = false;

	/**
	 * Trigger for the tooltip, by default hover
	 * If set to 'custom', use pTooltipShow to define if the tooltip is visible or not.
	 */
	@Input({ alias: 'pTooltipTrigger' }) protected _pTooltipTrigger : NgxFloatUiTriggers.hover | 'custom' | null = null;

	/**
	 * Should the tooltip be shown? Use together with tooltip trigger 'custom'
	 */
	@Input() public pTooltipShow : boolean | null = null;

	/**
	 * Color of the arrow for the tooltip
	 */
	@Input() public pTooltipArrowColor : string | null = null;

	/**
	 * Should the arrow be behind the tooltip?
	 */
	@Input() public pTooltipArrowBehind : boolean = false;

	/**
	 * Should the tooltip be appended to the body? By default, yes
	 */
	@Input() public pTooltipAppendToBody : boolean = true;

	/**
	 * Custom class to be added to the float-ui element
	 */
	@Input() public pTooltipFloatUIElementClass : string | null = null;

	/**
	 * Is the tooltip always opened?
	 */
	@Input() public pTooltipAlwaysOpened : boolean = false;

	/**
	 * Should the tooltip have position fixed?
	 */
	@Input() public pTooltipPositionedFixed : boolean = true;

	/**
	 * Boundaries element selector for the tooltip.
	 */
	@Input() public pTooltipBoundariesElementSelector : string | null = null;

	/**
	 * Should the tooltip prevent overflow?
	 */
	@Input() public pPreventOverflow : boolean = true;

	@ContentChild(PTooltipReferenceObjectDirective) private pTooltipReferenceObject ?: PTooltipReferenceObjectDirective;

	constructor(
		protected elementRef : ElementRef<HTMLElement>,
		protected viewContainerRef : ViewContainerRef,
		protected injector : Injector,
		protected zone : NgZone,
		private console : LogService,
		private localize : LocalizePipe,
	) {
		super();

		if (!documentObserverConnected) {
			documentObserver.observe(document.body, {childList: true, subtree: true});
			documentObserverConnected = true;
		}

		this.zone.runOutsideAngular(() => {
			this.documentChangedSubscription = documentChanged.subscribe(() => {
				if (!this.floatUiContent?.referenceObject.isConnected) {
					this.eraseTooltip();
				}
			});
		});
	}

	private documentChangedSubscription : Subscription | null = null;

	private get pTooltipTrigger() : NgxFloatUiTriggers.hover | 'custom' {
		if (this._pTooltipTrigger !== null) return this._pTooltipTrigger;
		return NgxFloatUiTriggers.hover;
	}

	private handlePTooltipAttributeChange(changes : NonUndefined<PSimpleChanges<PTooltipDirective>['pTooltip']>) : void {
		if (changes.currentValue === null) {
			this.eraseTooltip();
		} else if (changes.currentValue !== changes.previousValue && this.floatUiHtmlElement) {
			const wasHidden = this.hidden;
			this.hideFloatUiElement(this.floatUiHtmlElement);
			this.eraseTooltip();
			if (!wasHidden)
				this.handleMouseEnter();
		}
	}

	private handlePTooltipShowAttributeChange(currentValue : PTooltipDirective['pTooltipShow']) : void {
		this.pTooltipShow = currentValue;
		if (this.pTooltipShow) {
			this.createTooltipIfNeeded();
			this.displayTooltip();
		} else {
			if (this.floatUiHtmlElement)
				this.hideFloatUiElement(this.floatUiHtmlElement);
		}
	}

	public ngOnChanges(changes : PSimpleChanges<PTooltipDirective>) : void {
		if ('pTooltip' in changes && !changes.pTooltip.isFirstChange() && changes.pTooltip.previousValue !== changes.pTooltip.currentValue) {
			this.handlePTooltipAttributeChange(changes.pTooltip);
		}

		if (changes.pTooltipTheme) {
			this.handlePTooltipAttributeChange(changes.pTooltipTheme);
		}

		if ('pTooltipShow' in changes && changes.pTooltipShow.previousValue !== changes.pTooltipShow.currentValue) {
			this.handlePTooltipShowAttributeChange(changes.pTooltipShow.currentValue);
		}
	}

	public ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.validateValues();
		if (this.pTooltipTrigger === NgxFloatUiTriggers.hover) {
			this.setMouseListeners(this.elementRef.nativeElement);
		}
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	private isInStorybookPage = false;

	public ngOnInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.isInStorybookPage = window.location.href.includes('storybook');

		if (this.pTooltipAlwaysOpened) {
			this._pTooltipTrigger = 'custom';
			this.pTooltipShow = true;
			this.pTooltipPositionedFixed = false;
			this.handlePTooltipShowAttributeChange(this.pTooltipShow);
		}
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	/**
	 * Timeout to hide the tooltip
	 */
	private hideTimeout : number | null = null;

	/**
	 * Timeout to trigger interaction with the tooltip.
	 * If interaction A happens, but there is some potential that interaction B will follow soon, then we can set the
	 * interactionTimeout in interaction A and stop that timeout in interaction B, if B got triggered.
	 */
	private interactionTimeout : number | null = null;

	/**
	 * Is the tooltip hidden?
	 * This stores the directive-internal state. If you want to modify the visibility of the tooltip, use {@link PTooltipDirective#pTooltipShow}.
	 */
	public hidden : boolean = true;

	/**
	 * FloatUi component created with the viewRef of where it was instantiated.
	 */
	private floatUiContent : NgxFloatUiContentComponent | null = null;

	/**
	 * Native element of the floatUiContent.
	 * This is the element the card styles will be added to.
	 */
	private floatUiHtmlElement : HTMLElement | null = null;

	/**
	 * Element that will be added as a child of the floatUiHtmlElement,
	 * this element is the one that contains the content of the tooltip itself.
	 *
	 * It creates the element based on the pTooltip.
	 */
	private tooltipHtmlElement ! : HTMLElement;

	public Config = Config;

	private createTooltipHeadlineElement() : HTMLElement {
		assumeNonNull(this.pTooltipHeadline);
		let headlineElement ! : HTMLElement;
		if (this.pTooltipHeadline instanceof TemplateRef) {
			headlineElement = document.createElement('div');
			const view = this.viewContainerRef.createEmbeddedView(this.pTooltipHeadline);
			headlineElement.append(...view.rootNodes);
		} else {
			headlineElement = document.createElement('h6');
			headlineElement.append(this.pTooltipHeadline);
			headlineElement.classList.add('fw-semibold');
		}
		return headlineElement;
	}

	/**
	 * Method responsible for creating an HTMLElement given only a string.
	 *
	 * @param text String received via props in this directive
	 * @returns An HTMLElement that contains the string
	 */
	private createCardForString(text : string) : HTMLElement {
		const wrapperCard : HTMLDivElement = document.createElement('div');
		wrapperCard.style.cssText = this.pTooltipWrapperStyle;
		wrapperCard.classList.add('d-block', 'm-0', 'overflow-hidden', 'card');
		const cardBodyElement : HTMLDivElement = document.createElement('div');
		cardBodyElement.classList.add('card-body', 'p-2', 'text-wrap');

		if (this.pTooltipHeadline !== null) {
			const headlineElement = this.createTooltipHeadlineElement();
			cardBodyElement.append(headlineElement);
		}

		// The provided text might contain html, so we need to create a div to hold it
		const contentElement : HTMLDivElement = document.createElement('div');
		contentElement.innerHTML = text;
		cardBodyElement.append(contentElement);

		wrapperCard.append(cardBodyElement);
		return wrapperCard;
	}

	private displayTooltip() {
		if (!this.hidden) return;

		this.zone.runOutsideAngular(() => {
			if (this.floatUiHtmlElement === null) return null;
			if (this.floatUiContent === null) return null;

			// update tooltip styles
			this.floatUiHtmlElement.ariaHidden = 'false';
			this.floatUiContent.floatUiOptions.styles = this.floatUiOptionsStyles;

			// append to document
			if (this.pTooltipAppendToBody)
				document.body.appendChild(this.floatUiHtmlElement);
			else
				this.elementRef.nativeElement.parentElement!.appendChild(this.floatUiHtmlElement);

			// show the tooltip
			// Both show() and handleTooltipCropping() require layouting information. So, execute them on an animation frame.
			window.requestAnimationFrame(() => {
				// As this happens in an animation frame, the tooltip trigger might have been removed in the meantime, for example due to navigation.
				// If we don't check this, it is possible to have tooltips dangling around.
				if (!this.floatUiContent?.referenceObject.isConnected) return;
				this.floatUiContent.show();
				this.hidden = false;
				this.handleTooltipCropping();
			});
		});
	}

	private get floatUiOptionsStyles() : NonUndefined<NgxFloatUiOptions['styles']> {
		return {
			// eslint-disable-next-line @typescript-eslint/naming-convention -- Not defined by us.
			'max-width': this.pTooltipMaxWidth,

			// We always want it to use the maximum intrinsic size of the content
			width: 'max-content',

			// eslint-disable-next-line @typescript-eslint/naming-convention -- Not defined by us.
			'min-width': this.pTooltipMinWidth,
		};
	}

	private hideFloatUiElement(floatUiHtmlElement : HTMLElement) : void {
		if (this.hidden) return;

		floatUiHtmlElement.ariaHidden = 'true';
		floatUiHtmlElement.remove();
		if (this.floatUiContent)
			this.floatUiContent.hide();
		this.hidden = true;
	}

	/**
	 * Time to wait before triggering the change of state of the tooltip (hidden or not)
	 */
	private TRIGGER_TOOLTIP_TIMEOUT = 300;

	private croppedContentTooltipNeeded(target : HTMLElement) : boolean {
		if (this.pTooltip !== null && !this.createdPTooltipContentFromTextContent) return false;
		if (target.scrollWidth > target.offsetWidth || target.scrollHeight > target.offsetHeight) {
			return true;
		}
		return false;
	}

	private IGNORED_ELEMENTS = ['fa-icon', 'p-led'];

	private hiddenContentTooltipNeeded(target : HTMLElement) : boolean {
		if (this.pTooltip !== null && !this.createdPTooltipContentFromTextContent) return false;

		for (const child of target.children) {
			if (this.IGNORED_ELEMENTS.includes(child.tagName.toLowerCase()))
				continue;
			if (window.getComputedStyle(child).display === 'none') {
				return true;
			}
			if (this.hiddenContentTooltipNeeded(child as HTMLElement)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * @param event Mouse enter event. null if it got called from code instead of a user event.
	 */
	private handleMouseEnter(event : Event | null = null) : void {
		this.zone.runOutsideAngular(() => {
			// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
			if (!Config.IS_MOBILE) {

				let targetElement : HTMLElement | null = null;
				if (event) {

					targetElement = event.target as HTMLElement;

					// targetElement is the tooltip?
					const isHoveringTooltip = targetElement.tagName === 'FLOAT-UI-CONTENT';

					// If the user moved from trigger to tooltip, and there was a gap between those elements, then the user would
					// have no chance to hover the tooltip as it would immediately close after leaving the trigger. To prevent
					// this, we stop the hiding process on tooltip hover.
					if (isHoveringTooltip) {
						this.clearHideTimeout();
						return;
					}

					if (

						/*
						 * Even elements that should cropOnOverflow can have a provided tooltip content which explains the value.
						 * In that case, even if the text is cropped, we prioritize the provided tooltip content over the cropped
						 * text.
						 */
						this.pTooltip === null &&
						this.pTooltipOnOverflow &&
						!this.croppedContentTooltipNeeded(targetElement)
					) return;

					if (this.pTooltip === null && this.pTooltipOnHide && !this.hiddenContentTooltipNeeded(targetElement)) {
						return;
					}
				}

				// needed to update the tooltip, as the html content of the element could have changed in the meantime
				const tooltipNotNeededAnymore = this.resetTooltipsFromTextContent(targetElement);
				if (tooltipNotNeededAnymore) return;

				// time to wait before displaying the tooltip
				this.clearHideTimeout();

				this.interactionTimeout = window.setTimeout(() => {
					this.createTooltipIfNeeded();
					this.displayTooltip();
					this.floatUiHtmlElement!.style.opacity = '100%';

					this.clearInteractionTimeout();
				}, this.TRIGGER_TOOLTIP_TIMEOUT);
			}
		});

	}

	/**
	 * Will check if the tooltips are still needed, and return the correct value.
	 *
	 * @param targetElement The element that should be checked for the need of a tooltip
	 */
	private resetTooltipsFromTextContent(targetElement : HTMLElement | null) : boolean {
		if (this.pTooltip !== null && this.createdPTooltipContentFromTextContent) {
			this.eraseTooltip();
			if (this.pTooltipOnOverflow && targetElement && !this.croppedContentTooltipNeeded(targetElement))
				return true;
			if (this.pTooltipOnHide && targetElement && !this.hiddenContentTooltipNeeded(targetElement))
				return true;
		}
		return false;
	}

	private clearHideTimeout() {
		if (this.hideTimeout !== null) {
			window.clearTimeout(this.hideTimeout);
			this.hideTimeout = null;
		}
	}

	private clearInteractionTimeout() {
		if (this.interactionTimeout) {
			window.clearTimeout(this.interactionTimeout);
			this.interactionTimeout = null;
		}
	}

	/**
	 * Hide the tooltip.
	 */
	public hide() : void {
		this.hideFloatUiElement(this.floatUiHtmlElement!);
	}

	private handleMouseLeave() : void {
		this.zone.runOutsideAngular(() => {
			this.clearInteractionTimeout();
			// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
			if (!Config.IS_MOBILE && this.floatUiHtmlElement) {
				const floatUi = this.floatUiHtmlElement;
				this.hideTimeout = window.setTimeout(() => {
					floatUi.style.opacity = '0%';
					this.hideFloatUiElement(floatUi);
					this.clearHideTimeout();
				}, this.TRIGGER_TOOLTIP_TIMEOUT);

			}
		}) ;

	}

	private bindHandleMouseLeave = this.handleMouseLeave.bind(this);
	private bindHandleMouseEnter = this.handleMouseEnter.bind(this);

	/**
	 * Check if there is an existing floatUi
	 */
	protected hasFloatUi() : boolean {
		return this.floatUiContent !== null;
	}

	/**
	 * Add the required classes to the arrow element to change its color
	 */
	private addArrowElementColor(arrowHtmlElement : HTMLElement) : void {
		arrowHtmlElement.classList.add('has-color');
		if (this.pTooltipArrowColor) arrowHtmlElement.style.backgroundColor = `${this.pTooltipArrowColor}`;
		else arrowHtmlElement.classList.add(`bg-${this.pTooltipTheme}`);
		arrowHtmlElement.style.zIndex = this.pTooltipArrowBehind ? '-1' : '1';

		if (this.pTooltipBorderTheme !== null) {
			this.tooltipHtmlElement.classList.add(`border-${this.pTooltipBorderTheme}`);
		} else if (this.pTooltipTheme === this.enums.PThemeEnum.DARK && !(this.pTooltip instanceof HTMLElement)) {
			// We had cases where classList and style was not defined.
			// This is not important enough to throw in such cases. So we added a try/catch.
			try {
				// TODO: PLANO-159385 remove this and use the default border color if we decide to change
				this.tooltipHtmlElement.classList.add('border');
				this.tooltipHtmlElement.style.setProperty('border-color','gray', 'important');
			} catch (error) {
				this.console.error('tooltipHtmlElement is not an html element?', error);
			}
		} else {
			this.tooltipHtmlElement.classList.add('border-0');
		}
	}

	private stripInVisibleNodes(element : Element | Text) : string | null {
		// Comment nodes do not have content
		if (element.nodeType === Node.COMMENT_NODE) return null;

		// Test elements cant have children, so we can directly return the text here.
		if (typeUtils.isTypeTextNode(element)) return element.textContent?.trim() ?? null;

		// If the element is one of the ignored ones or a child of it, we want to ignore it as id does not have content that we want to show in the tooltip.
		for (const ignoredElement of this.IGNORED_ELEMENTS) {
			if (

				// Check if is one of the ignored elements
				element.tagName.toLowerCase() === ignoredElement ||

				// Check if is a child of one of the ignored elements
				element.parentElement?.closest(ignoredElement)

			) return null;
		}

		// If an element is hidden from the user, we dont need to show it's content in the tooltip, unless pTooltipOnHide is set
		const style = window.getComputedStyle(element);
		if (style.display === 'none' && !this.pTooltipOnHide) {
			return null;
		}

		if (element.textContent === null) return null;

		return this.pTooltipSeparateNodesBySpace ? element.textContent.trim() : element.textContent;
	}

	/**
	 * Take a node and get it's visible content as text.
	 * @param node The node to get the text content from
	 */
	private getTextContextFromNode(node : HTMLElement) : string | null {
		const result : string[] = [];
		if (this.stripInVisibleNodes(node) === null) return null;
		if (node.childNodes.length > 0) {
			for (const child of [...node.childNodes] as HTMLElement[]) {
				result.push(this.getTextContextFromNode(child) ?? '');
			}
		} else {
			result.push(this.stripInVisibleNodes(node) ?? '');
		}
		if (result.length > 0) {
			if (this.pTooltipSeparateNodesBySpace) {
				return result.join(' ');
			}
			return result.join('');
		}
		return null;
	}

	/**
	 * Flag to store the information if the content of this tooltip got created from the text content of the element
	 * that triggered the tooltip.
	 */
	private createdPTooltipContentFromTextContent = false;

	private createTemplateBasedTooltip(input : TemplateRef<unknown>) : HTMLElement {
		let result : HTMLElement;

		/**
		 * We use the viewContainerRef of the element on which we want to add the tooltip and create a view
		 * using the template ref. This needs to be done this way so we can use the context inside the viewContainerRef.
		 * For example, it makes sure that when we use template variables in the template ref, those will be linked to the
		 * ones defined in the html file where this directive was called.
		 */
		const viewWithContext = this.viewContainerRef.createEmbeddedView(input, null, {injector: this.injector});

		// Need to detect changes on the view of the tooltip when creating it, to ensure it has the correct state
		// It is possible that the tooltip hasn't updated yet, and on this step the template would be empty
		viewWithContext.detectChanges();

		// Validate content of the tooltip
		const hasNodeWithCardClass = viewWithContext.rootNodes.some((node) => node.classList?.contains('card'));
		if (hasNodeWithCardClass) {
			this.console.error(`The tooltip directive will take care of the card class, please remove it from the template. ClassList: »${viewWithContext.rootNodes[0].classList}«`);
		}

		// in case the tooltip has a ng-container we need to create a wrapping element to hold the tooltip
		// in such cases the first root node was empty
		if (viewWithContext.rootNodes.length === 1 && viewWithContext.rootNodes[0].nodeName === '#comment') {
			const wrapperElement = document.createElement('span');
			const rootNodes : Node[] = viewWithContext.rootNodes;
			wrapperElement.append(...rootNodes);
			result = wrapperElement;

			// some padding when template root node was empty
			result.classList.add('p-2');
		} else {
			if (viewWithContext.rootNodes.length === 1) {
				result = viewWithContext.rootNodes[0];
			} else {
				const wrapperElement = document.createElement('div');
				const rootNodes : Node[] = viewWithContext.rootNodes;
				wrapperElement.append(...rootNodes);
				result = wrapperElement;
			}
		}

		if (this.pTooltipHeadline !== null) {
			const headlineElement = this.createTooltipHeadlineElement();
			result.prepend(headlineElement);
		}

		return result;
	}

	private createFloatUiComponent() : NgxFloatUiContentComponent {

		return this.zone.runOutsideAngular(() => {
			// Create floatUiContent component instance
			const result = this.viewContainerRef.createComponent(NgxFloatUiContentComponent, {injector:this.injector}).instance;

			// Attach content to the new floatUiContent
			result.referenceObject = this.pTooltipReferenceObject ? this.pTooltipReferenceObject.elementRef.nativeElement : this.elementRef.nativeElement;

			// Start hidden
			result.hide();

			// Default floatUi options
			result.floatUiOptions = {
				...result.floatUiOptions,
				placement: this.pTooltipPlacement,
				disableAnimation: true,
				positionFixed: this.pTooltipPositionedFixed,

				// Don't want the tooltips jumping around for the screenshots, so we disable the overflow prevention on storybook
				preventOverflow: this.isInStorybookPage ? false : this.pPreventOverflow,
				trigger: NgxFloatUiTriggers.none,
			};

			if (this.pTooltipBoundariesElementSelector)
				result.floatUiOptions.boundariesElement = this.pTooltipBoundariesElementSelector;

			return result;
		});

	}

	private createFloatUiHtmlElement(input : NgxFloatUiContentComponent) : HTMLElement {
		const result = input.elRef.nativeElement as HTMLElement;

		if (this.pTooltipTrigger === NgxFloatUiTriggers.hover) {
			// Add the transition to the tooltip so we can change the opacity on mouse leave
			result.style.transition = `opacity ${this.TRIGGER_TOOLTIP_TIMEOUT}ms`;
			result.style.opacity = '0%';
		}

		return result;
	}

	/**
	 * Create the tooltip DOM element without attaching it to the document.
	 * Once the tooltip was created, the DOM element will not be deleted, so this method will only create the tooltip
	 * when it is called for the first time.
	 */
	protected createTooltipIfNeeded() : void {
		if (this.floatUiHtmlElement !== null)
			return;

		if (this.pTooltip === null || this.createdPTooltipContentFromTextContent) {
			if (this.pTooltipOnOverflow || this.pTooltipOnHide) {
				this.pTooltip = this.getTextContextFromNode(this.elementRef.nativeElement);
				this.createdPTooltipContentFromTextContent = true;
			}
			if (this.pTooltip === null) return;
		}

		if (this.pTooltip instanceof TemplateRef) {
			const templateToHtml = this.createTemplateBasedTooltip(this.pTooltip);
			this.tooltipHtmlElement = templateToHtml;
		} else if (typeof this.pTooltip === 'string') {
			const stringToHtml = this.createCardForString(this.pTooltip);
			this.tooltipHtmlElement = stringToHtml;
		} else {
			this.tooltipHtmlElement = this.pTooltip;
		}

		this.floatUiContent = this.createFloatUiComponent();
		this.floatUiHtmlElement = this.createFloatUiHtmlElement(this.floatUiContent);
		this.handleThemeAndAppendHTMLToTooltipContent(this.floatUiHtmlElement);

		// For flaky reasons in percy screenshots, we want to hide all tooltips which are triggered by hover, if they are not always opened.
		if (this.pTooltipTrigger === NgxFloatUiTriggers.hover && !this.pTooltipAlwaysOpened) {
			this.floatUiHtmlElement.classList.add('hide-in-percy');
		}

		const floatUIContainer = this.floatUiHtmlElement.firstChild as HTMLElement;

		// Set or overwrite some of the styles but keep the existing ones
		for (const style of Object.entries(this.floatUiOptionsStyles)) {
			floatUIContainer.style.setProperty(style[0], style[1]);
		}

		if (this.pTooltipShadow) {
			const cardElement = this.floatUiHtmlElement.querySelector('.card')!;
			cardElement.classList.add('shadow-lg');
		}

		if (this.pTooltipTheme !== this.enums.PThemeEnum.LIGHT) {
			const arrowHtmlElement : HTMLElement | null = this.floatUiHtmlElement.querySelector('.float-ui-arrow');
			if (arrowHtmlElement) {
				this.addArrowElementColor(arrowHtmlElement);
			}
		}
	}

	private showMoreOptionElement : HTMLElement | null = null;

	private createMoreLink() : HTMLAnchorElement {
		const moreLink = document.createElement('a');

		/*
		 * Playwright’s .getByRole('link') doesn’t work on a tags that don’t have a href attribute.
		 * It works when we explicitly set the role to link, though.
		 */
		moreLink.role = 'link';
		moreLink.className = 'link';
		moreLink.tabIndex = 0;

		const icon = this.enums.PlanoFaIconContextPool.NAV_FORWARD;
		const moreLinkIcon = document.createElement('span');
		moreLinkIcon.classList.add(...icon.split(' '), 'ms-1');
		const ariaLabelSpan = document.createElement('span');
		ariaLabelSpan.hidden = true;
		ariaLabelSpan.append(FaIconComponent.generateFaIconAriaLabel(icon));
		moreLinkIcon.append(ariaLabelSpan);

		const moreLinkText = document.createElement('a');
		moreLinkText.append(this.localize.transform('Alles anzeigen'));

		moreLink.append(moreLinkText);
		moreLink.append(moreLinkIcon);

		moreLink.addEventListener('click', async (event) => {
			event.preventDefault();
			const modalService = this.injector.get(ModalService);

			assume(!(this.pTooltip instanceof HTMLElement), '!(this.pTooltip instanceof HTMLElement)', 'The tooltips with HTML content should never be cropped.');

			// After the modal got opened, the templateRef of the tooltip got rendered to that modal.
			// So we need to make sure it will be re-rendered into the tooltip next time.
			this.eraseTooltip();

			await modalService.openDefaultModal({
				modalTitle: this.pTooltipHeadline,
				description: this.pTooltip,
				dismissBtnLabel: this.localize.transform('Schließen'),
			}, {
				theme: this.pTooltipTheme,

				// Class to prevent that the modal body has paddings when it contains a tooltip
				windowClass: 'p-tooltip-show-more-modal',
			}).result;
		});

		return moreLink;
	}

	private getSpacingPaddingClassesFromElement(element : Element) : string[] | null {
		return element.classList.value.match(BOOTSTRAP_PADDING_CLASSES_REGEX) ?? null;
	}

	/**
	 * Create the html for a 'show more' option.
	 */
	private createShowMoreOptionElement() : HTMLElement {
		const cardElement = this.floatUiHtmlElement!.querySelector('.card')!;

		const result = document.createElement('div');
		result.classList.add('show-more-option', 'rounded-lg');

		// If the card has a padding class, apply the same padding to the show more option.
		const paddingClasses = this.getSpacingPaddingClassesFromElement(cardElement);
		if (paddingClasses === null) {
			result.classList.add('p-2');
		} else {
			for (const paddingClass of paddingClasses) {
				result.classList.add(paddingClass);
			}
		}

		// Add more padding at the top so we can style it to make the content underneath fade out.
		result.style.setProperty('padding-top', '4rem', 'important');

		const cardStyles = window.getComputedStyle(cardElement);

		// Apply the same background color as the card. But make it fade in from the top.
		const backgroundColor = cardStyles.backgroundColor;
		result.style.background = `linear-gradient(to bottom, transparent 0%, ${backgroundColor} 4rem)`;

		// Create the more link
		const moreLink = this.createMoreLink();

		// Apply the same color as the card to the link
		const color = cardStyles.color;
		moreLink.style.color = color;

		// Apply the same font-size as the card to the link
		const fontSize = cardStyles.fontSize;
		moreLink.style.fontSize = fontSize;

		// Attach the 'show more' option
		result.append(moreLink);

		const wrappingShowMoreOption = document.createElement('div');

		wrappingShowMoreOption.classList.add('show-more-option-wrapper');

		wrappingShowMoreOption.append(result);

		return wrappingShowMoreOption;
	}

	/**
	 * Sometimes the content of a tooltip gets cropped, because of a limitation of height.
	 */
	private handleTooltipCropping() {
		// We don't need to create a new 'show more' option if there is already one.
		if (this.showMoreOptionElement) return;
		this.showMoreOptionElement = this.createShowMoreOptionElement();
		const cardElementParent = this.tooltipHtmlElement.parentElement!;
		cardElementParent.append(this.showMoreOptionElement);
	}

	// TODO: https://drplano.atlassian.net/browse/PLANO-184826
	private handleThemeAndAppendHTMLToTooltipContent(floatUiHtmlElement : HTMLElement) : void {
		const innerFloatUiHtmlElement = floatUiHtmlElement.querySelector<HTMLElement>('.ngxp__inner')!;

		// We had cases where classList and style was not defined.
		// This is not important enough to throw in such cases. So we added a try/catch.
		try {
			// Force the tooltip to have the same color as the one defined in the body.
			// We might change this if we restyle the tooltip.
			if (this.pTooltip instanceof HTMLElement) {
				floatUiHtmlElement.classList.add('p-html-tooltip');
			} else {
				this.wrapContentIntoCardElement(floatUiHtmlElement);
			}

			if (this.pTooltipFloatUIElementClass !== null) {
				floatUiHtmlElement.classList.add(this.pTooltipFloatUIElementClass);
			}

			innerFloatUiHtmlElement.appendChild(this.tooltipHtmlElement);

			if (this.pTooltipHover && this.pTooltipTrigger === NgxFloatUiTriggers.hover) {
				this.setMouseListeners(floatUiHtmlElement);
			}

			floatUiHtmlElement.remove();
		} catch (error) {
			this.console.error('tooltipHtmlElement is not an html element?', error);
		}
	}

	private wrapContentIntoCardElement(
		floatUiHtmlElement : HTMLElement,
	) : void {
		this.tooltipHtmlElement.classList.add('d-block', 'card');
		this.tooltipHtmlElement.style.cssText = this.pTooltipWrapperStyle;
		if (this.pTooltipTheme !== null) this.tooltipHtmlElement.classList.add(`bg-${this.pTooltipTheme}`);

		// set the font-size to small (will take the parents font-size as reference)
		this.tooltipHtmlElement.classList.add('small');

		// Add class to change width (defined in global-styles)
		floatUiHtmlElement.classList.add('p-tooltip');

		const floatUiElementContent = floatUiHtmlElement.childNodes[0] as HTMLElement;

		// cSpell:ignore describedby
		this.elementRef.nativeElement.setAttribute('aria-describedby', floatUiElementContent.id);
	}

	/**
	 * Reload the tooltip
	 */
	protected reloadTooltip() : void {
		if (this.hasFloatUi()) {
			this.floatUiContent!.update();
		}
	}

	/**
	 * Erase the tooltip and remove the event listeners
	 */
	protected eraseTooltip() : void {
		if (this.floatUiContent) {
			if (this.showMoreOptionElement !== null) {
				this.showMoreOptionElement.remove();
				this.showMoreOptionElement = null;
			}

			if (this.floatUiHtmlElement) {
				this.floatUiHtmlElement.remove();
				this.floatUiHtmlElement = null;
			}

			if (!this.pTooltipHover) {
				this.removeMouseListeners(this.elementRef.nativeElement);
			}

			this.floatUiContent = null;
		}
		this.hidden = true;
	}

	public ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.eraseTooltip();
		this.documentChangedSubscription?.unsubscribe();
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	private validateValues() : void {
		if (this.pTooltipTrigger === 'custom' && this.pTooltipShow === null) {
			throw new Error('No value provided to pTooltipShow even though pTooltipTrigger is set to custom!');
		}
	}

	private setMouseListeners(htmlElement : HTMLElement) : void {
		this.zone.runOutsideAngular(() => {
			htmlElement.addEventListener('mouseenter', this.bindHandleMouseEnter);
			htmlElement.addEventListener('mouseleave', this.bindHandleMouseLeave);
		});
	}

	private removeMouseListeners(htmlElement : HTMLElement) : void {
		this.zone.runOutsideAngular(() => {
			htmlElement.removeEventListener('mouseenter', this.bindHandleMouseEnter);
			htmlElement.removeEventListener('mouseleave', this.bindHandleMouseLeave);
		});
	}
}
