/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PSeverity } from '@plano/global-error-handler/error-utils';
import { PCalloutComponent } from '@plano/shared/core/component/p-callout/p-callout.component';
import { ExternalLinkDirective } from '@plano/shared/core/directive/external-link.directive';
import { MeService } from '@plano/shared/core/me/me.service';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { SafeHtmlPipe } from '@plano/shared/core/pipe/safe-html.pipe';
import { PSentryService } from '@plano/shared/sentry/sentry.service';

/**
 * The modal content that shows up when a error happens to the user.
 * Can be used in the global error handler to handle throws.
 */
@Component({
	selector: 'p-error-modal-content',
	templateUrl: './error-modal-content.component.html',
	styleUrls: ['./error-modal-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [
		PCommonModule,
		FormsModule,
		PCalloutComponent,
		SafeHtmlPipe,
		ExternalLinkDirective,
	],
})
export class PErrorModalContentComponent {
	/**
	 * The value of the textarea of the modal.
	 */
	@Input() public userMessage : string = '';

	/*
	 * TODO: Currently there is sometimes a problem with modal not hiding the page navigation so
	 * you can navigate somewhere else after error modal opened. This will report us a wrong href where
	 * error happened. To do a hotfix we save the href on modal open.
	 */
	constructor(
		private injector : Injector,
		private pSentryService : PSentryService,
		private localize : LocalizePipe,
	) {
	}

	protected readonly PSeverity = PSeverity;

	protected sentryIsBlocked : boolean | null = null;

	public errorObj ! : Error;
	public USER_MESSAGE_LENGTH_LIMIT = 180;

	protected severityLevel = PSeverity.FATAL;

	/**
	 * Set up the modal content and send the error to our error-tracking service.
	 * @param error Error that triggert the modal
	 * @param severity The severity level of error
	 */
	public async initModal(
		error : Error,
		severity : PSeverity.FATAL | PSeverity.WARNING | null = null,
	) : ReturnType<PSentryService['handleError']> {
		this.sentryIsBlocked = this.pSentryService.sentryIsBlocked();
		if (severity !== null) this.severityLevel = severity;

		this.errorObj = error;
		if (this.errorObj.name === 'Error' && this.errorObj.message) this.errorObj.name = `${this.errorObj.name}: ${this.errorObj.message}`;

		const me = this.injector.get<MeService>(MeService);
		return await this.pSentryService.handleError(this.errorObj, me, undefined, { level: this.severityLevel, extra: {error: this.errorObj} });
	}

	/**
	 * Try to set up and send a message to our error-tracking service.
	 * If it does not work, just continue, or if the user has written a message, ask him to send us the error message
	 * via support chat.
	 */
	private async sendMessageToSentryIfPossible() : Promise<void> {
		try {
			const me = this.injector.get<MeService>(MeService);
			await this.pSentryService.handleError(this.errorObj, me, this.userMessage, { level: this.severityLevel, extra: {error: this.errorObj} });
		} catch {
			if (this.userMessage) {
				window.prompt('Leider konnte deine Nachricht nicht verschickt werden.\nBitte kopiere die unten stehende Fehlermeldung und melde dich damit in unserem Support-Chat. Danke ♥\n\nSorry, your message could not be sent.\nPlease copy the error message below and report it via our support chat. Thank you ♥', `Error: ${this.errorObj.message}`);
			}
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public async onClose() : Promise<void> {
		await this.sendMessageToSentryIfPossible();
		window.location.reload();
	}

	/**
	 * Subject for error modal email when sentry is blocked
	 */
	public get errorModalEmailSubject() : string {
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return encodeURI(this.localize.transform('Ich hatte einen Fehler in Dr. Plano'));
	}

	/**
	 * Body for error modal email when sentry is blocked
	 */
	public errorModalEmailBody(error : string) : string {
		return encodeURI(this.localize.transform({sourceString: 'Folgende Fehlermeldung wurde mir angezeigt: ${error}',params: {error}}));
	}

	/**
	 * Get some color if user comes close or is at length limit
	 * @param length Length of user message
	 */
	public lengthLimitState(length : number) : 'danger' | 'warning' | null {
		if (!length) return null;
		if (length >= this.USER_MESSAGE_LENGTH_LIMIT) return 'danger';
		if (length >= (this.USER_MESSAGE_LENGTH_LIMIT / 100 * 70)) return 'warning';
		return null;
	}

	/** Turn all html line-breaks into <br> */
	protected backslashNToBR(text : string | undefined) : string | undefined {
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return text?.replace(/\r\n|\r|\n/g, '<br>↳&nbsp;&nbsp;');
	}
}
