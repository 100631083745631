/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
// cSpell:ignore kolkov
import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Input, NgZone, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextToHtmlService } from '@plano/client/scheduling/shared/text-to-html.service';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { LogService } from '@plano/shared/core/log.service';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { Subscription } from 'rxjs';

type ValueType = string | null;

/**
 * <p-textarea> extends <textarea> with all the options for pEditables.
 * @example with PFormControl binding
 * 	<form [formGroup]="myFormGroup">
 * 		<p-textarea
 * 			[formControl]="myFormGroup.get('lastName')"
 * 		></p-textarea>
 * 	</form>
 * @example with model binding
 * 	<p-textarea
 * 		[(ngModel)]="member.lastName"
 * 	></p-textarea>
 * @example as editable
 * 	<form [formGroup]="myFormGroup">
 * 		<p-textarea
 * 			[pEditable]="!member.isNewItem"
 * 			[api]="api"
 */
@Component({
	selector: 'p-textarea',
	templateUrl: './p-textarea.component.html',
	styleUrls: ['./p-textarea.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PTextareaComponent),
			multi: true,
		},
	],
})
export class PTextareaComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, AfterContentInit, EditableControlInterface, PFormControlComponentInterface {

	@ViewChild('pEditableRef') protected pEditableRef ?: EditableDirective;

	@Input('disabled') public override set disabled(input : boolean) {
		this.setDisabledState(input);
		this._disabled = input;
		super.disabled = input;
	}
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}

	/**
	 * Parent editable to be used for this textarea, we can't use the injection on the constructor
	 * as sometimes it might get lost if we use ng-content.
	 */
	@Input() protected parentEditable ?: EditableDirective;

	@Input('formControl') public override control : PFormControl | null = null;

	@HostBinding('class.flex-grow-1')
	protected readonly _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public placeholder : string | null = null;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public focusout = new EventEmitter<FocusEvent>();
	// eslint-disable-next-line @angular-eslint/no-output-native -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	@Output() public focus = new EventEmitter<FocusEvent>();

	/**
	 * Visual size of this component.
	 * Can be useful if you have few space in a button-bar or want to have large buttons on mobile.
	 */
	@Input() public size : PFormControlComponentInterface['size'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();

	// eslint-disable-next-line @angular-eslint/no-output-native -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	@Output() public blur = new EventEmitter<FocusEvent>();

	/** Event emitter for change event. */
	// eslint-disable-next-line @angular-eslint/no-output-native -- We do this for all our inputs
	@Output() protected change = new EventEmitter<Event>();

	constructor(
		protected override console : LogService,
		protected override pFormsService : PFormsService,
		private textToHtmlService : TextToHtmlService,
		private zone : NgZone,
	) {
		super(false, pFormsService, console);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get valueAsHtml() : string {
		if (!this.value) return '–';
		return this.textToHtmlService.textToHtml(this.value, false, false);
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.addListenerOnParentEditable();
		if (this.cannotSetHint !== null) throw new Error('cannotSetHint not implemented yet in this component.');
		return super.ngAfterContentInit();
	}

	private subscriptions : Subscription[] = [];

	/**
	 * If the parentEditable emits the beforeApiChanges event, we need to ensure that the value is trimmed for this input,
	 * if this input is not editable.
	 */
	private addListenerOnParentEditable() : void {
		if (!this.pEditable && this.parentEditable) {
			this.subscriptions.push(this.parentEditable.beforeApiChanges.subscribe(() => {
				this.ensureTrimmedValue();
			}));
		}
	}

	/**
	 * Trim the value if the editable directive says that nothing changed, but the value could still contain trailing or leading whitespaces
	 * that should be removed
	 */
	protected ensureTrimmedValue() : void {
		if (typeof this.value === 'string') {
			const trimmedValue = this.value.trim();
			if (trimmedValue === '') {
				this.value = null;
			} else if (this.value !== trimmedValue) {
				this.value = trimmedValue;
			}
		}
	}

	public _disabled : boolean = false;

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to set this if you want to use [(ngModel)] AND [formControl] together.
	 */

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	private _value : ValueType = null;
	public override _onChange : (value : ValueType) => void = () => {};

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void {
		const newValue = (event.target as HTMLTextAreaElement).value;
		if (this._value !== newValue) this._onChange(newValue);
		this.keyup.emit(event);
	}

	/** Get blur event from inside this component, and pass it on. */
	public onBlur(event : FocusEvent) : void {
		this.onTouched(event);
		this.blur.emit(event);
	}

	/**
	 * Get change event from inside this component, and pass it on.
	 * @param event The event that triggered the change
	 */
	public onChange(event : Event) : void {
		this._onChange((event.target as HTMLTextAreaElement).value);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = (_event : Event) : void => {};

	/** the value of this control */
	public get value() : ValueType { return this._value; }
	public set value(value : ValueType) {
		if (this._value === value) return;

		this._value = value;

		this._onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		this._onChange = (value : any) => {
			const newValue = value === '' ? null : value;
			this.value = newValue;
			fn(value);
		};
	}

	// eslint-disable-next-line jsdoc/require-param -- False-positive. Interface defines the params.
	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	// eslint-disable-next-line jsdoc/require-param -- False-positive. Interface defines the params.
	/** @see ControlValueAccessor#setDisabledState */
	public setDisabledState(isDisabled : boolean) : void {
		if (this._disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this._disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			// make sure the formControl value is up-to-date with the AI value
			if (!this.disabled && this.attributeInfo) this.refreshValue();
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/** @see PFormsService.visibleErrors */
	public get visibleErrors() : VisibleErrorsType {
		return this.pFormsService.visibleErrors(this.control!);
	}
}
