<button *ngIf="dismissable" type="button" class="btn btn-frameless close float-end" data-dismiss="alert" title="Schließen" i18n-title
	(click)="onClose()"
>
	<fa-icon aria-hidden="true" [icon]="enums.PlanoFaIconContextPool.DISMISS" />
</button>
<fa-icon
	*ngIf="icon"
	class="me-2"
	[icon]="icon"
/> <ng-content />
