/* eslint max-lines: ["error", 200] -- It is expected that a core module has more lines than the average */
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PBadgeComponent } from '@plano/client/shared/component/p-badge/p-badge.component';
import { WarningsComponent } from '@plano/shared/core/component/alerts/warnings.component';
import { GridComponent } from '@plano/shared/core/component/grid/grid.component';
import { PPageHeadlineComponent } from '@plano/shared/core/component/icon-headline/page-headline.component';
import { PIconTooltipComponent } from '@plano/shared/core/component/icon-tooltip/icon-tooltip.component';
import { PNavBackHeadlineComponent } from '@plano/shared/core/component/nav-back-headline/p-nav-back-headline.component';
import { POnlinePaymentNotSupportedAlertComponent } from '@plano/shared/core/component/online-payment-not-supported-alert/online-payment-not-supported-alert.component';
import { PCalloutComponent } from '@plano/shared/core/component/p-callout/p-callout.component';
import { AnchorInCollapsibleDirective } from '@plano/shared/core/component/p-collapsible/p-anchor-in-collapsible.directive';
import { PCollapsibleComponent } from '@plano/shared/core/component/p-collapsible/p-collapsible.component';
import { PDebugComponent } from '@plano/shared/core/component/p-debug/p-debug.component';
import { PHeadlineComponent } from '@plano/shared/core/component/p-headline/p-headline.component';
import { PNavBackAnchorComponent } from '@plano/shared/core/component/p-nav-back-anchor/nav-back-anchor.component';
import { PProgressComponent } from '@plano/shared/core/component/p-progress/p-progress.component';
import { PTodoComponent } from '@plano/shared/core/component/p-todo/p-todo.component';
import { PYoutubeComponent } from '@plano/shared/core/component/p-youtube/p-youtube.component';
import { SpinnerComponent } from '@plano/shared/core/component/spinner/spinner.component';
import { ValidationHintComponent } from '@plano/shared/core/component/validation-hint/validation-hint.component';
import { YouKnowWhatComponent } from '@plano/shared/core/component/you-know-what/you-know-what.component';
import { RouterStorageDirective } from '@plano/shared/core/directive/anchor-url-storage.directive';
import { PCloseOnEscDirective } from '@plano/shared/core/directive/close-on-esc.directive';
import { PAutoFocusInsideModalDirective } from '@plano/shared/core/p-auto-focus/p-auto-focus.directive';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { PModalModule } from '@plano/shared/core/p-modal/modal.module';
import { CorePipesModule } from '@plano/shared/core/pipe/core-pipes.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { NgProgressModule } from 'ngx-progressbar';

@NgModule({
	imports: [
		CorePipesModule,
		NgxFloatUiModule.forRoot({disableAnimation: true}),
		FaIconComponent,
		FormsModule,
		GridComponent,
		NgProgressModule,
		PAutoFocusInsideModalDirective,
		PBadgeComponent,
		PCalloutComponent,
		PCloseOnEscDirective,
		PCollapsibleComponent,
		PCommonModule,
		PDebugComponent,
		PHeadlineComponent,
		PIconTooltipComponent,
		PModalModule,
		PNavBackHeadlineComponent,
		PPageHeadlineComponent,
		RouterStorageDirective,
		SpinnerComponent,
		TooltipModule,
		ValidationHintComponent,
		YouKnowWhatComponent,
		// eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		AnchorInCollapsibleDirective,
		PTodoComponent,
	],
	declarations: [
		PNavBackAnchorComponent,
		POnlinePaymentNotSupportedAlertComponent,
		PProgressComponent,
		PYoutubeComponent,
		WarningsComponent, // TODO: This component includes MeService. Move to another Module, to make CoreComponentsModule Api-free. Alternative: Get SchedulingApiWarnings without bootstrapping our whole api-code.
	],
	providers: [
		GridComponent,
		PCalloutComponent,
		PIconTooltipComponent,
	],
	exports: [
		GridComponent,
		NgxFloatUiModule,
		PAutoFocusInsideModalDirective,
		PBadgeComponent,
		PCalloutComponent,
		PCollapsibleComponent,
		PDebugComponent,
		PHeadlineComponent,
		PIconTooltipComponent,
		PNavBackAnchorComponent,
		PNavBackHeadlineComponent,
		POnlinePaymentNotSupportedAlertComponent,
		PPageHeadlineComponent,
		PProgressComponent,
		PTodoComponent,
		PYoutubeComponent,
		SpinnerComponent,
		ValidationHintComponent,
		WarningsComponent,
		// eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		AnchorInCollapsibleDirective,
	],
})
export class CoreComponentsModule {}
