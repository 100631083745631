/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { ApiDataWrapperBase, ApiLoadArgs, ApiSaveArgs } from '@plano/shared/api';
import { ApiBase } from '@plano/shared/api/base/api-base/api-base';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { IdBase } from '@plano/shared/api/base/id/id-base';
import { Meta } from '@plano/shared/api/base/meta';
import { Config } from '@plano/shared/core/config';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

/**
 * Params to be used when creating a new item.
 */
export interface ApiObjectWrapperNewItemParams<T extends ApiObjectWrapper<any>> {

	/**
	 * Use this to initialize values which are needed by attribute-info logic.
	 */
	initCode : ((item : T) => void) | null;

	/**
	 * Set a specific backend id raw-data. Normally, you dont want to set a value here as backends sets the id.
	 */
	backendIdRaw : any;
}

/**
 * Params to be used when a wrapper for existing raw-data should be created.
 */
export interface ApiObjectWrapperExistingRawDataParams {

	/**
	 * The complete raw-data of the object.
	 */
	rawData : any[];
}

/**
 * A wrapper class for api object data.
 */
export abstract class ApiObjectWrapper<T extends ApiObjectWrapper<any>> extends ApiDataWrapperBase {

	/**
	 * Constructor to be used when creating a new item.
	 */
	constructor(
		api : ApiBase,
		parent : ApiDataWrapperBase | null,
		private implementationConstructor: (new (_api : ApiBase) => T),
	) {
		super(api, parent);
	}

	/**
	 * NOTE: This is only for internal usage. It should not be used directly.
	 *
	 * Disables the attribute-info checks in a setter.
	 */
	public static _disableSetterChecks = false;

	/**
	 * NOTE: Only for internal usage.
	 *
	 * Initializes an object-wrapper. This is implemented in a separate method (instead in the constructor) to be
	 * able to execute this after all child attributes of the extending class have been initialized.
	 */
	protected _initObjectWrapper(params : ApiObjectWrapperNewItemParams<T> | ApiObjectWrapperExistingRawDataParams, atomic : boolean) : void {
		// creating a wrapper for existing raw-data?
		if ('rawData' in params) {
			this._updateRawData(params.rawData, false);
		} else {
			// otherwise creating a new item.

			// set some basic raw-data so "initCode" can be executed on it
			this.data = Meta.createNewObject(atomic, params.backendIdRaw, this.aiThis.nodeName);

			// initCode
			if (params.initCode) {
				ApiObjectWrapper._disableSetterChecks = true;
				params.initCode(this as unknown as T);
				ApiObjectWrapper._disableSetterChecks = false;
			}

			// generate missing data
			this._updateRawData(this.rawData, true);
		}
	}

	/**
	 * Initializes the raw-data of all children of this data-wrapper if the previous value was `undefined`.
	 * Note, that this is only done when the value should be available. I.e. when `attributeInfo.show` is true.
	 */
	protected generateMissingData(data : any[]) : void {
		// iterate all child attribute-infos.
		// eslint-disable-next-line no-restricted-syntax -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		for (const propertyName in this) {
			if (propertyName.startsWith('ai') && propertyName !== 'aiThis') {
				const attributeInfo = this[propertyName] as unknown as ApiAttributeInfo<ApiDataWrapperBase, any>;
				const rawDataIndex = attributeInfo.rawDataIndex;

				if (rawDataIndex && data[rawDataIndex] === undefined && attributeInfo.isAvailable)
					data[rawDataIndex] = attributeInfo.defaultValue;
			}
		}
	}

	/**
	 * @returns Is this a newly created item?
	 */
	public override get isNewItem() : boolean {
		// Note that we explicitly use the id (instead of the newItemId) to check if an item is new because
		// newItemId is not resetted after the item is saved. See ApiListWrapper._updateRawData() for more details.
		return this.data && Meta.getBackendId(this.data) === undefined;
	}

	/**
	 * @returns The new-item-id of this object.
	 */
	public get newItemId() : number | null {
		return this.data[0][3] ?? null;
	}

	/**
	 * @returns a scroll fragment for this object. It will always return the newItemId, if available,
	 * to ensure the HTML id of the element remains as newItemId instead of changing to backendId if
	 * the load occurs before the scrollTo functionality starts.
	 */
	public get scrollToFragment() : string | null {
		if (this.newItemId !== null)
			return this.newItemId.toString();
		return this.id?.toString() ?? null;
	}

	/**
	 * currently this is equivalent to api.save().
	 */
	public async saveDetailed({success = null, error = null} : ApiSaveArgs = {}) : Promise<HttpResponse<unknown>> {
		return this.api.save({success: success, error: error});
	}

	/**
	 * Loads the api data with a detailed view of this node.
	 */
	public async loadDetailed({success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(this.api, notNull(this.id), this.dni, { success: success, error: error, searchParams: searchParams});
	}

	/**
	 * Implementation for loading a detailed view of this object.
	 */
	protected static async loadDetailedImpl(
		api : ApiBase,
		id : IdBase<any>,
		dni : string,
		{success = null, error = null, searchParams = null} : ApiLoadArgs = {},
	) : Promise<HttpResponse<unknown>> {
		searchParams = searchParams ?? new HttpParams();

		searchParams = searchParams
			.set('dni', dni)
			.set('di', id.toString());

		return await api.load({
			success: (response : HttpResponse<unknown>) => {
				if (success)
					success(response);
			},
			error: (response : HttpErrorResponse) => {
				api.data._updateRawData(null, false);

				if (error)
					error(response);
			},
			searchParams: searchParams,
		});
	}

	/**
	 * Creates a copy of this item. The new item will have new-item ids instead of the original ids.
	 * This method will automatically update any ID attributes in the copied object, referencing the replaced IDs, by the new value.
	 * @param doNotAddToIdReplacementListGetter See {@link Meta.replaceIdsByNewItemIds()}
	 * @returns The new copied item. Note that if the original item was a list-item, the copied item will not automatically be added to the list.
	 * So, consider adding it manually yourself to the list.
	 */
	public copy(doNotAddToIdReplacementListGetter : ((data : any[]) => any[]) | null = null) : T {
		// copy data
		const dataCopy = structuredClone(this.data);
		const doNotAddToIdReplacementList = doNotAddToIdReplacementListGetter ? doNotAddToIdReplacementListGetter(dataCopy) : [];
		const idReplacements = Meta.replaceIdsByNewItemIds(dataCopy, doNotAddToIdReplacementList);

		// copy wrapper
		const newWrapper = new this.implementationConstructor(this.api);
		newWrapper._updateRawData(dataCopy, false);

		// fix id references
		newWrapper._fixIds(idReplacements);

		return newWrapper;
	}

	/**
	 * This method is called by all getters. It will perform some debug checks and validate if it is OK to call the
	 * getter at current point in time.
	 */
	protected getterDebugValidations(attributeInfo : ApiAttributeInfo<any, unknown>, disableErrorOnFailure : boolean) : void {
		// prevent access when isAvailable === false
		// TODO: PLANO-153699 Remove "Config.APPLICATION_MODE !== 'KARMA'"
		if (Config.DEBUG && Config.APPLICATION_MODE !== 'KARMA' && attributeInfo.isAvailable !== true) {
			const errorMessage = `You are accessing the attribute ${attributeInfo.id} although attributeInfo.isAvailable is equal ${attributeInfo.isAvailable}.
				Please see documentation of ApiAttributeInfo.isAvailable() for further details.`;

			if (disableErrorOnFailure) {
				this.api.console.warn(`${errorMessage} This error message has temporarily been disabled. Please enable it again. See https://drplano.atlassian.net/browse/PLANO-156512`);
			} else {
				this.api.console.error(errorMessage);
			}
		}
	}

	/**
	 * A runtime check if canSet is possible.
	 * @param testSetter If true, the check will be skipped.
	 * @param propertyName The name of the property which should be set.
	 */
	private validateCanSet(
		testSetter : boolean,
		propertyName : string,
	) : void {
		// validation
		// TODO: PLANO-153699 Remove "Config.APPLICATION_MODE !== 'KARMA'"
		const attributeInfo = this.getAttributeInfo(propertyName);

		if (
			Config.APPLICATION_MODE !== 'KARMA' &&
			!ApiObjectWrapper._disableSetterChecks &&
			!testSetter &&
			!this.api.isBackendOperationRunning &&
			!attributeInfo.canSet
		)
			throw new Error(`You are setting an attribute although canSet is ${attributeInfo.canSet}. Please make sure to check canSet. id: ${attributeInfo.id}`);
	}

	/**
	 * Helper method to implement setters.
	 */
	protected setterImpl(
		rawDataIndex : number,
		newValue : any,
		propertyName : string,
		testSetter : boolean,
		preCode : ((this : T) => void) | null,
		postCode : ((this : T) => void) | null,
	) : void {
		this.validateCanSet(testSetter, propertyName);

		// Execute
		if (newValue instanceof IdBase)
			newValue = newValue.rawData;

		if (newValue !== this.data[rawDataIndex]) {
			if (preCode !== null)
				preCode.call(this as any as T);

			this.data[rawDataIndex] = newValue;

			if (postCode !== null)
				postCode.call(this as any as T);

			this.api.changed(propertyName);
		}
	}
}
