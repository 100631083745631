import { SchedulingApiMailSentToBookingPersonBase, SchedulingApiMember } from '@plano/shared/api';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiMailSentToBookingPerson extends SchedulingApiMailSentToBookingPersonBase {

	/**
	 * If this mail is a manually triggered resend then this returns the member who requester the resend.
	 */
	public get resendRequester() : SchedulingApiMember | null {
		return this.api.data.members.get(this.resendRequesterId) ?? null;
	}
}
