import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { isColorfulTheme, PBtnTheme, PTextColorEnum } from '@plano/client/shared/bootstrap.utils';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { PCloseOnEscDirective } from '@plano/shared/core/directive/close-on-esc.directive';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { LogService } from '@plano/shared/core/log.service';
import { FaIconComponentTheme } from '@plano/shared/core/p-common/fa-icon/fa-icon-component.types';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalContentWrapperDirective, PModalTemplateContext, PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { SafeHtmlPipe } from '@plano/shared/core/pipe/safe-html.pipe';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { BOOTSTRAP_PADDING_CLASSES_REGEX } from '@plano/shared/core/utils/regex-utils';
import { PButtonComponent } from '@plano/shared/p-forms/p-button/p-button.component';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import { Subscription } from 'rxjs';
import { isString } from 'underscore';

/**
 * A component to be used as a wrapper for the content of a modal.
 * Use it inside a {@link PModalContentComponent}.
 *
 * @example
 * ```html
 * <p-modal-content>
 * 	<p-modal-content-body>
 * 		Hello World
 * 	</p-modal-content-body>
 * </p-modal-content>
 */
@Component({
	selector: 'p-modal-content-body',
	template: '<ng-content />',
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		PCommonModule,
	],
})
export class PModalContentBodyComponent implements AfterContentInit {
	@HostBinding('class.d-block')
	protected readonly _alwaysTrue = true;

	constructor(
		private elementRef : ElementRef<HTMLElement>,
	) {}

	public ngAfterContentInit() : void {
		// Forbid the usage of bootstraps padding utility classes.
		if (Config.DEBUG && this.elementRef.nativeElement.className.match(BOOTSTRAP_PADDING_CLASSES_REGEX)) {
			throw new Error(`Please do not use padding-classes on <p-modal-content-body>. Set a [size] on <p-modal-content> instead.`);
		}
	}
}

/**
 * A component to be used as a wrapper for the footer of a modal.
 * Use it inside a {@link PModalContentComponent}.
 *
 * @example
 * ```html
 * <p-modal-content>
 * 	<p-modal-content-footer>
 * 		<button>OK</button>
 * 	</p-modal-content-footer>
 * </p-modal-content>
 */
@Component({
	selector: 'p-modal-content-footer',
	template: '<ng-content />',
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		PCommonModule,
	],
})
export class PModalContentFooterComponent {
	@HostBinding('class.modal-footer')
	@HostBinding('class.d-flex')
	protected readonly _alwaysTrue = true;
	@HostBinding('class.justify-content-between') private classJustifyContentBetween = true;
	@HostBinding('class.align-items-center') private classAlignItemsCenter = true;
}

/**
 * A component that includes usual modal content like a header and a footer with OK and cancel buttons etc.
 *
 * @example
 * 	<p-modal-content
 * 		(onDismiss)="dismiss($event)"
 * 		(onClose)="success($event)"
 * 	>
 * 		<p-modal-content-body>
 * 			Hello World
 * 		</p-modal-content-body>
 *  </p-modal-content>
 */
@Component({
	selector: 'p-modal-content',
	templateUrl: './p-modal-content.component.html',
	styleUrls: ['./p-modal-content.component.scss'],
	standalone: true,
	imports: [
		PCommonModule,
		PTooltipDirective,
		PCloseOnEscDirective,
		FaIconComponent,
		PButtonComponent,
		SafeHtmlPipe,
	],
})
export class PModalContentComponent extends PBaseClass implements AfterViewInit, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public modalBodyHeight : string | null = null;

	/**
	 * Overwrites the padding of the modal content.
	 */
	@Input() public size : (
		'frameless' |
		null
	) = null;

	@HostBinding('class.h-100')
	protected readonly _alwaysTrue = true;

	/** @see ModalContentOptions#modalTitle */
	@Input() public modalTitle ?: ModalContentOptions['modalTitle'];

	/** The icon for the modal title */
	@Input() protected icon : PlanoFaIconPoolKeys | null = null;

	/** The theme for the icon for the modal title */
	@Input() protected iconTheme : FaIconComponentTheme | null = null;

	/**
	 * Written to the dismiss button.
	 * Default: No
	 */
	@Input() public dismissBtnLabel : string | null = null;

	/**
	 * The label of the button that closes the modal successfully.
	 * This will be set when user opens the Modal.
	 * If you want the possibility to change the text during a lifecycle of a Modal, provide a fn : () => string here.
	 *
	 * Default: Yes
	 */
	@Input() public closeBtnLabel : (string | (() => string)) | null = null;

	/** Visual Theme of the close button */
	@Input('closeBtnTheme') public _closeBtnTheme : PBtnTheme | null = null;

	/** Icon for the close button */
	@Input() public closeBtnIcon : PlanoFaIconPoolKeys | null = null;

	/**
	 * Should the close button icon be shown on the right side of the button?
	 * @default false
	 */
	@Input() public closeBtnIconPosition : PButtonComponent['badgeOrIconPosition'] = 'start';

	/**
	 * Should the close-/success-button be disabled?
	 * @default false
	 */
	@Input() public closeBtnDisabled : boolean = false;

	/**
	 * A tooltip for the close button.
	 * Will be shown on hover, even if the button is disabled.
	 */
	@Input() public closeBtnTooltip : PTooltipDirective['pTooltip'] = null;

	/**
	 * Should the dismiss button be hidden?
	 */
	@Input() public hideDismissBtn : boolean = false;

	/**
	 * Should the close button be hidden?
	 * Only use this if {@link onClose} is bound, too.
	 * Otherwise the close button will be hidden automatically, so you don't need to set this.
	 */
	@Input('hideCloseBtn') public _hideCloseBtn : boolean = false;

	/**
	 * Additional options to be shown in the modal header.
	 */
	@Input() protected modalOptionsTemplate : TemplateRef<unknown> | null = null;

	/**
	 * Happens when the user closes the modal with some kind of success/OK button
	 * TODO: PLANO-188089 Can this be turned into a private property?
	 */
	@Output() protected onClose = new EventEmitter<Event>();

	/**
	 * Happens when the user closes the modal with some kind of cancel button.
	 * E.g. a × icon in the top right corner.
	 * This also triggers if the user clicks outside the modal (if outside-click is not disabled by any config)
	 */
	@Output() private onDismiss = new EventEmitter<Event>();

	@ViewChild('footer', { static: true }) private footer! : ElementRef<HTMLElement>;

	/**
	 * The view child of the close button so you are free to place it where you want.
	 * Note that this is not static as there might be internal conditions to show/hide the element.
	 */
	@ViewChild('closeBtnRef', { static: false }) public closeBtnRef : TemplateRef<Element> | null = null;

	/**
	 * The view child of the dismiss button so you are free to place it where you want.
	 * Note that this is not static as there might be internal conditions to show/hide the element.
	 */
	@ViewChild('dismissBtnRef', { static: false }) public dismissBtnRef : TemplateRef<Element> | null = null;

	@ContentChild(PModalContentBodyComponent) private content ?: PModalContentBodyComponent;

	// HACK: quick fix for modals that have no footer,
	// since i implemented support for .footerViewContainerRef in modal-default-template
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('showCustomFooter') private _showCustomFooter : boolean | null = null;

	@ViewChild(PCloseOnEscDirective) private closeOnEscDirective ?: PCloseOnEscDirective;

	constructor(
		private localize : LocalizePipe,
		public pSentryService : PSentryService,
		protected modalService : ModalService,
		private console : LogService,
		@Optional() private parentWrapperDivDirective ?: PModalContentWrapperDirective,
		@Optional() private parentWrapperTemplateDirective ?: PModalTemplateDirective,
	) {
		super();
	}

	public Config = Config;
	public PTextColorEnum = PTextColorEnum;

	protected isString = isString;

	private subscriptions : Subscription[] = [];

	public ngAfterViewInit() : void {
		this.validateValues();
		this.initValues();
		this.subscriptions.push(this.modalService.modalStateCloseSubject.subscribe(closeValue => {
			if (
				closeValue.type === 'dismiss' &&
				(this.parentWrapperTemplateDirective?.openModalStackIndex === closeValue.stackIndex ||
				this.parentWrapperDivDirective?.openModalStackIndex === closeValue.stackIndex)
			)
				this.onDismiss.emit();
		}));
	}

	public ngOnDestroy() : void {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	private validateValues() : void {
		if (this._hideCloseBtn && !this.onClose.observed) this.console.error('Not necessary set hideCloseBtn to true if (onClose) is not set');
		if (!this.parentWrapperDivDirective && !this.parentWrapperTemplateDirective) {
			const additionalInfo = typeof this.modalTitle === 'string' ? `modalTitle: ${this.modalTitle}` : '';
			this.console.error(`Please use <p-modal-content> as child of <ng-template pModalTemplate …. ${additionalInfo}`);
		}
		if (!this.content) throw new Error(`No <p-modal-content-body> provided for modal`);
	}

	/**
	 * Should the close button be hidden?
	 */
	protected get hideCloseBtn() : boolean {
		if (this._hideCloseBtn) return this._hideCloseBtn;
		return !this.onClose.observed;
	}

	/**	closeBtnLabel can be a fn which returns a string */
	public get closeBtnLabelAsString() : string | null {
		if (this.closeBtnLabel === null) return null;
		if (typeof this.closeBtnLabel === 'string') return this.closeBtnLabel;
		return this.closeBtnLabel();
	}

	/**
	 * Set some default values for properties that are not defined yet
	 */
	public initValues() : void {
		if (this.modalTitle === undefined) this.modalTitle = this.localize.transform('Sicher?');
		if (this.dismissBtnLabel === null) this.dismissBtnLabel = this.localize.transform(this.hideCloseBtn ? 'Alles klar 👌' : 'Nein');
		if (this.closeBtnLabel === null) this.closeBtnLabel = this.localize.transform('Ja');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showCustomFooter() : boolean {
		if (this._showCustomFooter !== null) return this._showCustomFooter;
		return this.footer.nativeElement.children.length > 0;
	}

	/**
	 * A shorthand to get access to the theme.
	 */
	protected get theme() : PModalTemplateContext['theme'] {
		return this.parentWrapperTemplateDirective?.theme ?? this.parentWrapperDivDirective?.theme ?? null;
	}

	/**
	 * Depending on the theme, should the text be white?
	 */
	public get textWhite() : boolean {
		return isColorfulTheme(this.theme);
	}

	/**
	 * Should the footer that is defined in the template file of this component be hidden?
	 * @returns
	 *  - true If a custom footer is provided or all content of the internal footer would be hidden.
	 *  - false If there is no custom footer provided and there is some content for the internal footer.
	 */
	protected get hideInternalFooter() : boolean {
		if (this.showCustomFooter) return true;
		return !!this.hideDismissBtn && !!this.hideCloseBtn;
	}

	/**
	 * Theme of the close button
	 */
	protected get closeBtnTheme() : PBtnTheme {
		if (this._closeBtnTheme !== null) return this._closeBtnTheme;

		// If this modal is colorful then we need light buttons in the footer otherwise the buttons can be colorful.
		return isColorfulTheme(this.theme) ? this.enums.PThemeEnum.LIGHT : this.enums.PThemeEnum.PRIMARY;
	}

	/**
	 * Theme of the dismiss button
	 */
	protected get dismissBtnTheme() : PBtnTheme {
		// If this modal is colorful then we need light buttons in the footer otherwise the buttons can be colorful.
		return isColorfulTheme(this.theme) ? this.enums.PThemeEnum.LIGHT : this.enums.PThemeEnum.SECONDARY;
	}

	/**
	 * Should the modal header be shown?
	 */
	protected get showModalHeader() : boolean {
		return this.modalTitle !== null || this.icon !== null;
	}
}
