/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "booking_system_data".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	LOCALE = 1;
	LOCATION_NAME = 2;
	BOOKING_PAGE_COVER = 3;
	COMPANY_LOGO = 4;
	LOCATION_HOMEPAGE = 5;
	TERMS_URL = 6;
	DATA_PROTECTION_URL = 7;
	TERMS_OF_PARTICIPATION_URL = 8;
	CANCELLATION_POLICY_URL = 9;
	BOOKING_PROCESS_CONTACT_EMAIL = 10;
	GIFT_CARD_CONTACT_EMAIL = 11;
	RE_CAPTCHA_ENABLED_IN_BOOKING_PROCESS = 12;
	GIFT_CARD_ONLINE_REDEMPTION_ALLOWED = 13;
}


@Injectable({
  providedIn: 'root',
})
export class BookingSystemDataApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'booking_system_data');
	}

	consts = new Consts();

	protected version() : string {
		return 'ed7aef6fb4634844a65abb3ea647099f,bb970fe8653192e13da520ef220794e4';
	}

	private dataWrapper = new BookingSystemDataApiRoot(this);

	get data() : BookingSystemDataApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : BookingSystemDataApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new BookingSystemDataApiRoot(this);
	}
}

		 
export class BookingSystemDataApiRoot extends ApiObjectWrapper<BookingSystemDataApiRoot>
{
	constructor(override readonly api : BookingSystemDataApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<BookingSystemDataApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, BookingSystemDataApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<BookingSystemDataApiRoot, BookingSystemDataApiRoot> = new ApiAttributeInfo<BookingSystemDataApiRoot, BookingSystemDataApiRoot>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : BookingSystemDataApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiLocale : ApiAttributeInfo<BookingSystemDataApiRoot, string> = new ApiAttributeInfo<BookingSystemDataApiRoot, string>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'locale',
			nodeName: 'LOCALE',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiLocationName : ApiAttributeInfo<BookingSystemDataApiRoot, string> = new ApiAttributeInfo<BookingSystemDataApiRoot, string>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'locationName',
			nodeName: 'LOCATION_NAME',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiBookingPageCover : ApiAttributeInfo<BookingSystemDataApiRoot, Image> = new ApiAttributeInfo<BookingSystemDataApiRoot, Image>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'bookingPageCover',
			nodeName: 'BOOKING_PAGE_COVER',
			type: PApiType.Image,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiCompanyLogo : ApiAttributeInfo<BookingSystemDataApiRoot, Image> = new ApiAttributeInfo<BookingSystemDataApiRoot, Image>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'companyLogo',
			nodeName: 'COMPANY_LOGO',
			type: PApiType.Image,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiLocationHomepage : ApiAttributeInfo<BookingSystemDataApiRoot, Url> = new ApiAttributeInfo<BookingSystemDataApiRoot, Url>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'locationHomepage',
			nodeName: 'LOCATION_HOMEPAGE',
			type: PApiType.Url,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiTermsUrl : ApiAttributeInfo<BookingSystemDataApiRoot, Url> = new ApiAttributeInfo<BookingSystemDataApiRoot, Url>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'termsUrl',
			nodeName: 'TERMS_URL',
			type: PApiType.Url,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiDataProtectionUrl : ApiAttributeInfo<BookingSystemDataApiRoot, Url> = new ApiAttributeInfo<BookingSystemDataApiRoot, Url>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'dataProtectionUrl',
			nodeName: 'DATA_PROTECTION_URL',
			type: PApiType.Url,
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});
	aiTermsOfParticipationUrl : ApiAttributeInfo<BookingSystemDataApiRoot, Url> = new ApiAttributeInfo<BookingSystemDataApiRoot, Url>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'termsOfParticipationUrl',
			nodeName: 'TERMS_OF_PARTICIPATION_URL',
			type: PApiType.Url,
			hasPermissionToSet: () => false,
			rawDataIndex: 8,
		});
	aiCancellationPolicyUrl : ApiAttributeInfo<BookingSystemDataApiRoot, Url> = new ApiAttributeInfo<BookingSystemDataApiRoot, Url>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'cancellationPolicyUrl',
			nodeName: 'CANCELLATION_POLICY_URL',
			type: PApiType.Url,
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});
	aiBookingProcessContactEmail : ApiAttributeInfo<BookingSystemDataApiRoot, Email> = new ApiAttributeInfo<BookingSystemDataApiRoot, Email>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'bookingProcessContactEmail',
			nodeName: 'BOOKING_PROCESS_CONTACT_EMAIL',
			type: PApiType.Email,
			hasPermissionToSet: () => false,
			rawDataIndex: 10,
		});
	aiGiftCardContactEmail : ApiAttributeInfo<BookingSystemDataApiRoot, Email> = new ApiAttributeInfo<BookingSystemDataApiRoot, Email>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'giftCardContactEmail',
			nodeName: 'GIFT_CARD_CONTACT_EMAIL',
			type: PApiType.Email,
			hasPermissionToSet: () => false,
			rawDataIndex: 11,
		});
	aiReCaptchaEnabledInBookingProcess : ApiAttributeInfo<BookingSystemDataApiRoot, boolean> = new ApiAttributeInfo<BookingSystemDataApiRoot, boolean>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'reCaptchaEnabledInBookingProcess',
			nodeName: 'RE_CAPTCHA_ENABLED_IN_BOOKING_PROCESS',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : BookingSystemDataApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 12,
		});
	aiGiftCardOnlineRedemptionAllowed : ApiAttributeInfo<BookingSystemDataApiRoot, boolean> = new ApiAttributeInfo<BookingSystemDataApiRoot, boolean>({
			apiObjWrapper: this as any as BookingSystemDataApiRoot,
			name: 'giftCardOnlineRedemptionAllowed',
			nodeName: 'GIFT_CARD_ONLINE_REDEMPTION_ALLOWED',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : BookingSystemDataApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});

	/**
	 * The locale of the client.
	 *
	 * @type {string}
	 */
	get locale() : string {
		this.getterDebugValidations(this.aiLocale, false);
		return this.data[1];
	}

	set localeTestSetter(v : string) {
        this.setterImpl(1, v, 'locale', true, null, null);
	}

	/**
	 * The client location name.
	 *
	 * @type {string}
	 */
	get locationName() : string {
		this.getterDebugValidations(this.aiLocationName, false);
		return this.data[2];
	}

	set locationNameTestSetter(v : string) {
        this.setterImpl(2, v, 'locationName', true, null, null);
	}

	/**
	 * Cover image of the booking page.
	 *
	 * @type {Image}
	 */
	get bookingPageCover() : Image | null {
		this.getterDebugValidations(this.aiBookingPageCover, false);
		const path = this.data[3] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set bookingPageCoverTestSetter(v : Image | null) {
        this.setterImpl(3, v, 'bookingPageCover', true, null, null);
	}

	/**
	 * Logo of the client.
	 *
	 * @type {Image}
	 */
	get companyLogo() : Image | null {
		this.getterDebugValidations(this.aiCompanyLogo, false);
		const path = this.data[4] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set companyLogoTestSetter(v : Image | null) {
        this.setterImpl(4, v, 'companyLogo', true, null, null);
	}

	/**
	 * Homepage of the client.
	 *
	 * @type {Url}
	 */
	get locationHomepage() : Url | null {
		this.getterDebugValidations(this.aiLocationHomepage, false);
		return this.data[5];
	}

	set locationHomepageTestSetter(v : Url | null) {
        this.setterImpl(5, v, 'locationHomepage', true, null, null);
	}

	/**
	 * Client's terms page url.
	 *
	 * @type {Url}
	 */
	get termsUrl() : Url | null {
		this.getterDebugValidations(this.aiTermsUrl, false);
		return this.data[6];
	}

	set termsUrlTestSetter(v : Url | null) {
        this.setterImpl(6, v, 'termsUrl', true, null, null);
	}

	/**
	 * Client's data protection page url.
	 *
	 * @type {Url}
	 */
	get dataProtectionUrl() : Url | null {
		this.getterDebugValidations(this.aiDataProtectionUrl, false);
		return this.data[7];
	}

	set dataProtectionUrlTestSetter(v : Url | null) {
        this.setterImpl(7, v, 'dataProtectionUrl', true, null, null);
	}

	/**
	 * Client's terms of participation page url.
	 *
	 * @type {Url}
	 */
	get termsOfParticipationUrl() : Url | null {
		this.getterDebugValidations(this.aiTermsOfParticipationUrl, false);
		return this.data[8];
	}

	set termsOfParticipationUrlTestSetter(v : Url | null) {
        this.setterImpl(8, v, 'termsOfParticipationUrl', true, null, null);
	}

	/**
	 * Client's cancellation policy page url.
	 *
	 * @type {Url}
	 */
	get cancellationPolicyUrl() : Url | null {
		this.getterDebugValidations(this.aiCancellationPolicyUrl, false);
		return this.data[9];
	}

	set cancellationPolicyUrlTestSetter(v : Url | null) {
        this.setterImpl(9, v, 'cancellationPolicyUrl', true, null, null);
	}

	/**
	 * Contact email for current booking process. Note that for all questions concerning gift cards, you should instead use "giftCardContactEmail".
	 *
	 * @type {Email}
	 */
	get bookingProcessContactEmail() : Email {
		this.getterDebugValidations(this.aiBookingProcessContactEmail, false);
		return this.data[10];
	}

	set bookingProcessContactEmailTestSetter(v : Email) {
        this.setterImpl(10, v, 'bookingProcessContactEmail', true, null, null);
	}

	/**
	 * Contact email for gift cards.
	 *
	 * @type {Email}
	 */
	get giftCardContactEmail() : Email {
		this.getterDebugValidations(this.aiGiftCardContactEmail, false);
		return this.data[11];
	}

	set giftCardContactEmailTestSetter(v : Email) {
        this.setterImpl(11, v, 'giftCardContactEmail', true, null, null);
	}

	/**
	 * Should ReCaptcha be used in the booking processes of this client?
	 *
	 * @type {boolean}
	 */
	get reCaptchaEnabledInBookingProcess() : boolean {
		this.getterDebugValidations(this.aiReCaptchaEnabledInBookingProcess, false);
		return this.data[12];
	}

	set reCaptchaEnabledInBookingProcessTestSetter(v : boolean) {
        this.setterImpl(12, v, 'reCaptchaEnabledInBookingProcess', true, null, null);
	}

	/**
	 * Can gift-cards be redeemed over the booking plugin?
	 *
	 * @type {boolean}
	 */
	get giftCardOnlineRedemptionAllowed() : boolean {
		this.getterDebugValidations(this.aiGiftCardOnlineRedemptionAllowed, false);
		return this.data[13];
	}

	set giftCardOnlineRedemptionAllowedTestSetter(v : boolean) {
        this.setterImpl(13, v, 'giftCardOnlineRedemptionAllowed', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : BookingSystemDataApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}



