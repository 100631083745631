/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { NgZone } from '@angular/core';

/**
 * Wait until a certain condition function returns true.
 * The interval are ran outside of angular to avoid change detection,
 * so you should pass a NgZone.
 *
 * @param zone The NgZone to run the interval outside of
 * @param conditionFn the function that should return true to resolve the promise
 * @param intervalDuration The interval duration in milliseconds. Defaults to 100.
 * @param numberOfRepetitions The number of repetitions to run the interval. If not set it will run indefinitely.
 * @returns a promise that resolves to
 * - true if the operation was successful,
 * - false if it had to give up after the maximum number of repetitions
*/
export async function waitUntil(
	zone : NgZone,
	conditionFn : () => boolean,
	intervalDuration : number = 100,
	numberOfRepetitions : number | null = null,
) : Promise<boolean> {
	return await waitForValueNotUndefined(zone, () => (conditionFn() ? true : undefined), intervalDuration, numberOfRepetitions) ?? false;
}

// eslint-disable-next-line jsdoc/require-jsdoc -- false-positive
export async function waitForValueNotUndefined<T>(
	zone : NgZone,
	getter : () => T | undefined,
	intervalDuration ?: number,
	numberOfRepetitions ?: null,
) : Promise<T>;
// eslint-disable-next-line jsdoc/require-jsdoc -- false-positive
export async function waitForValueNotUndefined<T>(
	zone : NgZone,
	getter : () => T | undefined,
	intervalDuration : number,
	numberOfRepetitions : number | null,
) : Promise<T | null>;

/**
 * Wait for the value to not be undefined
 * @param zone The NgZone to run the code outside of
 * @param getter The function that should return the value to wait for
 * @param intervalDuration The interval duration in milliseconds
 * @param numberOfRepetitions The number of repetitions the interval should re-run to try to resolve the promise
 * @returns The value that was returned by the getter or null if the value was undefined after the maximum number of repetitions
 */
export async function waitForValueNotUndefined<T>(
	zone : NgZone,
	getter : () => T | undefined,
	intervalDuration : number = 100,
	numberOfRepetitions : number | null = null,
) : Promise<T | null> {
	return await new Promise<T | null>((resolve) => {
		zone.runOutsideAngular(() => {
			// If it is possible to immediately return, then do it, otherwise a interval will be used.
			const firstGetterValue = getter();
			if (firstGetterValue !== undefined) {
				resolve(firstGetterValue);
				return;
			}

			// Set up an interval to repeatedly call the getter until it returns a value or max retries reached
			let numberOfRepetitionsRun = 0;

			const interval = window.setInterval(() => {
				const value = getter();
				if (value !== undefined) {
					window.clearInterval(interval);
					resolve(value);
					return;
				}
				numberOfRepetitionsRun++;
				if (numberOfRepetitions !== null && numberOfRepetitionsRun >= numberOfRepetitions) {
					window.clearInterval(interval);
					throw new Error('waitForValueNotUndefined: The value was not defined after the maximum number of repetitions');
				}
			}, intervalDuration);
		});
	});
}

/**
 * Wait for a certain amount of time
 * @param zone The NgZone to run the timeout outside of
 * @param ms The amount of time to wait in milliseconds
 */
export const promiseTimeout = async (
	zone : NgZone,
	ms : number,
) : Promise<void> => {
	return new Promise(resolve => {
		zone.runOutsideAngular(() => {
			window.setTimeout(resolve, ms);
		});
	});
};
