/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { ApiDataWrapperBase, ApiListWrapper } from '@plano/shared/api';

/**
 * A list class containing other api-lists.
 */
export class ApiLists<T> extends ApiListWrapper<T> {

	public override createNewItem() : T {
		throw new Error('unsupported');
	}

	protected override wrapRawData(_itemRawData : any[]) : T {
		throw new Error('unsupported');
	}

	/**
	 * @returns Does this list contains primitive items?
	 */
	protected containsPrimitives() : boolean {
		return false;
	}

	/**
	 * @returns Does this list contains id items?
	 */
	protected containsIds() : boolean {
		return false;
	}

	/**
	 * @returns The `dni` value of this list. See `common.txt`.
	 */
	protected get dni() : string {
		return '0';
	}

	/**
	 * @returns Creates and returns a new instance of this list.
	 */
	protected createInstance(parent : ApiDataWrapperBase | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ApiLists<T>(this.api, parent, isView, removeDestroyedItems) as typeof this;
	}
}
