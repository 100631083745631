/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { HttpParams } from '@angular/common/http';
import { defaultSortingForShiftModelCoursePaymentMethods } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model-course-payment-methods-sorting.const';
import { INITIALIZED_IN_BACKEND, SchedulingApiBookingBase, SchedulingApiBookingParticipantBase, SchedulingApiBookingsBase, SchedulingApiBookingState, SchedulingApiPaymentMethodType } from '@plano/shared/api';
import { ClientCurrency } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Data } from '@plano/shared/core/data/data';
import { assume, assumeNonNull, notNull } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { SchedulingApiBookable } from './scheduling-api-bookable.service';
import { SchedulingApiShiftModel } from './scheduling-api-shift-model.service';
import { SchedulingApiShift } from './scheduling-api-shift.service';
import { SchedulingApiShiftModelCoursePaymentMethods } from './scheduling-api-shiftmodel-course-payment-methods.service';
import { SchedulingApiTransactions } from './scheduling-api-transactions.service';
import { PPaymentStatusEnum } from './scheduling-api.utils';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiBooking extends SchedulingApiBookingBase {

	public override get participantCount() : number {
		if (this.aiParticipants.isAvailable) {
			return this.participants.length;
		}
		return super.participantCount;
	}
	public override set participantCount(v : number) {
		super.participantCount = v;
	}

	public override get isDetailedLoaded() : boolean {
		// Adjust the custom load-detailed logic from backend.
		if (super.isDetailedLoaded)
			return true;

		// item is also loaded detailed if one of its shifts is loaded detailed
		if (this.courseSelector !== null &&
			this.api.currentlyDetailedLoaded instanceof SchedulingApiShift &&
			this.courseSelector.contains(this.api.currentlyDetailedLoaded.id)
		) {
			return true;
		}

		return false;
	}

	/**
	 * This Price is independent from the type of booking. So a canceled request can also have a price.
	 */
	public get price() : ClientCurrency {
		if (this.overallTariffId !== null) {
			const tariff = this.model.courseTariffs.get(this.overallTariffId);
			if (!tariff) throw new Error('Could not get tariff');
			return tariff.getTotalFee(this.participantCount);
		}

		// TODO: PLANO-156519
		if (!this.aiParticipants.isAvailable) return 0;

		// Add all totalFees together
		return this.participants
			.iterable()
			.flatMap(item => item.totalFee)
			.reduce((a, b) => a + b, 0);
	}

	/**
	 * shorthand that returns the related model
	 */
	public get model() : SchedulingApiShiftModel {
		// NOTE: This methods exists on multiple classes:
		// TimeStampApiShift
		// SchedulingApiShift
		// SchedulingApiBooking
		// SchedulingApiTodaysShiftDescription
		// SchedulingApiWorkingTime
		const SHIFT_MODEL = this.api.data.shiftModels.get(this.shiftModelId);
		assumeNonNull(SHIFT_MODEL, 'SHIFT_MODEL', 'PRODUCTION-50X');

		return SHIFT_MODEL;
	}

	/**
	 * Was the shiftModel of type onlyWholeCourseBookable when this booking has been created?
	 * The user has no possibility to change the shiftModels onlyWholeCourseBookable, so it is save to
	 * ask for the models onlyWholeCourseBookable.
	 */
	public get onlyWholeCourseBookable() : boolean | null {
		return this.model.onlyWholeCourseBookable;
	}

	public override get shiftModelId() : Id {
		return super.shiftModelId;
	}

	public override set shiftModelId(shiftModelId : Id) {
		super.shiftModelId = shiftModelId;

		// When creating a new booking, we dont want to set "participantCount" when it is not whole-course-bookable
		if (this.isNewItem && !this.model.onlyWholeCourseBookable)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			this.participantCount = INITIALIZED_IN_BACKEND as any;
	}

	/**
	 * Get the name based on the linked shiftModel
	 */
	public get name() : SchedulingApiShiftModel['name'] {
		// NOTE: This methods exists on multiple classes:
		// SchedulingApiBooking
		return this.model.name;
	}

	/**
	 * @returns All transactions belonging to this booking.
	 */
	public get transactions() : SchedulingApiTransactions {
		// TODO: PLANO-156519
		if (!this.api.data.aiTransactions.isAvailable) return new SchedulingApiTransactions(this.api, null, true, false);
		return this.api.data.transactions.filterBy(item => this.id.equals(item.bookingId));
	}

	/**
	 * getter for the status of payment
	 */
	public get paymentStatus() : PPaymentStatusEnum | null {
		return SchedulingApiBookable.paymentStatus(this);
	}

	/**
	 * Does the shopper need to pay {@link price}?
	 */
	public get shopperNeedsToPayPrice() : boolean {
		return this.state === SchedulingApiBookingState.BOOKED || this.state === SchedulingApiBookingState.INQUIRY;
	}

	/**
	 * How much needs to be paid for this booking overall. This value is independent of how much has been paid already
	 * (i.e. `currentlyPaid`).
	 */
	public get amountToPay() : ClientCurrency | null {
		if (
			// eslint-disable-next-line unicorn/prefer-number-properties -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			isNaN(this.cancellationFee) ||
			this.cancellationFee < 0
		) return null;
		let amountToPay = this.shopperNeedsToPayPrice ? this.price : 0;
		amountToPay += this.cancellationFee;
		return amountToPay;
	}

	/**
	 * Overall amount which can be refunded.
	 */
	public get refundableAmount() : ClientCurrency {
		return this.currentlyPaid;
	}

	/**
	 * @see SchedulingApiBookingBase#currentlyPaidWithoutLatestCreatedTransaction
	 */
	public get currentlyPaidWithoutLatestCreatedTransaction() : ClientCurrency {
		return SchedulingApiBookable.calculateCurrentlyPaidWithoutLatestCreatedTransaction(this, super.currentlyPaid);
	}

	/**
	 * @see SchedulingApiBookingBase#currentlyPaid
	 */
	public override get currentlyPaid() : ClientCurrency {
		return SchedulingApiBookable.calculateCurrentlyPaid(this, super.currentlyPaid);
	}

	/**
	 * @see SchedulingApiBookable#getOpenAmount
	 */
	public getOpenAmount(currentlyPaid ?: ClientCurrency | null) : ClientCurrency | null {
		return SchedulingApiBookable.getOpenAmount(this, currentlyPaid);
	}

	/**
	 * Check if this booking fits to the given search term.
	 * @param term The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(term : string) : boolean {
		const termLow = term.toLowerCase();
		const TERMS = termLow.split(' ');

		const searchableContent : string[] = ([
			this.firstName,
			this.lastName,
		]).concat(
			this.participants.map(item => item.firstName),
		).concat(
			this.participants.map(item => item.lastName),
		);
		if (this.bookingNumber) searchableContent.push(this.bookingNumber.toString());
		if (this.email) searchableContent.push(this.email);

		for (const TERM of TERMS) {
			if (!TERM) continue;
			for (const item of searchableContent) {
				const itemLow = item.toLowerCase();
				if (itemLow.includes(TERM)) return true;
			}
		}
		return false;
	}

	/**
	 * Returns the participant with `isBookingPerson` equal `true`. If none exists, returns `null`.
	 */
	public get bookingPersonParticipant() : SchedulingApiBookingParticipant | null {
		return this.aiParticipants.isAvailable ? this.participants.find(item => item.isBookingPerson) : null;
	}

	/**
	 * A booking with `price` equal `0`.
	 */
	public get isFreeBooking() : boolean {
		return this.price === 0;
	}

	/**
	 * Get all paymentMethods available for this booking
	 */
	public get coursePaymentMethods() : SchedulingApiShiftModelCoursePaymentMethods {
		return this.model.coursePaymentMethods.filterBy(item => {
			if (this.paymentMethodId?.equals(item.id)) return true;
			if (item.trashed) return false;
			return true;
		});
	}

	private _courseMiscPaymentMethods = new Data<SchedulingApiShiftModelCoursePaymentMethods>(this.api);

	/**
	 * Get all MISC paymentMethods available for this booking
	 */
	public get courseMiscPaymentMethods() : SchedulingApiShiftModelCoursePaymentMethods {
		return this._courseMiscPaymentMethods.get(() => {
			return this.coursePaymentMethods
				.filterBy(item => item.type === SchedulingApiPaymentMethodType.MISC)
				// eslint-disable-next-line deprecation/deprecation -- TODO: PLANO-187682
				.sortedBy(defaultSortingForShiftModelCoursePaymentMethods);
		});
	}

	/**
	 * Is this booking canceled?
	 */
	public get isCanceled() : boolean {
		return this.state === SchedulingApiBookingState.CANCELED;
	}

	/**
	 * Get a fitting icon for the state of this booking
	 */
	public get stateIcon() : PlanoFaIconPoolKeys | null {
		switch (this.state) {
			case SchedulingApiBookingState.BOOKED:
				return enumsObject.PlanoFaIconContextPool.BOOKING_BOOKED;
			case SchedulingApiBookingState.CANCELED:
				return enumsObject.PlanoFaIconContextPool.BOOKING_CANCELED;
			case SchedulingApiBookingState.INQUIRY:
				return enumsObject.PlanoFaIconContextPool.BOOKING_INQUIRY;
			case SchedulingApiBookingState.INQUIRY_DECLINED:
				return enumsObject.PlanoFaIconContextPool.BOOKING_DECLINED;
		}
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiBookings extends SchedulingApiBookingsBase {
	/**
	 * Search these bookings by a given term.
	 * @param term The string to search for. Can be a string that a user typed into an input element.
	 */
	public search(term : Parameters<SchedulingApiBooking['fitsSearch']>[0]) : this {
		if (term === '') return this;
		return this.filterBy(item => item.fitsSearch(term));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public async createNewBooking(
		shiftModel : SchedulingApiShiftModel,
		searchParams : HttpParams | null = null,
	) : Promise<SchedulingApiBooking> {

		// a new shift is partly a copy of the shift-model. So we must first get all the data of the shift-model
		await shiftModel.loadDetailed({searchParams : searchParams});
		return super.createNewItem(newItem => {
			newItem.shiftModelId = shiftModel.id;
		});
	}
}

/** @see SchedulingApiBookingParticipantBase */
export class SchedulingApiBookingParticipant extends SchedulingApiBookingParticipantBase {

	/**
	 * Get full amount that has to be payed for this participant.
	 * @returns The total fee for this participant
	 */
	public get totalFee() : ClientCurrency {
		// Get the booking
		const booking = notNull(this.parent!.parent);

		if (this.tariffId !== null) {
			const tariff = notNull(booking.model.courseTariffs.get(this.tariffId));

			// Add all fees for this participant together
			let result = 0;
			for (const fee of tariff.fees.iterable()) {

				// As there was no overallTariffId, we can assume that each fee is for one participant.
				// To make sure we detect any issues in the implementation early, we check this assumption.
				assume(fee.perXParticipants === 1, 'fee.perXParticipants', 'Is expected to be 1');

				result += fee.fee;
			}
			return result;
		}

		// When tariffId is null, this means that the participant has free entrance.
		return 0;
	}
}
