/* eslint-disable @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */

import { IconName, IconStyle } from '@fortawesome/fontawesome-common-types';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

// NOTE: Information on how to handle the pool and the font awesome icons can be found in the following link:
// https://drplano.atlassian.net/wiki/spaces/EN/pages/2778300419/Maintenance+of+our+font+awesome+icons

// TODO: PLANO-188477 Make it possible to define family and style at the same id
type PIconId = `fa-${IconName} fa-${IconStyle}` | `fa-${string} fa-kit` | `fa-${string} fa-kit-duotone`;

type PIconPool = Partial<Record<PIconId, (readonly string [] | readonly [])>>;

/**
 * A Set of all Icons available in our App.
 */
export const PlanoFaIconPool = {
	'fa-0 fa-solid': [],
	'fa-1 fa-solid': [],
	'fa-2 fa-solid': [],
	'fa-3 fa-solid': [],
	'fa-4 fa-solid': [],
	'fa-5 fa-solid': [],
	'fa-6 fa-solid': [],
	'fa-7 fa-solid': [],
	'fa-8 fa-solid': [],
	'fa-9 fa-solid': [],
	'fa-address-card fa-duotone': [],
	'fa-address-card fa-solid': [
		'WORK_MODELS',
	],
	'fa-alarm-clock fa-duotone': [
		'EXPIRED',
	],
	'fa-align-left fa-duotone': [
		'TEXT_LEFT',
	],
	'fa-amazon-pay fa-brands': [],
	'fa-anchor fa-duotone': [],
	'fa-android fa-brands': [
		'BRAND_ANDROID',
	],
	'fa-angle-left fa-solid': [
		'PREV',
	],
	'fa-angle-right fa-duotone': [],
	'fa-angle-right fa-solid': [
		'NEXT',
	],
	'fa-apple fa-brands': [
		'BRAND_APPLE',
	],
	'fa-apple-pay fa-brands': [],
	'fa-arrow-down fa-duotone': [
		'SCROLL_DOWN',
	],
	'fa-arrow-down-1-9 fa-duotone': [
		'ORDER_ASCENDING',
	],
	'fa-arrow-down-long fa-solid': [],
	'fa-arrow-left fa-duotone': [],
	'fa-arrow-left fa-solid': [
		'NAV_BACK',
	],
	'fa-arrow-left-to-line fa-duotone': [],
	'fa-arrow-right fa-duotone': [
		'NAV_FORWARD',
		'CHARGEBACK_FLOW',
	],
	'fa-arrow-right-from-bracket fa-duotone': [
		'LOGOUT',
	],
	'fa-arrow-right-long fa-solid': [],
	'fa-arrow-right-to-line fa-duotone': [],
	'fa-arrow-rotate-left fa-duotone': [
		'UNDO',
	],
	'fa-arrow-rotate-right fa-duotone': [],
	'fa-arrow-up fa-duotone': [
		'SCROLL_UP',
	],
	'fa-arrow-up-right-from-square fa-duotone': [
		'NAV_NEW_TAB',
		'EXTERNAL_LINK',
		'EXPORT',
	],
	'fa-arrows-alt-h fa-duotone': [],
	'fa-arrows-alt-v fa-duotone': [],
	'fa-arrows-rotate fa-duotone': [
		'SYNCING',
	],
	'fa-at fa-duotone': [
		'EMAIL_AT',
	],
	'fa-badge-check fa-duotone': [
		'ALLOWED',
	],
	'fa-bag-shopping fa-solid': [
		'ITEMS_SALES',
	],
	'fa-ban fa-duotone': [],
	'fa-ban fa-solid': [
		'NOT_ALLOWED',
	],
	'fa-barcode fa-duotone': [
		'BAR_CODE',
	],
	'fa-barcode-read fa-duotone': [],
	'fa-bars fa-duotone': [
		'MOBILE_MENU',
	],
	'fa-bell fa-duotone': [
		'NOTIFICATION_SETTINGS',
	],
	'fa-bell fa-solid': [
		'PUSH_NOTIFICATION',
	],
	'fa-bell-slash fa-duotone': [
		'NO_PUSH_NOTIFICATION',
	],
	'fa-bitcoin fa-brands': [],
	'fa-bolt fa-duotone': [
		'DANGER',
	],
	'fa-book-section fa-duotone': [
		'TAB_ACCOUNTING',
	],
	'fa-briefcase-medical fa-duotone': [],
	'fa-briefcase-medical fa-solid': [
		'ITEMS_LEAVE_ILLNESS',
	],
	'fa-browser fa-duotone': [
		'TAB_PLUGIN',
	],
	'fa-bullhorn fa-duotone': [],
	'fa-bullseye-pointer fa-duotone': [],
	'fa-cake-candles fa-duotone': [
		'BIRTHDAY',
	],
	'fa-calculator fa-solid': [],
	'fa-calendar fa-duotone': [
		'CALENDAR_MONTH',
	],
	'fa-calendar-check fa-duotone': [
		'CALENDAR_SELECT_RELATED_SHIFTS',
	],
	'fa-calendar-day fa-duotone': [
		'CALENDAR_DAY',
	],
	'fa-calendar-days fa-duotone': [
		'AREA_SCHEDULING',
		'STATE_DATE_EMPTY',
		'STATE_DATE_PICKED',
	],
	'fa-calendar-times fa-duotone': [
		'CALENDAR_EXCLUDE',
	],
	'fa-calendar-week fa-duotone': [
		'CALENDAR_WEEK',
	],
	'fa-camera fa-duotone': [
		'CAMERA',
	],
	'fa-caret-down fa-duotone': [
		'DROPDOWN_CLOSE',
	],
	'fa-caret-down fa-solid': [
		'SORT_ASCENDING',
	],
	'fa-caret-up fa-duotone': [
		'DROPDOWN_OPEN',
	],
	'fa-caret-up fa-solid': [
		'SORT_DESCENDING',
	],
	'fa-cash-register fa-duotone': [
		'PAY_BY_CASH',
	],
	'fa-cat fa-duotone': [
		'SOMETHING_WENT_WRONG',
	],
	'fa-cc-mastercard fa-brands': [],
	'fa-cc-visa fa-brands': [],
	'fa-chart-line fa-duotone': [],
	'fa-chart-line-up fa-duotone': [
		'RISING',
	],
	'fa-chart-pie fa-duotone': [
		'STATISTICS_EXPORT',
	],
	'fa-check fa-duotone': [
		'SUCCESS',
		'BOOKING_BOOKED',
	],
	'fa-check-circle fa-duotone': [],
	'fa-chevron-circle-right fa-solid': [],
	'fa-chevron-down fa-duotone': [
		'COLLAPSIBLE_CLOSE',
	],
	'fa-chevron-left fa-duotone': [
		'COLLAPSIBLE_CLOSE_TO_LEFT',
	],
	'fa-chevron-left fa-solid': [],
	'fa-chevron-right fa-duotone': [],
	'fa-chevron-right fa-solid': [],
	'fa-chevron-up fa-duotone': [
		'COLLAPSIBLE_OPEN',
	],
	'fa-chevron-up fa-solid': [],
	'fa-chevrons-down fa-duotone': [
		'UNCOLLAPSE_ALL',
	],
	'fa-chevrons-up fa-duotone': [
		'COLLAPSE_ALL',
	],
	'fa-chf-sign fa-solid': [
		'CHF',
	],
	'fa-chrome fa-brands': [],
	'fa-circle fa-regular': [
		'RADIO_UNSELECTED',
	],
	'fa-circle fa-solid': [
		'RADIO_SELECTED',
	],
	'fa-circle-1 fa-solid': [],
	'fa-circle-2 fa-solid': [],
	'fa-circle-3 fa-solid': [],
	'fa-circle-4 fa-solid': [],
	'fa-circle-5 fa-solid': [],
	'fa-circle-6 fa-solid': [],
	'fa-circle-7 fa-solid': [],
	'fa-circle-8 fa-solid': [],
	'fa-circle-9 fa-solid': [],
	'fa-circle-arrow-down fa-duotone': [
		'DECREASE',
	],
	'fa-circle-arrow-up fa-duotone': [
		'INCREASE',
	],
	'fa-circle-check fa-duotone': [
		'OK',
	],
	'fa-circle-check fa-regular': [
		'ACKNOWLEDGED',
	],
	'fa-circle-half fa-duotone': [],
	'fa-circle-info fa-regular': [
		'TOOLTIP_INFO',
	],
	'fa-circle-quarter fa-duotone': [],
	'fa-circle-question fa-regular': [
		'TOOLTIP_WARNING',
		'VALUE_UNKNOWN',
	],
	'fa-circle-question fa-solid': [
		'AREA_FAQ',
	],
	'fa-circle-three-quarters fa-duotone': [],
	'fa-circle-user fa-duotone': [],
	'fa-circle-xmark fa-duotone': [
		'NOT_POSSIBLE',
	],
	'fa-clock fa-duotone': [
		'CLOCK',
	],
	'fa-clone fa-duotone': [],
	'fa-clone fa-regular': [],
	'fa-cog fa-duotone': [],
	'fa-coins fa-duotone': [
		'BOOKING_PAYMENT_STATUS',
		'AREA_TRANSACTIONS',
	],
	'fa-coins fa-regular': [],
	'fa-coins fa-solid': [],
	'fa-comment fa-duotone': [
		'COMMENT',
	],
	'fa-comment fa-regular': [],
	'fa-comment-dots fa-regular': [
		'BOOKING_COMMENT',
	],
	'fa-comment-plus fa-duotone': [
		'ADD_COMMENT',
	],
	'fa-cookie-bite fa-solid': [],
	'fa-copy fa-duotone': [
		'COPY_TO_CLIPBOARD',
	],
	'fa-creative-commons-nc-eu fa-brands': [
		'CREATIVE_COMMONS',
	],
	'fa-credit-card fa-duotone': [],
	'fa-crow fa-duotone': [
		'EARLY_BIRD',
	],

	// TODO: PLANO-188477 Add fa-duotone here
	'fa-crown fa-regular': [],

	'fa-desktop fa-duotone': [],
	'fa-dot-circle fa-regular': [],
	'fa-down fa-duotone': [],
	'fa-down fa-solid': [],
	'fa-download fa-duotone': [
		'DOWNLOAD',
	],
	'fa-earth-americas fa-solid': [
		'INTERNET',
	],
	'fa-edge fa-brands': [],
	'fa-ellipsis fa-duotone': [],
	'fa-ellipsis-vertical fa-solid': [
		'MORE_ACTIONS',
	],
	'fa-envelope fa-duotone': [
		'EMAIL_NOTIFICATION',
	],
	'fa-envelope fa-solid': [],
	'fa-envelope-open-text fa-duotone': [
		'OPEN_MAIL',
	],
	'fa-envelopes fa-duotone': [
		'TAB_EMAIL_TEMPLATES',
	],
	'fa-equals fa-solid': [
		'EQUALS',
	],
	'fa-euro-sign fa-solid': [
		'EUR',
	],
	'fa-exchange-alt fa-duotone': [],
	'fa-exclamation-triangle fa-solid': [
		'WARNING',
	],
	'fa-eye fa-duotone': [
		'CAN_READ',
		'VISIBLE',
	],
	'fa-eye-slash fa-duotone': [
		'INVISIBLE',
	],
	'fa-eyes fa-regular': [],
	'fa-face-frown-slight fa-regular': [],
	'fa-face-monocle fa-duotone': [],
	'fa-face-smile fa-regular': [],
	'fa-face-smile-relaxed fa-solid': [
		'RELAXED',
	],
	'fa-facebook-square fa-brands': [
		'BRAND_FACEBOOK',
	],
	'fa-file fa-light': [],
	'fa-file-alt fa-duotone': [],
	'fa-file-binary fa-duotone': [
		'BINARY_FILE',
	],
	'fa-file-csv fa-duotone': [
		'CSV_FILE',
	],
	'fa-file-excel fa-duotone': [
		'EXCEL_FILE',
	],
	'fa-file-lines fa-duotone': [],
	'fa-file-lines fa-light': [],
	'fa-file-pdf fa-duotone': [
		'PDF_FILE',
	],
	'fa-filter fa-solid': [
		'FILTER',
	],
	'fa-filter-slash fa-duotone': [],
	'fa-fire fa-duotone': [],
	'fa-firefox fa-brands': [],
	'fa-flask fa-duotone': [
		'EVALUATE_SHIFT_PLAN',
	],
	'fa-flask fa-solid': [],
	'fa-floppy-disk fa-duotone': [],
	'fa-gears fa-duotone': [
		'AUTOMATIC_SCHEDULING',
	],
	'fa-ghost fa-duotone': [],
	'fa-ghost fa-regular': [],
	'fa-gift fa-duotone': [],
	'fa-gift-card fa-duotone': [
		'GIFT_CARD',
	],
	'fa-globe-americas fa-duotone': [],
	'fa-google-pay fa-brands': [],
	'fa-grid-dividers fa-solid': [
		'GRID_MENU',
	],
	'fa-hand fa-duotone': [
		'NO_ACCESS',
	],
	'fa-hand-holding-box fa-duotone': [],
	'fa-hand-holding-heart fa-duotone': [
		'THANK',
	],
	'fa-hand-point-left fa-duotone': [],
	'fa-hand-point-left fa-solid': [
		'POINT_LEFT',
	],
	'fa-hand-point-up fa-duotone': [],
	'fa-hand-point-up fa-solid': [
		'PAY_ATTENTION',
	],
	'fa-hand-pointer fa-duotone': [],
	'fa-handshake-angle fa-duotone': [
		'ITEMS_SHIFT_EXCHANGE',
	],
	'fa-hashtag fa-regular': [
		'NUMBER',
	],
	'fa-headset fa-duotone': [],
	'fa-heart fa-duotone': [],
	'fa-heart fa-regular': [],
	'fa-heart fa-solid': [
		'ITEMS_ASSIGNMENT_PROCESS',
	],
	'fa-hourglass fa-duotone': [
		'HOURGLASS_HALF',
	],
	'fa-hourglass-half fa-duotone': [],
	'fa-house fa-duotone': [
		'HOUSE',
	],
	'fa-id-card fa-regular': [],
	'fa-ideal fa-brands': [],
	'fa-image fa-solid': [
		'IMAGE_UPLOAD',
	],
	'fa-inbox fa-duotone': [
		'BOOKING_INQUIRY',
		'DESK',
	],
	'fa-info fa-duotone': [
		'MORE_INFO',
	],
	'fa-info-circle fa-duotone': [],
	'fa-instagram fa-brands': [
		'BRAND_INSTAGRAM',
	],
	'fa-internet-explorer fa-brands': [
		'BRAND_INTERNET_EXPLORER',
	],
	'fa-island-tropical fa-duotone': [
		'HOLIDAYS',
	],
	'fa-key fa-duotone': [
		'KEY',
	],
	'fa-laptop fa-duotone': [
		'ONLINE_PAYMENT',
	],
	'fa-layer-group fa-duotone': [
		'ITEMS_SHIFT_MODELS',
	],
	'fa-light-coins-slash fa-kit': [],
	'fa-lightbulb fa-duotone': [
		'TIP',
		'AREA_TUTORIALS',
	],
	'fa-lightbulb fa-solid': [],
	'fa-link fa-duotone': [
		'LINK',
	],
	'fa-link fa-solid': [],
	'fa-link-slash fa-duotone': [
		'UNLINK',
	],
	'fa-list fa-duotone': [
		'LIST',
	],
	'fa-location-dot fa-duotone': [],
	'fa-lock fa-duotone': [],
	'fa-lock fa-solid': [
		'LOCKED',
	],
	'fa-lock-keyhole-open fa-duotone': [
		'UNLOCKED',
	],
	'fa-long-arrow-alt-down fa-duotone': [],
	'fa-long-arrow-alt-right fa-duotone': [],
	'fa-magnifying-glass fa-solid': [
		'SEARCH',
	],
	'fa-magnifying-glass-minus fa-duotone': [
		'ZOOM_OUT',
	],
	'fa-magnifying-glass-plus fa-duotone': [
		'ZOOM_IN',
	],
	'fa-map-signs fa-duotone': [
		'NOT_FOUND',
	],
	'fa-mars fa-duotone': ['MALE'],
	'fa-megaphone fa-duotone': [
		'MARKETING',
	],
	'fa-megaphone fa-solid': [
		'MARKETING_BADGE',
	],
	'fa-merge fa-duotone': [],
	'fa-messages-question fa-duotone': [],
	'fa-minus fa-duotone': [],
	'fa-minus fa-solid': [
		'MINUS',
	],
	'fa-mobile-alt fa-duotone': [],
	'fa-money-check fa-solid': [
		'BANK_TRANSFER',
	],
	'fa-money-check-pen fa-duotone': [
		'TEXT_AREA',
	],
	'fa-mug-hot fa-duotone': [],
	'fa-not-equal fa-solid': [
		'DIFFERENT',
	],
	'fa-note-sticky fa-duotone': [
		'NOTE',
	],
	'fa-opera fa-brands': [],
	'fa-paintbrush fa-duotone': [
		'EDIT_COLOR',
	],
	'fa-paper-plane fa-duotone': [
		'SEND_EMAIL',
	],
	'fa-pause fa-duotone': [
		'PAUSE',
	],
	'fa-paypal fa-brands': [
		'BRAND_PAYPAL',
	],
	'fa-pen fa-duotone': [
		'EDIT',
		'CAN_SET',
	],
	'fa-pen fa-regular': [],
	'fa-percentage fa-duotone': [
		'PERCENTAGE',
	],
	'fa-percentage fa-light': [],
	'fa-person-chalkboard fa-duotone': [],
	'fa-person-digging fa-regular': [],
	'fa-phone fa-duotone': [
		'PHONE',
	],
	'fa-phone-volume fa-solid': [],
	'fa-piggy-bank fa-duotone': [],
	'fa-pipe fa-solid': [
		'PIPE_SEPARATOR',
	],
	'fa-play fa-duotone': [
		'EXECUTE',
	],
	'fa-plug fa-duotone': [],
	'fa-plus fa-duotone': [],
	'fa-plus fa-solid': [
		'ADD',
	],
	'fa-print fa-duotone': [
		'PRINTER',
	],
	'fa-question fa-duotone': [
		'QUESTION',
	],
	'fa-reply fa-duotone': [
		'REFUND',
	],
	'fa-right-left fa-duotone': [
		'EXCHANGE_SHIFT',
	],
	'fa-right-long fa-duotone': [
		'EXCHANGE_SHIFT_OFFER',
	],
	'fa-right-to-bracket fa-duotone': [
		'LOGIN',
	],
	'fa-rocket-launch fa-duotone': [],
	'fa-sack fa-duotone': [],
	'fa-safari fa-brands': [],
	'fa-scale-balanced fa-duotone': [],
	'fa-scale-unbalanced-flip fa-duotone': [],
	'fa-scissors fa-solid': [],
	'fa-screwdriver-wrench fa-duotone': [],
	'fa-search fa-solid': [],
	'fa-section fa-solid': [],
	'fa-share fa-duotone': [
		'PAYMENT',
	],
	'fa-shield-blank fa-duotone': [
		'SECURITY',
	],
	'fa-shield-check fa-duotone': [
		'PERMISSIONS',
	],
	'fa-sign-in-alt fa-duotone': [
		'NAV_EXTERNAL',
	],
	'fa-slot-machine fa-duotone': [
		'CODE_GENERATOR',
	],
	'fa-snooze fa-duotone': [],
	'fa-spinner-third fa-duotone': [
		'LOADING',
	],
	'fa-split fa-duotone': [],
	'fa-square fa-regular': [
		'CHECKBOX_UNSELECTED',
	],
	'fa-square-check fa-solid': [
		'CHECKBOX_SELECTED',
	],
	'fa-square-info fa-duotone': [
		'TAB_BASIC_DATA',
	],
	'fa-star fa-solid': [],
	'fa-star-half fa-solid': [],
	'fa-sterling-sign fa-duotone': [
		'GBP',
	],
	'fa-stopwatch fa-duotone': [
		'AREA_TIME_STAMP',
	],
	'fa-stopwatch fa-regular': [],
	'fa-swap fa-duotone': [],
	'fa-sync-alt fa-duotone': [],
	'fa-telescope fa-duotone': [],
	'fa-thumbs-down fa-light': [],
	'fa-thumbs-down fa-regular': [],
	'fa-thumbs-up fa-duotone': [
		'THUMBS_UP',
	],
	'fa-thumbs-up fa-light': [],
	'fa-thumbs-up fa-regular': [
	],
	'fa-times-circle fa-duotone': [],
	'fa-tools fa-solid':[],
	'fa-transgender-alt fa-duotone': ['DIVERSE_GENDER'],
	'fa-trash fa-duotone': [
		'DELETE',
		'TRASHED',
	],
	'fa-trash-arrow-up fa-duotone': [
		'RESTORE',
	],
	'fa-trophy fa-solid': [
		'PARTICIPATED',
	],
	'fa-umbrella-beach fa-duotone': [
		'ITEMS_LEAVE_VACATION',
	],
	'fa-university fa-duotone': [],
	'fa-up fa-duotone': [],
	'fa-up fa-solid': [],
	'fa-upload fa-duotone': [
		'UPLOAD',
	],
	'fa-user fa-duotone': [
		'ITEMS_MEMBER',
		'ITEMS_BOOKING_PERSON',
	],
	'fa-user-group fa-duotone': [
		'ITEMS_PARTICIPANTS',
	],
	'fa-user-lock fa-duotone': [],
	'fa-user-plus fa-solid': [],
	'fa-user-secret fa-duotone': [
		'TAB_DATA_PROTECTION',
	],
	'fa-user-shield fa-duotone': [],
	'fa-user-times fa-duotone': [],
	'fa-user-times fa-solid': [],
	'fa-users fa-duotone': [
		'ITEMS_MEMBERS',
		'ITEMS_PARTICIPANT',
	],
	'fa-users-slash fa-duotone': [],
	'fa-venus fa-duotone': ['FEMALE'],
	'fa-virus fa-duotone': [
		'VIRUS',
	],
	'fa-wallet fa-duotone': [],
	'fa-wand-magic-sparkles fa-duotone': [
		'AUTO_SCHEDULING',
	],
	'fa-webhook fa-duotone': [
		'API',
	],
	'fa-wrench fa-solid': [
		'IMPLEMENTATION',
	],
	'fa-xmark fa-solid': [
		'DISMISS',
		'CANCELED',
		'BOOKING_CANCELED',
		'BOOKING_DECLINED',
	],
	'fa-youtube fa-brands': [
		'BRAND_YOUTUBE',
	],

} as const satisfies PIconPool;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PlanoFaIconPoolKeys = keyof typeof enumsObject.PlanoFaIconPool;

/**
 * A reverse mapping of PlanoFaIconPool where the multiple values in the value turn into keys.
 */
export const PlanoFaIconContextPool = (() => {
	const reversedPool : Record<(typeof PlanoFaIconPool)[keyof typeof PlanoFaIconPool][number], keyof typeof PlanoFaIconPool> =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		{} as Record<(typeof PlanoFaIconPool)[keyof typeof PlanoFaIconPool][number], keyof typeof PlanoFaIconPool>;

	const objectEntries : [keyof typeof PlanoFaIconPool, (typeof PlanoFaIconPool)[keyof typeof PlanoFaIconPool]][] = [];

	for (const entry of Object.entries(PlanoFaIconPool)) {
		objectEntries.push([entry[0] as keyof typeof PlanoFaIconPool,entry[1]]);
	}

	for (const entry of objectEntries) {
		const [key, values] : [keyof typeof PlanoFaIconPool, (typeof PlanoFaIconPool)[keyof typeof PlanoFaIconPool]] = entry;
		for (const value of values) {
			reversedPool[value] = key;
		}
	}

	return reversedPool;
})();
