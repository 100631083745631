/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { ChangeDetectionStrategy, Component, EmbeddedViewRef, HostBinding, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogService } from '@plano/shared/core/log.service';
import { ModalContentComponent } from '@plano/shared/core/p-modal/modal-content.component';
import { PModalTemplateContext, PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { PModalContentComponent } from '@plano/shared/core/p-modal/p-modal-content/p-modal-content.component';
import { NonUndefined } from '@plano/shared/core/utils/null-type-utils';
import { isString } from 'underscore';

/** All the properties that can be set for e.g. open a confirm modal. */
export interface ModalContentOptions {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	description ?: string | TemplateRef<unknown> | null;

	/**
	 * Title to be shown in the header of the modal.
	 * Set it to null if you don't want to show a header.
	 */
	modalTitle ?: string | TemplateRef<unknown> | null;

	/** @see {@link PModalContentComponent['closeBtnLabel']} */
	closeBtnLabel ?: PModalContentComponent['closeBtnLabel'];

	/** @see {@link PModalContentComponent['closeBtnIcon']} */
	closeBtnIcon ?: PModalContentComponent['closeBtnIcon'];

	/** @see {@link PModalContentComponent['closeBtnTheme']} */
	closeBtnTheme ?: PModalContentComponent['closeBtnTheme'];

	/**
	 * Is the close button disabled?
	 */
	closeBtnDisabled ?: () => boolean;

	dismissBtnLabel ?: string;

	/**
	 * The instance of the pModalTemplateDirective that should be rendered as content of this component.
	 * Related: PLANO-178066
	 */
	pModalTemplate ?: PModalTemplateDirective;

	/**
	 * The template that should be rendered as footer of this component.
	 */
	footerTemplateRef ?: TemplateRef<unknown>;

	/**
	 * The context of .contentTemplateRef
	 * More Info: https://blog.angular-university.io/angular-ng-template-ng-container-ngtemplateoutlet/#templatecontext
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	contentTemplateContext ?: {[key : string] : any};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	footerTemplateContext ?: {[key : string] : any};

	/**
	 * Should the dismiss button be visible on the footer?
	 * If set to true it will still be possible to dismiss with the × button or a click outside the modal.
	 * @default true
	 */
	hideDismissBtn ?: boolean;

	/** PModalContentComponent#closeBtnTheme */
	icon ?: PModalContentComponent['icon'];
}

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector -- This is not meant to be used in a template.
	selector: '',
	templateUrl: './modal-default-template.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PModalDefaultTemplateComponent extends ModalContentComponent {
	@HostBinding('class.flex-grow-1')
	protected readonly _alwaysTrue = true;

	@ViewChild('content', { read: ViewContainerRef, static: true }) private contentViewContainerRef ! : ViewContainerRef;
	@ViewChild('footer', { read: ViewContainerRef, static: true }) private footerViewContainerRef ! : ViewContainerRef;

	constructor(
		private activeModal : NgbActiveModal,
		private console : LogService,
	) {
		super();
	}

	public modalContentOptions : ModalContentOptions = {};
	public theme : PModalTemplateContext['theme'] = null;
	protected isString = isString;

	/**
	 * Initializes the Modal with the necessary properties from its parent component.
	 * Set necessary default values, if not provided by the given options.
	 * @param options The options to initialize the modal with.
	 * @param theme The theme to use for the modal.
	 */
	public initModal(
		options : ModalContentOptions,
		theme : NonUndefined<PModalTemplateContext['theme']>,
	) : void {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!!options.description === !!options.pModalTemplate?.template) {
			const modalTitle = typeof options.modalTitle === 'string' ? options.modalTitle : null;
			const humanReadableIdentifier = modalTitle ?? options.icon ?? `with theme: ${theme}`;
			this.console.error(`Please set either description or contentTemplateRef on modal ${humanReadableIdentifier}`);
		}

		const contentTemplateRef = options.pModalTemplate?.template ?? (options.description instanceof TemplateRef ? options.description : null);

		this.modalContentOptions = {
			...options,

			// Make sure that the description is null if a contentTemplateRef is already provided
			description: contentTemplateRef ? null : (options.description ?? null),
		};

		if (theme !== null) this.theme = theme;

		if (contentTemplateRef) {
			// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			this.embeddedContentView = contentTemplateRef.createEmbeddedView(options.contentTemplateContext ? options.contentTemplateContext : {});
			this.contentViewContainerRef.insert(this.embeddedContentView);
		}

		if (options.footerTemplateRef) {
			// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			this.embeddedFooterView = options.footerTemplateRef.createEmbeddedView(options.footerTemplateContext ? options.footerTemplateContext : {});
			this.footerViewContainerRef.insert(this.embeddedFooterView);
		}

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		this.showCustomFooter = !!options.footerTemplateRef;
	}

	/** @deprecated HACK: quick fix for modals that have no footer,
	 *  since i implemented support for .footerViewContainerRef in modal-default-template */
	public showCustomFooter : boolean | null = null;

	/**
	 * A handle to control the embedded view inside this component.
	 *
	 * NOTE: 	Do not handle this.embeddedView ( e.g. .destroy() ) inside this component.
	 *  			Handle it where a modal has been opened.
	 */
	public embeddedContentView : EmbeddedViewRef<unknown> | undefined;

	public embeddedFooterView : EmbeddedViewRef<unknown> | undefined;

	/**
	 * Close this modal
	 */
	public onClose() : void {
		this.activeModal.close();
	}

	/**
	 * Dismiss this modal
	 */
	public onDismiss() : void {
		this.activeModal.dismiss();
	}

	/** Is close button disabled? */
	public get closeBtnDisabled() : boolean {
		return !!this.modalContentOptions.closeBtnDisabled?.();
	}
}
