<ng-container [ngSwitch]="level">
	<h1 *ngSwitchCase="1" [class]="headlineClasses"><ng-container *ngTemplateOutlet="contentTemplate" /></h1>
	<h2 *ngSwitchCase="2" [class]="headlineClasses"><ng-container *ngTemplateOutlet="contentTemplate" /></h2>
	<h3 *ngSwitchCase="3" [class]="headlineClasses"><ng-container *ngTemplateOutlet="contentTemplate" /></h3>
	<h4 *ngSwitchCase="4" [class]="headlineClasses"><ng-container *ngTemplateOutlet="contentTemplate" /></h4>
	<h5 *ngSwitchCase="5" [class]="headlineClasses"><ng-container *ngTemplateOutlet="contentTemplate" /></h5>
	<h6 *ngSwitchCase="6" [class]="headlineClasses"><ng-container *ngTemplateOutlet="contentTemplate" /></h6>
</ng-container>

<ng-template #contentTemplate>
	<ng-content />
</ng-template>
