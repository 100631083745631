/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { ApiDataWrapperBase, ApiListWrapper, SchedulingApiMember } from '@plano/shared/api';
import { DateTime } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { BirthdayService } from './birthday.service';
import { SchedulingApiService } from './scheduling-api.service';

/**
 * A birthday item contains some basic data like start, end, the id of the related member, etc.
 * This should help to write cleaner templates than if we would have to use only the lists and objects that are
 * defined by the {@link SchedulingApiService}.
 */
export class SchedulingApiBirthday {
	public isHovered : boolean = false;

	public day ! : 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31;

	public month ! : 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

	public firstName ! : SchedulingApiMember['firstName'];
	public lastName ! : SchedulingApiMember['lastName'];
	public memberId ! : SchedulingApiMember['id'];

	/**
	 * @see SchedulingApiMember#id
	 */
	public get id() : Id {
		return this.memberId;
	}

	/**
	 * Support scrollToFragment so it matches other elements.
	 */
	public get scrollToFragment() : string {
		return this.id.toString();
	}

	/**
	 * The start of the birthday.
	 */
	public get start() : DateTime {
		const birthdayDay = this.day;
		const birthdayMonth = this.month;
		const momentService = new PMomentService(Config.LOCALE_ID);
		const birthday = momentService.m().startOf('day').set('month', birthdayMonth).set('date', birthdayDay);
		return +birthday;
	}

	/**
	 * The end of the birthday.
	 */
	public get end() : DateTime {
		const momentService = new PMomentService(Config.LOCALE_ID);
		return momentService.m(this.start).add(1, 'day').valueOf();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public startBasedOnCalendarRequest(calendarRequestStart : number, pMomentService : PMomentService) : number {
		const lastRequestedDate = calendarRequestStart;
		const lastRequestedMoment = pMomentService.m(lastRequestedDate);
		if (this.month < 7 && +lastRequestedMoment.get('month') >= 7) lastRequestedMoment.add(1, 'year').startOf('day');
		return +lastRequestedMoment.set('month', this.month).set('date', this.day);
	}

}

/**
 * A list of birthday items.
 * @see {@link SchedulingApiBirthday}
 * Note that the api does not offer anything like a Birthday list, so we don‘t have a typical base class like
 * SchedulingApiBirthdaysBase here.
 */
export class SchedulingApiBirthdays extends ApiListWrapper<SchedulingApiBirthday> {
	constructor(
		public birthdayService : BirthdayService | null,
		public override api : SchedulingApiService,
		parent : ApiDataWrapperBase | null,
		removeDestroyedItems : boolean,
	) {
		super(api, parent, true, removeDestroyedItems);
	}

	protected override containsPrimitives() : boolean {
		return false;
	}

	protected override createInstance(parent : ApiDataWrapperBase | null, _isView : true, removeDestroyedItems : boolean) : typeof this {
		return new SchedulingApiBirthdays(this.birthdayService, this.api, parent, removeDestroyedItems) as typeof this;
	}

	protected override containsIds() : boolean {
		return false;
	}

	protected override get dni() : string {
		return '0';
	}

	/** @see ApiListWrapper#push */
	public override push(birthday : SchedulingApiBirthday) : void {
		super.push(birthday);
		this.birthdayService?.changed(null);
	}

	private _getByDay = new Map<number, Data<SchedulingApiBirthdays>>();

	/**
	 * get birthdays of day
	 * This includes all birthdays happen at the provided day.
	 * @param dayStart - timestamp of the desired day
	 */
	public getByDay(dayStart : number) : SchedulingApiBirthdays {
		if (!this._getByDay.has(dayStart)) {
			this._getByDay.set(dayStart, new Data(this.api));
		}
		return this._getByDay.get(dayStart)!.get(() => {
			Assertions.ensureIsDayStart(dayStart);

			const moment = new PMomentService(Config.LOCALE_ID).m(dayStart);
			return this.filterBy(item => {
				const month = moment.get('month');
				const date = moment.get('date');
				if (item.month !== month) return false;
				if (item.day !== date) return false;
				return true;
			});
		});
	}
}
