<ng-container
	[pEditable]="pEditable"
	[api]="api"
	[valid]="valid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit($event)"
	(editMode)="editMode.emit($event)"
	(beforeApiChanges)="beforeApiChanges.emit($event)"
>
	<button
		#innerBtn
		[title]="btnTitle"
		[style.display]="!show ? 'none!important' : undefined"
		type="button"
		[attr.role]="btnRole"
		[class.progress-bar-striped]="isLoading"
		[class.progress-bar-animated]="isLoading"
		class="position-relative btn d-flex gap-{{gap}} {{alignItemsClass}} {{justifyClass}} {{flexGrowClass}} {{btnThemeClass}} {{btnClasses}}"
		[attr.id]="btnId"
		[attr.aria-checked]="ariaChecked"

		[pCloseOnEsc]="pCloseOnEscBtn && !pCloseOnEscBtnAnimate"
		[pCloseOnEscType]="pCloseOnEscType"
		[pCloseOnEscTriggerVisible]="pCloseOnEscTriggerVisible"
		[animate]="false"

		[pTooltip]="btnTooltip"

		pAutoFocus

		(focus)="focus.emit()"
		(blur)="blur.emit()"

		pEditableInstantSaveOnClick
		(triggerClick)="isLoading ? undefined : triggerClick.emit($event)"

		[class.required]="required"
		[class.has-danger]="hasDanger"

		[class.dark-mode]="darkMode"

		[class.btn-sm]="size === enums.BootstrapSize.SM"
		[class.btn-lg]="size === enums.BootstrapSize.LG"
		[class.btn-frameless]="frameless"
		[class.rounded-pill]="pillClass === true"
		[class.rounded-start-pill]="pillClass === 'start'"
		[class.active]="isActiveButton || (isLoading && theme === enums.PThemeEnum.SECONDARY)"
		[class.btn-toggle]="buttonType === PButtonType.TOGGLE"
		[class.btn-filter]="buttonType === PButtonType.FILTER"

		[class.striped-info]="stripesTheme === enums.PThemeEnum.INFO"
		[class.striped-primary]="stripesTheme === enums.PThemeEnum.PRIMARY"

		[class.text-muted]="textStyle === PTextColorEnum.MUTED"
		[class.text-primary]="textStyle === enums.PThemeEnum.PRIMARY"
		[class.text-secondary]="textStyle === enums.PThemeEnum.SECONDARY"
		[class.text-success]="textStyle === enums.PThemeEnum.SUCCESS"
		[class.text-info]="textStyle === enums.PThemeEnum.INFO"
		[class.text-warning]="textStyle === enums.PThemeEnum.WARNING"
		[class.text-light]="textStyle === enums.PThemeEnum.LIGHT"
		[class.text-dark]="textStyle === enums.PThemeEnum.DARK"
		[class.text-danger]="textStyle === enums.PThemeEnum.DANGER"
		[class.text-white]="textStyle === PTextColorEnum.WHITE"
		[class.text-start]="textAlign === 'left'"
		[class.text-end]="textAlign === 'right'"
		[class.rounded-end]="rounded === BootstrapRounded.RIGHT"
		[class.rounded-start]="rounded === BootstrapRounded.LEFT"
		[class.rounded-start-0]="rounded === BootstrapRounded.START_0"
		[class.rounded-end-0]="rounded === BootstrapRounded.END_0"
		[class.rounded-0]="rounded === BootstrapRounded.NONE"

		[disabled]="disabled || !canSet"
		[attr.aria-label]="ariaLabel"
	>
		<ng-container *ngIf="badgeOrIconPosition === 'start'"><ng-container *ngTemplateOutlet="badgeOrIconTemplate" /></ng-container>
		<div class="ng-content-wrapper {{ngContentWrapperClass}} gap-{{gap}} {{flexGrowClassForNgContentWrapper}} {{alignItemsClassForNgContentWrapper}} {{justifyClassForNgContentWrapper}}">
			<ng-content />
		</div>
		<ng-container *ngIf="badgeOrIconPosition === 'end'"><ng-container *ngTemplateOutlet="badgeOrIconTemplate" /></ng-container>
	</button>
	@if (!!disabled && !!cannotSetHint && !isLoading) {
		<p-button
			class="flex-grow-0 btn-group-append"
			[frameless]="frameless"
			[size]="size"
			[theme]="theme"
			(triggerClick)="openCannotSetHint()"
			[icon]="enums.PlanoFaIconContextPool.MORE_INFO"
			btnTitle="Info" i18n-btnTitle
		/>
	}
	@if (btnAppendTemplate !== null) {
		<button
			class="btn flex-grow-0 flex-shrink-0 {{btnAppendThemeClass}} {{btnAppendClasses}}"
			(click)="btnAppendClicked.emit($event)"
			[pTooltip]="btnAppendTooltip"
		>
			<ng-container *ngTemplateOutlet="btnAppendTemplate" />
		</button>
	}
</ng-container>
<ng-template #badgeOrIconTemplate>
	@if (!!badge) {
		<p-badge
			[content]="badge"
			[badgeAttachTopPosition]="badgeAttachTopPosition"
			[size]="size ?? null"
		/>
	}
	@if (!!icon) {
		<fa-icon
			[pCloseOnEsc]="pCloseOnEscBtn && pCloseOnEscBtnAnimate"
			[pCloseOnEscType]="pCloseOnEscType"
			[pCloseOnEscTriggerVisible]="pCloseOnEscTriggerVisible"
			[animate]="true"

			[icon]="icon"
			[theme]="iconTheme"
			[spin]="iconSpin"
			[size]="iconSize"
		/>
	}
</ng-template>
