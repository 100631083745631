import { Directive, HostBinding } from '@angular/core';

/**
 * Directive to crop the content of an element when it overflows.
 */
@Directive({
	selector: '[pCropOnOverflow], [pTooltipOnOverflow]',
	standalone: true,
})
export class PCropOnOverflowDirective {
    @HostBinding('class.crop-on-overflow') public readonly _alwaysTrue = true;
}
