/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable } from '@angular/core';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';

/**
 * A service to turn text into html and crop it if wanted.
 */
@Injectable( { providedIn: 'root' } )
export class TextToHtmlService {
	constructor(
		private localize : LocalizePipe,
	) {
	}

	/**
	 * Is the text longer then the allowed maximum?
	 */
	private trimText(
		text : string,
		maxTextLength : number,
		addTrailingHint ?: boolean,
	) : string {
		if (text.length <= maxTextLength) return text;
		let result = text.slice(0, maxTextLength).trim();
		if (addTrailingHint) result += `… <a class="link" tabindex="0">${this.localize.transform('Alles anzeigen')}</a>`;
		return result;
	}

	/**
	 * Is the text longer then the allowed maximum?
	 */
	private cutLineBreaks(
		text : string,
		maxLines : number,
		addTrailingHint ?: boolean,
	) : string {
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const lineBreaksCount = text.replaceAll(/[^\n]/g, '').length;
		if (lineBreaksCount < maxLines) return text;

		// get position of nth line-break
		const delimiter = '\n';
		const pos = text.split(delimiter, maxLines).join(delimiter).length;
		let result = text.slice(0, pos).trim();
		if (addTrailingHint) result += `… <a class="link" tabindex="0">${this.localize.transform('Alles anzeigen')}</a>`;

		return result;
	}

	/**
	 * Turn the text into html [and crop it if wanted]
	 * @param text The text to turn into html
	 * @param inputMaxTextLength
	 * 	The maximal length of the text. Is the text longer, then it will be cropped.
	 * 	You can set this to false if you never want the text to be cropped.
	 * @param inputMaxLines
	 * 	The maximal amount of lines of the text. Is the text longer, then it will be cropped.
	 * 	You can set this to false if you never want the text to be cropped.
	 * @param addTrailingHint
	 * 	If the text gets cropped, a »read more…« text will be added at the end.
	 * 	You can pass a function here that will be executed when the user clicks on the »read more…« text.
	 */
	public textToHtml(
		text : string,
		inputMaxTextLength ?: number | boolean,
		inputMaxLines ?: number | boolean,
		addTrailingHint : boolean = true,
	) : string {
		let result : string = text;

		let maxTextLength : number | false;
		let maxLines : number | false;

		if (inputMaxTextLength === undefined || inputMaxTextLength === true) {
			maxTextLength = 100;
		} else {
			maxTextLength = inputMaxTextLength;
		}

		if (inputMaxLines === undefined || inputMaxLines === true) {
			maxLines = 4;
		} else {
			maxLines = inputMaxLines;
		}

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (maxTextLength) result = this.trimText(result, maxTextLength, addTrailingHint);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (maxLines) result = this.cutLineBreaks(result, maxLines, addTrailingHint);

		result = result.replaceAll('\n', '<br>');

		return result;
	}
}
