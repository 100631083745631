import { Injectable, NgZone } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PDropdownService } from '@plano/shared/p-forms/p-dropdown/p-dropdown.service';

/**
 * Service that provides a method "refreshFocus" that can be called
 * from the outside to check if some new element should be focused.
 *
 * This method can be used to when the modal is already open but we want to change
 * the focus element. This happens for example in the change-selectors-modal, because
 * there we have modals with multiple steps which require focus to be calculated
 * whenever the step changes.
 */
@Injectable({ providedIn: 'root' })
export class PFocusService {

	constructor(
		private modalService : ModalService,
		private pDropdownService : PDropdownService,
		private zone : NgZone,
	) {
		// subscribe to when a modal is opened through the modal service
		this.modalService.stateOpenSubject.subscribe(() => {
			this.refreshModalFocus();
		});

		// subscribe to when a dropdown is opened through the dropdown service
		this.pDropdownService.stateOpenSubject.subscribe((dropdownContentElementRef) => {
			this.initDropdownFocus(dropdownContentElementRef.nativeElement);
		});
	}

	private AUTO_FOCUS_SELECTOR = '.p-auto-focus:not(.disabled)';

	private getVisibleElements(
		htmlElement : HTMLElement,
		selectors : string[],
	) : HTMLElement[] | null {
		const selector = selectors.join(',');
		const elements = [...htmlElement.querySelectorAll<HTMLElement>(selector)];

		// offsetParent returns null if the element is not visible. This is enough for now, but should be replaced by the
		// more precise .checkVisibility() method in the future. We can not use the .checkVisibility() method right now,
		// because it is not supported by iOS 16. So we have to wait for the iOS 19 release first as we support the 3 last
		// major versions.
		// TODO: PLANO-188267 use .checkVisibility() instead of .offsetParent
		const result = elements.filter(element => element.offsetParent);

		return result.length > 0 ? result : null;
	}

	/**
	 * All elements that are focussable (enabled & visible) inside the provided HTMLElement
	 * @param htmlElement The element to check
	 */
	public getAutoFocussableElements(htmlElement : HTMLElement) : HTMLElement[] | null {
		return this.getVisibleElements(htmlElement, [this.AUTO_FOCUS_SELECTOR]);
	}

	/**
	 * All elements that are focussable (enabled & visible) inside the provided HTMLElement
	 * @param htmlElement The element to check
	 */
	public getFocussableElements(htmlElement : HTMLElement) : HTMLElement[] | null {
		// cSpell:words contenteditable
		return this.getVisibleElements(htmlElement, [
			this.AUTO_FOCUS_SELECTOR,
			'a[href]',
			'button:not([disabled])',
			`input:not([disabled]):not([type="hidden"])`,
			'select:not([disabled])',
			'textarea:not([disabled])',
			'area[href]',
			'iframe',
			'object',
			'embed',
			'[contenteditable]',
			`[tabindex]:not([tabindex="-1"])`,
		]);
	}

	/**
	 * Get the element that should be focussed with the highest priority.
	 * @param htmlElement The element to check
	 */
	private getFirstElementToFocus(
		htmlElement : HTMLElement,
	) : HTMLElement | null {
		const focussableElements = this.getAutoFocussableElements(htmlElement);
		if (!focussableElements) return null;

		const focussableElementsArray = Array.from(focussableElements);
		const elementWithPriority = focussableElementsArray.find(item => item.classList.contains('priority-auto-focus'));
		return elementWithPriority ?? focussableElementsArray[0];
	}

	/**
	 * Refresh the focussed element by performing the necessary logics to decide which element, if any, should be focused.
	 */
	public refreshModalFocus() {
		// select the last opened modal
		const modalElement = Array.from(document.querySelectorAll<HTMLElement>('ngb-modal-window')).at(-1)!;

		// Dont set the focus inside the htmlElement on mobile, focus on htmlElement instead to avoid that a close button gets focused
		// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
		if (Config.IS_MOBILE) {
			modalElement.focus();
			return;
		}

		// Get the element that should be focussed with the highest priority.
		const focusableElement = this.getFirstElementToFocus(modalElement);

		if (focusableElement) {
			// check if the first focusable element is visible, if not, no focus should happen
			const focusElementObserver = new IntersectionObserver((entries, observer) => {
				if (entries[0].isIntersecting) {
					focusableElement.focus();
					observer.disconnect();
				} else {
					// focus the modal window if no auto focus could be set to avoid that the close button gets focused
					modalElement.focus();
					observer.disconnect();
				}
			}, {
				// focus only if the element is fully visible
				threshold: 1.0,
			});
			focusElementObserver.observe(focusableElement);
		} else {
			// focus the outer element if no auto-focus could be set to avoid that the close button gets focused
			modalElement.focus();
		}
	}

	/**
	 * Focus the element with the highest autofocus priority inside the dropdown-content.
	 * It will possibly be scrolled into view to maker sure it is visible.
	 * @param dropdownContentElement The dropdown-content element that just got opened
	 */
	private initDropdownFocus(dropdownContentElement : HTMLElement) {
		this.zone.runOutsideAngular(() => {

			// Get the element that should be focussed with the highest priority.
			const focusableElement = this.getFirstElementToFocus(dropdownContentElement);
			if (focusableElement) {

				const shouldPreventScrolling = this.shouldPreventScrollOnElement(focusableElement);
				if (!shouldPreventScrolling)
					focusableElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });

				focusableElement.focus({preventScroll: shouldPreventScrolling});
			}
		});
	}

	/**
	 * Should we prevent scrolling to the element when it gets focused?
	 * @param element The element to check
	 */
	public shouldPreventScrollOnElement(element : HTMLElement) : boolean {
		// If the element is inside a sticky headline, we don't want to scroll to it, as it is already visible.
		const stickyElements = document.querySelectorAll('.sticky-headline');
		let hasStickyParent = false;
		for (const stickyElement of stickyElements) {
			if (stickyElement.contains(element)) {
				hasStickyParent = true;
				break;
			}
		}
		return hasStickyParent;
	}
}
