/* eslint-disable @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */

/** **************************************************************************************************
 * NOTE: If you add an polyfill here, remember to mark it with some 'unicorn/expiring-todo-comments'
 * to make clear how long it will be necessary to stay here.
 ***************************************************************************************************/

/*
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/**
 * https://github.com/angular/angular/blob/master/packages/zone.js/README.md
 */
import 'zone.js';

/**
 * More or less this polyfill:
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#polyfill
 */
// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
if (!Element.prototype.matches as unknown) {
	Element.prototype.matches = (
		(Element.prototype as any).matchesSelector ||
		(Element.prototype as any).mozMatchesSelector ||
		(Element.prototype as any).msMatchesSelector ||
		(Element.prototype as any).oMatchesSelector ||
		(Element.prototype as any).webkitMatchesSelector
	);
}
