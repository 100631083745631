import { USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';

export const SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	prefix: 'schedulingApiBasedPages',
	name: 'calendarMode',
} as const;

export type SchedulingApiBasedPagesIndexedDBKeyDataType =
    typeof SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY;
