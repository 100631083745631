/**
 * Does the content match the search term?
 *
 * @param content The content to check
 * @param searchTerm The search terms to check against. E.g. a space separated string the user typed into a search input.
 * @returns `null` if there is no search term, `true` if it matches, `false` if it doesn’t.
 */
export const stringMatchesSearchTerm = (
	content : string,
	searchTerm : string,
) => {
	const termLow = searchTerm.toLowerCase();
	const TERMS = termLow.split(' ');

	for (const TERM of TERMS) {
		if (!TERM) continue;
		const itemLow = content.toLowerCase();

		// If the term is included in the content, the content does match. So we continue to check other terms.
		if (itemLow.includes(TERM)) continue;

		// If the previous conditions did not match, then the current TERM does not fit to the content.
		// As soon as one of the terms does not fit, the content is not considered matching.
		return false;
	}

	return true;
};
