<div
	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	#pEditableRef="pEditable"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit()"
	(editMode)="editMode.emit($event)"
	(beforeApiChanges)="beforeApiChanges.emit($event);ensureTrimmedValue()"
>
	<div class="input-group"
		[class.input-group-sm]="size === enums.BootstrapSize.SM"
		[class.input-group-lg]="size === enums.BootstrapSize.LG"
		[class.has-danger]="control && control.touched && control.invalid"
	>
		<ng-container *ngTemplateOutlet="prependItem" />
		<ng-container *ngIf="!readMode">
			<textarea
				[class.d-none]="readMode"
				pAutoFocus

				autosize
				[maxHeight]="'300px'"

				pEditableTriggerFocussable

				[(ngModel)]="value"
				[placeholder]="placeholder ?? ''"
				class="form-control"
				[disabled]="control ? control.disabled : disabled"
				[required]="hasRequiredError"
				[class.required]="hasRequiredError"
				(focusout)="focusout.emit($event)"
				(focus)="focus.emit($event)"
				[attr.aria-disabled]="pEditableRef?.dismissAnimationRunning || pEditableRef?.successAnimationRunning"
				(change)="onChange($event)"
				(keyup)="onKeyUp($event)"
				(blur)="onBlur($event)"
			></textarea>
			<ng-container
				*ngIf="pEditable && (!pEditableRef?.pEditable || pEditableRef?.showBtns)"
			>
				<button
					class="input-group-append"
					pEditableDismissButton
				><fa-icon [icon]="enums.PlanoFaIconContextPool.UNDO" /></button>
				<button
					class="input-group-append"
					pEditableSuccessButton
				><fa-icon [icon]="enums.PlanoFaIconContextPool.SUCCESS" /></button>
			</ng-container>
		</ng-container>

		<ng-template [ngIf]="readMode">
			<blockquote class="form-control-read-mode" [class.text-muted]="!value"><span [innerHTML]="valueAsHtml | pSafeHtml"></span></blockquote>
		</ng-template>
		<ng-container *ngTemplateOutlet="appendItem" />
	</div>
</div>

<ng-template [ngIf]="!!control">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="control"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[checkTouched]="checkTouched"
	/>
</ng-template>

<ng-template #prependItem>
	<ng-content select=".input-group-prepend" />
</ng-template>
<ng-template #appendItem>
	<ng-content select=".input-group-append" />
</ng-template>
