/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { ClientCurrency, Euro } from '@plano/shared/api/base/generated-types.ag';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PMath {

	/**
	 * @param value The value to be rounded.
	 * @param decimalPlaces The number of digits on the right side of the comma.
	 * @returns Returns `value` rounded.
	 */
	public static roundToDecimalPlaces(value : number, decimalPlaces : number) : number {
		const multiplier = Math.pow(10, decimalPlaces);
		return Math.round(value * multiplier) / multiplier;
	}

	/**
	 * @param value The value to be cut.
	 * @param decimalPlaces The number of digits on the right side of the comma.
	 * @returns Returns `value` cut to the wanted amount of decimal points.
	 */
	public static cutToDecimalPlaces(value : number, decimalPlaces : number) : number {
		const valueAsString = value.toString();
		if (valueAsString.includes('e+')) {
			throw new Error('Support for astronomical numbers not implemented yet ;)');
		}
		if (!valueAsString.includes('.')) return value;
		const result = valueAsString.toString().slice(0, valueAsString.indexOf('.') + decimalPlaces);
		return Number(result);
	}

	/**
	 * Subtract two floats and round them.
	 *
	 * Reason why you should use this method instead of simply `-`:
	 * It is type-save and calculations with floats in js can be difficult.
	 * Some floats can not be mapped to binary code exactly.
	 * One effect: In js 1 - 0.7 gives you 0.30000000000000004
	 * More Info: https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
	 */
	public static subtractCurrency(value1 : ClientCurrency | Euro, value2 : ClientCurrency | Euro) : ClientCurrency | Euro {
		return PMath.roundToDecimalPlaces(value1 - value2, 2);
	}

	/**
	 * Add two floats and round them.
	 *
	 * Reason why you should use this method instead of simply `-`:
	 * It is type-save and calculations with floats in js can be difficult.
	 * Some floats can not be mapped to binary code exactly.
	 * One effect: In js 1 - 0.7 gives you 0.30000000000000004
	 * More Info: https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
	 */
	public static addCurrency(value1 : ClientCurrency | Euro, value2 : ClientCurrency | Euro) : ClientCurrency | Euro {
		return PMath.roundToDecimalPlaces(value1 + value2, 2);
	}

	/**
	 * Get the amount of digits after the decimal separator.
	 *
	 * @param value The value to check.
	 * @returns The amount of digits after the decimal separator.
	 */
	public static amountOfDigitsAfterDecimalSeparator(
		value : number,
	) : number {
		const valueAsString = value.toString();
		return valueAsString.split('.')[1]?.length ?? 0;
	}
}
