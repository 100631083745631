import { Injectable } from '@angular/core';
import { PFocusService } from '@plano/shared/core/p-auto-focus/p-focus.service';
import { PGlobalEventListenersService } from '@plano/shared/core/p-global-event-listeners.service';
import { assume } from '@plano/shared/core/utils/null-type-utils';

/**
 * Service that provides methods that add more convenience for the people who are required to or prefer to use the keyboard.
 */
@Injectable({ providedIn: 'root' })
export class PKeyboardService {

	constructor(
		private pGlobalEventListenersService : PGlobalEventListenersService,
		private pFocusService : PFocusService,
	) {
		this.pGlobalEventListenersService.globalKeyDownEvent.subscribe((event) => {
			this.handleKeyboardNavigationForDropdown(event);
		});
	}

	/**
	 * This handles the navigation for dropdowns (menus and comboboxes).
	 * Note that if the focus is on a combobox button which is placed inside a menu, the menu navigation has the higher
	 * priority, as it is the least surprising behavior.
	 * @param event The keyboard event that triggered this method
	 */
	private handleKeyboardNavigationForDropdown(event : KeyboardEvent) {
		const currentTarget = event.target as HTMLElement;

		// If this is part of a menu, handle the keyboard navigation for the menu
		// If this is a combobox, handle the keyboard navigation for the combobox
		const targetWrappingMenu = this.getTargetWrappingMenu(currentTarget);
		if (targetWrappingMenu) {
			this.handleKeyboardNavigationForMenu(event, targetWrappingMenu);
		} else if (currentTarget.role === 'combobox') {
			this.handleArrowKeyNavigationForCombobox(event);
		}
	}

	/**
	 * If the focus is on a combobox button, it should be possible to open the menu with the arrow keys
	 * @param event The keyboard event that triggered this method
	 */
	private handleArrowKeyNavigationForCombobox(
		event : KeyboardEvent,
	) {
		const currentTarget = event.target as HTMLElement;
		assume(currentTarget.role === 'combobox');

		switch (event.key) {
			case 'ArrowDown':
			case 'ArrowUp':
				currentTarget.click();
				event.preventDefault();
		}
	}

	/**
	 * If the focus is inside a menu, it should be possible to navigate through the menu with the keyboard
	 * @param event The keyboard event that triggered this method
	 * @param menu The menu that the focus is currently in
	 */
	private handleKeyboardNavigationForMenu(
		event : KeyboardEvent,
		menu : HTMLElement,
	) {
		const currentTarget = event.target as HTMLElement;
		if (
			event.key === 'ArrowDown' ||
			event.key === 'Tab' && !event.shiftKey
		) {
			this.focusAnotherElement('next', currentTarget, menu);
			event.preventDefault();
		} else if (
			event.key === 'ArrowUp' ||
			event.key === 'Tab' && event.shiftKey
		) {
			this.focusAnotherElement('previous', currentTarget, menu);
			event.preventDefault();
		}
	}

	/**
	 * Focus another element relative to the one which currently has index.
	 * @param input The direction to focus in
	 * @param currentTarget The element to focus relative to
	 * @param targetWrappingMenu The menu that wraps the target element
	 */
	private focusAnotherElement(
		input : 'previous' | 'next',
		currentTarget : HTMLElement,
		targetWrappingMenu : HTMLElement,
	) {
		const focussableElementsInsideMenu = this.pFocusService.getFocussableElements(targetWrappingMenu);
		if (!focussableElementsInsideMenu) return;

		const elementsSortedByTabIndex = focussableElementsInsideMenu.sort((a, b) => {
			return a.tabIndex - b.tabIndex;
		});

		const currentIndex = elementsSortedByTabIndex.indexOf(currentTarget);

		// Focus the next index
		const indexOfElementToFocus = (currentIndex + (input === 'next' ? 1 : -1)) % elementsSortedByTabIndex.length;
		const elementToFocus = elementsSortedByTabIndex.at(indexOfElementToFocus);
		if (elementToFocus) {
			const shouldPreventScrolling = this.pFocusService.shouldPreventScrollOnElement(elementToFocus);
			elementToFocus.focus({preventScroll: shouldPreventScrolling});
		}
	}

	private getTargetWrappingMenu(targetElement : HTMLElement) {
		// get closest element with role menu
		return targetElement.closest<HTMLElement>('[role=menu]');
	}
}
