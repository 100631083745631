<div
	pModalTemplate
	[pModalId]="null"
	[theme]="theme"
>
	<p-modal-content
		[modalTitle]="modalContentOptions.modalTitle"
		[icon]="modalContentOptions.icon ?? null"
		[dismissBtnLabel]="modalContentOptions.dismissBtnLabel ?? null"
		[closeBtnLabel]="modalContentOptions.closeBtnLabel ?? null"
		[closeBtnIcon]="modalContentOptions.closeBtnIcon ?? null"
		[closeBtnTheme]="modalContentOptions.closeBtnTheme ?? null"
		[hideDismissBtn]="!!modalContentOptions.hideDismissBtn"
		[closeBtnDisabled]="closeBtnDisabled"

		[showCustomFooter]="showCustomFooter"
		(onDismiss)="onDismiss()"
		[hideCloseBtn]="!modalContentOptions.closeBtnLabel"
		(onClose)="onClose()"
	>
		<p-modal-content-body>
			@if (isString(modalContentOptions.description)) {
				<p [innerHTML]="modalContentOptions.description | pSafeHtml"></p>
			} @else {
				<ng-container *ngTemplateOutlet="modalContentOptions.description ?? null" />
			}
			<ng-container #content />

		</p-modal-content-body>

		<p-modal-content-footer>
			<ng-container #footer />
		</p-modal-content-footer>
	</p-modal-content>

</div>
