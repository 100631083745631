/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { Directive, HostBinding } from '@angular/core';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { PBaseClass } from '@plano/shared/base';
import { LogService } from '@plano/shared/core/log.service';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';

let instanceIdStore = 0;

/**
 * A directive for components that need to set the aria-labelledby attribute.
 * It will generate a unique id and set it as the value of the aria-labelledby attribute of the host.
 * The id will be provided as public property `ariaLabelHtmlId`, so you can use it in your template.
 *
 * @example
 * foo.component.ts:
 *   …
 *   class FooComponent extends UniqueAriaLabelByDirective {
 *     …
 * foo.component.html:
 *   <div>
 *     <div>…other stuff…</div>
 * 	   <label [id]="ariaLabelHtmlId">…</label>
 * 	   <div>…other stuff…</div>
 *  </div>
 */
@Directive()
export class UniqueAriaLabelByDirective extends PBaseClass {
	@HostBinding('attr.aria-labelledby') public ariaLabelHtmlId : `aria-label-${number}`;

	constructor() {
		super();
		this.ariaLabelHtmlId = `aria-label-${++instanceIdStore}`;
	}
}

/**
 * @see ControlWithEditableDirective
 *
 * This is the best way we could find to make it possible to make a class extend
 * UniqueAriaLabelByDirective as well as ControlWithEditableDirective. If you think you have a
 * better idea, please let us know.
 */
@Directive()
export class UniqueAriaLabelByWithControlWithEditableDirective extends ControlWithEditableDirective {
	@HostBinding('attr.aria-labelledby') public ariaLabelHtmlId : `aria-label-${number}`;

	constructor(
		attributeInfoRequired : boolean = true,
		protected override pFormsService : PFormsService,
		protected override console ?: LogService,
	) {
		super(attributeInfoRequired, pFormsService, console);
		this.ariaLabelHtmlId = `aria-label-${++instanceIdStore}`;
	}
}
