// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This component is part of of PCommonModule, so we can not use it here TODO: PLANO-184041 Remove this
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { Config } from '@plano/shared/core/config';
import { IgnoreGutterDirective } from '@plano/shared/core/ignore-gutter.directive';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';

/**
 * The available styles for the backend color
 */
export type BackgroundColorStyle = ExtractFromUnion<'light' | 'dark' | 'primary', PThemeEnum> | 'darker' | 'white';

/**
 * A box that can be wrapped around content to make it scrollable.
 */
@Component({
	selector: 'p-scroll-box',
	templateUrl: './scroll-box.component.html',
	styleUrls: ['./scroll-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [
		CommonModule,
		IgnoreGutterDirective,
	],
})
export class ScrollBoxComponent implements AfterViewInit, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private backgroundStyle : BackgroundColorStyle | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public alwaysShowScrollbar : boolean = false;

	/**
	 * Should this scroll box disable the scrollbar gutter?
	 */
	@Input() public disableScrollbarGutter : boolean = false;

	/**
	 * Should there be no shadows?
	 */
	@Input() public noShadows : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public contentContainerStyles : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public fixedFooterTemplate : TemplateRef<unknown> | null = null;

	/**
	 * Should the scroll box be a sub scroll box? Meaning, is it inside another scroll box?
	 */
	@HostBinding('class.position-relative')
	@HostBinding('class.hide-scrollbar')
	@Input() protected isSubScrollBox : boolean = false;

	/**
	 * Emits when the user scrolls the content.
	 */
	@Output() protected onScroll = new EventEmitter<Event>();

	@ViewChild('contentElement') private contentElementRef ?: ElementRef<HTMLElement>;

	constructor(
		private zone : NgZone,
	) {

	}

	public ngAfterViewInit() : void {
		this.zone.runOutsideAngular(() => {
			this.contentElementRef?.nativeElement.addEventListener('scroll', (event) => {
				this.onScroll.emit(event);
			});
		});
	}

	public ngOnDestroy() : void {
		this.resizeObserver?.disconnect();
	}

	private resizeObserver : ResizeObserver | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showFooterAsFixed() : boolean {
		// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
		if (Config.IS_MOBILE) return false;
		return !!this.fixedFooterTemplate;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get backgroundColor() : BackgroundColorStyle | undefined {
		if (this.backgroundStyle) return this.backgroundStyle;
		return undefined;
	}
}
