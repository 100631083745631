import { SchedulingApiPermissionGroupShiftModelPermission } from '@plano/shared/api';
import { SortFnArray } from '@plano/shared/api/base/api-list-wrapper/api-list-wrapper.type';
import { stringCompare } from '@plano/shared/core/utils/sorting-utils';

/**
 * Sort function array to sort activity permissions
 * @param a The first item to compare
 * @param b The second item to compare
 */
export const defaultSortingForPermissionGroupShiftModelPermissions : SortFnArray<SchedulingApiPermissionGroupShiftModelPermission> = [
	(a, b) => {
		const aActivity = a.activity;
		const bActivity = b.activity;

		if (!aActivity) {
			return bActivity ? 1 : 0;
		} else if (!bActivity) {
			return -1;
		}

		if (!aActivity.aiName.value || !bActivity.aiName.value) return 0;
		return stringCompare(aActivity.name, bActivity.name);
	},
];
