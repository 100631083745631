import { ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PBackgroundColor } from '@plano/client/shared/bootstrap.utils';
import { NonUndefined } from '@plano/shared/core/utils/null-type-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ModalServiceOptions {

	/** @see NgbModalOptions#keyboard */
	public keyboard ?: NonUndefined<NgbModalOptions['keyboard']>;

	/**
	 * Size of a new modal window.
	 * @default SM. If this is not set, {@link ModalService#openModal} will set the default.
	 */
	public size ?:
		typeof enumsObject.BootstrapSize.SM |
		typeof enumsObject.BootstrapSize.MD |
		typeof enumsObject.BootstrapSize.LG |
		typeof enumsObject.BootstrapSize.XL |
		'fullscreen';

	/**
	 * A custom class to append to the modal window.
	 */
	public windowClass ?: NgbModalOptions['windowClass'];

	/**
	 * Whether a backdrop element should be created for a given modal (true by default).
	 * Alternatively, specify 'static' for a backdrop which doesn't close the modal on click.
	 */
	public backdrop ?: NgbModalOptions['backdrop'];

	/**
	 * Custom class to append to the modal backdrop
	 */
	public backdropClass ?: NgbModalOptions['backdropClass'];

	/**
	 * Custom class to append to the modal backdrop
	 */
	public theme ?: PBackgroundColor | null = null;

	/**
	 * Scrollable modal content (false by default).
	 *
	 * @since 5.0.0
	 */
	public scrollable ?: NgbModalOptions['scrollable'];

	/**
	 * If `true`, modal opening and closing will be animated.
	 */
	public animation ?: NgbModalOptions['animation'];

	/**
	 * Injector to be used when creating the content inside the modal
	 */
	public injector ?: NgbModalOptions['injector'];

	/**
	 * Method to be ran before the modal is dismissed.
	 * If it returns false, the modal won't be dismissed.
	 */
	public beforeDismiss ?: NgbModalOptions['beforeDismiss'];

	/**
	 * Should the modal stretch to the maximum height available?
	 */
	public isMaxHeightModal ?: boolean;
}

/**
 * Event: Happens when we defined it that way
 * ModalDismissReasons: Gets passed by internal NgbModal plugins code on backdrop click or esc key press. @see ModalDismissReasons
 * undefined: This happens when user has modal open, and navigates via browser-nav-buttons
 */
export type ModalDismissParam = Event | ModalDismissReasons | undefined;
