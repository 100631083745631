/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'pUrlForHumans',
	standalone: true,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PUrlForHumansPipe implements PipeTransform {

	/**
	 * Transform a url to a nice clean thing.
	 * @example
	 *   http://www.dr-plano.com to dr-plano.com
	 */
	public transform(url : string) : string | undefined {
		// remove protocol like 'https://'
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const withoutProtocol = url.replace(/^https?:\/\/(?:www\.)?/, '');

		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const urlArray = withoutProtocol.split(/\//);
		const domain = urlArray.shift();
		let tail = urlArray.join('/');

		if (tail.length > 0) {
			if (tail.length > 8) tail = `${tail.slice(0, 5)}…`;
			return `${domain}/${tail}`;
		} else {
			return domain;
		}

	}
}
