/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

import { HolidayItemsApiHolidayItem } from '@plano/shared/api';


/**
 * This service enables access to the api "holiday_items".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	HOLIDAY_ITEM_START = 1;
	HOLIDAY_ITEM_END = 2;
	HOLIDAY_ITEM_NAME = 3;
	HOLIDAY_ITEM_MISSING_TRANSLATION = 4;
	HOLIDAY_ITEM_EMOJI = 5;
	HOLIDAY_ITEM_PUBLIC_HOLIDAY_DURATION_TYPE = 6;
	HOLIDAY_ITEM_LABEL_IDS = 7;
	HOLIDAY_ITEM_OBSERVED_REGION = 8;
	HOLIDAY_ITEM_ADDITIONAL_OBSERVED_REGIONS = 9;
}


@Injectable({
  providedIn: 'root',
})
export class HolidayItemsApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'holiday_items');
	}

	consts = new Consts();

	protected version() : string {
		return 'af1bd5754adb966fb5925b2f1137e408,010c3f6a48ffee34aa65bcfed7a8aeb9';
	}

	private dataWrapper = new HolidayItemsApiRoot(this, false, false);

	get data() : HolidayItemsApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : HolidayItemsApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new HolidayItemsApiRoot(this, false, false);
	}
}

		 export class HolidayItemsApiRoot extends ApiListWrapper<HolidayItemsApiHolidayItem>
{
	constructor(override readonly api : HolidayItemsApiService,
		
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, null, isView, removeDestroyedItems, '');
	}

	override wrapRawData(itemRawData : any) : HolidayItemsApiHolidayItem {
		return new HolidayItemsApiHolidayItem(this.api, this as unknown as HolidayItemsApiRoot, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ApiDataWrapperBase | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new HolidayItemsApiRoot(this.api, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '1';
	}

	override createNewItem(_initCode : ((newItem : HolidayItemsApiHolidayItem) => void) | null = null, _backendId : Id | null = null) : HolidayItemsApiHolidayItem {
		const newItem = new HolidayItemsApiHolidayItem(this.api, this as unknown as HolidayItemsApiRoot, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<HolidayItemsApiRoot, HolidayItemsApiRoot> = new ApiAttributeInfo<HolidayItemsApiRoot, HolidayItemsApiRoot>({
			apiObjWrapper: this as any as HolidayItemsApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : HolidayItemsApiRoot, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			
		});
}

				 
export class HolidayItemsApiHolidayItemBase extends ApiObjectWrapper<HolidayItemsApiHolidayItem>
{
	constructor(override readonly api : HolidayItemsApiService,
		override readonly parent : HolidayItemsApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<HolidayItemsApiHolidayItem> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, HolidayItemsApiHolidayItem as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<HolidayItemsApiHolidayItem, HolidayItemsApiHolidayItem> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, HolidayItemsApiHolidayItem>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'holidayItem',
			nodeName: 'HOLIDAY_ITEM',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : HolidayItemsApiHolidayItem, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiStart : ApiAttributeInfo<HolidayItemsApiHolidayItem, Date> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, Date>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'start',
			nodeName: 'HOLIDAY_ITEM_START',
			type: PApiType.Date,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<HolidayItemsApiHolidayItem, DateExclusiveEnd> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, DateExclusiveEnd>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'end',
			nodeName: 'HOLIDAY_ITEM_END',
			type: PApiType.DateExclusiveEnd,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiName : ApiAttributeInfo<HolidayItemsApiHolidayItem, string> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, string>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'name',
			nodeName: 'HOLIDAY_ITEM_NAME',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiMissingTranslation : ApiAttributeInfo<HolidayItemsApiHolidayItem, boolean> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, boolean>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'missingTranslation',
			nodeName: 'HOLIDAY_ITEM_MISSING_TRANSLATION',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : HolidayItemsApiHolidayItem, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiEmoji : ApiAttributeInfo<HolidayItemsApiHolidayItem, Emoji> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, Emoji>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'emoji',
			nodeName: 'HOLIDAY_ITEM_EMOJI',
			type: PApiType.Emoji,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiPublicHolidayDurationType : ApiAttributeInfo<HolidayItemsApiHolidayItem, HolidayItemsApiPublicHolidayDurationType> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, HolidayItemsApiPublicHolidayDurationType>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'publicHolidayDurationType',
			nodeName: 'HOLIDAY_ITEM_PUBLIC_HOLIDAY_DURATION_TYPE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiObservedRegion : ApiAttributeInfo<HolidayItemsApiHolidayItem, string> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, string>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'observedRegion',
			nodeName: 'HOLIDAY_ITEM_OBSERVED_REGION',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : HolidayItemsApiHolidayItem) {
				return ((this.api.getCurrentDataSearchParamValue('dataSource') === 'dr-planos-database'));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 8,
		});
	aiAdditionalObservedRegions : ApiAttributeInfo<HolidayItemsApiHolidayItem, string> = new ApiAttributeInfo<HolidayItemsApiHolidayItem, string>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItem,
			name: 'additionalObservedRegions',
			nodeName: 'HOLIDAY_ITEM_ADDITIONAL_OBSERVED_REGIONS',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : HolidayItemsApiHolidayItem) {
				return ((this.api.getCurrentDataSearchParamValue('dataSource') === 'dr-planos-database'));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});

	/**
	 * Start of this holiday-item.
	 *
	 * @type {Date}
	 */
	get start() : Date {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set startTestSetter(v : Date) {
        this.setterImpl(1, v, 'start', true, null, null);
	}

	/**
	 * End of this holiday-item.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get end() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set endTestSetter(v : DateExclusiveEnd) {
        this.setterImpl(2, v, 'end', true, null, null);
	}

	/**
	 * The name of the holiday-item.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[3];
	}

	set nameTestSetter(v : string) {
        this.setterImpl(3, v, 'name', true, null, null);
	}

	/**
	 * For "dataSource" equal "dr-planos-database", sometimes the desired language passed by "languageIsoCode" is not available in which case the API returns the item name in a default language. In such a case, this value will be "true".
	 *
	 * @type {boolean}
	 */
	get missingTranslation() : boolean {
		this.getterDebugValidations(this.aiMissingTranslation, false);
		return this.data[4];
	}

	set missingTranslationTestSetter(v : boolean) {
        this.setterImpl(4, v, 'missingTranslation', true, null, null);
	}

	/**
	 * The emoji associated with this holiday-item.
	 *
	 * @type {Emoji}
	 */
	get emoji() : Emoji | null {
		this.getterDebugValidations(this.aiEmoji, false);
		return this.data[5];
	}

	set emojiTestSetter(v : Emoji | null) {
        this.setterImpl(5, v, 'emoji', true, null, null);
	}

	/**
	 * The duration of the public holiday. I.e. the portion of the day (whole day, half day, ...). Only available for public holiday-items.
	 *
	 * @type {HolidayItemsApiPublicHolidayDurationType}
	 */
	get publicHolidayDurationType() : HolidayItemsApiPublicHolidayDurationType | null {
		this.getterDebugValidations(this.aiPublicHolidayDurationType, false);
		return this.data[6];
	}

	set publicHolidayDurationTypeTestSetter(v : HolidayItemsApiPublicHolidayDurationType | null) {
        this.setterImpl(6, v, 'publicHolidayDurationType', true, null, null);
	}

	private labelIdsWrapper : HolidayItemsApiHolidayItemLabelIds = new HolidayItemsApiHolidayItemLabelIds(this.api,
		this as unknown as HolidayItemsApiHolidayItem, false, false);
	public aiLabelIds = this.labelIdsWrapper.aiThis;

	/**
	 * A list of label-ids that tags this holiday-item. See "schedulingApi.data.holidays.labels".
	 */
	get labelIds() : HolidayItemsApiHolidayItemLabelIds {
		this.getterDebugValidations(this.aiLabelIds, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiLabelIds.defaultValue;
			this.labelIdsWrapper._updateRawData(this.data[7], true);
		}
		return this.labelIdsWrapper;
	}

	set labelIdsTestSetter(v : HolidayItemsApiHolidayItemLabelIds) {
        this.setterImpl(7, v.rawData, 'labelIds', true, null, function(this : HolidayItemsApiHolidayItem) {this.labelIdsWrapper = v;});
	}

	/**
	 * For "dataSource" equal "dr-planos-database", a localized string representation of where this item is valid. This will contain the region which directly intersects the desired sub-division provided by "subdivisionCode". See also "additionalObservedRegions".
	 *
	 * @type {string}
	 */
	get observedRegion() : string {
		this.getterDebugValidations(this.aiObservedRegion, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiObservedRegion.defaultValue;
			
		}
		return this.data[8];
	}

	set observedRegionTestSetter(v : string) {
        this.setterImpl(8, v, 'observedRegion', true, null, null);
	}

	/**
	 * For "dataSource" equal "dr-planos-database", if the item is valid in further regions in addition to "observedRegion" then this will contain a string representation of those additional regions. These will be regions which don't have a direct intersection with the desired sub-division provided by "subdivisionCode".
	 *
	 * @type {string}
	 */
	get additionalObservedRegions() : string | null {
		this.getterDebugValidations(this.aiAdditionalObservedRegions, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[9] === undefined) {
			this.data[9] = this.aiAdditionalObservedRegions.defaultValue;
			
		}
		return this.data[9];
	}

	set additionalObservedRegionsTestSetter(v : string | null) {
        this.setterImpl(9, v, 'additionalObservedRegions', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.labelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('HOLIDAY_ITEM should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.labelIdsWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : HolidayItemsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}

export enum HolidayItemsApiPublicHolidayDurationType {
	WHOLE_DAY = 1,
	THREE_QUARTER_DAY = 2,
	HALF_DAY = 3,
	QUARTER_DAY = 4,
}
		 export class HolidayItemsApiHolidayItemLabelIds extends ApiListWrapper<LabelId>
{
	constructor(override readonly api : HolidayItemsApiService,
		override readonly parent : HolidayItemsApiHolidayItem | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'labelIds');
	}

	override wrapRawData(itemRawData : any) : LabelId {
		return LabelId.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : HolidayItemsApiHolidayItem | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new HolidayItemsApiHolidayItemLabelIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '9';
	}

	override createNewItem(_initCode : ((newItem : LabelId) => void) | null = null) : LabelId {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<HolidayItemsApiHolidayItemLabelIds, HolidayItemsApiHolidayItemLabelIds> = new ApiAttributeInfo<HolidayItemsApiHolidayItemLabelIds, HolidayItemsApiHolidayItemLabelIds>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItemLabelIds,
			name: 'labelIds',
			nodeName: 'HOLIDAY_ITEM_LABEL_IDS',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : HolidayItemsApiHolidayItemLabelIds) {
				return ((this.api.getCurrentDataSearchParamValue('dataSource') === 'previous-years'));
			},
			hasPermissionToSet: () => false,
			defaultValue: function(this : HolidayItemsApiHolidayItemLabelIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.LabelId,
			rawDataIndex: 7,
		});
	aiHolidayItemLabelId : ApiAttributeInfo<HolidayItemsApiHolidayItemLabelIds, LabelId> = new ApiAttributeInfo<HolidayItemsApiHolidayItemLabelIds, LabelId>({
			apiObjWrapper: this as any as HolidayItemsApiHolidayItemLabelIds,
			name: 'holidayItemLabelId',
			nodeName: 'HOLIDAY_ITEM_LABEL_ID',
			type: PApiType.LabelId,
			hasPermissionToSet: () => false,
			
		});
}

		

