import { errorTypeUtils } from '@plano/shared/core/utils/error-type-utils';
import { CamelCaseToKebabCase, KebabCaseToCamelCase, NonEmptyString, notEmptyString } from './typescript-utils-types';

/**
 * Helper methods for enums.
 */
export class EnumUtils {

	/**
	 * Get all values of an Enum as an Array
	 * @examples
	 * 	enum Food { BANANA = 'banana', APPLE = 'apple' } => ['banana', 'apple']
	 * 	enum Food { BANANA, APPLE } => [0, 1]
	 * 	enum Food { BANANA = 100, APPLE = 200 } => [100, 200]
	 */
	public static getValues<T = unknown>(theEnum : { [s : string] : T }) : T[] {
		const allValues = (Object.values(theEnum) as unknown as T[]);
		const numberValues = allValues.filter(value => typeof value === 'number');
		if (numberValues.length > 0) return numberValues;
		return allValues.filter(value => typeof value !== 'number');
	}

	/**
	 * @param value The value whose string representation should be returned.
	 * @param theEnum The enum to which `value` belongs.
	 * @returns The enum value as a string.
	 */
	public static getValueName<T = unknown>(value : T, theEnum : { [s : string] : T }) : string {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Cant think of an implementation without using any
		return (theEnum as any)[value];
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const camelCaseToKebabCase : <CamelCaseType extends string>(input : CamelCaseType) => CamelCaseToKebabCase<CamelCaseType> =
	(camelCaseInput) => {
		if (camelCaseInput === camelCaseInput.toLowerCase()) return camelCaseInput as CamelCaseToKebabCase<typeof camelCaseInput>;
		return camelCaseInput
			.split('')
			.map((letter, index) => {
				return (letter.toUpperCase() === letter) ?
					// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					`${index === 0 ? '' : '-'}${letter.toLowerCase()}` :
					letter;
			})
			.join('') as CamelCaseToKebabCase<typeof camelCaseInput>;
	};

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const kebabCaseToCamelCase : <KebabCaseType extends string>(input : KebabCaseType) => KebabCaseToCamelCase<KebabCaseType> =
	(kebabCaseInput) => {
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const REGEX_UPPERCASE_CHAR_FOLLOWED_BY_LOWERCASE = /[A-Z_-\d][A-Za-z]/g;

		const firstChar = kebabCaseInput
			.charAt(0)
			.toLowerCase();
		const otherChars = kebabCaseInput
			.slice(1)
			.replaceAll(
				REGEX_UPPERCASE_CHAR_FOLLOWED_BY_LOWERCASE,
				(v) => v.charAt(1).toUpperCase(),
			);

		const result = firstChar + otherChars;
		return result as KebabCaseToCamelCase<typeof kebabCaseInput>;
	};

/**
 * Transform string into a valid html id
 * @param value The string to transform
 */
export const stringToId = (
	value : string,
) : NonEmptyString => {
	if (value.length === 0) throw new Error('Must be a non empty string.');
	const withDashes = value.toLowerCase()
		.replaceAll('ä', 'ae')
		.replaceAll('ü', 'ue')
		.replaceAll('ö', 'oe')
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- we want an hyphen here for the url
		.replaceAll(/[\s&,.]/gu, '-')
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- we want an hyphen here for the url
		.replaceAll(/[^\w\-]/gu, '-');
	return notEmptyString(`${encodeURIComponent(withDashes)}`);
};

/**
 * Is the provided value a Primitive?
 * @param value the value to check
 */
export const isPrimitive : (value : unknown) => boolean = (value) => {
	return value !== Object(value);
};

/**
 * A utility class to check values for their type.
 */
export const typeUtils = {
	/**
	 * Is the provided value a text node?
	 * @param element The element to check
	 */
	isTypeTextNode: (element : Element | Text) : element is Text => {
		return element.nodeType === Node.TEXT_NODE;
	},

	/**
	 * Is the provided value an Event?
	 * Usually typescript would already tell you, but sometimes, like e.g. when you are in a catch block where the type of
	 * error is always unknown, you need to check it manually.
	 * @param value The value to check
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Is ok here since this method exists to improve the type information
	isTypeEvent: (value : any) : value is Event => {
		return 'type' in value && 'target' in value && 'defaultPrevented' in value;
	},

	...errorTypeUtils,
};
