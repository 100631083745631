/* eslint-disable @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { ApiObjectWrapper } from '@plano/shared/api';

/**
 * Compare method to use for strings, use this instead of localeCompare as it is much more performant.
 */
export const stringCompare = new Intl.Collator(undefined, {sensitivity: 'base', numeric: true}).compare;

/**
 * Items can have the same date and time, e.g. if they are automatically generated by the backend, and
 * have not been triggered by the user. In this case, the date/time does not tell which of the items was
 * generated first. So we read that from the id.
 * @param item The item that should be sorted.
 */
export const takeCareOfCaseWhereDateIsNotPrecise : (item : ApiObjectWrapper<any>) => unknown = (item) => item.id;

/**
 * @see takeCareOfCaseWhereDateIsNotPrecise
 * We want the most recent item first per default, we invert the id.
 * @param item The item that should be sorted.
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-unary-minus -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export const takeCareOfCaseWhereDateIsNotPreciseReverse : (item : ApiObjectWrapper<any>) => unknown = (item) => (item.id ? -item.id : item.id);
