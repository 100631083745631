// cSpell:ignore IDBP
import { Injector, NgZone } from '@angular/core';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { MigrationScript, TimestampString } from '@plano/shared/core/indexed-db/migration/migration-scripts.types';
import { PIndexedDBKeyDataType } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { waitForValueNotUndefined } from '@plano/shared/core/utils/async-await-utils';
import { IDBPDatabase, openDB } from 'idb';

/**
 * Helper function to get the indexedDB string-key from our own key data type.
 * @param key Our own key data-type to identify store values.
 */
export function getIndexedDBKey(key : PIndexedDBKeyDataType) : string {
	if (key.prefix === null) {
		return key.name;
	}
	return `${key.prefix}_${key.name}`;
}

/**
 * Database class that holds the migration scripts and the current version of the indexedDB database,
 * as well as the cached database instance, for the sync methods.
 */
export class IndexedDBDatabase {
	constructor(
		injector : Injector,
		name : string,
		migrationScripts : {[key : TimestampString] : MigrationScript},
	) {
		this.injector = injector;
		this.name = name;
		this.migrationScripts = migrationScripts;
		const pMomentService = this.injector.get(PMomentService);

		// Assign the version to the latest migration script timestamp
		this.version = Math.max(...Object.keys(migrationScripts).map((key) => pMomentService.utc(key, 'YYYY.MM.DD.HH.mm.ss').valueOf()));
	}

	public name : string;
	private migrationScripts : {[key : TimestampString] : MigrationScript};
	private version : number;
	private injector : Injector;

	/**
	 * Cache for the database. This is useful to be able to access the database synchronously.
	 */
	private cache : { [storeName : string] : {[key : string] : string} } = {};

	/**
	 * The indexedDB instance itself.
	 * It is undefined while the database couldn't be opened yet.
	 */
	private indexedDB : IDBPDatabase | undefined = undefined;

	/**
	 * Start the database and execute all migration scripts.
	 */
	public async startDatabase() {

		const injector = this.injector;
		const zone = injector.get(NgZone);
		const pMomentService = injector.get(PMomentService);
		const migrationScripts = this.migrationScripts;

		await zone.runOutsideAngular(async () => {
			this.indexedDB = await openDB(this.name, this.version, {
				/**
				 * This method is called when the database is created for the first time, or when the database version is increased.
				 *
				 * @param database - The database object being created.
				 * @param oldVersion - The old version of the database.
				 * @param _newVersion - The new version of the database.
				 * @param transaction - The transaction object.
				 */
				// eslint-disable-next-line @typescript-eslint/typedef -- false-positive. Its already typed by openDB.
				async upgrade(database, oldVersion, _newVersion, transaction) {

					const sortedMigrationScripts = Object.entries(migrationScripts).sort((a, b) => {
						return pMomentService.utc(a[0], 'YYYY.MM.DD.HH.mm.ss' ).valueOf() - pMomentService.utc(b[0], 'YYYY.MM.DD.HH.mm.ss').valueOf();
					});

					for (const [timestampString, script] of sortedMigrationScripts) {
						const timestamp = pMomentService.utc(timestampString, 'YYYY.MM.DD.HH.mm.ss').valueOf();
						if (oldVersion < timestamp) {
							await script(database, transaction, injector);
						}
					}
				},

				/**
				 * Method that gets called when the database operations get blocked, for example, because a tab with an old version is opened.
				 */
				blocked() {
					const localizePipe = injector.get(LocalizePipe);
					alert(localizePipe.transform('Die Datenbank kann gerade nicht aufgerufen werden – möglicherweise wird gerade eine neue Version bereitgestellt. Bitte schließe alle Dr.&nbsp;Plano-Tabs, damit du in diesem Tab weitermachen kannst.'));
				},

				/**
				 * Called if this connection is blocking a future version of the database from opening.
				 */
				blocking() {
					throw new Error('IndexedDB connection got blocked.');
				},

				/**
				 * Method that gets called when the database connection is terminated.
				 */
				terminated() {
					throw new Error('IndexedDB connection was terminated.');
				},

			});

			for (const storeName of this.indexedDB.objectStoreNames) {
				this.cache[storeName] = {};
				const storeKeys = await this.indexedDB.getAllKeys(storeName);
				for (const key of storeKeys) {
					this.cache[storeName][key.toLocaleString()] = await this.indexedDB.get(storeName, key);
				}
			}
		});

	}

	/**
	 * Close the database.
	 */
	public closeDatabase() : void {
		if (this.indexedDB)
			this.indexedDB.close();
		this.indexedDB = undefined;
	}

	/**
	 * Set the value in the database.
	 *
	 * @param key - The key to set.
	 * @param value - The value to set.
	 * @param afterIndexedDBUpdatedCallback - Callback that gets called after the indexedDB has been updated
	 */
	public set(key : PIndexedDBKeyDataType, value : string | boolean | number, afterIndexedDBUpdatedCallback : (() => void) | null = null) {
		void this.putIntoIndexedDB(key, value, afterIndexedDBUpdatedCallback);
		this.cache[key.store][getIndexedDBKey(key)] = `${value}`;
	}

	private async putIntoIndexedDB(key : PIndexedDBKeyDataType, value : string | boolean | number, afterIndexedDBUpdatedCallback : (() => void) | null = null) {
		const zone = this.injector.get(NgZone);
		const indexedDB = await waitForValueNotUndefined(zone, () => this.indexedDB);
		await indexedDB.put(key.store, `${value}`, getIndexedDBKey(key));
		afterIndexedDBUpdatedCallback?.();
	}

	/**
	 * Get the value from the database. Returns null if the key couldn't be found or undefined if the database is not yet opened.
	 * @param key - The key to get.
	 */
	public get(key : PIndexedDBKeyDataType) : string | null | undefined {
		if (this.indexedDB === undefined)
			return undefined;
		return this.cache[key.store][getIndexedDBKey(key)] ?? null;
	}

	/**
	 * Check if the key exists in the database.
	 * @param key - The key to check.
	 */
	public has(key : PIndexedDBKeyDataType) : boolean {
		const value = this.get(key);

		return value !== null;
	}

	/**
	 * Delete the value from the database, if it exists.
	 * @param key - The key to delete.
	 * @param afterIndexedDBUpdatedCallback - Callback that gets called after the indexedDB has been updated.
	 */
	public delete(key : PIndexedDBKeyDataType, afterIndexedDBUpdatedCallback : (() => void) | null = null) {
		void this.deleteFromIndexedDb(key, afterIndexedDBUpdatedCallback);
		delete this.cache[key.store][getIndexedDBKey(key)];
	}

	private async deleteFromIndexedDb(key : PIndexedDBKeyDataType, afterIndexedDBUpdatedCallback : (() => void) | null = null) {
		const zone = this.injector.get(NgZone);
		const indexedDB = await waitForValueNotUndefined(zone, () => this.indexedDB);

		if (!indexedDB.objectStoreNames.contains(key.store))
			return;

		const hasEntry = await indexedDB.get(key.store, getIndexedDBKey(key));
		if (hasEntry)
			await indexedDB.delete(key.store, getIndexedDBKey(key));
		afterIndexedDBUpdatedCallback?.();
	}
}
