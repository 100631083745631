@if (!hidden) {
	<div
		class="position-relative"
		[@slideToLeft]
	>
		<div
			class="card bg-sepia row-gap-2"
		>
			@if (!hasChosenCookieSetting) {
				<p i18n>Wir verwenden Cookies, um dein Erlebnis auf unserer Seite leckerer zu gestalten – ganz ohne Kalorien und Krümel im Tastaturspalt.</p>
				<div class="d-flex align-items-center justify-content-between flex-wrap gap-2">
					<p-button
						(triggerClick)="choseCookieSetting('all')"
						[theme]="enums.PThemeEnum.SEPIA"
						icon="fa-cookie-bite fa-solid"
						i18n
					>Gebt mir alle Cookies!</p-button>
					<a tabindex="0" role="link" (click)="showMoreOptions=true;" class="fs-sm mb-0" i18n>… mehr Optionen</a>
				</div>
				@if (showMoreOptions) {
					<small class="mt-2" style="line-height: 1.65" i18n><a tabindex="0" role="link" (click)="choseCookieSetting('essential')">Nur notwendige Cookies akzeptieren</a> oder mehr dazu erfahren in unserer <a routerLink="/privacy">Datenschutzerklärung</a>.</small>
				}
			} @else {
				<h3 class="text-white h4 mb-0" i18n>Danke für dein Vertrauen</h3>
				<p i18n>Wir behandeln deine Daten mit größter Sorgfalt und bieten dir ein knackig-sicheres Erlebnis 😊</p>
				<p-button
					[attributeInfo]="null"
					(triggerClick)="hidden = true;"
					[theme]="PBtnThemeEnum.OUTLINE_LIGHT"
					class="mt-2"
					btnClasses="flex-grow-0"
					i18n
				>Schließen</p-button>
			}
		</div>
		@if (hasChosenCookieSetting) {
			<img src="images/cookie-bite-animation.webp" alt="Cookie animation" i18n-alt/>
		}
	</div>
}
