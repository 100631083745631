import { SchedulingApiPaymentMethodType, SchedulingApiShiftModelCoursePaymentMethodBase, SchedulingApiShiftModelCoursePaymentMethodsBase } from '@plano/shared/api';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';
import { PShiftmodelTariffService } from '@plano/shared/p-forms/p-shiftmodel-tariff.service';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModelCoursePaymentMethods extends SchedulingApiShiftModelCoursePaymentMethodsBase {

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => !item.trashed);
	}

	/** Check if the minimum amount of paymentMethods is present */
	public minPaymentMethods() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.MIN_LENGTH, fn: (_control) => {
			const model = this.parent!;
			const noChargeableTariff = !PShiftmodelTariffService.hasVisibleCourseTariffWithCosts(model.courseTariffs);
			if (noChargeableTariff) return null;
			if (PShiftmodelTariffService.hasVisiblePaymentMethod(model.coursePaymentMethods)) return null;

			let minPaymentMethodsErrorText : PDictionarySource | null = null;
			if (!model.coursePaymentMethods.hasUntrashedItem) {
				minPaymentMethodsErrorText = 'Dieses Angebot ist kostenpflichtig. Daher muss eine Zahlungsart vorhanden sein, die Kunden bei der Online-Buchung auswählen können.';
			} else if (!PShiftmodelTariffService.hasVisiblePaymentMethod(model.coursePaymentMethods)) {
				minPaymentMethodsErrorText = 'Dieses Angebot ist kostenpflichtig. Daher muss eine Zahlungsart vorhanden sein, die Kunden bei der Online-Buchung auswählen können. Aktuell sind alle Zahlungsarten als <mark>interne Zahlungsarten</mark> eingestellt und damit bei der Online-Buchung nicht verfügbar.';
			}
			assumeNonNull(minPaymentMethodsErrorText, 'minPaymentMethodsErrorText', 'Could not figure out the reason for the validation error.');

			return { [PPossibleErrorNames.MIN_LENGTH] : {
				name: PPossibleErrorNames.MIN_LENGTH,
				type: PApiType.ApiList,
				actual: 0,
				expected: 1,
				errorText: minPaymentMethodsErrorText,
			} };
		}});
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModelCoursePaymentMethod extends SchedulingApiShiftModelCoursePaymentMethodBase {
	/**
	 * Check if there are no payment methods with the same name
	 */
	public checkNoDuplicatePaymentMethodNames() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME, fn: (_control) => {
			if (this.parent) {
				for (const paymentMethod of this.parent.iterable()) {
					if (paymentMethod === this)
						continue;

					if (paymentMethod.trashed) continue;

					if (this.aiName.value === null || paymentMethod.aiName.value === null) continue;

					if (paymentMethod.name.toLowerCase() === this.aiName.value.toLowerCase()) {
						// We found a duplicate
						return { [PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME]: {
							name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME,
							type: null,
						}};
					}
				}
			}

			return null;
		}});
	}

	/**
	 * If this is a payment method of type {@link SchedulingApiPaymentMethodType.ONLINE_PAYMENT}, check if the parent
	 * contains already another payment method of the same type.
	 */
	protected checkNoRepeatedOnlinePaymentMethod() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD, fn: (_control) => {
			if (this.type === SchedulingApiPaymentMethodType.ONLINE_PAYMENT && this.parent && this.parentHasExistingOnlinePaymentMethod) {
				// We found a duplicate
				return { [PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD]: {
					name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD,
					type: null,
				}};
			}
			return null;

		}});
	}

	/**
	 * Check if the parent has another existing paymentMethod of type {@link SchedulingApiPaymentMethodType.ONLINE_PAYMENT}
	 */
	protected get parentHasExistingOnlinePaymentMethod() : boolean {
		return this.parent!.some(item => {
			return !item.trashed && !item.id.equals(this.id) && item.type === SchedulingApiPaymentMethodType.ONLINE_PAYMENT;
		});
	}
}
