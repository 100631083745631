/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedApiClientType, AuthenticatedApiGender, AuthenticatedApiRole, AuthenticatedApiService } from '@plano/shared/api';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { PSupportedCountryCodes } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { PIndexedDBService } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { AUTHENTICATION_INDEXED_DB_S_KEY } from '@plano/shared/core/me/authentication.indexeddb.type';
import { waitForValueNotUndefined } from '@plano/shared/core/utils/async-await-utils';
import { TawkApiInterface } from '@plano/shared/core/utils/p-window';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class MeService extends AuthenticatedApiService {

	constructor(
		private cookie : CookieService,
		private pSentryService : PSentryService,
		http : HttpClient,
		apiError : ApiErrorService,
		router : Router,
		zone : NgZone,
		protected override injector : Injector,
	) {
		super(http, router, apiError, zone, injector);

		// ensure tawk data are up-to-date
		const tawkApi : TawkApiInterface | null | undefined = window.Tawk_API;

		if (tawkApi) {
			try {
				tawkApi.onBeforeLoad = () => {
					this.updateTawkData();
				};

				tawkApi.onChatMaximized = () => {
					this.updateTawkData();
				};
			} catch (error) {
				// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				console.warn('Error in tawkApi.onBeforeLoad or tawkApi.onChatMaximized', error);
			}
		}

		// handle api errors
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		apiError.error.subscribe(
			(response : HttpErrorResponse) => {
				// unauthorized api call?
				if (response.status === 401) {
					this.openLoginPage();
				}
			},
			// eslint-disable-next-line promise/prefer-await-to-callbacks -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			(error : unknown) => {
				// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				console.error(error);
			},
		);
	}

	/**
	 * This is emitted after is logged in successfully.
	 * The boolean value of this subject means "true" => Logged in from login form.
	 * "false" => Logged in indexedDB credentials.
	 */
	public readonly afterLogin : Subject<boolean> = new Subject<boolean>();

	/**
	 * Current credentials are valid? Note that this value can be "undefined" if no credentials are set.
	 */
	public invalidCredentials : boolean | undefined;

	/**
	 * Previous executed load() failed because credentials were invalid?
	 */
	public previousLoginHadInvalidCredentials : boolean = false;

	/**
	 * What was the path before the last login failed?
	 */
	public pathWhenLoginFailed : string | undefined;

	protected basicAuthValue : string | null = null;

	/**
	 * Should debug information only visible when using master password be hidden?
	 * See {@link PDebugComponent} for further information.
	 */
	public hideDebugInformation = false;

	/**
	 * Sets up environment and user data necessary for chat support
	 */
	private updateTawkData() : void {
		try {

			const tawkApi : TawkApiInterface | undefined = window.Tawk_API;

			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (tawkApi?.setAttributes && this.isLoaded()) {
				try	{
					// add visitor data
					tawkApi.setAttributes({
						name  : `${this.data.firstName} ${this.data.lastName}`,
						email : this.data.email,
						hash  : this.data.tawkHmac,
					});
				} catch (error) {
					// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					console.warn('Error in tawkApi.setAttributes', error);
				}

				// add tags
				const tags : string[] = [];

				if (this.data.isOwner)
					tags.push('Admin');

				if (this.data.testAccountDeadline)
					tags.push('Test-Account');

				switch (this.data.gender) {
					case AuthenticatedApiGender.FEMALE:
						tags.push('female');
						break;
					case AuthenticatedApiGender.DIVERSE:
						tags.push('diverse');
						break;
					default:
					case AuthenticatedApiGender.MALE:
						tags.push('male');
				}

				if (this.data.phone)
					tags.push(`Phone: ${this.data.phone}`);

				if (this.data.aiLocationName.isAvailable &&
					this.data.aiCompanyName.isAvailable &&
					this.data.aiClientId.isAvailable
				) {
					tags.push(`${this.data.locationName}/${this.data.companyName} (Id: ${this.data.clientId.toString()})`, this.data.locale);
				}

				try	{
					tawkApi.addTags(tags);
				} catch (error) {
					// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					console.warn('Error in tawkApi.addTags', error);
				}
			}
		} catch (error) {
			// We dont have control of tawk errors. So, just log them and ensure that user wont get an error modal
			// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			console.warn(error);
		}
	}

	/**
	 * Should the special test-client-expired view be shown? Note, that
	 * depending if logged in user is default or owner client the view will be different. To differentiate it,
	 * use `showExpiredClientViewForDefaultMember()` or `showExpiredClientViewForOwner()`.
	 */
	public get showExpiredClientView() : boolean {
		return this.data.aiClientType.isAvailable === true &&
			this.data.clientType === AuthenticatedApiClientType.TEST_EXPIRED &&
			!this.data.loggedInWithMasterPassword;
	}

	/**
	 * Should the special test-client-expired view for default-members be shown?
	 */
	public get showExpiredClientViewForDefaultMember() : boolean {
		return this.showExpiredClientView && this.data.role === AuthenticatedApiRole.CLIENT_DEFAULT;
	}

	/**
	 * Should the special test-client-expired view for client-owner be shown?
	 */
	public get showExpiredClientViewForOwner() : boolean {
		return this.showExpiredClientView && this.data.role === AuthenticatedApiRole.CLIENT_OWNER;
	}

	/**
	 * Returns the authentication value of the login data if === valid ascii
	 */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public calcBasisAuthValue(email : string, password : string) : string | 'not_ascii' {
		const basicAuthValue = `${email}:${password}`;

		// cspell:ignore restlet
		// restlet supports ASCII/ISO-8859-1 encoding.
		// cSpell:disable-next-line
		// See org.restlet.engine.security.HttpBasicHelper -> parseResponse().
		// Also btoa() on many browsers throw errors for utf-8.
		// eslint-disable-next-line no-control-regex, require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (/[^\u0000-\u00FF]/.test(basicAuthValue)) {
			return 'not_ascii';
		}

		return btoa(basicAuthValue);
	}

	public loggedInFromLoginForm = false;

	/**
	 * Authenticate a user.
	 *
	 * @param basicAuthValue credential basic-auth value
	 * @param loggedInFromLoginForm As the member logged in from form or from the IndexedDB?
	 * @param searchParams Params for the api request
	 */
	public async login(
		basicAuthValue : string,
		loggedInFromLoginForm : boolean,
		searchParams : HttpParams = new HttpParams(),
	) : Promise<HttpResponse<unknown> | 'not_ascii'> {
		this.loggedInFromLoginForm = loggedInFromLoginForm;
		if (basicAuthValue === 'not_ascii') {
			return 'not_ascii';
		}

		this.basicAuthValue = basicAuthValue;

		// clear old data
		// Note that this call does not clear IndexedDB (parameter is false) because on Android app
		// the following load call sometimes crashed. If this would clear IndexedDB this would mean loss
		// of login data because no new IndexedDB will be set then.
		await this.clearCredentials(false);

		// set new credentials
		Config.HTTP_AUTH_CODE = `Basic ${this.basicAuthValue}`;

		try {
			const loadResponse = await this.load({searchParams});
			if (!loadResponse.ok) {
				// eslint-disable-next-line rxjs/throw-error -- need to throw loadResponse to handle it in the catch block
				throw loadResponse;
			}
			this.invalidCredentials = false;
			this.previousLoginHadInvalidCredentials = false;

			// store IndexedDB of successful login
			await this.storeCredentialsInIndexedDB(basicAuthValue);

			// tell external tools about current login
			this.updateTawkData();
			this.setUserDataForErrorReporting();
			this.afterLogin.next(loggedInFromLoginForm);
			return loadResponse;
		} catch (loginError) {
			const response = loginError as HttpResponse<unknown>;

			// invalid credentials?
			this.previousLoginHadInvalidCredentials = (response.status === 401);
			this.invalidCredentials = (response.status === 401);

			if (this.invalidCredentials) {
				await this.clearCredentials(true);
			} else {
				await this.storeCredentialsInIndexedDB(basicAuthValue);
			}

			throw loginError;
		}
	}

	/**
	 * Store the new credentials in the IndexedDB.
	 * @param basicAuthValue credential basic-auth value
	 */
	private async storeCredentialsInIndexedDB(
		basicAuthValue : string,
	) {
		const pIndexedDBService = await waitForValueNotUndefined(this.zone, () => this.injector.get(PIndexedDBService, undefined));
		await pIndexedDBService.waitUntilDatabaseIsReady(AUTHENTICATION_INDEXED_DB_S_KEY.database);
		const setPromise = new Promise(resolve => {
			pIndexedDBService.set(AUTHENTICATION_INDEXED_DB_S_KEY, basicAuthValue, resolve.bind(this, true));
		});
		await setPromise;
	}

	/**
	 * @param url The url to add the country code to
	 * @returns The passed url with the correct country version of the logged in user.
	 */
	public ensureCorrectFrontendCountryVersion(url : string) : string {

		const countryCode = Config.getCountryCode(this.data.locale);
		if (countryCode === null) throw new Error('Could not get countryCode');
		const countryPath = countryCode.toLowerCase();

		// Generated admin is currently always locale de-DE.
		// Prevent redirect to admin locale as otherwise we cannot login to admin area when another frontend version is started, and redirect to admin area.
		if (
			this.data.role === AuthenticatedApiRole.ADMIN || this.data.role === AuthenticatedApiRole.SUPER_ADMIN
		) {
			return `${Config.FRONTEND_URL}/de/admin`;
		}

		// Ensure that client-users always use the correct frontend country version.
		if (!this.urlCountryMatchesUserCountry(url)) {
			// Tell app about the new country code.
			if (Config.platform === 'appAndroid' || Config.platform === 'appIOS') {
				window.nsWebViewInterface.emit('countryCode', Config.getCountryCode());
			}

			if (this.urlHasAnyCountryCode(url)) {
				// If the url contains a country code, replace it with the country code of the logged in user.
				const relevantPartOfUrl = url.split(Config.FRONTEND_URL)[1].split(/\//u).slice(2).join('/');
				url = `${Config.FRONTEND_URL}/${countryPath}${relevantPartOfUrl.at(0) === '/' ? '' : '/'}${relevantPartOfUrl}`;
			} else {
				// If the url does not contain any country code, add the country code of the logged in user.
				url = `${Config.FRONTEND_URL}/${countryPath}${url.split(Config.FRONTEND_URL)[1]}`;
			}

		}

		return url;
	}

	private urlHasAnyCountryCode(url : string) : boolean {
		const urlSplitWithoutBase = url.split(Config.FRONTEND_URL)[1].split(/\//u);

		const potentialCountryCode = urlSplitWithoutBase.at(1);

		return potentialCountryCode !== undefined && Object.values(PSupportedCountryCodes).map(
			countryCodeTyped => countryCodeTyped.toString(),
		).includes(potentialCountryCode.toUpperCase());
	}

	/**
	 * Checks if the frontend country version equals the country of logged in user.
	 * @param url The url to check
	 */
	public urlCountryMatchesUserCountry(url : string) : boolean {

		if (!this.urlHasAnyCountryCode(url)) {
			return false;
		}

		const countryCode = Config.getCountryCode(this.data.locale);
		if (countryCode === null) throw new Error('Could not get countryCode');

		return url.includes(`/${countryCode.toLowerCase()}`);
	}

	/**
	 * Collect and set the user data for error reporting tool
	 */
	private setUserDataForErrorReporting() : void {
		this.pSentryService.setUserAndAccountScope(this);
	}

	/**
	 * Deletes the user data for error reporting tool
	 */
	private clearUserDataForErrorReporting() : void {
		this.pSentryService.setUserAndAccountScope(null);
	}

	/**
	 *  Get current url
	 */
	private get url() : string {
		// Any navigation which has not been executed yet will be accessible through this.router.getCurrentNavigation().
		const currentNavigation = this.router.getCurrentNavigation();
		return currentNavigation ?
			currentNavigation.extractedUrl.toString() :
			this.router.url;
	}

	/**
	 * The path to the page where the user navigated to before logging if != log-in page
	 */
	public rememberPathWhenLoginFailed() : void {
		const currentUrl = this.router.getCurrentNavigation()?.finalUrl?.toString();
		if (currentUrl !== undefined && currentUrl !== Config.LOGIN_PATH) {
			this.pathWhenLoginFailed = window.location.href;
		}
	}

	/**
	 * Subject that will emit after the user has logged out, with the id of the logged out user
	 */
	public afterLogout : Subject<Id> = new Subject<Id>();

	/**
	 * Logs the current user out
	 */
	public async logout() : Promise<void> {
		const loggingOutUserId = this.data.id;
		await this.clearCredentials(true);

		this.clearUserDataForErrorReporting();

		// On login we have associated users data with current tawk chat. No, remove connection again
		// cspell:ignore tawkuuid
		this.cookie.delete('__tawkuuid', '/');

		this.afterLogout.next(loggingOutUserId);
	}

	/**
	 * Clear credential data.
	 * @param changeIndexedDB Should indexedDB values be touched?
	 */
	private async clearCredentials(changeIndexedDB : boolean) : Promise<void> {
		this.unload();

		this.invalidCredentials = undefined;
		Config.HTTP_AUTH_CODE = null;

		// remove indexedDB entry
		if (changeIndexedDB) {
			const pIndexedDBService = await waitForValueNotUndefined(this.zone, () => this.injector.get(PIndexedDBService, undefined));
			const deletePromise = new Promise(resolve => {
				pIndexedDBService.delete(AUTHENTICATION_INDEXED_DB_S_KEY, resolve.bind(this, true));
			});
			await deletePromise;
		}

	}

	/**
	 * Try to login from credentials in IndexedDB.
	 */
	public async loginFromIndexedDBCredentials() : Promise<void> {
		const pIndexedDBService = await waitForValueNotUndefined(this.zone, () => this.injector.get(PIndexedDBService, undefined));

		let basicAuthValue;

		try {
			/**
			 * The maximum duration the code is allowed to try to get the authentication key from the database.
			 * Without setting a maximum, it would be possible that the promise would never resolve,
			 * which would block the user without any feedback in the UI.
			 */
			const maxDurationMs = 5000;
			const intervalDuration = 100;

			basicAuthValue = await waitForValueNotUndefined(
				this.zone,
				() => pIndexedDBService.get(AUTHENTICATION_INDEXED_DB_S_KEY),
				intervalDuration,
				maxDurationMs / intervalDuration,
			);
		} catch {
			throw new Error('Could not get credentials from IndexedDB');
		}

		// already logged in with these data?
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.isLoaded() && basicAuthValue === this.basicAuthValue) {
			return;
		}

		// set credentials
		if (basicAuthValue) {
			await this.login(basicAuthValue, false);
		} else {
			throw new Error('No credentials in IndexedDB');
		}
	}

	/**
	 * Navigates to the app-depending log-in page
	 */
	public openLoginPage() : void {
		const loginPath = Config.LOGIN_PATH;

		if (!loginPath) {
			return;
		}

		const currPath = this.url;

		if (currPath !== loginPath) {
			// redirect to login page
			// eslint-disable-next-line ban/ban, @typescript-eslint/no-floating-promises -- intended navigation, FIXME: Remove this before you work here.
			this.router.navigate([loginPath]);
		}
	}

	/**
	 * Check if the test-phase is running
	 */
	public get isTestAccount() : boolean {
		return !!this.data.testAccountDeadline;
	}

	/**
	 * Returns the role and hence the permissions of the current user
	 */
	public get role() : AuthenticatedApiRole | undefined {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.isLoaded()) return undefined;
		if (this.data.isOwner) return AuthenticatedApiRole.CLIENT_OWNER;
		return AuthenticatedApiRole.CLIENT_DEFAULT;
	}
}
