/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { LocationChangeEvent, PlatformLocation } from '@angular/common';
import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastsService } from '@plano/client/service/toasts.service';
import { isChangeSelectorsModal } from '@plano/client/shared/p-transmission/change-selectors-modal.utils';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { AnchorLinkDirective } from '@plano/shared/core/p-common/p-anchor/p-anchor.directive';
import { ModalContentComponent } from '@plano/shared/core/p-modal/modal-content.component';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { LocalizePipe, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { waitUntil } from '@plano/shared/core/utils/async-await-utils';
import { assumeIsUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PClipboardService } from '@plano/shared/p-forms/p-clipboard.service';
import { Subject, Subscription } from 'rxjs';
import { PConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ModalContentOptions, PModalDefaultTemplateComponent } from './modal-default-template/modal-default-template.component';
import { ModalDismissParam, ModalServiceOptions } from './modal.service.options';

/** The possible parameter value for a modal Promise */
export type ModalResult<SuccessValueType = Event> = {
	action : 'success',
	value : SuccessValueType,
} | {
	action : 'dismiss',
	value : ModalDismissParam,
};

/** The return type of a method like openModal() */
export type ModalRef<SuccessValueType = Event> = {
	result : Promise<ModalResult<SuccessValueType>>,
	componentInstance : NgbModalRef['componentInstance'],
	close : NgbModalRef['close'],
	dismiss : NgbModalRef['dismiss'],
};

type PModalStackItem = {
	modalRef : NgbModalRef,
	keyboard : ModalServiceOptions['keyboard'],
};

/**
 * A service to open and handle modals.
 * Read the docs of {@link openModal} to learn how to create modals.
 */
@Injectable({ providedIn: 'root' })
export class ModalService extends PBaseClass implements OnDestroy {
	constructor(
		private modal : NgbModal,
		private location : PlatformLocation,
		private localize : LocalizePipe,
		private pRouterService : PRouterService,
		private router : Router,
		private zone : NgZone,
		private activatedRoute : ActivatedRoute,
		private console : LogService,
		private toastsService : ToastsService,
		private pClipboardService : PClipboardService,
	) {
		super();
		this.location.onPopState((event : LocationChangeEvent) => {
			if (this.pRouterService.ignoreCurrentNavigation) return;

			// ensure that modal is opened
			if (this.topModalRef === null) return;
			(event as unknown as Event).preventDefault();

			// the modal queryParam is passed when the modal is opened
			if (Object.keys(this.activatedRoute.snapshot.queryParams).join('').includes('modal'))
				return;
			this.topModalRef.dismiss();
		});
		this.pRouterServiceNavigationListener = this.pRouterService.events.subscribe((event) => {
			// don’t listen to any other events than ActivationEnd
			if (!(event instanceof ActivationEnd)) return;

			// the modal queryParam is passed when the modal is opened
			if (Object.keys(event.snapshot.queryParams).join('').includes('modal'))
				return;

			// we don't want to close the modal if the modal scroll query param is passed
			// we also need to pass the current url as long as we don't change the url handle of p-tabs
			if (event.snapshot.queryParams['modal-scroll']) {
				// Cleanup query-parameter
				const queryParams = new URLSearchParams(window.location.search);
				queryParams.delete('modal-scroll');

				// the removal should not add a browser history stack
				history.replaceState({}, '', `${window.location.pathname}${queryParams.size > 0 ? '?' : ''}${queryParams.toString()}`);

			}

			while (this.topModalRef) {
				this.topModalRef.dismiss();
				this.modalStack.pop();
			}
		});
	}

	/**
	 * Probably will never run since the service is provided in root,
	 * however we keep this here in case one day we change the provided in
	 */
	public ngOnDestroy() : void {
		this.pRouterServiceNavigationListener.unsubscribe();
	}

	private pRouterServiceNavigationListener! : Subscription;

	private modalStack : PModalStackItem[] = [];

	/**
	 * Number of blocking modals
	 */
	private highlightCancelBlockModals : number = 0;

	/**
	 * Subject that will emit when a modal gets closed/dismissed, with the correct string in the type and the index of the modal in the stack.
	 */
	public modalStateCloseSubject = new Subject<{type : 'dismiss' | 'success', stackIndex : number}>();

	/**
	 * Subject that will emit when a modal gets opened.
	 * It also emits the id of the modal that was opened, if any.
	 */
	public stateOpenSubject = new Subject<string | null>();

	/**
	 * Turn the options object to the NgbModalOptions object that the external library expects.
	 * This method also sets the default values for the options object if needed.
	 * @param input The options object that should be converted to NgbModalOptions
	 * @returns The NgbModalOptions object that can be passed to the NgbModal service
	 */
	private modalServiceOptionsToNgbModalOptions(input : Omit<ModalServiceOptions, 'scrollable'>) : NgbModalOptions {
		const modalServiceOptionDefaults = new ModalServiceOptions();
		const modalServiceOptions = {
			backdropClass: '',
			...modalServiceOptionDefaults,
			...input,
		};

		if (modalServiceOptions.size === undefined) modalServiceOptions.size = this.enums.BootstrapSize.SM;
		if (modalServiceOptions.animation === undefined) modalServiceOptions.animation = modalServiceOptions.size !== 'fullscreen';

		// Never want animations in GHERKIN mode
		if (Config.APPLICATION_MODE === 'GHERKIN')
			modalServiceOptions.animation = false;

		if (modalServiceOptions.theme !== undefined && modalServiceOptions.theme !== null) {
			modalServiceOptions.windowClass += ` modal-${modalServiceOptions.theme}`;
		}

		let backdropClass = modalServiceOptions.backdropClass!;
		backdropClass += (modalServiceOptions.backdrop === 'static' ? ' not-clickable bg-white' : '');

		const ngbModalOptions : NgbModalOptions = {
			size: modalServiceOptions.size,
			windowClass: modalServiceOptions.windowClass!,
			backdrop: modalServiceOptions.backdrop!,
			backdropClass: backdropClass,

			// NOTE: This will always be set to false, as we have our own implementation of the escape key mechanism.
			// See PCloseOnEscDirective
			keyboard: false,
			centered: false,
			scrollable: true,
			animation: modalServiceOptions.animation,

			beforeDismiss: input.beforeDismiss ?? (() => true),
		};

		if (input.injector !== undefined) ngbModalOptions.injector = input.injector;

		return ngbModalOptions;
	}

	/**
	 * Take information that was provided in the modalTemplate and add it to the options object.
	 * @param modalTemplate The modalTemplate that was passed to the openModal function
	 * @param inputOptions The options that the information should be added to
	 */
	private optionsWithInformationFromModalContent(
		modalTemplate : PModalTemplateDirective,
		inputOptions ?: Omit<ModalServiceOptions, 'scrollable' | 'theme'>,
	) : Omit<ModalServiceOptions, 'scrollable'> {
		// We dont want to mutate the inputOptions object, so we create a new object with the same values
		const result : ModalServiceOptions = { ...inputOptions };

		/*
		 * This check ensures that when the p-change-selectors-modal
		 * is passed inside the templateRef, the keyboard value gets set to false
		 * so we can't dismiss the modal using the escape key, or clicking outside the modal.
		 * Doing it here avoids that we have to remember to set the variable from the outside everytime.
		 */
		if (isChangeSelectorsModal(modalTemplate)) {
			result.keyboard = false;
		}

		if (modalTemplate.theme !== undefined) {

			// If modalContent instanceof PModalTemplateDirective then 'theme' is omitted in inputOptions. See overloads of
			// openModal. Instead, modalContent should contain the theme information.
			assumeIsUndefined(result.theme);

			result.theme = modalTemplate.theme;
		}

		if (modalTemplate.modalSize !== undefined && modalTemplate.modalSize !== null) {
			result.size = modalTemplate.modalSize;
		}

		return result;
	}

	/**
	 * Method to check if there are any open modals that block the escape listener that removes highlighting.
	 */
	public get hasHighlightCancelBlockModals() : boolean {
		return this.highlightCancelBlockModals > 0;
	}

	/**
	 * Function to get the top modal stack item of the modal stack
	 */
	private get topModalStackItem() : PModalStackItem | null {
		return this.modalStack[this.modalStack.length - 1] ?? null;
	}

	/**
	 * Function to get the top modal of the modal stack
	 */
	public get topModalRef() : NgbModalRef | null {
		return this.topModalStackItem?.modalRef ?? null;
	}

	/**
	 * Get the keyboard attribute of the modal which is currently the top modal.
	 */
	public get topModalKeyboard() : boolean | null {
		return this.topModalStackItem?.keyboard ?? null;
	}

	/**
	 * Add a modal that will block the escape listener from resetting highlighting
	 */
	public addBlockHighlightModalCount() : void {
		this.highlightCancelBlockModals++;
	}

	/**
	 * Remove a modal that would block the escape listener from resetting highlighting
	 */
	public removeBlockHighlightModalCount() : void {
		if (this.highlightCancelBlockModals > 0) {
			this.highlightCancelBlockModals--;
		}
	}

	/**
	 * Add the modal id to the url so it can be open via url later
	 */
	public addModalToUrl(pModalId : string | null) : string | null {
		const modalQueryName = `modal${this.modalStack.length}`;

		// new object that will hold the new queryParams for the modals
		const newQueryParams : {[key : string] : string | null} = {};

		for (const queryParam of Object.keys(this.activatedRoute.snapshot.queryParams)) {
			const modalIndex = queryParam.split('modal').at(1);

			/*
			 * The modal ids on the url might not match any modal, so we set the value of such queryParams
			 * to null
			 */
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (modalIndex !== undefined && /\d+/.test(modalIndex) && +modalIndex > this.modalStack.length)
				newQueryParams[queryParam] = null;
		}
		newQueryParams[modalQueryName] = pModalId;

		// set ignoreNavigationEvent to true to prevent the modals from closing on navigation, also keep the fragment
		// so the animation runs as smoothly as possible
		if (this.activatedRoute.snapshot.fragment === null)
		// eslint-disable-next-line ban/ban -- intended navigation
			void this.pRouterService.navigate([], {queryParams: newQueryParams, queryParamsHandling: 'merge', replaceUrl: true, state: {ignoreNavigationEvent: true}});
		// eslint-disable-next-line ban/ban -- intended navigation
		else void this.pRouterService.navigate([], {queryParams: newQueryParams, queryParamsHandling: 'merge', replaceUrl: true, state: {ignoreNavigationEvent: true}, fragment: this.activatedRoute.snapshot.fragment});

		return pModalId === null ? null : modalQueryName;
	}

	private anchorLinkDirective : AnchorLinkDirective | null = null;

	/**
	 * Remove the modal id from the url based on the query param name (meaning: 'modal0', 'modal1' and more)
	 */
	public async removeModalFromUrl(modalQueryName : string) : Promise<void> {
		await this.zone.runOutsideAngular(async () => {
			await waitUntil(this.zone, () => !this.router.getCurrentNavigation(),10);
			// eslint-disable-next-line ban/ban -- intended navigation to remove queryParam
			void this.pRouterService.navigate([], {queryParams: {[modalQueryName]: null}, queryParamsHandling: 'merge', replaceUrl: true, state: {ignoreNavigationEvent: true}});
		});
	}

	// If you pass a component class name as modalContent, then there is no theme information attached to it.
	// Thus we allow that theme gets passed as inputOptions.
	public openModal<SuccessValueType = Event>(
		modalContent : typeof ModalContentComponent,
		inputOptions ?: Omit<ModalServiceOptions, 'scrollable'>,
	) : ModalRef<SuccessValueType>;

	// If a PModalTemplateDirective gets provided, then settings 'theme' in inputOptions is omitted, because
	// PModalTemplateDirective already contains that information
	public openModal<SuccessValueType = Event>(
		modalContent : PModalTemplateDirective,
		inputOptions ?: Omit<ModalServiceOptions, 'scrollable' | 'theme'>,
	) : ModalRef<SuccessValueType>;

	/**
	 * Open a new Modal with the provided content and options.
	 *
	 * @example If you can, prefer to define your modal content in the template:
	 * 	In your *.component.html file:
	 *  ```html
	 * 	<ng-template
	 * 		#myModalContent
	 * 		pModalContent
	 * 		[modalId]="myModalId"
	 * 		[theme]="enums.PThemeEnum.PRIMARY"
	 * 	>
	 * 		<p-modal-content
	 * 			modalTitle="Testkunden erstellen"
	 * 			(onDismiss)="d($event)"
	 * 			(onClose)="c($event);"
	 * 		>
	 * 			<p-modal-content-body>
	 * 				Test
	 * 			</p-modal-content-body>
	 * 		</p-modal-content>
	 * 	</ng-template>
	 *  ```
	 *
	 * 	In your *.component.ts file:
	 *  ```typescript
	 * 	@ViewChild('modalContent', {static: true}) private myModalContent ! : PModalTemplateDirective;
	 * 	doSomething() {
	 * 		this.modalService.openModal(this.myModalContent);
	 * 	}
	 *  ```
	 *
	 * @example
	 * 	In your *.component.ts file:
	 * 	doSomething() {
	 * 		const modalRef = this.openModal(MySpecialModalContentComponent, confirmModalOptions);
	 * 		const pMySpecialModalContentComponent = modalRef.componentInstance as MySpecialModalContentComponent;
	 * 		pMySpecialModalContentComponent.initModal(content, options.theme);
	 * 	}
	 * 	In order to do this, your MySpecialModalContentComponent needs to extend the class {@link ModalContentComponent}.
	 *
	 * @param modalContent The content of the modal. Can either be a template (of type {@link PModalTemplateDirective}), or
	 * a component class which extends {@link ModalContentComponent}.
	 * @param inputOptions The options for the modal. If you pass a component class to {@link modalContent}, then you can
	 * pass a theme here, otherwise the {@link PModalTemplateDirective} would already contain that information.
	 * By default, all modals will have scrollbar-gutter stable, to disabled this pass the inputOptions object with a
	 * windowClass "no-gutter-body".
	 *
	 * @returns A ModalRef object that contains the result of the modal, the component instance and methods to
	 * close/dismiss the modal.
	 */
	public openModal<SuccessValueType = Event>(
		modalContent : typeof ModalContentComponent | PModalTemplateDirective,
		inputOptions : Omit<ModalServiceOptions, 'scrollable'> = {},
	) : ModalRef<SuccessValueType> {
		// We dont want to mutate the inputOptions object, so we create a new object with the same values
		let options = { ...inputOptions };

		if (modalContent instanceof PModalTemplateDirective) {
			options = this.optionsWithInformationFromModalContent(modalContent, options);
		}
		const ngbModalServiceOptions = this.modalServiceOptionsToNgbModalOptions(options);

		// add animation when user tries to close modal with keyboard
		if (options.keyboard === false) {
			ngbModalServiceOptions.backdrop = 'static';
		}

		const modalId = modalContent instanceof PModalTemplateDirective && typeof modalContent.pModalId === 'string' ? modalContent.pModalId : null;
		const modalQueryName = this.addModalToUrl(modalId);

		let topModal : NgbModalRef;
		const modalIndexInStack = this.modalStack.length;
		if (modalContent instanceof PModalTemplateDirective) {
			modalContent.openModalStackIndex = modalIndexInStack;
			topModal = this.modal.open(modalContent.template, ngbModalServiceOptions);
		} else {
			topModal = this.modal.open(modalContent, ngbModalServiceOptions);
			const modalContentComponent = topModal.componentInstance as ModalContentComponent;
			modalContentComponent.openModalStackIndex = modalIndexInStack;
		}
		const modalContentWithIndex = modalContent instanceof PModalTemplateDirective ? modalContent : topModal.componentInstance as ModalContentComponent;
		const modalStackItem : PModalStackItem = {
			modalRef: topModal,
			keyboard: options.keyboard,
		};
		this.modalStack.push(modalStackItem);
		const openedModals : NodeListOf<HTMLElement> | null = document.querySelectorAll('ngb-modal-window');
		const openedModalWindow : HTMLElement | null = openedModals.item(openedModals.length - 1).querySelector('.modal-dialog') ?? null;

		if (options.isMaxHeightModal) {
			const modalContentElement : HTMLElement | null = openedModalWindow?.querySelector('.modal-content') ?? null;
			modalContentElement?.classList.add('max-height-modal');
		}

		/*
		 All our modals are scrollable, but we can’t really add our scroll-box inside as our modals are controlled by a service that we don’t control
		 and it would require more style changes, so we just add the class 'p-scroll-parent' to the modal window.
		 */
		openedModalWindow?.classList.add('p-scroll-parent');
		const openedModalBody : HTMLElement | null = openedModalWindow?.querySelector('.modal-body') ?? null;
		let resizeModalBodyObserver : ResizeObserver | null = null;
		this.zone.runOutsideAngular(() => {
			if (openedModalBody) {
				if (openedModalBody.clientHeight < openedModalBody.scrollHeight) {
						openedModalWindow!.classList.add('modal-has-scroll');
				} else openedModalWindow!.classList.remove('modal-has-scroll');
				resizeModalBodyObserver = new ResizeObserver(() => {
					if (openedModalBody.clientHeight < openedModalBody.scrollHeight) {
							openedModalWindow!.classList.add('modal-has-scroll');
					} else openedModalWindow!.classList.remove('modal-has-scroll');
				});
				resizeModalBodyObserver.observe(openedModalBody);
			}
			window.requestAnimationFrame(() => {
				this.stateOpenSubject.next(modalId);
				if (modalId !== null) {
					const firstElementChild = openedModalWindow?.querySelector('.modal-header')?.firstElementChild;
					if (firstElementChild) {
						const innerHtml = firstElementChild.innerHTML;
						firstElementChild.innerHTML = '';
						const newSpanElement = document.createElement('span');
						newSpanElement.classList.add('d-inline-flex', 'align-items-center');
						newSpanElement.innerHTML = innerHtml;
						firstElementChild.appendChild(newSpanElement);
						this.anchorLinkDirective = new AnchorLinkDirective(new ElementRef(newSpanElement), this.console, this.localize, this.pClipboardService, this.toastsService);
						this.anchorLinkDirective.anchorPos = 'center-right';
						this.anchorLinkDirective.noLinkId = true;
						// eslint-disable-next-line @angular-eslint/no-lifecycle-call -- we need to run afterViewInit from the outside
						this.anchorLinkDirective.ngAfterViewInit();
					}
				}
			});
			openedModalWindow?.focus();
		});
		const promise = new Promise<ModalResult<SuccessValueType>>(resolve => {
			// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
			topModal.result.then(async (result) => {
				if (modalQueryName !== null) {
					await this.removeModalFromUrl(modalQueryName);
				}
				this.modalStateCloseSubject.next({type: 'success', stackIndex: modalIndexInStack});
				// eslint-disable-next-line @angular-eslint/no-lifecycle-call -- need to call ngOnDestroy from the outside
				this.anchorLinkDirective?.ngOnDestroy();

				this.modalStack.splice(modalIndexInStack, 1);
				resizeModalBodyObserver?.disconnect();
				resolve({
					action: 'success',
					value: result,
				});
			// eslint-disable-next-line promise/prefer-await-to-callbacks, promise/prefer-await-to-then -- FIXME: Remove this before you work here.
			}).catch(async (error : ModalDismissParam) => {
				if (modalQueryName !== null) {
					await this.removeModalFromUrl(modalQueryName);
				}
				this.modalStateCloseSubject.next({type: 'dismiss', stackIndex: modalIndexInStack});
				// eslint-disable-next-line @angular-eslint/no-lifecycle-call -- need to call ngOnDestroy from the outside
				this.anchorLinkDirective?.ngOnDestroy();
				this.modalStack.splice(modalIndexInStack, 1);
				resizeModalBodyObserver?.disconnect();
				resolve({
					action: 'dismiss',
					value: error,
				});
			// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
			}).finally(() => {
				modalContentWithIndex.openModalStackIndex = null;
			});
		});

		// HACK: This is a hack for the modal-over-modal scroll issue.
		// More Info: https://github.com/ng-bootstrap/ng-bootstrap/issues/643
		// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
		topModal.result.then(() => {
			if (document.querySelector('body > .modal.open')) {
				document.body.classList.add('modal-open');
			}
		// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
		}).catch(() => {
			if (document.querySelector('body.modal-open')) {
				document.body.classList.remove('modal-open');
			}
		// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
		}).finally(() => {
			const modalWindow : HTMLElement | null = (document.querySelector('ngb-modal-window'));
			modalWindow?.focus();
		});

		return {
			result: promise,
			componentInstance: topModal.componentInstance,
			close: (result) => { topModal.close(result); },
			dismiss: (result) => { topModal.dismiss(result); },
		};
	}

	/**
	 * Opens a confirm modal without the need to provide a template for the inner content
	 * If you don’t provide a dismiss handler, the Dismiss-Button in the footer will be hidden.
	 */
	public confirm(
		contentOptions : ModalContentOptions,
		options : Pick<ModalServiceOptions, 'theme' | 'size'> = {},
	) : ModalRef {
		if (contentOptions.closeBtnLabel === null) contentOptions.closeBtnLabel = this.localize.transform('Ja');
		if (contentOptions.dismissBtnLabel === undefined) contentOptions.dismissBtnLabel = this.localize.transform('Nein');
		const confirmModalOptions : ModalServiceOptions = {
			size: options.size ?? this.enums.BootstrapSize.SM,
			animation: true,
		};

		if (options.theme !== undefined && options.theme !== null) confirmModalOptions.theme = options.theme;

		const modalRef = this.openModal(PConfirmModalComponent, confirmModalOptions);
		const pConfirmModalComponent = modalRef.componentInstance as PConfirmModalComponent;

		pConfirmModalComponent.initModal(contentOptions, confirmModalOptions.theme);
		return modalRef;
	}

	/**
	 * Opens a themed modal without the need to provide a template for the inner content
	 * @param modalContentOptions The options for the modal content
	 * @param options The options for the modal service like size and keyboard
	 */
	public openDefaultModal<T = Event>(
		modalContentOptions : ModalContentOptions,
		options : Omit<ModalServiceOptions, 'success'> | null = null,
	) : ModalRef<T> {
		if (options === null) options = {};
		const modalRef = this.openModal<T>(PModalDefaultTemplateComponent, options);

		// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
		void modalRef.result.then(promiseResult => {
			if (promiseResult.action === 'dismiss') {
				// It is important to destroy before you run options.dismiss.
				// Imagine: Dismiss could remove things from api which embeddedContentView relies on.
				defaultModalComponent.embeddedContentView?.destroy();
				defaultModalComponent.embeddedFooterView?.destroy();
			}
		// eslint-disable-next-line promise/prefer-await-to-then -- FIXME: Remove this before you work here.
		}).finally(() => {
			defaultModalComponent.embeddedContentView?.destroy();
			defaultModalComponent.embeddedFooterView?.destroy();
		});

		const defaultModalComponent = modalRef.componentInstance as PModalDefaultTemplateComponent;
		const theme = options.theme ?? null;
		defaultModalComponent.initModal(modalContentOptions, theme);

		return modalRef;
	}

	/**
	 * Shorthand to open a modal for Attribute Info’s `cannotSetHint`
	 * @param cannotSetHint The text to display in the modal
	 * @param cannotSetHintTemplate A template for the modal content if you want to provide one
	 * @param theme The theme of the modal
	 */
	public openCannotSetHintModal(
		cannotSetHint : PDictionarySource,
		cannotSetHintTemplate : PModalTemplateDirective | null = null,

		// TODO: PLANO-177814 -- set this to theme INFO as default
		theme : typeof this.enums.PThemeEnum.INFO | null = null,
	) : ModalRef {
		const modalContentOptions : ModalContentOptions = {
			modalTitle: null,
			description: cannotSetHintTemplate ? null : this.localize.transform(cannotSetHint),
		};
		if (cannotSetHintTemplate) modalContentOptions.pModalTemplate = cannotSetHintTemplate;
		return this.openDefaultModal(modalContentOptions, {
			theme: theme,
			size: this.enums.BootstrapSize.SM,
		});
	}

	/**
	 * Opens a warning modal without the need to provide a template for the inner content.
	 * @param modalContentOptions The options for the modal, closeBtnLabel is required, as otherwise the button for closing could easily be mistaken
	 * for a dismiss button, and so checking the result of the modal could be miss leading. This way, if we can still not add a specific close button label by setting null,
	 * but it is a conscious decision.
	 * @param modalSize The size of the modal
	 */
	public warn(
		modalContentOptions : Omit<ModalContentOptions, 'closeBtnLabel'> & Required<Pick<ModalContentOptions, 'closeBtnLabel'>>,
		modalSize : ModalServiceOptions['size'] | null = null,
	) : ModalRef {
		if (modalContentOptions.modalTitle === undefined) modalContentOptions.modalTitle = this.localize.transform('Achtung!');
		const options : ModalServiceOptions = {
			theme: this.enums.PThemeEnum.WARNING,
		};
		if (modalSize !== null) options.size = modalSize;

		return this.openDefaultModal(modalContentOptions, options);
	}

	/**
	 * Opens a info modal without the need to provide a template for the inner content.
	 * @param modalContentOptions The options for the modal, closeBtnLabel is required, as otherwise the button for closing could easily be mistaken
	 * for a dismiss button, and so checking the result of the modal could be miss leading. This way, if we can still not add a specific close button label by setting null,
	 * but it is a conscious decision.
	 * @param modalSize The size of the modal
	 */
	public info(
		modalContentOptions : Omit<ModalContentOptions, 'closeBtnLabel'> & Required<Pick<ModalContentOptions, 'closeBtnLabel'>>,
		modalSize : ModalServiceOptions['size'] | null = null,
	) : ModalRef {
		if (modalContentOptions.modalTitle === undefined) modalContentOptions.modalTitle = this.localize.transform('Hinweis');
		const options : ModalServiceOptions = {
			theme: this.enums.PThemeEnum.INFO,
		};
		if (modalSize !== null) options.size = modalSize;

		return this.openDefaultModal(modalContentOptions, options);
	}

	/**
	 * Opens a danger-themed modal without the need to provide a template for the inner content.
	 * Use this for critical situations to protect the user from unconscious actions.
	 * @param modalContentOptions The options for the modal, closeBtnLabel is required, as otherwise the button for closing could easily be mistaken
	 * for a dismiss button, and so checking the result of the modal could be miss leading. This way, if we can still not add a specific close button label by setting null,
	 * but it is a conscious decision.
	 * @param modalSize The size of the modal
	 * @param animation Whether the modal’s appearance should be animated
	 */
	public danger(
		modalContentOptions : Omit<ModalContentOptions, 'closeBtnLabel'> & Required<Pick<ModalContentOptions, 'closeBtnLabel'>>,
		modalSize : ModalServiceOptions['size'] = this.enums.BootstrapSize.MD,
		animation : ModalServiceOptions['animation'] = true,
	) : ModalRef {
		if (modalContentOptions.modalTitle === undefined) modalContentOptions.modalTitle = this.localize.transform('Fehler!');
		return this.openDefaultModal(modalContentOptions, {
			theme: this.enums.PThemeEnum.DANGER,
			size: modalSize,
			animation: animation,
		});
	}

	/**
	 * This is a shorthand for bringing a templateRef of a modal-content into a structure that fits
	 * into the saveChangesHook attribute of an pEditable
	 * @param modalContent The templateRef of the template which contains the content of the modal
	 * @param options The options for the modal like size and keyboard
	 */
	public getEditableHookModal(
		modalContent : PModalTemplateDirective,
		options : Pick<ModalServiceOptions, 'size' | 'keyboard'> = {},
	) : () => ModalRef['result'] {
		return async () => {
			const modalOptions : ModalServiceOptions = {};
			if (options.size !== undefined) modalOptions.size = options.size;
			if (options.keyboard !== undefined) modalOptions.keyboard = options.keyboard;
			return this.openModal(
				modalContent,
				modalOptions,
			).result;
		};
	}

	/**
	 * Get the number of open modals
	 */
	public get numberOfOpenModals() : number {
		return this.modalStack.length;
	}
}
