import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service that emits when global event listeners are fired, this prevents that we add a lot
 * of event listeners, for the same events.
 */
@Injectable( { providedIn: 'root' } )
export class PGlobalEventListenersService {

	constructor(
		zone : NgZone,
	) {
		zone.runOutsideAngular(() => {
			document.addEventListener('scroll', (event) => {this.globalScrollEvent.next(event);}, true);
			document.addEventListener('wheel', (event) => {this.globalWheelEvent.next(event);}, true);
			document.addEventListener('focus', (event) => {this.globalFocusEvent.next(event);}, true);
			document.addEventListener('blur', (event) => {this.globalBlurEvent.next(event);}, true);
			document.addEventListener('click', (event) => {this.globalClickEvent.next(event);}, true);
			document.addEventListener('mousedown', (event) => {this.globalMouseDownEvent.next(event);}, true);
			document.addEventListener('mouseup', (event) => {this.globalMouseUpEvent.next(event);}, true);
			document.addEventListener('keydown', (event) => {this.globalKeyDownEvent.next(event);}, true);
			document.addEventListener('keyup', (event) => {this.globalKeyUpEvent.next(event);}, true);
		});
	}

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalClickEvent = new Subject<MouseEvent>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalMouseDownEvent = new Subject<MouseEvent>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalMouseUpEvent = new Subject<MouseEvent>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalFocusEvent = new Subject<FocusEvent>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalBlurEvent = new Subject<FocusEvent>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalWheelEvent = new Subject<WheelEvent>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalScrollEvent = new Subject<Event>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalKeyDownEvent = new Subject<KeyboardEvent>();

	/**
	 * This event subject doesn't trigger change detection automatically as the javascript events listeners,
	 * so please note that, if needed, the change detection should be handled by the components that use it.
	 */
	public globalKeyUpEvent = new Subject<KeyboardEvent>();
}
