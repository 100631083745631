/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { Component, ContentChildren, ElementRef, EventEmitter, Host, HostBinding, Input, OnInit, Optional, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { ALIGN_ITEMS_CLASSES_REGEX, BootstrapRounded, FLEX_GROW_CLASSES_REGEX, getThemeFromBtnTheme, JUSTIFY_CONTENT_CLASSES_REGEX, PBtnTheme, PBtnThemeEnum, PTextColor, PTextColorEnum, PThemeEnum, ROUNDED_CLASSES_REGEX } from '@plano/client/shared/bootstrap.utils';
import { PBadgeComponent } from '@plano/client/shared/component/p-badge/p-badge.component';
import { PBadgeComponentInterface } from '@plano/client/shared/component/p-badge/p-badge.types';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { EditableControlInterface, EditableDirective, EditableInstantSaveOnClickDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { Config } from '@plano/shared/core/config';
import { PCloseOnEscDirective } from '@plano/shared/core/directive/close-on-esc.directive';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { PAutoFocusInsideModalDirective } from '@plano/shared/core/p-auto-focus/p-auto-focus.directive';
import { FaIconComponentIcon } from '@plano/shared/core/p-common/fa-icon/fa-icon-component.types';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { ModalDirective } from '@plano/shared/core/p-modal/modal.directive';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { createFakeClickOutput } from '@plano/shared/core/utils/angular-utils';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PButtonType {
	DEFAULT = 'default',
	TOGGLE = 'toggle',
	FILTER = 'filter',
}

@Component({
	selector: 'p-button',
	templateUrl: './p-button.component.html',
	styleUrls: ['./p-button.component.scss'],
	standalone: true,
	imports: [
		PCommonModule,
		FaIconComponent,
		EditableDirective,
		EditableInstantSaveOnClickDirective,
		PBadgeComponent,
		PTooltipDirective,
		PAutoFocusInsideModalDirective,
		PCloseOnEscDirective,
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PButtonComponent extends AttributeInfoBaseComponentDirective implements EditableControlInterface, OnInit, PComponentInterface {
	/** @deprecated use @see PButtonComponent.btnTitle instead */
	@Input() public title ! : never;

	/**
	 * HTML title to be added to the button
	 *
	 * NOTE: Use this instead of title
	 */
	@Input() public btnTitle : string | null = null;

	/**
	 * Should this component be animated to show that a process is running?
	 * @see PComponentInterface#isLoading
	 */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	/**
	 * A tooltip to attach to the button
	 * TODO: PLANO-186041 Can this be removed?
	 */
	@Input() public btnTooltip : PTooltipDirective['pTooltip'] = null;

	/**
	 * Template to be appended to the button, this template will be wrapped in a button, and when clicked,
	 * will emit the event btnAppendClicked
	 * TODO:
	 * 	PLANO-186041 this is not necessary anymore. You can replace cases using btnAppendTemplate with a
	 * 	div.btn-group and two p-button’s inside it.
	 */
	@Input() protected btnAppendTemplate : TemplateRef<unknown> | null = null;

	/**
	 * Tooltip for the button that is appended
	 */
	@Input() protected btnAppendTooltip : TemplateRef<unknown> | string | null = null;

	/**
	 * Classes to be added to the appended button, if any
	 */
	@Input() protected btnAppendClasses : string = '';

	/**
	 * Event emitter for when the appended template is clicked
	 */
	@Output() protected btnAppendClicked = new EventEmitter<MouseEvent>();

	/**
	 * Should the label inside the button wrap
	 */
	@Input() public wrapLabel = true;

	/**
	 * Should the button be frameless?
	 * This removes the border and rounding of the button.
	 */
	@Input() public frameless : boolean = false;

	@HostBinding('class.d-flex')
	protected readonly _alwaysTrue = true;

	@HostBinding('class.btn-group') private get _isBtnGroup() : boolean {
		return (!!this.disabled && this.cannotSetHint !== null && !this.isLoading) || this.btnAppendTemplate !== null;
	}

	/**
	 * The `aria-label` text which will be attached to the HTML `button` element.
	 * Normally, it should not be necessary to set this manually unless e.g. the button text is just an icon
	 * but you want to locate the button by a text.
	 */
	@Input() public ariaLabel : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : PFormControlComponentInterface['size'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('theme') private _theme : PBtnTheme | null = null;

	/** Should there be stripes in the background of this button? */
	@Input() protected stripesTheme : typeof this.enums.PThemeEnum.INFO | typeof this.enums.PThemeEnum.PRIMARY | null = null;

	/**
	 * The id of the button element.
	 * TODO: PLANO-186041 This input will be obsolete when PLANO-186041 is done.
	 */
	@Input() public btnId : string | null = null;

	/**
	 * Classes that should be added to the html button element
	 */
	public get btnClasses() : string {
		return this._btnClasses;
	}
	@Input() public set btnClasses(input : string) {
		if (input.match(ROUNDED_CLASSES_REGEX) !== null) this.console?.error(`Please use the [rounded] input instead of setting bootstraps rounded classes directly. Your current classes are »${input}«`);
		this._btnClasses = input;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public textStyle : PTextColor | null = null;

	/**
	 * Role to be added to the button
	 */
	@Input() public btnRole : 'button' | 'combobox' | 'columnheader' | 'checkbox' = 'button';

	/**
	 * Should the button label be aligned left, right or center?
	 */
	@Input() public textAlign : 'left' | 'right' | 'center' = 'center';

	/**
	 * Is the button checked? Should be used in combination with the btnRole 'checkbox'
	 */
	@Input() protected ariaChecked : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public buttonType : PButtonType = PButtonType.DEFAULT;

	/**
	 * Is this button active? Can be used in combination with type @see PButtonType.TOGGLE
	 */
	@Input() public isActiveButton : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public darkMode : boolean = false;

	/** @see PBadgeComponentInterface#content */
	@Input() public badge : PBadgeComponentInterface['content'] = null;

	/** @see PBadgeComponentInterface#badgeAttachTopPosition */
	@Input() public badgeAttachTopPosition : PBadgeComponentInterface['badgeAttachTopPosition'] = 'right';

	/**
	 * A class that gets added to the element that wraps the ng-content.
	 * TODO: PLANO-186041 Remove this input if possible.
	 */
	@Input() protected ngContentWrapperClass : string = '';

	@ContentChildren(FaIconComponent) public faIconInsideNgContent ?: QueryList<FaIconComponent>;

	@ViewChild('innerBtn') public innerBtn ?: ElementRef<HTMLButtonElement>;

	/**
	 * @see EditableInstantSaveOnClickDirective.triggerClick
	 * Use this instead of (click)
	 * TODO: PLANO-169467 Give this a more intuitive name.
	 */
	@Output() public triggerClick = new EventEmitter<MouseEvent>();

	/**
	 * Prevent dev’s from trying to bind (onClick). Unfortunately there is not error about it from Angular.
	 * @deprecated Use (triggerClick) instead
	 */
	@Output() public onClick ! : never;

	/**
	 * @see createFakeClickOutput
	 * @deprecated Use (triggerClick) instead
	 */
	// eslint-disable-next-line @angular-eslint/no-output-native -- See createFakeClickOutput
	@Output() public click = createFakeClickOutput();

	/**
	 * For a non-rounded button use workaround: [btnClasses]="'rounded-0'"
	 */
	@Input() public rounded ?: BootstrapRounded | null = null;

	/**
	 * Button will have a pill style if its a toggle button. With [pill]="false" you can enforce a non-pill button.
	 */
	@Input('pill') public _pill : 'start' | boolean | null = null;

	// These are necessary Inputs and Outputs for pEditable form-element
	@Input() public pEditable : EditableControlInterface['pEditable'] = false;
	@Input() public api : EditableControlInterface['api'] = null;
	@Input() public valid : EditableControlInterface['valid'] = null;
	// eslint-disable-next-line @typescript-eslint/require-await -- shortest way to define the default
	@Input() public saveChangesHook : EditableControlInterface['saveChangesHook'] = async () => null;
	@Output() public onSaveStart : EditableControlInterface['onSaveStart'] = new EventEmitter();
	@Output() public onSaveSuccess : EditableControlInterface['onSaveSuccess'] = new EventEmitter();
	@Output() public onDismiss : EditableDirective['onDismiss'] = new EventEmitter();
	@Output() public onLeaveCurrent : EditableControlInterface['onLeaveCurrent'] = new EventEmitter();
	@Output() public beforeApiChanges : EditableControlInterface['beforeApiChanges'] = new EventEmitter<undefined>();
	@Output() public editMode : EditableControlInterface['editMode'] = new EventEmitter<boolean>(undefined);

	// TODO: PLANO-186041 Remove this input if possible.
	/** @see PCloseOnEscBtnDirective#pCloseOnEsc */
	@Input() public pCloseOnEscBtn : PCloseOnEscDirective['pCloseOnEsc'] = false;

	// TODO: PLANO-186041 Remove this input if possible.
	/** @see PCloseOnEscBtnDirective#pCloseOnEscType */
	@Input() public pCloseOnEscType : PCloseOnEscDirective['pCloseOnEscType'] = 'dropdown';

	// TODO: PLANO-186041 Remove this input if possible.
	/** @see PCloseOnEscBtnDirective#pCloseOnEscTriggerVisible */
	@Input() public pCloseOnEscTriggerVisible : PCloseOnEscDirective['pCloseOnEscTriggerVisible'] = true;

	// TODO: PLANO-186041 Remove this input if possible.
	/** @see PCloseOnEscBtnDirective#animate */
	@Input() public pCloseOnEscBtnAnimate : PCloseOnEscDirective['animate'] = true;

	/**
	 * Is this button disabled?
	 */
	public get disabled() : boolean {
		return this._disabled || !this.canSet;
	}
	@Input('disabled') public set disabled(input : boolean) {
		this._disabled = input;
	}

	/** Is it required to click this button? */
	@Input() public required : boolean = false;

	/** Should this btn be marked as something invalid? */
	@Input() public hasDanger : boolean = false;

	@Input('type') private set type(_input : 'button' | 'submit') {
		if (this.console) {
			this.console.error('Setting a button type is not supported on p-button yet');
		} else {
			throw new Error('Setting a button type is not supported on p-button yet');
		}
	}

	@Input('class')
	private get class() : string { return this._class; }
	private set class(input : string) {
		// TODO: PLANO-186041 Remove next block
		if (
			input.includes('btn') &&

			// TODO: PLANO-186041 Remove next line
			!input.includes('btn-group-append') &&

			// TODO: PLANO-186041 Remove next line
			!input.includes('btn-group-prepend')
		) this.console?.error(`Don’t set button classes on p-button. Use e.g. [theme]="PThemeEnum.PRIMARY" or [theme]="PBtnThemeEnum.OUTLINE_DANGER". Your current classes are »${input}«`);
		this._class = input;
	}

	/** @see FaIconComponent#spin */
	@Input() public iconSpin : FaIconComponent['spin'] = false;

	/** @see FaIconComponent#size */
	@Input() protected iconSize : FaIconComponent['size'] = null;

	/**
	 * Icon next to the button-text
	 */
	public get icon() : FaIconComponentIcon | null {
		if (this._icon !== null) return this._icon;
		if (this.buttonType === PButtonType.FILTER) {
			return this.isActiveButton ? this.enums.PlanoFaIconContextPool.INVISIBLE : this.enums.PlanoFaIconContextPool.VISIBLE;
		}
		return null;
	}
	@Input('icon') public set icon(input : FaIconComponentIcon | null) {
		this._icon = input;
	}

	/**
	 * Should the icon/badge be aligned at the start or the end?
	 * @default 'start'
	 */
	@Input() public badgeOrIconPosition : 'start' | 'end' = 'start';

	/**
	 * The gap between the content elements like icon and text.
	 */
	@Input() protected gap : 0 | 1 | 2 | 3 | 4 | 5 = 2;

	// eslint-disable-next-line @angular-eslint/no-output-native -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	@Output() public focus = new EventEmitter<MouseEvent>();
	// eslint-disable-next-line @angular-eslint/no-output-native -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	@Output() public blur = new EventEmitter<MouseEvent>();

	/** The link that is potentially bound to this button */
	@Input() private routerLink : string | null = null;

	constructor(
		private modalService : ModalService,
		public elementRef ?: ElementRef<HTMLElement>,
		@Optional() @Host() public pModal ?: ModalDirective,
		protected override console ?: LogService,
	) {
		super(true, console);
	}

	private readonly ALIGN_ITEMS_DEFAULT = 'align-items-center';
	private readonly FLEX_GROW_DEFAULT = 'flex-grow-1';
	private _btnClasses : string = '';
	public _disabled : boolean = false;
	public _icon : FaIconComponentIcon | null = null;
	private _class = '';

	// eslint-disable-next-line jsdoc/require-param -- False-positive. Interface defines the params.
	/** @see ControlValueAccessor#setDisabledState */
	public setDisabledState(isDisabled : boolean) : void {
		if (this._disabled === isDisabled) return;

		// Set internal attribute
		this._disabled = isDisabled;
	}

	private extractTextColorFromTheme() : Exclude<PThemeEnum, 'info'> | null {
		let result : PThemeEnum | null;

		switch (this.theme) {
			case PBtnThemeEnum.OUTLINE_PRIMARY:
			case PBtnThemeEnum.OUTLINE_SUCCESS:
			case PBtnThemeEnum.OUTLINE_INFO:
			case PBtnThemeEnum.OUTLINE_WARNING:
			case PBtnThemeEnum.OUTLINE_LIGHT:
			case PBtnThemeEnum.OUTLINE_SECONDARY:
			case PBtnThemeEnum.OUTLINE_DANGER:
			case PBtnThemeEnum.OUTLINE_DARK:
			case PBtnThemeEnum.OUTLINE_PURPLE:
			case PBtnThemeEnum.OUTLINE_PISTACHIO:
			case PBtnThemeEnum.OUTLINE_SEPIA:
				result = getThemeFromBtnTheme(this.theme);
				break;
			default:
				result = this.theme;
		}

		// FaIconComponent#icon does not support theme INFO.
		if (result === this.enums.PThemeEnum.INFO) return null;

		return result;
	}

	/**
	 * In which theme-color should the icon appear.
	 * If this is some kind of toggle-button, then this will probably change based on active state.
	 */
	public get iconTheme() {
		if (this.buttonType === PButtonType.FILTER) {
			return this.isActiveButton ? this.extractTextColorFromTheme() : null;
		}
		return null;
	}

	protected override attributeInfoRequired = false;

	public BootstrapRounded = BootstrapRounded;
	public PTextColorEnum = PTextColorEnum;
	public PButtonType = PButtonType;
	public PBtnThemeEnum = PBtnThemeEnum;

	public config : typeof Config = Config;

	public override ngOnInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initValues();
		return super.ngOnInit();
	}

	/**
	 * In which color-theme should the button appear?
	 */
	public get theme() : Exclude<PButtonComponent['_theme'], null> {
		if (this._theme !== null) return this._theme;
		if (this.buttonType === PButtonType.TOGGLE) return this.enums.PThemeEnum.PRIMARY;
		if (this.buttonType === PButtonType.FILTER) return this.darkMode ? PBtnThemeEnum.OUTLINE_LIGHT : this.enums.PThemeEnum.PRIMARY;
		return this.enums.PThemeEnum.SECONDARY;
	}

	/**
	 * Set values that are necessary for this component/directive.
	 * These initValues methods are used in many components and directives
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		this.validateValues();
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	private validateValues() : void {

		// Output names should never collide with native HTML dom events
		// See: https://medium.com/angular-athens/naming-of-output-events-in-angular-2063cad94183
		if (
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			(this.click as EventEmitter<Event>).observed &&
			!this.pModal &&

			// If a routerLink gets used in combination with this component, the click event will be
			// observed internally by the routerLink directive.
			!this.routerLink
		) {
			this.console?.error(`Button has potential problem: ${this.elementRef?.nativeElement.outerText ?? this.icon}. Use the components '(triggerClick)' instead of native html’s '(click)'. For more info, see https://medium.com/angular-athens/naming-of-output-events-in-angular-2063cad94183`);
		}

		if ((this.buttonType === PButtonType.TOGGLE || this.buttonType === PButtonType.FILTER) && this.icon === null && this.faIconInsideNgContent?.length === 0) this.console?.error('icon must be provided for toggle buttons');

		if (this.pCloseOnEscBtn && this.pCloseOnEscBtnAnimate && this.icon === null) {
			this.console?.error('If you want to animate the close-on-esc, you need to provide an icon that will be animated.');
		}
	}

	private get justifyClassByType() : string {
		if (this.buttonType === PButtonType.TOGGLE) return 'justify-content-start';
		if (this.buttonType === PButtonType.FILTER) return 'justify-content-start';
		return 'justify-content-center';
	}

	/** Determine a justify class (with a default) for the button */
	public get justifyClass() : string {
		// If the button already gets such classes passed, we dont need any internal logic to apply them.
		if (this.btnClasses.match(JUSTIFY_CONTENT_CLASSES_REGEX) !== null) return '';

		return this.justifyClassByType;
	}

	/**
	 * Get the justify-content class for the ng-content-wrapper.
	 */
	protected get justifyClassForNgContentWrapper() : string {
		// If the classes already contain them, we dont need to apply any more.
		if (this.ngContentWrapperClass.match(JUSTIFY_CONTENT_CLASSES_REGEX) !== null) return '';

		// If the parent contains such classes, then the children should get the same.
		const matches = this.btnClasses.match(JUSTIFY_CONTENT_CLASSES_REGEX);
		if (matches !== null) {
			return matches.join(' ');
		}

		return this.justifyClassByType;
	}

	/** Determine a align-items class (with a default) for the button */
	public get alignItemsClass() : string {
		// If the classes already contain them, we dont need to apply others.
		if (this.btnClasses.match(ALIGN_ITEMS_CLASSES_REGEX) !== null) return '';

		return this.ALIGN_ITEMS_DEFAULT;
	}

	/**
	 * Get the align-items class for the ng-content-wrapper.
	 */
	protected get alignItemsClassForNgContentWrapper() : string {
		// If the classes already contain them, we dont need to apply any more.
		if (this.ngContentWrapperClass.match(ALIGN_ITEMS_CLASSES_REGEX) !== null) return '';

		// If the parent contains such classes, then the children should get the same.
		const matches = this.btnClasses.match(ALIGN_ITEMS_CLASSES_REGEX);
		if (matches !== null) {
			return matches.join(' ');
		}

		return this.ALIGN_ITEMS_DEFAULT;
	}

	/** Determine a flex-grow class (with a default) for the button */
	public get flexGrowClass() : string {
		// If the classes already contain them, we dont need to apply others.
		if (this.btnClasses.match(FLEX_GROW_CLASSES_REGEX) !== null) return '';

		// If the parent contains such classes, then the children should get the same.
		const matches = this._class.match(FLEX_GROW_CLASSES_REGEX);
		if (matches !== null) {
			return matches.join(' ');
		}

		return this.FLEX_GROW_DEFAULT;
	}

	/**
	 * Get the flex-grow class for the ng-content-wrapper.
	 */
	protected get flexGrowClassForNgContentWrapper() : string {
		// If the classes already contain them, we dont need to apply others.
		if (this.ngContentWrapperClass.match(FLEX_GROW_CLASSES_REGEX) !== null) return '';

		// If the parent contains such classes, then the children should get the same.
		const matches = this.btnClasses.match(FLEX_GROW_CLASSES_REGEX);
		if (matches !== null) {
			return matches.join(' ');
		}

		return '';
	}

	private get isRoundedToggleButton() : boolean {
		return this.buttonType === PButtonType.TOGGLE && !this.class.includes('card-option') && !this.btnClasses.includes('card-option');
	}

	private get needsPillClass() : boolean {
		return this.isRoundedToggleButton || this.buttonType === PButtonType.FILTER;
	}

	/**
	 * Add a pill class if {@link needsPillClass} is true.
	 * @returns
	 * 	false if there should not be a pill class
	 * 	true if there should be a pill class
	 * 	'start' if the pill should be rounded at the start
	 */
	public get pillClass() : 'start' | boolean {
		if (this._pill !== null) return this._pill;
		if (this.needsPillClass) {
			if (this.btnAppendTemplate === null) return true;
			return 'start';
		}
		return false;
	}

	/**
	 * Open a Modal like info-circle does it when in IS_MOBILE mode.
	 */
	public openCannotSetHint() : void {
		if (this.cannotSetHint === null) {
			this.console?.error('It should not have been possible to run this method');
			return;
		}
		this.modalService.openCannotSetHintModal(this.cannotSetHint);
	}

	/**
	 * Get the related btn-class for the theme
	 */
	protected get btnThemeClass() : string {
		return `btn-${this.theme}`;
	}

	/**
	 * Get the related btn-class for the theme for the appended button.
	 * Only if the user has set a theme then this getter will return the related btn-class.
	 */
	protected get btnAppendThemeClass() : string {
		if (this._theme === null) return 'btn-secondary';
		return `btn-${this._theme}`;
	}
}
