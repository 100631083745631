import { PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunicationAction } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PossibleActionIcons = keyof Pick<typeof enumsObject.PlanoFaIconPool, 'fa-pen fa-duotone' | 'fa-xmark fa-solid' | 'fa-check fa-duotone' | 'fa-right-left fa-duotone' | 'fa-right-long fa-duotone' >;

class ActionUiObject {
	constructor(
		text : PDictionarySourceString | null,
		icon ?: PossibleActionIcons,
	) {
		this.text = text;
		this.icon = icon;
		this.style = this.getIconStyle(icon);
	}

	public text : PDictionarySourceString | null = null;
	public icon : PossibleActionIcons | undefined;
	public style : ExtractFromUnion<'danger' | 'success', PThemeEnum> | null;

	private getIconStyle(icon : PossibleActionIcons | undefined) : ActionUiObject['style'] | null {
		if (!icon) return null;
		switch (icon) {
			case 'fa-xmark fa-solid' :
				return enumsObject.PThemeEnum.DANGER;
			case 'fa-check fa-duotone' :
				return enumsObject.PThemeEnum.SUCCESS;
			case 'fa-right-left fa-duotone' :
				return enumsObject.PThemeEnum.SUCCESS;
			case 'fa-right-long fa-duotone' :
				return enumsObject.PThemeEnum.SUCCESS;
			case 'fa-pen fa-duotone':
				return null;
		}
	}
}

type ArrayOfStateTextsType = {
	enum : SchedulingApiShiftExchangeCommunicationAction;
	text : PDictionarySourceString | null;
	icon ?: PossibleActionIcons;
}[];

/**
 * Class responsible for holding the information on each communication action on a shift exchange,
 * it holds the corresponding text and possibly icon to be shown in the UI given a certain action.
 */
export class PActionData {
	constructor() {
		this.initData();
	}

	private data : {
		[index in SchedulingApiShiftExchangeCommunicationAction] ?: ActionUiObject
	} = {};

	private initData() : void {
		this.initStateDataValues();
		if (Config.DEBUG) this.checkMissingData();
	}

	private addUiObject(
		action : SchedulingApiShiftExchangeCommunicationAction,
		text : PDictionarySourceString | null,
		icon ?: PossibleActionIcons,
	) : void {
		const UI_OBJECT = new ActionUiObject(text, icon);
		this.data[action] = UI_OBJECT;
	}

	public arrayOfStateTexts : ArrayOfStateTextsType = [
		{ enum: SchedulingApiShiftExchangeCommunicationAction.A_REPORTED_ILLNESS, text: null },
		{ enum: SchedulingApiShiftExchangeCommunicationAction.IM_REPORTED_ILLNESS, text: null },
		{ enum: SchedulingApiShiftExchangeCommunicationAction.CP_ASSIGNED_SAME_TIME, text: null },
		{ enum: SchedulingApiShiftExchangeCommunicationAction.CP_ASSIGNED_SAME_SHIFT, text: null },
		{ enum: SchedulingApiShiftExchangeCommunicationAction.CP_IS_ABSENT, text: null },
		{ enum: SchedulingApiShiftExchangeCommunicationAction.CP_IS_ILL, text: null },
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_NEEDS_RESPONSE,
			text: '${INDISPOSED_MEMBER_FIRST_NAME} ${C_WAITS} auf Antwort',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_IM_CHANGE_SWAPPED_SHIFT,
			text: 'Mein Tauschangebot ändern',
			icon: 'fa-pen fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_IM_DECLINE_SWAP,
			text: 'Doch nicht tauschen',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_ACCEPT,
			text: 'Tauschangebot annehmen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_CANNOT,
			text: 'Kann doch nicht',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MATCH,
			text: 'Mein Tauschangebot ändern',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MISMATCH,
			text: 'Mein Tauschangebot ändern',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_IM_DECLINE_TAKE,
			text: 'Mein Tauschangebot ändern',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_CP_ACCEPT,
			text: '${IM_OFFERED_SHIFTS} abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_CP_CANNOT,
			text: 'Kann doch nicht',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_CHANGED_MIND_WANTS_TAKE_CP_SWAP_SHIFT,
			text: 'Schichten doch tauschen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_SWAP_IM_SWAP_SHIFT,
			text: 'Schichten doch tauschen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_SWAP_CP_CHANGE_OFFERED_SHIFTS,
			text: 'Angebot an ${INDISPOSED_MEMBER_FIRST_NAME} ändern',
			icon: 'fa-pen fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_SWAP_CP_TAKE_SHIFT_PREF_MATCH,
			text: '${IM_OFFERED_SHIFTS} doch abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_SWAP_CP_TAKE_SHIFT_PREF_MISMATCH,
			text: '${IM_OFFERED_SHIFTS} doch abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_TAKE_IM_TAKE_SHIFT,
			text: '${IM_OFFERED_SHIFTS} doch abgeben',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.IM_DECLINED_TAKE_CP_SWAP_SHIFT,
			text: 'Schichten doch tauschen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_NOT_RESPONDED_CP_CANNOT,
			text: 'Kann nicht',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_NOT_RESPONDED_CP_SWAP_SHIFT,
			text: 'Schichten tauschen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_NOT_RESPONDED_CP_TAKE_SHIFT_PREF_MATCH,
			text: '${IM_OFFERED_SHIFTS} abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_NOT_RESPONDED_CP_TAKE_SHIFT_PREF_MISMATCH,
			text: '${IM_OFFERED_SHIFTS} abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_RESPONDED_NO_CP_SWAP_SHIFT,
			text: 'Schichten doch tauschen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_RESPONDED_NO_CP_TAKE_SHIFT_PREF_MATCH,
			text: '${IM_OFFERED_SHIFTS} doch abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_RESPONDED_NO_CP_TAKE_SHIFT_PREF_MISMATCH,
			text: '${IM_OFFERED_SHIFTS} doch abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_SWAP_IM_ACCEPT,
			text: 'Tauschangebot ansehen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_SWAP_IM_DECLINE_SWAP,
			text: 'Will nicht tauschen',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_SWAP_CP_CANNOT,
			text: 'Kann doch nicht',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_SWAP_CP_CHANGE_OFFERED_SHIFTS,
			text: 'Angebot an ${INDISPOSED_MEMBER_FIRST_NAME} ändern',
			icon: 'fa-pen fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MATCH,
			text: '${IM_OFFERED_SHIFTS} doch abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.ILLNESS_NEEDS_CONFIRMATION_A_DECLINED,
			text: 'Ablehnen',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITH_SHIFT_EXCHANGE,
			text: 'Akzeptieren & Ersatz suchen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.ILLNESS_CONFIRMED_WITHOUT_SHIFT_EXCHANGE_A_START_SHIFT_EXCHANGE,
			text: 'Doch Ersatz suchen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITHOUT_SHIFT_EXCHANGE,
			text: 'Akzeptieren',
			icon: 'fa-check fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.ILLNESS_DECLINED_A_ACCEPT_WITH_SHIFT_EXCHANGE,
			text: 'Doch akzeptieren & Ersatz suchen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.ILLNESS_DECLINED_A_ACCEPT_WITHOUT_SHIFT_EXCHANGE,
			text: 'Doch akzeptieren',
			icon: 'fa-check fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MISMATCH,
			text: '${IM_OFFERED_SHIFTS} doch abnehmen',
			icon: 'fa-right-long fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_TAKE_CP_SWAP_SHIFT,
			text: 'Schichten doch tauschen',
			icon: 'fa-right-left fa-duotone',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_TAKE_CP_CANNOT,
			text: 'kann doch nicht',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_TAKE_IM_DECLINE,
			text: '${IM_OFFERED_SHIFTS} nicht abgeben',
			icon: 'fa-xmark fa-solid',
		},
		{
			enum: SchedulingApiShiftExchangeCommunicationAction.CP_WANTS_TAKE_IM_ACCEPT,
			text: '${IM_OFFERED_SHIFTS} abgeben',
			icon: 'fa-right-long fa-duotone',
		},
	];

	private initStateDataValues() : void {
		for (const stateTextObject of this.arrayOfStateTexts) {
			this.addUiObject(
				stateTextObject.enum,
				stateTextObject.text,
				stateTextObject.icon,
			);
		}
	}

	private forEachEnumValue(input : typeof SchedulingApiShiftExchangeCommunicationAction, success : (enumValue : number) => void) : void {
		for (const stateKey of Object.keys(input)) {
			if (Number.isNaN(+stateKey)) continue;
			success(+stateKey);
		}
	}

	private checkMissingData() : void {

		/** shorthands */
		const exchangeAction = SchedulingApiShiftExchangeCommunicationAction;

		this.forEachEnumValue(exchangeAction, (exchangeActionValue) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if ((this.data as any)[exchangeActionValue] !== undefined) return;
			throw new Error(`TODO: PActionText missing for [${exchangeAction[exchangeActionValue]}]`);
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getText(
		action : SchedulingApiShiftExchangeCommunicationAction,
		_shiftExchange : SchedulingApiShiftExchange,
	) : PDictionarySourceString | null {
		const actionData = this.data[action] ?? null;

		if (actionData) return actionData.text;

		if (Config.DEBUG) {
			throw new Error(`PActionText could not be found for [${SchedulingApiShiftExchangeCommunicationAction[action]}]`);
		}
		return 'Fehler aufgetreten …';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getIcon(action : SchedulingApiShiftExchangeCommunicationAction) : PossibleActionIcons | undefined {
		const result = this.data[action];
		if (result) return result.icon;
		return undefined;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getIconStyle(action : SchedulingApiShiftExchangeCommunicationAction) : ActionUiObject['style'] | null {
		const result = this.data[action];
		if (result) return result.style;
		return null;
	}
}
