/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { HttpParams } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY } from '@plano/client/scheduling/scheduling-api-based-pages.service.indexeddb.type';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { DataInput } from '@plano/shared/core/data/data-input';
import { PIndexedDBService, PServiceWithIndexedDBInterface } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { PUrlParamsServiceInterface } from '@plano/shared/core/interfaces/p-service.interface';
import { LogService } from '@plano/shared/core/log.service';
import { turnDateIntoUrlParamValue } from '@plano/shared/core/router.utils';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { Subject } from 'rxjs';
import { CalendarModes } from './calendar-modes';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type DetailObjectType = 'shift' | 'shiftModel' | 'member';

class UrlParam extends DataInput implements PServiceWithIndexedDBInterface {
	constructor(
		protected override readonly zone : NgZone,
		protected readonly pIndexedDBService : PIndexedDBService,
		private console : LogService,
		private router : Router,

		private locale : PSupportedLocaleIds,
	) {
		super(zone);
	}

	private _calendarMode : CalendarModes | null = null;

	/**
	 * From angular docs: {@link https://github.com/atscott/angular/blob/ee3700fa0703ebbe94b1c26a50a266bb557af670/packages/router/src/router_state.ts#L306}
	 */
	private collectRouteParams() : Record<string, string> {
		let params : Record<string, string> = {};

		const stack : ActivatedRouteSnapshot[] = [
			this.router.routerState.snapshot.root,
		];

		while (stack.length > 0) {
			const route = stack.pop();
			if (route === undefined) {
				continue;
			}

			params = { ...params, ...route.params };

			stack.push(...route.children);
		}

		return params;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get calendarMode() : CalendarModes {
		if (this._calendarMode === null) {
			this._calendarMode = this.readCalendarModeIndexedDBEntry();
			if (this._calendarMode === null) {
				const calendarModeInRoute = this.collectRouteParams()['calendarMode'] as (CalendarModes | undefined);
				// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				if (calendarModeInRoute)
					this.calendarMode = calendarModeInRoute;
				else this.calendarMode = CalendarModes.DAY;
			}
		}
		return this._calendarMode!;
	}

	public set calendarMode(value : CalendarModes | null) {
		if (value === null)
			this.pIndexedDBService.delete(SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY);
		else this.pIndexedDBService.set(SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY, value);
		this._calendarMode = value;
		this.changed(undefined);
	}

	private _date : number | null = null;
	public set date(date : number | null) {
		const pMoment = new PMomentService(this.locale, this.console);
		if (date !== null && Number.isNaN(date)) {
			date = null;
		}
		const newValue = +pMoment.m(date ?? undefined).startOf('day');

		// ensure this.urlParam.date is start of day
		this._date = newValue;
		this.changed(undefined);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get date() : number | null {
		return this._date;
	}

	public detailObject : DetailObjectType | null = null;
	public detailObjectId : number | null = null;

	private readCalendarModeIndexedDBEntry() : CalendarModes | null {
		// get current calendar mode
		let returnCalendarMode : CalendarModes | null = null;
		if (this.pIndexedDBService.has(SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY)) {
			const calendarModeInRoute : CalendarModes | undefined = this.collectRouteParams()['calendarMode'] as (CalendarModes | undefined);
			const calendarModeInIndexedDB = this.pIndexedDBService.get(SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY) as CalendarModes;
			// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (calendarModeInRoute && calendarModeInIndexedDB !== calendarModeInRoute ) {
				returnCalendarMode = calendarModeInRoute;
			} else returnCalendarMode = this.pIndexedDBService.get(SCHEDULING_API_BASED_PAGES_INDEXED_DB_CALENDAR_MODE_KEY) as CalendarModes;
		}
		return returnCalendarMode;
	}

	/**
	 * Read values from indexedDB if available
	 */
	public readIndexedDB() : void {
		const calendarModeInIndexedDB = this.readCalendarModeIndexedDBEntry();
		if (calendarModeInIndexedDB !== null) {
			this.calendarMode = calendarModeInIndexedDB;
		}
	}

	/**
	 * Set some default values for properties that are not defined yet
	 */
	public initValues() : void {
		const pMoment = new PMomentService(this.locale, this.console);
		this.date = pMoment.m().startOf('D').valueOf();
	}

	/**
	 * Takes params from this.route.snapshot.params and writes them to the related property of service.urlParam
	 * @param params The params that should be stored to the related properties
	 */
	public writeUrlParamsToService(params : Params | null) : void {
		if (!params || JSON.stringify(params) === JSON.stringify({})) {
			// eslint-disable-next-line ban/ban -- intended navigation
			void this.router.navigate([`client/scheduling/${this.calendarMode}/${turnDateIntoUrlParamValue(this.date)}`]);
			return;
		}
		if (!params['date']) this.console.error('no date available');

		// Ignore params when being redirected to default route
		if (params['date'] !== turnDateIntoUrlParamValue(null)) {
			if (params['calendarMode']) {
				this.calendarMode = params['calendarMode'];
			}
			if (params['date'] && params['date'] !== this.date) {
				this.date = +params['date'];
			}
		}

		if (params['detailObject'] !== this.detailObject) {
			if (params['detailObject']) {
				this.detailObject = params['detailObject'];
			} else {
				this.detailObject = null;
			}
			this.changed(undefined);
		}

		this.updateDetailObjectId(params);
	}

	private updateDetailObjectId(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : {[key : string] : any},
	) : void {
		if (params['detailObjectId'] === this.detailObjectId) return;

		if (params['detailObjectId']) {
			this.detailObjectId = Number.parseInt(params['detailObjectId'], 10);
		} else {
			this.detailObjectId = null;
		}
	}

	/** @see PServiceInterface#unload */
	public unload() : void {
		this._calendarMode = null;
		this._date = null;
		this.detailObject = null;
		this.detailObjectId = null;
	}

}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PossibleApiLoadDataValues = 'calendar' | 'shift-exchange' | 'reporting' | 'notifications' | 'bookings' | 'giftCards' | 'transactions' | 'bookingSystemSettings';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export abstract class AbstractSchedulingApiBasedPagesService extends DataInput
	implements PServiceWithIndexedDBInterface, PUrlParamsServiceInterface {
	constructor(
		protected readonly dataParamInput : PossibleApiLoadDataValues,
		protected override readonly zone : NgZone,
		protected readonly pIndexedDBService : PIndexedDBService,
		protected readonly console : LogService,
		protected readonly router : Router,
		protected readonly locale : PSupportedLocaleIds,
	) {
		super(zone);
		this.urlParam = new UrlParam(zone, pIndexedDBService, console, router, locale);
		this.dataParam = dataParamInput;
		this.urlParam.onChange.subscribe(() => {
			this.changed(undefined);
		});
	}

	public queryParams : HttpParams | null = null;
	public dataParam : PossibleApiLoadDataValues | null = null;

	/**
	 * afterNavigationCallbacks can store callbacks that can be executed later when the api is loaded.
	 * @deprecated See https://drplano.atlassian.net/browse/PLANO-188339
	 * TODO: PLANO-188339 remove afterNavigationCallbacks
	 */
	public afterNavigationCallbacks : (() => void)[] = [];

	public urlParam : UrlParam;

	public schedulingApiHasBeenLoadedOnSchedulingComponent : Subject<void> = new Subject<void>();

	/**
	 * Read values from indexedDB if available
	 */
	public readIndexedDB() : void {
		this.urlParam.readIndexedDB();
	}

	/**
	 * Init all necessary values for this class
	 */
	public initValues() : void {
		this.urlParam.initValues();
	}

	/**
	 * Start timestamp of current calendarMode
	 * @examples
	 * If calenderMode is 'week' then shiftsStart is timestamp of 'start of week'
	 * If calenderMode is 'month' then shiftsStart is timestamp of 'start of first week of month'
	 */
	public get shiftsStart() : number {
		const pMoment = new PMomentService(this.locale, this.console);
		const dateAsMoment = pMoment.m(this.urlParam.date ?? undefined);
		const firstDay = dateAsMoment.startOf(this.urlParam.calendarMode);
		return +firstDay;
	}

	/**
	 * End timestamp of current calendarMode
	 * @example
	 * If .calenderMode is 'month' and .date is 21.07. then shiftsEnd is timestamp of last millisecond of 31.07.
	 */
	public get shiftsEnd() : number {
		const pMoment = new PMomentService(this.locale, this.console);
		const dateAsMoment = pMoment.m(this.urlParam.date ?? undefined);
		const firstDay = dateAsMoment.endOf(this.urlParam.calendarMode);
		return +firstDay;
	}

	/**
	 * update queryParam values based on urlParam, bookingsService etc.
	 */
	public updateQueryParams(_skipBookings ?: boolean) : void {
		// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (Config.DEBUG && this.urlParam.date === 0) console.error(`set ${this.urlParam.date} first`);

		assumeNonNull(this.dataParam, 'this.dataParam');
		this.queryParams = new HttpParams()
			.set('data', this.dataParam)
			.set('start', (this.shiftsStart).toString())
			.set('end', (this.shiftsEnd).toString());

		// if (!skipBookings) this.updateBookingRelatedQueryParams();
	}

	/** @see UrlParam#writeUrlParamsToService */
	public writeUrlParamsToService(params : Parameters<UrlParam['writeUrlParamsToService']>[0]) : void {
		this.urlParam.writeUrlParamsToService(params);
	}

	/** @see PServiceInterface#unload */
	public unload() : void {
		this.urlParam.unload();
	}
}
