/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { Component, Input } from '@angular/core';
import { PPermissionsService } from '@plano/client/permissions/permissions.service';
import { LogService } from '@plano/shared/core/log.service';
import { MeService } from '@plano/shared/core/me/me.service';

@Component({
	selector: 'p-external-link',
	templateUrl: './p-external-link.component.html',
	styleUrls: ['./p-external-link.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PExternalLinkComponent {

	/**
	 * The link to be used in the external link, this will override the owner_link and
	 * employee_link if passed.
	 */
	@Input() public link : string | null = null;

	/**
	 * Link to be used when the logged in user is an owner
	 */
	@Input('link_for_admins_and_managers') public linkForAdminsAndManagers : string | null = null;

	/**
	 * Link to be used when the logged in user is an employee
	 */
	@Input('link_for_employees') public linkForEmployees : string | null = null;

	constructor(
		protected meService : MeService,
		private console : LogService,
		private pPermissionsService : PPermissionsService,
	) {

	}

	/**
	 * Get the external link to be used in this component
	 */
	public get externalLink() : string | null {
		if (this.link) return this.link;
		if (this.linkForAdminsAndManagers && (this.meService.data.isOwner || this.pPermissionsService.hasManagerPermission)) return this.linkForAdminsAndManagers;
		if (this.linkForEmployees && !(this.meService.data.isOwner || this.pPermissionsService.hasManagerPermission)) return this.linkForEmployees;
		this.console.error('No matching link was found for external link!');
		return null;
	}
}
