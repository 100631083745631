import { AffectedShiftsApiShiftBase, AffectedShiftsApiShiftsBase, SchedulingApiShift } from '@plano/shared/api';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AffectedShiftsApiShift extends AffectedShiftsApiShiftBase {

	/**
	 * Calculate how many members can be assigned till this shift is saturated
	 */
	public get emptyMemberSlots() : SchedulingApiShift['emptyMemberSlots'] {
		if (this.aiAssignedMemberIds.isAvailable !== true) return 0;

		let result : number;
		const amountOfEmptyBadges = this.neededMembersCount - this.assignedMemberIds.length;
		if (amountOfEmptyBadges >= 0) {
			result = amountOfEmptyBadges;
		} else {
			result = 0;
		}
		return result;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AffectedShiftsApiShifts extends AffectedShiftsApiShiftsBase {
}
