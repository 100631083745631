/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { assume } from '@plano/shared/core/utils/null-type-utils';
import { NegativeInteger } from '@plano/shared/core/utils/typescript-utils-types';
import { ShiftSelector } from './shift-selector';

/**
 * This class is id of a single shift.
 */
export class ShiftId extends ShiftSelector {
	/**
	 * A shift-selector cannot be instantiated publicly as it always comes from backend.
	 */
	private constructor(value : any[] | number) {
		super(value);
	}

	/**
	 * @param value The raw-data of the shift-id or a negative new-item-id.
	 */
	public static override create<T extends number>(value : any[] | NegativeInteger<T>) : ShiftId {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		assume(!!value, '!!value', 'PRODUCTION-505');
		return new ShiftId(value);
	}

	public override get seriesId() : number {
		return super.seriesId!;
	}

	public override get packetIndex() : number {
		return super.packetIndex!;
	}

	public override get shiftIndex() : number {
		return super.shiftIndex!;
	}

	public override get start() : number {
		return super.start!;
	}

	public override get end() : number {
		return super.end!;
	}

	/**
	 * @returns The raw-data of this shift-id as a json string.
	 */
	public toJsonString() : string {
		return JSON.stringify(this.rawData);
	}

	/**
	 * @param jsonString Api raw-data of the shift-id (Value returned by `toJsonString()`).
	 * @returns Parses `data` and creates a `ShiftId` object from it.
	 */
	public static parseJsonString(jsonString : string) : ShiftId {
		const dataObj = JSON.parse(jsonString);
		return new ShiftId(dataObj);
	}

	/**
	 * @returns A string suited for urls only with characters which are not changed by url encoding.
	 * This is done because we had problems with urls (from bookmarks?) which were url encoded to many times.
	 * To prevent this problem, the urls should consist of only characters which are not changed
	 * by url encoding.
	 * Returns null if shift is a new item.
	 */
	public toUrl() : string | null {
		if (typeof this.data === 'number')
			return null;

		const d = this.data;
		return `${d[1]},${d[2]},${d[3]},${d[4]},${d[5]},${d[6]},${d[7]},${d[8]}`;
	}

	/**
	 * @returns Parses a shift-id string which was generated by the "toUrl()" method.
	 */
	public static fromUrl(value : string) : ShiftId {

		const values = value.split(',');

		const data = [];
		data[0] = [null, false, true, null];
		data[1] = Number.parseInt(values[0], 10);
		data[2] = Number.parseInt(values[1], 10);
		data[3] = Number.parseInt(values[2], 10);
		data[4] = Number.parseInt(values[3], 10);
		data[5] = Number.parseInt(values[4], 10);
		data[6] = Number.parseInt(values[5], 10);
		data[7] = Number.parseInt(values[6], 10);
		data[8] = values[7] === 'null' ? null : values[7];

		return new ShiftId(data);
	}

	/**
	 * This makes pretty Url-compatible Urls like 1179-0-0-0-30-1525125600000-1525212000000
	 */
	public toPrettyString() : string {
		if (typeof this.data === 'number')
			throw new Error(`You cannot access this for a new-item-id. Data: ${this.data}`);

		let result = '';
		for (let i = 0; i < Math.min(6,this.data.length); i++) {
			if (i !== 0) {
				if (result !== '') {
					// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					result += '-';
				}
				result += this.data[i];
			}
		}
		return result;
	}

	/**
	 * @param data String created by `toPrettyString()`.
	 * @returns Parses the string created by `toPrettyString()` and creates `ShiftId` from it.
	 */
	public static parsePrettyString(data : string) : ShiftId {
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const dataObj = JSON.parse(`[${ data.split('-').slice(0,6).toString() }]`);
		return new ShiftId(dataObj);
	}
}
