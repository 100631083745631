/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint-disable no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description', Solve the remaining cases please. */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import packageJson from '@plano/../package.json';
import { PSentryScopeContext, PSeverity, errorMatchesRegEx, sentryReportIgnoreList } from '@plano/global-error-handler/error-utils';
import { Config } from '@plano/shared/core/config';
import { MeService } from '@plano/shared/core/me/me.service';
import { errorTypeUtils } from '@plano/shared/core/utils/error-type-utils';
import { AngularWrappedError } from '@plano/shared/core/utils/error-utils-types';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import * as Sentry from '@sentry/browser';
import { Integration, Primitive, Transaction } from '@sentry/types';

type PTransactionId = `performance-of-${string}`;
type SentryDeviceDataType = {
	brand ?: string,
	family : string,
	model ?: string,
};
type SentryBrowserDataType = {
	name : string,
	version ?: string,
};

const transactions : {
	id : PTransactionId,
	transaction : Transaction,
}[] = [];

type TextReplaceReturnObject = {
	text : string,
	replacedItems : {[key : string] : string}[],
};

/**
 * Everything we do with Sentry should go here.
 */
@Injectable({ providedIn: 'root' })
export class PSentryService {
	/**
	 * Should this error be dropped instead of sent to Sentry?
	 * @param error The error to check
	 * @param userMessage A message that the user has written
	 */
	public shouldDropError(error : Error, userMessage : string | null = null) : boolean {
		if (userMessage !== null) return false;

		// We don’t care about errors from headless browsers
		const isHeadless = Config.browser.name?.toLowerCase().includes('headless');
		if (isHeadless) {
			// We want to be sure that this can not cause another throw which would probably lead to a loop.
			// So we pack it in a try-catch and only log if there is an error.
			try {
				const isPrerenderIO = window.navigator.userAgent.toLowerCase().includes('prerender');

				// All headless browsers should be ignored except for prerender.io
				return !isPrerenderIO;
			} catch (tryCatchError) {
				console.error(tryCatchError);
			}
			return true;
		}

		if (sentryReportIgnoreList.length === 0) return false;
		return sentryReportIgnoreList.some(regex => {
			return errorMatchesRegEx(error, regex);
		});
	}

	/**
	 * Turn something like
	 * `/de/client/shift/12345,0,0,0,61,1654034400000,1654120800000,2344/start-date/1234567890123/`
	 * into
	 * `/de/client/shift/<shift-id>/start-date/<timestamp>`
	 *
	 * and
	 *
	 * `Error: Could not find item »205434191«`
	 * into
	 * `Error: Could not find item »<number_0>«`
	 * @param text The string to remove the variables from
	 */
	// NOTE: Has to be public to be able to test it in the spec file
	public removeVarsFromText(text : string) : TextReplaceReturnObject {
		const result : TextReplaceReturnObject = { text: text, replacedItems: [] };

		/*
		TODO: PLANO-170403
		We need the notEndingWith here to avoid using negative look ahead, which are not available in safari.
		*/
		const processItems = (title : string, regex : RegExp, notEndingWith : string | null = null) : void => {
			let items : string [] = result.text.match(regex) ?? [];
			if (notEndingWith && items.length > 0) {
				items = items.filter(item => !item.endsWith(notEndingWith));
			}
			for (const item of items) {
				// Skip these replacements because they are most likely part of our localhost client url.
				const entryAlreadyExists = result.replacedItems.filter(findItem => {
					return !!Object.keys(findItem)[0].includes(title);
				});
				const key = entryAlreadyExists.length === 0 ? `${title}_0` : `${title}_${entryAlreadyExists.length.toString()}`;
				result.replacedItems.push({[key]: item});
				result.text = result.text.replace(item, `<${key}>`);
			}
		};

		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		processItems('shiftId', /[1-9]\d+(?:,[\w-]*)+/g);
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		processItems('timestamp', /\d{13,14}/g);
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		processItems('hash', /[\da-f]{32}|[\da-f]{16}/g);
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		processItems('number', /[1-9]\d*>?/g, '>');

		// Remove trailing slash from url
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		result.text = result.text.replaceAll(/(<?\w+>?)\/$/g, '$1');
		return result;
	}

	private get sentryProjectUrl() : string {
		switch (this.environment) {
			case 'staging':
				return 'https://88b2d3bd836f458b991c9c36f77bd2ec@o372563.ingest.sentry.io/4504802194489344';
			case 'production':
				return 'https://7bc16c77d5784796a2345f4e2f443fff@sentry.io/5187494';
			case 'development':
				return 'https://5f563ada6f7c468780086759f1338dec@o372563.ingest.sentry.io/5249158';
		}
	}

	/** @see https://docs.sentry.io/product/sentry-basics/concepts/environments/ */
	private get environment() : 'production' | 'staging' | 'development' {
		if (window.location.href.includes('staging.dr-plano.com')) {
			return 'staging';
		} else if (window.location.href.includes('dr-plano.com')) {
			return 'production';
		} else {
			return 'development';
		}
	}

	private get tracesSampleRate() : number {
		switch (this.environment) {
			case 'staging':
				return 1.0;
			case 'production':
				return 0.45;
			case 'development':
				return 0.001;
		}
	}

	/**
	 * Init Sentry and set everything up that wont change till Sentry calls go out
	 */
	public init() : void {
		const integrations : Integration[] = [];
		integrations.push(
			Sentry.breadcrumbsIntegration({
				console: true, // Log calls to `console.log`, `console.debug`, etc
				dom: true, // Log all click and keypress events
				fetch: true, // Log HTTP requests done with the Fetch API
				history: true, // Log calls to `history.pushState` and friends
				sentry: true, // Log whenever we send an event to the server
				xhr: true, // Log HTTP requests done with the XHR API
			}),
		);
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		integrations.push(new Sentry.Integrations.HttpContext());
		if (Config.DEBUG && Config.APPLICATION_MODE === 'DEV') {
			// For now this is a test run, so we only enable it on dev
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			integrations.push(new Sentry.Replay());
		}
		integrations.push(Sentry.browserTracingIntegration({enableInp: true, beforeStartSpan: (span) => {
			return {
				...span,
				name: this.removeVarsFromText(span.name).text,
			};
		}, idleTimeout: 3000}));
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		integrations.push(new Sentry.Integrations.InboundFilters());

		Sentry.init({
			dsn: this.sentryProjectUrl,
			tunnel: '/guard/',
			environment: this.environment,
			attachStacktrace: true,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			release: (packageJson as any)?.version,
			denyUrls: [
				// Storybook
				// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				/http:\/\/(localhost|127\.0\.0\.1):6006\/.*/,
				// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				/http:\/\/(localhost|127\.0\.0\.1):6007\/.*/,

				// Tests
				// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				/http:\/\/(localhost|127\.0\.0\.1):9876\/.*/,

				// Admin-Area
				// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				/http:\/\/(localhost|127\.0\.0\.1):\d{4}\/\w*\/admin\/.*/,
			],
			ignoreErrors: [], // Leave this empty. Use this.ignoreErrors instead
			defaultIntegrations: false,
			integrations: integrations,
			tracesSampleRate: this.tracesSampleRate,
		});

		void this.setDetailsThatWontChangeDuringSession(Config.platform);
	}

	/**
	 * Start transaction
	 */
	public startTransaction(input : PTransactionId) : void {
		transactions.push({
			id: input,
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			transaction: Sentry.startTransaction({ name: 'test-transaction' }),
		});
	}

	/**
	 * Stop transaction
	 */
	public stopTransaction(input : PTransactionId) : void {
		const transactionObject = transactions.find(item => item.id === input) ?? null;
		assumeNonNull(transactionObject, 'transaction', `Forgot to start Transaction »${input}« first?`);
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		transactionObject.transaction.finish(); // Finishing the transaction will send it to Sentry
	}

	/**
	 * Send a user feedback to sentry. It will be added to the previously sent event/error.
	 *
	 * @param message The message to send to Sentry
	 * @param errorObj The error object to send to Sentry
	 * @returns If flush was successful
	 */
	public async captureUserFeedback(
		message : string,
		errorObj : Error | null,
	) : Promise<boolean> {
		this.setSentryTags();

		const eventId = this.lastEventId()!;
		Sentry.captureUserFeedback({
			// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			event_id: eventId,
			comments: message,
			name: errorObj?.name ?? message,
			email: '',
		});

		// Currently the user feedback api does not seem to be reliable.
		// So we send the message again as a normal message.
		// TODO: PLANO-179600 Remove this when it got obsolete.
		await this.captureMessage(message, {
			level: PSeverity.FATAL,
			extra: { error: errorObj },
		});

		console.debug(`Sentry eventId: ${eventId}`);
		return await this.flush();
	}

	/**
	 * Send any message to sentry.
	 * If possible, please use LogService.error(…) instead.
	 *
	 * @param message The message to send to Sentry
	 * @param context The context to send to Sentry, it must include the error
	 * @returns If flush was successful
	 */
	public async captureMessage(
		message : string,
		context : PSentryScopeContext,
	) : Promise<boolean> {
		this.setSentryTags();

		const eventId = Sentry.captureMessage(message, {
			...context,
			extra: {
				...context.extra,
				lastClickedElements: this.lastClickedElements,
			},
		});

		console.debug(`Sentry eventId: ${eventId}`);
		return await this.flush();
	}

	/**
	 * Handle an error.
	 * You can pass an error to it, and it will decide if it should be dropped or sent, and if sent,
	 * with which additional information.
	 *
	 * @param error The error to send to Sentry
	 * @param me A MeService instance
	 * @param userMessage A message to send to Sentry
	 * @param scopeContext The context to send to Sentry
	 * @returns A promise which resolves to `true` if the queue successfully drains before the timeout, or `false` if it
	 * doesn't (or if there's no client defined). See {@link flush}
	 */
	public async handleError(
		error : Error | HttpErrorResponse | AngularWrappedError | string,
		me : MeService | null,
		userMessage : string | null = null,
		scopeContext : PSentryScopeContext | null = null,
	) : Promise<ReturnType<typeof Sentry.captureException> | ReturnType<PSentryService['captureUserFeedback']> | null> {
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (userMessage !== null) userMessage = userMessage.replaceAll(/\r\n|\n|\r/g, ' ❦ ');

		this.setSentryTags();

		this.setSentryScopeForException(error, me, userMessage);
		const EXTRACTED_ERROR = this.extractError(error);

		// set defaults for scopeContext
		scopeContext = {
			...scopeContext,
			level : scopeContext?.level ?? PSeverity.FATAL,
			extra : {
				...(scopeContext?.extra),
				error: EXTRACTED_ERROR,
				lastClickedElements: this.lastClickedElements,
			},
			fingerprint: this.fingerprint(userMessage, EXTRACTED_ERROR),
		};

		if (this.shouldDropError(EXTRACTED_ERROR, userMessage)) return null;

		if (userMessage) {
			return this.captureUserFeedback(userMessage, EXTRACTED_ERROR);
		}

		Sentry.captureException(EXTRACTED_ERROR, scopeContext);
		return await this.flush();
	}

	private fingerprint(
		userMessage : string | null = null,
		error : Error | HttpErrorResponse,
	) : string[] {
		if (userMessage) return [userMessage];

		let result : string | null = null;

		if (errorTypeUtils.isTypeHttpErrorResponse(error)) {
			// If this is a HTTP Error we don't want to change the error.message
			result = error.message;
		} else {
			// If this is not a HTTP Error it might be a custom error, which contains
			// timestamps, ids, etc. We want to remove them from the fingerprint.
			result = this.removeVarsFromText(error.message).text;
		}

		return [result];
	}

	/**
	 * Call `flush()` on the current client, if there is one. See {@link Client.flush}.
	 *
	 * @see flush
	 * @param timeout Maximum time in ms the client should wait to flush its event queue. Omitting this parameter will cause
	 * the client to wait until all events are sent before resolving the promise.
	 */
	public async flush(timeout : number = 2500) : Promise<boolean> {
		return await Sentry.flush(timeout);
	}

	/** @see Sentry#lastEventId */
	public lastEventId() : string | undefined {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return Sentry.lastEventId();
	}

	/**
	 * Set user.
	 * Run this after user has logged in.
	 * Run this with null input when user logs out
	 */
	public setUserAndAccountScope(input : MeService | null) : void {
		if (input === null) {
			Sentry.setUser(null);
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			Sentry.configureScope((scope) => {
				scope.setTag('locationName', null);
				scope.setTag('isPaidClient', null);
				scope.setTag('isOwner', null);
			});
			return;
		}
		Sentry.setUser({
			id: input.data.id.toString(),
			username: `${input.data.firstName} ${input.data.lastName}`,
			email: input.data.email,
			companyName: input.data.aiCompanyName.isAvailable ? input.data.companyName : null,
		});

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		Sentry.configureScope((scope) => {
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (input.isLoaded()) {
				scope.setTag('locationName', input.data.aiLocationName.isAvailable ? input.data.locationName : null);
				scope.setTag('isPaidClient', (!input.isTestAccount).toString());
				scope.setTag('isOwner', input.data.isOwner.toString());
			}
		});
	}

	private async setDetailsThatWontChangeDuringSession(platform : typeof Config['platform']) : Promise<void> {
		switch (platform) {
			case 'appIOS': {
				const device : SentryDeviceDataType = {
					brand: 'Apple',
					family: 'iPhone',
					model: 'Unknown (Nativescript)',
				};
				await this.addToContext('device', device);

				const browser : SentryBrowserDataType = {
					name: 'Nativescript WebView',
				};
				await this.addToContext('browser', browser);

				type SentryOSDataType = {
					name : string,
				};
				const os : SentryOSDataType = {
					name : 'iOS',
				};
				await this.addToContext('os', os);
				break;
			}

			case 'appAndroid': {
				const device : SentryDeviceDataType = {
					brand: 'Unknown (Nativescript)',
					family: 'Android',
					model: 'Unknown (Nativescript)',
				};
				await this.addToContext('device', device);

				const browser : SentryBrowserDataType = {
					name: 'Nativescript WebView',
				};
				await this.addToContext('browser', browser);

				type SentryOSDataType = {
					name : string,
				};
				const os : SentryOSDataType = {
					name : 'Android',
				};
				await this.addToContext('os', os);
				break;
			}

			default:
				break;
		}

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		Sentry.configureScope(scope => {
			scope.setTag('Config.DEBUG', Config.DEBUG ? Config.DEBUG.toString() : undefined);
		});
	}

	private setSentryTags() : void {
		const urlWithReplacements = this.removeVarsFromText(location.pathname);
		this.setTag('url', urlWithReplacements.text);
		for (const item of urlWithReplacements.replacedItems) {
			this.setTag(Object.keys(item)[0], Object.values(item)[0]);
		}
		this.setTag('config_locale', Config.LOCALE_ID);
	}

	/**
	 * Set sentry scope for an exception
	 */
	private setSentryScopeForException(
		error : Parameters<PSentryService['extractError']>[0],
		me : MeService | null,
		userMessage : string | null = null,
	) : void {
		const EXTRACTED_ERROR = this.extractError(error);

		if (userMessage !== null) EXTRACTED_ERROR.name = this.getSubjectPrefix(true, me);

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		Sentry.configureScope(async (scope) => {
			if (
				// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				!!error &&
				typeof error !== 'string' &&
				errorTypeUtils.isTypeHttpErrorResponse(error)
			) {
				return this.addToContext('server', {
					stack: JSON.stringify(error.error, undefined, 2),
					status: error.status,
					statusText: error.statusText,
					message: error.message,
					url: error.url,
				});
			}

			// TODO: PLANO-177612 Remove this when it gets obsolete.
			if (userMessage) scope.setTag('userMessage', userMessage);
		});
	}

	/**
	 * Get a simple error object from the input, which can be a more complex error object.
	 * E.g. a HttpErrorResponse in Angular has properties like .status, .type but also a .error which contains the simple
	 * JS Error object.
	 */
	private extractError(error : Parameters<PSentryService['handleError']>[0]) : Error {
		try {
			if (typeof error === 'string') return new Error(error);

			if (errorTypeUtils.isAngularWrappedError(error)) {
				// Try to unwrap zone.js error.
				// https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
				if (errorTypeUtils.isTypeError(error.ngOriginalError)) return error.ngOriginalError;
				return new Error(error.ngOriginalError);
			}

			if (errorTypeUtils.isTypeHttpErrorResponse(error)) {
				// It happened that error.error is null [PLANO-FE-4MJ]
				if (error.error) {
					// If it's http module error, extract as much information from it as we can.

					// The `error` property of http exception can be either an `Error` object, which we can use directly…
					if (errorTypeUtils.isTypeError(error.error)) {
						return error.error;
					}

					// …or an`ErrorEvent`, which can provide us with the message but no stack…
					if (
						errorTypeUtils.isTypeErrorEvent(error.error) &&
						error.error.message // Inspired by https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-692494628
					) {
						return new Error(error.error.message);
					}

					return new Error(`${error.name}: ${error.status} ${error.error[0]}`);
				}

				// If we don't have any detailed information, fallback to the request message itself.
				return new Error(error.message);
			}

			// We can handle messages and Error objects directly.
			if (errorTypeUtils.isTypeError(error)) return error;
		} catch (catchError) {
			console.error(`Error has unexpected type`, error, catchError);
			this.setTag('errorTypeUnexpected', `${JSON.stringify(catchError)}`);
		}
		return error as never;
	}

	/**
	 * Create subject
	 */
	public getSubjectPrefix(
		hasUserMessage : boolean,
		me : MeService | null,
	) : string {
		let result = '';

		if (hasUserMessage) {
			result += '💬 | ';
		}

		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (me?.isLoaded()) {
			if (me.isTestAccount) result += '⏲️ ';
			if (me.data.isOwner) result += '👑 ';
			if (me.data.firstName && me.data.locationName) {
				result += `${me.data.firstName} @ ${me.data.locationName}`;
			}
		} else {
			result += '?';
		}

		// Jira creates tickets from our emails. The email subject will be the title of the ticket.
		// Character limit if Jira for titles is 255
		result = result.slice(0, 255);

		return result;
	}

	private clicksTextInfo : string [] = [];

	/**
	 * Add click info as a tag to Sentry
	 */
	public addClickTextInfo(element : HTMLElement | EventTarget) : void {
		const AMOUNT_OF_SAVED_CLICKS = 30;
		const htmlElement = (element as HTMLElement);
		const elementTextContent = htmlElement.textContent?.trim();
		const elementTitle : string | undefined = htmlElement.title as (string | undefined);
		const elementHtml = htmlElement.innerHTML;
		this.clicksTextInfo.push(elementTextContent?.length ? elementTextContent : (elementTitle && elementTitle.length > 0 ? elementTitle : elementHtml));
		if (this.clicksTextInfo.length > AMOUNT_OF_SAVED_CLICKS) {
			this.clicksTextInfo = this.clicksTextInfo.slice(-AMOUNT_OF_SAVED_CLICKS);
		}
	}

	/**
	 * Get last clicked elements as a formatted string to make it look nice in the Sentry UI.
	 */
	private get lastClickedElements() : string {
		return this.clicksTextInfo.join(' ->\n').trim();
	}

	/**
	 * Set a Sentry Tag
	 * Use this if you want specific additional information on your entry.
	 */
	public setTag(name : string, value : Primitive) : void {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		Sentry.configureScope((scope) => {
			scope.setTag(name, value);
		});
	}

	/**
	 * Set subtitle for a sentry entry
	 */
	public setSubTitle(subTitle : string) : void {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		Sentry.configureScope((scope) => {
			scope.setTransactionName(subTitle);
		});
	}

	private async addToContext(
		contextName : string,
		data : Record<string, unknown>,
	) : Promise<void> {
		return new Promise(resolve => {
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			Sentry.configureScope(scope => {
				scope.setContext(contextName, {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					...(scope as any)._contexts[contextName],
					...data,
				});

				resolve();
			});
		});
	}

	/**
	 * Check if sentry is blocked. E.g. by an ad-blocker.
	 *
	 * We determine this by asking for some internal data from
	 * This is a workaround as sentry does not provide a public API for this.
	 * Related: https://stackoverflow.com/questions/77365885/detect-if-sentry-capturemessage-or-sentry-captureexception-gets-blocked-in
	 */
	public sentryIsBlocked() : boolean | null {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const client = Sentry.getCurrentHub().getClient();
		if (!client) return null;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a hack anyway.
		const outcomes = (client as any)['_outcomes'];
		const numberOfNetworkSessionErrors : number | undefined = outcomes['network_error:session'];
		return !!numberOfNetworkSessionErrors && numberOfNetworkSessionErrors > 0;
	}
}
