import { Subject } from 'rxjs';

/**
 * Calls the given method only once when the subscribable emits the first value.
 * After that, the subscription is unsubscribed.
 * @param subject The subject to subscribe to
 * @param method The method to call
 */
export const callOnlyOnceOnSubscribe = <T>(
	subject : Subject<T>,
	method : () => void,
) : void => {
	// eslint-disable-next-line dr-plano-rules/prevent-unsubscribe-on-subscribe -- Here we can call it
	const subscription = subject.subscribe(() => {
		method();
		subscription.unsubscribe();
	});
};
