import { Integer } from '@plano/shared/api/base/generated-types.ag';
import { NonZeroInteger } from '@plano/shared/core/utils/typescript-utils-types';
import { IdBase } from './id-base';

/**
 * Representing an database Id object. This should behave like an primitive.
 * The user expects to use the "=" operator to make copies. So, the content of an id should never be changed
 * afterward it was initialized.
 */
export class Id extends IdBase<number> {
	protected constructor(value : Integer) {
		super(value);
	}

	/**
	 * @param value The raw-data of the id or a negative new-item-id.
	 */
	public static create<T extends number>(value : NonZeroInteger<T>) : Id {
		return new Id(value);
	}
}
