/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint "@typescript-eslint/no-restricted-imports": ["error", {
	"name": "./scheduling-api.service",
	"message": "Need a class as type? Add an Interface for it in scheduling-api.interfaces.ts"
}, {
	"name": "./@plano/shared/api",
	"message": "This adds a huge import chain. Avoid it!."
}] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { ShiftData } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-timeline-layout.service';
import { PTextColor, PTextColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { SchedulingApiShift } from '@plano/shared/api';
import { SortFnArray } from '@plano/shared/api/base/api-list-wrapper/api-list-wrapper.type';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { PlanoFaIconContextPool, PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { stringCompare } from '@plano/shared/core/utils/sorting-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

/**
 * Enum defining possible payment status of a bookable.
 */
export enum PPaymentStatusEnum {

	/**
	 * Money need to be refunded to booking person.
	 */
	REFUND_NEEDED = 'REFUND_NEEDED',

	/**
	 * A bookable with a positive `amountToPay` and `openAmount` equal zero.
	 * I.e. the booking person had to pay something and paid it completely.
	 */
	PAID = 'PAID',

	/**
	 * Bookable has been paid partially.
	 */
	PARTIALLY_PAID = 'PARTIALLY_PAID',

	/**
	 * A bookable with a positive `amountToPay` and `currentlyPaid` equal 0.
	 * I.e. the booking person has to pay something but has not paid anything yet.
	 */
	UNPAID = 'UNPAID',

	/**
	 * A bookable with `price` and `openAmount` are 0.
	 * I.e. a free bookable where nothing was paid.
	 */
	PAID_FREE_BOOKABLE = 'PAID_FREE_BOOKABLE',

	/**
	 * A bookable where both `amountToPay` and `openAmount` are 0.
	 * I.e. a bookable where currently nothing has to be paid (e.g. because cancelled) and also nothing was paid.
	 */
	PAID_NO_AMOUNT_TO_PAY = 'PAID_NO_AMOUNT_TO_PAY',
}

/**
 * Get a icon for paymentStatus
 */
export const getPaymentStatusIcon = (paymentStatus : PPaymentStatusEnum | null) : PlanoFaIconPoolKeys => {
	if (paymentStatus === null) return PlanoFaIconContextPool.LOADING;
	if (paymentStatus === PPaymentStatusEnum.REFUND_NEEDED) return PlanoFaIconContextPool.REFUND;
	return PlanoFaIconContextPool.BOOKING_PAYMENT_STATUS;
};

/**
 * Get a theme / color for paymentStatus icon
 */
export const getPaymentStatusIconStyle = (paymentStatus : PPaymentStatusEnum | null) : Exclude<PTextColor, 'info'> | null => {
	switch (paymentStatus) {
		case PPaymentStatusEnum.PAID_FREE_BOOKABLE :
			return PTextColorEnum.MUTED;
		case PPaymentStatusEnum.PAID :
			return enumsObject.PThemeEnum.SUCCESS;
		case PPaymentStatusEnum.UNPAID :
		case PPaymentStatusEnum.REFUND_NEEDED :
			return enumsObject.PThemeEnum.DANGER;
		case PPaymentStatusEnum.PARTIALLY_PAID :
			return enumsObject.PThemeEnum.WARNING;
		case PPaymentStatusEnum.PAID_NO_AMOUNT_TO_PAY:
		case null:
			return null;
	}
};

/**
 * Get the tooltip theme for the current payment status
 */
export const getPaymentStatusTooltipTheme = (paymentStatus : PPaymentStatusEnum) : Exclude<PThemeEnum, 'info'> => {
	switch (paymentStatus) {
		case PPaymentStatusEnum.PAID_FREE_BOOKABLE :
			return enumsObject.PThemeEnum.LIGHT;
		case PPaymentStatusEnum.PAID :
			return enumsObject.PThemeEnum.SUCCESS;
		case PPaymentStatusEnum.UNPAID :
		case PPaymentStatusEnum.REFUND_NEEDED :
			return enumsObject.PThemeEnum.DANGER;
		case PPaymentStatusEnum.PARTIALLY_PAID :
			return enumsObject.PThemeEnum.WARNING;
		case PPaymentStatusEnum.PAID_NO_AMOUNT_TO_PAY:
			return enumsObject.PThemeEnum.DARK;
	}
};

/**
 * getter for the title of the status of payment
 */
export const paymentStatusTitle = (paymentStatus : PPaymentStatusEnum) : PDictionarySourceString => {
	switch (paymentStatus) {
		case PPaymentStatusEnum.REFUND_NEEDED :
			return 'Rückerstattung fällig';
		case PPaymentStatusEnum.PAID_NO_AMOUNT_TO_PAY :
			return 'Keine Zahlungen offen';
		case PPaymentStatusEnum.PAID_FREE_BOOKABLE :
			return 'Kostenlos';
		case PPaymentStatusEnum.PAID :
			return 'Komplett bezahlt';
		case PPaymentStatusEnum.UNPAID :
			return 'Noch nicht bezahlt';
		case PPaymentStatusEnum.PARTIALLY_PAID :
			return 'Teils bezahlt';
	}
};

const compareFnName = (a : string | null, b : string | null) : number => {
	if (!a) return -1;
	if (!b) return 1;
	return stringCompare(a, b);
};

/**
 * An array of sort fn’s that can be used to sort shifts in some kind of list view.
 *
 * NOTE: This implementation is aligned with the implementation in `ShiftListImpl.java` -> `sortLikeFrontendListViews()`.
 * Make sure to update both sides when changing anything.
 *
 * When you use it, remember that the priority goes from lowest to highest here. So revert the fn’s when us use them in sort().
 *
 * @example
 *   shifts.sort([...sortShiftsForListViewFns].reverse(), { inPlace: false });
 *
 * TODO: PLANO-184171 Switch from [...array].reverse() to ES2023’s array.toReversed()
 */
export const sortShiftsForListViewFns : SortFnArray<SchedulingApiShift> = [
	(itemA, itemB) => itemA.id.seriesId - itemB.id.seriesId,
	(itemA, itemB) => compareFnName(itemA.name, itemB.name),
	(aShift, bShift) => aShift.end - bShift.end,
	(aShift, bShift) => aShift.start - bShift.start,
];

// eslint-disable-next-line jsdoc/require-param -- Dont want to document every sort function
/**
 * An array of sort fn’s that can be used to sort shifts in some kind of timeline view.
 *
 * 1. criteria: shifts of the same series (smaller should appear first)
 * 1. criteria: start of shift (smaller should appear first)
 * 2. criteria: end of shift (greater should appear first)
 * 3. criteria: name (of activity)
 *
 * @example
 *   for (const compareFn of sortShiftsForTimelineViewFns) {
 *     shifts.sort(compareFn)
 *   }
 */
export const sortShiftsForTimelineViewFns : SortFnArray<ShiftData> = [
	(a, b) => a.shift.id.seriesId - b.shift.id.seriesId,
	(a, b) => compareFnName(a.shift.name, b.shift.name),
	(a, b) => a.shift.end - b.shift.end,
	(a, b) => a.shift.start - b.shift.start,
];
