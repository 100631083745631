import { AfterViewInit, Directive, Input } from '@angular/core';
import { LogService } from '@plano/shared/core/log.service';

/**
 * Since we have a component for the definition of modal content, custom templates are deprecated.
 * To make sure that this does not get accidentally overlooked, we implement a runtime check here.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- It’s ok as the directive should prevent the usage of this css class
	selector: '.modal-body',
	standalone: true,
})
export class ValidModalsDirective implements AfterViewInit {
	/**
	 * We have some existing cases that we don’t want to fix now.
	 * @deprecated Make use of the component 'p-modal-content' instead of using this flag.
	 */
	@Input() private skipDeprecationCheck : boolean = false;

	constructor(
		private console : LogService,
	) {}

	public ngAfterViewInit() : void {
		// eslint-disable-next-line deprecation/deprecation -- Backwards compatibility
		if (this.skipDeprecationCheck) return;
		this.console.error(`Do not use a custom template for your modal content. Use the component 'p-modal-content' instead.`);
	}
}
