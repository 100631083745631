/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { ApiBase } from '@plano/shared/api/base/api-base/api-base';
import { Meta } from '@plano/shared/api/base/meta';
import { Subject } from 'rxjs';
import { ApiAttributeInfo } from './attribute-info/api-attribute-info';
import { IdBase } from './id/id-base';
import { ObjectWithRawData } from './object-with-raw-data';

/**
 * The base class for api data wrappers.
 */
export abstract class ApiDataWrapperBase extends ObjectWithRawData {
	constructor(public api : ApiBase, public parent : ApiDataWrapperBase | null) {
		super();
	}

	public aiThis ! : ApiAttributeInfo<any, any>;

	/**
	 * A subject which completes on item destroy.
	 */
	// eslint-disable-next-line @typescript-eslint/member-ordering -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public readonly destroyed = new Subject<ApiDataWrapperBase>();

	/**
	 * Returns the id of this wrapper.
	 */
	public abstract get id() : IdBase<any> | null;

	/**
	 * Replaces all id references which were found in the map "idReplacements" map.
	 * @param idReplacements
	 */
	public abstract _fixIds(idReplacements : Map<any, number>) : void;

	/**
	 * The `dni` number being used for `loadDetailed()`. `dni` meaning detailed-node-id. It identifies uniquely a node of current api.
	 */
	protected abstract get dni() : string;

	/**
	 * Updates the raw data of this wrapper.
	 * @param data New raw data.
	 * @param _generateMissingData Should missing raw data be generated? I.e. if the wrapper structure
	 * 		requires a given structure but this is not available in the raw data then the missing
	 * 		part will be generated.
	 */
	public _updateRawData(data : any[] | null, _generateMissingData : boolean) : void {
		if (!data) {
			// wrapper is being "destroyed". Inform others.
			this.destroyed.next(this);
			this.destroyed.complete();
		}

		// Is this wrapper currently loaded in detail?
		if (data) {
			const di = this.api.getLastLoadSearchParams()?.get('di') ?? null;
			const dni = this.api.getLastLoadSearchParams()?.get('dni') ?? null;

			if (this.dni === dni && di && JSON.stringify(Meta.getBackendId(data)) === di)
				this.api.currentlyDetailedLoaded = this;
		}
	}

	/**
	 * @returns Returns a list of the names of all child primitive attributes attached to this wrapper.
	 */
	public get childPrimitiveNames() : Array<string> {
		const result : string[] = [];

		// eslint-disable-next-line no-restricted-syntax -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		for (const propertyName in this) {
			if (propertyName.startsWith('ai')) {
				const attributeInfo = this[propertyName] as unknown as ApiAttributeInfo<ApiDataWrapperBase, any>;

				if (!attributeInfo.isWrapper)
					result.push(attributeInfo.name);
			}
		}

		return result;
	}

	/**
	 * @returns Returns a list of the names of all child wrapper attributes attached to this wrapper.
	 */
	public get childWrapperNames() : Array<string> {
		const result : string[] = [];

		// eslint-disable-next-line no-restricted-syntax -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		for (const propertyName in this) {
			if (propertyName.endsWith('Wrapper')) {
				// remove "Wrapper" from end
				const wrapperAttributeName = propertyName.slice(0, -7);

				result.push(wrapperAttributeName);
			}
		}

		return result;
	}

	/**
	 * @returns Returns the attribute-info for an attribute-name.
	 */
	public getAttributeInfo(attributeName : string) : ApiAttributeInfo<ApiDataWrapperBase, any> {
		return (this as any)[`ai${ attributeName.charAt(0).toUpperCase() }${attributeName.slice(1)}`];
	}

	/**
	 * Is this data-wrapper loaded in detailed?
	 * You can override this to adjust the logic when backend has also some custom logic.
	 * See `requestingDetailedMethodCall`.
	 * TODO: Add this also to Api generator so the overriding of the logics is transparent in the XML files.
	 */
	public get isDetailedLoaded() : boolean {
		return this.api.currentlyDetailedLoaded === this;
	}

	/**
	 * @returns Is this a newly created item?
	 */
	public abstract get isNewItem() : boolean;
}
