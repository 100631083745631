import { Extra, Extras, ScopeContext } from '@sentry/types';

/**
 * Helper function to check if an error matches a regex.
 * @param error The error to check
 * @param regex The regex to check against
 */
export const errorMatchesRegEx = (
	error : Error,
	regex : RegExp,
) : boolean => {
	// If there is no error message, it can not match any regex.
	if (!error.message) {
		// But we dont expect that to happen. So for debugging purposes we log it at least to the console.
		// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		console.error('Error has no message', error);
		return false;
	}
	return !!error.message.match(regex) || !!error.name.match(regex);
};

/**
 * A list of error messages that we know about and don't want to report/throw.
 * The reason for ignoring it is that we analyzed the issue and decided that it
 * is not worth blocking the user.
 */
export const throwIgnoreList : RegExp[] = [
	// Tawk is an optional chat service. If it fails, we don't want the user to be blocked.
	// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	new RegExp(/.*tawk\.to.*/),
	// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	new RegExp(/Socket server did not execute the callback for\.*/),

	// This is our tawk id. [PLANO-36160]
	// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	new RegExp(/599aa06c1b1bed47ceb05bde/),

	// Slick is an optional slider service for the UI. If it fails, we don't want the user to be blocked.
	// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	new RegExp(/.*slick.*/),

	// This is an expected error. For more details search for that message in code.
	// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	new RegExp(/Client could not be found\. This error is expected when client has not activated their kletterszene integration\./),
];

/**
 * A list of error messages that we know about and don't want sentry to be notified about.
 */
export const sentryReportIgnoreList : RegExp[] = [
	...throwIgnoreList,
];

/**
 * A list of error levels that can be used for logging.
 */
export enum PSeverity {

	/** This is critical/blocking. It opened a Error-Modal for the user. */
	FATAL = 'fatal',

	/**
	 * An error that is not critical enough to block the user.
	 */
	// NOTE:
	// We keep this commented out here as a reminder that Sentry would support it. But we dont use it.
	// The reason why we dont use it is that 'error' looks too close to fatal in the UI of Sentry.
	// ERROR = 'error',

	/** Unexpected but harmless. */
	WARNING = 'warning',

	/** This is not a error. We just want to collect data about it. */
	INFO = 'info',

	/** This is only for debugging. */
	// NOTE:
	// We keep this commented out here as a reminder that Sentry would support it. But we dont use it.
	// The reason why we dont use it is that we have enough other severity levels.
	// DEBUG = 'debug',
}

/**
 * The scope object that can contains meta info’s for a Sentry entry.
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export interface PSentryScopeContext extends Partial<ScopeContext> {
	level ?: PSeverity;
	extra : Extras & {
		error : Extra
	}
}
