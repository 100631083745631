<p-badge
	theme="secondary"
	[size]="enums.BootstrapSize.LG"

	*ngIf="ai.isAvailable && me.data.loggedInWithMasterPassword && !me.hideDebugInformation"
	[pTooltip]="tooltip"
>
	<img class="logo me-1" src="images/plano-logo/p.svg" alt="Dr.&nbsp;Plano Logo" height="15px" />
	{{ label }}
</p-badge>

<ng-template #tooltip>
	<div>
		<div class="pt-3 ps-3 pe-3">{{ ai.value }}</div>
		<hr class="bg-light" />
		<div class="pb-3 ps-3 pe-3">
			<div class="mb-2"><fa-icon [icon]="enums.PlanoFaIconContextPool.MORE_INFO" />This debug information is only shown internally when logged in with our master password.</div>
			<div>You can hide all debug information by clicking in main menu on <code>HIDE DEBUG INFORMATION</code>.</div>
		</div>
	</div>
</ng-template>
