/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint-disable max-classes-per-file -- FIXME: Remove this before you work here., Solve the remaining cases please. */
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { WarningsService } from '@plano/client/shared/warnings.service';
import { ApiLists, TimeStampApiAllowedTimeStampDeviceBase, TimeStampApiAllowedTimeStampDevicesBase, TimeStampApiRootBase, TimeStampApiServiceBase, TimeStampApiShiftBase, TimeStampApiShiftModelBase, TimeStampApiShiftModelsBase, TimeStampApiShiftsBase, TimeStampApiStampedMemberBase } from '@plano/shared/api';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { ApiDataCopyAttribute } from '@plano/shared/api/base/api-data-copy-attribute/api-data-copy-attribute';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { assumeNonNull, assumeNotUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { stringCompare } from '@plano/shared/core/utils/sorting-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

/** @see TimeStampApiServiceBase */
@Injectable({ providedIn: 'root' })
export class TimeStampApiService extends TimeStampApiServiceBase {
	constructor(
		public override http : HttpClient,
		public override router : Router,
		public override apiError : ApiErrorService,
		private warnings : WarningsService,
		public override zone : NgZone,
		injector : Injector,
	) {
		super(http, router, apiError, zone, injector);
	}

	/**
	 * Returns true if member started shift, and is not pausing.
	 * if you just want to check if shift is started, use !!api.data.start
	 */
	public get isWorking() : boolean | null {
		if (this.isPausing) return false;
		if (this.isDone) return false;
		if (!this.data.aiStart.isAvailable) return null;
		return !!this.data.start;
	}

	/**
	 * Returns true if member started a pause and has not finished it yet
	 */
	public get isPausing() : boolean | null {
		if (!this.data.aiUncompletedRegularPauseStart.isAvailable) return null;
		return !!this.data.uncompletedRegularPauseStart;
	}

	/**
	 * Shift has an end-time
	 */
	private get isDone() : boolean | null {
		if (!this.data.aiEnd.isAvailable) return null;
		return !!this.data.end;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public startPause() : void {
		const now = +(new PMomentService(Config.LOCALE_ID).m());

		this.data.uncompletedRegularPauseStart = now;
	}
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public completePause(duration ?: number) : void {
		const pauseDuration = duration ?? this.data.regularPauseDuration;
		this.data.completedRegularPausesDuration = pauseDuration;
		this.data.uncompletedRegularPauseStart = null;
	}

	/**
	 * Is time-stamp for this member running?
	 */
	public timeStampIsRunning() : boolean | null {
		if (!this.data.aiStart.isAvailable) return null;
		if (!this.data.start) return false;
		if (this.isDone === null) return null;
		return !this.isDone;
	}

	/**
	 * @see WarningsService#getWarningMessages
	 */
	public get warningMessages() : ReturnType<WarningsService['getWarningMessages']> {
		return this.warnings.getWarningMessages(this.data);
	}

	/**
	 * Is there any warning message?
	 */
	public get hasWarningMessages() : boolean {
		return this.data.warnUnplannedWork ||
			this.data.warnStampedNotCurrentTime ||
			this.data.warnStampedNotShiftTime;
	}
}

class SDuration {
	// Time in Milliseconds
	constructor(a : number, b : number = 0) {
		// a == startTime and b == endTime in milliseconds?
		let startTime = a;
		let endTime = b;

		if (endTime < startTime) {
			const temp = startTime;
			startTime = endTime;
			endTime = temp;
		}

		const END = +(new PMomentService(Config.LOCALE_ID).m(endTime));
		const START = +(new PMomentService(Config.LOCALE_ID).m(startTime));
		this.duration = END - START;
	}

	public duration : number;
}

/** @see TimeStampApiRootBase */
export class TimeStampApiRoot extends TimeStampApiRootBase {

	/**
	 * There is a problem with the binding to a textarea. This must be empty string or
	 * filled string but not Null.
	 */
	public override get comment() : string {
		const result = super.comment;
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return result ? result : '';
	}

	public override set comment(newValue : string | null) {
		super.comment = newValue;
	}

	/**
	 * Get selected Item no matter if its shift or shiftmodel.
	 * This is a shortcut. The BaseApi only holds Id's of shift or shiftModel, and
	 * not the shift or shiftmodel itself.
	 */
	public get selectedItem() : TimeStampApiShift | TimeStampApiShiftModel | null {
		const shift = this.selectedShift;
		const shiftModel = this.selectedShiftModel;
		return shift ?? shiftModel;
	}

	private cutMillisecondsFromDuration(duration : number) : number {
		// TODO: [PLANO-111111] Duplicate
		const pMoment = new PMomentService(undefined);
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const momentDuration = pMoment.duration(duration);
		const milliseconds : number = momentDuration.milliseconds();
		return momentDuration.subtract(milliseconds).asMilliseconds();
	}

	/**
	 * @returns sum of completed pauses and running pause
	 */
	public get regularPauseDuration() : number {
		let uncompletedPauseDuration;

		if (this.uncompletedRegularPauseStart === null) {
			uncompletedPauseDuration = new SDuration(0).duration;
		} else {
			assumeNotUndefined(this.uncompletedRegularPauseStart as typeof this.uncompletedRegularPauseStart | undefined, 'PRODUCTION-5E5');
			const start = this.cutMillisecondsFromDuration(this.uncompletedRegularPauseStart);
			const end = this.cutMillisecondsFromDuration(+(new PMomentService(Config.LOCALE_ID).m()));

			uncompletedPauseDuration = new SDuration(start, end).duration;
		}

		return this.completedRegularPausesDuration + uncompletedPauseDuration;
	}

	/**
	 * This represents the duration of the stamped time excluding pauses.
	 * @returns The duration of the stamped time excluding pauses or null if the time stamp has not started yet.
	 */
	public get workingTimeDuration() : number | null {
		// time stamp has not started yet?
		if (this.start === null)
			return null;

		// working time is duration from time-stamp start to now minus pause duration

		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		let currentWorkingTimeEnd = this.end ? this.end : (+(new PMomentService(Config.LOCALE_ID).m()));
		currentWorkingTimeEnd = this.cutMillisecondsFromDuration(currentWorkingTimeEnd);
		const start = this.cutMillisecondsFromDuration(this.start);

		const workingTimeDuration = new SDuration(start, currentWorkingTimeEnd).duration;
		return workingTimeDuration - this.regularPauseDuration;
	}

	/**
	 * Get selected shift.
	 * This is a shortcut. The BaseApi only holds Id the shift, and
	 * not the shift itself.
	 */
	private get selectedShift() : TimeStampApiShift | null {
		const id = this.selectedShiftId;
		if (id === null) return null;
		return this.shifts.get(id);
	}

	/**
	 * Get selected shiftModel.
	 * This is a shortcut. The BaseApi only holds Id the shiftModel, and
	 * not the shiftmodel itself.
	 */
	private get selectedShiftModel() : TimeStampApiShiftModel | null {

		if (this.selectedShiftModelId !== null) {
			const shiftModel = this.api.data.shiftModels.get(this.selectedShiftModelId);
			if (shiftModel) {
				return shiftModel;
			}
		}
		return null;
	}
}

/** @see TimeStampApiStampedMemberBase */
export class TimeStampApiStampedMember extends TimeStampApiStampedMemberBase {

	private cutMillisecondsFromDuration(duration : number) : number {
		// TODO: [PLANO-111111] Duplicate
		const pMoment = new PMomentService(undefined);
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const momentDuration = pMoment.duration(duration);
		const milliseconds : number = momentDuration.milliseconds();
		return momentDuration.subtract(milliseconds).asMilliseconds();
	}

	/**
	 * Difference between now and start of stamped time.
	 * This represents the duration of the stamped time including pauses.
	 */
	public get activityDuration() : number {
		const start : number = this.cutMillisecondsFromDuration(this.activityStart);
		const now : number = this.cutMillisecondsFromDuration(+(new PMomentService(Config.LOCALE_ID).m()));
		return now - start;
	}

}

/** @see TimeStampApiShiftBase */
export class TimeStampApiShift extends TimeStampApiShiftBase {

	// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public selected = new ApiDataCopyAttribute<boolean>(false);

	/**
	 * shorthand that returns the related model
	 */
	public get model() : TimeStampApiShiftModel {
		// NOTE: This methods exists on multiple classes:
		// TimeStampApiShift
		// SchedulingApiShift
		// SchedulingApiBooking
		// SchedulingApiTodaysShiftDescription
		// SchedulingApiWorkingTime
		const SHIFT_MODEL = this.api.data.shiftModels.get(this.modelId);
		assumeNonNull(SHIFT_MODEL, 'SHIFT_MODEL');

		return SHIFT_MODEL;
	}

	/**
	 * Get the name based on the linked shiftModel
	 */
	public get name() : TimeStampApiShiftModel['name'] {
		// NOTE: This methods exists on multiple classes:
		// SchedulingApiRoot
		// TimeStampApiRoot
		return this.model.name;
	}
}

/** @see TimeStampApiShiftsBase */
export class TimeStampApiShifts extends TimeStampApiShiftsBase {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get selected() : this {
		return this.filterBy(item => item.selected.value);
	}

}

/** @see TimeStampApiShiftModelBase */
export class TimeStampApiShiftModel extends TimeStampApiShiftModelBase {
	/**
	 * Check if this shiftModel fits to the given search term.
	 * @param searchTerm The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(searchTerm : string | null) : boolean {
		if (searchTerm === null) return true;
		if (searchTerm === '') return true;
		for (const termItem of searchTerm.split(' ')) {
			const termLow = termItem.toLowerCase();

			if (this.name.toLowerCase().includes(termLow)) continue;
			if (this.activityAreaName.toLowerCase().includes(termLow)) continue;
			if (this.aiCourseTitle.isAvailable && this.courseTitle?.toLowerCase().includes(termLow)) continue;

			return false;
		}
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public equals(shiftModel : TimeStampApiShiftModel) : boolean {
		// NOTE: duplicate! This methods exists on multiple classes:
		// SchedulingApiRoot
		// TimeStampApiRoot
		return this.id.equals(shiftModel.id);
	}

}

/** @see TimeStampApiShiftModelsBase */
export class TimeStampApiShiftModels extends TimeStampApiShiftModelsBase {

	/**
	 * Search these shiftModels by a given string.
	 * @param term The string to search for. Can be a string that a user typed into an input element.
	 */
	public search(term : Parameters<TimeStampApiShiftModel['fitsSearch']>[0]) : this {
		if (term === '') return this;
		return this.filterBy(item => item.fitsSearch(term));
	}

	private _groupByActivityArea = new Data<ApiLists<TimeStampApiShiftModels>>(this.api);

	/**
	 * @returns Returns a list of lists where each inner list contains the shift-models with the same parent name.
	 * Note: Iterating maps in ng templates seems not be supported. So, instead this list of list structure was used.
	 */
	public get groupByActivityArea() : ApiLists<TimeStampApiShiftModels> {
		return this._groupByActivityArea.get(() => {
			return this.groupedBy((a, b) => stringCompare(a.activityAreaName, b.activityAreaName), false, false);
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get trashedItemsAmount() : number {
		let result = 0;
		for (const shiftModel of this.iterable()) {
			if (shiftModel.trashed) {
				result += 1;
			}
		}
		return result;
	}

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => !item.trashed);
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class TimeStampApiAllowedTimeStampDevice extends TimeStampApiAllowedTimeStampDeviceBase {

	/**
	 * Get an icon name for <fa-icon>
	 */
	public get iconName() : PlanoFaIconPoolKeys {
		// Return browserName if the string equals a fontawesome icon name
		switch (this.browserName) {
			case 'ie':
				return enumsObject.PlanoFaIconContextPool.BRAND_INTERNET_EXPLORER;
			case 'chrome':
			case 'safari':
			case 'firefox':
			case 'opera':
			case 'internet-explorer':
			case 'edge':
				return `fa-${this.browserName} fa-brands`;
			case 'appAndroid':
				return enumsObject.PlanoFaIconContextPool.BRAND_ANDROID;
			case 'appIOS':
				return enumsObject.PlanoFaIconContextPool.BRAND_APPLE;
			default:
				return enumsObject.PlanoFaIconContextPool.INTERNET;
		}
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class TimeStampApiAllowedTimeStampDevices extends TimeStampApiAllowedTimeStampDevicesBase {

	/**
	 * @returns Returns if this device matches the given api "allowedDevice". `undefined` is returned when
	 * visitor-id is not determined yet so this cannot be answered.
	 */
	public matchesDeviceItem(allowedDevice : TimeStampApiAllowedTimeStampDevice) : boolean | null {
		const visitorId = this.api.fingerprintService.visitorId;

		if (visitorId === undefined)
			return null;

		return visitorId === allowedDevice.visitorId;
	}

	/**
	 * @returns Returns the matching allowedTimeStampDevice item from the api list. If none exists `null` is returned.
	 * `undefined` is returned if visitor-id is not determined yet so this cannot be answered.
	 */
	private getMatchingDeviceItem() : TimeStampApiAllowedTimeStampDevice | null | undefined {
		if (this.api.fingerprintService.visitorId === undefined)
			return undefined;

		for (const allowedDevice of this.iterable()) {
			if (this.matchesDeviceItem(allowedDevice))
				return allowedDevice;
		}

		return null;
	}

	/**
	 * @returns Is this device allowed to time-stamp? `undefined` is returned if this cannot be determined yet.
	 */
	public isDeviceAllowedToTimeStamp() : boolean | undefined {
		// all devices allowed?
		if (this.length === 0)
			return true;

		// Otherwise check if this device matches any of the allowed devices.
		const matchingDeviceItem = this.getMatchingDeviceItem();

		if (matchingDeviceItem === undefined) return undefined;
		return !!matchingDeviceItem;
	}

	private get allowedDeviceBrowserName() : string | null {
		return Config.platform === 'browser' ? 	Config.browser.name	:
			Config.platform;
	}

	/**
	 * Allow this device to time-stamp. Note that this method itself calls `api.save()`.
	 */
	public allowDeviceToTimeStamp(name : string) : void {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises, promise/prefer-await-to-then -- FIXME: Remove this before you work here., FIXME: Remove this before you work here.
		this.api.fingerprintService.getVisitorIdPromise().then(() => {
			// Remove old item if one exists
			const oldItem = this.getMatchingDeviceItem();

			if (oldItem)
				this.removeItem(oldItem);

			// create new item
			const newItem = this.createNewItem();
			newItem.name = name;
			newItem.visitorId = this.api.fingerprintService.visitorId!;
			// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			newItem.browserName = this.allowedDeviceBrowserName ?? '-';

			// eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Remove this before you work here.
			this.api.save();
		});
	}
}
