/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable } from '@angular/core';
import { PCalloutTheme } from '@plano/client/shared/bootstrap.utils';
import { ApiListWrapper } from '@plano/shared/api';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { NonUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { NgProgressComponent } from 'ngx-progressbar';
import { interval, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, flatMap, startWith, takeWhile, windowToggle } from 'rxjs/operators';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface ToastObject {

	/**
	 * Title of the Toast.
	 * If you don‘t set it, a default value will be set.
	 * If you set it to null, no title will be shown.
	 */
	title ?: string | null;
	content : string;

	/**
	 * Visual style of the toast
	 */
	theme ?: PCalloutTheme;

	/**
	 * Duration of visibility.
	 * @see ToastsService#visibilityDurationToNumber
	 */
	visibilityDuration ?: 'short' | 'medium' | 'long' | 'infinite';
	visibleOnMobile ?: boolean;

	/**
	 * Title of the Toast. If set to null, a default value will be set.
	 */
	icon ?: PlanoFaIconPoolKeys;

	close ?: () => void;
	dismiss ?: () => void;
	closeBtnLabel ?: string;
	dismissBtnLabel ?: string;
}

type ProgressPercentage = number;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface ExtendedToastObject extends ToastObject {
	progressChange$ : Subject<'start' | 'complete' | ProgressPercentage>,
	progressPaused$ : Subject<boolean>,
	progressInterval : Subscription | null,
	progressPercent : number,
}

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ToastsService extends PBaseClass {
	constructor(
		private localize : LocalizePipe,
		private console : LogService,
	) {
		super();
	}

	private toasts : ExtendedToastObject[] = [];

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get toastsAreAvailable() : boolean {
		return this.toasts.length > 0;
	}

	/**
	 * To be able to have this.toasts private, i added iterable() like we have it in ApiListWrapper
	 */
	public iterable() : ReturnType<ApiListWrapper<ExtendedToastObject>['iterable']> {
		return this.toasts;
	}

	/**
	 * How many toasts are visible?
	 */
	public get length() : ApiListWrapper<ExtendedToastObject>['length'] {
		return this.toasts.length;
	}

	/**
	 * Returns the newest toast in the list of toasts
	 */
	public get newest() : ExtendedToastObject {
		return this.toasts[this.toasts.length - 1];
	}

	private getIndexOfItemWithSameContent(toastInput : ToastObject) : number | null {
		for (let i = 0; i < this.toasts.length; i++) {
			if (
				this.toasts[i].content.toString() === toastInput.content.toString() &&
				this.toasts[i].title?.toString() === toastInput.title?.toString()
			) {
				return i;
			}
		}
		return null;
	}

	private initDefaultValues(toast : ToastObject) : void {
		if (toast.theme === undefined) toast.theme = this.enums.PThemeEnum.PRIMARY;
		if (!toast.visibilityDuration) {
			switch (toast.theme) {
				case this.enums.PThemeEnum.SUCCESS:
					toast.visibilityDuration = 'short';
					break;
				case this.enums.PThemeEnum.WARNING:
					toast.visibilityDuration = 'medium';
					break;
				case this.enums.PThemeEnum.DANGER:
					toast.visibilityDuration = 'infinite';
					break;
				default:
					toast.visibilityDuration = 'medium';
					break;
			}
		}
		if (toast.visibleOnMobile === undefined) toast.visibleOnMobile = true;
		if (toast.close !== undefined && toast.closeBtnLabel === undefined) {
			toast.closeBtnLabel = this.localize.transform('OK');
		}
		if (toast.dismiss !== undefined && toast.dismissBtnLabel === undefined) {
			toast.dismissBtnLabel = this.localize.transform('Schließen');
		}
	}

	/**
	 * Add a new Toast.
	 */
	public addToast(toastInput : ToastObject) : void {
		let index = this.getIndexOfItemWithSameContent(toastInput);
		if (index !== null) {
			this.runProgress(this.toasts[index]);
			return;
		}

		const toast : ExtendedToastObject = {
			progressChange$ : new Subject(),
			progressPaused$ : new Subject(),
			progressInterval : null,
			progressPercent : 0,
			...toastInput,
		};

		this.initDefaultValues(toast);

		this.toasts.push(toast);
		this.runProgress(toast);
		index = this.getIndexOfItemWithSameContent(toast);
		if (index === null) this.console.warn('Could not find related toast');
	}

	public PROGRESSBAR_SPEED : NgProgressComponent['speed'] = 500;

	private runProgress(toast : ExtendedToastObject) : void {
		const visibilityDuration = this.visibilityDurationToNumber(toast.visibilityDuration ?? 'infinite');
		if (visibilityDuration === null) return;

		// If this progressbar is already running, just reset the interval
		if (toast.progressInterval !== null) {
			toast.progressPercent = 0;
			return;
		}

		toast.progressChange$.next(toast.progressPercent);

		const pause$ = toast.progressPaused$.pipe(
			startWith(false),
			distinctUntilChanged(),
		);
		const ons$ = pause$.pipe(filter(v => v));
		const offs$ = pause$.pipe(filter(v => !v));

		const oneStepPercentage = 100 / visibilityDuration * this.PROGRESSBAR_SPEED;
		toast.progressInterval = interval(this.PROGRESSBAR_SPEED).pipe(

			// Define whats happing after the interval.
			finalize(() => {
				this.removeToast(toast);
			}),

			// Stop when 100 is reached
			// eslint-disable-next-line rxjs/no-ignored-takewhile-value -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			takeWhile((_value) => toast.progressPercent < 100),

			// Make progressbar stop depending on toast.progressPaused$ state
			windowToggle(
				offs$,
				() => ons$,
			),
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			flatMap(x => x),

		).subscribe(() => {
			const newPercent = toast.progressPercent + oneStepPercentage;
			toast.progressPercent = newPercent;
			toast.progressChange$.next(toast.progressPercent);
		});

	}

	/**
	 * How long should the toast be visible?
	 * @param visibilityDuration Duration in human understandable terms. Will be translated into milliseconds.
	 * @returns duration in milliseconds or null if duration should be infinite.
	 */
	public visibilityDurationToNumber(visibilityDuration : NonUndefined<ToastObject['visibilityDuration']>) : number | null {
		// Execution speed of automatic tests is unpredictable. To avoid flakiness, all toasts never close automatically in Gherkin scenarios.
		if (Config.APPLICATION_MODE === 'GHERKIN')
			return null;

		// return toast duration
		switch (visibilityDuration) {
			case 'short' :
				return 2500;
			case 'medium' :
				return 5000;
			case 'long' :
				return 10000;
			case 'infinite' :
				return null;
		}
	}

	/**
	 * Remove one toast from the list of visible toasts
	 * @param input The toast that should be removed
	 */
	public removeToast(input : ToastObject) : void {
		const index = this.getIndexOfItemWithSameContent(input);
		if (index === null) {

			// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (this.console.warn) this.console.warn('Could not find related toast');
			return;
		}

		const toast = this.toasts[index];

		// If can’t find the ref, remove any.
		toast.progressChange$.next('complete');

		// window.clearTimeout(toast.timeout ?? undefined);
		this.toasts.splice(index, 1);
	}

	/** Hide all toasts immediately / remove them from the internal list */
	public removeAllToasts() : void {
		this.toasts = [];
	}

	/**
	 * A toast to show for features that are not implemented yet.
	 * This is an alternative to the {@link PTodoComponent}.
	 */
	public comingSoon() : void {
		this.addToast({
			content: this.localize.transform('Demnächst verfügbar…'),
			theme: this.enums.PThemeEnum.PURPLE,
		});
	}
}
