/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable } from '@angular/core';
import { AffectedShiftsApiShifts, AuthenticatedApiRole, SchedulingApiActivityArea, SchedulingApiBooking, SchedulingApiMember, SchedulingApiPermissionGroupRole, SchedulingApiPermissionGroupShiftModelPermission, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchange, SchedulingApiShiftExchangeShiftRefs, SchedulingApiShiftModel, SchedulingApiShiftModels } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Data } from '@plano/shared/core/data/data';
import { MeService } from '@plano/shared/core/me/me.service';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

/**
 * Service to check if the logged in user has certain permissions.
 * NOTE: Usually, this service should not be accessed directly, but instead the permission methods of the attribute-infos should be used.
 */
@Injectable( { providedIn: 'root' } )
export class PPermissionsService {
	constructor(
		private meService : MeService,
		private api : SchedulingApiService,
	) {
	}

	/**
	 * @param input Either the member object or the id of the member.
	 * Is the passed `input` the logged in user?
	 */
	public isMe(input : SchedulingApiMember | Id) : boolean {
		const ID = input instanceof SchedulingApiMember ? input.id : input;
		return this.meService.data.id.equals(ID);
	}

	/**
	 * Check if this Member is Client Owner.
	 * @returns
	 * - boolean If the user is a client owner or not.
	 * - undefined If it is currently not possible to figure it out. E.g. because meService is not loaded.
	 */
	public get isOwner() : boolean | undefined {
		return this.userIs(AuthenticatedApiRole.CLIENT_OWNER);
	}

	/**
	 * @returns member object of Scheduling Api of currently logged in member.
	 */
	public get loggedInMember() : SchedulingApiMember | undefined {
		if (!this.api.data.aiMembers.isAvailable) return undefined;
		return this.api.data.members.get(this.meService.data.id) ?? undefined;
	}

	/**
	 * @returns Can logged in user edit given `item`?
	 */
	public userCanWrite(
		item : SchedulingApiShift | SchedulingApiShiftModel | Id,
	) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;

		const shiftModelId = this.getShiftModelId(item);

		// client-owners can always get
		if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
			return true;

		// HACK: When we create a shift we currently create a copy of the shiftmodel. When employees with write permission for a shift-model create a shift,
		// they generally would not have the permission to write the new copied shift-model. Make an exception here.
		if (item instanceof SchedulingApiShiftModel && item.isNewItem) return true;

		// any permissionGroup permits this?
		for (const permissionGroupId of this.loggedInMember.permissionGroupIds.iterable()) {
			const shiftModelPermission = this.getShiftModelPermission(permissionGroupId, shiftModelId);

			if (shiftModelPermission !== null && shiftModelPermission.canWrite) {
				return true;
			}
		}

		return false;
	}

	/**
	 * @param item The item for which the shift-model id should be returned. When `item` is of type `Id`, we assume that it is the shift-model id.
	 * @returns The shift-model id for the given `item`.
	 */
	private getShiftModelId(item : SchedulingApiShift | SchedulingApiShiftModel | Id) : Id {
		if (item instanceof SchedulingApiShift) {
			return item.shiftModelId;
		} else if (item instanceof SchedulingApiShiftModel) {
			return item.id;
		} else {
			return item;
		}
	}

	/**
	 * Check if user can read this activity-area.
	 * @param activityArea The activity-area to be checked.
	 */
	public userCanReadActivityArea(
		activityArea : SchedulingApiActivityArea,
	) : boolean {
		// Owners can read any activity area
		if (this.isOwner === true) return true;

		// TODO: PLANO-148596 Possibly needs to be removed when trashed activities are restorable / visible to the user.
		// Trashed activities should not be shown to the user.
		if (activityArea.trashed) return false;

		// If one of the activities should be shown to the user, then the activity area needs to be shown as well.
		return activityArea.relatedActivities.some(activity => this.userCanReadActivity(activity));
	}

	/**
	 * Check if user can read this shift-model or its shift. This permission does not include the bookings of the shift-model.
	 * @param activity The shift-model to be checked.
	 */
	public userCanReadActivity(activity : SchedulingApiShiftModel) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;

		// client-owners can always read
		if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
			return true;

		// Assignable members can read
		if (activity.aiAssignableMembers.isAvailable && activity.assignableMembers.contains(this.meService.data.id)) return true;

		// any permissionGroup permits this?
		for (const permissionGroupId of this.loggedInMember.permissionGroupIds.iterable()) {
			const shiftModelPermission = this.getShiftModelPermission(permissionGroupId, activity.id);

			if (shiftModelPermission !== null && shiftModelPermission.canRead) {
				return true;
			}
		}
	}

	/**
	 * @returns Can the logged in user write `shiftExchange`?
	 */
	public userCanWriteShiftExchange(shiftExchange : SchedulingApiShiftExchange) : boolean {
		return shiftExchange.indisposedMember === this.loggedInMember || this.hasManagerPermissionForShiftExchange(shiftExchange);
	}

	/**
	 * Check if user can get manager notifications for `item`.
	 * @param item The item to be checked. If `null`, it checks if the user can get manager notifications for any shift-model.
	 */
	public userCanGetManagerNotifications(item : SchedulingApiShift | SchedulingApiShiftModel | Id | null = null) : boolean | undefined {
		if (item) {
			if (this.loggedInMember === undefined) return undefined;

			// get shift-model id
			const shiftModelId = this.getShiftModelId(item);

			// client-owners can always get
			if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
				return true;

			// any permissionGroup permits this?
			for (const permissionGroupId of this.loggedInMember.permissionGroupIds.iterable()) {
				const shiftModelPermission = this.getShiftModelPermission(permissionGroupId, shiftModelId);

				if (shiftModelPermission !== null && shiftModelPermission.canGetManagerNotifications) {
					return true;
				}
			}

			return false;
		} else {
			// Check if member is getting manager notifications for any shift-model.
			for (const shiftModel of this.api.data.shiftModels.iterable()) {
				if (this.userCanGetManagerNotifications(shiftModel))
					return true;
			}

			return false;
		}
	}

	/**
	 * @param permissionGroupId The permissionGroup id which is checked.
	 * @param shiftModelId The shift-model id which is checked.
	 * @returns The shift-model permission item if it exists. Otherwise `null` is returned.
	 */
	private getShiftModelPermission(permissionGroupId : Id, shiftModelId : Id) : SchedulingApiPermissionGroupShiftModelPermission | null	{
		const permissionGroup = notNull(this.api.data.permissionGroups.get(permissionGroupId));
		const shiftModel = notNull(this.api.data.shiftModels.get(shiftModelId));
		return permissionGroup.shiftModelPermissions.getByItem(shiftModel);
	}

	/**
	 * Check if user can read the bookings of `item`.
	 * @param item The item whose bookings should be checked.
	 */
	public userCanReadBookings(item : SchedulingApiShiftModel | SchedulingApiShift) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;

		// client-owners can always read
		if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
			return true;

		// A member assigned to a shift can always read
		if (item instanceof SchedulingApiShift && item.assignedMemberIds.contains(this.meService.data.id)) return true;

		// any permissionGroup permits this?
		const shiftModelId = this.getShiftModelId(item);

		for (const permissionGroupId of this.loggedInMember.permissionGroupIds.iterable()) {
			const shiftModelPermission = this.getShiftModelPermission(permissionGroupId, shiftModelId);

			if (shiftModelPermission?.canReadBookings) {
				return true;
			}
		}
	}

	/**
	 * Check if user can write bookings for given shift-model.
	 */
	public userCanWriteBookingsOfShiftModel(shiftModel : SchedulingApiShiftModel) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;

		// client-owners can always get
		if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
			return true;

		// any permissionGroup permits this?
		for (const permissionGroupId of this.loggedInMember.permissionGroupIds.iterable()) {
			const shiftModelPermission = this.getShiftModelPermission(permissionGroupId, shiftModel.id);

			if (shiftModelPermission !== null && shiftModelPermission.canWriteBookings) {
				return true;
			}
		}

		return false;
	}

	/**
	 * @returns Can this Member write `booking`?
	 */
	public userCanWriteBooking(booking : SchedulingApiBooking) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;

		// has write permission for the shift-model of the booking?
		return this.userCanWriteBookingsOfShiftModel(booking.model);
	}

	/**
	 * Check if user can write bookings of at least one shift-model.
	 */
	public userCanWriteBookings() : boolean | undefined {
		if (!this.api.data.aiShiftModels.isAvailable) return undefined;

		// Can current member write at least one shift-model?
		for (const shiftModel of this.api.data.shiftModels.iterable()) {
			if (this.userCanWriteBookingsOfShiftModel(shiftModel))
				return true;
		}

		return false;
	}

	/**
	 * @returns Can this Member execute online-refunds?
	 */
	public userCanOnlineRefund(item : SchedulingApiBooking | SchedulingApiShiftModel | AffectedShiftsApiShifts) : boolean | undefined {
		if (item instanceof AffectedShiftsApiShifts) {
			// Can logged in member online refund all shifts?
			for (const shift of item.iterable()) {
				const shiftModel = notNull(this.api.data.shiftModels.get(shift.id.shiftModelId));

				if (!this.userCanOnlineRefund(shiftModel))
					return false;
			}

			return true;
		} else {
			if (this.loggedInMember === undefined) return undefined;
			const shiftModel = item instanceof SchedulingApiBooking ? item.model : item;

			// client-owners can always get
			if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
				return true;

			// any permissionGroup permits this?
			for (const permissionGroupId of this.loggedInMember.permissionGroupIds.iterable()) {
				const shiftModelPermission = this.getShiftModelPermission(permissionGroupId, shiftModel.id);

				if (shiftModelPermission !== null && shiftModelPermission.canOnlineRefund) {
					return true;
				}
			}

			return false;
		}
	}

	/**
	 * Check if user can read any of these shift-models?
	 */
	public userCanReadAny(items : SchedulingApiShiftModels) : boolean {
		for (const ITEM of items.iterable()) {
			if (this.userCanReadActivity(ITEM)) return true;
		}
		return false;
	}

	/**
	 * Check if user can read and write booking settings
	 */
	public get canReadAndWriteBookingSystemSettings() : boolean	| undefined {
		if (this.loggedInMember === undefined) return undefined;

		// any permissionGroup permits this?
		for (const permissionGroup of this.loggedInMember.permissionGroups.iterable()) {
			if (permissionGroup.canReadAndWriteBookingSystemSettings)
				return true;
		}

		return false;
	}

	/**
	 * @param shiftModelId Id of the shift
	 * Check if this Member has manager permission for this particular shiftModel.
	 */
	public hasManagerPermissionForShiftModel(shiftModelId : Id) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;
		return this.userCanWrite(shiftModelId) && this.userCanGetManagerNotifications(shiftModelId);
	}

	/**
	 * @returns Has the logged in user manager permission for `shiftExchange`?
	 * To avoid that nobody will have manager permission it is enough to have manager permission for one of the shift-models
	 */
	public hasManagerPermissionForShiftExchange(shiftExchange : SchedulingApiShiftExchange) : boolean {
		for (const shiftRef of shiftExchange.shiftRefs.iterable()) {
			if (this.hasManagerPermissionForShiftModel(shiftRef.id.shiftModelId))
				return true;
		}

		return false;
	}

	/**
	 * @returns Has the logged in user manager-permission for "member"? This returns true when logged in user has
	 * manager permission for at least one shift-model to which member is assignable.
	 */
	public hasManagerPermissionForMember(member : SchedulingApiMember) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;

		// client-owners have always manager permission
		if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
			return true;

		// Logged in user has manager permission any of the assignable shift-models of the member?
		for (const ASSIGNABLE_SHIFT_MODEL of member.assignableShiftModels.iterable()) {
			const SHIFT_MODEL = ASSIGNABLE_SHIFT_MODEL.shiftModel;

			if (SHIFT_MODEL.trashed) continue;

			// member might not have read-permission for the shift-model and so SHIFT_MODEL might be "null"
			if (this.hasManagerPermissionForShiftModel(SHIFT_MODEL.id))
				return true;
		}

		return false;
	}

	/**
	 * Does the logged in user have manager-permission?
	 */
	public hasManagerPermissionForAllShiftRefs(shiftRefs : SchedulingApiShiftExchangeShiftRefs) : boolean | undefined {
		if (this.loggedInMember === undefined) return undefined;

		// client-owners always have manager permission
		if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
			return true;

		// has manager permission for at least one shift-model?
		for (const SHIFT_REF of shiftRefs.iterable()) {
			if (this.hasManagerPermissionForShiftModel(SHIFT_REF.id.shiftModelId))
				return true;
		}
		return false;
	}

	private _hasManagerPermission = new Data<boolean | undefined>(this.api);

	/**
	 * @returns Is there at least one shift-model where the logged in user has manager permission?
	 */
	public get hasManagerPermission() : boolean | undefined {
		return this._hasManagerPermission.get(() => {
			if (this.api.data.aiShiftModels.isAvailable !== true || this.loggedInMember === undefined) return undefined;

			// client-owners always have manager permission
			if (this.loggedInMember.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER)
				return true;

			// any shift-model with manager permission?
			for (const SHIFT_MODEL of this.api.data.shiftModels.iterable()) {
				if (this.hasManagerPermissionForShiftModel(SHIFT_MODEL.id)) return true;
			}

			return false;
		});
	}

	/**
	 * Check if user is allowed to write assignmentProcesses
	 */
	public get userCanSetAssignmentProcesses() : boolean | undefined {
		return this.hasManagerPermission;
	}

	/**
	 * Check if user is allowed to write leaves
	 */
	public get userCanWriteLeaves() : boolean {
		if (this.meService.data.isOwner) return true;
		return false;
	}

	/**
	 * Check if user is allowed to see earnings of `member` for `shiftModelId`.
	 */
	public userCanSeeEarningsForShiftModel(shiftModelId : Id, member : SchedulingApiMember) : boolean | undefined {
		return member.id.equals(this.meService.data.id) || this.hasManagerPermissionForShiftModel(shiftModelId);
	}

	/**
	 * @param member The member whose earnings should be shown.
	 * @returns Can logged in user see earnings of "member"? This is true when logged in user is the member himself or
	 * has manager-permission for that member.
	 */
	public userCanSeeEarningsOfMember(member : SchedulingApiMember) : boolean | undefined {
		if (this.isMe(member)) return true;
		return this.hasManagerPermissionForMember(member);
	}

	/**
	 * Check if this Member can create shiftModels or write at least one shiftModel.
	 * @returns
	 * - true if the user has the permission to create a shiftModel or the permission to edit at least one of the existing ones.
	 * - false if the user has no permission to create or edit any shiftModel.
	 * - undefined If the necessary data is not available (yet) to determine the permission.
	 */
	public get userCanWriteAnyShiftModel() : boolean | undefined {
		if (this.isOwner === undefined) return undefined;
		if (this.isOwner) {
			return true;
		} else {
			if (!this.api.data.aiShiftModels.isAvailable) return undefined;
			return this.api.data.shiftModels.iterable().some(shiftModel => {
				return this.userCanWrite(shiftModel);
			});
		}
	}

	private overrideRequesterRole : AuthenticatedApiRole | null = null;

	/**
	 * Set the role that should be used for the requester.
	 * This is useful for testing purposes
	 * @param role The role that should be used for the requester
	 */
	public setOverrideRequesterRole(role : AuthenticatedApiRole | null) {
		this.overrideRequesterRole = role;
	}

	/**
	 * Does the requester match at least one of the provided roles?
	 * You can also provide a Id of a Member to check if the requester equals this id.
	 */
	public userIs(...inputArray : (AuthenticatedApiRole | Id)[]) : boolean {
		for (const INPUT of inputArray) {
			if (INPUT instanceof Id) {
				if (!this.isMe(INPUT)) continue;
				return true;
			}

			if (INPUT === (this.overrideRequesterRole ?? this.meService.data.role))
				return true;
		}
		return false;
	}
}
