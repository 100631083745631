/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { SchedulingApiCustomBookableMailEventType, SchedulingApiCustomBookableMailsBase, SchedulingApiShiftModelCourseTariffBase, SchedulingApiShiftModelCourseTariffFeesBase, SchedulingApiShiftModelCourseTariffsBase } from '@plano/shared/api';

/** @see SchedulingApiCustomBookableMailsBase */
export class SchedulingApiCustomBookableMails extends SchedulingApiCustomBookableMailsBase {

	/**
	 * get a list of
	 */
	public getByEventType( eventType : SchedulingApiCustomBookableMailEventType ) : SchedulingApiCustomBookableMails {
		const list = new SchedulingApiCustomBookableMails(this.api, null, true);
		for (const item of this.iterable()) {
			if (item.eventType === eventType) {
				list.push(item);
			}
		}
		return list;
	}
}

/** @see SchedulingApiShiftModelCourseTariffFeesBase */
export class SchedulingApiShiftModelCourseTariffFees extends SchedulingApiShiftModelCourseTariffFeesBase {
}

/** @see SchedulingApiShiftModelCourseTariffBase */
export class SchedulingApiShiftModelCourseTariff extends SchedulingApiShiftModelCourseTariffBase {
	/**
	 * Get total fee for specific booking
	 */
	public getTotalFee(participantCount : number) : number {
		if (participantCount === 0) return 0;
		let result = 0;
		for (const fee of this.fees.iterable()) {
			const times = Math.ceil(participantCount / fee.perXParticipants);
			result += (fee.fee * times);
		}
		return result;
	}
}

/** @see SchedulingApiShiftModelCourseTariffsBase */
export class SchedulingApiShiftModelCourseTariffs extends SchedulingApiShiftModelCourseTariffsBase {

	/**
	 * Does any tariff with costs exist?
	 */
	public hasVisibleCourseTariffWithCosts() : boolean {
		const ACTIVE_TARIFF = this.findBy(tariff => {
			if (tariff.trashed) return false;
			if (tariff.isInternal) return false;
			return !!tariff.fees.findBy(fee => fee.fee > 0);
		});
		return !!ACTIVE_TARIFF;
	}

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => !item.trashed);
	}
}
