// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- As this is the module that should replace all other CommonModule imports, it is ok to import CommonModule here once. TODO: PLANO-184041 Remove this
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PCardDirective } from '@plano/shared/core/p-common/card.directive';
import { DisabledDirective } from '@plano/shared/core/p-common/disabled.directive';
import { PHiddenDirective } from '@plano/shared/core/p-common/hidden.directive';
import { PMutedDirective } from '@plano/shared/core/p-common/muted.directive';
import { AnchorLinkAtCollapsibleDirective } from '@plano/shared/core/p-common/p-anchor/p-anchor-collapsible.directive';
import { AnchorIndicatorDirective } from '@plano/shared/core/p-common/p-anchor/p-anchor-indicator.directive';
import { AnchorLinkAtLabelDirective } from '@plano/shared/core/p-common/p-anchor/p-anchor-label.directive';
import { AnchorLinkAtSpanDirective } from '@plano/shared/core/p-common/p-anchor/p-anchor-span.directive';
import { AnchorLinkDirective } from '@plano/shared/core/p-common/p-anchor/p-anchor.directive';
import { TitleToAriaLabelDirective } from '@plano/shared/core/p-common/title-to-aria-label.directive';
import { ValidFragmentsDirective } from '@plano/shared/core/p-common/valid-fragments.directive';
import { ValidModalsDirective } from '@plano/shared/core/p-common/valid-modals.directive';

/**
 * A Module that can and should be used everywhere.
 * It contains only directives, which would not cause an error if not imported.
 * Example: A directive that has a css class as selector.
 */
@NgModule({
	imports: [
		AnchorIndicatorDirective,
		AnchorLinkAtCollapsibleDirective,
		AnchorLinkAtLabelDirective,
		AnchorLinkAtSpanDirective,
		AnchorLinkDirective,
		CommonModule,
		DisabledDirective,
		PCardDirective,
		PHiddenDirective,
		PMutedDirective,
		RouterModule,
		TitleToAriaLabelDirective,
		ValidFragmentsDirective,
		ValidModalsDirective,
	],
	exports: [
		AnchorIndicatorDirective,
		AnchorLinkAtCollapsibleDirective,
		AnchorLinkAtLabelDirective,
		AnchorLinkAtSpanDirective,
		AnchorLinkDirective,
		CommonModule,
		DisabledDirective,
		PCardDirective,
		PHiddenDirective,
		PMutedDirective,
		RouterModule,
		TitleToAriaLabelDirective,
		ValidFragmentsDirective,
		ValidModalsDirective,
	],
})
export class PCommonModule {}
