/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

import { SchedulingApiRoot } from '@plano/shared/api';
import { SchedulingApiShifts } from '@plano/shared/api';
import { SchedulingApiShift } from '@plano/shared/api';
import { SchedulingApiShiftAssignableMembers } from '@plano/shared/api';
import { SchedulingApiShiftRepetition } from '@plano/shared/api';
import { SchedulingApiWorkingTimes } from '@plano/shared/api';
import { SchedulingApiWorkingTime } from '@plano/shared/api';
import { SchedulingApiLeaves } from '@plano/shared/api';
import { SchedulingApiLeave } from '@plano/shared/api';
import { SchedulingApiLeaveDays } from '@plano/shared/api';
import { SchedulingApiLeaveDay } from '@plano/shared/api';
import { SchedulingApiShiftExchanges } from '@plano/shared/api';
import { SchedulingApiShiftExchange } from '@plano/shared/api';
import { SchedulingApiShiftExchangeShiftRefs } from '@plano/shared/api';
import { SchedulingApiShiftExchangeCommunications } from '@plano/shared/api';
import { SchedulingApiShiftExchangeCommunication } from '@plano/shared/api';
import { SchedulingApiShiftExchangeCommunicationSwapOffers } from '@plano/shared/api';
import { SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs } from '@plano/shared/api';
import { SchedulingApiAssignmentProcesses } from '@plano/shared/api';
import { SchedulingApiAssignmentProcess } from '@plano/shared/api';
import { SchedulingApiAssignmentProcessShiftRefs } from '@plano/shared/api';
import { SchedulingApiMembers } from '@plano/shared/api';
import { SchedulingApiMember } from '@plano/shared/api';
import { SchedulingApiAssignableShiftModels } from '@plano/shared/api';
import { SchedulingApiAssignableShiftModel } from '@plano/shared/api';
import { SchedulingApiBookings } from '@plano/shared/api';
import { SchedulingApiBooking } from '@plano/shared/api';
import { SchedulingApiBookingParticipant } from '@plano/shared/api';
import { SchedulingApiActivityAreas } from '@plano/shared/api';
import { SchedulingApiActivityArea } from '@plano/shared/api';
import { SchedulingApiShiftModels } from '@plano/shared/api';
import { SchedulingApiShiftModel } from '@plano/shared/api';
import { SchedulingApiShiftModelAssignableMembers } from '@plano/shared/api';
import { SchedulingApiShiftModelRepetition } from '@plano/shared/api';
import { SchedulingApiShiftModelCourseTariffs } from '@plano/shared/api';
import { SchedulingApiShiftModelCourseTariff } from '@plano/shared/api';
import { SchedulingApiShiftModelCourseTariffFees } from '@plano/shared/api';
import { SchedulingApiShiftModelCoursePaymentMethods } from '@plano/shared/api';
import { SchedulingApiShiftModelCoursePaymentMethod } from '@plano/shared/api';
import { SchedulingApiShiftModelCancellationPolicyFeePeriods } from '@plano/shared/api';
import { SchedulingApiShiftModelCancellationPolicyFeePeriod } from '@plano/shared/api';
import { SchedulingApiPermissionGroups } from '@plano/shared/api';
import { SchedulingApiPermissionGroup } from '@plano/shared/api';
import { SchedulingApiPermissionGroupShiftModelPermissions } from '@plano/shared/api';
import { SchedulingApiPermissionGroupShiftModelPermission } from '@plano/shared/api';
import { SchedulingApiAccountingPeriods } from '@plano/shared/api';
import { SchedulingApiAccountingPeriod } from '@plano/shared/api';
import { SchedulingApiAccountingPeriodExpectedMemberData } from '@plano/shared/api';
import { SchedulingApiMemos } from '@plano/shared/api';
import { SchedulingApiTodaysShiftDescriptions } from '@plano/shared/api';
import { SchedulingApiTodaysShiftDescription } from '@plano/shared/api';
import { SchedulingApiHolidaysHolidayItems } from '@plano/shared/api';
import { SchedulingApiHolidaysHolidayItem } from '@plano/shared/api';
import { SchedulingApiHolidaysLabels } from '@plano/shared/api';
import { SchedulingApiHolidaysLabel } from '@plano/shared/api';
import { SchedulingApiCustomBookableMails } from '@plano/shared/api';
import { SchedulingApiGiftCardSettings } from '@plano/shared/api';
import { SchedulingApiGiftCardSettingsPredefinedPrice } from '@plano/shared/api';
import { SchedulingApiGiftCards } from '@plano/shared/api';
import { SchedulingApiGiftCard } from '@plano/shared/api';
import { SchedulingApiTransactions } from '@plano/shared/api';
import { SchedulingApiTransaction } from '@plano/shared/api';
import { SchedulingApiWorkModel } from '@plano/shared/api';
import { SchedulingApiWorkModelWorkRule } from '@plano/shared/api';
import { SchedulingApiWorkModelWorkRuleVersions } from '@plano/shared/api';
import { SchedulingApiWorkModelWorkRuleVersion } from '@plano/shared/api';
import { SchedulingApiWorkModelWorkRuleVersionCheckScope } from '@plano/shared/api';
import { SchedulingApiWorkModelWorkRuleVersionCheckElement } from '@plano/shared/api';
import { SchedulingApiWorkModelWorkRuleVersionFilterList } from '@plano/shared/api';
import { SchedulingApiWorkModelWorkRuleVersionThreshold } from '@plano/shared/api';
import { SchedulingApiWarnings } from '@plano/shared/api';
import { SchedulingApiWarning } from '@plano/shared/api';
import { SchedulingApiAdyenAccount } from '@plano/shared/api';
import { SchedulingApiMailSentToBookingPerson } from '@plano/shared/api';
import { SchedulingApiShiftChangeSelector } from '@plano/shared/api';
import { SchedulingApiAutomaticBookingCancellationSettings } from '@plano/shared/api';


/**
 * This service enables access to the api "scheduling".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	SCHEDULING_API_INVALID_TIME_RANGE = 462;
	EARLY_BIRD_REACHED_NEEDED_MEMBERS_COUNT = 232;
	NOTIFICATIONS_CONFIG = 1;
	SHIFTS = 2;
	WORKING_TIMES = 3;
	LEAVES = 4;
	SHIFT_EXCHANGES = 5;
	ASSIGNMENT_PROCESSES = 6;
	MEMBERS = 7;
	BOOKINGS = 8;
	ACTIVITY_AREAS = 9;
	SHIFT_MODELS = 10;
	IS_USING_ROUTES_MANAGER = 11;
	KLETTERSZENE_ID = 12;
	POS_SYSTEM = 13;
	PERMISSION_GROUPS = 14;
	ACCOUNTING_PERIODS = 15;
	MEMOS = 16;
	TODAYS_SHIFT_DESCRIPTIONS = 17;
	HOLIDAYS = 18;
	POSSIBLE_VAT_PERCENTS = 19;
	CUSTOM_BOOKABLE_MAILS = 20;
	NOTIFICATION_SETTINGS = 21;
	GIFT_CARD_SETTINGS = 22;
	GIFT_CARDS = 23;
	TRANSACTIONS = 24;
	WORK_MODELS = 25;
	WARNINGS = 26;
	EVALUATION = 27;
	BOOKING_PAGE_COVER = 28;
	COMPANY_LOGO = 29;
	ADYEN_ACCOUNT = 30;
	IS_ONLINE_PAYMENT_AVAILABLE = 31;
	RE_CAPTCHA_ENABLED_IN_BOOKING_PROCESS = 32;
	RE_CAPTCHA_WHITE_LISTED_HOST_NAMES = 33;
	MAILS_SENT_TO_BOOKING_PERSON = 34;
	MESSAGES = 35;
	SHIFT_CHANGE_SELECTOR = 36;
	AUTOMATIC_BOOKING_CANCELLATION_SETTINGS = 37;
	NOTIFICATIONS_CONFIG_SEND = 1;
	NOTIFICATIONS_CONFIG_COMMENT = 2;
	SHIFT_MY_PREF = 1;
	SHIFT_ASSIGNABLE_MEMBERS = 2;
	SHIFT_ASSIGNED_MEMBER_IDS = 3;
	SHIFT_START = 4;
	SHIFT_END = 5;
	SHIFT_NEEDED_MEMBERS_COUNT = 6;
	SHIFT_MODEL_ID = 7;
	SHIFT_MEMBER_PREFS = 8;
	SHIFT_PACKET_SHIFTS = 9;
	SHIFT_DESCRIPTION = 10;
	SHIFT_IS_REMOVED = 11;
	SHIFT_IS_COURSE_CANCELED = 12;
	SHIFT_IS_COURSE_ONLINE = 13;
	SHIFT_MIN_COURSE_PARTICIPANT_COUNT = 14;
	SHIFT_MAX_COURSE_PARTICIPANT_COUNT = 15;
	SHIFT_CURRENT_COURSE_PARTICIPANT_COUNT = 16;
	SHIFT_EARLY_BIRD_ASSIGN_TO_ME = 17;
	SHIFT_NEEDED_MEMBERS_COUNT_CONF = 18;
	SHIFT_TIME = 19;
	SHIFT_WORKING_TIME_CREATION_METHOD = 20;
	SHIFT_REPETITION = 21;
	SHIFT_SERIES_START = 22;
	SHIFT_CURRENT_COURSE_ATTENDEE_COUNT = 23;
	SHIFT_ASSIGNABLE_MEMBER_EARNINGS = 1;
	SHIFT_ASSIGNABLE_MEMBER_ID = 2;
	SHIFT_PACKET_SHIFT_START = 1;
	SHIFT_PACKET_SHIFT_END = 2;
	SHIFT_PACKET_SHIFT_ASSIGNED_MEMBER_IDS = 3;
	SHIFT_MEMBER_PREF_MEMBER_ID = 1;
	SHIFT_MEMBER_PREF_VALUE = 2;
	SHIFT_TIME_START = 1;
	SHIFT_TIME_END = 2;
	SHIFT_NEEDED_MEMBERS_COUNT_CONF_NEEDED_MEMBERS_COUNT = 1;
	SHIFT_NEEDED_MEMBERS_COUNT_CONF_PER_X_PARTICIPANTS = 2;
	SHIFT_NEEDED_MEMBERS_COUNT_CONF_IS_ZERO_NOT_REACHED_MIN_PARTICIPANTS_COUNT = 3;
	SHIFT_CHANGE_SELECTOR_SHIFT_MODEL_ID = 1;
	SHIFT_CHANGE_SELECTOR_SHIFTS_OF_SHIFT_MODEL_ID = 2;
	SHIFT_CHANGE_SELECTOR_SHIFTS_OF_SHIFT_MODEL_VERSION = 3;
	SHIFT_CHANGE_SELECTOR_SHIFTS_OF_SERIES_ID = 4;
	SHIFT_CHANGE_SELECTOR_SHIFTS_OF_PACKET_INDEX = 5;
	SHIFT_CHANGE_SELECTOR_ADD_CHANGE_SELECTORS = 6;
	SHIFT_CHANGE_SELECTOR_START = 7;
	SHIFT_CHANGE_SELECTOR_END = 8;
	SHIFT_REPETITION_TYPE = 1;
	SHIFT_REPETITION_X = 2;
	SHIFT_REPETITION_REPETITION_END_MODE = 3;
	SHIFT_REPETITION_ENDS_AFTER_REPETITION_COUNT = 4;
	SHIFT_REPETITION_ENDS_AFTER_DATE = 5;
	SHIFT_REPETITION_PACKET = 6;
	SHIFT_REPETITION_ON_MONDAY = 7;
	SHIFT_REPETITION_ON_TUESDAY = 8;
	SHIFT_REPETITION_ON_WEDNESDAY = 9;
	SHIFT_REPETITION_ON_THURSDAY = 10;
	SHIFT_REPETITION_ON_FRIDAY = 11;
	SHIFT_REPETITION_ON_SATURDAY = 12;
	SHIFT_REPETITION_ON_SUNDAY = 13;
	SHIFT_REPETITION_PACKET_TYPE = 1;
	SHIFT_REPETITION_PACKET_X = 2;
	SHIFT_REPETITION_PACKET_ENDS_AFTER_REPETITION_COUNT = 3;
	SHIFT_REPETITION_PACKET_ON_MONDAY = 4;
	SHIFT_REPETITION_PACKET_ON_TUESDAY = 5;
	SHIFT_REPETITION_PACKET_ON_WEDNESDAY = 6;
	SHIFT_REPETITION_PACKET_ON_THURSDAY = 7;
	SHIFT_REPETITION_PACKET_ON_FRIDAY = 8;
	SHIFT_REPETITION_PACKET_ON_SATURDAY = 9;
	SHIFT_REPETITION_PACKET_ON_SUNDAY = 10;
	WORKING_TIME_COMMENT = 1;
	WORKING_TIME_TIME = 2;
	WORKING_TIME_REGULAR_PAUSE_DURATION = 3;
	WORKING_TIME_MEMBER_ID = 4;
	WORKING_TIME_AUTOMATIC_PAUSE_DURATION = 5;
	WORKING_TIME_PLANNED_START = 6;
	WORKING_TIME_PLANNED_END = 7;
	WORKING_TIME_WHEN_MEMBER_STAMPED_START = 8;
	WORKING_TIME_WHEN_MEMBER_STAMPED_END = 9;
	WORKING_TIME_HOURLY_EARNINGS = 10;
	WORKING_TIME_WARN_UNPLANNED_WORK = 11;
	WORKING_TIME_WARN_STAMPED_NOT_CURRENT_TIME = 12;
	WORKING_TIME_WARN_STAMPED_NOT_SHIFT_TIME = 13;
	WORKING_TIME_SHIFT_MODEL_ID = 14;
	WORKING_TIME_TIME_START = 1;
	WORKING_TIME_TIME_END = 2;
	LEAVE_INTERNAL_NOTES = 1;
	LEAVE_MEMBER_ID = 2;
	LEAVE_DAYS = 3;
	LEAVE_VISIBLE_TO = 4;
	LEAVE_DATE_OF_CREATION = 5;
	LEAVE_DAY_DAY_START = 1;
	LEAVE_DAY_TYPE = 2;
	LEAVE_DAY_DURATION_TYPE = 3;
	LEAVE_DAY_DURATION = 4;
	LEAVE_DAY_START_TIME = 5;
	LEAVE_DAY_END_TIME = 6;
	LEAVE_DAY_PAYMENT_TYPE = 7;
	LEAVE_DAY_HOURLY_WAGE = 8;
	ASSIGNMENT_PROCESS_STATE = 1;
	ASSIGNMENT_PROCESS_DEADLINE = 2;
	ASSIGNMENT_PROCESS_NAME = 3;
	ASSIGNMENT_PROCESS_SHIFT_REFS = 4;
	ASSIGNMENT_PROCESS_ONLY_ASK_PREFS_FOR_UNASSIGNED_SHIFTS = 5;
	ASSIGNMENT_PROCESS_ASSIGN_MEMBERS_WHEN_NO_PREF_AVAILABLE = 6;
	ASSIGNMENT_PROCESS_REMOVE_PROCESS_WHEN_EARLY_BIRD_ASSIGNED_ALL_SHIFTS = 7;
	ASSIGNMENT_PROCESS_ASSIGNMENT_STATE = 8;
	ASSIGNMENT_PROCESS_MISSING_ASSIGNMENTS_COUNT = 9;
	ASSIGNMENT_PROCESS_TODO_SHIFTS_COUNT_CURRENT_VIEW = 10;
	ASSIGNMENT_PROCESS_TODO_SHIFTS_COUNT_RIGHT_VIEW = 11;
	ASSIGNMENT_PROCESS_TODO_SHIFTS_COUNT_LEFT_VIEW = 12;
	ASSIGNMENT_PROCESS_FIRST_DAY_START_WITH_TODO = 13;
	ASSIGNMENT_PROCESS_TYPE = 14;
	ASSIGNMENT_PROCESS_MISSING_PREFS_MEMBER_IDS = 15;
	ASSIGNMENT_PROCESS_CAN_EDIT = 16;
	ASSIGNMENT_PROCESS_SHIFT_REF_REQUESTER_CAN_SET_PREF = 1;
	ASSIGNMENT_PROCESS_SHIFT_REF_REQUESTER_CAN_DO_EARLY_BIRD = 2;
	MEMBER_FIRST_NAME = 1;
	MEMBER_LAST_NAME = 2;
	MEMBER_EMAIL = 3;
	MEMBER_TRASHED = 4;
	MEMBER_MIN_MONTHLY_EARNINGS = 5;
	MEMBER_MAX_MONTHLY_EARNINGS = 6;
	MEMBER_DESIRED_MONTHLY_EARNINGS = 7;
	MEMBER_PERMISSION_GROUP_IDS = 8;
	MEMBER_AVG_DAYS_PER_WEEK = 9;
	MEMBER_AVG_HOURS_PER_DAY = 10;
	MEMBER_ASSIGNABLE_SHIFT_MODELS = 11;
	MEMBER_BIRTHDAY = 12;
	MEMBER_AVATAR = 13;
	MEMBER_PHONE = 14;
	MEMBER_GENDER = 15;
	MEMBER_PASSWORD = 16;
	MEMBER_ADDRESS_STREET = 17;
	MEMBER_ADDRESS_POSTAL_CODE = 18;
	MEMBER_ADDRESS_CITY = 19;
	MEMBER_EMPLOYMENT_BEGIN = 20;
	MEMBER_EMPLOYMENT_END = 21;
	MEMBER_COMMENTS = 22;
	MEMBER_PERSONNEL_NUMBERS = 23;
	MEMBER_PLACE_OF_BIRTH = 24;
	MEMBER_SOCIAL_SECURITY_NUMBER = 25;
	MEMBER_TAX_ID = 26;
	MEMBER_HEALTH_INSURANCE = 27;
	MEMBER_NATIONALITY = 28;
	MEMBER_DENOMINATION = 29;
	MEMBER_ACCOUNT_IBAN = 30;
	MEMBER_ACCOUNT_OWNER = 31;
	MEMBER_MAIN_JOB = 32;
	MEMBER_CHANGE_SELECTOR = 33;
	MEMBER_ASSIGNABLE_SHIFT_MODEL_EARNINGS = 1;
	MEMBER_ASSIGNABLE_SHIFT_MODEL_ID = 2;
	MEMBER_CHANGE_SELECTOR_START = 1;
	SHIFT_EXCHANGE_TODO_COUNT = 1;
	SHIFT_EXCHANGE_STATE = 2;
	SHIFT_EXCHANGE_COMMUNICATION_INFO = 3;
	SHIFT_EXCHANGE_LAST_UPDATE = 4;
	SHIFT_EXCHANGE_REQUESTER_RELATIONSHIP = 5;
	SHIFT_EXCHANGE_IS_ILLNESS = 6;
	SHIFT_EXCHANGE_SHIFT_REFS = 7;
	SHIFT_EXCHANGE_SWAPPED_SHIFT_REFS = 8;
	SHIFT_EXCHANGE_INDISPOSED_MEMBER_ID = 9;
	SHIFT_EXCHANGE_NEW_ASSIGNED_MEMBER_ID = 10;
	SHIFT_EXCHANGE_INDISPOSED_MEMBER_PREFERS_SWAPPING = 11;
	SHIFT_EXCHANGE_DEADLINE = 12;
	SHIFT_EXCHANGE_MEMBER_ID_ADDRESSED_TO = 13;
	SHIFT_EXCHANGE_ILLNESS_RESPONDER_COMMENT_TO_MEMBERS = 14;
	SHIFT_EXCHANGE_INDISPOSED_MEMBER_COMMENT = 15;
	SHIFT_EXCHANGE_SHOW_ON_DESK = 16;
	SHIFT_EXCHANGE_SHOW_IN_LIST = 17;
	SHIFT_EXCHANGE_LEAVE_ID = 18;
	SHIFT_EXCHANGE_COMMUNICATIONS = 19;
	SHIFT_EXCHANGE_CLOSE_SHIFT_EXCHANGE = 20;
	SHIFT_EXCHANGE_OPEN_SHIFT_EXCHANGE = 21;
	SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFERS = 1;
	SHIFT_EXCHANGE_COMMUNICATION_INDISPOSED_MEMBERS_SELECTED_SO_ID = 2;
	SHIFT_EXCHANGE_COMMUNICATION_PERFORM_ACTION = 3;
	SHIFT_EXCHANGE_COMMUNICATION_PERFORM_ACTION_COMMENT = 4;
	SHIFT_EXCHANGE_COMMUNICATION_COMMUNICATION_STATE = 5;
	SHIFT_EXCHANGE_COMMUNICATION_COMMUNICATION_PARTNER_ID = 6;
	SHIFT_EXCHANGE_COMMUNICATION_LAST_ACTION = 7;
	SHIFT_EXCHANGE_COMMUNICATION_LAST_ACTION_TIME = 8;
	SHIFT_EXCHANGE_COMMUNICATION_LAST_ACTION_COMMENT = 9;
	SHIFT_EXCHANGE_COMMUNICATION_IS_ILLNESS_REVIEW_COMMUNICATION = 10;
	SHIFT_EXCHANGE_COMMUNICATION_REQUESTER_ROLE = 11;
	SHIFT_EXCHANGE_COMMUNICATION_HAS_TODO = 12;
	SHIFT_EXCHANGE_SHIFT_REF_START = 1;
	SHIFT_EXCHANGE_SHIFT_REF_END = 2;
	SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFER_SHIFT_REFS = 1;
	ACTIVITY_AREA_NAME = 1;
	ACTIVITY_AREA_EMOJI = 2;
	ACTIVITY_AREA_TRASHED = 3;
	SHIFT_MODEL_COLOR = 1;
	SHIFT_MODEL_NAME = 2;
	SHIFT_MODEL_ACTIVITY_AREA_ID = 3;
	SHIFT_MODEL_TRASHED = 4;
	SHIFT_MODEL_ASSIGNABLE_MEMBERS = 5;
	SHIFT_MODEL_REPETITION = 6;
	SHIFT_MODEL_TIME = 7;
	SHIFT_MODEL_COST_CENTRE = 8;
	SHIFT_MODEL_ARTICLE_GROUP = 9;
	SHIFT_MODEL_POS_ACCOUNTS = 10;
	SHIFT_MODEL_IS_COURSE = 11;
	SHIFT_MODEL_COURSE_TYPE = 12;
	SHIFT_MODEL_CHANGE_SELECTOR = 13;
	SHIFT_MODEL_AUTOMATIC_BOOKABLE_MAIL_IDS = 14;
	SHIFT_MODEL_ONLY_WHOLE_COURSE_BOOKABLE = 15;
	SHIFT_MODEL_COURSE_TARIFFS = 16;
	SHIFT_MODEL_COURSE_PAYMENT_METHODS = 17;
	SHIFT_MODEL_ONLINE_CANCELLATION_FOR_CHARGEABLE_BOOKINGS_ENABLED = 18;
	SHIFT_MODEL_CURRENT_CANCELLATION_POLICY_ID = 19;
	SHIFT_MODEL_CANCELLATION_POLICIES = 20;
	SHIFT_MODEL_IS_COURSE_ONLINE = 21;
	SHIFT_MODEL_BOOKING_DESIRED_DATE_SETTING = 22;
	SHIFT_MODEL_COURSE_CODE_PREFIX = 23;
	SHIFT_MODEL_COURSE_GROUP = 24;
	SHIFT_MODEL_FREECLIMBER_ARTICLE_ID = 25;
	SHIFT_MODEL_BOOKING_PERSON_MIN_AGE = 26;
	SHIFT_MODEL_PARTICIPANT_MIN_AGE = 27;
	SHIFT_MODEL_PARTICIPANT_MAX_AGE = 28;
	SHIFT_MODEL_COURSE_TITLE = 29;
	SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF = 30;
	SHIFT_MODEL_DESCRIPTION = 31;
	SHIFT_MODEL_ASSIGNED_MEMBER_IDS = 32;
	SHIFT_MODEL_WORKING_TIME_CREATION_METHOD = 33;
	SHIFT_MODEL_COURSE_SUBTITLE = 34;
	SHIFT_MODEL_COURSE_DESCRIPTION = 35;
	SHIFT_MODEL_COURSE_HIGHLIGHTS = 36;
	SHIFT_MODEL_COURSE_EQUIPMENT_REQUIREMENTS = 37;
	SHIFT_MODEL_COURSE_SKILL_REQUIREMENTS = 38;
	SHIFT_MODEL_COURSE_LOCATION = 39;
	SHIFT_MODEL_COURSE_CONTACT_NAME = 40;
	SHIFT_MODEL_COURSE_CONTACT_EMAIL = 41;
	SHIFT_MODEL_COURSE_CONTACT_PHONE = 42;
	SHIFT_MODEL_ARRIVAL_TIME_BEFORE_COURSE = 43;
	SHIFT_MODEL_COURSE_BOOKING_DEADLINE_FROM = 44;
	SHIFT_MODEL_COURSE_BOOKING_DEADLINE_UNTIL = 45;
	SHIFT_MODEL_MIN_COURSE_PARTICIPANT_COUNT = 46;
	SHIFT_MODEL_MAX_COURSE_PARTICIPANT_COUNT = 47;
	SHIFT_MODEL_USE_BOOKING_DEADLINES_FOR_SLOTS = 48;
	SHIFT_MODEL_ONLINE_CANCELLATION_FOR_FREE_BOOKINGS_ENABLED = 49;
	SHIFT_MODEL_ONLINE_CANCELLATION_FOR_FREE_BOOKINGS_DEADLINE = 50;
	SHIFT_MODEL_ONLINE_CANCELLATION_FOR_CHARGEABLE_BOOKINGS_DEADLINE = 51;
	SHIFT_MODEL_ONLINE_CANCELLATION_FOR_WITHDRAWABLE_BOOKINGS_ALWAYS_ENABLED = 52;
	SHIFT_MODEL_ONLINE_CANCELLATION_AUTOMATIC_ONLINE_REFUND_ENABLED = 53;
	SHIFT_MODEL_ONLINE_CANCELLATION_AUTOMATIC_GIFT_CARD_REFUND_ENABLED = 54;
	SHIFT_MODEL_SHOW_ASSIGNED_MEMBERS_IN_BOOKING_PLUGIN = 55;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS = 56;
	SHIFT_MODEL_TIME_START = 1;
	SHIFT_MODEL_TIME_END = 2;
	SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF_NEEDED_MEMBERS_COUNT = 1;
	SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF_PER_X_PARTICIPANTS = 2;
	SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF_IS_ZERO_NOT_REACHED_MIN_PARTICIPANTS_COUNT = 3;
	SHIFT_MODEL_ASSIGNABLE_MEMBER_EARNINGS = 1;
	SHIFT_MODEL_ASSIGNABLE_MEMBER_ID = 2;
	SHIFT_MODEL_CHANGE_SELECTOR_START = 1;
	SHIFT_MODEL_REPETITION_TYPE = 1;
	SHIFT_MODEL_REPETITION_X = 2;
	SHIFT_MODEL_REPETITION_REPETITION_END_MODE = 3;
	SHIFT_MODEL_REPETITION_ENDS_AFTER_REPETITION_COUNT = 4;
	SHIFT_MODEL_REPETITION_ENDS_AFTER_DATE = 5;
	SHIFT_MODEL_REPETITION_PACKET = 6;
	SHIFT_MODEL_REPETITION_ON_MONDAY = 7;
	SHIFT_MODEL_REPETITION_ON_TUESDAY = 8;
	SHIFT_MODEL_REPETITION_ON_WEDNESDAY = 9;
	SHIFT_MODEL_REPETITION_ON_THURSDAY = 10;
	SHIFT_MODEL_REPETITION_ON_FRIDAY = 11;
	SHIFT_MODEL_REPETITION_ON_SATURDAY = 12;
	SHIFT_MODEL_REPETITION_ON_SUNDAY = 13;
	SHIFT_MODEL_REPETITION_PACKET_TYPE = 1;
	SHIFT_MODEL_REPETITION_PACKET_X = 2;
	SHIFT_MODEL_REPETITION_PACKET_ENDS_AFTER_REPETITION_COUNT = 3;
	SHIFT_MODEL_REPETITION_PACKET_ON_MONDAY = 4;
	SHIFT_MODEL_REPETITION_PACKET_ON_TUESDAY = 5;
	SHIFT_MODEL_REPETITION_PACKET_ON_WEDNESDAY = 6;
	SHIFT_MODEL_REPETITION_PACKET_ON_THURSDAY = 7;
	SHIFT_MODEL_REPETITION_PACKET_ON_FRIDAY = 8;
	SHIFT_MODEL_REPETITION_PACKET_ON_SATURDAY = 9;
	SHIFT_MODEL_REPETITION_PACKET_ON_SUNDAY = 10;
	SHIFT_MODEL_POS_ACCOUNT_VAT_PERCENT = 1;
	SHIFT_MODEL_POS_ACCOUNT_NAME = 2;
	SHIFT_MODEL_COURSE_HIGHLIGHT_TEXT = 1;
	SHIFT_MODEL_COURSE_TARIFF_NAME = 1;
	SHIFT_MODEL_COURSE_TARIFF_DESCRIPTION = 2;
	SHIFT_MODEL_COURSE_TARIFF_IS_INTERNAL = 3;
	SHIFT_MODEL_COURSE_TARIFF_FEES = 4;
	SHIFT_MODEL_COURSE_TARIFF_HAS_ADDITIONAL_FIELD = 5;
	SHIFT_MODEL_COURSE_TARIFF_ADDITIONAL_FIELD_LABEL = 6;
	SHIFT_MODEL_COURSE_TARIFF_FOR_COURSE_DATES_FROM = 7;
	SHIFT_MODEL_COURSE_TARIFF_FOR_COURSE_DATES_UNTIL = 8;
	SHIFT_MODEL_COURSE_TARIFF_NEGATE_FOR_COURSE_DATES_INTERVAL = 9;
	SHIFT_MODEL_COURSE_TARIFF_TRASHED = 10;
	SHIFT_MODEL_COURSE_TARIFF_APPLY_TO_BOOKING = 11;
	SHIFT_MODEL_COURSE_TARIFF_APPLY_TO_PARTICIPANT = 12;
	SHIFT_MODEL_COURSE_TARIFF_FEE_NAME = 1;
	SHIFT_MODEL_COURSE_TARIFF_FEE_FEE = 2;
	SHIFT_MODEL_COURSE_TARIFF_FEE_PER_X_PARTICIPANTS = 3;
	SHIFT_MODEL_COURSE_TARIFF_FEE_VAT_PERCENT = 4;
	ONLINE_PAYMENT = 1;
	MISC = 2;
	SHIFT_MODEL_COURSE_PAYMENT_METHOD_TYPE = 1;
	SHIFT_MODEL_COURSE_PAYMENT_METHOD_NAME = 2;
	SHIFT_MODEL_COURSE_PAYMENT_METHOD_DESCRIPTION = 3;
	SHIFT_MODEL_COURSE_PAYMENT_METHOD_IS_INTERNAL = 4;
	SHIFT_MODEL_COURSE_PAYMENT_METHOD_TRASHED = 5;
	SHIFT_MODEL_COURSE_PAYMENT_METHOD_APPLY_TO_BOOKING = 6;
	SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIODS = 1;
	SHIFT_MODEL_CANCELLATION_POLICY_WITHDRAWAL_ENABLED = 2;
	SHIFT_MODEL_CANCELLATION_POLICY_WITHDRAWAL_PERIOD = 3;
	SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_FEE_FIX = 1;
	SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_FEE_PERCENTAGE = 2;
	SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_START = 3;
	SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_END = 4;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_ACTIVATED = 1;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_ACTIVATED_BY_MEMBER_ID = 2;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_ACTIVATED_DATE_TIME = 3;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_INITIAL_BALANCE = 4;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_MIN_SHOPPING_CARD_VALUE = 5;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_EXPIRATION_DURATION = 6;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_RECIPIENT = 7;
	SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_BOOKED_EMAIL_CUSTOM_MESSAGE = 8;
	PERMISSION_GROUP_NAME = 1;
	CLIENT_DEFAULT = 1;
	CLIENT_OWNER = 2;
	PERMISSION_GROUP_ROLE = 2;
	PERMISSION_GROUP_CAN_READ_AND_WRITE_BOOKING_SYSTEM_SETTINGS = 3;
	PERMISSION_GROUP_DESCRIPTION = 4;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSIONS = 5;
	PERMISSION_GROUP_DATE_OF_CREATION = 6;
	PERMISSION_GROUP_CREATOR_ID = 7;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_SHIFT_MODEL_ID = 1;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_ACTIVITY_AREA_ID = 2;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_READ = 3;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_READ_BOOKINGS = 4;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_WRITE_BOOKINGS = 5;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_WRITE = 6;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_ONLINE_REFUND = 7;
	PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_GET_MANAGER_NOTIFICATIONS = 8;
	ACCOUNTING_PERIOD_START = 1;
	ACCOUNTING_PERIOD_END = 2;
	ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA = 3;
	ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA_ITEM_EARNINGS = 1;
	ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA_ITEM_WORKING_HOURS = 2;
	MEMO_MESSAGE = 1;
	MEMO_START = 2;
	MEMO_END = 3;
	TODAYS_SHIFT_DESCRIPTION_DESCRIPTION = 1;
	TODAYS_SHIFT_DESCRIPTION_SHIFT_START = 2;
	TODAYS_SHIFT_DESCRIPTION_SHIFT_END = 3;
	TODAYS_SHIFT_DESCRIPTION_ASSIGNED_MEMBER_IDS = 4;
	BOOKING_STATE = 1;
	BOOKING_INTERNAL_NOTES = 2;
	BOOKING_BOOKING_NUMBER = 3;
	BOOKING_COURSE_SELECTOR = 4;
	BOOKING_SHIFT_MODEL_ID = 5;
	BOOKING_DATE_OF_BOOKING = 6;
	BOOKING_BOOKING_COMMENT = 7;
	BOOKING_ALL_SHIFTS_REMOVED = 8;
	BOOKING_FIRST_NAME = 9;
	BOOKING_LAST_NAME = 10;
	BOOKING_FIRST_SHIFT_START = 11;
	BOOKING_PARTICIPANT_COUNT = 12;
	BOOKING_CURRENTLY_PAID = 13;
	BOOKING_CANCELLATION_FEE = 14;
	BOOKING_PARTICIPANTS = 15;
	BOOKING_OVERALL_TARIFF_ID = 16;
	BOOKING_FIRST_SHIFT_END = 17;
	BOOKING_FIRST_SHIFT_SELECTOR = 18;
	BOOKING_ATTENDED = 19;
	BOOKING_CAN_WRITE_ATTENDED = 20;
	BOOKING_ATTENDED_SET_BY_POS = 21;
	BOOKING_DATE_OF_BIRTH = 22;
	BOOKING_STREET_AND_HOUSE_NUMBER = 23;
	BOOKING_CITY = 24;
	BOOKING_POSTAL_CODE = 25;
	BOOKING_EMAIL = 26;
	BOOKING_PHONE_MOBILE = 27;
	BOOKING_PHONE_LANDLINE = 28;
	BOOKING_PAYMENT_METHOD_ID = 29;
	BOOKING_DESIRED_DATE = 30;
	BOOKING_WANTS_NEWSLETTER = 31;
	BOOKING_PAID_BEFORE_TRANSACTION_LIST_INTRODUCTION = 32;
	BOOKING_COMPANY = 33;
	BOOKING_ADDITIONAL_FIELD_VALUE = 34;
	BOOKING_AGE_MIN = 35;
	BOOKING_AGE_MAX = 36;
	BOOKING_CANCELLATION_POLICY_ID = 37;
	BOOKING_HAS_RIGHT_OF_WITHDRAWAL = 38;
	BOOKING_APPLICABLE_CANCELLATION_FEE_PERIOD_ID = 39;
	BOOKING_ACCOUNT_REFUND_LIMIT_DUE_TO_ONLINE_BALANCE = 40;
	BOOKING_IS_ANONYMIZED = 41;
	BOOKING_COURSE_TYPE = 42;
	BOOKING_BOOKING_DESIRED_DATE_SETTING = 43;
	BOOKING_CREATED_BY = 44;
	BOOKING_TESTING_DEFERRED_PAYMENT_TOKEN = 45;
	BOOKING_PARTICIPANT_ATTENDED = 1;
	BOOKING_PARTICIPANT_IS_BOOKING_PERSON = 2;
	BOOKING_PARTICIPANT_FIRST_NAME = 3;
	BOOKING_PARTICIPANT_LAST_NAME = 4;
	BOOKING_PARTICIPANT_EMAIL = 5;
	BOOKING_PARTICIPANT_DATE_OF_BIRTH = 6;
	BOOKING_PARTICIPANT_TARIFF_ID = 7;
	BOOKING_PARTICIPANT_ADDITIONAL_FIELD_VALUE = 8;
	CUSTOM_BOOKABLE_MAIL_NAME = 1;
	CUSTOM_BOOKABLE_MAIL_EVENT_TYPE = 2;
	CUSTOM_BOOKABLE_MAIL_SEND_TO_BOOKING_PERSON = 3;
	CUSTOM_BOOKABLE_MAIL_SEND_TO_PARTICIPANTS = 4;
	CUSTOM_BOOKABLE_MAIL_SUBJECT_TEMPLATE = 5;
	CUSTOM_BOOKABLE_MAIL_TEXT_TEMPLATE = 6;
	CUSTOM_BOOKABLE_MAIL_REPLY_TO = 7;
	NOTIFICATION_SETTINGS_PUSH_TOKENS = 1;
	NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPES = 2;
	NOTIFICATION_SETTINGS_PUSH_TOKEN_TOKEN = 1;
	NOTIFICATION_SETTINGS_PUSH_TOKEN_TYPE = 2;
	NOTIFICATION_SETTINGS_PUSH_TOKEN_IS_DISABLED = 3;
	NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUPS = 1;
	NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_DEVICE_TYPE = 2;
	NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_ENABLED = 1;
	NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_IS_RECOMMENDED_TO_RECEIVE = 2;
	NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_GROUP = 3;
	NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_TITLE = 4;
	GIFT_CARD_SETTINGS_IS_SALE_ENABLED = 1;
	GIFT_CARD_SETTINGS_WAYS_TO_REDEEM = 2;
	GIFT_CARD_SETTINGS_FREE_PRICE_CHOICE = 3;
	GIFT_CARD_SETTINGS_MIN_PRICE = 4;
	GIFT_CARD_SETTINGS_MAX_PRICE = 5;
	GIFT_CARD_SETTINGS_PREDEFINED_PRICES = 6;
	GIFT_CARD_SETTINGS_HOMEPAGE_DESCRIPTION_TEXT = 7;
	GIFT_CARD_SETTINGS_EXPIRATION_DURATION = 8;
	GIFT_CARD_SETTINGS_CONTACT_NAME = 9;
	GIFT_CARD_SETTINGS_CONTACT_EMAIL = 10;
	GIFT_CARD_SETTINGS_CONTACT_PHONE = 11;
	GIFT_CARD_SETTINGS_CODE_PREFIX = 12;
	GIFT_CARD_SETTINGS_CODE_LENGTH = 13;
	GIFT_CARD_SETTINGS_CODE_ONLY_CONTAINS_DIGITS = 14;
	GIFT_CARD_SETTINGS_CUSTOM_TEMPLATE = 15;
	GIFT_CARD_SETTINGS_FONT_COLOR = 16;
	GIFT_CARD_SETTINGS_BACKGROUND_COLOR = 17;
	GIFT_CARD_SETTINGS_VAT_PERCENT = 18;
	GIFT_CARD_SETTINGS_POS_ACCOUNT = 19;
	GIFT_CARD_SETTINGS_REDEEMABLE_SINGLE_PURPOSE_GIFT_CARD_COUNT = 20;
	GIFT_CARD_SETTINGS_PROHIBIT_REDEMPTION_FOR_OTHER_VAT_PERCENT = 21;
	GIFT_CARD_SETTINGS_GIFT_CARDS_SOLD_THROUGH_POS_BEFORE_DATE_ARE_UNSAFE = 22;
	GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_PERMITTED_DATE_TIME = 23;
	GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_PERMITTED_BY_MEMBER_ID = 24;
	GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_PERMITTED = 25;
	GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_ACTIVATED_DATE_TIME = 26;
	GIFT_CARD_SETTINGS_HAS_GIFT_CARDS = 27;
	GIFT_CARD_SETTINGS_PREDEFINED_PRICE_PRICE = 1;
	GIFT_CARD_SETTINGS_PREDEFINED_PRICE_DESCRIPTION = 2;
	TRANSACTION_TYPE = 1;
	TRANSACTION_DATE_TIME = 2;
	TRANSACTION_PAYMENT_METHOD_TYPE = 3;
	TRANSACTION_MISC_PAYMENT_METHOD_NAME = 4;
	TRANSACTION_DR_PLANO_FEE_NET = 5;
	TRANSACTION_BALANCE_CHANGE = 6;
	TRANSACTION_OFFER_NAME = 7;
	TRANSACTION_BOOKING_NUMBER = 8;
	TRANSACTION_BOOKING_PERSON_FIRST_NAME = 9;
	TRANSACTION_BOOKING_PERSON_LAST_NAME = 10;
	TRANSACTION_BOOKING_ID = 11;
	TRANSACTION_CREATED_BY = 12;
	TRANSACTION_CREATOR_ID = 13;
	TRANSACTION_GIFT_CARD_ID = 14;
	TRANSACTION_SHIFT_MODEL_ID = 15;
	TRANSACTION_FAILED_CHILD_ID = 16;
	TRANSACTION_ORIGINAL_PSP_REFERENCE = 17;
	TRANSACTION_TESTING_PSP_REFERENCE = 18;
	TRANSACTION_TESTING_TRANSFER_FUNDS_AMOUNT = 19;
	TRANSACTION_TESTING_TRANSFER_FUNDS_PSP = 20;
	TRANSACTION_TESTING_TRANSFER_FUNDS_STATE = 21;
	TRANSACTION_TESTING_IS_SETTLED = 22;
	TRANSACTION_ABS_AMOUNT = 23;
	TRANSACTION_CHILD_CHARGEBACK_ID = 24;
	TRANSACTION_CHILD_CHARGEBACK_REVERSED_ID = 25;
	TRANSACTION_CHILD_SECOND_CHARGEBACK_ID = 26;
	TRANSACTION_VAT_PERCENT = 27;
	TRANSACTION_STATUS = 28;
	TRANSACTION_INTERNAL_NOTES = 29;
	TRANSACTION_BOOKABLE_ID = 30;
	TRANSACTION_REDEEM_CHILD_PAYMENT_ID = 31;
	TRANSACTION_BALANCE = 32;
	TRANSACTION_BANK_ACCOUNT_HINT = 33;
	TRANSACTION_BANK_DESCRIPTION = 34;
	TRANSACTION_PARENT_ID = 35;
	TRANSACTION_POS_ACCOUNTS = 36;
	TRANSACTION_POS_BON_ID = 37;
	TRANSACTION_FAILED_REASON = 38;
	TRANSACTION_REFUND_GIFT_CARD_INTERNAL_NOTES = 39;
	TRANSACTION_DR_PLANO_FEE_VAT_DEPRECATED = 40;
	WORK_MODEL_NAME = 1;
	WORK_MODEL_DESCRIPTION = 2;
	WORK_MODEL_WORK_RULES = 3;
	WORK_MODEL_DATE_OF_CREATION = 4;
	WORK_MODEL_CREATOR_ID = 5;
	WORK_MODEL_WORK_RULE_NAME = 1;
	WORK_MODEL_WORK_RULE_DESCRIPTION = 2;
	WORK_MODEL_WORK_RULE_VERSIONS = 3;
	WORK_MODEL_WORK_RULE_DATE_OF_CREATION = 4;
	WORK_MODEL_WORK_RULE_CREATOR_ID = 5;
	WORK_MODEL_WORK_RULE_VERSION_ACTIVE_PERIOD_START = 1;
	WORK_MODEL_WORK_RULE_VERSION_ACTIVE_PERIOD_END = 2;
	WORK_MODEL_WORK_RULE_VERSION_INTERNAL_NOTES = 3;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE = 4;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_ELEMENTS = 5;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LISTS = 6;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD = 7;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPES = 8;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_ELEMENT_TYPE = 1;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_TYPE = 1;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_COUNT = 2;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_CHECK_WHOLE_DAY = 3;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_START_TIME = 4;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_END_TIME = 5;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_WEEKDAYS = 6;
	WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_ANCHOR_DATE = 7;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOGICAL_CONNECTIVE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_CHECK_ELEMENT_ID = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTERS = 3;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTERS = 4;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTERS = 5;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTERS = 6;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTERS = 7;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTERS = 8;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTERS = 9;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTERS = 10;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTERS = 11;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTER_TYPES = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTER_TYPES = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTER_WEEKDAYS = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_MIN = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_MAX = 3;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_AGE_UNKNOWN = 4;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER_START_TIME = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER_END_TIME = 3;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_ACTIVITY_AREA_IDS = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_ACTIVITY_IDS = 3;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTER_ITEM_CATEGORIES = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_TYPE = 2;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_LABEL_IDS = 3;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_ITEMS_WITH_NO_LABEL = 4;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTER_EXCLUDE = 1;
	WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTER_SHIFT_PREFERENCES = 2;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_TYPE = 1;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD = 2;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_NEGATIVE_INTERVAL_2_START = 3;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_NEGATIVE_INTERVAL_1_START = 4;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_POSITIVE_INTERVAL_1_START = 5;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_POSITIVE_INTERVAL_2_START = 6;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_WARNING_EMAIL_NOTIFICATION = 7;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_KEEP_A_LEDGER = 8;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_NEGATIVE_INTERVAL_2_START = 9;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_NEGATIVE_INTERVAL_1_START = 10;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_POSITIVE_INTERVAL_1_START = 11;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_POSITIVE_INTERVAL_2_START = 12;
	WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_WARNING_EMAIL_NOTIFICATION = 13;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_START = 1;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_END = 2;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_SUMMANDS = 3;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_SUM = 4;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_SUM_WARNING = 5;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_LEDGER_CHANGE = 6;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_LEDGER_VALUE = 7;
	WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_LEDGER_WARNING = 8;
	HOLIDAYS_HOLIDAY_ITEMS = 1;
	HOLIDAYS_LABELS = 2;
	HOLIDAYS_HOLIDAY_ITEM_TYPE = 1;
	HOLIDAYS_HOLIDAY_ITEM_START = 2;
	HOLIDAYS_HOLIDAY_ITEM_END = 3;
	HOLIDAYS_HOLIDAY_ITEM_NAME = 4;
	HOLIDAYS_HOLIDAY_ITEM_EMOJI = 5;
	HOLIDAYS_HOLIDAY_ITEM_PUBLIC_HOLIDAY_DURATION_TYPE = 6;
	HOLIDAYS_HOLIDAY_ITEM_LABEL_IDS = 7;
	HOLIDAYS_LABEL_NAME = 1;
	HOLIDAYS_LABEL_EMOJI = 2;
	GIFT_CARD_FIRST_NAME = 1;
	GIFT_CARD_LAST_NAME = 2;
	GIFT_CARD_BOOKING_NUMBER = 3;
	GIFT_CARD_CODE = 4;
	GIFT_CARD_CURRENT_VALUE = 5;
	GIFT_CARD_DATE_OF_BOOKING = 6;
	GIFT_CARD_PRICE = 7;
	GIFT_CARD_INITIAL_BALANCE = 8;
	GIFT_CARD_CURRENTLY_PAID = 9;
	GIFT_CARD_STATUS = 10;
	GIFT_CARD_INTERNAL_NOTES = 11;
	GIFT_CARD_CANCELLATION_FEE = 12;
	GIFT_CARD_VAT_PERCENT = 13;
	GIFT_CARD_OVERRIDE_WAYS_TO_REDEEM = 14;
	GIFT_CARD_IS_CURRENT_VALUE_KNOWN = 15;
	GIFT_CARD_WAYS_TO_REDEEM = 16;
	GIFT_CARD_EMAIL = 17;
	GIFT_CARD_EXPIRATION_DATE = 18;
	GIFT_CARD_REDEEMED_AMOUNT = 19;
	GIFT_CARD_CREATED_BY = 20;
	GIFT_CARD_MESSAGE_FOR_RECIPIENT = 21;
	GIFT_CARD_POS_ACCOUNT = 22;
	GIFT_CARD_PAID_BEFORE_TRANSACTION_LIST_INTRODUCTION = 23;
	GIFT_CARD_ACCOUNT_REFUND_LIMIT_DUE_TO_ONLINE_BALANCE = 24;
	GIFT_CARD_IS_ANONYMIZED = 25;
	GIFT_CARD_IS_CODE_SAFE_FOR_ONLINE_REDEMPTION = 26;
	GIFT_CARD_CREATED_FOR_BOOKING_ID = 27;
	GIFT_CARD_MIN_SHOPPER_CARD_VALUE = 28;
	GIFT_CARD_TESTING_DEFERRED_PAYMENT_TOKEN = 29;
	WARNING_TEXT = 1;
	WARNING_SEVERITY = 2;
	WARNING_CONCERNS_MEMBER_ID = 3;
	WARNING_FOR_SWAP_OFFER_ID = 4;
	WARNING_FOR_SWAP_OFFER_NEW_ITEM_ID = 5;
	EVALUATION_GENERATE = 1;
	ADYEN_ACCOUNT_BALANCE = 1;
	ADYEN_ACCOUNT_HOLDER_STATE = 2;
	ADYEN_ACCOUNT_HOLDER_PAYOUT_STATE = 3;
	ADYEN_ACCOUNT_HOLDER_PROCESSING_STATE = 4;
	ADYEN_ACCOUNT_ONBOARDING_URL = 5;
	ADYEN_ACCOUNT_DEADLINE_DATE = 6;
	ADYEN_ACCOUNT_UPCOMING_DEADLINE_STATE = 7;
	ADYEN_ACCOUNT_TESTING_DESIRED_DEPOSIT = 8;
	ADYEN_ACCOUNT_TESTING_ACCOUNT_HOLDER_CODE = 9;
	ADYEN_ACCOUNT_TESTING_ACCOUNT_HOLDER_PAYMENT_ACCOUNT = 10;
	ADYEN_ACCOUNT_TESTING_LIABLE_PAYMENT_ACCOUNT = 11;
	ADYEN_ACCOUNT_ONBOARDING_ACTION_REQUIRED_OR_PENDING = 12;
	ADYEN_ACCOUNT_PAYOUT_SCHEDULE = 13;
	ADYEN_ACCOUNT_NEXT_PAYOUT_DATE = 14;
	MESSAGES_REMOVED_DUPLICATE_RE_CAPTCHA_WHITE_LISTED_HOST_NAME = 1;
	MESSAGES_SENT_REQUESTER_REPORT_ABOUT_REMOVED_ASSIGNMENTS = 2;
	MESSAGES_ONLINE_REFUND_INFO = 3;
	MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO = 4;
	MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_AFFECTED_SHIFT_MODEL_ID = 1;
	MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EVENT_TRIGGERED = 2;
	MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EMAIL_SENT_TO_BOOKING_PERSON = 3;
	MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EMAIL_SENT_TO_PARTICIPANTS = 4;
	MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EMAIL_NOT_SENT_BECAUSE_OF_MISSING_EMAIL = 5;
	MAIL_SENT_TO_BOOKING_PERSON_DATE_TIME = 1;
	MAIL_SENT_TO_BOOKING_PERSON_EVENT_TYPE = 2;
	MAIL_SENT_TO_BOOKING_PERSON_RECIPIENT_EMAIL = 3;
	MAIL_SENT_TO_BOOKING_PERSON_SUBJECT = 4;
	MAIL_SENT_TO_BOOKING_PERSON_TEXT = 5;
	MAIL_SENT_TO_BOOKING_PERSON_RESEND_REQUESTER_ID = 6;
	MAIL_SENT_TO_BOOKING_PERSON_RESEND = 7;
	AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_NO_CANCELLATION_FEES = 1;
	AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_AUTOMATIC_ONLINE_REFUND = 2;
	AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_TRANSACTION_INTERNAL_NOTES = 3;
	AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_AUTOMATIC_GIFT_CARD_REFUND = 4;
	AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_GIFT_CARD_INTERNAL_NOTES = 5;
}


@Injectable({
  providedIn: 'root',
})
export class SchedulingApiServiceBase extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'scheduling');
	}

	consts = new Consts();

	protected version() : string {
		return 'eaae642157d69010e8dd8d31fd128221,f80d64d3c609436f1fd54e8bfcf82629';
	}

	private dataWrapper = new SchedulingApiRoot(this);

	get data() : SchedulingApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : SchedulingApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new SchedulingApiRoot(this);
	}
}

		 
export class SchedulingApiRootBase extends ApiObjectWrapper<SchedulingApiRoot>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, SchedulingApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiRoot, SchedulingApiRoot> = new ApiAttributeInfo<SchedulingApiRoot, SchedulingApiRoot>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiIsUsingRoutesManager : ApiAttributeInfo<SchedulingApiRoot, boolean> = new ApiAttributeInfo<SchedulingApiRoot, boolean>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: 'isUsingRoutesManager',
			nodeName: 'IS_USING_ROUTES_MANAGER',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiRoot) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiKletterszeneId : ApiAttributeInfo<SchedulingApiRoot, string> = new ApiAttributeInfo<SchedulingApiRoot, string>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: 'kletterszeneId',
			nodeName: 'KLETTERSZENE_ID',
			type: PApiType.string,
			hasPermissionToSet: function(this : SchedulingApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 12,
		});
	aiPosSystem : ApiAttributeInfo<SchedulingApiRoot, SchedulingApiPosSystem> = new ApiAttributeInfo<SchedulingApiRoot, SchedulingApiPosSystem>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: 'posSystem',
			nodeName: 'POS_SYSTEM',
			type: PApiType.Enum,
			hasPermissionToSet: function(this : SchedulingApiRoot) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 13,
		});
	aiBookingPageCover : ApiAttributeInfo<SchedulingApiRoot, Image> = new ApiAttributeInfo<SchedulingApiRoot, Image>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: 'bookingPageCover',
			nodeName: 'BOOKING_PAGE_COVER',
			type: PApiType.Image,
			validations: function(this : SchedulingApiRoot) {
				return [
					() => {
						return this.api.validators.imageMaxWidth(1800);
					},
					() => {
						return this.api.validators.imageMaxHeight(684);
					},
					() => {
						return this.api.validators.maxFileSize(10240);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiRoot) {
				return [
					() => {
						return this.api.asyncValidators.asyncImageRatio(2.6315789);
					},
					() => {
						return this.api.asyncValidators.asyncImageMinWidth(900);
					},
					() => {
						return this.api.asyncValidators.asyncImageMinHeight(342);
					},
				];
			}
			,
			rawDataIndex: 28,
		});
	aiCompanyLogo : ApiAttributeInfo<SchedulingApiRoot, Image> = new ApiAttributeInfo<SchedulingApiRoot, Image>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: 'companyLogo',
			nodeName: 'COMPANY_LOGO',
			type: PApiType.Image,
			validations: function(this : SchedulingApiRoot) {
				return [
					() => {
						return this.api.validators.imageMaxWidth(360);
					},
					() => {
						return this.api.validators.imageMaxHeight(360);
					},
					() => {
						return this.api.validators.maxFileSize(5120);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiRoot) {
				return [
					() => {
						return this.api.asyncValidators.asyncImageRatio(1.0);
					},
					() => {
						return this.api.asyncValidators.asyncImageMinWidth(180);
					},
					() => {
						return this.api.asyncValidators.asyncImageMinHeight(180);
					},
				];
			}
			,
			rawDataIndex: 29,
		});
	aiIsOnlinePaymentAvailable : ApiAttributeInfo<SchedulingApiRoot, boolean> = new ApiAttributeInfo<SchedulingApiRoot, boolean>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: 'isOnlinePaymentAvailable',
			nodeName: 'IS_ONLINE_PAYMENT_AVAILABLE',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 31,
		});
	aiReCaptchaEnabledInBookingProcess : ApiAttributeInfo<SchedulingApiRoot, boolean> = new ApiAttributeInfo<SchedulingApiRoot, boolean>({
			apiObjWrapper: this as any as SchedulingApiRoot,
			name: 'reCaptchaEnabledInBookingProcess',
			nodeName: 'RE_CAPTCHA_ENABLED_IN_BOOKING_PROCESS',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiRoot) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'bookingSystemSettings'));
			},
			hasPermissionToSet: function(this : SchedulingApiRoot) {
	return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			validations: function(this : SchedulingApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiRoot) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 32,
		});

	private notificationsConfigWrapper : SchedulingApiNotificationsConfig = new SchedulingApiNotificationsConfig(this.api,
		this as unknown as SchedulingApiRoot);
	public aiNotificationsConfig = this.notificationsConfigWrapper.aiThis;

	/**
	 * Object to configure notifications for current action. IMPORTANT: This is the first element in the API so it is deserialized before everything else.
	 */
	get notificationsConfig() : SchedulingApiNotificationsConfig {
		this.getterDebugValidations(this.aiNotificationsConfig, false);
		return this.notificationsConfigWrapper;
	}

	set notificationsConfigTestSetter(v : SchedulingApiNotificationsConfig) {
        this.setterImpl(1, v.rawData, 'notificationsConfig', true, null, function(this : SchedulingApiRoot) {this.notificationsConfigWrapper = v;});
	}

	private shiftsWrapper : SchedulingApiShifts = new SchedulingApiShifts(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiShifts = this.shiftsWrapper.aiThis;

	/**
	 * A list of the shifts in time interval between query parameters "start" and "end". Only send for query parameter "data=calendar".
	 */
	get shifts() : SchedulingApiShifts {
		this.getterDebugValidations(this.aiShifts, false);
		return this.shiftsWrapper;
	}

	set shiftsTestSetter(v : SchedulingApiShifts) {
        this.setterImpl(2, v.rawData, 'shifts', true, null, function(this : SchedulingApiRoot) {this.shiftsWrapper = v;});
	}

	private workingTimesWrapper : SchedulingApiWorkingTimes = new SchedulingApiWorkingTimes(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiWorkingTimes = this.workingTimesWrapper.aiThis;

	/**
	 * A list of working times. Only send for query parameter "data=reporting".
	 */
	get workingTimes() : SchedulingApiWorkingTimes {
		this.getterDebugValidations(this.aiWorkingTimes, false);
		return this.workingTimesWrapper;
	}

	set workingTimesTestSetter(v : SchedulingApiWorkingTimes) {
        this.setterImpl(3, v.rawData, 'workingTimes', true, null, function(this : SchedulingApiRoot) {this.workingTimesWrapper = v;});
	}

	private leavesWrapper : SchedulingApiLeaves = new SchedulingApiLeaves(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiLeaves = this.leavesWrapper.aiThis;

	/**
	 * A list of leaves.
	 */
	get leaves() : SchedulingApiLeaves {
		this.getterDebugValidations(this.aiLeaves, false);
		return this.leavesWrapper;
	}

	set leavesTestSetter(v : SchedulingApiLeaves) {
        this.setterImpl(4, v.rawData, 'leaves', true, null, function(this : SchedulingApiRoot) {this.leavesWrapper = v;});
	}

	private shiftExchangesWrapper : SchedulingApiShiftExchanges = new SchedulingApiShiftExchanges(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiShiftExchanges = this.shiftExchangesWrapper.aiThis;

	/**
	 * A list of shift-exchange items.
	 */
	get shiftExchanges() : SchedulingApiShiftExchanges {
		this.getterDebugValidations(this.aiShiftExchanges, false);
		return this.shiftExchangesWrapper;
	}

	set shiftExchangesTestSetter(v : SchedulingApiShiftExchanges) {
        this.setterImpl(5, v.rawData, 'shiftExchanges', true, null, function(this : SchedulingApiRoot) {this.shiftExchangesWrapper = v;});
	}

	private assignmentProcessesWrapper : SchedulingApiAssignmentProcesses = new SchedulingApiAssignmentProcesses(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiAssignmentProcesses = this.assignmentProcessesWrapper.aiThis;

	/**
	 * A list of assignment processes. Only send for query parameter data equal "calendar", "reporting" or "shiftExchange".
	 */
	get assignmentProcesses() : SchedulingApiAssignmentProcesses {
		this.getterDebugValidations(this.aiAssignmentProcesses, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiAssignmentProcesses.defaultValue;
			this.assignmentProcessesWrapper._updateRawData(this.data[6], true);
		}
		return this.assignmentProcessesWrapper;
	}

	set assignmentProcessesTestSetter(v : SchedulingApiAssignmentProcesses) {
        this.setterImpl(6, v.rawData, 'assignmentProcesses', true, null, function(this : SchedulingApiRoot) {this.assignmentProcessesWrapper = v;});
	}

	private membersWrapper : SchedulingApiMembers = new SchedulingApiMembers(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiMembers = this.membersWrapper.aiThis;

	/**
	 * A list of the members.
	 */
	get members() : SchedulingApiMembers {
		this.getterDebugValidations(this.aiMembers, false);
		return this.membersWrapper;
	}

	set membersTestSetter(v : SchedulingApiMembers) {
        this.setterImpl(7, v.rawData, 'members', true, null, function(this : SchedulingApiRoot) {this.membersWrapper = v;});
	}

	private bookingsWrapper : SchedulingApiBookings = new SchedulingApiBookings(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiBookings = this.bookingsWrapper.aiThis;

	/**
	 * A list of course bookings. NOTE: Currently it is crucial that bookings are deserialized before shift-models because shift-model tariff serialization depends on bookings data (see applyTo... values)
	 */
	get bookings() : SchedulingApiBookings {
		this.getterDebugValidations(this.aiBookings, false);
		return this.bookingsWrapper;
	}

	set bookingsTestSetter(v : SchedulingApiBookings) {
        this.setterImpl(8, v.rawData, 'bookings', true, null, function(this : SchedulingApiRoot) {this.bookingsWrapper = v;});
	}

	private activityAreasWrapper : SchedulingApiActivityAreas = new SchedulingApiActivityAreas(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiActivityAreas = this.activityAreasWrapper.aiThis;

	/**
	 * List of clients activity-areas.
	 */
	get activityAreas() : SchedulingApiActivityAreas {
		this.getterDebugValidations(this.aiActivityAreas, false);
		return this.activityAreasWrapper;
	}

	set activityAreasTestSetter(v : SchedulingApiActivityAreas) {
        this.setterImpl(9, v.rawData, 'activityAreas', true, null, function(this : SchedulingApiRoot) {this.activityAreasWrapper = v;});
	}

	private shiftModelsWrapper : SchedulingApiShiftModels = new SchedulingApiShiftModels(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiShiftModels = this.shiftModelsWrapper.aiThis;

	/**
	 * A list of the shift models.
	 */
	get shiftModels() : SchedulingApiShiftModels {
		this.getterDebugValidations(this.aiShiftModels, false);
		return this.shiftModelsWrapper;
	}

	set shiftModelsTestSetter(v : SchedulingApiShiftModels) {
        this.setterImpl(10, v.rawData, 'shiftModels', true, null, function(this : SchedulingApiRoot) {this.shiftModelsWrapper = v;});
	}

	/**
	 * Is this client using the Routes Manager api?
	 *
	 * @type {boolean}
	 */
	get isUsingRoutesManager() : boolean {
		this.getterDebugValidations(this.aiIsUsingRoutesManager, false);
		return this.data[11];
	}

	set isUsingRoutesManager(v : boolean) {
        this.setterImpl(11, v, 'isUsingRoutesManager', false, null, null);
	}

	/**
	 * Is this client using the kletterszene.com api? If yes, then here the kletterszene.com client id is stored.
	 *
	 * @type {string}
	 */
	get kletterszeneId() : string | null {
		this.getterDebugValidations(this.aiKletterszeneId, false);
		return this.data[12];
	}

	set kletterszeneId(v : string | null) {
        this.setterImpl(12, v, 'kletterszeneId', false, null, null);
	}

	/**
	 * Pos system currently being used by this client. Can be edited with "canReadAndWriteBookingSystemSettings" permission.
	 *
	 * @type {SchedulingApiPosSystem}
	 */
	get posSystem() : SchedulingApiPosSystem | null {
		this.getterDebugValidations(this.aiPosSystem, false);
		return this.data[13];
	}

	set posSystem(v : SchedulingApiPosSystem | null) {
        this.setterImpl(13, v, 'posSystem', false, null, null);
	}

	private permissionGroupsWrapper : SchedulingApiPermissionGroups = new SchedulingApiPermissionGroups(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiPermissionGroups = this.permissionGroupsWrapper.aiThis;

	/**
	 * A list of the permission groups. A default member only gets the permission group which is assigned to him.
	 */
	get permissionGroups() : SchedulingApiPermissionGroups {
		this.getterDebugValidations(this.aiPermissionGroups, false);
		return this.permissionGroupsWrapper;
	}

	set permissionGroupsTestSetter(v : SchedulingApiPermissionGroups) {
        this.setterImpl(14, v.rawData, 'permissionGroups', true, null, function(this : SchedulingApiRoot) {this.permissionGroupsWrapper = v;});
	}

	private accountingPeriodsWrapper : SchedulingApiAccountingPeriods = new SchedulingApiAccountingPeriods(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiAccountingPeriods = this.accountingPeriodsWrapper.aiThis;

	/**
	 * A list with information about the different accounting-periods intersecting given time interval. Only send for query parameter data equal "calendar", "reporting" and "shiftExchange".
	 */
	get accountingPeriods() : SchedulingApiAccountingPeriods {
		this.getterDebugValidations(this.aiAccountingPeriods, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[15] === undefined) {
			this.data[15] = this.aiAccountingPeriods.defaultValue;
			this.accountingPeriodsWrapper._updateRawData(this.data[15], true);
		}
		return this.accountingPeriodsWrapper;
	}

	set accountingPeriodsTestSetter(v : SchedulingApiAccountingPeriods) {
        this.setterImpl(15, v.rawData, 'accountingPeriods', true, null, function(this : SchedulingApiRoot) {this.accountingPeriodsWrapper = v;});
	}

	private memosWrapper : SchedulingApiMemos = new SchedulingApiMemos(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiMemos = this.memosWrapper.aiThis;

	/**
	 * A list of memos. For query parameter "calendar", "reporting" and "shiftExchange" this list contains todays memos. For query parameter "calendar" additionally it contains all memos in interval "time". For other query parameters no memos are returned.
	 */
	get memos() : SchedulingApiMemos {
		this.getterDebugValidations(this.aiMemos, false);
		return this.memosWrapper;
	}

	set memosTestSetter(v : SchedulingApiMemos) {
        this.setterImpl(16, v.rawData, 'memos', true, null, function(this : SchedulingApiRoot) {this.memosWrapper = v;});
	}

	private todaysShiftDescriptionsWrapper : SchedulingApiTodaysShiftDescriptions = new SchedulingApiTodaysShiftDescriptions(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiTodaysShiftDescriptions = this.todaysShiftDescriptionsWrapper.aiThis;

	/**
	 * A list of all todays shift descriptions.
	 */
	get todaysShiftDescriptions() : SchedulingApiTodaysShiftDescriptions {
		this.getterDebugValidations(this.aiTodaysShiftDescriptions, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[17] === undefined) {
			this.data[17] = this.aiTodaysShiftDescriptions.defaultValue;
			this.todaysShiftDescriptionsWrapper._updateRawData(this.data[17], true);
		}
		return this.todaysShiftDescriptionsWrapper;
	}

	set todaysShiftDescriptionsTestSetter(v : SchedulingApiTodaysShiftDescriptions) {
        this.setterImpl(17, v.rawData, 'todaysShiftDescriptions', true, null, function(this : SchedulingApiRoot) {this.todaysShiftDescriptionsWrapper = v;});
	}

	private holidaysWrapper : SchedulingApiHolidays = new SchedulingApiHolidays(this.api,
		this as unknown as SchedulingApiRoot);
	public aiHolidays = this.holidaysWrapper.aiThis;

	/**
	 * Object containing the holidays settings and the list of holiday items.
	 */
	get holidays() : SchedulingApiHolidays {
		this.getterDebugValidations(this.aiHolidays, false);
		return this.holidaysWrapper;
	}

	set holidaysTestSetter(v : SchedulingApiHolidays) {
        this.setterImpl(18, v.rawData, 'holidays', true, null, function(this : SchedulingApiRoot) {this.holidaysWrapper = v;});
	}

	private possibleVatPercentsWrapper : SchedulingApiPossibleVatPercents = new SchedulingApiPossibleVatPercents(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiPossibleVatPercents = this.possibleVatPercentsWrapper.aiThis;

	/**
	 * A list of possible vat-percent values for this account.
	 */
	get possibleVatPercents() : SchedulingApiPossibleVatPercents {
		this.getterDebugValidations(this.aiPossibleVatPercents, false);
		return this.possibleVatPercentsWrapper;
	}

	set possibleVatPercentsTestSetter(v : SchedulingApiPossibleVatPercents) {
        this.setterImpl(19, v.rawData, 'possibleVatPercents', true, null, function(this : SchedulingApiRoot) {this.possibleVatPercentsWrapper = v;});
	}

	private customBookableMailsWrapper : SchedulingApiCustomBookableMails = new SchedulingApiCustomBookableMails(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiCustomBookableMails = this.customBookableMailsWrapper.aiThis;

	/**
	 * A list of custom bookable emails which define email templates. Only send for query parameter data equal "calendar" and "bookingSystemSettings". Only editable by client owners.
	 */
	get customBookableMails() : SchedulingApiCustomBookableMails {
		this.getterDebugValidations(this.aiCustomBookableMails, false);
		return this.customBookableMailsWrapper;
	}

	set customBookableMailsTestSetter(v : SchedulingApiCustomBookableMails) {
        this.setterImpl(20, v.rawData, 'customBookableMails', true, null, function(this : SchedulingApiRoot) {this.customBookableMailsWrapper = v;});
	}

	private notificationSettingsWrapper : SchedulingApiNotificationSettings = new SchedulingApiNotificationSettings(this.api,
		this as unknown as SchedulingApiRoot);
	public aiNotificationSettings = this.notificationSettingsWrapper.aiThis;

	/**
	 * Notification settings of current user.
	 */
	get notificationSettings() : SchedulingApiNotificationSettings {
		this.getterDebugValidations(this.aiNotificationSettings, false);
		return this.notificationSettingsWrapper;
	}

	set notificationSettingsTestSetter(v : SchedulingApiNotificationSettings) {
        this.setterImpl(21, v.rawData, 'notificationSettings', true, null, function(this : SchedulingApiRoot) {this.notificationSettingsWrapper = v;});
	}

	private giftCardSettingsWrapper : SchedulingApiGiftCardSettings = new SchedulingApiGiftCardSettings(this.api,
		this as unknown as SchedulingApiRoot);
	public aiGiftCardSettings = this.giftCardSettingsWrapper.aiThis;

	/**
	 * Gift Card settings. Send for data equal "bookingSystemSettings".
	 */
	get giftCardSettings() : SchedulingApiGiftCardSettings {
		this.getterDebugValidations(this.aiGiftCardSettings, false);
		return this.giftCardSettingsWrapper;
	}

	set giftCardSettingsTestSetter(v : SchedulingApiGiftCardSettings) {
        this.setterImpl(22, v.rawData, 'giftCardSettings', true, null, function(this : SchedulingApiRoot) {this.giftCardSettingsWrapper = v;});
	}

	private giftCardsWrapper : SchedulingApiGiftCards = new SchedulingApiGiftCards(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiGiftCards = this.giftCardsWrapper.aiThis;

	/**
	 * List of available gift cards. Send for data equal "giftCards".
	 */
	get giftCards() : SchedulingApiGiftCards {
		this.getterDebugValidations(this.aiGiftCards, false);
		return this.giftCardsWrapper;
	}

	set giftCardsTestSetter(v : SchedulingApiGiftCards) {
        this.setterImpl(23, v.rawData, 'giftCards', true, null, function(this : SchedulingApiRoot) {this.giftCardsWrapper = v;});
	}

	private transactionsWrapper : SchedulingApiTransactions = new SchedulingApiTransactions(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiTransactions = this.transactionsWrapper.aiThis;

	/**
	 * A list of transactions.
	 */
	get transactions() : SchedulingApiTransactions {
		this.getterDebugValidations(this.aiTransactions, false);
		return this.transactionsWrapper;
	}

	set transactionsTestSetter(v : SchedulingApiTransactions) {
        this.setterImpl(24, v.rawData, 'transactions', true, null, function(this : SchedulingApiRoot) {this.transactionsWrapper = v;});
	}

	private workModelsWrapper : SchedulingApiWorkModels = new SchedulingApiWorkModels(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiWorkModels = this.workModelsWrapper.aiThis;

	/**
	 * The list of work models.
	 */
	get workModels() : SchedulingApiWorkModels {
		this.getterDebugValidations(this.aiWorkModels, false);
		return this.workModelsWrapper;
	}

	set workModelsTestSetter(v : SchedulingApiWorkModels) {
        this.setterImpl(25, v.rawData, 'workModels', true, null, function(this : SchedulingApiRoot) {this.workModelsWrapper = v;});
	}

	private warningsWrapper : SchedulingApiWarnings = new SchedulingApiWarnings(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiWarnings = this.warningsWrapper.aiThis;

	/**
	 * Warnings for current request. Read-only.
	 */
	get warnings() : SchedulingApiWarnings {
		this.getterDebugValidations(this.aiWarnings, false);
		return this.warningsWrapper;
	}

	set warningsTestSetter(v : SchedulingApiWarnings) {
        this.setterImpl(26, v.rawData, 'warnings', true, null, function(this : SchedulingApiRoot) {this.warningsWrapper = v;});
	}

	private evaluationWrapper : SchedulingApiEvaluation = new SchedulingApiEvaluation(this.api,
		this as unknown as SchedulingApiRoot);
	public aiEvaluation = this.evaluationWrapper.aiThis;

	/**
	 * An object containing the schedule evaluation (Only send to client owners).
	 */
	get evaluation() : SchedulingApiEvaluation {
		this.getterDebugValidations(this.aiEvaluation, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[27] === undefined) {
			this.data[27] = this.aiEvaluation.defaultValue;
			this.evaluationWrapper._updateRawData(this.data[27], true);
		}
		return this.evaluationWrapper;
	}

	set evaluationTestSetter(v : SchedulingApiEvaluation) {
        this.setterImpl(27, v.rawData, 'evaluation', true, null, function(this : SchedulingApiRoot) {this.evaluationWrapper = v;});
	}

	/**
	 * 
	 *
	 * @type {Image}
	 */
	get bookingPageCover() : Image | null {
		this.getterDebugValidations(this.aiBookingPageCover, false);
		const path = this.data[28] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set bookingPageCover(v : Image | null) {
        this.setterImpl(28, v, 'bookingPageCover', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Image}
	 */
	get companyLogo() : Image | null {
		this.getterDebugValidations(this.aiCompanyLogo, false);
		const path = this.data[29] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set companyLogo(v : Image | null) {
        this.setterImpl(29, v, 'companyLogo', false, null, null);
	}

	private adyenAccountWrapper : SchedulingApiAdyenAccount = new SchedulingApiAdyenAccount(this.api,
		this as unknown as SchedulingApiRoot);
	public aiAdyenAccount = this.adyenAccountWrapper.aiThis;

	/**
	 * Adyen data.
	 */
	get adyenAccount() : SchedulingApiAdyenAccount {
		this.getterDebugValidations(this.aiAdyenAccount, false);
		return this.adyenAccountWrapper;
	}

	set adyenAccountTestSetter(v : SchedulingApiAdyenAccount) {
        this.setterImpl(30, v.rawData, 'adyenAccount', true, null, function(this : SchedulingApiRoot) {this.adyenAccountWrapper = v;});
	}

	/**
	 * Has client onboarded to Adyen? Read only.
	 *
	 * @type {boolean}
	 */
	get isOnlinePaymentAvailable() : boolean {
		this.getterDebugValidations(this.aiIsOnlinePaymentAvailable, false);
		return this.data[31];
	}

	set isOnlinePaymentAvailableTestSetter(v : boolean) {
        this.setterImpl(31, v, 'isOnlinePaymentAvailable', true, null, null);
	}

	/**
	 * Should ReCaptcha be used in the booking processes of this client?
	 *
	 * @type {boolean}
	 */
	get reCaptchaEnabledInBookingProcess() : boolean {
		this.getterDebugValidations(this.aiReCaptchaEnabledInBookingProcess, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[32] === undefined) {
			this.data[32] = this.aiReCaptchaEnabledInBookingProcess.defaultValue;
			
		}
		return this.data[32];
	}

	set reCaptchaEnabledInBookingProcess(v : boolean) {
        this.setterImpl(32, v, 'reCaptchaEnabledInBookingProcess', false, null, null);
	}

	private reCaptchaWhiteListedHostNamesWrapper : SchedulingApiReCaptchaWhiteListedHostNames = new SchedulingApiReCaptchaWhiteListedHostNames(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiReCaptchaWhiteListedHostNames = this.reCaptchaWhiteListedHostNamesWrapper.aiThis;

	/**
	 * Host-names being permitted during Google reCaptcha Token validation. Send for data equal "bookingSystemSettings".
	 */
	get reCaptchaWhiteListedHostNames() : SchedulingApiReCaptchaWhiteListedHostNames {
		this.getterDebugValidations(this.aiReCaptchaWhiteListedHostNames, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[33] === undefined) {
			this.data[33] = this.aiReCaptchaWhiteListedHostNames.defaultValue;
			this.reCaptchaWhiteListedHostNamesWrapper._updateRawData(this.data[33], true);
		}
		return this.reCaptchaWhiteListedHostNamesWrapper;
	}

	set reCaptchaWhiteListedHostNamesTestSetter(v : SchedulingApiReCaptchaWhiteListedHostNames) {
        this.setterImpl(33, v.rawData, 'reCaptchaWhiteListedHostNames', true, null, function(this : SchedulingApiRoot) {this.reCaptchaWhiteListedHostNamesWrapper = v;});
	}

	private mailsSentToBookingPersonWrapper : SchedulingApiMailsSentToBookingPerson = new SchedulingApiMailsSentToBookingPerson(this.api,
		this as unknown as SchedulingApiRoot, false, false);
	public aiMailsSentToBookingPerson = this.mailsSentToBookingPersonWrapper.aiThis;

	/**
	 * All mails send to the booking person. Only sent when a booking or gift card is loaded detailed.
	 */
	get mailsSentToBookingPerson() : SchedulingApiMailsSentToBookingPerson {
		this.getterDebugValidations(this.aiMailsSentToBookingPerson, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[34] === undefined) {
			this.data[34] = this.aiMailsSentToBookingPerson.defaultValue;
			this.mailsSentToBookingPersonWrapper._updateRawData(this.data[34], true);
		}
		return this.mailsSentToBookingPersonWrapper;
	}

	set mailsSentToBookingPersonTestSetter(v : SchedulingApiMailsSentToBookingPerson) {
        this.setterImpl(34, v.rawData, 'mailsSentToBookingPerson', true, null, function(this : SchedulingApiRoot) {this.mailsSentToBookingPersonWrapper = v;});
	}

	private messagesWrapper : SchedulingApiMessages = new SchedulingApiMessages(this.api,
		this as unknown as SchedulingApiRoot);
	public aiMessages = this.messagesWrapper.aiThis;

	/**
	 * Messages which backend sends to frontend.
	 */
	get messages() : SchedulingApiMessages {
		this.getterDebugValidations(this.aiMessages, false);
		return this.messagesWrapper;
	}

	set messagesTestSetter(v : SchedulingApiMessages) {
        this.setterImpl(35, v.rawData, 'messages', true, null, function(this : SchedulingApiRoot) {this.messagesWrapper = v;});
	}

	private shiftChangeSelectorWrapper : SchedulingApiShiftChangeSelector = new SchedulingApiShiftChangeSelector(this.api,
		this as unknown as SchedulingApiRoot);
	public aiShiftChangeSelector = this.shiftChangeSelectorWrapper.aiThis;

	/**
	 * Set by user to define on what the shift changes should be applied.
	 */
	get shiftChangeSelector() : SchedulingApiShiftChangeSelector {
		this.getterDebugValidations(this.aiShiftChangeSelector, false);
		return this.shiftChangeSelectorWrapper;
	}

	set shiftChangeSelectorTestSetter(v : SchedulingApiShiftChangeSelector) {
        this.setterImpl(36, v.rawData, 'shiftChangeSelector', true, null, function(this : SchedulingApiRoot) {this.shiftChangeSelectorWrapper = v;});
	}

	private automaticBookingCancellationSettingsWrapper : SchedulingApiAutomaticBookingCancellationSettings = new SchedulingApiAutomaticBookingCancellationSettings(this.api,
		this as unknown as SchedulingApiRoot);
	public aiAutomaticBookingCancellationSettings = this.automaticBookingCancellationSettingsWrapper.aiThis;

	/**
	 * Here you can configure the automatic booking cancellation when a shift is removed or cancelled.
	 */
	get automaticBookingCancellationSettings() : SchedulingApiAutomaticBookingCancellationSettings {
		this.getterDebugValidations(this.aiAutomaticBookingCancellationSettings, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[37] === undefined) {
			this.data[37] = this.aiAutomaticBookingCancellationSettings.defaultValue;
			this.automaticBookingCancellationSettingsWrapper._updateRawData(this.data[37], true);
		}
		return this.automaticBookingCancellationSettingsWrapper;
	}

	set automaticBookingCancellationSettingsTestSetter(v : SchedulingApiAutomaticBookingCancellationSettings) {
        this.setterImpl(37, v.rawData, 'automaticBookingCancellationSettings', true, null, function(this : SchedulingApiRoot) {this.automaticBookingCancellationSettingsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.notificationsConfigWrapper._fixIds(_idReplacements);
		this.shiftsWrapper._fixIds(_idReplacements);
		this.workingTimesWrapper._fixIds(_idReplacements);
		this.leavesWrapper._fixIds(_idReplacements);
		this.shiftExchangesWrapper._fixIds(_idReplacements);
		this.assignmentProcessesWrapper._fixIds(_idReplacements);
		this.membersWrapper._fixIds(_idReplacements);
		this.bookingsWrapper._fixIds(_idReplacements);
		this.activityAreasWrapper._fixIds(_idReplacements);
		this.shiftModelsWrapper._fixIds(_idReplacements);
		this.permissionGroupsWrapper._fixIds(_idReplacements);
		this.accountingPeriodsWrapper._fixIds(_idReplacements);
		this.memosWrapper._fixIds(_idReplacements);
		this.todaysShiftDescriptionsWrapper._fixIds(_idReplacements);
		this.holidaysWrapper._fixIds(_idReplacements);
		this.possibleVatPercentsWrapper._fixIds(_idReplacements);
		this.customBookableMailsWrapper._fixIds(_idReplacements);
		this.notificationSettingsWrapper._fixIds(_idReplacements);
		this.giftCardSettingsWrapper._fixIds(_idReplacements);
		this.giftCardsWrapper._fixIds(_idReplacements);
		this.transactionsWrapper._fixIds(_idReplacements);
		this.workModelsWrapper._fixIds(_idReplacements);
		this.warningsWrapper._fixIds(_idReplacements);
		this.evaluationWrapper._fixIds(_idReplacements);
		this.adyenAccountWrapper._fixIds(_idReplacements);
		this.reCaptchaWhiteListedHostNamesWrapper._fixIds(_idReplacements);
		this.mailsSentToBookingPersonWrapper._fixIds(_idReplacements);
		this.messagesWrapper._fixIds(_idReplacements);
		this.shiftChangeSelectorWrapper._fixIds(_idReplacements);
		this.automaticBookingCancellationSettingsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.notificationsConfigWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.shiftsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.workingTimesWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		this.leavesWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
		this.shiftExchangesWrapper._updateRawData(data && data[5] !== undefined ? data[5] : null, generateMissingData);
		this.assignmentProcessesWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
		this.membersWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.bookingsWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		this.activityAreasWrapper._updateRawData(data && data[9] !== undefined ? data[9] : null, generateMissingData);
		this.shiftModelsWrapper._updateRawData(data && data[10] !== undefined ? data[10] : null, generateMissingData);
		this.permissionGroupsWrapper._updateRawData(data && data[14] !== undefined ? data[14] : null, generateMissingData);
		this.accountingPeriodsWrapper._updateRawData(data && data[15] !== undefined ? data[15] : null, generateMissingData);
		this.memosWrapper._updateRawData(data && data[16] !== undefined ? data[16] : null, generateMissingData);
		this.todaysShiftDescriptionsWrapper._updateRawData(data && data[17] !== undefined ? data[17] : null, generateMissingData);
		this.holidaysWrapper._updateRawData(data && data[18] !== undefined ? data[18] : null, generateMissingData);
		this.possibleVatPercentsWrapper._updateRawData(data && data[19] !== undefined ? data[19] : null, generateMissingData);
		this.customBookableMailsWrapper._updateRawData(data && data[20] !== undefined ? data[20] : null, generateMissingData);
		this.notificationSettingsWrapper._updateRawData(data && data[21] !== undefined ? data[21] : null, generateMissingData);
		this.giftCardSettingsWrapper._updateRawData(data && data[22] !== undefined ? data[22] : null, generateMissingData);
		this.giftCardsWrapper._updateRawData(data && data[23] !== undefined ? data[23] : null, generateMissingData);
		this.transactionsWrapper._updateRawData(data && data[24] !== undefined ? data[24] : null, generateMissingData);
		this.workModelsWrapper._updateRawData(data && data[25] !== undefined ? data[25] : null, generateMissingData);
		this.warningsWrapper._updateRawData(data && data[26] !== undefined ? data[26] : null, generateMissingData);
		this.evaluationWrapper._updateRawData(data && data[27] !== undefined ? data[27] : null, generateMissingData);
		this.adyenAccountWrapper._updateRawData(data && data[30] !== undefined ? data[30] : null, generateMissingData);
		this.reCaptchaWhiteListedHostNamesWrapper._updateRawData(data && data[33] !== undefined ? data[33] : null, generateMissingData);
		this.mailsSentToBookingPersonWrapper._updateRawData(data && data[34] !== undefined ? data[34] : null, generateMissingData);
		this.messagesWrapper._updateRawData(data && data[35] !== undefined ? data[35] : null, generateMissingData);
		this.shiftChangeSelectorWrapper._updateRawData(data && data[36] !== undefined ? data[36] : null, generateMissingData);
		this.automaticBookingCancellationSettingsWrapper._updateRawData(data && data[37] !== undefined ? data[37] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiNotificationsConfig extends ApiObjectWrapper<SchedulingApiNotificationsConfig>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiNotificationsConfig> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiNotificationsConfig as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationsConfig, SchedulingApiNotificationsConfig> = new ApiAttributeInfo<SchedulingApiNotificationsConfig, SchedulingApiNotificationsConfig>({
			apiObjWrapper: this as any as SchedulingApiNotificationsConfig,
			name: 'notificationsConfig',
			nodeName: 'NOTIFICATIONS_CONFIG',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiNotificationsConfig, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiSend : ApiAttributeInfo<SchedulingApiNotificationsConfig, boolean> = new ApiAttributeInfo<SchedulingApiNotificationsConfig, boolean>({
			apiObjWrapper: this as any as SchedulingApiNotificationsConfig,
			name: 'send',
			nodeName: 'NOTIFICATIONS_CONFIG_SEND',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiNotificationsConfig) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiNotificationsConfig) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiNotificationsConfig, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiComment : ApiAttributeInfo<SchedulingApiNotificationsConfig, string> = new ApiAttributeInfo<SchedulingApiNotificationsConfig, string>({
			apiObjWrapper: this as any as SchedulingApiNotificationsConfig,
			name: 'comment',
			nodeName: 'NOTIFICATIONS_CONFIG_COMMENT',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : SchedulingApiNotificationsConfig) {
				return ((this.send));
			},
			validations: function(this : SchedulingApiNotificationsConfig) {
				return [
					() => {
						return this.api.validators.maxLength(350, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiNotificationsConfig) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});

	/**
	 * Should notifications be send for current action?
	 *
	 * @type {boolean}
	 */
	get send() : boolean {
		this.getterDebugValidations(this.aiSend, false);
		return this.data[1];
	}

	set send(v : boolean) {
        this.setterImpl(1, v, 'send', false, null, function(this : SchedulingApiNotificationsConfig) {// If no message should be send, then the written comment should be removed
					if(!v)
						this.rawData[this.api.consts.NOTIFICATIONS_CONFIG_COMMENT] = null;});
	}

	/**
	 * A custom comment to be added to the notifications.
	 *
	 * @type {string}
	 */
	get comment() : string | null {
		this.getterDebugValidations(this.aiComment, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiComment.defaultValue;
			
		}
		return this.data[2];
	}

	set comment(v : string | null) {
        this.setterImpl(2, v, 'comment', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftsBase extends ApiListWrapper<SchedulingApiShift>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shifts');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShift {
		return new SchedulingApiShift(this.api, this as unknown as SchedulingApiShifts, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShifts(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '3';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShift) => void) | null = null, _backendId : ShiftId | null = null) : SchedulingApiShift {
		const newItem = new SchedulingApiShift(this.api, this as unknown as SchedulingApiShifts, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shifts');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShifts, SchedulingApiShifts> = new ApiAttributeInfo<SchedulingApiShifts, SchedulingApiShifts>({
			apiObjWrapper: this as any as SchedulingApiShifts,
			name: 'shifts',
			nodeName: 'SHIFTS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiShifts, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 2,
		});
}

				 
export class SchedulingApiShiftBase extends ApiObjectWrapper<SchedulingApiShift>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShifts | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShift> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShift as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShift, SchedulingApiShift> = new ApiAttributeInfo<SchedulingApiShift, SchedulingApiShift>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'shift',
			nodeName: 'SHIFT',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiShift) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShift, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiMyPref : ApiAttributeInfo<SchedulingApiShift, SchedulingApiShiftMemberPrefValue> = new ApiAttributeInfo<SchedulingApiShift, SchedulingApiShiftMemberPrefValue>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'myPref',
			nodeName: 'SHIFT_MY_PREF',
			type: PApiType.Enum,
			skipParentSetConditions: true,
			rawDataIndex: 1,
		});
	aiStart : ApiAttributeInfo<SchedulingApiShift, DateTime> = new ApiAttributeInfo<SchedulingApiShift, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'start',
			nodeName: 'SHIFT_START',
			type: PApiType.DateTime,
			canSetByBusinessLogic: function(this : SchedulingApiShift) {
				{const conditionValue = this.isNewItem; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShift) {
				return [
					() => {
return this.api.validators.required(PApiType.DateTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShift) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiShift, DateTime> = new ApiAttributeInfo<SchedulingApiShift, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'end',
			nodeName: 'SHIFT_END',
			type: PApiType.DateTime,
			rawDataIndex: 5,
		});
	aiNeededMembersCount : ApiAttributeInfo<SchedulingApiShift, Integer> = new ApiAttributeInfo<SchedulingApiShift, Integer>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'neededMembersCount',
			nodeName: 'SHIFT_NEEDED_MEMBERS_COUNT',
			type: PApiType.Integer,
			rawDataIndex: 6,
		});
	aiShiftModelId : ApiAttributeInfo<SchedulingApiShift, Id> = new ApiAttributeInfo<SchedulingApiShift, Id>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'shiftModelId',
			nodeName: 'SHIFT_MODEL_ID',
			type: PApiType.Id,
			rawDataIndex: 7,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiShift, string> = new ApiAttributeInfo<SchedulingApiShift, string>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'description',
			nodeName: 'SHIFT_DESCRIPTION',
			type: PApiType.string,
			rawDataIndex: 10,
		});
	aiIsRemoved : ApiAttributeInfo<SchedulingApiShift, boolean> = new ApiAttributeInfo<SchedulingApiShift, boolean>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'isRemoved',
			nodeName: 'SHIFT_IS_REMOVED',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiShift, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiIsCourseCanceled : ApiAttributeInfo<SchedulingApiShift, boolean> = new ApiAttributeInfo<SchedulingApiShift, boolean>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'isCourseCanceled',
			nodeName: 'SHIFT_IS_COURSE_CANCELED',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShift) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShift) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShift, _nodeId : string) {return false;},
			rawDataIndex: 12,
		});
	aiIsCourseOnline : ApiAttributeInfo<SchedulingApiShift, boolean> = new ApiAttributeInfo<SchedulingApiShift, boolean>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'isCourseOnline',
			nodeName: 'SHIFT_IS_COURSE_ONLINE',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : SchedulingApiShift) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				{const conditionValue = !this.model.aiBookingDesiredDateSetting.isAvailable || this.model.bookingDesiredDateSetting !==
						SchedulingApiBookingDesiredDateSetting.ONLY_DESIRED_DATES; if(!conditionValue) return conditionValue === false ? {sourceString: 'Schichten/Termine von »${name}« können nicht im Plugin angezeigt werden, da entsprechend der Einstellungen in der Tätigkeit die Kunden nur Anfragen mit Wunschterminen schicken sollen – es sollen keine vorgegebenen Termine zur Auswahl stehen.', params: {name: this.model.name}} : undefined;}
				{const conditionValue = ((this as any)?._model /* TODO: PLANO-162226 */ && this.model.isCourseOnline); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShift) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShift) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShift, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});
	aiMinCourseParticipantCount : ApiAttributeInfo<SchedulingApiShift, Integer> = new ApiAttributeInfo<SchedulingApiShift, Integer>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'minCourseParticipantCount',
			nodeName: 'SHIFT_MIN_COURSE_PARTICIPANT_COUNT',
			type: PApiType.Integer,
			canSetByBusinessLogic: function(this : SchedulingApiShift) {
				{const conditionValue = ((this as any)?._model /* TODO: PLANO-162226 */ && this.model.isCourse); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShift) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.maxCourseParticipantCount, true, PApiType.Integer, this.aiMaxCourseParticipantCount.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShift) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiMaxCourseParticipantCount : ApiAttributeInfo<SchedulingApiShift, Integer> = new ApiAttributeInfo<SchedulingApiShift, Integer>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'maxCourseParticipantCount',
			nodeName: 'SHIFT_MAX_COURSE_PARTICIPANT_COUNT',
			type: PApiType.Integer,
			canSetByBusinessLogic: function(this : SchedulingApiShift) {
				{const conditionValue = ((this as any)?._model /* TODO: PLANO-162226 */ && this.model.isCourse); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShift) {
				return [
					() => {
return this.api.validators.min(() => this.minCourseParticipantCount, true, PApiType.Integer, this.aiMinCourseParticipantCount.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShift) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiCurrentCourseParticipantCount : ApiAttributeInfo<SchedulingApiShift, Integer> = new ApiAttributeInfo<SchedulingApiShift, Integer>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'currentCourseParticipantCount',
			nodeName: 'SHIFT_CURRENT_COURSE_PARTICIPANT_COUNT',
			type: PApiType.Integer,
			rawDataIndex: 16,
		});
	aiEarlyBirdAssignToMe : ApiAttributeInfo<SchedulingApiShift, boolean> = new ApiAttributeInfo<SchedulingApiShift, boolean>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'earlyBirdAssignToMe',
			nodeName: 'SHIFT_EARLY_BIRD_ASSIGN_TO_ME',
			type: PApiType.boolean,
			skipParentSetConditions: true,
			validations: function(this : SchedulingApiShift) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShift) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShift, _nodeId : string) {return false;},
			rawDataIndex: 17,
		});
	aiWorkingTimeCreationMethod : ApiAttributeInfo<SchedulingApiShift, SchedulingApiWorkingTimeCreationMethod> = new ApiAttributeInfo<SchedulingApiShift, SchedulingApiWorkingTimeCreationMethod>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'workingTimeCreationMethod',
			nodeName: 'SHIFT_WORKING_TIME_CREATION_METHOD',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiShift) {
				return ((this.api.pPermissionsService.userCanWrite(this)));
			},
			validations: function(this : SchedulingApiShift) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShift) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiSeriesStart : ApiAttributeInfo<SchedulingApiShift, DateTime> = new ApiAttributeInfo<SchedulingApiShift, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'seriesStart',
			nodeName: 'SHIFT_SERIES_START',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			rawDataIndex: 22,
		});
	aiCurrentCourseAttendeeCount : ApiAttributeInfo<SchedulingApiShift, Integer> = new ApiAttributeInfo<SchedulingApiShift, Integer>({
			apiObjWrapper: this as any as SchedulingApiShift,
			name: 'currentCourseAttendeeCount',
			nodeName: 'SHIFT_CURRENT_COURSE_ATTENDEE_COUNT',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			rawDataIndex: 23,
		});

	/**
	 * Shift preference of logged in user.
	 *
	 * @type {SchedulingApiShiftMemberPrefValue}
	 */
	get myPref() : SchedulingApiShiftMemberPrefValue | null {
		this.getterDebugValidations(this.aiMyPref, false);
		return this.data[1];
	}

	set myPref(v : SchedulingApiShiftMemberPrefValue | null) {
        this.setterImpl(1, v, 'myPref', false, null, null);
	}

	private assignableMembersWrapper : SchedulingApiShiftAssignableMembers = new SchedulingApiShiftAssignableMembers(this.api,
		this as unknown as SchedulingApiShift, false, false);
	public aiAssignableMembers = this.assignableMembersWrapper.aiThis;

	/**
	 * Only editable by client owners. Which members are assignable to this shift. Make sure this is deserialized before assignedMemberIds because this is a prerequisite. Can be used with "shiftChangeSelector".
	 */
	get assignableMembers() : SchedulingApiShiftAssignableMembers {
		this.getterDebugValidations(this.aiAssignableMembers, false);
		return this.assignableMembersWrapper;
	}

	set assignableMembersTestSetter(v : SchedulingApiShiftAssignableMembers) {
        this.setterImpl(2, v.rawData, 'assignableMembers', true, null, function(this : SchedulingApiShift) {this.assignableMembersWrapper = v;});
	}

	private assignedMemberIdsWrapper : SchedulingApiShiftAssignedMemberIds = new SchedulingApiShiftAssignedMemberIds(this.api,
		this as unknown as SchedulingApiShift, false, false);
	public aiAssignedMemberIds = this.assignedMemberIdsWrapper.aiThis;

	/**
	 * A list of the assigned members' ids. Can be used with "shiftChangeSelector".
	 */
	get assignedMemberIds() : SchedulingApiShiftAssignedMemberIds {
		this.getterDebugValidations(this.aiAssignedMemberIds, false);
		return this.assignedMemberIdsWrapper;
	}

	set assignedMemberIdsTestSetter(v : SchedulingApiShiftAssignedMemberIds) {
        this.setterImpl(3, v.rawData, 'assignedMemberIds', true, null, function(this : SchedulingApiShift) {this.assignedMemberIdsWrapper = v;});
	}

	/**
	 * Start of shift (inclusive). This value is only editable when creating a new shift to setting the series-start. Otherwise, you can instead edit "time.start".
	 *
	 * @type {DateTime}
	 */
	get start() : DateTime {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[4];
	}

	set start(v : DateTime) {
        this.setterImpl(4, v, 'start', false, null, function(this : SchedulingApiShift) {this.seriesStart = v;});
	}

	/**
	 * End of shift (exclusive). This value is not editable. You can instead edit "time.end".
	 *
	 * @type {DateTime}
	 */
	get end() : DateTime {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[5];
	}

	set end(v : DateTime) {
        this.setterImpl(5, v, 'end', false, null, null);
	}

	/**
	 * How many members should be assigned to this shift? Read only. Modify "neededMembersCountConf" instead.
	 *
	 * @type {Integer}
	 */
	get neededMembersCount() : Integer {
		this.getterDebugValidations(this.aiNeededMembersCount, false);
		return this.data[6];
	}

	set neededMembersCount(v : Integer) {
        this.setterImpl(6, v, 'neededMembersCount', false, null, null);
	}

	private shiftModelIdWrapper : Id = null!;

	/**
	 * Id of the shift model to which this shift belongs.
	 *
	 * @type {Id}
	 */
	get shiftModelId() : Id {
		this.getterDebugValidations(this.aiShiftModelId, false);
		return this.shiftModelIdWrapper;
	}

	set shiftModelId(v : Id) {
        this.setterImpl(7, v, 'shiftModelId', false, null, function(this : SchedulingApiShift) {this.shiftModelIdWrapper = v;});
	}

	private memberPrefsWrapper : SchedulingApiShiftMemberPrefs = new SchedulingApiShiftMemberPrefs(this.api,
		this as unknown as SchedulingApiShift, false, false);
	public aiMemberPrefs = this.memberPrefsWrapper.aiThis;

	/**
	 * Only send to client owners. A list of the member preferences for this shift.
	 */
	get memberPrefs() : SchedulingApiShiftMemberPrefs {
		this.getterDebugValidations(this.aiMemberPrefs, false);
		return this.memberPrefsWrapper;
	}

	set memberPrefsTestSetter(v : SchedulingApiShiftMemberPrefs) {
        this.setterImpl(8, v.rawData, 'memberPrefs', true, null, function(this : SchedulingApiShift) {this.memberPrefsWrapper = v;});
	}

	private packetShiftsWrapper : SchedulingApiShiftPacketShifts = new SchedulingApiShiftPacketShifts(this.api,
		this as unknown as SchedulingApiShift, false, false);
	public aiPacketShifts = this.packetShiftsWrapper.aiThis;

	/**
	 * A list of all packet shifts belonging to this shift. Note that this list is empty if this shift does not belong to a packet.
	 */
	get packetShifts() : SchedulingApiShiftPacketShifts {
		this.getterDebugValidations(this.aiPacketShifts, false);
		return this.packetShiftsWrapper;
	}

	set packetShiftsTestSetter(v : SchedulingApiShiftPacketShifts) {
        this.setterImpl(9, v.rawData, 'packetShifts', true, null, function(this : SchedulingApiShift) {this.packetShiftsWrapper = v;});
	}

	/**
	 * Description of the shift. Can be used with "shiftChangeSelector".
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[10];
	}

	set description(v : string | null) {
        this.setterImpl(10, v, 'description', false, null, null);
	}

	/**
	 * Is this shift removed?
	 *
	 * @type {boolean}
	 */
	get isRemoved() : boolean {
		this.getterDebugValidations(this.aiIsRemoved, false);
		return this.data[11];
	}

	set isRemoved(v : boolean) {
        this.setterImpl(11, v, 'isRemoved', false, null, null);
	}

	/**
	 * Is the course canceled?
	 *
	 * @type {boolean}
	 */
	get isCourseCanceled() : boolean {
		this.getterDebugValidations(this.aiIsCourseCanceled, false);
		return this.data[12];
	}

	set isCourseCanceled(v : boolean) {
        this.setterImpl(12, v, 'isCourseCanceled', false, null, null);
	}

	/**
	 * Is the course online? Can be used with "shiftChangeSelector".
	 *
	 * @type {boolean}
	 */
	get isCourseOnline() : boolean {
		this.getterDebugValidations(this.aiIsCourseOnline, false);
		return this.data[13];
	}

	set isCourseOnline(v : boolean) {
        this.setterImpl(13, v, 'isCourseOnline', false, null, null);
	}

	/**
	 * Minimal course participant count. Can be used with "shiftChangeSelector".
	 *
	 * @type {Integer}
	 */
	get minCourseParticipantCount() : Integer {
		this.getterDebugValidations(this.aiMinCourseParticipantCount, false);
		return this.data[14];
	}

	set minCourseParticipantCount(v : Integer) {
        this.setterImpl(14, v, 'minCourseParticipantCount', false, null, null);
	}

	/**
	 * Maximal course participant count. Can be used with "shiftChangeSelector".
	 *
	 * @type {Integer}
	 */
	get maxCourseParticipantCount() : Integer | null {
		this.getterDebugValidations(this.aiMaxCourseParticipantCount, false);
		return this.data[15];
	}

	set maxCourseParticipantCount(v : Integer | null) {
        this.setterImpl(15, v, 'maxCourseParticipantCount', false, null, null);
	}

	/**
	 * Current course participant count? Read only. Being determined by bookings.
	 *
	 * @type {Integer}
	 */
	get currentCourseParticipantCount() : Integer {
		this.getterDebugValidations(this.aiCurrentCourseParticipantCount, false);
		return this.data[16];
	}

	set currentCourseParticipantCount(v : Integer) {
        this.setterImpl(16, v, 'currentCourseParticipantCount', false, null, null);
	}

	/**
	 * Assign this shift by early bird assignment process to me.
	 *
	 * @type {boolean}
	 */
	get earlyBirdAssignToMe() : boolean {
		this.getterDebugValidations(this.aiEarlyBirdAssignToMe, false);
		return this.data[17];
	}

	set earlyBirdAssignToMe(v : boolean) {
        this.setterImpl(17, v, 'earlyBirdAssignToMe', false, null, null);
	}

	private neededMembersCountConfWrapper : SchedulingApiShiftNeededMembersCountConf = new SchedulingApiShiftNeededMembersCountConf(this.api,
		this as unknown as SchedulingApiShift);
	public aiNeededMembersCountConf = this.neededMembersCountConfWrapper.aiThis;

	/**
	 * (Detail) Configuration values from which "neededMembersCount" is calculated. Can be used with "changeSelector".
	 */
	get neededMembersCountConf() : SchedulingApiShiftNeededMembersCountConf {
		this.getterDebugValidations(this.aiNeededMembersCountConf, false);
		return this.neededMembersCountConfWrapper;
	}

	set neededMembersCountConfTestSetter(v : SchedulingApiShiftNeededMembersCountConf) {
        this.setterImpl(18, v.rawData, 'neededMembersCountConf', true, null, function(this : SchedulingApiShift) {this.neededMembersCountConfWrapper = v;});
	}

	private timeWrapper : SchedulingApiShiftTime = new SchedulingApiShiftTime(this.api,
		this as unknown as SchedulingApiShift);
	public aiTime = this.timeWrapper.aiThis;

	/**
	 * (Detail) The time when this shift will start (relative to current day). Can be used with "shiftChangeSelector".
	 */
	get time() : SchedulingApiShiftTime {
		this.getterDebugValidations(this.aiTime, false);
		return this.timeWrapper;
	}

	set timeTestSetter(v : SchedulingApiShiftTime) {
        this.setterImpl(19, v.rawData, 'time', true, null, function(this : SchedulingApiShift) {this.timeWrapper = v;});
	}

	/**
	 * (Detail) How is the corresponding working-time to this shift going to be created? Only send to client owners. Can be used with "shiftChangeSelector".
	 *
	 * @type {SchedulingApiWorkingTimeCreationMethod}
	 */
	get workingTimeCreationMethod() : SchedulingApiWorkingTimeCreationMethod {
		this.getterDebugValidations(this.aiWorkingTimeCreationMethod, false);
		return this.data[20];
	}

	set workingTimeCreationMethod(v : SchedulingApiWorkingTimeCreationMethod) {
        this.setterImpl(20, v, 'workingTimeCreationMethod', false, null, null);
	}

	private repetitionWrapper : SchedulingApiShiftRepetition = new SchedulingApiShiftRepetition(this.api,
		this as unknown as SchedulingApiShift);
	public aiRepetition = this.repetitionWrapper.aiThis;

	/**
	 * (Detail) Repetition pattern of this shift.
	 */
	get repetition() : SchedulingApiShiftRepetition {
		this.getterDebugValidations(this.aiRepetition, false);
		return this.repetitionWrapper;
	}

	set repetitionTestSetter(v : SchedulingApiShiftRepetition) {
        this.setterImpl(21, v.rawData, 'repetition', true, null, function(this : SchedulingApiShift) {this.repetitionWrapper = v;});
	}

	/**
	 * (Detail) The start of the series to which this shift belongs.
	 *
	 * @type {DateTime}
	 */
	get seriesStart() : DateTime {
		this.getterDebugValidations(this.aiSeriesStart, false);
		return this.data[22];
	}

	set seriesStart(v : DateTime) {
        this.setterImpl(22, v, 'seriesStart', false, null, null);
	}

	/**
	 * (Detail) Current course attendee count? Read only. Being determined by bookings.
	 *
	 * @type {Integer}
	 */
	get currentCourseAttendeeCount() : Integer {
		this.getterDebugValidations(this.aiCurrentCourseAttendeeCount, false);
		return this.data[23];
	}

	set currentCourseAttendeeCount(v : Integer) {
        this.setterImpl(23, v, 'currentCourseAttendeeCount', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.assignableMembersWrapper._fixIds(_idReplacements);
		this.assignedMemberIdsWrapper._fixIds(_idReplacements);
		this.data[7] = Meta.getReplacedId(this.data[7], _idReplacements);
		this.shiftModelIdWrapper = this.data[7] === null ? null! : Id.create(this.data[7]);
		this.memberPrefsWrapper._fixIds(_idReplacements);
		this.packetShiftsWrapper._fixIds(_idReplacements);
		this.neededMembersCountConfWrapper._fixIds(_idReplacements);
		this.timeWrapper._fixIds(_idReplacements);
		this.repetitionWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.assignableMembersWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.assignedMemberIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[7] : null, this.shiftModelIdWrapper))
			this.shiftModelIdWrapper = data && data[7] ? Id.create(data[7]) : null!;
		this.memberPrefsWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		this.packetShiftsWrapper._updateRawData(data && data[9] !== undefined ? data[9] : null, generateMissingData);
		this.neededMembersCountConfWrapper._updateRawData(data && data[18] !== undefined ? data[18] : null, generateMissingData);
		this.timeWrapper._updateRawData(data && data[19] !== undefined ? data[19] : null, generateMissingData);
		this.repetitionWrapper._updateRawData(data && data[21] !== undefined ? data[21] : null, generateMissingData);
	}

	protected get dni() : string {
		return '41';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '41', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiShiftMemberPrefValue {
	CANNOT = 1,
	CAN = 2,
	WANT = 3,
}
		 export class SchedulingApiShiftAssignableMembersBase extends ApiListWrapper<SchedulingApiShiftAssignableMember>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShift | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignableMembers');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftAssignableMember {
		return new SchedulingApiShiftAssignableMember(this.api, this as unknown as SchedulingApiShiftAssignableMembers, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShift | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftAssignableMembers(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '43';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftAssignableMember) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftAssignableMember {
		const newItem = new SchedulingApiShiftAssignableMember(this.api, this as unknown as SchedulingApiShiftAssignableMembers, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('assignableMembers');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftAssignableMembers, SchedulingApiShiftAssignableMembers> = new ApiAttributeInfo<SchedulingApiShiftAssignableMembers, SchedulingApiShiftAssignableMembers>({
			apiObjWrapper: this as any as SchedulingApiShiftAssignableMembers,
			name: 'assignableMembers',
			nodeName: 'SHIFT_ASSIGNABLE_MEMBERS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiShiftAssignableMembers) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftAssignableMembers, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 2,
		});
}

				 
export class SchedulingApiShiftAssignableMember extends ApiObjectWrapper<SchedulingApiShiftAssignableMember>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftAssignableMembers | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftAssignableMember> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftAssignableMember as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftAssignableMember, SchedulingApiShiftAssignableMember> = new ApiAttributeInfo<SchedulingApiShiftAssignableMember, SchedulingApiShiftAssignableMember>({
			apiObjWrapper: this as any as SchedulingApiShiftAssignableMember,
			name: 'shiftAssignableMember',
			nodeName: 'SHIFT_ASSIGNABLE_MEMBER',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftAssignableMember, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			
		});
	aiHourlyEarnings : ApiAttributeInfo<SchedulingApiShiftAssignableMember, ClientCurrency> = new ApiAttributeInfo<SchedulingApiShiftAssignableMember, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiShiftAssignableMember,
			name: 'hourlyEarnings',
			nodeName: 'SHIFT_ASSIGNABLE_MEMBER_EARNINGS',
			type: PApiType.ClientCurrency,
			rawDataIndex: 1,
		});
	aiMemberId : ApiAttributeInfo<SchedulingApiShiftAssignableMember, Id> = new ApiAttributeInfo<SchedulingApiShiftAssignableMember, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftAssignableMember,
			name: 'memberId',
			nodeName: 'SHIFT_ASSIGNABLE_MEMBER_ID',
			type: PApiType.Id,
			rawDataIndex: 2,
		});

	/**
	 * Members hourly earnings for this shift model
	 *
	 * @type {ClientCurrency}
	 */
	get hourlyEarnings() : ClientCurrency {
		this.getterDebugValidations(this.aiHourlyEarnings, false);
		return this.data[1];
	}

	set hourlyEarnings(v : ClientCurrency) {
        this.setterImpl(1, v, 'hourlyEarnings', false, null, null);
	}

	private memberIdWrapper : Id = null!;

	/**
	 * Id of assignable member
	 *
	 * @type {Id}
	 */
	get memberId() : Id {
		this.getterDebugValidations(this.aiMemberId, false);
		return this.memberIdWrapper;
	}

	set memberId(v : Id) {
        this.setterImpl(2, v, 'memberId', false, null, function(this : SchedulingApiShiftAssignableMember) {this.memberIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.memberIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_ASSIGNABLE_MEMBER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[2] : null, this.memberIdWrapper))
			this.memberIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
	}

	protected get dni() : string {
		return '65';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '65', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftAssignedMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShift | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignedMemberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiShift | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftAssignedMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '44';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftAssignedMemberIds, SchedulingApiShiftAssignedMemberIds> = new ApiAttributeInfo<SchedulingApiShiftAssignedMemberIds, SchedulingApiShiftAssignedMemberIds>({
			apiObjWrapper: this as any as SchedulingApiShiftAssignedMemberIds,
			name: 'assignedMemberIds',
			nodeName: 'SHIFT_ASSIGNED_MEMBER_IDS',
			type: PApiType.ApiList,
			hasPermissionToGet: function(this : SchedulingApiShiftAssignedMemberIds) {
				return ((!!this.data));
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftAssignedMemberIds) {
				{const conditionValue = this.parent!.assignableMembersAvailableForAssignment; if(!conditionValue) return conditionValue === false ? {sourceString: 'Es wurden noch keine Personen eingetragen, die »${name}« machen dürfen. Bei Fragen wende dich bitte an deine Admins.', params: {name: this.parent!.model.name}} : undefined;}
				{const conditionValue = this.parent!.aiAssignedMemberIds.isAvailable; if(!conditionValue) return conditionValue === false ? {sourceString: 'Die Besetzung dieser Schicht ist gerade in Bearbeitung und wird dir daher nicht angezeigt. Bei Fragen wende dich bitte an deine Admins.', params: false} : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftAssignedMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 3,
		});
	aiShiftAssignedMemberId : ApiAttributeInfo<SchedulingApiShiftAssignedMemberIds, Id> = new ApiAttributeInfo<SchedulingApiShiftAssignedMemberIds, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftAssignedMemberIds,
			name: 'shiftAssignedMemberId',
			nodeName: 'SHIFT_ASSIGNED_MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 export class SchedulingApiShiftMemberPrefs extends ApiListWrapper<SchedulingApiShiftMemberPref>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShift | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'memberPrefs');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftMemberPref {
		return new SchedulingApiShiftMemberPref(this.api, this as unknown as SchedulingApiShiftMemberPrefs, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShift | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftMemberPrefs(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '49';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftMemberPref) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftMemberPref {
		const newItem = new SchedulingApiShiftMemberPref(this.api, this as unknown as SchedulingApiShiftMemberPrefs, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('memberPrefs');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftMemberPrefs, SchedulingApiShiftMemberPrefs> = new ApiAttributeInfo<SchedulingApiShiftMemberPrefs, SchedulingApiShiftMemberPrefs>({
			apiObjWrapper: this as any as SchedulingApiShiftMemberPrefs,
			name: 'memberPrefs',
			nodeName: 'SHIFT_MEMBER_PREFS',
			type: PApiType.ApiList,
			hasPermissionToGet: function(this : SchedulingApiShiftMemberPrefs) {
				return ((this.api.pPermissionsService.userCanWrite(this.parent!)));
			},
			defaultValue: function(this : SchedulingApiShiftMemberPrefs, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 8,
		});
}

				 
export class SchedulingApiShiftMemberPref extends ApiObjectWrapper<SchedulingApiShiftMemberPref>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftMemberPrefs | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftMemberPref> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftMemberPref as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftMemberPref, SchedulingApiShiftMemberPref> = new ApiAttributeInfo<SchedulingApiShiftMemberPref, SchedulingApiShiftMemberPref>({
			apiObjWrapper: this as any as SchedulingApiShiftMemberPref,
			name: 'shiftMemberPref',
			nodeName: 'SHIFT_MEMBER_PREF',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftMemberPref, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiMemberId : ApiAttributeInfo<SchedulingApiShiftMemberPref, Id> = new ApiAttributeInfo<SchedulingApiShiftMemberPref, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftMemberPref,
			name: 'memberId',
			nodeName: 'SHIFT_MEMBER_PREF_MEMBER_ID',
			type: PApiType.Id,
			rawDataIndex: 1,
		});
	aiPref : ApiAttributeInfo<SchedulingApiShiftMemberPref, SchedulingApiShiftMemberPrefValue> = new ApiAttributeInfo<SchedulingApiShiftMemberPref, SchedulingApiShiftMemberPrefValue>({
			apiObjWrapper: this as any as SchedulingApiShiftMemberPref,
			name: 'pref',
			nodeName: 'SHIFT_MEMBER_PREF_VALUE',
			type: PApiType.Enum,
			rawDataIndex: 2,
		});

	private memberIdWrapper : Id = null!;

	/**
	 * Id of the member
	 *
	 * @type {Id}
	 */
	get memberId() : Id {
		this.getterDebugValidations(this.aiMemberId, false);
		return this.memberIdWrapper;
	}

	set memberId(v : Id) {
        this.setterImpl(1, v, 'memberId', false, null, function(this : SchedulingApiShiftMemberPref) {this.memberIdWrapper = v;});
	}

	/**
	 * Preference of the member
	 *
	 * @type {SchedulingApiShiftMemberPrefValue}
	 */
	get pref() : SchedulingApiShiftMemberPrefValue {
		this.getterDebugValidations(this.aiPref, false);
		return this.data[2];
	}

	set pref(v : SchedulingApiShiftMemberPrefValue) {
        this.setterImpl(2, v, 'pref', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[1] = Meta.getReplacedId(this.data[1], _idReplacements);
		this.memberIdWrapper = this.data[1] === null ? null! : Id.create(this.data[1]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MEMBER_PREF should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[1] : null, this.memberIdWrapper))
			this.memberIdWrapper = data && data[1] ? Id.create(data[1]) : null!;
	}

	protected get dni() : string {
		return '74';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '74', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftPacketShifts extends ApiListWrapper<SchedulingApiShiftPacketShift>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShift | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'packetShifts');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftPacketShift {
		return new SchedulingApiShiftPacketShift(this.api, this as unknown as SchedulingApiShiftPacketShifts, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShift | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftPacketShifts(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '50';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftPacketShift) => void) | null = null, _backendId : ShiftId | null = null) : SchedulingApiShiftPacketShift {
		const newItem = new SchedulingApiShiftPacketShift(this.api, this as unknown as SchedulingApiShiftPacketShifts, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('packetShifts');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftPacketShifts, SchedulingApiShiftPacketShifts> = new ApiAttributeInfo<SchedulingApiShiftPacketShifts, SchedulingApiShiftPacketShifts>({
			apiObjWrapper: this as any as SchedulingApiShiftPacketShifts,
			name: 'packetShifts',
			nodeName: 'SHIFT_PACKET_SHIFTS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiShiftPacketShifts, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 9,
		});
}

				 
export class SchedulingApiShiftPacketShift extends ApiObjectWrapper<SchedulingApiShiftPacketShift>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftPacketShifts | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftPacketShift> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftPacketShift as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftPacketShift, SchedulingApiShiftPacketShift> = new ApiAttributeInfo<SchedulingApiShiftPacketShift, SchedulingApiShiftPacketShift>({
			apiObjWrapper: this as any as SchedulingApiShiftPacketShift,
			name: 'shiftPacketShift',
			nodeName: 'SHIFT_PACKET_SHIFT',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftPacketShift, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiStart : ApiAttributeInfo<SchedulingApiShiftPacketShift, DateTime> = new ApiAttributeInfo<SchedulingApiShiftPacketShift, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShiftPacketShift,
			name: 'start',
			nodeName: 'SHIFT_PACKET_SHIFT_START',
			type: PApiType.DateTime,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiShiftPacketShift, DateTime> = new ApiAttributeInfo<SchedulingApiShiftPacketShift, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShiftPacketShift,
			name: 'end',
			nodeName: 'SHIFT_PACKET_SHIFT_END',
			type: PApiType.DateTime,
			rawDataIndex: 2,
		});

	/**
	 * Start of the packet shift (inclusive). This value is not editable.
	 *
	 * @type {DateTime}
	 */
	get start() : DateTime {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set start(v : DateTime) {
        this.setterImpl(1, v, 'start', false, null, null);
	}

	/**
	 * End of the packet shift (exclusive). This value is not editable.
	 *
	 * @type {DateTime}
	 */
	get end() : DateTime {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set end(v : DateTime) {
        this.setterImpl(2, v, 'end', false, null, null);
	}

	private assignedMemberIdsWrapper : SchedulingApiShiftPacketShiftAssignedMemberIds = new SchedulingApiShiftPacketShiftAssignedMemberIds(this.api,
		this as unknown as SchedulingApiShiftPacketShift, false, false);
	public aiAssignedMemberIds = this.assignedMemberIdsWrapper.aiThis;

	/**
	 * List of member ids being assigned to this shift.
	 */
	get assignedMemberIds() : SchedulingApiShiftPacketShiftAssignedMemberIds {
		this.getterDebugValidations(this.aiAssignedMemberIds, false);
		return this.assignedMemberIdsWrapper;
	}

	set assignedMemberIdsTestSetter(v : SchedulingApiShiftPacketShiftAssignedMemberIds) {
        this.setterImpl(3, v.rawData, 'assignedMemberIds', true, null, function(this : SchedulingApiShiftPacketShift) {this.assignedMemberIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.assignedMemberIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_PACKET_SHIFT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.assignedMemberIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '68';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '68', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftPacketShiftAssignedMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftPacketShift | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignedMemberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiShiftPacketShift | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftPacketShiftAssignedMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '71';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftPacketShiftAssignedMemberIds, SchedulingApiShiftPacketShiftAssignedMemberIds> = new ApiAttributeInfo<SchedulingApiShiftPacketShiftAssignedMemberIds, SchedulingApiShiftPacketShiftAssignedMemberIds>({
			apiObjWrapper: this as any as SchedulingApiShiftPacketShiftAssignedMemberIds,
			name: 'assignedMemberIds',
			nodeName: 'SHIFT_PACKET_SHIFT_ASSIGNED_MEMBER_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiShiftPacketShiftAssignedMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 3,
		});
	aiShiftPacketShiftAssignedMemberId : ApiAttributeInfo<SchedulingApiShiftPacketShiftAssignedMemberIds, Id> = new ApiAttributeInfo<SchedulingApiShiftPacketShiftAssignedMemberIds, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftPacketShiftAssignedMemberIds,
			name: 'shiftPacketShiftAssignedMemberId',
			nodeName: 'SHIFT_PACKET_SHIFT_ASSIGNED_MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 
export class SchedulingApiShiftNeededMembersCountConf extends ApiObjectWrapper<SchedulingApiShiftNeededMembersCountConf>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShift | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftNeededMembersCountConf> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftNeededMembersCountConf as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, SchedulingApiShiftNeededMembersCountConf> = new ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, SchedulingApiShiftNeededMembersCountConf>({
			apiObjWrapper: this as any as SchedulingApiShiftNeededMembersCountConf,
			name: 'neededMembersCountConf',
			nodeName: 'SHIFT_NEEDED_MEMBERS_COUNT_CONF',
			type: PApiType.ApiObject,
			isDetailedAttribute: true,
			defaultValue: function(this : SchedulingApiShiftNeededMembersCountConf, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 18,
		});
	aiNeededMembersCount : ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, Integer> = new ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftNeededMembersCountConf,
			name: 'neededMembersCount',
			nodeName: 'SHIFT_NEEDED_MEMBERS_COUNT_CONF_NEEDED_MEMBERS_COUNT',
			type: PApiType.Integer,
			validations: function(this : SchedulingApiShiftNeededMembersCountConf) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftNeededMembersCountConf) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiPerXParticipants : ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, Integer> = new ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftNeededMembersCountConf,
			name: 'perXParticipants',
			nodeName: 'SHIFT_NEEDED_MEMBERS_COUNT_CONF_PER_X_PARTICIPANTS',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftNeededMembersCountConf) {
				return ((((this.parent as any)?._model /* TODO: PLANO-162226 */ && this.parent!.model.isCourse)));
			},
			validations: function(this : SchedulingApiShiftNeededMembersCountConf) {
				return [
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftNeededMembersCountConf) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiIsZeroNotReachedMinParticipantsCount : ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, boolean> = new ApiAttributeInfo<SchedulingApiShiftNeededMembersCountConf, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftNeededMembersCountConf,
			name: 'isZeroNotReachedMinParticipantsCount',
			nodeName: 'SHIFT_NEEDED_MEMBERS_COUNT_CONF_IS_ZERO_NOT_REACHED_MIN_PARTICIPANTS_COUNT',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiShiftNeededMembersCountConf, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});

	/**
	 * See perXParticipants for documentation.
	 *
	 * @type {Integer}
	 */
	get neededMembersCount() : Integer {
		this.getterDebugValidations(this.aiNeededMembersCount, false);
		return this.data[1];
	}

	set neededMembersCount(v : Integer) {
        this.setterImpl(1, v, 'neededMembersCount', false, null, null);
	}

	/**
	 * If this value is "null" then the final needed-members-count will be just "neededMembersCount". Otherwise it will be "neededMembersCount * ceil(participant-count / perXParticipants)". The calculated needed-members-count can be retrieved in shift.neededMembersCount.
	 *
	 * @type {Integer}
	 */
	get perXParticipants() : Integer | null {
		this.getterDebugValidations(this.aiPerXParticipants, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiPerXParticipants.defaultValue;
			
		}
		return this.data[2];
	}

	set perXParticipants(v : Integer | null) {
        this.setterImpl(2, v, 'perXParticipants', false, null, null);
	}

	/**
	 * If "true" then needed-members-count will be zero when participants count has not reached min-participants-count.
	 *
	 * @type {boolean}
	 */
	get isZeroNotReachedMinParticipantsCount() : boolean {
		this.getterDebugValidations(this.aiIsZeroNotReachedMinParticipantsCount, false);
		return this.data[3];
	}

	set isZeroNotReachedMinParticipantsCount(v : boolean) {
        this.setterImpl(3, v, 'isZeroNotReachedMinParticipantsCount', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '59';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '59', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiShiftTime extends ApiObjectWrapper<SchedulingApiShiftTime>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShift | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftTime> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftTime as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftTime, SchedulingApiShiftTime> = new ApiAttributeInfo<SchedulingApiShiftTime, SchedulingApiShiftTime>({
			apiObjWrapper: this as any as SchedulingApiShiftTime,
			name: 'time',
			nodeName: 'SHIFT_TIME',
			type: PApiType.ApiObject,
			isDetailedAttribute: true,
			defaultValue: function(this : SchedulingApiShiftTime, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 19,
		});
	aiStart : ApiAttributeInfo<SchedulingApiShiftTime, LocalTime> = new ApiAttributeInfo<SchedulingApiShiftTime, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiShiftTime,
			name: 'start',
			nodeName: 'SHIFT_TIME_START',
			type: PApiType.LocalTime,
			validations: function(this : SchedulingApiShiftTime) {
				return [
					() => {
return this.api.validators.max(() => this.end, false, PApiType.LocalTime, this.aiEnd.id, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftTime) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiShiftTime, LocalTime> = new ApiAttributeInfo<SchedulingApiShiftTime, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiShiftTime,
			name: 'end',
			nodeName: 'SHIFT_TIME_END',
			type: PApiType.LocalTime,
			validations: function(this : SchedulingApiShiftTime) {
				return [
					() => {
return this.api.validators.min(() => this.start, false, PApiType.LocalTime, this.aiStart.id, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftTime) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});

	/**
	 * The time when this shift will start. Note that you can edit this value but not "shift.start".
	 *
	 * @type {LocalTime}
	 */
	get start() : LocalTime {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set start(v : LocalTime) {
        this.setterImpl(1, v, 'start', false, null, null);
	}

	/**
	 * The time when this shift will end. Note that you can edit this value but not "shift.end".
	 *
	 * @type {LocalTime}
	 */
	get end() : LocalTime {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set end(v : LocalTime) {
        this.setterImpl(2, v, 'end', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '60';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '60', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiWorkingTimeCreationMethod {
	TIME_STAMP = 1,
	AUTOMATIC = 2,
}
		 
export class SchedulingApiShiftRepetitionBase extends ApiObjectWrapper<SchedulingApiShiftRepetition>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShift | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftRepetition> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftRepetition as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftRepetition, SchedulingApiShiftRepetition> = new ApiAttributeInfo<SchedulingApiShiftRepetition, SchedulingApiShiftRepetition>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'repetition',
			nodeName: 'SHIFT_REPETITION',
			type: PApiType.ApiObject,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				{const conditionValue = this.isNewItem; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => { return SchedulingApiShiftModelRepetition.checkEveryXWeeksRepetitionOneWeekDayIsSelected(this) },
					() => { return SchedulingApiShiftModelRepetition.checkIfEveryXWeeksRepetitionAndWeekDaysSelectionFitsTogether(this) },
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 21,
		});
	aiType : ApiAttributeInfo<SchedulingApiShiftRepetition, SchedulingApiShiftRepetitionType> = new ApiAttributeInfo<SchedulingApiShiftRepetition, SchedulingApiShiftRepetitionType>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'type',
			nodeName: 'SHIFT_REPETITION_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiX : ApiAttributeInfo<SchedulingApiShiftRepetition, Integer> = new ApiAttributeInfo<SchedulingApiShiftRepetition, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'x',
			nodeName: 'SHIFT_REPETITION_X',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiRepetitionEndMode : ApiAttributeInfo<SchedulingApiShiftRepetition, ShiftModelRepetitionEndMode> = new ApiAttributeInfo<SchedulingApiShiftRepetition, ShiftModelRepetitionEndMode>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'repetitionEndMode',
			nodeName: 'SHIFT_REPETITION_REPETITION_END_MODE',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return ShiftModelRepetitionEndMode.NEVER;},
			rawDataIndex: 3,
		});
	aiEndsAfterRepetitionCount : ApiAttributeInfo<SchedulingApiShiftRepetition, Integer> = new ApiAttributeInfo<SchedulingApiShiftRepetition, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'endsAfterRepetitionCount',
			nodeName: 'SHIFT_REPETITION_ENDS_AFTER_REPETITION_COUNT',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE)&&(this.repetitionEndMode === ShiftModelRepetitionEndMode.AFTER_X_TIMES));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
		if(((this.repetitionEndMode === ShiftModelRepetitionEndMode.AFTER_X_TIMES)))
		{
			return this.api.validators.min(2, true, PApiType.Integer, undefined, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.max(200, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiEndsAfterDate : ApiAttributeInfo<SchedulingApiShiftRepetition, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiShiftRepetition, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'endsAfterDate',
			nodeName: 'SHIFT_REPETITION_ENDS_AFTER_DATE',
			type: PApiType.DateExclusiveEnd,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE)&&(this.repetitionEndMode === ShiftModelRepetitionEndMode.ENDS_AFTER_DATE));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
		if(((this.repetitionEndMode === ShiftModelRepetitionEndMode.ENDS_AFTER_DATE)))
		{
			return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(this.parent!.seriesStart, false, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiIsRepeatingOnMonday : ApiAttributeInfo<SchedulingApiShiftRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'isRepeatingOnMonday',
			nodeName: 'SHIFT_REPETITION_ON_MONDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return false;},
			rawDataIndex: 7,
		});
	aiIsRepeatingOnTuesday : ApiAttributeInfo<SchedulingApiShiftRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'isRepeatingOnTuesday',
			nodeName: 'SHIFT_REPETITION_ON_TUESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return false;},
			rawDataIndex: 8,
		});
	aiIsRepeatingOnWednesday : ApiAttributeInfo<SchedulingApiShiftRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'isRepeatingOnWednesday',
			nodeName: 'SHIFT_REPETITION_ON_WEDNESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return false;},
			rawDataIndex: 9,
		});
	aiIsRepeatingOnThursday : ApiAttributeInfo<SchedulingApiShiftRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'isRepeatingOnThursday',
			nodeName: 'SHIFT_REPETITION_ON_THURSDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return false;},
			rawDataIndex: 10,
		});
	aiIsRepeatingOnFriday : ApiAttributeInfo<SchedulingApiShiftRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'isRepeatingOnFriday',
			nodeName: 'SHIFT_REPETITION_ON_FRIDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiIsRepeatingOnSaturday : ApiAttributeInfo<SchedulingApiShiftRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'isRepeatingOnSaturday',
			nodeName: 'SHIFT_REPETITION_ON_SATURDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return false;},
			rawDataIndex: 12,
		});
	aiIsRepeatingOnSunday : ApiAttributeInfo<SchedulingApiShiftRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetition,
			name: 'isRepeatingOnSunday',
			nodeName: 'SHIFT_REPETITION_ON_SUNDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetition, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});

	/**
	 * repetition type. "NONE" means no repetition
	 *
	 * @type {SchedulingApiShiftRepetitionType}
	 */
	get type() : SchedulingApiShiftRepetitionType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiShiftRepetitionType) {
        this.setterImpl(1, v, 'type', false, null, null);
	}

	/**
	 * This is the "x" value defined by the type property (e.g. EVERY_X_DAYS or EVERY_X_WEEKS).
	 *
	 * @type {Integer}
	 */
	get x() : Integer {
		this.getterDebugValidations(this.aiX, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiX.defaultValue;
			
		}
		return this.data[2];
	}

	set x(v : Integer) {
        this.setterImpl(2, v, 'x', false, null, null);
	}

	/**
	 * What is the criteria to end the repetition?
	 *
	 * @type {ShiftModelRepetitionEndMode}
	 */
	get repetitionEndMode() : ShiftModelRepetitionEndMode {
		this.getterDebugValidations(this.aiRepetitionEndMode, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiRepetitionEndMode.defaultValue;
			
		}
		return this.data[3];
	}

	set repetitionEndMode(v : ShiftModelRepetitionEndMode) {
        this.setterImpl(3, v, 'repetitionEndMode', false, null, null);
	}

	/**
	 * Should the repetition end after a given count? If not, set to "0".
	 *
	 * @type {Integer}
	 */
	get endsAfterRepetitionCount() : Integer {
		this.getterDebugValidations(this.aiEndsAfterRepetitionCount, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiEndsAfterRepetitionCount.defaultValue;
			
		}
		return this.data[4];
	}

	set endsAfterRepetitionCount(v : Integer) {
        this.setterImpl(4, v, 'endsAfterRepetitionCount', false, null, null);
	}

	/**
	 * Should the repetition end after a given date?
	 *
	 * @type {DateExclusiveEnd}
	 */
	get endsAfterDate() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiEndsAfterDate, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiEndsAfterDate.defaultValue;
			
		}
		return this.data[5];
	}

	set endsAfterDate(v : DateExclusiveEnd | null) {
        this.setterImpl(5, v, 'endsAfterDate', false, null, null);
	}

	private packetRepetitionWrapper : SchedulingApiShiftRepetitionPacket = new SchedulingApiShiftRepetitionPacket(this.api,
		this as unknown as SchedulingApiShiftRepetition);
	public aiPacketRepetition = this.packetRepetitionWrapper.aiThis;

	/**
	 * The packet repetition pattern.
	 */
	get packetRepetition() : SchedulingApiShiftRepetitionPacket {
		this.getterDebugValidations(this.aiPacketRepetition, false);
		return this.packetRepetitionWrapper;
	}

	set packetRepetitionTestSetter(v : SchedulingApiShiftRepetitionPacket) {
        this.setterImpl(6, v.rawData, 'packetRepetition', true, null, function(this : SchedulingApiShiftRepetition) {this.packetRepetitionWrapper = v;});
	}

	/**
	 * Is there a repetition on Monday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnMonday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnMonday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiIsRepeatingOnMonday.defaultValue;
			
		}
		return this.data[7];
	}

	set isRepeatingOnMonday(v : boolean) {
        this.setterImpl(7, v, 'isRepeatingOnMonday', false, null, null);
	}

	/**
	 * Is there a repetition on Tuesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnTuesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnTuesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiIsRepeatingOnTuesday.defaultValue;
			
		}
		return this.data[8];
	}

	set isRepeatingOnTuesday(v : boolean) {
        this.setterImpl(8, v, 'isRepeatingOnTuesday', false, null, null);
	}

	/**
	 * Is there a repetition on Wednesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnWednesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnWednesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[9] === undefined) {
			this.data[9] = this.aiIsRepeatingOnWednesday.defaultValue;
			
		}
		return this.data[9];
	}

	set isRepeatingOnWednesday(v : boolean) {
        this.setterImpl(9, v, 'isRepeatingOnWednesday', false, null, null);
	}

	/**
	 * Is there a repetition on Thursday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnThursday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnThursday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[10] === undefined) {
			this.data[10] = this.aiIsRepeatingOnThursday.defaultValue;
			
		}
		return this.data[10];
	}

	set isRepeatingOnThursday(v : boolean) {
        this.setterImpl(10, v, 'isRepeatingOnThursday', false, null, null);
	}

	/**
	 * Is there a repetition on Friday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnFriday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnFriday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[11] === undefined) {
			this.data[11] = this.aiIsRepeatingOnFriday.defaultValue;
			
		}
		return this.data[11];
	}

	set isRepeatingOnFriday(v : boolean) {
        this.setterImpl(11, v, 'isRepeatingOnFriday', false, null, null);
	}

	/**
	 * Is there a repetition on Saturday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSaturday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSaturday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[12] === undefined) {
			this.data[12] = this.aiIsRepeatingOnSaturday.defaultValue;
			
		}
		return this.data[12];
	}

	set isRepeatingOnSaturday(v : boolean) {
        this.setterImpl(12, v, 'isRepeatingOnSaturday', false, null, null);
	}

	/**
	 * Is there a repetition on Sunday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSunday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSunday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[13] === undefined) {
			this.data[13] = this.aiIsRepeatingOnSunday.defaultValue;
			
		}
		return this.data[13];
	}

	set isRepeatingOnSunday(v : boolean) {
        this.setterImpl(13, v, 'isRepeatingOnSunday', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.packetRepetitionWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.packetRepetitionWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
	}

	protected get dni() : string {
		return '62';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '62', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiShiftRepetitionType {
	NONE = 1,
	EVERY_X_DAYS = 2,
	EVERY_X_WEEKS = 3,
	EVERY_X_MONTHS = 4,
	EVERY_X_YEARS = 5,
}
export enum ShiftModelRepetitionEndMode {
	NEVER = 1,
	AFTER_X_TIMES = 2,
	ENDS_AFTER_DATE = 3,
}
		 
export class SchedulingApiShiftRepetitionPacket extends ApiObjectWrapper<SchedulingApiShiftRepetitionPacket>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftRepetition | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftRepetitionPacket> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftRepetitionPacket as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, SchedulingApiShiftRepetitionPacket> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, SchedulingApiShiftRepetitionPacket>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'packetRepetition',
			nodeName: 'SHIFT_REPETITION_PACKET',
			type: PApiType.ApiObject,
			canSetByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				{const conditionValue = this.isNewItem; if(!conditionValue) return conditionValue === false ? {sourceString: 'Die Einstellungen für das Schicht-Paket kannst du nur in der Schichtvorlage oder beim Erstellen neuer Schichten bearbeiten. Hier ist es leider nicht mehr möglich.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => { return SchedulingApiShiftModelRepetition.checkEveryXWeeksRepetitionOneWeekDayIsSelected(this) },
					() => { return SchedulingApiShiftModelRepetition.checkIfEveryXWeeksRepetitionAndWeekDaysSelectionFitsTogether(this) },
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 6,
		});
	aiType : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, SchedulingApiShiftRepetitionType> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, SchedulingApiShiftRepetitionType>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'type',
			nodeName: 'SHIFT_REPETITION_PACKET_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiX : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, Integer> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'x',
			nodeName: 'SHIFT_REPETITION_PACKET_X',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiEndsAfterRepetitionCount : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, Integer> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'endsAfterRepetitionCount',
			nodeName: 'SHIFT_REPETITION_PACKET_ENDS_AFTER_REPETITION_COUNT',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(2, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(200, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiIsRepeatingOnMonday : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'isRepeatingOnMonday',
			nodeName: 'SHIFT_REPETITION_PACKET_ON_MONDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiIsRepeatingOnTuesday : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'isRepeatingOnTuesday',
			nodeName: 'SHIFT_REPETITION_PACKET_ON_TUESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});
	aiIsRepeatingOnWednesday : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'isRepeatingOnWednesday',
			nodeName: 'SHIFT_REPETITION_PACKET_ON_WEDNESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 6,
		});
	aiIsRepeatingOnThursday : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'isRepeatingOnThursday',
			nodeName: 'SHIFT_REPETITION_PACKET_ON_THURSDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 7,
		});
	aiIsRepeatingOnFriday : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'isRepeatingOnFriday',
			nodeName: 'SHIFT_REPETITION_PACKET_ON_FRIDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 8,
		});
	aiIsRepeatingOnSaturday : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'isRepeatingOnSaturday',
			nodeName: 'SHIFT_REPETITION_PACKET_ON_SATURDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 9,
		});
	aiIsRepeatingOnSunday : ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftRepetitionPacket,
			name: 'isRepeatingOnSunday',
			nodeName: 'SHIFT_REPETITION_PACKET_ON_SUNDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 10,
		});

	/**
	 * repetition type. "NONE" means no repetition
	 *
	 * @type {SchedulingApiShiftRepetitionType}
	 */
	get type() : SchedulingApiShiftRepetitionType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiShiftRepetitionType) {
        this.setterImpl(1, v, 'type', false, null, null);
	}

	/**
	 * This is the "x" value defined by the type property (e.g. EVERY_X_DAYS or EVERY_X_WEEKS).
	 *
	 * @type {Integer}
	 */
	get x() : Integer {
		this.getterDebugValidations(this.aiX, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiX.defaultValue;
			
		}
		return this.data[2];
	}

	set x(v : Integer) {
        this.setterImpl(2, v, 'x', false, null, null);
	}

	/**
	 * Should the repetition end after a given count? If not, set to "0".
	 *
	 * @type {Integer}
	 */
	get endsAfterRepetitionCount() : Integer {
		this.getterDebugValidations(this.aiEndsAfterRepetitionCount, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiEndsAfterRepetitionCount.defaultValue;
			
		}
		return this.data[3];
	}

	set endsAfterRepetitionCount(v : Integer) {
        this.setterImpl(3, v, 'endsAfterRepetitionCount', false, null, null);
	}

	/**
	 * Is there a repetition on Monday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnMonday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnMonday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiIsRepeatingOnMonday.defaultValue;
			
		}
		return this.data[4];
	}

	set isRepeatingOnMonday(v : boolean) {
        this.setterImpl(4, v, 'isRepeatingOnMonday', false, null, null);
	}

	/**
	 * Is there a repetition on Tuesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnTuesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnTuesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiIsRepeatingOnTuesday.defaultValue;
			
		}
		return this.data[5];
	}

	set isRepeatingOnTuesday(v : boolean) {
        this.setterImpl(5, v, 'isRepeatingOnTuesday', false, null, null);
	}

	/**
	 * Is there a repetition on Wednesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnWednesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnWednesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiIsRepeatingOnWednesday.defaultValue;
			
		}
		return this.data[6];
	}

	set isRepeatingOnWednesday(v : boolean) {
        this.setterImpl(6, v, 'isRepeatingOnWednesday', false, null, null);
	}

	/**
	 * Is there a repetition on Thursday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnThursday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnThursday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiIsRepeatingOnThursday.defaultValue;
			
		}
		return this.data[7];
	}

	set isRepeatingOnThursday(v : boolean) {
        this.setterImpl(7, v, 'isRepeatingOnThursday', false, null, null);
	}

	/**
	 * Is there a repetition on Friday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnFriday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnFriday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiIsRepeatingOnFriday.defaultValue;
			
		}
		return this.data[8];
	}

	set isRepeatingOnFriday(v : boolean) {
        this.setterImpl(8, v, 'isRepeatingOnFriday', false, null, null);
	}

	/**
	 * Is there a repetition on Saturday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSaturday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSaturday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[9] === undefined) {
			this.data[9] = this.aiIsRepeatingOnSaturday.defaultValue;
			
		}
		return this.data[9];
	}

	set isRepeatingOnSaturday(v : boolean) {
        this.setterImpl(9, v, 'isRepeatingOnSaturday', false, null, null);
	}

	/**
	 * Is there a repetition on Sunday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSunday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSunday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[10] === undefined) {
			this.data[10] = this.aiIsRepeatingOnSunday.defaultValue;
			
		}
		return this.data[10];
	}

	set isRepeatingOnSunday(v : boolean) {
        this.setterImpl(10, v, 'isRepeatingOnSunday', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '95';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '95', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkingTimesBase extends ApiListWrapper<SchedulingApiWorkingTime>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'workingTimes');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkingTime {
		return new SchedulingApiWorkingTime(this.api, this as unknown as SchedulingApiWorkingTimes, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkingTimes(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '4';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkingTime) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkingTime {
		const newItem = new SchedulingApiWorkingTime(this.api, this as unknown as SchedulingApiWorkingTimes, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('workingTimes');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkingTimes, SchedulingApiWorkingTimes> = new ApiAttributeInfo<SchedulingApiWorkingTimes, SchedulingApiWorkingTimes>({
			apiObjWrapper: this as any as SchedulingApiWorkingTimes,
			name: 'workingTimes',
			nodeName: 'WORKING_TIMES',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiWorkingTimes) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiWorkingTimes, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 3,
		});
}

				 
export class SchedulingApiWorkingTimeBase extends ApiObjectWrapper<SchedulingApiWorkingTime>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkingTimes | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkingTime> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkingTime as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkingTime, SchedulingApiWorkingTime> = new ApiAttributeInfo<SchedulingApiWorkingTime, SchedulingApiWorkingTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'workingTime',
			nodeName: 'WORKING_TIME',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkingTime, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiComment : ApiAttributeInfo<SchedulingApiWorkingTime, string> = new ApiAttributeInfo<SchedulingApiWorkingTime, string>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'comment',
			nodeName: 'WORKING_TIME_COMMENT',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiRegularPauseDuration : ApiAttributeInfo<SchedulingApiWorkingTime, Duration> = new ApiAttributeInfo<SchedulingApiWorkingTime, Duration>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'regularPauseDuration',
			nodeName: 'WORKING_TIME_REGULAR_PAUSE_DURATION',
			type: PApiType.Duration,
			validations: function(this : SchedulingApiWorkingTime) {
				return [
					() => {
return this.api.validators.required(PApiType.Duration, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => (this.time.start !== null! && this.time.end !== null!) ? this.time.end - this.time.start : null /* TODO: PLANO-151346 */, false, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkingTime) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkingTime, _nodeId : string) {return 0;},
			rawDataIndex: 3,
		});
	aiMemberId : ApiAttributeInfo<SchedulingApiWorkingTime, Id> = new ApiAttributeInfo<SchedulingApiWorkingTime, Id>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'memberId',
			nodeName: 'WORKING_TIME_MEMBER_ID',
			type: PApiType.Id,
			validations: function(this : SchedulingApiWorkingTime) {
				return [
					() => {
return this.api.validators.required(PApiType.Id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkingTime) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiAutomaticPauseDuration : ApiAttributeInfo<SchedulingApiWorkingTime, Duration> = new ApiAttributeInfo<SchedulingApiWorkingTime, Duration>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'automaticPauseDuration',
			nodeName: 'WORKING_TIME_AUTOMATIC_PAUSE_DURATION',
			type: PApiType.Duration,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiPlannedStart : ApiAttributeInfo<SchedulingApiWorkingTime, DateTime> = new ApiAttributeInfo<SchedulingApiWorkingTime, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'plannedStart',
			nodeName: 'WORKING_TIME_PLANNED_START',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiPlannedEnd : ApiAttributeInfo<SchedulingApiWorkingTime, DateTime> = new ApiAttributeInfo<SchedulingApiWorkingTime, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'plannedEnd',
			nodeName: 'WORKING_TIME_PLANNED_END',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});
	aiWhenMemberStampedStart : ApiAttributeInfo<SchedulingApiWorkingTime, DateTime> = new ApiAttributeInfo<SchedulingApiWorkingTime, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'whenMemberStampedStart',
			nodeName: 'WORKING_TIME_WHEN_MEMBER_STAMPED_START',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 8,
		});
	aiWhenMemberStampedEnd : ApiAttributeInfo<SchedulingApiWorkingTime, DateTime> = new ApiAttributeInfo<SchedulingApiWorkingTime, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'whenMemberStampedEnd',
			nodeName: 'WORKING_TIME_WHEN_MEMBER_STAMPED_END',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});
	aiHourlyEarnings : ApiAttributeInfo<SchedulingApiWorkingTime, ClientCurrency> = new ApiAttributeInfo<SchedulingApiWorkingTime, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'hourlyEarnings',
			nodeName: 'WORKING_TIME_HOURLY_EARNINGS',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiWorkingTime) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkingTime) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiWarnUnplannedWork : ApiAttributeInfo<SchedulingApiWorkingTime, boolean> = new ApiAttributeInfo<SchedulingApiWorkingTime, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'warnUnplannedWork',
			nodeName: 'WORKING_TIME_WARN_UNPLANNED_WORK',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWorkingTime, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiWarnStampedNotCurrentTime : ApiAttributeInfo<SchedulingApiWorkingTime, boolean> = new ApiAttributeInfo<SchedulingApiWorkingTime, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'warnStampedNotCurrentTime',
			nodeName: 'WORKING_TIME_WARN_STAMPED_NOT_CURRENT_TIME',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWorkingTime, _nodeId : string) {return false;},
			rawDataIndex: 12,
		});
	aiWarnStampedNotShiftTime : ApiAttributeInfo<SchedulingApiWorkingTime, boolean> = new ApiAttributeInfo<SchedulingApiWorkingTime, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'warnStampedNotShiftTime',
			nodeName: 'WORKING_TIME_WARN_STAMPED_NOT_SHIFT_TIME',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWorkingTime, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});
	aiShiftModelId : ApiAttributeInfo<SchedulingApiWorkingTime, Id> = new ApiAttributeInfo<SchedulingApiWorkingTime, Id>({
			apiObjWrapper: this as any as SchedulingApiWorkingTime,
			name: 'shiftModelId',
			nodeName: 'WORKING_TIME_SHIFT_MODEL_ID',
			type: PApiType.Id,
			validations: function(this : SchedulingApiWorkingTime) {
				return [
					() => {
return this.api.validators.required(PApiType.Id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkingTime) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});

	/**
	 * Comment of member. Editable only by member.
	 *
	 * @type {string}
	 */
	get comment() : string | null {
		this.getterDebugValidations(this.aiComment, false);
		return this.data[1];
	}

	set commentTestSetter(v : string | null) {
        this.setterImpl(1, v, 'comment', true, null, null);
	}

	private timeWrapper : SchedulingApiWorkingTimeTime = new SchedulingApiWorkingTimeTime(this.api,
		this as unknown as SchedulingApiWorkingTime);
	public aiTime = this.timeWrapper.aiThis;

	/**
	 * Time of working time.
	 */
	get time() : SchedulingApiWorkingTimeTime {
		this.getterDebugValidations(this.aiTime, false);
		return this.timeWrapper;
	}

	set timeTestSetter(v : SchedulingApiWorkingTimeTime) {
        this.setterImpl(2, v.rawData, 'time', true, null, function(this : SchedulingApiWorkingTime) {this.timeWrapper = v;});
	}

	/**
	 * Regular pause duration (done by member).
	 *
	 * @type {Duration}
	 */
	get regularPauseDuration() : Duration {
		this.getterDebugValidations(this.aiRegularPauseDuration, false);
		return this.data[3];
	}

	set regularPauseDuration(v : Duration) {
        this.setterImpl(3, v, 'regularPauseDuration', false, null, null);
	}

	private memberIdWrapper : Id = null!;

	/**
	 * The member id to which this working time belongs.
	 *
	 * @type {Id}
	 */
	get memberId() : Id {
		this.getterDebugValidations(this.aiMemberId, false);
		return this.memberIdWrapper;
	}

	set memberId(v : Id) {
        this.setterImpl(4, v, 'memberId', false, null, function(this : SchedulingApiWorkingTime) {this.memberIdWrapper = v;});
	}

	/**
	 * Automatic pause duration (added by Dr. Plano).
	 *
	 * @type {Duration}
	 */
	get automaticPauseDuration() : Duration {
		this.getterDebugValidations(this.aiAutomaticPauseDuration, false);
		return this.data[5];
	}

	set automaticPauseDurationTestSetter(v : Duration) {
        this.setterImpl(5, v, 'automaticPauseDuration', true, null, null);
	}

	/**
	 * Start time of shift (inclusive). Is "null" if no shift is associated with this working-time. Read only.
	 *
	 * @type {DateTime}
	 */
	get plannedStart() : DateTime | null {
		this.getterDebugValidations(this.aiPlannedStart, false);
		return this.data[6];
	}

	set plannedStartTestSetter(v : DateTime | null) {
        this.setterImpl(6, v, 'plannedStart', true, null, null);
	}

	/**
	 * End time of shift (exclusive). Is "null" if no shift is associated with this working-time. Read only.
	 *
	 * @type {DateTime}
	 */
	get plannedEnd() : DateTime | null {
		this.getterDebugValidations(this.aiPlannedEnd, false);
		return this.data[7];
	}

	set plannedEndTestSetter(v : DateTime | null) {
        this.setterImpl(7, v, 'plannedEnd', true, null, null);
	}

	/**
	 * When was the start-time stamped (using time-stamp). This value can be for any case "null" (considering legacy data for which we have not stored this value).
	 *
	 * @type {DateTime}
	 */
	get whenMemberStampedStart() : DateTime | null {
		this.getterDebugValidations(this.aiWhenMemberStampedStart, false);
		return this.data[8];
	}

	set whenMemberStampedStartTestSetter(v : DateTime | null) {
        this.setterImpl(8, v, 'whenMemberStampedStart', true, null, null);
	}

	/**
	 * When was the end-time stamped (using time-stamp). This value can be for any case "null" (considering legacy data for which we have not stored this value).
	 *
	 * @type {DateTime}
	 */
	get whenMemberStampedEnd() : DateTime | null {
		this.getterDebugValidations(this.aiWhenMemberStampedEnd, false);
		return this.data[9];
	}

	set whenMemberStampedEndTestSetter(v : DateTime | null) {
        this.setterImpl(9, v, 'whenMemberStampedEnd', true, null, null);
	}

	/**
	 * Hourly earnings.
	 *
	 * @type {ClientCurrency}
	 */
	get hourlyEarnings() : ClientCurrency {
		this.getterDebugValidations(this.aiHourlyEarnings, false);
		return this.data[10];
	}

	set hourlyEarnings(v : ClientCurrency) {
        this.setterImpl(10, v, 'hourlyEarnings', false, null, null);
	}

	/**
	 * Unplanned work warning? Read only.
	 *
	 * @type {boolean}
	 */
	get warnUnplannedWork() : boolean {
		this.getterDebugValidations(this.aiWarnUnplannedWork, false);
		return this.data[11];
	}

	set warnUnplannedWorkTestSetter(v : boolean) {
        this.setterImpl(11, v, 'warnUnplannedWork', true, null, null);
	}

	/**
	 * Stamped not current time warning? Read only.
	 *
	 * @type {boolean}
	 */
	get warnStampedNotCurrentTime() : boolean {
		this.getterDebugValidations(this.aiWarnStampedNotCurrentTime, false);
		return this.data[12];
	}

	set warnStampedNotCurrentTimeTestSetter(v : boolean) {
        this.setterImpl(12, v, 'warnStampedNotCurrentTime', true, null, null);
	}

	/**
	 * Stamped not shift time warning? Read only.
	 *
	 * @type {boolean}
	 */
	get warnStampedNotShiftTime() : boolean {
		this.getterDebugValidations(this.aiWarnStampedNotShiftTime, false);
		return this.data[13];
	}

	set warnStampedNotShiftTimeTestSetter(v : boolean) {
        this.setterImpl(13, v, 'warnStampedNotShiftTime', true, null, null);
	}

	private shiftModelIdWrapper : Id = null!;

	/**
	 * The shift model id to which this working time belongs. Read only.
	 *
	 * @type {Id}
	 */
	get shiftModelId() : Id {
		this.getterDebugValidations(this.aiShiftModelId, false);
		return this.shiftModelIdWrapper;
	}

	set shiftModelId(v : Id) {
        this.setterImpl(14, v, 'shiftModelId', false, null, function(this : SchedulingApiWorkingTime) {this.shiftModelIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.timeWrapper._fixIds(_idReplacements);
		this.data[4] = Meta.getReplacedId(this.data[4], _idReplacements);
		this.memberIdWrapper = this.data[4] === null ? null! : Id.create(this.data[4]);
		this.data[14] = Meta.getReplacedId(this.data[14], _idReplacements);
		this.shiftModelIdWrapper = this.data[14] === null ? null! : Id.create(this.data[14]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORKING_TIME should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.timeWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[4] : null, this.memberIdWrapper))
			this.memberIdWrapper = data && data[4] ? Id.create(data[4]) : null!;
		if(!Meta.isSameId(data ? data[14] : null, this.shiftModelIdWrapper))
			this.shiftModelIdWrapper = data && data[14] ? Id.create(data[14]) : null!;
	}

	protected get dni() : string {
		return '113';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '113', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiWorkingTimeTime extends ApiObjectWrapper<SchedulingApiWorkingTimeTime>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkingTime | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkingTimeTime> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkingTimeTime as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkingTimeTime, SchedulingApiWorkingTimeTime> = new ApiAttributeInfo<SchedulingApiWorkingTimeTime, SchedulingApiWorkingTimeTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTimeTime,
			name: 'time',
			nodeName: 'WORKING_TIME_TIME',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkingTimeTime, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	aiStart : ApiAttributeInfo<SchedulingApiWorkingTimeTime, DateTime> = new ApiAttributeInfo<SchedulingApiWorkingTimeTime, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTimeTime,
			name: 'start',
			nodeName: 'WORKING_TIME_TIME_START',
			type: PApiType.DateTime,
			validations: function(this : SchedulingApiWorkingTimeTime) {
				return [
					() => {
return this.api.validators.required(PApiType.DateTime, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.end === null ? null : this.api.pMoment.m(this.end).subtract(this.parent!.regularPauseDuration, 'ms').valueOf() /* TODO:
						PLANO-151346 */, false, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkingTimeTime) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiWorkingTimeTime, DateTime> = new ApiAttributeInfo<SchedulingApiWorkingTimeTime, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkingTimeTime,
			name: 'end',
			nodeName: 'WORKING_TIME_TIME_END',
			type: PApiType.DateTime,
			validations: function(this : SchedulingApiWorkingTimeTime) {
				return [
					() => {
return this.api.validators.required(PApiType.DateTime, undefined);						return null;
					},
					() => {
return this.api.validators.min(() => this.start === null ? null : this.api.pMoment.m(this.start).add(this.parent!.regularPauseDuration, 'ms').valueOf() /* TODO:
						PLANO-151346 */, false, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkingTimeTime) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});

	/**
	 * Start time of working-time (inclusive).
	 *
	 * @type {DateTime}
	 */
	get start() : DateTime {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set start(v : DateTime) {
        this.setterImpl(1, v, 'start', false, null, null);
	}

	/**
	 * End time of working-time (exclusive).
	 *
	 * @type {DateTime}
	 */
	get end() : DateTime {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set end(v : DateTime) {
        this.setterImpl(2, v, 'end', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '115';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '115', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiLeavesBase extends ApiListWrapper<SchedulingApiLeave>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'leaves');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiLeave {
		return new SchedulingApiLeave(this.api, this as unknown as SchedulingApiLeaves, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiLeaves(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '5';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiLeave) => void) | null = null, _backendId : Id | null = null) : SchedulingApiLeave {
		const newItem = new SchedulingApiLeave(this.api, this as unknown as SchedulingApiLeaves, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('leaves');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiLeaves, SchedulingApiLeaves> = new ApiAttributeInfo<SchedulingApiLeaves, SchedulingApiLeaves>({
			apiObjWrapper: this as any as SchedulingApiLeaves,
			name: 'leaves',
			nodeName: 'LEAVES',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiLeaves) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiLeaves, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 4,
		});
}

				 
export class SchedulingApiLeaveBase extends ApiObjectWrapper<SchedulingApiLeave>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiLeaves | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiLeave> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiLeave as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiLeave, SchedulingApiLeave> = new ApiAttributeInfo<SchedulingApiLeave, SchedulingApiLeave>({
			apiObjWrapper: this as any as SchedulingApiLeave,
			name: 'leave',
			nodeName: 'LEAVE',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : SchedulingApiLeave) {
				return true;
			},
			defaultValue: function(this : SchedulingApiLeave, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiInternalNotes : ApiAttributeInfo<SchedulingApiLeave, string> = new ApiAttributeInfo<SchedulingApiLeave, string>({
			apiObjWrapper: this as any as SchedulingApiLeave,
			name: 'internalNotes',
			nodeName: 'LEAVE_INTERNAL_NOTES',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiLeave) {
				return ((this.member === null || this.api.pPermissionsService.hasManagerPermissionForMember(this.member)));
			},
			validations: function(this : SchedulingApiLeave) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeave) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiMemberId : ApiAttributeInfo<SchedulingApiLeave, Id> = new ApiAttributeInfo<SchedulingApiLeave, Id>({
			apiObjWrapper: this as any as SchedulingApiLeave,
			name: 'memberId',
			nodeName: 'LEAVE_MEMBER_ID',
			type: PApiType.Id,
			validations: function(this : SchedulingApiLeave) {
				return [
					() => {
return this.api.validators.required(PApiType.Id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeave) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiVisibleTo : ApiAttributeInfo<SchedulingApiLeave, SchedulingApiLeaveVisibleTo> = new ApiAttributeInfo<SchedulingApiLeave, SchedulingApiLeaveVisibleTo>({
			apiObjWrapper: this as any as SchedulingApiLeave,
			name: 'visibleTo',
			nodeName: 'LEAVE_VISIBLE_TO',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiLeave) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeave) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiLeave, _nodeId : string) {return SchedulingApiLeaveVisibleTo.MANAGERS_ONLY;},
			rawDataIndex: 4,
		});
	aiDateOfCreation : ApiAttributeInfo<SchedulingApiLeave, DateTime> = new ApiAttributeInfo<SchedulingApiLeave, DateTime>({
			apiObjWrapper: this as any as SchedulingApiLeave,
			name: 'dateOfCreation',
			nodeName: 'LEAVE_DATE_OF_CREATION',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});

	/**
	 * A comment to be shown to managers of the leave.
	 *
	 * @type {string}
	 */
	get internalNotes() : string | null {
		this.getterDebugValidations(this.aiInternalNotes, false);
		return this.data[1];
	}

	set internalNotes(v : string | null) {
        this.setterImpl(1, v, 'internalNotes', false, null, null);
	}

	private memberIdWrapper : Id = null!;

	/**
	 * The member id to which this leave belongs.
	 *
	 * @type {Id}
	 */
	get memberId() : Id {
		this.getterDebugValidations(this.aiMemberId, false);
		return this.memberIdWrapper;
	}

	set memberId(v : Id) {
        this.setterImpl(2, v, 'memberId', false, null, function(this : SchedulingApiLeave) {this.memberIdWrapper = v;});
	}

	private leaveDaysWrapper : SchedulingApiLeaveDays = new SchedulingApiLeaveDays(this.api,
		this as unknown as SchedulingApiLeave, false, false);
	public aiLeaveDays = this.leaveDaysWrapper.aiThis;

	/**
	 * A list defining the days of the leave. This list is sorted by "leaveDay.dayStart". It is not allowed to have wholes in it. I.e. "dayStart" of the items in this list will always be consecutive days.
	 */
	get leaveDays() : SchedulingApiLeaveDays {
		this.getterDebugValidations(this.aiLeaveDays, false);
		return this.leaveDaysWrapper;
	}

	set leaveDaysTestSetter(v : SchedulingApiLeaveDays) {
        this.setterImpl(3, v.rawData, 'leaveDays', true, null, function(this : SchedulingApiLeave) {this.leaveDaysWrapper = v;});
	}

	/**
	 * Who can see this leave item?
	 *
	 * @type {SchedulingApiLeaveVisibleTo}
	 */
	get visibleTo() : SchedulingApiLeaveVisibleTo {
		this.getterDebugValidations(this.aiVisibleTo, false);
		return this.data[4];
	}

	set visibleTo(v : SchedulingApiLeaveVisibleTo) {
        this.setterImpl(4, v, 'visibleTo', false, null, null);
	}

	/**
	 * (Detail) When was this item created? This returns "null" for items created before we introduced this attribute.
	 *
	 * @type {DateTime}
	 */
	get dateOfCreation() : DateTime | null {
		this.getterDebugValidations(this.aiDateOfCreation, false);
		return this.data[5];
	}

	set dateOfCreationTestSetter(v : DateTime | null) {
        this.setterImpl(5, v, 'dateOfCreation', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.memberIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
		this.leaveDaysWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('LEAVE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[2] : null, this.memberIdWrapper))
			this.memberIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
		this.leaveDaysWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '130';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '130', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiLeaveDaysBase extends ApiListWrapper<SchedulingApiLeaveDay>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiLeave | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'leaveDays');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiLeaveDay {
		return new SchedulingApiLeaveDay(this.api, this as unknown as SchedulingApiLeaveDays, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiLeave | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiLeaveDays(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '133';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiLeaveDay) => void) | null = null, _backendId : Id | null = null) : SchedulingApiLeaveDay {
		const newItem = new SchedulingApiLeaveDay(this.api, this as unknown as SchedulingApiLeaveDays, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('leaveDays');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiLeaveDays, SchedulingApiLeaveDays> = new ApiAttributeInfo<SchedulingApiLeaveDays, SchedulingApiLeaveDays>({
			apiObjWrapper: this as any as SchedulingApiLeaveDays,
			name: 'leaveDays',
			nodeName: 'LEAVE_DAYS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiLeaveDays) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDays) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiLeaveDays, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 3,
		});
}

				 
export class SchedulingApiLeaveDayBase extends ApiObjectWrapper<SchedulingApiLeaveDay>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiLeaveDays | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiLeaveDay> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiLeaveDay as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDay> = new ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDay>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'leaveDay',
			nodeName: 'LEAVE_DAY',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiLeaveDay, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiDayStart : ApiAttributeInfo<SchedulingApiLeaveDay, Date> = new ApiAttributeInfo<SchedulingApiLeaveDay, Date>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'dayStart',
			nodeName: 'LEAVE_DAY_DAY_START',
			type: PApiType.Date,
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiType : ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDayType> = new ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDayType>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'type',
			nodeName: 'LEAVE_DAY_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiDurationType : ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDayDurationType> = new ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDayDurationType>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'durationType',
			nodeName: 'LEAVE_DAY_DURATION_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiDuration : ApiAttributeInfo<SchedulingApiLeaveDay, Duration> = new ApiAttributeInfo<SchedulingApiLeaveDay, Duration>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'duration',
			nodeName: 'LEAVE_DAY_DURATION',
			type: PApiType.Duration,
			isAvailableByBusinessLogic: function(this : SchedulingApiLeaveDay) {
				return ((this.durationType === SchedulingApiLeaveDayDurationType.WHOLE_DAY));
			},
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
		if(((this.durationType === SchedulingApiLeaveDayDurationType.WHOLE_DAY)))
		{
			return this.api.validators.required(PApiType.Duration, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.Duration, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.Duration, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(90000000, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiStartTime : ApiAttributeInfo<SchedulingApiLeaveDay, LocalTime> = new ApiAttributeInfo<SchedulingApiLeaveDay, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'startTime',
			nodeName: 'LEAVE_DAY_START_TIME',
			type: PApiType.LocalTime,
			isAvailableByBusinessLogic: function(this : SchedulingApiLeaveDay) {
				return ((this.durationType !== SchedulingApiLeaveDayDurationType.WHOLE_DAY));
			},
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
		if(((this.durationType !== SchedulingApiLeaveDayDurationType.WHOLE_DAY)))
		{
			return this.api.validators.required(PApiType.LocalTime, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.LocalTime, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.max(() => this.endTime, false, PApiType.LocalTime, this.aiEndTime.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiEndTime : ApiAttributeInfo<SchedulingApiLeaveDay, LocalTime> = new ApiAttributeInfo<SchedulingApiLeaveDay, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'endTime',
			nodeName: 'LEAVE_DAY_END_TIME',
			type: PApiType.LocalTime,
			isAvailableByBusinessLogic: function(this : SchedulingApiLeaveDay) {
				return ((this.durationType !== SchedulingApiLeaveDayDurationType.WHOLE_DAY));
			},
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
		if(((this.durationType !== SchedulingApiLeaveDayDurationType.WHOLE_DAY)))
		{
			return this.api.validators.required(PApiType.LocalTime, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.LocalTime, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(() => this.startTime, false, PApiType.LocalTime, this.aiStartTime.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiPaymentType : ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDayPaymentType> = new ApiAttributeInfo<SchedulingApiLeaveDay, SchedulingApiLeaveDayPaymentType>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'paymentType',
			nodeName: 'LEAVE_DAY_PAYMENT_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiHourlyWage : ApiAttributeInfo<SchedulingApiLeaveDay, ClientCurrency> = new ApiAttributeInfo<SchedulingApiLeaveDay, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiLeaveDay,
			name: 'hourlyWage',
			nodeName: 'LEAVE_DAY_HOURLY_WAGE',
			type: PApiType.ClientCurrency,
			isAvailableByBusinessLogic: function(this : SchedulingApiLeaveDay) {
				return ((this.paymentType === SchedulingApiLeaveDayPaymentType.PAID_WITH_HOURLY_WAGE));
			},
			validations: function(this : SchedulingApiLeaveDay) {
				return [
					() => {
		if(((this.paymentType === SchedulingApiLeaveDayPaymentType.PAID_WITH_HOURLY_WAGE)))
		{
			return this.api.validators.required(PApiType.ClientCurrency, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.ClientCurrency, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(0, false, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiLeaveDay) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});

	/**
	 * The start of day which this item defines a leave for (i.e. 0:00 of that day).
	 *
	 * @type {Date}
	 */
	get dayStart() : Date {
		this.getterDebugValidations(this.aiDayStart, false);
		return this.data[1];
	}

	set dayStart(v : Date) {
        this.setterImpl(1, v, 'dayStart', false, null, null);
	}

	/**
	 * Type of the leave day.
	 *
	 * @type {SchedulingApiLeaveDayType}
	 */
	get type() : SchedulingApiLeaveDayType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[2];
	}

	set type(v : SchedulingApiLeaveDayType) {
        this.setterImpl(2, v, 'type', false, null, null);
	}

	/**
	 * The different possible "length" of a leave day.
	 *
	 * @type {SchedulingApiLeaveDayDurationType}
	 */
	get durationType() : SchedulingApiLeaveDayDurationType {
		this.getterDebugValidations(this.aiDurationType, false);
		return this.data[3];
	}

	set durationType(v : SchedulingApiLeaveDayDurationType) {
        this.setterImpl(3, v, 'durationType', false, null, null);
	}

	/**
	 * How many milliseconds of the day are considered as a leave. This is only provided when "durationType" is equal WHOLE_DAY.
	 *
	 * @type {Duration}
	 */
	get duration() : Duration | null {
		this.getterDebugValidations(this.aiDuration, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiDuration.defaultValue;
			
		}
		return this.data[4];
	}

	set duration(v : Duration | null) {
        this.setterImpl(4, v, 'duration', false, null, null);
	}

	/**
	 * The start-time of the leave in this day. This is only provided when "durationType" is *not* equal WHOLE_DAY.
	 *
	 * @type {LocalTime}
	 */
	get startTime() : LocalTime | null {
		this.getterDebugValidations(this.aiStartTime, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiStartTime.defaultValue;
			
		}
		return this.data[5];
	}

	set startTime(v : LocalTime | null) {
        this.setterImpl(5, v, 'startTime', false, null, null);
	}

	/**
	 * The end-time of the leave in this day. This is only provided when "durationType" is *not* equal WHOLE_DAY.
	 *
	 * @type {LocalTime}
	 */
	get endTime() : LocalTime | null {
		this.getterDebugValidations(this.aiEndTime, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiEndTime.defaultValue;
			
		}
		return this.data[6];
	}

	set endTime(v : LocalTime | null) {
        this.setterImpl(6, v, 'endTime', false, null, null);
	}

	/**
	 * How is this leave day paid?
	 *
	 * @type {SchedulingApiLeaveDayPaymentType}
	 */
	get paymentType() : SchedulingApiLeaveDayPaymentType {
		this.getterDebugValidations(this.aiPaymentType, false);
		return this.data[7];
	}

	set paymentType(v : SchedulingApiLeaveDayPaymentType) {
        this.setterImpl(7, v, 'paymentType', false, null, null);
	}

	/**
	 * Hourly wage of this leave day. This is only provided when "paymentType" is equal PAID_WITH_HOURLY_WAGE.
	 *
	 * @type {ClientCurrency}
	 */
	get hourlyWage() : ClientCurrency | null {
		this.getterDebugValidations(this.aiHourlyWage, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiHourlyWage.defaultValue;
			
		}
		return this.data[8];
	}

	set hourlyWage(v : ClientCurrency | null) {
        this.setterImpl(8, v, 'hourlyWage', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('LEAVE_DAY should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '136';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '136', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiLeaveDayType {
	VACATION = 1,
	ILLNESS = 2,
	PUBLIC_HOLIDAY = 3,
	COMPENSATORY_TIME_OFF = 4,
	MISCELLANEOUS = 5,
}
export enum SchedulingApiLeaveDayDurationType {
	WHOLE_DAY = 1,
	THREE_QUARTER_DAY = 2,
	HALF_DAY = 3,
	QUARTER_DAY = 4,
}
export enum SchedulingApiLeaveDayPaymentType {
	UNPAID = 1,
	PAID_WITHOUT_HOURLY_WAGE = 2,
	PAID_WITH_HOURLY_WAGE = 3,
}
export enum SchedulingApiLeaveVisibleTo {
	ALL_USERS = 1,
	MANAGERS_ONLY = 2,
	MANAGERS_AND_AFFECTED_USERS = 3,
}
		 export class SchedulingApiShiftExchangesBase extends ApiListWrapper<SchedulingApiShiftExchange>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftExchanges');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftExchange {
		return new SchedulingApiShiftExchange(this.api, this as unknown as SchedulingApiShiftExchanges, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftExchanges(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '6';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftExchange) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftExchange {
		const newItem = new SchedulingApiShiftExchange(this.api, this as unknown as SchedulingApiShiftExchanges, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftExchanges');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchanges, SchedulingApiShiftExchanges> = new ApiAttributeInfo<SchedulingApiShiftExchanges, SchedulingApiShiftExchanges>({
			apiObjWrapper: this as any as SchedulingApiShiftExchanges,
			name: 'shiftExchanges',
			nodeName: 'SHIFT_EXCHANGES',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiShiftExchanges, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 5,
		});
}

				 
export class SchedulingApiShiftExchangeBase extends ApiObjectWrapper<SchedulingApiShiftExchange>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchanges | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftExchange> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftExchange as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchange> = new ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchange>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'shiftExchange',
			nodeName: 'SHIFT_EXCHANGE',
			type: PApiType.ApiObject,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftExchange) {
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftExchange, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiTodoCount : ApiAttributeInfo<SchedulingApiShiftExchange, Integer> = new ApiAttributeInfo<SchedulingApiShiftExchange, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'todoCount',
			nodeName: 'SHIFT_EXCHANGE_TODO_COUNT',
			type: PApiType.Integer,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiState : ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchangeState> = new ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchangeState>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'state',
			nodeName: 'SHIFT_EXCHANGE_STATE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiCommunicationInfo : ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunicationInfo> = new ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunicationInfo>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'communicationInfo',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_INFO',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiLastUpdate : ApiAttributeInfo<SchedulingApiShiftExchange, DateTime> = new ApiAttributeInfo<SchedulingApiShiftExchange, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'lastUpdate',
			nodeName: 'SHIFT_EXCHANGE_LAST_UPDATE',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiRequesterRelationship : ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchangeRequesterRelationship> = new ApiAttributeInfo<SchedulingApiShiftExchange, SchedulingApiShiftExchangeRequesterRelationship>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'requesterRelationship',
			nodeName: 'SHIFT_EXCHANGE_REQUESTER_RELATIONSHIP',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiIsIllness : ApiAttributeInfo<SchedulingApiShiftExchange, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchange, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'isIllness',
			nodeName: 'SHIFT_EXCHANGE_IS_ILLNESS',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiShiftExchange) {
				{const conditionValue = this.api.pPermissionsService.userCanWriteShiftExchange(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftExchange) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftExchange) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftExchange, _nodeId : string) {return false;},
			rawDataIndex: 6,
		});
	aiIndisposedMemberId : ApiAttributeInfo<SchedulingApiShiftExchange, Id> = new ApiAttributeInfo<SchedulingApiShiftExchange, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'indisposedMemberId',
			nodeName: 'SHIFT_EXCHANGE_INDISPOSED_MEMBER_ID',
			type: PApiType.Id,
			validations: function(this : SchedulingApiShiftExchange) {
				return [
					() => {
return this.api.validators.required(PApiType.Id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftExchange) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiNewAssignedMemberId : ApiAttributeInfo<SchedulingApiShiftExchange, Id> = new ApiAttributeInfo<SchedulingApiShiftExchange, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'newAssignedMemberId',
			nodeName: 'SHIFT_EXCHANGE_NEW_ASSIGNED_MEMBER_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 10,
		});
	aiIndisposedMemberPrefersSwapping : ApiAttributeInfo<SchedulingApiShiftExchange, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchange, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'indisposedMemberPrefersSwapping',
			nodeName: 'SHIFT_EXCHANGE_INDISPOSED_MEMBER_PREFERS_SWAPPING',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftExchange) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftExchange) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftExchange, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiDeadline : ApiAttributeInfo<SchedulingApiShiftExchange, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiShiftExchange, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'deadline',
			nodeName: 'SHIFT_EXCHANGE_DEADLINE',
			type: PApiType.DateExclusiveEnd,
			hasPermissionToSet: function(this : SchedulingApiShiftExchange) {
				{const conditionValue = this.api.pPermissionsService.userCanWriteShiftExchange(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				{const conditionValue = this.shiftRefs.length > 0; if(!conditionValue) return conditionValue === false ? {sourceString: 'Bitte wähle erst eine oder mehrere Schichten, da die Fristsetzung davon beeinflusst werden kann.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftExchange) {
				return [
					() => {
		if(((this.state === SchedulingApiShiftExchangeState.ILLNESS_NEEDS_CONFIRMATION ||
							this.state === SchedulingApiShiftExchangeState.ACTIVE ||
							this.isNewItem /* In case of isNewItem the state is unknown */)))
		{
			return this.api.validators.min(+(this.api.pMoment.m().add(1, 'day').startOf('day')), true, PApiType.DateExclusiveEnd, undefined, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.max(this.shiftRefs.latestEnd ? this.api.pMoment.m(this.shiftRefs.latestEnd).startOf('day').valueOf() : null, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftExchange) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiMemberIdAddressedTo : ApiAttributeInfo<SchedulingApiShiftExchange, Id> = new ApiAttributeInfo<SchedulingApiShiftExchange, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'memberIdAddressedTo',
			nodeName: 'SHIFT_EXCHANGE_MEMBER_ID_ADDRESSED_TO',
			type: PApiType.Id,
			hasPermissionToSet: function(this : SchedulingApiShiftExchange) {
				{const conditionValue = this.api.pPermissionsService.userCanWriteShiftExchange(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 13,
		});
	aiIllnessResponderCommentToMembers : ApiAttributeInfo<SchedulingApiShiftExchange, string> = new ApiAttributeInfo<SchedulingApiShiftExchange, string>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'illnessResponderCommentToMembers',
			nodeName: 'SHIFT_EXCHANGE_ILLNESS_RESPONDER_COMMENT_TO_MEMBERS',
			type: PApiType.string,
			hasPermissionToSet: function(this : SchedulingApiShiftExchange) {
				{const conditionValue = this.api.pPermissionsService.hasManagerPermissionForShiftExchange(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 14,
		});
	aiIndisposedMemberComment : ApiAttributeInfo<SchedulingApiShiftExchange, string> = new ApiAttributeInfo<SchedulingApiShiftExchange, string>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'indisposedMemberComment',
			nodeName: 'SHIFT_EXCHANGE_INDISPOSED_MEMBER_COMMENT',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiShiftExchange) {
				return ((this.hasPermissionToSeeIndisposedMemberComment()));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftExchange) {
	return ((this.indisposedMember === this.api.pPermissionsService.loggedInMember)||(this.api.pPermissionsService.hasManagerPermissionForShiftExchange(this)));
			},
			rawDataIndex: 15,
		});
	aiShowOnDesk : ApiAttributeInfo<SchedulingApiShiftExchange, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchange, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'showOnDesk',
			nodeName: 'SHIFT_EXCHANGE_SHOW_ON_DESK',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiShiftExchange, _nodeId : string) {return false;},
			rawDataIndex: 16,
		});
	aiShowInList : ApiAttributeInfo<SchedulingApiShiftExchange, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchange, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'showInList',
			nodeName: 'SHIFT_EXCHANGE_SHOW_IN_LIST',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiShiftExchange, _nodeId : string) {return false;},
			rawDataIndex: 17,
		});
	aiLeaveId : ApiAttributeInfo<SchedulingApiShiftExchange, Id> = new ApiAttributeInfo<SchedulingApiShiftExchange, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'leaveId',
			nodeName: 'SHIFT_EXCHANGE_LEAVE_ID',
			type: PApiType.Id,
			rawDataIndex: 18,
		});
	aiCloseShiftExchange : ApiAttributeInfo<SchedulingApiShiftExchange, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchange, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'closeShiftExchange',
			nodeName: 'SHIFT_EXCHANGE_CLOSE_SHIFT_EXCHANGE',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiShiftExchange) {
				return ((this.data[this.api.consts.SHIFT_EXCHANGE_CLOSE_SHIFT_EXCHANGE] !== undefined)&&(!this.isNewItem));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftExchange) {
				{const conditionValue = this.data[this.api.consts.SHIFT_EXCHANGE_CLOSE_SHIFT_EXCHANGE] !== undefined; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftExchange) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftExchange) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftExchange, _nodeId : string) {return false;},
			rawDataIndex: 20,
		});
	aiOpenShiftExchange : ApiAttributeInfo<SchedulingApiShiftExchange, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchange, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchange,
			name: 'openShiftExchange',
			nodeName: 'SHIFT_EXCHANGE_OPEN_SHIFT_EXCHANGE',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiShiftExchange) {
				return ((this.data[this.api.consts.SHIFT_EXCHANGE_OPEN_SHIFT_EXCHANGE] !== undefined));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftExchange) {
				{const conditionValue = this.data[this.api.consts.SHIFT_EXCHANGE_OPEN_SHIFT_EXCHANGE] !== undefined; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftExchange) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftExchange) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftExchange, _nodeId : string) {return false;},
			rawDataIndex: 21,
		});

	/**
	 * Todo count to be shown as badge on the shift-exchange item. Read-only.
	 *
	 * @type {Integer}
	 */
	get todoCount() : Integer {
		this.getterDebugValidations(this.aiTodoCount, false);
		return this.data[1];
	}

	set todoCountTestSetter(v : Integer) {
        this.setterImpl(1, v, 'todoCount', true, null, null);
	}

	/**
	 * Global state of this shift-exchange. This value is the same independent of the requester. See in contrast "communicationInfo". This value is read-only and is automatically updated by backend.
	 *
	 * @type {SchedulingApiShiftExchangeState}
	 */
	get state() : SchedulingApiShiftExchangeState {
		this.getterDebugValidations(this.aiState, false);
		return this.data[2];
	}

	set stateTestSetter(v : SchedulingApiShiftExchangeState) {
        this.setterImpl(2, v, 'state', true, null, null);
	}

	/**
	 * Info about current communication state from the perspective of current requester. Read-only.
	 *
	 * @type {SchedulingApiShiftExchangeCommunicationInfo}
	 */
	get communicationInfo() : SchedulingApiShiftExchangeCommunicationInfo {
		this.getterDebugValidations(this.aiCommunicationInfo, false);
		return this.data[3];
	}

	set communicationInfoTestSetter(v : SchedulingApiShiftExchangeCommunicationInfo) {
        this.setterImpl(3, v, 'communicationInfo', true, null, null);
	}

	/**
	 * When was this item last updated? Note that this is from perspective of current requester. A member who only sees one communication will not be notified of any updated concerning other communications. Read-only.
	 *
	 * @type {DateTime}
	 */
	get lastUpdate() : DateTime {
		this.getterDebugValidations(this.aiLastUpdate, false);
		return this.data[4];
	}

	set lastUpdateTestSetter(v : DateTime) {
        this.setterImpl(4, v, 'lastUpdate', true, null, null);
	}

	/**
	 * What's the requester's relationship to this shift-exchange? Read-only.
	 *
	 * @type {SchedulingApiShiftExchangeRequesterRelationship}
	 */
	get requesterRelationship() : SchedulingApiShiftExchangeRequesterRelationship {
		this.getterDebugValidations(this.aiRequesterRelationship, false);
		return this.data[5];
	}

	set requesterRelationshipTestSetter(v : SchedulingApiShiftExchangeRequesterRelationship) {
        this.setterImpl(5, v, 'requesterRelationship', true, null, null);
	}

	/**
	 * Is the cause of this shift-exchange an illness?
	 *
	 * @type {boolean}
	 */
	get isIllness() : boolean {
		this.getterDebugValidations(this.aiIsIllness, false);
		return this.data[6];
	}

	set isIllness(v : boolean) {
        this.setterImpl(6, v, 'isIllness', false, null, null);
	}

	private shiftRefsWrapper : SchedulingApiShiftExchangeShiftRefs = new SchedulingApiShiftExchangeShiftRefs(this.api,
		this as unknown as SchedulingApiShiftExchange, false, false);
	public aiShiftRefs = this.shiftRefsWrapper.aiThis;

	/**
	 * References to shift(s) for which this shift-exchange was created.
	 */
	get shiftRefs() : SchedulingApiShiftExchangeShiftRefs {
		this.getterDebugValidations(this.aiShiftRefs, false);
		return this.shiftRefsWrapper;
	}

	set shiftRefsTestSetter(v : SchedulingApiShiftExchangeShiftRefs) {
        this.setterImpl(7, v.rawData, 'shiftRefs', true, null, function(this : SchedulingApiShiftExchange) {this.shiftRefsWrapper = v;});
	}

	private swappedShiftRefsWrapper : SchedulingApiShiftExchangeSwappedShiftRefs = new SchedulingApiShiftExchangeSwappedShiftRefs(this.api,
		this as unknown as SchedulingApiShiftExchange, false, false);
	public aiSwappedShiftRefs = this.swappedShiftRefsWrapper.aiThis;

	/**
	 * Reference to shift(s) being swapped.
	 */
	get swappedShiftRefs() : SchedulingApiShiftExchangeSwappedShiftRefs {
		this.getterDebugValidations(this.aiSwappedShiftRefs, false);
		return this.swappedShiftRefsWrapper;
	}

	set swappedShiftRefsTestSetter(v : SchedulingApiShiftExchangeSwappedShiftRefs) {
        this.setterImpl(8, v.rawData, 'swappedShiftRefs', true, null, function(this : SchedulingApiShiftExchange) {this.swappedShiftRefsWrapper = v;});
	}

	private indisposedMemberIdWrapper : Id = null!;

	/**
	 * The id of the member who was originally assigned and who can do this shift and needs a replacement. Can only be set during item creation. A requester with assignment-process permission may set here the id of another member. Otherwise this has to be the id of the requester.
	 *
	 * @type {Id}
	 */
	get indisposedMemberId() : Id {
		this.getterDebugValidations(this.aiIndisposedMemberId, false);
		return this.indisposedMemberIdWrapper;
	}

	set indisposedMemberId(v : Id) {
        this.setterImpl(9, v, 'indisposedMemberId', false, null, function(this : SchedulingApiShiftExchange) {this.indisposedMemberIdWrapper = v;});
	}

	private newAssignedMemberIdWrapper : Id | null = null!;

	/**
	 * After a successful shift-exchange this property contains the id of the member who is now assigned to the shift.
	 *
	 * @type {Id}
	 */
	get newAssignedMemberId() : Id | null {
		this.getterDebugValidations(this.aiNewAssignedMemberId, false);
		return this.newAssignedMemberIdWrapper;
	}

	set newAssignedMemberIdTestSetter(v : Id | null) {
        this.setterImpl(10, v, 'newAssignedMemberId', true, null, function(this : SchedulingApiShiftExchange) {this.newAssignedMemberIdWrapper = v;});
	}

	/**
	 * Does the indisposed member prefers swapping? If no then he prefers that the shift is just taken.
	 *
	 * @type {boolean}
	 */
	get indisposedMemberPrefersSwapping() : boolean {
		this.getterDebugValidations(this.aiIndisposedMemberPrefersSwapping, false);
		return this.data[11];
	}

	set indisposedMemberPrefersSwapping(v : boolean) {
        this.setterImpl(11, v, 'indisposedMemberPrefersSwapping', false, null, null);
	}

	/**
	 * The deadline for this shift-exchange until when the shift-exchange should be successful.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get deadline() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiDeadline, false);
		return this.data[12];
	}

	set deadline(v : DateExclusiveEnd | null) {
        this.setterImpl(12, v, 'deadline', false, null, null);
	}

	private memberIdAddressedToWrapper : Id | null = null!;

	/**
	 * Id of the member to whom this shift-exchange is addressed. If not set it is offered to everyone who could take this shift.
	 *
	 * @type {Id}
	 */
	get memberIdAddressedTo() : Id | null {
		this.getterDebugValidations(this.aiMemberIdAddressedTo, false);
		return this.memberIdAddressedToWrapper;
	}

	set memberIdAddressedTo(v : Id | null) {
        this.setterImpl(13, v, 'memberIdAddressedTo', false, null, function(this : SchedulingApiShiftExchange) {this.memberIdAddressedToWrapper = v;});
	}

	/**
	 * Comment to members by the admin who responded this illness inquiry.
	 *
	 * @type {string}
	 */
	get illnessResponderCommentToMembers() : string | null {
		this.getterDebugValidations(this.aiIllnessResponderCommentToMembers, false);
		return this.data[14];
	}

	set illnessResponderCommentToMembers(v : string | null) {
        this.setterImpl(14, v, 'illnessResponderCommentToMembers', false, null, null);
	}

	/**
	 * Comment of the indisposed member.
	 *
	 * @type {string}
	 */
	get indisposedMemberComment() : string | null {
		this.getterDebugValidations(this.aiIndisposedMemberComment, true);
		return this.data[15];
	}

	set indisposedMemberComment(v : string | null) {
        this.setterImpl(15, v, 'indisposedMemberComment', false, null, null);
	}

	/**
	 * Should this shift-exchange been shown on requester's desk? Read-only.
	 *
	 * @type {boolean}
	 */
	get showOnDesk() : boolean {
		this.getterDebugValidations(this.aiShowOnDesk, false);
		return this.data[16];
	}

	set showOnDeskTestSetter(v : boolean) {
        this.setterImpl(16, v, 'showOnDesk', true, null, null);
	}

	/**
	 * Should this shift-exchange been shown in the shift-exchange list? Read-only.
	 *
	 * @type {boolean}
	 */
	get showInList() : boolean {
		this.getterDebugValidations(this.aiShowInList, false);
		return this.data[17];
	}

	set showInListTestSetter(v : boolean) {
        this.setterImpl(17, v, 'showInList', true, null, null);
	}

	private leaveIdWrapper : Id | null = null!;

	/**
	 * The id of the leave to which this shift-exchange is connected. Note that one leave can be connected to multiple shift-exchanges. Can be null.
	 *
	 * @type {Id}
	 */
	get leaveId() : Id | null {
		this.getterDebugValidations(this.aiLeaveId, false);
		return this.leaveIdWrapper;
	}

	set leaveId(v : Id | null) {
        this.setterImpl(18, v, 'leaveId', false, null, function(this : SchedulingApiShiftExchange) {this.leaveIdWrapper = v;});
	}

	private communicationsWrapper : SchedulingApiShiftExchangeCommunications = new SchedulingApiShiftExchangeCommunications(this.api,
		this as unknown as SchedulingApiShiftExchange, false, false);
	public aiCommunications = this.communicationsWrapper.aiThis;

	/**
	 * (Detail) List of communications with other members about this shift-exchange. Only send when data="shiftExchange" or when detailed view of this item was requested.
	 */
	get communications() : SchedulingApiShiftExchangeCommunications {
		this.getterDebugValidations(this.aiCommunications, false);
		return this.communicationsWrapper;
	}

	set communicationsTestSetter(v : SchedulingApiShiftExchangeCommunications) {
        this.setterImpl(19, v.rawData, 'communications', true, null, function(this : SchedulingApiShiftExchange) {this.communicationsWrapper = v;});
	}

	/**
	 * (Detail) Set it to "true" to close this shift-exchange.
	 *
	 * @type {boolean}
	 */
	get closeShiftExchange() : boolean {
		this.getterDebugValidations(this.aiCloseShiftExchange, false);
		return this.data[20];
	}

	set closeShiftExchange(v : boolean) {
        this.setterImpl(20, v, 'closeShiftExchange', false, null, null);
	}

	/**
	 * (Detail) Set it to "true" to open this shift-exchange.
	 *
	 * @type {boolean}
	 */
	get openShiftExchange() : boolean {
		this.getterDebugValidations(this.aiOpenShiftExchange, false);
		return this.data[21];
	}

	set openShiftExchange(v : boolean) {
        this.setterImpl(21, v, 'openShiftExchange', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftRefsWrapper._fixIds(_idReplacements);
		this.swappedShiftRefsWrapper._fixIds(_idReplacements);
		this.data[9] = Meta.getReplacedId(this.data[9], _idReplacements);
		this.indisposedMemberIdWrapper = this.data[9] === null ? null! : Id.create(this.data[9]);
		this.data[10] = Meta.getReplacedId(this.data[10], _idReplacements);
		this.newAssignedMemberIdWrapper = this.data[10] === null ? null! : Id.create(this.data[10]);
		this.data[13] = Meta.getReplacedId(this.data[13], _idReplacements);
		this.memberIdAddressedToWrapper = this.data[13] === null ? null! : Id.create(this.data[13]);
		this.data[18] = Meta.getReplacedId(this.data[18], _idReplacements);
		this.leaveIdWrapper = this.data[18] === null ? null! : Id.create(this.data[18]);
		this.communicationsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_EXCHANGE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftRefsWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.swappedShiftRefsWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[9] : null, this.indisposedMemberIdWrapper))
			this.indisposedMemberIdWrapper = data && data[9] ? Id.create(data[9]) : null!;
		if(!Meta.isSameId(data ? data[10] : null, this.newAssignedMemberIdWrapper))
			this.newAssignedMemberIdWrapper = data && data[10] ? Id.create(data[10]) : null!;
		if(!Meta.isSameId(data ? data[13] : null, this.memberIdAddressedToWrapper))
			this.memberIdAddressedToWrapper = data && data[13] ? Id.create(data[13]) : null!;
		if(!Meta.isSameId(data ? data[18] : null, this.leaveIdWrapper))
			this.leaveIdWrapper = data && data[18] ? Id.create(data[18]) : null!;
		this.communicationsWrapper._updateRawData(data && data[19] !== undefined ? data[19] : null, generateMissingData);
	}

	protected get dni() : string {
		return '205';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '205', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiShiftExchangeState {
	SWAP_SUCCESSFUL = 1,
	TAKE_SUCCESSFUL = 2,
	ILLNESS_NEEDS_CONFIRMATION = 3,
	ILLNESS_DECLINED = 4,
	ILLNESS_ACCEPT_WITHOUT_SHIFT_EXCHANGE = 5,
	ACTIVE = 6,
	FAILED_DEADLINE_PASSED = 7,
	FAILED_SHIFTS_STARTED = 8,
	FAILED_EVERYONE_DECLINED = 9,
	REMOVED_FROM_SHIFT = 10,
	SHIFTS_REMOVED = 11,
	CLOSED_MANUALLY = 12,
}
export enum SchedulingApiShiftExchangeCommunicationInfo {
	NOT_ACTIVE = 1,
	IM_MUST_ACCEPT = 2,
	CP_MUST_ACCEPT = 3,
	NO_OFFER_YET = 4,
	NO_OFFER_YET_CP_CANNOT = 5,
	NO_OFFER_YET_IM_RESPONDED_NO = 6,
	NO_OFFER_YET_CP_RESPONDED_NO = 7,
}
export enum SchedulingApiShiftExchangeRequesterRelationship {
	A = 1,
	IM = 2,
	CP = 3,
	A_IN_COMMUNICATION = 4,
	MEMBER_NOT_ASSIGNABLE = 5,
}
		 export class SchedulingApiShiftExchangeShiftRefsBase extends ApiListWrapper<SchedulingApiShiftExchangeShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchange | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftRefs');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftExchangeShiftRef {
		return new SchedulingApiShiftExchangeShiftRef(this.api, this as unknown as SchedulingApiShiftExchangeShiftRefs, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftExchange | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftExchangeShiftRefs(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '212';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftExchangeShiftRef) => void) | null = null, _backendId : ShiftId | null = null) : SchedulingApiShiftExchangeShiftRef {
		const newItem = new SchedulingApiShiftExchangeShiftRef(this.api, this as unknown as SchedulingApiShiftExchangeShiftRefs, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftRefs');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeShiftRefs, SchedulingApiShiftExchangeShiftRefs> = new ApiAttributeInfo<SchedulingApiShiftExchangeShiftRefs, SchedulingApiShiftExchangeShiftRefs>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeShiftRefs,
			name: 'shiftRefs',
			nodeName: 'SHIFT_EXCHANGE_SHIFT_REFS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiShiftExchangeShiftRefs) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftExchangeShiftRefs) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftExchangeShiftRefs, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 7,
		});
}

				 
export class SchedulingApiShiftExchangeShiftRef extends ApiObjectWrapper<SchedulingApiShiftExchangeShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchangeShiftRefs | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftExchangeShiftRef> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftExchangeShiftRef as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeShiftRef, SchedulingApiShiftExchangeShiftRef> = new ApiAttributeInfo<SchedulingApiShiftExchangeShiftRef, SchedulingApiShiftExchangeShiftRef>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeShiftRef,
			name: 'shiftExchangeShiftRef',
			nodeName: 'SHIFT_EXCHANGE_SHIFT_REF',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftExchangeShiftRef, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiStart : ApiAttributeInfo<SchedulingApiShiftExchangeShiftRef, DateTime> = new ApiAttributeInfo<SchedulingApiShiftExchangeShiftRef, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeShiftRef,
			name: 'start',
			nodeName: 'SHIFT_EXCHANGE_SHIFT_REF_START',
			type: PApiType.DateTime,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiShiftExchangeShiftRef, DateTime> = new ApiAttributeInfo<SchedulingApiShiftExchangeShiftRef, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeShiftRef,
			name: 'end',
			nodeName: 'SHIFT_EXCHANGE_SHIFT_REF_END',
			type: PApiType.DateTime,
			rawDataIndex: 2,
		});

	/**
	 * Start of the shift
	 *
	 * @type {DateTime}
	 */
	get start() : DateTime {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set start(v : DateTime) {
        this.setterImpl(1, v, 'start', false, null, null);
	}

	/**
	 * Start of the shift
	 *
	 * @type {DateTime}
	 */
	get end() : DateTime {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set end(v : DateTime) {
        this.setterImpl(2, v, 'end', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_EXCHANGE_SHIFT_REF should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '240';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '240', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftExchangeSwappedShiftRefs extends ApiListWrapper<SchedulingApiShiftExchangeSwappedShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchange | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'swappedShiftRefs');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftExchangeSwappedShiftRef {
		return new SchedulingApiShiftExchangeSwappedShiftRef(this.api, this as unknown as SchedulingApiShiftExchangeSwappedShiftRefs, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftExchange | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftExchangeSwappedShiftRefs(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '213';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftExchangeSwappedShiftRef) => void) | null = null, _backendId : ShiftId | null = null) : SchedulingApiShiftExchangeSwappedShiftRef {
		const newItem = new SchedulingApiShiftExchangeSwappedShiftRef(this.api, this as unknown as SchedulingApiShiftExchangeSwappedShiftRefs, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('swappedShiftRefs');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeSwappedShiftRefs, SchedulingApiShiftExchangeSwappedShiftRefs> = new ApiAttributeInfo<SchedulingApiShiftExchangeSwappedShiftRefs, SchedulingApiShiftExchangeSwappedShiftRefs>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeSwappedShiftRefs,
			name: 'swappedShiftRefs',
			nodeName: 'SHIFT_EXCHANGE_SWAPPED_SHIFT_REFS',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiShiftExchangeSwappedShiftRefs, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 8,
		});
	aiShiftExchangeSwappedShiftRef : ApiAttributeInfo<SchedulingApiShiftExchangeSwappedShiftRefs, SchedulingApiShiftExchangeSwappedShiftRef> = new ApiAttributeInfo<SchedulingApiShiftExchangeSwappedShiftRefs, SchedulingApiShiftExchangeSwappedShiftRef>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeSwappedShiftRefs,
			name: 'shiftExchangeSwappedShiftRef',
			nodeName: 'SHIFT_EXCHANGE_SWAPPED_SHIFT_REF',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiShiftExchangeSwappedShiftRefs, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class SchedulingApiShiftExchangeSwappedShiftRef extends ApiObjectWrapper<SchedulingApiShiftExchangeSwappedShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchangeSwappedShiftRefs | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftExchangeSwappedShiftRef> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftExchangeSwappedShiftRef as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeSwappedShiftRef, SchedulingApiShiftExchangeSwappedShiftRef> = new ApiAttributeInfo<SchedulingApiShiftExchangeSwappedShiftRef, SchedulingApiShiftExchangeSwappedShiftRef>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeSwappedShiftRef,
			name: 'shiftExchangeSwappedShiftRef',
			nodeName: 'SHIFT_EXCHANGE_SWAPPED_SHIFT_REF',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiShiftExchangeSwappedShiftRef, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_EXCHANGE_SWAPPED_SHIFT_REF should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '243';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '243', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftExchangeCommunicationsBase extends ApiListWrapper<SchedulingApiShiftExchangeCommunication>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchange | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'communications');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftExchangeCommunication {
		return new SchedulingApiShiftExchangeCommunication(this.api, this as unknown as SchedulingApiShiftExchangeCommunications, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftExchange | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftExchangeCommunications(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '224';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftExchangeCommunication) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftExchangeCommunication {
		const newItem = new SchedulingApiShiftExchangeCommunication(this.api, this as unknown as SchedulingApiShiftExchangeCommunications, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('communications');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeCommunications, SchedulingApiShiftExchangeCommunications> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunications, SchedulingApiShiftExchangeCommunications>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunications,
			name: 'communications',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATIONS',
			type: PApiType.ApiList,
			isDetailedAttribute: true,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunications, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 19,
		});
}

				 
export class SchedulingApiShiftExchangeCommunicationBase extends ApiObjectWrapper<SchedulingApiShiftExchangeCommunication>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchangeCommunications | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftExchangeCommunication> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftExchangeCommunication as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunication> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunication>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'shiftExchangeCommunication',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiShiftExchangeCommunication) {
	return ((this.parent!.parent!.indisposedMember === this.api.pPermissionsService.loggedInMember)||(this.communicationPartner === this.api.pPermissionsService.loggedInMember));
			},
			defaultValue: function(this : SchedulingApiShiftExchangeCommunication, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiIndisposedMembersSelectedSOId : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, Id> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'indisposedMembersSelectedSOId',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_INDISPOSED_MEMBERS_SELECTED_SO_ID',
			type: PApiType.Id,
			rawDataIndex: 2,
		});
	aiPerformAction : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationAction> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationAction>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'performAction',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_PERFORM_ACTION',
			type: PApiType.Enum,
			rawDataIndex: 3,
		});
	aiPerformActionComment : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, string> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, string>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'performActionComment',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_PERFORM_ACTION_COMMENT',
			type: PApiType.string,
			rawDataIndex: 4,
		});
	aiCommunicationState : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationState> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationState>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'communicationState',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_COMMUNICATION_STATE',
			type: PApiType.Enum,
			rawDataIndex: 5,
		});
	aiCommunicationPartnerId : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, Id> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'communicationPartnerId',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_COMMUNICATION_PARTNER_ID',
			type: PApiType.Id,
			rawDataIndex: 6,
		});
	aiLastAction : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationAction> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationAction>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'lastAction',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_LAST_ACTION',
			type: PApiType.Enum,
			rawDataIndex: 7,
		});
	aiLastActionTime : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, DateTime> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'lastActionTime',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_LAST_ACTION_TIME',
			type: PApiType.DateTime,
			rawDataIndex: 8,
		});
	aiLastActionComment : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, string> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, string>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'lastActionComment',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_LAST_ACTION_COMMENT',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiShiftExchangeCommunication) {
				return ((this.hasPermissionToSeeLastActionComment()));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftExchangeCommunication) {
				{const conditionValue = this.hasPermissionToEditLastActionComment(); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 9,
		});
	aiIsIllnessReviewCommunication : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'isIllnessReviewCommunication',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_IS_ILLNESS_REVIEW_COMMUNICATION',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunication, _nodeId : string) {return false;},
			rawDataIndex: 10,
		});
	aiRequesterRole : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationRequesterRole> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationRequesterRole>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'requesterRole',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_REQUESTER_ROLE',
			type: PApiType.Enum,
			rawDataIndex: 11,
		});
	aiHasTodo : ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, boolean> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunication, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunication,
			name: 'hasTodo',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_HAS_TODO',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunication, _nodeId : string) {return false;},
			rawDataIndex: 12,
		});

	private swapOffersWrapper : SchedulingApiShiftExchangeCommunicationSwapOffers = new SchedulingApiShiftExchangeCommunicationSwapOffers(this.api,
		this as unknown as SchedulingApiShiftExchangeCommunication, false, false);
	public aiSwapOffers = this.swapOffersWrapper.aiThis;

	/**
	 * List of swap offers. Each offer consist of a list of shifts which CP has offered for swap.
	 */
	get swapOffers() : SchedulingApiShiftExchangeCommunicationSwapOffers {
		this.getterDebugValidations(this.aiSwapOffers, false);
		return this.swapOffersWrapper;
	}

	set swapOffersTestSetter(v : SchedulingApiShiftExchangeCommunicationSwapOffers) {
        this.setterImpl(1, v.rawData, 'swapOffers', true, null, function(this : SchedulingApiShiftExchangeCommunication) {this.swapOffersWrapper = v;});
	}

	private indisposedMembersSelectedSOIdWrapper : Id | null = null!;

	/**
	 * Id of the swap offer which was selected by the indisposed member.
	 *
	 * @type {Id}
	 */
	get indisposedMembersSelectedSOId() : Id | null {
		this.getterDebugValidations(this.aiIndisposedMembersSelectedSOId, false);
		return this.indisposedMembersSelectedSOIdWrapper;
	}

	set indisposedMembersSelectedSOId(v : Id | null) {
        this.setterImpl(2, v, 'indisposedMembersSelectedSOId', false, null, function(this : SchedulingApiShiftExchangeCommunication) {this.indisposedMembersSelectedSOIdWrapper = v;});
	}

	/**
	 * Set here the action which to perform next. Backend always send "null" back.
	 *
	 * @type {SchedulingApiShiftExchangeCommunicationAction}
	 */
	get performAction() : SchedulingApiShiftExchangeCommunicationAction | null {
		this.getterDebugValidations(this.aiPerformAction, false);
		return this.data[3];
	}

	set performAction(v : SchedulingApiShiftExchangeCommunicationAction | null) {
        this.setterImpl(3, v, 'performAction', false, null, null);
	}

	/**
	 * Optionally set here a comment for the action to be performed. Backend always send empty string back.
	 *
	 * @type {string}
	 */
	get performActionComment() : string | null {
		this.getterDebugValidations(this.aiPerformActionComment, false);
		return this.data[4];
	}

	set performActionComment(v : string | null) {
        this.setterImpl(4, v, 'performActionComment', false, null, null);
	}

	/**
	 * Current state of this communication. Read-only.
	 *
	 * @type {SchedulingApiShiftExchangeCommunicationState}
	 */
	get communicationState() : SchedulingApiShiftExchangeCommunicationState {
		this.getterDebugValidations(this.aiCommunicationState, false);
		return this.data[5];
	}

	set communicationState(v : SchedulingApiShiftExchangeCommunicationState) {
        this.setterImpl(5, v, 'communicationState', false, null, null);
	}

	private communicationPartnerIdWrapper : Id = null!;

	/**
	 * Member id with whom the indisposed member is communicating.
	 *
	 * @type {Id}
	 */
	get communicationPartnerId() : Id {
		this.getterDebugValidations(this.aiCommunicationPartnerId, false);
		return this.communicationPartnerIdWrapper;
	}

	set communicationPartnerId(v : Id) {
        this.setterImpl(6, v, 'communicationPartnerId', false, null, function(this : SchedulingApiShiftExchangeCommunication) {this.communicationPartnerIdWrapper = v;});
	}

	/**
	 * What was the last action? Read-only.
	 *
	 * @type {SchedulingApiShiftExchangeCommunicationAction}
	 */
	get lastAction() : SchedulingApiShiftExchangeCommunicationAction {
		this.getterDebugValidations(this.aiLastAction, false);
		return this.data[7];
	}

	set lastAction(v : SchedulingApiShiftExchangeCommunicationAction) {
        this.setterImpl(7, v, 'lastAction', false, null, null);
	}

	/**
	 * Time of the last action. Read-only.
	 *
	 * @type {DateTime}
	 */
	get lastActionTime() : DateTime {
		this.getterDebugValidations(this.aiLastActionTime, false);
		return this.data[8];
	}

	set lastActionTime(v : DateTime) {
        this.setterImpl(8, v, 'lastActionTime', false, null, null);
	}

	/**
	 * Comment for the last action. Read-only.
	 *
	 * @type {string}
	 */
	get lastActionComment() : string | null {
		this.getterDebugValidations(this.aiLastActionComment, false);
		return this.data[9];
	}

	set lastActionComment(v : string | null) {
        this.setterImpl(9, v, 'lastActionComment', false, null, null);
	}

	/**
	 * Is this a communication of an admin concerned about reviewing (accepting/declining) this illness? Read-only.
	 *
	 * @type {boolean}
	 */
	get isIllnessReviewCommunication() : boolean {
		this.getterDebugValidations(this.aiIsIllnessReviewCommunication, false);
		return this.data[10];
	}

	set isIllnessReviewCommunication(v : boolean) {
        this.setterImpl(10, v, 'isIllnessReviewCommunication', false, null, null);
	}

	/**
	 * Role of requester related to this communication. Read-only. Can be "null". This role is specific to this communication as an admin can have several communications with different roles (once as admin and once as CP). In contrast shiftExchange.requesterRelationship defines a general role relative to whole shiftExchange.
	 *
	 * @type {SchedulingApiShiftExchangeCommunicationRequesterRole}
	 */
	get requesterRole() : SchedulingApiShiftExchangeCommunicationRequesterRole | null {
		this.getterDebugValidations(this.aiRequesterRole, false);
		return this.data[11];
	}

	set requesterRole(v : SchedulingApiShiftExchangeCommunicationRequesterRole | null) {
        this.setterImpl(11, v, 'requesterRole', false, null, null);
	}

	/**
	 * Has the requester a todo for this communication?
	 *
	 * @type {boolean}
	 */
	get hasTodo() : boolean {
		this.getterDebugValidations(this.aiHasTodo, false);
		return this.data[12];
	}

	set hasTodo(v : boolean) {
        this.setterImpl(12, v, 'hasTodo', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.swapOffersWrapper._fixIds(_idReplacements);
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.indisposedMembersSelectedSOIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
		this.data[6] = Meta.getReplacedId(this.data[6], _idReplacements);
		this.communicationPartnerIdWrapper = this.data[6] === null ? null! : Id.create(this.data[6]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_EXCHANGE_COMMUNICATION should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.swapOffersWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[2] : null, this.indisposedMembersSelectedSOIdWrapper))
			this.indisposedMembersSelectedSOIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
		if(!Meta.isSameId(data ? data[6] : null, this.communicationPartnerIdWrapper))
			this.communicationPartnerIdWrapper = data && data[6] ? Id.create(data[6]) : null!;
	}

	protected get dni() : string {
		return '227';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '227', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftExchangeCommunicationSwapOffersBase extends ApiListWrapper<SchedulingApiShiftExchangeCommunicationSwapOffer>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchangeCommunication | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'swapOffers');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftExchangeCommunicationSwapOffer {
		return new SchedulingApiShiftExchangeCommunicationSwapOffer(this.api, this as unknown as SchedulingApiShiftExchangeCommunicationSwapOffers, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftExchangeCommunication | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftExchangeCommunicationSwapOffers(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '228';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftExchangeCommunicationSwapOffer) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftExchangeCommunicationSwapOffer {
		const newItem = new SchedulingApiShiftExchangeCommunicationSwapOffer(this.api, this as unknown as SchedulingApiShiftExchangeCommunicationSwapOffers, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('swapOffers');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOffers, SchedulingApiShiftExchangeCommunicationSwapOffers> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOffers, SchedulingApiShiftExchangeCommunicationSwapOffers>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunicationSwapOffers,
			name: 'swapOffers',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunicationSwapOffers, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
}

				 
export class SchedulingApiShiftExchangeCommunicationSwapOffer extends ApiObjectWrapper<SchedulingApiShiftExchangeCommunicationSwapOffer>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchangeCommunicationSwapOffers | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftExchangeCommunicationSwapOffer> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftExchangeCommunicationSwapOffer as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiShiftExchangeCommunicationSwapOffer> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiShiftExchangeCommunicationSwapOffer>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunicationSwapOffer,
			name: 'shiftExchangeCommunicationSwapOffer',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFER',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunicationSwapOffer, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private shiftRefsWrapper : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs = new SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs(this.api,
		this as unknown as SchedulingApiShiftExchangeCommunicationSwapOffer, false, false);
	public aiShiftRefs = this.shiftRefsWrapper.aiThis;

	/**
	 * References to shift(s) which belong to this offer.
	 */
	get shiftRefs() : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs {
		this.getterDebugValidations(this.aiShiftRefs, false);
		return this.shiftRefsWrapper;
	}

	set shiftRefsTestSetter(v : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs) {
        this.setterImpl(1, v.rawData, 'shiftRefs', true, null, function(this : SchedulingApiShiftExchangeCommunicationSwapOffer) {this.shiftRefsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftRefsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftRefsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '244';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '244', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefsBase extends ApiListWrapper<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchangeCommunicationSwapOffer | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftRefs');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef {
		return new SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef(this.api, this as unknown as SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftExchangeCommunicationSwapOffer | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '245';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef) => void) | null = null, _backendId : ShiftId | null = null) : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef {
		const newItem = new SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef(this.api, this as unknown as SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftRefs');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs,
			name: 'shiftRefs',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFER_SHIFT_REFS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
	aiShiftExchangeCommunicationSwapOfferShiftRef : ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs,
			name: 'shiftExchangeCommunicationSwapOfferShiftRef',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFER_SHIFT_REF',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef extends ApiObjectWrapper<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef> = new ApiAttributeInfo<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef>({
			apiObjWrapper: this as any as SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef,
			name: 'shiftExchangeCommunicationSwapOfferShiftRef',
			nodeName: 'SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFER_SHIFT_REF',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_EXCHANGE_COMMUNICATION_SWAP_OFFER_SHIFT_REF should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '246';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '246', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiShiftExchangeCommunicationAction {
	IM_REPORTED_ILLNESS = 1,
	A_REPORTED_ILLNESS = 2,
	ILLNESS_NEEDS_CONFIRMATION_A_DECLINED = 3,
	ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITH_SHIFT_EXCHANGE = 4,
	ILLNESS_NEEDS_CONFIRMATION_A_ACCEPT_WITHOUT_SHIFT_EXCHANGE = 5,
	ILLNESS_DECLINED_A_ACCEPT_WITHOUT_SHIFT_EXCHANGE = 6,
	ILLNESS_DECLINED_A_ACCEPT_WITH_SHIFT_EXCHANGE = 7,
	ILLNESS_CONFIRMED_WITHOUT_SHIFT_EXCHANGE_A_START_SHIFT_EXCHANGE = 8,
	IM_NEEDS_RESPONSE = 9,
	CP_ASSIGNED_SAME_TIME = 10,
	CP_ASSIGNED_SAME_SHIFT = 11,
	CP_IS_ABSENT = 12,
	CP_IS_ILL = 13,
	CP_NOT_RESPONDED_CP_SWAP_SHIFT = 14,
	CP_NOT_RESPONDED_CP_TAKE_SHIFT_PREF_MISMATCH = 15,
	CP_NOT_RESPONDED_CP_TAKE_SHIFT_PREF_MATCH = 16,
	CP_NOT_RESPONDED_CP_CANNOT = 17,
	CP_RESPONDED_NO_CP_SWAP_SHIFT = 18,
	CP_RESPONDED_NO_CP_TAKE_SHIFT_PREF_MISMATCH = 19,
	CP_RESPONDED_NO_CP_TAKE_SHIFT_PREF_MATCH = 20,
	CP_WANTS_SWAP_CP_CHANGE_OFFERED_SHIFTS = 21,
	CP_WANTS_SWAP_CP_CANNOT = 22,
	CP_WANTS_SWAP_IM_DECLINE_SWAP = 23,
	CP_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MISMATCH = 24,
	CP_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MATCH = 25,
	CP_WANTS_SWAP_IM_ACCEPT = 26,
	IM_DECLINED_SWAP_CP_CHANGE_OFFERED_SHIFTS = 27,
	IM_DECLINED_SWAP_IM_SWAP_SHIFT = 28,
	IM_DECLINED_SWAP_CP_TAKE_SHIFT_PREF_MISMATCH = 29,
	IM_DECLINED_SWAP_CP_TAKE_SHIFT_PREF_MATCH = 30,
	CP_WANTS_TAKE_CP_SWAP_SHIFT = 31,
	CP_WANTS_TAKE_CP_CANNOT = 32,
	CP_WANTS_TAKE_IM_DECLINE = 33,
	CP_WANTS_TAKE_IM_ACCEPT = 34,
	IM_DECLINED_TAKE_CP_SWAP_SHIFT = 35,
	IM_DECLINED_TAKE_IM_TAKE_SHIFT = 36,
	IM_CHANGED_MIND_WANTS_SWAP_IM_CHANGE_SWAPPED_SHIFT = 37,
	IM_CHANGED_MIND_WANTS_SWAP_IM_DECLINE_SWAP = 38,
	IM_CHANGED_MIND_WANTS_SWAP_CP_CANNOT = 39,
	IM_CHANGED_MIND_WANTS_SWAP_CP_ACCEPT = 40,
	IM_CHANGED_MIND_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MISMATCH = 41,
	IM_CHANGED_MIND_WANTS_SWAP_CP_TAKE_SHIFT_PREF_MATCH = 42,
	IM_CHANGED_MIND_WANTS_TAKE_CP_SWAP_SHIFT = 43,
	IM_CHANGED_MIND_WANTS_TAKE_IM_DECLINE_TAKE = 44,
	IM_CHANGED_MIND_WANTS_TAKE_CP_CANNOT = 45,
	IM_CHANGED_MIND_WANTS_TAKE_CP_ACCEPT = 46,
}
export enum SchedulingApiShiftExchangeCommunicationState {
	SWAP_SUCCESSFUL = 1,
	TAKE_SUCCESSFUL = 2,
	ILLNESS_NEEDS_CONFIRMATION = 3,
	ILLNESS_DECLINED = 4,
	ILLNESS_CONFIRMED = 5,
	ILLNESS_CONFIRMED_WITHOUT_SHIFT_EXCHANGE = 6,
	CP_NOT_RESPONDED = 7,
	CP_RESPONDED_NO = 8,
	CP_CANNOT_SHIFT_EXCHANGE = 9,
	CP_WANTS_SWAP = 10,
	IM_DECLINED_SWAP = 11,
	CP_WANTS_TAKE = 12,
	IM_DECLINED_TAKE = 13,
	IM_CHANGED_MIND_WANTS_SWAP = 14,
	IM_CHANGED_MIND_WANTS_TAKE = 15,
}
export enum SchedulingApiShiftExchangeCommunicationRequesterRole {
	A = 1,
	IM = 2,
	CP = 3,
}
		 export class SchedulingApiAssignmentProcessesBase extends ApiListWrapper<SchedulingApiAssignmentProcess>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignmentProcesses');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiAssignmentProcess {
		return new SchedulingApiAssignmentProcess(this.api, this as unknown as SchedulingApiAssignmentProcesses, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiAssignmentProcesses(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '7';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiAssignmentProcess) => void) | null = null, _backendId : Id | null = null) : SchedulingApiAssignmentProcess {
		const newItem = new SchedulingApiAssignmentProcess(this.api, this as unknown as SchedulingApiAssignmentProcesses, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('assignmentProcesses');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAssignmentProcesses, SchedulingApiAssignmentProcesses> = new ApiAttributeInfo<SchedulingApiAssignmentProcesses, SchedulingApiAssignmentProcesses>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcesses,
			name: 'assignmentProcesses',
			nodeName: 'ASSIGNMENT_PROCESSES',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiAssignmentProcesses) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'calendar')||(this.api.getCurrentDataSearchParamValue('data') === 'reporting')||(this.api.getCurrentDataSearchParamValue('data') === 'shiftExchange')||(this.api.getCurrentDataSearchParamValue('data') === 'giftCards')||(this.api.getCurrentDataSearchParamValue('data') === 'transactions')||(this.api.getCurrentDataSearchParamValue('data') === 'bookings')||(this.api.currentlyDetailedLoaded instanceof SchedulingApiShiftExchange));
			},
			defaultValue: function(this : SchedulingApiAssignmentProcesses, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 6,
		});
}

				 
export class SchedulingApiAssignmentProcessBase extends ApiObjectWrapper<SchedulingApiAssignmentProcess>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAssignmentProcesses | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiAssignmentProcess> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiAssignmentProcess as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcess> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcess>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'assignmentProcess',
			nodeName: 'ASSIGNMENT_PROCESS',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : SchedulingApiAssignmentProcess) {
				return true;
			},
			defaultValue: function(this : SchedulingApiAssignmentProcess, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiState : ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessState> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessState>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'state',
			nodeName: 'ASSIGNMENT_PROCESS_STATE',
			type: PApiType.Enum,
			defaultValue: function(this : SchedulingApiAssignmentProcess, _nodeId : string) {return SchedulingApiAssignmentProcessState.NOT_STARTED;},
			rawDataIndex: 1,
		});
	aiDeadline : ApiAttributeInfo<SchedulingApiAssignmentProcess, DateTime> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, DateTime>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'deadline',
			nodeName: 'ASSIGNMENT_PROCESS_DEADLINE',
			type: PApiType.DateTime,
			rawDataIndex: 2,
		});
	aiName : ApiAttributeInfo<SchedulingApiAssignmentProcess, string> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, string>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'name',
			nodeName: 'ASSIGNMENT_PROCESS_NAME',
			type: PApiType.string,
			rawDataIndex: 3,
		});
	aiOnlyAskPrefsForUnassignedShifts : ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'onlyAskPrefsForUnassignedShifts',
			nodeName: 'ASSIGNMENT_PROCESS_ONLY_ASK_PREFS_FOR_UNASSIGNED_SHIFTS',
			type: PApiType.boolean,
			rawDataIndex: 5,
		});
	aiAssignMembersWhenNoPrefAvailable : ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'assignMembersWhenNoPrefAvailable',
			nodeName: 'ASSIGNMENT_PROCESS_ASSIGN_MEMBERS_WHEN_NO_PREF_AVAILABLE',
			type: PApiType.boolean,
			rawDataIndex: 6,
		});
	aiRemoveProcessWhenEarlyBirdAssignedAllShifts : ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'removeProcessWhenEarlyBirdAssignedAllShifts',
			nodeName: 'ASSIGNMENT_PROCESS_REMOVE_PROCESS_WHEN_EARLY_BIRD_ASSIGNED_ALL_SHIFTS',
			type: PApiType.boolean,
			rawDataIndex: 7,
		});
	aiAssignmentState : ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessAssignmentState> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessAssignmentState>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'assignmentState',
			nodeName: 'ASSIGNMENT_PROCESS_ASSIGNMENT_STATE',
			type: PApiType.Enum,
			rawDataIndex: 8,
		});
	aiMissingAssignmentsCount : ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'missingAssignmentsCount',
			nodeName: 'ASSIGNMENT_PROCESS_MISSING_ASSIGNMENTS_COUNT',
			type: PApiType.Integer,
			rawDataIndex: 9,
		});
	aiTodoShiftsCountCurrentView : ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'todoShiftsCountCurrentView',
			nodeName: 'ASSIGNMENT_PROCESS_TODO_SHIFTS_COUNT_CURRENT_VIEW',
			type: PApiType.Integer,
			rawDataIndex: 10,
		});
	aiTodoShiftsCountRightView : ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'todoShiftsCountRightView',
			nodeName: 'ASSIGNMENT_PROCESS_TODO_SHIFTS_COUNT_RIGHT_VIEW',
			type: PApiType.Integer,
			rawDataIndex: 11,
		});
	aiTodoShiftsCountLeftView : ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, Integer>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'todoShiftsCountLeftView',
			nodeName: 'ASSIGNMENT_PROCESS_TODO_SHIFTS_COUNT_LEFT_VIEW',
			type: PApiType.Integer,
			rawDataIndex: 12,
		});
	aiFirstDayStartWithTodo : ApiAttributeInfo<SchedulingApiAssignmentProcess, Date> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, Date>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'firstDayStartWithTodo',
			nodeName: 'ASSIGNMENT_PROCESS_FIRST_DAY_START_WITH_TODO',
			type: PApiType.Date,
			rawDataIndex: 13,
		});
	aiType : ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessType> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessType>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'type',
			nodeName: 'ASSIGNMENT_PROCESS_TYPE',
			type: PApiType.Enum,
			rawDataIndex: 14,
		});
	aiCanEdit : ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean> = new ApiAttributeInfo<SchedulingApiAssignmentProcess, boolean>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcess,
			name: 'canEdit',
			nodeName: 'ASSIGNMENT_PROCESS_CAN_EDIT',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiAssignmentProcess, _nodeId : string) {return false;},
			rawDataIndex: 16,
		});

	/**
	 * Current state of the process.
	 *
	 * @type {SchedulingApiAssignmentProcessState}
	 */
	get state() : SchedulingApiAssignmentProcessState {
		this.getterDebugValidations(this.aiState, false);
		return this.data[1];
	}

	set state(v : SchedulingApiAssignmentProcessState) {
        this.setterImpl(1, v, 'state', false, null, null);
	}

	/**
	 * Deadline of the current state.
	 *
	 * @type {DateTime}
	 */
	get deadline() : DateTime {
		this.getterDebugValidations(this.aiDeadline, false);
		return this.data[2];
	}

	set deadline(v : DateTime) {
        this.setterImpl(2, v, 'deadline', false, null, null);
	}

	/**
	 * Name of the process.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[3];
	}

	set name(v : string) {
        this.setterImpl(3, v, 'name', false, null, null);
	}

	private shiftRefsWrapper : SchedulingApiAssignmentProcessShiftRefs = new SchedulingApiAssignmentProcessShiftRefs(this.api,
		this as unknown as SchedulingApiAssignmentProcess, false, false);
	public aiShiftRefs = this.shiftRefsWrapper.aiThis;

	/**
	 * A list of shift ids being assigned by this process.
	 */
	get shiftRefs() : SchedulingApiAssignmentProcessShiftRefs {
		this.getterDebugValidations(this.aiShiftRefs, false);
		return this.shiftRefsWrapper;
	}

	set shiftRefsTestSetter(v : SchedulingApiAssignmentProcessShiftRefs) {
        this.setterImpl(4, v.rawData, 'shiftRefs', true, null, function(this : SchedulingApiAssignmentProcess) {this.shiftRefsWrapper = v;});
	}

	/**
	 * Should member preferences only be asked for unassigned shifts? (i.e. shifts whose assignment count is less then the needed members count).
	 *
	 * @type {boolean}
	 */
	get onlyAskPrefsForUnassignedShifts() : boolean | null {
		this.getterDebugValidations(this.aiOnlyAskPrefsForUnassignedShifts, false);
		return this.data[5];
	}

	set onlyAskPrefsForUnassignedShifts(v : boolean | null) {
        this.setterImpl(5, v, 'onlyAskPrefsForUnassignedShifts', false, null, null);
	}

	/**
	 * Can a member be assigned by the algorithm to a shift when that member has not chosen pref for that shift? Only relevant for type == DR_PLANO.
	 *
	 * @type {boolean}
	 */
	get assignMembersWhenNoPrefAvailable() : boolean | null {
		this.getterDebugValidations(this.aiAssignMembersWhenNoPrefAvailable, false);
		return this.data[6];
	}

	set assignMembersWhenNoPrefAvailable(v : boolean | null) {
        this.setterImpl(6, v, 'assignMembersWhenNoPrefAvailable', false, null, null);
	}

	/**
	 * If set true then the whole process is removed automatically when in EARLY_BIRD_SCHEDULING state all shifts were assigned the required needed-members-count.
	 *
	 * @type {boolean}
	 */
	get removeProcessWhenEarlyBirdAssignedAllShifts() : boolean | null {
		this.getterDebugValidations(this.aiRemoveProcessWhenEarlyBirdAssignedAllShifts, false);
		return this.data[7];
	}

	set removeProcessWhenEarlyBirdAssignedAllShifts(v : boolean | null) {
        this.setterImpl(7, v, 'removeProcessWhenEarlyBirdAssignedAllShifts', false, null, null);
	}

	/**
	 * What is the assignment state of the shifts of this process? Read-only.
	 *
	 * @type {SchedulingApiAssignmentProcessAssignmentState}
	 */
	get assignmentState() : SchedulingApiAssignmentProcessAssignmentState {
		this.getterDebugValidations(this.aiAssignmentState, false);
		return this.data[8];
	}

	set assignmentState(v : SchedulingApiAssignmentProcessAssignmentState) {
        this.setterImpl(8, v, 'assignmentState', false, null, null);
	}

	/**
	 * The overall number of missing assignments for this process. I.e. for a given shift Math.max(0, shift.neededMembersCount - shift.assignmentCount).
	 *
	 * @type {Integer}
	 */
	get missingAssignmentsCount() : Integer {
		this.getterDebugValidations(this.aiMissingAssignmentsCount, false);
		return this.data[9];
	}

	set missingAssignmentsCount(v : Integer) {
        this.setterImpl(9, v, 'missingAssignmentsCount', false, null, null);
	}

	/**
	 * The number of shifts for which the user has to do something in current view. Read-only.
	 *
	 * @type {Integer}
	 */
	get todoShiftsCountCurrentView() : Integer {
		this.getterDebugValidations(this.aiTodoShiftsCountCurrentView, false);
		return this.data[10];
	}

	set todoShiftsCountCurrentView(v : Integer) {
        this.setterImpl(10, v, 'todoShiftsCountCurrentView', false, null, null);
	}

	/**
	 * The number of shifts for which the user has to do something on the right side of current view. Read-only.
	 *
	 * @type {Integer}
	 */
	get todoShiftsCountRightView() : Integer {
		this.getterDebugValidations(this.aiTodoShiftsCountRightView, false);
		return this.data[11];
	}

	set todoShiftsCountRightView(v : Integer) {
        this.setterImpl(11, v, 'todoShiftsCountRightView', false, null, null);
	}

	/**
	 * The number of shifts for which the user has to do something on the left side of current view. Read-only.
	 *
	 * @type {Integer}
	 */
	get todoShiftsCountLeftView() : Integer {
		this.getterDebugValidations(this.aiTodoShiftsCountLeftView, false);
		return this.data[12];
	}

	set todoShiftsCountLeftView(v : Integer) {
        this.setterImpl(12, v, 'todoShiftsCountLeftView', false, null, null);
	}

	/**
	 * The start of the first day which has any TODO for the requester. Read-only.
	 *
	 * @type {Date}
	 */
	get firstDayStartWithTodo() : Date | null {
		this.getterDebugValidations(this.aiFirstDayStartWithTodo, false);
		return this.data[13];
	}

	set firstDayStartWithTodo(v : Date | null) {
        this.setterImpl(13, v, 'firstDayStartWithTodo', false, null, null);
	}

	/**
	 * Type of the process. Can only be set on process creation. The states of each process type is DR_PLANO (NOT_STARTED -> ASKING_MEMBER_PREFERENCES -> NEEDING_APPROVAL -> APPROVE); MANUAL (NOT_STARTED -> ASKING_MEMBER_PREFERENCES -> MANUAL_SCHEDULING -> APPROVE); EARLY_BIRD (NOT_STARTED -> EARLY_BIRD_SCHEDULING -> EARLY_BIRD_FINISHED).
	 *
	 * @type {SchedulingApiAssignmentProcessType}
	 */
	get type() : SchedulingApiAssignmentProcessType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[14];
	}

	set type(v : SchedulingApiAssignmentProcessType) {
        this.setterImpl(14, v, 'type', false, null, null);
	}

	private missingPrefsMemberIdsWrapper : SchedulingApiAssignmentProcessMissingPrefsMemberIds = new SchedulingApiAssignmentProcessMissingPrefsMemberIds(this.api,
		this as unknown as SchedulingApiAssignmentProcess, false, false);
	public aiMissingPrefsMemberIds = this.missingPrefsMemberIdsWrapper.aiThis;

	/**
	 * Ids of members with missing prefs. Send to owner. Send during ASKING_MEMBER_PREFERENCES and all following states. Read-only.
	 */
	get missingPrefsMemberIds() : SchedulingApiAssignmentProcessMissingPrefsMemberIds {
		this.getterDebugValidations(this.aiMissingPrefsMemberIds, false);
		return this.missingPrefsMemberIdsWrapper;
	}

	set missingPrefsMemberIdsTestSetter(v : SchedulingApiAssignmentProcessMissingPrefsMemberIds) {
        this.setterImpl(15, v.rawData, 'missingPrefsMemberIds', true, null, function(this : SchedulingApiAssignmentProcess) {this.missingPrefsMemberIdsWrapper = v;});
	}

	/**
	 * Can the requester edit this assignment-process? Read-only.
	 *
	 * @type {boolean}
	 */
	get canEdit() : boolean {
		this.getterDebugValidations(this.aiCanEdit, false);
		return this.data[16];
	}

	set canEdit(v : boolean) {
        this.setterImpl(16, v, 'canEdit', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftRefsWrapper._fixIds(_idReplacements);
		this.missingPrefsMemberIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ASSIGNMENT_PROCESS should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftRefsWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
		this.missingPrefsMemberIdsWrapper._updateRawData(data && data[15] !== undefined ? data[15] : null, generateMissingData);
	}

	protected get dni() : string {
		return '145';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '145', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiAssignmentProcessState {
	NOT_STARTED = 1,
	ASKING_MEMBER_PREFERENCES = 2,
	NEEDING_APPROVAL = 3,
	MANUAL_SCHEDULING = 4,
	APPROVE = 5,
	EARLY_BIRD_SCHEDULING = 6,
	EARLY_BIRD_FINISHED = 7,
}
		 export class SchedulingApiAssignmentProcessShiftRefsBase extends ApiListWrapper<SchedulingApiAssignmentProcessShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAssignmentProcess | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftRefs');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiAssignmentProcessShiftRef {
		return new SchedulingApiAssignmentProcessShiftRef(this.api, this as unknown as SchedulingApiAssignmentProcessShiftRefs, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiAssignmentProcess | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiAssignmentProcessShiftRefs(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '149';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiAssignmentProcessShiftRef) => void) | null = null, _backendId : ShiftId | null = null) : SchedulingApiAssignmentProcessShiftRef {
		const newItem = new SchedulingApiAssignmentProcessShiftRef(this.api, this as unknown as SchedulingApiAssignmentProcessShiftRefs, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftRefs');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRefs, SchedulingApiAssignmentProcessShiftRefs> = new ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRefs, SchedulingApiAssignmentProcessShiftRefs>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcessShiftRefs,
			name: 'shiftRefs',
			nodeName: 'ASSIGNMENT_PROCESS_SHIFT_REFS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiAssignmentProcessShiftRefs, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 4,
		});
}

				 
export class SchedulingApiAssignmentProcessShiftRef extends ApiObjectWrapper<SchedulingApiAssignmentProcessShiftRef>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAssignmentProcessShiftRefs | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiAssignmentProcessShiftRef> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiAssignmentProcessShiftRef as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRef, SchedulingApiAssignmentProcessShiftRef> = new ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRef, SchedulingApiAssignmentProcessShiftRef>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcessShiftRef,
			name: 'assignmentProcessShiftRef',
			nodeName: 'ASSIGNMENT_PROCESS_SHIFT_REF',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiAssignmentProcessShiftRef, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiRequesterCanSetPref : ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRef, boolean> = new ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRef, boolean>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcessShiftRef,
			name: 'requesterCanSetPref',
			nodeName: 'ASSIGNMENT_PROCESS_SHIFT_REF_REQUESTER_CAN_SET_PREF',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiAssignmentProcessShiftRef, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiRequesterCanDoEarlyBird : ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRef, boolean> = new ApiAttributeInfo<SchedulingApiAssignmentProcessShiftRef, boolean>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcessShiftRef,
			name: 'requesterCanDoEarlyBird',
			nodeName: 'ASSIGNMENT_PROCESS_SHIFT_REF_REQUESTER_CAN_DO_EARLY_BIRD',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiAssignmentProcessShiftRef, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});

	/**
	 * Can requester currently set shift.myPref?
	 *
	 * @type {boolean}
	 */
	get requesterCanSetPref() : boolean {
		this.getterDebugValidations(this.aiRequesterCanSetPref, false);
		return this.data[1];
	}

	set requesterCanSetPref(v : boolean) {
        this.setterImpl(1, v, 'requesterCanSetPref', false, null, null);
	}

	/**
	 * Can requester currently set shift.earlyBirdAssignToMe?
	 *
	 * @type {boolean}
	 */
	get requesterCanDoEarlyBird() : boolean {
		this.getterDebugValidations(this.aiRequesterCanDoEarlyBird, false);
		return this.data[2];
	}

	set requesterCanDoEarlyBird(v : boolean) {
        this.setterImpl(2, v, 'requesterCanDoEarlyBird', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ASSIGNMENT_PROCESS_SHIFT_REF should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '162';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '162', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiAssignmentProcessAssignmentState {
	NONE_ASSIGNED = 1,
	PARTIALLY_ASSIGNED = 2,
	ALL_ASSIGNED = 3,
}
export enum SchedulingApiAssignmentProcessType {
	DR_PLANO = 1,
	MANUAL = 2,
	EARLY_BIRD = 3,
}
		 export class SchedulingApiAssignmentProcessMissingPrefsMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAssignmentProcess | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'missingPrefsMemberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiAssignmentProcess | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiAssignmentProcessMissingPrefsMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '160';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAssignmentProcessMissingPrefsMemberIds, SchedulingApiAssignmentProcessMissingPrefsMemberIds> = new ApiAttributeInfo<SchedulingApiAssignmentProcessMissingPrefsMemberIds, SchedulingApiAssignmentProcessMissingPrefsMemberIds>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcessMissingPrefsMemberIds,
			name: 'missingPrefsMemberIds',
			nodeName: 'ASSIGNMENT_PROCESS_MISSING_PREFS_MEMBER_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiAssignmentProcessMissingPrefsMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 15,
		});
	aiAssignmentProcessMissingPrefsMemberId : ApiAttributeInfo<SchedulingApiAssignmentProcessMissingPrefsMemberIds, Id> = new ApiAttributeInfo<SchedulingApiAssignmentProcessMissingPrefsMemberIds, Id>({
			apiObjWrapper: this as any as SchedulingApiAssignmentProcessMissingPrefsMemberIds,
			name: 'assignmentProcessMissingPrefsMemberId',
			nodeName: 'ASSIGNMENT_PROCESS_MISSING_PREFS_MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 export class SchedulingApiMembersBase extends ApiListWrapper<SchedulingApiMember>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'members');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiMember {
		return new SchedulingApiMember(this.api, this as unknown as SchedulingApiMembers, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiMembers(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '8';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiMember) => void) | null = null, _backendId : Id | null = null) : SchedulingApiMember {
		const newItem = new SchedulingApiMember(this.api, this as unknown as SchedulingApiMembers, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('members');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMembers, SchedulingApiMembers> = new ApiAttributeInfo<SchedulingApiMembers, SchedulingApiMembers>({
			apiObjWrapper: this as any as SchedulingApiMembers,
			name: 'members',
			nodeName: 'MEMBERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiMembers, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 7,
		});
}

				 
export class SchedulingApiMemberBase extends ApiObjectWrapper<SchedulingApiMember>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiMembers | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiMember> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiMember as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMember, SchedulingApiMember> = new ApiAttributeInfo<SchedulingApiMember, SchedulingApiMember>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'member',
			nodeName: 'MEMBER',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiMember) {
	return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.pPermissionsService.isMe(this)));
			},
			defaultValue: function(this : SchedulingApiMember, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiFirstName : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'firstName',
			nodeName: 'MEMBER_FIRST_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiLastName : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'lastName',
			nodeName: 'MEMBER_LAST_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiEmail : ApiAttributeInfo<SchedulingApiMember, Email> = new ApiAttributeInfo<SchedulingApiMember, Email>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'email',
			nodeName: 'MEMBER_EMAIL',
			type: PApiType.Email,
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
						this.api.asyncValidators.emailValidAsync.bind(this.api.asyncValidators, true
						,(this.isNewItem ? undefined : this.id)
						 ),
				];
			}
			,
			rawDataIndex: 3,
		});
	aiTrashed : ApiAttributeInfo<SchedulingApiMember, boolean> = new ApiAttributeInfo<SchedulingApiMember, boolean>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'trashed',
			nodeName: 'MEMBER_TRASHED',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiMember, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiMinMonthlyEarnings : ApiAttributeInfo<SchedulingApiMember, ClientCurrency> = new ApiAttributeInfo<SchedulingApiMember, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'minMonthlyEarnings',
			nodeName: 'MEMBER_MIN_MONTHLY_EARNINGS',
			type: PApiType.ClientCurrency,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userCanSeeEarningsOfMember(this)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.maxMonthlyEarnings, true, PApiType.ClientCurrency, this.aiMaxMonthlyEarnings.id, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiMaxMonthlyEarnings : ApiAttributeInfo<SchedulingApiMember, ClientCurrency> = new ApiAttributeInfo<SchedulingApiMember, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'maxMonthlyEarnings',
			nodeName: 'MEMBER_MAX_MONTHLY_EARNINGS',
			type: PApiType.ClientCurrency,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userCanSeeEarningsOfMember(this)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(() => this.minMonthlyEarnings, true, PApiType.ClientCurrency, this.aiMinMonthlyEarnings.id, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiDesiredMonthlyEarnings : ApiAttributeInfo<SchedulingApiMember, ClientCurrency> = new ApiAttributeInfo<SchedulingApiMember, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'desiredMonthlyEarnings',
			nodeName: 'MEMBER_DESIRED_MONTHLY_EARNINGS',
			type: PApiType.ClientCurrency,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userCanSeeEarningsOfMember(this)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.isMe(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.maxMonthlyEarnings, true, PApiType.ClientCurrency, this.aiMaxMonthlyEarnings.id, undefined);						return null;
					},
					() => {
return this.api.validators.min(() => this.minMonthlyEarnings, true, PApiType.ClientCurrency, this.aiMinMonthlyEarnings.id, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiMember, _nodeId : string) {return INITIALIZED_IN_BACKEND;},
			rawDataIndex: 7,
		});
	aiAvgDaysPerWeek : ApiAttributeInfo<SchedulingApiMember, Days> = new ApiAttributeInfo<SchedulingApiMember, Days>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'avgDaysPerWeek',
			nodeName: 'MEMBER_AVG_DAYS_PER_WEEK',
			type: PApiType.Days,
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});
	aiAvgHoursPerDay : ApiAttributeInfo<SchedulingApiMember, Hours> = new ApiAttributeInfo<SchedulingApiMember, Hours>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'avgHoursPerDay',
			nodeName: 'MEMBER_AVG_HOURS_PER_DAY',
			type: PApiType.Hours,
			hasPermissionToSet: () => false,
			rawDataIndex: 10,
		});
	aiBirthday : ApiAttributeInfo<SchedulingApiMember, Date> = new ApiAttributeInfo<SchedulingApiMember, Date>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'birthday',
			nodeName: 'MEMBER_BIRTHDAY',
			type: PApiType.Date,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.data[this.api.consts.MEMBER_BIRTHDAY] !== undefined)||(this.isNewItem));
			},
			isAvailableByBusinessLogic: function(this : SchedulingApiMember) {
				return ((this.api.currentlyDetailedLoaded instanceof SchedulingApiMember)||(!this.trashed && this.data[this.api.consts.MEMBER_BIRTHDAY] !== null)||(this.isNewItem));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
	return ((this.api.pPermissionsService.hasManagerPermissionForMember(this))||(this.api.pPermissionsService.isMe(this)));
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.min(-1577926800000, true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(+this.api.pMoment.monthsFromNow(-1), true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiAvatar : ApiAttributeInfo<SchedulingApiMember, Image> = new ApiAttributeInfo<SchedulingApiMember, Image>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'avatar',
			nodeName: 'MEMBER_AVATAR',
			type: PApiType.Image,
			isAvailableByBusinessLogic: function(this : SchedulingApiMember) {
				return ((!this.isNewItem));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.isMe(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
						return this.api.validators.imageMaxWidth(256);
					},
					() => {
						return this.api.validators.imageMaxHeight(256);
					},
					() => {
						return this.api.validators.maxFileSize(5120);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
					() => {
						return this.api.asyncValidators.asyncImageRatio(1.0);
					},
					() => {
						return this.api.asyncValidators.asyncImageMinWidth(256);
					},
					() => {
						return this.api.asyncValidators.asyncImageMinHeight(256);
					},
				];
			}
			,
			rawDataIndex: 13,
		});
	aiPhone : ApiAttributeInfo<SchedulingApiMember, Tel> = new ApiAttributeInfo<SchedulingApiMember, Tel>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'phone',
			nodeName: 'MEMBER_PHONE',
			type: PApiType.Tel,
			isDetailedAttribute: true,
			rawDataIndex: 14,
		});
	aiGender : ApiAttributeInfo<SchedulingApiMember, SchedulingApiGender> = new ApiAttributeInfo<SchedulingApiMember, SchedulingApiGender>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'gender',
			nodeName: 'MEMBER_GENDER',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiPassword : ApiAttributeInfo<SchedulingApiMember, Password> = new ApiAttributeInfo<SchedulingApiMember, Password>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'password',
			nodeName: 'MEMBER_PASSWORD',
			type: PApiType.Password,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.Password, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiMember, _nodeId : string) {return INITIALIZED_IN_BACKEND;},
			rawDataIndex: 16,
		});
	aiAddressStreet : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'addressStreet',
			nodeName: 'MEMBER_ADDRESS_STREET',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.pPermissionsService.isMe(this)));
			},
			rawDataIndex: 17,
		});
	aiAddressPostalCode : ApiAttributeInfo<SchedulingApiMember, PostalCode> = new ApiAttributeInfo<SchedulingApiMember, PostalCode>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'addressPostalCode',
			nodeName: 'MEMBER_ADDRESS_POSTAL_CODE',
			type: PApiType.PostalCode,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.pPermissionsService.isMe(this)));
			},
			rawDataIndex: 18,
		});
	aiAddressCity : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'addressCity',
			nodeName: 'MEMBER_ADDRESS_CITY',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.pPermissionsService.isMe(this)));
			},
			rawDataIndex: 19,
		});
	aiEmploymentBegin : ApiAttributeInfo<SchedulingApiMember, Date> = new ApiAttributeInfo<SchedulingApiMember, Date>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'employmentBegin',
			nodeName: 'MEMBER_EMPLOYMENT_BEGIN',
			type: PApiType.Date,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.max(() => this.employmentEnd, true, PApiType.Date, this.aiEmploymentEnd.id, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiEmploymentEnd : ApiAttributeInfo<SchedulingApiMember, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiMember, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'employmentEnd',
			nodeName: 'MEMBER_EMPLOYMENT_END',
			type: PApiType.DateExclusiveEnd,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.min(() => this.employmentBegin, true, PApiType.DateExclusiveEnd, this.aiEmploymentBegin.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiComments : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'comments',
			nodeName: 'MEMBER_COMMENTS',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 22,
		});
	aiPersonnelNumbers : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'personnelNumbers',
			nodeName: 'MEMBER_PERSONNEL_NUMBERS',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 23,
		});
	aiPlaceOfBirth : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'placeOfBirth',
			nodeName: 'MEMBER_PLACE_OF_BIRTH',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 24,
		});
	aiSocialSecurityNumber : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'socialSecurityNumber',
			nodeName: 'MEMBER_SOCIAL_SECURITY_NUMBER',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 25,
		});
	aiTaxId : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'taxId',
			nodeName: 'MEMBER_TAX_ID',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 26,
		});
	aiHealthInsurance : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'healthInsurance',
			nodeName: 'MEMBER_HEALTH_INSURANCE',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 27,
		});
	aiNationality : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'nationality',
			nodeName: 'MEMBER_NATIONALITY',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 28,
		});
	aiDenomination : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'denomination',
			nodeName: 'MEMBER_DENOMINATION',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 29,
		});
	aiAccountIBAN : ApiAttributeInfo<SchedulingApiMember, Iban> = new ApiAttributeInfo<SchedulingApiMember, Iban>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'accountIBAN',
			nodeName: 'MEMBER_ACCOUNT_IBAN',
			type: PApiType.Iban,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.pPermissionsService.isMe(this)));
			},
			rawDataIndex: 30,
		});
	aiAccountOwner : ApiAttributeInfo<SchedulingApiMember, string> = new ApiAttributeInfo<SchedulingApiMember, string>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'accountOwner',
			nodeName: 'MEMBER_ACCOUNT_OWNER',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiMember) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.pPermissionsService.isMe(this)));
			},
			rawDataIndex: 31,
		});
	aiMainJob : ApiAttributeInfo<SchedulingApiMember, boolean> = new ApiAttributeInfo<SchedulingApiMember, boolean>({
			apiObjWrapper: this as any as SchedulingApiMember,
			name: 'mainJob',
			nodeName: 'MEMBER_MAIN_JOB',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiMember) {
				{const conditionValue = this.api.pPermissionsService.hasManagerPermissionForMember(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMember) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMember) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiMember, _nodeId : string) {return false;},
			rawDataIndex: 32,
		});

	/**
	 * First name of the member
	 *
	 * @type {string}
	 */
	get firstName() : string {
		this.getterDebugValidations(this.aiFirstName, false);
		return this.data[1];
	}

	set firstName(v : string) {
        this.setterImpl(1, v, 'firstName', false, null, null);
	}

	/**
	 * Last name of the member.
	 *
	 * @type {string}
	 */
	get lastName() : string {
		this.getterDebugValidations(this.aiLastName, false);
		return this.data[2];
	}

	set lastName(v : string) {
        this.setterImpl(2, v, 'lastName', false, null, null);
	}

	/**
	 * Email address of this member. (Note for Milad: This needs to be deserialized before "trashed" because trashed=false checks if current email is unused)
	 *
	 * @type {Email}
	 */
	get email() : Email {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[3];
	}

	set email(v : Email) {
        this.setterImpl(3, v, 'email', false, null, null);
	}

	/**
	 * Is this member trashed?
	 *
	 * @type {boolean}
	 */
	get trashed() : boolean {
		this.getterDebugValidations(this.aiTrashed, false);
		return this.data[4];
	}

	set trashed(v : boolean) {
        this.setterImpl(4, v, 'trashed', false, null, null);
	}

	/**
	 * What is the minimum this member should earn?
	 *
	 * @type {ClientCurrency}
	 */
	get minMonthlyEarnings() : ClientCurrency {
		this.getterDebugValidations(this.aiMinMonthlyEarnings, false);
		return this.data[5];
	}

	set minMonthlyEarnings(v : ClientCurrency) {
        this.setterImpl(5, v, 'minMonthlyEarnings', false, null, null);
	}

	/**
	 * What is the maximum this member should earn?
	 *
	 * @type {ClientCurrency}
	 */
	get maxMonthlyEarnings() : ClientCurrency {
		this.getterDebugValidations(this.aiMaxMonthlyEarnings, false);
		return this.data[6];
	}

	set maxMonthlyEarnings(v : ClientCurrency) {
        this.setterImpl(6, v, 'maxMonthlyEarnings', false, null, null);
	}

	/**
	 * What is the desired earnings of this member?
	 *
	 * @type {ClientCurrency}
	 */
	get desiredMonthlyEarnings() : ClientCurrency {
		this.getterDebugValidations(this.aiDesiredMonthlyEarnings, false);
		return this.data[7];
	}

	set desiredMonthlyEarnings(v : ClientCurrency) {
        this.setterImpl(7, v, 'desiredMonthlyEarnings', false, null, null);
	}

	private permissionGroupIdsWrapper : SchedulingApiMemberPermissionGroupIds = new SchedulingApiMemberPermissionGroupIds(this.api,
		this as unknown as SchedulingApiMember, false, false);
	public aiPermissionGroupIds = this.permissionGroupIdsWrapper.aiThis;

	/**
	 * A list of ids of the permission groups assigned to this member. This list must contain at least one item.
	 */
	get permissionGroupIds() : SchedulingApiMemberPermissionGroupIds {
		this.getterDebugValidations(this.aiPermissionGroupIds, false);
		return this.permissionGroupIdsWrapper;
	}

	set permissionGroupIdsTestSetter(v : SchedulingApiMemberPermissionGroupIds) {
        this.setterImpl(8, v.rawData, 'permissionGroupIds', true, null, function(this : SchedulingApiMember) {this.permissionGroupIdsWrapper = v;});
	}

	/**
	 * Only send for data == "reporting" and to client-owners. How many days per week does this member work on average.
	 *
	 * @type {Days}
	 */
	get avgDaysPerWeek() : Days | null {
		this.getterDebugValidations(this.aiAvgDaysPerWeek, false);
		return this.data[9];
	}

	set avgDaysPerWeekTestSetter(v : Days | null) {
        this.setterImpl(9, v, 'avgDaysPerWeek', true, null, null);
	}

	/**
	 * Only send for data == "reporting" and to client-owners. How many hours per day does this member work on average.
	 *
	 * @type {Hours}
	 */
	get avgHoursPerDay() : Hours | null {
		this.getterDebugValidations(this.aiAvgHoursPerDay, false);
		return this.data[10];
	}

	set avgHoursPerDayTestSetter(v : Hours | null) {
        this.setterImpl(10, v, 'avgHoursPerDay', true, null, null);
	}

	private assignableShiftModelsWrapper : SchedulingApiAssignableShiftModels = new SchedulingApiAssignableShiftModels(this.api,
		this as unknown as SchedulingApiMember, false, false);
	public aiAssignableShiftModels = this.assignableShiftModelsWrapper.aiThis;

	/**
	 * A list of all assignable shift models. Can be used with "changeSelector".
	 */
	get assignableShiftModels() : SchedulingApiAssignableShiftModels {
		this.getterDebugValidations(this.aiAssignableShiftModels, false);
		return this.assignableShiftModelsWrapper;
	}

	set assignableShiftModelsTestSetter(v : SchedulingApiAssignableShiftModels) {
        this.setterImpl(11, v.rawData, 'assignableShiftModels', true, null, function(this : SchedulingApiMember) {this.assignableShiftModelsWrapper = v;});
	}

	/**
	 * Members birthday.
	 *
	 * @type {Date}
	 */
	get birthday() : Date | null {
		this.getterDebugValidations(this.aiBirthday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[12] === undefined) {
			this.data[12] = this.aiBirthday.defaultValue;
			
		}
		return this.data[12];
	}

	set birthday(v : Date | null) {
        this.setterImpl(12, v, 'birthday', false, null, null);
	}

	/**
	 * The profile image of the member.
	 *
	 * @type {Image}
	 */
	get avatar() : Image | null {
		this.getterDebugValidations(this.aiAvatar, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[13] === undefined) {
			this.data[13] = this.aiAvatar.defaultValue;
			
		}
		const path = this.data[13] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set avatar(v : Image | null) {
        this.setterImpl(13, v, 'avatar', false, null, null);
	}

	/**
	 * (Detail) Phone number of the member.
	 *
	 * @type {Tel}
	 */
	get phone() : Tel | null {
		this.getterDebugValidations(this.aiPhone, false);
		return this.data[14];
	}

	set phone(v : Tel | null) {
        this.setterImpl(14, v, 'phone', false, null, null);
	}

	/**
	 * (Detail) The gender of the user.
	 *
	 * @type {SchedulingApiGender}
	 */
	get gender() : SchedulingApiGender {
		this.getterDebugValidations(this.aiGender, false);
		return this.data[15];
	}

	set gender(v : SchedulingApiGender) {
        this.setterImpl(15, v, 'gender', false, null, null);
	}

	/**
	 * (Detail) Password of this member. Api always returns "xxxxxx". It is used for setting a new password.
	 *
	 * @type {Password}
	 */
	get password() : Password {
		this.getterDebugValidations(this.aiPassword, false);
		return this.data[16];
	}

	set password(v : Password) {
        this.setterImpl(16, v, 'password', false, null, null);
	}

	/**
	 * (Detail) Street and house number of member.
	 *
	 * @type {string}
	 */
	get addressStreet() : string | null {
		this.getterDebugValidations(this.aiAddressStreet, false);
		return this.data[17];
	}

	set addressStreet(v : string | null) {
        this.setterImpl(17, v, 'addressStreet', false, null, null);
	}

	/**
	 * (Detail) Postal code of member.
	 *
	 * @type {PostalCode}
	 */
	get addressPostalCode() : PostalCode | null {
		this.getterDebugValidations(this.aiAddressPostalCode, false);
		return this.data[18];
	}

	set addressPostalCode(v : PostalCode | null) {
        this.setterImpl(18, v, 'addressPostalCode', false, null, null);
	}

	/**
	 * (Detail) City of member.
	 *
	 * @type {string}
	 */
	get addressCity() : string | null {
		this.getterDebugValidations(this.aiAddressCity, false);
		return this.data[19];
	}

	set addressCity(v : string | null) {
        this.setterImpl(19, v, 'addressCity', false, null, null);
	}

	/**
	 * (Detail) When did the employment of this member begin?
	 *
	 * @type {Date}
	 */
	get employmentBegin() : Date {
		this.getterDebugValidations(this.aiEmploymentBegin, false);
		return this.data[20];
	}

	set employmentBegin(v : Date) {
        this.setterImpl(20, v, 'employmentBegin', false, null, null);
	}

	/**
	 * (Detail) When did the employment of this member end?
	 *
	 * @type {DateExclusiveEnd}
	 */
	get employmentEnd() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiEmploymentEnd, false);
		return this.data[21];
	}

	set employmentEnd(v : DateExclusiveEnd | null) {
        this.setterImpl(21, v, 'employmentEnd', false, null, null);
	}

	/**
	 * (Detail) Comments related to the member.
	 *
	 * @type {string}
	 */
	get comments() : string | null {
		this.getterDebugValidations(this.aiComments, false);
		return this.data[22];
	}

	set comments(v : string | null) {
        this.setterImpl(22, v, 'comments', false, null, null);
	}

	/**
	 * (Detail) Personnel numbers of this member.
	 *
	 * @type {string}
	 */
	get personnelNumbers() : string | null {
		this.getterDebugValidations(this.aiPersonnelNumbers, false);
		return this.data[23];
	}

	set personnelNumbers(v : string | null) {
        this.setterImpl(23, v, 'personnelNumbers', false, null, null);
	}

	/**
	 * (Detail) Place of birth of this member.
	 *
	 * @type {string}
	 */
	get placeOfBirth() : string | null {
		this.getterDebugValidations(this.aiPlaceOfBirth, false);
		return this.data[24];
	}

	set placeOfBirth(v : string | null) {
        this.setterImpl(24, v, 'placeOfBirth', false, null, null);
	}

	/**
	 * (Detail) Social security number of this member.
	 *
	 * @type {string}
	 */
	get socialSecurityNumber() : string | null {
		this.getterDebugValidations(this.aiSocialSecurityNumber, false);
		return this.data[25];
	}

	set socialSecurityNumber(v : string | null) {
        this.setterImpl(25, v, 'socialSecurityNumber', false, null, null);
	}

	/**
	 * (Detail) Tax ID of this member.
	 *
	 * @type {string}
	 */
	get taxId() : string | null {
		this.getterDebugValidations(this.aiTaxId, false);
		return this.data[26];
	}

	set taxId(v : string | null) {
        this.setterImpl(26, v, 'taxId', false, null, null);
	}

	/**
	 * (Detail) Health insurance of this member.
	 *
	 * @type {string}
	 */
	get healthInsurance() : string | null {
		this.getterDebugValidations(this.aiHealthInsurance, false);
		return this.data[27];
	}

	set healthInsurance(v : string | null) {
        this.setterImpl(27, v, 'healthInsurance', false, null, null);
	}

	/**
	 * (Detail) The nationality of the member.
	 *
	 * @type {string}
	 */
	get nationality() : string | null {
		this.getterDebugValidations(this.aiNationality, false);
		return this.data[28];
	}

	set nationality(v : string | null) {
        this.setterImpl(28, v, 'nationality', false, null, null);
	}

	/**
	 * (Detail) Denomination of this member.
	 *
	 * @type {string}
	 */
	get denomination() : string | null {
		this.getterDebugValidations(this.aiDenomination, false);
		return this.data[29];
	}

	set denomination(v : string | null) {
        this.setterImpl(29, v, 'denomination', false, null, null);
	}

	/**
	 * (Detail) Bank account IBAN of this member.
	 *
	 * @type {Iban}
	 */
	get accountIBAN() : Iban | null {
		this.getterDebugValidations(this.aiAccountIBAN, false);
		return this.data[30];
	}

	set accountIBAN(v : Iban | null) {
        this.setterImpl(30, v, 'accountIBAN', false, null, null);
	}

	/**
	 * (Detail) Bank account owner of this member.
	 *
	 * @type {string}
	 */
	get accountOwner() : string | null {
		this.getterDebugValidations(this.aiAccountOwner, false);
		return this.data[31];
	}

	set accountOwner(v : string | null) {
        this.setterImpl(31, v, 'accountOwner', false, null, null);
	}

	/**
	 * (Detail) Is this the main job of the member?
	 *
	 * @type {boolean}
	 */
	get mainJob() : boolean {
		this.getterDebugValidations(this.aiMainJob, false);
		return this.data[32];
	}

	set mainJob(v : boolean) {
        this.setterImpl(32, v, 'mainJob', false, null, null);
	}

	private changeSelectorWrapper : SchedulingApiMemberChangeSelector = new SchedulingApiMemberChangeSelector(this.api,
		this as unknown as SchedulingApiMember);
	public aiChangeSelector = this.changeSelectorWrapper.aiThis;

	/**
	 * (Detail) Set by user to define what should - additionally to this member - be modified.
	 */
	get changeSelector() : SchedulingApiMemberChangeSelector {
		this.getterDebugValidations(this.aiChangeSelector, false);
		return this.changeSelectorWrapper;
	}

	set changeSelectorTestSetter(v : SchedulingApiMemberChangeSelector) {
        this.setterImpl(33, v.rawData, 'changeSelector', true, null, function(this : SchedulingApiMember) {this.changeSelectorWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.permissionGroupIdsWrapper._fixIds(_idReplacements);
		this.assignableShiftModelsWrapper._fixIds(_idReplacements);
		this.changeSelectorWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('MEMBER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.permissionGroupIdsWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		this.assignableShiftModelsWrapper._updateRawData(data && data[11] !== undefined ? data[11] : null, generateMissingData);
		this.changeSelectorWrapper._updateRawData(data && data[33] !== undefined ? data[33] : null, generateMissingData);
	}

	protected get dni() : string {
		return '166';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '166', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiMemberPermissionGroupIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiMember | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'permissionGroupIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiMember | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiMemberPermissionGroupIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '174';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMemberPermissionGroupIds, SchedulingApiMemberPermissionGroupIds> = new ApiAttributeInfo<SchedulingApiMemberPermissionGroupIds, SchedulingApiMemberPermissionGroupIds>({
			apiObjWrapper: this as any as SchedulingApiMemberPermissionGroupIds,
			name: 'permissionGroupIds',
			nodeName: 'MEMBER_PERMISSION_GROUP_IDS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiMemberPermissionGroupIds) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMemberPermissionGroupIds) {
				return [
					() => { return this.parent!.minAmountOfAdmins() },
				];
			},
			asyncValidations: function(this : SchedulingApiMemberPermissionGroupIds) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiMemberPermissionGroupIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 8,
		});
	aiMemberPermissionGroupId : ApiAttributeInfo<SchedulingApiMemberPermissionGroupIds, Id> = new ApiAttributeInfo<SchedulingApiMemberPermissionGroupIds, Id>({
			apiObjWrapper: this as any as SchedulingApiMemberPermissionGroupIds,
			name: 'memberPermissionGroupId',
			nodeName: 'MEMBER_PERMISSION_GROUP_ID',
			type: PApiType.Id,
			
		});
}

				 export class SchedulingApiMemberAssignableShiftModelsBase extends ApiListWrapper<SchedulingApiAssignableShiftModel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiMember | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignableShiftModels');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiAssignableShiftModel {
		return new SchedulingApiAssignableShiftModel(this.api, this as unknown as SchedulingApiAssignableShiftModels, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiMember | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiAssignableShiftModels(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '177';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiAssignableShiftModel) => void) | null = null, _backendId : Id | null = null) : SchedulingApiAssignableShiftModel {
		const newItem = new SchedulingApiAssignableShiftModel(this.api, this as unknown as SchedulingApiAssignableShiftModels, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('assignableShiftModels');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAssignableShiftModels, SchedulingApiAssignableShiftModels> = new ApiAttributeInfo<SchedulingApiAssignableShiftModels, SchedulingApiAssignableShiftModels>({
			apiObjWrapper: this as any as SchedulingApiAssignableShiftModels,
			name: 'assignableShiftModels',
			nodeName: 'MEMBER_ASSIGNABLE_SHIFT_MODELS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiAssignableShiftModels) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiAssignableShiftModels, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 11,
		});
}

				 
export class SchedulingApiMemberAssignableShiftModelBase extends ApiObjectWrapper<SchedulingApiAssignableShiftModel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAssignableShiftModels | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiAssignableShiftModel> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiAssignableShiftModel as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAssignableShiftModel, SchedulingApiAssignableShiftModel> = new ApiAttributeInfo<SchedulingApiAssignableShiftModel, SchedulingApiAssignableShiftModel>({
			apiObjWrapper: this as any as SchedulingApiAssignableShiftModel,
			name: 'memberAssignableShiftModel',
			nodeName: 'MEMBER_ASSIGNABLE_SHIFT_MODEL',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiAssignableShiftModel, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiHourlyEarnings : ApiAttributeInfo<SchedulingApiAssignableShiftModel, ClientCurrency> = new ApiAttributeInfo<SchedulingApiAssignableShiftModel, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiAssignableShiftModel,
			name: 'hourlyEarnings',
			nodeName: 'MEMBER_ASSIGNABLE_SHIFT_MODEL_EARNINGS',
			type: PApiType.ClientCurrency,
			rawDataIndex: 1,
		});
	aiShiftModelId : ApiAttributeInfo<SchedulingApiAssignableShiftModel, Id> = new ApiAttributeInfo<SchedulingApiAssignableShiftModel, Id>({
			apiObjWrapper: this as any as SchedulingApiAssignableShiftModel,
			name: 'shiftModelId',
			nodeName: 'MEMBER_ASSIGNABLE_SHIFT_MODEL_ID',
			type: PApiType.Id,
			rawDataIndex: 2,
		});

	/**
	 * Members hourly earnings for this shift model
	 *
	 * @type {ClientCurrency}
	 */
	get hourlyEarnings() : ClientCurrency {
		this.getterDebugValidations(this.aiHourlyEarnings, false);
		return this.data[1];
	}

	set hourlyEarnings(v : ClientCurrency) {
        this.setterImpl(1, v, 'hourlyEarnings', false, null, null);
	}

	private shiftModelIdWrapper : Id = null!;

	/**
	 * Id of assignable shift model
	 *
	 * @type {Id}
	 */
	get shiftModelId() : Id {
		this.getterDebugValidations(this.aiShiftModelId, false);
		return this.shiftModelIdWrapper;
	}

	set shiftModelId(v : Id) {
        this.setterImpl(2, v, 'shiftModelId', false, null, function(this : SchedulingApiAssignableShiftModel) {this.shiftModelIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.shiftModelIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('MEMBER_ASSIGNABLE_SHIFT_MODEL should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[2] : null, this.shiftModelIdWrapper))
			this.shiftModelIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
	}

	protected get dni() : string {
		return '201';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '201', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiGender {
	MALE = 1,
	FEMALE = 2,
	DIVERSE = 3,
}
		 
export class SchedulingApiMemberChangeSelector extends ApiObjectWrapper<SchedulingApiMemberChangeSelector>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiMember | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiMemberChangeSelector> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiMemberChangeSelector as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMemberChangeSelector, SchedulingApiMemberChangeSelector> = new ApiAttributeInfo<SchedulingApiMemberChangeSelector, SchedulingApiMemberChangeSelector>({
			apiObjWrapper: this as any as SchedulingApiMemberChangeSelector,
			name: 'changeSelector',
			nodeName: 'MEMBER_CHANGE_SELECTOR',
			type: PApiType.ApiObject,
			isDetailedAttribute: true,
			defaultValue: function(this : SchedulingApiMemberChangeSelector, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 33,
		});
	aiStart : ApiAttributeInfo<SchedulingApiMemberChangeSelector, Date> = new ApiAttributeInfo<SchedulingApiMemberChangeSelector, Date>({
			apiObjWrapper: this as any as SchedulingApiMemberChangeSelector,
			name: 'start',
			nodeName: 'MEMBER_CHANGE_SELECTOR_START',
			type: PApiType.Date,
			validations: function(this : SchedulingApiMemberChangeSelector) {
				return [
					() => {
return this.api.validators.min(+this.api.pMoment.monthsFromNow(-5), true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(+this.api.pMoment.monthsFromNow(12), true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMemberChangeSelector) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});

	/**
	 * The start time from which the existing shifts should be modified.
	 *
	 * @type {Date}
	 */
	get start() : Date | null {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set start(v : Date | null) {
        this.setterImpl(1, v, 'start', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '199';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '199', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiBookingsBase extends ApiListWrapper<SchedulingApiBooking>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'bookings');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiBooking {
		return new SchedulingApiBooking(this.api, this as unknown as SchedulingApiBookings, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiBookings(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '9';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiBooking) => void) | null = null, _backendId : Id | null = null) : SchedulingApiBooking {
		const newItem = new SchedulingApiBooking(this.api, this as unknown as SchedulingApiBookings, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('bookings');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiBookings, SchedulingApiBookings> = new ApiAttributeInfo<SchedulingApiBookings, SchedulingApiBookings>({
			apiObjWrapper: this as any as SchedulingApiBookings,
			name: 'bookings',
			nodeName: 'BOOKINGS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiBookings) {
				{const conditionValue = this.api.pPermissionsService.userCanWriteBookings(); if(!conditionValue) return conditionValue === false ? {sourceString: 'Dir fehlen die Rechte, um Buchungen erstellen zu können.', params: false} : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiBookings) {
				{const conditionValue = this.api.data.aiShiftModels.isAvailable && this.api.data.shiftModels.length > 0; if(!conditionValue) return conditionValue === false ? {sourceString: 'Du brauchst mindestens eine buchbare Tätigkeit, um Buchungen erstellen zu können.', params: false} : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiBookings, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 8,
		});
}

				 
export class SchedulingApiBookingBase extends ApiObjectWrapper<SchedulingApiBooking>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiBookings | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiBooking> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiBooking as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBooking> = new ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBooking>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'booking',
			nodeName: 'BOOKING',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiBooking) {
				{const conditionValue = this.api.pPermissionsService.userCanWriteBooking(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiState : ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBookingState> = new ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBookingState>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'state',
			nodeName: 'BOOKING_STATE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiInternalNotes : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'internalNotes',
			nodeName: 'BOOKING_INTERNAL_NOTES',
			type: PApiType.string,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiBookingNumber : ApiAttributeInfo<SchedulingApiBooking, Integer> = new ApiAttributeInfo<SchedulingApiBooking, Integer>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'bookingNumber',
			nodeName: 'BOOKING_BOOKING_NUMBER',
			type: PApiType.Integer,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiCourseSelector : ApiAttributeInfo<SchedulingApiBooking, ShiftSelector> = new ApiAttributeInfo<SchedulingApiBooking, ShiftSelector>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'courseSelector',
			nodeName: 'BOOKING_COURSE_SELECTOR',
			type: PApiType.ShiftSelector,
			rawDataIndex: 4,
		});
	aiShiftModelId : ApiAttributeInfo<SchedulingApiBooking, Id> = new ApiAttributeInfo<SchedulingApiBooking, Id>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'shiftModelId',
			nodeName: 'BOOKING_SHIFT_MODEL_ID',
			type: PApiType.Id,
			canSetByBusinessLogic: function(this : SchedulingApiBooking) {
				{const conditionValue = this.aiShiftModelId.value === null; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiDateOfBooking : ApiAttributeInfo<SchedulingApiBooking, DateTime> = new ApiAttributeInfo<SchedulingApiBooking, DateTime>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'dateOfBooking',
			nodeName: 'BOOKING_DATE_OF_BOOKING',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiBookingComment : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'bookingComment',
			nodeName: 'BOOKING_BOOKING_COMMENT',
			type: PApiType.string,
			rawDataIndex: 7,
		});
	aiAllShiftsRemoved : ApiAttributeInfo<SchedulingApiBooking, boolean> = new ApiAttributeInfo<SchedulingApiBooking, boolean>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'allShiftsRemoved',
			nodeName: 'BOOKING_ALL_SHIFTS_REMOVED',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return false;},
			rawDataIndex: 8,
		});
	aiFirstName : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'firstName',
			nodeName: 'BOOKING_FIRST_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiLastName : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'lastName',
			nodeName: 'BOOKING_LAST_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiFirstShiftStart : ApiAttributeInfo<SchedulingApiBooking, DateTime> = new ApiAttributeInfo<SchedulingApiBooking, DateTime>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'firstShiftStart',
			nodeName: 'BOOKING_FIRST_SHIFT_START',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 11,
		});
	aiParticipantCount : ApiAttributeInfo<SchedulingApiBooking, Integer> = new ApiAttributeInfo<SchedulingApiBooking, Integer>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'participantCount',
			nodeName: 'BOOKING_PARTICIPANT_COUNT',
			type: PApiType.Integer,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiCurrentlyPaid : ApiAttributeInfo<SchedulingApiBooking, ClientCurrency> = new ApiAttributeInfo<SchedulingApiBooking, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'currentlyPaid',
			nodeName: 'BOOKING_CURRENTLY_PAID',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 13,
		});
	aiCancellationFee : ApiAttributeInfo<SchedulingApiBooking, ClientCurrency> = new ApiAttributeInfo<SchedulingApiBooking, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'cancellationFee',
			nodeName: 'BOOKING_CANCELLATION_FEE',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(10000, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return 0;},
			rawDataIndex: 14,
		});
	aiOverallTariffId : ApiAttributeInfo<SchedulingApiBooking, Id> = new ApiAttributeInfo<SchedulingApiBooking, Id>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'overallTariffId',
			nodeName: 'BOOKING_OVERALL_TARIFF_ID',
			type: PApiType.Id,
			rawDataIndex: 16,
		});
	aiFirstShiftEnd : ApiAttributeInfo<SchedulingApiBooking, DateTime> = new ApiAttributeInfo<SchedulingApiBooking, DateTime>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'firstShiftEnd',
			nodeName: 'BOOKING_FIRST_SHIFT_END',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 17,
		});
	aiFirstShiftSelector : ApiAttributeInfo<SchedulingApiBooking, ShiftId> = new ApiAttributeInfo<SchedulingApiBooking, ShiftId>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'firstShiftSelector',
			nodeName: 'BOOKING_FIRST_SHIFT_SELECTOR',
			type: PApiType.ShiftId,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 18,
		});
	aiAttended : ApiAttributeInfo<SchedulingApiBooking, boolean> = new ApiAttributeInfo<SchedulingApiBooking, boolean>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'attended',
			nodeName: 'BOOKING_ATTENDED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiBooking) {
				{const conditionValue = this.canWriteAttended; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			skipParentSetConditions: true,
			rawDataIndex: 19,
		});
	aiCanWriteAttended : ApiAttributeInfo<SchedulingApiBooking, boolean> = new ApiAttributeInfo<SchedulingApiBooking, boolean>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'canWriteAttended',
			nodeName: 'BOOKING_CAN_WRITE_ATTENDED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return true;},
			rawDataIndex: 20,
		});
	aiAttendedSetByPos : ApiAttributeInfo<SchedulingApiBooking, boolean> = new ApiAttributeInfo<SchedulingApiBooking, boolean>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'attendedSetByPos',
			nodeName: 'BOOKING_ATTENDED_SET_BY_POS',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return false;},
			rawDataIndex: 21,
		});
	aiDateOfBirth : ApiAttributeInfo<SchedulingApiBooking, Date> = new ApiAttributeInfo<SchedulingApiBooking, Date>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'dateOfBirth',
			nodeName: 'BOOKING_DATE_OF_BIRTH',
			type: PApiType.Date,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
					() => {
return this.api.validators.min(-1577926800000, true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(+this.api.pMoment.m().add(0, 'minutes'), true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiStreetAndHouseNumber : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'streetAndHouseNumber',
			nodeName: 'BOOKING_STREET_AND_HOUSE_NUMBER',
			type: PApiType.string,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiCity : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'city',
			nodeName: 'BOOKING_CITY',
			type: PApiType.string,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiPostalCode : ApiAttributeInfo<SchedulingApiBooking, PostalCode> = new ApiAttributeInfo<SchedulingApiBooking, PostalCode>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'postalCode',
			nodeName: 'BOOKING_POSTAL_CODE',
			type: PApiType.PostalCode,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.PostalCode, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiEmail : ApiAttributeInfo<SchedulingApiBooking, Email> = new ApiAttributeInfo<SchedulingApiBooking, Email>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'email',
			nodeName: 'BOOKING_EMAIL',
			type: PApiType.Email,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiPhoneMobile : ApiAttributeInfo<SchedulingApiBooking, Tel> = new ApiAttributeInfo<SchedulingApiBooking, Tel>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'phoneMobile',
			nodeName: 'BOOKING_PHONE_MOBILE',
			type: PApiType.Tel,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Tel, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	aiPhoneLandline : ApiAttributeInfo<SchedulingApiBooking, Tel> = new ApiAttributeInfo<SchedulingApiBooking, Tel>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'phoneLandline',
			nodeName: 'BOOKING_PHONE_LANDLINE',
			type: PApiType.Tel,
			isDetailedAttribute: true,
			rawDataIndex: 28,
		});
	aiPaymentMethodId : ApiAttributeInfo<SchedulingApiBooking, Id> = new ApiAttributeInfo<SchedulingApiBooking, Id>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'paymentMethodId',
			nodeName: 'BOOKING_PAYMENT_METHOD_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			rawDataIndex: 29,
		});
	aiDesiredDate : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'desiredDate',
			nodeName: 'BOOKING_DESIRED_DATE',
			type: PApiType.string,
			isDetailedAttribute: true,
			rawDataIndex: 30,
		});
	aiWantsNewsletter : ApiAttributeInfo<SchedulingApiBooking, boolean> = new ApiAttributeInfo<SchedulingApiBooking, boolean>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'wantsNewsletter',
			nodeName: 'BOOKING_WANTS_NEWSLETTER',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return false;},
			rawDataIndex: 31,
		});
	aiPaidBeforeTransactionListIntroduction : ApiAttributeInfo<SchedulingApiBooking, ClientCurrency> = new ApiAttributeInfo<SchedulingApiBooking, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'paidBeforeTransactionListIntroduction',
			nodeName: 'BOOKING_PAID_BEFORE_TRANSACTION_LIST_INTRODUCTION',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 32,
		});
	aiCompany : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'company',
			nodeName: 'BOOKING_COMPANY',
			type: PApiType.string,
			isDetailedAttribute: true,
			rawDataIndex: 33,
		});
	aiAdditionalFieldValue : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'additionalFieldValue',
			nodeName: 'BOOKING_ADDITIONAL_FIELD_VALUE',
			type: PApiType.string,
			isDetailedAttribute: true,
			rawDataIndex: 34,
		});
	aiAgeMin : ApiAttributeInfo<SchedulingApiBooking, Integer> = new ApiAttributeInfo<SchedulingApiBooking, Integer>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'ageMin',
			nodeName: 'BOOKING_AGE_MIN',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiBooking) {
				return ((this.model.onlyWholeCourseBookable === true));
			},
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
		if(((this.model.onlyWholeCourseBookable === true)))
		{
			return this.api.validators.required(PApiType.Integer, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.max(() => this.ageMax, true, PApiType.Integer, this.aiAgeMax.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 35,
		});
	aiAgeMax : ApiAttributeInfo<SchedulingApiBooking, Integer> = new ApiAttributeInfo<SchedulingApiBooking, Integer>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'ageMax',
			nodeName: 'BOOKING_AGE_MAX',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiBooking) {
				return ((this.model.onlyWholeCourseBookable === true));
			},
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
		if(((this.model.onlyWholeCourseBookable === true)))
		{
			return this.api.validators.required(PApiType.Integer, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(() => this.ageMin, true, PApiType.Integer, this.aiAgeMin.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			rawDataIndex: 36,
		});
	aiCancellationPolicyId : ApiAttributeInfo<SchedulingApiBooking, Id> = new ApiAttributeInfo<SchedulingApiBooking, Id>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'cancellationPolicyId',
			nodeName: 'BOOKING_CANCELLATION_POLICY_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 37,
		});
	aiHasRightOfWithdrawal : ApiAttributeInfo<SchedulingApiBooking, boolean> = new ApiAttributeInfo<SchedulingApiBooking, boolean>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'hasRightOfWithdrawal',
			nodeName: 'BOOKING_HAS_RIGHT_OF_WITHDRAWAL',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return false;},
			rawDataIndex: 38,
		});
	aiApplicableCancellationFeePeriodId : ApiAttributeInfo<SchedulingApiBooking, Id> = new ApiAttributeInfo<SchedulingApiBooking, Id>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'applicableCancellationFeePeriodId',
			nodeName: 'BOOKING_APPLICABLE_CANCELLATION_FEE_PERIOD_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 39,
		});
	aiRefundLimitDueToOnlineBalance : ApiAttributeInfo<SchedulingApiBooking, ClientCurrency> = new ApiAttributeInfo<SchedulingApiBooking, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'refundLimitDueToOnlineBalance',
			nodeName: 'BOOKING_ACCOUNT_REFUND_LIMIT_DUE_TO_ONLINE_BALANCE',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 40,
		});
	aiIsAnonymized : ApiAttributeInfo<SchedulingApiBooking, boolean> = new ApiAttributeInfo<SchedulingApiBooking, boolean>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'isAnonymized',
			nodeName: 'BOOKING_IS_ANONYMIZED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return false;},
			rawDataIndex: 41,
		});
	aiCourseType : ApiAttributeInfo<SchedulingApiBooking, SchedulingApiCourseType> = new ApiAttributeInfo<SchedulingApiBooking, SchedulingApiCourseType>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'courseType',
			nodeName: 'BOOKING_COURSE_TYPE',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return this.model.courseType;},
			rawDataIndex: 42,
		});
	aiBookingDesiredDateSetting : ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBookingDesiredDateSetting> = new ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBookingDesiredDateSetting>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'bookingDesiredDateSetting',
			nodeName: 'BOOKING_BOOKING_DESIRED_DATE_SETTING',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiBooking) {
				return ((this.model.aiBookingDesiredDateSetting.isAvailable));
			},
			hasPermissionToSet: () => false,
			validations: function(this : SchedulingApiBooking) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBooking) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiBooking, _nodeId : string) {return this.model.bookingDesiredDateSetting;},
			rawDataIndex: 43,
		});
	aiCreatedBy : ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBookableCreatedBy> = new ApiAttributeInfo<SchedulingApiBooking, SchedulingApiBookableCreatedBy>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'createdBy',
			nodeName: 'BOOKING_CREATED_BY',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 44,
		});
	aiTestingDeferredPaymentToken : ApiAttributeInfo<SchedulingApiBooking, string> = new ApiAttributeInfo<SchedulingApiBooking, string>({
			apiObjWrapper: this as any as SchedulingApiBooking,
			name: 'testingDeferredPaymentToken',
			nodeName: 'BOOKING_TESTING_DEFERRED_PAYMENT_TOKEN',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiBooking) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 45,
		});

	/**
	 * Current state of this booking
	 *
	 * @type {SchedulingApiBookingState}
	 */
	get state() : SchedulingApiBookingState {
		this.getterDebugValidations(this.aiState, false);
		return this.data[1];
	}

	set state(v : SchedulingApiBookingState) {
        this.setterImpl(1, v, 'state', false, null, null);
	}

	/**
	 * Internal notes for this booking.
	 *
	 * @type {string}
	 */
	get internalNotes() : string | null {
		this.getterDebugValidations(this.aiInternalNotes, false);
		return this.data[2];
	}

	set internalNotes(v : string | null) {
        this.setterImpl(2, v, 'internalNotes', false, null, null);
	}

	/**
	 * Unique booking number.
	 *
	 * @type {Integer}
	 */
	get bookingNumber() : Integer {
		this.getterDebugValidations(this.aiBookingNumber, false);
		return this.data[3];
	}

	set bookingNumberTestSetter(v : Integer) {
        this.setterImpl(3, v, 'bookingNumber', true, null, null);
	}

	private courseSelectorWrapper : ShiftSelector | null = null!;

	/**
	 * A shift-selector selecting a shift or a whole packet. When setting this value you can set it to the selector of a shift and in case this shift belongs to a packet, backend will automatically change it to the packet selector.
	 *
	 * @type {ShiftSelector}
	 */
	get courseSelector() : ShiftSelector | null {
		this.getterDebugValidations(this.aiCourseSelector, false);
		return this.courseSelectorWrapper;
	}

	set courseSelector(v : ShiftSelector | null) {
        this.setterImpl(4, v, 'courseSelector', false, null, function(this : SchedulingApiBooking) {this.courseSelectorWrapper = v;});
	}

	private shiftModelIdWrapper : Id = null!;

	/**
	 * The shift-model to which this booking belongs.
	 *
	 * @type {Id}
	 */
	get shiftModelId() : Id {
		this.getterDebugValidations(this.aiShiftModelId, false);
		return this.shiftModelIdWrapper;
	}

	set shiftModelId(v : Id) {
        this.setterImpl(5, v, 'shiftModelId', false, null, function(this : SchedulingApiBooking) {this.shiftModelIdWrapper = v;});
	}

	/**
	 * Date when booking was created.
	 *
	 * @type {DateTime}
	 */
	get dateOfBooking() : DateTime {
		this.getterDebugValidations(this.aiDateOfBooking, false);
		return this.data[6];
	}

	set dateOfBookingTestSetter(v : DateTime) {
        this.setterImpl(6, v, 'dateOfBooking', true, null, null);
	}

	/**
	 * A comment being given by the booking person during the booking process.
	 *
	 * @type {string}
	 */
	get bookingComment() : string | null {
		this.getterDebugValidations(this.aiBookingComment, false);
		return this.data[7];
	}

	set bookingComment(v : string | null) {
        this.setterImpl(7, v, 'bookingComment', false, null, null);
	}

	/**
	 * Have all shifts associated which this booking been removed? Read only.
	 *
	 * @type {boolean}
	 */
	get allShiftsRemoved() : boolean {
		this.getterDebugValidations(this.aiAllShiftsRemoved, false);
		return this.data[8];
	}

	set allShiftsRemovedTestSetter(v : boolean) {
        this.setterImpl(8, v, 'allShiftsRemoved', true, null, null);
	}

	/**
	 * First name of the booking person.
	 *
	 * @type {string}
	 */
	get firstName() : string {
		this.getterDebugValidations(this.aiFirstName, false);
		return this.data[9];
	}

	set firstName(v : string) {
        this.setterImpl(9, v, 'firstName', false, null, function(this : SchedulingApiBooking) {if(this.bookingPersonParticipant !== null)
						this.bookingPersonParticipant.firstName = v;});
	}

	/**
	 * Last name of the booking person.
	 *
	 * @type {string}
	 */
	get lastName() : string {
		this.getterDebugValidations(this.aiLastName, false);
		return this.data[10];
	}

	set lastName(v : string) {
        this.setterImpl(10, v, 'lastName', false, null, function(this : SchedulingApiBooking) {if(this.bookingPersonParticipant !== null)
						this.bookingPersonParticipant.lastName = v;});
	}

	/**
	 * Start time of first not removed course shift. If all course shifts are removed then time of first original shift is taken. Is "null" when no shift is associated with this booking. Read only.
	 *
	 * @type {DateTime}
	 */
	get firstShiftStart() : DateTime | null {
		this.getterDebugValidations(this.aiFirstShiftStart, false);
		return this.data[11];
	}

	set firstShiftStartTestSetter(v : DateTime | null) {
        this.setterImpl(11, v, 'firstShiftStart', true, null, null);
	}

	/**
	 * The participant count of this booking. Should only be defined manually when shiftModel.onlyWholeCourseBookable == true. Otherwise this value will be automatically determined by backend according to participants list.
	 *
	 * @type {Integer}
	 */
	get participantCount() : Integer {
		this.getterDebugValidations(this.aiParticipantCount, false);
		return this.data[12];
	}

	set participantCount(v : Integer) {
        this.setterImpl(12, v, 'participantCount', false, null, null);
	}

	/**
	 * How much has currently been paid by the booking person.
	 *
	 * @type {ClientCurrency}
	 */
	get currentlyPaid() : ClientCurrency {
		this.getterDebugValidations(this.aiCurrentlyPaid, false);
		return this.data[13];
	}

	set currentlyPaidTestSetter(v : ClientCurrency) {
        this.setterImpl(13, v, 'currentlyPaid', true, null, null);
	}

	/**
	 * The cancellation fee to be paid for this booking.
	 *
	 * @type {ClientCurrency}
	 */
	get cancellationFee() : ClientCurrency {
		this.getterDebugValidations(this.aiCancellationFee, false);
		return this.data[14];
	}

	set cancellationFee(v : ClientCurrency) {
        this.setterImpl(14, v, 'cancellationFee', false, null, null);
	}

	private participantsWrapper : SchedulingApiBookingParticipants = new SchedulingApiBookingParticipants(this.api,
		this as unknown as SchedulingApiBooking, false, false);
	public aiParticipants = this.participantsWrapper.aiThis;

	/**
	 * A list of participants belonging to this booking. Should only be defined when shiftModel.onlyWholeCourseBookable == false.
	 */
	get participants() : SchedulingApiBookingParticipants {
		this.getterDebugValidations(this.aiParticipants, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[15] === undefined) {
			this.data[15] = this.aiParticipants.defaultValue;
			this.participantsWrapper._updateRawData(this.data[15], true);
		}
		return this.participantsWrapper;
	}

	set participantsTestSetter(v : SchedulingApiBookingParticipants) {
        this.setterImpl(15, v.rawData, 'participants', true, null, function(this : SchedulingApiBooking) {this.participantsWrapper = v;});
	}

	private overallTariffIdWrapper : Id | null = null!;

	/**
	 * When shiftModel.onlyWholeCourseBookable == true this will return the tariff of the booking. In this case, this value still can be "null" in which case the booking is free. When shiftModel.onlyWholeCourseBookable == false, this will always be null and each participant will define his own tariff.
	 *
	 * @type {Id}
	 */
	get overallTariffId() : Id | null {
		this.getterDebugValidations(this.aiOverallTariffId, false);
		return this.overallTariffIdWrapper;
	}

	set overallTariffId(v : Id | null) {
        this.setterImpl(16, v, 'overallTariffId', false, null, function(this : SchedulingApiBooking) {this.overallTariffIdWrapper = v;});
	}

	/**
	 * (Detail) End time of first not removed course shift. If all course shifts are removed then time of first original shift is taken. Is "null" when no shift is associated with this booking. Read only.
	 *
	 * @type {DateTime}
	 */
	get firstShiftEnd() : DateTime | null {
		this.getterDebugValidations(this.aiFirstShiftEnd, false);
		return this.data[17];
	}

	set firstShiftEndTestSetter(v : DateTime | null) {
        this.setterImpl(17, v, 'firstShiftEnd', true, null, null);
	}

	private firstShiftSelectorWrapper : ShiftId | null = null!;

	/**
	 * (Detail) The shift-selector of the first not removed course shift. If all course shifts are removed then shift-selector of the original first shift is returned.
	 *
	 * @type {ShiftId}
	 */
	get firstShiftSelector() : ShiftId | null {
		this.getterDebugValidations(this.aiFirstShiftSelector, false);
		return this.firstShiftSelectorWrapper;
	}

	set firstShiftSelectorTestSetter(v : ShiftId | null) {
        this.setterImpl(18, v, 'firstShiftSelector', true, null, function(this : SchedulingApiBooking) {this.firstShiftSelectorWrapper = v;});
	}

	/**
	 * (Detail) Has the booking been attended? Show this only when the booking has participants. If not, then "null" will be returned by the api in which case you should look at "participant.attended".
	 *
	 * @type {boolean}
	 */
	get attended() : boolean | null {
		this.getterDebugValidations(this.aiAttended, false);
		return this.data[19];
	}

	set attended(v : boolean | null) {
        this.setterImpl(19, v, 'attended', false, null, null);
	}

	/**
	 * (Detail) Can requester write booking.attended or participant.attended?
	 *
	 * @type {boolean}
	 */
	get canWriteAttended() : boolean {
		this.getterDebugValidations(this.aiCanWriteAttended, false);
		return this.data[20];
	}

	set canWriteAttendedTestSetter(v : boolean) {
        this.setterImpl(20, v, 'canWriteAttended', true, null, null);
	}

	/**
	 * (Detail) Was the attended attribute set the POS system?
	 *
	 * @type {boolean}
	 */
	get attendedSetByPos() : boolean {
		this.getterDebugValidations(this.aiAttendedSetByPos, false);
		return this.data[21];
	}

	set attendedSetByPosTestSetter(v : boolean) {
        this.setterImpl(21, v, 'attendedSetByPos', true, null, null);
	}

	/**
	 * (Detail) Date of birth of the booking person.
	 *
	 * @type {Date}
	 */
	get dateOfBirth() : Date {
		this.getterDebugValidations(this.aiDateOfBirth, false);
		return this.data[22];
	}

	set dateOfBirth(v : Date) {
        this.setterImpl(22, v, 'dateOfBirth', false, null, function(this : SchedulingApiBooking) {if(this.bookingPersonParticipant !== null)
						this.bookingPersonParticipant.dateOfBirth = v;});
	}

	/**
	 * (Detail) Street and house number of the booking person.
	 *
	 * @type {string}
	 */
	get streetAndHouseNumber() : string {
		this.getterDebugValidations(this.aiStreetAndHouseNumber, false);
		return this.data[23];
	}

	set streetAndHouseNumber(v : string) {
        this.setterImpl(23, v, 'streetAndHouseNumber', false, null, null);
	}

	/**
	 * (Detail) City of the booking person.
	 *
	 * @type {string}
	 */
	get city() : string {
		this.getterDebugValidations(this.aiCity, false);
		return this.data[24];
	}

	set city(v : string) {
        this.setterImpl(24, v, 'city', false, null, null);
	}

	/**
	 * (Detail) Postal code of the booking person.
	 *
	 * @type {PostalCode}
	 */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.aiPostalCode, false);
		return this.data[25];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(25, v, 'postalCode', false, null, null);
	}

	/**
	 * (Detail) Email of the booking person.
	 *
	 * @type {Email}
	 */
	get email() : Email {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[26];
	}

	set email(v : Email) {
        this.setterImpl(26, v, 'email', false, null, function(this : SchedulingApiBooking) {if(this.bookingPersonParticipant !== null)
						this.bookingPersonParticipant.email = v;});
	}

	/**
	 * (Detail) Mobile phone of the booking person.
	 *
	 * @type {Tel}
	 */
	get phoneMobile() : Tel {
		this.getterDebugValidations(this.aiPhoneMobile, false);
		return this.data[27];
	}

	set phoneMobile(v : Tel) {
        this.setterImpl(27, v, 'phoneMobile', false, null, null);
	}

	/**
	 * (Detail) Landline phone of the booking person.
	 *
	 * @type {Tel}
	 */
	get phoneLandline() : Tel | null {
		this.getterDebugValidations(this.aiPhoneLandline, false);
		return this.data[28];
	}

	set phoneLandline(v : Tel | null) {
        this.setterImpl(28, v, 'phoneLandline', false, null, null);
	}

	private paymentMethodIdWrapper : Id | null = null!;

	/**
	 * (Detail) The payment-method being selected by the booking person.
	 *
	 * @type {Id}
	 */
	get paymentMethodId() : Id | null {
		this.getterDebugValidations(this.aiPaymentMethodId, false);
		return this.paymentMethodIdWrapper;
	}

	set paymentMethodId(v : Id | null) {
        this.setterImpl(29, v, 'paymentMethodId', false, null, function(this : SchedulingApiBooking) {this.paymentMethodIdWrapper = v;});
	}

	/**
	 * (Detail) The desired date description being given by the booking person.
	 *
	 * @type {string}
	 */
	get desiredDate() : string | null {
		this.getterDebugValidations(this.aiDesiredDate, false);
		return this.data[30];
	}

	set desiredDate(v : string | null) {
        this.setterImpl(30, v, 'desiredDate', false, null, null);
	}

	/**
	 * (Detail) Does the booking person wants newsletter?
	 *
	 * @type {boolean}
	 */
	get wantsNewsletter() : boolean {
		this.getterDebugValidations(this.aiWantsNewsletter, false);
		return this.data[31];
	}

	set wantsNewsletter(v : boolean) {
        this.setterImpl(31, v, 'wantsNewsletter', false, null, null);
	}

	/**
	 * (Detail) The amount which was paid before we introduced the detailed transaction list for each bookable. Ignore it when it is "null".
	 *
	 * @type {ClientCurrency}
	 */
	get paidBeforeTransactionListIntroduction() : ClientCurrency | null {
		this.getterDebugValidations(this.aiPaidBeforeTransactionListIntroduction, false);
		return this.data[32];
	}

	set paidBeforeTransactionListIntroductionTestSetter(v : ClientCurrency | null) {
        this.setterImpl(32, v, 'paidBeforeTransactionListIntroduction', true, null, null);
	}

	/**
	 * (Detail) Company of the booking person.
	 *
	 * @type {string}
	 */
	get company() : string | null {
		this.getterDebugValidations(this.aiCompany, false);
		return this.data[33];
	}

	set company(v : string | null) {
        this.setterImpl(33, v, 'company', false, null, null);
	}

	/**
	 * (Detail) Additional field value for the whole booking. Should only be shown when booking has an overallTariff with "overallTariff.additionalFieldLabel" defined.
	 *
	 * @type {string}
	 */
	get additionalFieldValue() : string | null {
		this.getterDebugValidations(this.aiAdditionalFieldValue, false);
		return this.data[34];
	}

	set additionalFieldValue(v : string | null) {
        this.setterImpl(34, v, 'additionalFieldValue', false, null, null);
	}

	/**
	 * (Detail) Description about min average age of the participants. Can only be defined when shiftModel.onlyWholeCourseBookable == true.
	 *
	 * @type {Integer}
	 */
	get ageMin() : Integer | null {
		this.getterDebugValidations(this.aiAgeMin, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[35] === undefined) {
			this.data[35] = this.aiAgeMin.defaultValue;
			
		}
		return this.data[35];
	}

	set ageMin(v : Integer | null) {
        this.setterImpl(35, v, 'ageMin', false, null, null);
	}

	/**
	 * (Detail) Description about max average age of the participants. Can only be defined when shiftModel.onlyWholeCourseBookable == true.
	 *
	 * @type {Integer}
	 */
	get ageMax() : Integer | null {
		this.getterDebugValidations(this.aiAgeMax, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[36] === undefined) {
			this.data[36] = this.aiAgeMax.defaultValue;
			
		}
		return this.data[36];
	}

	set ageMax(v : Integer | null) {
        this.setterImpl(36, v, 'ageMax', false, null, null);
	}

	private cancellationPolicyIdWrapper : Id = null!;

	/**
	 * (Detail) The id of the cancellation-policy used by this booking.
	 *
	 * @type {Id}
	 */
	get cancellationPolicyId() : Id {
		this.getterDebugValidations(this.aiCancellationPolicyId, false);
		return this.cancellationPolicyIdWrapper;
	}

	set cancellationPolicyIdTestSetter(v : Id) {
        this.setterImpl(37, v, 'cancellationPolicyId', true, null, function(this : SchedulingApiBooking) {this.cancellationPolicyIdWrapper = v;});
	}

	/**
	 * (Detail) Has the booking person have write of withdrawal at this moment?
	 *
	 * @type {boolean}
	 */
	get hasRightOfWithdrawal() : boolean {
		this.getterDebugValidations(this.aiHasRightOfWithdrawal, false);
		return this.data[38];
	}

	set hasRightOfWithdrawalTestSetter(v : boolean) {
        this.setterImpl(38, v, 'hasRightOfWithdrawal', true, null, null);
	}

	private applicableCancellationFeePeriodIdWrapper : Id | null = null!;

	/**
	 * (Detail) The fee-period id of the cancellation-policy "cancellationPolicyId" which should be applied at this moment.
	 *
	 * @type {Id}
	 */
	get applicableCancellationFeePeriodId() : Id | null {
		this.getterDebugValidations(this.aiApplicableCancellationFeePeriodId, false);
		return this.applicableCancellationFeePeriodIdWrapper;
	}

	set applicableCancellationFeePeriodIdTestSetter(v : Id | null) {
        this.setterImpl(39, v, 'applicableCancellationFeePeriodId', true, null, function(this : SchedulingApiBooking) {this.applicableCancellationFeePeriodIdWrapper = v;});
	}

	/**
	 * (Detail) The max refund value when limited by online balance. This value is send to everyone who can trigger online refunds. Thus as a security measure it is only send when online balance limits the refund amount to not leak the online balance to unauthorized users. Otherwise "null" is returned.
	 *
	 * @type {ClientCurrency}
	 */
	get refundLimitDueToOnlineBalance() : ClientCurrency | null {
		this.getterDebugValidations(this.aiRefundLimitDueToOnlineBalance, false);
		return this.data[40];
	}

	set refundLimitDueToOnlineBalanceTestSetter(v : ClientCurrency | null) {
        this.setterImpl(40, v, 'refundLimitDueToOnlineBalance', true, null, null);
	}

	/**
	 * (Detail) Is this booking anonymized?
	 *
	 * @type {boolean}
	 */
	get isAnonymized() : boolean {
		this.getterDebugValidations(this.aiIsAnonymized, false);
		return this.data[41];
	}

	set isAnonymizedTestSetter(v : boolean) {
        this.setterImpl(41, v, 'isAnonymized', true, null, null);
	}

	/**
	 * (Detail) The course-type to the time when this booking was created. When handling a specific booking you should always use this value instead of "shiftModel.courseType" to remain independent of changes in "shiftModel.courseType".
	 *
	 * @type {SchedulingApiCourseType}
	 */
	get courseType() : SchedulingApiCourseType {
		this.getterDebugValidations(this.aiCourseType, false);
		return this.data[42];
	}

	set courseTypeTestSetter(v : SchedulingApiCourseType) {
        this.setterImpl(42, v, 'courseType', true, null, null);
	}

	/**
	 * (Detail) The desired-date setting to the time when this booking was created. When handling a specific booking you should always use this value instead of "shiftModel.bookingDesiredDateSetting" to remain independent of changes in "shiftModel.bookingDesiredDateSetting".
	 *
	 * @type {SchedulingApiBookingDesiredDateSetting}
	 */
	get bookingDesiredDateSetting() : SchedulingApiBookingDesiredDateSetting {
		this.getterDebugValidations(this.aiBookingDesiredDateSetting, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[43] === undefined) {
			this.data[43] = this.aiBookingDesiredDateSetting.defaultValue;
			
		}
		return this.data[43];
	}

	set bookingDesiredDateSettingTestSetter(v : SchedulingApiBookingDesiredDateSetting) {
        this.setterImpl(43, v, 'bookingDesiredDateSetting', true, null, null);
	}

	/**
	 * (Detail) What has created this booking?
	 *
	 * @type {SchedulingApiBookableCreatedBy}
	 */
	get createdBy() : SchedulingApiBookableCreatedBy {
		this.getterDebugValidations(this.aiCreatedBy, false);
		return this.data[44];
	}

	set createdByTestSetter(v : SchedulingApiBookableCreatedBy) {
        this.setterImpl(44, v, 'createdBy', true, null, null);
	}

	/**
	 * (Detail) A token for deferred Payments. only for testing.
	 *
	 * @type {string}
	 */
	get testingDeferredPaymentToken() : string {
		this.getterDebugValidations(this.aiTestingDeferredPaymentToken, false);
		return this.data[45];
	}

	set testingDeferredPaymentTokenTestSetter(v : string) {
        this.setterImpl(45, v, 'testingDeferredPaymentToken', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[4] = Meta.getReplacedId(this.data[4], _idReplacements);
		this.courseSelectorWrapper = this.data[4] === null ? null! : ShiftSelector.create(this.data[4]);
		this.data[5] = Meta.getReplacedId(this.data[5], _idReplacements);
		this.shiftModelIdWrapper = this.data[5] === null ? null! : Id.create(this.data[5]);
		this.participantsWrapper._fixIds(_idReplacements);
		this.data[16] = Meta.getReplacedId(this.data[16], _idReplacements);
		this.overallTariffIdWrapper = this.data[16] === null ? null! : Id.create(this.data[16]);
		this.data[18] = Meta.getReplacedId(this.data[18], _idReplacements);
		this.firstShiftSelectorWrapper = this.data[18] === null ? null! : ShiftId.create(this.data[18]);
		this.data[29] = Meta.getReplacedId(this.data[29], _idReplacements);
		this.paymentMethodIdWrapper = this.data[29] === null ? null! : Id.create(this.data[29]);
		this.data[37] = Meta.getReplacedId(this.data[37], _idReplacements);
		this.cancellationPolicyIdWrapper = this.data[37] === null ? null! : Id.create(this.data[37]);
		this.data[39] = Meta.getReplacedId(this.data[39], _idReplacements);
		this.applicableCancellationFeePeriodIdWrapper = this.data[39] === null ? null! : Id.create(this.data[39]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('BOOKING should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[4] : null, this.courseSelectorWrapper))
			this.courseSelectorWrapper = data && data[4] ? ShiftSelector.create(data[4]) : null!;
		if(!Meta.isSameId(data ? data[5] : null, this.shiftModelIdWrapper))
			this.shiftModelIdWrapper = data && data[5] ? Id.create(data[5]) : null!;
		this.participantsWrapper._updateRawData(data && data[15] !== undefined ? data[15] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[16] : null, this.overallTariffIdWrapper))
			this.overallTariffIdWrapper = data && data[16] ? Id.create(data[16]) : null!;
		if(!Meta.isSameId(data ? data[18] : null, this.firstShiftSelectorWrapper))
			this.firstShiftSelectorWrapper = data && data[18] ? ShiftId.create(data[18]) : null!;
		if(!Meta.isSameId(data ? data[29] : null, this.paymentMethodIdWrapper))
			this.paymentMethodIdWrapper = data && data[29] ? Id.create(data[29]) : null!;
		if(!Meta.isSameId(data ? data[37] : null, this.cancellationPolicyIdWrapper))
			this.cancellationPolicyIdWrapper = data && data[37] ? Id.create(data[37]) : null!;
		if(!Meta.isSameId(data ? data[39] : null, this.applicableCancellationFeePeriodIdWrapper))
			this.applicableCancellationFeePeriodIdWrapper = data && data[39] ? Id.create(data[39]) : null!;
	}

	protected get dni() : string {
		return '424';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '424', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiBookingState {
	INQUIRY = 1,
	INQUIRY_DECLINED = 2,
	BOOKED = 3,
	CANCELED = 4,
}
		 export class SchedulingApiBookingParticipants extends ApiListWrapper<SchedulingApiBookingParticipant>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiBooking | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'participants');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiBookingParticipant {
		return new SchedulingApiBookingParticipant(this.api, this as unknown as SchedulingApiBookingParticipants, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiBooking | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiBookingParticipants(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '439';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiBookingParticipant) => void) | null = null, _backendId : Id | null = null) : SchedulingApiBookingParticipant {
		const newItem = new SchedulingApiBookingParticipant(this.api, this as unknown as SchedulingApiBookingParticipants, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('participants');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiBookingParticipants, SchedulingApiBookingParticipants> = new ApiAttributeInfo<SchedulingApiBookingParticipants, SchedulingApiBookingParticipants>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipants,
			name: 'participants',
			nodeName: 'BOOKING_PARTICIPANTS',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiBookingParticipants) {
				return ((!this.parent!.model.onlyWholeCourseBookable));
			},
			validations: function(this : SchedulingApiBookingParticipants) {
				return [
					() => {
		if(((this.parent!.model.onlyWholeCourseBookable === false)))
		{
			return this.api.validators.required(PApiType.ApiList, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.ApiList, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBookingParticipants) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiBookingParticipants, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 15,
		});
}

				 
export class SchedulingApiBookingParticipantBase extends ApiObjectWrapper<SchedulingApiBookingParticipant>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiBookingParticipants | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiBookingParticipant> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiBookingParticipant as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiBookingParticipant, SchedulingApiBookingParticipant> = new ApiAttributeInfo<SchedulingApiBookingParticipant, SchedulingApiBookingParticipant>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'bookingParticipant',
			nodeName: 'BOOKING_PARTICIPANT',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiBookingParticipant, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiAttended : ApiAttributeInfo<SchedulingApiBookingParticipant, boolean> = new ApiAttributeInfo<SchedulingApiBookingParticipant, boolean>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'attended',
			nodeName: 'BOOKING_PARTICIPANT_ATTENDED',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiBookingParticipant) {
				{const conditionValue = this.parent?.parent?.canWriteAttended; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			skipParentSetConditions: true,
			validations: function(this : SchedulingApiBookingParticipant) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBookingParticipant) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiBookingParticipant, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiIsBookingPerson : ApiAttributeInfo<SchedulingApiBookingParticipant, boolean> = new ApiAttributeInfo<SchedulingApiBookingParticipant, boolean>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'isBookingPerson',
			nodeName: 'BOOKING_PARTICIPANT_IS_BOOKING_PERSON',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiBookingParticipant) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBookingParticipant) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiBookingParticipant, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	aiFirstName : ApiAttributeInfo<SchedulingApiBookingParticipant, string> = new ApiAttributeInfo<SchedulingApiBookingParticipant, string>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'firstName',
			nodeName: 'BOOKING_PARTICIPANT_FIRST_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiBookingParticipant) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBookingParticipant) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiLastName : ApiAttributeInfo<SchedulingApiBookingParticipant, string> = new ApiAttributeInfo<SchedulingApiBookingParticipant, string>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'lastName',
			nodeName: 'BOOKING_PARTICIPANT_LAST_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiBookingParticipant) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBookingParticipant) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiEmail : ApiAttributeInfo<SchedulingApiBookingParticipant, Email> = new ApiAttributeInfo<SchedulingApiBookingParticipant, Email>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'email',
			nodeName: 'BOOKING_PARTICIPANT_EMAIL',
			type: PApiType.Email,
			validations: function(this : SchedulingApiBookingParticipant) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBookingParticipant) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiDateOfBirth : ApiAttributeInfo<SchedulingApiBookingParticipant, Date> = new ApiAttributeInfo<SchedulingApiBookingParticipant, Date>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'dateOfBirth',
			nodeName: 'BOOKING_PARTICIPANT_DATE_OF_BIRTH',
			type: PApiType.Date,
			validations: function(this : SchedulingApiBookingParticipant) {
				return [
					() => {
return this.api.validators.min(-1577926800000, true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(+this.api.pMoment.m().add(0, 'minutes'), true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiBookingParticipant) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiTariffId : ApiAttributeInfo<SchedulingApiBookingParticipant, Id> = new ApiAttributeInfo<SchedulingApiBookingParticipant, Id>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'tariffId',
			nodeName: 'BOOKING_PARTICIPANT_TARIFF_ID',
			type: PApiType.Id,
			rawDataIndex: 7,
		});
	aiAdditionalFieldValue : ApiAttributeInfo<SchedulingApiBookingParticipant, string> = new ApiAttributeInfo<SchedulingApiBookingParticipant, string>({
			apiObjWrapper: this as any as SchedulingApiBookingParticipant,
			name: 'additionalFieldValue',
			nodeName: 'BOOKING_PARTICIPANT_ADDITIONAL_FIELD_VALUE',
			type: PApiType.string,
			isDetailedAttribute: true,
			rawDataIndex: 8,
		});

	/**
	 * Has the participant attended?
	 *
	 * @type {boolean}
	 */
	get attended() : boolean {
		this.getterDebugValidations(this.aiAttended, false);
		return this.data[1];
	}

	set attended(v : boolean) {
        this.setterImpl(1, v, 'attended', false, null, null);
	}

	/**
	 * Is this the booking person? If so, the attributes like firstName, lastName, ... from this item will be ignored and data from booking item will be taken.
	 *
	 * @type {boolean}
	 */
	get isBookingPerson() : boolean {
		this.getterDebugValidations(this.aiIsBookingPerson, false);
		return this.data[2];
	}

	set isBookingPerson(v : boolean) {
        this.setterImpl(2, v, 'isBookingPerson', false, null, function(this : SchedulingApiBookingParticipant) {if(v) {
						this.firstName = this.parent!.parent!.firstName;
						this.lastName = this.parent!.parent!.lastName;
						this.email = this.parent!.parent!.email;
						this.dateOfBirth = this.parent!.parent!.dateOfBirth;
					}});
	}

	/**
	 * First name of the participant
	 *
	 * @type {string}
	 */
	get firstName() : string {
		this.getterDebugValidations(this.aiFirstName, false);
		return this.data[3];
	}

	set firstName(v : string) {
        this.setterImpl(3, v, 'firstName', false, null, null);
	}

	/**
	 * Last name of the participant
	 *
	 * @type {string}
	 */
	get lastName() : string {
		this.getterDebugValidations(this.aiLastName, false);
		return this.data[4];
	}

	set lastName(v : string) {
        this.setterImpl(4, v, 'lastName', false, null, null);
	}

	/**
	 * Email of the participant
	 *
	 * @type {Email}
	 */
	get email() : Email {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[5];
	}

	set email(v : Email) {
        this.setterImpl(5, v, 'email', false, null, null);
	}

	/**
	 * Date of birth of the participant
	 *
	 * @type {Date}
	 */
	get dateOfBirth() : Date {
		this.getterDebugValidations(this.aiDateOfBirth, false);
		return this.data[6];
	}

	set dateOfBirth(v : Date) {
        this.setterImpl(6, v, 'dateOfBirth', false, null, null);
	}

	private tariffIdWrapper : Id | null = null!;

	/**
	 * Tariff id of the participant. When "null" is returned, the participant does not have to pay anything.
	 *
	 * @type {Id}
	 */
	get tariffId() : Id | null {
		this.getterDebugValidations(this.aiTariffId, false);
		return this.tariffIdWrapper;
	}

	set tariffId(v : Id | null) {
        this.setterImpl(7, v, 'tariffId', false, null, function(this : SchedulingApiBookingParticipant) {this.tariffIdWrapper = v;});
	}

	/**
	 * (Detail) Additional field value for this participant. Should only be shown when participant has a tariff with "tariff.additionalFieldLabel" defined.
	 *
	 * @type {string}
	 */
	get additionalFieldValue() : string | null {
		this.getterDebugValidations(this.aiAdditionalFieldValue, false);
		return this.data[8];
	}

	set additionalFieldValue(v : string | null) {
        this.setterImpl(8, v, 'additionalFieldValue', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[7] = Meta.getReplacedId(this.data[7], _idReplacements);
		this.tariffIdWrapper = this.data[7] === null ? null! : Id.create(this.data[7]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('BOOKING_PARTICIPANT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[7] : null, this.tariffIdWrapper))
			this.tariffIdWrapper = data && data[7] ? Id.create(data[7]) : null!;
	}

	protected get dni() : string {
		return '470';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '470', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiBookableCreatedBy {
	MANUAL_BOOKING = 1,
	BOOKING_PLUGIN = 2,
	BETA_7 = 3,
	ROUTES_MANAGER = 4,
	TENDU = 5,
	MANUAL_REFUND_VIA_GIFT_CARD = 6,
	ONLINE_CANCELLATION_REFUND_VIA_GIFT_CARD = 7,
	MARKETING_GIFT_CARD = 8,
	BOULDERADO = 9,
	BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION = 10,
	KLETTERSZENE = 11,
}
		 export class SchedulingApiActivityAreasBase extends ApiListWrapper<SchedulingApiActivityArea>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'activityAreas');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiActivityArea {
		return new SchedulingApiActivityArea(this.api, this as unknown as SchedulingApiActivityAreas, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiActivityAreas(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '10';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiActivityArea) => void) | null = null, _backendId : Id | null = null) : SchedulingApiActivityArea {
		const newItem = new SchedulingApiActivityArea(this.api, this as unknown as SchedulingApiActivityAreas, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('activityAreas');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiActivityAreas, SchedulingApiActivityAreas> = new ApiAttributeInfo<SchedulingApiActivityAreas, SchedulingApiActivityAreas>({
			apiObjWrapper: this as any as SchedulingApiActivityAreas,
			name: 'activityAreas',
			nodeName: 'ACTIVITY_AREAS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiActivityAreas) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? {sourceString: 'Hierfür fehlt dir die Berechtigung.', params: false} : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiActivityAreas, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 9,
		});
}

				 
export class SchedulingApiActivityAreaBase extends ApiObjectWrapper<SchedulingApiActivityArea>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiActivityAreas | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiActivityArea> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiActivityArea as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiActivityArea, SchedulingApiActivityArea> = new ApiAttributeInfo<SchedulingApiActivityArea, SchedulingApiActivityArea>({
			apiObjWrapper: this as any as SchedulingApiActivityArea,
			name: 'activityArea',
			nodeName: 'ACTIVITY_AREA',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiActivityArea, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiActivityArea, string> = new ApiAttributeInfo<SchedulingApiActivityArea, string>({
			apiObjWrapper: this as any as SchedulingApiActivityArea,
			name: 'name',
			nodeName: 'ACTIVITY_AREA_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiActivityArea) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(70, PApiType.string, undefined);
					},
					() => { return this.checkNoDuplicateNames() },
				];
			},
			asyncValidations: function(this : SchedulingApiActivityArea) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiEmoji : ApiAttributeInfo<SchedulingApiActivityArea, Emoji> = new ApiAttributeInfo<SchedulingApiActivityArea, Emoji>({
			apiObjWrapper: this as any as SchedulingApiActivityArea,
			name: 'emoji',
			nodeName: 'ACTIVITY_AREA_EMOJI',
			type: PApiType.Emoji,
			rawDataIndex: 2,
		});
	aiTrashed : ApiAttributeInfo<SchedulingApiActivityArea, boolean> = new ApiAttributeInfo<SchedulingApiActivityArea, boolean>({
			apiObjWrapper: this as any as SchedulingApiActivityArea,
			name: 'trashed',
			nodeName: 'ACTIVITY_AREA_TRASHED',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiActivityArea) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiActivityArea) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiActivityArea, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});

	/**
	 * Name of the activity area.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * Emoji of the activity area.
	 *
	 * @type {Emoji}
	 */
	get emoji() : Emoji | null {
		this.getterDebugValidations(this.aiEmoji, false);
		return this.data[2];
	}

	set emoji(v : Emoji | null) {
        this.setterImpl(2, v, 'emoji', false, null, null);
	}

	/**
	 * Is the activity area trashed.
	 *
	 * @type {boolean}
	 */
	get trashed() : boolean {
		this.getterDebugValidations(this.aiTrashed, false);
		return this.data[3];
	}

	set trashed(v : boolean) {
        this.setterImpl(3, v, 'trashed', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ACTIVITY_AREA should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '247';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '247', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelsBase extends ApiListWrapper<SchedulingApiShiftModel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftModels');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModel {
		return new SchedulingApiShiftModel(this.api, this as unknown as SchedulingApiShiftModels, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModels(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '11';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModel) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModel {
		const newItem = new SchedulingApiShiftModel(this.api, this as unknown as SchedulingApiShiftModels, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftModels');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModels, SchedulingApiShiftModels> = new ApiAttributeInfo<SchedulingApiShiftModels, SchedulingApiShiftModels>({
			apiObjWrapper: this as any as SchedulingApiShiftModels,
			name: 'shiftModels',
			nodeName: 'SHIFT_MODELS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiShiftModels, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 10,
		});
}

				 
export class SchedulingApiShiftModelBase extends ApiObjectWrapper<SchedulingApiShiftModel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModels | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModel> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModel as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiShiftModel> = new ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiShiftModel>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'shiftModel',
			nodeName: 'SHIFT_MODEL',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
	return ((this.api.pPermissionsService.userCanWrite(this))||(this.api.currentlyDetailedLoaded instanceof SchedulingApiBooking &&
						this.api.pPermissionsService.userCanWriteBooking(this.api.currentlyDetailedLoaded)));
			},
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiColor : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'color',
			nodeName: 'SHIFT_MODEL_COLOR',
			type: PApiType.string,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiName : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'name',
			nodeName: 'SHIFT_MODEL_NAME',
			type: PApiType.string,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(70, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiActivityAreaId : ApiAttributeInfo<SchedulingApiShiftModel, Id> = new ApiAttributeInfo<SchedulingApiShiftModel, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'activityAreaId',
			nodeName: 'SHIFT_MODEL_ACTIVITY_AREA_ID',
			type: PApiType.Id,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.Id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiTrashed : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'trashed',
			nodeName: 'SHIFT_MODEL_TRASHED',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiCostCentre : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'costCentre',
			nodeName: 'SHIFT_MODEL_COST_CENTRE',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiShiftModel) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 8,
		});
	aiArticleGroup : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'articleGroup',
			nodeName: 'SHIFT_MODEL_ARTICLE_GROUP',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiShiftModel) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 9,
		});
	aiIsCourse : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'isCourse',
			nodeName: 'SHIFT_MODEL_IS_COURSE',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiCourseType : ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiCourseType> = new ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiCourseType>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseType',
			nodeName: 'SHIFT_MODEL_COURSE_TYPE',
			type: PApiType.Enum,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return SchedulingApiCourseType.ONLINE_BOOKABLE;},
			rawDataIndex: 12,
		});
	aiOnlyWholeCourseBookable : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlyWholeCourseBookable',
			nodeName: 'SHIFT_MODEL_ONLY_WHOLE_COURSE_BOOKABLE',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
		if(((this.isCourse)))
		{
			return this.api.validators.required(PApiType.boolean, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiOnlineCancellationForChargeableBookingsEnabled : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlineCancellationForChargeableBookingsEnabled',
			nodeName: 'SHIFT_MODEL_ONLINE_CANCELLATION_FOR_CHARGEABLE_BOOKINGS_ENABLED',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				{const conditionValue = this.currentCancellationPolicy?.feePeriods!.length !== 0; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 18,
		});
	aiCurrentCancellationPolicyId : ApiAttributeInfo<SchedulingApiShiftModel, Id> = new ApiAttributeInfo<SchedulingApiShiftModel, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'currentCancellationPolicyId',
			nodeName: 'SHIFT_MODEL_CURRENT_CANCELLATION_POLICY_ID',
			type: PApiType.Id,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isNewItem; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.Id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiIsCourseOnline : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'isCourseOnline',
			nodeName: 'SHIFT_MODEL_IS_COURSE_ONLINE',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 21,
		});
	aiBookingDesiredDateSetting : ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiBookingDesiredDateSetting> = new ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiBookingDesiredDateSetting>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'bookingDesiredDateSetting',
			nodeName: 'SHIFT_MODEL_BOOKING_DESIRED_DATE_SETTING',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModel) {
				return ((this.courseType === SchedulingApiCourseType.ONLINE_INQUIRY));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return SchedulingApiBookingDesiredDateSetting.DESIRED_DATE_NOT_ALLOWED;},
			rawDataIndex: 22,
		});
	aiCourseCodePrefix : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseCodePrefix',
			nodeName: 'SHIFT_MODEL_COURSE_CODE_PREFIX',
			type: PApiType.string,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => { return this.validatePrefixOccupied() },
					() => { return this.validatePrefixPattern() },
					() => {
						return this.api.validators.maxLength(6, PApiType.string, undefined);
					},
					() => {
		if(((this.isCourse)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiCourseGroup : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseGroup',
			nodeName: 'SHIFT_MODEL_COURSE_GROUP',
			type: PApiType.string,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 24,
		});
	aiFreeclimberArticleId : ApiAttributeInfo<SchedulingApiShiftModel, Integer> = new ApiAttributeInfo<SchedulingApiShiftModel, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'freeclimberArticleId',
			nodeName: 'SHIFT_MODEL_FREECLIMBER_ARTICLE_ID',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModel) {
				return ((this.isCourse)&&(this.api.data.posSystem === SchedulingApiPosSystem.FREECLIMBER));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiBookingPersonMinAge : ApiAttributeInfo<SchedulingApiShiftModel, Years> = new ApiAttributeInfo<SchedulingApiShiftModel, Years>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'bookingPersonMinAge',
			nodeName: 'SHIFT_MODEL_BOOKING_PERSON_MIN_AGE',
			type: PApiType.Years,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.min(1, true, PApiType.Years, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(100, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiParticipantMinAge : ApiAttributeInfo<SchedulingApiShiftModel, Years> = new ApiAttributeInfo<SchedulingApiShiftModel, Years>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'participantMinAge',
			nodeName: 'SHIFT_MODEL_PARTICIPANT_MIN_AGE',
			type: PApiType.Years,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.min(1, true, PApiType.Years, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(100, true, PApiType.Years, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.participantMaxAge, true, PApiType.Years, this.aiParticipantMaxAge.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	aiParticipantMaxAge : ApiAttributeInfo<SchedulingApiShiftModel, Years> = new ApiAttributeInfo<SchedulingApiShiftModel, Years>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'participantMaxAge',
			nodeName: 'SHIFT_MODEL_PARTICIPANT_MAX_AGE',
			type: PApiType.Years,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.min(1, true, PApiType.Years, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(100, true, PApiType.Years, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.min(() => this.participantMinAge, true, PApiType.Years, this.aiParticipantMinAge.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});
	aiCourseTitle : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseTitle',
			nodeName: 'SHIFT_MODEL_COURSE_TITLE',
			type: PApiType.string,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
		if(((this.isCourse)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'description',
			nodeName: 'SHIFT_MODEL_DESCRIPTION',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 31,
		});
	aiWorkingTimeCreationMethod : ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiWorkingTimeCreationMethod> = new ApiAttributeInfo<SchedulingApiShiftModel, SchedulingApiWorkingTimeCreationMethod>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'workingTimeCreationMethod',
			nodeName: 'SHIFT_MODEL_WORKING_TIME_CREATION_METHOD',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiShiftModel) {
				return ((this.api.pPermissionsService.userCanWrite(this)));
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 33,
		});
	aiCourseSubtitle : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseSubtitle',
			nodeName: 'SHIFT_MODEL_COURSE_SUBTITLE',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 34,
		});
	aiCourseDescription : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseDescription',
			nodeName: 'SHIFT_MODEL_COURSE_DESCRIPTION',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 35,
		});
	aiCourseEquipmentRequirements : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseEquipmentRequirements',
			nodeName: 'SHIFT_MODEL_COURSE_EQUIPMENT_REQUIREMENTS',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 37,
		});
	aiCourseSkillRequirements : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseSkillRequirements',
			nodeName: 'SHIFT_MODEL_COURSE_SKILL_REQUIREMENTS',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 38,
		});
	aiCourseLocation : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseLocation',
			nodeName: 'SHIFT_MODEL_COURSE_LOCATION',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 39,
		});
	aiCourseContactName : ApiAttributeInfo<SchedulingApiShiftModel, string> = new ApiAttributeInfo<SchedulingApiShiftModel, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseContactName',
			nodeName: 'SHIFT_MODEL_COURSE_CONTACT_NAME',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 40,
		});
	aiCourseContactEmail : ApiAttributeInfo<SchedulingApiShiftModel, Email> = new ApiAttributeInfo<SchedulingApiShiftModel, Email>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseContactEmail',
			nodeName: 'SHIFT_MODEL_COURSE_CONTACT_EMAIL',
			type: PApiType.Email,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 41,
		});
	aiCourseContactPhone : ApiAttributeInfo<SchedulingApiShiftModel, Tel> = new ApiAttributeInfo<SchedulingApiShiftModel, Tel>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseContactPhone',
			nodeName: 'SHIFT_MODEL_COURSE_CONTACT_PHONE',
			type: PApiType.Tel,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 42,
		});
	aiArrivalTimeBeforeCourse : ApiAttributeInfo<SchedulingApiShiftModel, Duration> = new ApiAttributeInfo<SchedulingApiShiftModel, Duration>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'arrivalTimeBeforeCourse',
			nodeName: 'SHIFT_MODEL_ARRIVAL_TIME_BEFORE_COURSE',
			type: PApiType.Duration,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 43,
		});
	aiCourseBookingDeadlineFrom : ApiAttributeInfo<SchedulingApiShiftModel, Duration> = new ApiAttributeInfo<SchedulingApiShiftModel, Duration>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseBookingDeadlineFrom',
			nodeName: 'SHIFT_MODEL_COURSE_BOOKING_DEADLINE_FROM',
			type: PApiType.Duration,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModel) {
				return ((this.courseType !== SchedulingApiCourseType.NO_BOOKING));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 44,
		});
	aiCourseBookingDeadlineUntil : ApiAttributeInfo<SchedulingApiShiftModel, Duration> = new ApiAttributeInfo<SchedulingApiShiftModel, Duration>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'courseBookingDeadlineUntil',
			nodeName: 'SHIFT_MODEL_COURSE_BOOKING_DEADLINE_UNTIL',
			type: PApiType.Duration,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModel) {
				return ((this.courseType !== SchedulingApiCourseType.NO_BOOKING));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 45,
		});
	aiMinCourseParticipantCount : ApiAttributeInfo<SchedulingApiShiftModel, Integer> = new ApiAttributeInfo<SchedulingApiShiftModel, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'minCourseParticipantCount',
			nodeName: 'SHIFT_MODEL_MIN_COURSE_PARTICIPANT_COUNT',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.maxCourseParticipantCount, true, PApiType.Integer, this.aiMaxCourseParticipantCount.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return 1;},
			rawDataIndex: 46,
		});
	aiMaxCourseParticipantCount : ApiAttributeInfo<SchedulingApiShiftModel, Integer> = new ApiAttributeInfo<SchedulingApiShiftModel, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'maxCourseParticipantCount',
			nodeName: 'SHIFT_MODEL_MAX_COURSE_PARTICIPANT_COUNT',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.min(() => this.minCourseParticipantCount, true, PApiType.Integer, this.aiMinCourseParticipantCount.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 47,
		});
	aiUseBookingDeadlinesForSlots : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'useBookingDeadlinesForSlots',
			nodeName: 'SHIFT_MODEL_USE_BOOKING_DEADLINES_FOR_SLOTS',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModel) {
				return ((this.courseType !== SchedulingApiCourseType.NO_BOOKING));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 48,
		});
	aiOnlineCancellationForFreeBookingsEnabled : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlineCancellationForFreeBookingsEnabled',
			nodeName: 'SHIFT_MODEL_ONLINE_CANCELLATION_FOR_FREE_BOOKINGS_ENABLED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 49,
		});
	aiOnlineCancellationForFreeBookingsDeadline : ApiAttributeInfo<SchedulingApiShiftModel, Duration> = new ApiAttributeInfo<SchedulingApiShiftModel, Duration>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlineCancellationForFreeBookingsDeadline',
			nodeName: 'SHIFT_MODEL_ONLINE_CANCELLATION_FOR_FREE_BOOKINGS_DEADLINE',
			type: PApiType.Duration,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModel) {
				return ((this.onlineCancellationForFreeBookingsEnabled));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.min(0, true, PApiType.Duration, undefined, undefined);						return null;
					},
					() => {
						return this.api.validators.notUndefined(PApiType.Duration, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			rawDataIndex: 50,
		});
	aiOnlineCancellationForChargeableBookingsDeadline : ApiAttributeInfo<SchedulingApiShiftModel, Duration> = new ApiAttributeInfo<SchedulingApiShiftModel, Duration>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlineCancellationForChargeableBookingsDeadline',
			nodeName: 'SHIFT_MODEL_ONLINE_CANCELLATION_FOR_CHARGEABLE_BOOKINGS_DEADLINE',
			type: PApiType.Duration,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModel) {
				return ((this.onlineCancellationForChargeableBookingsEnabled));
			},
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.min(0, true, PApiType.Duration, undefined, undefined);						return null;
					},
					() => {
						return this.api.validators.notUndefined(PApiType.Duration, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return undefined;},
			rawDataIndex: 51,
		});
	aiOnlineCancellationForWithdrawableBookingsAlwaysEnabled : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlineCancellationForWithdrawableBookingsAlwaysEnabled',
			nodeName: 'SHIFT_MODEL_ONLINE_CANCELLATION_FOR_WITHDRAWABLE_BOOKINGS_ALWAYS_ENABLED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				{const conditionValue = this.currentCancellationPolicy!.aiWithdrawalPeriod.isAvailable && this.currentCancellationPolicy!.withdrawalPeriod !== null; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um diese Funktionalität nutzen zu können, setze bitte erst eine Widerrufsfrist.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 52,
		});
	aiOnlineCancellationAutomaticOnlineRefundEnabled : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlineCancellationAutomaticOnlineRefundEnabled',
			nodeName: 'SHIFT_MODEL_ONLINE_CANCELLATION_AUTOMATIC_ONLINE_REFUND_ENABLED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				{const conditionValue = this.api.data.isOnlinePaymentAvailable; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um diese Funktionalität nutzen zu können, aktiviere bitte erst die <a href="client/plugin/payments" target="_blank">Online-Zahlung</a>.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 53,
		});
	aiOnlineCancellationAutomaticGiftCardRefundEnabled : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'onlineCancellationAutomaticGiftCardRefundEnabled',
			nodeName: 'SHIFT_MODEL_ONLINE_CANCELLATION_AUTOMATIC_GIFT_CARD_REFUND_ENABLED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.data.giftCardSettings.isSaleEnabled; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um diese Funktion nutzen zu können, musst du zuerst den <a href="client/plugin/gift-cards" target="_blank">Gutscheinverkauf aktivieren</a>.', params: false} : undefined;}
				{const conditionValue = this.onlineCancellationAutomaticOnlineRefundEnabled; if(!conditionValue) return conditionValue === false ? {sourceString: 'Die automatische Gutschein-Ausstellung steht derzeit nur für Buchungen zur Verfügung, die online bezahlt worden sind. Daher musst du erst die »automatische Rückzahlung per Online-Zahlung« aktivieren, bevor du diese Funktion nutzen kannst.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 54,
		});
	aiShowAssignedMembersInBookingPlugin : ApiAttributeInfo<SchedulingApiShiftModel, boolean> = new ApiAttributeInfo<SchedulingApiShiftModel, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModel,
			name: 'showAssignedMembersInBookingPlugin',
			nodeName: 'SHIFT_MODEL_SHOW_ASSIGNED_MEMBERS_IN_BOOKING_PLUGIN',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModel) {
				{const conditionValue = this.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModel) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModel) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 55,
		});

	/**
	 * Color of shift model in format "rrggbb".
	 *
	 * @type {string}
	 */
	get color() : string {
		this.getterDebugValidations(this.aiColor, false);
		return this.data[1];
	}

	set color(v : string) {
        this.setterImpl(1, v, 'color', false, null, null);
	}

	/**
	 * Name of shift model.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[2];
	}

	set name(v : string) {
        this.setterImpl(2, v, 'name', false, null, null);
	}

	private activityAreaIdWrapper : Id = null!;

	/**
	 * Id of the activity-area this shift-model is assigned to. This will be available for all not-trashed shift-models.
	 *
	 * @type {Id}
	 */
	get activityAreaId() : Id {
		this.getterDebugValidations(this.aiActivityAreaId, false);
		return this.activityAreaIdWrapper;
	}

	set activityAreaId(v : Id) {
        this.setterImpl(3, v, 'activityAreaId', false, null, function(this : SchedulingApiShiftModel) {this.activityAreaIdWrapper = v;});
	}

	/**
	 * Is this shift model trashed?
	 *
	 * @type {boolean}
	 */
	get trashed() : boolean {
		this.getterDebugValidations(this.aiTrashed, false);
		return this.data[4];
	}

	set trashed(v : boolean) {
        this.setterImpl(4, v, 'trashed', false, null, null);
	}

	private assignableMembersWrapper : SchedulingApiShiftModelAssignableMembers = new SchedulingApiShiftModelAssignableMembers(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiAssignableMembers = this.assignableMembersWrapper.aiThis;

	/**
	 * Only send to client owners. Which members are assignable to this shift-model. Make sure this is deserialized before assignedMemberIds because this is a prerequisite.  Can be used with "changeSelector".
	 */
	get assignableMembers() : SchedulingApiShiftModelAssignableMembers {
		this.getterDebugValidations(this.aiAssignableMembers, false);
		return this.assignableMembersWrapper;
	}

	set assignableMembersTestSetter(v : SchedulingApiShiftModelAssignableMembers) {
        this.setterImpl(5, v.rawData, 'assignableMembers', true, null, function(this : SchedulingApiShiftModel) {this.assignableMembersWrapper = v;});
	}

	private repetitionWrapper : SchedulingApiShiftModelRepetition = new SchedulingApiShiftModelRepetition(this.api,
		this as unknown as SchedulingApiShiftModel);
	public aiRepetition = this.repetitionWrapper.aiThis;

	/**
	 * Repetition pattern of this shift-model.
	 */
	get repetition() : SchedulingApiShiftModelRepetition {
		this.getterDebugValidations(this.aiRepetition, false);
		return this.repetitionWrapper;
	}

	set repetitionTestSetter(v : SchedulingApiShiftModelRepetition) {
        this.setterImpl(6, v.rawData, 'repetition', true, null, function(this : SchedulingApiShiftModel) {this.repetitionWrapper = v;});
	}

	private timeWrapper : SchedulingApiShiftModelTime = new SchedulingApiShiftModelTime(this.api,
		this as unknown as SchedulingApiShiftModel);
	public aiTime = this.timeWrapper.aiThis;

	/**
	 * The time when this shift-model will start (relative to current day). Can be used with "changeSelector".
	 */
	get time() : SchedulingApiShiftModelTime {
		this.getterDebugValidations(this.aiTime, false);
		return this.timeWrapper;
	}

	set timeTestSetter(v : SchedulingApiShiftModelTime) {
        this.setterImpl(7, v.rawData, 'time', true, null, function(this : SchedulingApiShiftModel) {this.timeWrapper = v;});
	}

	/**
	 * "Kostenstelle" used for controlling. Note that this is not a detailed field to enable type-ahead functionality.
	 *
	 * @type {string}
	 */
	get costCentre() : string | null {
		this.getterDebugValidations(this.aiCostCentre, false);
		return this.data[8];
	}

	set costCentre(v : string | null) {
        this.setterImpl(8, v, 'costCentre', false, null, null);
	}

	/**
	 * "Warengruppe" used for controlling. Note that this is not a detailed field to enable type-ahead functionality.
	 *
	 * @type {string}
	 */
	get articleGroup() : string | null {
		this.getterDebugValidations(this.aiArticleGroup, false);
		return this.data[9];
	}

	set articleGroup(v : string | null) {
        this.setterImpl(9, v, 'articleGroup', false, null, null);
	}

	private posAccountsWrapper : SchedulingApiShiftModelPosAccounts = new SchedulingApiShiftModelPosAccounts(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiPosAccounts = this.posAccountsWrapper.aiThis;

	/**
	 * Pos accounts for this shift-model. Note that this is not a detailed field to enable type-ahead functionality.
	 */
	get posAccounts() : SchedulingApiShiftModelPosAccounts {
		this.getterDebugValidations(this.aiPosAccounts, false);
		return this.posAccountsWrapper;
	}

	set posAccountsTestSetter(v : SchedulingApiShiftModelPosAccounts) {
        this.setterImpl(10, v.rawData, 'posAccounts', true, null, function(this : SchedulingApiShiftModel) {this.posAccountsWrapper = v;});
	}

	/**
	 * Is this a course?
	 *
	 * @type {boolean}
	 */
	get isCourse() : boolean {
		this.getterDebugValidations(this.aiIsCourse, false);
		return this.data[11];
	}

	set isCourse(v : boolean) {
        this.setterImpl(11, v, 'isCourse', false, function(this : SchedulingApiShiftModel) {// When a user is about to create a copy of an existing shift-model to create a new one, and isCourse of
					// the new shift-model gets set to false, the prefix will not be changeable by the user anymore.
					// Validators check if prefixes are occupied, no matter if shift-models
					// are courses or not.
					// We don’t want to unnecessarily occupy prefixes.
					if (this.isNewItem && !v) {
						this.courseCodePrefix = null;
					}}, function(this : SchedulingApiShiftModel) {// Set a default for the prefix
					if (this.courseCodePrefix === null && v) {
						this.courseCodePrefix = (this as unknown as SchedulingApiShiftModel).parent!.getDefaultPrefix(this);
					}
					if (this.isCourse && this.courseTitle === null) {
						this.courseTitle = this.name;
					}});
	}

	/**
	 * The course-type or future bookings of this shift-model. When handling an existing booking, you should use "booking.courseType", so changing this value will not affect existing bookings.
	 *
	 * @type {SchedulingApiCourseType}
	 */
	get courseType() : SchedulingApiCourseType | null {
		this.getterDebugValidations(this.aiCourseType, false);
		return this.data[12];
	}

	set courseType(v : SchedulingApiCourseType | null) {
        this.setterImpl(12, v, 'courseType', false, null, null);
	}

	private changeSelectorWrapper : SchedulingApiShiftModelChangeSelector = new SchedulingApiShiftModelChangeSelector(this.api,
		this as unknown as SchedulingApiShiftModel);
	public aiChangeSelector = this.changeSelectorWrapper.aiThis;

	/**
	 * Set by user to define what should - additionally to this member - be modified.
	 */
	get changeSelector() : SchedulingApiShiftModelChangeSelector {
		this.getterDebugValidations(this.aiChangeSelector, false);
		return this.changeSelectorWrapper;
	}

	set changeSelectorTestSetter(v : SchedulingApiShiftModelChangeSelector) {
        this.setterImpl(13, v.rawData, 'changeSelector', true, null, function(this : SchedulingApiShiftModel) {this.changeSelectorWrapper = v;});
	}

	private automaticBookableMailIdsWrapper : SchedulingApiShiftModelAutomaticBookableMailIds = new SchedulingApiShiftModelAutomaticBookableMailIds(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiAutomaticBookableMailIds = this.automaticBookableMailIdsWrapper.aiThis;

	/**
	 * A list of custom-bookable-mail ids which are attached to this shift-model for automatic mail sending.
	 */
	get automaticBookableMailIds() : SchedulingApiShiftModelAutomaticBookableMailIds {
		this.getterDebugValidations(this.aiAutomaticBookableMailIds, false);
		return this.automaticBookableMailIdsWrapper;
	}

	set automaticBookableMailIdsTestSetter(v : SchedulingApiShiftModelAutomaticBookableMailIds) {
        this.setterImpl(14, v.rawData, 'automaticBookableMailIds', true, null, function(this : SchedulingApiShiftModel) {this.automaticBookableMailIdsWrapper = v;});
	}

	/**
	 * A booking will book the whole course? Originally, this value is "null". The first time this shift-model is made bookable this value has to be set and it cannot be changed afterwards. This is necessary, because mixing up whole-course and single-participant bookings will create chaos. (Note for Milad: This has currently be deserialized before tariffs/payment-methods. Thus, it is positioned currently here).
	 *
	 * @type {boolean}
	 */
	get onlyWholeCourseBookable() : boolean | null {
		this.getterDebugValidations(this.aiOnlyWholeCourseBookable, false);
		return this.data[15];
	}

	set onlyWholeCourseBookable(v : boolean | null) {
        this.setterImpl(15, v, 'onlyWholeCourseBookable', false, null, function(this : SchedulingApiShiftModel) {this.marketingGiftCardSettings.rawData[this.api.consts.SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_RECIPIENT] =
						v ? SchedulingApiMarketingGiftCardSettingsRecipient.BOOKING_PERSON : SchedulingApiMarketingGiftCardSettingsRecipient.BOOKING_PARTICIPANTS;});
	}

	private courseTariffsWrapper : SchedulingApiShiftModelCourseTariffs = new SchedulingApiShiftModelCourseTariffs(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiCourseTariffs = this.courseTariffsWrapper.aiThis;

	/**
	 * A list of course tariffs.
	 */
	get courseTariffs() : SchedulingApiShiftModelCourseTariffs {
		this.getterDebugValidations(this.aiCourseTariffs, false);
		return this.courseTariffsWrapper;
	}

	set courseTariffsTestSetter(v : SchedulingApiShiftModelCourseTariffs) {
        this.setterImpl(16, v.rawData, 'courseTariffs', true, null, function(this : SchedulingApiShiftModel) {this.courseTariffsWrapper = v;});
	}

	private coursePaymentMethodsWrapper : SchedulingApiShiftModelCoursePaymentMethods = new SchedulingApiShiftModelCoursePaymentMethods(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiCoursePaymentMethods = this.coursePaymentMethodsWrapper.aiThis;

	/**
	 * A list of course payment methods.
	 */
	get coursePaymentMethods() : SchedulingApiShiftModelCoursePaymentMethods {
		this.getterDebugValidations(this.aiCoursePaymentMethods, false);
		return this.coursePaymentMethodsWrapper;
	}

	set coursePaymentMethodsTestSetter(v : SchedulingApiShiftModelCoursePaymentMethods) {
        this.setterImpl(17, v.rawData, 'coursePaymentMethods', true, null, function(this : SchedulingApiShiftModel) {this.coursePaymentMethodsWrapper = v;});
	}

	/**
	 * Is online cancellation by booking person for chargeable bookings allowed? (Backend-note by Milad: This has to be deserialized before SHIFT_MODEL_ONLINE_CANCELLATION_FOR_CHARGEABLE_BOOKINGS_ENABLED because the "required" validation of this depends on that value)
	 *
	 * @type {boolean}
	 */
	get onlineCancellationForChargeableBookingsEnabled() : boolean {
		this.getterDebugValidations(this.aiOnlineCancellationForChargeableBookingsEnabled, false);
		return this.data[18];
	}

	set onlineCancellationForChargeableBookingsEnabled(v : boolean) {
        this.setterImpl(18, v, 'onlineCancellationForChargeableBookingsEnabled', false, null, null);
	}

	private currentCancellationPolicyIdWrapper : Id = null!;

	/**
	 * The id of the current cancellationPolicy which will be used for new bookings.
	 *
	 * @type {Id}
	 */
	get currentCancellationPolicyId() : Id {
		this.getterDebugValidations(this.aiCurrentCancellationPolicyId, false);
		return this.currentCancellationPolicyIdWrapper;
	}

	set currentCancellationPolicyId(v : Id) {
        this.setterImpl(19, v, 'currentCancellationPolicyId', false, null, function(this : SchedulingApiShiftModel) {this.currentCancellationPolicyIdWrapper = v;});
	}

	private cancellationPoliciesWrapper : SchedulingApiShiftModelCancellationPolicies = new SchedulingApiShiftModelCancellationPolicies(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiCancellationPolicies = this.cancellationPoliciesWrapper.aiThis;

	/**
	 * The list of all cancellation policies of this shift-model. You can only edit shift-models current cancellation policy. See "shiftModel.currentCancellationPolicyId". (Note by Milad: This has to be deserialized after SHIFT_MODEL_CANCELLATION_POLICIES because the "required" validation of that depends on this value)
	 */
	get cancellationPolicies() : SchedulingApiShiftModelCancellationPolicies {
		this.getterDebugValidations(this.aiCancellationPolicies, false);
		return this.cancellationPoliciesWrapper;
	}

	set cancellationPoliciesTestSetter(v : SchedulingApiShiftModelCancellationPolicies) {
        this.setterImpl(20, v.rawData, 'cancellationPolicies', true, null, function(this : SchedulingApiShiftModel) {this.cancellationPoliciesWrapper = v;});
	}

	/**
	 * Is the course online?
	 *
	 * @type {boolean}
	 */
	get isCourseOnline() : boolean {
		this.getterDebugValidations(this.aiIsCourseOnline, false);
		return this.data[21];
	}

	set isCourseOnline(v : boolean) {
        this.setterImpl(21, v, 'isCourseOnline', false, null, null);
	}

	/**
	 * Can the booking person give a desired date? Only set this when courseType is INQUIRY. Otherwise by default it is DESIRED_DATE_NOT_ALLOWED. This defines the setting for future bookings. When handling an existing booking, you should use "booking.bookingDesiredDateSetting", so changing this value will not affect existing bookings.
	 *
	 * @type {SchedulingApiBookingDesiredDateSetting}
	 */
	get bookingDesiredDateSetting() : SchedulingApiBookingDesiredDateSetting {
		this.getterDebugValidations(this.aiBookingDesiredDateSetting, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[22] === undefined) {
			this.data[22] = this.aiBookingDesiredDateSetting.defaultValue;
			
		}
		return this.data[22];
	}

	set bookingDesiredDateSetting(v : SchedulingApiBookingDesiredDateSetting) {
        this.setterImpl(22, v, 'bookingDesiredDateSetting', false, null, null);
	}

	/**
	 * Prefix being used to generate course codes. If no value is set then no course codes will be generated.
	 *
	 * @type {string}
	 */
	get courseCodePrefix() : string | null {
		this.getterDebugValidations(this.aiCourseCodePrefix, false);
		return this.data[23];
	}

	set courseCodePrefix(v : string | null) {
        this.setterImpl(23, v, 'courseCodePrefix', false, null, null);
	}

	/**
	 * course group which is used to group the courses in the booking plug-in.
	 *
	 * @type {string}
	 */
	get courseGroup() : string | null {
		this.getterDebugValidations(this.aiCourseGroup, false);
		return this.data[24];
	}

	set courseGroup(v : string | null) {
        this.setterImpl(24, v, 'courseGroup', false, null, null);
	}

	/**
	 * Article id of Freeclimber POS system enabling payments through Freeclimber. Optional and only relevant when a course is bookable.
	 *
	 * @type {Integer}
	 */
	get freeclimberArticleId() : Integer | null {
		this.getterDebugValidations(this.aiFreeclimberArticleId, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[25] === undefined) {
			this.data[25] = this.aiFreeclimberArticleId.defaultValue;
			
		}
		return this.data[25];
	}

	set freeclimberArticleId(v : Integer | null) {
        this.setterImpl(25, v, 'freeclimberArticleId', false, null, null);
	}

	/**
	 * The minimal age allowed for the booking person. The booking person's age is calculate at the date of booking.
	 *
	 * @type {Years}
	 */
	get bookingPersonMinAge() : Years | null {
		this.getterDebugValidations(this.aiBookingPersonMinAge, false);
		return this.data[26];
	}

	set bookingPersonMinAge(v : Years | null) {
        this.setterImpl(26, v, 'bookingPersonMinAge', false, null, null);
	}

	/**
	 * The minimal age allowed for the participant. Participant ages are defined by "participant.dateOfBirth" or "booking.ageMin" + "booking.ageMax". In case of "participant.dateOfBirth" the age is calculated at the date of the first shift of the course.
	 *
	 * @type {Years}
	 */
	get participantMinAge() : Years | null {
		this.getterDebugValidations(this.aiParticipantMinAge, false);
		return this.data[27];
	}

	set participantMinAge(v : Years | null) {
        this.setterImpl(27, v, 'participantMinAge', false, null, null);
	}

	/**
	 * See "participantMinAge".
	 *
	 * @type {Years}
	 */
	get participantMaxAge() : Years | null {
		this.getterDebugValidations(this.aiParticipantMaxAge, false);
		return this.data[28];
	}

	set participantMaxAge(v : Years | null) {
        this.setterImpl(28, v, 'participantMaxAge', false, null, null);
	}

	/**
	 * course title
	 *
	 * @type {string}
	 */
	get courseTitle() : string | null {
		this.getterDebugValidations(this.aiCourseTitle, false);
		return this.data[29];
	}

	set courseTitle(v : string | null) {
        this.setterImpl(29, v, 'courseTitle', false, null, null);
	}

	private neededMembersCountConfWrapper : SchedulingApiShiftModelNeededMembersCountConf = new SchedulingApiShiftModelNeededMembersCountConf(this.api,
		this as unknown as SchedulingApiShiftModel);
	public aiNeededMembersCountConf = this.neededMembersCountConfWrapper.aiThis;

	/**
	 * (Detail) Configuration values from which a shift's "neededMembersCount" is calculated. Can be used with "changeSelector".
	 */
	get neededMembersCountConf() : SchedulingApiShiftModelNeededMembersCountConf {
		this.getterDebugValidations(this.aiNeededMembersCountConf, false);
		return this.neededMembersCountConfWrapper;
	}

	set neededMembersCountConfTestSetter(v : SchedulingApiShiftModelNeededMembersCountConf) {
        this.setterImpl(30, v.rawData, 'neededMembersCountConf', true, null, function(this : SchedulingApiShiftModel) {this.neededMembersCountConfWrapper = v;});
	}

	/**
	 * (Detail) Description of the shift-model. Can be used with "changeSelector".
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[31];
	}

	set description(v : string | null) {
        this.setterImpl(31, v, 'description', false, null, null);
	}

	private assignedMemberIdsWrapper : SchedulingApiShiftModelAssignedMemberIds = new SchedulingApiShiftModelAssignedMemberIds(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiAssignedMemberIds = this.assignedMemberIdsWrapper.aiThis;

	/**
	 * (Detail) Only send to client owners. A list of the assigned members' ids. Can be used with "changeSelector".
	 */
	get assignedMemberIds() : SchedulingApiShiftModelAssignedMemberIds {
		this.getterDebugValidations(this.aiAssignedMemberIds, false);
		return this.assignedMemberIdsWrapper;
	}

	set assignedMemberIdsTestSetter(v : SchedulingApiShiftModelAssignedMemberIds) {
        this.setterImpl(32, v.rawData, 'assignedMemberIds', true, null, function(this : SchedulingApiShiftModel) {this.assignedMemberIdsWrapper = v;});
	}

	/**
	 * (Detail) How is the corresponding working-time to this shift-model going to be created? Only send to client owners. Can be used with "changeSelector".
	 *
	 * @type {SchedulingApiWorkingTimeCreationMethod}
	 */
	get workingTimeCreationMethod() : SchedulingApiWorkingTimeCreationMethod {
		this.getterDebugValidations(this.aiWorkingTimeCreationMethod, false);
		return this.data[33];
	}

	set workingTimeCreationMethod(v : SchedulingApiWorkingTimeCreationMethod) {
        this.setterImpl(33, v, 'workingTimeCreationMethod', false, null, null);
	}

	/**
	 * (Detail) course subtitle
	 *
	 * @type {string}
	 */
	get courseSubtitle() : string | null {
		this.getterDebugValidations(this.aiCourseSubtitle, false);
		return this.data[34];
	}

	set courseSubtitle(v : string | null) {
        this.setterImpl(34, v, 'courseSubtitle', false, null, null);
	}

	/**
	 * (Detail) course description
	 *
	 * @type {string}
	 */
	get courseDescription() : string | null {
		this.getterDebugValidations(this.aiCourseDescription, false);
		return this.data[35];
	}

	set courseDescription(v : string | null) {
        this.setterImpl(35, v, 'courseDescription', false, null, null);
	}

	private courseHighlightsWrapper : SchedulingApiShiftModelCourseHighlights = new SchedulingApiShiftModelCourseHighlights(this.api,
		this as unknown as SchedulingApiShiftModel, false, false);
	public aiCourseHighlights = this.courseHighlightsWrapper.aiThis;

	/**
	 * (Detail) A list of course highlights
	 */
	get courseHighlights() : SchedulingApiShiftModelCourseHighlights {
		this.getterDebugValidations(this.aiCourseHighlights, false);
		return this.courseHighlightsWrapper;
	}

	set courseHighlightsTestSetter(v : SchedulingApiShiftModelCourseHighlights) {
        this.setterImpl(36, v.rawData, 'courseHighlights', true, null, function(this : SchedulingApiShiftModel) {this.courseHighlightsWrapper = v;});
	}

	/**
	 * (Detail) What a participant should bring to the course.
	 *
	 * @type {string}
	 */
	get courseEquipmentRequirements() : string | null {
		this.getterDebugValidations(this.aiCourseEquipmentRequirements, false);
		return this.data[37];
	}

	set courseEquipmentRequirements(v : string | null) {
        this.setterImpl(37, v, 'courseEquipmentRequirements', false, null, null);
	}

	/**
	 * (Detail) Course skill requirements for a participant
	 *
	 * @type {string}
	 */
	get courseSkillRequirements() : string | null {
		this.getterDebugValidations(this.aiCourseSkillRequirements, false);
		return this.data[38];
	}

	set courseSkillRequirements(v : string | null) {
        this.setterImpl(38, v, 'courseSkillRequirements', false, null, null);
	}

	/**
	 * (Detail) Course location
	 *
	 * @type {string}
	 */
	get courseLocation() : string | null {
		this.getterDebugValidations(this.aiCourseLocation, false);
		return this.data[39];
	}

	set courseLocation(v : string | null) {
        this.setterImpl(39, v, 'courseLocation', false, null, null);
	}

	/**
	 * (Detail) Contact name for any questions.
	 *
	 * @type {string}
	 */
	get courseContactName() : string | null {
		this.getterDebugValidations(this.aiCourseContactName, false);
		return this.data[40];
	}

	set courseContactName(v : string | null) {
        this.setterImpl(40, v, 'courseContactName', false, null, null);
	}

	/**
	 * (Detail) Contact email for any questions.
	 *
	 * @type {Email}
	 */
	get courseContactEmail() : Email | null {
		this.getterDebugValidations(this.aiCourseContactEmail, false);
		return this.data[41];
	}

	set courseContactEmail(v : Email | null) {
        this.setterImpl(41, v, 'courseContactEmail', false, null, null);
	}

	/**
	 * (Detail) Contact phone for any questions.
	 *
	 * @type {Tel}
	 */
	get courseContactPhone() : Tel | null {
		this.getterDebugValidations(this.aiCourseContactPhone, false);
		return this.data[42];
	}

	set courseContactPhone(v : Tel | null) {
        this.setterImpl(42, v, 'courseContactPhone', false, null, null);
	}

	/**
	 * (Detail) How many milliseconds before course begin should a participant arrive?
	 *
	 * @type {Duration}
	 */
	get arrivalTimeBeforeCourse() : Duration | null {
		this.getterDebugValidations(this.aiArrivalTimeBeforeCourse, false);
		return this.data[43];
	}

	set arrivalTimeBeforeCourse(v : Duration | null) {
        this.setterImpl(43, v, 'arrivalTimeBeforeCourse', false, null, null);
	}

	/**
	 * (Detail) From how many milliseconds before course begin can a booking be done?
	 *
	 * @type {Duration}
	 */
	get courseBookingDeadlineFrom() : Duration | null {
		this.getterDebugValidations(this.aiCourseBookingDeadlineFrom, true);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[44] === undefined) {
			this.data[44] = this.aiCourseBookingDeadlineFrom.defaultValue;
			
		}
		return this.data[44];
	}

	set courseBookingDeadlineFrom(v : Duration | null) {
        this.setterImpl(44, v, 'courseBookingDeadlineFrom', false, null, null);
	}

	/**
	 * (Detail) Until how many milliseconds before course begin can a booking be done?
	 *
	 * @type {Duration}
	 */
	get courseBookingDeadlineUntil() : Duration | null {
		this.getterDebugValidations(this.aiCourseBookingDeadlineUntil, true);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[45] === undefined) {
			this.data[45] = this.aiCourseBookingDeadlineUntil.defaultValue;
			
		}
		return this.data[45];
	}

	set courseBookingDeadlineUntil(v : Duration | null) {
        this.setterImpl(45, v, 'courseBookingDeadlineUntil', false, null, null);
	}

	/**
	 * (Detail) Minimal course participant count. Can be used with "changeSelector".
	 *
	 * @type {Integer}
	 */
	get minCourseParticipantCount() : Integer {
		this.getterDebugValidations(this.aiMinCourseParticipantCount, false);
		return this.data[46];
	}

	set minCourseParticipantCount(v : Integer) {
        this.setterImpl(46, v, 'minCourseParticipantCount', false, null, null);
	}

	/**
	 * (Detail) Maximal course participant count. Set "null" to have no maximal course participant count. Can be used with "changeSelector".
	 *
	 * @type {Integer}
	 */
	get maxCourseParticipantCount() : Integer | null {
		this.getterDebugValidations(this.aiMaxCourseParticipantCount, false);
		return this.data[47];
	}

	set maxCourseParticipantCount(v : Integer | null) {
        this.setterImpl(47, v, 'maxCourseParticipantCount', false, null, null);
	}

	/**
	 * (Detail) Should booking deadlines be calculated for slot bookings? This means: "courseBookingDeadlineFrom" is calculated based on start of shift (default is start of day of shift). Furthermore, when "courseBookingDeadlineUntil" is not set you can book until end of shift (default is start of shift).
	 *
	 * @type {boolean}
	 */
	get useBookingDeadlinesForSlots() : boolean {
		this.getterDebugValidations(this.aiUseBookingDeadlinesForSlots, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[48] === undefined) {
			this.data[48] = this.aiUseBookingDeadlinesForSlots.defaultValue;
			
		}
		return this.data[48];
	}

	set useBookingDeadlinesForSlots(v : boolean) {
        this.setterImpl(48, v, 'useBookingDeadlinesForSlots', false, null, null);
	}

	/**
	 * (Detail) Is online cancellation by booking person for free bookings allowed?
	 *
	 * @type {boolean}
	 */
	get onlineCancellationForFreeBookingsEnabled() : boolean {
		this.getterDebugValidations(this.aiOnlineCancellationForFreeBookingsEnabled, false);
		return this.data[49];
	}

	set onlineCancellationForFreeBookingsEnabled(v : boolean) {
        this.setterImpl(49, v, 'onlineCancellationForFreeBookingsEnabled', false, null, null);
	}

	/**
	 * (Detail) When "onlineCancellationForFreeBookingsEnabled" is "true" then this defines the deadline until how many milliseconds before course start the online cancellation is allowed. When "null" is returned then there is no such deadline.
	 *
	 * @type {Duration}
	 */
	get onlineCancellationForFreeBookingsDeadline() : Duration | null {
		this.getterDebugValidations(this.aiOnlineCancellationForFreeBookingsDeadline, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[50] === undefined) {
			this.data[50] = this.aiOnlineCancellationForFreeBookingsDeadline.defaultValue;
			
		}
		return this.data[50];
	}

	set onlineCancellationForFreeBookingsDeadline(v : Duration | null) {
        this.setterImpl(50, v, 'onlineCancellationForFreeBookingsDeadline', false, null, null);
	}

	/**
	 * (Detail) When "onlineCancellationForChargeableBookingsEnabled" is "true" then this defines the deadline until how many milliseconds before course start the online cancellation is allowed. When "null" is returned then there is no such deadline.
	 *
	 * @type {Duration}
	 */
	get onlineCancellationForChargeableBookingsDeadline() : Duration | null {
		this.getterDebugValidations(this.aiOnlineCancellationForChargeableBookingsDeadline, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[51] === undefined) {
			this.data[51] = this.aiOnlineCancellationForChargeableBookingsDeadline.defaultValue;
			
		}
		return this.data[51];
	}

	set onlineCancellationForChargeableBookingsDeadline(v : Duration | null) {
        this.setterImpl(51, v, 'onlineCancellationForChargeableBookingsDeadline', false, null, null);
	}

	/**
	 * (Detail) Is online cancellation by booking person for withdrawable bookings allowed?
	 *
	 * @type {boolean}
	 */
	get onlineCancellationForWithdrawableBookingsAlwaysEnabled() : boolean {
		this.getterDebugValidations(this.aiOnlineCancellationForWithdrawableBookingsAlwaysEnabled, false);
		return this.data[52];
	}

	set onlineCancellationForWithdrawableBookingsAlwaysEnabled(v : boolean) {
        this.setterImpl(52, v, 'onlineCancellationForWithdrawableBookingsAlwaysEnabled', false, null, null);
	}

	/**
	 * (Detail) When online cancellation is done should any online payment be automatically refunded?
	 *
	 * @type {boolean}
	 */
	get onlineCancellationAutomaticOnlineRefundEnabled() : boolean {
		this.getterDebugValidations(this.aiOnlineCancellationAutomaticOnlineRefundEnabled, false);
		return this.data[53];
	}

	set onlineCancellationAutomaticOnlineRefundEnabled(v : boolean) {
        this.setterImpl(53, v, 'onlineCancellationAutomaticOnlineRefundEnabled', false, null, function(this : SchedulingApiShiftModel) {// You cannot gift-card refund when this is deactivated
					if(!v)
						this.rawData[this.api.consts.SHIFT_MODEL_ONLINE_CANCELLATION_AUTOMATIC_GIFT_CARD_REFUND_ENABLED] = false;});
	}

	/**
	 * (Detail) When online cancellation is done should any gift-card redemption be refunded by creating a new gift-card for the booking person?
	 *
	 * @type {boolean}
	 */
	get onlineCancellationAutomaticGiftCardRefundEnabled() : boolean {
		this.getterDebugValidations(this.aiOnlineCancellationAutomaticGiftCardRefundEnabled, false);
		return this.data[54];
	}

	set onlineCancellationAutomaticGiftCardRefundEnabled(v : boolean) {
        this.setterImpl(54, v, 'onlineCancellationAutomaticGiftCardRefundEnabled', false, null, null);
	}

	/**
	 * (Detail) Should the assigned users of the course be shown in the booking plugin?
	 *
	 * @type {boolean}
	 */
	get showAssignedMembersInBookingPlugin() : boolean {
		this.getterDebugValidations(this.aiShowAssignedMembersInBookingPlugin, false);
		return this.data[55];
	}

	set showAssignedMembersInBookingPlugin(v : boolean) {
        this.setterImpl(55, v, 'showAssignedMembersInBookingPlugin', false, null, null);
	}

	private marketingGiftCardSettingsWrapper : SchedulingApiShiftModelMarketingGiftCardSettings = new SchedulingApiShiftModelMarketingGiftCardSettings(this.api,
		this as unknown as SchedulingApiShiftModel);
	public aiMarketingGiftCardSettings = this.marketingGiftCardSettingsWrapper.aiThis;

	/**
	 * (Detail) The settings for sending marketing gift-cards for this shift-model.
	 */
	get marketingGiftCardSettings() : SchedulingApiShiftModelMarketingGiftCardSettings {
		this.getterDebugValidations(this.aiMarketingGiftCardSettings, false);
		return this.marketingGiftCardSettingsWrapper;
	}

	set marketingGiftCardSettingsTestSetter(v : SchedulingApiShiftModelMarketingGiftCardSettings) {
        this.setterImpl(56, v.rawData, 'marketingGiftCardSettings', true, null, function(this : SchedulingApiShiftModel) {this.marketingGiftCardSettingsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[3] = Meta.getReplacedId(this.data[3], _idReplacements);
		this.activityAreaIdWrapper = this.data[3] === null ? null! : Id.create(this.data[3]);
		this.assignableMembersWrapper._fixIds(_idReplacements);
		this.repetitionWrapper._fixIds(_idReplacements);
		this.timeWrapper._fixIds(_idReplacements);
		this.posAccountsWrapper._fixIds(_idReplacements);
		this.changeSelectorWrapper._fixIds(_idReplacements);
		this.automaticBookableMailIdsWrapper._fixIds(_idReplacements);
		this.courseTariffsWrapper._fixIds(_idReplacements);
		this.coursePaymentMethodsWrapper._fixIds(_idReplacements);
		this.data[19] = Meta.getReplacedId(this.data[19], _idReplacements);
		this.currentCancellationPolicyIdWrapper = this.data[19] === null ? null! : Id.create(this.data[19]);
		this.cancellationPoliciesWrapper._fixIds(_idReplacements);
		this.neededMembersCountConfWrapper._fixIds(_idReplacements);
		this.assignedMemberIdsWrapper._fixIds(_idReplacements);
		this.courseHighlightsWrapper._fixIds(_idReplacements);
		this.marketingGiftCardSettingsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[3] : null, this.activityAreaIdWrapper))
			this.activityAreaIdWrapper = data && data[3] ? Id.create(data[3]) : null!;
		this.assignableMembersWrapper._updateRawData(data && data[5] !== undefined ? data[5] : null, generateMissingData);
		this.repetitionWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
		this.timeWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.posAccountsWrapper._updateRawData(data && data[10] !== undefined ? data[10] : null, generateMissingData);
		this.changeSelectorWrapper._updateRawData(data && data[13] !== undefined ? data[13] : null, generateMissingData);
		this.automaticBookableMailIdsWrapper._updateRawData(data && data[14] !== undefined ? data[14] : null, generateMissingData);
		this.courseTariffsWrapper._updateRawData(data && data[16] !== undefined ? data[16] : null, generateMissingData);
		this.coursePaymentMethodsWrapper._updateRawData(data && data[17] !== undefined ? data[17] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[19] : null, this.currentCancellationPolicyIdWrapper))
			this.currentCancellationPolicyIdWrapper = data && data[19] ? Id.create(data[19]) : null!;
		this.cancellationPoliciesWrapper._updateRawData(data && data[20] !== undefined ? data[20] : null, generateMissingData);
		this.neededMembersCountConfWrapper._updateRawData(data && data[30] !== undefined ? data[30] : null, generateMissingData);
		this.assignedMemberIdsWrapper._updateRawData(data && data[32] !== undefined ? data[32] : null, generateMissingData);
		this.courseHighlightsWrapper._updateRawData(data && data[36] !== undefined ? data[36] : null, generateMissingData);
		this.marketingGiftCardSettingsWrapper._updateRawData(data && data[56] !== undefined ? data[56] : null, generateMissingData);
	}

	protected get dni() : string {
		return '251';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '251', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelAssignableMembersBase extends ApiListWrapper<SchedulingApiShiftModelAssignableMember>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignableMembers');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelAssignableMember {
		return new SchedulingApiShiftModelAssignableMember(this.api, this as unknown as SchedulingApiShiftModelAssignableMembers, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelAssignableMembers(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '256';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelAssignableMember) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelAssignableMember {
		const newItem = new SchedulingApiShiftModelAssignableMember(this.api, this as unknown as SchedulingApiShiftModelAssignableMembers, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('assignableMembers');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelAssignableMembers, SchedulingApiShiftModelAssignableMembers> = new ApiAttributeInfo<SchedulingApiShiftModelAssignableMembers, SchedulingApiShiftModelAssignableMembers>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAssignableMembers,
			name: 'assignableMembers',
			nodeName: 'SHIFT_MODEL_ASSIGNABLE_MEMBERS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiShiftModelAssignableMembers) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelAssignableMembers, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 5,
		});
}

				 
export class SchedulingApiShiftModelAssignableMember extends ApiObjectWrapper<SchedulingApiShiftModelAssignableMember>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelAssignableMembers | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelAssignableMember> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelAssignableMember as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelAssignableMember, SchedulingApiShiftModelAssignableMember> = new ApiAttributeInfo<SchedulingApiShiftModelAssignableMember, SchedulingApiShiftModelAssignableMember>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAssignableMember,
			name: 'shiftModelAssignableMember',
			nodeName: 'SHIFT_MODEL_ASSIGNABLE_MEMBER',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelAssignableMember, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			
		});
	aiHourlyEarnings : ApiAttributeInfo<SchedulingApiShiftModelAssignableMember, ClientCurrency> = new ApiAttributeInfo<SchedulingApiShiftModelAssignableMember, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAssignableMember,
			name: 'hourlyEarnings',
			nodeName: 'SHIFT_MODEL_ASSIGNABLE_MEMBER_EARNINGS',
			type: PApiType.ClientCurrency,
			rawDataIndex: 1,
		});
	aiMemberId : ApiAttributeInfo<SchedulingApiShiftModelAssignableMember, Id> = new ApiAttributeInfo<SchedulingApiShiftModelAssignableMember, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAssignableMember,
			name: 'memberId',
			nodeName: 'SHIFT_MODEL_ASSIGNABLE_MEMBER_ID',
			type: PApiType.Id,
			rawDataIndex: 2,
		});

	/**
	 * Members hourly earnings for this shift model
	 *
	 * @type {ClientCurrency}
	 */
	get hourlyEarnings() : ClientCurrency {
		this.getterDebugValidations(this.aiHourlyEarnings, false);
		return this.data[1];
	}

	set hourlyEarnings(v : ClientCurrency) {
        this.setterImpl(1, v, 'hourlyEarnings', false, null, null);
	}

	private memberIdWrapper : Id = null!;

	/**
	 * Id of assignable member
	 *
	 * @type {Id}
	 */
	get memberId() : Id {
		this.getterDebugValidations(this.aiMemberId, false);
		return this.memberIdWrapper;
	}

	set memberId(v : Id) {
        this.setterImpl(2, v, 'memberId', false, null, function(this : SchedulingApiShiftModelAssignableMember) {this.memberIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.memberIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_ASSIGNABLE_MEMBER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[2] : null, this.memberIdWrapper))
			this.memberIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
	}

	protected get dni() : string {
		return '313';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '313', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiShiftModelRepetitionBase extends ApiObjectWrapper<SchedulingApiShiftModelRepetition>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelRepetition> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelRepetition as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelRepetition, SchedulingApiShiftModelRepetition> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, SchedulingApiShiftModelRepetition>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'repetition',
			nodeName: 'SHIFT_MODEL_REPETITION',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiShiftModelRepetition) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => { return SchedulingApiShiftModelRepetition.checkEveryXWeeksRepetitionOneWeekDayIsSelected(this) },
					() => { return SchedulingApiShiftModelRepetition.checkIfEveryXWeeksRepetitionAndWeekDaysSelectionFitsTogether(this) },
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 6,
		});
	aiType : ApiAttributeInfo<SchedulingApiShiftModelRepetition, SchedulingApiShiftRepetitionType> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, SchedulingApiShiftRepetitionType>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'type',
			nodeName: 'SHIFT_MODEL_REPETITION_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return SchedulingApiShiftRepetitionType.NONE;},
			rawDataIndex: 1,
		});
	aiX : ApiAttributeInfo<SchedulingApiShiftModelRepetition, Integer> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'x',
			nodeName: 'SHIFT_MODEL_REPETITION_X',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiRepetitionEndMode : ApiAttributeInfo<SchedulingApiShiftModelRepetition, ShiftModelRepetitionEndMode> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, ShiftModelRepetitionEndMode>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'repetitionEndMode',
			nodeName: 'SHIFT_MODEL_REPETITION_REPETITION_END_MODE',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return ShiftModelRepetitionEndMode.NEVER;},
			rawDataIndex: 3,
		});
	aiEndsAfterRepetitionCount : ApiAttributeInfo<SchedulingApiShiftModelRepetition, Integer> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'endsAfterRepetitionCount',
			nodeName: 'SHIFT_MODEL_REPETITION_ENDS_AFTER_REPETITION_COUNT',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE)&&(this.repetitionEndMode === ShiftModelRepetitionEndMode.AFTER_X_TIMES));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
		if(((this.repetitionEndMode === ShiftModelRepetitionEndMode.AFTER_X_TIMES)))
		{
			return this.api.validators.min(2, true, PApiType.Integer, undefined, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.max(200, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiEndsAfterDate : ApiAttributeInfo<SchedulingApiShiftModelRepetition, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'endsAfterDate',
			nodeName: 'SHIFT_MODEL_REPETITION_ENDS_AFTER_DATE',
			type: PApiType.DateExclusiveEnd,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE)&&(this.repetitionEndMode === ShiftModelRepetitionEndMode.ENDS_AFTER_DATE));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
		if(((this.repetitionEndMode === ShiftModelRepetitionEndMode.ENDS_AFTER_DATE)))
		{
			return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiIsRepeatingOnMonday : ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'isRepeatingOnMonday',
			nodeName: 'SHIFT_MODEL_REPETITION_ON_MONDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return false;},
			rawDataIndex: 7,
		});
	aiIsRepeatingOnTuesday : ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'isRepeatingOnTuesday',
			nodeName: 'SHIFT_MODEL_REPETITION_ON_TUESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return false;},
			rawDataIndex: 8,
		});
	aiIsRepeatingOnWednesday : ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'isRepeatingOnWednesday',
			nodeName: 'SHIFT_MODEL_REPETITION_ON_WEDNESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return false;},
			rawDataIndex: 9,
		});
	aiIsRepeatingOnThursday : ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'isRepeatingOnThursday',
			nodeName: 'SHIFT_MODEL_REPETITION_ON_THURSDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return false;},
			rawDataIndex: 10,
		});
	aiIsRepeatingOnFriday : ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'isRepeatingOnFriday',
			nodeName: 'SHIFT_MODEL_REPETITION_ON_FRIDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiIsRepeatingOnSaturday : ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'isRepeatingOnSaturday',
			nodeName: 'SHIFT_MODEL_REPETITION_ON_SATURDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return false;},
			rawDataIndex: 12,
		});
	aiIsRepeatingOnSunday : ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetition, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetition,
			name: 'isRepeatingOnSunday',
			nodeName: 'SHIFT_MODEL_REPETITION_ON_SUNDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetition) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetition) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetition) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetition, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});

	/**
	 * repetition type. "NONE" means no repetition
	 *
	 * @type {SchedulingApiShiftRepetitionType}
	 */
	get type() : SchedulingApiShiftRepetitionType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiShiftRepetitionType) {
        this.setterImpl(1, v, 'type', false, null, null);
	}

	/**
	 * This is the "x" value defined by the type property (e.g. EVERY_X_DAYS or EVERY_X_WEEKS).
	 *
	 * @type {Integer}
	 */
	get x() : Integer {
		this.getterDebugValidations(this.aiX, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiX.defaultValue;
			
		}
		return this.data[2];
	}

	set x(v : Integer) {
        this.setterImpl(2, v, 'x', false, null, null);
	}

	/**
	 * What is the criteria to end the repetition?
	 *
	 * @type {ShiftModelRepetitionEndMode}
	 */
	get repetitionEndMode() : ShiftModelRepetitionEndMode {
		this.getterDebugValidations(this.aiRepetitionEndMode, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiRepetitionEndMode.defaultValue;
			
		}
		return this.data[3];
	}

	set repetitionEndMode(v : ShiftModelRepetitionEndMode) {
        this.setterImpl(3, v, 'repetitionEndMode', false, null, null);
	}

	/**
	 * Should the repetition end after a given count? If not, set to "0".
	 *
	 * @type {Integer}
	 */
	get endsAfterRepetitionCount() : Integer {
		this.getterDebugValidations(this.aiEndsAfterRepetitionCount, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiEndsAfterRepetitionCount.defaultValue;
			
		}
		return this.data[4];
	}

	set endsAfterRepetitionCount(v : Integer) {
        this.setterImpl(4, v, 'endsAfterRepetitionCount', false, null, null);
	}

	/**
	 * Should the repetition end after a given date? If not, set to "0".
	 *
	 * @type {DateExclusiveEnd}
	 */
	get endsAfterDate() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiEndsAfterDate, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiEndsAfterDate.defaultValue;
			
		}
		return this.data[5];
	}

	set endsAfterDate(v : DateExclusiveEnd | null) {
        this.setterImpl(5, v, 'endsAfterDate', false, null, null);
	}

	private packetRepetitionWrapper : SchedulingApiShiftModelRepetitionPacket = new SchedulingApiShiftModelRepetitionPacket(this.api,
		this as unknown as SchedulingApiShiftModelRepetition);
	public aiPacketRepetition = this.packetRepetitionWrapper.aiThis;

	/**
	 * The packet repetition pattern.
	 */
	get packetRepetition() : SchedulingApiShiftModelRepetitionPacket {
		this.getterDebugValidations(this.aiPacketRepetition, false);
		return this.packetRepetitionWrapper;
	}

	set packetRepetitionTestSetter(v : SchedulingApiShiftModelRepetitionPacket) {
        this.setterImpl(6, v.rawData, 'packetRepetition', true, null, function(this : SchedulingApiShiftModelRepetition) {this.packetRepetitionWrapper = v;});
	}

	/**
	 * Is there a repetition on Monday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnMonday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnMonday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiIsRepeatingOnMonday.defaultValue;
			
		}
		return this.data[7];
	}

	set isRepeatingOnMonday(v : boolean) {
        this.setterImpl(7, v, 'isRepeatingOnMonday', false, null, null);
	}

	/**
	 * Is there a repetition on Tuesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnTuesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnTuesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiIsRepeatingOnTuesday.defaultValue;
			
		}
		return this.data[8];
	}

	set isRepeatingOnTuesday(v : boolean) {
        this.setterImpl(8, v, 'isRepeatingOnTuesday', false, null, null);
	}

	/**
	 * Is there a repetition on Wednesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnWednesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnWednesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[9] === undefined) {
			this.data[9] = this.aiIsRepeatingOnWednesday.defaultValue;
			
		}
		return this.data[9];
	}

	set isRepeatingOnWednesday(v : boolean) {
        this.setterImpl(9, v, 'isRepeatingOnWednesday', false, null, null);
	}

	/**
	 * Is there a repetition on Thursday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnThursday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnThursday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[10] === undefined) {
			this.data[10] = this.aiIsRepeatingOnThursday.defaultValue;
			
		}
		return this.data[10];
	}

	set isRepeatingOnThursday(v : boolean) {
        this.setterImpl(10, v, 'isRepeatingOnThursday', false, null, null);
	}

	/**
	 * Is there a repetition on Friday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnFriday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnFriday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[11] === undefined) {
			this.data[11] = this.aiIsRepeatingOnFriday.defaultValue;
			
		}
		return this.data[11];
	}

	set isRepeatingOnFriday(v : boolean) {
        this.setterImpl(11, v, 'isRepeatingOnFriday', false, null, null);
	}

	/**
	 * Is there a repetition on Saturday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSaturday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSaturday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[12] === undefined) {
			this.data[12] = this.aiIsRepeatingOnSaturday.defaultValue;
			
		}
		return this.data[12];
	}

	set isRepeatingOnSaturday(v : boolean) {
        this.setterImpl(12, v, 'isRepeatingOnSaturday', false, null, null);
	}

	/**
	 * Is there a repetition on Sunday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSunday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSunday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[13] === undefined) {
			this.data[13] = this.aiIsRepeatingOnSunday.defaultValue;
			
		}
		return this.data[13];
	}

	set isRepeatingOnSunday(v : boolean) {
        this.setterImpl(13, v, 'isRepeatingOnSunday', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.packetRepetitionWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.packetRepetitionWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
	}

	protected get dni() : string {
		return '257';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '257', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiShiftModelRepetitionPacket extends ApiObjectWrapper<SchedulingApiShiftModelRepetitionPacket>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelRepetition | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelRepetitionPacket> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelRepetitionPacket as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, SchedulingApiShiftModelRepetitionPacket> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, SchedulingApiShiftModelRepetitionPacket>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'packetRepetition',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => { return SchedulingApiShiftModelRepetition.checkEveryXWeeksRepetitionOneWeekDayIsSelected(this) },
					() => { return SchedulingApiShiftModelRepetition.checkIfEveryXWeeksRepetitionAndWeekDaysSelectionFitsTogether(this) },
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 6,
		});
	aiType : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, SchedulingApiShiftRepetitionType> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, SchedulingApiShiftRepetitionType>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'type',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return SchedulingApiShiftRepetitionType.NONE;},
			rawDataIndex: 1,
		});
	aiX : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, Integer> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'x',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_X',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiEndsAfterRepetitionCount : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, Integer> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'endsAfterRepetitionCount',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ENDS_AFTER_REPETITION_COUNT',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type !== SchedulingApiShiftRepetitionType.NONE));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(2, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(200, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiIsRepeatingOnMonday : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'isRepeatingOnMonday',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ON_MONDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiIsRepeatingOnTuesday : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'isRepeatingOnTuesday',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ON_TUESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});
	aiIsRepeatingOnWednesday : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'isRepeatingOnWednesday',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ON_WEDNESDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 6,
		});
	aiIsRepeatingOnThursday : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'isRepeatingOnThursday',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ON_THURSDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 7,
		});
	aiIsRepeatingOnFriday : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'isRepeatingOnFriday',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ON_FRIDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 8,
		});
	aiIsRepeatingOnSaturday : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'isRepeatingOnSaturday',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ON_SATURDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 9,
		});
	aiIsRepeatingOnSunday : ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelRepetitionPacket, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelRepetitionPacket,
			name: 'isRepeatingOnSunday',
			nodeName: 'SHIFT_MODEL_REPETITION_PACKET_ON_SUNDAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return ((this.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS));
			},
			validations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelRepetitionPacket) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelRepetitionPacket, _nodeId : string) {return false;},
			rawDataIndex: 10,
		});

	/**
	 * repetition type. "NONE" means no repetition
	 *
	 * @type {SchedulingApiShiftRepetitionType}
	 */
	get type() : SchedulingApiShiftRepetitionType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiShiftRepetitionType) {
        this.setterImpl(1, v, 'type', false, null, null);
	}

	/**
	 * This is the "x" value defined by the type property (e.g. EVERY_X_DAYS or EVERY_X_WEEKS).
	 *
	 * @type {Integer}
	 */
	get x() : Integer {
		this.getterDebugValidations(this.aiX, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiX.defaultValue;
			
		}
		return this.data[2];
	}

	set x(v : Integer) {
        this.setterImpl(2, v, 'x', false, null, null);
	}

	/**
	 * Should the repetition end after a given count? If not, set to "0".
	 *
	 * @type {Integer}
	 */
	get endsAfterRepetitionCount() : Integer {
		this.getterDebugValidations(this.aiEndsAfterRepetitionCount, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiEndsAfterRepetitionCount.defaultValue;
			
		}
		return this.data[3];
	}

	set endsAfterRepetitionCount(v : Integer) {
        this.setterImpl(3, v, 'endsAfterRepetitionCount', false, null, null);
	}

	/**
	 * Is there a repetition on Monday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnMonday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnMonday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiIsRepeatingOnMonday.defaultValue;
			
		}
		return this.data[4];
	}

	set isRepeatingOnMonday(v : boolean) {
        this.setterImpl(4, v, 'isRepeatingOnMonday', false, null, null);
	}

	/**
	 * Is there a repetition on Tuesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnTuesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnTuesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiIsRepeatingOnTuesday.defaultValue;
			
		}
		return this.data[5];
	}

	set isRepeatingOnTuesday(v : boolean) {
        this.setterImpl(5, v, 'isRepeatingOnTuesday', false, null, null);
	}

	/**
	 * Is there a repetition on Wednesday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnWednesday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnWednesday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiIsRepeatingOnWednesday.defaultValue;
			
		}
		return this.data[6];
	}

	set isRepeatingOnWednesday(v : boolean) {
        this.setterImpl(6, v, 'isRepeatingOnWednesday', false, null, null);
	}

	/**
	 * Is there a repetition on Thursday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnThursday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnThursday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiIsRepeatingOnThursday.defaultValue;
			
		}
		return this.data[7];
	}

	set isRepeatingOnThursday(v : boolean) {
        this.setterImpl(7, v, 'isRepeatingOnThursday', false, null, null);
	}

	/**
	 * Is there a repetition on Friday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnFriday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnFriday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiIsRepeatingOnFriday.defaultValue;
			
		}
		return this.data[8];
	}

	set isRepeatingOnFriday(v : boolean) {
        this.setterImpl(8, v, 'isRepeatingOnFriday', false, null, null);
	}

	/**
	 * Is there a repetition on Saturday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSaturday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSaturday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[9] === undefined) {
			this.data[9] = this.aiIsRepeatingOnSaturday.defaultValue;
			
		}
		return this.data[9];
	}

	set isRepeatingOnSaturday(v : boolean) {
        this.setterImpl(9, v, 'isRepeatingOnSaturday', false, null, null);
	}

	/**
	 * Is there a repetition on Sunday? Only valid when type is "EVERY_X_WEEKS".
	 *
	 * @type {boolean}
	 */
	get isRepeatingOnSunday() : boolean {
		this.getterDebugValidations(this.aiIsRepeatingOnSunday, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[10] === undefined) {
			this.data[10] = this.aiIsRepeatingOnSunday.defaultValue;
			
		}
		return this.data[10];
	}

	set isRepeatingOnSunday(v : boolean) {
        this.setterImpl(10, v, 'isRepeatingOnSunday', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '323';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '323', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiShiftModelTime extends ApiObjectWrapper<SchedulingApiShiftModelTime>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelTime> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelTime as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelTime, SchedulingApiShiftModelTime> = new ApiAttributeInfo<SchedulingApiShiftModelTime, SchedulingApiShiftModelTime>({
			apiObjWrapper: this as any as SchedulingApiShiftModelTime,
			name: 'time',
			nodeName: 'SHIFT_MODEL_TIME',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiShiftModelTime) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelTime) {
				{const conditionValue = !this.parent!.trashed; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelTime, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 7,
		});
	aiStart : ApiAttributeInfo<SchedulingApiShiftModelTime, LocalTime> = new ApiAttributeInfo<SchedulingApiShiftModelTime, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiShiftModelTime,
			name: 'start',
			nodeName: 'SHIFT_MODEL_TIME_START',
			type: PApiType.LocalTime,
			validations: function(this : SchedulingApiShiftModelTime) {
				return [
					() => {
return this.api.validators.max(() => this.end, false, PApiType.LocalTime, this.aiEnd.id, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelTime) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiShiftModelTime, LocalTime> = new ApiAttributeInfo<SchedulingApiShiftModelTime, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiShiftModelTime,
			name: 'end',
			nodeName: 'SHIFT_MODEL_TIME_END',
			type: PApiType.LocalTime,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiShiftModelTime) {
				return [
					() => {
return this.api.validators.min(() => this.start, false, PApiType.LocalTime, this.aiStart.id, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelTime) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});

	/**
	 * The time when this shift-model will start.
	 *
	 * @type {LocalTime}
	 */
	get start() : LocalTime {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set start(v : LocalTime) {
        this.setterImpl(1, v, 'start', false, null, null);
	}

	/**
	 * (Detail) The time when this shift-model will end.
	 *
	 * @type {LocalTime}
	 */
	get end() : LocalTime {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set end(v : LocalTime) {
        this.setterImpl(2, v, 'end', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '258';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '258', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelPosAccounts extends ApiListWrapper<SchedulingApiShiftModelPosAccount>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'posAccounts');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelPosAccount {
		return new SchedulingApiShiftModelPosAccount(this.api, this as unknown as SchedulingApiShiftModelPosAccounts, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelPosAccounts(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '261';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelPosAccount) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelPosAccount {
		const newItem = new SchedulingApiShiftModelPosAccount(this.api, this as unknown as SchedulingApiShiftModelPosAccounts, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('posAccounts');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelPosAccounts, SchedulingApiShiftModelPosAccounts> = new ApiAttributeInfo<SchedulingApiShiftModelPosAccounts, SchedulingApiShiftModelPosAccounts>({
			apiObjWrapper: this as any as SchedulingApiShiftModelPosAccounts,
			name: 'posAccounts',
			nodeName: 'SHIFT_MODEL_POS_ACCOUNTS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiShiftModelPosAccounts) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelPosAccounts, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 10,
		});
}

				 
export class SchedulingApiShiftModelPosAccount extends ApiObjectWrapper<SchedulingApiShiftModelPosAccount>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelPosAccounts | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelPosAccount> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelPosAccount as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelPosAccount, SchedulingApiShiftModelPosAccount> = new ApiAttributeInfo<SchedulingApiShiftModelPosAccount, SchedulingApiShiftModelPosAccount>({
			apiObjWrapper: this as any as SchedulingApiShiftModelPosAccount,
			name: 'shiftModelPosAccount',
			nodeName: 'SHIFT_MODEL_POS_ACCOUNT',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelPosAccount, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiVatPercent : ApiAttributeInfo<SchedulingApiShiftModelPosAccount, Percent> = new ApiAttributeInfo<SchedulingApiShiftModelPosAccount, Percent>({
			apiObjWrapper: this as any as SchedulingApiShiftModelPosAccount,
			name: 'vatPercent',
			nodeName: 'SHIFT_MODEL_POS_ACCOUNT_VAT_PERCENT',
			type: PApiType.Percent,
			rawDataIndex: 1,
		});
	aiName : ApiAttributeInfo<SchedulingApiShiftModelPosAccount, string> = new ApiAttributeInfo<SchedulingApiShiftModelPosAccount, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelPosAccount,
			name: 'name',
			nodeName: 'SHIFT_MODEL_POS_ACCOUNT_NAME',
			type: PApiType.string,
			rawDataIndex: 2,
		});

	/**
	 * Vat-percent value.
	 *
	 * @type {Percent}
	 */
	get vatPercent() : Percent {
		this.getterDebugValidations(this.aiVatPercent, false);
		return this.data[1];
	}

	set vatPercent(v : Percent) {
        this.setterImpl(1, v, 'vatPercent', false, null, null);
	}

	/**
	 * Name of the POS account.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[2];
	}

	set name(v : string) {
        this.setterImpl(2, v, 'name', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_POS_ACCOUNT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '341';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '341', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiCourseType {
	ONLINE_BOOKABLE = 1,
	ONLINE_INQUIRY = 2,
	NO_BOOKING = 3,
}
		 
export class SchedulingApiShiftModelChangeSelector extends ApiObjectWrapper<SchedulingApiShiftModelChangeSelector>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelChangeSelector> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelChangeSelector as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelChangeSelector, SchedulingApiShiftModelChangeSelector> = new ApiAttributeInfo<SchedulingApiShiftModelChangeSelector, SchedulingApiShiftModelChangeSelector>({
			apiObjWrapper: this as any as SchedulingApiShiftModelChangeSelector,
			name: 'changeSelector',
			nodeName: 'SHIFT_MODEL_CHANGE_SELECTOR',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiShiftModelChangeSelector) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelChangeSelector, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 13,
		});
	aiStart : ApiAttributeInfo<SchedulingApiShiftModelChangeSelector, Date> = new ApiAttributeInfo<SchedulingApiShiftModelChangeSelector, Date>({
			apiObjWrapper: this as any as SchedulingApiShiftModelChangeSelector,
			name: 'start',
			nodeName: 'SHIFT_MODEL_CHANGE_SELECTOR_START',
			type: PApiType.Date,
			validations: function(this : SchedulingApiShiftModelChangeSelector) {
				return [
					() => {
return this.api.validators.min(+this.api.pMoment.monthsFromNow(-5), true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(+this.api.pMoment.monthsFromNow(12), true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelChangeSelector) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});

	/**
	 * The start time from which the existing shifts should be modified. If "null" then no shifts will be modified.
	 *
	 * @type {Date}
	 */
	get start() : Date | null {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set start(v : Date | null) {
        this.setterImpl(1, v, 'start', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '264';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '264', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelAutomaticBookableMailIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'automaticBookableMailIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelAutomaticBookableMailIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '265';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelAutomaticBookableMailIds, SchedulingApiShiftModelAutomaticBookableMailIds> = new ApiAttributeInfo<SchedulingApiShiftModelAutomaticBookableMailIds, SchedulingApiShiftModelAutomaticBookableMailIds>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAutomaticBookableMailIds,
			name: 'automaticBookableMailIds',
			nodeName: 'SHIFT_MODEL_AUTOMATIC_BOOKABLE_MAIL_IDS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiShiftModelAutomaticBookableMailIds) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelAutomaticBookableMailIds) {
				{const conditionValue = this.parent!.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelAutomaticBookableMailIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 14,
		});
	aiShiftModelAutomaticBookableMailId : ApiAttributeInfo<SchedulingApiShiftModelAutomaticBookableMailIds, Id> = new ApiAttributeInfo<SchedulingApiShiftModelAutomaticBookableMailIds, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAutomaticBookableMailIds,
			name: 'shiftModelAutomaticBookableMailId',
			nodeName: 'SHIFT_MODEL_AUTOMATIC_BOOKABLE_MAIL_ID',
			type: PApiType.Id,
			
		});
}

				 export class SchedulingApiShiftModelCourseTariffsBase extends ApiListWrapper<SchedulingApiShiftModelCourseTariff>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'courseTariffs');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelCourseTariff {
		return new SchedulingApiShiftModelCourseTariff(this.api, this as unknown as SchedulingApiShiftModelCourseTariffs, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelCourseTariffs(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '267';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelCourseTariff) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelCourseTariff {
		const newItem = new SchedulingApiShiftModelCourseTariff(this.api, this as unknown as SchedulingApiShiftModelCourseTariffs, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('courseTariffs');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCourseTariffs, SchedulingApiShiftModelCourseTariffs> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariffs, SchedulingApiShiftModelCourseTariffs>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariffs,
			name: 'courseTariffs',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFFS',
			type: PApiType.ApiList,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelCourseTariffs) {
				{const conditionValue = this.parent!.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelCourseTariffs, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 16,
		});
}

				 
export class SchedulingApiShiftModelCourseTariffBase extends ApiObjectWrapper<SchedulingApiShiftModelCourseTariff>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCourseTariffs | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelCourseTariff> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelCourseTariff as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, SchedulingApiShiftModelCourseTariff> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, SchedulingApiShiftModelCourseTariff>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'shiftModelCourseTariff',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariff, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, string> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'name',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(70, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, string> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'description',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_DESCRIPTION',
			type: PApiType.string,
			rawDataIndex: 2,
		});
	aiIsInternal : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'isInternal',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_IS_INTERNAL',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariff, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	aiHasAdditionalField : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'hasAdditionalField',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_HAS_ADDITIONAL_FIELD',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelCourseTariff) {
				{const conditionValue = this.api.currentlyDetailedLoaded instanceof SchedulingApiShiftModel || (!!this.parent && this.parent!.parent!.isNewItem); if(!conditionValue) return conditionValue === false ? {sourceString: 'Diese Einstellung kannst du in einer bestehenden Buchung nicht ändern. Wenn du die Abfrage dieser zusätzlichen Info für zukünftige Buchungen ändern möchtest, kannst du das in der betroffenen Tätigkeit tun.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariff, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});
	aiAdditionalFieldLabel : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, string> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'additionalFieldLabel',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_ADDITIONAL_FIELD_LABEL',
			type: PApiType.string,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelCourseTariff) {
				{const conditionValue = this.hasAdditionalField; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
		if(((this.hasAdditionalField)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
						return null;
					},
					() => {
						return this.api.validators.maxLength(30, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiForCourseDatesFrom : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, Date> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, Date>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'forCourseDatesFrom',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FOR_COURSE_DATES_FROM',
			type: PApiType.Date,
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
return this.api.validators.max(() => this.forCourseDatesUntil, false, PApiType.Date, this.aiForCourseDatesUntil.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiForCourseDatesUntil : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'forCourseDatesUntil',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FOR_COURSE_DATES_UNTIL',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
return this.api.validators.min(() => this.forCourseDatesFrom, false, PApiType.DateExclusiveEnd, this.aiForCourseDatesFrom.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiNegateForCourseDatesInterval : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'negateForCourseDatesInterval',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_NEGATE_FOR_COURSE_DATES_INTERVAL',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariff, _nodeId : string) {return false;},
			rawDataIndex: 9,
		});
	aiTrashed : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'trashed',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_TRASHED',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariff) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariff, _nodeId : string) {return false;},
			rawDataIndex: 10,
		});
	aiApplyToBooking : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, Id> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'applyToBooking',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_APPLY_TO_BOOKING',
			type: PApiType.Id,
			rawDataIndex: 11,
		});
	aiApplyToParticipant : ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, Id> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariff, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariff,
			name: 'applyToParticipant',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_APPLY_TO_PARTICIPANT',
			type: PApiType.Id,
			rawDataIndex: 12,
		});

	/**
	 * Tariff Name
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * Tariff Description
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[2];
	}

	set description(v : string | null) {
        this.setterImpl(2, v, 'description', false, null, null);
	}

	/**
	 * Is the tariff internal? I.e. only usable during manual booking?
	 *
	 * @type {boolean}
	 */
	get isInternal() : boolean {
		this.getterDebugValidations(this.aiIsInternal, false);
		return this.data[3];
	}

	set isInternal(v : boolean) {
        this.setterImpl(3, v, 'isInternal', false, null, null);
	}

	private feesWrapper : SchedulingApiShiftModelCourseTariffFees = new SchedulingApiShiftModelCourseTariffFees(this.api,
		this as unknown as SchedulingApiShiftModelCourseTariff, false, false);
	public aiFees = this.feesWrapper.aiThis;

	/**
	 * A list of tariff's fees
	 */
	get fees() : SchedulingApiShiftModelCourseTariffFees {
		this.getterDebugValidations(this.aiFees, false);
		return this.feesWrapper;
	}

	set feesTestSetter(v : SchedulingApiShiftModelCourseTariffFees) {
        this.setterImpl(4, v.rawData, 'fees', true, null, function(this : SchedulingApiShiftModelCourseTariff) {this.feesWrapper = v;});
	}

	/**
	 * Does this tariff require an additional field? If so, then "additionalFieldLabel" is required.
	 *
	 * @type {boolean}
	 */
	get hasAdditionalField() : boolean {
		this.getterDebugValidations(this.aiHasAdditionalField, false);
		return this.data[5];
	}

	set hasAdditionalField(v : boolean) {
        this.setterImpl(5, v, 'hasAdditionalField', false, function(this : SchedulingApiShiftModelCourseTariff) {if (!v) {
					this.additionalFieldLabel = null;
					}}, null);
	}

	/**
	 * If this contains an empty string then this tariff does not require an additional field. Otherwise this contains the label of the additional field.
	 *
	 * @type {string}
	 */
	get additionalFieldLabel() : string | null {
		this.getterDebugValidations(this.aiAdditionalFieldLabel, false);
		return this.data[6];
	}

	set additionalFieldLabel(v : string | null) {
        this.setterImpl(6, v, 'additionalFieldLabel', false, null, null);
	}

	/**
	 * This tariff should be used for course dates from this given date-time. Relevant is start of the first course-shift. Optional value.
	 *
	 * @type {Date}
	 */
	get forCourseDatesFrom() : Date | null {
		this.getterDebugValidations(this.aiForCourseDatesFrom, false);
		return this.data[7];
	}

	set forCourseDatesFrom(v : Date | null) {
        this.setterImpl(7, v, 'forCourseDatesFrom', false, null, null);
	}

	/**
	 * This tariff should be used for course dates until this given date-time. Relevant is start of the first course-shift. Optional value.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get forCourseDatesUntil() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiForCourseDatesUntil, false);
		return this.data[8];
	}

	set forCourseDatesUntil(v : DateExclusiveEnd | null) {
        this.setterImpl(8, v, 'forCourseDatesUntil', false, null, null);
	}

	/**
	 * Normally "forCourseDatesFrom" and "forCourseDatesUntil" define an interval for which this tariff should be used. When this is "true" then they define an interval for which this tariff should NOT be used.
	 *
	 * @type {boolean}
	 */
	get negateForCourseDatesInterval() : boolean {
		this.getterDebugValidations(this.aiNegateForCourseDatesInterval, false);
		return this.data[9];
	}

	set negateForCourseDatesInterval(v : boolean) {
        this.setterImpl(9, v, 'negateForCourseDatesInterval', false, null, null);
	}

	/**
	 * Is the tariff trashed?
	 *
	 * @type {boolean}
	 */
	get trashed() : boolean {
		this.getterDebugValidations(this.aiTrashed, false);
		return this.data[10];
	}

	set trashed(v : boolean) {
        this.setterImpl(10, v, 'trashed', false, null, null);
	}

	private applyToBookingWrapper : Id | null = null!;

	/**
	 * Are changes to this tariff being done for a an existing booking? Then the tariff will only be changed for this booking and not globally.
	 *
	 * @type {Id}
	 */
	get applyToBooking() : Id | null {
		this.getterDebugValidations(this.aiApplyToBooking, false);
		return this.applyToBookingWrapper;
	}

	set applyToBooking(v : Id | null) {
        this.setterImpl(11, v, 'applyToBooking', false, null, function(this : SchedulingApiShiftModelCourseTariff) {this.applyToBookingWrapper = v;});
	}

	private applyToParticipantWrapper : Id | null = null!;

	/**
	 * Are changes to this tariff being done for a an existing participant? Then the tariff will only be changed for this participant and not globally.
	 *
	 * @type {Id}
	 */
	get applyToParticipant() : Id | null {
		this.getterDebugValidations(this.aiApplyToParticipant, false);
		return this.applyToParticipantWrapper;
	}

	set applyToParticipant(v : Id | null) {
        this.setterImpl(12, v, 'applyToParticipant', false, null, function(this : SchedulingApiShiftModelCourseTariff) {this.applyToParticipantWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.feesWrapper._fixIds(_idReplacements);
		this.data[11] = Meta.getReplacedId(this.data[11], _idReplacements);
		this.applyToBookingWrapper = this.data[11] === null ? null! : Id.create(this.data[11]);
		this.data[12] = Meta.getReplacedId(this.data[12], _idReplacements);
		this.applyToParticipantWrapper = this.data[12] === null ? null! : Id.create(this.data[12]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_COURSE_TARIFF should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.feesWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[11] : null, this.applyToBookingWrapper))
			this.applyToBookingWrapper = data && data[11] ? Id.create(data[11]) : null!;
		if(!Meta.isSameId(data ? data[12] : null, this.applyToParticipantWrapper))
			this.applyToParticipantWrapper = data && data[12] ? Id.create(data[12]) : null!;
	}

	protected get dni() : string {
		return '346';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '346', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelCourseTariffFeesBase extends ApiListWrapper<SchedulingApiShiftModelCourseTariffFee>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCourseTariff | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'fees');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelCourseTariffFee {
		return new SchedulingApiShiftModelCourseTariffFee(this.api, this as unknown as SchedulingApiShiftModelCourseTariffFees, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModelCourseTariff | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelCourseTariffFees(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '350';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelCourseTariffFee) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelCourseTariffFee {
		const newItem = new SchedulingApiShiftModelCourseTariffFee(this.api, this as unknown as SchedulingApiShiftModelCourseTariffFees, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('fees');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFees, SchedulingApiShiftModelCourseTariffFees> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFees, SchedulingApiShiftModelCourseTariffFees>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariffFees,
			name: 'fees',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FEES',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiShiftModelCourseTariffFees) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariffFees) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariffFees, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 4,
		});
}

				 
export class SchedulingApiShiftModelCourseTariffFee extends ApiObjectWrapper<SchedulingApiShiftModelCourseTariffFee>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCourseTariffFees | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelCourseTariffFee> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelCourseTariffFee as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, SchedulingApiShiftModelCourseTariffFee> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, SchedulingApiShiftModelCourseTariffFee>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariffFee,
			name: 'shiftModelCourseTariffFee',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FEE',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariffFee, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, string> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariffFee,
			name: 'name',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FEE_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
					() => {
						return this.api.validators.maxLength(50, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiFee : ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, ClientCurrency> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariffFee,
			name: 'fee',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FEE_FEE',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiPerXParticipants : ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, Integer> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariffFee,
			name: 'perXParticipants',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FEE_PER_X_PARTICIPANTS',
			type: PApiType.Integer,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelCourseTariffFee) {
				{const conditionValue = this.parent?.parent?.parent?.parent?.onlyWholeCourseBookable === true; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
		if(((this.parent?.parent?.parent?.parent?.onlyWholeCourseBookable === false)))
		{
			return this.api.validators.max(1, true, PApiType.Integer, undefined, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariffFee, _nodeId : string) {return 1;},
			rawDataIndex: 3,
		});
	aiVatPercent : ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, Percent> = new ApiAttributeInfo<SchedulingApiShiftModelCourseTariffFee, Percent>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseTariffFee,
			name: 'vatPercent',
			nodeName: 'SHIFT_MODEL_COURSE_TARIFF_FEE_VAT_PERCENT',
			type: PApiType.Percent,
			validations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
					() => {
return this.api.validators.required(PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseTariffFee) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCourseTariffFee, _nodeId : string) {return this.api.isLoaded() ? Math.max(...this.api.data.possibleVatPercents.iterable()) : 0;},
			rawDataIndex: 4,
		});

	/**
	 * Fee Name
	 *
	 * @type {string}
	 */
	get name() : string | null {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string | null) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * Gross amount to pay
	 *
	 * @type {ClientCurrency}
	 */
	get fee() : ClientCurrency {
		this.getterDebugValidations(this.aiFee, false);
		return this.data[2];
	}

	set fee(v : ClientCurrency) {
        this.setterImpl(2, v, 'fee', false, null, null);
	}

	/**
	 * Amount has to be paid per how many participants?
	 *
	 * @type {Integer}
	 */
	get perXParticipants() : Integer {
		this.getterDebugValidations(this.aiPerXParticipants, false);
		return this.data[3];
	}

	set perXParticipants(v : Integer) {
        this.setterImpl(3, v, 'perXParticipants', false, null, null);
	}

	/**
	 * VAT percent.
	 *
	 * @type {Percent}
	 */
	get vatPercent() : Percent {
		this.getterDebugValidations(this.aiVatPercent, false);
		return this.data[4];
	}

	set vatPercent(v : Percent) {
        this.setterImpl(4, v, 'vatPercent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_COURSE_TARIFF_FEE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '359';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '359', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelCoursePaymentMethodsBase extends ApiListWrapper<SchedulingApiShiftModelCoursePaymentMethod>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'coursePaymentMethods');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelCoursePaymentMethod {
		return new SchedulingApiShiftModelCoursePaymentMethod(this.api, this as unknown as SchedulingApiShiftModelCoursePaymentMethods, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelCoursePaymentMethods(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '268';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelCoursePaymentMethod) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelCoursePaymentMethod {
		const newItem = new SchedulingApiShiftModelCoursePaymentMethod(this.api, this as unknown as SchedulingApiShiftModelCoursePaymentMethods, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('coursePaymentMethods');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethods, SchedulingApiShiftModelCoursePaymentMethods> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethods, SchedulingApiShiftModelCoursePaymentMethods>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethods,
			name: 'coursePaymentMethods',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHODS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiShiftModelCoursePaymentMethods) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelCoursePaymentMethods) {
				{const conditionValue = this.parent!.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelCoursePaymentMethods) {
				return [
					() => { return this.minPaymentMethods() },
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCoursePaymentMethods) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCoursePaymentMethods, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 17,
		});
}

				 
export class SchedulingApiShiftModelCoursePaymentMethodBase extends ApiObjectWrapper<SchedulingApiShiftModelCoursePaymentMethod>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCoursePaymentMethods | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelCoursePaymentMethod> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelCoursePaymentMethod as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, SchedulingApiShiftModelCoursePaymentMethod> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, SchedulingApiShiftModelCoursePaymentMethod>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
			name: 'shiftModelCoursePaymentMethod',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHOD',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelCoursePaymentMethod, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiType : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, SchedulingApiPaymentMethodType> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, SchedulingApiPaymentMethodType>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
			name: 'type',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHOD_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
					() => { return this.checkNoRepeatedOnlinePaymentMethod() },
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
				];
			}
			,
			rawDataIndex: 1,
			attributeValueInfos: new Map([
				[
					SchedulingApiPaymentMethodType.ONLINE_PAYMENT,
					new ApiAttributeValueInfo<SchedulingApiShiftModelCoursePaymentMethod>({
						apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
						canSetByBusinessLogic: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				{const conditionValue = (
								this.type === SchedulingApiPaymentMethodType.ONLINE_PAYMENT ||
								!this.parentHasExistingOnlinePaymentMethod
								); if(!conditionValue) return conditionValue === false ? {sourceString: 'Für diese Tätigkeit gibt es schon eine Zahlungsart von Typ »Online-Zahlung«.', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiPaymentMethodType.MISC,
					new ApiAttributeValueInfo<SchedulingApiShiftModelCoursePaymentMethod>({
						apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
					}),
				],
			]),
		});
	aiName : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, string> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
			name: 'name',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHOD_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => { return this.checkNoDuplicatePaymentMethodNames() },
					() => {
						return this.api.validators.maxLength(50, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, string> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
			name: 'description',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHOD_DESCRIPTION',
			type: PApiType.string,
			rawDataIndex: 3,
		});
	aiIsInternal : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
			name: 'isInternal',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHOD_IS_INTERNAL',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCoursePaymentMethod, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiTrashed : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
			name: 'trashed',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHOD_TRASHED',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCoursePaymentMethod) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCoursePaymentMethod, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});
	aiApplyToBooking : ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, Id> = new ApiAttributeInfo<SchedulingApiShiftModelCoursePaymentMethod, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCoursePaymentMethod,
			name: 'applyToBooking',
			nodeName: 'SHIFT_MODEL_COURSE_PAYMENT_METHOD_APPLY_TO_BOOKING',
			type: PApiType.Id,
			rawDataIndex: 6,
		});

	/**
	 * Payment method type
	 *
	 * @type {SchedulingApiPaymentMethodType}
	 */
	get type() : SchedulingApiPaymentMethodType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiPaymentMethodType) {
        this.setterImpl(1, v, 'type', false, null, function(this : SchedulingApiShiftModelCoursePaymentMethod) {const ONLINE_PAYMENT_NAME = this.api.localizePipe.transform('Online-Zahlung');
					switch(this.type) {
					 	case SchedulingApiPaymentMethodType.ONLINE_PAYMENT:
							this.aiName.value = ONLINE_PAYMENT_NAME;
							this.description = null;
							break;

						case SchedulingApiPaymentMethodType.MISC:
							if (this.aiName.value === ONLINE_PAYMENT_NAME) {
								this.aiName.value = null;
								this.description = null;
							}
							break;
					}});
	}

	/**
	 * Payment method name
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[2];
	}

	set name(v : string) {
        this.setterImpl(2, v, 'name', false, null, null);
	}

	/**
	 * Payment method description.
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[3];
	}

	set description(v : string | null) {
        this.setterImpl(3, v, 'description', false, null, null);
	}

	/**
	 * Is the payment-method internal? I.e. only usable during manual booking?
	 *
	 * @type {boolean}
	 */
	get isInternal() : boolean {
		this.getterDebugValidations(this.aiIsInternal, false);
		return this.data[4];
	}

	set isInternal(v : boolean) {
        this.setterImpl(4, v, 'isInternal', false, null, null);
	}

	/**
	 * Is the payment method trashed?
	 *
	 * @type {boolean}
	 */
	get trashed() : boolean {
		this.getterDebugValidations(this.aiTrashed, false);
		return this.data[5];
	}

	set trashed(v : boolean) {
        this.setterImpl(5, v, 'trashed', false, null, null);
	}

	private applyToBookingWrapper : Id | null = null!;

	/**
	 * Are changes to this payment-method being done for a an existing booking? If so, it will only be modified for this booking and not globally.
	 *
	 * @type {Id}
	 */
	get applyToBooking() : Id | null {
		this.getterDebugValidations(this.aiApplyToBooking, false);
		return this.applyToBookingWrapper;
	}

	set applyToBooking(v : Id | null) {
        this.setterImpl(6, v, 'applyToBooking', false, null, function(this : SchedulingApiShiftModelCoursePaymentMethod) {this.applyToBookingWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[6] = Meta.getReplacedId(this.data[6], _idReplacements);
		this.applyToBookingWrapper = this.data[6] === null ? null! : Id.create(this.data[6]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_COURSE_PAYMENT_METHOD should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[6] : null, this.applyToBookingWrapper))
			this.applyToBookingWrapper = data && data[6] ? Id.create(data[6]) : null!;
	}

	protected get dni() : string {
		return '364';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '364', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiPaymentMethodType {
	ONLINE_PAYMENT = 1,
	MISC = 2,
}
		 export class SchedulingApiShiftModelCancellationPolicies extends ApiListWrapper<SchedulingApiShiftModelCancellationPolicy>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'cancellationPolicies');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelCancellationPolicy {
		return new SchedulingApiShiftModelCancellationPolicy(this.api, this as unknown as SchedulingApiShiftModelCancellationPolicies, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelCancellationPolicies(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '271';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelCancellationPolicy) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelCancellationPolicy {
		const newItem = new SchedulingApiShiftModelCancellationPolicy(this.api, this as unknown as SchedulingApiShiftModelCancellationPolicies, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('cancellationPolicies');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicies, SchedulingApiShiftModelCancellationPolicies> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicies, SchedulingApiShiftModelCancellationPolicies>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicies,
			name: 'cancellationPolicies',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICIES',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiShiftModelCancellationPolicies) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelCancellationPolicies) {
				{const conditionValue = this.parent!.isCourse; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelCancellationPolicies) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCancellationPolicies) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCancellationPolicies, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 20,
		});
}

				 
export class SchedulingApiShiftModelCancellationPolicy extends ApiObjectWrapper<SchedulingApiShiftModelCancellationPolicy>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCancellationPolicies | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelCancellationPolicy> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelCancellationPolicy as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicy, SchedulingApiShiftModelCancellationPolicy> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicy, SchedulingApiShiftModelCancellationPolicy>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicy,
			name: 'shiftModelCancellationPolicy',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelCancellationPolicy, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			
		});
	aiWithdrawalEnabled : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicy, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicy, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicy,
			name: 'withdrawalEnabled',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_WITHDRAWAL_ENABLED',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftModelCancellationPolicy) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCancellationPolicy) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCancellationPolicy, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	aiWithdrawalPeriod : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicy, Days> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicy, Days>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicy,
			name: 'withdrawalPeriod',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_WITHDRAWAL_PERIOD',
			type: PApiType.Days,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelCancellationPolicy) {
				return ((this.withdrawalEnabled));
			},
			validations: function(this : SchedulingApiShiftModelCancellationPolicy) {
				return [
					() => {
		if(((this.withdrawalEnabled)))
		{
			return this.api.validators.required(PApiType.Days, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(0, false, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCancellationPolicy) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});

	private feePeriodsWrapper : SchedulingApiShiftModelCancellationPolicyFeePeriods = new SchedulingApiShiftModelCancellationPolicyFeePeriods(this.api,
		this as unknown as SchedulingApiShiftModelCancellationPolicy, false, false);
	public aiFeePeriods = this.feePeriodsWrapper.aiThis;

	/**
	 * A list of all fee periods. The items of this list are sorted in a chronological direction where each period starts at "feePeriod.start" and ends at the next period's "start". The first period in the list must have "start" set to "null" which means that it starts from "infinite". The last period in the list is valid to infinite. So, for any given date-time a specific period can be associated.
	 */
	get feePeriods() : SchedulingApiShiftModelCancellationPolicyFeePeriods {
		this.getterDebugValidations(this.aiFeePeriods, false);
		return this.feePeriodsWrapper;
	}

	set feePeriodsTestSetter(v : SchedulingApiShiftModelCancellationPolicyFeePeriods) {
        this.setterImpl(1, v.rawData, 'feePeriods', true, null, function(this : SchedulingApiShiftModelCancellationPolicy) {this.feePeriodsWrapper = v;});
	}

	/**
	 * Is booking withdrawal allowed? See "withdrawalPeriod".
	 *
	 * @type {boolean}
	 */
	get withdrawalEnabled() : boolean {
		this.getterDebugValidations(this.aiWithdrawalEnabled, false);
		return this.data[2];
	}

	set withdrawalEnabled(v : boolean) {
        this.setterImpl(2, v, 'withdrawalEnabled', false, null, function(this : SchedulingApiShiftModelCancellationPolicy) {this.data[this.api.consts.SHIFT_MODEL_CANCELLATION_POLICY_WITHDRAWAL_PERIOD] = null;});
	}

	/**
	 * Defines the number of days after the booking date in which the booking person has the permission of withdrawal. I.e. he can cancel the booking without paying any cancellation fees. Only relevant when "withdrawalPeriod" is "true".
	 *
	 * @type {Days}
	 */
	get withdrawalPeriod() : Days | null {
		this.getterDebugValidations(this.aiWithdrawalPeriod, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiWithdrawalPeriod.defaultValue;
			
		}
		return this.data[3];
	}

	set withdrawalPeriod(v : Days | null) {
        this.setterImpl(3, v, 'withdrawalPeriod', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.feePeriodsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_CANCELLATION_POLICY should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.feePeriodsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '372';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '372', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelCancellationPolicyFeePeriodsBase extends ApiListWrapper<SchedulingApiShiftModelCancellationPolicyFeePeriod>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCancellationPolicy | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'feePeriods');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelCancellationPolicyFeePeriod {
		return new SchedulingApiShiftModelCancellationPolicyFeePeriod(this.api, this as unknown as SchedulingApiShiftModelCancellationPolicyFeePeriods, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModelCancellationPolicy | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelCancellationPolicyFeePeriods(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '373';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelCancellationPolicyFeePeriod) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelCancellationPolicyFeePeriod {
		const newItem = new SchedulingApiShiftModelCancellationPolicyFeePeriod(this.api, this as unknown as SchedulingApiShiftModelCancellationPolicyFeePeriods, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('feePeriods');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriods, SchedulingApiShiftModelCancellationPolicyFeePeriods> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriods, SchedulingApiShiftModelCancellationPolicyFeePeriods>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicyFeePeriods,
			name: 'feePeriods',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIODS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriods) {
				return [
					() => {
		if(((this.parent!.parent?.parent!.onlineCancellationForChargeableBookingsEnabled)))
		{
			return this.api.validators.required(PApiType.ApiList, 'Du hast die Funktion aktiviert, dass deine Kunden kostenpflichtige Buchungen selbst online stornieren dürfen. Voraussetzung dafür ist, dass du hier deine Stornogebühren angibst.');
		}
						return null;
					},
					() => { return this.checkFirstPeriodShouldHaveNullStart() },
					() => {
						return this.api.validators.maxLength(10, PApiType.ApiList, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriods) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriods, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
}

				 
export class SchedulingApiShiftModelCancellationPolicyFeePeriodBase extends ApiObjectWrapper<SchedulingApiShiftModelCancellationPolicyFeePeriod>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCancellationPolicyFeePeriods | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelCancellationPolicyFeePeriod> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelCancellationPolicyFeePeriod as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, SchedulingApiShiftModelCancellationPolicyFeePeriod> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, SchedulingApiShiftModelCancellationPolicyFeePeriod>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicyFeePeriod,
			name: 'shiftModelCancellationPolicyFeePeriod',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiFeeFix : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, ClientCurrency> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicyFeePeriod,
			name: 'feeFix',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_FEE_FIX',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiFeePercentage : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, number> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, number>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicyFeePeriod,
			name: 'feePercentage',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_FEE_PERCENTAGE',
			type: PApiType.number,
			validations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod) {
				return [
					() => {
return this.api.validators.required(PApiType.number, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(100, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiStart : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, Days> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, Days>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicyFeePeriod,
			name: 'start',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_START',
			type: PApiType.Days,
			validations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod) {
				return [
					() => {
return this.api.validators.min(0, true, PApiType.Days, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.min(() => this.end, true, PApiType.Days, this.aiEnd.id, undefined);						return null;
					},
					() => {
						return this.api.validators.notUndefined(PApiType.Days, undefined);
					},
					() => {
		if(((this.parent!.indexOf(this)! > 0)))
		{
			return this.api.validators.required(PApiType.Days, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, Days> = new ApiAttributeInfo<SchedulingApiShiftModelCancellationPolicyFeePeriod, Days>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCancellationPolicyFeePeriod,
			name: 'end',
			nodeName: 'SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD_END',
			type: PApiType.Days,
			hasPermissionToSet: function(this : SchedulingApiShiftModelCancellationPolicyFeePeriod) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: this.end === null ? 'Das Ende der ersten Zeitspanne muss so definiert sein, um Lücken zu vermeiden und sicherzustellen, dass auch Stornos nach dem gebuchten Termin abgedeckt sind. Bei mehrtägigen Angeboten gilt immer der Starttermin als Referenz.' :     'Das Ende errechnet sich automatisch anhand des Startzeitpunktes der vorherigen Zeitspanne. So werden Lücken zwischen den Zeiträumen vermieden.', params: false} : undefined;}
				return true;
			},
			rawDataIndex: 4,
		});

	/**
	 * The fix fee of a booking cancellation.
	 *
	 * @type {ClientCurrency}
	 */
	get feeFix() : ClientCurrency {
		this.getterDebugValidations(this.aiFeeFix, false);
		return this.data[1];
	}

	set feeFix(v : ClientCurrency) {
        this.setterImpl(1, v, 'feeFix', false, null, null);
	}

	/**
	 * The percentage fee of a booking cancellation.
	 *
	 * @type {number}
	 */
	get feePercentage() : number {
		this.getterDebugValidations(this.aiFeePercentage, false);
		return this.data[2];
	}

	set feePercentage(v : number) {
        this.setterImpl(2, v, 'feePercentage', false, null, null);
	}

	/**
	 * From when is this period valid? The number of days is relative to the beginning of the day when the course starts. The first item in the list must always have this set to "null". See "cancellationPeriod.feePeriods" for more details.
	 *
	 * @type {Days}
	 */
	get start() : Days | null {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[3];
	}

	set start(v : Days | null) {
        this.setterImpl(3, v, 'start', false, null, null);
	}

	/**
	 * Until when is this period valid? The number of days is relative to the beginning of the day when the course starts. This value is automatically calculated based on the "start" of the next fee period. See "cancellationPeriod.feePeriods" for more details.
	 *
	 * @type {Days}
	 */
	get end() : Days | null {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[4];
	}

	set end(v : Days | null) {
        this.setterImpl(4, v, 'end', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_CANCELLATION_POLICY_FEE_PERIOD should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '376';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '376', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiBookingDesiredDateSetting {
	DESIRED_DATE_NOT_ALLOWED = 1,
	DESIRED_DATE_OPTIONAL = 2,
	ONLY_DESIRED_DATES = 3,
}
		 
export class SchedulingApiShiftModelNeededMembersCountConf extends ApiObjectWrapper<SchedulingApiShiftModelNeededMembersCountConf>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelNeededMembersCountConf> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelNeededMembersCountConf as any);

		this._initObjectWrapper(params, true);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, SchedulingApiShiftModelNeededMembersCountConf> = new ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, SchedulingApiShiftModelNeededMembersCountConf>({
			apiObjWrapper: this as any as SchedulingApiShiftModelNeededMembersCountConf,
			name: 'neededMembersCountConf',
			nodeName: 'SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF',
			type: PApiType.ApiObject,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModelNeededMembersCountConf) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelNeededMembersCountConf, _nodeId : string) {return Meta.createNewObject(true, undefined, _nodeId);},
			rawDataIndex: 30,
		});
	aiNeededMembersCount : ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, Integer> = new ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModelNeededMembersCountConf,
			name: 'neededMembersCount',
			nodeName: 'SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF_NEEDED_MEMBERS_COUNT',
			type: PApiType.Integer,
			validations: function(this : SchedulingApiShiftModelNeededMembersCountConf) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelNeededMembersCountConf) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiPerXParticipants : ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, Integer> = new ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftModelNeededMembersCountConf,
			name: 'perXParticipants',
			nodeName: 'SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF_PER_X_PARTICIPANTS',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftModelNeededMembersCountConf) {
				return ((this.parent?.aiIsCourse.isAvailable && this.parent.isCourse));
			},
			validations: function(this : SchedulingApiShiftModelNeededMembersCountConf) {
				return [
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelNeededMembersCountConf) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiIsZeroNotReachedMinParticipantsCount : ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelNeededMembersCountConf, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelNeededMembersCountConf,
			name: 'isZeroNotReachedMinParticipantsCount',
			nodeName: 'SHIFT_MODEL_NEEDED_MEMBERS_COUNT_CONF_IS_ZERO_NOT_REACHED_MIN_PARTICIPANTS_COUNT',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiShiftModelNeededMembersCountConf, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});

	/**
	 * See perXParticipants for documentation.
	 *
	 * @type {Integer}
	 */
	get neededMembersCount() : Integer {
		this.getterDebugValidations(this.aiNeededMembersCount, false);
		return this.data[1];
	}

	set neededMembersCount(v : Integer) {
        this.setterImpl(1, v, 'neededMembersCount', false, null, null);
	}

	/**
	 * If this value is "null" then the final needed-members-count will be just "neededMembersCount". Otherwise it will be "neededMembersCount * ceil(participant-count / perXParticipants)". The calculated needed-members-count can be retrieved in shift.neededMembersCount.
	 *
	 * @type {Integer}
	 */
	get perXParticipants() : Integer | null {
		this.getterDebugValidations(this.aiPerXParticipants, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiPerXParticipants.defaultValue;
			
		}
		return this.data[2];
	}

	set perXParticipants(v : Integer | null) {
        this.setterImpl(2, v, 'perXParticipants', false, null, null);
	}

	/**
	 * If "true" then needed-members-count will be zero when participants count has not reached min-participants-count.
	 *
	 * @type {boolean}
	 */
	get isZeroNotReachedMinParticipantsCount() : boolean {
		this.getterDebugValidations(this.aiIsZeroNotReachedMinParticipantsCount, false);
		return this.data[3];
	}

	set isZeroNotReachedMinParticipantsCount(v : boolean) {
        this.setterImpl(3, v, 'isZeroNotReachedMinParticipantsCount', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '281';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '281', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiShiftModelAssignedMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignedMemberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelAssignedMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '283';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelAssignedMemberIds, SchedulingApiShiftModelAssignedMemberIds> = new ApiAttributeInfo<SchedulingApiShiftModelAssignedMemberIds, SchedulingApiShiftModelAssignedMemberIds>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAssignedMemberIds,
			name: 'assignedMemberIds',
			nodeName: 'SHIFT_MODEL_ASSIGNED_MEMBER_IDS',
			type: PApiType.ApiList,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModelAssignedMemberIds) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelAssignedMemberIds) {
				{const conditionValue = this.parent!.assignableMembersAvailableForAssignment; if(!conditionValue) return conditionValue === false ? {sourceString: 'Es wurden noch keine Personen eingetragen, die »${name}« machen dürfen.', params: {name: this.parent!.name}} : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelAssignedMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 32,
		});
	aiShiftModelAssignedMemberId : ApiAttributeInfo<SchedulingApiShiftModelAssignedMemberIds, Id> = new ApiAttributeInfo<SchedulingApiShiftModelAssignedMemberIds, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModelAssignedMemberIds,
			name: 'shiftModelAssignedMemberId',
			nodeName: 'SHIFT_MODEL_ASSIGNED_MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 export class SchedulingApiShiftModelCourseHighlights extends ApiListWrapper<SchedulingApiShiftModelCourseHighlight>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'courseHighlights');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftModelCourseHighlight {
		return new SchedulingApiShiftModelCourseHighlight(this.api, this as unknown as SchedulingApiShiftModelCourseHighlights, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiShiftModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiShiftModelCourseHighlights(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '287';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftModelCourseHighlight) => void) | null = null, _backendId : Id | null = null) : SchedulingApiShiftModelCourseHighlight {
		const newItem = new SchedulingApiShiftModelCourseHighlight(this.api, this as unknown as SchedulingApiShiftModelCourseHighlights, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('courseHighlights');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCourseHighlights, SchedulingApiShiftModelCourseHighlights> = new ApiAttributeInfo<SchedulingApiShiftModelCourseHighlights, SchedulingApiShiftModelCourseHighlights>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseHighlights,
			name: 'courseHighlights',
			nodeName: 'SHIFT_MODEL_COURSE_HIGHLIGHTS',
			type: PApiType.ApiList,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModelCourseHighlights) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelCourseHighlights, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 36,
		});
}

				 
export class SchedulingApiShiftModelCourseHighlight extends ApiObjectWrapper<SchedulingApiShiftModelCourseHighlight>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModelCourseHighlights | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelCourseHighlight> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelCourseHighlight as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelCourseHighlight, SchedulingApiShiftModelCourseHighlight> = new ApiAttributeInfo<SchedulingApiShiftModelCourseHighlight, SchedulingApiShiftModelCourseHighlight>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseHighlight,
			name: 'shiftModelCourseHighlight',
			nodeName: 'SHIFT_MODEL_COURSE_HIGHLIGHT',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftModelCourseHighlight, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiText : ApiAttributeInfo<SchedulingApiShiftModelCourseHighlight, string> = new ApiAttributeInfo<SchedulingApiShiftModelCourseHighlight, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelCourseHighlight,
			name: 'text',
			nodeName: 'SHIFT_MODEL_COURSE_HIGHLIGHT_TEXT',
			type: PApiType.string,
			validations: function(this : SchedulingApiShiftModelCourseHighlight) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelCourseHighlight) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});

	/**
	 * Highlight text
	 *
	 * @type {string}
	 */
	get text() : string {
		this.getterDebugValidations(this.aiText, false);
		return this.data[1];
	}

	set text(v : string) {
        this.setterImpl(1, v, 'text', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_COURSE_HIGHLIGHT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '344';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '344', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiShiftModelMarketingGiftCardSettings extends ApiObjectWrapper<SchedulingApiShiftModelMarketingGiftCardSettings>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiShiftModel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftModelMarketingGiftCardSettings> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftModelMarketingGiftCardSettings as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, SchedulingApiShiftModelMarketingGiftCardSettings> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, SchedulingApiShiftModelMarketingGiftCardSettings>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'marketingGiftCardSettings',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS',
			type: PApiType.ApiObject,
			isDetailedAttribute: true,
			hasPermissionToSet: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.parent!); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiShiftModelMarketingGiftCardSettings, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 56,
		});
	aiActivated : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, boolean> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'activated',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_ACTIVATED',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = this.api.data.giftCardSettings.isSaleEnabled; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um Marketing-Gutscheine versenden zu können, aktiviere den Gutschein-Verkauf', params: false} : undefined;}
				{const conditionValue = this.api.data.giftCardSettings.waysToRedeem === SchedulingApiGiftCardSettingsWaysToRedeem.ONLINE_AND_POS ||
						this.api.data.giftCardSettings.waysToRedeem === SchedulingApiGiftCardSettingsWaysToRedeem.ONLINE; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um Marketing-Gutscheine versenden zu können, musst du erst die Online-Einlösbarkeit deiner Gutscheine aktivieren, da Marketing-Gutscheine nur online über das Homepage-Plugin von Dr. Plano eingelöst werden können. Ändere jetzt die Einstellung', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelMarketingGiftCardSettings, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiActivatedByMemberId : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, Id> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'activatedByMemberId',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_ACTIVATED_BY_MEMBER_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiActivatedDateTime : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, DateTime> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, DateTime>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'activatedDateTime',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_ACTIVATED_DATE_TIME',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiInitialBalance : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, ClientCurrency> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'initialBalance',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_INITIAL_BALANCE',
			type: PApiType.ClientCurrency,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = this.activated; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(200, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelMarketingGiftCardSettings, _nodeId : string) {return 5;},
			rawDataIndex: 4,
		});
	aiMinShoppingCardValue : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, ClientCurrency> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'minShoppingCardValue',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_MIN_SHOPPING_CARD_VALUE',
			type: PApiType.ClientCurrency,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = this.activated; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelMarketingGiftCardSettings, _nodeId : string) {return 50;},
			rawDataIndex: 5,
		});
	aiExpirationDuration : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, Months> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, Months>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'expirationDuration',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_EXPIRATION_DURATION',
			type: PApiType.Months,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = this.activated; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.Months, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Months, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(24, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelMarketingGiftCardSettings, _nodeId : string) {return 3;},
			rawDataIndex: 6,
		});
	aiRecipient : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, SchedulingApiMarketingGiftCardSettingsRecipient> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, SchedulingApiMarketingGiftCardSettingsRecipient>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'recipient',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_RECIPIENT',
			type: PApiType.Enum,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = this.activated; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftModelMarketingGiftCardSettings, _nodeId : string) {return SchedulingApiMarketingGiftCardSettingsRecipient.BOOKING_PERSON;},
			rawDataIndex: 7,
			attributeValueInfos: new Map([
				[
					SchedulingApiMarketingGiftCardSettingsRecipient.BOOKING_PARTICIPANTS,
					new ApiAttributeValueInfo<SchedulingApiShiftModelMarketingGiftCardSettings>({
						apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
						canSetByBusinessLogic: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = !this.parent!.onlyWholeCourseBookable; if(!conditionValue) return conditionValue === false ? {sourceString: 'Entsprechend der Platzbuchungs-Einstellung dieser Tätigkeit buchen Kunden Termine dieses Angebots komplett und keine einzelnen Plätze. Bei solchen Buchungen wird nur die Email-Adresse der buchenden Person, aber keine Email-Adressen der teilnehmenden Personen erfasst. Daher können keine Marketing-Gutscheine an teilnehmende Personen verschickt werden.', params: false} : undefined;}
				return true;
						},
					}),
				],
			]),
		});
	aiBookedEmailCustomMessage : ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, string> = new ApiAttributeInfo<SchedulingApiShiftModelMarketingGiftCardSettings, string>({
			apiObjWrapper: this as any as SchedulingApiShiftModelMarketingGiftCardSettings,
			name: 'bookedEmailCustomMessage',
			nodeName: 'SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS_BOOKED_EMAIL_CUSTOM_MESSAGE',
			type: PApiType.string,
			canSetByBusinessLogic: function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {
				{const conditionValue = this.activated; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 8,
		});

	/**
	 * Should marketing gift-cards be sent for this shift-model?
	 *
	 * @type {boolean}
	 */
	get activated() : boolean {
		this.getterDebugValidations(this.aiActivated, false);
		return this.data[1];
	}

	set activated(v : boolean) {
        this.setterImpl(1, v, 'activated', false, null, null);
	}

	private activatedByMemberIdWrapper : Id | null = null!;

	/**
	 * The member-id who has activated marketing gift-cards for this shift-model.
	 *
	 * @type {Id}
	 */
	get activatedByMemberId() : Id | null {
		this.getterDebugValidations(this.aiActivatedByMemberId, false);
		return this.activatedByMemberIdWrapper;
	}

	set activatedByMemberIdTestSetter(v : Id | null) {
        this.setterImpl(2, v, 'activatedByMemberId', true, null, function(this : SchedulingApiShiftModelMarketingGiftCardSettings) {this.activatedByMemberIdWrapper = v;});
	}

	/**
	 * When has marketing gift-cards for this shift-model be activated?
	 *
	 * @type {DateTime}
	 */
	get activatedDateTime() : DateTime | null {
		this.getterDebugValidations(this.aiActivatedDateTime, false);
		return this.data[3];
	}

	set activatedDateTimeTestSetter(v : DateTime | null) {
        this.setterImpl(3, v, 'activatedDateTime', true, null, null);
	}

	/**
	 * The initial balance of the sent marketing gift-cards.
	 *
	 * @type {ClientCurrency}
	 */
	get initialBalance() : ClientCurrency {
		this.getterDebugValidations(this.aiInitialBalance, false);
		return this.data[4];
	}

	set initialBalance(v : ClientCurrency) {
        this.setterImpl(4, v, 'initialBalance', false, null, null);
	}

	/**
	 * The minimum shopping-card value when the sent marketing gift-cards can be redeemed. This should achieve that the marketing gift-cards produce relevant revenue.
	 *
	 * @type {ClientCurrency}
	 */
	get minShoppingCardValue() : ClientCurrency {
		this.getterDebugValidations(this.aiMinShoppingCardValue, false);
		return this.data[5];
	}

	set minShoppingCardValue(v : ClientCurrency) {
        this.setterImpl(5, v, 'minShoppingCardValue', false, null, null);
	}

	/**
	 * After how many months should marketing gift-cards, sent for this shift-model, expire?
	 *
	 * @type {Months}
	 */
	get expirationDuration() : Months {
		this.getterDebugValidations(this.aiExpirationDuration, false);
		return this.data[6];
	}

	set expirationDuration(v : Months) {
        this.setterImpl(6, v, 'expirationDuration', false, null, null);
	}

	/**
	 * To whom should the marketing gift-cards be sent?
	 *
	 * @type {SchedulingApiMarketingGiftCardSettingsRecipient}
	 */
	get recipient() : SchedulingApiMarketingGiftCardSettingsRecipient {
		this.getterDebugValidations(this.aiRecipient, false);
		return this.data[7];
	}

	set recipient(v : SchedulingApiMarketingGiftCardSettingsRecipient) {
        this.setterImpl(7, v, 'recipient', false, null, null);
	}

	/**
	 * Custom message being added to the BOOKED email of the marketing gift-card.
	 *
	 * @type {string}
	 */
	get bookedEmailCustomMessage() : string | null {
		this.getterDebugValidations(this.aiBookedEmailCustomMessage, false);
		return this.data[8];
	}

	set bookedEmailCustomMessage(v : string | null) {
        this.setterImpl(8, v, 'bookedEmailCustomMessage', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.activatedByMemberIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL_MARKETING_GIFT_CARD_SETTINGS should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[2] : null, this.activatedByMemberIdWrapper))
			this.activatedByMemberIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
	}

	protected get dni() : string {
		return '307';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '307', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiMarketingGiftCardSettingsRecipient {
	BOOKING_PERSON = 1,
	BOOKING_PARTICIPANTS = 2,
}
export enum SchedulingApiPosSystem {
	BOULDERADO = 1,
	FREECLIMBER = 2,
	BETA_7 = 3,
}
		 export class SchedulingApiPermissionGroupsBase extends ApiListWrapper<SchedulingApiPermissionGroup>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'permissionGroups');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiPermissionGroup {
		return new SchedulingApiPermissionGroup(this.api, this as unknown as SchedulingApiPermissionGroups, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiPermissionGroups(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '15';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiPermissionGroup) => void) | null = null, _backendId : Id | null = null) : SchedulingApiPermissionGroup {
		const newItem = new SchedulingApiPermissionGroup(this.api, this as unknown as SchedulingApiPermissionGroups, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('permissionGroups');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiPermissionGroups, SchedulingApiPermissionGroups> = new ApiAttributeInfo<SchedulingApiPermissionGroups, SchedulingApiPermissionGroups>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroups,
			name: 'permissionGroups',
			nodeName: 'PERMISSION_GROUPS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiPermissionGroups) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiPermissionGroups) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroups) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroups, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 14,
		});
}

				 
export class SchedulingApiPermissionGroupBase extends ApiObjectWrapper<SchedulingApiPermissionGroup>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiPermissionGroups | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiPermissionGroup> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiPermissionGroup as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiPermissionGroup, SchedulingApiPermissionGroup> = new ApiAttributeInfo<SchedulingApiPermissionGroup, SchedulingApiPermissionGroup>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroup,
			name: 'permissionGroup',
			nodeName: 'PERMISSION_GROUP',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiPermissionGroup, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiPermissionGroup, string> = new ApiAttributeInfo<SchedulingApiPermissionGroup, string>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroup,
			name: 'name',
			nodeName: 'PERMISSION_GROUP_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiPermissionGroup) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(50, PApiType.string, undefined);
					},
					() => { return this.checkPermissionGroupsWithSameName() },
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroup) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiRole : ApiAttributeInfo<SchedulingApiPermissionGroup, SchedulingApiPermissionGroupRole> = new ApiAttributeInfo<SchedulingApiPermissionGroup, SchedulingApiPermissionGroupRole>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroup,
			name: 'role',
			nodeName: 'PERMISSION_GROUP_ROLE',
			type: PApiType.Enum,
			hasPermissionToSet: function(this : SchedulingApiPermissionGroup) {
				{const conditionValue = true; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiPermissionGroup) {
				{const conditionValue = !this.isLastAdminPermissionGroup; if(!conditionValue) return conditionValue === false ? {sourceString: 'Du kannst den Typ aktuell nicht ändern, da diese Rechtegruppe die einzige des Typs »Admins« ist, der User zugewiesen sind. Sobald eine weitere Admin-Rechtegruppe mit zugewiesenen Usern vorhanden ist, kannst du deine gewünschte Änderung hier vornehmen.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiPermissionGroup) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroup) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiCanReadAndWriteBookingSystemSettings : ApiAttributeInfo<SchedulingApiPermissionGroup, boolean> = new ApiAttributeInfo<SchedulingApiPermissionGroup, boolean>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroup,
			name: 'canReadAndWriteBookingSystemSettings',
			nodeName: 'PERMISSION_GROUP_CAN_READ_AND_WRITE_BOOKING_SYSTEM_SETTINGS',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiPermissionGroup) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroup) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroup, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiPermissionGroup, string> = new ApiAttributeInfo<SchedulingApiPermissionGroup, string>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroup,
			name: 'description',
			nodeName: 'PERMISSION_GROUP_DESCRIPTION',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiPermissionGroup) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			validations: function(this : SchedulingApiPermissionGroup) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroup) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiDateOfCreation : ApiAttributeInfo<SchedulingApiPermissionGroup, DateTime> = new ApiAttributeInfo<SchedulingApiPermissionGroup, DateTime>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroup,
			name: 'dateOfCreation',
			nodeName: 'PERMISSION_GROUP_DATE_OF_CREATION',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiCreatorId : ApiAttributeInfo<SchedulingApiPermissionGroup, Id> = new ApiAttributeInfo<SchedulingApiPermissionGroup, Id>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroup,
			name: 'creatorId',
			nodeName: 'PERMISSION_GROUP_CREATOR_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});

	/**
	 * The name of the permission group
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * The role of this permission group.
	 *
	 * @type {SchedulingApiPermissionGroupRole}
	 */
	get role() : SchedulingApiPermissionGroupRole {
		this.getterDebugValidations(this.aiRole, false);
		return this.data[2];
	}

	set role(v : SchedulingApiPermissionGroupRole) {
        this.setterImpl(2, v, 'role', false, null, null);
	}

	/**
	 * Can this member read and write booking system settings? For role == "CLIENT_OWNER" this value be always "true".
	 *
	 * @type {boolean}
	 */
	get canReadAndWriteBookingSystemSettings() : boolean {
		this.getterDebugValidations(this.aiCanReadAndWriteBookingSystemSettings, false);
		return this.data[3];
	}

	set canReadAndWriteBookingSystemSettings(v : boolean) {
        this.setterImpl(3, v, 'canReadAndWriteBookingSystemSettings', false, null, null);
	}

	/**
	 * The optional description for this permission-group.
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[4];
	}

	set description(v : string | null) {
        this.setterImpl(4, v, 'description', false, null, null);
	}

	private shiftModelPermissionsWrapper : SchedulingApiPermissionGroupShiftModelPermissions = new SchedulingApiPermissionGroupShiftModelPermissions(this.api,
		this as unknown as SchedulingApiPermissionGroup, false, false);
	public aiShiftModelPermissions = this.shiftModelPermissionsWrapper.aiThis;

	/**
	 * Additional shift-model permissions. Only send for permission group of requester or when owner and data="permissionSettings". For a detailed loaded permission-group, this will contain a list of all shift-models and activity-areas. Otherwise, only items are returned which change the permissions.
	 */
	get shiftModelPermissions() : SchedulingApiPermissionGroupShiftModelPermissions {
		this.getterDebugValidations(this.aiShiftModelPermissions, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiShiftModelPermissions.defaultValue;
			this.shiftModelPermissionsWrapper._updateRawData(this.data[5], true);
		}
		return this.shiftModelPermissionsWrapper;
	}

	set shiftModelPermissionsTestSetter(v : SchedulingApiPermissionGroupShiftModelPermissions) {
        this.setterImpl(5, v.rawData, 'shiftModelPermissions', true, null, function(this : SchedulingApiPermissionGroup) {this.shiftModelPermissionsWrapper = v;});
	}

	/**
	 * (Detail) When was this item created? "null" is returned when the value is unknown, for items which were created before the introduction of this attribute.
	 *
	 * @type {DateTime}
	 */
	get dateOfCreation() : DateTime | null {
		this.getterDebugValidations(this.aiDateOfCreation, false);
		return this.data[6];
	}

	set dateOfCreationTestSetter(v : DateTime | null) {
        this.setterImpl(6, v, 'dateOfCreation', true, null, null);
	}

	private creatorIdWrapper : Id | null = null!;

	/**
	 * (Detail) The user who created this item. "null" is returned when: 1) "dateOfCreation" is also "null". In this case the value is unknown, because the item was created before the introduction of this field. 2) The item was created by Dr. Plano during test-client creation.
	 *
	 * @type {Id}
	 */
	get creatorId() : Id | null {
		this.getterDebugValidations(this.aiCreatorId, false);
		return this.creatorIdWrapper;
	}

	set creatorIdTestSetter(v : Id | null) {
        this.setterImpl(7, v, 'creatorId', true, null, function(this : SchedulingApiPermissionGroup) {this.creatorIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftModelPermissionsWrapper._fixIds(_idReplacements);
		this.data[7] = Meta.getReplacedId(this.data[7], _idReplacements);
		this.creatorIdWrapper = this.data[7] === null ? null! : Id.create(this.data[7]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('PERMISSION_GROUP should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftModelPermissionsWrapper._updateRawData(data && data[5] !== undefined ? data[5] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[7] : null, this.creatorIdWrapper))
			this.creatorIdWrapper = data && data[7] ? Id.create(data[7]) : null!;
	}

	protected get dni() : string {
		return '389';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '389', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiPermissionGroupRole {
	CLIENT_DEFAULT = 1,
	CLIENT_OWNER = 2,
}
		 export class SchedulingApiPermissionGroupShiftModelPermissionsBase extends ApiListWrapper<SchedulingApiPermissionGroupShiftModelPermission>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiPermissionGroup | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftModelPermissions');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiPermissionGroupShiftModelPermission {
		return new SchedulingApiPermissionGroupShiftModelPermission(this.api, this as unknown as SchedulingApiPermissionGroupShiftModelPermissions, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiPermissionGroup | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiPermissionGroupShiftModelPermissions(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '394';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiPermissionGroupShiftModelPermission) => void) | null = null, _backendId : Id | null = null) : SchedulingApiPermissionGroupShiftModelPermission {
		const newItem = new SchedulingApiPermissionGroupShiftModelPermission(this.api, this as unknown as SchedulingApiPermissionGroupShiftModelPermissions, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftModelPermissions');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermissions, SchedulingApiPermissionGroupShiftModelPermissions> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermissions, SchedulingApiPermissionGroupShiftModelPermissions>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermissions,
			name: 'shiftModelPermissions',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSIONS',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiPermissionGroupShiftModelPermissions) {
				return ((this.api.currentlyDetailedLoaded === this.parent)||(this.api.pPermissionsService.loggedInMember!.permissionGroupIds.contains(this.parent!.id)));
			},
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermissions, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 5,
		});
}

				 
export class SchedulingApiPermissionGroupShiftModelPermissionBase extends ApiObjectWrapper<SchedulingApiPermissionGroupShiftModelPermission>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiPermissionGroupShiftModelPermissions | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiPermissionGroupShiftModelPermission> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiPermissionGroupShiftModelPermission as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, SchedulingApiPermissionGroupShiftModelPermission> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, SchedulingApiPermissionGroupShiftModelPermission>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'permissionGroupShiftModelPermission',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermission, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiShiftModelId : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, Id> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, Id>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'shiftModelId',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_SHIFT_MODEL_ID',
			type: PApiType.Id,
			rawDataIndex: 1,
		});
	aiActivityAreaId : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, Id> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, Id>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'activityAreaId',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_ACTIVITY_AREA_ID',
			type: PApiType.Id,
			rawDataIndex: 2,
		});
	aiCanRead : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'canRead',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_READ',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermission, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	aiCanReadBookings : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'canReadBookings',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_READ_BOOKINGS',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermission, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiCanWriteBookings : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'canWriteBookings',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_WRITE_BOOKINGS',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermission, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});
	aiCanWrite : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'canWrite',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_WRITE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermission, _nodeId : string) {return false;},
			rawDataIndex: 6,
		});
	aiCanOnlineRefund : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'canOnlineRefund',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_ONLINE_REFUND',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermission, _nodeId : string) {return false;},
			rawDataIndex: 7,
		});
	aiCanGetManagerNotifications : ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean> = new ApiAttributeInfo<SchedulingApiPermissionGroupShiftModelPermission, boolean>({
			apiObjWrapper: this as any as SchedulingApiPermissionGroupShiftModelPermission,
			name: 'canGetManagerNotifications',
			nodeName: 'PERMISSION_GROUP_SHIFT_MODEL_PERMISSION_CAN_GET_MANAGER_NOTIFICATIONS',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiPermissionGroupShiftModelPermission) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiPermissionGroupShiftModelPermission, _nodeId : string) {return false;},
			rawDataIndex: 8,
		});

	private shiftModelIdWrapper : Id | null = null!;

	/**
	 * The id of the shift-model for which this item describes permissions. Can be null in which case "activityAreaId" has to be defined.
	 *
	 * @type {Id}
	 */
	get shiftModelId() : Id | null {
		this.getterDebugValidations(this.aiShiftModelId, false);
		return this.shiftModelIdWrapper;
	}

	set shiftModelId(v : Id | null) {
        this.setterImpl(1, v, 'shiftModelId', false, null, function(this : SchedulingApiPermissionGroupShiftModelPermission) {this.shiftModelIdWrapper = v;});
	}

	private activityAreaIdWrapper : Id | null = null!;

	/**
	 * Activity-area id for which this item describes permissions. Can be null in which case "shiftModelId" has to be defined.
	 *
	 * @type {Id}
	 */
	get activityAreaId() : Id | null {
		this.getterDebugValidations(this.aiActivityAreaId, false);
		return this.activityAreaIdWrapper;
	}

	set activityAreaId(v : Id | null) {
        this.setterImpl(2, v, 'activityAreaId', false, null, function(this : SchedulingApiPermissionGroupShiftModelPermission) {this.activityAreaIdWrapper = v;});
	}

	/**
	 * Granting read permission to member? If no shiftModelPermission-item is send then for owner this values is assumed to be "true". For default member "true" if he is assignable otherwise "false".
	 *
	 * @type {boolean}
	 */
	get canRead() : boolean {
		this.getterDebugValidations(this.aiCanRead, false);
		return this.data[3];
	}

	set canRead(v : boolean) {
        this.setterImpl(3, v, 'canRead', false, null, null);
	}

	/**
	 * User can read bookings? By default only users assigned to a shift can see the bookings. If no shiftModelPermission-item is send then for owner this values is assumed to be "true" and for default member "false".
	 *
	 * @type {boolean}
	 */
	get canReadBookings() : boolean {
		this.getterDebugValidations(this.aiCanReadBookings, false);
		return this.data[4];
	}

	set canReadBookings(v : boolean) {
        this.setterImpl(4, v, 'canReadBookings', false, null, null);
	}

	/**
	 * Granting bookings write permission to member? If no shiftModelPermission-item is send then for owner this values is assumed to be "true" and for default member "false".
	 *
	 * @type {boolean}
	 */
	get canWriteBookings() : boolean {
		this.getterDebugValidations(this.aiCanWriteBookings, false);
		return this.data[5];
	}

	set canWriteBookings(v : boolean) {
        this.setterImpl(5, v, 'canWriteBookings', false, null, null);
	}

	/**
	 * Granting write permission to member? If no shiftModelPermission-item is send then for owner this values is assumed to be "true" and for default member "false".
	 *
	 * @type {boolean}
	 */
	get canWrite() : boolean {
		this.getterDebugValidations(this.aiCanWrite, false);
		return this.data[6];
	}

	set canWrite(v : boolean) {
        this.setterImpl(6, v, 'canWrite', false, null, null);
	}

	/**
	 * Granting online-refund permission to member? If no shiftModelPermission-item is send then for owner this values is assumed to be "true" and for default member "false".
	 *
	 * @type {boolean}
	 */
	get canOnlineRefund() : boolean {
		this.getterDebugValidations(this.aiCanOnlineRefund, false);
		return this.data[7];
	}

	set canOnlineRefund(v : boolean) {
        this.setterImpl(7, v, 'canOnlineRefund', false, null, null);
	}

	/**
	 * This member should get manager notifications? If no shiftModelPermission-item is send then for owner this values is assumed to be "true" and for default member "false".
	 *
	 * @type {boolean}
	 */
	get canGetManagerNotifications() : boolean {
		this.getterDebugValidations(this.aiCanGetManagerNotifications, false);
		return this.data[8];
	}

	set canGetManagerNotifications(v : boolean) {
        this.setterImpl(8, v, 'canGetManagerNotifications', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[1] = Meta.getReplacedId(this.data[1], _idReplacements);
		this.shiftModelIdWrapper = this.data[1] === null ? null! : Id.create(this.data[1]);
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.activityAreaIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('PERMISSION_GROUP_SHIFT_MODEL_PERMISSION should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[1] : null, this.shiftModelIdWrapper))
			this.shiftModelIdWrapper = data && data[1] ? Id.create(data[1]) : null!;
		if(!Meta.isSameId(data ? data[2] : null, this.activityAreaIdWrapper))
			this.activityAreaIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
	}

	protected get dni() : string {
		return '397';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '397', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiAccountingPeriodsBase extends ApiListWrapper<SchedulingApiAccountingPeriod>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'accountingPeriods');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiAccountingPeriod {
		return new SchedulingApiAccountingPeriod(this.api, this as unknown as SchedulingApiAccountingPeriods, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiAccountingPeriods(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '16';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiAccountingPeriod) => void) | null = null, _backendId : Id | null = null) : SchedulingApiAccountingPeriod {
		const newItem = new SchedulingApiAccountingPeriod(this.api, this as unknown as SchedulingApiAccountingPeriods, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('accountingPeriods');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAccountingPeriods, SchedulingApiAccountingPeriods> = new ApiAttributeInfo<SchedulingApiAccountingPeriods, SchedulingApiAccountingPeriods>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriods,
			name: 'accountingPeriods',
			nodeName: 'ACCOUNTING_PERIODS',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiAccountingPeriods) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'calendar')||(this.api.getCurrentDataSearchParamValue('data') === 'reporting')||(this.api.getCurrentDataSearchParamValue('data') === 'giftCards')||(this.api.getCurrentDataSearchParamValue('data') === 'transactions')||(this.api.getCurrentDataSearchParamValue('data') === 'bookings')||(this.api.getCurrentDataSearchParamValue('data') === 'shiftExchange'));
			},
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiAccountingPeriods, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 15,
		});
}

				 
export class SchedulingApiAccountingPeriodBase extends ApiObjectWrapper<SchedulingApiAccountingPeriod>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAccountingPeriods | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiAccountingPeriod> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiAccountingPeriod as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAccountingPeriod, SchedulingApiAccountingPeriod> = new ApiAttributeInfo<SchedulingApiAccountingPeriod, SchedulingApiAccountingPeriod>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriod,
			name: 'accountingPeriod',
			nodeName: 'ACCOUNTING_PERIOD',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiAccountingPeriod, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiStart : ApiAttributeInfo<SchedulingApiAccountingPeriod, Date> = new ApiAttributeInfo<SchedulingApiAccountingPeriod, Date>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriod,
			name: 'start',
			nodeName: 'ACCOUNTING_PERIOD_START',
			type: PApiType.Date,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiAccountingPeriod, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiAccountingPeriod, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriod,
			name: 'end',
			nodeName: 'ACCOUNTING_PERIOD_END',
			type: PApiType.DateExclusiveEnd,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});

	/**
	 * Start of the accounting period (inclusive).
	 *
	 * @type {Date}
	 */
	get start() : Date {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set startTestSetter(v : Date) {
        this.setterImpl(1, v, 'start', true, null, null);
	}

	/**
	 * End of the accounting period (exclusive).
	 *
	 * @type {DateExclusiveEnd}
	 */
	get end() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set endTestSetter(v : DateExclusiveEnd) {
        this.setterImpl(2, v, 'end', true, null, null);
	}

	private expectedMemberDataWrapper : SchedulingApiAccountingPeriodExpectedMemberData = new SchedulingApiAccountingPeriodExpectedMemberData(this.api,
		this as unknown as SchedulingApiAccountingPeriod, false, false);
	public aiExpectedMemberData = this.expectedMemberDataWrapper.aiThis;

	/**
	 * A list of expected member data. An item of this list has the same id as a member to whom that item belongs. Note, that this list only contains members for whom the requester has permission to read earnings.
	 */
	get expectedMemberData() : SchedulingApiAccountingPeriodExpectedMemberData {
		this.getterDebugValidations(this.aiExpectedMemberData, false);
		return this.expectedMemberDataWrapper;
	}

	set expectedMemberDataTestSetter(v : SchedulingApiAccountingPeriodExpectedMemberData) {
        this.setterImpl(3, v.rawData, 'expectedMemberData', true, null, function(this : SchedulingApiAccountingPeriod) {this.expectedMemberDataWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.expectedMemberDataWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ACCOUNTING_PERIOD should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.expectedMemberDataWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '406';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '406', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiAccountingPeriodExpectedMemberDataBase extends ApiListWrapper<SchedulingApiAccountingPeriodExpectedMemberDataItem>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAccountingPeriod | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'expectedMemberData');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiAccountingPeriodExpectedMemberDataItem {
		return new SchedulingApiAccountingPeriodExpectedMemberDataItem(this.api, this as unknown as SchedulingApiAccountingPeriodExpectedMemberData, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiAccountingPeriod | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiAccountingPeriodExpectedMemberData(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '409';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiAccountingPeriodExpectedMemberDataItem) => void) | null = null, _backendId : Id | null = null) : SchedulingApiAccountingPeriodExpectedMemberDataItem {
		const newItem = new SchedulingApiAccountingPeriodExpectedMemberDataItem(this.api, this as unknown as SchedulingApiAccountingPeriodExpectedMemberData, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('expectedMemberData');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberData, SchedulingApiAccountingPeriodExpectedMemberData> = new ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberData, SchedulingApiAccountingPeriodExpectedMemberData>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriodExpectedMemberData,
			name: 'expectedMemberData',
			nodeName: 'ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiAccountingPeriodExpectedMemberData, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 3,
		});
}

				 
export class SchedulingApiAccountingPeriodExpectedMemberDataItem extends ApiObjectWrapper<SchedulingApiAccountingPeriodExpectedMemberDataItem>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiAccountingPeriodExpectedMemberData | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiAccountingPeriodExpectedMemberDataItem> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiAccountingPeriodExpectedMemberDataItem as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberDataItem, SchedulingApiAccountingPeriodExpectedMemberDataItem> = new ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberDataItem, SchedulingApiAccountingPeriodExpectedMemberDataItem>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriodExpectedMemberDataItem,
			name: 'accountingPeriodExpectedMemberDataItem',
			nodeName: 'ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA_ITEM',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiAccountingPeriodExpectedMemberDataItem, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiEarnings : ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberDataItem, ClientCurrency> = new ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberDataItem, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriodExpectedMemberDataItem,
			name: 'earnings',
			nodeName: 'ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA_ITEM_EARNINGS',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiWorkingHours : ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberDataItem, Hours> = new ApiAttributeInfo<SchedulingApiAccountingPeriodExpectedMemberDataItem, Hours>({
			apiObjWrapper: this as any as SchedulingApiAccountingPeriodExpectedMemberDataItem,
			name: 'workingHours',
			nodeName: 'ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA_ITEM_WORKING_HOURS',
			type: PApiType.Hours,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});

	/**
	 * The expected earnings of the member.
	 *
	 * @type {ClientCurrency}
	 */
	get earnings() : ClientCurrency {
		this.getterDebugValidations(this.aiEarnings, false);
		return this.data[1];
	}

	set earningsTestSetter(v : ClientCurrency) {
        this.setterImpl(1, v, 'earnings', true, null, null);
	}

	/**
	 * The expected working hours of the member.
	 *
	 * @type {Hours}
	 */
	get workingHours() : Hours {
		this.getterDebugValidations(this.aiWorkingHours, false);
		return this.data[2];
	}

	set workingHoursTestSetter(v : Hours) {
        this.setterImpl(2, v, 'workingHours', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ACCOUNTING_PERIOD_EXPECTED_MEMBER_DATA_ITEM should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '410';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '410', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiMemosBase extends ApiListWrapper<SchedulingApiMemo>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'memos');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiMemo {
		return new SchedulingApiMemo(this.api, this as unknown as SchedulingApiMemos, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiMemos(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '17';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiMemo) => void) | null = null, _backendId : Id | null = null) : SchedulingApiMemo {
		const newItem = new SchedulingApiMemo(this.api, this as unknown as SchedulingApiMemos, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('memos');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMemos, SchedulingApiMemos> = new ApiAttributeInfo<SchedulingApiMemos, SchedulingApiMemos>({
			apiObjWrapper: this as any as SchedulingApiMemos,
			name: 'memos',
			nodeName: 'MEMOS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiMemos) {
				{const conditionValue = this.api.pPermissionsService.hasManagerPermission; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiMemos, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 16,
		});
}

				 
export class SchedulingApiMemo extends ApiObjectWrapper<SchedulingApiMemo>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiMemos | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiMemo> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiMemo as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMemo, SchedulingApiMemo> = new ApiAttributeInfo<SchedulingApiMemo, SchedulingApiMemo>({
			apiObjWrapper: this as any as SchedulingApiMemo,
			name: 'memo',
			nodeName: 'MEMO',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiMemo, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiMessage : ApiAttributeInfo<SchedulingApiMemo, string> = new ApiAttributeInfo<SchedulingApiMemo, string>({
			apiObjWrapper: this as any as SchedulingApiMemo,
			name: 'message',
			nodeName: 'MEMO_MESSAGE',
			type: PApiType.string,
			validations: function(this : SchedulingApiMemo) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMemo) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiStart : ApiAttributeInfo<SchedulingApiMemo, Date> = new ApiAttributeInfo<SchedulingApiMemo, Date>({
			apiObjWrapper: this as any as SchedulingApiMemo,
			name: 'start',
			nodeName: 'MEMO_START',
			type: PApiType.Date,
			validations: function(this : SchedulingApiMemo) {
				return [
					() => {
return this.api.validators.max(() => this.end, true, PApiType.Date, this.aiEnd.id, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMemo) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiMemo, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiMemo, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiMemo,
			name: 'end',
			nodeName: 'MEMO_END',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : SchedulingApiMemo) {
				return [
					() => {
return this.api.validators.min(() => this.start, true, PApiType.DateExclusiveEnd, this.aiStart.id, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMemo) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});

	/**
	 * Message of the memo.
	 *
	 * @type {string}
	 */
	get message() : string {
		this.getterDebugValidations(this.aiMessage, false);
		return this.data[1];
	}

	set message(v : string) {
        this.setterImpl(1, v, 'message', false, null, null);
	}

	/**
	 * Start time of memo (inclusive).
	 *
	 * @type {Date}
	 */
	get start() : Date {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[2];
	}

	set start(v : Date) {
        this.setterImpl(2, v, 'start', false, null, null);
	}

	/**
	 * End time of memo (exclusive).
	 *
	 * @type {DateExclusiveEnd}
	 */
	get end() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[3];
	}

	set end(v : DateExclusiveEnd) {
        this.setterImpl(3, v, 'end', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('MEMO should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '413';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '413', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiTodaysShiftDescriptionsBase extends ApiListWrapper<SchedulingApiTodaysShiftDescription>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'todaysShiftDescriptions');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiTodaysShiftDescription {
		return new SchedulingApiTodaysShiftDescription(this.api, this as unknown as SchedulingApiTodaysShiftDescriptions, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiTodaysShiftDescriptions(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '18';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiTodaysShiftDescription) => void) | null = null, _backendId : ShiftId | null = null) : SchedulingApiTodaysShiftDescription {
		const newItem = new SchedulingApiTodaysShiftDescription(this.api, this as unknown as SchedulingApiTodaysShiftDescriptions, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('todaysShiftDescriptions');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiTodaysShiftDescriptions, SchedulingApiTodaysShiftDescriptions> = new ApiAttributeInfo<SchedulingApiTodaysShiftDescriptions, SchedulingApiTodaysShiftDescriptions>({
			apiObjWrapper: this as any as SchedulingApiTodaysShiftDescriptions,
			name: 'todaysShiftDescriptions',
			nodeName: 'TODAYS_SHIFT_DESCRIPTIONS',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiTodaysShiftDescriptions) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'calendar')||(this.api.getCurrentDataSearchParamValue('data') === 'reporting')||(this.api.getCurrentDataSearchParamValue('data') === 'giftCards')||(this.api.getCurrentDataSearchParamValue('data') === 'transactions')||(this.api.getCurrentDataSearchParamValue('data') === 'bookings')||(this.api.getCurrentDataSearchParamValue('data') === 'shiftExchange'));
			},
			defaultValue: function(this : SchedulingApiTodaysShiftDescriptions, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 17,
		});
}

				 
export class SchedulingApiTodaysShiftDescriptionBase extends ApiObjectWrapper<SchedulingApiTodaysShiftDescription>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiTodaysShiftDescriptions | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiTodaysShiftDescription> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiTodaysShiftDescription as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiTodaysShiftDescription, SchedulingApiTodaysShiftDescription> = new ApiAttributeInfo<SchedulingApiTodaysShiftDescription, SchedulingApiTodaysShiftDescription>({
			apiObjWrapper: this as any as SchedulingApiTodaysShiftDescription,
			name: 'todaysShiftDescription',
			nodeName: 'TODAYS_SHIFT_DESCRIPTION',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiTodaysShiftDescription) {
				{const conditionValue = this.api.pPermissionsService.userCanWrite(this.id.shiftModelId); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			
		});
	aiDescription : ApiAttributeInfo<SchedulingApiTodaysShiftDescription, string> = new ApiAttributeInfo<SchedulingApiTodaysShiftDescription, string>({
			apiObjWrapper: this as any as SchedulingApiTodaysShiftDescription,
			name: 'description',
			nodeName: 'TODAYS_SHIFT_DESCRIPTION_DESCRIPTION',
			type: PApiType.string,
			rawDataIndex: 1,
		});
	aiShiftStart : ApiAttributeInfo<SchedulingApiTodaysShiftDescription, DateTime> = new ApiAttributeInfo<SchedulingApiTodaysShiftDescription, DateTime>({
			apiObjWrapper: this as any as SchedulingApiTodaysShiftDescription,
			name: 'shiftStart',
			nodeName: 'TODAYS_SHIFT_DESCRIPTION_SHIFT_START',
			type: PApiType.DateTime,
			rawDataIndex: 2,
		});
	aiShiftEnd : ApiAttributeInfo<SchedulingApiTodaysShiftDescription, DateTime> = new ApiAttributeInfo<SchedulingApiTodaysShiftDescription, DateTime>({
			apiObjWrapper: this as any as SchedulingApiTodaysShiftDescription,
			name: 'shiftEnd',
			nodeName: 'TODAYS_SHIFT_DESCRIPTION_SHIFT_END',
			type: PApiType.DateTime,
			rawDataIndex: 3,
		});

	/**
	 * Description of the shift.
	 *
	 * @type {string}
	 */
	get description() : string {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[1];
	}

	set description(v : string) {
        this.setterImpl(1, v, 'description', false, null, null);
	}

	/**
	 * Start of shift.
	 *
	 * @type {DateTime}
	 */
	get shiftStart() : DateTime {
		this.getterDebugValidations(this.aiShiftStart, false);
		return this.data[2];
	}

	set shiftStart(v : DateTime) {
        this.setterImpl(2, v, 'shiftStart', false, null, null);
	}

	/**
	 * End of shift.
	 *
	 * @type {DateTime}
	 */
	get shiftEnd() : DateTime {
		this.getterDebugValidations(this.aiShiftEnd, false);
		return this.data[3];
	}

	set shiftEnd(v : DateTime) {
        this.setterImpl(3, v, 'shiftEnd', false, null, null);
	}

	private assignedMemberIdsWrapper : SchedulingApiTodaysShiftDescriptionAssignedMemberIds = new SchedulingApiTodaysShiftDescriptionAssignedMemberIds(this.api,
		this as unknown as SchedulingApiTodaysShiftDescription, false, false);
	public aiAssignedMemberIds = this.assignedMemberIdsWrapper.aiThis;

	/**
	 * A list of the assigned members' ids.
	 */
	get assignedMemberIds() : SchedulingApiTodaysShiftDescriptionAssignedMemberIds {
		this.getterDebugValidations(this.aiAssignedMemberIds, false);
		return this.assignedMemberIdsWrapper;
	}

	set assignedMemberIdsTestSetter(v : SchedulingApiTodaysShiftDescriptionAssignedMemberIds) {
        this.setterImpl(4, v.rawData, 'assignedMemberIds', true, null, function(this : SchedulingApiTodaysShiftDescription) {this.assignedMemberIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.assignedMemberIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('TODAYS_SHIFT_DESCRIPTION should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.assignedMemberIdsWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
	}

	protected get dni() : string {
		return '417';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '417', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiTodaysShiftDescriptionAssignedMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiTodaysShiftDescription | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'assignedMemberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiTodaysShiftDescription | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiTodaysShiftDescriptionAssignedMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '421';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiTodaysShiftDescriptionAssignedMemberIds, SchedulingApiTodaysShiftDescriptionAssignedMemberIds> = new ApiAttributeInfo<SchedulingApiTodaysShiftDescriptionAssignedMemberIds, SchedulingApiTodaysShiftDescriptionAssignedMemberIds>({
			apiObjWrapper: this as any as SchedulingApiTodaysShiftDescriptionAssignedMemberIds,
			name: 'assignedMemberIds',
			nodeName: 'TODAYS_SHIFT_DESCRIPTION_ASSIGNED_MEMBER_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiTodaysShiftDescriptionAssignedMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 4,
		});
	aiTodaysShiftDescriptionAssignedMemberId : ApiAttributeInfo<SchedulingApiTodaysShiftDescriptionAssignedMemberIds, Id> = new ApiAttributeInfo<SchedulingApiTodaysShiftDescriptionAssignedMemberIds, Id>({
			apiObjWrapper: this as any as SchedulingApiTodaysShiftDescriptionAssignedMemberIds,
			name: 'todaysShiftDescriptionAssignedMemberId',
			nodeName: 'TODAYS_SHIFT_DESCRIPTION_ASSIGNED_MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 
export class SchedulingApiHolidays extends ApiObjectWrapper<SchedulingApiHolidays>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiHolidays> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiHolidays as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiHolidays, SchedulingApiHolidays> = new ApiAttributeInfo<SchedulingApiHolidays, SchedulingApiHolidays>({
			apiObjWrapper: this as any as SchedulingApiHolidays,
			name: 'holidays',
			nodeName: 'HOLIDAYS',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiHolidays) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? {sourceString: 'Du hast leider keine Berechtigung hierfür. Wende dich bitte an deine Admins.', params: false} : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiHolidays, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 18,
		});

	private holidayItemsWrapper : SchedulingApiHolidaysHolidayItems = new SchedulingApiHolidaysHolidayItems(this.api,
		this as unknown as SchedulingApiHolidays, false, false);
	public aiHolidayItems = this.holidayItemsWrapper.aiThis;

	/**
	 * List of holiday-items.
	 */
	get holidayItems() : SchedulingApiHolidaysHolidayItems {
		this.getterDebugValidations(this.aiHolidayItems, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[1] === undefined) {
			this.data[1] = this.aiHolidayItems.defaultValue;
			this.holidayItemsWrapper._updateRawData(this.data[1], true);
		}
		return this.holidayItemsWrapper;
	}

	set holidayItemsTestSetter(v : SchedulingApiHolidaysHolidayItems) {
        this.setterImpl(1, v.rawData, 'holidayItems', true, null, function(this : SchedulingApiHolidays) {this.holidayItemsWrapper = v;});
	}

	private labelsWrapper : SchedulingApiHolidaysLabels = new SchedulingApiHolidaysLabels(this.api,
		this as unknown as SchedulingApiHolidays, false, false);
	public aiLabels = this.labelsWrapper.aiThis;

	/**
	 * List of labels to be used to tag holiday-items.
	 */
	get labels() : SchedulingApiHolidaysLabels {
		this.getterDebugValidations(this.aiLabels, false);
		return this.labelsWrapper;
	}

	set labelsTestSetter(v : SchedulingApiHolidaysLabels) {
        this.setterImpl(2, v.rawData, 'labels', true, null, function(this : SchedulingApiHolidays) {this.labelsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.holidayItemsWrapper._fixIds(_idReplacements);
		this.labelsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('HOLIDAYS should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.holidayItemsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.labelsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '19';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '19', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiHolidaysHolidayItemsBase extends ApiListWrapper<SchedulingApiHolidaysHolidayItem>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiHolidays | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'holidayItems');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiHolidaysHolidayItem {
		return new SchedulingApiHolidaysHolidayItem(this.api, this as unknown as SchedulingApiHolidaysHolidayItems, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiHolidays | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiHolidaysHolidayItems(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '679';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiHolidaysHolidayItem) => void) | null = null, _backendId : Id | null = null) : SchedulingApiHolidaysHolidayItem {
		const newItem = new SchedulingApiHolidaysHolidayItem(this.api, this as unknown as SchedulingApiHolidaysHolidayItems, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('holidayItems');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiHolidaysHolidayItems, SchedulingApiHolidaysHolidayItems> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItems, SchedulingApiHolidaysHolidayItems>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItems,
			name: 'holidayItems',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEMS',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiHolidaysHolidayItems) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'calendar')||(this.api.getCurrentDataSearchParamValue('data') === 'holidays'));
			},
			hasPermissionToSet: function(this : SchedulingApiHolidaysHolidayItems) {
				{const conditionValue = !this.api.pWindowSizeService.windowWidthIsBelowScreenSize(1200); if(!conditionValue) return conditionValue === false ? {sourceString: 'Bitte verwende einen Laptop oder ein Gerät mit einem größeren Bildschirm (mindestens 1200 px breit), um diese Seite bearbeiten zu können.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiHolidaysHolidayItems) {
				return [
					() => {
						return this.api.validators.maxLength(100, PApiType.ApiList, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysHolidayItems) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiHolidaysHolidayItems, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
}

				 
export class SchedulingApiHolidaysHolidayItemBase extends ApiObjectWrapper<SchedulingApiHolidaysHolidayItem>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiHolidaysHolidayItems | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiHolidaysHolidayItem> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiHolidaysHolidayItem as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, SchedulingApiHolidaysHolidayItem> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, SchedulingApiHolidaysHolidayItem>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItem,
			name: 'holidaysHolidayItem',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiHolidaysHolidayItem, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiType : ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, SchedulingApiHolidayItemType> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, SchedulingApiHolidayItemType>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItem,
			name: 'type',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiStart : ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, Date> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, Date>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItem,
			name: 'start',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_START',
			type: PApiType.Date,
			validations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
					() => {
		if(((this.type !== SchedulingApiHolidayItemType.PUBLIC_HOLIDAY)))
		{
			return this.api.validators.max(() => this.end, false, PApiType.Date, this.aiEnd.id, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(+notNull(this.api.getCurrentDataSearchParamValue('start')), true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(+notNull(this.api.getCurrentDataSearchParamValue('end')), false, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItem,
			name: 'end',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_END',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
					() => {
return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);						return null;
					},
					() => {
return this.api.validators.min(() => this.start, false, PApiType.DateExclusiveEnd, this.aiStart.id, undefined);						return null;
					},
					() => {
return this.api.validators.max(+notNull(this.api.getCurrentDataSearchParamValue('end')) + 10368000000, false, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiName : ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, string> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, string>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItem,
			name: 'name',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(60, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiEmoji : ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, Emoji> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, Emoji>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItem,
			name: 'emoji',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_EMOJI',
			type: PApiType.Emoji,
			rawDataIndex: 5,
		});
	aiPublicHolidayDurationType : ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, SchedulingApiLeaveDayDurationType> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItem, SchedulingApiLeaveDayDurationType>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItem,
			name: 'publicHolidayDurationType',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_PUBLIC_HOLIDAY_DURATION_TYPE',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiHolidaysHolidayItem) {
				return ((this.type === SchedulingApiHolidayItemType.PUBLIC_HOLIDAY));
			},
			validations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
					() => {
		if(((this.type === SchedulingApiHolidayItemType.PUBLIC_HOLIDAY)))
		{
			return this.api.validators.required(PApiType.Enum, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.Enum, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysHolidayItem) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiHolidaysHolidayItem, _nodeId : string) {return SchedulingApiLeaveDayDurationType.WHOLE_DAY;},
			rawDataIndex: 6,
		});

	/**
	 * The type of the holiday-item.
	 *
	 * @type {SchedulingApiHolidayItemType}
	 */
	get type() : SchedulingApiHolidayItemType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiHolidayItemType) {
        this.setterImpl(1, v, 'type', false, null, null);
	}

	/**
	 * Start of this holiday-item.
	 *
	 * @type {Date}
	 */
	get start() : Date {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[2];
	}

	set start(v : Date) {
        this.setterImpl(2, v, 'start', false, null, function(this : SchedulingApiHolidaysHolidayItem) {// For public-holidays end must always be end of the starting day
					if(this.type === SchedulingApiHolidayItemType.PUBLIC_HOLIDAY)
						this.end = this.api.pMoment.m(v).add(1, 'day').valueOf();});
	}

	/**
	 * End of this holiday-item.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get end() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[3];
	}

	set end(v : DateExclusiveEnd) {
        this.setterImpl(3, v, 'end', false, null, null);
	}

	/**
	 * The name of the holiday-item.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[4];
	}

	set name(v : string) {
        this.setterImpl(4, v, 'name', false, null, null);
	}

	/**
	 * The emoji associated with this holiday-item.
	 *
	 * @type {Emoji}
	 */
	get emoji() : Emoji | null {
		this.getterDebugValidations(this.aiEmoji, false);
		return this.data[5];
	}

	set emoji(v : Emoji | null) {
        this.setterImpl(5, v, 'emoji', false, null, null);
	}

	/**
	 * The duration of the public holiday. I.e. the portion of the day (whole day, half day, ...). Only available for public holiday-items.
	 *
	 * @type {SchedulingApiLeaveDayDurationType}
	 */
	get publicHolidayDurationType() : SchedulingApiLeaveDayDurationType | null {
		this.getterDebugValidations(this.aiPublicHolidayDurationType, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiPublicHolidayDurationType.defaultValue;
			
		}
		return this.data[6];
	}

	set publicHolidayDurationType(v : SchedulingApiLeaveDayDurationType | null) {
        this.setterImpl(6, v, 'publicHolidayDurationType', false, null, null);
	}

	private labelIdsWrapper : SchedulingApiHolidaysHolidayItemLabelIds = new SchedulingApiHolidaysHolidayItemLabelIds(this.api,
		this as unknown as SchedulingApiHolidaysHolidayItem, false, false);
	public aiLabelIds = this.labelIdsWrapper.aiThis;

	/**
	 * A list of label-ids that tags this holiday-item. See "api.data.holidays.labels".
	 */
	get labelIds() : SchedulingApiHolidaysHolidayItemLabelIds {
		this.getterDebugValidations(this.aiLabelIds, false);
		return this.labelIdsWrapper;
	}

	set labelIdsTestSetter(v : SchedulingApiHolidaysHolidayItemLabelIds) {
        this.setterImpl(7, v.rawData, 'labelIds', true, null, function(this : SchedulingApiHolidaysHolidayItem) {this.labelIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.labelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('HOLIDAYS_HOLIDAY_ITEM should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.labelIdsWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
	}

	protected get dni() : string {
		return '681';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '681', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiHolidaysHolidayItemLabelIds extends ApiListWrapper<LabelId>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiHolidaysHolidayItem | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'labelIds');
	}

	override wrapRawData(itemRawData : any) : LabelId {
		return LabelId.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiHolidaysHolidayItem | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiHolidaysHolidayItemLabelIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '688';
	}

	override createNewItem(_initCode : ((newItem : LabelId) => void) | null = null) : LabelId {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiHolidaysHolidayItemLabelIds, SchedulingApiHolidaysHolidayItemLabelIds> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItemLabelIds, SchedulingApiHolidaysHolidayItemLabelIds>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItemLabelIds,
			name: 'labelIds',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_LABEL_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiHolidaysHolidayItemLabelIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.LabelId,
			rawDataIndex: 7,
		});
	aiHolidaysHolidayItemLabelId : ApiAttributeInfo<SchedulingApiHolidaysHolidayItemLabelIds, LabelId> = new ApiAttributeInfo<SchedulingApiHolidaysHolidayItemLabelIds, LabelId>({
			apiObjWrapper: this as any as SchedulingApiHolidaysHolidayItemLabelIds,
			name: 'holidaysHolidayItemLabelId',
			nodeName: 'HOLIDAYS_HOLIDAY_ITEM_LABEL_ID',
			type: PApiType.LabelId,
			
		});
}

				 export class SchedulingApiHolidaysLabelsBase extends ApiListWrapper<SchedulingApiHolidaysLabel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiHolidays | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'labels');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiHolidaysLabel {
		return new SchedulingApiHolidaysLabel(this.api, this as unknown as SchedulingApiHolidaysLabels, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiHolidays | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiHolidaysLabels(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '680';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiHolidaysLabel) => void) | null = null, _backendId : Id | null = null) : SchedulingApiHolidaysLabel {
		const newItem = new SchedulingApiHolidaysLabel(this.api, this as unknown as SchedulingApiHolidaysLabels, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('labels');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiHolidaysLabels, SchedulingApiHolidaysLabels> = new ApiAttributeInfo<SchedulingApiHolidaysLabels, SchedulingApiHolidaysLabels>({
			apiObjWrapper: this as any as SchedulingApiHolidaysLabels,
			name: 'labels',
			nodeName: 'HOLIDAYS_LABELS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiHolidaysLabels) {
				return [
					() => {
						return this.api.validators.maxLength(50, PApiType.ApiList, undefined);
					},
					() => { return this.checkLabelsWithSameName() },
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysLabels) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiHolidaysLabels, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 2,
		});
}

				 
export class SchedulingApiHolidaysLabelBase extends ApiObjectWrapper<SchedulingApiHolidaysLabel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiHolidaysLabels | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiHolidaysLabel> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiHolidaysLabel as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiHolidaysLabel, SchedulingApiHolidaysLabel> = new ApiAttributeInfo<SchedulingApiHolidaysLabel, SchedulingApiHolidaysLabel>({
			apiObjWrapper: this as any as SchedulingApiHolidaysLabel,
			name: 'holidaysLabel',
			nodeName: 'HOLIDAYS_LABEL',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiHolidaysLabel, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiHolidaysLabel, string> = new ApiAttributeInfo<SchedulingApiHolidaysLabel, string>({
			apiObjWrapper: this as any as SchedulingApiHolidaysLabel,
			name: 'name',
			nodeName: 'HOLIDAYS_LABEL_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiHolidaysLabel) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(50, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiHolidaysLabel) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiEmoji : ApiAttributeInfo<SchedulingApiHolidaysLabel, Emoji> = new ApiAttributeInfo<SchedulingApiHolidaysLabel, Emoji>({
			apiObjWrapper: this as any as SchedulingApiHolidaysLabel,
			name: 'emoji',
			nodeName: 'HOLIDAYS_LABEL_EMOJI',
			type: PApiType.Emoji,
			rawDataIndex: 2,
		});

	/**
	 * The name of the label.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * The emoji associated with this label.
	 *
	 * @type {Emoji}
	 */
	get emoji() : Emoji | null {
		this.getterDebugValidations(this.aiEmoji, false);
		return this.data[2];
	}

	set emoji(v : Emoji | null) {
        this.setterImpl(2, v, 'emoji', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('HOLIDAYS_LABEL should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '690';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '690', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiPossibleVatPercents extends ApiListWrapper<Percent>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'possibleVatPercents');
	}

	override wrapRawData(itemRawData : any) : Percent {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiPossibleVatPercents(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '20';
	}

	override createNewItem(_initCode : ((newItem : Percent) => void) | null = null) : Percent {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiPossibleVatPercents, SchedulingApiPossibleVatPercents> = new ApiAttributeInfo<SchedulingApiPossibleVatPercents, SchedulingApiPossibleVatPercents>({
			apiObjWrapper: this as any as SchedulingApiPossibleVatPercents,
			name: 'possibleVatPercents',
			nodeName: 'POSSIBLE_VAT_PERCENTS',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiPossibleVatPercents, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Percent,
			rawDataIndex: 19,
		});
	aiPossibleVatPercent : ApiAttributeInfo<SchedulingApiPossibleVatPercents, Percent> = new ApiAttributeInfo<SchedulingApiPossibleVatPercents, Percent>({
			apiObjWrapper: this as any as SchedulingApiPossibleVatPercents,
			name: 'possibleVatPercent',
			nodeName: 'POSSIBLE_VAT_PERCENT',
			type: PApiType.Percent,
			hasPermissionToSet: () => false,
			
		});
}

				 export class SchedulingApiCustomBookableMailsBase extends ApiListWrapper<SchedulingApiCustomBookableMail>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'customBookableMails');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiCustomBookableMail {
		return new SchedulingApiCustomBookableMail(this.api, this as unknown as SchedulingApiCustomBookableMails, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiCustomBookableMails(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '21';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiCustomBookableMail) => void) | null = null, _backendId : Id | null = null) : SchedulingApiCustomBookableMail {
		const newItem = new SchedulingApiCustomBookableMail(this.api, this as unknown as SchedulingApiCustomBookableMails, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('customBookableMails');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiCustomBookableMails, SchedulingApiCustomBookableMails> = new ApiAttributeInfo<SchedulingApiCustomBookableMails, SchedulingApiCustomBookableMails>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMails,
			name: 'customBookableMails',
			nodeName: 'CUSTOM_BOOKABLE_MAILS',
			type: PApiType.ApiList,
			hasPermissionToSet: function(this : SchedulingApiCustomBookableMails) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiCustomBookableMails, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 20,
		});
}

				 
export class SchedulingApiCustomBookableMail extends ApiObjectWrapper<SchedulingApiCustomBookableMail>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiCustomBookableMails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiCustomBookableMail> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiCustomBookableMail as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiCustomBookableMail, SchedulingApiCustomBookableMail> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, SchedulingApiCustomBookableMail>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'customBookableMail',
			nodeName: 'CUSTOM_BOOKABLE_MAIL',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiCustomBookableMail, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiCustomBookableMail, string> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, string>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'name',
			nodeName: 'CUSTOM_BOOKABLE_MAIL_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiCustomBookableMail) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiCustomBookableMail) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiEventType : ApiAttributeInfo<SchedulingApiCustomBookableMail, SchedulingApiCustomBookableMailEventType> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, SchedulingApiCustomBookableMailEventType>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'eventType',
			nodeName: 'CUSTOM_BOOKABLE_MAIL_EVENT_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiCustomBookableMail) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiCustomBookableMail) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiSendToBookingPerson : ApiAttributeInfo<SchedulingApiCustomBookableMail, boolean> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, boolean>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'sendToBookingPerson',
			nodeName: 'CUSTOM_BOOKABLE_MAIL_SEND_TO_BOOKING_PERSON',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiCustomBookableMail) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiCustomBookableMail) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiCustomBookableMail, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	aiSendToParticipants : ApiAttributeInfo<SchedulingApiCustomBookableMail, boolean> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, boolean>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'sendToParticipants',
			nodeName: 'CUSTOM_BOOKABLE_MAIL_SEND_TO_PARTICIPANTS',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiCustomBookableMail) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiCustomBookableMail) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiCustomBookableMail, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiSubjectTemplate : ApiAttributeInfo<SchedulingApiCustomBookableMail, string> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, string>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'subjectTemplate',
			nodeName: 'CUSTOM_BOOKABLE_MAIL_SUBJECT_TEMPLATE',
			type: PApiType.string,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiCustomBookableMail) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiCustomBookableMail) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiTextTemplate : ApiAttributeInfo<SchedulingApiCustomBookableMail, string> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, string>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'textTemplate',
			nodeName: 'CUSTOM_BOOKABLE_MAIL_TEXT_TEMPLATE',
			type: PApiType.string,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiCustomBookableMail) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiCustomBookableMail) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiReplyTo : ApiAttributeInfo<SchedulingApiCustomBookableMail, Email> = new ApiAttributeInfo<SchedulingApiCustomBookableMail, Email>({
			apiObjWrapper: this as any as SchedulingApiCustomBookableMail,
			name: 'replyTo',
			nodeName: 'CUSTOM_BOOKABLE_MAIL_REPLY_TO',
			type: PApiType.Email,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiCustomBookableMail) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiCustomBookableMail) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});

	/**
	 * Template name.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * Event-type for which this email should be used.
	 *
	 * @type {SchedulingApiCustomBookableMailEventType}
	 */
	get eventType() : SchedulingApiCustomBookableMailEventType {
		this.getterDebugValidations(this.aiEventType, false);
		return this.data[2];
	}

	set eventType(v : SchedulingApiCustomBookableMailEventType) {
        this.setterImpl(2, v, 'eventType', false, null, null);
	}

	/**
	 * Should this mail be send to the booking person?
	 *
	 * @type {boolean}
	 */
	get sendToBookingPerson() : boolean {
		this.getterDebugValidations(this.aiSendToBookingPerson, false);
		return this.data[3];
	}

	set sendToBookingPerson(v : boolean) {
        this.setterImpl(3, v, 'sendToBookingPerson', false, null, null);
	}

	/**
	 * Should this mail be send to participants of the bookings? Note that for bookings where no individual participant information are available backend will fallback to send mail to the booking person when this is true.
	 *
	 * @type {boolean}
	 */
	get sendToParticipants() : boolean {
		this.getterDebugValidations(this.aiSendToParticipants, false);
		return this.data[4];
	}

	set sendToParticipants(v : boolean) {
        this.setterImpl(4, v, 'sendToParticipants', false, null, null);
	}

	/**
	 * (Detail) Template for email's subject.
	 *
	 * @type {string}
	 */
	get subjectTemplate() : string {
		this.getterDebugValidations(this.aiSubjectTemplate, false);
		return this.data[5];
	}

	set subjectTemplate(v : string) {
        this.setterImpl(5, v, 'subjectTemplate', false, null, null);
	}

	/**
	 * (Detail) Template for email's text. It is assumed that line-breaks are represented by the exact string "<br>".
	 *
	 * @type {string}
	 */
	get textTemplate() : string {
		this.getterDebugValidations(this.aiTextTemplate, false);
		return this.data[6];
	}

	set textTemplate(v : string) {
        this.setterImpl(6, v, 'textTemplate', false, null, null);
	}

	/**
	 * (Detail) Email address being set as reply-to field when sending this email.
	 *
	 * @type {Email}
	 */
	get replyTo() : Email {
		this.getterDebugValidations(this.aiReplyTo, false);
		return this.data[7];
	}

	set replyTo(v : Email) {
        this.setterImpl(7, v, 'replyTo', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CUSTOM_BOOKABLE_MAIL should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '479';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '479', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiCustomBookableMailEventType {
	INQUIRY_ARRIVAL_NOTICE = 1,
	INQUIRY_DECLINED = 2,
	BOOKING_BOOKED = 3,
	COURSE_REMINDER = 4,
	BOOKING_CANCELED = 5,
	PARTICIPATED = 6,
	PAYMENT_PARTIAL = 7,
	PAYMENT_COMPLETE = 8,
	ONLINE_PAYMENT_FAILED = 9,
	REFUNDED = 10,
	REFUND_FAILED = 11,
	PRICE_CHANGED = 12,
	DATE_CHANGED = 13,
	PAYMENT_METHOD_CHANGED = 14,
	GIFT_CARD_BOOKED = 15,
	REFUND_GIFT_CARD_BOOKED = 16,
	MARKETING_GIFT_CARD_BOOKED = 17,
	GIFT_CARD_CANCELED = 18,
	GIFT_CARD_EXPIRATION_REMINDER = 19,
}
		 
export class SchedulingApiNotificationSettings extends ApiObjectWrapper<SchedulingApiNotificationSettings>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiNotificationSettings> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiNotificationSettings as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationSettings, SchedulingApiNotificationSettings> = new ApiAttributeInfo<SchedulingApiNotificationSettings, SchedulingApiNotificationSettings>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettings,
			name: 'notificationSettings',
			nodeName: 'NOTIFICATION_SETTINGS',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiNotificationSettings, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 21,
		});

	private pushTokensWrapper : SchedulingApiNotificationSettingsPushTokens = new SchedulingApiNotificationSettingsPushTokens(this.api,
		this as unknown as SchedulingApiNotificationSettings, false, false);
	public aiPushTokens = this.pushTokensWrapper.aiThis;

	/**
	 * A list of the push-tokens registered for this user. This list is read-only. To modify this user's push-tokens use the "/push_token" api.
	 */
	get pushTokens() : SchedulingApiNotificationSettingsPushTokens {
		this.getterDebugValidations(this.aiPushTokens, false);
		return this.pushTokensWrapper;
	}

	set pushTokensTestSetter(v : SchedulingApiNotificationSettingsPushTokens) {
        this.setterImpl(1, v.rawData, 'pushTokens', true, null, function(this : SchedulingApiNotificationSettings) {this.pushTokensWrapper = v;});
	}

	private settingsForDeviceTypesWrapper : SchedulingApiNotificationSettingsSettingsForDeviceTypes = new SchedulingApiNotificationSettingsSettingsForDeviceTypes(this.api,
		this as unknown as SchedulingApiNotificationSettings, false, false);
	public aiSettingsForDeviceTypes = this.settingsForDeviceTypesWrapper.aiThis;

	/**
	 * A list of settings for each available device type. Send when data="notificationSettings".
	 */
	get settingsForDeviceTypes() : SchedulingApiNotificationSettingsSettingsForDeviceTypes {
		this.getterDebugValidations(this.aiSettingsForDeviceTypes, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiSettingsForDeviceTypes.defaultValue;
			this.settingsForDeviceTypesWrapper._updateRawData(this.data[2], true);
		}
		return this.settingsForDeviceTypesWrapper;
	}

	set settingsForDeviceTypesTestSetter(v : SchedulingApiNotificationSettingsSettingsForDeviceTypes) {
        this.setterImpl(2, v.rawData, 'settingsForDeviceTypes', true, null, function(this : SchedulingApiNotificationSettings) {this.settingsForDeviceTypesWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.pushTokensWrapper._fixIds(_idReplacements);
		this.settingsForDeviceTypesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('NOTIFICATION_SETTINGS should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.pushTokensWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.settingsForDeviceTypesWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '22';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '22', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiNotificationSettingsPushTokens extends ApiListWrapper<SchedulingApiNotificationSettingsPushToken>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiNotificationSettings | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'pushTokens');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiNotificationSettingsPushToken {
		return new SchedulingApiNotificationSettingsPushToken(this.api, this as unknown as SchedulingApiNotificationSettingsPushTokens, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiNotificationSettings | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiNotificationSettingsPushTokens(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '487';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiNotificationSettingsPushToken) => void) | null = null, _backendId : Id | null = null) : SchedulingApiNotificationSettingsPushToken {
		const newItem = new SchedulingApiNotificationSettingsPushToken(this.api, this as unknown as SchedulingApiNotificationSettingsPushTokens, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('pushTokens');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationSettingsPushTokens, SchedulingApiNotificationSettingsPushTokens> = new ApiAttributeInfo<SchedulingApiNotificationSettingsPushTokens, SchedulingApiNotificationSettingsPushTokens>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsPushTokens,
			name: 'pushTokens',
			nodeName: 'NOTIFICATION_SETTINGS_PUSH_TOKENS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiNotificationSettingsPushTokens, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
}

				 
export class SchedulingApiNotificationSettingsPushToken extends ApiObjectWrapper<SchedulingApiNotificationSettingsPushToken>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiNotificationSettingsPushTokens | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiNotificationSettingsPushToken> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiNotificationSettingsPushToken as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, SchedulingApiNotificationSettingsPushToken> = new ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, SchedulingApiNotificationSettingsPushToken>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsPushToken,
			name: 'notificationSettingsPushToken',
			nodeName: 'NOTIFICATION_SETTINGS_PUSH_TOKEN',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiNotificationSettingsPushToken, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiToken : ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, string> = new ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, string>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsPushToken,
			name: 'token',
			nodeName: 'NOTIFICATION_SETTINGS_PUSH_TOKEN_TOKEN',
			type: PApiType.string,
			validations: function(this : SchedulingApiNotificationSettingsPushToken) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiNotificationSettingsPushToken) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiType : ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, SchedulingApiPushTokenType> = new ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, SchedulingApiPushTokenType>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsPushToken,
			name: 'type',
			nodeName: 'NOTIFICATION_SETTINGS_PUSH_TOKEN_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiNotificationSettingsPushToken) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiNotificationSettingsPushToken) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiIsDisabled : ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, boolean> = new ApiAttributeInfo<SchedulingApiNotificationSettingsPushToken, boolean>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsPushToken,
			name: 'isDisabled',
			nodeName: 'NOTIFICATION_SETTINGS_PUSH_TOKEN_IS_DISABLED',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiNotificationSettingsPushToken) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiNotificationSettingsPushToken) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiNotificationSettingsPushToken, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});

	/**
	 * The token.
	 *
	 * @type {string}
	 */
	get token() : string {
		this.getterDebugValidations(this.aiToken, false);
		return this.data[1];
	}

	set token(v : string) {
        this.setterImpl(1, v, 'token', false, null, null);
	}

	/**
	 * The type of the Push Token (Web, Android, iOS).
	 *
	 * @type {SchedulingApiPushTokenType}
	 */
	get type() : SchedulingApiPushTokenType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[2];
	}

	set type(v : SchedulingApiPushTokenType) {
        this.setterImpl(2, v, 'type', false, null, null);
	}

	/**
	 * Is this push-token disabled? Then no push-notifications will be sent to it. See "PushToken.java" for workflows when a token is disabled.
	 *
	 * @type {boolean}
	 */
	get isDisabled() : boolean {
		this.getterDebugValidations(this.aiIsDisabled, false);
		return this.data[3];
	}

	set isDisabled(v : boolean) {
        this.setterImpl(3, v, 'isDisabled', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('NOTIFICATION_SETTINGS_PUSH_TOKEN should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '489';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '489', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiPushTokenType {
	WEB = 1,
	ANDROID = 2,
	IOS = 3,
}
		 export class SchedulingApiNotificationSettingsSettingsForDeviceTypes extends ApiListWrapper<SchedulingApiNotificationSettingsSettingsForDeviceType>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiNotificationSettings | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'settingsForDeviceTypes');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiNotificationSettingsSettingsForDeviceType {
		return new SchedulingApiNotificationSettingsSettingsForDeviceType(this.api, this as unknown as SchedulingApiNotificationSettingsSettingsForDeviceTypes, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiNotificationSettings | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiNotificationSettingsSettingsForDeviceTypes(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '488';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiNotificationSettingsSettingsForDeviceType) => void) | null = null, _backendId : Id | null = null) : SchedulingApiNotificationSettingsSettingsForDeviceType {
		const newItem = new SchedulingApiNotificationSettingsSettingsForDeviceType(this.api, this as unknown as SchedulingApiNotificationSettingsSettingsForDeviceTypes, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('settingsForDeviceTypes');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypes, SchedulingApiNotificationSettingsSettingsForDeviceTypes> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypes, SchedulingApiNotificationSettingsSettingsForDeviceTypes>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceTypes,
			name: 'settingsForDeviceTypes',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPES',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiNotificationSettingsSettingsForDeviceTypes) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'notificationSettings'));
			},
			defaultValue: function(this : SchedulingApiNotificationSettingsSettingsForDeviceTypes, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 2,
		});
}

				 
export class SchedulingApiNotificationSettingsSettingsForDeviceType extends ApiObjectWrapper<SchedulingApiNotificationSettingsSettingsForDeviceType>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiNotificationSettingsSettingsForDeviceTypes | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiNotificationSettingsSettingsForDeviceType> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiNotificationSettingsSettingsForDeviceType as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceType, SchedulingApiNotificationSettingsSettingsForDeviceType> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceType, SchedulingApiNotificationSettingsSettingsForDeviceType>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceType,
			name: 'notificationSettingsSettingsForDeviceType',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE',
			type: PApiType.ApiObject,
			isAvailableByBusinessLogic: function(this : SchedulingApiNotificationSettingsSettingsForDeviceType) {
				return true;
			},
			defaultValue: function(this : SchedulingApiNotificationSettingsSettingsForDeviceType, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiDeviceType : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceType, SchedulingApiNotificationSettingsDeviceType> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceType, SchedulingApiNotificationSettingsDeviceType>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceType,
			name: 'deviceType',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_DEVICE_TYPE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});

	private notificationGroupsWrapper : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups = new SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups(this.api,
		this as unknown as SchedulingApiNotificationSettingsSettingsForDeviceType, false, false);
	public aiNotificationGroups = this.notificationGroupsWrapper.aiThis;

	/**
	 * A list of all notification groups.
	 */
	get notificationGroups() : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups {
		this.getterDebugValidations(this.aiNotificationGroups, false);
		return this.notificationGroupsWrapper;
	}

	set notificationGroupsTestSetter(v : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups) {
        this.setterImpl(1, v.rawData, 'notificationGroups', true, null, function(this : SchedulingApiNotificationSettingsSettingsForDeviceType) {this.notificationGroupsWrapper = v;});
	}

	/**
	 * For which device type does this item define settings? Read-only.
	 *
	 * @type {SchedulingApiNotificationSettingsDeviceType}
	 */
	get deviceType() : SchedulingApiNotificationSettingsDeviceType {
		this.getterDebugValidations(this.aiDeviceType, false);
		return this.data[2];
	}

	set deviceTypeTestSetter(v : SchedulingApiNotificationSettingsDeviceType) {
        this.setterImpl(2, v, 'deviceType', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.notificationGroupsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.notificationGroupsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '493';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '493', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups extends ApiListWrapper<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiNotificationSettingsSettingsForDeviceType | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'notificationGroups');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup {
		return new SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup(this.api, this as unknown as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiNotificationSettingsSettingsForDeviceType | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '494';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup) => void) | null = null, _backendId : Id | null = null) : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup {
		const newItem = new SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup(this.api, this as unknown as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('notificationGroups');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups, SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups, SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups,
			name: 'notificationGroups',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUPS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
}

				 
export class SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup extends ApiObjectWrapper<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroups | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup,
			name: 'notificationSettingsSettingsForDeviceTypeNotificationGroup',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiEnabled : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, boolean> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, boolean>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup,
			name: 'enabled',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_ENABLED',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiRecommendedToReceive : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, boolean> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, boolean>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup,
			name: 'recommendedToReceive',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_IS_RECOMMENDED_TO_RECEIVE',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	aiGroup : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, SchedulingApiNotificationSettingsNotificationGroup> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, SchedulingApiNotificationSettingsNotificationGroup>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup,
			name: 'group',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_GROUP',
			type: PApiType.Enum,
			rawDataIndex: 3,
		});
	aiTitle : ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, SchedulingApiNotificationSettingsNotificationTitle> = new ApiAttributeInfo<SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup, SchedulingApiNotificationSettingsNotificationTitle>({
			apiObjWrapper: this as any as SchedulingApiNotificationSettingsSettingsForDeviceTypeNotificationGroup,
			name: 'title',
			nodeName: 'NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP_TITLE',
			type: PApiType.Enum,
			rawDataIndex: 4,
		});

	/**
	 * Is notifications for this group enabled?
	 *
	 * @type {boolean}
	 */
	get enabled() : boolean {
		this.getterDebugValidations(this.aiEnabled, false);
		return this.data[1];
	}

	set enabled(v : boolean) {
        this.setterImpl(1, v, 'enabled', false, null, null);
	}

	/**
	 * When "true" it is recommended that this group is enabled at least for one device type.
	 *
	 * @type {boolean}
	 */
	get recommendedToReceive() : boolean {
		this.getterDebugValidations(this.aiRecommendedToReceive, false);
		return this.data[2];
	}

	set recommendedToReceive(v : boolean) {
        this.setterImpl(2, v, 'recommendedToReceive', false, null, null);
	}

	/**
	 * The notification group. Read-only.
	 *
	 * @type {SchedulingApiNotificationSettingsNotificationGroup}
	 */
	get group() : SchedulingApiNotificationSettingsNotificationGroup {
		this.getterDebugValidations(this.aiGroup, false);
		return this.data[3];
	}

	set group(v : SchedulingApiNotificationSettingsNotificationGroup) {
        this.setterImpl(3, v, 'group', false, null, null);
	}

	/**
	 * The title of this notification group. Read-only.
	 *
	 * @type {SchedulingApiNotificationSettingsNotificationTitle}
	 */
	get title() : SchedulingApiNotificationSettingsNotificationTitle {
		this.getterDebugValidations(this.aiTitle, false);
		return this.data[4];
	}

	set title(v : SchedulingApiNotificationSettingsNotificationTitle) {
        this.setterImpl(4, v, 'title', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('NOTIFICATION_SETTINGS_SETTINGS_FOR_DEVICE_TYPE_NOTIFICATION_GROUP should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '496';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '496', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiNotificationSettingsNotificationGroup {
	SHIFTS_ASSIGNMENT_PROCESS_GENERAL = 1,
	SHIFTS_ASSIGNMENT_PROCESS_REMINDER = 2,
	SHIFTS_ASSIGNMENT_PROCESS_REPORTS = 3,
	SHIFTS_ASSIGNMENT_CHANGED = 4,
	SHIFTS_ASSIGNMENT_REMINDER = 5,
	SHIFTS_COURSE_CANCELED = 6,
	TIME_STAMP_NOT_STAMPED = 7,
	TIME_STAMP_INFORM_OWNER_MEMBER_NOT_USED = 8,
	COURSE_NOT_REACHED_MIN_PARTICIPANT_COUNT = 9,
	COURSE_REACHED_MIN_PARTICIPANT_COUNT = 10,
	COURSE_INQUIRY_ARRIVED = 11,
	COURSE_INQUIRY_DECLINED = 12,
	COURSE_BOOKED = 13,
	COURSE_BOOKING_CANCELED_BY_MEMBER = 14,
	COURSE_BOOKING_CANCELED_BY_BOOKING_PERSON = 15,
	COURSE_BOOKING_CANCELED_MANUAL_REFUND_NEEDED = 16,
	COURSE_INFORM_BOOKING_COMMENT = 17,
	ONLINE_PAYMENT_FAILED = 18,
	ONLINE_REFUNDED_BY_MEMBER = 19,
	ONLINE_REFUND_FAILED = 20,
	GIFT_CARD_BOOKED = 21,
	GIFT_CARD_CANCELED = 22,
	REFUND_GIFT_CARD_BOOKED = 23,
	SHIFT_EXCHANGE_GENERAL = 24,
	SHIFT_EXCHANGE_REMINDER = 25,
	SHIFT_EXCHANGE_ITEM_CREATED = 26,
	SHIFT_EXCHANGE_ITEM_SUCCESSFUL = 27,
	SHIFT_EXCHANGE_ITEM_SUCCESSFUL_WITH_WARNINGS = 28,
	SHIFT_EXCHANGE_ITEM_FAILED = 29,
}
export enum SchedulingApiNotificationSettingsNotificationTitle {
	TITLE_SHIFT_EXCHANGE = 1,
	TITLE_BOOKING_SYSTEM = 2,
	TITLE_TIME_STAMP = 3,
	TITLE_SHIFTS = 4,
}
export enum SchedulingApiNotificationSettingsDeviceType {
	MAIL = 1,
	APP_PUSH_NOTIFICATION = 2,
	WEB_PUSH_NOTIFICATION = 3,
}
		 
export class SchedulingApiGiftCardSettingsBase extends ApiObjectWrapper<SchedulingApiGiftCardSettings>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiGiftCardSettings> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiGiftCardSettings as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiGiftCardSettings, SchedulingApiGiftCardSettings> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, SchedulingApiGiftCardSettings>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'giftCardSettings',
			nodeName: 'GIFT_CARD_SETTINGS',
			type: PApiType.ApiObject,
			hasPermissionToSet: function(this : SchedulingApiGiftCardSettings) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => { return this.checkFreePriceChoiceOrPredefinedPricesIsAvailable() },
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCardSettings, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 22,
		});
	aiIsSaleEnabled : ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'isSaleEnabled',
			nodeName: 'GIFT_CARD_SETTINGS_IS_SALE_ENABLED',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : SchedulingApiGiftCardSettings) {
				{const conditionValue = this.api.data.isOnlinePaymentAvailable; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um Gutscheine online verkaufen & bezahlen lassen zu können, aktiviere bitte erst die <a href="client/plugin/payments" target="_blank">Online-Zahlung</a>.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCardSettings, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiWaysToRedeem : ApiAttributeInfo<SchedulingApiGiftCardSettings, SchedulingApiGiftCardSettingsWaysToRedeem> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, SchedulingApiGiftCardSettingsWaysToRedeem>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'waysToRedeem',
			nodeName: 'GIFT_CARD_SETTINGS_WAYS_TO_REDEEM',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 2,
			attributeValueInfos: new Map([
				[
					SchedulingApiGiftCardSettingsWaysToRedeem.ONLINE_AND_POS,
					new ApiAttributeValueInfo<SchedulingApiGiftCardSettings>({
						apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
						canSetByBusinessLogic: function(this : SchedulingApiGiftCardSettings) {
				{const conditionValue = this.posSynchronizationActivatedDateTime !== null; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um diese Option zu nutzen, musst du erst die <a href="client/plugin/gift-cards/synchronization" target="_blank">Synchronisation mit der Kasse »Boulderado« aktivieren</a>.', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiGiftCardSettingsWaysToRedeem.POS,
					new ApiAttributeValueInfo<SchedulingApiGiftCardSettings>({
						apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
						canSetByBusinessLogic: function(this : SchedulingApiGiftCardSettings) {
				{const conditionValue = this.posSynchronizationActivatedDateTime !== null; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um diese Option zu nutzen, musst du erst die <a href="client/plugin/gift-cards/synchronization" target="_blank">Synchronisation mit der Kasse »Boulderado« aktivieren</a>.', params: false} : undefined;}
				return true;
						},
					}),
				],
			]),
		});
	aiFreePriceChoice : ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'freePriceChoice',
			nodeName: 'GIFT_CARD_SETTINGS_FREE_PRICE_CHOICE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCardSettings, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	aiMinPrice : ApiAttributeInfo<SchedulingApiGiftCardSettings, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'minPrice',
			nodeName: 'GIFT_CARD_SETTINGS_MIN_PRICE',
			type: PApiType.ClientCurrency,
			isAvailableByBusinessLogic: function(this : SchedulingApiGiftCardSettings) {
				return ((this.aiFreePriceChoice.isAvailable && this.freePriceChoice));
			},
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
		if(((this.aiFreePriceChoice.isAvailable && this.freePriceChoice)))
		{
			return this.api.validators.required(PApiType.ClientCurrency, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.ClientCurrency, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.maxPrice, false, PApiType.ClientCurrency, this.aiMaxPrice.id, undefined);						return null;
					},
					() => {
return this.api.validators.max(9999, false, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiMaxPrice : ApiAttributeInfo<SchedulingApiGiftCardSettings, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'maxPrice',
			nodeName: 'GIFT_CARD_SETTINGS_MAX_PRICE',
			type: PApiType.ClientCurrency,
			isAvailableByBusinessLogic: function(this : SchedulingApiGiftCardSettings) {
				return ((this.aiFreePriceChoice.isAvailable && this.freePriceChoice));
			},
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
		if(((this.aiFreePriceChoice.isAvailable && this.freePriceChoice)))
		{
			return this.api.validators.required(PApiType.ClientCurrency, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.ClientCurrency, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(() => this.minPrice, false, PApiType.ClientCurrency, this.aiMinPrice.id, undefined);						return null;
					},
					() => {
return this.api.validators.max(9999, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiHomepageDescriptionText : ApiAttributeInfo<SchedulingApiGiftCardSettings, string> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'homepageDescriptionText',
			nodeName: 'GIFT_CARD_SETTINGS_HOMEPAGE_DESCRIPTION_TEXT',
			type: PApiType.string,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiExpirationDuration : ApiAttributeInfo<SchedulingApiGiftCardSettings, Years> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Years>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'expirationDuration',
			nodeName: 'GIFT_CARD_SETTINGS_EXPIRATION_DURATION',
			type: PApiType.Years,
			rawDataIndex: 8,
		});
	aiContactName : ApiAttributeInfo<SchedulingApiGiftCardSettings, string> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'contactName',
			nodeName: 'GIFT_CARD_SETTINGS_CONTACT_NAME',
			type: PApiType.string,
			rawDataIndex: 9,
		});
	aiContactEmail : ApiAttributeInfo<SchedulingApiGiftCardSettings, Email> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Email>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'contactEmail',
			nodeName: 'GIFT_CARD_SETTINGS_CONTACT_EMAIL',
			type: PApiType.Email,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiContactPhone : ApiAttributeInfo<SchedulingApiGiftCardSettings, Tel> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Tel>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'contactPhone',
			nodeName: 'GIFT_CARD_SETTINGS_CONTACT_PHONE',
			type: PApiType.Tel,
			rawDataIndex: 11,
		});
	aiCodePrefix : ApiAttributeInfo<SchedulingApiGiftCardSettings, string> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'codePrefix',
			nodeName: 'GIFT_CARD_SETTINGS_CODE_PREFIX',
			type: PApiType.string,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {return this.api.validators.pattern(/^[A-Za-z0-9\-_]+$/, 'Bitte nur folgende Zeichen benutzen: A-Z, 0-9, -, _.')},
					() => {
						return this.api.validators.maxLength(7, PApiType.string, undefined);
					},
					() => {
		if(((this.codeOnlyContainsDigits)))
		{
			return this.api.validators.maxDecimalPlacesCount(0, PApiType.string, undefined);
		}
						return null;
					},
					() => {
		if(((this.codeOnlyContainsDigits)))
		{
			return this.api.validators.number(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiCodeLength : ApiAttributeInfo<SchedulingApiGiftCardSettings, Integer> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Integer>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'codeLength',
			nodeName: 'GIFT_CARD_SETTINGS_CODE_LENGTH',
			type: PApiType.Integer,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
					() => {
return this.api.validators.min(9, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(12, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiCodeOnlyContainsDigits : ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'codeOnlyContainsDigits',
			nodeName: 'GIFT_CARD_SETTINGS_CODE_ONLY_CONTAINS_DIGITS',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : SchedulingApiGiftCardSettings) {
				{const conditionValue = this.waysToRedeem !== SchedulingApiGiftCardSettingsWaysToRedeem.ONLINE; if(!conditionValue) return conditionValue === false ? {sourceString: 'Solange Gutscheine online eingelöst werden können, ist es aus Sicherheitsgründen nicht möglich, die Gutschein-Codes auf Ziffern zu beschränken.', params: false} : undefined;}
				{const conditionValue = this.waysToRedeem !== SchedulingApiGiftCardSettingsWaysToRedeem.ONLINE_AND_POS; if(!conditionValue) return conditionValue === false ? {sourceString: 'Solange Gutscheine online eingelöst werden können, ist es aus Sicherheitsgründen nicht möglich, die Gutschein-Codes auf Ziffern zu beschränken.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCardSettings, _nodeId : string) {return false;},
			rawDataIndex: 14,
		});
	aiCustomTemplate : ApiAttributeInfo<SchedulingApiGiftCardSettings, Pdf> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Pdf>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'customTemplate',
			nodeName: 'GIFT_CARD_SETTINGS_CUSTOM_TEMPLATE',
			type: PApiType.Pdf,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
						return this.api.validators.maxFileSize(3072);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
						return this.api.asyncValidators.asyncPdfPagesCount(1);
					},
					() => {
						return this.api.asyncValidators.asyncPdfPageDimension('A4');
					},
				];
			}
			,
			rawDataIndex: 15,
		});
	aiFontColor : ApiAttributeInfo<SchedulingApiGiftCardSettings, Color> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Color>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'fontColor',
			nodeName: 'GIFT_CARD_SETTINGS_FONT_COLOR',
			type: PApiType.Color,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.Color, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiBackgroundColor : ApiAttributeInfo<SchedulingApiGiftCardSettings, Color> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Color>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'backgroundColor',
			nodeName: 'GIFT_CARD_SETTINGS_BACKGROUND_COLOR',
			type: PApiType.Color,
			rawDataIndex: 17,
		});
	aiVatPercent : ApiAttributeInfo<SchedulingApiGiftCardSettings, Percent> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Percent>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'vatPercent',
			nodeName: 'GIFT_CARD_SETTINGS_VAT_PERCENT',
			type: PApiType.Percent,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiPosAccount : ApiAttributeInfo<SchedulingApiGiftCardSettings, string> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'posAccount',
			nodeName: 'GIFT_CARD_SETTINGS_POS_ACCOUNT',
			type: PApiType.string,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
						return this.api.validators.maxLength(30, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiRedeemableSinglePurposeGiftCardCount : ApiAttributeInfo<SchedulingApiGiftCardSettings, Integer> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Integer>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'redeemableSinglePurposeGiftCardCount',
			nodeName: 'GIFT_CARD_SETTINGS_REDEEMABLE_SINGLE_PURPOSE_GIFT_CARD_COUNT',
			type: PApiType.Integer,
			hasPermissionToSet: () => false,
			rawDataIndex: 20,
		});
	aiProhibitRedemptionForOtherVatPercent : ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'prohibitRedemptionForOtherVatPercent',
			nodeName: 'GIFT_CARD_SETTINGS_PROHIBIT_REDEMPTION_FOR_OTHER_VAT_PERCENT',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCardSettings, _nodeId : string) {return true;},
			rawDataIndex: 21,
		});
	aiGiftCardsSoldThroughPosBeforeDateAreUnsafe : ApiAttributeInfo<SchedulingApiGiftCardSettings, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'giftCardsSoldThroughPosBeforeDateAreUnsafe',
			nodeName: 'GIFT_CARD_SETTINGS_GIFT_CARDS_SOLD_THROUGH_POS_BEFORE_DATE_ARE_UNSAFE',
			type: PApiType.DateExclusiveEnd,
			rawDataIndex: 22,
		});
	aiPosSynchronizationPermittedDateTime : ApiAttributeInfo<SchedulingApiGiftCardSettings, DateTime> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, DateTime>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'posSynchronizationPermittedDateTime',
			nodeName: 'GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_PERMITTED_DATE_TIME',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 23,
		});
	aiPosSynchronizationPermittedByMemberId : ApiAttributeInfo<SchedulingApiGiftCardSettings, Id> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, Id>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'posSynchronizationPermittedByMemberId',
			nodeName: 'GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_PERMITTED_BY_MEMBER_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 24,
		});
	aiPosSynchronizationPermitted : ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'posSynchronizationPermitted',
			nodeName: 'GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_PERMITTED',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiGiftCardSettings) {
				{const conditionValue = this.posSynchronizationPermitted ||
						(this.isSaleEnabled && this.api.data.posSystem === SchedulingApiPosSystem.BOULDERADO); if(!conditionValue) return conditionValue === false ? {sourceString: this.isSaleEnabled ? 'Bitte verbinde die Schnittstelle zur Kassensoftware »Boulderado«, um fortfahren zu können.' : 'Bitte aktiviere den Gutscheinverkauf, um fortfahren zu können.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiGiftCardSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCardSettings, _nodeId : string) {return false;},
			rawDataIndex: 25,
		});
	aiPosSynchronizationActivatedDateTime : ApiAttributeInfo<SchedulingApiGiftCardSettings, DateTime> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, DateTime>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'posSynchronizationActivatedDateTime',
			nodeName: 'GIFT_CARD_SETTINGS_POS_SYNCHRONIZATION_ACTIVATED_DATE_TIME',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 26,
		});
	aiHasGiftCards : ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean> = new ApiAttributeInfo<SchedulingApiGiftCardSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettings,
			name: 'hasGiftCards',
			nodeName: 'GIFT_CARD_SETTINGS_HAS_GIFT_CARDS',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiGiftCardSettings, _nodeId : string) {return false;},
			rawDataIndex: 27,
		});

	/**
	 * Is giftCard sale enabled?
	 *
	 * @type {boolean}
	 */
	get isSaleEnabled() : boolean {
		this.getterDebugValidations(this.aiIsSaleEnabled, false);
		return this.data[1];
	}

	set isSaleEnabled(v : boolean) {
        this.setterImpl(1, v, 'isSaleEnabled', false, null, null);
	}

	/**
	 * How can gift-cards be redeemed?
	 *
	 * @type {SchedulingApiGiftCardSettingsWaysToRedeem}
	 */
	get waysToRedeem() : SchedulingApiGiftCardSettingsWaysToRedeem {
		this.getterDebugValidations(this.aiWaysToRedeem, false);
		return this.data[2];
	}

	set waysToRedeem(v : SchedulingApiGiftCardSettingsWaysToRedeem) {
        this.setterImpl(2, v, 'waysToRedeem', false, null, null);
	}

	/**
	 * Can the booking person choose the gift card price freely? Respecting "minPrice" and "maxPrice".
	 *
	 * @type {boolean}
	 */
	get freePriceChoice() : boolean {
		this.getterDebugValidations(this.aiFreePriceChoice, false);
		return this.data[3];
	}

	set freePriceChoice(v : boolean) {
        this.setterImpl(3, v, 'freePriceChoice', false, null, null);
	}

	/**
	 * Minimal giftCard sale value. This value must be an integer.
	 *
	 * @type {ClientCurrency}
	 */
	get minPrice() : ClientCurrency | null {
		this.getterDebugValidations(this.aiMinPrice, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiMinPrice.defaultValue;
			
		}
		return this.data[4];
	}

	set minPrice(v : ClientCurrency | null) {
        this.setterImpl(4, v, 'minPrice', false, null, null);
	}

	/**
	 * Maximal giftCard sale value. This value must be an integer.
	 *
	 * @type {ClientCurrency}
	 */
	get maxPrice() : ClientCurrency | null {
		this.getterDebugValidations(this.aiMaxPrice, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiMaxPrice.defaultValue;
			
		}
		return this.data[5];
	}

	set maxPrice(v : ClientCurrency | null) {
        this.setterImpl(5, v, 'maxPrice', false, null, null);
	}

	private predefinedPricesWrapper : SchedulingApiGiftCardSettingsPredefinedPrices = new SchedulingApiGiftCardSettingsPredefinedPrices(this.api,
		this as unknown as SchedulingApiGiftCardSettings, false, false);
	public aiPredefinedPrices = this.predefinedPricesWrapper.aiThis;

	/**
	 * A list of predefined prices which will be offered to the booking person.
	 */
	get predefinedPrices() : SchedulingApiGiftCardSettingsPredefinedPrices {
		this.getterDebugValidations(this.aiPredefinedPrices, false);
		return this.predefinedPricesWrapper;
	}

	set predefinedPricesTestSetter(v : SchedulingApiGiftCardSettingsPredefinedPrices) {
        this.setterImpl(6, v.rawData, 'predefinedPrices', true, null, function(this : SchedulingApiGiftCardSettings) {this.predefinedPricesWrapper = v;});
	}

	/**
	 * The html text which will be shown on the booking plugin.
	 *
	 * @type {string}
	 */
	get homepageDescriptionText() : string {
		this.getterDebugValidations(this.aiHomepageDescriptionText, false);
		return this.data[7];
	}

	set homepageDescriptionText(v : string) {
        this.setterImpl(7, v, 'homepageDescriptionText', false, null, null);
	}

	/**
	 * The expiration duration of the giftCards.
	 *
	 * @type {Years}
	 */
	get expirationDuration() : Years | null {
		this.getterDebugValidations(this.aiExpirationDuration, false);
		return this.data[8];
	}

	set expirationDuration(v : Years | null) {
        this.setterImpl(8, v, 'expirationDuration', false, null, null);
	}

	/**
	 * The name of the contact-person concerning gift-cards.
	 *
	 * @type {string}
	 */
	get contactName() : string | null {
		this.getterDebugValidations(this.aiContactName, false);
		return this.data[9];
	}

	set contactName(v : string | null) {
        this.setterImpl(9, v, 'contactName', false, null, null);
	}

	/**
	 * The email of the contact-person concerning gift-cards. This will also be used as reply-to address for emails being sent out concerning gift-cards.
	 *
	 * @type {Email}
	 */
	get contactEmail() : Email {
		this.getterDebugValidations(this.aiContactEmail, false);
		return this.data[10];
	}

	set contactEmail(v : Email) {
        this.setterImpl(10, v, 'contactEmail', false, null, null);
	}

	/**
	 * The phone of the contact-person concerning gift-cards.
	 *
	 * @type {Tel}
	 */
	get contactPhone() : Tel | null {
		this.getterDebugValidations(this.aiContactPhone, false);
		return this.data[11];
	}

	set contactPhone(v : Tel | null) {
        this.setterImpl(11, v, 'contactPhone', false, null, null);
	}

	/**
	 * An optional prefix being added before the generated code.
	 *
	 * @type {string}
	 */
	get codePrefix() : string {
		this.getterDebugValidations(this.aiCodePrefix, false);
		return this.data[12];
	}

	set codePrefix(v : string) {
        this.setterImpl(12, v, 'codePrefix', false, null, null);
	}

	/**
	 * The length of the generated code. Should be greater equal 9. This value does not include the "giftCardCodePrefix".
	 *
	 * @type {Integer}
	 */
	get codeLength() : Integer {
		this.getterDebugValidations(this.aiCodeLength, false);
		return this.data[13];
	}

	set codeLength(v : Integer) {
        this.setterImpl(13, v, 'codeLength', false, null, null);
	}

	/**
	 * Should the generated code only contain digits? If "false" it will also contain alphabetic characters.
	 *
	 * @type {boolean}
	 */
	get codeOnlyContainsDigits() : boolean {
		this.getterDebugValidations(this.aiCodeOnlyContainsDigits, false);
		return this.data[14];
	}

	set codeOnlyContainsDigits(v : boolean) {
        this.setterImpl(14, v, 'codeOnlyContainsDigits', false, null, null);
	}

	/**
	 * A custom template used to generate the gift-cards. If "null" is returned then the default template will be used.
	 *
	 * @type {Pdf}
	 */
	get customTemplate() : Pdf | null {
		this.getterDebugValidations(this.aiCustomTemplate, false);
		const path = this.data[15] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set customTemplate(v : Pdf | null) {
        this.setterImpl(15, v, 'customTemplate', false, null, null);
	}

	/**
	 * The font-color to be used to print any text on the gift-card template.
	 *
	 * @type {Color}
	 */
	get fontColor() : Color {
		this.getterDebugValidations(this.aiFontColor, false);
		return this.data[16];
	}

	set fontColor(v : Color) {
        this.setterImpl(16, v, 'fontColor', false, null, null);
	}

	/**
	 * The background color to be used for the area where we print text on the gift-card template.
	 *
	 * @type {Color}
	 */
	get backgroundColor() : Color | null {
		this.getterDebugValidations(this.aiBackgroundColor, false);
		return this.data[17];
	}

	set backgroundColor(v : Color | null) {
        this.setterImpl(17, v, 'backgroundColor', false, null, null);
	}

	/**
	 * The vat-percent of newly sold gift-cards.
	 *
	 * @type {Percent}
	 */
	get vatPercent() : Percent {
		this.getterDebugValidations(this.aiVatPercent, false);
		return this.data[18];
	}

	set vatPercent(v : Percent) {
        this.setterImpl(18, v, 'vatPercent', false, null, null);
	}

	/**
	 * The "Erlöskonto" of newly sold gift-cards.
	 *
	 * @type {string}
	 */
	get posAccount() : string | null {
		this.getterDebugValidations(this.aiPosAccount, false);
		return this.data[19];
	}

	set posAccount(v : string | null) {
        this.setterImpl(19, v, 'posAccount', false, null, null);
	}

	/**
	 * Number of "Einzweckgutscheine" in this client which are currently redeemable.
	 *
	 * @type {Integer}
	 */
	get redeemableSinglePurposeGiftCardCount() : Integer {
		this.getterDebugValidations(this.aiRedeemableSinglePurposeGiftCardCount, false);
		return this.data[20];
	}

	set redeemableSinglePurposeGiftCardCountTestSetter(v : Integer) {
        this.setterImpl(20, v, 'redeemableSinglePurposeGiftCardCount', true, null, null);
	}

	/**
	 * When a gift-card has vat-percent unequal 0 (So called "Einzweckgutschein") then this boolean decides if it can be used to redeem tariffs with another vat-percent. Gift cards with vat-percent equal 0 can always be used for all vat-percent (So called "Mehrzweckgutschein").
	 *
	 * @type {boolean}
	 */
	get prohibitRedemptionForOtherVatPercent() : boolean {
		this.getterDebugValidations(this.aiProhibitRedemptionForOtherVatPercent, false);
		return this.data[21];
	}

	set prohibitRedemptionForOtherVatPercent(v : boolean) {
        this.setterImpl(21, v, 'prohibitRedemptionForOtherVatPercent', false, null, null);
	}

	/**
	 * When set, all gift-cards sold through POS before this date are considered to have an unsafe code, so they will not be available for online redemption. "null" means that all gift-cards sold through POS are considered as safe.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get giftCardsSoldThroughPosBeforeDateAreUnsafe() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiGiftCardsSoldThroughPosBeforeDateAreUnsafe, false);
		return this.data[22];
	}

	set giftCardsSoldThroughPosBeforeDateAreUnsafe(v : DateExclusiveEnd | null) {
        this.setterImpl(22, v, 'giftCardsSoldThroughPosBeforeDateAreUnsafe', false, null, null);
	}

	/**
	 * The date-time when the POS-synchronization was permitted. "null" is returned when currently POS-synchronization is not permitted. Read-only.
	 *
	 * @type {DateTime}
	 */
	get posSynchronizationPermittedDateTime() : DateTime | null {
		this.getterDebugValidations(this.aiPosSynchronizationPermittedDateTime, false);
		return this.data[23];
	}

	set posSynchronizationPermittedDateTimeTestSetter(v : DateTime | null) {
        this.setterImpl(23, v, 'posSynchronizationPermittedDateTime', true, null, null);
	}

	private posSynchronizationPermittedByMemberIdWrapper : Id | null = null!;

	/**
	 * The member-id who permitted POS-synchronization. "null" is returned when currently POS-synchronization is not permitted. Read-only.
	 *
	 * @type {Id}
	 */
	get posSynchronizationPermittedByMemberId() : Id | null {
		this.getterDebugValidations(this.aiPosSynchronizationPermittedByMemberId, false);
		return this.posSynchronizationPermittedByMemberIdWrapper;
	}

	set posSynchronizationPermittedByMemberIdTestSetter(v : Id | null) {
        this.setterImpl(24, v, 'posSynchronizationPermittedByMemberId', true, null, function(this : SchedulingApiGiftCardSettings) {this.posSynchronizationPermittedByMemberIdWrapper = v;});
	}

	/**
	 * Is the POS-synchronization currently permitted? Use this attribute to give/revoke permission.
	 *
	 * @type {boolean}
	 */
	get posSynchronizationPermitted() : boolean {
		this.getterDebugValidations(this.aiPosSynchronizationPermitted, false);
		return this.data[25];
	}

	set posSynchronizationPermitted(v : boolean) {
        this.setterImpl(25, v, 'posSynchronizationPermitted', false, null, null);
	}

	/**
	 * The date-time when the POS-synchronization was activated. "null" is returned when currently POS-synchronization is not activated. Read-only.
	 *
	 * @type {DateTime}
	 */
	get posSynchronizationActivatedDateTime() : DateTime | null {
		this.getterDebugValidations(this.aiPosSynchronizationActivatedDateTime, false);
		return this.data[26];
	}

	set posSynchronizationActivatedDateTimeTestSetter(v : DateTime | null) {
        this.setterImpl(26, v, 'posSynchronizationActivatedDateTime', true, null, null);
	}

	/**
	 * Does this client current have any gift-cards?
	 *
	 * @type {boolean}
	 */
	get hasGiftCards() : boolean {
		this.getterDebugValidations(this.aiHasGiftCards, false);
		return this.data[27];
	}

	set hasGiftCardsTestSetter(v : boolean) {
        this.setterImpl(27, v, 'hasGiftCards', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.predefinedPricesWrapper._fixIds(_idReplacements);
		this.data[24] = Meta.getReplacedId(this.data[24], _idReplacements);
		this.posSynchronizationPermittedByMemberIdWrapper = this.data[24] === null ? null! : Id.create(this.data[24]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('GIFT_CARD_SETTINGS should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.predefinedPricesWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[24] : null, this.posSynchronizationPermittedByMemberIdWrapper))
			this.posSynchronizationPermittedByMemberIdWrapper = data && data[24] ? Id.create(data[24]) : null!;
	}

	protected get dni() : string {
		return '23';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '23', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiGiftCardSettingsWaysToRedeem {
	ONLINE = 1,
	POS = 2,
	ONLINE_AND_POS = 3,
	OTHER = 4,
	DISABLED = 5,
}
		 export class SchedulingApiGiftCardSettingsPredefinedPrices extends ApiListWrapper<SchedulingApiGiftCardSettingsPredefinedPrice>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiGiftCardSettings | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'predefinedPrices');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiGiftCardSettingsPredefinedPrice {
		return new SchedulingApiGiftCardSettingsPredefinedPrice(this.api, this as unknown as SchedulingApiGiftCardSettingsPredefinedPrices, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiGiftCardSettings | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiGiftCardSettingsPredefinedPrices(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '506';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiGiftCardSettingsPredefinedPrice) => void) | null = null, _backendId : Id | null = null) : SchedulingApiGiftCardSettingsPredefinedPrice {
		const newItem = new SchedulingApiGiftCardSettingsPredefinedPrice(this.api, this as unknown as SchedulingApiGiftCardSettingsPredefinedPrices, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('predefinedPrices');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrices, SchedulingApiGiftCardSettingsPredefinedPrices> = new ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrices, SchedulingApiGiftCardSettingsPredefinedPrices>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettingsPredefinedPrices,
			name: 'predefinedPrices',
			nodeName: 'GIFT_CARD_SETTINGS_PREDEFINED_PRICES',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiGiftCardSettingsPredefinedPrices) {
				return [
					() => {
						return this.api.validators.maxLength(15, PApiType.ApiList, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettingsPredefinedPrices) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCardSettingsPredefinedPrices, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 6,
		});
}

				 
export class SchedulingApiGiftCardSettingsPredefinedPriceBase extends ApiObjectWrapper<SchedulingApiGiftCardSettingsPredefinedPrice>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiGiftCardSettingsPredefinedPrices | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiGiftCardSettingsPredefinedPrice> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiGiftCardSettingsPredefinedPrice as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrice, SchedulingApiGiftCardSettingsPredefinedPrice> = new ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrice, SchedulingApiGiftCardSettingsPredefinedPrice>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettingsPredefinedPrice,
			name: 'giftCardSettingsPredefinedPrice',
			nodeName: 'GIFT_CARD_SETTINGS_PREDEFINED_PRICE',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiGiftCardSettingsPredefinedPrice, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiPrice : ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrice, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrice, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettingsPredefinedPrice,
			name: 'price',
			nodeName: 'GIFT_CARD_SETTINGS_PREDEFINED_PRICE_PRICE',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiGiftCardSettingsPredefinedPrice) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(9999, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => { return this.checkNoDuplicatePredefinedPrices() },
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettingsPredefinedPrice) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrice, string> = new ApiAttributeInfo<SchedulingApiGiftCardSettingsPredefinedPrice, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCardSettingsPredefinedPrice,
			name: 'description',
			nodeName: 'GIFT_CARD_SETTINGS_PREDEFINED_PRICE_DESCRIPTION',
			type: PApiType.string,
			validations: function(this : SchedulingApiGiftCardSettingsPredefinedPrice) {
				return [
					() => {
						return this.api.validators.maxLength(100, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCardSettingsPredefinedPrice) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});

	/**
	 * The predefined price of the gift card.
	 *
	 * @type {ClientCurrency}
	 */
	get price() : ClientCurrency {
		this.getterDebugValidations(this.aiPrice, false);
		return this.data[1];
	}

	set price(v : ClientCurrency) {
        this.setterImpl(1, v, 'price', false, null, null);
	}

	/**
	 * A description text to be shown next to the predefined price.
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[2];
	}

	set description(v : string | null) {
        this.setterImpl(2, v, 'description', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('GIFT_CARD_SETTINGS_PREDEFINED_PRICE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '528';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '528', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiGiftCardsBase extends ApiListWrapper<SchedulingApiGiftCard>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'giftCards');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiGiftCard {
		return new SchedulingApiGiftCard(this.api, this as unknown as SchedulingApiGiftCards, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiGiftCards(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '24';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiGiftCard) => void) | null = null, _backendId : Id | null = null) : SchedulingApiGiftCard {
		const newItem = new SchedulingApiGiftCard(this.api, this as unknown as SchedulingApiGiftCards, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('giftCards');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiGiftCards, SchedulingApiGiftCards> = new ApiAttributeInfo<SchedulingApiGiftCards, SchedulingApiGiftCards>({
			apiObjWrapper: this as any as SchedulingApiGiftCards,
			name: 'giftCards',
			nodeName: 'GIFT_CARDS',
			type: PApiType.ApiList,
			hasPermissionToGet: function(this : SchedulingApiGiftCards) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.currentlyDetailedLoaded instanceof SchedulingApiBooking));
			},
			hasPermissionToSet: function(this : SchedulingApiGiftCards) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiGiftCards, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 23,
		});
}

				 
export class SchedulingApiGiftCardBase extends ApiObjectWrapper<SchedulingApiGiftCard>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiGiftCards | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiGiftCard> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiGiftCard as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCard> = new ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCard>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'giftCard',
			nodeName: 'GIFT_CARD',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiGiftCard, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiFirstName : ApiAttributeInfo<SchedulingApiGiftCard, string> = new ApiAttributeInfo<SchedulingApiGiftCard, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'firstName',
			nodeName: 'GIFT_CARD_FIRST_NAME',
			type: PApiType.string,
			rawDataIndex: 1,
		});
	aiLastName : ApiAttributeInfo<SchedulingApiGiftCard, string> = new ApiAttributeInfo<SchedulingApiGiftCard, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'lastName',
			nodeName: 'GIFT_CARD_LAST_NAME',
			type: PApiType.string,
			rawDataIndex: 2,
		});
	aiBookingNumber : ApiAttributeInfo<SchedulingApiGiftCard, Integer> = new ApiAttributeInfo<SchedulingApiGiftCard, Integer>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'bookingNumber',
			nodeName: 'GIFT_CARD_BOOKING_NUMBER',
			type: PApiType.Integer,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiCode : ApiAttributeInfo<SchedulingApiGiftCard, string> = new ApiAttributeInfo<SchedulingApiGiftCard, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'code',
			nodeName: 'GIFT_CARD_CODE',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiGiftCard) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiCurrentValue : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'currentValue',
			nodeName: 'GIFT_CARD_CURRENT_VALUE',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiDateOfBooking : ApiAttributeInfo<SchedulingApiGiftCard, DateTime> = new ApiAttributeInfo<SchedulingApiGiftCard, DateTime>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'dateOfBooking',
			nodeName: 'GIFT_CARD_DATE_OF_BOOKING',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiPrice : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'price',
			nodeName: 'GIFT_CARD_PRICE',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiInitialBalance : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'initialBalance',
			nodeName: 'GIFT_CARD_INITIAL_BALANCE',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiCurrentlyPaid : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'currentlyPaid',
			nodeName: 'GIFT_CARD_CURRENTLY_PAID',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});
	aiStatus : ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCardStatus> = new ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCardStatus>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'status',
			nodeName: 'GIFT_CARD_STATUS',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiInternalNotes : ApiAttributeInfo<SchedulingApiGiftCard, string> = new ApiAttributeInfo<SchedulingApiGiftCard, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'internalNotes',
			nodeName: 'GIFT_CARD_INTERNAL_NOTES',
			type: PApiType.string,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiCancellationFee : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'cancellationFee',
			nodeName: 'GIFT_CARD_CANCELLATION_FEE',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(10000, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCard, _nodeId : string) {return 0;},
			rawDataIndex: 12,
		});
	aiVatPercent : ApiAttributeInfo<SchedulingApiGiftCard, Percent> = new ApiAttributeInfo<SchedulingApiGiftCard, Percent>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'vatPercent',
			nodeName: 'GIFT_CARD_VAT_PERCENT',
			type: PApiType.Percent,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
return this.api.validators.required(PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiGiftCard, _nodeId : string) {return this.api.data.giftCardSettings.vatPercent;},
			rawDataIndex: 13,
		});
	aiOverrideWaysToRedeem : ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCardOverrideWaysToRedeem> = new ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCardOverrideWaysToRedeem>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'overrideWaysToRedeem',
			nodeName: 'GIFT_CARD_OVERRIDE_WAYS_TO_REDEEM',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiGiftCard) {
				return ((this.api.data.giftCardSettings.waysToRedeem !== SchedulingApiGiftCardSettingsWaysToRedeem.OTHER));
			},
			rawDataIndex: 14,
		});
	aiIsCurrentValueKnown : ApiAttributeInfo<SchedulingApiGiftCard, boolean> = new ApiAttributeInfo<SchedulingApiGiftCard, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'isCurrentValueKnown',
			nodeName: 'GIFT_CARD_IS_CURRENT_VALUE_KNOWN',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiGiftCard, _nodeId : string) {return false;},
			rawDataIndex: 15,
		});
	aiWaysToRedeem : ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCardSettingsWaysToRedeem> = new ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiGiftCardSettingsWaysToRedeem>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'waysToRedeem',
			nodeName: 'GIFT_CARD_WAYS_TO_REDEEM',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 16,
		});
	aiEmail : ApiAttributeInfo<SchedulingApiGiftCard, Email> = new ApiAttributeInfo<SchedulingApiGiftCard, Email>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'email',
			nodeName: 'GIFT_CARD_EMAIL',
			type: PApiType.Email,
			rawDataIndex: 17,
		});
	aiExpirationDate : ApiAttributeInfo<SchedulingApiGiftCard, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiGiftCard, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'expirationDate',
			nodeName: 'GIFT_CARD_EXPIRATION_DATE',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
return this.api.validators.min(this.api.pMoment.m(this.dateOfBooking).startOf('day').valueOf(), true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
					() => {
		if(((this.forceExpirationDateBeAlignedWithStatus && this.status === SchedulingApiGiftCardStatus.EXPIRED)))
		{
			return this.api.validators.max(+this.api.pMoment.daysFromNow(0), true, PApiType.DateExclusiveEnd, undefined, undefined);
		}
						return null;
					},
					() => {
		if(((this.forceExpirationDateBeAlignedWithStatus && this.status === SchedulingApiGiftCardStatus.EXPIRED)))
		{
			return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);
		}
						return null;
					},
					() => {
		if(((this.forceExpirationDateBeAlignedWithStatus && this.status === SchedulingApiGiftCardStatus.BOOKED)))
		{
			return this.api.validators.min(+this.api.pMoment.daysFromNow(1), true, PApiType.DateExclusiveEnd, undefined, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiRedeemedAmount : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'redeemedAmount',
			nodeName: 'GIFT_CARD_REDEEMED_AMOUNT',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 19,
		});
	aiCreatedBy : ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiBookableCreatedBy> = new ApiAttributeInfo<SchedulingApiGiftCard, SchedulingApiBookableCreatedBy>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'createdBy',
			nodeName: 'GIFT_CARD_CREATED_BY',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 20,
		});
	aiMessageForRecipient : ApiAttributeInfo<SchedulingApiGiftCard, string> = new ApiAttributeInfo<SchedulingApiGiftCard, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'messageForRecipient',
			nodeName: 'GIFT_CARD_MESSAGE_FOR_RECIPIENT',
			type: PApiType.string,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : SchedulingApiGiftCard) {
				{const conditionValue = this.createdBy !== SchedulingApiBookableCreatedBy.MARKETING_GIFT_CARD; if(!conditionValue) return conditionValue === false ? {sourceString: 'Marketing-Gutscheine werden nicht erworben, sondern von deinem Betrieb für die Kunden ausgestellt. Deshalb steht diese Nachricht nicht zur Verfügung.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
						return this.api.validators.maxLength(30, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiPosAccount : ApiAttributeInfo<SchedulingApiGiftCard, string> = new ApiAttributeInfo<SchedulingApiGiftCard, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'posAccount',
			nodeName: 'GIFT_CARD_POS_ACCOUNT',
			type: PApiType.string,
			isDetailedAttribute: true,
			validations: function(this : SchedulingApiGiftCard) {
				return [
					() => {
						return this.api.validators.maxLength(30, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiGiftCard) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiPaidBeforeTransactionListIntroduction : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'paidBeforeTransactionListIntroduction',
			nodeName: 'GIFT_CARD_PAID_BEFORE_TRANSACTION_LIST_INTRODUCTION',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 23,
		});
	aiRefundLimitDueToOnlineBalance : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'refundLimitDueToOnlineBalance',
			nodeName: 'GIFT_CARD_ACCOUNT_REFUND_LIMIT_DUE_TO_ONLINE_BALANCE',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 24,
		});
	aiIsAnonymized : ApiAttributeInfo<SchedulingApiGiftCard, boolean> = new ApiAttributeInfo<SchedulingApiGiftCard, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'isAnonymized',
			nodeName: 'GIFT_CARD_IS_ANONYMIZED',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiGiftCard, _nodeId : string) {return false;},
			rawDataIndex: 25,
		});
	aiIsCodeSafeForOnlineRedemption : ApiAttributeInfo<SchedulingApiGiftCard, boolean> = new ApiAttributeInfo<SchedulingApiGiftCard, boolean>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'isCodeSafeForOnlineRedemption',
			nodeName: 'GIFT_CARD_IS_CODE_SAFE_FOR_ONLINE_REDEMPTION',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiGiftCard, _nodeId : string) {return false;},
			rawDataIndex: 26,
		});
	aiCreatedForBookingId : ApiAttributeInfo<SchedulingApiGiftCard, Id> = new ApiAttributeInfo<SchedulingApiGiftCard, Id>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'createdForBookingId',
			nodeName: 'GIFT_CARD_CREATED_FOR_BOOKING_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 27,
		});
	aiMinShoppingCardValue : ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency> = new ApiAttributeInfo<SchedulingApiGiftCard, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'minShoppingCardValue',
			nodeName: 'GIFT_CARD_MIN_SHOPPER_CARD_VALUE',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 28,
		});
	aiTestingDeferredPaymentToken : ApiAttributeInfo<SchedulingApiGiftCard, string> = new ApiAttributeInfo<SchedulingApiGiftCard, string>({
			apiObjWrapper: this as any as SchedulingApiGiftCard,
			name: 'testingDeferredPaymentToken',
			nodeName: 'GIFT_CARD_TESTING_DEFERRED_PAYMENT_TOKEN',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToGet: function(this : SchedulingApiGiftCard) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 29,
		});

	/**
	 * First name of gift card owner.
	 *
	 * @type {string}
	 */
	get firstName() : string | null {
		this.getterDebugValidations(this.aiFirstName, false);
		return this.data[1];
	}

	set firstName(v : string | null) {
        this.setterImpl(1, v, 'firstName', false, null, null);
	}

	/**
	 * Last name of gift card owner.
	 *
	 * @type {string}
	 */
	get lastName() : string | null {
		this.getterDebugValidations(this.aiLastName, false);
		return this.data[2];
	}

	set lastName(v : string | null) {
        this.setterImpl(2, v, 'lastName', false, null, null);
	}

	/**
	 * The booking number of this gift card.
	 *
	 * @type {Integer}
	 */
	get bookingNumber() : Integer {
		this.getterDebugValidations(this.aiBookingNumber, false);
		return this.data[3];
	}

	set bookingNumberTestSetter(v : Integer) {
        this.setterImpl(3, v, 'bookingNumber', true, null, null);
	}

	/**
	 * Code of gift card.
	 *
	 * @type {string}
	 */
	get code() : string {
		this.getterDebugValidations(this.aiCode, false);
		return this.data[4];
	}

	set codeTestSetter(v : string) {
        this.setterImpl(4, v, 'code', true, null, null);
	}

	/**
	 * Current value of this gift card.
	 *
	 * @type {ClientCurrency}
	 */
	get currentValue() : ClientCurrency {
		this.getterDebugValidations(this.aiCurrentValue, false);
		return this.data[5];
	}

	set currentValueTestSetter(v : ClientCurrency) {
        this.setterImpl(5, v, 'currentValue', true, null, null);
	}

	/**
	 * When was this gift card sold?
	 *
	 * @type {DateTime}
	 */
	get dateOfBooking() : DateTime {
		this.getterDebugValidations(this.aiDateOfBooking, false);
		return this.data[6];
	}

	set dateOfBookingTestSetter(v : DateTime) {
        this.setterImpl(6, v, 'dateOfBooking', true, null, null);
	}

	/**
	 * For how much money was the gift-card sold?
	 *
	 * @type {ClientCurrency}
	 */
	get price() : ClientCurrency {
		this.getterDebugValidations(this.aiPrice, false);
		return this.data[7];
	}

	set price(v : ClientCurrency) {
        this.setterImpl(7, v, 'price', false, null, null);
	}

	/**
	 * Initial balance of the gift-card. This is can be higher than "price" either because of discounts or because the gift-card was completely free (e.g. marketing gift-cards).
	 *
	 * @type {ClientCurrency}
	 */
	get initialBalance() : ClientCurrency {
		this.getterDebugValidations(this.aiInitialBalance, false);
		return this.data[8];
	}

	set initialBalance(v : ClientCurrency) {
        this.setterImpl(8, v, 'initialBalance', false, null, null);
	}

	/**
	 * How much has currently been paid by the booking person.
	 *
	 * @type {ClientCurrency}
	 */
	get currentlyPaid() : ClientCurrency {
		this.getterDebugValidations(this.aiCurrentlyPaid, false);
		return this.data[9];
	}

	set currentlyPaidTestSetter(v : ClientCurrency) {
        this.setterImpl(9, v, 'currentlyPaid', true, null, null);
	}

	/**
	 * Current status of this gift-card.
	 *
	 * @type {SchedulingApiGiftCardStatus}
	 */
	get status() : SchedulingApiGiftCardStatus {
		this.getterDebugValidations(this.aiStatus, false);
		return this.data[10];
	}

	set status(v : SchedulingApiGiftCardStatus) {
        this.setterImpl(10, v, 'status', false, null, null);
	}

	/**
	 * Internal notes for the gift-card.
	 *
	 * @type {string}
	 */
	get internalNotes() : string | null {
		this.getterDebugValidations(this.aiInternalNotes, false);
		return this.data[11];
	}

	set internalNotes(v : string | null) {
        this.setterImpl(11, v, 'internalNotes', false, null, null);
	}

	/**
	 * The cancellation fee to be paid for this gift-card.
	 *
	 * @type {ClientCurrency}
	 */
	get cancellationFee() : ClientCurrency {
		this.getterDebugValidations(this.aiCancellationFee, false);
		return this.data[12];
	}

	set cancellationFee(v : ClientCurrency) {
        this.setterImpl(12, v, 'cancellationFee', false, null, null);
	}

	/**
	 * The vat-percent of this gift-card.
	 *
	 * @type {Percent}
	 */
	get vatPercent() : Percent {
		this.getterDebugValidations(this.aiVatPercent, false);
		return this.data[13];
	}

	set vatPercent(v : Percent) {
        this.setterImpl(13, v, 'vatPercent', false, null, null);
	}

	/**
	 * Set this enum to change ways-to-redeem for this gift-card. Set "null" if the original calculated ways-to-redeem value should not be changed.
	 *
	 * @type {SchedulingApiGiftCardOverrideWaysToRedeem}
	 */
	get overrideWaysToRedeem() : SchedulingApiGiftCardOverrideWaysToRedeem | null {
		this.getterDebugValidations(this.aiOverrideWaysToRedeem, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[14] === undefined) {
			this.data[14] = this.aiOverrideWaysToRedeem.defaultValue;
			
		}
		return this.data[14];
	}

	set overrideWaysToRedeem(v : SchedulingApiGiftCardOverrideWaysToRedeem | null) {
        this.setterImpl(14, v, 'overrideWaysToRedeem', false, null, null);
	}

	/**
	 * Is the current value of this gift-card known? When gift-cards are redeemed in an external way, then this is set to "false".
	 *
	 * @type {boolean}
	 */
	get isCurrentValueKnown() : boolean {
		this.getterDebugValidations(this.aiIsCurrentValueKnown, false);
		return this.data[15];
	}

	set isCurrentValueKnownTestSetter(v : boolean) {
        this.setterImpl(15, v, 'isCurrentValueKnown', true, null, null);
	}

	/**
	 * How can this gift-card be redeemed? Note, that "giftCardSettings.waysToRedeem" returns the desired ways to redeem. But, because of different criteria some gift-cards might deviate from this. This returns the final ways for this gift-card.
	 *
	 * @type {SchedulingApiGiftCardSettingsWaysToRedeem}
	 */
	get waysToRedeem() : SchedulingApiGiftCardSettingsWaysToRedeem {
		this.getterDebugValidations(this.aiWaysToRedeem, false);
		return this.data[16];
	}

	set waysToRedeemTestSetter(v : SchedulingApiGiftCardSettingsWaysToRedeem) {
        this.setterImpl(16, v, 'waysToRedeem', true, null, null);
	}

	/**
	 * Email of gift card owner.
	 *
	 * @type {Email}
	 */
	get email() : Email | null {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[17];
	}

	set email(v : Email | null) {
        this.setterImpl(17, v, 'email', false, null, null);
	}

	/**
	 * When will this gift card expire. Read-only.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get expirationDate() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiExpirationDate, false);
		return this.data[18];
	}

	set expirationDate(v : DateExclusiveEnd | null) {
        this.setterImpl(18, v, 'expirationDate', false, null, null);
	}

	/**
	 * How much has this gift-card been redeemed? The returned value will be equal or less than zero.
	 *
	 * @type {ClientCurrency}
	 */
	get redeemedAmount() : ClientCurrency {
		this.getterDebugValidations(this.aiRedeemedAmount, false);
		return this.data[19];
	}

	set redeemedAmountTestSetter(v : ClientCurrency) {
        this.setterImpl(19, v, 'redeemedAmount', true, null, null);
	}

	/**
	 * What has created this gift-card?
	 *
	 * @type {SchedulingApiBookableCreatedBy}
	 */
	get createdBy() : SchedulingApiBookableCreatedBy {
		this.getterDebugValidations(this.aiCreatedBy, false);
		return this.data[20];
	}

	set createdByTestSetter(v : SchedulingApiBookableCreatedBy) {
        this.setterImpl(20, v, 'createdBy', true, null, null);
	}

	/**
	 * (Detail) An optional message which the booking person can write for the gift card recipient. E.g. the name of the recipient or for what the gift card is intended for.
	 *
	 * @type {string}
	 */
	get messageForRecipient() : string | null {
		this.getterDebugValidations(this.aiMessageForRecipient, false);
		return this.data[21];
	}

	set messageForRecipient(v : string | null) {
        this.setterImpl(21, v, 'messageForRecipient', false, null, null);
	}

	/**
	 * (Detail) The "Erlöskonto" of this gift-card.
	 *
	 * @type {string}
	 */
	get posAccount() : string | null {
		this.getterDebugValidations(this.aiPosAccount, false);
		return this.data[22];
	}

	set posAccount(v : string | null) {
        this.setterImpl(22, v, 'posAccount', false, null, null);
	}

	/**
	 * (Detail) The amount which was paid before we introduced the detailed transaction list for each bookable. Ignore it when it is "null".
	 *
	 * @type {ClientCurrency}
	 */
	get paidBeforeTransactionListIntroduction() : ClientCurrency | null {
		this.getterDebugValidations(this.aiPaidBeforeTransactionListIntroduction, false);
		return this.data[23];
	}

	set paidBeforeTransactionListIntroductionTestSetter(v : ClientCurrency | null) {
        this.setterImpl(23, v, 'paidBeforeTransactionListIntroduction', true, null, null);
	}

	/**
	 * (Detail) The max refund value when limited by online balance. This value is send to everyone who can trigger online refunds. Thus as a security measure it is only send when online balance limits the refund amount to not leak the online balance to unauthorized users. Otherwise "null" is returned.
	 *
	 * @type {ClientCurrency}
	 */
	get refundLimitDueToOnlineBalance() : ClientCurrency | null {
		this.getterDebugValidations(this.aiRefundLimitDueToOnlineBalance, false);
		return this.data[24];
	}

	set refundLimitDueToOnlineBalanceTestSetter(v : ClientCurrency | null) {
        this.setterImpl(24, v, 'refundLimitDueToOnlineBalance', true, null, null);
	}

	/**
	 * (Detail) Is this gift card anonymized?
	 *
	 * @type {boolean}
	 */
	get isAnonymized() : boolean {
		this.getterDebugValidations(this.aiIsAnonymized, false);
		return this.data[25];
	}

	set isAnonymizedTestSetter(v : boolean) {
        this.setterImpl(25, v, 'isAnonymized', true, null, null);
	}

	/**
	 * (Detail) Is the gift-card code safe for being used in online redemption?
	 *
	 * @type {boolean}
	 */
	get isCodeSafeForOnlineRedemption() : boolean {
		this.getterDebugValidations(this.aiIsCodeSafeForOnlineRedemption, false);
		return this.data[26];
	}

	set isCodeSafeForOnlineRedemptionTestSetter(v : boolean) {
        this.setterImpl(26, v, 'isCodeSafeForOnlineRedemption', true, null, null);
	}

	private createdForBookingIdWrapper : Id | null = null!;

	/**
	 * (Detail) The id of the booking for which this gift-card was created. I.e. as a refund or marketing gift-card.
	 *
	 * @type {Id}
	 */
	get createdForBookingId() : Id | null {
		this.getterDebugValidations(this.aiCreatedForBookingId, false);
		return this.createdForBookingIdWrapper;
	}

	set createdForBookingIdTestSetter(v : Id | null) {
        this.setterImpl(27, v, 'createdForBookingId', true, null, function(this : SchedulingApiGiftCard) {this.createdForBookingIdWrapper = v;});
	}

	/**
	 * (Detail) The minimum shopping card value when this gift-card can be redeemed.
	 *
	 * @type {ClientCurrency}
	 */
	get minShoppingCardValue() : ClientCurrency | null {
		this.getterDebugValidations(this.aiMinShoppingCardValue, false);
		return this.data[28];
	}

	set minShoppingCardValueTestSetter(v : ClientCurrency | null) {
        this.setterImpl(28, v, 'minShoppingCardValue', true, null, null);
	}

	/**
	 * (Detail) A token for deferred Payments. Only for testing.
	 *
	 * @type {string}
	 */
	get testingDeferredPaymentToken() : string {
		this.getterDebugValidations(this.aiTestingDeferredPaymentToken, false);
		return this.data[29];
	}

	set testingDeferredPaymentTokenTestSetter(v : string) {
        this.setterImpl(29, v, 'testingDeferredPaymentToken', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[27] = Meta.getReplacedId(this.data[27], _idReplacements);
		this.createdForBookingIdWrapper = this.data[27] === null ? null! : Id.create(this.data[27]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('GIFT_CARD should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[27] : null, this.createdForBookingIdWrapper))
			this.createdForBookingIdWrapper = data && data[27] ? Id.create(data[27]) : null!;
	}

	protected get dni() : string {
		return '693';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '693', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiGiftCardStatus {
	BOOKED = 1,
	CANCELED = 2,
	EXPIRED = 3,
}
export enum SchedulingApiGiftCardOverrideWaysToRedeem {
	IGNORE_WARNINGS = 1,
	DISABLE_REDEMPTION = 2,
}
		 export class SchedulingApiTransactionsBase extends ApiListWrapper<SchedulingApiTransaction>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'transactions');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiTransaction {
		return new SchedulingApiTransaction(this.api, this as unknown as SchedulingApiTransactions, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiTransactions(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '25';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiTransaction) => void) | null = null, _backendId : Id | null = null) : SchedulingApiTransaction {
		const newItem = new SchedulingApiTransaction(this.api, this as unknown as SchedulingApiTransactions, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('transactions');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiTransactions, SchedulingApiTransactions> = new ApiAttributeInfo<SchedulingApiTransactions, SchedulingApiTransactions>({
			apiObjWrapper: this as any as SchedulingApiTransactions,
			name: 'transactions',
			nodeName: 'TRANSACTIONS',
			type: PApiType.ApiList,
			hasPermissionToGet: function(this : SchedulingApiTransactions) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.currentlyDetailedLoaded instanceof SchedulingApiBooking)||(this.api.data.aiBookings.isAvailable && this.api.data.bookings.containsANewItem)||(this.api.currentlyDetailedLoaded instanceof SchedulingApiGiftCard)||(this.api.currentlyDetailedLoaded instanceof SchedulingApiTransaction));
			},
			hasPermissionToSet: function(this : SchedulingApiTransactions) {
	return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER) || this.api.pPermissionsService.userIs(AuthenticatedApiRole.ADMIN) || this.api.pPermissionsService.userIs(AuthenticatedApiRole.SUPER_ADMIN))||(this.api.currentlyDetailedLoaded instanceof SchedulingApiBooking &&
						this.api.pPermissionsService.userCanWriteBooking(this.api.currentlyDetailedLoaded)));
			},
			defaultValue: function(this : SchedulingApiTransactions, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 24,
		});
}

				 
export class SchedulingApiTransactionBase extends ApiObjectWrapper<SchedulingApiTransaction>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiTransactions | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiTransaction> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiTransaction as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransaction> = new ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransaction>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'transaction',
			nodeName: 'TRANSACTION',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiTransaction, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiType : ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionType> = new ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionType>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'type',
			nodeName: 'TRANSACTION_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 1,
			attributeValueInfos: new Map([
				[
					SchedulingApiTransactionType.PAYMENT,
					new ApiAttributeValueInfo<SchedulingApiTransaction>({
						apiObjWrapper: this as any as SchedulingApiTransaction,
						canSetByBusinessLogic: function(this : SchedulingApiTransaction) {
				{const conditionValue = this.bookable instanceof SchedulingApiGiftCard ||
								(this.bookable instanceof SchedulingApiBooking && this.bookable.courseMiscPaymentMethods.length > 0); if(!conditionValue) return conditionValue === false ? {sourceString: 'Hier kannst du Zahlungen erfassen, die du nicht per Online-Zahlung, sondern über eine andere Zahlungsart vorgenommen hast und die deshalb von Dr. Plano nicht automatisch erfasst werden konnten. Bevor du allerdings eine solche Zahlung eingeben kannst, musst du erst in der Tätigkeit unter »Buchungseinstellungen« die Zahlungsart anlegen. Aktuell sind nämlich keine Zahlungsarten vorhanden.', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiTransactionType.REFUND,
					new ApiAttributeValueInfo<SchedulingApiTransaction>({
						apiObjWrapper: this as any as SchedulingApiTransaction,
						canSetByBusinessLogic: function(this : SchedulingApiTransaction) {
				{const conditionValue = this.bookablePrevRefundableAmount! > 0; if(!conditionValue) return conditionValue === false ? {sourceString: 'Es kann nichts erstattet werden, da der Saldo aller bisherigen Einzahlungen und Rückerstattungen <mark>0</mark> ergibt.', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiTransactionType.GIFT_CARD_REDEMPTION,
					new ApiAttributeValueInfo<SchedulingApiTransaction>({
						apiObjWrapper: this as any as SchedulingApiTransaction,
						canSetByBusinessLogic: function(this : SchedulingApiTransaction) {
				{const conditionValue = this.giftCard!.currentValue > 0; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiTransactionType.GIFT_CARD_REDEMPTION_CORRECTION,
					new ApiAttributeValueInfo<SchedulingApiTransaction>({
						apiObjWrapper: this as any as SchedulingApiTransaction,
						canSetByBusinessLogic: function(this : SchedulingApiTransaction) {
				{const conditionValue = this.giftCard!.currentValue < this.giftCard!.initialBalance; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
						},
					}),
				],
			]),
		});
	aiDateTime : ApiAttributeInfo<SchedulingApiTransaction, DateTime> = new ApiAttributeInfo<SchedulingApiTransaction, DateTime>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'dateTime',
			nodeName: 'TRANSACTION_DATE_TIME',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
return this.api.validators.required(PApiType.DateTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiPaymentMethodType : ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionPaymentMethodType> = new ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionPaymentMethodType>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'paymentMethodType',
			nodeName: 'TRANSACTION_PAYMENT_METHOD_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 3,
			attributeValueInfos: new Map([
				[
					SchedulingApiTransactionPaymentMethodType.MISC,
					new ApiAttributeValueInfo<SchedulingApiTransaction>({
						apiObjWrapper: this as any as SchedulingApiTransaction,
						canSetByBusinessLogic: function(this : SchedulingApiTransaction) {
				{const conditionValue = this.bookable instanceof SchedulingApiGiftCard || (this.bookable instanceof SchedulingApiBooking &&
								this.bookable.courseMiscPaymentMethods.length > 0); if(!conditionValue) return conditionValue === false ? {sourceString: 'Hier kannst du Zahlungen erfassen, die du nicht per Online-Zahlung, sondern über eine andere Zahlungsart vorgenommen hast und die deshalb von Dr. Plano nicht automatisch erfasst werden konnten. Bevor du allerdings eine solche Zahlung eingeben kannst, musst du erst in der Tätigkeit unter »Buchungseinstellungen« die Zahlungsart anlegen. Aktuell sind nämlich keine Zahlungsarten vorhanden.', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiTransactionPaymentMethodType.GIFT_CARD,
					new ApiAttributeValueInfo<SchedulingApiTransaction>({
						apiObjWrapper: this as any as SchedulingApiTransaction,
						isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
							return ((this.bookingId !== null));
						},
						canSetByBusinessLogic: function(this : SchedulingApiTransaction) {
				{const conditionValue = this.api.data.giftCardSettings.isSaleEnabled; if(!conditionValue) return conditionValue === false ? {sourceString: 'Diese Rückzahlungsoption steht zur Verfügung, sobald du den <a href="client/plugin/gift-cards" target="_blank">Gutscheinverkauf über Dr. Plano</a> aktiviert hast.', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT,
					new ApiAttributeValueInfo<SchedulingApiTransaction>({
						apiObjWrapper: this as any as SchedulingApiTransaction,
						isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
							return ((this.type === SchedulingApiTransactionType.REFUND));
						},
						canSetByBusinessLogic: function(this : SchedulingApiTransaction) {
				{const conditionValue = this.api.data.isOnlinePaymentAvailable; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um bequem Online-Rückerstattungen vornehmen zu können, aktiviere die <a href="client/plugin/payments" target="_blank">Online-Zahlungsfunktion</a>.', params: false} : undefined;}
				{const conditionValue = this.bookable instanceof SchedulingApiGiftCard || this.api.pPermissionsService.userCanOnlineRefund(this.bookable!.model); if(!conditionValue) return conditionValue === false ? {sourceString: 'Du hast keine Berechtigung, Online-Rückerstattungen an Kunden zu veranlassen. Wende dich bitte an deine Personalleitung, falls das geändert werden soll.', params: false} : undefined;}
				{const conditionValue = this.bookablePrevOnlineRefundableAmount! > 0; if(!conditionValue) return conditionValue === false ? {sourceString: 'Online-Rückerstattung aktuell nicht möglich, da diese Buchung kein Online-Guthaben aufweist. Mehr dazu erfährst du <a href="client/plugin/faq-online-payment#refund" target="_blank">hier</a>.', params: false} : undefined;}
				{const conditionValue = this.bookable!.refundLimitDueToOnlineBalance === null || this.bookable!.refundLimitDueToOnlineBalance > 0; if(!conditionValue) return conditionValue === false ? {sourceString: 'Online-Rückerstattung aktuell nicht möglich, da dein gesamter Account <a href="client/sales/transactions" target="_blank">kein Online-Guthaben</a> aufweist.', params: false} : undefined;}
				return true;
						},
					}),
				],
			]),
		});
	aiMiscPaymentMethodName : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'miscPaymentMethodName',
			nodeName: 'TRANSACTION_MISC_PAYMENT_METHOD_NAME',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
				return ((this.paymentMethodType === SchedulingApiTransactionPaymentMethodType.MISC));
			},
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
		if(((this.paymentMethodType === SchedulingApiTransactionPaymentMethodType.MISC)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiDrPlanoFeeNet : ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency> = new ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'drPlanoFeeNet',
			nodeName: 'TRANSACTION_DR_PLANO_FEE_NET',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiBalanceChange : ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency> = new ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'balanceChange',
			nodeName: 'TRANSACTION_BALANCE_CHANGE',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiOfferName : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'offerName',
			nodeName: 'TRANSACTION_OFFER_NAME',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
				return ((this.type !== SchedulingApiTransactionType.PAYOUT)&&(this.type !== SchedulingApiTransactionType.PAYOUT_FAILED)&&(this.type !== SchedulingApiTransactionType.AUTO_DEBIT)&&(this.type !== SchedulingApiTransactionType.AUTO_DEBIT_FAILED)&&(this.type !== SchedulingApiTransactionType.DR_PLANO_FEE_VAT));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});
	aiBookingNumber : ApiAttributeInfo<SchedulingApiTransaction, Integer> = new ApiAttributeInfo<SchedulingApiTransaction, Integer>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'bookingNumber',
			nodeName: 'TRANSACTION_BOOKING_NUMBER',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
				return ((this.type !== SchedulingApiTransactionType.PAYOUT)&&(this.type !== SchedulingApiTransactionType.PAYOUT_FAILED)&&(this.type !== SchedulingApiTransactionType.AUTO_DEBIT)&&(this.type !== SchedulingApiTransactionType.AUTO_DEBIT_FAILED)&&(this.type !== SchedulingApiTransactionType.DR_PLANO_FEE_VAT));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 8,
		});
	aiBookingPersonFirstName : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'bookingPersonFirstName',
			nodeName: 'TRANSACTION_BOOKING_PERSON_FIRST_NAME',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});
	aiBookingPersonLastName : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'bookingPersonLastName',
			nodeName: 'TRANSACTION_BOOKING_PERSON_LAST_NAME',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 10,
		});
	aiBookingId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'bookingId',
			nodeName: 'TRANSACTION_BOOKING_ID',
			type: PApiType.Id,
			rawDataIndex: 11,
		});
	aiCreatedBy : ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionCreatedBy> = new ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionCreatedBy>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'createdBy',
			nodeName: 'TRANSACTION_CREATED_BY',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiTransaction, _nodeId : string) {return SchedulingApiTransactionCreatedBy.DR_PLANO_USER;},
			rawDataIndex: 12,
		});
	aiCreatorId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'creatorId',
			nodeName: 'TRANSACTION_CREATOR_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 13,
		});
	aiGiftCardId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'giftCardId',
			nodeName: 'TRANSACTION_GIFT_CARD_ID',
			type: PApiType.Id,
			rawDataIndex: 14,
		});
	aiShiftModelId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'shiftModelId',
			nodeName: 'TRANSACTION_SHIFT_MODEL_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 15,
		});
	aiFailedChildId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'failedChildId',
			nodeName: 'TRANSACTION_FAILED_CHILD_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 16,
		});
	aiOriginalPspReference : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'originalPspReference',
			nodeName: 'TRANSACTION_ORIGINAL_PSP_REFERENCE',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiTransaction) {
				return ((this.api.isAutomaticTesting || this.api.me.data.loggedInWithMasterPassword));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 17,
		});
	aiTestingPspReference : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'testingPspReference',
			nodeName: 'TRANSACTION_TESTING_PSP_REFERENCE',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiTransaction) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 18,
		});
	aiTestingTransferFundsAmount : ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency> = new ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'testingTransferFundsAmount',
			nodeName: 'TRANSACTION_TESTING_TRANSFER_FUNDS_AMOUNT',
			type: PApiType.ClientCurrency,
			hasPermissionToGet: function(this : SchedulingApiTransaction) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 19,
		});
	aiTestingTransferFundsPSP : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'testingTransferFundsPSP',
			nodeName: 'TRANSACTION_TESTING_TRANSFER_FUNDS_PSP',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiTransaction) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 20,
		});
	aiTestingTransferFundsState : ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionTransferFundsState> = new ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionTransferFundsState>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'testingTransferFundsState',
			nodeName: 'TRANSACTION_TESTING_TRANSFER_FUNDS_STATE',
			type: PApiType.Enum,
			hasPermissionToGet: function(this : SchedulingApiTransaction) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 21,
		});
	aiTestingIsSettled : ApiAttributeInfo<SchedulingApiTransaction, boolean> = new ApiAttributeInfo<SchedulingApiTransaction, boolean>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'testingIsSettled',
			nodeName: 'TRANSACTION_TESTING_IS_SETTLED',
			type: PApiType.boolean,
			hasPermissionToGet: function(this : SchedulingApiTransaction) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiTransaction, _nodeId : string) {return false;},
			rawDataIndex: 22,
		});
	aiAbsAmount : ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency> = new ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'absAmount',
			nodeName: 'TRANSACTION_ABS_AMOUNT',
			type: PApiType.ClientCurrency,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
					() => {
		if(((this.type !== SchedulingApiTransactionType.PAYOUT)&&(this.type !== SchedulingApiTransactionType.PAYOUT_FAILED)&&(this.createdBy !== SchedulingApiTransactionCreatedBy.BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION)))
		{
			return this.api.validators.max(10000, true, PApiType.ClientCurrency, undefined, 'Der eingegebene Betrag ist außergewöhnlich hoch. Falls er mit Sicherheit richtig ist, dann melde dich bitte bei uns für die Weiterbearbeitung.');
		}
						return null;
					},
					() => {
return this.api.validators.min(0, false, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
					() => {
		if(((this.type === SchedulingApiTransactionType.REFUND)))
		{
			return this.api.validators.max(this.bookable!.currentlyPaidWithoutLatestCreatedTransaction, true, PApiType.ClientCurrency, undefined, 'Dein Kunde hat insgesamt ${max} eingezahlt. Du kannst nicht mehr als das zurückzahlen.');
		}
						return null;
					},
					() => {
		if(((this.type === SchedulingApiTransactionType.REFUND && this.paymentMethodType ===
							SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT)))
		{
			return this.api.validators.max(this.bookable!.refundLimitDueToOnlineBalance, true, PApiType.ClientCurrency, undefined, 'Du kannst max. ${max} zurückerstatten, da das <a href="client/sales/transactions" target="_blank" rel="noopener">Online-Guthaben</a> des gesamten Accounts abzüglich Gebühren für mehr nicht ausreicht.');
		}
						return null;
					},
					() => {
		if(((this.type === SchedulingApiTransactionType.REFUND && this.paymentMethodType ===
							SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT)))
		{
			return this.api.validators.max(+this.bookable!.transactions!.filterBy(item => !item.isNewItem).onlineRefundableAmount!.toFixed(2), true, PApiType.ClientCurrency, undefined, 'Du kannst max. ${max} zurückerstatten, da das Online-Guthaben dieser Buchung für mehr nicht ausreicht.');
		}
						return null;
					},
					() => {
		if(((this.type === SchedulingApiTransactionType.GIFT_CARD_REDEMPTION)))
		{
			return this.api.validators.max(this.giftCard!.currentValue, true, PApiType.ClientCurrency, undefined, undefined);
		}
						return null;
					},
					() => {
		if(((this.type === SchedulingApiTransactionType.GIFT_CARD_REDEMPTION_CORRECTION)))
		{
			return this.api.validators.max(+(this.giftCard!.initialBalance - this.giftCard!.currentValue).toFixed(2), true, PApiType.ClientCurrency, undefined, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiChildChargebackId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'childChargebackId',
			nodeName: 'TRANSACTION_CHILD_CHARGEBACK_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 24,
		});
	aiChildChargebackReversedId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'childChargebackReversedId',
			nodeName: 'TRANSACTION_CHILD_CHARGEBACK_REVERSED_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 25,
		});
	aiChildSecondChargebackId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'childSecondChargebackId',
			nodeName: 'TRANSACTION_CHILD_SECOND_CHARGEBACK_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 26,
		});
	aiVatPercent : ApiAttributeInfo<SchedulingApiTransaction, number> = new ApiAttributeInfo<SchedulingApiTransaction, number>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'vatPercent',
			nodeName: 'TRANSACTION_VAT_PERCENT',
			type: PApiType.number,
			hasPermissionToSet: () => false,
			rawDataIndex: 27,
		});
	aiStatus : ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionStatus> = new ApiAttributeInfo<SchedulingApiTransaction, SchedulingApiTransactionStatus>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'status',
			nodeName: 'TRANSACTION_STATUS',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 28,
		});
	aiInternalNotes : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'internalNotes',
			nodeName: 'TRANSACTION_INTERNAL_NOTES',
			type: PApiType.string,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});
	aiBookableId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'bookableId',
			nodeName: 'TRANSACTION_BOOKABLE_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 30,
		});
	aiRedeemChildPaymentId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'redeemChildPaymentId',
			nodeName: 'TRANSACTION_REDEEM_CHILD_PAYMENT_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 31,
		});
	aiBalance : ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency> = new ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'balance',
			nodeName: 'TRANSACTION_BALANCE',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
				return ((this.paymentMethodType === SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 32,
		});
	aiBankAccountHint : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'bankAccountHint',
			nodeName: 'TRANSACTION_BANK_ACCOUNT_HINT',
			type: PApiType.string,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
				return ((this.type === SchedulingApiTransactionType.PAYOUT)||(this.type === SchedulingApiTransactionType.PAYOUT_FAILED)||(this.type === SchedulingApiTransactionType.AUTO_DEBIT)||(this.type === SchedulingApiTransactionType.AUTO_DEBIT_FAILED));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 33,
		});
	aiBankDescription : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'bankDescription',
			nodeName: 'TRANSACTION_BANK_DESCRIPTION',
			type: PApiType.string,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
				return ((this.paymentMethodType === SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT)&&(this.type !== SchedulingApiTransactionType.DR_PLANO_FEE_VAT));
			},
			hasPermissionToSet: () => false,
			validations: function(this : SchedulingApiTransaction) {
				return [
					() => {
		if(((this.paymentMethodType === SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT)&&(this.type !== SchedulingApiTransactionType.DR_PLANO_FEE_VAT)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiTransaction) {
				return [
				];
			}
			,
			rawDataIndex: 34,
		});
	aiParentId : ApiAttributeInfo<SchedulingApiTransaction, Id> = new ApiAttributeInfo<SchedulingApiTransaction, Id>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'parentId',
			nodeName: 'TRANSACTION_PARENT_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 35,
		});
	aiPosAccounts : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'posAccounts',
			nodeName: 'TRANSACTION_POS_ACCOUNTS',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 36,
		});
	aiPosBonId : ApiAttributeInfo<SchedulingApiTransaction, Integer> = new ApiAttributeInfo<SchedulingApiTransaction, Integer>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'posBonId',
			nodeName: 'TRANSACTION_POS_BON_ID',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : SchedulingApiTransaction) {
				return ((this.createdBy === SchedulingApiTransactionCreatedBy.BOULDERADO));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 37,
		});
	aiFailedReason : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'failedReason',
			nodeName: 'TRANSACTION_FAILED_REASON',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 38,
		});
	aiRefundGiftCardInternalNotes : ApiAttributeInfo<SchedulingApiTransaction, string> = new ApiAttributeInfo<SchedulingApiTransaction, string>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'refundGiftCardInternalNotes',
			nodeName: 'TRANSACTION_REFUND_GIFT_CARD_INTERNAL_NOTES',
			type: PApiType.string,
			isDetailedAttribute: true,
			rawDataIndex: 39,
		});
	aiDrPlanoFeeVatDeprecated : ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency> = new ApiAttributeInfo<SchedulingApiTransaction, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiTransaction,
			name: 'drPlanoFeeVatDeprecated',
			nodeName: 'TRANSACTION_DR_PLANO_FEE_VAT_DEPRECATED',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 40,
		});

	/**
	 * The type of this transaction
	 *
	 * @type {SchedulingApiTransactionType}
	 */
	get type() : SchedulingApiTransactionType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiTransactionType) {
        this.setterImpl(1, v, 'type', false, null, function(this : SchedulingApiTransaction) {(this as unknown as SchedulingApiTransaction).transactionTypeSetPostCode(v)});
	}

	/**
	 * The date-time of this transaction.
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[2];
	}

	set dateTimeTestSetter(v : DateTime) {
        this.setterImpl(2, v, 'dateTime', true, null, null);
	}

	/**
	 * The payment-method type of the transaction. See "shiftModel.coursePaymentMethods.type".
	 *
	 * @type {SchedulingApiTransactionPaymentMethodType}
	 */
	get paymentMethodType() : SchedulingApiTransactionPaymentMethodType {
		this.getterDebugValidations(this.aiPaymentMethodType, false);
		return this.data[3];
	}

	set paymentMethodType(v : SchedulingApiTransactionPaymentMethodType) {
        this.setterImpl(3, v, 'paymentMethodType', false, null, null);
	}

	/**
	 * The MISC payment-method name. Only send for "paymentMethodType" == MISC.
	 *
	 * @type {string}
	 */
	get miscPaymentMethodName() : string | null {
		this.getterDebugValidations(this.aiMiscPaymentMethodName, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiMiscPaymentMethodName.defaultValue;
			
		}
		return this.data[4];
	}

	set miscPaymentMethodName(v : string | null) {
        this.setterImpl(4, v, 'miscPaymentMethodName', false, null, null);
	}

	/**
	 * The fee we are taking for the transaction. This is always zero or a positive number. Note that this does not include any VAT as we will subtract whole VAT at the end of the month using a VAT transaction.
	 *
	 * @type {ClientCurrency}
	 */
	get drPlanoFeeNet() : ClientCurrency {
		this.getterDebugValidations(this.aiDrPlanoFeeNet, false);
		return this.data[5];
	}

	set drPlanoFeeNetTestSetter(v : ClientCurrency) {
        this.setterImpl(5, v, 'drPlanoFeeNet', true, null, null);
	}

	/**
	 * The amount which will be settled on the online-payment account. Can be zero, positive or negative.
	 *
	 * @type {ClientCurrency}
	 */
	get balanceChange() : ClientCurrency {
		this.getterDebugValidations(this.aiBalanceChange, false);
		return this.data[6];
	}

	set balanceChangeTestSetter(v : ClientCurrency) {
        this.setterImpl(6, v, 'balanceChange', true, null, null);
	}

	/**
	 * The name of the offer. Either a translation of the word "Gutschein" or the course-title.
	 *
	 * @type {string}
	 */
	get offerName() : string | null {
		this.getterDebugValidations(this.aiOfferName, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiOfferName.defaultValue;
			
		}
		return this.data[7];
	}

	set offerNameTestSetter(v : string | null) {
        this.setterImpl(7, v, 'offerName', true, null, null);
	}

	/**
	 * The booking number of the bookable to which this transaction belongs. I.e. the booking-number of the bookable "transaction.bookableId".
	 *
	 * @type {Integer}
	 */
	get bookingNumber() : Integer | null {
		this.getterDebugValidations(this.aiBookingNumber, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiBookingNumber.defaultValue;
			
		}
		return this.data[8];
	}

	set bookingNumberTestSetter(v : Integer | null) {
        this.setterImpl(8, v, 'bookingNumber', true, null, null);
	}

	/**
	 * First-name of the person for whom this transaction was created.
	 *
	 * @type {string}
	 */
	get bookingPersonFirstName() : string | null {
		this.getterDebugValidations(this.aiBookingPersonFirstName, false);
		return this.data[9];
	}

	set bookingPersonFirstNameTestSetter(v : string | null) {
        this.setterImpl(9, v, 'bookingPersonFirstName', true, null, null);
	}

	/**
	 * Last-name of the person for whom this transaction was created.
	 *
	 * @type {string}
	 */
	get bookingPersonLastName() : string | null {
		this.getterDebugValidations(this.aiBookingPersonLastName, false);
		return this.data[10];
	}

	set bookingPersonLastNameTestSetter(v : string | null) {
        this.setterImpl(10, v, 'bookingPersonLastName', true, null, null);
	}

	private bookingIdWrapper : Id | null = null!;

	/**
	 * The id of the booking to which this transaction is connected.
	 *
	 * @type {Id}
	 */
	get bookingId() : Id | null {
		this.getterDebugValidations(this.aiBookingId, false);
		return this.bookingIdWrapper;
	}

	set bookingId(v : Id | null) {
        this.setterImpl(11, v, 'bookingId', false, null, function(this : SchedulingApiTransaction) {this.bookingIdWrapper = v;});
	}

	/**
	 * What has triggered the creation of this transaction?
	 *
	 * @type {SchedulingApiTransactionCreatedBy}
	 */
	get createdBy() : SchedulingApiTransactionCreatedBy {
		this.getterDebugValidations(this.aiCreatedBy, false);
		return this.data[12];
	}

	set createdByTestSetter(v : SchedulingApiTransactionCreatedBy) {
        this.setterImpl(12, v, 'createdBy', true, null, null);
	}

	private creatorIdWrapper : Id | null = null!;

	/**
	 * The id of the member who has created this transaction.
	 *
	 * @type {Id}
	 */
	get creatorId() : Id | null {
		this.getterDebugValidations(this.aiCreatorId, false);
		return this.creatorIdWrapper;
	}

	set creatorIdTestSetter(v : Id | null) {
        this.setterImpl(13, v, 'creatorId', true, null, function(this : SchedulingApiTransaction) {this.creatorIdWrapper = v;});
	}

	private giftCardIdWrapper : Id | null = null!;

	/**
	 * The id of the giftCard to which this transaction is connected.
	 *
	 * @type {Id}
	 */
	get giftCardId() : Id | null {
		this.getterDebugValidations(this.aiGiftCardId, false);
		return this.giftCardIdWrapper;
	}

	set giftCardId(v : Id | null) {
        this.setterImpl(14, v, 'giftCardId', false, null, function(this : SchedulingApiTransaction) {this.giftCardIdWrapper = v;});
	}

	private shiftModelIdWrapper : Id | null = null!;

	/**
	 * The id of the shift-model to which this transaction is connected. I.e. this is the shift-model of "transaction.bookingId". Can be "null".
	 *
	 * @type {Id}
	 */
	get shiftModelId() : Id | null {
		this.getterDebugValidations(this.aiShiftModelId, false);
		return this.shiftModelIdWrapper;
	}

	set shiftModelIdTestSetter(v : Id | null) {
        this.setterImpl(15, v, 'shiftModelId', true, null, function(this : SchedulingApiTransaction) {this.shiftModelIdWrapper = v;});
	}

	private failedChildIdWrapper : Id | null = null!;

	/**
	 * The id of a failed child transaction item. E.g. this is a PAYMENT item and there exists a corresponding PAYMENT_FAILED child item. Then this value contains the id of the PAYMENT_FAILED item. Note that a payment with chargebacks will have multiple failed children. In that case this value will be the id of one of the chargeback items. In that case, rather use "childChargebackId", "childChargebackReversedId" and "childSecondChargebackId".
	 *
	 * @type {Id}
	 */
	get failedChildId() : Id | null {
		this.getterDebugValidations(this.aiFailedChildId, false);
		return this.failedChildIdWrapper;
	}

	set failedChildIdTestSetter(v : Id | null) {
        this.setterImpl(16, v, 'failedChildId', true, null, function(this : SchedulingApiTransaction) {this.failedChildIdWrapper = v;});
	}

	/**
	 * Adyens original PSP reference of the transaction.
	 *
	 * @type {string}
	 */
	get originalPspReference() : string | null {
		this.getterDebugValidations(this.aiOriginalPspReference, false);
		return this.data[17];
	}

	set originalPspReferenceTestSetter(v : string | null) {
        this.setterImpl(17, v, 'originalPspReference', true, null, null);
	}

	/**
	 * Adyens PSP reference of the transaction. This is only send in testing mode.
	 *
	 * @type {string}
	 */
	get testingPspReference() : string | null {
		this.getterDebugValidations(this.aiTestingPspReference, false);
		return this.data[18];
	}

	set testingPspReferenceTestSetter(v : string | null) {
        this.setterImpl(18, v, 'testingPspReference', true, null, null);
	}

	/**
	 * The TransferFunds-Amount for this transaction. This is only send in testing mode.
	 *
	 * @type {ClientCurrency}
	 */
	get testingTransferFundsAmount() : ClientCurrency | null {
		this.getterDebugValidations(this.aiTestingTransferFundsAmount, false);
		return this.data[19];
	}

	set testingTransferFundsAmountTestSetter(v : ClientCurrency | null) {
        this.setterImpl(19, v, 'testingTransferFundsAmount', true, null, null);
	}

	/**
	 * The TransferFunds-PSP for this transaction. This is only send in testing mode.
	 *
	 * @type {string}
	 */
	get testingTransferFundsPSP() : string | null {
		this.getterDebugValidations(this.aiTestingTransferFundsPSP, false);
		return this.data[20];
	}

	set testingTransferFundsPSPTestSetter(v : string | null) {
        this.setterImpl(20, v, 'testingTransferFundsPSP', true, null, null);
	}

	/**
	 * The TransferFunds-State for this transaction. This is only send in testing mode.
	 *
	 * @type {SchedulingApiTransactionTransferFundsState}
	 */
	get testingTransferFundsState() : SchedulingApiTransactionTransferFundsState | null {
		this.getterDebugValidations(this.aiTestingTransferFundsState, false);
		return this.data[21];
	}

	set testingTransferFundsStateTestSetter(v : SchedulingApiTransactionTransferFundsState | null) {
        this.setterImpl(21, v, 'testingTransferFundsState', true, null, null);
	}

	/**
	 * True if the transaction is settled as defined by TransactionLogic.isSettled(false). This is only send in testing mode.
	 *
	 * @type {boolean}
	 */
	get testingIsSettled() : boolean {
		this.getterDebugValidations(this.aiTestingIsSettled, false);
		return this.data[22];
	}

	set testingIsSettledTestSetter(v : boolean) {
        this.setterImpl(22, v, 'testingIsSettled', true, null, null);
	}

	/**
	 * The absolute amount of the transaction. Set this value when creating a new transaction.
	 *
	 * @type {ClientCurrency}
	 */
	get absAmount() : ClientCurrency {
		this.getterDebugValidations(this.aiAbsAmount, false);
		return this.data[23];
	}

	set absAmount(v : ClientCurrency) {
        this.setterImpl(23, v, 'absAmount', false, null, null);
	}

	private childChargebackIdWrapper : Id | null = null!;

	/**
	 * The id of the child chargeback transaction if it exists.
	 *
	 * @type {Id}
	 */
	get childChargebackId() : Id | null {
		this.getterDebugValidations(this.aiChildChargebackId, false);
		return this.childChargebackIdWrapper;
	}

	set childChargebackIdTestSetter(v : Id | null) {
        this.setterImpl(24, v, 'childChargebackId', true, null, function(this : SchedulingApiTransaction) {this.childChargebackIdWrapper = v;});
	}

	private childChargebackReversedIdWrapper : Id | null = null!;

	/**
	 * The id of the child chargeback-reversed transaction if it exists.
	 *
	 * @type {Id}
	 */
	get childChargebackReversedId() : Id | null {
		this.getterDebugValidations(this.aiChildChargebackReversedId, false);
		return this.childChargebackReversedIdWrapper;
	}

	set childChargebackReversedIdTestSetter(v : Id | null) {
        this.setterImpl(25, v, 'childChargebackReversedId', true, null, function(this : SchedulingApiTransaction) {this.childChargebackReversedIdWrapper = v;});
	}

	private childSecondChargebackIdWrapper : Id | null = null!;

	/**
	 * The id of the child second-chargeback transaction if it exists.
	 *
	 * @type {Id}
	 */
	get childSecondChargebackId() : Id | null {
		this.getterDebugValidations(this.aiChildSecondChargebackId, false);
		return this.childSecondChargebackIdWrapper;
	}

	set childSecondChargebackIdTestSetter(v : Id | null) {
        this.setterImpl(26, v, 'childSecondChargebackId', true, null, function(this : SchedulingApiTransaction) {this.childSecondChargebackIdWrapper = v;});
	}

	/**
	 * The vat percent. Only given for "DR_PLANO_FEE_VAT" transactions.
	 *
	 * @type {number}
	 */
	get vatPercent() : number | null {
		this.getterDebugValidations(this.aiVatPercent, false);
		return this.data[27];
	}

	set vatPercentTestSetter(v : number | null) {
        this.setterImpl(27, v, 'vatPercent', true, null, null);
	}

	/**
	 * A simple status value being used for filtering the transactions.
	 *
	 * @type {SchedulingApiTransactionStatus}
	 */
	get status() : SchedulingApiTransactionStatus {
		this.getterDebugValidations(this.aiStatus, false);
		return this.data[28];
	}

	set statusTestSetter(v : SchedulingApiTransactionStatus) {
        this.setterImpl(28, v, 'status', true, null, null);
	}

	/**
	 * An internal comment for the transaction.
	 *
	 * @type {string}
	 */
	get internalNotes() : string | null {
		this.getterDebugValidations(this.aiInternalNotes, false);
		return this.data[29];
	}

	set internalNotes(v : string | null) {
        this.setterImpl(29, v, 'internalNotes', false, null, null);
	}

	private bookableIdWrapper : Id | null = null!;

	/**
	 * The id of the bookable to which this transaction belongs. You can find the booking-number of this bookable under "transaction.bookingNumber".
	 *
	 * @type {Id}
	 */
	get bookableId() : Id | null {
		this.getterDebugValidations(this.aiBookableId, false);
		return this.bookableIdWrapper;
	}

	set bookableIdTestSetter(v : Id | null) {
        this.setterImpl(30, v, 'bookableId', true, null, function(this : SchedulingApiTransaction) {this.bookableIdWrapper = v;});
	}

	private redeemChildPaymentIdWrapper : Id | null = null!;

	/**
	 * (Detail) When this is a redeem transaction and there is a payment transaction connected to it, this is the id of that payment transaction.
	 *
	 * @type {Id}
	 */
	get redeemChildPaymentId() : Id | null {
		this.getterDebugValidations(this.aiRedeemChildPaymentId, false);
		return this.redeemChildPaymentIdWrapper;
	}

	set redeemChildPaymentIdTestSetter(v : Id | null) {
        this.setterImpl(31, v, 'redeemChildPaymentId', true, null, function(this : SchedulingApiTransaction) {this.redeemChildPaymentIdWrapper = v;});
	}

	/**
	 * (Detail) Current balance of the online-payment account.
	 *
	 * @type {ClientCurrency}
	 */
	get balance() : ClientCurrency {
		this.getterDebugValidations(this.aiBalance, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[32] === undefined) {
			this.data[32] = this.aiBalance.defaultValue;
			
		}
		return this.data[32];
	}

	set balanceTestSetter(v : ClientCurrency) {
        this.setterImpl(32, v, 'balance', true, null, null);
	}

	/**
	 * (Detail) An bank account hint for online-payment transactions. E.g. the last characters of an IBAN number "***05432". Can be "null".
	 *
	 * @type {string}
	 */
	get bankAccountHint() : string | null {
		this.getterDebugValidations(this.aiBankAccountHint, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[33] === undefined) {
			this.data[33] = this.aiBankAccountHint.defaultValue;
			
		}
		return this.data[33];
	}

	set bankAccountHintTestSetter(v : string | null) {
        this.setterImpl(33, v, 'bankAccountHint', true, null, null);
	}

	/**
	 * (Detail) The bank description shown in the bank account. Can be "null".
	 *
	 * @type {string}
	 */
	get bankDescription() : string | null {
		this.getterDebugValidations(this.aiBankDescription, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[34] === undefined) {
			this.data[34] = this.aiBankDescription.defaultValue;
			
		}
		return this.data[34];
	}

	set bankDescriptionTestSetter(v : string | null) {
        this.setterImpl(34, v, 'bankDescription', true, null, null);
	}

	private parentIdWrapper : Id | null = null!;

	/**
	 * (Detail) The id of the parent transaction.
	 *
	 * @type {Id}
	 */
	get parentId() : Id | null {
		this.getterDebugValidations(this.aiParentId, false);
		return this.parentIdWrapper;
	}

	set parentIdTestSetter(v : Id | null) {
        this.setterImpl(35, v, 'parentId', true, null, function(this : SchedulingApiTransaction) {this.parentIdWrapper = v;});
	}

	/**
	 * (Detail) The POS accounts to which this transaction belongs.
	 *
	 * @type {string}
	 */
	get posAccounts() : string | null {
		this.getterDebugValidations(this.aiPosAccounts, false);
		return this.data[36];
	}

	set posAccountsTestSetter(v : string | null) {
        this.setterImpl(36, v, 'posAccounts', true, null, null);
	}

	/**
	 * (Detail) The POS bon-id which triggered the creation of this transaction.
	 *
	 * @type {Integer}
	 */
	get posBonId() : Integer | null {
		this.getterDebugValidations(this.aiPosBonId, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[37] === undefined) {
			this.data[37] = this.aiPosBonId.defaultValue;
			
		}
		return this.data[37];
	}

	set posBonIdTestSetter(v : Integer | null) {
        this.setterImpl(37, v, 'posBonId', true, null, null);
	}

	/**
	 * (Detail) The fail reason String, or null if the transaction didn't fail.
	 *
	 * @type {string}
	 */
	get failedReason() : string | null {
		this.getterDebugValidations(this.aiFailedReason, false);
		return this.data[38];
	}

	set failedReasonTestSetter(v : string | null) {
        this.setterImpl(38, v, 'failedReason', true, null, null);
	}


	set refundGiftCardInternalNotes(v : string | null) {
        this.setterImpl(39, v, 'refundGiftCardInternalNotes', false, null, null);
	}

	/**
	 * (Detail) Absolute vat amount added to the fee we take for this transaction. This value is deprecated as we dont take VAT directly anymore with the transaction but we have now a special DR_PLANO_FEE_VAT transaction which we create at the end of each month. For new transactions this value will always be 0.
	 *
	 * @type {ClientCurrency}
	 */
	get drPlanoFeeVatDeprecated() : ClientCurrency {
		this.getterDebugValidations(this.aiDrPlanoFeeVatDeprecated, false);
		return this.data[40];
	}

	set drPlanoFeeVatDeprecatedTestSetter(v : ClientCurrency) {
        this.setterImpl(40, v, 'drPlanoFeeVatDeprecated', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[11] = Meta.getReplacedId(this.data[11], _idReplacements);
		this.bookingIdWrapper = this.data[11] === null ? null! : Id.create(this.data[11]);
		this.data[13] = Meta.getReplacedId(this.data[13], _idReplacements);
		this.creatorIdWrapper = this.data[13] === null ? null! : Id.create(this.data[13]);
		this.data[14] = Meta.getReplacedId(this.data[14], _idReplacements);
		this.giftCardIdWrapper = this.data[14] === null ? null! : Id.create(this.data[14]);
		this.data[15] = Meta.getReplacedId(this.data[15], _idReplacements);
		this.shiftModelIdWrapper = this.data[15] === null ? null! : Id.create(this.data[15]);
		this.data[16] = Meta.getReplacedId(this.data[16], _idReplacements);
		this.failedChildIdWrapper = this.data[16] === null ? null! : Id.create(this.data[16]);
		this.data[24] = Meta.getReplacedId(this.data[24], _idReplacements);
		this.childChargebackIdWrapper = this.data[24] === null ? null! : Id.create(this.data[24]);
		this.data[25] = Meta.getReplacedId(this.data[25], _idReplacements);
		this.childChargebackReversedIdWrapper = this.data[25] === null ? null! : Id.create(this.data[25]);
		this.data[26] = Meta.getReplacedId(this.data[26], _idReplacements);
		this.childSecondChargebackIdWrapper = this.data[26] === null ? null! : Id.create(this.data[26]);
		this.data[30] = Meta.getReplacedId(this.data[30], _idReplacements);
		this.bookableIdWrapper = this.data[30] === null ? null! : Id.create(this.data[30]);
		this.data[31] = Meta.getReplacedId(this.data[31], _idReplacements);
		this.redeemChildPaymentIdWrapper = this.data[31] === null ? null! : Id.create(this.data[31]);
		this.data[35] = Meta.getReplacedId(this.data[35], _idReplacements);
		this.parentIdWrapper = this.data[35] === null ? null! : Id.create(this.data[35]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('TRANSACTION should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[11] : null, this.bookingIdWrapper))
			this.bookingIdWrapper = data && data[11] ? Id.create(data[11]) : null!;
		if(!Meta.isSameId(data ? data[13] : null, this.creatorIdWrapper))
			this.creatorIdWrapper = data && data[13] ? Id.create(data[13]) : null!;
		if(!Meta.isSameId(data ? data[14] : null, this.giftCardIdWrapper))
			this.giftCardIdWrapper = data && data[14] ? Id.create(data[14]) : null!;
		if(!Meta.isSameId(data ? data[15] : null, this.shiftModelIdWrapper))
			this.shiftModelIdWrapper = data && data[15] ? Id.create(data[15]) : null!;
		if(!Meta.isSameId(data ? data[16] : null, this.failedChildIdWrapper))
			this.failedChildIdWrapper = data && data[16] ? Id.create(data[16]) : null!;
		if(!Meta.isSameId(data ? data[24] : null, this.childChargebackIdWrapper))
			this.childChargebackIdWrapper = data && data[24] ? Id.create(data[24]) : null!;
		if(!Meta.isSameId(data ? data[25] : null, this.childChargebackReversedIdWrapper))
			this.childChargebackReversedIdWrapper = data && data[25] ? Id.create(data[25]) : null!;
		if(!Meta.isSameId(data ? data[26] : null, this.childSecondChargebackIdWrapper))
			this.childSecondChargebackIdWrapper = data && data[26] ? Id.create(data[26]) : null!;
		if(!Meta.isSameId(data ? data[30] : null, this.bookableIdWrapper))
			this.bookableIdWrapper = data && data[30] ? Id.create(data[30]) : null!;
		if(!Meta.isSameId(data ? data[31] : null, this.redeemChildPaymentIdWrapper))
			this.redeemChildPaymentIdWrapper = data && data[31] ? Id.create(data[31]) : null!;
		if(!Meta.isSameId(data ? data[35] : null, this.parentIdWrapper))
			this.parentIdWrapper = data && data[35] ? Id.create(data[35]) : null!;
	}

	protected get dni() : string {
		return '531';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '531', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiTransactionType {
	PAYMENT = 1,
	PAYMENT_FAILED = 2,
	REFUND = 3,
	REFUND_FAILED = 4,
	PAYOUT = 5,
	PAYOUT_FAILED = 6,
	CHARGEBACK = 7,
	CHARGEBACK_REVERSED = 8,
	SECOND_CHARGEBACK = 9,
	AUTO_DEBIT = 10,
	AUTO_DEBIT_FAILED = 11,
	DR_PLANO_FEE_VAT = 12,
	GIFT_CARD_REDEMPTION = 13,
	GIFT_CARD_REDEMPTION_CORRECTION = 14,
}
export enum SchedulingApiTransactionPaymentMethodType {
	ONLINE_PAYMENT = 1,
	MISC = 2,
	POS = 3,
	GIFT_CARD = 4,
	MARKETING_GIFT_CARD = 5,
}
export enum SchedulingApiTransactionCreatedBy {
	DR_PLANO = 1,
	DR_PLANO_USER = 2,
	DR_PLANO_PAYMENT_PAGE = 3,
	ONLINE_CANCELLATION = 4,
	RELEASE_GIFT_CARD_REDEMPTION_TRANSACTIONS = 5,
	BOULDERADO = 6,
	BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION = 7,
	FREECLIMBER = 8,
	BETA_7 = 9,
}
export enum SchedulingApiTransactionTransferFundsState {
	OUTSTANDING = 1,
	PENDING = 2,
	SUCCESSFUL = 3,
}
export enum SchedulingApiTransactionStatus {
	SUCCESSFUL = 1,
	FAILED = 2,
	CORRECTION = 3,
}
		 export class SchedulingApiWorkModels extends ApiListWrapper<SchedulingApiWorkModel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'workModels');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModel {
		return new SchedulingApiWorkModel(this.api, this as unknown as SchedulingApiWorkModels, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModels(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '26';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModel) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModel {
		const newItem = new SchedulingApiWorkModel(this.api, this as unknown as SchedulingApiWorkModels, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('workModels');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModels, SchedulingApiWorkModels> = new ApiAttributeInfo<SchedulingApiWorkModels, SchedulingApiWorkModels>({
			apiObjWrapper: this as any as SchedulingApiWorkModels,
			name: 'workModels',
			nodeName: 'WORK_MODELS',
			type: PApiType.ApiList,
			hasPermissionToGet: function(this : SchedulingApiWorkModels) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: function(this : SchedulingApiWorkModels) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiWorkModels, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 25,
		});
}

				 
export class SchedulingApiWorkModelBase extends ApiObjectWrapper<SchedulingApiWorkModel>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModels | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModel> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModel as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModel, SchedulingApiWorkModel> = new ApiAttributeInfo<SchedulingApiWorkModel, SchedulingApiWorkModel>({
			apiObjWrapper: this as any as SchedulingApiWorkModel,
			name: 'workModel',
			nodeName: 'WORK_MODEL',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkModel, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiWorkModel, string> = new ApiAttributeInfo<SchedulingApiWorkModel, string>({
			apiObjWrapper: this as any as SchedulingApiWorkModel,
			name: 'name',
			nodeName: 'WORK_MODEL_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiWorkModel) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(70, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModel) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiWorkModel, string> = new ApiAttributeInfo<SchedulingApiWorkModel, string>({
			apiObjWrapper: this as any as SchedulingApiWorkModel,
			name: 'description',
			nodeName: 'WORK_MODEL_DESCRIPTION',
			type: PApiType.string,
			validations: function(this : SchedulingApiWorkModel) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModel) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiDateOfCreation : ApiAttributeInfo<SchedulingApiWorkModel, DateTime> = new ApiAttributeInfo<SchedulingApiWorkModel, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModel,
			name: 'dateOfCreation',
			nodeName: 'WORK_MODEL_DATE_OF_CREATION',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiCreatorId : ApiAttributeInfo<SchedulingApiWorkModel, Id> = new ApiAttributeInfo<SchedulingApiWorkModel, Id>({
			apiObjWrapper: this as any as SchedulingApiWorkModel,
			name: 'creatorId',
			nodeName: 'WORK_MODEL_CREATOR_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});

	/**
	 * The name of this work model.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * An optional descriptive text for the work model.
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[2];
	}

	set description(v : string | null) {
        this.setterImpl(2, v, 'description', false, null, null);
	}

	private workRulesWrapper : SchedulingApiWorkModelWorkRules = new SchedulingApiWorkModelWorkRules(this.api,
		this as unknown as SchedulingApiWorkModel, false, false);
	public aiWorkRules = this.workRulesWrapper.aiThis;

	/**
	 * The list of work rules belonging to this work model.
	 */
	get workRules() : SchedulingApiWorkModelWorkRules {
		this.getterDebugValidations(this.aiWorkRules, false);
		return this.workRulesWrapper;
	}

	set workRulesTestSetter(v : SchedulingApiWorkModelWorkRules) {
        this.setterImpl(3, v.rawData, 'workRules', true, null, function(this : SchedulingApiWorkModel) {this.workRulesWrapper = v;});
	}

	/**
	 * (Detail) When was this item created?
	 *
	 * @type {DateTime}
	 */
	get dateOfCreation() : DateTime {
		this.getterDebugValidations(this.aiDateOfCreation, false);
		return this.data[4];
	}

	set dateOfCreationTestSetter(v : DateTime) {
        this.setterImpl(4, v, 'dateOfCreation', true, null, null);
	}

	private creatorIdWrapper : Id | null = null!;

	/**
	 * (Detail) The user who created this item. "null" is returned when the item was created by Dr. Plano.
	 *
	 * @type {Id}
	 */
	get creatorId() : Id | null {
		this.getterDebugValidations(this.aiCreatorId, false);
		return this.creatorIdWrapper;
	}

	set creatorIdTestSetter(v : Id | null) {
        this.setterImpl(5, v, 'creatorId', true, null, function(this : SchedulingApiWorkModel) {this.creatorIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.workRulesWrapper._fixIds(_idReplacements);
		this.data[5] = Meta.getReplacedId(this.data[5], _idReplacements);
		this.creatorIdWrapper = this.data[5] === null ? null! : Id.create(this.data[5]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.workRulesWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[5] : null, this.creatorIdWrapper))
			this.creatorIdWrapper = data && data[5] ? Id.create(data[5]) : null!;
	}

	protected get dni() : string {
		return '572';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '572', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRules extends ApiListWrapper<SchedulingApiWorkModelWorkRule>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModel | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'workRules');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRule {
		return new SchedulingApiWorkModelWorkRule(this.api, this as unknown as SchedulingApiWorkModelWorkRules, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModel | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRules(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '575';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRule) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRule {
		const newItem = new SchedulingApiWorkModelWorkRule(this.api, this as unknown as SchedulingApiWorkModelWorkRules, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('workRules');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRules, SchedulingApiWorkModelWorkRules> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRules, SchedulingApiWorkModelWorkRules>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRules,
			name: 'workRules',
			nodeName: 'WORK_MODEL_WORK_RULES',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRules, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 3,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleBase extends ApiObjectWrapper<SchedulingApiWorkModelWorkRule>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRules | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRule> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRule as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRule, SchedulingApiWorkModelWorkRule> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRule, SchedulingApiWorkModelWorkRule>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRule,
			name: 'workModelWorkRule',
			nodeName: 'WORK_MODEL_WORK_RULE',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkModelWorkRule, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<SchedulingApiWorkModelWorkRule, string> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRule, string>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRule,
			name: 'name',
			nodeName: 'WORK_MODEL_WORK_RULE_NAME',
			type: PApiType.string,
			validations: function(this : SchedulingApiWorkModelWorkRule) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
					() => {
						return this.api.validators.maxLength(70, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRule) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiDescription : ApiAttributeInfo<SchedulingApiWorkModelWorkRule, string> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRule, string>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRule,
			name: 'description',
			nodeName: 'WORK_MODEL_WORK_RULE_DESCRIPTION',
			type: PApiType.string,
			validations: function(this : SchedulingApiWorkModelWorkRule) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRule) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiDateOfCreation : ApiAttributeInfo<SchedulingApiWorkModelWorkRule, DateTime> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRule, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRule,
			name: 'dateOfCreation',
			nodeName: 'WORK_MODEL_WORK_RULE_DATE_OF_CREATION',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiCreatorId : ApiAttributeInfo<SchedulingApiWorkModelWorkRule, Id> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRule, Id>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRule,
			name: 'creatorId',
			nodeName: 'WORK_MODEL_WORK_RULE_CREATOR_ID',
			type: PApiType.Id,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});

	/**
	 * The name of this work rule.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
	 * An optional descriptive text for the work rule.
	 *
	 * @type {string}
	 */
	get description() : string | null {
		this.getterDebugValidations(this.aiDescription, false);
		return this.data[2];
	}

	set description(v : string | null) {
        this.setterImpl(2, v, 'description', false, null, null);
	}

	private versionsWrapper : SchedulingApiWorkModelWorkRuleVersions = new SchedulingApiWorkModelWorkRuleVersions(this.api,
		this as unknown as SchedulingApiWorkModelWorkRule, false, false);
	public aiVersions = this.versionsWrapper.aiThis;

	/**
	 * List of work rule versions, each item defining the rule for an active period.
	 */
	get versions() : SchedulingApiWorkModelWorkRuleVersions {
		this.getterDebugValidations(this.aiVersions, false);
		return this.versionsWrapper;
	}

	set versionsTestSetter(v : SchedulingApiWorkModelWorkRuleVersions) {
        this.setterImpl(3, v.rawData, 'versions', true, null, function(this : SchedulingApiWorkModelWorkRule) {this.versionsWrapper = v;});
	}

	/**
	 * When was this item created?
	 *
	 * @type {DateTime}
	 */
	get dateOfCreation() : DateTime {
		this.getterDebugValidations(this.aiDateOfCreation, false);
		return this.data[4];
	}

	set dateOfCreationTestSetter(v : DateTime) {
        this.setterImpl(4, v, 'dateOfCreation', true, null, null);
	}

	private creatorIdWrapper : Id | null = null!;

	/**
	 * (Detail) The user who created this item. "null" is returned when the item was created by Dr. Plano.
	 *
	 * @type {Id}
	 */
	get creatorId() : Id | null {
		this.getterDebugValidations(this.aiCreatorId, false);
		return this.creatorIdWrapper;
	}

	set creatorIdTestSetter(v : Id | null) {
        this.setterImpl(5, v, 'creatorId', true, null, function(this : SchedulingApiWorkModelWorkRule) {this.creatorIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.versionsWrapper._fixIds(_idReplacements);
		this.data[5] = Meta.getReplacedId(this.data[5], _idReplacements);
		this.creatorIdWrapper = this.data[5] === null ? null! : Id.create(this.data[5]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.versionsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		if(!Meta.isSameId(data ? data[5] : null, this.creatorIdWrapper))
			this.creatorIdWrapper = data && data[5] ? Id.create(data[5]) : null!;
	}

	protected get dni() : string {
		return '578';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '578', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionsBase extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersion>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRule | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'versions');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersion {
		return new SchedulingApiWorkModelWorkRuleVersion(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersions, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRule | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersions(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '581';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersion) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersion {
		const newItem = new SchedulingApiWorkModelWorkRuleVersion(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersions, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('versions');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersions, SchedulingApiWorkModelWorkRuleVersions> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersions, SchedulingApiWorkModelWorkRuleVersions>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersions,
			name: 'versions',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSIONS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersions) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, undefined);						return null;
					},
					() => { return this.checkOverlappingActivePeriods(); },
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersions) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersions, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 3,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionBase extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersion>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersions | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersion> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersion as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, SchedulingApiWorkModelWorkRuleVersion> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, SchedulingApiWorkModelWorkRuleVersion>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersion,
			name: 'workModelWorkRuleVersion',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersion, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiActivePeriodStart : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, Date> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, Date>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersion,
			name: 'activePeriodStart',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_ACTIVE_PERIOD_START',
			type: PApiType.Date,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersion) {
				return [
					() => {
return this.api.validators.max(() => this.activePeriodEnd, false, PApiType.Date, this.aiActivePeriodEnd.id, undefined);						return null;
					},
					() => {
						return this.api.validators.notUndefined(PApiType.Date, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersion) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersion, _nodeId : string) {return undefined;},
			rawDataIndex: 1,
		});
	aiActivePeriodEnd : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersion,
			name: 'activePeriodEnd',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_ACTIVE_PERIOD_END',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersion) {
				return [
					() => {
return this.api.validators.min(() => this.activePeriodStart, false, PApiType.DateExclusiveEnd, this.aiActivePeriodStart.id, undefined);						return null;
					},
					() => {
						return this.api.validators.notUndefined(PApiType.DateExclusiveEnd, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersion) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersion, _nodeId : string) {return undefined;},
			rawDataIndex: 2,
		});
	aiInternalNotes : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, string> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersion, string>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersion,
			name: 'internalNotes',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_INTERNAL_NOTES',
			type: PApiType.string,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersion) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersion) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});

	/**
	 * From when is this version valid? "null" means there is no start limit for this version.
	 *
	 * @type {Date}
	 */
	get activePeriodStart() : Date | null | undefined {
		this.getterDebugValidations(this.aiActivePeriodStart, false);
		return this.data[1];
	}

	set activePeriodStart(v : Date | null | undefined) {
        this.setterImpl(1, v, 'activePeriodStart', false, null, null);
	}

	/**
	 * Until when is this version valid? "null" means there is no end limit for this version.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get activePeriodEnd() : DateExclusiveEnd | null | undefined {
		this.getterDebugValidations(this.aiActivePeriodEnd, false);
		return this.data[2];
	}

	set activePeriodEnd(v : DateExclusiveEnd | null | undefined) {
        this.setterImpl(2, v, 'activePeriodEnd', false, null, null);
	}

	/**
	 * An optional internal note for this version.
	 *
	 * @type {string}
	 */
	get internalNotes() : string | null {
		this.getterDebugValidations(this.aiInternalNotes, false);
		return this.data[3];
	}

	set internalNotes(v : string | null) {
        this.setterImpl(3, v, 'internalNotes', false, null, null);
	}

	private workRuleCheckScopeWrapper : SchedulingApiWorkModelWorkRuleVersionCheckScope = new SchedulingApiWorkModelWorkRuleVersionCheckScope(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersion);
	public aiWorkRuleCheckScope = this.workRuleCheckScopeWrapper.aiThis;

	/**
	 * The check-scope object defining for this version how to determine the areas to be checked.
	 */
	get workRuleCheckScope() : SchedulingApiWorkModelWorkRuleVersionCheckScope {
		this.getterDebugValidations(this.aiWorkRuleCheckScope, false);
		return this.workRuleCheckScopeWrapper;
	}

	set workRuleCheckScopeTestSetter(v : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
        this.setterImpl(4, v.rawData, 'workRuleCheckScope', true, null, function(this : SchedulingApiWorkModelWorkRuleVersion) {this.workRuleCheckScopeWrapper = v;});
	}

	private workRuleCheckElementsWrapper : SchedulingApiWorkModelWorkRuleVersionCheckElements = new SchedulingApiWorkModelWorkRuleVersionCheckElements(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersion, false, false);
	public aiWorkRuleCheckElements = this.workRuleCheckElementsWrapper.aiThis;

	/**
	 * The list of check-elements to be calculated for each check-scope.
	 */
	get workRuleCheckElements() : SchedulingApiWorkModelWorkRuleVersionCheckElements {
		this.getterDebugValidations(this.aiWorkRuleCheckElements, false);
		return this.workRuleCheckElementsWrapper;
	}

	set workRuleCheckElementsTestSetter(v : SchedulingApiWorkModelWorkRuleVersionCheckElements) {
        this.setterImpl(5, v.rawData, 'workRuleCheckElements', true, null, function(this : SchedulingApiWorkModelWorkRuleVersion) {this.workRuleCheckElementsWrapper = v;});
	}

	private filterListsWrapper : SchedulingApiWorkModelWorkRuleVersionFilterLists = new SchedulingApiWorkModelWorkRuleVersionFilterLists(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersion, false, false);
	public aiFilterLists = this.filterListsWrapper.aiThis;

	/**
	 * List of filter-lists being attached to either the check-scope or a check-element item. When creating a new check scope/element, you should also create its attached filter-list. Technical note: This item must be deserialized after "WORK_MODEL_WORK_RULE_VERSION_CHECK_ELEMENTS" so the filter-list of newly created check-element will be available here.
	 */
	get filterLists() : SchedulingApiWorkModelWorkRuleVersionFilterLists {
		this.getterDebugValidations(this.aiFilterLists, false);
		return this.filterListsWrapper;
	}

	set filterListsTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterLists) {
        this.setterImpl(6, v.rawData, 'filterLists', true, null, function(this : SchedulingApiWorkModelWorkRuleVersion) {this.filterListsWrapper = v;});
	}

	private workRuleThresholdWrapper : SchedulingApiWorkModelWorkRuleVersionThreshold = new SchedulingApiWorkModelWorkRuleVersionThreshold(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersion);
	public aiWorkRuleThreshold = this.workRuleThresholdWrapper.aiThis;

	/**
	 * The threshold settings for this rule-version. Including the threshold value, type and its warning levels. And if a ledger should be kept and its warning levels.
	 */
	get workRuleThreshold() : SchedulingApiWorkModelWorkRuleVersionThreshold {
		this.getterDebugValidations(this.aiWorkRuleThreshold, false);
		return this.workRuleThresholdWrapper;
	}

	set workRuleThresholdTestSetter(v : SchedulingApiWorkModelWorkRuleVersionThreshold) {
        this.setterImpl(7, v.rawData, 'workRuleThreshold', true, null, function(this : SchedulingApiWorkModelWorkRuleVersion) {this.workRuleThresholdWrapper = v;});
	}

	private previewCheckScopesWrapper : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes = new SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersion, false, false);
	public aiPreviewCheckScopes = this.previewCheckScopesWrapper.aiThis;

	/**
	 * 
	 */
	get previewCheckScopes() : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes {
		this.getterDebugValidations(this.aiPreviewCheckScopes, false);
		return this.previewCheckScopesWrapper;
	}

	set previewCheckScopesTestSetter(v : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes) {
        this.setterImpl(8, v.rawData, 'previewCheckScopes', true, null, function(this : SchedulingApiWorkModelWorkRuleVersion) {this.previewCheckScopesWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.workRuleCheckScopeWrapper._fixIds(_idReplacements);
		this.workRuleCheckElementsWrapper._fixIds(_idReplacements);
		this.filterListsWrapper._fixIds(_idReplacements);
		this.workRuleThresholdWrapper._fixIds(_idReplacements);
		this.previewCheckScopesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.workRuleCheckScopeWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
		this.workRuleCheckElementsWrapper._updateRawData(data && data[5] !== undefined ? data[5] : null, generateMissingData);
		this.filterListsWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
		this.workRuleThresholdWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.previewCheckScopesWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
	}

	protected get dni() : string {
		return '584';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '584', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiWorkModelWorkRuleVersionCheckScopeBase extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionCheckScope>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersion | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionCheckScope> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionCheckScope as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, SchedulingApiWorkModelWorkRuleVersionCheckScope> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, SchedulingApiWorkModelWorkRuleVersionCheckScope>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScope,
			name: 'workRuleCheckScope',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 4,
		});
	aiType : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, SchedulingApiWorkRuleCheckScopeType> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, SchedulingApiWorkRuleCheckScopeType>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScope,
			name: 'type',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiCount : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, Integer> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, Integer>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScope,
			name: 'count',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_COUNT',
			type: PApiType.Integer,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return ((this.type === SchedulingApiWorkRuleCheckScopeType.DAYS)||(this.type === SchedulingApiWorkRuleCheckScopeType.WEEKS)||(this.type === SchedulingApiWorkRuleCheckScopeType.MONTHS));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
					() => {
		if(((this.type === SchedulingApiWorkRuleCheckScopeType.DAYS)||(this.type === SchedulingApiWorkRuleCheckScopeType.WEEKS)||(this.type === SchedulingApiWorkRuleCheckScopeType.MONTHS)))
		{
			return this.api.validators.required(PApiType.Integer, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.Integer, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
		if(((this.type === SchedulingApiWorkRuleCheckScopeType.DAYS)))
		{
			return this.api.validators.max(365, true, PApiType.Integer, undefined, undefined);
		}
						return null;
					},
					() => {
		if(((this.type === SchedulingApiWorkRuleCheckScopeType.WEEKS)))
		{
			return this.api.validators.max(52, true, PApiType.Integer, undefined, undefined);
		}
						return null;
					},
					() => {
		if(((this.type === SchedulingApiWorkRuleCheckScopeType.MONTHS)))
		{
			return this.api.validators.max(12, true, PApiType.Integer, undefined, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiCheckWholeDay : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScope,
			name: 'checkWholeDay',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_CHECK_WHOLE_DAY',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return ((this.type === SchedulingApiWorkRuleCheckScopeType.INDIVIDUAL_WEEKDAYS)||(this.type === SchedulingApiWorkRuleCheckScopeType.DAYS && this.count === 1)||(this.type === SchedulingApiWorkRuleCheckScopeType.PUBLIC_HOLIDAYS));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
					() => {
		if(((this.type === SchedulingApiWorkRuleCheckScopeType.INDIVIDUAL_WEEKDAYS)||(this.type === SchedulingApiWorkRuleCheckScopeType.DAYS && this.count === 1)||(this.type === SchedulingApiWorkRuleCheckScopeType.PUBLIC_HOLIDAYS)))
		{
			return this.api.validators.required(PApiType.boolean, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.boolean, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope, _nodeId : string) {return true;},
			rawDataIndex: 3,
		});
	aiStartTime : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, LocalTime> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScope,
			name: 'startTime',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_START_TIME',
			type: PApiType.LocalTime,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return ((this.aiCheckWholeDay.isAvailable && this.checkWholeDay === false));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
					() => {
		if(((this.endTime === null)))
		{
			return this.api.validators.required(PApiType.LocalTime, 'Bitte mindestens eines der beiden Felder ausfüllen.');
		}
						return null;
					},
					() => {
return this.api.validators.notEquals(() => this.endTime, PApiType.LocalTime, this.aiEndTime.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiEndTime : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, LocalTime> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScope,
			name: 'endTime',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_END_TIME',
			type: PApiType.LocalTime,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return ((this.aiCheckWholeDay.isAvailable && this.checkWholeDay === false));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
					() => {
		if(((this.startTime === null)))
		{
			return this.api.validators.required(PApiType.LocalTime, 'Bitte mindestens eines der beiden Felder ausfüllen.');
		}
						return null;
					},
					() => {
return this.api.validators.notEquals(() => this.startTime, PApiType.LocalTime, this.aiStartTime.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiAnchorDate : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, Date> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScope, Date>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScope,
			name: 'anchorDate',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_ANCHOR_DATE',
			type: PApiType.Date,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return ((this.type === SchedulingApiWorkRuleCheckScopeType.DAYS && (this.count??0) > 1)||(this.type === SchedulingApiWorkRuleCheckScopeType.WEEKS)||(this.type === SchedulingApiWorkRuleCheckScopeType.MONTHS));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
					() => {
		if(((this.type === SchedulingApiWorkRuleCheckScopeType.DAYS && (this.count??0) > 1)||(this.type === SchedulingApiWorkRuleCheckScopeType.WEEKS)||(this.type === SchedulingApiWorkRuleCheckScopeType.MONTHS)))
		{
			return this.api.validators.required(PApiType.Date, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.Date, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});

	/**
	 * The type of the check-scope to be calculated.
	 *
	 * @type {SchedulingApiWorkRuleCheckScopeType}
	 */
	get type() : SchedulingApiWorkRuleCheckScopeType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiWorkRuleCheckScopeType) {
        this.setterImpl(1, v, 'type', false, null, null);
	}

	/**
	 * The number of days, weeks or months to be checked as a block.
	 *
	 * @type {Integer}
	 */
	get count() : Integer | null {
		this.getterDebugValidations(this.aiCount, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[2] === undefined) {
			this.data[2] = this.aiCount.defaultValue;
			
		}
		return this.data[2];
	}

	set count(v : Integer | null) {
        this.setterImpl(2, v, 'count', false, null, null);
	}

	/**
	 * Should the whole day be checked? if not, then "startTime" and "endTime" will provide the hours which should be checked.
	 *
	 * @type {boolean}
	 */
	get checkWholeDay() : boolean | null {
		this.getterDebugValidations(this.aiCheckWholeDay, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiCheckWholeDay.defaultValue;
			
		}
		return this.data[3];
	}

	set checkWholeDay(v : boolean | null) {
        this.setterImpl(3, v, 'checkWholeDay', false, null, null);
	}

	/**
	 * The start-time of the day when the check should start.
	 *
	 * @type {LocalTime}
	 */
	get startTime() : LocalTime | null {
		this.getterDebugValidations(this.aiStartTime, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiStartTime.defaultValue;
			
		}
		return this.data[4];
	}

	set startTime(v : LocalTime | null) {
        this.setterImpl(4, v, 'startTime', false, null, null);
	}

	/**
	 * The end-time of the day when the check should start. Note that when "endTime" is smaller than "startTime", this means that the check will extend into the next day until "endTime".
	 *
	 * @type {LocalTime}
	 */
	get endTime() : LocalTime | null {
		this.getterDebugValidations(this.aiEndTime, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiEndTime.defaultValue;
			
		}
		return this.data[5];
	}

	set endTime(v : LocalTime | null) {
        this.setterImpl(5, v, 'endTime', false, null, null);
	}

	private weekdaysWrapper : SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays = new SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionCheckScope, false, false);
	public aiWeekdays = this.weekdaysWrapper.aiThis;

	/**
	 * The list of weekdays to be checked.
	 */
	get weekdays() : SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays {
		this.getterDebugValidations(this.aiWeekdays, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiWeekdays.defaultValue;
			this.weekdaysWrapper._updateRawData(this.data[6], true);
		}
		return this.weekdaysWrapper;
	}

	set weekdaysTestSetter(v : SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays) {
        this.setterImpl(6, v.rawData, 'weekdays', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionCheckScope) {this.weekdaysWrapper = v;});
	}

	/**
	 * The anchor date which defines the start of the recurrence. Note that this only aims to define the recurrence. Still the versions "activePeriodStart" and "activePeriodEnd" define the time-interval which is checked by the rule-version. By separating this anchor-date from the active-period start/end, we can easily copy a rule-version and change its active-period without affecting the recurrence rhythm.
	 *
	 * @type {Date}
	 */
	get anchorDate() : Date | null {
		this.getterDebugValidations(this.aiAnchorDate, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiAnchorDate.defaultValue;
			
		}
		return this.data[7];
	}

	set anchorDate(v : Date | null) {
        this.setterImpl(7, v, 'anchorDate', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.weekdaysWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.weekdaysWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
	}

	protected get dni() : string {
		return '588';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '588', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiWorkRuleCheckScopeType {
	DAYS = 1,
	INDIVIDUAL_WEEKDAYS = 2,
	WEEKS = 3,
	MONTHS = 4,
	WORK_SESSIONS = 5,
	PUBLIC_HOLIDAYS = 6,
	SCHOOL_HOLIDAYS = 7,
}
		 export class SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays extends ApiListWrapper<SchedulingApiWeekday>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionCheckScope | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'weekdays');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWeekday {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionCheckScope | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '600';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWeekday) => void) | null = null) : SchedulingApiWeekday {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays, SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays, SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays,
			name: 'weekdays',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_WEEKDAYS',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays) {
				return ((this.parent!.type === SchedulingApiWorkRuleCheckScopeType.INDIVIDUAL_WEEKDAYS));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays) {
				return [
					() => {
		if(((this.parent!.type === SchedulingApiWorkRuleCheckScopeType.INDIVIDUAL_WEEKDAYS)))
		{
			return this.api.validators.required(PApiType.ApiList, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.ApiList, undefined);
		}
						return null;
					},
					() => {
						return this.api.validators.maxLength(6, PApiType.ApiList, 'Du kannst nicht alle Tage auswählen. <strong>Tipp:</strong> Wenn du jeden einzelnen Tag prüfen möchtest, wähle als Prüfbereich »Tage« und gib den Wert »1« ein.');
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Enum,
			rawDataIndex: 6,
		});
	aiWorkModelWorkRuleVersionCheckScopeWeekday : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays, SchedulingApiWeekday> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays, SchedulingApiWeekday>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckScopeWeekdays,
			name: 'workModelWorkRuleVersionCheckScopeWeekday',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_SCOPE_WEEKDAY',
			type: PApiType.Enum,
			
		});
}

		export enum SchedulingApiWeekday {
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
	SUNDAY = 7,
}
		 export class SchedulingApiWorkModelWorkRuleVersionCheckElements extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionCheckElement>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersion | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'workRuleCheckElements');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionCheckElement {
		return new SchedulingApiWorkModelWorkRuleVersionCheckElement(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionCheckElements, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersion | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionCheckElements(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '589';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionCheckElement) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionCheckElement {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionCheckElement(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionCheckElements, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('workRuleCheckElements');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckElements, SchedulingApiWorkModelWorkRuleVersionCheckElements> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckElements, SchedulingApiWorkModelWorkRuleVersionCheckElements>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElements,
			name: 'workRuleCheckElements',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_ELEMENTS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElements) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElements) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElements, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 5,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionCheckElementBase extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionCheckElement>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionCheckElements | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionCheckElement> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionCheckElement as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement, SchedulingApiWorkModelWorkRuleVersionCheckElement> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement, SchedulingApiWorkModelWorkRuleVersionCheckElement>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
			name: 'workModelWorkRuleVersionCheckElement',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_ELEMENT',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiType : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement, SchedulingApiWorkRuleCheckElementType> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement, SchedulingApiWorkRuleCheckElementType>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
			name: 'type',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_CHECK_ELEMENT_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
					() => {return this.ensureCheckElementsHaveSameUnit()},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				return [
				];
			}
			,
			rawDataIndex: 1,
			attributeValueInfos: new Map([
				[
					SchedulingApiWorkRuleCheckElementType.PAUSE_COUNT,
					new ApiAttributeValueInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement>({
						apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
						canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: 'Demnächst verfügbar…', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiWorkRuleCheckElementType.PAUSE_HOURS,
					new ApiAttributeValueInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement>({
						apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
						canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: 'Demnächst verfügbar…', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiWorkRuleCheckElementType.REST_PERIOD_COUNT,
					new ApiAttributeValueInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement>({
						apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
						canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: 'Demnächst verfügbar…', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiWorkRuleCheckElementType.REST_PERIOD_HOURS,
					new ApiAttributeValueInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement>({
						apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
						canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: 'Demnächst verfügbar…', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_COUNT,
					new ApiAttributeValueInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement>({
						apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
						canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: 'Demnächst verfügbar…', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_HOURS,
					new ApiAttributeValueInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement>({
						apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
						canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: 'Demnächst verfügbar…', params: false} : undefined;}
				return true;
						},
					}),
				],
				[
					SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_EARNINGS,
					new ApiAttributeValueInfo<SchedulingApiWorkModelWorkRuleVersionCheckElement>({
						apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionCheckElement,
						canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				{const conditionValue = false; if(!conditionValue) return conditionValue === false ? {sourceString: 'Demnächst verfügbar…', params: false} : undefined;}
				return true;
						},
					}),
				],
			]),
		});

	/**
	 * The type of the check-element to be calculated.
	 *
	 * @type {SchedulingApiWorkRuleCheckElementType}
	 */
	get type() : SchedulingApiWorkRuleCheckElementType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[1];
	}

	set type(v : SchedulingApiWorkRuleCheckElementType) {
        this.setterImpl(1, v, 'type', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_CHECK_ELEMENT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '593';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '593', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiWorkRuleCheckElementType {
	WORK_SESSION_COUNT = 1,
	WORK_SESSION_HOURS = 2,
	WORK_SESSION_DAYS = 3,
	WORK_SESSION_EARNINGS = 4,
	LEAVE_COUNT = 5,
	LEAVE_HOURS = 6,
	LEAVE_DAYS = 7,
	LEAVE_EARNINGS = 8,
	PAUSE_COUNT = 9,
	PAUSE_HOURS = 10,
	REST_PERIOD_COUNT = 11,
	REST_PERIOD_HOURS = 12,
	SHIFT_PREFERENCE_COUNT = 13,
	SHIFT_PREFERENCE_HOURS = 14,
	PAY_DIFFERENTIALS_COUNT = 15,
	PAY_DIFFERENTIALS_HOURS = 16,
	PAY_DIFFERENTIALS_EARNINGS = 17,
}
		 export class SchedulingApiWorkModelWorkRuleVersionFilterLists extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterList>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersion | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'filterLists');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterList {
		return new SchedulingApiWorkModelWorkRuleVersionFilterList(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterLists, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersion | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterLists(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '590';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterList) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterList {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterList(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterLists, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('filterLists');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterLists, SchedulingApiWorkModelWorkRuleVersionFilterLists> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterLists, SchedulingApiWorkModelWorkRuleVersionFilterLists>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterLists,
			name: 'filterLists',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LISTS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterLists, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 6,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListBase extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterList>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterLists | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterList> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterList as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterList, SchedulingApiWorkModelWorkRuleVersionFilterList> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterList, SchedulingApiWorkModelWorkRuleVersionFilterList>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterList,
			name: 'workModelWorkRuleVersionFilterList',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterList, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiLogicalConnective : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterList, SchedulingApiWorkRuleFilterListLogicalConnective> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterList, SchedulingApiWorkRuleFilterListLogicalConnective>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterList,
			name: 'logicalConnective',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOGICAL_CONNECTIVE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterList, _nodeId : string) {return SchedulingApiWorkRuleFilterListLogicalConnective.ALL_FILTERS_MUST_BE_MET;},
			rawDataIndex: 1,
		});
	aiCheckElementId : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterList, Id> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterList, Id>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterList,
			name: 'checkElementId',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_CHECK_ELEMENT_ID',
			type: PApiType.Id,
			rawDataIndex: 2,
		});

	/**
	 * How should the filters defined by this list be combined?
	 *
	 * @type {SchedulingApiWorkRuleFilterListLogicalConnective}
	 */
	get logicalConnective() : SchedulingApiWorkRuleFilterListLogicalConnective {
		this.getterDebugValidations(this.aiLogicalConnective, false);
		return this.data[1];
	}

	set logicalConnective(v : SchedulingApiWorkRuleFilterListLogicalConnective) {
        this.setterImpl(1, v, 'logicalConnective', false, null, null);
	}

	private checkElementIdWrapper : Id | null = null!;

	/**
	 * The check-element for which this list defines filters. If this is "null" then the filter-list is defining filters for the check-scope.
	 *
	 * @type {Id}
	 */
	get checkElementId() : Id | null {
		this.getterDebugValidations(this.aiCheckElementId, false);
		return this.checkElementIdWrapper;
	}

	set checkElementId(v : Id | null) {
        this.setterImpl(2, v, 'checkElementId', false, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.checkElementIdWrapper = v;});
	}

	private leaveDayTypeFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiLeaveDayTypeFilters = this.leaveDayTypeFiltersWrapper.aiThis;

	/**
	 * The list of leave-day-type filters.
	 */
	get leaveDayTypeFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters {
		this.getterDebugValidations(this.aiLeaveDayTypeFilters, false);
		return this.leaveDayTypeFiltersWrapper;
	}

	set leaveDayTypeFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters) {
        this.setterImpl(3, v.rawData, 'leaveDayTypeFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.leaveDayTypeFiltersWrapper = v;});
	}

	private leaveDayPaymentTypeFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiLeaveDayPaymentTypeFilters = this.leaveDayPaymentTypeFiltersWrapper.aiThis;

	/**
	 * The list of leave-day-payment-type filters.
	 */
	get leaveDayPaymentTypeFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters {
		this.getterDebugValidations(this.aiLeaveDayPaymentTypeFilters, false);
		return this.leaveDayPaymentTypeFiltersWrapper;
	}

	set leaveDayPaymentTypeFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters) {
        this.setterImpl(4, v.rawData, 'leaveDayPaymentTypeFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.leaveDayPaymentTypeFiltersWrapper = v;});
	}

	private weekdayFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiWeekdayFilters = this.weekdayFiltersWrapper.aiThis;

	/**
	 * The list of weekday filters.
	 */
	get weekdayFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters {
		this.getterDebugValidations(this.aiWeekdayFilters, false);
		return this.weekdayFiltersWrapper;
	}

	set weekdayFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters) {
        this.setterImpl(5, v.rawData, 'weekdayFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.weekdayFiltersWrapper = v;});
	}

	private usersAgeFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiUsersAgeFilters = this.usersAgeFiltersWrapper.aiThis;

	/**
	 * The list of users-age filters.
	 */
	get usersAgeFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters {
		this.getterDebugValidations(this.aiUsersAgeFilters, false);
		return this.usersAgeFiltersWrapper;
	}

	set usersAgeFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters) {
        this.setterImpl(6, v.rawData, 'usersAgeFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.usersAgeFiltersWrapper = v;});
	}

	private localTimeFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiLocalTimeFilters = this.localTimeFiltersWrapper.aiThis;

	/**
	 * The list of local-time filters.
	 */
	get localTimeFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters {
		this.getterDebugValidations(this.aiLocalTimeFilters, false);
		return this.localTimeFiltersWrapper;
	}

	set localTimeFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters) {
        this.setterImpl(7, v.rawData, 'localTimeFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.localTimeFiltersWrapper = v;});
	}

	private activityFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiActivityFilters = this.activityFiltersWrapper.aiThis;

	/**
	 * The list of activity filters.
	 */
	get activityFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters {
		this.getterDebugValidations(this.aiActivityFilters, false);
		return this.activityFiltersWrapper;
	}

	set activityFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters) {
        this.setterImpl(8, v.rawData, 'activityFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.activityFiltersWrapper = v;});
	}

	private itemCategoryFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiItemCategoryFilters = this.itemCategoryFiltersWrapper.aiThis;

	/**
	 * The list of item-category filters.
	 */
	get itemCategoryFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters {
		this.getterDebugValidations(this.aiItemCategoryFilters, false);
		return this.itemCategoryFiltersWrapper;
	}

	set itemCategoryFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters) {
        this.setterImpl(9, v.rawData, 'itemCategoryFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.itemCategoryFiltersWrapper = v;});
	}

	private holidayItemLabelFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiHolidayItemLabelFilters = this.holidayItemLabelFiltersWrapper.aiThis;

	/**
	 * The list of holiday-item-label filters.
	 */
	get holidayItemLabelFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters {
		this.getterDebugValidations(this.aiHolidayItemLabelFilters, false);
		return this.holidayItemLabelFiltersWrapper;
	}

	set holidayItemLabelFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters) {
        this.setterImpl(10, v.rawData, 'holidayItemLabelFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.holidayItemLabelFiltersWrapper = v;});
	}

	private shiftPreferenceFiltersWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters = new SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterList, false, false);
	public aiShiftPreferenceFilters = this.shiftPreferenceFiltersWrapper.aiThis;

	/**
	 * The list of shift-preference filters.
	 */
	get shiftPreferenceFilters() : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters {
		this.getterDebugValidations(this.aiShiftPreferenceFilters, false);
		return this.shiftPreferenceFiltersWrapper;
	}

	set shiftPreferenceFiltersTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters) {
        this.setterImpl(11, v.rawData, 'shiftPreferenceFilters', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterList) {this.shiftPreferenceFiltersWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.checkElementIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
		this.leaveDayTypeFiltersWrapper._fixIds(_idReplacements);
		this.leaveDayPaymentTypeFiltersWrapper._fixIds(_idReplacements);
		this.weekdayFiltersWrapper._fixIds(_idReplacements);
		this.usersAgeFiltersWrapper._fixIds(_idReplacements);
		this.localTimeFiltersWrapper._fixIds(_idReplacements);
		this.activityFiltersWrapper._fixIds(_idReplacements);
		this.itemCategoryFiltersWrapper._fixIds(_idReplacements);
		this.holidayItemLabelFiltersWrapper._fixIds(_idReplacements);
		this.shiftPreferenceFiltersWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[2] : null, this.checkElementIdWrapper))
			this.checkElementIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
		this.leaveDayTypeFiltersWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		this.leaveDayPaymentTypeFiltersWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
		this.weekdayFiltersWrapper._updateRawData(data && data[5] !== undefined ? data[5] : null, generateMissingData);
		this.usersAgeFiltersWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
		this.localTimeFiltersWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.activityFiltersWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		this.itemCategoryFiltersWrapper._updateRawData(data && data[9] !== undefined ? data[9] : null, generateMissingData);
		this.holidayItemLabelFiltersWrapper._updateRawData(data && data[10] !== undefined ? data[10] : null, generateMissingData);
		this.shiftPreferenceFiltersWrapper._updateRawData(data && data[11] !== undefined ? data[11] : null, generateMissingData);
	}

	protected get dni() : string {
		return '603';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '603', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiWorkRuleFilterListLogicalConnective {
	ALL_FILTERS_MUST_BE_MET = 1,
	AT_LEAST_ONE_FILTER_MUST_BE_MET = 2,
}
		 export class SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'leaveDayTypeFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '606';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('leaveDayTypeFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters,
			name: 'leaveDayTypeFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 3,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter,
			name: 'workModelWorkRuleVersionFilterListLeaveDayTypeFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	private typesWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes = new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter, false, false);
	public aiTypes = this.typesWrapper.aiThis;

	/**
	 * List of the types being filtered.
	 */
	get types() : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes {
		this.getterDebugValidations(this.aiTypes, false);
		return this.typesWrapper;
	}

	set typesTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes) {
        this.setterImpl(2, v.rawData, 'types', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter) {this.typesWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.typesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.typesWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '615';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '615', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes extends ApiListWrapper<SchedulingApiLeaveDayType>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'types');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiLeaveDayType {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '617';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiLeaveDayType) => void) | null = null) : SchedulingApiLeaveDayType {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes,
			name: 'types',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTER_TYPES',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');						return null;
					},
					() => {
						return this.api.validators.maxLength(4, PApiType.ApiList, () => this.parent!.exclude ? 'Es ist nicht zulässig, alle Optionen aus der Liste auszuwählen. Wähle bitte mindestens eine Option ab oder entferne den Filter vollständig. <strong>Tipp:</strong> Falls du alle Einträge einer Kategorie herausfiltern möchtest, verwende den Filter »Alle Einträge nach Kategorie«.' : 'Wenn du alle Optionen auswählst, hat der Filter keinen Effekt. Bitte wähle mindestens eine Option ab oder entferne den Filter vollständig.');
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Enum,
			rawDataIndex: 2,
		});
	aiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterType : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes, SchedulingApiLeaveDayType> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes, SchedulingApiLeaveDayType>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayTypeFilterTypes,
			name: 'workModelWorkRuleVersionFilterListLeaveDayTypeFilterType',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_TYPE_FILTER_TYPE',
			type: PApiType.Enum,
			
		});
}

				 export class SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'leaveDayPaymentTypeFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '607';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('leaveDayPaymentTypeFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters,
			name: 'leaveDayPaymentTypeFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 4,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter,
			name: 'workModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	private typesWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes = new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter, false, false);
	public aiTypes = this.typesWrapper.aiThis;

	/**
	 * List of the payment-types being filtered.
	 */
	get types() : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes {
		this.getterDebugValidations(this.aiTypes, false);
		return this.typesWrapper;
	}

	set typesTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes) {
        this.setterImpl(2, v.rawData, 'types', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter) {this.typesWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.typesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.typesWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '619';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '619', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes extends ApiListWrapper<SchedulingApiLeaveDayPaymentType>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'types');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiLeaveDayPaymentType {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '621';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiLeaveDayPaymentType) => void) | null = null) : SchedulingApiLeaveDayPaymentType {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes, SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes,
			name: 'types',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTER_TYPES',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');						return null;
					},
					() => {
						return this.api.validators.maxLength(2, PApiType.ApiList, () => this.parent!.exclude ? 'Es ist nicht zulässig, alle Optionen aus der Liste auszuwählen. Wähle bitte mindestens eine Option ab oder entferne den Filter vollständig. <strong>Tipp:</strong> Falls du alle Einträge einer Kategorie herausfiltern möchtest, verwende den Filter »Alle Einträge nach Kategorie«.' : 'Wenn du alle Optionen auswählst, hat der Filter keinen Effekt. Bitte wähle mindestens eine Option ab oder entferne den Filter vollständig.');
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Enum,
			rawDataIndex: 2,
		});
	aiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterType : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes, SchedulingApiLeaveDayPaymentType> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes, SchedulingApiLeaveDayPaymentType>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterTypes,
			name: 'workModelWorkRuleVersionFilterListLeaveDayPaymentTypeFilterType',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LEAVE_DAY_PAYMENT_TYPE_FILTER_TYPE',
			type: PApiType.Enum,
			
		});
}

				 export class SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'weekdayFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '608';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('weekdayFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters, SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters, SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters,
			name: 'weekdayFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 5,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter, SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter, SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter,
			name: 'workModelWorkRuleVersionFilterListWeekdayFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	private weekdaysWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays = new SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter, false, false);
	public aiWeekdays = this.weekdaysWrapper.aiThis;

	/**
	 * List of the weekdays being filtered.
	 */
	get weekdays() : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays {
		this.getterDebugValidations(this.aiWeekdays, false);
		return this.weekdaysWrapper;
	}

	set weekdaysTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays) {
        this.setterImpl(2, v.rawData, 'weekdays', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter) {this.weekdaysWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.weekdaysWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.weekdaysWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '623';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '623', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays extends ApiListWrapper<SchedulingApiWeekday>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'weekdays');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWeekday {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '625';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWeekday) => void) | null = null) : SchedulingApiWeekday {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays, SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays, SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays,
			name: 'weekdays',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTER_WEEKDAYS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');						return null;
					},
					() => {
						return this.api.validators.maxLength(6, PApiType.ApiList, () => this.parent!.exclude ? 'Es ist nicht zulässig, alle Optionen aus der Liste auszuwählen. Wähle bitte mindestens eine Option ab oder entferne den Filter vollständig.' : 'Wenn du alle Optionen auswählst, hat der Filter keinen Effekt. Bitte wähle mindestens eine Option ab oder entferne den Filter vollständig.');
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Enum,
			rawDataIndex: 2,
		});
	aiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekday : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays, SchedulingApiWeekday> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays, SchedulingApiWeekday>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListWeekdayFilterWeekdays,
			name: 'workModelWorkRuleVersionFilterListWeekdayFilterWeekday',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_WEEKDAY_FILTER_WEEKDAY',
			type: PApiType.Enum,
			
		});
}

				 export class SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'usersAgeFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '609';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('usersAgeFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters,
			name: 'usersAgeFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 6,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter,
			name: 'workModelWorkRuleVersionFilterListUsersAgeFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiMin : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, Integer> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, Integer>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter,
			name: 'min',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_MIN',
			type: PApiType.Integer,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
					() => {
return this.api.validators.min(1, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.max, true, PApiType.Integer, this.aiMax.id, undefined);						return null;
					},
					() => {
		if(((this.max === null && !this.ageUnknown)))
		{
			return this.api.validators.required(PApiType.Integer, 'Bitte mindestens eines der beiden Felder ausfüllen.');
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiMax : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, Integer> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, Integer>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter,
			name: 'max',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_MAX',
			type: PApiType.Integer,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
					() => {
return this.api.validators.min(() => this.min, true, PApiType.Integer, this.aiMin.id, undefined);						return null;
					},
					() => {
return this.api.validators.max(100, true, PApiType.Integer, undefined, undefined);						return null;
					},
					() => {
		if(((this.min === null && !this.ageUnknown)))
		{
			return this.api.validators.required(PApiType.Integer, 'Bitte mindestens eines der beiden Felder ausfüllen.');
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiAgeUnknown : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter,
			name: 'ageUnknown',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER_AGE_UNKNOWN',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListUsersAgeFilter, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	/**
	 * The inclusive minimum age being accepted. "null" means there is no minimum limit.
	 *
	 * @type {Integer}
	 */
	get min() : Integer | null {
		this.getterDebugValidations(this.aiMin, false);
		return this.data[2];
	}

	set min(v : Integer | null) {
        this.setterImpl(2, v, 'min', false, null, null);
	}

	/**
	 * The inclusive maximum age being accepted. "null" means there is no minimum limit.
	 *
	 * @type {Integer}
	 */
	get max() : Integer | null {
		this.getterDebugValidations(this.aiMax, false);
		return this.data[3];
	}

	set max(v : Integer | null) {
        this.setterImpl(3, v, 'max', false, null, null);
	}

	/**
	 * Should users with unknown age be accepted?
	 *
	 * @type {boolean}
	 */
	get ageUnknown() : boolean {
		this.getterDebugValidations(this.aiAgeUnknown, false);
		return this.data[4];
	}

	set ageUnknown(v : boolean) {
        this.setterImpl(4, v, 'ageUnknown', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_USERS_AGE_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '627';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '627', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'localTimeFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '610';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('localTimeFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters, SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters,
			name: 'localTimeFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 7,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter,
			name: 'workModelWorkRuleVersionFilterListLocalTimeFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiStartTime : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, LocalTime> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter,
			name: 'startTime',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER_START_TIME',
			type: PApiType.LocalTime,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
					() => {
		if(((this.endTime === null)))
		{
			return this.api.validators.required(PApiType.LocalTime, 'Bitte mindestens eines der beiden Felder ausfüllen.');
		}
						return null;
					},
					() => {
return this.api.validators.notEquals(() => this.endTime, PApiType.LocalTime, this.aiEndTime.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiEndTime : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, LocalTime> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter, LocalTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter,
			name: 'endTime',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER_END_TIME',
			type: PApiType.LocalTime,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
					() => {
		if(((this.startTime === null)))
		{
			return this.api.validators.required(PApiType.LocalTime, 'Bitte mindestens eines der beiden Felder ausfüllen.');
		}
						return null;
					},
					() => {
return this.api.validators.notEquals(() => this.startTime, PApiType.LocalTime, this.aiStartTime.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListLocalTimeFilter) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	/**
	 * The inclusive start-time being accepted. "null" means the start-time limit is the start of the day.
	 *
	 * @type {LocalTime}
	 */
	get startTime() : LocalTime | null {
		this.getterDebugValidations(this.aiStartTime, false);
		return this.data[2];
	}

	set startTime(v : LocalTime | null) {
        this.setterImpl(2, v, 'startTime', false, null, null);
	}

	/**
	 * The exclusive end-time being accepted. "null" means the end-time limit is the end of the day. Note that when "endTime" is smaller than "startTime", this means that the check will extend into the next day until "endTime".
	 *
	 * @type {LocalTime}
	 */
	get endTime() : LocalTime | null {
		this.getterDebugValidations(this.aiEndTime, false);
		return this.data[3];
	}

	set endTime(v : LocalTime | null) {
        this.setterImpl(3, v, 'endTime', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_LOCAL_TIME_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '632';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '632', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'activityFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '611';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('activityFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters,
			name: 'activityFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 8,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter,
			name: 'workModelWorkRuleVersionFilterListActivityFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	private activityAreaIdsWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds = new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, false, false);
	public aiActivityAreaIds = this.activityAreaIdsWrapper.aiThis;

	/**
	 * A list of activity-area ids to be filtered. See "api.data.activityAreas".
	 */
	get activityAreaIds() : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds {
		this.getterDebugValidations(this.aiActivityAreaIds, false);
		return this.activityAreaIdsWrapper;
	}

	set activityAreaIdsTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds) {
        this.setterImpl(2, v.rawData, 'activityAreaIds', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter) {this.activityAreaIdsWrapper = v;});
	}

	private activityIdsWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds = new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter, false, false);
	public aiActivityIds = this.activityIdsWrapper.aiThis;

	/**
	 * A list of activity ids to be filtered. See "api.data.shiftModels".
	 */
	get activityIds() : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds {
		this.getterDebugValidations(this.aiActivityIds, false);
		return this.activityIdsWrapper;
	}

	set activityIdsTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds) {
        this.setterImpl(3, v.rawData, 'activityIds', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter) {this.activityIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.activityAreaIdsWrapper._fixIds(_idReplacements);
		this.activityIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.activityAreaIdsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.activityIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '636';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '636', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'activityAreaIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '638';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds,
			name: 'activityAreaIds',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_ACTIVITY_AREA_IDS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds) {
				return [
					() => {
		if(((this.parent!.activityIds.length === 0)))
		{
			return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 2,
		});
	aiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaId : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds, Id> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds, Id>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityAreaIds,
			name: 'workModelWorkRuleVersionFilterListActivityFilterActivityAreaId',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_ACTIVITY_AREA_ID',
			type: PApiType.Id,
			
		});
}

				 export class SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'activityIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '639';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds, SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds,
			name: 'activityIds',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_ACTIVITY_IDS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds) {
				return [
					() => {
		if(((this.parent!.activityAreaIds.length === 0)))
		{
			return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 3,
		});
	aiWorkModelWorkRuleVersionFilterListActivityFilterActivityId : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds, Id> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds, Id>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListActivityFilterActivityIds,
			name: 'workModelWorkRuleVersionFilterListActivityFilterActivityId',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ACTIVITY_FILTER_ACTIVITY_ID',
			type: PApiType.Id,
			
		});
}

				 export class SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'itemCategoryFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '612';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('itemCategoryFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters, SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters, SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters,
			name: 'itemCategoryFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 9,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter, SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter, SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter,
			name: 'workModelWorkRuleVersionFilterListItemCategoryFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	private itemCategoriesWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories = new SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter, false, false);
	public aiItemCategories = this.itemCategoriesWrapper.aiThis;

	/**
	 * List of the item-categories being filtered.
	 */
	get itemCategories() : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories {
		this.getterDebugValidations(this.aiItemCategories, false);
		return this.itemCategoriesWrapper;
	}

	set itemCategoriesTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories) {
        this.setterImpl(2, v.rawData, 'itemCategories', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter) {this.itemCategoriesWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.itemCategoriesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.itemCategoriesWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '642';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '642', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories extends ApiListWrapper<SchedulingApiWorkRuleItemCategory>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'itemCategories');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkRuleItemCategory {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '644';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkRuleItemCategory) => void) | null = null) : SchedulingApiWorkRuleItemCategory {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories, SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories, SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories,
			name: 'itemCategories',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTER_ITEM_CATEGORIES',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');						return null;
					},
					() => {
						return this.api.validators.maxLength(3, PApiType.ApiList, () => this.parent!.exclude ? 'Es ist nicht zulässig, alle Optionen aus der Liste auszuwählen. Wähle bitte mindestens eine Option ab oder entferne den Filter vollständig. <strong>Tipp:</strong> Falls du alle Einträge einer Kategorie herausfiltern möchtest, verwende den Filter »Alle Einträge nach Kategorie«.' : 'Wenn du alle Optionen auswählst, hat der Filter keinen Effekt. Bitte wähle mindestens eine Option ab oder entferne den Filter vollständig.');
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Enum,
			rawDataIndex: 2,
		});
	aiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategory : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories, SchedulingApiWorkRuleItemCategory> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories, SchedulingApiWorkRuleItemCategory>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListItemCategoryFilterItemCategories,
			name: 'workModelWorkRuleVersionFilterListItemCategoryFilterItemCategory',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_ITEM_CATEGORY_FILTER_ITEM_CATEGORY',
			type: PApiType.Enum,
			
		});
}

		export enum SchedulingApiWorkRuleItemCategory {
	LEAVES = 1,
	WORK_SESSIONS = 2,
	PUBLIC_HOLIDAYS = 3,
	SCHOOL_HOLIDAYS = 4,
}
		 export class SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'holidayItemLabelFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '613';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('holidayItemLabelFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters,
			name: 'holidayItemLabelFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 10,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter,
			name: 'workModelWorkRuleVersionFilterListHolidayItemLabelFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!, this.type);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiType : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, SchedulingApiHolidayItemType> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, SchedulingApiHolidayItemType>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter,
			name: 'type',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiItemsWithNoLabel : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter,
			name: 'itemsWithNoLabel',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_ITEMS_WITH_NO_LABEL',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	/**
	 * This filter affects the holiday-items which have "type" equal this value.
	 *
	 * @type {SchedulingApiHolidayItemType}
	 */
	get type() : SchedulingApiHolidayItemType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[2];
	}

	set type(v : SchedulingApiHolidayItemType) {
        this.setterImpl(2, v, 'type', false, null, null);
	}

	private labelsWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds = new SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter, false, false);
	public aiLabels = this.labelsWrapper.aiThis;

	/**
	 * A list of label-ids to be filtered. See "api.data.holidays.labels".
	 */
	get labels() : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds {
		this.getterDebugValidations(this.aiLabels, false);
		return this.labelsWrapper;
	}

	set labelsTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds) {
        this.setterImpl(3, v.rawData, 'labels', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter) {this.labelsWrapper = v;});
	}

	/**
	 * Should holiday-items with no label be accepted?
	 *
	 * @type {boolean}
	 */
	get itemsWithNoLabel() : boolean {
		this.getterDebugValidations(this.aiItemsWithNoLabel, false);
		return this.data[4];
	}

	set itemsWithNoLabel(v : boolean) {
        this.setterImpl(4, v, 'itemsWithNoLabel', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.labelsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.labelsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '646';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '646', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiHolidayItemType {
	PUBLIC_HOLIDAY = 1,
	SCHOOL_HOLIDAY = 2,
}
		 export class SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds extends ApiListWrapper<LabelId>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'labels');
	}

	override wrapRawData(itemRawData : any) : LabelId {
		return LabelId.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '649';
	}

	override createNewItem(_initCode : ((newItem : LabelId) => void) | null = null) : LabelId {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds,
			name: 'labels',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_LABEL_IDS',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds) {
				return [
					() => {
		if(((this.parent!.aiItemsWithNoLabel.isAvailable && !this.parent!.itemsWithNoLabel)))
		{
			return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.LabelId,
			rawDataIndex: 3,
		});
	aiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelId : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds, LabelId> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds, LabelId>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelIds,
			name: 'workModelWorkRuleVersionFilterListHolidayItemLabelFilterLabelId',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_HOLIDAY_ITEM_LABEL_FILTER_LABEL_ID',
			type: PApiType.LabelId,
			
		});
}

				 export class SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftPreferenceFilters');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterList | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '614';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftPreferenceFilters');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters, SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters, SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters,
			name: 'shiftPreferenceFilters',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTERS',
			type: PApiType.ApiList,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 11,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilters | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter, SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter, SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter,
			name: 'workModelWorkRuleVersionFilterListShiftPreferenceFilter',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTER',
			type: PApiType.ApiObject,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter) {
				return [
					() => {return this.parent!.parent!.validateOnlySupportedFiltersAreUsed(this.parent!);},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiExclude : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter,
			name: 'exclude',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTER_EXCLUDE',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});

	/**
	 * When "false" this filter returns the opposite set of items which it normally would filter.
	 *
	 * @type {boolean}
	 */
	get exclude() : boolean {
		this.getterDebugValidations(this.aiExclude, false);
		return this.data[1];
	}

	set exclude(v : boolean) {
        this.setterImpl(1, v, 'exclude', false, null, null);
	}

	private shiftPreferencesWrapper : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences = new SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter, false, false);
	public aiShiftPreferences = this.shiftPreferencesWrapper.aiThis;

	/**
	 * List of the shift-preferences being filtered.
	 */
	get shiftPreferences() : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences {
		this.getterDebugValidations(this.aiShiftPreferences, false);
		return this.shiftPreferencesWrapper;
	}

	set shiftPreferencesTestSetter(v : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences) {
        this.setterImpl(2, v.rawData, 'shiftPreferences', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter) {this.shiftPreferencesWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftPreferencesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftPreferencesWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '652';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '652', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences extends ApiListWrapper<SchedulingApiShiftMemberPrefValue>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftPreferences');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiShiftMemberPrefValue {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilter | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '654';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiShiftMemberPrefValue) => void) | null = null) : SchedulingApiShiftMemberPrefValue {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences, SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences, SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences,
			name: 'shiftPreferences',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTER_SHIFT_PREFERENCES',
			type: PApiType.ApiList,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences) {
				return [
					() => {
return this.api.validators.required(PApiType.ApiList, 'Bitte wähle mindestens eine Option aus der Liste, damit der Filter wirkt. Wenn du den Filter nicht mehr brauchst, entferne ihn bitte vollständig.');						return null;
					},
					() => {
						return this.api.validators.maxLength(2, PApiType.ApiList, () => this.parent!.exclude ? 'Es ist nicht zulässig, alle Optionen aus der Liste auszuwählen. Wähle bitte mindestens eine Option ab oder entferne den Filter vollständig. <strong>Tipp:</strong> Falls du alle Einträge einer Kategorie herausfiltern möchtest, verwende den Filter »Alle Einträge nach Kategorie«.' : 'Wenn du alle Optionen auswählst, hat der Filter keinen Effekt. Bitte wähle mindestens eine Option ab oder entferne den Filter vollständig.');
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Enum,
			rawDataIndex: 2,
		});
	aiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreference : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences, SchedulingApiShiftMemberPrefValue> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences, SchedulingApiShiftMemberPrefValue>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreferences,
			name: 'workModelWorkRuleVersionFilterListShiftPreferenceFilterShiftPreference',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_FILTER_LIST_SHIFT_PREFERENCE_FILTER_SHIFT_PREFERENCE',
			type: PApiType.Enum,
			
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionThresholdBase extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionThreshold>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersion | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionThreshold> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionThreshold as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkModelWorkRuleVersionThreshold> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkModelWorkRuleVersionThreshold>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'workRuleThreshold',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 7,
		});
	aiThresholdType : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkRuleThresholdType> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkRuleThresholdType>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'thresholdType',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_TYPE',
			type: PApiType.Enum,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold, _nodeId : string) {return SchedulingApiWorkRuleThresholdType.TARGET;},
			rawDataIndex: 1,
		});
	aiThreshold : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, ClientCurrency|number> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, ClientCurrency|number>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'threshold',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD',
			type: () => (this as unknown as SchedulingApiWorkModelWorkRuleVersionThreshold).thresholdApiType,
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
return this.api.validators.required(() => (this as unknown as SchedulingApiWorkModelWorkRuleVersionThreshold).thresholdApiType, undefined);						return null;
					},
					() => {
return this.api.validators.min(0, true, () => (this as unknown as SchedulingApiWorkModelWorkRuleVersionThreshold).thresholdApiType, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, () => (this as unknown as SchedulingApiWorkModelWorkRuleVersionThreshold).thresholdApiType, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiThresholdNegativeInterval2Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'thresholdNegativeInterval2Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_NEGATIVE_INTERVAL_2_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.thresholdType === SchedulingApiWorkRuleThresholdType.TARGET)||(this.thresholdType === SchedulingApiWorkRuleThresholdType.BALANCE));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.thresholdType === SchedulingApiWorkRuleThresholdType.TARGET)||(this.thresholdType === SchedulingApiWorkRuleThresholdType.BALANCE)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(() => this.thresholdNegativeInterval1Start, true, PApiType.Percent, this.aiThresholdNegativeInterval1Start.id, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
					() => {
return this.api.validators.max(1.01, true, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiThresholdNegativeInterval1Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'thresholdNegativeInterval1Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_NEGATIVE_INTERVAL_1_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.thresholdType !== SchedulingApiWorkRuleThresholdType.MINIMUM));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.thresholdType !== SchedulingApiWorkRuleThresholdType.MINIMUM)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.Percent, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
					() => {
return this.api.validators.max(1.01, true, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiThresholdPositiveInterval1Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'thresholdPositiveInterval1Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_POSITIVE_INTERVAL_1_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.thresholdType !== SchedulingApiWorkRuleThresholdType.MAXIMUM));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.thresholdType !== SchedulingApiWorkRuleThresholdType.MAXIMUM)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.Percent, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiThresholdPositiveInterval2Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'thresholdPositiveInterval2Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_POSITIVE_INTERVAL_2_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.thresholdType === SchedulingApiWorkRuleThresholdType.TARGET)||(this.thresholdType === SchedulingApiWorkRuleThresholdType.BALANCE));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.thresholdType === SchedulingApiWorkRuleThresholdType.TARGET)||(this.thresholdType === SchedulingApiWorkRuleThresholdType.BALANCE)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(() => this.thresholdPositiveInterval1Start, true, PApiType.Percent, this.aiThresholdPositiveInterval1Start.id, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiThresholdWarningEmailNotification : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkRuleWarningEmailNotification> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkRuleWarningEmailNotification>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'thresholdWarningEmailNotification',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_THRESHOLD_WARNING_EMAIL_NOTIFICATION',
			type: PApiType.Enum,
			rawDataIndex: 7,
		});
	aiKeepALedger : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, boolean> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, boolean>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'keepALedger',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_KEEP_A_LEDGER',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				{const conditionValue = this.thresholdType === SchedulingApiWorkRuleThresholdType.TARGET || this.thresholdType === SchedulingApiWorkRuleThresholdType.BALANCE; if(!conditionValue) return conditionValue === false ? {sourceString: 'Zurzeit kannst du nur für Prüfwerte vom Typ »Soll« oder »Guthaben« ein Konto führen. Wenn du auch für andere Typen ein Konto benötigst, melde dich bitte bei uns im Live-Chat. Dein Feedback ist uns sehr wichtig.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiLedgerNegativeInterval2Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'ledgerNegativeInterval2Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_NEGATIVE_INTERVAL_2_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.keepALedger));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.keepALedger)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(() => this.ledgerNegativeInterval1Start, true, PApiType.Percent, this.aiLedgerNegativeInterval1Start.id, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiLedgerNegativeInterval1Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'ledgerNegativeInterval1Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_NEGATIVE_INTERVAL_1_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.keepALedger));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.keepALedger)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.Percent, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiLedgerPositiveInterval1Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'ledgerPositiveInterval1Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_POSITIVE_INTERVAL_1_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.keepALedger));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.keepALedger)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(0, true, PApiType.Percent, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiLedgerPositiveInterval2Start : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, Percent>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'ledgerPositiveInterval2Start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_POSITIVE_INTERVAL_2_START',
			type: PApiType.Percent,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.keepALedger));
			},
			validations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
					() => {
		if(((this.keepALedger)))
		{
			return this.api.validators.required(PApiType.Percent, undefined);
		}
						return null;
					},
					() => {
return this.api.validators.min(() => this.ledgerPositiveInterval1Start, true, PApiType.Percent, this.aiLedgerPositiveInterval1Start.id, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiLedgerWarningEmailNotification : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkRuleWarningEmailNotification> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionThreshold, SchedulingApiWorkRuleWarningEmailNotification>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionThreshold,
			name: 'ledgerWarningEmailNotification',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_LEDGER_WARNING_EMAIL_NOTIFICATION',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {
				return ((this.keepALedger));
			},
			rawDataIndex: 13,
		});

	/**
	 * Defining the meaning of "threshold".
	 *
	 * @type {SchedulingApiWorkRuleThresholdType}
	 */
	get thresholdType() : SchedulingApiWorkRuleThresholdType {
		this.getterDebugValidations(this.aiThresholdType, false);
		return this.data[1];
	}

	set thresholdType(v : SchedulingApiWorkRuleThresholdType) {
        this.setterImpl(1, v, 'thresholdType', false, null, function(this : SchedulingApiWorkModelWorkRuleVersionThreshold) {if(v !== SchedulingApiWorkRuleThresholdType.TARGET && v !== SchedulingApiWorkRuleThresholdType.BALANCE)
						this.rawData[this.api.consts.WORK_MODEL_WORK_RULE_VERSION_THRESHOLD_KEEP_A_LEDGER] = false;});
	}

	/**
	 * The threshold being checked by this rule version.
	 *
	 * @type {ClientCurrency|number}
	 */
	get threshold() : ClientCurrency|number {
		this.getterDebugValidations(this.aiThreshold, false);
		return this.data[2];
	}

	set threshold(v : ClientCurrency|number) {
        this.setterImpl(2, v, 'threshold', false, null, null);
	}

	/**
	 * Defining a warning-level threshold, depending on "thresholdType".
	 *
	 * @type {Percent}
	 */
	get thresholdNegativeInterval2Start() : Percent | null {
		this.getterDebugValidations(this.aiThresholdNegativeInterval2Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiThresholdNegativeInterval2Start.defaultValue;
			
		}
		return this.data[3];
	}

	set thresholdNegativeInterval2Start(v : Percent | null) {
        this.setterImpl(3, v, 'thresholdNegativeInterval2Start', false, null, null);
	}

	/**
	 * Defining a warning-level threshold, depending on "thresholdType".
	 *
	 * @type {Percent}
	 */
	get thresholdNegativeInterval1Start() : Percent | null {
		this.getterDebugValidations(this.aiThresholdNegativeInterval1Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[4] === undefined) {
			this.data[4] = this.aiThresholdNegativeInterval1Start.defaultValue;
			
		}
		return this.data[4];
	}

	set thresholdNegativeInterval1Start(v : Percent | null) {
        this.setterImpl(4, v, 'thresholdNegativeInterval1Start', false, null, null);
	}

	/**
	 * Defining a warning-level threshold, depending on "thresholdType".
	 *
	 * @type {Percent}
	 */
	get thresholdPositiveInterval1Start() : Percent | null {
		this.getterDebugValidations(this.aiThresholdPositiveInterval1Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiThresholdPositiveInterval1Start.defaultValue;
			
		}
		return this.data[5];
	}

	set thresholdPositiveInterval1Start(v : Percent | null) {
        this.setterImpl(5, v, 'thresholdPositiveInterval1Start', false, null, null);
	}

	/**
	 * Defining a warning-level threshold, depending on "thresholdType".
	 *
	 * @type {Percent}
	 */
	get thresholdPositiveInterval2Start() : Percent | null {
		this.getterDebugValidations(this.aiThresholdPositiveInterval2Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiThresholdPositiveInterval2Start.defaultValue;
			
		}
		return this.data[6];
	}

	set thresholdPositiveInterval2Start(v : Percent | null) {
        this.setterImpl(6, v, 'thresholdPositiveInterval2Start', false, null, null);
	}

	/**
	 * Should our system send notification when the evaluation of the rule results in a changed warning-level for the threshold?
	 *
	 * @type {SchedulingApiWorkRuleWarningEmailNotification}
	 */
	get thresholdWarningEmailNotification() : SchedulingApiWorkRuleWarningEmailNotification | null {
		this.getterDebugValidations(this.aiThresholdWarningEmailNotification, false);
		return this.data[7];
	}

	set thresholdWarningEmailNotification(v : SchedulingApiWorkRuleWarningEmailNotification | null) {
        this.setterImpl(7, v, 'thresholdWarningEmailNotification', false, null, null);
	}

	/**
	 * Should a ledger be kept, "summing" up the deviations to "threshold" over all the check-scopes?
	 *
	 * @type {boolean}
	 */
	get keepALedger() : boolean {
		this.getterDebugValidations(this.aiKeepALedger, false);
		return this.data[8];
	}

	set keepALedger(v : boolean) {
        this.setterImpl(8, v, 'keepALedger', false, null, null);
	}

	/**
	 * If "keepALedger" is true, this will define the ledger's transition to negative-red warning level.
	 *
	 * @type {Percent}
	 */
	get ledgerNegativeInterval2Start() : Percent | null {
		this.getterDebugValidations(this.aiLedgerNegativeInterval2Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[9] === undefined) {
			this.data[9] = this.aiLedgerNegativeInterval2Start.defaultValue;
			
		}
		return this.data[9];
	}

	set ledgerNegativeInterval2Start(v : Percent | null) {
        this.setterImpl(9, v, 'ledgerNegativeInterval2Start', false, null, null);
	}

	/**
	 * If "keepALedger" is true, this will define the ledger's transition to negative-yellow warning level.
	 *
	 * @type {Percent}
	 */
	get ledgerNegativeInterval1Start() : Percent | null {
		this.getterDebugValidations(this.aiLedgerNegativeInterval1Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[10] === undefined) {
			this.data[10] = this.aiLedgerNegativeInterval1Start.defaultValue;
			
		}
		return this.data[10];
	}

	set ledgerNegativeInterval1Start(v : Percent | null) {
        this.setterImpl(10, v, 'ledgerNegativeInterval1Start', false, null, null);
	}

	/**
	 * If "keepALedger" is true, this will define the ledger's transition to positive-yellow warning level.
	 *
	 * @type {Percent}
	 */
	get ledgerPositiveInterval1Start() : Percent | null {
		this.getterDebugValidations(this.aiLedgerPositiveInterval1Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[11] === undefined) {
			this.data[11] = this.aiLedgerPositiveInterval1Start.defaultValue;
			
		}
		return this.data[11];
	}

	set ledgerPositiveInterval1Start(v : Percent | null) {
        this.setterImpl(11, v, 'ledgerPositiveInterval1Start', false, null, null);
	}

	/**
	 * If "keepALedger" is true, this will define the ledger's transition to positive-red warning level.
	 *
	 * @type {Percent}
	 */
	get ledgerPositiveInterval2Start() : Percent | null {
		this.getterDebugValidations(this.aiLedgerPositiveInterval2Start, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[12] === undefined) {
			this.data[12] = this.aiLedgerPositiveInterval2Start.defaultValue;
			
		}
		return this.data[12];
	}

	set ledgerPositiveInterval2Start(v : Percent | null) {
        this.setterImpl(12, v, 'ledgerPositiveInterval2Start', false, null, null);
	}

	/**
	 * Should our system send notification when the evaluation of the rule results in a changed warning-level for the ledger?
	 *
	 * @type {SchedulingApiWorkRuleWarningEmailNotification}
	 */
	get ledgerWarningEmailNotification() : SchedulingApiWorkRuleWarningEmailNotification | null {
		this.getterDebugValidations(this.aiLedgerWarningEmailNotification, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[13] === undefined) {
			this.data[13] = this.aiLedgerWarningEmailNotification.defaultValue;
			
		}
		return this.data[13];
	}

	set ledgerWarningEmailNotification(v : SchedulingApiWorkRuleWarningEmailNotification | null) {
        this.setterImpl(13, v, 'ledgerWarningEmailNotification', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_THRESHOLD should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '591';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '591', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiWorkRuleThresholdType {
	MINIMUM = 1,
	MAXIMUM = 2,
	TARGET = 3,
	BALANCE = 4,
}
export enum SchedulingApiWorkRuleWarningEmailNotification {
	RED = 1,
	YELLOW_AND_RED = 2,
}
		 export class SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes extends ApiListWrapper<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersion | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'previewCheckScopes');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope {
		return new SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersion | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '592';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope {
		const newItem = new SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope(this.api, this as unknown as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('previewCheckScopes');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes, SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes, SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes,
			name: 'previewCheckScopes',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPES',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 8,
		});
}

				 
export class SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope extends ApiObjectWrapper<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopes | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'workModelWorkRuleVersionPreviewCheckScope',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiStart : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, DateTime> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'start',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_START',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, DateTime> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, DateTime>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'end',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_END',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiSum : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, number> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, number>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'sum',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_SUM',
			type: PApiType.number,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiSumWarning : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, SchedulingApiWorkRuleWarning> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, SchedulingApiWorkRuleWarning>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'sumWarning',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_SUM_WARNING',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiLedgerChange : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, number> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, number>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'ledgerChange',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_LEDGER_CHANGE',
			type: PApiType.number,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope) {
				return ((this.parent!.parent!.workRuleThreshold.keepALedger));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiLedgerValue : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, number> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, number>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'ledgerValue',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_LEDGER_VALUE',
			type: PApiType.number,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope) {
				return ((this.parent!.parent!.workRuleThreshold.keepALedger));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});
	aiLedgerWarning : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, SchedulingApiWorkRuleWarning> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, SchedulingApiWorkRuleWarning>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope,
			name: 'ledgerWarning',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_LEDGER_WARNING',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope) {
				return ((this.parent!.parent!.workRuleThreshold.keepALedger));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 8,
		});

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get start() : DateTime {
		this.getterDebugValidations(this.aiStart, false);
		return this.data[1];
	}

	set startTestSetter(v : DateTime) {
        this.setterImpl(1, v, 'start', true, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get end() : DateTime {
		this.getterDebugValidations(this.aiEnd, false);
		return this.data[2];
	}

	set endTestSetter(v : DateTime) {
        this.setterImpl(2, v, 'end', true, null, null);
	}

	private summandsWrapper : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands = new SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands(this.api,
		this as unknown as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope, false, false);
	public aiSummands = this.summandsWrapper.aiThis;

	/**
	 * The values for each check-element.
	 */
	get summands() : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands {
		this.getterDebugValidations(this.aiSummands, false);
		return this.summandsWrapper;
	}

	set summandsTestSetter(v : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands) {
        this.setterImpl(3, v.rawData, 'summands', true, null, function(this : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope) {this.summandsWrapper = v;});
	}

	/**
	 * The sum of all "summands"
	 *
	 * @type {number}
	 */
	get sum() : number {
		this.getterDebugValidations(this.aiSum, false);
		return this.data[4];
	}

	set sumTestSetter(v : number) {
        this.setterImpl(4, v, 'sum', true, null, null);
	}

	/**
	 * 
	 *
	 * @type {SchedulingApiWorkRuleWarning}
	 */
	get sumWarning() : SchedulingApiWorkRuleWarning | null {
		this.getterDebugValidations(this.aiSumWarning, false);
		return this.data[5];
	}

	set sumWarningTestSetter(v : SchedulingApiWorkRuleWarning | null) {
        this.setterImpl(5, v, 'sumWarning', true, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get ledgerChange() : number | null {
		this.getterDebugValidations(this.aiLedgerChange, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[6] === undefined) {
			this.data[6] = this.aiLedgerChange.defaultValue;
			
		}
		return this.data[6];
	}

	set ledgerChangeTestSetter(v : number | null) {
        this.setterImpl(6, v, 'ledgerChange', true, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get ledgerValue() : number | null {
		this.getterDebugValidations(this.aiLedgerValue, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiLedgerValue.defaultValue;
			
		}
		return this.data[7];
	}

	set ledgerValueTestSetter(v : number | null) {
        this.setterImpl(7, v, 'ledgerValue', true, null, null);
	}

	/**
	 * 
	 *
	 * @type {SchedulingApiWorkRuleWarning}
	 */
	get ledgerWarning() : SchedulingApiWorkRuleWarning | null {
		this.getterDebugValidations(this.aiLedgerWarning, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiLedgerWarning.defaultValue;
			
		}
		return this.data[8];
	}

	set ledgerWarningTestSetter(v : SchedulingApiWorkRuleWarning | null) {
        this.setterImpl(8, v, 'ledgerWarning', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.summandsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.summandsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '669';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '669', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands extends ApiListWrapper<number>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'summands');
	}

	override wrapRawData(itemRawData : any) : number {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScope | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '672';
	}

	override createNewItem(_initCode : ((newItem : number) => void) | null = null) : number {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands, SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands, SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands,
			name: 'summands',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_SUMMANDS',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.number,
			rawDataIndex: 3,
		});
	aiWorkModelWorkRuleVersionPreviewCheckScopeSummand : ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands, number> = new ApiAttributeInfo<SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands, number>({
			apiObjWrapper: this as any as SchedulingApiWorkModelWorkRuleVersionPreviewCheckScopeSummands,
			name: 'workModelWorkRuleVersionPreviewCheckScopeSummand',
			nodeName: 'WORK_MODEL_WORK_RULE_VERSION_PREVIEW_CHECK_SCOPE_SUMMAND',
			type: PApiType.number,
			hasPermissionToSet: () => false,
			
		});
}

		export enum SchedulingApiWorkRuleWarning {
	YELLOW = 1,
	RED = 2,
}
		 export class SchedulingApiWarningsBase extends ApiListWrapper<SchedulingApiWarning>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'warnings');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiWarning {
		return new SchedulingApiWarning(this.api, this as unknown as SchedulingApiWarnings, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiWarnings(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '27';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiWarning) => void) | null = null, _backendId : Id | null = null) : SchedulingApiWarning {
		const newItem = new SchedulingApiWarning(this.api, this as unknown as SchedulingApiWarnings, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('warnings');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWarnings, SchedulingApiWarnings> = new ApiAttributeInfo<SchedulingApiWarnings, SchedulingApiWarnings>({
			apiObjWrapper: this as any as SchedulingApiWarnings,
			name: 'warnings',
			nodeName: 'WARNINGS',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWarnings, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 26,
		});
}

				 
export class SchedulingApiWarningBase extends ApiObjectWrapper<SchedulingApiWarning>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiWarnings | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiWarning> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiWarning as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiWarning, SchedulingApiWarning> = new ApiAttributeInfo<SchedulingApiWarning, SchedulingApiWarning>({
			apiObjWrapper: this as any as SchedulingApiWarning,
			name: 'warning',
			nodeName: 'WARNING',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiWarning, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiText : ApiAttributeInfo<SchedulingApiWarning, string> = new ApiAttributeInfo<SchedulingApiWarning, string>({
			apiObjWrapper: this as any as SchedulingApiWarning,
			name: 'text',
			nodeName: 'WARNING_TEXT',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiSeverity : ApiAttributeInfo<SchedulingApiWarning, SchedulingApiWarningSeverity> = new ApiAttributeInfo<SchedulingApiWarning, SchedulingApiWarningSeverity>({
			apiObjWrapper: this as any as SchedulingApiWarning,
			name: 'severity',
			nodeName: 'WARNING_SEVERITY',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiConcernsMemberId : ApiAttributeInfo<SchedulingApiWarning, Id> = new ApiAttributeInfo<SchedulingApiWarning, Id>({
			apiObjWrapper: this as any as SchedulingApiWarning,
			name: 'concernsMemberId',
			nodeName: 'WARNING_CONCERNS_MEMBER_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiForSwapOfferId : ApiAttributeInfo<SchedulingApiWarning, Id> = new ApiAttributeInfo<SchedulingApiWarning, Id>({
			apiObjWrapper: this as any as SchedulingApiWarning,
			name: 'forSwapOfferId',
			nodeName: 'WARNING_FOR_SWAP_OFFER_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiForSwapOfferNewItemId : ApiAttributeInfo<SchedulingApiWarning, Integer> = new ApiAttributeInfo<SchedulingApiWarning, Integer>({
			apiObjWrapper: this as any as SchedulingApiWarning,
			name: 'forSwapOfferNewItemId',
			nodeName: 'WARNING_FOR_SWAP_OFFER_NEW_ITEM_ID',
			type: PApiType.Integer,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});

	/**
	 * Text of this warning
	 *
	 * @type {string}
	 */
	get text() : string {
		this.getterDebugValidations(this.aiText, false);
		return this.data[1];
	}

	set textTestSetter(v : string) {
        this.setterImpl(1, v, 'text', true, null, null);
	}

	/**
	 * Severity level of this warning.
	 *
	 * @type {SchedulingApiWarningSeverity}
	 */
	get severity() : SchedulingApiWarningSeverity {
		this.getterDebugValidations(this.aiSeverity, false);
		return this.data[2];
	}

	set severityTestSetter(v : SchedulingApiWarningSeverity) {
        this.setterImpl(2, v, 'severity', true, null, null);
	}

	private concernsMemberIdWrapper : Id = null!;

	/**
	 * Which member is concerned by this warning?
	 *
	 * @type {Id}
	 */
	get concernsMemberId() : Id {
		this.getterDebugValidations(this.aiConcernsMemberId, false);
		return this.concernsMemberIdWrapper;
	}

	set concernsMemberIdTestSetter(v : Id) {
        this.setterImpl(3, v, 'concernsMemberId', true, null, function(this : SchedulingApiWarning) {this.concernsMemberIdWrapper = v;});
	}

	private forSwapOfferIdWrapper : Id | null = null!;

	/**
	 * To which swap offer does this warning belong?
	 *
	 * @type {Id}
	 */
	get forSwapOfferId() : Id | null {
		this.getterDebugValidations(this.aiForSwapOfferId, false);
		return this.forSwapOfferIdWrapper;
	}

	set forSwapOfferIdTestSetter(v : Id | null) {
        this.setterImpl(4, v, 'forSwapOfferId', true, null, function(this : SchedulingApiWarning) {this.forSwapOfferIdWrapper = v;});
	}

	/**
	 * To which swap offer newItemId does this warning belong?
	 *
	 * @type {Integer}
	 */
	get forSwapOfferNewItemId() : Integer | null {
		this.getterDebugValidations(this.aiForSwapOfferNewItemId, false);
		return this.data[5];
	}

	set forSwapOfferNewItemIdTestSetter(v : Integer | null) {
        this.setterImpl(5, v, 'forSwapOfferNewItemId', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[3] = Meta.getReplacedId(this.data[3], _idReplacements);
		this.concernsMemberIdWrapper = this.data[3] === null ? null! : Id.create(this.data[3]);
		this.data[4] = Meta.getReplacedId(this.data[4], _idReplacements);
		this.forSwapOfferIdWrapper = this.data[4] === null ? null! : Id.create(this.data[4]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WARNING should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[3] : null, this.concernsMemberIdWrapper))
			this.concernsMemberIdWrapper = data && data[3] ? Id.create(data[3]) : null!;
		if(!Meta.isSameId(data ? data[4] : null, this.forSwapOfferIdWrapper))
			this.forSwapOfferIdWrapper = data && data[4] ? Id.create(data[4]) : null!;
	}

	protected get dni() : string {
		return '723';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '723', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiWarningSeverity {
	INFO = 1,
	WARNING = 2,
	FATAL = 3,
}
		 
export class SchedulingApiEvaluation extends ApiObjectWrapper<SchedulingApiEvaluation>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiEvaluation> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiEvaluation as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiEvaluation, SchedulingApiEvaluation> = new ApiAttributeInfo<SchedulingApiEvaluation, SchedulingApiEvaluation>({
			apiObjWrapper: this as any as SchedulingApiEvaluation,
			name: 'evaluation',
			nodeName: 'EVALUATION',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : SchedulingApiEvaluation) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			isAvailableByBusinessLogic: function(this : SchedulingApiEvaluation) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'calendar'));
			},
			hasPermissionToSet: function(this : SchedulingApiEvaluation) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiEvaluation, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 27,
		});
	aiGenerate : ApiAttributeInfo<SchedulingApiEvaluation, boolean> = new ApiAttributeInfo<SchedulingApiEvaluation, boolean>({
			apiObjWrapper: this as any as SchedulingApiEvaluation,
			name: 'generate',
			nodeName: 'EVALUATION_GENERATE',
			type: PApiType.boolean,
			defaultValue: function(this : SchedulingApiEvaluation, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});

	/**
	 * Should a report be generated? Currently this report is being send by email to the requester.
	 *
	 * @type {boolean}
	 */
	get generate() : boolean {
		this.getterDebugValidations(this.aiGenerate, false);
		return this.data[1];
	}

	set generate(v : boolean) {
        this.setterImpl(1, v, 'generate', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '28';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '28', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiAdyenAccountBase extends ApiObjectWrapper<SchedulingApiAdyenAccount>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiAdyenAccount> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiAdyenAccount as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAdyenAccount> = new ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAdyenAccount>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'adyenAccount',
			nodeName: 'ADYEN_ACCOUNT',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : SchedulingApiAdyenAccount) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiAdyenAccount, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 30,
		});
	aiBalance : ApiAttributeInfo<SchedulingApiAdyenAccount, ClientCurrency> = new ApiAttributeInfo<SchedulingApiAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'balance',
			nodeName: 'ADYEN_ACCOUNT_BALANCE',
			type: PApiType.ClientCurrency,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			isAvailableByBusinessLogic: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'transactions'));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiAccountHolderState : ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderState> = new ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderState>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'accountHolderState',
			nodeName: 'ADYEN_ACCOUNT_HOLDER_STATE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiAccountHolderPayoutState : ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderPayoutState> = new ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderPayoutState>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'accountHolderPayoutState',
			nodeName: 'ADYEN_ACCOUNT_HOLDER_PAYOUT_STATE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiAccountHolderProcessingState : ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderProcessingState> = new ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderProcessingState>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'accountHolderProcessingState',
			nodeName: 'ADYEN_ACCOUNT_HOLDER_PROCESSING_STATE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiOnboardingUrl : ApiAttributeInfo<SchedulingApiAdyenAccount, Url> = new ApiAttributeInfo<SchedulingApiAdyenAccount, Url>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'onboardingUrl',
			nodeName: 'ADYEN_ACCOUNT_ONBOARDING_URL',
			type: PApiType.Url,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.pPermissionsService.canReadAndWriteBookingSystemSettings));
			},
			hasPermissionToSet: function(this : SchedulingApiAdyenAccount) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? {sourceString: 'Du hast leider keine Berechtigung hierfür. Wende dich bitte an deine Admins.', params: false} : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiAdyenAccount) {
				{const conditionValue = !this.api.isBackendOperationRunning; if(!conditionValue) return conditionValue === false ? false : undefined;}
				{const conditionValue = !this.api.me.isTestAccount; if(!conditionValue) return conditionValue === false ? {sourceString: 'Die Online-Zahlung steht im Test-Account nicht zur Verfügung. <a href="./client/testaccount">Hole dir jetzt einen Echt-Account</a> 🎉', params: false} : undefined;}
				{const conditionValue = !this.isClosedOrSuspended; if(!conditionValue) return conditionValue === false ? {sourceString: 'Dein Account ist zurzeit leider gesperrt! Falls du die Online-Zahlung wieder nutzen möchtest, melde dich bitte bei uns.', params: false} : undefined;}
				{const conditionValue = !!this.api.pPricesService.currencyIsSupportedForOnlinePayment; if(!conditionValue) return conditionValue === false ? {sourceString: 'Online-Zahlung steht für deine Landeswährung noch nicht zur Verfügung. Falls du die Online-Zahlung nutzen möchtest, melde dich gerne bei uns im Chat oder per <a href="mailto:service@dr-plano.com?subject=Unterstützung der Online-Zahlung für mein Land">Email</a>.', params: false} : undefined;}
				{const conditionValue = this.allCheckboxesAccepted; if(!conditionValue) return conditionValue === false ? {sourceString: 'Bevor du weitermachen kannst, musst du die Nutzungsbedingungen akzeptieren und die Checkboxen ankreuzen.', params: false} : undefined;}
				return true;
			},
			rawDataIndex: 5,
		});
	aiDeadlineDate : ApiAttributeInfo<SchedulingApiAdyenAccount, DateTime> = new ApiAttributeInfo<SchedulingApiAdyenAccount, DateTime>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'deadlineDate',
			nodeName: 'ADYEN_ACCOUNT_DEADLINE_DATE',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiUpcomingDeadlineState : ApiAttributeInfo<SchedulingApiAdyenAccount, string> = new ApiAttributeInfo<SchedulingApiAdyenAccount, string>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'upcomingDeadlineState',
			nodeName: 'ADYEN_ACCOUNT_UPCOMING_DEADLINE_STATE',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});
	aiTestingDesiredDeposit : ApiAttributeInfo<SchedulingApiAdyenAccount, ClientCurrency> = new ApiAttributeInfo<SchedulingApiAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'testingDesiredDeposit',
			nodeName: 'ADYEN_ACCOUNT_TESTING_DESIRED_DEPOSIT',
			type: PApiType.ClientCurrency,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 8,
		});
	aiTestingAccountHolderCode : ApiAttributeInfo<SchedulingApiAdyenAccount, string> = new ApiAttributeInfo<SchedulingApiAdyenAccount, string>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'testingAccountHolderCode',
			nodeName: 'ADYEN_ACCOUNT_TESTING_ACCOUNT_HOLDER_CODE',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});
	aiTestingAccountHolderPaymentAccount : ApiAttributeInfo<SchedulingApiAdyenAccount, string> = new ApiAttributeInfo<SchedulingApiAdyenAccount, string>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'testingAccountHolderPaymentAccount',
			nodeName: 'ADYEN_ACCOUNT_TESTING_ACCOUNT_HOLDER_PAYMENT_ACCOUNT',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 10,
		});
	aiTestingLiablePaymentAccount : ApiAttributeInfo<SchedulingApiAdyenAccount, string> = new ApiAttributeInfo<SchedulingApiAdyenAccount, string>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'testingLiablePaymentAccount',
			nodeName: 'ADYEN_ACCOUNT_TESTING_LIABLE_PAYMENT_ACCOUNT',
			type: PApiType.string,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.isAutomaticTesting));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 11,
		});
	aiOnboardingActionRequiredOrPending : ApiAttributeInfo<SchedulingApiAdyenAccount, boolean> = new ApiAttributeInfo<SchedulingApiAdyenAccount, boolean>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'onboardingActionRequiredOrPending',
			nodeName: 'ADYEN_ACCOUNT_ONBOARDING_ACTION_REQUIRED_OR_PENDING',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiAdyenAccount, _nodeId : string) {return false;},
			rawDataIndex: 12,
		});
	aiPayoutSchedule : ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderPayoutSchedule> = new ApiAttributeInfo<SchedulingApiAdyenAccount, SchedulingApiAccountHolderPayoutSchedule>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'payoutSchedule',
			nodeName: 'ADYEN_ACCOUNT_PAYOUT_SCHEDULE',
			type: PApiType.Enum,
			isAvailableByBusinessLogic: function(this : SchedulingApiAdyenAccount) {
				return ((this.accountHolderState!==SchedulingApiAccountHolderState.NOT_INITIALIZED));
			},
			hasPermissionToSet: function(this : SchedulingApiAdyenAccount) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiAdyenAccount) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiAdyenAccount) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiNextPayoutDate : ApiAttributeInfo<SchedulingApiAdyenAccount, DateTime> = new ApiAttributeInfo<SchedulingApiAdyenAccount, DateTime>({
			apiObjWrapper: this as any as SchedulingApiAdyenAccount,
			name: 'nextPayoutDate',
			nodeName: 'ADYEN_ACCOUNT_NEXT_PAYOUT_DATE',
			type: PApiType.DateTime,
			hasPermissionToGet: function(this : SchedulingApiAdyenAccount) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			isAvailableByBusinessLogic: function(this : SchedulingApiAdyenAccount) {
				return ((this.accountHolderPayoutState===SchedulingApiAccountHolderPayoutState.PAYOUT_ALLOWED));
			},
			hasPermissionToSet: () => false,
			rawDataIndex: 14,
		});

	/**
	 * Current online payment account balance.
	 *
	 * @type {ClientCurrency}
	 */
	get balance() : ClientCurrency {
		this.getterDebugValidations(this.aiBalance, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[1] === undefined) {
			this.data[1] = this.aiBalance.defaultValue;
			
		}
		return this.data[1];
	}

	set balanceTestSetter(v : ClientCurrency) {
        this.setterImpl(1, v, 'balance', true, null, null);
	}

	/**
	 * AccountHolder State for Adyen
	 *
	 * @type {SchedulingApiAccountHolderState}
	 */
	get accountHolderState() : SchedulingApiAccountHolderState {
		this.getterDebugValidations(this.aiAccountHolderState, false);
		return this.data[2];
	}

	set accountHolderStateTestSetter(v : SchedulingApiAccountHolderState) {
        this.setterImpl(2, v, 'accountHolderState', true, null, null);
	}

	/**
	 * AccountHolder-Payout State for Adyen
	 *
	 * @type {SchedulingApiAccountHolderPayoutState}
	 */
	get accountHolderPayoutState() : SchedulingApiAccountHolderPayoutState {
		this.getterDebugValidations(this.aiAccountHolderPayoutState, false);
		return this.data[3];
	}

	set accountHolderPayoutStateTestSetter(v : SchedulingApiAccountHolderPayoutState) {
        this.setterImpl(3, v, 'accountHolderPayoutState', true, null, null);
	}

	/**
	 * AccountHolder-Processing State for Adyen
	 *
	 * @type {SchedulingApiAccountHolderProcessingState}
	 */
	get accountHolderProcessingState() : SchedulingApiAccountHolderProcessingState {
		this.getterDebugValidations(this.aiAccountHolderProcessingState, false);
		return this.data[4];
	}

	set accountHolderProcessingStateTestSetter(v : SchedulingApiAccountHolderProcessingState) {
        this.setterImpl(4, v, 'accountHolderProcessingState', true, null, null);
	}

	/**
	 * Onboarding URL for Adyen, only used as a response. Set "generate" to trigger url generation. Returns "not created" if the AdyenAccount was not yet created.
	 *
	 * @type {Url}
	 */
	get onboardingUrl() : Url | null {
		this.getterDebugValidations(this.aiOnboardingUrl, false);
		return this.data[5];
	}

	set onboardingUrl(v : Url | null) {
        this.setterImpl(5, v, 'onboardingUrl', false, null, null);
	}

	/**
	 * Deadline Date. The account has time until this date to take actions to prevent his account from getting banned etc.
	 *
	 * @type {DateTime}
	 */
	get deadlineDate() : DateTime | null {
		this.getterDebugValidations(this.aiDeadlineDate, false);
		return this.data[6];
	}

	set deadlineDateTestSetter(v : DateTime | null) {
        this.setterImpl(6, v, 'deadlineDate', true, null, null);
	}

	/**
	 * Next state, thats coming, when the deadline passed without action.
	 *
	 * @type {string}
	 */
	get upcomingDeadlineState() : string | null {
		this.getterDebugValidations(this.aiUpcomingDeadlineState, false);
		return this.data[7];
	}

	set upcomingDeadlineStateTestSetter(v : string | null) {
        this.setterImpl(7, v, 'upcomingDeadlineState', true, null, null);
	}

	/**
	 * What is the current desired deposit? Only send in test environment.
	 *
	 * @type {ClientCurrency}
	 */
	get testingDesiredDeposit() : ClientCurrency {
		this.getterDebugValidations(this.aiTestingDesiredDeposit, false);
		return this.data[8];
	}

	set testingDesiredDepositTestSetter(v : ClientCurrency) {
        this.setterImpl(8, v, 'testingDesiredDeposit', true, null, null);
	}

	/**
	 * Adyen sub-merchants account-holder-code.
	 *
	 * @type {string}
	 */
	get testingAccountHolderCode() : string | null {
		this.getterDebugValidations(this.aiTestingAccountHolderCode, false);
		return this.data[9];
	}

	set testingAccountHolderCodeTestSetter(v : string | null) {
        this.setterImpl(9, v, 'testingAccountHolderCode', true, null, null);
	}

	/**
	 * Adyen sub-merchants account-holder payment account.
	 *
	 * @type {string}
	 */
	get testingAccountHolderPaymentAccount() : string | null {
		this.getterDebugValidations(this.aiTestingAccountHolderPaymentAccount, false);
		return this.data[10];
	}

	set testingAccountHolderPaymentAccountTestSetter(v : string | null) {
        this.setterImpl(10, v, 'testingAccountHolderPaymentAccount', true, null, null);
	}

	/**
	 * our Adyen Liable-Account's payment account.
	 *
	 * @type {string}
	 */
	get testingLiablePaymentAccount() : string {
		this.getterDebugValidations(this.aiTestingLiablePaymentAccount, false);
		return this.data[11];
	}

	set testingLiablePaymentAccountTestSetter(v : string) {
        this.setterImpl(11, v, 'testingLiablePaymentAccount', true, null, null);
	}

	/**
	 * Indicates whether there is an Action required regarding Onboarding or it is in Pending process
	 *
	 * @type {boolean}
	 */
	get onboardingActionRequiredOrPending() : boolean {
		this.getterDebugValidations(this.aiOnboardingActionRequiredOrPending, false);
		return this.data[12];
	}

	set onboardingActionRequiredOrPendingTestSetter(v : boolean) {
        this.setterImpl(12, v, 'onboardingActionRequiredOrPending', true, null, null);
	}

	/**
	 * AccountHolder-Payout-Schedule for this Adyen Account Holder
	 *
	 * @type {SchedulingApiAccountHolderPayoutSchedule}
	 */
	get payoutSchedule() : SchedulingApiAccountHolderPayoutSchedule {
		this.getterDebugValidations(this.aiPayoutSchedule, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[13] === undefined) {
			this.data[13] = this.aiPayoutSchedule.defaultValue;
			
		}
		return this.data[13];
	}

	set payoutSchedule(v : SchedulingApiAccountHolderPayoutSchedule) {
        this.setterImpl(13, v, 'payoutSchedule', false, null, null);
	}

	/**
	 * The next PayoutDate for this Adyen-AccountHolder. If this date would be in the past, return start of current day. May be null when payout is disabled
	 *
	 * @type {DateTime}
	 */
	get nextPayoutDate() : DateTime | null {
		this.getterDebugValidations(this.aiNextPayoutDate, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[14] === undefined) {
			this.data[14] = this.aiNextPayoutDate.defaultValue;
			
		}
		return this.data[14];
	}

	set nextPayoutDateTestSetter(v : DateTime | null) {
        this.setterImpl(14, v, 'nextPayoutDate', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ADYEN_ACCOUNT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '31';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '31', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiAccountHolderState {
	NOT_INITIALIZED = 1,
	INITIAL_ONBOARDING = 2,
	INACTIVE = 3,
	ACTIVE = 4,
	SUSPENDED = 5,
	CLOSED = 6,
}
export enum SchedulingApiAccountHolderPayoutState {
	PAYOUT_ALLOWED = 1,
	PAYOUT_DISABLED = 2,
}
export enum SchedulingApiAccountHolderProcessingState {
	PROCESSING_ALLOWED = 1,
	PROCESSING_DISABLED = 2,
}
export enum SchedulingApiAccountHolderPayoutSchedule {
	WEEKLY = 1,
	BIWEEKLY = 2,
	MONTHLY = 3,
}
		 export class SchedulingApiReCaptchaWhiteListedHostNames extends ApiListWrapper<Url>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'reCaptchaWhiteListedHostNames');
	}

	override wrapRawData(itemRawData : any) : Url {
		return itemRawData;
	}

	protected containsPrimitives() : boolean {
		return true;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiReCaptchaWhiteListedHostNames(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '34';
	}

	override createNewItem(_initCode : ((newItem : Url) => void) | null = null) : Url {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<SchedulingApiReCaptchaWhiteListedHostNames, SchedulingApiReCaptchaWhiteListedHostNames> = new ApiAttributeInfo<SchedulingApiReCaptchaWhiteListedHostNames, SchedulingApiReCaptchaWhiteListedHostNames>({
			apiObjWrapper: this as any as SchedulingApiReCaptchaWhiteListedHostNames,
			name: 'reCaptchaWhiteListedHostNames',
			nodeName: 'RE_CAPTCHA_WHITE_LISTED_HOST_NAMES',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiReCaptchaWhiteListedHostNames) {
				return ((this.api.getCurrentDataSearchParamValue('data') === 'bookingSystemSettings'));
			},
			hasPermissionToSet: function(this : SchedulingApiReCaptchaWhiteListedHostNames) {
				{const conditionValue = this.api.pPermissionsService.canReadAndWriteBookingSystemSettings; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : SchedulingApiReCaptchaWhiteListedHostNames, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Url,
			rawDataIndex: 33,
		});
	aiReCaptchaWhiteListedHostName : ApiAttributeInfo<SchedulingApiReCaptchaWhiteListedHostNames, Url> = new ApiAttributeInfo<SchedulingApiReCaptchaWhiteListedHostNames, Url>({
			apiObjWrapper: this as any as SchedulingApiReCaptchaWhiteListedHostNames,
			name: 'reCaptchaWhiteListedHostName',
			nodeName: 'RE_CAPTCHA_WHITE_LISTED_HOST_NAME',
			type: PApiType.Url,
			
		});
}

				 export class SchedulingApiMailsSentToBookingPerson extends ApiListWrapper<SchedulingApiMailSentToBookingPerson>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'mailsSentToBookingPerson');
	}

	override wrapRawData(itemRawData : any) : SchedulingApiMailSentToBookingPerson {
		return new SchedulingApiMailSentToBookingPerson(this.api, this as unknown as SchedulingApiMailsSentToBookingPerson, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : SchedulingApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new SchedulingApiMailsSentToBookingPerson(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '35';
	}

	override createNewItem(_initCode : ((newItem : SchedulingApiMailSentToBookingPerson) => void) | null = null, _backendId : Id | null = null) : SchedulingApiMailSentToBookingPerson {
		const newItem = new SchedulingApiMailSentToBookingPerson(this.api, this as unknown as SchedulingApiMailsSentToBookingPerson, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('mailsSentToBookingPerson');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMailsSentToBookingPerson, SchedulingApiMailsSentToBookingPerson> = new ApiAttributeInfo<SchedulingApiMailsSentToBookingPerson, SchedulingApiMailsSentToBookingPerson>({
			apiObjWrapper: this as any as SchedulingApiMailsSentToBookingPerson,
			name: 'mailsSentToBookingPerson',
			nodeName: 'MAILS_SENT_TO_BOOKING_PERSON',
			type: PApiType.ApiList,
			isAvailableByBusinessLogic: function(this : SchedulingApiMailsSentToBookingPerson) {
				return ((this.api.currentlyDetailedLoaded instanceof SchedulingApiBooking || this.api.currentlyDetailedLoaded instanceof SchedulingApiGiftCard));
			},
			defaultValue: function(this : SchedulingApiMailsSentToBookingPerson, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 34,
		});
}

				 
export class SchedulingApiMailSentToBookingPersonBase extends ApiObjectWrapper<SchedulingApiMailSentToBookingPerson>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiMailsSentToBookingPerson | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiMailSentToBookingPerson> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiMailSentToBookingPerson as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, SchedulingApiMailSentToBookingPerson> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, SchedulingApiMailSentToBookingPerson>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'mailSentToBookingPerson',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiMailSentToBookingPerson, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiDateTime : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, DateTime> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, DateTime>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'dateTime',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON_DATE_TIME',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiEventType : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, SchedulingApiCustomBookableMailEventType> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, SchedulingApiCustomBookableMailEventType>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'eventType',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON_EVENT_TYPE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiRecipientEmail : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, Email> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, Email>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'recipientEmail',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON_RECIPIENT_EMAIL',
			type: PApiType.Email,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiSubject : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, string> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, string>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'subject',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON_SUBJECT',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiText : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, string> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, string>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'text',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON_TEXT',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiResendRequesterId : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, Id> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, Id>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'resendRequesterId',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON_RESEND_REQUESTER_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiResend : ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, boolean> = new ApiAttributeInfo<SchedulingApiMailSentToBookingPerson, boolean>({
			apiObjWrapper: this as any as SchedulingApiMailSentToBookingPerson,
			name: 'resend',
			nodeName: 'MAIL_SENT_TO_BOOKING_PERSON_RESEND',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiMailSentToBookingPerson) {
				{const conditionValue = this.api.currentlyDetailedLoaded!.aiThis.canSet; if(!conditionValue) return conditionValue === false ? {sourceString: 'Hierfür fehlt dir die Berechtigung.', params: false} : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiMailSentToBookingPerson) {
				{const conditionValue = !(this.api.currentlyDetailedLoaded as SchedulingApiBooking | SchedulingApiGiftCard).isAnonymized; if(!conditionValue) return conditionValue === false ? {sourceString: 'Diese Email wurde anonymisiert.', params: false} : undefined;}
				{const conditionValue = (this.api.currentlyDetailedLoaded as SchedulingApiBooking | SchedulingApiGiftCard).email !== null; if(!conditionValue) return conditionValue === false ? {sourceString: 'Aktuell fehlt die Email-Adresse der buchenden Person. Das erneute Versenden funktioniert erst, nachdem du eine Email-Adresse eingetragen hast.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiMailSentToBookingPerson) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiMailSentToBookingPerson) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiMailSentToBookingPerson, _nodeId : string) {return false;},
			rawDataIndex: 7,
		});

	/**
	 * When was this mail sent?
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[1];
	}

	set dateTimeTestSetter(v : DateTime) {
        this.setterImpl(1, v, 'dateTime', true, null, null);
	}

	/**
	 * The event which triggered this mail.
	 *
	 * @type {SchedulingApiCustomBookableMailEventType}
	 */
	get eventType() : SchedulingApiCustomBookableMailEventType {
		this.getterDebugValidations(this.aiEventType, false);
		return this.data[2];
	}

	set eventTypeTestSetter(v : SchedulingApiCustomBookableMailEventType) {
        this.setterImpl(2, v, 'eventType', true, null, null);
	}

	/**
	 * The recipient mail address.
	 *
	 * @type {Email}
	 */
	get recipientEmail() : Email {
		this.getterDebugValidations(this.aiRecipientEmail, false);
		return this.data[3];
	}

	set recipientEmailTestSetter(v : Email) {
        this.setterImpl(3, v, 'recipientEmail', true, null, null);
	}

	/**
	 * Subject of the mail.
	 *
	 * @type {string}
	 */
	get subject() : string {
		this.getterDebugValidations(this.aiSubject, false);
		return this.data[4];
	}

	set subjectTestSetter(v : string) {
        this.setterImpl(4, v, 'subject', true, null, null);
	}

	/**
	 * Text of the mail. Note that this will contain html code as we send html mails.
	 *
	 * @type {string}
	 */
	get text() : string {
		this.getterDebugValidations(this.aiText, false);
		return this.data[5];
	}

	set textTestSetter(v : string) {
        this.setterImpl(5, v, 'text', true, null, null);
	}

	private resendRequesterIdWrapper : Id | null = null!;

	/**
	 * If this mail is a manually triggered resend, then this returns the member-id who requester the resend.
	 *
	 * @type {Id}
	 */
	get resendRequesterId() : Id | null {
		this.getterDebugValidations(this.aiResendRequesterId, false);
		return this.resendRequesterIdWrapper;
	}

	set resendRequesterIdTestSetter(v : Id | null) {
        this.setterImpl(6, v, 'resendRequesterId', true, null, function(this : SchedulingApiMailSentToBookingPerson) {this.resendRequesterIdWrapper = v;});
	}

	/**
	 * Set this to "true" to trigger a resend of the mail. Note that the mail will be send to booking-persons current mail address.
	 *
	 * @type {boolean}
	 */
	get resend() : boolean {
		this.getterDebugValidations(this.aiResend, false);
		return this.data[7];
	}

	set resend(v : boolean) {
        this.setterImpl(7, v, 'resend', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[6] = Meta.getReplacedId(this.data[6], _idReplacements);
		this.resendRequesterIdWrapper = this.data[6] === null ? null! : Id.create(this.data[6]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('MAIL_SENT_TO_BOOKING_PERSON should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[6] : null, this.resendRequesterIdWrapper))
			this.resendRequesterIdWrapper = data && data[6] ? Id.create(data[6]) : null!;
	}

	protected get dni() : string {
		return '754';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '754', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiMessages extends ApiObjectWrapper<SchedulingApiMessages>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiMessages> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiMessages as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMessages, SchedulingApiMessages> = new ApiAttributeInfo<SchedulingApiMessages, SchedulingApiMessages>({
			apiObjWrapper: this as any as SchedulingApiMessages,
			name: 'messages',
			nodeName: 'MESSAGES',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessages, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 35,
		});
	aiRemovedDuplicateReCaptchaWhiteListedHostName : ApiAttributeInfo<SchedulingApiMessages, boolean> = new ApiAttributeInfo<SchedulingApiMessages, boolean>({
			apiObjWrapper: this as any as SchedulingApiMessages,
			name: 'removedDuplicateReCaptchaWhiteListedHostName',
			nodeName: 'MESSAGES_REMOVED_DUPLICATE_RE_CAPTCHA_WHITE_LISTED_HOST_NAME',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessages, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiSentRequestorReportAboutRemovedAssignments : ApiAttributeInfo<SchedulingApiMessages, boolean> = new ApiAttributeInfo<SchedulingApiMessages, boolean>({
			apiObjWrapper: this as any as SchedulingApiMessages,
			name: 'sentRequestorReportAboutRemovedAssignments',
			nodeName: 'MESSAGES_SENT_REQUESTER_REPORT_ABOUT_REMOVED_ASSIGNMENTS',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessages, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	aiOnlineRefundInfo : ApiAttributeInfo<SchedulingApiMessages, SchedulingApiOnlineRefundInfo> = new ApiAttributeInfo<SchedulingApiMessages, SchedulingApiOnlineRefundInfo>({
			apiObjWrapper: this as any as SchedulingApiMessages,
			name: 'onlineRefundInfo',
			nodeName: 'MESSAGES_ONLINE_REFUND_INFO',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});

	/**
	 * When adding a white-listed host-name, has backend automatically removed it again because it was a duplicate value?
	 *
	 * @type {boolean}
	 */
	get removedDuplicateReCaptchaWhiteListedHostName() : boolean {
		this.getterDebugValidations(this.aiRemovedDuplicateReCaptchaWhiteListedHostName, false);
		return this.data[1];
	}

	set removedDuplicateReCaptchaWhiteListedHostNameTestSetter(v : boolean) {
        this.setterImpl(1, v, 'removedDuplicateReCaptchaWhiteListedHostName', true, null, null);
	}

	/**
	 * Has a report about removed assignments been sent to requester?
	 *
	 * @type {boolean}
	 */
	get sentRequestorReportAboutRemovedAssignments() : boolean {
		this.getterDebugValidations(this.aiSentRequestorReportAboutRemovedAssignments, false);
		return this.data[2];
	}

	set sentRequestorReportAboutRemovedAssignmentsTestSetter(v : boolean) {
        this.setterImpl(2, v, 'sentRequestorReportAboutRemovedAssignments', true, null, null);
	}

	/**
	 * Tells if an online refund could be executed or not.
	 *
	 * @type {SchedulingApiOnlineRefundInfo}
	 */
	get onlineRefundInfo() : SchedulingApiOnlineRefundInfo | null {
		this.getterDebugValidations(this.aiOnlineRefundInfo, false);
		return this.data[3];
	}

	set onlineRefundInfoTestSetter(v : SchedulingApiOnlineRefundInfo | null) {
        this.setterImpl(3, v, 'onlineRefundInfo', true, null, null);
	}

	private customBookableMailsInfoWrapper : SchedulingApiMessagesCustomBookableMailsInfo = new SchedulingApiMessagesCustomBookableMailsInfo(this.api,
		this as unknown as SchedulingApiMessages);
	public aiCustomBookableMailsInfo = this.customBookableMailsInfoWrapper.aiThis;

	/**
	 * This object contains information about send custom-bookable-mails.
	 */
	get customBookableMailsInfo() : SchedulingApiMessagesCustomBookableMailsInfo {
		this.getterDebugValidations(this.aiCustomBookableMailsInfo, false);
		return this.customBookableMailsInfoWrapper;
	}

	set customBookableMailsInfoTestSetter(v : SchedulingApiMessagesCustomBookableMailsInfo) {
        this.setterImpl(4, v.rawData, 'customBookableMailsInfo', true, null, function(this : SchedulingApiMessages) {this.customBookableMailsInfoWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.customBookableMailsInfoWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.customBookableMailsInfoWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
	}

	protected get dni() : string {
		return '36';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '36', { success: success, error: error, searchParams: searchParams});
	}
}

export enum SchedulingApiOnlineRefundInfo {
	ONLINE_REFUND_FAILED = 1,
	ONLINE_REFUND_PARTIALLY = 2,
	ONLINE_REFUND_SUCCESSFUL = 3,
}
		 
export class SchedulingApiMessagesCustomBookableMailsInfo extends ApiObjectWrapper<SchedulingApiMessagesCustomBookableMailsInfo>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiMessages | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiMessagesCustomBookableMailsInfo> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiMessagesCustomBookableMailsInfo as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, SchedulingApiMessagesCustomBookableMailsInfo> = new ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, SchedulingApiMessagesCustomBookableMailsInfo>({
			apiObjWrapper: this as any as SchedulingApiMessagesCustomBookableMailsInfo,
			name: 'customBookableMailsInfo',
			nodeName: 'MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessagesCustomBookableMailsInfo, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 4,
		});
	aiAffectedShiftModelId : ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, Id> = new ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, Id>({
			apiObjWrapper: this as any as SchedulingApiMessagesCustomBookableMailsInfo,
			name: 'affectedShiftModelId',
			nodeName: 'MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_AFFECTED_SHIFT_MODEL_ID',
			type: PApiType.Id,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiEventTriggered : ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean> = new ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean>({
			apiObjWrapper: this as any as SchedulingApiMessagesCustomBookableMailsInfo,
			name: 'eventTriggered',
			nodeName: 'MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EVENT_TRIGGERED',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessagesCustomBookableMailsInfo, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	aiEmailSentToBookingPerson : ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean> = new ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean>({
			apiObjWrapper: this as any as SchedulingApiMessagesCustomBookableMailsInfo,
			name: 'emailSentToBookingPerson',
			nodeName: 'MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EMAIL_SENT_TO_BOOKING_PERSON',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessagesCustomBookableMailsInfo, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	aiEmailSentToParticipants : ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean> = new ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean>({
			apiObjWrapper: this as any as SchedulingApiMessagesCustomBookableMailsInfo,
			name: 'emailSentToParticipants',
			nodeName: 'MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EMAIL_SENT_TO_PARTICIPANTS',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessagesCustomBookableMailsInfo, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiEmailNotSentBecauseOfMissingEmail : ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean> = new ApiAttributeInfo<SchedulingApiMessagesCustomBookableMailsInfo, boolean>({
			apiObjWrapper: this as any as SchedulingApiMessagesCustomBookableMailsInfo,
			name: 'emailNotSentBecauseOfMissingEmail',
			nodeName: 'MESSAGES_CUSTOM_BOOKABLE_MAILS_INFO_EMAIL_NOT_SENT_BECAUSE_OF_MISSING_EMAIL',
			type: PApiType.boolean,
			hasPermissionToSet: () => false,
			defaultValue: function(this : SchedulingApiMessagesCustomBookableMailsInfo, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});

	private affectedShiftModelIdWrapper : Id | null = null!;

	/**
	 * The shift-model for which custom-bookable-mail events were triggered. If events for multiple shift-models have been triggered this returns the last shift-model.
	 *
	 * @type {Id}
	 */
	get affectedShiftModelId() : Id | null {
		this.getterDebugValidations(this.aiAffectedShiftModelId, false);
		return this.affectedShiftModelIdWrapper;
	}

	set affectedShiftModelIdTestSetter(v : Id | null) {
        this.setterImpl(1, v, 'affectedShiftModelId', true, null, function(this : SchedulingApiMessagesCustomBookableMailsInfo) {this.affectedShiftModelIdWrapper = v;});
	}

	/**
	 * Was a custom-bookable-mail event triggered?
	 *
	 * @type {boolean}
	 */
	get eventTriggered() : boolean {
		this.getterDebugValidations(this.aiEventTriggered, false);
		return this.data[2];
	}

	set eventTriggeredTestSetter(v : boolean) {
        this.setterImpl(2, v, 'eventTriggered', true, null, null);
	}

	/**
	 * Was an email send to booking person?
	 *
	 * @type {boolean}
	 */
	get emailSentToBookingPerson() : boolean {
		this.getterDebugValidations(this.aiEmailSentToBookingPerson, false);
		return this.data[3];
	}

	set emailSentToBookingPersonTestSetter(v : boolean) {
        this.setterImpl(3, v, 'emailSentToBookingPerson', true, null, null);
	}

	/**
	 * Was an email send to participants?
	 *
	 * @type {boolean}
	 */
	get emailSentToParticipants() : boolean {
		this.getterDebugValidations(this.aiEmailSentToParticipants, false);
		return this.data[4];
	}

	set emailSentToParticipantsTestSetter(v : boolean) {
        this.setterImpl(4, v, 'emailSentToParticipants', true, null, null);
	}

	/**
	 * Was no email sent because the email of booking person is not available?
	 *
	 * @type {boolean}
	 */
	get emailNotSentBecauseOfMissingEmail() : boolean {
		this.getterDebugValidations(this.aiEmailNotSentBecauseOfMissingEmail, false);
		return this.data[5];
	}

	set emailNotSentBecauseOfMissingEmailTestSetter(v : boolean) {
        this.setterImpl(5, v, 'emailNotSentBecauseOfMissingEmail', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[1] = Meta.getReplacedId(this.data[1], _idReplacements);
		this.affectedShiftModelIdWrapper = this.data[1] === null ? null! : Id.create(this.data[1]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[1] : null, this.affectedShiftModelIdWrapper))
			this.affectedShiftModelIdWrapper = data && data[1] ? Id.create(data[1]) : null!;
	}

	protected get dni() : string {
		return '747';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '747', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiShiftChangeSelectorBase extends ApiObjectWrapper<SchedulingApiShiftChangeSelector>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiShiftChangeSelector> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiShiftChangeSelector as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiShiftChangeSelector, SchedulingApiShiftChangeSelector> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, SchedulingApiShiftChangeSelector>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'shiftChangeSelector',
			nodeName: 'SHIFT_CHANGE_SELECTOR',
			type: PApiType.ApiObject,
			defaultValue: function(this : SchedulingApiShiftChangeSelector, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 36,
		});
	aiShiftModelId : ApiAttributeInfo<SchedulingApiShiftChangeSelector, Id> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'shiftModelId',
			nodeName: 'SHIFT_CHANGE_SELECTOR_SHIFT_MODEL_ID',
			type: PApiType.Id,
			rawDataIndex: 1,
		});
	aiShiftsOfShiftModelId : ApiAttributeInfo<SchedulingApiShiftChangeSelector, Id> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, Id>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'shiftsOfShiftModelId',
			nodeName: 'SHIFT_CHANGE_SELECTOR_SHIFTS_OF_SHIFT_MODEL_ID',
			type: PApiType.Id,
			rawDataIndex: 2,
		});
	aiShiftsOfShiftModelVersion : ApiAttributeInfo<SchedulingApiShiftChangeSelector, Integer> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'shiftsOfShiftModelVersion',
			nodeName: 'SHIFT_CHANGE_SELECTOR_SHIFTS_OF_SHIFT_MODEL_VERSION',
			type: PApiType.Integer,
			rawDataIndex: 3,
		});
	aiShiftsOfSeriesId : ApiAttributeInfo<SchedulingApiShiftChangeSelector, Integer> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'shiftsOfSeriesId',
			nodeName: 'SHIFT_CHANGE_SELECTOR_SHIFTS_OF_SERIES_ID',
			type: PApiType.Integer,
			rawDataIndex: 4,
		});
	aiShiftsOfPacketIndex : ApiAttributeInfo<SchedulingApiShiftChangeSelector, Integer> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, Integer>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'shiftsOfPacketIndex',
			nodeName: 'SHIFT_CHANGE_SELECTOR_SHIFTS_OF_PACKET_INDEX',
			type: PApiType.Integer,
			rawDataIndex: 5,
		});
	aiAddChangeSelectors : ApiAttributeInfo<SchedulingApiShiftChangeSelector, boolean> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, boolean>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'addChangeSelectors',
			nodeName: 'SHIFT_CHANGE_SELECTOR_ADD_CHANGE_SELECTORS',
			type: PApiType.boolean,
			validations: function(this : SchedulingApiShiftChangeSelector) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftChangeSelector) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftChangeSelector, _nodeId : string) {return false;},
			rawDataIndex: 6,
		});
	aiStart : ApiAttributeInfo<SchedulingApiShiftChangeSelector, Date> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, Date>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'start',
			nodeName: 'SHIFT_CHANGE_SELECTOR_START',
			type: PApiType.Date,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftChangeSelector) {
				return ((!!this.addChangeSelectors));
			},
			validations: function(this : SchedulingApiShiftChangeSelector) {
				return [
					() => {
return this.api.validators.min((this.api.currentlyDetailedLoaded instanceof SchedulingApiShift) ?
						+this.api.pMoment.m(this.api.currentlyDetailedLoaded.id.start).add(-5, 'months') :
						+this.api.pMoment.monthsFromNow(-5), true, PApiType.Date, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.end, false, PApiType.Date, this.aiEnd.id, undefined);						return null;
					},
					() => {
						return this.api.validators.notUndefined(PApiType.Date, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftChangeSelector) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiEnd : ApiAttributeInfo<SchedulingApiShiftChangeSelector, DateExclusiveEnd> = new ApiAttributeInfo<SchedulingApiShiftChangeSelector, DateExclusiveEnd>({
			apiObjWrapper: this as any as SchedulingApiShiftChangeSelector,
			name: 'end',
			nodeName: 'SHIFT_CHANGE_SELECTOR_END',
			type: PApiType.DateExclusiveEnd,
			isAvailableByBusinessLogic: function(this : SchedulingApiShiftChangeSelector) {
				return ((!!this.addChangeSelectors));
			},
			validations: function(this : SchedulingApiShiftChangeSelector) {
				return [
					() => {
return this.api.validators.min(() => this.start, false, PApiType.DateExclusiveEnd, this.aiStart.id, undefined);						return null;
					},
					() => {
return this.api.validators.max((this.api.currentlyDetailedLoaded instanceof SchedulingApiShift) ?
						+this.api.pMoment.m(this.api.currentlyDetailedLoaded.id.start).add(12, 'months') :
						+this.api.pMoment.monthsFromNow(12), true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
					() => {
						return this.api.validators.notUndefined(PApiType.DateExclusiveEnd, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiShiftChangeSelector) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiShiftChangeSelector, _nodeId : string) {return undefined;},
			rawDataIndex: 8,
		});

	private shiftModelIdWrapper : Id | null = null!;

	/**
	 * The model id to which the changes should be applied.
	 *
	 * @type {Id}
	 */
	get shiftModelId() : Id | null {
		this.getterDebugValidations(this.aiShiftModelId, false);
		return this.shiftModelIdWrapper;
	}

	set shiftModelId(v : Id | null) {
        this.setterImpl(1, v, 'shiftModelId', false, null, function(this : SchedulingApiShiftChangeSelector) {this.shiftModelIdWrapper = v;});
	}

	private shiftsOfShiftModelIdWrapper : Id | null = null!;

	/**
	 * The model id whose shifts the changes should be applied to.
	 *
	 * @type {Id}
	 */
	get shiftsOfShiftModelId() : Id | null {
		this.getterDebugValidations(this.aiShiftsOfShiftModelId, false);
		return this.shiftsOfShiftModelIdWrapper;
	}

	set shiftsOfShiftModelId(v : Id | null) {
        this.setterImpl(2, v, 'shiftsOfShiftModelId', false, null, function(this : SchedulingApiShiftChangeSelector) {this.shiftsOfShiftModelIdWrapper = v;});
	}

	/**
	 * The model version whose shifts the changes should be applied to.
	 *
	 * @type {Integer}
	 */
	get shiftsOfShiftModelVersion() : Integer | null {
		this.getterDebugValidations(this.aiShiftsOfShiftModelVersion, false);
		return this.data[3];
	}

	set shiftsOfShiftModelVersion(v : Integer | null) {
        this.setterImpl(3, v, 'shiftsOfShiftModelVersion', false, null, null);
	}

	/**
	 * The series id whose shifts the changes should be applied to.
	 *
	 * @type {Integer}
	 */
	get shiftsOfSeriesId() : Integer | null {
		this.getterDebugValidations(this.aiShiftsOfSeriesId, false);
		return this.data[4];
	}

	set shiftsOfSeriesId(v : Integer | null) {
        this.setterImpl(4, v, 'shiftsOfSeriesId', false, null, null);
	}

	/**
	 * The packet index whose shifts the changes should be applied to.
	 *
	 * @type {Integer}
	 */
	get shiftsOfPacketIndex() : Integer | null {
		this.getterDebugValidations(this.aiShiftsOfPacketIndex, false);
		return this.data[5];
	}

	set shiftsOfPacketIndex(v : Integer | null) {
        this.setterImpl(5, v, 'shiftsOfPacketIndex', false, null, null);
	}

	/**
	 * Is the change applied to other shifts? (Used only in frontend)
	 *
	 * @type {boolean}
	 */
	get addChangeSelectors() : boolean {
		this.getterDebugValidations(this.aiAddChangeSelectors, false);
		return this.data[6];
	}

	set addChangeSelectors(v : boolean) {
        this.setterImpl(6, v, 'addChangeSelectors', false, null, null);
	}

	/**
	 * The start of the time interval from where the shifts should be modified. When "null" all shifts into the past will be modified.
	 *
	 * @type {Date}
	 */
	get start() : Date | null {
		this.getterDebugValidations(this.aiStart, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[7] === undefined) {
			this.data[7] = this.aiStart.defaultValue;
			
		}
		return this.data[7];
	}

	set start(v : Date | null) {
        this.setterImpl(7, v, 'start', false, function(this : SchedulingApiShiftChangeSelector) {if (v) Assertions.ensureIsDayStart(v)}, null);
	}

	/**
	 * The end of the time interval from where the shifts should be modified. When "null" all shifts in the future will be modified. Initially this has the value "undefined" to force the user to select a value explicitly.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get end() : DateExclusiveEnd | null | undefined {
		this.getterDebugValidations(this.aiEnd, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiEnd.defaultValue;
			
		}
		return this.data[8];
	}

	set end(v : DateExclusiveEnd | null | undefined) {
        this.setterImpl(8, v, 'end', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[1] = Meta.getReplacedId(this.data[1], _idReplacements);
		this.shiftModelIdWrapper = this.data[1] === null ? null! : Id.create(this.data[1]);
		this.data[2] = Meta.getReplacedId(this.data[2], _idReplacements);
		this.shiftsOfShiftModelIdWrapper = this.data[2] === null ? null! : Id.create(this.data[2]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[1] : null, this.shiftModelIdWrapper))
			this.shiftModelIdWrapper = data && data[1] ? Id.create(data[1]) : null!;
		if(!Meta.isSameId(data ? data[2] : null, this.shiftsOfShiftModelIdWrapper))
			this.shiftsOfShiftModelIdWrapper = data && data[2] ? Id.create(data[2]) : null!;
	}

	protected get dni() : string {
		return '37';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '37', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class SchedulingApiAutomaticBookingCancellationSettingsBase extends ApiObjectWrapper<SchedulingApiAutomaticBookingCancellationSettings>
{
	constructor(override readonly api : SchedulingApiServiceBase,
		override readonly parent : SchedulingApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiAutomaticBookingCancellationSettings> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, SchedulingApiAutomaticBookingCancellationSettings as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, SchedulingApiAutomaticBookingCancellationSettings> = new ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, SchedulingApiAutomaticBookingCancellationSettings>({
			apiObjWrapper: this as any as SchedulingApiAutomaticBookingCancellationSettings,
			name: 'automaticBookingCancellationSettings',
			nodeName: 'AUTOMATIC_BOOKING_CANCELLATION_SETTINGS',
			type: PApiType.ApiObject,
			isAvailableByBusinessLogic: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return ((this.api.affectedShiftsApi.data.aiBookingsCanceledCount.isAvailable &&
						this.api.affectedShiftsApi.data.aiBookingsDeclinedCount.isAvailable &&
						(this.api.affectedShiftsApi.data.bookingsCanceledCount + this.api.affectedShiftsApi.data.bookingsDeclinedCount > 0)));
			},
			defaultValue: function(this : SchedulingApiAutomaticBookingCancellationSettings, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 37,
		});
	aiNoCancellationFees : ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, boolean> = new ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiAutomaticBookingCancellationSettings,
			name: 'noCancellationFees',
			nodeName: 'AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_NO_CANCELLATION_FEES',
			type: PApiType.boolean,
			isAvailableByBusinessLogic: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return ((this.api.affectedShiftsApi.data.bookingsCanceledCount > 0));
			},
			validations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiAutomaticBookingCancellationSettings, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiAutomaticOnlineRefund : ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, boolean> = new ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiAutomaticBookingCancellationSettings,
			name: 'automaticOnlineRefund',
			nodeName: 'AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_AUTOMATIC_ONLINE_REFUND',
			type: PApiType.boolean,
			hasPermissionToSet: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				{const conditionValue = this.api.pPermissionsService.userCanOnlineRefund(this.api.affectedShiftsApi.data.shifts); if(!conditionValue) return conditionValue === false ? {sourceString: 'Du hast keine Berechtigung, Online-Rückerstattungen an Kunden für folgende Tätigkeiten zu veranlassen: <b>${listOfShiftModelsWithMissingOnlineRefundPermission}</b>. Wende dich bitte an deine Admins, falls das geändert werden soll.', params: {listOfShiftModelsWithMissingOnlineRefundPermission: this.listOfShiftModelsWithMissingOnlineRefundPermission}} : undefined;}
				return true;
			},
			canSetByBusinessLogic: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				{const conditionValue = this.api.data.isOnlinePaymentAvailable; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um diese Funktionalität nutzen zu können, aktiviere bitte erst die <a href="client/plugin/payments" target="_blank">Online-Zahlung</a>.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiAutomaticBookingCancellationSettings, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	aiTransactionInternalNotes : ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, string> = new ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, string>({
			apiObjWrapper: this as any as SchedulingApiAutomaticBookingCancellationSettings,
			name: 'transactionInternalNotes',
			nodeName: 'AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_TRANSACTION_INTERNAL_NOTES',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return ((this.automaticOnlineRefund));
			},
			validations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiAutomaticGiftCardRefund : ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, boolean> = new ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, boolean>({
			apiObjWrapper: this as any as SchedulingApiAutomaticBookingCancellationSettings,
			name: 'automaticGiftCardRefund',
			nodeName: 'AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_AUTOMATIC_GIFT_CARD_REFUND',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				{const conditionValue = this.api.data.giftCardSettings.isSaleEnabled; if(!conditionValue) return conditionValue === false ? {sourceString: 'Um diese Funktion nutzen zu können, musst du zuerst den <a href="client/plugin/gift-cards" target="_blank">Gutscheinverkauf aktivieren</a>.', params: false} : undefined;}
				{const conditionValue = this.automaticOnlineRefund; if(!conditionValue) return conditionValue === false ? {sourceString: 'Die automatische Gutschein-Ausstellung steht derzeit nur für Buchungen zur Verfügung, die online bezahlt worden sind. Daher musst du erst die »automatische Rückzahlung per Online-Zahlung« aktivieren, bevor du diese Funktion nutzen kannst.', params: false} : undefined;}
				return true;
			},
			validations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
				];
			}
			,
			defaultValue: function(this : SchedulingApiAutomaticBookingCancellationSettings, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	aiGiftCardInternalNotes : ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, string> = new ApiAttributeInfo<SchedulingApiAutomaticBookingCancellationSettings, string>({
			apiObjWrapper: this as any as SchedulingApiAutomaticBookingCancellationSettings,
			name: 'giftCardInternalNotes',
			nodeName: 'AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_GIFT_CARD_INTERNAL_NOTES',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return ((this.automaticGiftCardRefund));
			},
			validations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
					() => {
						return this.api.validators.maxLength(2000, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : SchedulingApiAutomaticBookingCancellationSettings) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});

	/**
	 * If "true" no cancellation-fees will be set for the automatically cancelled bookings. If "false" then cancellation-fees defined by the bookings cancellation-policy will be added to the booking.
	 *
	 * @type {boolean}
	 */
	get noCancellationFees() : boolean {
		this.getterDebugValidations(this.aiNoCancellationFees, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[1] === undefined) {
			this.data[1] = this.aiNoCancellationFees.defaultValue;
			
		}
		return this.data[1];
	}

	set noCancellationFees(v : boolean) {
        this.setterImpl(1, v, 'noCancellationFees', false, null, null);
	}

	/**
	 * Should automatically any online open-amount for the booking be refunded?
	 *
	 * @type {boolean}
	 */
	get automaticOnlineRefund() : boolean {
		this.getterDebugValidations(this.aiAutomaticOnlineRefund, false);
		return this.data[2];
	}

	set automaticOnlineRefund(v : boolean) {
        this.setterImpl(2, v, 'automaticOnlineRefund', false, null, function(this : SchedulingApiAutomaticBookingCancellationSettings) {// You cannot gift-card refund when this is deactivated
					if(!v)
						this.rawData[this.api.consts.AUTOMATIC_BOOKING_CANCELLATION_SETTINGS_AUTOMATIC_GIFT_CARD_REFUND] = false;});
	}

	/**
	 * If automatic online refund should be done then this defines the internal comment to be set for the created transactions.
	 *
	 * @type {string}
	 */
	get transactionInternalNotes() : string | null {
		this.getterDebugValidations(this.aiTransactionInternalNotes, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[3] === undefined) {
			this.data[3] = this.aiTransactionInternalNotes.defaultValue;
			
		}
		return this.data[3];
	}

	set transactionInternalNotes(v : string | null) {
        this.setterImpl(3, v, 'transactionInternalNotes', false, null, null);
	}

	/**
	 * Should automatically a refund gift-card be created for any payments by gift-card?
	 *
	 * @type {boolean}
	 */
	get automaticGiftCardRefund() : boolean {
		this.getterDebugValidations(this.aiAutomaticGiftCardRefund, false);
		return this.data[4];
	}

	set automaticGiftCardRefund(v : boolean) {
        this.setterImpl(4, v, 'automaticGiftCardRefund', false, null, null);
	}

	/**
	 * If automatic gift-card refund should be done then this defines the internal comment to be set for the created gift-cards.
	 *
	 * @type {string}
	 */
	get giftCardInternalNotes() : string | null {
		this.getterDebugValidations(this.aiGiftCardInternalNotes, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.aiGiftCardInternalNotes.defaultValue;
			
		}
		return this.data[5];
	}

	set giftCardInternalNotes(v : string | null) {
        this.setterImpl(5, v, 'giftCardInternalNotes', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '38';
	}

	static async loadDetailed(	api : SchedulingApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '38', { success: success, error: error, searchParams: searchParams});
	}
}



