import { USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';

export type ReportFilterServiceIndexedDBKeyDataType =
typeof REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY |
typeof REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY |
typeof REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_USERS_WITHOUT_ENTRIES_KEY |
typeof REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_KEY |
typeof REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_FORECAST_KEY;

export const REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_LEAVES_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'showLeaves',
} as const;

export const REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_UNPAID_LEAVES_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'showUnpaidLeaves',
} as const;

export const REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_USERS_WITHOUT_ENTRIES_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'showUsersWithoutEntries',
} as const;

export const REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'showWorkingTimes',
} as const;

export const REPORT_FILTER_SERVICE_INDEXED_DB_SHOW_WORKING_TIMES_FORECAST_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'showWorkingTimesForecast',
} as const;
