/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, NgZone, Output, ViewChild } from '@angular/core';
import { UniqueAriaLabelByDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { pCollapsibleAnimationSpeed } from '@plano/shared/core/component/p-collapsible/p-collapsible.component.const';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { isElementFullyInViewport } from '@plano/shared/core/utils/element-utils';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PCollapsibleEvent = {event : MouseEvent | null, collapsedState : boolean};

/**
 * A collapsible component that can be used to hide content.
 *
 * @example with content
 * <p-collapsible>
 * 	<span trigger>Trigger</span>
 * 	<div content>Content</div>
 * </p-collapsible>
 *
 * @example without content
 * 	<p-collapsible #collapsibleTrigger>
 * 		<span trigger>Trigger</span>
 * 	</p-collapsible>
 * 	<div class="fancy-thing" *ngIf="!collapsibleTrigger.collapsed"></div>
 */
@Component({
	selector: 'p-collapsible',
	templateUrl: './p-collapsible.component.html',
	styleUrls: ['./p-collapsible.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [
		trigger(
			'slideOpen',
			[
				state('false, void', style({ height: '0px', overflow: 'hidden' })),
				state('true', style({ height: '*' })),
				transition( 'true <=> false', [animate(pCollapsibleAnimationSpeed)]),
			],
		),
	],
	standalone: true,
	imports: [
		PCommonModule,
		FaIconComponent,
	],
})
export class PCollapsibleComponent extends UniqueAriaLabelByDirective implements AfterViewInit {
	@HostBinding('class.border-danger')
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hasDanger : boolean = false;

	@HostBinding('class.border-primary')
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public borderPrimary : boolean = false;

	/**
	 * Classes to be added to the icon of the collapsible.
	 */
	@Input() public iconClasses : string | null = null;

	/**
	 * Class to add to the trigger button of the collapsible
	 */
	@Input() public triggerBtnClasses : string | null = null;

	/**
	 * Visual size of this component.
	 * Can be useful if you have few space in a button-bar or want to have large buttons on mobile.
	 */
	@Input() public size : typeof this.enums.BootstrapSize.SM | typeof this.enums.BootstrapSize.LG = this.enums.BootstrapSize.LG;

	@ViewChild('ngContentContainer') private ngContentContainer ?: ElementRef<HTMLElement>;

	@ViewChild('collapsibleButton') private collapsibleButton ! : ElementRef<HTMLButtonElement>;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public collapsedChange = new EventEmitter<PCollapsibleEvent>();

	@HostBinding('class.card')
	protected readonly _alwaysTrue = true;

	@HostBinding('class.mb-1') private get sizeIsSm() : boolean {
		return this.size === this.enums.BootstrapSize.SM;
	}

	/**
	 * If this is true, the content will be hidden.
	 */
	@Input() public collapsed : boolean = true;

	/**
	 * Sometimes we have more than one sticky element, and in such cases we might need to pass the
	 * stickyOffset so the elements don't overlap
	 */
	@Input() public stickyOffset : number = 0;

	@HostBinding('class.has-content')
	public hasContent : boolean = false;

	constructor(
		private modalService : ModalService,
		private zone : NgZone,
		private elementRef : ElementRef<HTMLElement>,
	) {
		super();
	}

	public ngAfterViewInit() : void {
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.ngContentContainer, 'ngContentContainer');
		this.hasContent = !!this.ngContentContainer.nativeElement.previousSibling;
	}

	/**
	 * Toggle the state if this is collapsed or not.
	 */
	private setCollapsed(input : PCollapsibleEvent) : void {
		this.collapsed = input.collapsedState;
		if (this.collapsed) {
			this.collapsibleButton.nativeElement.style.position = 'relative';
			this.collapsibleButton.nativeElement.style.top = '';
		} else {
			this.zone.runOutsideAngular(() => {
				window.setTimeout(() => {
					const wholeHeight = this.collapsibleButton.nativeElement.parentElement!.getBoundingClientRect().height;
					if (wholeHeight > window.innerHeight * 0.5) {
						this.collapsibleButton.nativeElement.style.position = 'sticky';
						let isInsideModal = false;

						// if there is a modal open, check if the collapsible is a child of the modal
						if (this.modalService.topModalRef) {
							for (const modalBodyElement of document.querySelectorAll<HTMLElement>('.modal-body')) {
								if (modalBodyElement.contains(this.elementRef.nativeElement)) {
									this.collapsibleButton.nativeElement.style.top = `-${window.getComputedStyle(modalBodyElement).paddingTop}`;
									isInsideModal = true;
								}
							}
						}
						if (!isInsideModal)
							this.collapsibleButton.nativeElement.style.top = `${this.stickyOffset}px`;
					}
				}, pCollapsibleAnimationSpeed + 10);
			});
		}
		this.collapsedChange.emit(input);
	}

	/**
	 * Toggle the state if this is collapsed or not.
	 */
	public toggle(event : PCollapsibleEvent['event']) : void {
		this.setCollapsed({event: event, collapsedState: !this.collapsed});
		if (this.collapsed && !isElementFullyInViewport(this.collapsibleButton.nativeElement)) {
			this.collapsibleButton.nativeElement.scrollIntoView({ behavior: 'smooth', block:'start'});
		}
	}

	/**
	 * An unique HTML id for the header.
	 */
	public get headerHtmlId() : string {
		return this.ariaLabelHtmlId;
	}

	// /**
	//  * Makes this component more accessible
	//  */
	// public handleKeyup(event : KeyboardEvent) : void {
	// 	event.stopPropagation();
	// 	switch (event.key) {
	// 		case 'Enter':
	// 			this.toggle();
	// 			break;
	// 		case 'Escape':
	// 			this.setCollapsed(true);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }
}
