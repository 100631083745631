import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Injectable } from '@angular/core';
import { PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { PBaseClass } from '@plano/shared/base';

/**
 * Service to handle all operations related to the localization of numbers, both separators and formatting.
 */
@Injectable({providedIn: 'root'})
export class PLocaleNumbersService extends PBaseClass {
	/**
	 * Get the thousands separators for a given locale.
	 * @param locale The locale to get the thousands separators for.
	 * @param [type='number'] The type of the input, can be 'currency' or 'number'
	 * @returns The thousands separators for the given locale, it returns an array
	 * as there can be multiple separators for a single locale, we might want to support
	 * different versions of the same separator, like apostrophes or spaces.
	 */
	public static thousandsSeparators(locale : PSupportedLocaleIds, type : 'currency' | 'number' = 'number') : string [] {
		const angularDefaultSeparator = getLocaleNumberSymbol(locale, type === 'currency' ? NumberSymbol.CurrencyGroup : NumberSymbol.Group);
		switch (angularDefaultSeparator) {
			// non-breaking space
			case '\u00A0':
				return ['\u00A0', ' '];
			case '’':
				return ['’', '\''];
			default:
				return [angularDefaultSeparator];
		}
	}

	/**
	 * Get the decimal separator for a given locale.
	 * @param locale The locale to get the decimal separator for.
	 * @param [type='number'] The type of the input, can be 'currency' or 'number'
	 */
	public static decimalSeparator(locale : PSupportedLocaleIds, type : 'currency' | 'number' = 'number') : string {
		return getLocaleNumberSymbol(locale, type === 'currency' ? NumberSymbol.CurrencyDecimal : NumberSymbol.Decimal);
	}

	/**
	 * Takes input like string '2.000'
	 * and returns number 2000 if locale is Germany
	 * or returns number 2 if locale is en
	 * @param locale The locale id for localization of the input
	 * @param input The input that the user gave us
	 * @param [type='number'] The type of the input, can be 'currency' or 'number'
	 * @returns a number if can be transformed to it or null otherwise
	 */
	public static turnLocaleNumberIntoNumber(locale : PSupportedLocaleIds, input : string | null | number, type : 'currency' | 'number' = 'number') : number | null {
		if (input === null) return null;

		// If the input is not a string or a number, we can't transform it
		if (typeof input !== 'string' && typeof input !== 'number') return null;

		// If the input is already a number, there is nothing to do
		if (typeof input === 'number') return input;

		// Get the correct separators
		const decimalSeparator = this.decimalSeparator(locale, type);
		const thousandsSeparators = this.thousandsSeparators(locale, type);

		let returnValue = input;

		let thousandsPart = '';

		// If the input has a decimal separator, we need to get the thousands part separately to be able to correctly validate it
		if (input.includes(decimalSeparator)) {
			thousandsPart = input.split(decimalSeparator)[0];
		} else {
			thousandsPart = input;
		}

		// Go through the possible thousand separators and check the validity of the sections separated by them
		for (const thousandSeparator of thousandsSeparators) {
			if (!thousandsPart.includes(thousandSeparator)) continue;
			const sections = thousandsPart.split(thousandSeparator);

			// One section can't have more than 3 digits
			if (sections.some(section => section.length > 3)) {
				return null;
			}

			// Aside from the first section, all other sections must have 3 digits
			if (sections.length > 1 && sections.slice(1).some(section => section.length !== 3)) {
				return null;
			}
			returnValue = sections.join('');
		}
		if (returnValue === input) returnValue = thousandsPart;

		if (input.endsWith(decimalSeparator)) {
			return null;
		}

		if (input.includes(decimalSeparator)) {
			const numberSections = input.split(decimalSeparator);

			// If there are more than one decimal separator, we can't transform the input
			if (numberSections.length > 2) {
				return null;
			}
			returnValue = `${returnValue}.${numberSections.at(1)?.replace(decimalSeparator, '.') ?? ''}`;
		}
		return Number.isNaN(+returnValue) ? null : +returnValue;
	}

	/**
	 * Takes input like number 2000.5
	 * and returns locale number as string '2000,5' if locale is Germany
	 * or returns '2000.5' if locale is en
	 * @param locale The locale id for localization of the input
	 * @param input The input that the user gave us
	 */
	public static turnNumberIntoLocaleNumber(locale : PSupportedLocaleIds, input : number | undefined | null) : string | null {
		if (input === null || input === undefined) return null;
		const decimalSeparator = this.decimalSeparator(locale);
		return input.toString().replace('.', decimalSeparator);
	}
}
