import { PopoverConfig } from 'ngx-bootstrap/popover';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export function getPopoverConfig() : PopoverConfig {
	return Object.assign(new PopoverConfig(), {
		container: 'body',
		triggers: 'mouseenter:mouseleave',
	});
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export function getTooltipConfig() : TooltipConfig {
	return Object.assign(new TooltipConfig(), {
		container: 'body',
		triggers: 'mouseenter:mouseleave',
	});
}
