import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ApiErrorService {

	/**
	 * Register to this subject to be notified about all api errors.
	 */
	// eslint-disable-next-line rxjs/no-ignored-replay-buffer -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public readonly error : ReplaySubject<HttpErrorResponse> = new ReplaySubject<HttpErrorResponse>();
}
