/* eslint complexity: ["error", 50] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS } from '@plano/shared/core/pipe/decimal-pipe.consts';
import { PDurationOnlyAsSingleUnitsPipe, PDurationTimePipe } from '@plano/shared/core/pipe/duration-time.pipe';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe, LocalizePipeParamsType } from '@plano/shared/core/pipe/localize.pipe';
import { PCurrencyPipe } from '@plano/shared/core/pipe/p-currency.pipe';
import { PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { AngularDatePipeFormat, PDateFormat } from '@plano/shared/core/pipe/p-date.pipe.enums';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { PPossibleErrorNames, PValidationErrorValue, ValidatorsServiceReturnType } from '@plano/shared/core/validators.types';

@Injectable({providedIn: 'root'})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ValidationHintService {
	constructor(
		private localize : LocalizePipe,
		private decimalPipe : DecimalPipe,
		private console : LogService,
		private datePipe : PDatePipe,
		private currencyPipe : PCurrencyPipe,
		private percentPipe : PercentPipe,
		private pDurationTimePipe : PDurationTimePipe,
		private pDurationOnlyAsSingleUnitsPipe : PDurationOnlyAsSingleUnitsPipe,
	) { }

	// error : ValidatorsServiceReturnType<'min'>
	// TODO: min() now returns PValidatorObject. So ValidatorsServiceReturnType only works for PValidatorFn.
	private getMinText(error : ValidatorsServiceReturnType<'min'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) {
			return this.localize.transform({
				sourceString: 'Diese Eingabe darf nicht kleiner sein als »${labelOfComparedControl}«.',
				params: {labelOfComparedControl: labelOfComparedControl},
			});
		}

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!error.errorText && error['min'] === 0) return this.localize.transform('Bitte keine negativen Zahlen.');

		let errorText : PDictionarySourceString | undefined = undefined;
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		type TMinErrorObj = Omit<ValidatorsServiceReturnType<'min'>, 'min'> & {min : number | string};
		let errorObj : TMinErrorObj = { ...error, min: error['min'] };
		switch (error.type) {
			case PApiType.LocalTime:
				errorText = errorText ?? 'Bitte mindestens die Zeit »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(
					error['min'],
					PDateFormat.VERY_SHORT_TIME,
					true,
				)};
				break;
			case PApiType.DateTime:
				errorText = errorText ?? 'Bitte mindestens die Zeit »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(error['min'], AngularDatePipeFormat.SHORT)};
				break;
			case PApiType.Date:
				errorText = errorText ?? 'Bitte mindestens das Datum »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(error['min'])};
				break;
			case PApiType.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte mindestens das Datum »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(error['min'] - 1)};
				break;
			case PApiType.ClientCurrency:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: this.currencyPipe.transform(error['min'], Config.CURRENCY_CODE)};
				break;
			case PApiType.Euro:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: this.currencyPipe.transform(error['min'], 'EUR')};
				break;
			case PApiType.Percent:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				const min = this.percentPipe.transform((error['min']));
				assumeNonNull(min);
				errorObj = {...error, min: min};
				break;
			case PApiType.Duration:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				if (error.durationUIType !== null && error.durationUIType !== undefined) {
					errorObj = {...error, min: this.pDurationOnlyAsSingleUnitsPipe.transform(error['min'], error.durationUIType)};
				} else {
					errorObj = {...error, min: this.pDurationTimePipe.transform(error['min'], false)};
				}
				break;
			case PApiType.Integer:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: `${Math.floor(+error['min'])}`};
				break;
			case PApiType.ApiList:
				errorText = errorText ?? 'Bitte mindestens »${min}« anlegen.';
				errorObj = {...error, min: `${Math.floor(+error['min'])}`};
				break;
			case PApiType.Minutes:
			case PApiType.Hours:
			case PApiType.Days:
			case PApiType.Months:
			case PApiType.Years:
			case PApiType.number:
			case PApiType.string:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: Number.isNaN(error['min']) ? error['min'] : this.decimalPipe.transform(error['min'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!};
				break;
			default:
				this.untransformedParamCheck('min', error);
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: Number.isNaN(error['min']) ? error['min'] : this.decimalPipe.transform(error['min'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!};
				break;
		}
		const params = this.turnIntoLocalizePipeParamsType(errorObj);
		return this.localize.transform({
			sourceString: errorText,
			params: params,
		}, false);
	}

	private getNotEqualsText(error : ValidatorsServiceReturnType<'notEquals'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) return this.localize.transform({
			sourceString: 'Die Eingabe darf nicht identisch sein mit »${labelOfComparedControl}«.',
			params: {labelOfComparedControl: labelOfComparedControl},
		});

		let errorText : PDictionarySourceString | undefined = undefined;
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		errorText = errorText ?? 'Die Eingabe darf nicht identisch sein mit »${notEquals}«.';
		return this.localize.transform({sourceString: errorText, params: error}, false);
	}

	// error : ValidatorsServiceReturnType<'min'>
	private getMaxText(error : ValidatorsServiceReturnType<'max'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) {
			return this.localize.transform({
				sourceString: 'Diese Eingabe darf nicht größer sein als »${labelOfComparedControl}«.',
				params: {labelOfComparedControl: labelOfComparedControl},
			});
		}

		let errorText : PDictionarySourceString | undefined = undefined;
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		let errorObj : ValidatorsServiceReturnType<'max'> = { ...error };
		switch (error.type) {
			case PApiType.LocalTime:
				errorText = errorText ?? 'Bitte höchstens die Uhrzeit »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'], PDateFormat.VERY_SHORT_TIME, true)};
				break;
			case PApiType.DateTime:
				errorText = errorText ?? 'Bitte höchstens die Zeit »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'], AngularDatePipeFormat.SHORT)};
				break;
			case PApiType.Date:
				errorText = errorText ?? 'Bitte höchstens das Datum »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'])};
				break;
			case PApiType.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte höchstens das Datum »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'] - 1)};
				break;
			case PApiType.ClientCurrency:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: this.currencyPipe.transform(error['max'], Config.CURRENCY_CODE)};
				break;
			case PApiType.Euro:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: this.currencyPipe.transform(error['max'], 'EUR')};
				break;
			case PApiType.Percent:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: this.percentPipe.transform((error['max']))};
				break;
			case PApiType.Duration:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				if (error.durationUIType !== null && error.durationUIType !== undefined) {
					errorObj = {...error, max: this.pDurationOnlyAsSingleUnitsPipe.transform(error['max'], error.durationUIType)};
				} else
					errorObj = {...error, max: this.pDurationTimePipe.transform(error['max'], false)};
				break;
			case PApiType.Integer:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: `${Math.floor(+error['max'])}`};
				break;
			case PApiType.ApiList:
				errorText = errorText ?? 'Bitte höchstens »${max}« anlegen.';
				errorObj = {...error, max: `${Math.floor(+error['max'])}`};
				break;
			case PApiType.Minutes:
			case PApiType.Hours:
			case PApiType.Days:
			case PApiType.Months:
			case PApiType.Years:
			case PApiType.number:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: Number.isNaN(error['max']) ? error['max'] : this.decimalPipe.transform(error['max'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!};
				break;
			default:
				this.untransformedParamCheck('max', error);
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				if (Number.isNaN(error['max'])) {errorObj = error;} else {
					errorObj = {...error, max: this.decimalPipe.transform(error['max'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!};
				}
		}

		return this.localize.transform({
			sourceString: errorText,
			params: errorObj,
		}, false);
	}

	private getGreaterThanText(error : ValidatorsServiceReturnType<'greaterThan'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) return this.localize.transform({
			sourceString: 'Eingabe muss größer sein als der Wert von »${labelOfComparedControl}«.',
			params: {labelOfComparedControl: labelOfComparedControl},
		});

		let errorText : PDictionarySourceString | undefined = undefined;
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		switch (error.type) {
			case PApiType.LocalTime:
				errorText = errorText ?? 'Bitte eine Zeit später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan, PDateFormat.VERY_SHORT_TIME, true)},
				});
			case PApiType.DateTime:
				errorText = errorText ?? 'Bitte eine Zeit später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan, AngularDatePipeFormat.SHORT)},
				});
			case PApiType.Date:
				errorText = errorText ?? 'Bitte ein Datum später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan)},
				});
			case PApiType.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte ein Datum später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan - 1)},
				});
			case PApiType.Percent:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				const greaterThan = this.percentPipe.transform(error.greaterThan);
				assumeNonNull(greaterThan);
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: greaterThan},
				});
			case PApiType.ClientCurrency:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: { greaterThan: this.currencyPipe.transform(error['greaterThan'], Config.CURRENCY_CODE)},
				});
			case PApiType.Euro:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: { greaterThan: this.currencyPipe.transform(error['greaterThan'], 'EUR')},
				});
			case PApiType.Integer:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: `${Math.floor(+error['greaterThan'])}`},
				});
			case PApiType.Duration:
				errorText = errorText ?? 'Bitte einen Wert größer als »${greaterThan}« eingeben.';
				let params = {};
				if (error.durationUIType !== null && error.durationUIType !== undefined) {
					params = {greaterThan: this.pDurationOnlyAsSingleUnitsPipe.transform(error['greaterThan'], error.durationUIType)};
				} else {
					params = {greaterThan: this.pDurationTimePipe.transform(error['greaterThan'], false)};
				}
				return this.localize.transform({
					sourceString: errorText,
					params: params,
				});
			case PApiType.Minutes:
			case PApiType.Hours:
			case PApiType.Days:
			case PApiType.Months:
			case PApiType.Years:
			case PApiType.number:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.decimalPipe.transform(error['greaterThan'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!},
				});
			default:
				this.untransformedParamCheck('greaterThan', error);
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({sourceString: errorText, params: error}, false);
		}
	}

	private fromKbToMb(kb : number) : number {
		return kb / 1024.0;
	}

	private getLessThanText(error : ValidatorsServiceReturnType<'lessThan'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) return this.localize.transform({
			sourceString: 'Eingabe muss kleiner sein als »${labelOfComparedControl}«.',
			params: {labelOfComparedControl: labelOfComparedControl},
		});

		let errorText : PDictionarySourceString | undefined = undefined;
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		switch (error.type) {
			case PApiType.LocalTime:
				errorText = errorText ?? 'Bitte eine Zeit früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'], PDateFormat.VERY_SHORT_TIME, true)},
				});
			case PApiType.DateTime:
				errorText = errorText ?? 'Bitte eine Zeit früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'], AngularDatePipeFormat.SHORT)},
				});
			case PApiType.Date:
				errorText = errorText ?? 'Bitte ein Datum früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'])},
				});
			case PApiType.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte ein Datum früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'] - 1)},
				});
			case PApiType.Percent:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				const lessThan = this.percentPipe.transform((error['lessThan']));
				assumeNonNull(lessThan);
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: lessThan},
				});
			case PApiType.ClientCurrency:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({ sourceString: errorText, params: { lessThan: this.currencyPipe.transform(error['lessThan'], Config.CURRENCY_CODE) }});
			case PApiType.Euro:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({ sourceString: errorText, params: { lessThan: this.currencyPipe.transform(error['lessThan'], 'EUR')}});
			case PApiType.Integer:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: `${this.decimalPipe.transform(Math.floor(+error['lessThan']), DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!}`},
				});
			case PApiType.number:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: `${this.decimalPipe.transform(+error['lessThan'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!}`},
				});
			case PApiType.Duration:
				errorText = errorText ?? 'Bitte einen Wert kleiner als »${lessThan}« eingeben.';
				let params = {};
				if (error.durationUIType !== null && error.durationUIType !== undefined) {
					params = {lessThan: this.pDurationOnlyAsSingleUnitsPipe.transform(error['lessThan'], error.durationUIType)};
				} else {
					params = {lessThan: this.pDurationTimePipe.transform(error['lessThan'], false)};
				}
				return this.localize.transform({
					sourceString: errorText,
					params: params,
				});
			case PApiType.Minutes:
			case PApiType.Hours:
			case PApiType.Days:
			case PApiType.Months:
			case PApiType.Years:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.decimalPipe.transform(error['lessThan'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!},
				});
			default:
				this.untransformedParamCheck('lessThan', error);
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({sourceString: errorText, params: error}, false);
		}
	}

	private untransformedParamCheck(key : string, error : PValidationErrorValue) : void {
		if (typeof error[key] === 'number') {
			this.console.error(`The value of ${key} is a number but needs to be transformed. So the error type ${error.type} is not handled yet.`);
		}
	}

	/**
	 * Create a copy of the provided object and turn the first layer of child values into strings if they are not null
	 * or undefined.
	 */
	private turnIntoLocalizePipeParamsType(input : { [key : string] : unknown }) : LocalizePipeParamsType {
		const output : LocalizePipeParamsType = {};

		for (const [key, value] of Object.entries(input)) {
			switch (typeof value) {
				case 'number':
					output[key] = this.decimalPipe.transform(value)!;
					break;
				case 'bigint':

					// We expect that we will never have a case where a bigint is shown in the UI. So we have not yet spent time
					// to implement a proper way to handle bigints. We just log the case to Sentry if it ever happens.
					this.console.error('BigInt is not supported by the localize pipe yet.');

					output[key] = value.toString();
					break;
				case 'string':
					output[key] = value;
					break;
				case 'boolean':
				case 'symbol':
				case 'undefined':
				case 'object':
				case 'function':

					// This value type is not relevant for the localize pipe
					break;
			}
		}

		return output;
	}

	/* eslint max-lines-per-function: ["error", 200] -- It’s ok that this method has many lines. It’s just a switch. */
	/* eslint complexity: ["error", 150] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
	/* eslint sonarjs/cognitive-complexity: ["error", 180] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
	/**
	 * Get an error value object, and generate a human readable error message text from it.
	 *
	 * @param error The error value object
	 * @param labelOfComparedControl If the error is compared to another control, this is the label of the other control.
	 */
	public getErrorText(error : PValidationErrorValue, labelOfComparedControl : string | null) : string {
		const name : PValidationErrorValue['name'] = error.name;
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const customErrorText = error.errorText ? (typeof error.errorText === 'string' ? error.errorText : error.errorText()) : null;
		const params = this.turnIntoLocalizePipeParamsType(error);
		const customMsg = customErrorText ? this.localize.transform({sourceString: customErrorText, params: params}, false) : null;
		switch (name) {
			case PPossibleErrorNames.MIN_LENGTH :
				if (customMsg) return customMsg;
				return this.minLengthText(error);
			case PPossibleErrorNames.NOT_EQUALS:
				if (customMsg) return customMsg;
				return this.getNotEqualsText(error as ValidatorsServiceReturnType<'notEquals'>, labelOfComparedControl);
			case PPossibleErrorNames.MAX :
				return this.getMaxText(error as ValidatorsServiceReturnType<'max'>, labelOfComparedControl);
			case PPossibleErrorNames.MIN :
				return this.getMinText(error as ValidatorsServiceReturnType<'min'>, labelOfComparedControl);
			case PPossibleErrorNames.REQUIRED :
			case PPossibleErrorNames.ID_DEFINED :
			case PPossibleErrorNames.NOT_UNDEFINED :
				if (customMsg) return customMsg;
				return this.localize.transform('Diese Eingabe ist Pflicht.');
			case PPossibleErrorNames.PLZ :
				if (customMsg) return customMsg;
				return this.localize.transform('Das ist keine gültige Postleitzahl.');
			case PPossibleErrorNames.UPPERCASE :
				if (customMsg) return customMsg;
				return this.localize.transform('Nur Großbuchstaben erlaubt.');
			case PPossibleErrorNames.GREATER_THAN :
				return this.getGreaterThanText(error as ValidatorsServiceReturnType<'greaterThan'>, labelOfComparedControl);
			case PPossibleErrorNames.LESS_THAN :
				return this.getLessThanText(error, labelOfComparedControl);
			case PPossibleErrorNames.FLOAT :
				if (customMsg) return customMsg;
				return this.floatText();
			case PPossibleErrorNames.INTEGER :
				if (customMsg) return customMsg;
				return this.localize.transform({
					sourceString: 'Bitte nur ganze Zahlen eingeben, z.B. »${example}«.',
					params: { example: this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)! },
				});
			case PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT :
				if (customMsg) return customMsg;
				return this.maxDecimalPlacesCountText(error);
			case PPossibleErrorNames.CURRENCY :
				if (customMsg) return customMsg;
				return this.currencyText(error);
			case PPossibleErrorNames.EMAIL_WITHOUT_AT :
				if (customMsg) return customMsg;
				return this.localize.transform('Es fehlt das »@« Zeichen.');
			case PPossibleErrorNames.URL_PROTOCOL_MISSING :
				if (customMsg) return customMsg;
				return this.localize.transform('Bitte mit »http://« oder »https://« beginnen.');
			case PPossibleErrorNames.DOMAIN :
			case PPossibleErrorNames.URL :
			case PPossibleErrorNames.EMAIL :
			case PPossibleErrorNames.IBAN :
			case PPossibleErrorNames.BIC :
				if (customMsg) return customMsg;
				return this.localize.transform('Falsches Format eingegeben.');
			case PPossibleErrorNames.PHONE :
				if (customMsg) return customMsg;
				return this.localize.transform('Falsches Format eingegeben. Richtiges Beispiel: »+491230000000«');
			case PPossibleErrorNames.MAX_LENGTH :
				if (customMsg) return customMsg;
				if (error.type === PApiType.ApiList) return this.localize.transform({sourceString: 'Es sind maximal ${requiredLength} Einträge erlaubt. Du hast aktuell ${actualLength}.', params: params}, false);
				return this.localize.transform({sourceString: 'Bitte maximal ${requiredLength} Zeichen eingeben. ${actualLength} ist zu viel.', params: params}, false);
			case PPossibleErrorNames.WHITESPACE :
				if (customMsg) return customMsg;
				return this.whitespaceText(error);
			case PPossibleErrorNames.NUMBERS_REQUIRED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Passwort muss eine Zahl enthalten.', params: params}, false);
			case PPossibleErrorNames.LETTERS_REQUIRED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Passwort muss einen Buchstaben enthalten.', params: params}, false);
			case PPossibleErrorNames.UPPERCASE_REQUIRED :
				if (customMsg) return customMsg;
				return this.localize.transform('Das Passwort muss einen Großbuchstaben enthalten.');
			case PPossibleErrorNames.EMAIL_INVALID :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Diese Email-Adresse scheint nicht zu existieren.', params: params}, false);
			case PPossibleErrorNames.EMAIL_USED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Diese Email-Adresse wird bereits benutzt.', params: params}, false);
			case PPossibleErrorNames.NUMBER_NAN :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: '»${actual}« ist keine Zahl.', params: params}, false);
			case PPossibleErrorNames.PASSWORD_UNCONFIRMED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Die Passwort-Wiederholung stimmt nicht.', params: params}, false);
			case PPossibleErrorNames.IMAGE_MIN_HEIGHT :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Bild ist nicht hoch genug.', params: params}, false);
			case PPossibleErrorNames.IMAGE_MIN_WIDTH :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Bild ist nicht breit genug.', params: params}, false);
			case PPossibleErrorNames.MAX_FILE_SIZE :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Deine Datei ist zu groß. Die maximal zulässige Größe beträgt ${expected} MB.', params: {expected: `${this.fromKbToMb(error['expected'])}`}}, false);
			case PPossibleErrorNames.IMAGE_RATIO :
				if (customMsg) return customMsg;
				return PPossibleErrorNames.IMAGE_RATIO;
			case PPossibleErrorNames.URL_INCOMPLETE:
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Die URL ist unvollständig.', params: params}, false);
			case undefined :
				this.console.error('error.name should never be undefined');
				return 'undefined';
			case PPossibleErrorNames.PATTERN :
			case PPossibleErrorNames.PASSWORD :
			case PPossibleErrorNames.PDF_MAX_PAGES :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Lade bitte eine Datei mit einer Seite hoch; deine Datei hat ${actual} Seiten.', params: params});
			case PPossibleErrorNames.PDF_PAGE_DIMENSION :
				if (customMsg) return customMsg;
				return this.localize.transform('Lade bitte eine Datei in DIN A4 Format (210 x 297 mm) hoch.');
			case PPossibleErrorNames.IMAGE_MAX_HEIGHT :
			case PPossibleErrorNames.IMAGE_MAX_WIDTH :
			case PPossibleErrorNames.DIFFERENT_UNIT :
				if (customMsg) return customMsg;
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
			case PPossibleErrorNames.FIRST_FEE_PERIOD_START_IS_NULL :
				if (customMsg) return customMsg;
				return this.localize.transform('Der Start deiner letzten Zeitspanne sollte »UNBEGRENZT« sein.');
			case PPossibleErrorNames.FREE_PRICE_CHOICE_OR_PREDEFINED_PRICES_NOT_SET :
				if (customMsg) return customMsg;
				return this.localize.transform('Bitte entweder vordefinierte Werte angeben oder die Wahl von individuellen Werten erlauben.');
			case PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD :
				if (customMsg) return customMsg;
				return this.localize.transform('Diese Zahlungsart existiert bereits.');
			case PPossibleErrorNames.DUPLICATE_PREDEFINED_PRICES :
				if (customMsg) return customMsg;
				return this.localize.transform('Dieser Wert existiert bereits.');
			case PPossibleErrorNames.ONE_WEEK_DAY_IS_SELECTED :
				if (customMsg) return customMsg;
				return this.localize.transform('Bitte mindestens einen Wochentag auswählen.');
			case PPossibleErrorNames.OCCUPIED :
			case PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME :
			case PPossibleErrorNames.DUPLICATE_ACTIVITY_AREA_NAME :
			case PPossibleErrorNames.DUPLICATE_PERMISSION_GROUP_NAME:
				if (customMsg) return customMsg;
				return this.localize.transform('Der Name wird schon verwendet.');
			case PPossibleErrorNames.ENSURE_NULL :
				this.console.warn('ENSURE_NULL should never touch the UI');
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
			case PPossibleErrorNames.EMAIL_HAS_ERROR :
				this.console.warn('EMAIL_HAS_ERROR should never touch the UI');
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
			case PPossibleErrorNames.NULL_VALIDATOR :
				this.console.warn('NULL_VALIDATOR should never touch the UI');
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
			case PPossibleErrorNames.DUPLICATE_LABELS :
				return this.localize.transform('Bitte keine identischen, sondern nur einzigartige Labels eingeben.');
			case PPossibleErrorNames.INVALID_FILTER :
				if (customMsg) return customMsg;
				this.console.error('INVALID_FILTER should always have a custom error message');
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
			case PPossibleErrorNames.OVERLAPPING_VERSIONS :
				if (customMsg) return customMsg;
				return this.localize.transform('Die Versionen dürfen sich nicht überschneiden.');

		}
	}

	private minLengthText(error : PValidationErrorValue) : string {
		const params = this.turnIntoLocalizePipeParamsType(error);
		if (error.type === PApiType.ApiList) return this.localize.transform({sourceString: 'Mindestens ${requiredLength} Einträge nötig (aktuell nur ${actualLength} vorhanden).', params: params}, false);
		return this.localize.transform({sourceString: 'Bitte mindestens ${requiredLength} Zeichen eingeben. ${actualLength} ist zu wenig.', params: params}, false);
	}

	private floatText() : string {
		return this.localize.transform({
			sourceString: 'Bitte nur Zahlen eingeben, z.B. »${example1}«.',
			params: {
				example1: this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!,
			},
		});
	}

	private maxDecimalPlacesCountText(error : PValidationErrorValue) : string {
		if (error['maxDigitsLength'] === 0) {
			return this.localize.transform({
				sourceString: 'Bitte nur ganze Zahlen eingeben, z.B. »${example}«.',
				params: { example: this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)! },
			});
		}
		const params = this.turnIntoLocalizePipeParamsType(error);
		return this.localize.transform({sourceString: 'Bitte höchstens ${maxDigitsLength} Nachkommastellen eintragen.', params: params}, false);
	}

	private currencyText(error : PValidationErrorValue) : string {
		const example1 = this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!;
		let example2 = this.currencyPipe.transform(12.5, (error['currencyCode'] ?? Config.CURRENCY_CODE), '');
		if (example2) example2 = example2.trim();
		return this.localize.transform({
			sourceString: 'Falsches Format eingegeben. Richtig wären z.B. »${example1}« oder »${example2}«.',
			params: {
				example1: example1,
				example2: example2,
			},
		});
	}

	private whitespaceText(error : PValidationErrorValue) : string {
		const params = this.turnIntoLocalizePipeParamsType(error);
		if (error.type === PApiType.Tel) {
			return this.localize.transform({sourceString: 'Bitte keine Leerzeichen am Anfang oder Ende der Telefonnummer eingeben.', params: params}, false);
		}
		return this.localize.transform({sourceString: 'Bitte keine Leerzeichen eingeben.', params: params}, false);
	}
}
