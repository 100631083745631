/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "dr_plano_holidays_database_countries".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	COUNTRY_NAME = 1;
	COUNTRY_ISO_CODE = 2;
	COUNTRY_LANGUAGES = 3;
	COUNTRY_LANGUAGE_ISO_CODE = 1;
	COUNTRY_LANGUAGE_NAME = 2;
}


@Injectable({
  providedIn: 'root',
})
export class DrPlanoHolidaysDatabaseCountriesApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'dr_plano_holidays_database_countries');
	}

	consts = new Consts();

	protected version() : string {
		return '59f0b0e3401843bc30fd7f3e3588cd8c,4faf8bc43d97684221242f20256c0971';
	}

	private dataWrapper = new DrPlanoHolidaysDatabaseCountriesApiRoot(this, false, false);

	get data() : DrPlanoHolidaysDatabaseCountriesApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : DrPlanoHolidaysDatabaseCountriesApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new DrPlanoHolidaysDatabaseCountriesApiRoot(this, false, false);
	}
}

		 export class DrPlanoHolidaysDatabaseCountriesApiRoot extends ApiListWrapper<DrPlanoHolidaysDatabaseCountriesApiCountry>
{
	constructor(override readonly api : DrPlanoHolidaysDatabaseCountriesApiService,
		
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, null, isView, removeDestroyedItems, '');
	}

	override wrapRawData(itemRawData : any) : DrPlanoHolidaysDatabaseCountriesApiCountry {
		return new DrPlanoHolidaysDatabaseCountriesApiCountry(this.api, this as unknown as DrPlanoHolidaysDatabaseCountriesApiRoot, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ApiDataWrapperBase | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new DrPlanoHolidaysDatabaseCountriesApiRoot(this.api, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '1';
	}

	override createNewItem(_initCode : ((newItem : DrPlanoHolidaysDatabaseCountriesApiCountry) => void) | null = null, _backendId : Id | null = null) : DrPlanoHolidaysDatabaseCountriesApiCountry {
		const newItem = new DrPlanoHolidaysDatabaseCountriesApiCountry(this.api, this as unknown as DrPlanoHolidaysDatabaseCountriesApiRoot, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiRoot, DrPlanoHolidaysDatabaseCountriesApiRoot> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiRoot, DrPlanoHolidaysDatabaseCountriesApiRoot>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : DrPlanoHolidaysDatabaseCountriesApiRoot, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			
		});
}

				 
export class DrPlanoHolidaysDatabaseCountriesApiCountry extends ApiObjectWrapper<DrPlanoHolidaysDatabaseCountriesApiCountry>
{
	constructor(override readonly api : DrPlanoHolidaysDatabaseCountriesApiService,
		override readonly parent : DrPlanoHolidaysDatabaseCountriesApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<DrPlanoHolidaysDatabaseCountriesApiCountry> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, DrPlanoHolidaysDatabaseCountriesApiCountry as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountry, DrPlanoHolidaysDatabaseCountriesApiCountry> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountry, DrPlanoHolidaysDatabaseCountriesApiCountry>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiCountry,
			name: 'country',
			nodeName: 'COUNTRY',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : DrPlanoHolidaysDatabaseCountriesApiCountry, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiName : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountry, string> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountry, string>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiCountry,
			name: 'name',
			nodeName: 'COUNTRY_NAME',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiIsoCode : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountry, string> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountry, string>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiCountry,
			name: 'isoCode',
			nodeName: 'COUNTRY_ISO_CODE',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});

	/**
	 * The human readable name of the country.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[1];
	}

	set nameTestSetter(v : string) {
        this.setterImpl(1, v, 'name', true, null, null);
	}

	/**
	 * ISO 3166-1 code of the country.
	 *
	 * @type {string}
	 */
	get isoCode() : string {
		this.getterDebugValidations(this.aiIsoCode, false);
		return this.data[2];
	}

	set isoCodeTestSetter(v : string) {
        this.setterImpl(2, v, 'isoCode', true, null, null);
	}

	private languagesWrapper : DrPlanoHolidaysDatabaseCountriesApiCountryLanguages = new DrPlanoHolidaysDatabaseCountriesApiCountryLanguages(this.api,
		this as unknown as DrPlanoHolidaysDatabaseCountriesApiCountry, false, false);
	public aiLanguages = this.languagesWrapper.aiThis;

	/**
	 * A list of languages for which holiday items are available.
	 */
	get languages() : DrPlanoHolidaysDatabaseCountriesApiCountryLanguages {
		this.getterDebugValidations(this.aiLanguages, false);
		return this.languagesWrapper;
	}

	set languagesTestSetter(v : DrPlanoHolidaysDatabaseCountriesApiCountryLanguages) {
        this.setterImpl(3, v.rawData, 'languages', true, null, function(this : DrPlanoHolidaysDatabaseCountriesApiCountry) {this.languagesWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.languagesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('COUNTRY should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.languagesWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : DrPlanoHolidaysDatabaseCountriesApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class DrPlanoHolidaysDatabaseCountriesApiCountryLanguages extends ApiListWrapper<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage>
{
	constructor(override readonly api : DrPlanoHolidaysDatabaseCountriesApiService,
		override readonly parent : DrPlanoHolidaysDatabaseCountriesApiCountry | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'languages');
	}

	override wrapRawData(itemRawData : any) : DrPlanoHolidaysDatabaseCountriesApiCountryLanguage {
		return new DrPlanoHolidaysDatabaseCountriesApiCountryLanguage(this.api, this as unknown as DrPlanoHolidaysDatabaseCountriesApiCountryLanguages, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : DrPlanoHolidaysDatabaseCountriesApiCountry | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new DrPlanoHolidaysDatabaseCountriesApiCountryLanguages(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '5';
	}

	override createNewItem(_initCode : ((newItem : DrPlanoHolidaysDatabaseCountriesApiCountryLanguage) => void) | null = null, _backendId : Id | null = null) : DrPlanoHolidaysDatabaseCountriesApiCountryLanguage {
		const newItem = new DrPlanoHolidaysDatabaseCountriesApiCountryLanguage(this.api, this as unknown as DrPlanoHolidaysDatabaseCountriesApiCountryLanguages, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('languages');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguages, DrPlanoHolidaysDatabaseCountriesApiCountryLanguages> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguages, DrPlanoHolidaysDatabaseCountriesApiCountryLanguages>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiCountryLanguages,
			name: 'languages',
			nodeName: 'COUNTRY_LANGUAGES',
			type: PApiType.ApiList,
			hasPermissionToSet: () => false,
			defaultValue: function(this : DrPlanoHolidaysDatabaseCountriesApiCountryLanguages, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 3,
		});
}

				 
export class DrPlanoHolidaysDatabaseCountriesApiCountryLanguage extends ApiObjectWrapper<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage>
{
	constructor(override readonly api : DrPlanoHolidaysDatabaseCountriesApiService,
		override readonly parent : DrPlanoHolidaysDatabaseCountriesApiCountryLanguages | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, DrPlanoHolidaysDatabaseCountriesApiCountryLanguage as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage, DrPlanoHolidaysDatabaseCountriesApiCountryLanguage> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage, DrPlanoHolidaysDatabaseCountriesApiCountryLanguage>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiCountryLanguage,
			name: 'countryLanguage',
			nodeName: 'COUNTRY_LANGUAGE',
			type: PApiType.ApiObject,
			hasPermissionToSet: () => false,
			defaultValue: function(this : DrPlanoHolidaysDatabaseCountriesApiCountryLanguage, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiIsoCode : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage, string> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage, string>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiCountryLanguage,
			name: 'isoCode',
			nodeName: 'COUNTRY_LANGUAGE_ISO_CODE',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiName : ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage, string> = new ApiAttributeInfo<DrPlanoHolidaysDatabaseCountriesApiCountryLanguage, string>({
			apiObjWrapper: this as any as DrPlanoHolidaysDatabaseCountriesApiCountryLanguage,
			name: 'name',
			nodeName: 'COUNTRY_LANGUAGE_NAME',
			type: PApiType.string,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});

	/**
	 * ISO 639-1 code of the language.
	 *
	 * @type {string}
	 */
	get isoCode() : string {
		this.getterDebugValidations(this.aiIsoCode, false);
		return this.data[1];
	}

	set isoCodeTestSetter(v : string) {
        this.setterImpl(1, v, 'isoCode', true, null, null);
	}

	/**
	 * The name of the language to be shown to the user.
	 *
	 * @type {string}
	 */
	get name() : string {
		this.getterDebugValidations(this.aiName, false);
		return this.data[2];
	}

	set nameTestSetter(v : string) {
        this.setterImpl(2, v, 'name', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('COUNTRY_LANGUAGE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '6';
	}

	static async loadDetailed(	api : DrPlanoHolidaysDatabaseCountriesApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '6', { success: success, error: error, searchParams: searchParams});
	}
}



