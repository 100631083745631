/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { isColorfulTheme, PTextColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { SchedulingApiShift, SchedulingApiShiftModel } from '@plano/shared/api';
import { PBaseClass } from '@plano/shared/base';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';

@Component({
	selector: 'p-modal-header',
	templateUrl: './modal-header.component.html',
	styleUrls: ['./modal-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ModalHeaderComponent extends PBaseClass {
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public headline : string | null = null;
	@Input() public item : SchedulingApiShift | SchedulingApiShiftModel | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClose : EventEmitter<Event> = new EventEmitter<Event>();
	@Input() private theme : PThemeEnum | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hasDanger : boolean | null = null;

	constructor(
		protected modalService : ModalService,
	) {
		super();
	}

	public PTextColorEnum = PTextColorEnum;

	/**
	 * Calculate the text color
	 */
	public get textWhite() : boolean {
		return isColorfulTheme(this.theme);
	}

	/**
	 * Start of item
	 */
	public get start() : number | null {
		if (this.item === null) return null;
		if (this.item instanceof SchedulingApiShiftModel) return null;
		return this.item.start;
	}
}
