/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "export_shifts_excel".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	SHIFT_IDS = 1;
	MEMBER_IDS = 2;
	SHIFT_MODEL_IDS = 3;
}


@Injectable({
  providedIn: 'root',
})
export class ExportShiftsExcelApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_shifts_excel');
	}

	consts = new Consts();

	protected version() : string {
		return 'b855cb1de99587840a62ae769d1db6cb,ec29b92e4dee242faa00410545ec35d1';
	}

	private dataWrapper = new ExportShiftsExcelApiRoot(this);

	get data() : ExportShiftsExcelApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportShiftsExcelApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportShiftsExcelApiRoot(this);
	}
}

		 
export class ExportShiftsExcelApiRoot extends ApiObjectWrapper<ExportShiftsExcelApiRoot>
{
	constructor(override readonly api : ExportShiftsExcelApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportShiftsExcelApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportShiftsExcelApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportShiftsExcelApiRoot, ExportShiftsExcelApiRoot> = new ApiAttributeInfo<ExportShiftsExcelApiRoot, ExportShiftsExcelApiRoot>({
			apiObjWrapper: this as any as ExportShiftsExcelApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportShiftsExcelApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private shiftIdsWrapper : ExportShiftsExcelApiShiftIds = new ExportShiftsExcelApiShiftIds(this.api,
		this as unknown as ExportShiftsExcelApiRoot, false, false);
	public aiShiftIds = this.shiftIdsWrapper.aiThis;

	/**
	 * Id list of the shifts to be exported.
	 */
	get shiftIds() : ExportShiftsExcelApiShiftIds {
		this.getterDebugValidations(this.aiShiftIds, false);
		return this.shiftIdsWrapper;
	}

	set shiftIdsTestSetter(v : ExportShiftsExcelApiShiftIds) {
        this.setterImpl(1, v.rawData, 'shiftIds', true, null, function(this : ExportShiftsExcelApiRoot) {this.shiftIdsWrapper = v;});
	}

	private memberIdsWrapper : ExportShiftsExcelApiMemberIds = new ExportShiftsExcelApiMemberIds(this.api,
		this as unknown as ExportShiftsExcelApiRoot, false, false);
	public aiMemberIds = this.memberIdsWrapper.aiThis;

	/**
	 * Id list of the members to be exported.
	 */
	get memberIds() : ExportShiftsExcelApiMemberIds {
		this.getterDebugValidations(this.aiMemberIds, false);
		return this.memberIdsWrapper;
	}

	set memberIdsTestSetter(v : ExportShiftsExcelApiMemberIds) {
        this.setterImpl(2, v.rawData, 'memberIds', true, null, function(this : ExportShiftsExcelApiRoot) {this.memberIdsWrapper = v;});
	}

	private shiftModelIdsWrapper : ExportShiftsExcelApiShiftModelIds = new ExportShiftsExcelApiShiftModelIds(this.api,
		this as unknown as ExportShiftsExcelApiRoot, false, false);
	public aiShiftModelIds = this.shiftModelIdsWrapper.aiThis;

	/**
	 * Id list of the shift model to be exported.
	 */
	get shiftModelIds() : ExportShiftsExcelApiShiftModelIds {
		this.getterDebugValidations(this.aiShiftModelIds, false);
		return this.shiftModelIdsWrapper;
	}

	set shiftModelIdsTestSetter(v : ExportShiftsExcelApiShiftModelIds) {
        this.setterImpl(3, v.rawData, 'shiftModelIds', true, null, function(this : ExportShiftsExcelApiRoot) {this.shiftModelIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftIdsWrapper._fixIds(_idReplacements);
		this.memberIdsWrapper._fixIds(_idReplacements);
		this.shiftModelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.memberIdsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.shiftModelIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportShiftsExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportShiftsExcelApiShiftIds extends ApiListWrapper<ExportShiftsExcelApiShiftId>
{
	constructor(override readonly api : ExportShiftsExcelApiService,
		override readonly parent : ExportShiftsExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftIds');
	}

	override wrapRawData(itemRawData : any) : ExportShiftsExcelApiShiftId {
		return new ExportShiftsExcelApiShiftId(this.api, this as unknown as ExportShiftsExcelApiShiftIds, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ExportShiftsExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportShiftsExcelApiShiftIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : ExportShiftsExcelApiShiftId) => void) | null = null, _backendId : ShiftId | null = null) : ExportShiftsExcelApiShiftId {
		const newItem = new ExportShiftsExcelApiShiftId(this.api, this as unknown as ExportShiftsExcelApiShiftIds, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('shiftIds');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<ExportShiftsExcelApiShiftIds, ExportShiftsExcelApiShiftIds> = new ApiAttributeInfo<ExportShiftsExcelApiShiftIds, ExportShiftsExcelApiShiftIds>({
			apiObjWrapper: this as any as ExportShiftsExcelApiShiftIds,
			name: 'shiftIds',
			nodeName: 'SHIFT_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportShiftsExcelApiShiftIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
	aiShiftId : ApiAttributeInfo<ExportShiftsExcelApiShiftIds, ExportShiftsExcelApiShiftId> = new ApiAttributeInfo<ExportShiftsExcelApiShiftIds, ExportShiftsExcelApiShiftId>({
			apiObjWrapper: this as any as ExportShiftsExcelApiShiftIds,
			name: 'shiftId',
			nodeName: 'SHIFT_ID',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportShiftsExcelApiShiftIds, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class ExportShiftsExcelApiShiftId extends ApiObjectWrapper<ExportShiftsExcelApiShiftId>
{
	constructor(override readonly api : ExportShiftsExcelApiService,
		override readonly parent : ExportShiftsExcelApiShiftIds | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportShiftsExcelApiShiftId> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ExportShiftsExcelApiShiftId as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportShiftsExcelApiShiftId, ExportShiftsExcelApiShiftId> = new ApiAttributeInfo<ExportShiftsExcelApiShiftId, ExportShiftsExcelApiShiftId>({
			apiObjWrapper: this as any as ExportShiftsExcelApiShiftId,
			name: 'shiftId',
			nodeName: 'SHIFT_ID',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportShiftsExcelApiShiftId, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_ID should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '5';
	}

	static async loadDetailed(	api : ExportShiftsExcelApiService
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '5', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportShiftsExcelApiMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportShiftsExcelApiService,
		override readonly parent : ExportShiftsExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'memberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportShiftsExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportShiftsExcelApiMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '3';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportShiftsExcelApiMemberIds, ExportShiftsExcelApiMemberIds> = new ApiAttributeInfo<ExportShiftsExcelApiMemberIds, ExportShiftsExcelApiMemberIds>({
			apiObjWrapper: this as any as ExportShiftsExcelApiMemberIds,
			name: 'memberIds',
			nodeName: 'MEMBER_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportShiftsExcelApiMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 2,
		});
	aiMemberId : ApiAttributeInfo<ExportShiftsExcelApiMemberIds, Id> = new ApiAttributeInfo<ExportShiftsExcelApiMemberIds, Id>({
			apiObjWrapper: this as any as ExportShiftsExcelApiMemberIds,
			name: 'memberId',
			nodeName: 'MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 export class ExportShiftsExcelApiShiftModelIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportShiftsExcelApiService,
		override readonly parent : ExportShiftsExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftModelIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportShiftsExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportShiftsExcelApiShiftModelIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '4';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportShiftsExcelApiShiftModelIds, ExportShiftsExcelApiShiftModelIds> = new ApiAttributeInfo<ExportShiftsExcelApiShiftModelIds, ExportShiftsExcelApiShiftModelIds>({
			apiObjWrapper: this as any as ExportShiftsExcelApiShiftModelIds,
			name: 'shiftModelIds',
			nodeName: 'SHIFT_MODEL_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportShiftsExcelApiShiftModelIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 3,
		});
	aiShiftModelId : ApiAttributeInfo<ExportShiftsExcelApiShiftModelIds, Id> = new ApiAttributeInfo<ExportShiftsExcelApiShiftModelIds, Id>({
			apiObjWrapper: this as any as ExportShiftsExcelApiShiftModelIds,
			name: 'shiftModelId',
			nodeName: 'SHIFT_MODEL_ID',
			type: PApiType.Id,
			
		});
}

		

