// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- PDatePipe is allowed to make use of DatePipe internally
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Date, DateTime, PSupportedLanguageCodes, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { AngularDatePipeFormat, PDateFormat } from '@plano/shared/core/pipe/p-date.pipe.enums';
import { DateFormats } from '@plano/shared/core/pipe/p-date.pipe.types';
import moment from 'moment-timezone';

/**
 * This pipe extends the {@link DatePipe} and provides additional formats.
 */
@Pipe({
	name: 'pDate',
	standalone: true,
})
export class PDatePipe implements PipeTransform {
	constructor(
		private datePipe : DatePipe,
		private console ?: LogService | null,
		private localizePipe ?: LocalizePipe,
	) {
	}

	private getCustomAppend(
		locale : PSupportedLocaleIds,
		format ?: DateFormats,
	) : ' Uhr' | null {
		if (
			format === AngularDatePipeFormat.SHORT_TIME &&
			Config.getLanguageCode(locale) === PSupportedLanguageCodes.de
		) return ' Uhr';
		return null;
	}

	private getShortTimeFormat<Format extends DateFormats>(
		format : Format,
		locale : PSupportedLocaleIds,
	) : 'HH:mm' | Format {
		switch (locale) {
			case PSupportedLocaleIds.en_NL:
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
				return 'HH:mm';
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_SE:
			case PSupportedLocaleIds.en:
				return format;

		}
	}

	private getVeryShortTimeFormat(
		locale : PSupportedLocaleIds,
	) : 'HH:mm' | AngularDatePipeFormat.SHORT_TIME {
		switch (locale) {
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
			case PSupportedLocaleIds.en_NL:
			case PSupportedLocaleIds.en_IT:
				return 'HH:mm';
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_SE:
			case PSupportedLocaleIds.en:
				return AngularDatePipeFormat.SHORT_TIME;
		}
	}

	private getMinimalDateFormat(
		locale : PSupportedLocaleIds,
	) : 'd.M.' | 'd/M' | 'M/d' {
		switch (locale) {
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
				return 'd.M.';

			case PSupportedLocaleIds.en_NL:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_SE:
				return 'd/M';

			case PSupportedLocaleIds.en:
				return 'M/d';
		}
	}

	private getVeryShortDateFormat(
		locale : PSupportedLocaleIds,
	) : 'dd.MM.yy' | 'dd/MM/yy' | 'MM/dd/yy' {
		switch (locale) {
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
				return 'dd.MM.yy';

			case PSupportedLocaleIds.en_NL:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_SE:
				return 'dd/MM/yy';

			case PSupportedLocaleIds.en:
				return 'MM/dd/yy';
		}
	}

	private getShortFormat(
		locale : PSupportedLocaleIds,
	) : 'dd.MM.yy, HH:mm' | AngularDatePipeFormat.SHORT {
		switch (locale) {
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
			case PSupportedLocaleIds.en_NL:
				return 'dd.MM.yy, HH:mm';

			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_SE:
			case PSupportedLocaleIds.en:
				return AngularDatePipeFormat.SHORT;
		}
	}

	private getMediumTimeFormat(
		locale : PSupportedLocaleIds,
	) : 'HH:mm:ss' | AngularDatePipeFormat.MEDIUM_TIME {
		switch (locale) {
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
			case PSupportedLocaleIds.en_NL:
				return 'HH:mm:ss';

			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_SE:
			case PSupportedLocaleIds.en:
				return AngularDatePipeFormat.MEDIUM_TIME;
		}
	}

	private getShortDateFormat<Format extends DateFormats>(
		format : Format,
		locale : PSupportedLocaleIds,
	) : 'dd.MM.yyyy' | 'dd/MM/yyyy' | Format {
		switch (locale) {
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
				return 'dd.MM.yyyy';
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_NL:
				return 'dd/MM/yyyy';
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_SE:
			case PSupportedLocaleIds.en:
				return format;
		}
	}

	/**
	 * @see PDateFormat#DATE_AT_TIME
	 * @param locale The locale to be used when the value gets transformed
	 */
	private getDateAtTimeFormat(
		locale : PSupportedLocaleIds,
	) : 'EE dd.MM. \'um\' HH:mm \'Uhr\'' | 'EE dd.MM. \'at\' HH:mm' | 'EE dd/MM \'at\' HH:mm' | 'EE MM/dd \'at\' HH:mm' {
		switch (locale) {
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
				return 'EE dd.MM. \'um\' HH:mm \'Uhr\'';

			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_RO:
				return 'EE dd.MM. \'at\' HH:mm';

			case PSupportedLocaleIds.en_NL:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_SE:
				return 'EE dd/MM \'at\' HH:mm';

			case PSupportedLocaleIds.en:
				return 'EE MM/dd \'at\' HH:mm';
		}
	}

	/**
	 * @see PDateFormat#LONG_DATE
	 * @param locale The locale to be used when the value gets transformed
	 */
	private getLongDateFormat(
		locale : PSupportedLocaleIds,
	) : 'EE dd. MMMM yyyy' | 'EE MMMM dd, yyyy' {
		switch (locale) {
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.en:
				return 'EE dd. MMMM yyyy';
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_GB:
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_LU:
			case PSupportedLocaleIds.en_NL:
			case PSupportedLocaleIds.en_RO:
			case PSupportedLocaleIds.en_SE:
				return 'EE MMMM dd, yyyy';
		}
	}

	/**
	 * We support more formats than the angular date pipe (e.g. {@link PDateFormat#VERY_SHORT_DATE}). They will be
	 * processed here.
	 *
	 * @param locale The locale to be used when the value gets transformed
	 * @param format The format to transform the value into
	 */
	private turnIntoAngularFormat<Format extends DateFormats>(
		locale : PSupportedLocaleIds,
		format : Format,
	) : (
		Format |
		ReturnType<typeof this.getShortTimeFormat<Format>> |
		ReturnType<typeof this.getVeryShortTimeFormat> |
		ReturnType<typeof this.getMinimalDateFormat> |
		ReturnType<typeof this.getVeryShortDateFormat> |
		ReturnType<typeof this.getShortDateFormat<Format>> |
		ReturnType<typeof this.getShortFormat> |
		ReturnType<typeof this.getMediumTimeFormat> |
		ReturnType<typeof this.getDateAtTimeFormat> |
		ReturnType<typeof this.getLongDateFormat> |

		// TODO: PLANO-185443 Remove `| undefined`
		undefined
	) {
		switch (format) {
			case `${PDateFormat.VERY_SHORT_TIME}`:
				return this.getVeryShortTimeFormat(locale);

			case `${PDateFormat.MINIMAL_DATE}`:
				return this.getMinimalDateFormat(locale);

			case `${PDateFormat.VERY_SHORT_DATE}`:
				return this.getVeryShortDateFormat(locale);

			case `${PDateFormat.DATE_AT_TIME}`:
				return this.getDateAtTimeFormat(locale);

			case `${PDateFormat.LONG_DATE_WITH_WEEKDAY}`:
				return this.getLongDateFormat(locale);

			case `${AngularDatePipeFormat.SHORT_TIME}`:
				return this.getShortTimeFormat(format, locale);

			case `${AngularDatePipeFormat.SHORT_DATE}`:
				return this.getShortDateFormat(format, locale);

			case `${AngularDatePipeFormat.SHORT}`:
				return this.getShortFormat(locale);

			case `${AngularDatePipeFormat.MEDIUM_TIME}`:
				return this.getMediumTimeFormat(locale);

			case `${AngularDatePipeFormat.MEDIUM_DATE}`:
			case `${AngularDatePipeFormat.LONG_DATE}`:

			// TODO: PLANO-188643 Remove these types or replace with pre-defined formats.
			// eslint-disable-next-line no-fallthrough -- TODO: PLANO-188643 false-positive
			case 'EEEE':
			case 'EE':
			case 'MMMM yyyy':
			case 'MMM yyyy':
			case 'MM.YY':
			case 'dd':
			case 'ww':
			case 'EE dd.MM':
				return format;

		}
	}

	private isToday(value : DateTime | Date, exclusive : boolean) {
		const valueToCompare = exclusive ? (value - 1) : value;
		return moment(valueToCompare).isSame(new Date(), 'day');
	}

	private getTimezoneOffset(
		value : number,
		locale : PSupportedLocaleIds,
		isTimezoneNotRelevant ?: boolean,
	) : string {
		let timezoneOffset : string;
		if (isTimezoneNotRelevant) {
			timezoneOffset = '+0000';
		} else {
			const TIME_ZONE = Config.getTimeZone(locale);
			if (TIME_ZONE === null) {
				const errorMsg = 'PDatePipe: TIME_ZONE is not defined [PLANO-21080]';
				// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				if (Config.DEBUG) if (this.console) { this.console.error(errorMsg); } else { console.error(errorMsg); }
			}
			const MOMENT = TIME_ZONE === null ? moment(value) : moment(value).tz(TIME_ZONE);
			timezoneOffset = MOMENT.format('ZZ');
		}
		return timezoneOffset;
	}

	public transform(
		value ?: number,
		format ?: DateFormats,
		isTimezoneNotRelevant ?: boolean,
		locale ?: PSupportedLocaleIds,
		showTodayAsText ?: false | true | 'exclusive',
	) : string;
	public transform(
		value ?: number | null,
		format ?: DateFormats,
		isTimezoneNotRelevant ?: boolean,
		locale ?: PSupportedLocaleIds,
		showTodayAsText ?: false | true | 'exclusive',
	) : string | null;

	/**
	 * Generate human readable date from given timestamp.
	 *
	 * @param value The value to transform
	 * @param format The format to transform the value into
	 * @param isTimezoneNotRelevant Is the timezone relevant for the provided value?
	 * @param locale The locale to be used when the value gets transform
	 * @param showTodayAsText If the date is today, should it be shown as text like 'today' in UI? Set to 'exclusive'
	 * if the provided value is an exclusive date.
	 */
	public transform(
		value ?: number | null,
		format ?: DateFormats,
		isTimezoneNotRelevant ?: boolean,
		locale ?: PSupportedLocaleIds,
		showTodayAsText ?: true | 'exclusive',
	) : string | null {
		if (value === undefined) {
			return this.localizePipe?.transform('--.--.----') ?? '–';
		}

		if (value === null) return '–';

		switch (showTodayAsText) {
			case true:
			case 'exclusive':
				if (this.isToday(value, showTodayAsText === 'exclusive')) {
					return this.localizePipe!.transform('heute');
				}
				break;
			case undefined:
				break;
		}

		const LOCALE : PSupportedLocaleIds = locale ?? Config.LOCALE_ID;
		const append = this.getCustomAppend(LOCALE, format);

		const timezoneOffset = this.getTimezoneOffset(value, LOCALE, isTimezoneNotRelevant);

		const angularFormat = format === undefined ? undefined : this.turnIntoAngularFormat(LOCALE, format);

		const formattedDate = this.datePipe.transform(value, angularFormat, timezoneOffset, LOCALE);
		if (formattedDate === null) return null;

		return `${formattedDate}${append ?? ''}`;
	}
}
