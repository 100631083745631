import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * A service to handle the states of dropdowns so other code can subscribe to it.
 */
@Injectable({ providedIn: 'root' })
export class PDropdownService {
	/**
	 * Subject that will emit when a dropdown gets opened.
	 * It emits a reference of the related dropdown-content element.
	 */
	public stateOpenSubject = new Subject<ElementRef<HTMLElement>>();
}
