/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { WarningsService } from '@plano/client/shared/warnings.service';
import { SchedulingApiShiftModel, SchedulingApiWorkingTimeBase, SchedulingApiWorkingTimesBase } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { SchedulingApiMember } from './scheduling-api-member.service';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiWorkingTime extends SchedulingApiWorkingTimeBase {
	private warnings : WarningsService = new WarningsService();

	/**
	 * shorthand that returns the related model
	 */
	public get model() : SchedulingApiShiftModel {
		// NOTE: This methods exists on multiple classes:
		// TimeStampApiShift
		// SchedulingApiShift
		// SchedulingApiBooking
		// SchedulingApiTodaysShiftDescription
		// SchedulingApiWorkingTime
		const SHIFT_MODEL = this.api.data.shiftModels.get(this.shiftModelId);
		assumeNonNull(SHIFT_MODEL, 'SHIFT_MODEL');

		return SHIFT_MODEL;
	}

	/**
	 * does the item overlap with interval?
	 */
	public overlaps(min : number, max : number) : boolean {
		const intervalIsBefore = max <= this.time.start;
		const intervalIsAfter = min >= this.time.end;
		return !intervalIsBefore && !intervalIsAfter;
	}

	/**
	 * Payroll duration
	 */
	public get duration() : number {
		const totalDuration = this.time.end - this.time.start;
		let result : number;
		if (totalDuration > this.regularPauseDuration) {
			result = totalDuration - this.regularPauseDuration;
		} else {
			result = 0;
		}
		return result;
	}

	/**
	 * Partial payroll duration for workingTime
	 */
	public durationBetween(min ?: number | null, max ?: number | null) : number {
		const START = (min && min > this.time.start) ? min : this.time.start;
		const END = (max && max < this.time.end) ? max : this.time.end;
		const duration = END - START;
		if (duration < 0) return 0;

		if (duration > this.regularPauseDuration) {
			if (min && min > this.time.start) {
				return duration;
			}
			return duration - this.regularPauseDuration;
		}
		return 0;
	}

	/**
	 * Get calculated total payroll duration in hours as float
	 */
	private get totalDurationInHours() : number {
		const pMoment = new PMomentService(undefined);
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return pMoment.duration(this.duration).asHours();
	}

	/**
	 * Get calculated total earnings of a workingTime entry
	 */
	public get totalEarnings() : number {
		return this.hourlyEarnings * this.totalDurationInHours;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isExpectedWorkingTime() : boolean {
		// For expected working-times the id is an array containing the shift-selector and the member-id.
		return Array.isArray(this.id.rawData);
	}

	/**
	 * Get calculated total earnings of a workingTime entry between two timestamps
	 */
	public totalEarningsBetween(min ?: number | null, max ?: number | null) : number {
		const pMoment = new PMomentService(undefined);
		const partialDuration = this.durationBetween(min, max);
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.hourlyEarnings * pMoment.duration(partialDuration).asHours();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get member() : SchedulingApiMember | null {
		return this.api.data.members.get(this.memberId);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get warningAmount() : number {
		let result : number = 0;
		if (this.warnUnplannedWork) {
			result += 1;
		}
		if (this.warnStampedNotShiftTime) {
			result += 1;
		}
		if (this.warnStampedNotCurrentTime) {
			result += 1;
		}
		return result;
	}

	/**
	 * @see WarningsService#getWarningMessages
	 */
	public get warningMessages() : ReturnType<WarningsService['getWarningMessages']> | null {
		const result = this.warnings.getWarningMessages(this);
		if (result.length === 0) return null;
		return result;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiWorkingTimes extends SchedulingApiWorkingTimesBase {

	/**
	 * get workingTimes between two timestamps
	 * @param min - Start date in milliseconds
	 * @param max - End date in milliseconds
	 */
	public between(min : number, max : number) : SchedulingApiWorkingTimes {
		const result : SchedulingApiWorkingTimes = new SchedulingApiWorkingTimes(this.api, null, true);
		for (const workingTime of this.iterable()) {
			const isInCurrentView = min <= workingTime.time.start && max > workingTime.time.start;
			if (isInCurrentView) {
				result.push(workingTime);
			}
		}
		return result;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get memberIds() : Id[] {
		const result : Id[] = [];
		for (const workingTime of this.iterable()) {
			const searchedItem = result.find(item => item.equals(workingTime.memberId));
			if (!searchedItem) {
				result.push(workingTime.memberId);
			}
		}
		return result;
	}

	/**
	 * Get item by Member in a new ListWrapper
	 */
	public getByMember( member : SchedulingApiMember ) : this {
		return this.filterBy(item => {
			if (item.isNewItem) return false;
			if (!item.memberId.equals(member.id)) return false;
			return true;
		});
	}

	/**
	 * Sum of payroll durations
	 */
	public get duration() : number {
		let result : number = 0;
		for (const workingTime of this.iterable()) {
			result += workingTime.duration;
		}
		return result;
	}

	/**
	 * Sum of partial payroll durations
	 */
	public durationBetween(min ?: number | null, max ?: number | null) : number {
		let result : number = 0;
		for (const workingTime of this.iterable()) {
			result += workingTime.durationBetween(min, max);
		}
		return result;
	}

	/**
	 * Sum of regular pause durations
	 */
	public get regularPauseDuration() : number {
		let result : number = 0;
		for (const workingTime of this.iterable()) {
			result += workingTime.regularPauseDuration;
		}
		return result;
	}

	/**
	 * Sum of automatic pause durations
	 */
	public get automaticPauseDuration() : number {
		let result : number = 0;
		for (const workingTime of this.iterable()) {
			result += workingTime.automaticPauseDuration;
		}
		return result;
	}

	/**
	 * Get sum of total earnings of all contained workingTimes
	 */
	public get totalEarnings() : number {
		let result : number = 0;
		for (const workingTime of this.iterable()) {
			result += workingTime.totalEarnings;
		}
		return result;
	}

	/**
	 * Get sum of partial earnings of all contained workingTimes
	 */
	public totalEarningsBetween(min ?: number | null, max ?: number | null) : number {
		let result : number = 0;
		for (const workingTime of this.iterable()) {
			result += workingTime.totalEarningsBetween(min, max);
		}
		return result;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get warningAmount() : number {
		let result : number = 0;
		for (const workingTime of this.iterable()) {
			result += workingTime.warningAmount;
		}
		return result;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get commentAmount() : number {
		return this.filterBy(item => !item.isNewItem && !!item.comment && item.comment.length > 0).length;
	}
}
