import { AfterViewInit, Directive, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';
import { PSimpleChanges } from '@plano/shared/api';

/**
 * Add the aria-label based on the title, if none was set
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	selector: '[title]',
	exportAs: 'titleToAriaLabelRef',
	standalone: true,
})
export class TitleToAriaLabelDirective implements AfterViewInit, OnChanges {
	/**
	 * Receive the aria label of the element if any
	 */
	// eslint-disable-next-line no-restricted-syntax -- Intended here, as we want to highjack the html title attribute to use it inside this component
	@HostBinding('attr.title')
	@Input() public get title() : string | undefined | null {
		return this._title;
	}
	public set title(value : string | undefined | null) {
		this._title = value;
	}

	constructor(private elementRef : ElementRef<HTMLElement>) {
	}

	private _title : string | undefined | null = '';

	private hadPreviousAriaLabel : boolean = false;

	public ngAfterViewInit() : void {
		if (this.elementRef.nativeElement.getAttribute('aria-label')) {
			this.hadPreviousAriaLabel = true;
			return;
		}
		if (this.title)
			this.elementRef.nativeElement.setAttribute('aria-label', this.title);
	}

	public ngOnChanges(changes : PSimpleChanges<TitleToAriaLabelDirective>) : void {
		if (this.hadPreviousAriaLabel) return;
		if (changes.title && !changes.title.isFirstChange()) {
			this.elementRef.nativeElement.setAttribute('aria-label', changes.title.currentValue ?? '');
		}
	}
}
