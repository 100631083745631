import { Inject, Injectable, LOCALE_ID, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SCHEDULING_SERVICE_INDEXED_DB_EARLY_BIRD_MODE_KEY, SCHEDULING_SERVICE_INDEXED_DB_SHOW_DAY_AS_LIST_KEY, SCHEDULING_SERVICE_INDEXED_DB_SHOW_WEEK_AS_LIST_KEY, SCHEDULING_SERVICE_INDEXED_DB_WISH_PICKER_MODE_KEY } from '@plano/client/scheduling/scheduling.service.indexeddb.type';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { PIndexedDBService, PServiceWithIndexedDBInterface } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- already extends another class
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { CalendarModes } from './calendar-modes';
import { AbstractSchedulingApiBasedPagesService } from './scheduling-api-based-pages.service';
import { PWishesService } from './wishes.service';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingService extends AbstractSchedulingApiBasedPagesService implements PServiceWithIndexedDBInterface {
	constructor(
		protected override readonly zone : NgZone,
		protected override readonly pIndexedDBService : PIndexedDBService,
		protected override readonly console : LogService,
		protected override readonly router : Router,
		@Inject(LOCALE_ID) protected override readonly locale : PSupportedLocaleIds,
		private localizePipe ?: LocalizePipe,
		private pWishesService ?: PWishesService,
		private toastsService ?: ToastsService,
	) {
		super('calendar', zone, pIndexedDBService, console, router, locale);
		this.readIndexedDB();
		this.initValues();
	}

	private _showDayAsList : boolean | null = null;
	private _showWeekAsList : boolean | null = null;
	private _wishPickerMode : boolean | null = null;
	private _earlyBirdMode : boolean | null = null;

	/** @see AbstractSchedulingApiBasedPagesService#afterNavigationCallbacks */
	public override afterNavigationCallbacks : (() => void)[] = [];

	/**
	 * Init all necessary default values for this class
	 */
	public override initValues() : void {
		super.initValues();

		if (this._wishPickerMode === null) this._wishPickerMode = false;
		if (this._earlyBirdMode === null) this._earlyBirdMode = false;
		if (this._showDayAsList === null) this._showDayAsList = false;
		if (this._showWeekAsList === null) this._showWeekAsList = true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showDayAsList() : boolean {
		// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
		if (Config.IS_MOBILE || this._showDayAsList === null || this.urlParam.calendarMode === CalendarModes.MONTH) {
			return true;
		}
		return this._showDayAsList;
	}
	public set showDayAsList(value : boolean) {
		this.pIndexedDBService.set(SCHEDULING_SERVICE_INDEXED_DB_SHOW_DAY_AS_LIST_KEY, value);
		this._showDayAsList = value;
		this.changed(undefined);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get showWeekAsList() : boolean {
		// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
		if (Config.IS_MOBILE || this._showWeekAsList === null || this.urlParam.calendarMode === CalendarModes.MONTH) {
			return true;
		}
		return this._showWeekAsList;
	}
	public set showWeekAsList(value : boolean) {
		this.pIndexedDBService.set(SCHEDULING_SERVICE_INDEXED_DB_SHOW_WEEK_AS_LIST_KEY, value);
		this._showWeekAsList = value;
		this.changed(undefined);
	}

	private triggerThankYouMessage() : void {
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.localizePipe);
		let msg : string = this.localizePipe.transform('Danke für deine Schichtwünsche.');
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.pWishesService);
		if (this.pWishesService.freeWishesCount) {
			msg += `<br>${this.pWishesService.freeWishesCount}`;
			if (this.pWishesService.freeWishesCount === 1) {
				msg += ` ${this.localizePipe.transform('Schichtwunsch ist noch offen.')}`;
			} else if (this.pWishesService.freeWishesCount > 1) {
				msg += ` ${this.localizePipe.transform('Schichtwünsche sind noch offen.')}`;
			}
		}

		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.toastsService);
		this.toastsService.addToast({
			content: msg,
			theme: this.pWishesService.freeWishesCount ? enumsObject.PThemeEnum.INFO : enumsObject.PThemeEnum.SUCCESS,
			visibleOnMobile: true,
		});
	}

	/**
	 * Is currently the wish-picking mode started?
	 */
	public get wishPickerMode() : boolean {
		return this._wishPickerMode!;
	}
	public set wishPickerMode(value : boolean) {
		// NOTE: don’t write wishPickerMode to IndexedDB. https://drplano.atlassian.net/browse/PLANO-7903
		this._earlyBirdMode = false;
		this._wishPickerMode = value;
		this.changed(undefined);
		if (!value) this.triggerThankYouMessage();
	}

	/**
	 * Is currently the early-bird picking mode started?
	 */
	public get earlyBirdMode() : boolean {
		return this._earlyBirdMode!;
	}
	public set earlyBirdMode(value : boolean) {
		// NOTE: don’t write earlyBirdMode to IndexedDB. https://drplano.atlassian.net/browse/PLANO-7903
		this._wishPickerMode = false;
		this._earlyBirdMode = value;
		this.changed(undefined);
	}

	/**
	 * Read values from indexedDB if available
	 */
	public override readIndexedDB() : void {
		super.readIndexedDB();

		if (this.pIndexedDBService.has(SCHEDULING_SERVICE_INDEXED_DB_SHOW_WEEK_AS_LIST_KEY)) {
			this.showWeekAsList = (this.pIndexedDBService.get(SCHEDULING_SERVICE_INDEXED_DB_SHOW_WEEK_AS_LIST_KEY)) === 'true';
		}
		if (this.pIndexedDBService.has(SCHEDULING_SERVICE_INDEXED_DB_SHOW_DAY_AS_LIST_KEY)) {
			this.showDayAsList = (this.pIndexedDBService.get(SCHEDULING_SERVICE_INDEXED_DB_SHOW_DAY_AS_LIST_KEY)) === 'true';
		}

		// NOTE: Don’t write wishPickerMode to IndexedDB. https://drplano.atlassian.net/browse/PLANO-7903
		if (this.pIndexedDBService.has(SCHEDULING_SERVICE_INDEXED_DB_WISH_PICKER_MODE_KEY)) {
			this.wishPickerMode = (this.pIndexedDBService.get(SCHEDULING_SERVICE_INDEXED_DB_WISH_PICKER_MODE_KEY)) === 'true';
		}

		// NOTE: Don’t write earlyBirdMode to IndexedDB. https://drplano.atlassian.net/browse/PLANO-7903
		if (this.pIndexedDBService.has(SCHEDULING_SERVICE_INDEXED_DB_EARLY_BIRD_MODE_KEY)) {
			this.earlyBirdMode = (this.pIndexedDBService.get(SCHEDULING_SERVICE_INDEXED_DB_EARLY_BIRD_MODE_KEY)) === 'true';
		}
	}

	/** @see PServiceInterface#unload */
	public override unload() : void {
		super.unload();
		this._showDayAsList = null;
		this._showWeekAsList = null;
		this._wishPickerMode = null;
		this._earlyBirdMode = null;
	}
}
