import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { PBaseClass } from '@plano/shared/base';

/**
 * Add the class.disabled to elements that have the disabled property,
 * we don't do it on p-button as the button inside is already disabled,
 * otherwise other interactive elements inside the p-button would not be
 * testable.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	selector: ':not(p-button)[disabled]',
	standalone: true,
})
export class DisabledDirective extends PBaseClass {
	@HostBinding('attr.aria-disabled')
	@HostBinding('disabled')
	@HostBinding('class.disabled')
	@Input() protected disabled : boolean | null = null;

	constructor(elementRef : ElementRef<HTMLElement>) {
		super();
		const htmlElement = elementRef.nativeElement;
		if (htmlElement.classList.contains('disabled')) {
			throw new Error("DON'T PASS THE CLASS.DISABLED DIRECTLY! use disabled instead");
		}
	}
}
