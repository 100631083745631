@if (subHeadline) {
	<label class="d-flex gap-2 mt-4 text-muted">
		@if (subHeadlineIcon ?? headlineIcon) {
			<fa-icon [icon]="subHeadlineIcon ?? headlineIcon" />
		}
		{{ subHeadline }}
	</label>
}

<p-headline
	headlineClasses="d-flex align-items-baseline col-gap-3"
	[level]="headlineLevel"
><fa-icon *ngIf="headlineIcon !== null && !subHeadline" [icon]="headlineIcon" />{{headline ? headline : ''}}<div #contentWrapper><ng-content /></div></p-headline>
