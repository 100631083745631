/**
 * A regex to match padding classes in bootstrap like p-2, px-3, pt-5, px-auto, etc.
 */
// cspell:ignore setbxy
export const BOOTSTRAP_PADDING_CLASSES_REGEX = /\bp[setbxy]?-([0-5]|auto)/gu;

/**
 * A regex to match margin classes in bootstrap like m-2, mx-3, mt-5, mx-auto, etc.
 */
// cspell:ignore setbxy
export const BOOTSTRAP_MARGIN_CLASSES_REGEX = /\bm[setbxy]?-([0-5]|auto)/gu;

/**
 * A regex to match bootstrap utility classes that change the horizontal margin like m-2, mx-3, mx-auto, etc.
 */
// cspell:ignore setbxy
export const BOOTSTRAP_HORIZONTAL_MARGIN_CLASSES_REGEX = /\bm[sex]?-([0-5]|auto)/gu;

/**
 * A regex to match css selectors of an id.
 * This will not match selectors that are just starting with an id like `#foo .bar`.
 */
export const HTML_ID_SELECTOR_REGEX = /^#[\w-]*$/iu;
