import { TemplateRef } from '@angular/core';
import { Params } from '@angular/router';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PDropdownItemComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown-item/p-dropdown-item.component';

/**
 * Type for the navigation items in the menu.
 */
type MenuNavigationItem<PrependItem> = {
	label : PDictionarySourceString,
	prependedItem : PrependItem,
	anchorRouterLink : `/${string}`,
	anchorQueryParams ?: Params
	anchorShowCondition ?: () => boolean
};

/**
 * Type for the navigation items in the menu.
 */
export type MenuNavigationItems<PrependItem = Exclude<PDropdownItemComponent['prependedItem'], TemplateRef<unknown>>> = {
	groupLabel : PDictionarySourceString | null,
	items : MenuNavigationItem<PrependItem>[]
}[];

/**
 * Order on which the navigation items should be rendered
 */
export const menuNavigationItemsOrder : {label : PDictionarySourceString, showMemberBadge ?: boolean, icon ?: PlanoFaIconPoolKeys} [] = [
	{label: 'Mein Profil', showMemberBadge: true},
	{label: 'Benachrichtigungs-Einstellungen', icon: enumsObject.PlanoFaIconContextPool.NOTIFICATION_SETTINGS},
	{label: 'Kalender-Synchronisation', icon: enumsObject.PlanoFaIconContextPool.SYNCING},
	{label: 'Account & Standort', icon: enumsObject.PlanoFaIconContextPool.HOUSE},
	{label: 'Feiertage & Schulferien', icon: enumsObject.PlanoFaIconContextPool.HOLIDAYS},
	{label: 'Arbeitsmodelle für User', icon: enumsObject.PlanoFaIconContextPool.WORK_MODELS},
	{label: 'Rechteverwaltung', icon: enumsObject.PlanoFaIconContextPool.PERMISSIONS},
	{label: 'Stempeluhr-Einstellungen', icon: enumsObject.PlanoFaIconContextPool.AREA_TIME_STAMP},
	{label: 'Buchungssystem-Einstellungen', icon: enumsObject.PlanoFaIconContextPool.ITEMS_SALES},
	{label: 'Abmelden', icon: enumsObject.PlanoFaIconContextPool.LOGOUT},
];

/**
 * Sort a given list according to the menuNavigationItemsOrder array
 * Note that the groups will not be sorted
 *
 * @param input List to be sorted
 */
export const sortMenuItems =
	(input : MenuNavigationItems) : void => {
		const labels = menuNavigationItemsOrder.map(item => item.label);
		for (const group of input) {
			const listOfMenuItems = group.items;
			listOfMenuItems.sort((itemA, itemB) => {
				return labels.indexOf(itemA.label) - labels.indexOf(itemB.label);
			});
			for (const [index, element] of listOfMenuItems.entries()) {
				const indexOfElement = labels.indexOf(element.label);
				const elementIcon = menuNavigationItemsOrder.at(indexOfElement)!.icon;
				if (elementIcon) {
					listOfMenuItems[index] = {...element, prependedItem: elementIcon};
				} else if (!menuNavigationItemsOrder.at(indexOfElement)!.showMemberBadge) {
					listOfMenuItems[index] = {...element, prependedItem: 'fa-question fa-duotone'};
				}
			}
		}
	};
