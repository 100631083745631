/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { Component, Input, TemplateRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { PBaseClass } from '@plano/shared/base';

@Component({
	selector: 'p-progress',
	templateUrl: './p-progress.component.html',
	styleUrls: ['./p-progress.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PProgressComponent extends PBaseClass {

	/**
	 * Is the progress bar striped?
	 */
	@Input() protected striped : boolean = false;

	/**
	 * Amount that the inner amount will change, useful
	 * to show the user changes in the progress bar
	 */
	@Input() protected set changeAmount(newChangeAmount : number) {
		this.innerChangeAmount = 0;

		// this timeout must match the one in the transition
		// NOTE: I reduced it a little bit in comparison to the transition to make it smoother
		window.setTimeout(() => {
			this.innerChangeAmount = newChangeAmount;
		}, 180);
	}

	/**
	 * Template with text to show at the start of the progress bar
	 */
	@Input() protected startTemplate : TemplateRef<unknown> | null = null;

	/**
	 * Template with text to show at the end of the progress bar
	 */
	@Input() protected endTemplate : TemplateRef<unknown> | null = null;

	/**
	 * Current value of the progress bar
	 */
	@Input() protected value : number = 0;

	/**
	 *  Max value of the progress bar
	 */
	@Input() protected maxValue : number = 100;

	/**
	 * Theme of the progress bar
	 */
	@Input() protected theme : PThemeEnum = this.enums.PThemeEnum.PRIMARY;

	/**
	 * With of the element responsible for showing the increase of the value
	 */
	public get increaseProgressBarWidth() : number {
		if (this.innerChangeAmount <= 0) return 0;
		return (this.innerChangeAmount / this.maxValue);
	}

	public innerChangeAmount : number = 0;

	/**
	 * With of the element responsible for showing the decrease of the value
	 */
	public get decreaseProgressBarWidth() : number {
		if (this.innerChangeAmount >= 0) return 0;
		return (-this.innerChangeAmount / this.maxValue);
	}
}
