import { Directive, HostBinding, Input } from '@angular/core';

/**
 * Auto focus on the input inside a modal only if it is visible.
 * Also prevent focus on mobile devices.
 */
@Directive({
	selector: '[pAutoFocus]',
	standalone: true,
})
export class PAutoFocusInsideModalDirective {
  @HostBinding('class.p-auto-focus')
	protected readonly _alwaysTrue = true;

	/**
	 * If the element should be the one to be selected even if it isn't the
	 * first focusable element inside the modal
	 */
	@HostBinding('class.priority-auto-focus')
	@Input() public hasAutoFocusPriority = false;
}
