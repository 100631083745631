/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { ApplicationRef, Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
import { BootstrapSize, BootstrapSizePool } from '@plano/client/shared/bootstrap.utils';

/**
 * Get the information about the width of the window without relying on Config.IS_MOBILE,
 * the value will be available without the need to recheck the window size every time,
 * it will automatically update the stored width on resize of the window.
 */
@Injectable({
	providedIn: 'root',
})
export class PWindowSizeService {
	constructor(
		private applicationRef : ApplicationRef,
	) {
		this._windowWidth = window.innerWidth;
		this.addResizeObserverToBody();
	}

	private _windowWidth : number;

	/**
	 * The width of the window.
	 */
	public get windowWidth() : number {
		return this._windowWidth;
	}

	private addResizeObserverToBody() : void {
		const resizeObserver = new ResizeObserver(() => {
			this._windowWidth = window.innerWidth;
			this.applicationRef.tick();
		});
		resizeObserver.observe(document.body);
	}

	private getBreakpointLowerLimit(breakpoint : BootstrapSize) : number {
		/*
		 * NOTE: If we change these values for some reason, it probably means we also need to change the values in
		 * $grid-breakpoints and vice-versa.
		 */
		switch (breakpoint) {
			case BootstrapSizePool.XXL: return 1400;
			case BootstrapSizePool.XL: return 1200;
			case BootstrapSizePool.LG: return 992;
			case BootstrapSizePool.MD: return 768;
			case BootstrapSizePool.SM: return 576;
			case BootstrapSizePool.XS: return 0;
		}
	}

	/**
	 * Check if the window is below a certain bootstrap breakpoint.
	 * If possible use a scss only solution instead of this function.
	 * @param breakpoint The breakpoint to check against
	 */
	public windowWidthIsBelowBreakpoint(breakpoint : BootstrapSize) : boolean {
		return this._windowWidth < this.getBreakpointLowerLimit(breakpoint);
	}

	/**
	 * Check if the window width is above or equal to a certain bootstrap breakpoint
	 * If possible use a scss only solution instead of this function.
	 * @param breakpoint The breakpoint to check against
	 */
	public windowWidthIsEqualOrAboveBreakpoint(breakpoint : BootstrapSize) : boolean {
		return this._windowWidth >= this.getBreakpointLowerLimit(breakpoint);
	}

	/**
	 * Check if the window width is below a certain screen size.
	 * @param screenSize The screen size to check against
	 */
	public windowWidthIsBelowScreenSize(screenSize : number) : boolean {
		return this._windowWidth <= screenSize;
	}

}
