/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "export_members_excel".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	MEMBER_IDS = 1;
}


@Injectable({
  providedIn: 'root',
})
export class ExportMembersExcelApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_members_excel');
	}

	consts = new Consts();

	protected version() : string {
		return 'd6f80d754bb1613c558cf697551968e9,4504a63decf32749c687d99b93b66bc2';
	}

	private dataWrapper = new ExportMembersExcelApiRoot(this);

	get data() : ExportMembersExcelApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportMembersExcelApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportMembersExcelApiRoot(this);
	}
}

		 
export class ExportMembersExcelApiRoot extends ApiObjectWrapper<ExportMembersExcelApiRoot>
{
	constructor(override readonly api : ExportMembersExcelApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportMembersExcelApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportMembersExcelApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportMembersExcelApiRoot, ExportMembersExcelApiRoot> = new ApiAttributeInfo<ExportMembersExcelApiRoot, ExportMembersExcelApiRoot>({
			apiObjWrapper: this as any as ExportMembersExcelApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : ExportMembersExcelApiRoot) {
				return ((this.api.pPermissionsService.hasManagerPermission));
			},
			hasPermissionToSet: function(this : ExportMembersExcelApiRoot) {
				{const conditionValue = this.api.pPermissionsService.hasManagerPermission; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : ExportMembersExcelApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private memberIdsWrapper : ExportMembersExcelApiMemberIds = new ExportMembersExcelApiMemberIds(this.api,
		this as unknown as ExportMembersExcelApiRoot, false, false);
	public aiMemberIds = this.memberIdsWrapper.aiThis;

	/**
	 * Id list of the members to be exported.
	 */
	get memberIds() : ExportMembersExcelApiMemberIds {
		this.getterDebugValidations(this.aiMemberIds, false);
		return this.memberIdsWrapper;
	}

	set memberIdsTestSetter(v : ExportMembersExcelApiMemberIds) {
        this.setterImpl(1, v.rawData, 'memberIds', true, null, function(this : ExportMembersExcelApiRoot) {this.memberIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.memberIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.memberIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportMembersExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportMembersExcelApiMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportMembersExcelApiService,
		override readonly parent : ExportMembersExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'memberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportMembersExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportMembersExcelApiMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportMembersExcelApiMemberIds, ExportMembersExcelApiMemberIds> = new ApiAttributeInfo<ExportMembersExcelApiMemberIds, ExportMembersExcelApiMemberIds>({
			apiObjWrapper: this as any as ExportMembersExcelApiMemberIds,
			name: 'memberIds',
			nodeName: 'MEMBER_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportMembersExcelApiMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 1,
		});
	aiMemberId : ApiAttributeInfo<ExportMembersExcelApiMemberIds, Id> = new ApiAttributeInfo<ExportMembersExcelApiMemberIds, Id>({
			apiObjWrapper: this as any as ExportMembersExcelApiMemberIds,
			name: 'memberId',
			nodeName: 'MEMBER_ID',
			type: PApiType.Id,
			
		});
}

		

