/* eslint-disable unicorn/prevent-abbreviations -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Config } from '@plano/shared/core/config';
import { drPlanoTeam } from '@plano/shared/core/dr-plano-team.const';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { SafeHtmlPipe } from '@plano/shared/core/pipe/safe-html.pipe';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { Trim } from '@plano/shared/core/utils/typescript-utils-types';

type DrPlanoTeamMemberFirstName = (typeof drPlanoTeam)[number]['firstName'];

/**
 * All possible mail prefixes for the members of the Dr. Plano team.
 */
type DrPlanoTeamMailPrefixes = Lowercase<Trim<DrPlanoTeamMemberFirstName>>;

/**
 * The possible mail prefixes of Dr. Plano mail addresses.
 */
export type DrPlanoMailPrefixes = DrPlanoTeamMailPrefixes | 'info' | 'service';

/**
 * This component let you show a dr-plano email. It is save against spam crawlers.
 */
@Component({
	selector: 'p-you-know-what',
	templateUrl: './you-know-what.component.html',
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ PCommonModule, SafeHtmlPipe ],
})
export class YouKnowWhatComponent {

	/**
	 * The user name of the email e.g. "john.doe". The produced
	 * email will then be "john.doe@dr-plano.com".
	 */
	@Input({required: true}) private user ! : DrPlanoMailPrefixes;

	/**
	 * The subject of the email.
	 */
	@Input() private topic : string | null = null;

	/**
	 * The text inside the a tag, that the user should click on.
	 * If not set, the email address will be shown.
	 */
	@Input() private content : string | null = null;

	/**
	 * Css classes for the a tag.
	 */
	@Input() private linkClass : string = '';

	/**
	 * A appended icon.
	 */
	@Input() private icon : PlanoFaIconPoolKeys | null = null;

	/**
	 * Open email client with a new email draft
	 */
	@HostListener('click') protected onClick() : void {
		const s = this.topic ? `?subject=${this.topic}` : '';

		const link = `mailto:${this.user}@dr-plano.com${s}`;

		// let app handle this?
		if (Config.platform === 'appAndroid' || Config.platform === 'appIOS') {
			window.nsWebViewInterface.emit('externalLink', link);
		} else {
			// otherwise just open
			window.location.href = link;
		}
	}

	constructor(
		private domSanitizer : DomSanitizer,
	) {}

	private get iconCode() : string | null {
		if (this.icon) {
			return `<span class="ms-1 fa-fw ${this.icon}"></span>`;
		}
		return null;
	}

	private get contentCode() : string {
		if (this.content) {
			return this.content;
		} else {
			const e2 = 'dr-plano';
			const e3 = 'com';
			return `${this.user}<span>&#64;</span>${e2}&#46;${e3}`; // NOTE: No i18n
		}
	}

	/**
	 * This makes it difficult for parsers to get our email address.
	 * This way it makes the email not crawlable, but also decreases our SEO.
	 */
	public get getATagCode() : string {
		// This is the text shown on the website. By default the email address is shown.
		const e = this.contentCode;

		// This is the icon appended to the text.
		const i = this.iconCode ?? '';

		const a1 = `<a class="nowrap ${this.linkClass}">`;
		const a2 = '</a>';

		return a1 + e + i + a2;
	}
}
