<p-callout
	[theme]="enums.PThemeEnum.WARNING"
	class="mb-0"
>
	<ng-container *ngIf="pPricesService.currencyIsSupportedForOnlinePayment === false && Config.getCountryCode() !== null; else noCountry"><span i18n>Für das Land {{Config.getCountryCode() | pTranslateEnum}} wird die Online-Zahlung noch nicht unterstützt.</span>&ngsp;<p-you-know-what
		[user]="'service'"
		[topic]="supportForMyCountryEmailSubject"
		content="Jetzt Support anfragen" i18n-content
		[icon]="enums.PlanoFaIconContextPool.NAV_FORWARD"
	/></ng-container>
	<ng-template #noCountry><span i18n>Bitte wähle erst ein Land aus, damit die für dich zutreffenden Informationen angezeigt werden.</span></ng-template>
</p-callout>
