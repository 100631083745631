<span [pTooltipOnOverflow]="true">
	<fa-icon icon="fa-person-digging fa-regular" class="me-1" />
	@if (hasNgContent) {
		<span>Coming soon:&ngsp;</span>
	} @else {
		<span i18n>Coming soon …</span>
	}

	<span *ngIf="hasNgContent">»</span>
	<span #ngContent [class.d-none]="!hasNgContent"><ng-content /></span>
	<span *ngIf="hasNgContent">«</span>

	@if (Config.DEBUG) {
		<span> | </span>
		<a
			externalLink="https://drplano.atlassian.net/browse/{{jira}}"
			target="_blank"
			rel="noopener"
		>
			<span>TODO: {{jira}}</span>
			<fa-icon [icon]="enums.PlanoFaIconContextPool.EXTERNAL_LINK" class="ms-1" />
		</a>
	}
</span>
