/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { NonUndefined } from '@plano/shared/core/utils/null-type-utils';

/**
 * A directive that can be used to open a modal.
 *
 * @example
 * 			<div
 * 				pModal
 * 				[modalContent]="modalContentRef"
 * 				[modalContentOptions]="modalContentOptions"
 * 				[modalServiceOptions]="modalServiceOptions"
 * 			>Or click here</div>
 * 			<ng-template
 * 				#modalContentRef="pModalTemplateExportAsRef"
 * 				pModalTemplate
 * 				pModalId="my-modal"
 * 				let-c="close"
 * 				let-d="dismiss"
 * 			>
 * 				<p-modal-content
 * 					(onDismiss)="d($event)"
 * 					(onClose)="c($event)"
 * 				>…</p-modal-content>
 * 			</ng-template>
 */
@Directive({
	selector: '[pModal]',
})
export class ModalDirective {
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input({required: true}) private modalContent ! : PModalTemplateDirective;

	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private modalSize : NonUndefined<ModalServiceOptions['size']> | null = null;
	@Input() private disabled : boolean | null = null;

	/**
	 * Triggers when modal gets opened
	 */
	@Output() private onModalOpen = new EventEmitter<Event>();

	@HostListener('click', ['$event']) private onClick(event : Event) : void {
		if (this.disabled) return;
		this.openModal(event);
	}

	constructor(
		private modalService : ModalService,
	) {
	}

	private openModal(event : Event) : void {
		this.onModalOpen.emit(event);
		this.modalService.openModal(this.modalContent, this.modalSize === null ? {} : { size: this.modalSize });
	}
}
