import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { PBackgroundColor } from '@plano/client/shared/bootstrap.utils';
import { PBaseClass } from '@plano/shared/base';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { PModalContentWrapperDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';

/**
 * Component to serve as base for components that should be openable as a modal using the modal service.
 */
@Component({
	template: '',
	standalone: true,
	imports: [
		PCommonModule,
	],
})
export abstract class ModalContentComponent extends PBaseClass implements AfterViewInit {

	@ViewChild(PModalContentWrapperDirective) public modalContentWrapper : PModalContentWrapperDirective | null = null;

	// if the inject(null) gets removed we get an error that the arguments are not injectable tokens
	// we use null as we don't know what will be injected onto the constructor
	constructor(@Inject(null) ..._arg0 : unknown []) {
		super();
	}
	public abstract initModal(...arg0 : unknown []) : void;
	public abstract theme : PBackgroundColor | null;

	/**
	 * When the modal with this content opens, the index of the modal in the modal stack is stored here.
	 * If the modal is not opened, this is null.
	 */
	public openModalStackIndex : number | null = null;

	public ngAfterViewInit() : void {
		if (this.modalContentWrapper) {
			this.modalContentWrapper.openModalStackIndex = this.openModalStackIndex;
		}
	}
}
