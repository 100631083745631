/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "export_reporting_excel".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	WORKING_TIME_IDS = 1;
	LEAVE_DAY_IDS = 2;
	MEMBER_IDS = 3;
	SHIFT_MODEL_IDS = 4;
}


@Injectable({
  providedIn: 'root',
})
export class ExportReportingExcelApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_reporting_excel');
	}

	consts = new Consts();

	protected version() : string {
		return '36531c10d30ee30a6e2e0628b49fe93f,ec60d7ca246508922c55cde467730df1';
	}

	private dataWrapper = new ExportReportingExcelApiRoot(this);

	get data() : ExportReportingExcelApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportReportingExcelApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportReportingExcelApiRoot(this);
	}
}

		 
export class ExportReportingExcelApiRoot extends ApiObjectWrapper<ExportReportingExcelApiRoot>
{
	constructor(override readonly api : ExportReportingExcelApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportReportingExcelApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportReportingExcelApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportReportingExcelApiRoot, ExportReportingExcelApiRoot> = new ApiAttributeInfo<ExportReportingExcelApiRoot, ExportReportingExcelApiRoot>({
			apiObjWrapper: this as any as ExportReportingExcelApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportReportingExcelApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private workingTimeIdsWrapper : ExportReportingExcelApiWorkingTimeIds = new ExportReportingExcelApiWorkingTimeIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false, false);
	public aiWorkingTimeIds = this.workingTimeIdsWrapper.aiThis;

	/**
	 * Id list of the working-times to be exported.
	 */
	get workingTimeIds() : ExportReportingExcelApiWorkingTimeIds {
		this.getterDebugValidations(this.aiWorkingTimeIds, false);
		return this.workingTimeIdsWrapper;
	}

	set workingTimeIdsTestSetter(v : ExportReportingExcelApiWorkingTimeIds) {
        this.setterImpl(1, v.rawData, 'workingTimeIds', true, null, function(this : ExportReportingExcelApiRoot) {this.workingTimeIdsWrapper = v;});
	}

	private leaveDayIdsWrapper : ExportReportingExcelApiLeaveDayIds = new ExportReportingExcelApiLeaveDayIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false, false);
	public aiLeaveDayIds = this.leaveDayIdsWrapper.aiThis;

	/**
	 * Id list of the leave days to be exported. This should be the filtered list of leave-days (applying time-range and all other reporting filters), to not duplicate the filtering logic both in frontend and backend.
	 */
	get leaveDayIds() : ExportReportingExcelApiLeaveDayIds {
		this.getterDebugValidations(this.aiLeaveDayIds, false);
		return this.leaveDayIdsWrapper;
	}

	set leaveDayIdsTestSetter(v : ExportReportingExcelApiLeaveDayIds) {
        this.setterImpl(2, v.rawData, 'leaveDayIds', true, null, function(this : ExportReportingExcelApiRoot) {this.leaveDayIdsWrapper = v;});
	}

	private memberIdsWrapper : ExportReportingExcelApiMemberIds = new ExportReportingExcelApiMemberIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false, false);
	public aiMemberIds = this.memberIdsWrapper.aiThis;

	/**
	 * Id list of the members to be exported.
	 */
	get memberIds() : ExportReportingExcelApiMemberIds {
		this.getterDebugValidations(this.aiMemberIds, false);
		return this.memberIdsWrapper;
	}

	set memberIdsTestSetter(v : ExportReportingExcelApiMemberIds) {
        this.setterImpl(3, v.rawData, 'memberIds', true, null, function(this : ExportReportingExcelApiRoot) {this.memberIdsWrapper = v;});
	}

	private shiftModelIdsWrapper : ExportReportingExcelApiShiftModelIds = new ExportReportingExcelApiShiftModelIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false, false);
	public aiShiftModelIds = this.shiftModelIdsWrapper.aiThis;

	/**
	 * Id list of the shift model to be exported.
	 */
	get shiftModelIds() : ExportReportingExcelApiShiftModelIds {
		this.getterDebugValidations(this.aiShiftModelIds, false);
		return this.shiftModelIdsWrapper;
	}

	set shiftModelIdsTestSetter(v : ExportReportingExcelApiShiftModelIds) {
        this.setterImpl(4, v.rawData, 'shiftModelIds', true, null, function(this : ExportReportingExcelApiRoot) {this.shiftModelIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.workingTimeIdsWrapper._fixIds(_idReplacements);
		this.leaveDayIdsWrapper._fixIds(_idReplacements);
		this.memberIdsWrapper._fixIds(_idReplacements);
		this.shiftModelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.workingTimeIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.leaveDayIdsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.memberIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		this.shiftModelIdsWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportReportingExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportReportingExcelApiWorkingTimeIds extends ApiListWrapper<ExportReportingExcelApiWorkingTimeId>
{
	constructor(override readonly api : ExportReportingExcelApiService,
		override readonly parent : ExportReportingExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'workingTimeIds');
	}

	override wrapRawData(itemRawData : any) : ExportReportingExcelApiWorkingTimeId {
		return new ExportReportingExcelApiWorkingTimeId(this.api, this as unknown as ExportReportingExcelApiWorkingTimeIds, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiWorkingTimeIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : ExportReportingExcelApiWorkingTimeId) => void) | null = null, _backendId : Id | null = null) : ExportReportingExcelApiWorkingTimeId {
		const newItem = new ExportReportingExcelApiWorkingTimeId(this.api, this as unknown as ExportReportingExcelApiWorkingTimeIds, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('workingTimeIds');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeIds> = new ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiWorkingTimeIds,
			name: 'workingTimeIds',
			nodeName: 'WORKING_TIME_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportReportingExcelApiWorkingTimeIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
	aiWorkingTimeId : ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeId> = new ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeId>({
			apiObjWrapper: this as any as ExportReportingExcelApiWorkingTimeIds,
			name: 'workingTimeId',
			nodeName: 'WORKING_TIME_ID',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportReportingExcelApiWorkingTimeIds, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class ExportReportingExcelApiWorkingTimeId extends ApiObjectWrapper<ExportReportingExcelApiWorkingTimeId>
{
	constructor(override readonly api : ExportReportingExcelApiService,
		override readonly parent : ExportReportingExcelApiWorkingTimeIds | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportReportingExcelApiWorkingTimeId> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ExportReportingExcelApiWorkingTimeId as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportReportingExcelApiWorkingTimeId, ExportReportingExcelApiWorkingTimeId> = new ApiAttributeInfo<ExportReportingExcelApiWorkingTimeId, ExportReportingExcelApiWorkingTimeId>({
			apiObjWrapper: this as any as ExportReportingExcelApiWorkingTimeId,
			name: 'workingTimeId',
			nodeName: 'WORKING_TIME_ID',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportReportingExcelApiWorkingTimeId, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORKING_TIME_ID should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '6';
	}

	static async loadDetailed(	api : ExportReportingExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '6', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportReportingExcelApiLeaveDayIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportReportingExcelApiService,
		override readonly parent : ExportReportingExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'leaveDayIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiLeaveDayIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '3';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportReportingExcelApiLeaveDayIds, ExportReportingExcelApiLeaveDayIds> = new ApiAttributeInfo<ExportReportingExcelApiLeaveDayIds, ExportReportingExcelApiLeaveDayIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiLeaveDayIds,
			name: 'leaveDayIds',
			nodeName: 'LEAVE_DAY_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportReportingExcelApiLeaveDayIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 2,
		});
	aiLeaveDayId : ApiAttributeInfo<ExportReportingExcelApiLeaveDayIds, Id> = new ApiAttributeInfo<ExportReportingExcelApiLeaveDayIds, Id>({
			apiObjWrapper: this as any as ExportReportingExcelApiLeaveDayIds,
			name: 'leaveDayId',
			nodeName: 'LEAVE_DAY_ID',
			type: PApiType.Id,
			
		});
}

				 export class ExportReportingExcelApiMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportReportingExcelApiService,
		override readonly parent : ExportReportingExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'memberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '4';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportReportingExcelApiMemberIds, ExportReportingExcelApiMemberIds> = new ApiAttributeInfo<ExportReportingExcelApiMemberIds, ExportReportingExcelApiMemberIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiMemberIds,
			name: 'memberIds',
			nodeName: 'MEMBER_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportReportingExcelApiMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 3,
		});
	aiMemberId : ApiAttributeInfo<ExportReportingExcelApiMemberIds, Id> = new ApiAttributeInfo<ExportReportingExcelApiMemberIds, Id>({
			apiObjWrapper: this as any as ExportReportingExcelApiMemberIds,
			name: 'memberId',
			nodeName: 'MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 export class ExportReportingExcelApiShiftModelIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportReportingExcelApiService,
		override readonly parent : ExportReportingExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftModelIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiShiftModelIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '5';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, ExportReportingExcelApiShiftModelIds> = new ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, ExportReportingExcelApiShiftModelIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiShiftModelIds,
			name: 'shiftModelIds',
			nodeName: 'SHIFT_MODEL_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportReportingExcelApiShiftModelIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 4,
		});
	aiShiftModelId : ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, Id> = new ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, Id>({
			apiObjWrapper: this as any as ExportReportingExcelApiShiftModelIds,
			name: 'shiftModelId',
			nodeName: 'SHIFT_MODEL_ID',
			type: PApiType.Id,
			
		});
}

		

