import { USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';

export const SCHEDULING_SERVICE_INDEXED_DB_SHOW_DAY_AS_LIST_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'showDayAsList',
} as const;

export const SCHEDULING_SERVICE_INDEXED_DB_SHOW_WEEK_AS_LIST_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'showWeekAsList',
} as const;

export const SCHEDULING_SERVICE_INDEXED_DB_WISH_PICKER_MODE_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'wishPickerMode',
} as const;

export const SCHEDULING_SERVICE_INDEXED_DB_EARLY_BIRD_MODE_KEY = {
	database: 'user',
	store: USER_STORE_NAME,

	// TODO: PLANO-184431 Add prefix
	prefix: null,
	name: 'earlyBirdMode',
} as const;

export type SchedulingServiceIndexedDBKeyDataType =
    typeof SCHEDULING_SERVICE_INDEXED_DB_SHOW_DAY_AS_LIST_KEY |
    typeof SCHEDULING_SERVICE_INDEXED_DB_SHOW_WEEK_AS_LIST_KEY |
    typeof SCHEDULING_SERVICE_INDEXED_DB_WISH_PICKER_MODE_KEY |
    typeof SCHEDULING_SERVICE_INDEXED_DB_EARLY_BIRD_MODE_KEY;
