import { SchedulingApiHolidaysLabelBase } from '@plano/shared/api';
import { stringMatchesSearchTerm } from '@plano/shared/core/utils/search-utils';

/** @see SchedulingApiHolidaysLabelBase */
export class SchedulingApiHolidaysLabel extends SchedulingApiHolidaysLabelBase {
	/**
	 * Check if this item fits to the given search term.
	 * @param searchTerm The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(searchTerm : string) : boolean {
		return stringMatchesSearchTerm(this.name, searchTerm);
	}
}
