/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { Location } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, enableProdMode, HostBinding, HostListener, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { PWishesService } from '@plano/client/scheduling/wishes.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PPerformanceTestingService } from '@plano/performance-testing.service';
import { PCloseOnEscTriggerEvent, store } from '@plano/shared/core/directive/close-on-esc.directive';
import { openTooltipAsModal } from '@plano/shared/core/directive/open-tooltip-as-modal';
import { PExternalLinkComponent } from '@plano/shared/core/external-link/p-external-link.component';
import { PFingerprintService } from '@plano/shared/core/fingerprint.service';
import { PIndexedDBService } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { LogService } from '@plano/shared/core/log.service';
import { PFocusService } from '@plano/shared/core/p-auto-focus/p-focus.service';
import { PGlobalEventListenersService } from '@plano/shared/core/p-global-event-listeners.service';
import { PKeyboardService } from '@plano/shared/core/p-keyboard.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PProgressbarService } from '@plano/shared/core/progressbar.service';
import { PWindowSizeService } from '@plano/shared/core/window-size.service';
import { NgProgressComponent } from 'ngx-progressbar';
import { ENVIRONMENT } from './environments/environment';
import { Config } from './shared/core/config';
import { ModalService } from './shared/core/p-modal/modal.service';
import { PRouterService } from './shared/core/router.service';
import { PScrollToSelectorService } from './shared/core/scroll-to-selector.service';
import { SeoService } from './shared/core/seo.service';
import { PSentryService } from './shared/sentry/sentry.service';

if (ENVIRONMENT.APPLICATION_MODE === 'PROD' || ENVIRONMENT.APPLICATION_MODE === 'GHERKIN') {
	enableProdMode();
}

@Component({
	selector: 'p-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AppComponent implements OnInit, AfterContentChecked, AfterViewInit {

	@ViewChild('progressBar', { static: true }) public progressBar ! : NgProgressComponent;

	@ViewChild('cookieConsentManagerWrapperElement') protected cookieConsentManagerWrapperElement ?: ElementRef<HTMLElement>;

	/**
	 * When user hits esc key, we need to prioritize what to dismiss.
	 * @param event The event that triggered the escape event.
	 */
	@HostListener('document:pCloseOnEscTrigger', ['$event']) private _onEsc(event : PCloseOnEscTriggerEvent) : void {
		this.console.debug('ClientComponent._onEsc()');
		this.dismissElement(event);
	}

	@HostBinding('class') private get cssClasses() : string | null {
		// Add CSS class with current viewport width. This is used in our visual testing to detect DOM update after the viewport width is changed.
		return Config.windowWidth ? `viewport-width-${Config.windowWidth}` : null;
	}

	constructor(
		private _pIndexedDBService : PIndexedDBService, // ensure service is created on app open
		private _seoService : SeoService, // ensure service is created on app open
		private _performanceTestingService : PPerformanceTestingService, // ensure service is created on app open
		private _pGlobalEventListenersService : PGlobalEventListenersService, // ensure service is created on app open
		private _pFocusService : PFocusService, // ensure service is created on app open
		private _pKeyboardService : PKeyboardService, // ensure service is created on app open
		private _pFingerprintService : PFingerprintService, // Ensure service is created on startup to improve identification accuracy
		private activatedRoute : ActivatedRoute,
		private modalService : ModalService,
		private location : Location,
		private router : Router,
		private pRouterService : PRouterService,
		private pScrollToSelectorService : PScrollToSelectorService,
		private pSentryService : PSentryService,
		private pWindowSizeService : PWindowSizeService,
		private injector : Injector,
		private console : LogService,
		private highlightService : HighlightService,
		private pWishesService : PWishesService,
		private schedulingService : SchedulingService,
		private toasts : ToastsService,
		private pProgressbarService : PProgressbarService,
		private zone : NgZone,
		private localizePipe : LocalizePipe,
	) {
		Config.setWindowWidthServiceAccess(this.pWindowSizeService);

		// eslint-disable-next-line rxjs/no-ignored-subscription -- App component will only be initialized once
		this.activatedRoute.queryParams.subscribe((inputQueryParams : Params) => {
			const { requiresLargeScreen } : Params = inputQueryParams;

			if (requiresLargeScreen) {
				// remove query params so reloading page does not trigger this again.
				// We use this.location which also removes the params from browsers history stack.
				const { url } : AppComponent['router'] = this.router;
				const baseUrl = url.slice(0, Math.max(0, url.indexOf('?')));
				this.location.replaceState(baseUrl);

				// show info that user should use his PC?
				// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
				if (Config.IS_MOBILE) {
					this.modalService.info(
						{
							description: this.localizePipe.transform('Die aufgerufene Seite ist über mobile Geräte nicht zugänglich. Bitte nutze einen Computer oder einen größeren Laptop.'),
							closeBtnLabel: null,
						},
					);
				}
			}
		});
		this.pSentryService.init();

		this.pRouterService.setListenerToHandleFragments();

		this.pRouterService.setNavigationSentryTransactionName();
	}

	private dismissElement(event : PCloseOnEscTriggerEvent) : void {
		switch (event.detail.type) {
			case 'early-bird-mode':
				this.dismissEarlyBirdMode(event);
				break;
			case 'wish-picker-mode':
				this.dismissWishPickerMode(event);
				break;
			case 'toast':
				this.dismissToast(event);
				break;
			case 'modal':
				this.dismissModal(event);
				break;
			case 'tooltip':
				if (openTooltipAsModal()) {
					this.dismissModal(event);
				} else {
					this.dismissTooltip(event);
				}
				break;
			case 'emoji-picker':
				this.dismissEmojiPicker(event);
				break;
			case 'typeahead':
				this.dismissTypeahead(event);
				break;
			case 'dropdown':
				this.dismissDropdown(event);
		}
	}

	private dismissTypeahead(event : PCloseOnEscTriggerEvent) : void {
		// click the hidden button to close the typeahead and remove it from the store
		if (store.last) {
			store.last.click();
			store.remove(store.last);
		}
		event.stopPropagation();
	}

	private dismissEarlyBirdMode(event : PCloseOnEscTriggerEvent) : void {
		this.schedulingService.earlyBirdMode = false;
		event.stopPropagation();
	}

	private dismissEmojiPicker(event : PCloseOnEscTriggerEvent) : void {
		// click the emoji picker trigger button to close it and remove it from the store
		if (store.last) {
			store.last.click();
			store.remove(store.last);
		}

		event.stopPropagation();
	}

	private dismissDropdown(event : PCloseOnEscTriggerEvent) : void {
		// click the dropdown-trigger to hide the dropdown-content and remove it from the store
		if (store.last) {
			store.last.click();

			// This method gets called on escape. So the focus is not on the dropdown trigger.
			// The previous click only performs a click, but does not set the focus to the target.
			// As the specs of the role menu tells, on escape should be set back to the trigger on escape.
			store.last.focus();

			store.remove(store.last);
		}

		event.stopPropagation();
	}

	private dismissWishPickerMode(event : PCloseOnEscTriggerEvent) : void {
		this.schedulingService.wishPickerMode = false;
		event.stopPropagation();
	}

	private dismissToast(event : PCloseOnEscTriggerEvent) : void {
		const newestToast = this.toasts.newest;
		this.toasts.removeToast(newestToast);
		event.stopPropagation();
	}

	private dismissModal(event : PCloseOnEscTriggerEvent) : void {
		const modalRef = this.modalService.topModalRef;
		if (modalRef) {
			modalRef.dismiss(event);
		}
	}

	private dismissTooltip(event : PCloseOnEscTriggerEvent) : void {
		if (!this.modalService.hasHighlightCancelBlockModals && this.highlightService.highlightedItem) {
			this.highlightService.setHighlighted(null);

			// Imagine the user is at the calendar view, and has highlighted a shift.
			// The members list of the sidebar will show the whishes that members gave for it.
			// If the highlighting ends, the visibility of the whishes should also end.
			this.pWishesService.item = null;

			event.stopPropagation();
		}
		this.zone.runOutsideAngular(() => {
			const showMoreModal = document.querySelector('.p-tooltip-show-more-modal');
			if (showMoreModal && showMoreModal.contains(event.target as Node)) {
				this.modalService.topModalRef?.dismiss(event);
			}
		});
	}

	public ngOnInit() : void {

		this.pProgressbarService.setSubscriber(this.progressBar);

		// add the pExternalLinkComponent as an element, so we can use it in external HTML as a normal element
		const pExternalLinkElement = createCustomElement(PExternalLinkComponent, {injector: this.injector});
		customElements.define('ld-p-external-link', pExternalLinkElement);

		// add to <body> one of the classes "is-mobile"/"is-desktop"
		const addMobileModeCssClass = () : void => {
			// Remove old class
			document.body.classList.remove('is-desktop');
			document.body.classList.remove('is-mobile');

			// Add current class for current viewport size
			// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
			const currentClass = Config.IS_MOBILE ? 'is-mobile' : 'is-desktop';
			document.body.classList.add(currentClass);
		};

		addMobileModeCssClass();
		this.zone.runOutsideAngular(() => {
			const resizeObserver = new ResizeObserver(addMobileModeCssClass);
			resizeObserver.observe(document.body);
		});
	}

	public ngAfterContentChecked() : void {
		this.pScrollToSelectorService.changeDetectionTriggered.next();
	}

	public ngAfterViewInit() : void {
		// To ensure that the cookie consent manager overlaps the tawk widget, we append it on the body
		if (this.cookieConsentManagerWrapperElement) {
			document.body.appendChild(this.cookieConsentManagerWrapperElement.nativeElement);
		}
	}
}
