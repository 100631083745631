/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { PAbstractControlComponentBaseDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/**
 * A directive to contain all logic that a component needs, which holds a form control as well as an editable directive.
 */
@Directive({
	selector: '[formControl][pEditable], [formGroup][pEditable], [formArray][pEditable]',
})
export class ControlWithEditableDirective extends PAbstractControlComponentBaseDirective implements EditableControlInterface {

	// These are necessary Inputs and Outputs for pEditable form-element
	@Input() public pEditable : EditableControlInterface['pEditable'] = false;
	@Input() public api : EditableControlInterface['api'] = null;
	@Input() public valid : EditableControlInterface['valid'] = null;
	// eslint-disable-next-line @typescript-eslint/require-await -- shortest way to define the default
	@Input() public saveChangesHook : EditableControlInterface['saveChangesHook'] = async () => null;
	@Output() public onSaveStart : EditableControlInterface['onSaveStart'] = new EventEmitter();
	@Output() public onSaveSuccess : EditableControlInterface['onSaveSuccess'] = new EventEmitter();
	@Output() public onDismiss : EditableDirective['onDismiss'] = new EventEmitter();
	@Output() public onLeaveCurrent : EditableControlInterface['onLeaveCurrent'] = new EventEmitter();
	@Output() public beforeApiChanges = new EventEmitter<undefined>();

	@Output() public editMode : EditableControlInterface['editMode'] = new EventEmitter<boolean>(undefined);

	/**
	 * Icon to visualize the success state.
	 * Can be some icon to visualize that a process is running.
	 */
	public get successBtnIcon() : PlanoFaIconPoolKeys {

		// Warning has higher priority than success, since it usually indicates a ongoing process.
		if (this.hasWarning) return this.enums.PlanoFaIconContextPool.SYNCING;

		return this.enums.PlanoFaIconContextPool.SUCCESS;
	}

	/**
	 * Should the success button icon be spinning?
	 */
	public get successBtnIconSpin() : boolean {
		if (this.successBtnIcon === this.enums.PlanoFaIconContextPool.SYNCING) return true;
		return false;
	}

	/**
	 * @see PAbstractControlComponentBaseDirective#isValid
	 *
	 * [valid]="…" overwrites the valid state if the formControl.
	 */
	public override get isValid() {
		if (this.hasWarning) return false;
		if (this.valid !== null) return this.valid;
		return super.isValid;
	}
}
