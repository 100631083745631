/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint max-lines: ["warn", 1300] -- Dont make this file even bigger. Invest some time to cleanup/split into several files */

/**	NOTE: Do not make this service more complex than it already is */
/* eslint complexity: ["error", 41] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, Validators } from '@angular/forms';
import { PApiTextType } from '@plano/shared/api';
import { Integer, PApiType } from '@plano/shared/api/base/generated-types.ag';
import { IdBase } from '@plano/shared/api/base/id/id-base';
import { Config } from '@plano/shared/core/config';
import { PLocaleNumbersService } from '@plano/shared/core/locale-numbers.service';
import { PMath } from '@plano/shared/core/utils/math-utils';
import { ValueOrFunction } from '@plano/shared/core/utils/typescript-utils-types';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import * as IBAN from 'iban';
import { PDictionarySourceString } from './pipe/localize.dictionary';
import { getFileFormat, getFileSize } from './utils/base64-utils';
import { assumeNotUndefined } from './utils/null-type-utils';
import { PPossibleErrorNames, PValidationErrors, PValidationErrorValue, PValidatorObject, ValidatorObjectInitData, ValidatorsServiceReturnType } from './validators.types';

/**
 * Regex to check if a string is an URL.
 */
// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export const FILE_URL_REGEXP = /^https?:.*$/;

// eslint-disable-next-line jsdoc/require-jsdoc, require-unicode-regexp -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const TIME_REGEXP : RegExp = /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/;

/**
 * Custom validations for our forms
 */
@Injectable({
	providedIn: 'root',
})
export class ValidatorsService {

	/**
	 * @see AbstractControl#updateValueAndValidity()
	 *
	 * @description
	 * Validator that performs no operation.
	 *
	 */
	public nullValidator() : PValidatorObject {
		return new PValidatorObject({
			name: PPossibleErrorNames.NULL_VALIDATOR,
			fn: (control) => Validators.nullValidator(control as AbstractControl),
		});
	}

	/**
	 * Validator that requires the control's value to be greater than or equal to the provided number.
	 *
	 * @param min The minimum value allowed
	 * @param equalIsAllowed If true, the value can be equal to the min value
	 * @param type Type of the value that gets validated
	 * @param comparedAttributeName Name of the attribute that contains the value which the control's value gets validated against
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public min(
		min : ValueOrFunction<number | null | undefined>,
		equalIsAllowed : boolean,
		type : Exclude<PApiType, PApiType.ApiList> | (() => Exclude<PApiType, PApiType.ApiList>),
		comparedAttributeName ?: string,
		errorText ?: ValueOrFunction<PDictionarySourceString>,
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {min : number} | ValidatorsServiceReturnType<'greaterThan'>>> {

		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['min']>['fn']> => {

			// cancel validation if the control value is null or not available
			if (control.value === null || control.value === undefined) return null;

			// if the bound attribute info is not available, the validation is not possible
			if (control instanceof PFormControl && control.attributeInfo && !control.attributeInfo.isAvailable) return null;

			const TYPE = (typeof type === 'function') ? type() : type;
			const MIN = (typeof min === 'function') ? min() : min;

			// cancel validation if the min value is null or not available
			if (MIN === null || MIN === undefined) return null;

			// do validation
			if (equalIsAllowed) {
				const ERRORS = Validators.min(MIN)(control as AbstractControl) as {min : {min : number, actual : number}} | null;
				if (!ERRORS) return null;
				const errorObject : PValidationErrorValue & { min : number; } = {
					name: PPossibleErrorNames.MIN,
					type: TYPE,
					errorText: errorText,
					...ERRORS[PPossibleErrorNames.MIN],
				};
				if (comparedAttributeName !== undefined) errorObject.comparedAttributeName = comparedAttributeName;
				return {
					[PPossibleErrorNames.MIN]: errorObject,
				};
			} else {
				const ERRORS = new ValidatorsService().greaterThan(MIN, TYPE, errorText).fn(control);
				if (!ERRORS) return null;
				const errorObject : ValidatorsServiceReturnType<'greaterThan'> = {
					min: MIN + 1,
					errorText: errorText,
					...ERRORS[PPossibleErrorNames.GREATER_THAN],
				};
				if (comparedAttributeName !== undefined) errorObject.comparedAttributeName = comparedAttributeName;
				return {
					[PPossibleErrorNames.GREATER_THAN]: errorObject,
				};
			}
		};

		const validatorObjectData : ValidatorObjectInitData<'sync', PValidationErrors<PValidationErrorValue & {min : number} | ValidatorsServiceReturnType<'greaterThan'>>> = {
			fn: fn,
			name: PPossibleErrorNames.MIN,
			additionalParams: {
				equalIsAllowed: equalIsAllowed,
			},
		};
		if (comparedAttributeName !== undefined) validatorObjectData.comparedAttributeName = comparedAttributeName;
		const comparedConst = (typeof min === 'function') ? min() : min;
		if (comparedConst !== undefined) validatorObjectData.comparedConst = comparedConst;
		return new PValidatorObject(validatorObjectData);
	}

	/**
	 * Validator that requires the control's value to be different from the provided value.
	 *
	 * @param value The value that the control's value should not be equal to
	 * @param type Type of the value that gets validated
	 * @param comparedAttributeName Name of the attribute that contains the value which the control's value gets validated against
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public notEquals(
		value : ValueOrFunction<number | string | boolean | null | undefined>,
		type : PApiType | (() => PApiType),
		comparedAttributeName ?: string,
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
		name : PPossibleErrorNames.NOT_EQUALS,
	}>> {
		return new PValidatorObject({name: PPossibleErrorNames.NOT_EQUALS, fn: (control) => {

			// cancel validation if the control value is null or not available
			if (control.value === null || control.value === undefined) return null;

			// if the bound attribute info is not available, the validation is not possible
			if (control instanceof PFormControl && control.attributeInfo && !control.attributeInfo.isAvailable) return null;

			const TYPE : PApiType = (typeof type === 'function') ? type() : type;
			const VALUE = (typeof value === 'function') ? value() : value;

			// cancel validation if the value is null or not available
			if (VALUE === null || VALUE === undefined) return null;

			if (control.value === VALUE) {
				const errorObj : PValidationErrorValue & { name : PPossibleErrorNames.NOT_EQUALS } = {
					name: PPossibleErrorNames.NOT_EQUALS,
					type: TYPE,
					notEquals: control.value,
					errorText: errorText,
				};
				if (comparedAttributeName)
					errorObj.comparedAttributeName = comparedAttributeName;
				return {
					[PPossibleErrorNames.NOT_EQUALS]: errorObj,
				};
			}
			return null;
		}});
	}

	/**
	 * Validator that requires the control's value to be less than or equal to the provided number.
	 *
	 * @param max The maximum value allowed
	 * @param equalIsAllowed If true, the value can be equal to the max value
	 * @param type Type of the value that gets validated
	 * @param comparedAttributeName Name of the attribute that contains the value which the control's value gets validated against
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public max(
		max : number | null | undefined | (() => number | null | undefined),
		equalIsAllowed : boolean,
		type : PApiType | (() => PApiType),
		comparedAttributeName ?: string,
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.LESS_THAN,
		} | PValidationErrorValue & {
			name : PPossibleErrorNames.MAX,
		}>> {
		const fn = (control : Pick<AbstractControl, 'value'> | Pick<FormArray, 'value' | 'length'>) : ReturnType<ReturnType<ValidatorsService['max']>['fn']> => {

			// cancel the validation if the control value is null or not available
			if (control.value === null || control.value === undefined) return null;

			// if the bound attribute info is not available, the validation is not possible
			if (control instanceof PFormControl && control.attributeInfo && !control.attributeInfo.isAvailable) return null;

			const TYPE : PApiType = (typeof type === 'function') ? type() : type;
			const MAX = (typeof max === 'function') ? max() : max;

			// cancel the validation if the max value is null or not available
			if (MAX === null || MAX === undefined) return null;

			// do validation
			if (equalIsAllowed) {
				const ERRORS = Validators.max(MAX)(control as AbstractControl) as {max : {max : number, actual : number}} | null;
				if (!ERRORS) return null;

				const errorObject : PValidationErrorValue & { name : PPossibleErrorNames.MAX } = {
					name: PPossibleErrorNames.MAX,
					type: TYPE,
					errorText : errorText,
					...ERRORS.max,
				};
				if (comparedAttributeName !== undefined) errorObject.comparedAttributeName = comparedAttributeName;
				return {
					[PPossibleErrorNames.MAX]: errorObject,
				};
			} else {
				const ERRORS = new ValidatorsService().lessThan(MAX, TYPE, errorText).fn(control);
				if (!ERRORS) return null;

				const errorObject : PValidationErrorValue & { name : PPossibleErrorNames.LESS_THAN } = {
					...ERRORS[PPossibleErrorNames.LESS_THAN],
					name: PPossibleErrorNames.LESS_THAN,
					type: TYPE,
					errorText : errorText,
				};
				if (comparedAttributeName !== undefined) errorObject.comparedAttributeName = comparedAttributeName;

				return {
					[PPossibleErrorNames.LESS_THAN]: errorObject,
				};
			}
		};

		const validatorObjectData : ValidatorObjectInitData<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.LESS_THAN,
		} | PValidationErrorValue & {
			name : PPossibleErrorNames.MAX,
		}>> = {
			fn: fn,
			name: PPossibleErrorNames.MAX,
			additionalParams: {
				equalIsAllowed: equalIsAllowed,
			},
		};
		if (comparedAttributeName !== undefined) validatorObjectData.comparedAttributeName = comparedAttributeName;
		const comparedConst = (typeof max === 'function' ? max() : max);
		if (comparedConst !== undefined) validatorObjectData.comparedConst = comparedConst;

		return new PValidatorObject(validatorObjectData);
	}

	/**
	 * Validator that requires the control's value to have a maximum of provided decimal numbers.
	 *
	 * @param max The maximum number of decimal places allowed
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public maxDecimalPlacesCount(
		max : number | null | (() => number | null),
		type : PApiType | (() => PApiType) | undefined,
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', ReturnType<ReturnType<ValidatorsService['number']>['fn']> | PValidationErrors<{
			name : PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT,
			maxDigitsLength : number,
		} & PValidationErrorValue>> {
		return new PValidatorObject({name: PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT, fn: (control) => {
			// TODO: item.aiFoo.type can be undefined. We had this a lot in out app before we turned
			// on strictNullChecks
			const TYPE : PApiType | undefined = (typeof type === 'function') ? type() : type;

			const numberValidation = this.number(TYPE, errorText).fn(control);
			if (numberValidation !== null) return numberValidation;

			let value = PLocaleNumbersService.turnLocaleNumberIntoNumber(Config.LOCALE_ID, control.value, TYPE === PApiType.ClientCurrency || type === PApiType.Euro ? 'currency' : 'number');

			if (value === null) return null;

			// If the type is percentage, the value is multiplied by 100, as otherwise the decimal places are not counted correctly.
			// As entering 10% would be 0.1 and not 10, meaning it would already have 1 decimal place, which is not the user enters.
			if (TYPE === PApiType.Percent) {
				const amountOfDigitsAfterDecimalSeparator = PMath.amountOfDigitsAfterDecimalSeparator(control.value);
				value = PMath.roundToDecimalPlaces(value * 100, amountOfDigitsAfterDecimalSeparator + 2);
			}

			const MAX = (typeof max === 'function') ? max() : max;

			// cancel the validation if the max is null
			if (MAX === null) return null;

			const decimalPart = value.toString().split('.').at(1);
			if (decimalPart === undefined) return null;
			if (decimalPart.length <= MAX) return null;

			return {
				[PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT]: {
					name: PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT,
					type: TYPE,
					actual: value,
					maxDigitsLength: MAX,
					errorText: errorText,
				},
			};
		}});
	}

	/**
	 * Ensure that the value is "null".
	 *
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public ensureNull(
		type : PApiType | (() => PApiType),
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<{
			name : PPossibleErrorNames.ENSURE_NULL,
			actual : number,
		} & PValidationErrorValue>> {
		// TODO: The api generator generates this for the "ensureNullWhenConditionIsFalse" attribute.
		// Normally, it does not make sense in UI because when something should be "null" the input field
		// will just be hidden (in which case no validation checks are done anyway). But, to be complete and consistent
		// we still have this validator.
		return new PValidatorObject({name: PPossibleErrorNames.ENSURE_NULL, fn: (control) => {
			const TYPE : PApiType = (typeof type === 'function') ? type() : type;
			if (control.value === null) return null;
			return {
				[PPossibleErrorNames.ENSURE_NULL]: {
					name: PPossibleErrorNames.ENSURE_NULL,
					type: TYPE,
					actual: control.value,
					errorText: errorText,
				},
			};
		}});
	}

	/**
	 * Check if there is any value
	 *
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public required(
		type : PApiType | (() => PApiType),
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
			// This happens if its a function returning a primitive
			const TYPE : PApiType = (typeof type === 'function') ? type() : type;

			switch (TYPE) {
				case PApiType.ApiList:
					if (control.value.length > 0) return null;
					break;
				default:
					if (
						control.value !== undefined &&
						control.value !== null &&
						control.value !== ''
					) return null;
					break;
			}

			return { [PPossibleErrorNames.REQUIRED]: {
				name: PPossibleErrorNames.REQUIRED,
				type: TYPE,
				actual: control.value,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Checks that the value is not `undefined`.
	 *
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public notUndefined(
		type : PApiType | (() => PApiType),
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.NOT_UNDEFINED, fn: (control) => {
			const TYPE : PApiType = (typeof type === 'function') ? type() : type;

			if (control.value !== undefined) return null;

			return { [PPossibleErrorNames.NOT_UNDEFINED]: {
				name: PPossibleErrorNames.NOT_UNDEFINED,
				type: TYPE,
				actual: control.value,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Check if value is long enough
	 * @param minLength the required minimum length
	 */
	public minLength(
		minLength : number,
		type : ValueOrFunction<PApiTextType | PApiType.ApiList>,
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.MIN_LENGTH, fn: (control) => {
			if (control.value === null) return null;
			const TYPE = (typeof type === 'function') ? type() : type;

			const LENGTH = (() => {
				if (typeof control.value === 'string') return control.value.trim().length;
				if (typeof control.value === 'number') return control.value.toString().length;
				return control.value.length as number;
			})();
			if (LENGTH >= minLength) return null;

			return {
				[PPossibleErrorNames.MIN_LENGTH]: {
					name: PPossibleErrorNames.MIN_LENGTH,
					type: TYPE,
					requiredLength: minLength,
					actualLength: LENGTH,
					errorText: errorText,
				},
			};
		}});
	}

	/**
	 * Check if value is short enough
	 * @param maxLength the required maximum length
	 */
	public maxLength(
		maxLength : number,
		type : ValueOrFunction<PApiTextType | PApiType.ApiList>,
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<{
			name : PPossibleErrorNames.MAX_LENGTH,
			requiredLength : number,
			actualLength : number,
		} & PValidationErrorValue>> {
		return new PValidatorObject({name: PPossibleErrorNames.MAX_LENGTH, fn: (control) => {
			const TYPE = (typeof type === 'function') ? type() : type;
			const ERRORS = Validators
				.maxLength(maxLength)(control as AbstractControl) as { maxlength : { requiredLength : number, actualLength : number} } | null;
			if (ERRORS === null) return null;
			return {
				[PPossibleErrorNames.MAX_LENGTH]: {
					...ERRORS[PPossibleErrorNames.MAX_LENGTH],
					name: PPossibleErrorNames.MAX_LENGTH,
					type: TYPE,
					errorText: errorText,
				},
			};
		}});
	}

	/**
	 * Check if matches regex
	 *
	 * @param pattern The regex the control value should be checked against
	 */
	public pattern(pattern : string | RegExp, errorText ?: PDictionarySourceString | (() => PDictionarySourceString)) : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.PATTERN, fn: (control) => {
			const PATTERN_ERROR : {
				pattern ?: {
					requiredPattern : string,
					actualValue : string
				}
			} | null = Validators.pattern(pattern)(control as AbstractControl);
			if (PATTERN_ERROR === null) return null;
			return { [PPossibleErrorNames.PATTERN]: {
				name: PPossibleErrorNames.PATTERN,
				type: null,
				actual: PATTERN_ERROR.pattern?.actualValue,
				requiredPattern: PATTERN_ERROR.pattern?.requiredPattern,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * NEVER USE THIS! Use max() instead
	 * @deprecated
	 */
	public maxDate() : PValidatorObject {
		throw new Error('NEVER USE THIS! Use max() instead');
	}

	/**
	 * Is value less then given float
	 * 0.5 fails with max(0) but not with greaterThan(0);
	 *
	 * @param input The maximum limit
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public lessThan(
		input : number,
		type : PApiType | (() => PApiType) | undefined,
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.LESS_THAN, fn: (control) : PValidationErrors<{
			name : PPossibleErrorNames.LESS_THAN,
			actual : number,
			lessThan : number,
		} & PValidationErrorValue> | null => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			const TYPE = (typeof type === 'function') ? type() : type;

			if (Number.isNaN(+control.value)) return null;

			if (control.value < input) return null;
			return { [PPossibleErrorNames.LESS_THAN]: {
				name: PPossibleErrorNames.LESS_THAN,
				type: TYPE,
				actual: control.value,
				lessThan: input,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Is value grater then given float
	 * 0.5 fails with min(1) but not with greaterThan(0);
	 *
	 * @param input The minimum limit
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public greaterThan(
		input : number,
		type : PApiType | (() => PApiType),
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.GREATER_THAN,
			actual : number,
			greaterThan : number,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.GREATER_THAN, fn: (control) => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			if (Number.isNaN(+control.value)) return null;

			const TYPE : PApiType = (typeof type === 'function') ? type() : type;

			if (control.value > input) return null;
			return { [PPossibleErrorNames.GREATER_THAN]: {
				name: PPossibleErrorNames.GREATER_THAN,
				type: TYPE,
				actual: control.value,
				greaterThan: input,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Is this a valid phone number?
	 * OK: "12345 - 1234567"
	 * NOT OK: "1212 / 1234lorem"
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public phone(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.MIN_LENGTH | PPossibleErrorNames.PHONE | PPossibleErrorNames.WHITESPACE,
			type : PApiType.Tel,
			actual : string,
			requiredLength ?: number,
			actualLength ?: number,
		}>> {
		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['phone']>['fn']> => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			// Check if value has whitespace inside
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const BEGINS_WITH_WHITESPACE = Validators.pattern(/^\S+/)(control as AbstractControl);
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const ENDS_WITH_WHITESPACE = Validators.pattern(/\S$/)(control as AbstractControl);
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- FIXME: Remove this before you work here.
			if (BEGINS_WITH_WHITESPACE || ENDS_WITH_WHITESPACE) {
				return { [PPossibleErrorNames.WHITESPACE]: {
					name: PPossibleErrorNames.WHITESPACE,
					type: PApiType.Tel,
					actual: control.value,
				} };
			}

			// If you change this phone validation condition, you also need to change it in code.js
			// eslint-disable-next-line unicorn/better-regex, require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description', This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const PHONE_REGEXP = /^(?:\d\s?)?(?:\(?\+(?:\(?\d{1,2}|00\d{1,2}\)?)[\-\s.]?\)? ?)?(?:\(\d+\)\s?)?(?:\d+ - )?(?:\d[\-\s.]?){0,14}\d\s*$/;
			const PATTERN_ERROR : {
				pattern ?: {
					requiredPattern : string,
					actualValue : string
				}
			} | null = Validators.pattern(PHONE_REGEXP)(control as AbstractControl);
			if (PATTERN_ERROR) return { [PPossibleErrorNames.PHONE]: {
				name: PPossibleErrorNames.PHONE,
				type: PApiType.Tel,
				actual: control.value,
				errorText: errorText,
			} };

			// If you change this phone validation condition, you also need to change it in code.js
			const REQUIRED_LENGTH = 6;
			const MIN_LENGTH_ERROR = new ValidatorsService().minLength(REQUIRED_LENGTH, PApiType.Tel).fn({
			// eslint-disable-next-line unicorn/prefer-number-properties -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				value: control.value !== null && !isNaN(control.value) ? control.value.toString() : control.value,
			});
			if (MIN_LENGTH_ERROR) return { [PPossibleErrorNames.MIN_LENGTH]: {
				name: PPossibleErrorNames.MIN_LENGTH,
				type: PApiType.Tel,
				actual: control.value,
				requiredLength: REQUIRED_LENGTH,
				actualLength: control.value?.length,
			} };

			return null;
		};

		return new PValidatorObject({name: PPossibleErrorNames.PHONE, fn: fn});
	}

	/**
	 * Is this a valid url?
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public url(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<{
			name : PPossibleErrorNames.URL | PPossibleErrorNames.URL_PROTOCOL_MISSING | PPossibleErrorNames.URL_INCOMPLETE | PPossibleErrorNames.WHITESPACE,
			type : PApiType.Url,
			actual : string,
		} & PValidationErrorValue>> {
		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['url']>['fn']> => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			// Regex playground: https://regexr.com/7ferl
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const URL_REGEXP = /https?:\/\/(www\.)?[\w#%+.:=@~-]{1,256}\.\b[\w#%&()+./:=?@~äöü-]*/gi;

			switch (control.value) {
				case 'h':
				case 'ht':
				case 'htt':
				case 'http':
				case 'http:':
				case 'http:/':
				case 'http://':
				case 'http://w':
				case 'http://ww':
				case 'http://www':
				case 'http://www.':
				case 'https':
				case 'https:':
				case 'https:/':
				case 'https://':
				case 'https://w':
				case 'https://ww':
				case 'https://www':
				case 'https://www.':
					return { [PPossibleErrorNames.URL_INCOMPLETE]: {
						name: PPossibleErrorNames.URL_INCOMPLETE,
						type: PApiType.Url,
						actual: control.value,
					} };
				default:
					// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					const STARTING_WITH_PROTOCOL_REGEX = /^(https?:\/\/).*/gi;
					if (!control.value.match(STARTING_WITH_PROTOCOL_REGEX)) {
						return { [PPossibleErrorNames.URL_PROTOCOL_MISSING]: {
							name: PPossibleErrorNames.URL_PROTOCOL_MISSING,
							type: PApiType.Url,
							actual: control.value,
						} };
					}
			}

			// Check for invalid patterns before required
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const HAS_WHITESPACE = Validators.pattern(/^\S*$/)(control as AbstractControl);
			if (HAS_WHITESPACE) {
				return { [PPossibleErrorNames.WHITESPACE]: {
					name: PPossibleErrorNames.WHITESPACE,
					type: PApiType.Url,
					actual: control.value,
				} };
			}

			const patternError = Validators.pattern(URL_REGEXP)(control as AbstractControl);
			if (!patternError && control.value === control.value.match(URL_REGEXP)[0]) {
				const controlValue : string = control.value;

				// TODO: PLANO-170403 this check is needed because we don't want to use negative look behind on the regexp
				// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				if (!controlValue.includes('/www.') || (controlValue.includes('/www.') && controlValue.match(/\./g)!.length > 1))
					return null;
			}

			return { [PPossibleErrorNames.URL]: {
				name: PPossibleErrorNames.URL,
				type: PApiType.Url,
				actual: control.value,
				errorText: errorText,
			} };
		};

		return new PValidatorObject({name: PPossibleErrorNames.URL, fn: fn});
	}

	/**
	 * Is this a valid domain?
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public domain(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<{
			name : PPossibleErrorNames.DOMAIN | PPossibleErrorNames.WHITESPACE | PPossibleErrorNames.URL,
			type : undefined | PApiType.Url,
			actual : string,
		} & PValidationErrorValue>> {
		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['domain']>['fn']> => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			// Check for invalid patterns before required
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const HAS_WHITESPACE = Validators.pattern(/^\S*$/)(control as AbstractControl);
			if (HAS_WHITESPACE) {
				return { [PPossibleErrorNames.WHITESPACE]: {
					name: PPossibleErrorNames.WHITESPACE,
					type: PApiType.Url,
					actual: control.value,
				} };
			}

			// regex copied from https://stackoverflow.com/a/3809435
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const DOMAIN_REGEXP = /(?:[\da-z](?:[\da-z-]{0,61}[\da-z])?\.)+[\da-z][\da-z-]{0,61}[\da-z]/gi;
			const patternError = Validators.pattern(DOMAIN_REGEXP)(control as AbstractControl);
			if (!patternError) return null;

			return { [PPossibleErrorNames.DOMAIN]: {
				name: PPossibleErrorNames.DOMAIN,
				type: undefined,
				actual: control.value,
				errorText: errorText,
			} };
		};

		return new PValidatorObject({name: PPossibleErrorNames.URL, fn: fn});
	}

	/**
	 * Is this a valid IBAN code?
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public iban(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.IBAN,
			type : undefined,
			actual : string,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.IBAN, fn: (control) => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			const isValid = IBAN.isValid(control.value);
			if (isValid) return null;

			return { [PPossibleErrorNames.IBAN]: {
				name: PPossibleErrorNames.IBAN,
				type: undefined,
				actual: control.value,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Is this a valid BIC code?
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public bic(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.BIC,
			type : undefined,
			actual : string,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.BIC, fn: (control) => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			// regex copied from https://stackoverflow.com/a/15920158
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const BIC_REGEXP = /^\s*[a-z]{6}[\da-z]{2}([\da-z]{3})?\s*$/i;
			const patternError = Validators.pattern(BIC_REGEXP)(control as AbstractControl);
			if (!patternError) return null;

			return { [PPossibleErrorNames.BIC]: {
				name: PPossibleErrorNames.BIC,
				type: undefined,
				actual: control.value,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Is this a valid password?
	 */
	public password() : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
		name : (
			PPossibleErrorNames.WHITESPACE |
			PPossibleErrorNames.MIN_LENGTH |
			PPossibleErrorNames.NUMBERS_REQUIRED |
			PPossibleErrorNames.LETTERS_REQUIRED |
			PPossibleErrorNames.UPPERCASE_REQUIRED |
			PPossibleErrorNames.PASSWORD
		),
	}>> {
		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['password']>['fn']> => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			// Check for invalid patterns before required
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const HAS_WHITESPACE = Validators.pattern(/^\S*$/)(control as AbstractControl);
			if (HAS_WHITESPACE) {
				return { [PPossibleErrorNames.WHITESPACE]: {
					name: PPossibleErrorNames.WHITESPACE,
					type: undefined,
					actual: control.value,
				} };
			}

			const MIN_LENGTH = new ValidatorsService().minLength(7, PApiType.Password).fn(control);
			if (MIN_LENGTH) {
				return { [PPossibleErrorNames.MIN_LENGTH]: {
					...MIN_LENGTH[PPossibleErrorNames.MIN_LENGTH],
					name: PPossibleErrorNames.MIN_LENGTH,
					actual: control.value,
				} };
			}

			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const HAS_NO_NUMBER = Validators.pattern(/\d/)(control as AbstractControl);
			if (HAS_NO_NUMBER) {
				return { [PPossibleErrorNames.NUMBERS_REQUIRED]: {
					name: PPossibleErrorNames.NUMBERS_REQUIRED,
					type: undefined,
					actual: control.value,
				} };
			}

			// cSpell:ignore ZÄÖÜ
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const HAS_NO_CHAR = Validators.pattern(/[a-zäöü]/i)(control as AbstractControl);
			if (HAS_NO_CHAR) {
				return { [PPossibleErrorNames.LETTERS_REQUIRED]: {
					name: PPossibleErrorNames.LETTERS_REQUIRED,
					type: undefined,
					actual: control.value,
				} };
			}

			// cSpell:ignore ZÄÖÜ
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const HAS_NO_UPPERCASE_CHAR = Validators.pattern(/[A-ZÄÖÜ]+/)(control as AbstractControl);
			if (HAS_NO_UPPERCASE_CHAR) {
				return { [PPossibleErrorNames.UPPERCASE_REQUIRED]: {
					name: PPossibleErrorNames.UPPERCASE_REQUIRED,
					type: undefined,
					actual: control.value,
				} };
			}

			return null;
		};

		return new PValidatorObject({
			name: PPossibleErrorNames.PASSWORD,
			fn: fn,
		});
	}

	/**
	 * Is value upper case?
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public uppercase(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.UPPERCASE,
			type : undefined,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.UPPERCASE, fn: (control) => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			if (control.value === control.value.toUpperCase()) return null;
			return { [PPossibleErrorNames.UPPERCASE]: {
				name: PPossibleErrorNames.UPPERCASE,
				type: undefined,
				actual: control.value,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * the .integer validator does not work on inputs that calculate timestamps internally.
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public integerDaysDuration(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.INTEGER,
			type : undefined,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.INTEGER, fn: (control) => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;
			const oneDayAsTimestamp = (24 * 60 * 60 * 1000);
			const isFullDay : boolean = (control.value % oneDayAsTimestamp) === 0;
			if (isFullDay) return null;
			return { [PPossibleErrorNames.INTEGER]: {
				name: PPossibleErrorNames.INTEGER,
				type: undefined,
				actual: control.value,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Check if this is a number in a locale-fitting format.
	 *
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public number(
		type : PApiType | (() => PApiType) | undefined,
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.NUMBER_NAN | PPossibleErrorNames.WHITESPACE | PPossibleErrorNames.FLOAT,
		}>> {
		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['number']>['fn']> => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			const TYPE = (typeof type === 'function') ? type() : type;
			if (control.value === null) return null;

			const valueToCheck = PLocaleNumbersService.turnLocaleNumberIntoNumber(Config.LOCALE_ID, control.value);
			if (valueToCheck === null) {
				return { [PPossibleErrorNames.NUMBER_NAN]: {
					name: PPossibleErrorNames.NUMBER_NAN,
					type: TYPE,
					actual: control.value,
					errorText: errorText,
				} };
			}

			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const HAS_WHITESPACE = Validators.pattern(/^\S*$/)(control as AbstractControl);
			if (HAS_WHITESPACE) return { [PPossibleErrorNames.WHITESPACE]: {
				name: PPossibleErrorNames.WHITESPACE,
				type: TYPE,
				actual: control.value,
			} };

			return null;

		};

		return new PValidatorObject({name: PPossibleErrorNames.NUMBER_NAN, fn: fn});
	}

	/**
	 * Is this a valid currency amount?
	 * OK: "12"
	 * OK: "12,32"
	 * OK: "12.32"
	 * NOT OK: "12.1234"
	 * NOT OK: "12 euro"
	 *
	 * @param type Type of the value that gets validated
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public currency(
		type : Exclude<PApiType, PApiType.ApiList> | (() => Exclude<PApiType, PApiType.ApiList>),
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<ValidatorsServiceReturnType<'number'> | ValidatorsServiceReturnType<'maxDecimalPlacesCount'> | PValidationErrorValue & {
			name : PPossibleErrorNames.CURRENCY,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.CURRENCY, fn: (control) => {

			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			const TYPE = (typeof type === 'function') ? type() : type;

			const value = PLocaleNumbersService.turnLocaleNumberIntoNumber(Config.LOCALE_ID, control.value, 'currency');

			if (value === null) {
				return { [PPossibleErrorNames.CURRENCY]: {
					name: PPossibleErrorNames.CURRENCY,
					type: TYPE,
					actual: control.value,
					currencyCode: undefined,
					errorText: errorText,
				} };
			}

			if (PMath.amountOfDigitsAfterDecimalSeparator(value) > 2) {
				return {
					[PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT]: {
						name: PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT,
						type: TYPE,
						actual: value,
						maxDigitsLength: 2,
						errorText: errorText,
					},
				};
			}

			return null;

		}});
	}

	/**
	 * Valid email format?
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public email(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.EMAIL_WITHOUT_AT | PPossibleErrorNames.EMAIL,
		}>> {
		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['email']>['fn']> => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;
			if (!control.value.includes('@')) return { [PPossibleErrorNames.EMAIL_WITHOUT_AT]: {
				name: PPossibleErrorNames.EMAIL_WITHOUT_AT,
				type: undefined,
				actual: control.value,
			} };

			// If you change this email validation condition, you also need to change it in code.js
			// eslint-disable-next-line literal-blacklist/literal-blacklist, require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description', This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const E_MAIL_REGEXP = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\wäöü\-]+\.)+[A-Za-z]{2,}))$/;
			const patternError = Validators.pattern(E_MAIL_REGEXP)(control as AbstractControl);
			if (!patternError) return null;
			return { [PPossibleErrorNames.EMAIL]: {
				name: PPossibleErrorNames.EMAIL,
				type: undefined,
				actual: control.value,
				errorText: errorText,
			} };
		};
		return new PValidatorObject({name: PPossibleErrorNames.EMAIL, fn: fn});
	}

	/**
	 * Is this id defined?
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public idDefined(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.ID_DEFINED,
			type : undefined,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.ID_DEFINED, fn: (control) => {
			if (control.value === null) return null;
			assumeNotUndefined(control.value);
			if (control.value instanceof IdBase) return null;
			return { [PPossibleErrorNames.ID_DEFINED]: {
				name: PPossibleErrorNames.ID_DEFINED,
				type: undefined,
				actual: control.value,
				errorText: errorText,
			} };
		}});
	}

	/**
	 * Is this a PLZ?
	 * OK: 12345
	 * OK: 1234 (Österreich)
	 *
	 * @param errorText Alternative error text to display (instead of the default text for this validation type)
	 */
	public plz(
		errorText ?: PDictionarySourceString | (() => PDictionarySourceString),
	) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
			name : PPossibleErrorNames.MIN_LENGTH | PPossibleErrorNames.MAX_LENGTH | PPossibleErrorNames.PLZ,
		}>> {
		return new PValidatorObject({name: PPossibleErrorNames.PLZ, fn: (control) : ReturnType<ReturnType<ValidatorsService['plz']>['fn']> => {
			const TYPE = PApiType.PostalCode;

			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			const MIN_LENGTH_ERRORS = new ValidatorsService().minLength(4, PApiType.PostalCode).fn(control);
			if (MIN_LENGTH_ERRORS) return {
				[PPossibleErrorNames.MIN_LENGTH]: {
					...MIN_LENGTH_ERRORS[PPossibleErrorNames.MIN_LENGTH],
					name: PPossibleErrorNames.MIN_LENGTH,
					actual: control.value,
					errorText: errorText,
				},
			};
			const ERRORS = new ValidatorsService().maxLength(8, TYPE).fn(control);
			if (ERRORS) return {
				[PPossibleErrorNames.MAX_LENGTH]: {
					...ERRORS[PPossibleErrorNames.MAX_LENGTH],
					type: undefined,
					actual: control.value,
					errorText: errorText,
				},
			};

			return null
			;
		}});
	}

	/**
	 * Check if value is the same as another value
	 *
	 * @param otherPassword the password to be compared with value
	 */
	public confirmPassword(otherPassword : () => string | null) : PValidatorObject<'sync', PValidationErrors<PValidationErrorValue & {
		name : PPossibleErrorNames.PASSWORD_UNCONFIRMED,
		type : PApiType.string,
	}>> {
		const fn = (control : Pick<AbstractControl, 'value'>) : ReturnType<ReturnType<ValidatorsService['confirmPassword']>['fn']> => {
			const OTHER_PASSWORD = otherPassword();
			if (!OTHER_PASSWORD) return null;
			if (!control.value && !OTHER_PASSWORD) return null;
			if (control.value === OTHER_PASSWORD) return null;
			return {
				[PPossibleErrorNames.PASSWORD_UNCONFIRMED]: {
					name: PPossibleErrorNames.PASSWORD_UNCONFIRMED,
					type: PApiType.string,
				},
			};
		};

		return new PValidatorObject({name: PPossibleErrorNames.PASSWORD_UNCONFIRMED, fn: fn});
	}

	/**
	 * @param maxFileSizeKb Max file size in kilobytes.
	 */
	public maxFileSize(maxFileSizeKb : Integer) : PValidatorObject {
		return new PValidatorObject({
			name: PPossibleErrorNames.MAX_FILE_SIZE,
			fn: (control) => {
				if (control.value === undefined) return null;
				if (control.value === '') return null;
				if (control.value === null) return null;

				// Is this a url of an image?
				if (control.value.match(FILE_URL_REGEXP)) return null;

				const sizeInKb = getFileSize(control.value);
				if (sizeInKb <= maxFileSizeKb) return null;

				return {
					[PPossibleErrorNames.MAX_FILE_SIZE]: {
						name: PPossibleErrorNames.MAX_FILE_SIZE,
						type: getFileFormat(control.value) === 'pdf' ? PApiType.Pdf : PApiType.Image,
						actual: sizeInKb,
						expected: maxFileSizeKb,
					},
				};
			},
			comparedConst: maxFileSizeKb,
		});
	}

	/**
	 * @param maxHeight Max image height in pixels.
	 */
	public imageMaxHeight(maxHeight : Integer) : PValidatorObject {
		return new PValidatorObject({
			name: PPossibleErrorNames.IMAGE_MAX_HEIGHT,

			// We don’t validate max-width and max-height. Our image-cropper component crops it down to the max.
			fn: () : PValidationErrors | null => null,
			comparedConst: maxHeight,
		});
	}

	/**
	 * @param maxWidth Max image width in pixels.
	 */
	public imageMaxWidth(maxWidth : Integer) : PValidatorObject {
		return new PValidatorObject({
			name: PPossibleErrorNames.IMAGE_MAX_WIDTH,

			// We don’t validate max-width and max-height. Our image-cropper component crops it down to the max.
			fn: () : PValidationErrors | null => null,
			comparedConst: maxWidth,
		});
	}
}
