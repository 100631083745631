import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@plano/environments/environment';
import { Environment } from '@plano/environments/environment.type';
import { PSupportedCountryCodes, PSupportedCurrencyCodes, PSupportedLanguageCodes, PSupportedLocaleIds, PSupportedTimeZones } from '@plano/shared/api/base/generated-types.ag';
import { EnumUtils } from '@plano/shared/core/utils/typescript-utils';
import { PWindowSizeService } from '@plano/shared/core/window-size.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { BrowserInfo, getBrowserInfoByUserAgent } from './utils/browser-utils';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class Config {
	/** @see Environment#BACKEND_URL_VALUE */
	public static readonly BACKEND_URL : Environment['BACKEND_URL_VALUE'] = ENVIRONMENT.BACKEND_URL_VALUE;

	/** @see Environment#FRONTEND_URL_VALUE */
	public static readonly FRONTEND_URL = ENVIRONMENT.FRONTEND_URL_VALUE;

	/** @see Environment#APPLICATION_MODE */
	// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition -- on android there is no localStorage
	public static readonly APPLICATION_MODE = localStorage?.getItem('overrideApplicationMode') ?? ENVIRONMENT.APPLICATION_MODE;

	/** @see Environment#IS_STAGING */
	public static readonly IS_STAGING = ENVIRONMENT.IS_STAGING;

	/** @see Environment#API_FILE_BASE_URL */
	public static readonly API_FILE_BASE_URL = ENVIRONMENT.API_FILE_BASE_URL;

	/** @see Environment#ADYEN_MODE */
	public static readonly ADYEN_MODE = ENVIRONMENT.ADYEN_MODE;

	/** @see Environment#ADYEN_CLIENT_KEY */
	public static readonly ADYEN_CLIENT_KEY = ENVIRONMENT.ADYEN_CLIENT_KEY;

	/** @see Environment#ADYEN_MERCHANT */
	public static readonly ADYEN_MERCHANT = ENVIRONMENT.ADYEN_MERCHANT;

	/** @see Environment#LAUNCH_DARKLY_CLIENT_ID */
	public static readonly LAUNCH_DARKLY_CLIENT_ID = ENVIRONMENT.LAUNCH_DARKLY_CLIENT_ID;

	/** @see Environment#RECAPTCHA_V3_SITE_KEY */
	public static readonly RECAPTCHA_V3_SITE_KEY = ENVIRONMENT.RECAPTCHA_V3_SITE_KEY;

	/**
	 * Date where we released the new feature to create transactions without VAT.
	 * The VAT will be accumulated till the end of the month since that day.
	 */
	public static readonly NEW_TRANSACTION_STYLE_WITHOUT_VAT_DATE = 1651356000000; // = 1.5.2022 0:00

	/**
	 * Timestamp of date of release 3.0.0 | Adyen.
	 */
	public static readonly ADYEN_RELEASE_DATE = 1636581600000; // = 1.11.2021, 2:00

	/**
	 * Timestamp of date of release 4.0.0 | GIFT CARDS.
	 */
	public static readonly GIFT_CARDS_RELEASE_DATE = 1697500800000; // = 17.10.2023, 2:00

	/**
	 * Timestamp of date of work-models release.
	 */
	public static readonly AZK_RELEASE_DATE = 1738371600000; // = 01.02.2025, 2:00

	/**
	 * Timestamp of release of 5.0 version.
	 */
	public static readonly VERSION_5_0_RELEASE_DATE = 1743458400000; // = 1.04.2025, 0:00

	/**
	 * The path to the log-out page. Can not be changed by the application.
	 */
	public static readonly LOGOUT_PATH = '/client/logout';

	/**
	 * For testing purposes, we can override the debug mode, to make performance tests more realistic.
	 * Otherwise we return the environment value. @see Environment#DEBUG_VALUE
	 */
	// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition -- on android there is no localStorage
	public static readonly DEBUG = localStorage?.getItem('overrideDebug') ?
		localStorage.getItem('overrideDebug') === 'true' :
		ENVIRONMENT.DEBUG_VALUE;

	/**
	 * Currency that should override the currency from the current loaded locale,
	 * this can be useful if for some reason we need to check foreign currencies,
	 * for example, when an admin wants to check the page of a foreign client in the admin page
	 */
	public static overrideCurrency : PSupportedCurrencyCodes | null = null;

	/**
	 * Timezone that should override the timezone from the current loaded locale,
	 * this can be useful if for some reason we need to check foreign timezones,
	 * for example, when an admin wants to check the page of a foreign client in the admin page
	 */
	public static overrideTimeZone : PSupportedTimeZones | null = null;

	/**
	 * Initializes the Config object.
	 */
	public static initialize() : void {
		// Is mobile?
		// Not sure what best solution is. For the moment we use combination of window width and userAgent check.
		// This is used quite often and was performance issue. So, we calculate this once.
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const agent = window.navigator.userAgent || window.navigator.vendor;

		/* eslint-disable regexp/no-unused-capturing-group, regexp/no-super-linear-backtracking, regexp/optimal-quantifier-concatenation -- FIXME: Remove this before you work here. */
		// eslint-disable-next-line regexp/no-misleading-capturing-group, require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		Config.userAgentIsMobile = (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i // cSpell:disable-line
			// eslint-disable-next-line regexp/prefer-character-class, require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			.test(agent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i // cSpell:disable-line
			.test(agent.slice(0, 4)));
		/* eslint-enable regexp/no-unused-capturing-group, regexp/no-super-linear-backtracking, regexp/optimal-quantifier-concatenation */

		this.initPlatform(agent);

		// WEB_PUSH_NOTIFICATIONS_ENABLED
		// We disable it on mobile intentionally to motivate users to download apps.
		Config.WEB_PUSH_NOTIFICATIONS_ENABLED = Config.platform === 'browser'	&&
											Config.APPLICATION_MODE !== 'KARMA'	&&
											'serviceWorker' in window.navigator &&
											firebase.messaging.isSupported() &&
											!window.navigator.userAgent.includes('prerender'); // loading service-worker for prerender.io crawler was problematic, so dont load (See PLANO-174994)
	}

	private static initPlatform(agent : string) : void {
		// platform
		if (agent.includes('drpAppAndroid'))
			Config.platform = 'appAndroid';
		else if (agent.includes('drpAppIOS'))
			Config.platform = 'appIOS';
		else
			Config.platform = 'browser';
	}

	/**
	 * A method that subscribes the window width property to the window size service.
	 * @param pWindowSizeService The global instance of the window size service.
	 */
	public static setWindowWidthServiceAccess(pWindowSizeService : PWindowSizeService) : void {
		this.pWindowSizeService = pWindowSizeService;
	}

	private static pWindowSizeService : PWindowSizeService | null = null;

	/**
	 * The current window width.
	 */
	public static get windowWidth() : number | null {
		return this.pWindowSizeService?.windowWidth ?? null;
	}

	/**
	 * Is the user agent a mobile device?
	 */
	public static userAgentIsMobile : boolean = false;

	/**
	 * The locale id of the current frontend version.
	 */
	public static LOCALE_ID : PSupportedLocaleIds; // FIXME: PLANO-20714

	/**
	 * @returns the frontend url containing the country code. E.g.: https://www.dr-plano.com/de.
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public static get FRONTEND_URL_LOCALIZED() : string {
		let result : string = Config.FRONTEND_URL;
		const countryCode = Config.getCountryCode();

		if (countryCode !== null)
			result += `/${countryCode.toLowerCase()}`;

		return result;
	}

	/**
	 * @param locale The locale to get the time-zone from.
	 * @returns Returns the time-zone for given "local".
	 */
	public static getTimeZone(locale : PSupportedLocaleIds) : PSupportedTimeZones | null {
		switch (locale) {
			case PSupportedLocaleIds.de_DE:
			case PSupportedLocaleIds.de_AT:
			case PSupportedLocaleIds.de_CH:
			case PSupportedLocaleIds.en_NL:
			case PSupportedLocaleIds.en_BE:
			case PSupportedLocaleIds.en_CZ:
			case PSupportedLocaleIds.en_SE:
			case PSupportedLocaleIds.en_IT:
			case PSupportedLocaleIds.en_LU :
				return PSupportedTimeZones.EUROPE_BERLIN;

			case PSupportedLocaleIds.en_GB:
				return PSupportedTimeZones.EUROPE_LONDON;

			case PSupportedLocaleIds.en_RO:
				return PSupportedTimeZones.EUROPE_BUCHAREST;

			case PSupportedLocaleIds.en:
				// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				if (Config.DEBUG) console.error(`Unsupported timezone for locale »${locale}«`);
				return PSupportedTimeZones.EUROPE_LONDON;
		}
	}

	/**
	 * @returns Returns time-zone of current frontend version.
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public static get TIME_ZONE() : PSupportedTimeZones | null {
		if (Config.overrideTimeZone !== null) return Config.overrideTimeZone;
		return Config.getTimeZone(Config.LOCALE_ID);
	}

	/**
	 * @returns Returns currency-code of current frontend version.
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public static get CURRENCY_CODE() : PSupportedCurrencyCodes | null {
		if (Config.overrideCurrency !== null) return Config.overrideCurrency;
		return Config.getCurrencyCode(Config.LOCALE_ID);
	}

	/**
	 * @param locale The locale to get the currency code from.
	 * @returns Returns the currency code for given "locale".
	 */
	public static getCurrencyCode(locale : PSupportedLocaleIds) : PSupportedCurrencyCodes | null {
		switch (locale) {
			case PSupportedLocaleIds.de_AT :
			case PSupportedLocaleIds.de_DE :
			case PSupportedLocaleIds.en_BE :
			case PSupportedLocaleIds.en_IT :
			case PSupportedLocaleIds.en_NL :
			case PSupportedLocaleIds.en_LU :
				return PSupportedCurrencyCodes.EUR;

			case PSupportedLocaleIds.de_CH :
				return PSupportedCurrencyCodes.CHF;

			case PSupportedLocaleIds.en_GB :
				return PSupportedCurrencyCodes.GBP;

			case PSupportedLocaleIds.en_CZ:
				return PSupportedCurrencyCodes.CZK;

			case PSupportedLocaleIds.en_SE :
				return PSupportedCurrencyCodes.SEK;

			case PSupportedLocaleIds.en_RO :
				return PSupportedCurrencyCodes.RON;

			case PSupportedLocaleIds.en :
				return null;
		}
	}

	/**
	 * @param locale The locale to get the country code from.
	 * @returns Returns the country code for given "locale".
	 */
	public static getCountryCode(locale ?: string) : PSupportedCountryCodes | null {
		locale = locale ?? Config.LOCALE_ID;
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const delimiterIndex = locale.indexOf('-');

		if (delimiterIndex < 0)
			return null;

		const CODE : PSupportedCountryCodes = locale.slice(delimiterIndex + 1) as PSupportedCountryCodes;
		const CODE_IS_VALID = EnumUtils.getValues(PSupportedCountryCodes).includes(CODE);
		if (!CODE_IS_VALID && Config.DEBUG) throw new Error(`Code »${CODE}« is not supported yet.`);
		return CODE;
	}

	/**
	 * Get language code by provided locale.
	 * If no locale provided, the global locale will be used.
	 * @param locale The locale to get the language code from.
	 */
	public static getLanguageCode(locale ?: string) : PSupportedLanguageCodes {
		locale = locale ?? Config.LOCALE_ID;

		const CODE : PSupportedLanguageCodes = locale.slice(0, 2) as PSupportedLanguageCodes;
		const CODE_IS_VALID = EnumUtils.getValues(PSupportedLanguageCodes).includes(CODE);
		if (!CODE_IS_VALID && Config.DEBUG) throw new Error(`Code »${CODE}« is not supported yet.`);
		return CODE;
	}

	/**
	 * Get the locale by country code.
	 * Useful e.g. if you don’t have the locale but want to get the language by country code.
	 * @param input The country code to get the locale from.
	 */
	public static getLocale(input : PSupportedCountryCodes) : PSupportedLocaleIds {
		switch (input) {
			case PSupportedCountryCodes.AT:
				return PSupportedLocaleIds.de_AT;

			case PSupportedCountryCodes.BE:
				return PSupportedLocaleIds.en_BE;

			case PSupportedCountryCodes.CH:
				return PSupportedLocaleIds.de_CH;

			case PSupportedCountryCodes.CZ:
				return PSupportedLocaleIds.en_CZ;

			case PSupportedCountryCodes.DE:
				return PSupportedLocaleIds.de_DE;

			case PSupportedCountryCodes.GB:
				return PSupportedLocaleIds.en_GB;

			case PSupportedCountryCodes.IT:
				return PSupportedLocaleIds.en_IT;

			case PSupportedCountryCodes.RO:
				return PSupportedLocaleIds.en_RO;

			case PSupportedCountryCodes.NL:
				return PSupportedLocaleIds.en_NL;

			case PSupportedCountryCodes.SE:
				return PSupportedLocaleIds.en_SE;

			case PSupportedCountryCodes.LU:
				return PSupportedLocaleIds.en_LU;

		}
	}

	/**
	 * Http authentication code. This value is set during runtime when users login data are available.
	 */
	public static HTTP_AUTH_CODE : string | null = null;

	/**
	 * The path to the log-in page. This can be changed by the application depending on current context.
	 */
	public static LOGIN_PATH : string;

	/**
	 * The platform the app is running on.
	 */
	public static platform : 'browser' | 'appAndroid' | 'appIOS' | null = null;

	/**
	 * Is the app being shown on a mobile application?
	 * @deprecated Use pWindowSizeService.windowWidthIsBelowBreakpoint(enums.BootstrapSize.LG) instead.
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public static get IS_MOBILE() : boolean {
		return Config.userAgentIsMobile || Config.windowWidth !== null && Config.windowWidth < 992;
	}

	/**
	 * Are web push notifications (firebase) enabled for the browser?
	 */
	public static WEB_PUSH_NOTIFICATIONS_ENABLED : boolean = false;

	/**
	 * @returns "name" is browser-name in lower case.
	 * 	"version" contains only the major browser version (so it can be returned as type "number").
	 */
	public static get browser() : BrowserInfo {
		return getBrowserInfoByUserAgent(window.navigator.userAgent);
	}

	/**
 	 * Get the mobile operating system
	 */
	public static getMobileOperatingSystem() : 'Android' | 'iOS' | 'unknown' {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const userAgent = window.navigator.userAgent || window.navigator.vendor;

		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (/android/i.test(userAgent)) {
			return 'Android';
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (/iPad|iPhone|iPod/.test(userAgent)) {
			return 'iOS';
		}

		return 'unknown';
	}
}

Config.initialize();
