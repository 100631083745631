import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PBaseClass } from '@plano/shared/base';

import { Config } from '@plano/shared/core/config';
import { PCropOnOverflowDirective } from '@plano/shared/core/directive/crop-on-overflow.directive';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { CorePipesModule } from '@plano/shared/core/pipe/core-pipes.module';
import { PRouterService } from '@plano/shared/core/router.service';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

@Component({
	selector: 'p-nav-back-headline',
	templateUrl: './p-nav-back-headline.component.html',
	styleUrls: ['./p-nav-back-headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [
		PCommonModule,
		PTooltipDirective,
		PCropOnOverflowDirective,
		FaIconComponent,
		CorePipesModule,
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PNavBackHeadlineComponent extends PBaseClass {

	/**
	 * label to be displayed
	 */
	@Input('label') public label : string | null = null;

	/**
	 * Icon to be displayed
	 */
	@Input() public icon : PlanoFaIconPoolKeys | null = null;

	@HostBinding('class.bg-light-cold')
	@HostBinding('class.w-100')
	@HostBinding('class.d-flex')
	@HostBinding('class.align-items-center')
	protected readonly _alwaysTrue = true;

	constructor(public pRouterService : PRouterService) {
		super();
	}

	public Config = Config;
}
