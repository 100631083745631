/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { SchedulingApiBirthday } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { SchedulingApiHolidaysHolidayItem, SchedulingApiLeave, SchedulingApiMember, SchedulingApiShift } from '@plano/shared/api';
import { PRouterService } from '@plano/shared/core/router.service';
import { Subject } from 'rxjs';

type HighlightItemType =
	SchedulingApiShift |
	SchedulingApiMember |
	SchedulingApiLeave |
	SchedulingApiHolidaysHolidayItem |
	SchedulingApiBirthday;

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class HighlightService {

	constructor(
		pRouterService : PRouterService,
	) {
		// When a navigation happens no item should be highlighted afterwards
		pRouterService.events.subscribe((navigationEvent) => {
			if (navigationEvent instanceof NavigationStart) {
				this.setHighlighted(null);
			}
		});
	}

	/**
	 * The element that defines what needs to be highlighted
	 */
	private _highlightedItem : HighlightItemType | null = null;

	private timestampOfLeavePart : number | null = null;
	public onChange : Subject<void> = new Subject<void>();

	/**
	 * Set provided item as the highlighted one
	 * @param input Highlighted Element
	 * @param timestampOfLeavePart Date of the clicked leave
	 */
	public setHighlighted(
		input : HighlightItemType | null,
		timestampOfLeavePart ?: number,
	) : void {
		if ((
			input instanceof SchedulingApiLeave ||
			input instanceof SchedulingApiHolidaysHolidayItem
		) && !timestampOfLeavePart) {
			throw new Error('timestampOfLeavePart must be defined for leave || holiday');
		}
		this.timestampOfLeavePart = timestampOfLeavePart ?? null;
		this._highlightedItem = input;
		this.onChange.next();
	}

	/**
	 * Clear all highlighting
	 */
	public clear() : void {
		this.setHighlighted(null);
	}

	/**
	 * Check if given item is the highlighted one
	 */
	public isHighlighted(
		input : HighlightItemType | null,
		timestampOfLeavePart ?: number,
	) : boolean {
		if (timestampOfLeavePart && timestampOfLeavePart !== this.timestampOfLeavePart) {
			return false;
		}
		return this._highlightedItem === input;
	}

	/**
	 * The element that defines what needs to be highlighted
	 */
	public get highlightedItem() : HighlightItemType | null {
		return this._highlightedItem;
	}

	/**
	 * Should the wish-icon be visible or not?
	 * The wish icon should be visible on the members list when a shift is highlighted,
	 * and a wish icon should be visible on the shift if a member is highlighted.
	 */
	public showWishIcon(item : HighlightItemType) : boolean {
		if (item instanceof SchedulingApiShift && this._highlightedItem instanceof SchedulingApiMember ||
			item instanceof SchedulingApiMember && this._highlightedItem instanceof SchedulingApiShift) return true;
		return false;
	}
}
