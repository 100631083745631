/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Currency } from '@adyen/adyen-web/dist/types/components/AmazonPay/types';
import { TransactionsSortedByEmum } from '@plano/client/sales/transactions/transactions.types';
import { ApiObjectWrapperExistingRawDataParams, ApiObjectWrapperNewItemParams, SchedulingApiBooking, SchedulingApiGiftCard, SchedulingApiMember, SchedulingApiServiceBase, SchedulingApiTransactionBase, SchedulingApiTransactionCreatedBy, SchedulingApiTransactionPaymentMethodType, SchedulingApiTransactionStatus, SchedulingApiTransactionType, SchedulingApiTransactionsBase } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ClientCurrency, PApiType } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiTransactions extends SchedulingApiTransactionsBase {

	/**
	 * @param objAttribute The attribute for which the sum should be calculated.
	 * @returns Calculates for all transactions in the list the sum of all `objAttribute` attribute.
	 */
	public getTotal(objAttribute : keyof SchedulingApiTransaction) : number {
		if (!this.length) return 0;
		const ARRAY = this.iterable();
		const LIST_OF_AMOUNTS = ARRAY.map((a) => a[objAttribute]);

		return LIST_OF_AMOUNTS.reduce((a, b) => a + b);
	}

	private _sumPositiveTransactions = new Data<ClientCurrency | undefined>(this.api);

	/**
	 * Sum of transactions with a positive `amount`. The returned value is 0 or positive.
	 */
	public get sumPositiveTransactions() : ClientCurrency | undefined {
		return this._sumPositiveTransactions.get(() => {
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (!this.api.isLoaded()) return undefined;

			const TRANSACTIONS = this.filterBy(item => (item.amount ?? 0) > 0);
			return TRANSACTIONS.getTotal('amount');
		});
	}

	private _sumNegativeTransactions = new Data<ClientCurrency | undefined>(this.api);

	/**
	 * Sum of transactions with a negative `amount`. The returned value is 0 or negative.
	 */
	public get sumNegativeTransactions() : ClientCurrency | undefined {
		return this._sumNegativeTransactions.get(() => {
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (!this.api.isLoaded()) return undefined;

			const TRANSACTIONS = this.filterBy(item => (item.amount ?? 0) < 0);
			return TRANSACTIONS.getTotal('amount');
		});
	}

	/**
	 * The amount-sum of positive transactions that contribute to the currently-paid status.
	 */
	public get totalPositiveCurrentlyPaidTransactions() : ClientCurrency | undefined {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return undefined;

		// This can happen when user changes filter settings that triggers a new api-call, so the list changes.
		// Note that this can be confusing when api-call was triggered by something else and does not change the result.
		if (this.api.isBackendOperationRunning) return undefined;

		const TRANSACTIONS = this.filterBy(item => item.affectsBookableCurrentlyPaid && (item.amount ?? 0) > 0);
		return TRANSACTIONS.getTotal('amount');
	}

	/**
	 * The amount-sum of negative transactions that contribute to the currently-paid status.
	 */
	public get totalNegativeCurrentlyPaidTransactions() : ClientCurrency | undefined {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return undefined;

		// This can happen when user changes filter settings that triggers a new api-call, so the list changes.
		// Note that this can be confusing when api-call was triggered by something else and does not change the result.
		if (this.api.isBackendOperationRunning) return undefined;

		const TRANSACTIONS = this.filterBy(item => item.affectsBookableCurrentlyPaid && (item.amount ?? 0) < 0);
		return TRANSACTIONS.getTotal('amount');
	}

	/**
	 * The `amount` resulting from all transactions (i.e. the sum of all `amount` values).
	 */
	public get amount() : ClientCurrency | undefined {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (!this.api.isLoaded()) return undefined;
		return this.getTotal('amount');
	}

	/**
	 * @returns The maximum amount which is refundable by online-payment.
	 * `null` means no online refund can be done at all because online-payment is not activated for this client.
	 */
	public get onlineRefundableAmount() : ClientCurrency | null {
		if (!this.api.data.isOnlinePaymentAvailable) return null;

		const onlinePaymentTransactions = this.filterBy(item => item.paymentMethodType === SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT);
		return onlinePaymentTransactions.getTotal('amount');
	}
}

type HintType = {
	text : PDictionarySource;
	link : string | null;
	fragment : string | null;
} | null;

/**
 * Custom extensions of the generated {@link SchedulingApiTransactionBase} class.
 */
export class SchedulingApiTransaction extends SchedulingApiTransactionBase {
	constructor(
		api : SchedulingApiServiceBase,
		parent : SchedulingApiTransactions | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<SchedulingApiTransaction> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null},
	) {
		super(api, parent, params);

		// For the case that a user wants to create a new transaction, we pre-fill the amount with a default value.
		// Therefore we need to know the bookable’s open amount.
		// This is not needed for existing transactions as well as transactions that are not related to a bookable like
		// `PAYOUT` or `AUTO_DEBIT`.
		if (this.bookable) {
			this.bookablePrevRefundableAmount = this.bookable.refundableAmount;
			this.bookablePrevOnlineRefundableAmount = this.bookable.transactions.onlineRefundableAmount;
		}
	}

	/**
	 * When the user creates a transaction for a bookable, this shows the overall refundable amount before this
	 * transaction was created.
	 */
	public readonly bookablePrevRefundableAmount : ClientCurrency | null = null;

	/**
	 * When the user creates a transaction for a bookable, this shows the overall *online* refundable amount before this
	 * transaction was created.
	 */
	public readonly bookablePrevOnlineRefundableAmount : ClientCurrency | null = null;

	/**
	 * @returns A human readable text describing the transaction type.
	 * The type is visualized by `typeTitle` and `typeSubTitle`.
	 */
	public get typeTitle() : string {
		switch (this.type) {
			case SchedulingApiTransactionType.CHARGEBACK:
			case SchedulingApiTransactionType.CHARGEBACK_REVERSED:
			case SchedulingApiTransactionType.SECOND_CHARGEBACK:
				return this.api.localizePipe.transform('Rückbuchung (Chargeback)');
			case SchedulingApiTransactionType.PAYMENT:
				return this.api.localizePipe.transform('Eingegangene Zahlung');
			case SchedulingApiTransactionType.PAYOUT:
				return this.api.localizePipe.transform('Auszahlung des Guthabens');
			case SchedulingApiTransactionType.REFUND:
				return this.api.localizePipe.transform('Rückerstattung');
			case SchedulingApiTransactionType.AUTO_DEBIT:
				return this.api.localizePipe.transform('Aufladung des Guthabens');
			case SchedulingApiTransactionType.PAYMENT_FAILED:
			case SchedulingApiTransactionType.PAYOUT_FAILED:
			case SchedulingApiTransactionType.REFUND_FAILED:
			case SchedulingApiTransactionType.AUTO_DEBIT_FAILED:
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION_CORRECTION:
				return this.api.localizePipe.transform('Korrektur');
			case SchedulingApiTransactionType.DR_PLANO_FEE_VAT:
				const vatPercent = this.api.percentPipe.transform(this.vatPercent, '0.0-1');
				assumeNonNull(vatPercent);
				return this.api.localizePipe.transform({sourceString: '${vatPercent} USt. auf die Online-Zahlungsgebühr', params: {vatPercent: vatPercent}});
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION:
				return this.api.localizePipe.transform('Gutschein-Einlösung');
		}
	}

	/**
	 * Should the user be able to set this property?
	 * @param newType The type that should be set.
	 */
	public transactionTypeSetPostCode(newType : SchedulingApiTransactionType) : void {
		if (newType === SchedulingApiTransactionType.PAYMENT || newType === SchedulingApiTransactionType.REFUND) {
			/*
			 * 	If type is PAYMENT, user can only use MISC as payment method type. We don’t show the related radio-buttons
			 * 	in the UI.
			 */
			if (newType === SchedulingApiTransactionType.PAYMENT) {
				this.data[this.api.consts.TRANSACTION_PAYMENT_METHOD_TYPE] = SchedulingApiTransactionPaymentMethodType.MISC;
			}
			this.data[this.api.consts.TRANSACTION_MISC_PAYMENT_METHOD_NAME] = null;

			// If a type is chosen the input for the amount should be pre-filled with a default value.
			this.absAmount = 0;
			assumeNonNull(this.bookable);

			if (newType === SchedulingApiTransactionType.PAYMENT) {
				const defaultAbsAmount = this.bookable.getOpenAmount();
				if (defaultAbsAmount !== null && defaultAbsAmount > 0) this.absAmount = defaultAbsAmount;
			} else {
				const openAmount = this.bookable.getOpenAmount();
				const defaultAbsAmount = openAmount === null ? null : -openAmount;
				if (defaultAbsAmount && defaultAbsAmount > 0) this.absAmount = defaultAbsAmount;
			}
		}
	}

	/**
	 * @returns A localized string describing the payment-method.
	 */
	public get paymentMethodName() : string | null {
		switch (this.paymentMethodType) {
			case SchedulingApiTransactionPaymentMethodType.MISC:
				// Can be null if this is a new item (e.g. in detail form of a new booking)
				return this.miscPaymentMethodName;

			case SchedulingApiTransactionPaymentMethodType.POS:
				return this.api.localizePipe.transform('Kassenzahlung');

			case SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT:
				return this.api.localizePipe.transform('Online-Zahlung');

			case SchedulingApiTransactionPaymentMethodType.GIFT_CARD:
				return this.api.localizePipe.transform('Gutschein');

			case SchedulingApiTransactionPaymentMethodType.MARKETING_GIFT_CARD:
				return this.api.localizePipe.transform('Marketing-Gutschein');
		}
	}

	/**
	 * Does this transaction affect `currentlyPaid` of a bookable?
	 */
	public get affectsBookableCurrentlyPaid() : boolean {
		// ATTENTION: Please also update TransactionLogic.getAffectsBookableCurrentlyPaid()
		return this.type === SchedulingApiTransactionType.PAYMENT ||
			this.type === SchedulingApiTransactionType.PAYMENT_FAILED ||
			this.type === SchedulingApiTransactionType.REFUND ||
			this.type === SchedulingApiTransactionType.REFUND_FAILED ||
			this.type === SchedulingApiTransactionType.CHARGEBACK ||
			this.type === SchedulingApiTransactionType.CHARGEBACK_REVERSED ||
			this.type === SchedulingApiTransactionType.SECOND_CHARGEBACK;
	}

	/**
	 * @returns Does this transaction has a child of type `CHARGEBACK`, `CHARGEBACK_REVERSED` or `SECOND_CHARGEBACK`?
	 */
	public get hasChildOfTypeChargeback() : boolean {
		return this.childChargebackId !== null ||
			this.childChargebackReversedId !== null ||
			this.childSecondChargebackId !== null;
	}

	// FIXME: PLANO-161893
	// eslint-disable-next-line no-restricted-syntax -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public aiPaymentMethodName = new ApiAttributeInfo<SchedulingApiTransaction, string>({
		apiObjWrapper: this,
		name: 'paymentMethodName',
		nodeName: 'TRANSACTION_PAYMENT_METHOD_NAME',
		type: PApiType.string,
	});

	/**
	 * @returns The bookable associated with this transaction item.
	 */
	public get bookable() : SchedulingApiBooking | SchedulingApiGiftCard | null {
		return this.booking ?? this.giftCard ?? null;
	}

	/**
	 * @returns The gift-card associated with this transaction item.
	 */
	public get giftCard() : SchedulingApiGiftCard | null {
		if (this.aiGiftCardId.isAvailable && this.giftCardId !== null) {
			return this.api.data.giftCards.get(this.giftCardId);
		} else {
			return null;
		}
	}

	/**
	 * @returns The gift-card associated with this transaction item.
	 */
	public get booking() : SchedulingApiBooking | null {
		if (this.aiBookingId.isAvailable && this.bookingId !== null) {
			return this.api.data.bookings.get(this.bookingId);
		} else {
			return null;
		}
	}

	/**
	 * @returns A human readable text describing the transaction type.
	 * The type is visualized by `typeTitle` and `typeSubTitle`.
	 */
	public get typeSubTitle() : string | null {
		switch (this.type) {
			case SchedulingApiTransactionType.CHARGEBACK:
			case SchedulingApiTransactionType.SECOND_CHARGEBACK:
			case SchedulingApiTransactionType.REFUND:
				if (!this.bookingPersonFirstName) return null;
				if (!this.bookingPersonLastName) return null;
				return this.api.localizePipe.transform({ sourceString: 'an ${firstName} ${lastName}', params: {
					firstName: this.bookingPersonFirstName,
					lastName: this.bookingPersonLastName,
				}});
			case SchedulingApiTransactionType.PAYMENT:
				if (!this.bookingPersonFirstName) return null;
				if (!this.bookingPersonLastName) return null;
				return this.api.localizePipe.transform({ sourceString: 'von ${firstName} ${lastName}', params: {
					firstName: this.bookingPersonFirstName,
					lastName: this.bookingPersonLastName,
				}});
			case SchedulingApiTransactionType.PAYOUT:
			case SchedulingApiTransactionType.AUTO_DEBIT:
				return this.api.localizePipe.transform('automatisch durch Dr.&nbsp;Plano');
			case SchedulingApiTransactionType.PAYMENT_FAILED:
				return this.api.localizePipe.transform('einer fehlgeschlagenen Einzahlung');
			case SchedulingApiTransactionType.PAYOUT_FAILED:
				return this.api.localizePipe.transform('einer fehlgeschlagenen Guthabenauszahlung');
			case SchedulingApiTransactionType.REFUND_FAILED:
				return this.api.localizePipe.transform('einer fehlgeschlagenen Rückerstattung');
			case SchedulingApiTransactionType.AUTO_DEBIT_FAILED:
				return this.api.localizePipe.transform('einer fehlgeschlagenen Guthaben-Aufladung');
			case SchedulingApiTransactionType.CHARGEBACK_REVERSED:
				return this.api.localizePipe.transform('einer angefochtenen Rückbuchung');
			case SchedulingApiTransactionType.DR_PLANO_FEE_VAT:
				const month = this.api.datePipe.transform(this.dateTime, 'MMMM yyyy');
				return this.api.localizePipe.transform({ sourceString: 'für ${month}', params: {month: month}});
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION:
				if (!this.bookingPersonFirstName) return null;
				if (!this.bookingPersonLastName) return null;
				return this.api.localizePipe.transform({ sourceString: 'durch ${firstName} ${lastName}', params: {
					firstName: this.bookingPersonFirstName,
					lastName: this.bookingPersonLastName,
				}});
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION_CORRECTION:
				return this.api.localizePipe.transform('einer Gutschein-Einlösung');
		}
	}

	// FIXME: PLANO-161893
	// eslint-disable-next-line no-restricted-syntax -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public aiAmount = new ApiAttributeInfo<SchedulingApiTransaction, Currency>({
		apiObjWrapper: this,
		name: 'amount',
		nodeName: 'TRANSACTION_AMOUNT',
		type: PApiType.ClientCurrency,
		hasPermissionToSet: () => false,
	});

	/**
	 * The amount of the transaction. In contrast to `absAmount` this value will be negative
	 * when money has been deduced from the account.
	 */
	public get amount() : ClientCurrency | null {
		if (this.aiAbsAmount.isAvailable === false) return null;
		if (this.aiAbsAmount.value === null) return null;

		switch (this.type) {
			case SchedulingApiTransactionType.PAYMENT:
			case SchedulingApiTransactionType.REFUND_FAILED:
			case SchedulingApiTransactionType.PAYOUT_FAILED:
			case SchedulingApiTransactionType.AUTO_DEBIT:
			case SchedulingApiTransactionType.CHARGEBACK_REVERSED:
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION_CORRECTION:
				return this.absAmount;

			case SchedulingApiTransactionType.PAYMENT_FAILED:
			case SchedulingApiTransactionType.REFUND:
			case SchedulingApiTransactionType.PAYOUT:
			case SchedulingApiTransactionType.CHARGEBACK:
			case SchedulingApiTransactionType.SECOND_CHARGEBACK:
			case SchedulingApiTransactionType.AUTO_DEBIT_FAILED:
			case SchedulingApiTransactionType.DR_PLANO_FEE_VAT:
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION:
				return -this.absAmount;
		}
	}

	/**
	 * The bookingNumber that the backend sends us is sometimes related to a booking, sometimes to a gift-card.
	 * Here we need to find out which one it is to generate a link for that entry.
	 */
	public get bookingNumberLink() : {
		link : `/client/${'gift-card' | 'booking'}/${string}/transactions`,
		fragment : string,
	} | null {
		// If there is no bookableId, then there is also no bookingNumber which can be linked to something.
		if (!this.aiBookableId.isAvailable || !this.bookableId) return null;

		if (this.aiBookingId.isAvailable && this.bookingId && this.bookableId.equals(this.bookingId)) {
			return {
				link: `/client/booking/${this.bookingId.toString()}/transactions`,
				fragment: this.bookingId.toString(),
			};
		} else {
			// The bookableId was not null and it was not a booking.
			// So it must be a gift-card.
			assumeNonNull(this.giftCardId);
			return {
				link: `/client/gift-card/${this.giftCardId.toString()}/transactions`,
				fragment: this.giftCardId.toString(),
			};
		}
	}

	/**
	 * The member who created the transaction.
	 */
	public get creator() : SchedulingApiMember | null {
		const creatorId = this.creatorId;
		return this.api.data.members.get(creatorId);
	}

	/**
	 * Get a label for the created by enum value.
	 * @param input - The enum value for which the label should be returned.
	 */
	public static getCreatedByLabel(input : SchedulingApiTransactionCreatedBy) : PDictionarySource {
		switch (input) {
			case SchedulingApiTransactionCreatedBy.DR_PLANO_PAYMENT_PAGE:
				return 'Payment-Seite';
			case SchedulingApiTransactionCreatedBy.BOULDERADO:
				return 'Boulderado';
			case SchedulingApiTransactionCreatedBy.BETA_7:
				return 'BETA7';
			case SchedulingApiTransactionCreatedBy.BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION:
				return 'Aktivierung: Gutschein-Synchronisation';
			case SchedulingApiTransactionCreatedBy.DR_PLANO_USER:
				return 'Dr.&nbsp;Plano-User';
			case SchedulingApiTransactionCreatedBy.FREECLIMBER:
				return 'Freeclimber';
			case SchedulingApiTransactionCreatedBy.RELEASE_GIFT_CARD_REDEMPTION_TRANSACTIONS:
				return 'Release: Gutschein-Einlösung';
			case SchedulingApiTransactionCreatedBy.DR_PLANO:
				return 'Dr.&nbsp;Plano';
			case SchedulingApiTransactionCreatedBy.ONLINE_CANCELLATION:
				return 'Online-Storno durch Kunden';
		}
	}

	/**
	 * Get a label for the status enum value.
	 * @param input - The enum value for which the label should be returned.
	 */
	public static getStatusLabel(input : SchedulingApiTransactionStatus) : PDictionarySource {
		switch (input) {
			case SchedulingApiTransactionStatus.SUCCESSFUL:
				return 'erfolgreich';
			case SchedulingApiTransactionStatus.FAILED:
				return 'fehlgeschlagen';
			case SchedulingApiTransactionStatus.CORRECTION:
				return 'Korrektur';
		}
	}

	/**
	 * Get a label for the status enum value.
	 * @param input - The enum value for which the label should be returned.
	 */
	public static getPaymentMethodTypeLabel(input : SchedulingApiTransactionPaymentMethodType) : PDictionarySource {
		// NOTE: These translations should match the ones for the backend in TransactionsToExcel.getPaymentMethodTypeLabel()
		switch (input) {
			case SchedulingApiTransactionPaymentMethodType.MISC:
				return 'Benutzerdefinierte Zahlungsarten';
			case SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT:
				return 'Online-Zahlung';
			case SchedulingApiTransactionPaymentMethodType.POS:
				return 'Kassenzahlung';
			case SchedulingApiTransactionPaymentMethodType.GIFT_CARD:
				return 'Gutschein';
			case SchedulingApiTransactionPaymentMethodType.MARKETING_GIFT_CARD:
				return 'Marketing-Gutschein';
		}
	}

	private get hintForOfferName() : HintType {
		// If we can not get the offer name, it can not have a hint.
		if (!this.aiOfferName.isAvailable) return null;

		if (this.offerName !== null) return null;
		switch (this.createdBy) {
			case SchedulingApiTransactionCreatedBy.BOULDERADO:
				return {
					text : 'Die Kassensoftware Boulderado übermittelte hierzu keine Daten.',
					link : '/client/plugin/gift-cards/faq',
					fragment: 'faq-pos-redemption-linked-items',
				};
			case SchedulingApiTransactionCreatedBy.RELEASE_GIFT_CARD_REDEMPTION_TRANSACTIONS:
				return {
					text : 'Bei einer automatisch erstellten Gutschein-Einlösungen liegen hierfür keine Daten vor.',
					link: '/client/plugin/gift-cards/faq',
					fragment: 'faq-corrections-after-release',
				};
			case SchedulingApiTransactionCreatedBy.BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION:
				return {
					text : 'Bei einer automatisch erstellten Gutschein-Einlösungen liegen hierfür keine Daten vor.',
					link: '/client/plugin/gift-cards/faq',
					fragment: 'faq-corrections-after-pos-sync',
				};
			case SchedulingApiTransactionCreatedBy.DR_PLANO_USER:
				if (this.paymentMethodType === SchedulingApiTransactionPaymentMethodType.GIFT_CARD) return {
					text : 'Diese manuell erfasste Gutschein-Einlösung wurde keiner Buchung zugeordnet.',
					link : null,
					fragment: null,
				};
				return null;
			case SchedulingApiTransactionCreatedBy.DR_PLANO:
			case SchedulingApiTransactionCreatedBy.DR_PLANO_PAYMENT_PAGE:
			case SchedulingApiTransactionCreatedBy.ONLINE_CANCELLATION:
			case SchedulingApiTransactionCreatedBy.FREECLIMBER:
			case SchedulingApiTransactionCreatedBy.BETA_7:
				return null;
		}
	}

	private get hintForBookingNumber() : HintType {
		switch (this.type) {
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION:
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION_CORRECTION:
				// eslint-disable-next-line sonarjs/no-nested-switch -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				switch (this.createdBy) {
					case SchedulingApiTransactionCreatedBy.BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION:
						if (this.bookingNumber !== null) return null;
						return {
							text : 'Bei einer automatisch erstellten Gutschein-Einlösungen liegen hierfür keine Daten vor.',
							link: '/client/plugin/gift-cards/faq',
							fragment: 'faq-corrections-after-pos-sync',
						};
					case SchedulingApiTransactionCreatedBy.RELEASE_GIFT_CARD_REDEMPTION_TRANSACTIONS:
						if (this.bookingNumber !== null) return null;
						return {
							text : 'Bei einer automatisch erstellten Gutschein-Einlösungen liegen hierfür keine Daten vor.',
							link: '/client/plugin/gift-cards/faq',
							fragment: 'faq-corrections-after-release',
						};
					case SchedulingApiTransactionCreatedBy.BOULDERADO:
						if (this.bookingNumber !== null) return null;
						return {
							text : 'Die Kassensoftware Boulderado übermittelte hierzu keine Daten.',
							link : '/client/plugin/gift-cards/faq',
							fragment: 'faq-pos-redemption-linked-items',
						};
					case SchedulingApiTransactionCreatedBy.DR_PLANO_USER:
					case SchedulingApiTransactionCreatedBy.DR_PLANO:
					case SchedulingApiTransactionCreatedBy.DR_PLANO_PAYMENT_PAGE:
					case SchedulingApiTransactionCreatedBy.ONLINE_CANCELLATION:
					case SchedulingApiTransactionCreatedBy.FREECLIMBER:
					case SchedulingApiTransactionCreatedBy.BETA_7:
						return null;
				}
				return null;
			case SchedulingApiTransactionType.PAYMENT:
			case SchedulingApiTransactionType.PAYMENT_FAILED:
			case SchedulingApiTransactionType.REFUND:
			case SchedulingApiTransactionType.REFUND_FAILED:
			case SchedulingApiTransactionType.PAYOUT:
			case SchedulingApiTransactionType.PAYOUT_FAILED:
			case SchedulingApiTransactionType.CHARGEBACK:
			case SchedulingApiTransactionType.CHARGEBACK_REVERSED:
			case SchedulingApiTransactionType.SECOND_CHARGEBACK:
			case SchedulingApiTransactionType.AUTO_DEBIT:
			case SchedulingApiTransactionType.AUTO_DEBIT_FAILED:
			case SchedulingApiTransactionType.DR_PLANO_FEE_VAT:
				return null;
		}
	}

	private get hintForType() : HintType {
		return this.hintForBookingNumber;
	}

	private get hintForTypeSubTitle() : HintType {
		if (this.typeSubTitle) return null;
		switch (this.type) {
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION:
			case SchedulingApiTransactionType.GIFT_CARD_REDEMPTION_CORRECTION:
			case SchedulingApiTransactionType.PAYMENT:
				// eslint-disable-next-line sonarjs/no-nested-switch -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				switch (this.createdBy) {
					case SchedulingApiTransactionCreatedBy.BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION:
						return {
							text : 'Bei einer automatisch erstellten Gutschein-Einlösungen liegen hierfür keine Daten vor.',
							link: '/client/plugin/gift-cards/faq',
							fragment: 'faq-corrections-after-pos-sync',
						};
					case SchedulingApiTransactionCreatedBy.RELEASE_GIFT_CARD_REDEMPTION_TRANSACTIONS:
						return {
							text : 'Bei einer automatisch erstellten Gutschein-Einlösungen liegen hierfür keine Daten vor.',
							link: '/client/plugin/gift-cards/faq',
							fragment: 'faq-corrections-after-release',
						};
					case SchedulingApiTransactionCreatedBy.BOULDERADO:
						return {
							text : 'Die Kassensoftware Boulderado übermittelte hierzu keine Daten.',
							link : '/client/plugin/gift-cards/faq',
							fragment: 'faq-pos-redemption-linked-items',
						};
					case SchedulingApiTransactionCreatedBy.DR_PLANO_USER:
					case SchedulingApiTransactionCreatedBy.DR_PLANO:
					case SchedulingApiTransactionCreatedBy.DR_PLANO_PAYMENT_PAGE:
					case SchedulingApiTransactionCreatedBy.ONLINE_CANCELLATION:
					case SchedulingApiTransactionCreatedBy.FREECLIMBER:
					case SchedulingApiTransactionCreatedBy.BETA_7:
						return null;
				}
				return null;
			case SchedulingApiTransactionType.PAYMENT_FAILED:
			case SchedulingApiTransactionType.REFUND:
			case SchedulingApiTransactionType.REFUND_FAILED:
			case SchedulingApiTransactionType.PAYOUT:
			case SchedulingApiTransactionType.PAYOUT_FAILED:
			case SchedulingApiTransactionType.CHARGEBACK:
			case SchedulingApiTransactionType.CHARGEBACK_REVERSED:
			case SchedulingApiTransactionType.SECOND_CHARGEBACK:
			case SchedulingApiTransactionType.AUTO_DEBIT:
			case SchedulingApiTransactionType.AUTO_DEBIT_FAILED:
			case SchedulingApiTransactionType.DR_PLANO_FEE_VAT:
				return null;
		}
	}

	/**
	 * Get a description for the creator of this transaction.
	 */
	private get hintForCreatedBy() : HintType {
		switch (this.createdBy) {
			case SchedulingApiTransactionCreatedBy.BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION:

				if (this.type === SchedulingApiTransactionType.PAYMENT) {
					return {
						text: 'Diese eingegangene Zahlung wurde automatisch von Dr.&nbsp;Plano erstellt, um die – im Zuge der Aktivierung der Gutschein-Synchronisation – aus Boulderado kommenden Gutscheine als bezahlt und damit einlösbar markieren zu können.',
						link: '/client/plugin/gift-cards/faq',
						fragment: 'faq-corrections-after-pos-sync',
					};
				}
				if (this.type === SchedulingApiTransactionType.GIFT_CARD_REDEMPTION) {
					return {
						text: 'Bei der Aktivierung der Gutschein-Synchronisation meldete Boulderado für den hier betroffenen Gutschein ein Restguthaben, das geringer war als das in Dr.&nbsp;Plano hinterlegte Restguthaben. Daher erstellte Dr.&nbsp;Plano automatisch diese Einlösungstransaktion, um auf das niedrigere Restguthaben zu kommen.',
						link: '/client/plugin/gift-cards/faq',
						fragment: 'faq-corrections-after-pos-sync',
					};
				}
				return null;

			case SchedulingApiTransactionCreatedBy.RELEASE_GIFT_CARD_REDEMPTION_TRANSACTIONS:
				return {
					text: {
						sourceString: 'Seit dem Release vom <mark>${date}</mark> werden für alle Gutschein-Einlösungen Transaktionen erstellt. Der hier betroffene Gutschein hatte zum Release-Zeitpunkt ein Restguthaben, das geringer war als sein Startguthaben. Daher erstellte Dr.&nbsp;Plano automatisch diese Einlösungstransaktion, um vom Startguthaben auf das niedrigere Restguthaben zu kommen.',
						params: {
							date: this.api.datePipe.transform(Config.GIFT_CARDS_RELEASE_DATE, 'shortDate'),
						},
					},
					link: '/client/plugin/gift-cards/faq',
					fragment: 'faq-corrections-after-release',
				};
			case SchedulingApiTransactionCreatedBy.DR_PLANO_USER:
				if (this.creator) {
					return {
						text: {
							sourceString: 'In Dr.&nbsp;Plano manuell erstellt durch ${firstName} ${lastName}.',
							params: {
								firstName: this.creator.firstName,
								lastName: this.creator.lastName,
							},
						},
						link: null,
						fragment: null,
					};
				}
				return null;
			case SchedulingApiTransactionCreatedBy.DR_PLANO_PAYMENT_PAGE:
				return {
					text: 'Bei kostenpflichtigen Buchungen werden deine Kunden auf die Payment-Seite von Dr.&nbsp;Plano weitergeleitet, wo sie über die Zahlungsart ihrer Wahl bezahlen können. Bei Buchungsanfragen oder anderen Zahlungsarten als die Online-Zahlung entscheiden sich deine Kunden auf der Payment-Seite für eine Zahlungsart, aber bezahlen erst später.',
					link: '/client/plugin/basis',
					fragment: 'customize-your-payment-page',
				};
			case SchedulingApiTransactionCreatedBy.ONLINE_CANCELLATION:
				return {
					text: 'Entsprechend der Einstellungen in der betroffenen Tätigkeit hat Dr.&nbsp;Plano automatisch diese Rückzahlung per Gutschein-Ausstellung vorgenommen.',
					link: '/client/plugin/gift-cards/faq',
					fragment: 'faq-refund-gift-cards',
				};
			case SchedulingApiTransactionCreatedBy.BOULDERADO:
			case SchedulingApiTransactionCreatedBy.FREECLIMBER:
			case SchedulingApiTransactionCreatedBy.BETA_7:
			case SchedulingApiTransactionCreatedBy.DR_PLANO:
				return null;
		}
	}

	/**
	 * What is the reason that we dont have any information in that area?
	 * @param area - The requested area
	 */
	public hint(
		area : TransactionsSortedByEmum | 'type-sub-title',
	) : HintType {
		switch (area) {
			case TransactionsSortedByEmum.OFFER_NAME:
				return this.hintForOfferName;
			case TransactionsSortedByEmum.BOOKING_NUMBER:
				return this.hintForBookingNumber;
			case TransactionsSortedByEmum.TYPE:
				return this.hintForType;
			case TransactionsSortedByEmum.CREATED_BY:
				return this.hintForCreatedBy;
			case 'type-sub-title':
				return this.hintForTypeSubTitle;
			case TransactionsSortedByEmum.DATE_TIME:
			case TransactionsSortedByEmum.AMOUNT:
			case TransactionsSortedByEmum.PAYMENT_METHOD_TYPE:
			case TransactionsSortedByEmum.INTERNAL_NOTES:
				return null;
		}
	}

	/**
	 * Automatically generated items get highlighted in the list.
	 */
	public get isAutomaticallyCreated() : boolean {
		return (
			this.createdBy === SchedulingApiTransactionCreatedBy.RELEASE_GIFT_CARD_REDEMPTION_TRANSACTIONS ||
			this.createdBy === SchedulingApiTransactionCreatedBy.BOULDERADO_ACTIVATE_GIFT_CARD_SYNCHRONIZATION
		);
	}
}
