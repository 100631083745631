import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { menuNavigationItemsOrder } from '@plano/client/shared/menu-navigation-items';
import { PBaseClass } from '@plano/shared/base';
import { PHeadlineComponent } from '@plano/shared/core/component/p-headline/p-headline.component';
import { LogService } from '@plano/shared/core/log.service';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { CorePipesModule } from '@plano/shared/core/pipe/core-pipes.module';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/**
 * A headline for a page.
 */
@Component({
	selector: 'p-page-headline',
	templateUrl: './page-headline.component.html',
	styleUrls: ['./page-headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [
		PCommonModule,
		CorePipesModule,
		FaIconComponent,
		PHeadlineComponent,
	],
})
export class PPageHeadlineComponent extends PBaseClass implements AfterViewInit {

	/**
	 * Headline text to be displayed
	 */
	@Input() public headline : string | null = null;

	/** @see PHeadlineComponent#level */
	@Input('headlineLevel') private _headlineLevel : typeof this.headlineLevel | null = null;

	/**
	 * Sub-Headline which usually is giving more context to the headline
	 */
	@Input() public subHeadline : string | null = null;

	/**
	 * Icon to be displayed next to the headline.
	 * If a {@link subHeadline} is given, the icon will be placed at the {@link subHeadline}.
	 */
	@Input('headlineIcon') private _headlineIcon : PlanoFaIconPoolKeys | null = null;

	/**
	 * The icon for the {@link subHeadline}.
	 * If none is defined, the {@link headlineIcon} will be used instead.
	 */
	@Input() protected subHeadlineIcon : PlanoFaIconPoolKeys | null = null;

	/**
	 * Content to be displayed instead of headline
	 */
	@ViewChild('contentWrapper', { static: true }) public ngContent ! : ElementRef<HTMLElement>;

	constructor(
		private console : LogService,
	) {
		super();
	}

	/**
	 * The level of the headline to be used in the template.
	 */
	protected get headlineLevel() : PHeadlineComponent['level'] {
		return this._headlineLevel ?? (this.subHeadline === null ? 2 : 3);
	}

	public ngAfterViewInit() {
		if (!this.headline && !(this.ngContent.nativeElement.textContent?.length)) {
			this.console.error('Headline or ng-content must be provided');
		}
	}

	/**
	 * Get headline icon for page
	 */
	public get headlineIcon() : PlanoFaIconPoolKeys | null {
		if (!this._headlineIcon) {
			const menuItem = menuNavigationItemsOrder.find(item => item.label === this.headline);
			if (!menuItem?.icon)
				return null;
			this._headlineIcon = menuItem.icon;
		}
		return this._headlineIcon;
	}
}
