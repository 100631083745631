import { inject, NgModule } from '@angular/core';
import { CanActivateFn, Router, RouterModule } from '@angular/router';
import { MeService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PIndexedDBService } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { callOnlyOnceOnSubscribe } from '@plano/shared/core/utils/rxjs-utils';
import { PRoutes } from './template-page-title-strategy.service';

const canActivateClientGuard : CanActivateFn = async () => {
	const meService = inject(MeService);
	const router = inject(Router);

	// eslint-disable-next-line deprecation/deprecation -- need to check if user is logged in
	if (!meService.isLoaded()) {

		// The login form of the client area is on the public page
		// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
		Config.LOGIN_PATH = Config.IS_MOBILE ? '/mobile-login' : '/';

		try {
			const waitForUserDatabaseOpenPromise = new Promise(resolve => {
				const pIndexedDBService = inject(PIndexedDBService);
				callOnlyOnceOnSubscribe(pIndexedDBService.openedUserDatabase, () => {
					resolve(true);
				});
			});
			await meService.loginFromIndexedDBCredentials();
			if (meService.showExpiredClientViewForOwner) {
				return router.parseUrl('/client/testaccount');
			}
			if (meService.showExpiredClientViewForDefaultMember) {
				return router.parseUrl(`${Config.LOGIN_PATH}#login`);
			}
			if (!meService.urlCountryMatchesUserCountry(window.location.href.split('#')[0])) {
				const fragment = window.location.href.split('#').at(1);
				window.location.href = meService.ensureCorrectFrontendCountryVersion(window.location.href.split('#')[0]) + (fragment ? `#${fragment}` : '');
			}
			await waitForUserDatabaseOpenPromise;
		} catch {
			if (meService.loggedInFromLoginForm) return false;
			meService.rememberPathWhenLoginFailed();
			return router.parseUrl(Config.LOGIN_PATH);
		}
	}
	return true;
};

export const ROUTES = [
	{ path: 'client', canActivate: [canActivateClientGuard] , loadChildren: () => import('./client/client.module').then( (m) => m.ClientModule ), loadComponent: () => import('./client/client.component').then(c => c.ClientComponent) },
	{ path: 'admin', loadChildren: () => import('./admin/admin.module').then( (m) => m.AdminModule ) },
	{ path: 'storybook', loadChildren: () => import('./p-storybook/p-storybook-routing.module').then( (m) => m.PStorybookRoutingModule)},
	{ path: '', loadChildren: () => import('./public/public.module').then( (m) => m.PublicModule ) },
] satisfies PRoutes;

@NgModule({
	imports: [RouterModule.forRoot(ROUTES, { useHash: false, anchorScrolling: 'enabled'})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
