/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

import { ClientsApiClient } from '@plano/shared/api';


/**
 * This service enables access to the api "admin/clients".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	CLIENT_COMPANY_NAME = 1;
	CLIENT_LOCATION_NAME = 2;
	CLIENT_TYPE = 3;
	CLIENT_TEST_ACCOUNT_DEADLINE = 4;
	CLIENT_TERMINATION_DATE = 5;
	CLIENT_PAID_ACCOUNT_DATE = 6;
	CLIENT_LOCATION_COUNTRY = 7;
	CLIENT_ADYEN_ACCOUNT = 8;
	CLIENT_BILLING = 9;
	CLIENT_VERIFICATION_DATE = 10;
	CLIENT_DISCOUNT_CODE = 11;
	CLIENT_BILLING_COUNTRY = 1;
	CLIENT_BILLING_HAS_TO_PAY_VAT = 2;
	CLIENT_BILLING_DISABLE_INVOICE_CREATION = 3;
	CLIENT_BILLING_DISCOUNT_TYPE = 4;
	CLIENT_BILLING_VAT_NUMBER = 5;
	CLIENT_BILLING_DISCOUNT_VALUE = 6;
	CLIENT_BILLING_DISCOUNT_START = 7;
	CLIENT_BILLING_DISCOUNT_END = 8;
	CLIENT_BILLING_BILLED_MEMBERS_COUNT_MAX = 9;
	CLIENT_BILLING_BILLED_MEMBERS_COUNT_SUBTRACT = 10;
	CLIENT_BILLING_DISCOUNT_COMMENT = 11;
	CLIENT_BILLING_CREDIT = 12;
	CLIENT_BILLING_EXEMPT_GIFT_CARD_SALES_FEES = 13;
	CLIENT_ADYEN_ACCOUNT_AUTO_DEBIT_ENABLED = 1;
	CLIENT_ADYEN_ACCOUNT_BALANCE = 2;
	CLIENT_ADYEN_ACCOUNT_DESIRED_DEPOSIT = 3;
	CLIENT_ADYEN_ACCOUNT_ONBOARDING_PASSED_DATE = 4;
	CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_CODE = 5;
	CLIENT_ADYEN_ACCOUNT_LAST_TRANSFER_FUNDS_EXECUTION_DATE = 6;
	CLIENT_ADYEN_ACCOUNT_LAST_AUTO_DEBIT_DATE = 7;
	CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PAYOUT_STATE = 8;
	CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PROCESSING_STATE = 9;
	CLIENT_ADYEN_ACCOUNT_OUTSTANDING_TRANSFER_FUNDS = 10;
	CLIENT_ADYEN_ACCOUNT_TRIGGER_PAYOUT_PROCESS = 11;
	CLIENT_ADYEN_ACCOUNT_MARK_ADYEN_EVENT_AS_SETTLED = 12;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK = 13;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_FAILURE_SINCE = 1;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_EXPECTED_BALANCE = 2;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_BALANCE = 3;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_DIFF = 4;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_PENDING_BALANCE = 5;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_ON_HOLD_BALANCE = 6;
}


@Injectable({
  providedIn: 'root',
})
export class ClientsApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'admin/clients');
	}

	consts = new Consts();

	protected version() : string {
		return 'a01a54a4f3713ceda081f1b8ab000de7,fc0451b7b5d851c1a4e09989ac2ec56b';
	}

	private dataWrapper = new ClientsApiRoot(this, false, false);

	get data() : ClientsApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ClientsApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ClientsApiRoot(this, false, false);
	}
}

		 export class ClientsApiRoot extends ApiListWrapper<ClientsApiClient>
{
	constructor(override readonly api : ClientsApiService,
		
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, null, isView, removeDestroyedItems, '');
	}

	override wrapRawData(itemRawData : any) : ClientsApiClient {
		return new ClientsApiClient(this.api, this as unknown as ClientsApiRoot, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ApiDataWrapperBase | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ClientsApiRoot(this.api, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '1';
	}

	override createNewItem(_initCode : ((newItem : ClientsApiClient) => void) | null = null, _backendId : Id | null = null) : ClientsApiClient {
		const newItem = new ClientsApiClient(this.api, this as unknown as ClientsApiRoot, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<ClientsApiRoot, ClientsApiRoot> = new ApiAttributeInfo<ClientsApiRoot, ClientsApiRoot>({
			apiObjWrapper: this as any as ClientsApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiList,
			hasPermissionToGet: function(this : ClientsApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			hasPermissionToSet: function(this : ClientsApiRoot) {
				{const conditionValue = this.api.pPermissionsService.userIs(AuthenticatedApiRole.SUPER_ADMIN); if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : ClientsApiRoot, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			
		});
}

				 
export class ClientsApiClientBase extends ApiObjectWrapper<ClientsApiClient>
{
	constructor(override readonly api : ClientsApiService,
		override readonly parent : ClientsApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ClientsApiClient> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClient as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ClientsApiClient, ClientsApiClient> = new ApiAttributeInfo<ClientsApiClient, ClientsApiClient>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'client',
			nodeName: 'CLIENT',
			type: PApiType.ApiObject,
			defaultValue: function(this : ClientsApiClient, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiCompanyName : ApiAttributeInfo<ClientsApiClient, string> = new ApiAttributeInfo<ClientsApiClient, string>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'companyName',
			nodeName: 'CLIENT_COMPANY_NAME',
			type: PApiType.string,
			validations: function(this : ClientsApiClient) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiLocationName : ApiAttributeInfo<ClientsApiClient, string> = new ApiAttributeInfo<ClientsApiClient, string>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'locationName',
			nodeName: 'CLIENT_LOCATION_NAME',
			type: PApiType.string,
			validations: function(this : ClientsApiClient) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiType : ApiAttributeInfo<ClientsApiClient, ClientsApiType> = new ApiAttributeInfo<ClientsApiClient, ClientsApiType>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'type',
			nodeName: 'CLIENT_TYPE',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiTestAccountDeadline : ApiAttributeInfo<ClientsApiClient, DateExclusiveEnd> = new ApiAttributeInfo<ClientsApiClient, DateExclusiveEnd>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'testAccountDeadline',
			nodeName: 'CLIENT_TEST_ACCOUNT_DEADLINE',
			type: PApiType.DateExclusiveEnd,
			canSetByBusinessLogic: function(this : ClientsApiClient) {
				{const conditionValue = this.type === ClientsApiType.TEST || this.type === ClientsApiType.TEST_EXPIRED; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClient) {
				return [
					() => {
return this.api.validators.min(() => this.verificationDate, true, PApiType.DateExclusiveEnd, this.aiVerificationDate.id, undefined);						return null;
					},
					() => {
		if(((this.type !== ClientsApiType.TEST_NEED_VERIFICATION)))
		{
			return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiTerminationDate : ApiAttributeInfo<ClientsApiClient, Date> = new ApiAttributeInfo<ClientsApiClient, Date>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'terminationDate',
			nodeName: 'CLIENT_TERMINATION_DATE',
			type: PApiType.Date,
			canSetByBusinessLogic: function(this : ClientsApiClient) {
				{const conditionValue = this.type === ClientsApiType.PAID; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 5,
		});
	aiPaidAccountDate : ApiAttributeInfo<ClientsApiClient, DateTime> = new ApiAttributeInfo<ClientsApiClient, DateTime>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'paidAccountDate',
			nodeName: 'CLIENT_PAID_ACCOUNT_DATE',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiLocationCountry : ApiAttributeInfo<ClientsApiClient, ClientsApiCountry> = new ApiAttributeInfo<ClientsApiClient, ClientsApiCountry>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'locationCountry',
			nodeName: 'CLIENT_LOCATION_COUNTRY',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});
	aiVerificationDate : ApiAttributeInfo<ClientsApiClient, DateTime> = new ApiAttributeInfo<ClientsApiClient, DateTime>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'verificationDate',
			nodeName: 'CLIENT_VERIFICATION_DATE',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 10,
		});
	aiDiscountCode : ApiAttributeInfo<ClientsApiClient, string> = new ApiAttributeInfo<ClientsApiClient, string>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'discountCode',
			nodeName: 'CLIENT_DISCOUNT_CODE',
			type: PApiType.string,
			isDetailedAttribute: true,
			validations: function(this : ClientsApiClient) {
				return [
					() => {
						return this.api.validators.maxLength(50, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});

	/**
	 * Name of the client.
	 *
	 * @type {string}
	 */
	get companyName() : string {
		this.getterDebugValidations(this.aiCompanyName, false);
		return this.data[1];
	}

	set companyName(v : string) {
        this.setterImpl(1, v, 'companyName', false, null, null);
	}

	/**
	 * Location Name of the client.
	 *
	 * @type {string}
	 */
	get locationName() : string {
		this.getterDebugValidations(this.aiLocationName, false);
		return this.data[2];
	}

	set locationName(v : string) {
        this.setterImpl(2, v, 'locationName', false, null, null);
	}

	/**
	 * Client type. Read-only
	 *
	 * @type {ClientsApiType}
	 */
	get type() : ClientsApiType {
		this.getterDebugValidations(this.aiType, false);
		return this.data[3];
	}

	set typeTestSetter(v : ClientsApiType) {
        this.setterImpl(3, v, 'type', true, null, null);
	}

	/**
	 * Deadline of the test account.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get testAccountDeadline() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiTestAccountDeadline, false);
		return this.data[4];
	}

	set testAccountDeadline(v : DateExclusiveEnd | null) {
        this.setterImpl(4, v, 'testAccountDeadline', false, null, null);
	}

	/**
	 * Date when the account will get terminated.
	 *
	 * @type {Date}
	 */
	get terminationDate() : Date | null {
		this.getterDebugValidations(this.aiTerminationDate, false);
		return this.data[5];
	}

	set terminationDate(v : Date | null) {
        this.setterImpl(5, v, 'terminationDate', false, null, null);
	}

	/**
	 * Date when the account was transformed into a paid account.
	 *
	 * @type {DateTime}
	 */
	get paidAccountDate() : DateTime | null {
		this.getterDebugValidations(this.aiPaidAccountDate, false);
		return this.data[6];
	}

	set paidAccountDateTestSetter(v : DateTime | null) {
        this.setterImpl(6, v, 'paidAccountDate', true, null, null);
	}

	/**
	 * The location country of this client. Read-only.
	 *
	 * @type {ClientsApiCountry}
	 */
	get locationCountry() : ClientsApiCountry {
		this.getterDebugValidations(this.aiLocationCountry, false);
		return this.data[7];
	}

	set locationCountryTestSetter(v : ClientsApiCountry) {
        this.setterImpl(7, v, 'locationCountry', true, null, null);
	}

	private adyenAccountWrapper : ClientsApiClientAdyenAccount = new ClientsApiClientAdyenAccount(this.api,
		this as unknown as ClientsApiClient);
	public aiAdyenAccount = this.adyenAccountWrapper.aiThis;

	/**
	 * Information about Online-Payment.
	 */
	get adyenAccount() : ClientsApiClientAdyenAccount {
		this.getterDebugValidations(this.aiAdyenAccount, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[8] === undefined) {
			this.data[8] = this.aiAdyenAccount.defaultValue;
			this.adyenAccountWrapper._updateRawData(this.data[8], true);
		}
		return this.adyenAccountWrapper;
	}

	set adyenAccountTestSetter(v : ClientsApiClientAdyenAccount) {
        this.setterImpl(8, v.rawData, 'adyenAccount', true, null, function(this : ClientsApiClient) {this.adyenAccountWrapper = v;});
	}

	private billingWrapper : ClientsApiClientBilling = new ClientsApiClientBilling(this.api,
		this as unknown as ClientsApiClient);
	public aiBilling = this.billingWrapper.aiThis;

	/**
	 * Information about billing of this client.
	 */
	get billing() : ClientsApiClientBilling {
		this.getterDebugValidations(this.aiBilling, false);
		return this.billingWrapper;
	}

	set billingTestSetter(v : ClientsApiClientBilling) {
        this.setterImpl(9, v.rawData, 'billing', true, null, function(this : ClientsApiClient) {this.billingWrapper = v;});
	}

	/**
	 * (Detail) When has the test-account been verified?
	 *
	 * @type {DateTime}
	 */
	get verificationDate() : DateTime | null {
		this.getterDebugValidations(this.aiVerificationDate, false);
		return this.data[10];
	}

	set verificationDateTestSetter(v : DateTime | null) {
        this.setterImpl(10, v, 'verificationDate', true, null, null);
	}

	/**
	 * (Detail) The discount-code which was entered by client owner when transferring the client to paid account.
	 *
	 * @type {string}
	 */
	get discountCode() : string | null {
		this.getterDebugValidations(this.aiDiscountCode, false);
		return this.data[11];
	}

	set discountCode(v : string | null) {
        this.setterImpl(11, v, 'discountCode', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.adyenAccountWrapper._fixIds(_idReplacements);
		this.billingWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.adyenAccountWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		this.billingWrapper._updateRawData(data && data[9] !== undefined ? data[9] : null, generateMissingData);
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}

export enum ClientsApiType {
	TEST_NEED_VERIFICATION = 1,
	TEST = 2,
	TEST_EXPIRED = 3,
	PAID = 4,
	TERMINATED = 5,
}
export enum ClientsApiCountry {
	GERMANY = 1,
	AUSTRIA = 2,
	SWITZERLAND = 3,
	ITALY = 4,
	ROMANIA = 5,
	NETHERLANDS = 6,
	BELGIUM = 7,
	UNITED_KINGDOM = 8,
	CZECH_REPUBLIC = 9,
	SWEDEN = 10,
	LUXEMBOURG = 11,
}
		 
export class ClientsApiClientAdyenAccount extends ApiObjectWrapper<ClientsApiClientAdyenAccount>
{
	constructor(override readonly api : ClientsApiService,
		override readonly parent : ClientsApiClient | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ClientsApiClientAdyenAccount> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClientAdyenAccount as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiClientAdyenAccount> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiClientAdyenAccount>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'adyenAccount',
			nodeName: 'CLIENT_ADYEN_ACCOUNT',
			type: PApiType.ApiObject,
			isAvailableByBusinessLogic: function(this : ClientsApiClientAdyenAccount) {
				return ((!!this.data));
			},
			defaultValue: function(this : ClientsApiClientAdyenAccount, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 8,
		});
	aiAutoDebitEnabled : ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'autoDebitEnabled',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_AUTO_DEBIT_ENABLED',
			type: PApiType.boolean,
			validations: function(this : ClientsApiClientAdyenAccount) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientAdyenAccount) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientAdyenAccount, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiBalance : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'balance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_BALANCE',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiDesiredDeposit : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'desiredDeposit',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_DESIRED_DEPOSIT',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiOnboardingPassedDate : ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'onboardingPassedDate',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ONBOARDING_PASSED_DATE',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiAccountHolderCode : ApiAttributeInfo<ClientsApiClientAdyenAccount, string> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, string>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'accountHolderCode',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_CODE',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiLastTransferFundsExecutionDate : ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'lastTransferFundsExecutionDate',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_LAST_TRANSFER_FUNDS_EXECUTION_DATE',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});
	aiLastAutoDebitDate : ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'lastAutoDebitDate',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_LAST_AUTO_DEBIT_DATE',
			type: PApiType.DateTime,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 7,
		});
	aiAccountHolderPayoutState : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderPayoutState> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderPayoutState>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'accountHolderPayoutState',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PAYOUT_STATE',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 8,
		});
	aiAccountHolderProcessingState : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderProcessingState> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderProcessingState>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'accountHolderProcessingState',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PROCESSING_STATE',
			type: PApiType.Enum,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 9,
		});
	aiOutstandingTransferFunds : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'outstandingTransferFunds',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_OUTSTANDING_TRANSFER_FUNDS',
			type: PApiType.ClientCurrency,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 10,
		});
	aiTriggerPayoutProcess : ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'triggerPayoutProcess',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_TRIGGER_PAYOUT_PROCESS',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			validations: function(this : ClientsApiClientAdyenAccount) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientAdyenAccount) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientAdyenAccount, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	aiMarkAdyenEventAsSettled : ApiAttributeInfo<ClientsApiClientAdyenAccount, Integer> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, Integer>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'markAdyenEventAsSettled',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_MARK_ADYEN_EVENT_AS_SETTLED',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			rawDataIndex: 12,
		});

	/**
	 * Is adyen auto-debit enabled for this account?
	 *
	 * @type {boolean}
	 */
	get autoDebitEnabled() : boolean {
		this.getterDebugValidations(this.aiAutoDebitEnabled, false);
		return this.data[1];
	}

	set autoDebitEnabled(v : boolean) {
        this.setterImpl(1, v, 'autoDebitEnabled', false, null, null);
	}

	/**
	 * The current online balance.
	 *
	 * @type {ClientCurrency}
	 */
	get balance() : ClientCurrency {
		this.getterDebugValidations(this.aiBalance, false);
		return this.data[2];
	}

	set balanceTestSetter(v : ClientCurrency) {
        this.setterImpl(2, v, 'balance', true, null, null);
	}

	/**
	 * (Detail) The current deposit level we expect currently for this client.
	 *
	 * @type {ClientCurrency}
	 */
	get desiredDeposit() : ClientCurrency {
		this.getterDebugValidations(this.aiDesiredDeposit, false);
		return this.data[3];
	}

	set desiredDepositTestSetter(v : ClientCurrency) {
        this.setterImpl(3, v, 'desiredDeposit', true, null, null);
	}

	/**
	 * (Detail) When has this account passed Adyen onboarding?
	 *
	 * @type {DateTime}
	 */
	get onboardingPassedDate() : DateTime | null {
		this.getterDebugValidations(this.aiOnboardingPassedDate, false);
		return this.data[4];
	}

	set onboardingPassedDateTestSetter(v : DateTime | null) {
        this.setterImpl(4, v, 'onboardingPassedDate', true, null, null);
	}

	/**
	 * (Detail) Adyens account holder code which uniquely identifies the sub-merchant.
	 *
	 * @type {string}
	 */
	get accountHolderCode() : string | null {
		this.getterDebugValidations(this.aiAccountHolderCode, false);
		return this.data[5];
	}

	set accountHolderCodeTestSetter(v : string | null) {
        this.setterImpl(5, v, 'accountHolderCode', true, null, null);
	}

	/**
	 * (Detail) When was the last transfer-funds executed. This is the pre-step for payouts (See https://drplano.atlassian.net/l/c/7A3Zd2Kz).
	 *
	 * @type {DateTime}
	 */
	get lastTransferFundsExecutionDate() : DateTime | null {
		this.getterDebugValidations(this.aiLastTransferFundsExecutionDate, false);
		return this.data[6];
	}

	set lastTransferFundsExecutionDateTestSetter(v : DateTime | null) {
        this.setterImpl(6, v, 'lastTransferFundsExecutionDate', true, null, null);
	}

	/**
	 * (Detail) When was the last auto-debit executed?
	 *
	 * @type {DateTime}
	 */
	get lastAutoDebitDate() : DateTime | null {
		this.getterDebugValidations(this.aiLastAutoDebitDate, false);
		return this.data[7];
	}

	set lastAutoDebitDateTestSetter(v : DateTime | null) {
        this.setterImpl(7, v, 'lastAutoDebitDate', true, null, null);
	}

	/**
	 * (Detail) AccountHolder-Payout State for Adyen
	 *
	 * @type {ClientsApiAccountHolderPayoutState}
	 */
	get accountHolderPayoutState() : ClientsApiAccountHolderPayoutState {
		this.getterDebugValidations(this.aiAccountHolderPayoutState, false);
		return this.data[8];
	}

	set accountHolderPayoutStateTestSetter(v : ClientsApiAccountHolderPayoutState) {
        this.setterImpl(8, v, 'accountHolderPayoutState', true, null, null);
	}

	/**
	 * (Detail) AccountHolder-Processing State for Adyen
	 *
	 * @type {ClientsApiAccountHolderProcessingState}
	 */
	get accountHolderProcessingState() : ClientsApiAccountHolderProcessingState {
		this.getterDebugValidations(this.aiAccountHolderProcessingState, false);
		return this.data[9];
	}

	set accountHolderProcessingStateTestSetter(v : ClientsApiAccountHolderProcessingState) {
        this.setterImpl(9, v, 'accountHolderProcessingState', true, null, null);
	}

	/**
	 * (Detail) The fees which still need to be transferred by transfer-funds (See https://drplano.atlassian.net/l/c/7A3Zd2Kz). This includes the OUTSTANDING and PENDING state.
	 *
	 * @type {ClientCurrency}
	 */
	get outstandingTransferFunds() : ClientCurrency {
		this.getterDebugValidations(this.aiOutstandingTransferFunds, false);
		return this.data[10];
	}

	set outstandingTransferFundsTestSetter(v : ClientCurrency) {
        this.setterImpl(10, v, 'outstandingTransferFunds', true, null, null);
	}

	/**
	 * (Detail) Set this to "true" to trigger the whole payout process (See https://drplano.atlassian.net/l/c/7A3Zd2Kz). This ignores all preconditions (last payout/TF, consistency check) and will trigger it again.
	 *
	 * @type {boolean}
	 */
	get triggerPayoutProcess() : boolean {
		this.getterDebugValidations(this.aiTriggerPayoutProcess, false);
		return this.data[11];
	}

	set triggerPayoutProcess(v : boolean) {
        this.setterImpl(11, v, 'triggerPayoutProcess', false, null, null);
	}

	/**
	 * (Detail) Set here the id of an Adyen event which should be marked as settled. This is typically used to fix failing consistency check.
	 *
	 * @type {Integer}
	 */
	get markAdyenEventAsSettled() : Integer | null {
		this.getterDebugValidations(this.aiMarkAdyenEventAsSettled, false);
		return this.data[12];
	}

	set markAdyenEventAsSettled(v : Integer | null) {
        this.setterImpl(12, v, 'markAdyenEventAsSettled', false, null, null);
	}

	private consistencyCheckWrapper : ClientsApiClientAdyenAccountConsistencyCheck = new ClientsApiClientAdyenAccountConsistencyCheck(this.api,
		this as unknown as ClientsApiClientAdyenAccount);
	public aiConsistencyCheck = this.consistencyCheckWrapper.aiThis;

	/**
	 * (Detail) Returns information for debugging our Adyen balance consistency check.
	 */
	get consistencyCheck() : ClientsApiClientAdyenAccountConsistencyCheck {
		this.getterDebugValidations(this.aiConsistencyCheck, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[13] === undefined) {
			this.data[13] = this.aiConsistencyCheck.defaultValue;
			this.consistencyCheckWrapper._updateRawData(this.data[13], true);
		}
		return this.consistencyCheckWrapper;
	}

	set consistencyCheckTestSetter(v : ClientsApiClientAdyenAccountConsistencyCheck) {
        this.setterImpl(13, v.rawData, 'consistencyCheck', true, null, function(this : ClientsApiClientAdyenAccount) {this.consistencyCheckWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.consistencyCheckWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT_ADYEN_ACCOUNT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.consistencyCheckWrapper._updateRawData(data && data[13] !== undefined ? data[13] : null, generateMissingData);
	}

	protected get dni() : string {
		return '10';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '10', { success: success, error: error, searchParams: searchParams});
	}
}

export enum ClientsApiAccountHolderPayoutState {
	PAYOUT_ALLOWED = 1,
	PAYOUT_DISABLED = 2,
}
export enum ClientsApiAccountHolderProcessingState {
	PROCESSING_ALLOWED = 1,
	PROCESSING_DISABLED = 2,
}
		 
export class ClientsApiClientAdyenAccountConsistencyCheck extends ApiObjectWrapper<ClientsApiClientAdyenAccountConsistencyCheck>
{
	constructor(override readonly api : ClientsApiService,
		override readonly parent : ClientsApiClientAdyenAccount | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ClientsApiClientAdyenAccountConsistencyCheck> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClientAdyenAccountConsistencyCheck as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientsApiClientAdyenAccountConsistencyCheck> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientsApiClientAdyenAccountConsistencyCheck>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'consistencyCheck',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK',
			type: PApiType.ApiObject,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : ClientsApiClientAdyenAccountConsistencyCheck) {
				return ((this.parent!.aiAccountHolderProcessingState.isAvailable && this.parent!.accountHolderProcessingState !==
						ClientsApiAccountHolderProcessingState.PROCESSING_DISABLED));
			},
			hasPermissionToSet: () => false,
			defaultValue: function(this : ClientsApiClientAdyenAccountConsistencyCheck, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 13,
		});
	aiBalanceConsistencyCheckFailureSince : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'balanceConsistencyCheckFailureSince',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_FAILURE_SINCE',
			type: PApiType.DateTime,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiExpectedBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'expectedBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_EXPECTED_BALANCE',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 2,
		});
	aiActualBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'actualBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_BALANCE',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 3,
		});
	aiDiff : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'diff',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_DIFF',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 4,
		});
	aiActualPendingBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'actualPendingBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_PENDING_BALANCE',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiActualOnHoldBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'actualOnHoldBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_ON_HOLD_BALANCE',
			type: PApiType.ClientCurrency,
			hasPermissionToSet: () => false,
			rawDataIndex: 6,
		});

	/**
	 * Since when is the consistency check failing? This will block payouts.
	 *
	 * @type {DateTime}
	 */
	get balanceConsistencyCheckFailureSince() : DateTime {
		this.getterDebugValidations(this.aiBalanceConsistencyCheckFailureSince, false);
		return this.data[1];
	}

	set balanceConsistencyCheckFailureSinceTestSetter(v : DateTime) {
        this.setterImpl(1, v, 'balanceConsistencyCheckFailureSince', true, null, null);
	}

	/**
	 * What Adyen balance the consistency check expects.
	 *
	 * @type {ClientCurrency}
	 */
	get expectedBalance() : ClientCurrency {
		this.getterDebugValidations(this.aiExpectedBalance, false);
		return this.data[2];
	}

	set expectedBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(2, v, 'expectedBalance', true, null, null);
	}

	/**
	 * Actual Adyen balance. This is the value we compare against "expectedBalance" during consistency check.
	 *
	 * @type {ClientCurrency}
	 */
	get actualBalance() : ClientCurrency {
		this.getterDebugValidations(this.aiActualBalance, false);
		return this.data[3];
	}

	set actualBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(3, v, 'actualBalance', true, null, null);
	}

	/**
	 * The difference between expected and actual balance.
	 *
	 * @type {ClientCurrency}
	 */
	get diff() : ClientCurrency {
		this.getterDebugValidations(this.aiDiff, false);
		return this.data[4];
	}

	set diffTestSetter(v : ClientCurrency) {
        this.setterImpl(4, v, 'diff', true, null, null);
	}

	/**
	 * Actual "pending" Adyen balance.
	 *
	 * @type {ClientCurrency}
	 */
	get actualPendingBalance() : ClientCurrency {
		this.getterDebugValidations(this.aiActualPendingBalance, false);
		return this.data[5];
	}

	set actualPendingBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(5, v, 'actualPendingBalance', true, null, null);
	}

	/**
	 * Actual "on-hold" Adyen balance.
	 *
	 * @type {ClientCurrency}
	 */
	get actualOnHoldBalance() : ClientCurrency {
		this.getterDebugValidations(this.aiActualOnHoldBalance, false);
		return this.data[6];
	}

	set actualOnHoldBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(6, v, 'actualOnHoldBalance', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '39';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '39', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class ClientsApiClientBilling extends ApiObjectWrapper<ClientsApiClientBilling>
{
	constructor(override readonly api : ClientsApiService,
		override readonly parent : ClientsApiClient | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ClientsApiClientBilling> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClientBilling as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ClientsApiClientBilling, ClientsApiClientBilling> = new ApiAttributeInfo<ClientsApiClientBilling, ClientsApiClientBilling>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'billing',
			nodeName: 'CLIENT_BILLING',
			type: PApiType.ApiObject,
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 9,
		});
	aiCountry : ApiAttributeInfo<ClientsApiClientBilling, ClientsApiCountry> = new ApiAttributeInfo<ClientsApiClientBilling, ClientsApiCountry>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'country',
			nodeName: 'CLIENT_BILLING_COUNTRY',
			type: PApiType.Enum,
			hasPermissionToSet: () => false,
			rawDataIndex: 1,
		});
	aiHasToPayVat : ApiAttributeInfo<ClientsApiClientBilling, boolean> = new ApiAttributeInfo<ClientsApiClientBilling, boolean>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'hasToPayVat',
			nodeName: 'CLIENT_BILLING_HAS_TO_PAY_VAT',
			type: PApiType.boolean,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	aiDisableInvoiceCreation : ApiAttributeInfo<ClientsApiClientBilling, boolean> = new ApiAttributeInfo<ClientsApiClientBilling, boolean>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'disableInvoiceCreation',
			nodeName: 'CLIENT_BILLING_DISABLE_INVOICE_CREATION',
			type: PApiType.boolean,
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	aiDiscountType : ApiAttributeInfo<ClientsApiClientBilling, ClientsApiDiscountType> = new ApiAttributeInfo<ClientsApiClientBilling, ClientsApiDiscountType>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountType',
			nodeName: 'CLIENT_BILLING_DISCOUNT_TYPE',
			type: PApiType.Enum,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiVatNumber : ApiAttributeInfo<ClientsApiClientBilling, string> = new ApiAttributeInfo<ClientsApiClientBilling, string>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'vatNumber',
			nodeName: 'CLIENT_BILLING_VAT_NUMBER',
			type: PApiType.string,
			isDetailedAttribute: true,
			hasPermissionToSet: () => false,
			rawDataIndex: 5,
		});
	aiDiscountValue : ApiAttributeInfo<ClientsApiClientBilling, Percent|Euro> = new ApiAttributeInfo<ClientsApiClientBilling, Percent|Euro>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountValue',
			nodeName: 'CLIENT_BILLING_DISCOUNT_VALUE',
			type: () => (this.aiDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiType.Percent : PApiType.Euro,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.min(0, true, () => (this.aiDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiType.Percent : PApiType.Euro, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.max(() => this.discountType === ClientsApiDiscountType.PERCENT ? 1 : null, true, () => (this.aiDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiType.Percent : PApiType.Euro, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.required(() => (this.aiDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiType.Percent : PApiType.Euro, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, () => (this.aiDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiType.Percent : PApiType.Euro, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiDiscountStart : ApiAttributeInfo<ClientsApiClientBilling, Date> = new ApiAttributeInfo<ClientsApiClientBilling, Date>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountStart',
			nodeName: 'CLIENT_BILLING_DISCOUNT_START',
			type: PApiType.Date,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.max(() => this.discountEnd, false, PApiType.Date, this.aiDiscountEnd.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiDiscountEnd : ApiAttributeInfo<ClientsApiClientBilling, DateExclusiveEnd> = new ApiAttributeInfo<ClientsApiClientBilling, DateExclusiveEnd>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountEnd',
			nodeName: 'CLIENT_BILLING_DISCOUNT_END',
			type: PApiType.DateExclusiveEnd,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.min(() => this.discountStart, false, PApiType.DateExclusiveEnd, this.aiDiscountStart.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiBilledMembersCountMax : ApiAttributeInfo<ClientsApiClientBilling, Integer> = new ApiAttributeInfo<ClientsApiClientBilling, Integer>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'billedMembersCountMax',
			nodeName: 'CLIENT_BILLING_BILLED_MEMBERS_COUNT_MAX',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.min(0, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiBilledMembersCountSubtract : ApiAttributeInfo<ClientsApiClientBilling, Integer> = new ApiAttributeInfo<ClientsApiClientBilling, Integer>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'billedMembersCountSubtract',
			nodeName: 'CLIENT_BILLING_BILLED_MEMBERS_COUNT_SUBTRACT',
			type: PApiType.Integer,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.min(0, false, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiDiscountComment : ApiAttributeInfo<ClientsApiClientBilling, string> = new ApiAttributeInfo<ClientsApiClientBilling, string>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountComment',
			nodeName: 'CLIENT_BILLING_DISCOUNT_COMMENT',
			type: PApiType.string,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			rawDataIndex: 11,
		});
	aiCredit : ApiAttributeInfo<ClientsApiClientBilling, Euro> = new ApiAttributeInfo<ClientsApiClientBilling, Euro>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'credit',
			nodeName: 'CLIENT_BILLING_CREDIT',
			type: PApiType.Euro,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.min(0, true, PApiType.Euro, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.required(PApiType.Euro, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiExemptGiftCardSalesFees : ApiAttributeInfo<ClientsApiClientBilling, boolean> = new ApiAttributeInfo<ClientsApiClientBilling, boolean>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'exemptGiftCardSalesFees',
			nodeName: 'CLIENT_BILLING_EXEMPT_GIFT_CARD_SALES_FEES',
			type: PApiType.boolean,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				{const conditionValue = !this.disableInvoiceCreation; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api.validators.required(PApiType.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});

	/**
	 * The billing country of this client. Read-only.
	 *
	 * @type {ClientsApiCountry}
	 */
	get country() : ClientsApiCountry | null {
		this.getterDebugValidations(this.aiCountry, false);
		return this.data[1];
	}

	set countryTestSetter(v : ClientsApiCountry | null) {
        this.setterImpl(1, v, 'country', true, null, null);
	}

	/**
	 * Does this client need to pay vat for dr-plano fee?
	 *
	 * @type {boolean}
	 */
	get hasToPayVat() : boolean {
		this.getterDebugValidations(this.aiHasToPayVat, false);
		return this.data[2];
	}

	set hasToPayVat(v : boolean) {
        this.setterImpl(2, v, 'hasToPayVat', false, null, null);
	}

	/**
	 * Should the whole invoice creation be disabled? When "true" no Billomat client and VAT transactions will be created anymore for this client.
	 *
	 * @type {boolean}
	 */
	get disableInvoiceCreation() : boolean {
		this.getterDebugValidations(this.aiDisableInvoiceCreation, false);
		return this.data[3];
	}

	set disableInvoiceCreation(v : boolean) {
        this.setterImpl(3, v, 'disableInvoiceCreation', false, null, null);
	}

	/**
	 * The current discountType for this account
	 *
	 * @type {ClientsApiDiscountType}
	 */
	get discountType() : ClientsApiDiscountType {
		this.getterDebugValidations(this.aiDiscountType, false);
		return this.data[4];
	}

	set discountType(v : ClientsApiDiscountType) {
        this.setterImpl(4, v, 'discountType', false, null, null);
	}

	/**
	 * (Detail) The vat-number of this client. Only being send when client does not have to pay vat. Read-only.
	 *
	 * @type {string}
	 */
	get vatNumber() : string | null {
		this.getterDebugValidations(this.aiVatNumber, false);
		return this.data[5];
	}

	set vatNumberTestSetter(v : string | null) {
        this.setterImpl(5, v, 'vatNumber', true, null, null);
	}

	/**
	 * (Detail) The discount value to be applied on the final invoice amount. Depending on "discountType" this is either an absolute discount (for all clients in euro) or a percentage discount.
	 *
	 * @type {Percent|Euro}
	 */
	get discountValue() : Percent|Euro {
		this.getterDebugValidations(this.aiDiscountValue, false);
		return this.data[6];
	}

	set discountValue(v : Percent|Euro) {
        this.setterImpl(6, v, 'discountValue', false, null, null);
	}

	/**
	 * (Detail) The start-time from when "discountValue" should be applied. "null" means no such start-limit exists.
	 *
	 * @type {Date}
	 */
	get discountStart() : Date | null {
		this.getterDebugValidations(this.aiDiscountStart, false);
		return this.data[7];
	}

	set discountStart(v : Date | null) {
        this.setterImpl(7, v, 'discountStart', false, null, null);
	}

	/**
	 * (Detail) The end-time from when "discountValue" should be applied. "null" means no such end-limit exists.
	 *
	 * @type {DateExclusiveEnd}
	 */
	get discountEnd() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiDiscountEnd, false);
		return this.data[8];
	}

	set discountEnd(v : DateExclusiveEnd | null) {
        this.setterImpl(8, v, 'discountEnd', false, null, null);
	}

	/**
	 * (Detail) The upper limit for maximum number of members to be billed for this client. "null" means no such limit exist.
	 *
	 * @type {Integer}
	 */
	get billedMembersCountMax() : Integer | null {
		this.getterDebugValidations(this.aiBilledMembersCountMax, false);
		return this.data[9];
	}

	set billedMembersCountMax(v : Integer | null) {
        this.setterImpl(9, v, 'billedMembersCountMax', false, null, null);
	}

	/**
	 * (Detail) The value to be subtract from the maximum number of members to be billed.
	 *
	 * @type {Integer}
	 */
	get billedMembersCountSubtract() : Integer | null {
		this.getterDebugValidations(this.aiBilledMembersCountSubtract, false);
		return this.data[10];
	}

	set billedMembersCountSubtract(v : Integer | null) {
        this.setterImpl(10, v, 'billedMembersCountSubtract', false, null, null);
	}

	/**
	 * (Detail) Internal comment about discounting.
	 *
	 * @type {string}
	 */
	get discountComment() : string | null {
		this.getterDebugValidations(this.aiDiscountComment, false);
		return this.data[11];
	}

	set discountComment(v : string | null) {
        this.setterImpl(11, v, 'discountComment', false, null, null);
	}

	/**
	 * (Detail) A one-time credit which will be subtracted from "misc" invoices.
	 *
	 * @type {Euro}
	 */
	get credit() : Euro {
		this.getterDebugValidations(this.aiCredit, false);
		return this.data[12];
	}

	set credit(v : Euro) {
        this.setterImpl(12, v, 'credit', false, null, null);
	}

	/**
	 * (Detail) Should gift-card sales fees be exempted for this client? Note, that still online-payment fees will occur if the gift cards are sold using online-payment. This is a global setting and will be applied when an invoice is created. You cannot limit it to a specific time-interval.
	 *
	 * @type {boolean}
	 */
	get exemptGiftCardSalesFees() : boolean {
		this.getterDebugValidations(this.aiExemptGiftCardSalesFees, false);
		return this.data[13];
	}

	set exemptGiftCardSalesFees(v : boolean) {
        this.setterImpl(13, v, 'exemptGiftCardSalesFees', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT_BILLING should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '11';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '11', { success: success, error: error, searchParams: searchParams});
	}
}

export enum ClientsApiDiscountType {
	ABSOLUTE = 1,
	PERCENT = 2,
}


