import { getIndexedDBKey } from '@plano/shared/core/indexed-db/indexed-db-database-class';
import { PIndexedDBKeyDataType } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { IDBPTransaction } from 'idb';

/**
 * Helper methods to support indexedDB migrations.
 */
export class PMigrationUtils {
	/**
     * Helper method to rename a key in a store. This method does nothing if the key does not exist there.
     * @param transaction The transaction to be used to apply the change.
     * @param oldIndexDBKey Previous indexedDB string-key.
     * @param newKey The new key which we are migrating to.
     */
	public static async renameKey(transaction : IDBPTransaction<unknown, string[], 'versionchange'>, oldIndexDBKey : string, newKey : PIndexedDBKeyDataType) : Promise<void> {
		const store = transaction.objectStore(newKey.store);
		const value = await store.get(oldIndexDBKey);

		if (value) {
			await store.put(value, getIndexedDBKey(newKey));
			await store.delete(oldIndexDBKey);
		}
	}
}
