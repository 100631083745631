import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PCloseOnEscDirective } from '@plano/shared/core/directive/close-on-esc.directive';
import { PCropOnOverflowDirective } from '@plano/shared/core/directive/crop-on-overflow.directive';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { PModalContentWrapperDirective, PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { PModalIdDirective } from '@plano/shared/core/p-modal/p-modal-id/p-modal-id.directive';
import { CorePipesModule } from '@plano/shared/core/pipe/core-pipes.module';
import { PConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PModalDefaultTemplateComponent } from './modal-default-template/modal-default-template.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalDirective } from './modal.directive';
import { PModalContentBodyComponent, PModalContentComponent, PModalContentFooterComponent } from './p-modal-content/p-modal-content.component';

@NgModule({
	imports: [
		CorePipesModule,
		FaIconComponent,
		NgbModule,
		PCloseOnEscDirective,
		PCommonModule,
		PCropOnOverflowDirective,
		PModalContentBodyComponent,
		PModalContentComponent,
		PModalContentFooterComponent,
		PModalContentWrapperDirective,
		PModalIdDirective,
		PModalTemplateDirective,
		PTooltipDirective,
	],
	declarations: [
		ModalDirective,
		ModalHeaderComponent,
		PConfirmModalComponent,
		PModalDefaultTemplateComponent,
	],
	exports: [
		ModalDirective,
		ModalHeaderComponent,
		PModalContentBodyComponent,
		PModalContentComponent,
		PModalContentFooterComponent,
		PModalContentWrapperDirective,
		PModalIdDirective,
		PModalTemplateDirective,
	],
})
export class PModalModule {}
