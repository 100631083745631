/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable } from '@angular/core';
import { SchedulingApiShiftModel } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { SchedulingApiBooking, SchedulingApiBookingParticipant, SchedulingApiShiftModelCoursePaymentMethods, SchedulingApiShiftModelCourseTariff, SchedulingApiShiftModelCourseTariffFee, SchedulingApiShiftModelCourseTariffs } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftmodelTariffService {

	/**
	 * If a tariff of an existing booking or participant has been modified, then the backend needs to know which booking
	 * or participant is related to this tariff modification. Therefore some attributes are set on the tariff object.
	 *
	 * @param tariff The tariff to set the apply attributes for.
	 * @param booking The booking to set the apply attributes for.
	 * @param participant The participant to set the apply attributes for.
	 */
	public setApplyToAttributes(
		tariff : SchedulingApiShiftModelCourseTariff,
		booking : SchedulingApiBooking | null = null,
		participant : SchedulingApiBookingParticipant | null = null,
	) : void {
		if (participant?.parent === null) throw new Error('The participant has no parent. Is it a lost reference?');

		const getId = (
			item : SchedulingApiBooking | SchedulingApiBookingParticipant | null,
			currentlySetId : Id | null,
		) : Id | null => {
			const result = item?.id ?? null;
			if (result && currentlySetId && !result.equals(currentlySetId)) {
				throw new Error('This tariff already should have been applied to a different booking/participant. [PRODUCTION-5JM]');
			}

			return result;
		};

		tariff.applyToBooking = getId(booking, tariff.applyToBooking);
		tariff.applyToParticipant = getId(participant, tariff.applyToParticipant);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public tariffRadioIsDisabled(courseTariff : SchedulingApiShiftModelCourseTariff, selectedTariff : Id | null) : boolean {
		if (courseTariff.trashed && !courseTariff.id.equals(selectedTariff)) return true;

		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public removeTariffFee(
		fee : SchedulingApiShiftModelCourseTariffFee,
		booking : SchedulingApiBooking | null,
		participant : SchedulingApiBookingParticipant | null,
	) : void {
		const tariff = fee.parent!.parent!;
		if (!fee.isNewItem) { this.setApplyToAttributes(tariff, booking, participant); }
		tariff.fees.removeItem(fee);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public removeTariff(
		tariff : SchedulingApiShiftModelCourseTariff,
		shiftModel : SchedulingApiShiftModel,
	) : void {
		if (!tariff.isNewItem) { this.setApplyToAttributes(tariff); }
		if (tariff.isNewItem) {
			shiftModel.courseTariffs.removeItem(tariff);
		} else {
			tariff.trashed = true;
		}
	}

	/**
	 * Does this tariff have course dates data?
	 * @param tariff The tariff to check
	 */
	public hasCourseDatesData(
		tariff : Pick<SchedulingApiShiftModelCourseTariff, (
			'negateForCourseDatesInterval' |
			'forCourseDatesFrom' |
			'forCourseDatesUntil' |
			'isInternal'
		)>,
	) : boolean {
		if (tariff.negateForCourseDatesInterval) return true;
		if (tariff.forCourseDatesFrom) return true;
		if (tariff.forCourseDatesUntil) return true;
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public tariffIsAvailableAtDate(
		// TODO: Get rid of undefined here
		tariff : SchedulingApiShiftModelCourseTariff | null | undefined,
		relevantDate : number | null,
	) : boolean | undefined {

		// This code is more or less copy paste from:
		// https://drplano.atlassian.net/browse/PLANO-34855

		if (!tariff) return undefined;
		if (!relevantDate) return undefined;

		const from = tariff.forCourseDatesFrom;
		const until = tariff.forCourseDatesUntil;
		const negate = tariff.negateForCourseDatesInterval;

		const isInInterval = (!from || relevantDate >= from) && (!until || relevantDate < until);

		if (isInInterval === !negate) return true;

		return false;
	}

	/** @see PShiftmodelTariffService.isFreeCourse */
	public isFreeCourse(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		return PShiftmodelTariffService.isFreeCourse(courseTariffs);
	}

	/**
	 * Under some circumstances this course gets visualized as 'for free' in the plugin.
	 * @param courseTariffs The tariffs of the item that should be checked
	 */
	public static isFreeCourse(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		return !PShiftmodelTariffService.hasVisibleCourseTariffWithCosts(courseTariffs);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public hasVisiblePaymentMethod(coursePaymentMethods : SchedulingApiShiftModelCoursePaymentMethods) : boolean {
		return PShiftmodelTariffService.hasVisiblePaymentMethod(coursePaymentMethods);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public static hasVisiblePaymentMethod(
		coursePaymentMethods : SchedulingApiShiftModelCoursePaymentMethods,
	) : boolean {
		const ACTIVE_PAYMENT_METHOD = coursePaymentMethods.findBy(coursePaymentMethod => {
			if (coursePaymentMethod.trashed) return false;
			if (coursePaymentMethod.isInternal) return false;
			return true;
		});
		return !!ACTIVE_PAYMENT_METHOD;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public hasVisibleCourseTariffWithCosts(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		return PShiftmodelTariffService.hasVisibleCourseTariffWithCosts(courseTariffs);
	}

	/**
	 * Checks if at least one course tariff is not trashed and has costs
	 */
	public static hasVisibleCourseTariffWithCosts(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		const ACTIVE_TARIFF = courseTariffs.findBy(tariff => {
			if (tariff.trashed) return false;
			if (tariff.isInternal) return false;
			return !!tariff.fees.findBy(fee => fee.fee > 0);
		});
		return !!ACTIVE_TARIFF;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public hasInBookingPluginVisibleTariff(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		const IN_BOOKING_PLUGIN_VISIBLE_TARIFF = courseTariffs.findBy(tariff => {
			if (tariff.trashed) return false;
			if (tariff.isInternal) return false;
			return true;
		});
		return !!IN_BOOKING_PLUGIN_VISIBLE_TARIFF;
	}
}
