/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { SchedulingApiMember, SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationAction, SchedulingApiShiftExchangeCommunicationState, SchedulingApiShiftExchangeRequesterRelationship, ShiftExchangeConceptServiceBase } from '@plano/shared/api';
import { LogService } from '@plano/shared/core/log.service';
import { MeService } from '@plano/shared/core/me/me.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe, LocalizePipeParamsType } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PActionData, PossibleActionIcons } from './p-action-data';
import { PCommunicationData } from './p-communication-data';
import { PStateData } from './p-state-data';
import { PShiftExchangeService } from './shift-exchange.service';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftExchangeConceptService extends ShiftExchangeConceptServiceBase {
	constructor(
		private meService : MeService,
		private console : LogService,
		private pShiftExchangeService : PShiftExchangeService,
		private localize : LocalizePipe,
	) {
		super();

		this.stateData = new PStateData();
		this.communicationData = new PCommunicationData();
		this.actionTexts = new PActionData();
	}

	private stateData : PStateData;
	private communicationData : PCommunicationData;
	private actionTexts : PActionData;

	/**
	 * Defines the bootstrap based color/design of that ui item.
	 */
	public getStateStyle(input : SchedulingApiShiftExchange) : PThemeEnum | null {
		return this.stateData.getStateStyle(input) ?? null;
	}

	/**
	 * Human readable State-Text.
	 */
	public getStateText(shiftExchange : SchedulingApiShiftExchange) : string | null {
		const text = this.stateData.getStateText(shiftExchange);
		return this.replaceTemplateMarkers(text, shiftExchange);
	}

	/**
	 * Defines what text is shown on the action button
	 */
	public getActionText(
		action : SchedulingApiShiftExchangeCommunicationAction,
		shiftExchange : SchedulingApiShiftExchange,
	) : string | null {
		const text = this.actionTexts.getText(action, shiftExchange);
		return this.replaceTemplateMarkers(text, shiftExchange);
	}

	/**
	 * Defines what icon is shown on the action button
	 */
	public getActionIcon(
		action : SchedulingApiShiftExchangeCommunicationAction,
	) : PossibleActionIcons | undefined {
		return this.actionTexts.getIcon(action);
	}

	/**
	 * Defines what icon is shown on the action button
	 */
	public getActionIconStyle(
		action : SchedulingApiShiftExchangeCommunicationAction | null = null,
	) : ReturnType<PActionData['getIconStyle']> {
		if (action === null) return null;
		return this.actionTexts.getIconStyle(action);
	}

	/**
	 * Icon for the button-badge.
	 * Gives the user some more info where he/she needs to do get things done.
	 *
	 * There are many states that can have a badge.
	 * Generally speaking, the icon is
	 * - CANCELED if a deadline passed or got canceled/declined
	 * - SUCCESS if an illness got accepted
	 * - QUESTION if swap exchange requests are ongoing/not answered yet
	 *
	 * To figure it out in detail look into {@link PStateData#arrayOfDataObjectForState},
	 * search for the `.state`, and check which icon is set in the related `.uiObject`.
	 */
	public getBadgeIcon(input : SchedulingApiShiftExchange) : (
		typeof enumsObject.PlanoFaIconContextPool.CANCELED |
		typeof enumsObject.PlanoFaIconContextPool.SUCCESS |
		typeof enumsObject.PlanoFaIconContextPool.QUESTION |
		null
	) {
		try {
			return this.stateData.getBadgeIcon(input);
		} catch (error) {
			this.console.error(error);
			return null;
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public isSomeKindOfIllnessState(state : SchedulingApiShiftExchangeCommunicationState) : boolean {
		switch (state) {
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_CONFIRMED :
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_DECLINED :
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_NEEDS_CONFIRMATION :
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_CONFIRMED_WITHOUT_SHIFT_EXCHANGE :
				return true;
			default :
				return false;
		}
	}

	/**
	 * Check if this kind of communication needs a confirmation by
	 * the communication partner to whom "the ball has been passed"
	 */
	public needsReviewByCP(
		requesterRelationship : SchedulingApiShiftExchangeRequesterRelationship,
		communicationState : SchedulingApiShiftExchangeCommunicationState,
	) : boolean {
		switch (communicationState) {
			case SchedulingApiShiftExchangeCommunicationState.IM_CHANGED_MIND_WANTS_SWAP :
				return requesterRelationship !== SchedulingApiShiftExchangeRequesterRelationship.CP;
			case SchedulingApiShiftExchangeCommunicationState.IM_CHANGED_MIND_WANTS_TAKE :
				return requesterRelationship !== SchedulingApiShiftExchangeRequesterRelationship.CP;
			default :
				return false;
		}
	}

	/**
	 * Check if this kind of communication needs a confirmation by
	 * the IM to whom "the ball has been passed" back
	 */
	public needsReviewByIM(
		requesterRelationship : SchedulingApiShiftExchangeRequesterRelationship,
		communicationState : SchedulingApiShiftExchangeCommunicationState,
	) : boolean {
		switch (communicationState) {
			case SchedulingApiShiftExchangeCommunicationState.CP_WANTS_SWAP :
				return requesterRelationship !== SchedulingApiShiftExchangeRequesterRelationship.IM;
			case SchedulingApiShiftExchangeCommunicationState.CP_WANTS_TAKE :
				return requesterRelationship !== SchedulingApiShiftExchangeRequesterRelationship.IM;
			default :
				return false;
		}
	}

	/**
	 * Defines the bootstrap based color/design of that ui item.
	 */
	public getCommunicationStateStyle(
		communicationState : SchedulingApiShiftExchangeCommunicationState,
		lastAction : SchedulingApiShiftExchangeCommunicationAction,
	) : PThemeEnum {
		return this.styleForCommunicationState(communicationState, lastAction);
	}

	private styleForCommunicationState(
		state : SchedulingApiShiftExchangeCommunicationState,
		lastAction : SchedulingApiShiftExchangeCommunicationAction,
	) : PThemeEnum {
		switch (state) {
			case SchedulingApiShiftExchangeCommunicationState.CP_NOT_RESPONDED :
				// eslint-disable-next-line sonarjs/no-nested-switch -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
				switch (lastAction) {
					case SchedulingApiShiftExchangeCommunicationAction.CP_IS_ILL :
					case SchedulingApiShiftExchangeCommunicationAction.CP_IS_ABSENT :
					case SchedulingApiShiftExchangeCommunicationAction.CP_ASSIGNED_SAME_SHIFT :
					case SchedulingApiShiftExchangeCommunicationAction.CP_ASSIGNED_SAME_TIME :
						return enumsObject.PThemeEnum.SECONDARY;
					default :
						return enumsObject.PThemeEnum.WARNING;
				}
			case SchedulingApiShiftExchangeCommunicationState.IM_DECLINED_SWAP :
			case SchedulingApiShiftExchangeCommunicationState.IM_DECLINED_TAKE :
			case SchedulingApiShiftExchangeCommunicationState.CP_RESPONDED_NO :
			case SchedulingApiShiftExchangeCommunicationState.CP_CANNOT_SHIFT_EXCHANGE :
				return enumsObject.PThemeEnum.SECONDARY;
			case SchedulingApiShiftExchangeCommunicationState.CP_WANTS_SWAP :
			case SchedulingApiShiftExchangeCommunicationState.CP_WANTS_TAKE :
			case SchedulingApiShiftExchangeCommunicationState.IM_CHANGED_MIND_WANTS_SWAP :
			case SchedulingApiShiftExchangeCommunicationState.IM_CHANGED_MIND_WANTS_TAKE :
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_NEEDS_CONFIRMATION :
				return enumsObject.PThemeEnum.WARNING;
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_CONFIRMED :
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_CONFIRMED_WITHOUT_SHIFT_EXCHANGE :
			case SchedulingApiShiftExchangeCommunicationState.SWAP_SUCCESSFUL :
			case SchedulingApiShiftExchangeCommunicationState.TAKE_SUCCESSFUL :
				return enumsObject.PThemeEnum.SUCCESS;
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_DECLINED :
				return enumsObject.PThemeEnum.DANGER;
			default :
				throw new Error('unsupported enum value');
		}
	}

	/**
	 * Human readable State-Text.
	 */
	public getActionStateText(
		shiftExchange : SchedulingApiShiftExchange,
		communication : SchedulingApiShiftExchangeCommunication,
		action : SchedulingApiShiftExchangeCommunicationAction,
	) : string | null {
		const text = this.communicationData.getStateText(action);
		return this.replaceTemplateMarkers(text, shiftExchange, communication);
	}

	private getResponsiblePerson(
		shiftExchangeObject : Parameters<PShiftExchangeService['getResponsiblePerson']>[0],
	) : SchedulingApiMember | null {
		return this.pShiftExchangeService.getResponsiblePerson(shiftExchangeObject);
	}

	private userIsResponsiblePerson(
		shiftExchangeObject : Parameters<PShiftExchangeService['getResponsiblePerson']>[0],
	) : boolean | null {
		const responsiblePerson = this.getResponsiblePerson(shiftExchangeObject);
		return responsiblePerson ? responsiblePerson.id.equals(this.meService.data.id) : null;
	}

	/**
	 * Replace text markers like ${IM_OFFERED_SHIFTS} with the correct calculated text.
	 * @param text The text from which to replace the template markers
	 * @param shiftExchangeObject The shift exchange itself
	 * @param communication The exchange communication for the shift exchange, if any
	 */
	public replaceTemplateMarkers(
		text : PDictionarySourceString | null = null,
		shiftExchangeObject : Pick<SchedulingApiShiftExchange, 'isIllness' | 'indisposedMember' | 'indisposedMemberId' | 'shiftRefs' | 'lastUpdate' | 'communications' | 'rawData' | 'aiCommunications'>,
		communication ?: SchedulingApiShiftExchangeCommunication,
	) : string | null {
		if (text === null) return null;
		const paramsForTranslation : LocalizePipeParamsType = {};

		const userIsIndisposedMember = this.meService.data.id.equals(shiftExchangeObject.indisposedMemberId);

		this.addMarkerTranslationToObject(text, paramsForTranslation, 'INDISPOSED_MEMBER_FIRST_NAME', (() => {
			if (userIsIndisposedMember) return this.localize.transform('Du');
			return shiftExchangeObject.indisposedMember!.firstName;
		})());

		this.addMarkerTranslationToObject(text, paramsForTranslation, 'RESPONSIBLE_PERSON_FIRST_NAME', (() => {
			// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
			assumeDefinedToGetStrictNullChecksRunning(shiftExchangeObject, 'shiftExchangeObject');
			const responsiblePerson = this.getResponsiblePerson(shiftExchangeObject);
			const userIsResponsiblePerson = this.userIsResponsiblePerson(shiftExchangeObject);
			if (responsiblePerson === null) return 'ERROR';
			if (userIsResponsiblePerson) return this.localize.transform('Du');
			return responsiblePerson.firstName;
		})());

		this.addMarkerTranslationToObject(text, paramsForTranslation, 'RESPONSIBLE_PERSON_WAITS', (() => {
			const userIsResponsiblePerson = this.userIsResponsiblePerson(shiftExchangeObject);
			if (userIsResponsiblePerson) return this.localize.transform('wartest');
			return this.localize.transform('wartet');
		})());

		this.addMarkerTranslationToObject(text, paramsForTranslation, 'C_HAS', (() => {
			if (userIsIndisposedMember) return this.localize.transform('hast');
			return this.localize.transform('hat');
		})());

		this.addMarkerTranslationToObject(text, paramsForTranslation, 'C_WAITS', (() => {
			if (userIsIndisposedMember) return this.localize.transform('wartest');
			return this.localize.transform('wartet');
		})());

		this.addMarkerTranslationToObject(text, paramsForTranslation, 'C_WANTS', (() => {
			if (userIsIndisposedMember) return this.localize.transform('willst');
			return this.localize.transform('will');
		})());

		this.addMarkerTranslationToObject(text, paramsForTranslation, 'IM_OFFERED_SHIFTS', (() => {
			if (shiftExchangeObject.shiftRefs.length === 1) {
				return this.localize.transform('Schicht');
			}
			return this.localize.transform('Schichten');
		})());

		if (communication) {
			const userIsCommunicationPartner = this.meService.data.id.equals(communication.communicationPartnerId);

			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (text.match(/\${CP_FIRST_NAME}/)) {
				paramsForTranslation['CP_FIRST_NAME'] = (() => {
					if (userIsCommunicationPartner) return this.localize.transform('Du');
					return communication.communicationPartner!.firstName;
				})();
			}

			if (
				userIsCommunicationPartner &&
				communication.communicationState === SchedulingApiShiftExchangeCommunicationState.CP_NOT_RESPONDED
			) {
				const userIsResponsiblePerson = this.userIsResponsiblePerson(shiftExchangeObject);
				if (userIsResponsiblePerson) return this.localize.transform('Bitte antworten');
			}

			this.addMarkerTranslationToObject(text, paramsForTranslation, 'CP_HAS', (() => {
				if (userIsCommunicationPartner) return this.localize.transform('hast');
				return this.localize.transform('hat');
			})());

			this.addMarkerTranslationToObject(text, paramsForTranslation, 'CP_SEEKS', (() => {
				if (userIsCommunicationPartner) return this.localize.transform('suchst');
				return this.localize.transform('sucht');
			})());

			this.addMarkerTranslationToObject(text, paramsForTranslation, 'CP_IS', (() => {
				if (userIsCommunicationPartner) return this.localize.transform('bist');
				return this.localize.transform('ist');
			})());

			this.addMarkerTranslationToObject(text, paramsForTranslation, 'CP_WANTS', (() => {
				if (userIsCommunicationPartner) return this.localize.transform('willst');
				return this.localize.transform('will');
			})());

			this.addMarkerTranslationToObject(text, paramsForTranslation, 'CP_IS_WORKING', (() => {
				if (userIsCommunicationPartner) return this.localize.transform('arbeitest');
				return this.localize.transform('arbeitet');
			})());
		}

		let result = this.localize.transform({sourceString: text, params: paramsForTranslation});

		if (this.containsMarkers(result)) {
			this.console.error(`replaceTemplateMarkers() could not replace all markers: ${result}`);
		}
		if (this.containsErrors(result)) {
			this.console.error(`replaceTemplateMarkers() result contains errors: ${result}`);
		}

		result = result.charAt(0).toUpperCase() + result.slice(1);
		return result;
	}

	private containsMarkers(input : string) : boolean {
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const markers = input.match(/#[\dA-Z]*#/g);
		return !!markers && markers.length > 0;
	}
	private containsErrors(input : string) : boolean {
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const markers = input.match(/ERROR/g);
		return !!markers && markers.length > 0;
	}

	private addMarkerTranslationToObject(sourceString : PDictionarySourceString, object : LocalizePipeParamsType, marker : string, replacement : string) : void {
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (sourceString.match(new RegExp(`\\\${${marker}}`))) {
			object[marker] = replacement;
		}
	}
}
