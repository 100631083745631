import { ApplicationRef, Injectable } from '@angular/core';

/**
 * Service providing tools for performance testing.
 */
@Injectable( { providedIn: 'root' } )
export class PPerformanceTestingService {

	constructor(private appRef : ApplicationRef) {
		// add this service to the window object, so playwright can access it
		window.performanceTestingService = this;
	}

	/**
	 * Executes repeatedly change detection and measures the average duration.
	 * @returns The average change detection duration in milliseconds.
	 */
	public measureAverageChangeDetectionDuration() : number {
		const executionCount = 20;
		let totalDuration = 0;

		for (let i = 0; i < executionCount; i++) {
			const start = performance.now();
			this.appRef.tick();
			const end = performance.now();

			totalDuration += end - start;
		}

		return Math.round(totalDuration / executionCount);
	}

	/**
	 * Shows the result of {@link measureAverageChangeDetectionDuration} in an alert.
	 */
	public showAlertAboutChangeDetectionDuration() : void {
		alert(`Average duration: ${this.measureAverageChangeDetectionDuration()} ms.

Value should be below 16 ms for 60 fps.`);
	}
}
