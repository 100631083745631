/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { AfterViewInit, ContentChild, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { AnchorIndicatorDirective } from '@plano/shared/core/p-common/p-anchor/p-anchor-indicator.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { stringToId } from '@plano/shared/core/utils/typescript-utils';
import { NonEmptyString, TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PClipboardService } from '@plano/shared/p-forms/p-clipboard.service';

@Directive({
	selector: ':not(p-collapsible):not(span):not([label])[pAnchorLink]',
	exportAs: 'pAnchorLink',
	standalone: true,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AnchorLinkDirective extends PBaseClass implements OnDestroy, AfterViewInit {

	/**
	 * The anchor string. This is the text that will be used to create the anchor url.
	 */
	@Input() public pAnchorLink ! : NonEmptyString;

	/** The HTML ID. Its forbidden to set it. We listen for it here to make a runtime check. */
	@Input('id') private elementHtmlId : string | null = null;

	/**
	 * Anchor position can be either position in the bottom right,right top or center right.
	 *
	 * By default the anchor position will be top-right, but if used with a label or in a span element
	 * the anchor position will be center-right by default.
	 *
	 * In case the anchor link is supposed to be set
	 * inline (per example with a h3 tag), the anchor should be set using
	 * a span wrapper element.
	 *
	 * So for a h3 tag such as:
	 * <h3>Title</h3>
	 *
	 * the anchor should be used like following:
	 * <h3>
	 * 	<span pAnchorLink="element-id">Title</span>
	 * </h3>
	 *
	 * It is also possible to define the color of the anchor link button,
	 * when position is outside through the use of the anchorColor property.
	 * The possible values are white and black, default being black.
	 * <span pAnchorLink="element-id" anchorColor="white">Title</span>
	 */
	@Input() public anchorPos : 'center-right' | 'bottom-right' | 'top-right' = 'top-right';
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() protected anchorRelative : 'inside' | 'outside' = 'outside';
	// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private anchorColor : 'black' | 'white' = 'black';

	/**
	 * Should the anchor button be visible on hover?
	 */
	@Input() protected hidePAnchorLinkButton : boolean = false;

	@ContentChild(AnchorIndicatorDirective) private pAnchorIndicator ?: AnchorIndicatorDirective;

	constructor(
		protected elementRef : ElementRef<HTMLElement>,
		protected console : LogService,
		protected localize : LocalizePipe,
		protected clipboard : PClipboardService,
		protected toasts : ToastsService,
	) {
		super();
	}

	/**
	 * Should the id be added to the copied url?
	 */
	public noLinkId = false;

	protected anchorLinkButton : HTMLButtonElement | null = null;
	private newAnchorId : string | null = null;

	private hideTimeOut : number | null = null;
	private preventMouseOut : number | null = null;

	/**
	 * Method to handle the display of the anchor link
	 */
	protected showAnchorLink() : void {
		if (this.anchorLinkButton === null) return;
		if (this.hideTimeOut !== null) {
			window.clearTimeout(this.hideTimeOut);
			this.hideTimeOut = null;
		}
		if (this.preventMouseOut !== null) {
			window.clearTimeout(this.preventMouseOut);
			this.preventMouseOut = null;
		}
		this.anchorLinkButton.style.visibility = 'visible';
		window.setTimeout(() => {
			if (this.anchorLinkButton === null) return;
			this.anchorLinkButton.classList.add('shown');
		}, 10);
	}

	/**
	 * Method to handle hiding the anchor link
	 */
	protected hideAnchorLink() : void {
		this.preventMouseOut = window.setTimeout(() => {
			if (this.anchorLinkButton === null) return;
			this.anchorLinkButton.classList.remove('shown');
			this.hideTimeOut = window.setTimeout(() => {
				if (this.anchorLinkButton === null) return;
				this.anchorLinkButton.style.visibility = 'hidden';
			}, 300);
		}, 300);
	}

	/**
	 * Adds the target to the desired element
	 */
	protected addIdToTargetElement(newId : string) : void {
		this.elementRef.nativeElement.id = newId;
	}

	private get pAnchorIndicatorElement() : HTMLElement {
		// Append to the marked element if any. If no marker take the current element.
		return this.pAnchorIndicator?.elementRef.nativeElement ?? this.elementRef.nativeElement;
	}

	/**
	 * Adds the anchor link button to the parent element
	 */
	protected appendAnchorLinkButton() : void {
		if (this.anchorLinkButton === null) return;
		const htmlElement : HTMLElement = this.pAnchorIndicatorElement;
		if (htmlElement.tagName.toLowerCase() === 'span') {
			htmlElement.classList.add('d-inline-block');
		}
		htmlElement.appendChild(this.anchorLinkButton);
		htmlElement.classList.add('position-relative');
	}

	/**
	 * Initialize this directive.
	 */
	public ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {

		this.newAnchorId = this.noLinkId ? null : stringToId(this.pAnchorLink);
		if (this.newAnchorId)
			this.addIdToTargetElement(this.newAnchorId);
		if (this.anchorLinkButton)
			this.anchorLinkButton.remove();
		if (!this.hidePAnchorLinkButton) {
			this.anchorLinkButton = this.createAnchorButton();

			if (this.elementHtmlId) {
				this.console.error(`It is not allowed to have id="…" on the same element that uses [pAnchorLink]="…"`);
			}

			this.appendAnchorLinkButton();
			this.addEventListeners();
		}

		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	/**
	 * Method called when component with directive is destroyed
	 */
	public ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (!this.hidePAnchorLinkButton && this.anchorLinkButton !== null) {
			this.anchorLinkButton.remove();
		}
		this.anchorLinkButton = null;
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	public Config = Config;

	private createAnchorButton() : HTMLButtonElement {
		const anchorButton = document.createElement('button');
		anchorButton.type = 'button';

		// Change to white if position inside because it has black background
		this.anchorColor = this.anchorRelative === 'inside' ? 'white' : this.anchorColor;
		anchorButton.innerHTML = `<span style="color:${this.anchorColor}" class='hide-in-percy fa-fw fa-link fa-solid'></span>`;
		anchorButton.title = this.localize.transform('Link zu diesem Element kopieren');
		anchorButton.ariaLabel = this.localize.transform('Link zu diesem Element kopieren');

		switch (this.anchorPos) {
			case 'center-right':
				anchorButton.classList.add('center', 'right');
				break;
			case 'bottom-right': anchorButton.classList.add('bottom', 'right');
				break;
			case 'top-right': anchorButton.classList.add('top', 'right');
		}

		if (this.anchorRelative === 'inside') {
			anchorButton.classList.add('bg-dark', 'inside', 'text-white');
		} else anchorButton.classList.add('outside');

		anchorButton.classList.add('btn', 'anchor-directive', 'position-absolute', 'shadow-none');

		anchorButton.addEventListener('click', (event : MouseEvent) : void => {
			event.preventDefault();
			event.stopPropagation();
			const currentUrl : string = window.location.href;
			const withoutAnchor : string = currentUrl.includes('#') ? currentUrl.slice(0, currentUrl.indexOf('#')) : currentUrl;
			if (this.newAnchorId === null) {this.clipboard.copy(`${withoutAnchor}`);} else {this.clipboard.copy(`${withoutAnchor}#${this.newAnchorId}`);}
			this.toasts.addToast({
				title: null,
				content: this.localize.transform('Der Link wurde in die Zwischenablage kopiert.'),
				theme: this.enums.PThemeEnum.SUCCESS,
				visibilityDuration: 'short',
			});

		});
		anchorButton.style.visibility = 'hidden';
		return anchorButton;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	protected addEventListeners() : void {
		this.pAnchorIndicatorElement.addEventListener('mouseenter', this.showAnchorLink.bind(this));
		this.pAnchorIndicatorElement.addEventListener('mouseleave', this.hideAnchorLink.bind(this));
	}
}
