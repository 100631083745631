import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Mark some content as safe html.
 *
 * If [innerHTML] is not used with this pipe then Angular assumes that the HTML code is not safe and will sanitize it.
 * Sanitizing has a very high performance hit.
 *
 * Sanitization is only needed if the HTML comes from user input (e.g. an API field whose value is provided by users).
 * In this case, we want to sanitize the field to prevent harmful code from being added.
 *
 * @see https://v17.angular.io/api/platform-browser/DomSanitizer#security-risk
 */
@Pipe({
	name: 'pSafeHtml',
	standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitized : DomSanitizer) {}

	/**
	 * Sanitize given html
	 * @param html The html to sanitize
	 */
	public transform(html : string) : SafeHtml {
		return this.sanitized.bypassSecurityTrustHtml(html);
	}
}
