import { EventEmitter, QueryList } from '@angular/core';
import { SortFnArray } from '@plano/shared/api/base/api-list-wrapper/api-list-wrapper.type';

/**
 * Dont use (click) on a component.
 *
 * @note
 * 	You might wonder why this is not simply set to `never` like `onClick`.
 * 	This is because Angular internally expects the `click` event to be subscribable.
 * 	So the angular core code throws if it’s not.
*/
export const createFakeClickOutput = () => {
	/*
	* We want to prevent dev’s from trying to bind (click). Unfortunately there is not error about it from Angular.
	* So to prevent it we can use this workaround on any component that needs it:
	*
	* Example code in your component:
	*/
	// /**
	//  * @see createFakeClickOutput
	//  * @deprecated Use (onClick) instead
	//  */
	// @Output() public click = createFakeClickOutput();
	// // eslint-disable-next-line @angular-eslint/no-output-native -- See createFakeClickOutput

	return new EventEmitter<MouseEvent>() as never;
};

type ObjectWithElementRef = {
	elementRef : {
		nativeElement : Element;
	};
};

/**
 * Takes a list of items in a QueryList and sorts them by a given set of sorting fns.
 * @param items The list of items to sort
 * @param itemsWrapper The element reference to apply the sorted items to
 * @param sortingFns The sorting functions to apply
 */
export const sortQueryList = <ItemType extends ObjectWithElementRef>(
	items : QueryList<ItemType>,
	itemsWrapper : Element,
	sortingFns : SortFnArray<ItemType>,
) : void => {
	if (items.length === 0) return;
	const sortedItems = items.toArray().sort(...sortingFns);
	for (const sortedItem of sortedItems) {
		itemsWrapper.appendChild(sortedItem.elementRef.nativeElement);
	}
};
