import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// TODO: PLANO-188763 Check if this enum is still needed
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const enum CurrentPageEnum {
	SCHEDULING,
	REPORT,
	BOOKING,
	LEAVE,
	SHIFT_EXCHANGE,
}

// TODO: PLANO-188763 Check if this service is still needed
@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ClientRoutingService {
	constructor(
		private router : Router,
	) {
		this.routerUrl = this.router.url;

		this.router.events.subscribe(() => {
			this.routerUrl = this.router.url;
		});
	}

	private routerUrl ! : string;

	/**
	 * On what page is the user?
	 */
	public get currentPage() : CurrentPageEnum | null {
		if (this.routerUrl.startsWith('/client/scheduling')) return CurrentPageEnum.SCHEDULING;
		if (this.routerUrl.startsWith('/client/report')) return CurrentPageEnum.REPORT;
		if (this.routerUrl.startsWith('/client/booking')) return CurrentPageEnum.BOOKING;
		if (this.routerUrl.startsWith('/client/shift-exchange')) return CurrentPageEnum.SHIFT_EXCHANGE;
		if (this.routerUrl.startsWith('/client/leave'))
			return CurrentPageEnum.LEAVE;
		return null;
	}
}
