/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
type OptionalIntoNull<T> = T extends undefined ? NonUndefined<T> | null : T;

/**
 * Turn all optional params into `| null` instead of `| undefined`
 */
export type OptionalParamsIntoNull<T> = {
	[P in keyof T] : OptionalIntoNull<T[P]>;
};

/**
 * Assume a variable to be defined.
 * We added this to a lot of lines in our app via the TABULA RASA strategy 😎
 * To understand why, you have to know that we had strictNullChecks turned off in the beginning of Dr. Plano.
 * We had a lot of locations where we assumed something to exists, which would cause errors
 * with strictNullChecks turned on. We could not fix each of these cases, so we invented this function
 * to 'hack' it. We then could turn strictNullChecks on and now we have to get rid of the use of this method
 * step by step.
 *
 * @deprecated
 * If
 * 1. you can NOT find a solution to write your code in a way it does not need assumptions
 * 2. you are sure, that your assumption is legit,
 * then use the function assumeNotUndefined or assumeNonNull instead.
 *
 * TODO: [PLANO-151410]
 * 		When was the last time this has crashed?
 * 		Check: https://sentry.io/organizations/dr-plano/issues/?groupStatsPeriod=auto&project=5187494&query=Pre-StrictNullCheck-Code&sort=freq&statsPeriod=90d
 *    We did the big null check refactoring (https://drplano.atlassian.net/browse/PLANO-18170) one year ago.
 *    If there was no error in the last 90 days, we can remove this method.
 * 		We can try to fix the remaining cases by:
 * 			- change the type to something that is not nullish
 * 			- use assumeNotUndefined() or assumeNonNull()
 * 			- use the optional chaining operator (?.)
 */
export const assumeDefinedToGetStrictNullChecksRunning : <T>(
	value : T | null | undefined,
	title ?: string,
	details ?: string
) => asserts value is NonUndefined<NonNullAndNonUndefined<T>> =
(value, title, details) => {
	if (value !== null && value !== undefined) return;
	let reasonString = details ? `Details: »${details}«` : '';
	if (!reasonString) reasonString = value === null ? 'value is null.' : 'value is undefined.';
	const expressionString = title ?? 'value';
	throw new TypeError(`Pre-StrictNullCheck-Code: ${expressionString} must be defined here. ${reasonString}`);
};

type IsNullable<T> = null extends T ? T : never;

// cSpell:ignore Undefinedable
type IsUndefinedable<T> = undefined extends T ? T : never;
type StrictNonNullable<T> = T extends null ? never : T;
type StrictNonUndefinable<T> = T extends undefined ? never : T;
/* eslint-disable-next-line @typescript-eslint/ban-types, jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
export type NonNullAndNonUndefined<T> = NonNullable<T>;

/** A type utility to remove `undefined` from a type */
export type NonUndefined<T> = T extends undefined ? never : T;

/**
 * Throws if the value is nullish.
 * Manipulates the type during compile time (control flow analysis).
 * @param value The value to check
 * @param title A human readable title for this assumption. This will be added to the error message.
 * Example: If you pass `myVariable` as {@link condition}, you can set this to `'myVariable'`.
 * @param details Provide further instructions to the developer or a bug-tracker id here.
 */
export const assumeNonNull : <T>(
	value : NonUndefined<IsNullable<T>>,
	title ?: string,
	details ?: string
) => asserts value is StrictNonNullable<NonUndefined<IsNullable<T>>> = (value, title, details) => {
	// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	if (value !== null) {
		if (value === undefined) throw new TypeError(`assumeNonNull() should never be applied to a \`undefined\` value. Use assumeNotUndefined() instead.`);
		return;
	}
	const reasonString = details ? `Details: »${details}«` : '';
	const expressionString = title ?? 'value';
	throw new TypeError(`${expressionString} should not be null here. ${reasonString}`);
};

/**
 * Throws if the value is undefined.
 * Manipulates the type during compile time (control flow analysis).
 * @param value The value to check
 * @param title A human readable title for this assumption. This will be added to the error message.
 * Example: If you pass `myVariable` as {@link condition}, you can set this to `'myVariable'`.
 * @param details Provide further instructions to the developer or a bug-tracker id here.
 * @param isNullable If the value is allowed to be null, set this to true
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export const assumeNotUndefined : <T extends NonNullAndNonUndefined<{}> | undefined>(
	value : StrictNonNullable<IsUndefinedable<T>>,
	title ?: string,
	details ?: string,
	isNullable ?: true,

	// @ts-expect-error -- We don’t want this method to throw every time we use it. So it makes sense for it to accept undefined but have a non-undefined type after Control Flow Analysis
) => asserts value is StrictNonNullable<NonNullAndNonUndefined<IsUndefinedable<T>>> =
(value, title, details, isNullable) => {
	const expressionString = title ?? 'value';
	if (value !== undefined) {
		// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition, no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description', This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (value === null && !isNullable) console.error(`Seems like »${expressionString}« is already null-ready. assumeNotUndefined() can probably be removed.`);
		return value;
	}
	const reasonString = details ? `Details: »${details}«` : '';
	throw new TypeError(`${expressionString} should not be undefined here. ${reasonString}`);
};

/**
 * Throws if the value is not undefined.
 * Manipulates the type during compile time (control flow analysis).
 * @param value The value to check
 * @param title A human readable title for this assumption. This will be added to the error message.
 * Example: If you pass `myVariable` as {@link condition}, you can set this to `'myVariable'`.
 * @param details Provide further instructions to the developer or a bug-tracker id here.
 */
export const assumeIsUndefined : (
	value : unknown,
	title ?: string,
	details ?: string,
) => asserts value is undefined =
(value, title, details) => {
	if (value === undefined) {
		return value;
	}
	const expressionString = title ?? 'value';
	const reasonString = details ? `Details: »${details}«` : '';
	throw new TypeError(`${expressionString} should be undefined here. ${reasonString}`);
};

/**
 * Throws if the value is undefined. Null is OK.
 * In contrast to `assumeNotUndefined`, this method does not manipulate the type during compile time (control flow analysis).
 * Instead it returns the value without the `undefined` type.
 * @param value The value to check
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export const notUndefined = function<T extends NonNullAndNonUndefined<{}> | undefined>(
	value : StrictNonNullable<IsUndefinedable<T>>,
) : NonNullAndNonUndefined<T> {
	if (value === undefined) {
		throw new TypeError(`value should not be undefined here.`);
	}

	return value;
};

/**
 * Throws if the value is `null`. `undefined` is OK.
 * In contrast to `assumeNonNull`, this method does not manipulate the type during compile time (control flow analysis).
 * Instead it returns the value without the `null` type.
 * @param value The value to check
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export const notNull = function<T extends NonNullAndNonUndefined<{}> | null>(
	value : StrictNonUndefinable<IsNullable<T>>,
) : NonNullAndNonUndefined<T> {
	if (value === null) {
		throw new TypeError(`value should not be null here.`);
	}

	return value;
};

/**
 * Throws if the condition is not true.
 * Manipulates the type during compile time (control flow analysis).
 * @param condition The condition to check
 * @param title A human readable title for this assumption. This will be added to the error message.
 * Example: If you pass `!this.contains(member)` as {@link condition}, you can set this to `'Member is not in the list'`
 * @param details Provide further instructions to the developer or a bug-tracker id here.
 */
export const assume : <T>(
	condition : T | true,
	title ?: string,
	details ?: string
) => asserts condition = (condition, title, details) => {
	if (condition === true) return;
	const reasonString = details ? `Details: »${details}«` : '';
	const expressionString = title ?? 'condition';
	throw new TypeError(`${expressionString} must be true here. ${reasonString}`);
};

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type NonEmptyArray<T> = [T, ...T[]];

/**
 * Throws if the value is an empty array.
 * Manipulates the type during compile time (control flow analysis).
 * @param array The array to check
 * @param title A human readable title for this assumption. This will be added to the error message.
 * Example: The name of the array. If you pass `this.shifts` as {@link array}, you can set `name` to `'shifts'`
 * @param details Provide further instructions to the developer or a bug-tracker id here.
 */
export const assumeNotEmpty : <T>(
	array : T[],
	title ?: string,
	details ?: string
) => asserts array is NonEmptyArray<T> = (array, title, details) => {
	if (array.length === 0) return;
	const reasonString = details ? `Details: »${details}«` : '';
	const expressionString = title ?? 'value';
	throw new TypeError(`${expressionString} must be true here. ${reasonString}`);
};
