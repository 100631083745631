/**
 * All themes that are available in the bootstrap framework
 */
// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export const PThemePool = {
	/** Use for call-to-action, highlighting, etc. and everything corporate-identity-related as it represents »Plano’s orange« */
	PRIMARY: 'primary',

	/** Use for successful/positive events/options/… */
	SUCCESS: 'success',

	/** Use for optional/additional events/options/… */
	INFO: 'info',

	/** Use for unexpected but non-critical events/options/… */
	WARNING: 'warning',

	/** Use only for critical/unexpected events/options/… */
	DANGER: 'danger',

	SECONDARY: 'secondary',
	LIGHT: 'light',
	DARK: 'dark',
	PURPLE: 'purple',
	PISTACHIO: 'pistachio',
	SEPIA: 'sepia',
} as const;

/**
 * Type of all themes that are available in the bootstrap framework
 * If you want to limit the possible values, do this: `ExtractFromUnion<'danger' | 'primary', PThemeEnum>`
 */
export type PThemeEnum = typeof PThemePool[keyof typeof PThemePool];

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PTextColorEnum {
	MUTED = 'muted',
	WHITE = 'white',
}
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PTextColor = PTextColorEnum | PThemeEnum;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PBackgroundColorEnum {
	WHITE = 'white',
}
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PBackgroundColor = PBackgroundColorEnum | PThemeEnum;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PBtnThemeEnum {
	OUTLINE_PRIMARY = 'outline-primary',
	OUTLINE_SUCCESS = 'outline-success',
	OUTLINE_INFO = 'outline-info',
	OUTLINE_WARNING = 'outline-warning',
	OUTLINE_DANGER = 'outline-danger',
	OUTLINE_SECONDARY = 'outline-secondary',
	OUTLINE_LIGHT = 'outline-light',
	OUTLINE_DARK = 'outline-dark',
	OUTLINE_PURPLE = 'outline-purple',
	OUTLINE_PISTACHIO = 'outline-pistachio',
	OUTLINE_SEPIA = 'outline-sepia',
}

/**
 * Take PBtnThemeEnum and return the corresponding PThemeEnum
 * @example Turns 'outline-primary' into 'primary'
 * @param theme The theme to convert
 */
export const getThemeFromBtnTheme = (
	theme : PBtnThemeEnum,
) : PThemeEnum => {
	switch (theme) {
		case PBtnThemeEnum.OUTLINE_PRIMARY:
			return PThemePool.PRIMARY;
		case PBtnThemeEnum.OUTLINE_SUCCESS:
			return PThemePool.SUCCESS;
		case PBtnThemeEnum.OUTLINE_INFO:
			return PThemePool.INFO;
		case PBtnThemeEnum.OUTLINE_WARNING:
			return PThemePool.WARNING;
		case PBtnThemeEnum.OUTLINE_SECONDARY:
			return PThemePool.SECONDARY;
		case PBtnThemeEnum.OUTLINE_LIGHT:
			return PThemePool.LIGHT;
		case PBtnThemeEnum.OUTLINE_DANGER:
			return PThemePool.DANGER;
		case PBtnThemeEnum.OUTLINE_DARK:
			return PThemePool.DARK;
		case PBtnThemeEnum.OUTLINE_PURPLE:
			return PThemePool.PURPLE;
		case PBtnThemeEnum.OUTLINE_PISTACHIO:
			return PThemePool.PISTACHIO;
		case PBtnThemeEnum.OUTLINE_SEPIA:
			return PThemePool.SEPIA;
	}
};

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PBtnTheme = PBtnThemeEnum | PThemeEnum;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PCalloutThemeEnum {
	PLAIN = 'plain',
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PCalloutTheme = PCalloutThemeEnum | PThemeEnum;

/**
 * All themes that are available in the bootstrap framework
 */
export enum BootstrapRounded {

	/** only on the left side */
	LEFT = 'left',

	/** only on the right side */
	RIGHT = 'right',

	/** never on the left side */
	START_0 = 'start-0',

	/** never on the right side */
	END_0 = 'end-0',

	/** no rounding */
	NONE = 'none',
}

/**
 * Different available amount of padding
 */
export type BootstrapPaddings = 0 | 1 | 2 | 3 | 4 | 5;

/**
 * Very common sizes in bootstrap
 */
export enum BootstrapSizePool {

	// xxs,

	XS = 'xs',

	/** Primary */
	SM = 'sm',

	MD = 'md',

	/** Something normal */
	LG = 'lg',

	/** Something normal */
	XL = 'xl',

	/** Something big */
	XXL = 'xxl',
}

/**
 * Type of all themes that are available in the bootstrap framework
 */
export type BootstrapSize = typeof BootstrapSizePool[keyof typeof BootstrapSizePool];

/**
 * @deprecated YOU SHOULD PREFER PThemeEnum
 * All themes that are available in the bootstrap framework
 */
export enum BootstrapStyles {

	/** Primary */
	PRIMARY = 'primary',

	/** Something normal */
	SECONDARY = 'secondary',

	/** Light */
	LIGHT = 'light',

	/** Dark */
	DARK = 'dark',

	/** Danger */
	DANGER = 'danger',
}

/**
 * The possible width of a col in bootstrap.
 * Can be used for classes like class="col-{{bootstrapWidth}}"
 */
export type BootstrapGridWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

/**
 * Utility function to check if the theme is a colorful theme.
 * This is useful to determine if styles should be different inside a colorful element.
 * @param theme The theme to check
 */
export const isColorfulTheme = (
	theme : PThemeEnum | PBackgroundColorEnum | PCalloutThemeEnum | null,
) : theme is Exclude<PThemeEnum, (
	typeof PThemePool.WARNING |
	typeof PThemePool.LIGHT |
	typeof PBackgroundColorEnum.WHITE |
	typeof PCalloutThemeEnum.PLAIN |
	null
)> => {
	switch (theme) {
		case PThemePool.PURPLE:
		case PThemePool.DARK:
		case PThemePool.DANGER:
		case PThemePool.PRIMARY:
		case PThemePool.SECONDARY:
		case PThemePool.SUCCESS:
		case PThemePool.INFO:
		case PThemePool.WARNING:
		case PThemePool.PISTACHIO:
		case PThemePool.SEPIA:
			return true;
		case PThemePool.LIGHT:
		case PBackgroundColorEnum.WHITE:
		case PCalloutThemeEnum.PLAIN:
		case null:
			return false;
	}
};

/** A RegEx to catch all flex-grow related utility classes */
export const FLEX_GROW_CLASSES_REGEX = /flex(?:-\w+)?-grow-[\w-]+/gu;

/** A RegEx to catch all align-items related utility classes */
export const ALIGN_ITEMS_CLASSES_REGEX = /align-items-[\w-]+/gu;

/** A RegEx to catch all justify-content related utility classes */
export const JUSTIFY_CONTENT_CLASSES_REGEX = /justify-content-[\w-]+/gu;

/** A RegEx to catch all rounded related utility classes */
export const ROUNDED_CLASSES_REGEX = /rounded-[\w-]+/gu;
