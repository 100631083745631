// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This component is part of of PCommonModule, so we can not use it here TODO: PLANO-184041 Remove this
import { CommonModule, NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, Renderer2 } from '@angular/core';
import { PSimpleChanges } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { FaIconComponentIcon, FaIconComponentTheme } from '@plano/shared/core/p-common/fa-icon/fa-icon-component.types';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PTextAlignType } from '@plano/shared/p-forms/p-input/p-input.types';
import { PCurrencyIconName } from './p-currency-icon-name.enum';

/**
 * A component that shows a FontAwesome icon.
 */
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	selector: 'fa-icon',
	templateUrl: './fa-icon.component.html',
	styleUrls: ['./fa-icon.component.scss'],

	/**
	 * FaIconComponent is a component that gets used in a lot of components, so having it Default causes some performance issues
	 */
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
	],
})
export class FaIconComponent extends NgClass implements PComponentInterface, OnChanges, AfterViewInit {

	/**
	 * When set to true, the component will have a fixed width instead of one that orientates on the width of the icon.
	 * Note that some icons are wider, and some are vertically shorter.
	 * @default true
	 */
	@Input() private fixedWidth : boolean = true;

	/**
	 * The title of the icon, this is needed as otherwise the aria label can be wrongly set.
	 */
	// eslint-disable-next-line no-restricted-syntax -- Intended to target the HTML title attribute
	@Input() private title : string | null = null;

	/**
	 * Does the icon represent a number?
	 * It will be true if the icon key is one of the following:
	 * fa-1, fa-2, fa-3, fa-4, fa-5, fa-6, fa-7, fa-8, fa-9, fa-0
	 */
	@HostBinding('class.is-number')
	private get _isNumber() : boolean {
		return this._icon === 'fa-0 fa-solid' || this._icon === 'fa-1 fa-solid' || this._icon === 'fa-2 fa-solid' || this._icon === 'fa-3 fa-solid' || this._icon === 'fa-4 fa-solid' || this._icon === 'fa-5 fa-solid' || this._icon === 'fa-6 fa-solid' || this._icon === 'fa-7 fa-solid' || this._icon === 'fa-8 fa-solid' || this._icon === 'fa-9 fa-solid';
	}

	/**
	 * The aria-label for the icon.
	 */
	@HostBinding('attr.aria-label') protected get ariaLabel() : string {
		if (this.title !== null) return this.title;
		if (this.iconText) return this.iconText;
		return this.innerAriaLabel ?? '';
	}

	/** Alignment of the text */
	@Input() private textAlign : PTextAlignType | null = null;

	/** Should the icon spin? */
	@Input('spin') private _spin : boolean = false;

	/** Should the icon be flipped horizontally? */
	@Input() private flipHorizontally = false;

	/** Should the icon be flipped vertically? */
	@Input() private flipVertically = false;

	/** Size of the icon */
	@Input() private size : 'xs' | 'sm' | 'lg' | 'xl' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | null = null;

	/** Should the icon be rotated? */
	@Input() private rotate : '90' | '180' | '270' | null = null;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	@Input() public theme : FaIconComponentTheme | null = null;

	/**
	 * Should the icon be aligned with the bottom of the text?
	 */
	@Input() public verticalAlignTextBottom : boolean = false;

	/**
	 * Is the component being rendered as a list icon?
	 */
	@Input() public isListIcon : boolean = false;

	/**
	 * How should the icon be animated?
	 * @default null No animation
	 */
	@Input() public animation : 'beat' | 'shake' | 'fade' | null = null;

	/**
	 * The icon to show in the UI.
	 * Can be null while the icon is loading.
	 */
	@Input('icon') public _icon : FaIconComponentIcon | null = null;

	/**
	 * The icon family to use for this icon.
	 * @deprecated This is a temporary solution, because currently you can not define the family as well as the style at
	 * one and the same {@link _icon}.
	 * TODO: PLANO-188477 Remove this
	 */
	@Input() protected additionalIconFamily : 'fa-duotone' | null = null;

	constructor(
		private console : LogService,
		public elementRef : ElementRef<HTMLElement>,
		private renderer : Renderer2,
	) {
		super(elementRef, renderer);
	}

	public ngAfterViewInit() : void {
		this.validateValues;
	}

	/**
	 * Generate a aria-label for the icon.
	 * This will be used in ui tests for example.
	 * @param iconNameClass The icon class from the pool.
	 * @param theme The theme of the icon.
	 */
	public static generateFaIconAriaLabel(iconNameClass : PlanoFaIconPoolKeys, theme : FaIconComponentTheme | null = null) : string {
		const themeString = theme === null ? '' : ` ${theme}`;
		return `icon(${iconNameClass.split('fa-').map(className => className.trim())[1]}${themeString})`;
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	private validateValues() : void {
		if (this.elementRef.nativeElement.classList.contains('fa-')) {
			this.console.error('Don’t use fontawesome’s »fa-*« classes on this component. Use the input attributes instead.');
		}
	}

	public ngOnChanges(changes : PSimpleChanges<FaIconComponent>) : void {
		this.refreshIconClasses();
		if (!!changes._icon || !!changes.theme) {
			this.refreshAriaLabel();
		}

	}

	private innerAriaLabel : string | null = null;

	private refreshAriaLabel() : void {
		this.innerAriaLabel = FaIconComponent.generateFaIconAriaLabel(this.iconNameClass, this.theme);
	}

	/**
	 * Set or refresh the icon classes based on the current attribute values.
	 */
	public refreshIconClasses() : void {
		this.ngClass = this.iconClasses;

		if (this.verticalAlignTextBottom) {
			this.renderer.addClass(this.elementRef.nativeElement, 'vertical-align-text-bottom');
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, 'vertical-align-text-bottom');
		}
	}

	private get spin() : boolean {
		if (this.isLoading) return false;
		return this._spin;
	}

	/**
	 * Fontawesome Style for this icon
	 * @returns the style class, or null if no style class is needed.
	 * @default 'duotone' in most cases. 'solid' for some icons where 'duotone' did not look good.
	 */
	private get iconStyle() : 'fa-solid' | 'fa-duotone' | null {
		// If the provided icons already has the style in it, we don’t need to add another one.
		const extractStyleFromIcon = this.icon?.split(' ')[1] ?? null;
		if (extractStyleFromIcon !== null) return null;

		switch (this.icon) {
			case PCurrencyIconName.CZK:
			case PCurrencyIconName.RON:
			case PCurrencyIconName.SEK:
				return 'fa-solid';
			default:
				return 'fa-duotone';
		}
	}

	private get icon() : FaIconComponent['_icon'] {
		if (this.isLoading) return enumsObject.PlanoFaIconContextPool.LOADING;
		return this._icon;
	}

	/**
	 * Some icons are not available in FontAwesome, like Kč for Czech koruna.
	 * In these cases we show simple ascii chars.
	 *
	 * @returns the text to show, or null if no text should be shown. If still loading, undefined.
	 */
	public get iconText() : string | undefined | null {
		if (this.isLoading) return undefined;
		if (this.icon === PCurrencyIconName.CZK) return 'Kč';
		if (this.icon === PCurrencyIconName.SEK) return 'kr';
		if (this.icon === PCurrencyIconName.RON) return 'RON';
		return null;
	}

	/**
	 * Classes to be added to the icon
	 */
	protected get iconClasses() : string[] {
		const result = [];

		if (this.isLoading) result.push('text-skeleton-animated');

		if (this.theme !== null) result.push(`text-${this.theme}`);

		if (this.iconText) result.push(`fa-text-icon`);

		if (this.fixedWidth && !this.iconText) result.push('fa-fw');
		if (this.textAlign) {
			switch (this.textAlign) {
				case 'left':
					result.push('text-start');
					break;
				case 'right':
					result.push('text-end');
					break;
				case 'center':
					result.push('text-center');
			}
		}
		if (this.spin) result.push('fa-spin');

		if (this.size === null) {
			result.push(`fa-default-size`);
		} else {
			result.push(`fa-${this.size}`);
		}

		if (this.rotate) result.push(`fa-rotate-${this.rotate}`);

		if (this.flipHorizontally) result.push('fa-flip-horizontal');
		if (this.flipVertically) result.push('fa-flip-vertical');

		if (this.isListIcon) {
			result.push('fa-li');
		}

		if (this.iconStyle) result.push(this.iconStyle);

		// eslint-disable-next-line deprecation/deprecation -- TODO: PLANO-188477
		if (this.additionalIconFamily) result.push(this.additionalIconFamily);

		if (!this.iconText)
			result.push(this.iconNameClass);

		if (this.animation)
			result.push(`fa-${this.animation}`);

		return result;
	}

	/**
	 * The name of the icon
	 */
	protected get iconNameClass() : PlanoFaIconPoolKeys {
		if (this.isLoading) return enumsObject.PlanoFaIconContextPool.LOADING;
		if (Config.DEBUG && this.icon === null) this.console.error('Icon should not be null here');
		if (this.iconText) this.console.error('Icon should not be text here');
		return this.icon! as PlanoFaIconPoolKeys;
	}
}
