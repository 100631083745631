/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "export_shift_exchange_statistics_excel".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	SHIFT_EXCHANGE_IDS = 1;
	MEMBER_IDS = 2;
	SHIFT_MODEL_IDS = 3;
}


@Injectable({
  providedIn: 'root',
})
export class ExportShiftExchangeStatisticsExcelApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_shift_exchange_statistics_excel');
	}

	consts = new Consts();

	protected version() : string {
		return '5231c22987cb915a63a2fa10b199a408,21be4eb23f2d3f339717145a00b81232';
	}

	private dataWrapper = new ExportShiftExchangeStatisticsExcelApiRoot(this);

	get data() : ExportShiftExchangeStatisticsExcelApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportShiftExchangeStatisticsExcelApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportShiftExchangeStatisticsExcelApiRoot(this);
	}
}

		 
export class ExportShiftExchangeStatisticsExcelApiRoot extends ApiObjectWrapper<ExportShiftExchangeStatisticsExcelApiRoot>
{
	constructor(override readonly api : ExportShiftExchangeStatisticsExcelApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportShiftExchangeStatisticsExcelApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportShiftExchangeStatisticsExcelApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiRoot, ExportShiftExchangeStatisticsExcelApiRoot> = new ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiRoot, ExportShiftExchangeStatisticsExcelApiRoot>({
			apiObjWrapper: this as any as ExportShiftExchangeStatisticsExcelApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : ExportShiftExchangeStatisticsExcelApiRoot) {
				return ((this.api.pPermissionsService.hasManagerPermission));
			},
			hasPermissionToSet: function(this : ExportShiftExchangeStatisticsExcelApiRoot) {
				{const conditionValue = this.api.pPermissionsService.hasManagerPermission; if(!conditionValue) return conditionValue === false ? false : undefined;}
				return true;
			},
			defaultValue: function(this : ExportShiftExchangeStatisticsExcelApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private shiftExchangeIdsWrapper : ExportShiftExchangeStatisticsExcelApiShiftExchangeIds = new ExportShiftExchangeStatisticsExcelApiShiftExchangeIds(this.api,
		this as unknown as ExportShiftExchangeStatisticsExcelApiRoot, false, false);
	public aiShiftExchangeIds = this.shiftExchangeIdsWrapper.aiThis;

	/**
	 * Id list of the shifts to be exported.
	 */
	get shiftExchangeIds() : ExportShiftExchangeStatisticsExcelApiShiftExchangeIds {
		this.getterDebugValidations(this.aiShiftExchangeIds, false);
		return this.shiftExchangeIdsWrapper;
	}

	set shiftExchangeIdsTestSetter(v : ExportShiftExchangeStatisticsExcelApiShiftExchangeIds) {
        this.setterImpl(1, v.rawData, 'shiftExchangeIds', true, null, function(this : ExportShiftExchangeStatisticsExcelApiRoot) {this.shiftExchangeIdsWrapper = v;});
	}

	private memberIdsWrapper : ExportShiftExchangeStatisticsExcelApiMemberIds = new ExportShiftExchangeStatisticsExcelApiMemberIds(this.api,
		this as unknown as ExportShiftExchangeStatisticsExcelApiRoot, false, false);
	public aiMemberIds = this.memberIdsWrapper.aiThis;

	/**
	 * Id list of the members to be exported.
	 */
	get memberIds() : ExportShiftExchangeStatisticsExcelApiMemberIds {
		this.getterDebugValidations(this.aiMemberIds, false);
		return this.memberIdsWrapper;
	}

	set memberIdsTestSetter(v : ExportShiftExchangeStatisticsExcelApiMemberIds) {
        this.setterImpl(2, v.rawData, 'memberIds', true, null, function(this : ExportShiftExchangeStatisticsExcelApiRoot) {this.memberIdsWrapper = v;});
	}

	private shiftModelIdsWrapper : ExportShiftExchangeStatisticsExcelApiShiftModelIds = new ExportShiftExchangeStatisticsExcelApiShiftModelIds(this.api,
		this as unknown as ExportShiftExchangeStatisticsExcelApiRoot, false, false);
	public aiShiftModelIds = this.shiftModelIdsWrapper.aiThis;

	/**
	 * Id list of the shift model to be exported.
	 */
	get shiftModelIds() : ExportShiftExchangeStatisticsExcelApiShiftModelIds {
		this.getterDebugValidations(this.aiShiftModelIds, false);
		return this.shiftModelIdsWrapper;
	}

	set shiftModelIdsTestSetter(v : ExportShiftExchangeStatisticsExcelApiShiftModelIds) {
        this.setterImpl(3, v.rawData, 'shiftModelIds', true, null, function(this : ExportShiftExchangeStatisticsExcelApiRoot) {this.shiftModelIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftExchangeIdsWrapper._fixIds(_idReplacements);
		this.memberIdsWrapper._fixIds(_idReplacements);
		this.shiftModelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftExchangeIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.memberIdsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.shiftModelIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportShiftExchangeStatisticsExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportShiftExchangeStatisticsExcelApiShiftExchangeIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportShiftExchangeStatisticsExcelApiService,
		override readonly parent : ExportShiftExchangeStatisticsExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftExchangeIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportShiftExchangeStatisticsExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportShiftExchangeStatisticsExcelApiShiftExchangeIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftExchangeIds, ExportShiftExchangeStatisticsExcelApiShiftExchangeIds> = new ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftExchangeIds, ExportShiftExchangeStatisticsExcelApiShiftExchangeIds>({
			apiObjWrapper: this as any as ExportShiftExchangeStatisticsExcelApiShiftExchangeIds,
			name: 'shiftExchangeIds',
			nodeName: 'SHIFT_EXCHANGE_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportShiftExchangeStatisticsExcelApiShiftExchangeIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 1,
		});
	aiShiftExchangeId : ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftExchangeIds, Id> = new ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftExchangeIds, Id>({
			apiObjWrapper: this as any as ExportShiftExchangeStatisticsExcelApiShiftExchangeIds,
			name: 'shiftExchangeId',
			nodeName: 'SHIFT_EXCHANGE_ID',
			type: PApiType.Id,
			
		});
}

				 export class ExportShiftExchangeStatisticsExcelApiMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportShiftExchangeStatisticsExcelApiService,
		override readonly parent : ExportShiftExchangeStatisticsExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'memberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportShiftExchangeStatisticsExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportShiftExchangeStatisticsExcelApiMemberIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '3';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiMemberIds, ExportShiftExchangeStatisticsExcelApiMemberIds> = new ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiMemberIds, ExportShiftExchangeStatisticsExcelApiMemberIds>({
			apiObjWrapper: this as any as ExportShiftExchangeStatisticsExcelApiMemberIds,
			name: 'memberIds',
			nodeName: 'MEMBER_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportShiftExchangeStatisticsExcelApiMemberIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 2,
		});
	aiMemberId : ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiMemberIds, Id> = new ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiMemberIds, Id>({
			apiObjWrapper: this as any as ExportShiftExchangeStatisticsExcelApiMemberIds,
			name: 'memberId',
			nodeName: 'MEMBER_ID',
			type: PApiType.Id,
			
		});
}

				 export class ExportShiftExchangeStatisticsExcelApiShiftModelIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportShiftExchangeStatisticsExcelApiService,
		override readonly parent : ExportShiftExchangeStatisticsExcelApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftModelIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportShiftExchangeStatisticsExcelApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportShiftExchangeStatisticsExcelApiShiftModelIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '4';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftModelIds, ExportShiftExchangeStatisticsExcelApiShiftModelIds> = new ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftModelIds, ExportShiftExchangeStatisticsExcelApiShiftModelIds>({
			apiObjWrapper: this as any as ExportShiftExchangeStatisticsExcelApiShiftModelIds,
			name: 'shiftModelIds',
			nodeName: 'SHIFT_MODEL_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportShiftExchangeStatisticsExcelApiShiftModelIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 3,
		});
	aiShiftModelId : ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftModelIds, Id> = new ApiAttributeInfo<ExportShiftExchangeStatisticsExcelApiShiftModelIds, Id>({
			apiObjWrapper: this as any as ExportShiftExchangeStatisticsExcelApiShiftModelIds,
			name: 'shiftModelId',
			nodeName: 'SHIFT_MODEL_ID',
			type: PApiType.Id,
			
		});
}

		

