import { Component, Input, NgZone, OnInit } from '@angular/core';
import { SLIDE_TO_LEFT_ON_HIDE } from '@plano/animations';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { COOKIE_CONSENT_SETTING_INDEXED_DB_KEY } from '@plano/client/shared/component/p-cookie-consent-manager/cookie-consent-setting.indexeddb.type';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { PIndexedDBService } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { waitForValueNotUndefined } from '@plano/shared/core/utils/async-await-utils';
import { PButtonComponent } from '@plano/shared/p-forms/p-button/p-button.component';

/**
 * Component to show a cookie consent manager, allowing users to accept all cookies or only the necessary ones.
 */
@Component({
	selector: 'p-cookie-consent-manager',
	templateUrl: './cookie-consent-manager.component.html',
	styleUrls: ['./cookie-consent-manager.component.scss'],
	standalone: true,
	imports: [ PCommonModule, PButtonComponent ],
	animations: [SLIDE_TO_LEFT_ON_HIDE],
})
export class PCookieConsentManagerComponent extends PBaseClass implements OnInit {

	/**
	 * Whether the user has chosen a cookie setting.
	 */
	@Input() public hasChosenCookieSetting : boolean = false;

	/**
	 * Whether to show more options in the cookie consent manager.
	 */
	@Input() public showMoreOptions : boolean = false;

	/**
	 * Whether the cookie consent manager should be hidden.
	 */
	@Input() public hidden = true;

	constructor(
		private pIndexedDBService : PIndexedDBService,
		private zone : NgZone,
	) {
		super();
	}

	protected PBtnThemeEnum = PBtnThemeEnum;

	public ngOnInit() : void {

		// If we are on a page for the booking process, we should not show the cookie consent manager
		// and we should not load the GTM.
		if (this.isPageForBookingProcess())
			return;

		// In case we are on native apps, we should load the GTM, as there we always allow all cookies.
		// But the cookie consent manager should remain hidden
		if ((Config.platform === 'appAndroid' || Config.platform === 'appIOS')) {
			this.loadGTM();
			return;
		}

		const asyncWrapper = async () => {
			const choseCookieValue = await this.getIndexedDBChoseCookieValue();
			if (choseCookieValue === 'all') {
				this.loadGTM();
			} else if (choseCookieValue === null) {
				this.hidden = false;
			}
		};
		void asyncWrapper();
	}

	private async getIndexedDBChoseCookieValue() : Promise<string | null> {
		return await waitForValueNotUndefined(this.zone, () => this.pIndexedDBService.get(COOKIE_CONSENT_SETTING_INDEXED_DB_KEY));
	}

	/**
	 * User has chosen an option in the cookie consent manager
	 * @param cookieSetting The cookie setting the user has chosen.
	 */
	protected choseCookieSetting(cookieSetting : 'all' | 'essential') : void {
		this.pIndexedDBService.set(COOKIE_CONSENT_SETTING_INDEXED_DB_KEY, cookieSetting);
		this.hasChosenCookieSetting = true;
		if (cookieSetting === 'all') {
			this.loadGTM();
		}
	}

	private isPageForBookingProcess() : boolean {
		return (
			window.location.pathname.includes('/booking/payment') || window.location.pathname.includes('/booking/cancel') || window.location.pathname.includes('/booking/gift-card-sale')
		);
	}

	private loadGTM() : void {
		if (!this.isPageForBookingProcess()) {
			const script = document.createElement('script');
			script.defer = true;
			script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-183004086-1');
			document.querySelectorAll('head')[0].appendChild(script);

			window.dataLayer = window.dataLayer || [];

			window.dataLayer.push('js', new Date());

			// eslint-disable-next-line @typescript-eslint/naming-convention -- From google tag manager
			window.dataLayer.push('config', 'UA-183004086-1', { anonymize_ip: true });
		}
	}
}
