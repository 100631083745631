/*
    NOTE: This File was created to separate angular utils from normal typescript utils, so we can use the typescript utils
    functionalities in our cucumber tests.
*/
import { HttpErrorResponse } from '@angular/common/http';
import { AngularWrappedError } from './error-utils-types';

/** I found such a object in the real world out there. Dont know whats the real type. */
// NOTE: If you want to move this to typescript-utils-angular-types.ts, make sure cucumber is still working. https://drplano.atlassian.net/browse/PLANO-143376?focusedCommentId=26375
export type SomeObjectWithPromiseAndRejection = {
	promise : unknown,
	rejection : unknown,
};

/**
 * A collection of utils to determine error types.
 */
export const errorTypeUtils = {
	isTypeError: (error : Error | Record<string, unknown>) : error is Error => {
		if (error instanceof Error) return true;
		if (error['name'] !== undefined && error['message'] !== undefined) return true;
		return false;
	},

	isTypeErrorEvent: (error : ErrorEvent | Record<string, unknown>) : error is ErrorEvent => {
		if (error instanceof ErrorEvent) return true;
		if (
			error['colno'] !== undefined &&
			error['error'] !== undefined &&
			error['filename'] !== undefined &&
			error['lineno'] !== undefined &&
			error['message'] !== undefined
		) return true;
		return false;
	},

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	isAngularWrappedError: (error : AngularWrappedError | any) : error is AngularWrappedError => {
		if (!error) return false;
		if (error.ngOriginalError === undefined) return false;
		return true;
	},

	isTypeHttpErrorResponse: (error : HttpErrorResponse | Error | Record<string, unknown>) : error is HttpErrorResponse => {
		if (error instanceof HttpErrorResponse) return true;
		if (typeof error.name === 'string' && error.name.includes('HttpErrorResponse')) return true;
		if (typeof error.message === 'string' && error.message.includes('HttpErrorResponse')) return true;
		return false;
	},

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	isTypeSomeObjectWithPromiseAndRejection: (input : any) : input is SomeObjectWithPromiseAndRejection => {
		if (input?.promise !== undefined && input.rejection !== undefined) return true;
		return false;
	},
};
