/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { AfterContentInit, Component } from '@angular/core';
import { PPricesService } from '@plano/client/shared/p-prices.service';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PTranslateEnumPipe } from '@plano/shared/core/pipe/p-translate-enum.pipe';

/**
 * A alert that can be used to inform the user that the currency is not supported for online payments.
 */
@Component({
	selector: 'p-online-payment-not-supported-alert',
	templateUrl: './online-payment-not-supported-alert.component.html',
})
export class POnlinePaymentNotSupportedAlertComponent extends PBaseClass implements AfterContentInit {
	constructor(
		private localize : LocalizePipe,
		private pTranslateEnumPipe : PTranslateEnumPipe,
		protected pPricesService : PPricesService,
		private console : LogService,
	) {
		super();
	}

	protected Config = Config;

	/** ngAfterContentInit */
	public ngAfterContentInit() : void {
		if (this.pPricesService.currencyIsSupportedForOnlinePayment === true) this.console.error('This component should not be used if the currency is supported.');
	}

	/**
	 * The email subject for an email that someone sends to us to ask for support of online-payment for the selected country
	 */
	protected get supportForMyCountryEmailSubject() : string {
		const countryCode = Config.getCountryCode();
		if (countryCode === null) return this.localize.transform('Unterstützung der Online-Zahlung für mein Land');
		return this.localize.transform({
			sourceString: 'Unterstützung der Online-Zahlung für ${country}',
			params: {
				country: this.pTranslateEnumPipe.transform(countryCode),
			},
		});
	}
}
