/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from '@plano/shared/core/p-modal/modal-content.component';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { PModalTemplateContext, PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { isString } from 'underscore';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector -- This is not meant to be used in a template.
	selector: '',
	templateUrl: './confirm-modal.component.html',
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PConfirmModalComponent extends ModalContentComponent {
	constructor(
		private activeModal : NgbActiveModal,
		private localize : LocalizePipe,
	) {
		super();
	}

	public modalContentOptions : ModalContentOptions = {};
	public theme : PModalTemplateContext['theme'] = null;

	/**
	 * Is the description a template ref? If so return it, otherwise return null
	 */
	public modalContentDescriptionTemplate : TemplateRef<unknown> | null = null;

	public pModalId : PModalTemplateDirective['pModalId'] = null;

	/**
	 * Initializes the Modal with the necessary properties from its parent component.
	 * @param contentOptions The content of the modal
	 * @param theme The theme of the modal
	 */
	public initModal(
		contentOptions : ModalContentOptions,
		theme : PModalTemplateContext['theme'] = null,
	) : void {
		this.modalContentOptions = contentOptions;
		if (this.modalContentOptions.description instanceof TemplateRef) {
			this.modalContentDescriptionTemplate = this.modalContentOptions.description;
		}
		if (theme !== null) this.theme = theme;
		if (this.modalContentOptions.closeBtnLabel === undefined) this.modalContentOptions.closeBtnLabel = this.localize.transform('OK');
		if (this.modalContentOptions.hideDismissBtn === undefined) {
			this.modalContentOptions.hideDismissBtn = this.modalContentOptions.dismissBtnLabel === undefined;
		}
	}

	protected isString = isString;

	/**
	 * Close this modal
	 */
	public onClose() : void {
		this.activeModal.close();
	}

	/**
	 * Dismiss this modal
	 */
	public onDismiss() : void {
		this.activeModal.dismiss();
	}
}
