/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable, Injector, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SchedulingApiService } from '@plano/shared/api';
import { pCollapsibleAnimationSpeed } from '@plano/shared/core/component/p-collapsible/p-collapsible.component.const';
import { IdSelector, ScrollTargetSelector, ScrollToSelectorOptions } from '@plano/shared/core/scroll-to-selector.service.types';
import { promiseTimeout, waitForValueNotUndefined, waitUntil } from '@plano/shared/core/utils/async-await-utils';
import { errorTypeUtils } from '@plano/shared/core/utils/error-type-utils';
import { HTML_ID_SELECTOR_REGEX } from '@plano/shared/core/utils/regex-utils';
import { callOnlyOnceOnSubscribe } from '@plano/shared/core/utils/rxjs-utils';
import { Subject } from 'rxjs';
import { LogService } from './log.service';
import { PProgressbarService } from './progressbar.service';

/**
 * Service that provides a method to handle the scrolling into elements.
 * See {@link scrollToSelector} for more information.
 */
@Injectable( { providedIn: 'root' } )
export class PScrollToSelectorService {

	constructor(
		injector : Injector,
		private zone : NgZone,
		private console : LogService,
		private pProgressbarService : PProgressbarService,
		private router : Router,
	) {
		// To avoid circular dependencies we inject manually
		this.schedulingApiService = injector.get(SchedulingApiService);

		// Every time api is loaded…
		this.schedulingApiService.onDataLoaded.subscribe(() => {
			// …wait for a change detection
			callOnlyOnceOnSubscribe(this.changeDetectionTriggered, () => {
				this.startSearchForSelector.next();
			});
		});
	}

	private schedulingApiService : SchedulingApiService;

	public changeDetectionTriggered : Subject<void> = new Subject<void>();
	private startSearchForSelector : Subject<void> = new Subject<void>();

	/**
	 * Handle the necessary classes to perform the animation of the element.
	 * If you want to prevent that the element gets animated, you can set the class `prevent-scroll-animation` on the element.
	 *
	 * @param element Element to be animated
	 */
	public async animateElement(element : HTMLElement) : Promise<void> {
		this.console.debug('Element is visible in scroll area');
		await this.handleScrolledClass(element);
	}

	private async getScrollTargetElement(selector : ScrollTargetSelector) {
		this.console.debug(`Get scroll target ${selector}`);
		let navigationHasHappened = false;
		const routerSubscriber = this.router.events.subscribe((event) => {

			// If the user navigates to another page, we want to stop looking for the element.
			// We only exclude the /undefined route because it is a route that is used to redirect to the correct route, and is temporary,
			// and also navigation marked with 'ignoreNavigationEvent'.
			if (
				event instanceof NavigationStart &&
				!this.router.getCurrentNavigation()?.extras.state?.['ignoreNavigationEvent'] &&
				!window.location.href.includes('/undefined')
			) {
				navigationHasHappened = true;
			}
		});
		return this.zone.runOutsideAngular(async () => {
			// TODO: PLANO-187829 Remove `waitForValueNotUndefined` if possible.
			return await waitForValueNotUndefined(this.zone, () => {
				let result : HTMLElement | null = null;

				// if the selector is an id we want to use getElementById method to be able to use ids that start with numbers
				if (this.selectorIsId(selector)) {
					// Get the element with that id
					// eslint-disable-next-line unicorn/prefer-query-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					result = document.getElementById(selector.slice(1));
				} else {
					// Get the first element with that selector
					result = document.querySelector<HTMLElement>(selector);
				}

				if (navigationHasHappened) {
					this.console.debug(`Navigation has happened. Stopping scroll to ${selector}`);
					routerSubscriber.unsubscribe();
					return null;
				}

				if (result && result.offsetWidth > 0) {
					routerSubscriber.unsubscribe();
					this.console.debug(`Scroll target »${selector}« found`);
					return result;
				}

				return undefined;
			});
		});

	}

	private async handleScrolledClass(element : HTMLElement) {
		await waitUntil(this.zone, () => {
			const elementWithScrolledToThisSelector = document.querySelector('.scrolled-to-this-selector');
			if (elementWithScrolledToThisSelector)
				elementWithScrolledToThisSelector.classList.remove('scrolled-to-this-selector');
			return document.querySelector('.scrolled-to-this-selector') === null;
		});

		// Wait for animation frame to ensure the class is added to the element after it was removed and processed by the
		// browser, to allow the animation to start on the same element if needed.
		window.requestAnimationFrame(() => {
			element.classList.add('scrolled-to-this-selector');
		});
	}

	private selectorIsId(selector : ScrollTargetSelector) : selector is IdSelector {
		return selector.match(HTML_ID_SELECTOR_REGEX) !== null;
	}

	/**
	 * Find the nearest scrollableParent of an element.
	 * @param node The element to find the nearest scrollable parent of
	 * @returns
	 * - HTMLElement the nearest scrollable parent (up to the body if no other is in between)
	 * - null if the node is not part of the DOM
	 */
	public nearestScrollableParent(node : HTMLElement) : HTMLElement | null {
		if (node.tagName === 'BODY') {
			// If this is the body tag, than there will not be another scrollable parent above it.
			return node;
		}

		return this.zone.runOutsideAngular(() => {
			// TODO: PLANO-187829 Remove this block if possible.
			if (node.parentElement === null) {
				// If the node is null, then the element is not part of the DOM
				return null;
			}

			if (
				node.classList.contains('p-scroll-parent') ||
				node.classList.contains('modal-body')
			) {
				return node;
			}
			return this.nearestScrollableParent(node.parentElement);
		});
	}

	private async unCollapseContainedCollapsable(element : HTMLElement) {
		const elements : NodeListOf<HTMLElement> | undefined = element.querySelectorAll<HTMLElement>('p-collapsible.collapsed button') as NodeListOf<HTMLElement> | undefined;
		const firstButtonInside = elements?.[0] ?? null;

		if (firstButtonInside !== null) {
			firstButtonInside.click();
			await promiseTimeout(this.zone, pCollapsibleAnimationSpeed);
		}
	}

	private getScrollIntoViewOptionsWithDefaults(
		scrollIntoViewOptions : ScrollIntoViewOptions | false | undefined,
	) : ScrollIntoViewOptions | false {
		if (scrollIntoViewOptions === false) {
			return false;
		}
		return { behavior: 'smooth', block: 'start', ...scrollIntoViewOptions};
	}

	/**
	 * Wait for an html element with the provided selector to appear in UI and scroll to it.
	 *
	 * If the element contains a collapsible, the collapsible will get clicked.
	 * If the method got called, but the user has already scrolled before the target was ready to be scrolled to, the
	 * automatic scroll will be aborted.
	 *
	 * @param selector An css-like selector. E.g. `#foo` or `.bar`
	 * @param options Options for the scroll-to functionality. Set to `false` to disable scrolling.
	 * @param options.scrollIntoViewOptions Options for JavaScript's scrollIntoView() method. Default is {behavior: 'smooth', block: 'start'}
	 * @param options.animate Should there be a glowing animation after scrolling to the selector? Default is true.
	 */
	public async scrollToSelector(
		selector : ScrollTargetSelector,
		{
			scrollIntoViewOptions,
			animate,
		} : ScrollToSelectorOptions = {},
	) {
		const scrollIntoViewOptionsWithDefaults : ScrollIntoViewOptions | false = this.getScrollIntoViewOptionsWithDefaults(scrollIntoViewOptions);
		const ANIMATED = animate ?? true;
		try {
			let scrollTargetElement : HTMLElement | null = null;

			if (scrollIntoViewOptionsWithDefaults !== false || ANIMATED) {
				scrollTargetElement = await this.getScrollTargetElement(selector);
			}

			// If the scroll target element was not found then we can't proceed.
			if (!scrollTargetElement) return;

			if (scrollIntoViewOptionsWithDefaults !== false) {
				await this.unCollapseContainedCollapsable(scrollTargetElement);

				this.console.debug(`🎉 scrollIntoView »${selector}«`);
				scrollTargetElement.scrollIntoView(scrollIntoViewOptionsWithDefaults);

				// It is possible to have some js code that changes the scroll position or breaks the scrollIntoView.
				// For this reason, we repeat the scrollIntoView until the element is in view.
				await waitUntil(this.zone, () => {
					if (!scrollTargetElement) return false;
					if (scrollTargetElement.getBoundingClientRect().top >= window.innerHeight) {
						scrollTargetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
						return false;
					}
					return true;
				}, 200);
			}

			if (ANIMATED) {
				await this.animateElement(scrollTargetElement);
			}

		} catch (error) {
			// TODO: PLANO-187494 Remove this or turn into a warn if possible.
			this.console.error(error);

			const typedError = error as Parameters<typeof errorTypeUtils.isTypeError>[0];
			if (errorTypeUtils.isTypeError(typedError) && typedError.message.includes('Please get new element')) {
				void this.scrollToSelector(selector, {
					scrollIntoViewOptions : scrollIntoViewOptionsWithDefaults,
					animate : ANIMATED,
				});
			}
		}
		this.pProgressbarService.complete();
	}
}
