import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { openTooltipAsModal } from '@plano/shared/core/directive/open-tooltip-as-modal';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { LogService } from '@plano/shared/core/log.service';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { PModalModule } from '@plano/shared/core/p-modal/modal.module';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-template.directive';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

/**
 * Component to add to any UI element that
 * - needs further explanation like a more difficult to understand form field or
 * - has additional content which would clutter up the UI if shown directly like a long comment of a list item
 *
 * The component will show an icon and when you hover or tab it, it will show the additional content.
 *
 * @example
 * ```html
 * <p-icon-tooltip [icon]="enums.PlanoFaIconContextPool.NOTE">{{item.internalNotes}}</p-icon-tooltip>
 * ```
 *
 * ```html
 * <label>Favorit Music <p-icon-tooltip>This will not be visible to your mom.</p-icon-tooltip></label>
 * <input />
 * ```
 */
@Component({
	selector: 'p-icon-tooltip',
	templateUrl: './icon-tooltip.component.html',
	styleUrls: ['./icon-tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [
		PCommonModule,
		PModalTemplateDirective,
		PModalModule,
		PTooltipDirective,
		FaIconComponent,
	],
})
export class PIconTooltipComponent extends PBaseClass {
	@ViewChild('ngContentRef') public ngContentRef ?: PModalTemplateDirective;

	/** Placement of the tooltip */
	@Input() public placement : NgxFloatUiPlacements = NgxFloatUiPlacements.TOP;

	/**
	 * @see PTooltipDirective#pTooltipMaxWidth
	 */
	@Input() protected pTooltipMaxWidth : PTooltipDirective['pTooltipMaxWidth'] = '250px';

	/**
	 * @see PTooltipDirective#pTooltipMinWidth
	 */
	@Input() protected pTooltipMinWidth : PTooltipDirective['pTooltipMinWidth'] = '0px';

	/**
	 * @see PTooltipDirective#pTooltipAlwaysOpened
	 */
	@Input() protected pTooltipAlwaysOpened : PTooltipDirective['pTooltipAlwaysOpened'] = false;

	/**
	 * The icon that will be shown. If not set internal logic will choose an icon.
	 */
	@Input('icon') private _icon : PlanoFaIconPoolKeys | null = null;

	@Input() public theme : Exclude<PThemeEnum, 'info'> | null = null;

	/**
	 * The theme of the icon that triggers the tooltip, if not set the theme of the tooltip will be used.
	 */
	@Input() protected triggerIconTheme : Exclude<PThemeEnum, 'info'> | null = null;

	/**
	 * If you don’t want the internal logic to add a fixed height, you can pass a defined height like 'auto' here.
	 */
	@Input('height') private _height : 'auto' | null = null;

	/** @see PTooltipDirective#pTooltipFloatUIElementClass */
	@Input() protected pTooltipFloatUIElementClass : string | null = null;

	/**
	 * size of the icon to be shown
	 */
	@Input() public size : 'small' | 'normal' = 'small';

	/**
	 * The spacing that will be added to the icon, if default is passed then the class
	 * px-2 will be added to the icon, if none, the padding should be provided from the outside
	 */
	@Input() public spacing : 'default' | 'none' = 'default';

	/** @see PTooltipDirective['pTooltipTrigger'] */
	@Input() protected pTooltipTrigger : PTooltipDirective['pTooltipTrigger'] = NgxFloatUiTriggers.hover;

	/** @see PTooltipDirective['pTooltipHeadline'] */
	@Input() protected pTooltipHeadline : PTooltipDirective['pTooltipHeadline'] = null;

	/** @deprecated Use {@link pTooltipHeadline} instead */
	@Input() protected headline ! : never;

	/** @see PTooltipDirective['pTooltipShow'] */
	@Input() protected pTooltipShow : PTooltipDirective['pTooltipShow'] = null;

	/** @see PTooltipDirective['pTooltipAppendToBody'] */
	@Input() public pTooltipAppendToBody : PTooltipDirective['pTooltipAppendToBody'] = true;

	@ContentChild(TemplateRef) public innerTemplate : TemplateRef<ElementRef<HTMLElement>> | null = null;

	constructor(
		private console : LogService,
		private modalService : ModalService,
	) {
		super();
	}

	protected readonly Config = Config;

	public NgxFloatUiTriggers = NgxFloatUiTriggers;
	public NgxFloatUiPlacements = NgxFloatUiPlacements;

	/**
	 * The icon to show as trigger element.
	 */
	protected get icon() : PlanoFaIconPoolKeys {
		if (this._icon !== null) return this._icon;
		switch (this.theme) {
			case this.enums.PThemeEnum.WARNING:
				return this.enums.PlanoFaIconContextPool.TOOLTIP_WARNING;
			default:
				return this.enums.PlanoFaIconContextPool.TOOLTIP_INFO;
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public onClickIcon(event : Event) : void {
		if (openTooltipAsModal()) {
			event.stopPropagation();
			if (!this.ngContentRef) {
				this.console.error('ngContentRef not defined');
				return;
			}
			this.modalService.openModal(this.ngContentRef);
		}
	}

	/**
	 * The theme of the icon that triggers the tooltip, if not set the theme of the tooltip will be used.
	 */
	protected get themeForIcon() : Exclude<PThemeEnum, 'info'> | null {
		if (this.triggerIconTheme !== null) return this.triggerIconTheme;
		return this.theme;
	}
}
