/* eslint max-lines: ["error", 1200] -- Don’t make this file even bigger. Invest some time to cleanup/simplify if possible */
import { Injectable, NgZone } from '@angular/core';
import { ReportFilterService } from '@plano/client/report/report-filter.service';
import { SchedulingFilterService } from '@plano/client/scheduling/scheduling-filter.service';
import { SchedulingApiBirthday } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { FILTER_SERVICE_INDEXED_DB_HIDDEN_ACTIVITY_AREAS_KEY, FILTER_SERVICE_INDEXED_DB_HIDDEN_MEMBERS_KEY, FILTER_SERVICE_INDEXED_DB_HIDDEN_SHIFT_MODELS_KEY, FilterServiceIndexedDBKeyPrefixDataType, filterServiceIndexedDBKeyWithPrefix } from '@plano/client/shared/filter.service.indexeddb.type';
import { P_DROPDOWN_LABELS_FILTER_CHECKED_LABEL_IDS, P_DROPDOWN_LABELS_FILTER_FILTER_NO_LABEL, P_DROPDOWN_LABELS_FILTER_POSITIVE_FILTER, PDropdownLabelsFilterIndexedDBKeyPrefixDataType, PDropdownLabelsFilterIndexedDBKeyPrefixFilterType, pDropdownLabelsFilterIndexedDBKeyWithPrefix } from '@plano/client/shared/p-dropdown-labels-filter/p-dropdown-labels-filter.indexeddb.type';
import { ApiBase, ApiDataWrapperBase, ApiListWrapper, ApiObjectWrapper, SchedulingApiActivityArea, SchedulingApiActivityAreas, SchedulingApiBooking, SchedulingApiHolidayItemType, SchedulingApiHolidaysHolidayItem, SchedulingApiHolidaysLabel, SchedulingApiLeave, SchedulingApiLeaves, SchedulingApiMember, SchedulingApiMembers, SchedulingApiPermissionGroupRole, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel, SchedulingApiShiftModels, SchedulingApiTodaysShiftDescription, SchedulingApiWorkingTime } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { DataInput } from '@plano/shared/core/data/data-input';
import { PIndexedDBKeyDataType, PIndexedDBService, PServiceWithIndexedDBInterface } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { PServiceInterface } from '@plano/shared/core/interfaces/p-service.interface';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { waitForValueNotUndefined, waitUntil } from '@plano/shared/core/utils/async-await-utils';
import { assume, notNull } from '@plano/shared/core/utils/null-type-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- already extends another class
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subject, Subscription } from 'rxjs';
import { ClientRoutingService, CurrentPageEnum } from './routing.service';

type FilterLists = SchedulingApiMembers | SchedulingApiShiftModels | SchedulingApiActivityAreas;

/**
 * Type of all elements that can be shown or hidden by the filter service.
 */
export type FilterItem = (
	SchedulingApiShiftModel |
	SchedulingApiMember |
	SchedulingApiActivityArea |
	SchedulingApiShift |
	SchedulingApiLeave |
	SchedulingApiLeaves |
	SchedulingApiHolidaysHolidayItem |
	SchedulingApiBirthday |
	SchedulingApiBooking |
	SchedulingApiWorkingTime |
	SchedulingApiTodaysShiftDescription |
	SchedulingApiHolidaysHolidayItem |
	FilterLists
);

/**
 * Interface that should be used by every service that is responsible for filtering.
 */
export interface FilterServiceInterface extends PServiceInterface {
	isVisible(input : unknown) : boolean;
	unloadFilters() : void;
}

/**
 * Wrapper for handling (read and write) a list to the indexedDB.
 */
export class IndexedDBListOfDataWrappers<T extends ApiDataWrapperBase> extends ApiListWrapper<T> {
	constructor(
		api : ApiBase,
		private pIndexedDBService : PIndexedDBService,
		private indexedDBData : PIndexedDBKeyDataType,
	) {
		super(api, null, true, false, 'IndexedDBListOfDataWrappers');
	}

	private skipIndexedDBCommunication = false;

	/**
	 * Get an array of the indexedDBs
	 */
	public get indexedDBArray() : number[] {
		if (!(this.pIndexedDBService.has(this.indexedDBData))) return [];
		const indexedDBValue = this.pIndexedDBService.get(this.indexedDBData)!;
		const result = JSON.parse(indexedDBValue);
		if (!Array.isArray(result)) return [];
		return result;
	}

	protected override containsIds() : boolean {
		return false;
	}

	protected override createInstance() : this {
		return new IndexedDBListOfDataWrappers<T>(this.api, this.pIndexedDBService, this.indexedDBData) as typeof this;
	}

	protected override containsPrimitives() : boolean {
		return false;
	}

	public override filterBy(filterFunction : (item : T) => boolean) : this {
		this.skipIndexedDBCommunication = true;
		const result = super.filterBy(filterFunction);
		this.skipIndexedDBCommunication = false;
		return result;
	}

	/**
	 * Push items into the indexedDB Array based on an list
	 * @param items The items to push
	 */
	public pushFromListOfItems(items : ApiListWrapper<T>) {
		this.skipIndexedDBCommunication = true;

		// Make sure one item is not added twice
		for (const item of items.iterable()) {
			if (this.contains(item)) continue;
			super.push(item);
		}
		this.skipIndexedDBCommunication = false;
		const itemsToAdd = JSON.stringify(this.map(item => +item.id!.toString()));
		this.pIndexedDBService.set(this.indexedDBData, itemsToAdd);
	}

	/**
	 * Remove items from the indexedDB Array based on an list
	 * @param items The items to remove
	 */
	public removeFromListOfItems(items : ApiListWrapper<T>) {
		this.skipIndexedDBCommunication = true;
		for (const item of items.iterable()) {
			this.removeItem(item);
		}
		this.skipIndexedDBCommunication = false;
		const itemsToAdd = JSON.stringify(this.map(item => +item.id!.toString()));
		this.pIndexedDBService.set(this.indexedDBData, itemsToAdd);
	}

	public override push(item : T) : void {
		// Make sure one item is not added twice
		assume(!this.contains(item), 'item is not yet in list', 'This should not happen. Why was this item added again?');
		super.push(item);
	}

	protected override onItemAdded(item : T) {
		super.onItemAdded(item);
		if (!this.skipIndexedDBCommunication)
			this.addIdToIndexedDB(item);
	}

	protected override onItemRemove(item : T) {
		super.onItemRemove(item);
		if (!this.skipIndexedDBCommunication)
			this.removeIdFromIndexedDB(item);
	}

	private addIdToIndexedDB(item : T) {
		const primitiveArray = this.indexedDBArray;
		if (!primitiveArray.includes(+item.id!.toString())) primitiveArray.push(+item.id!.toString());
		this.pIndexedDBService.set(this.indexedDBData, JSON.stringify(primitiveArray));
	}

	private removeIdFromIndexedDB(item : T) {
		const idArray = this.indexedDBArray;
		const indexOfItem = idArray.indexOf(item.id!.rawData);
		if (indexOfItem > -1) idArray.splice(indexOfItem, 1);
		this.pIndexedDBService.set(this.indexedDBData, JSON.stringify(idArray));
	}

	public override clear() {
		this.pIndexedDBService.delete(this.indexedDBData);
		this.dataUser = [];
		super.clear();
	}

	protected override get dni() : string {
		return '0';
	}
}

// TODO: PLANO-188887 If you want to change things on this service, note that the tests for it are currently commented
// out. Search for `PLANO-188887` in this project for more infos. So if you want to change things, you should temporarily
// comment them back in and at least run them locally.
// TODO: PLANO-114999 Are the following docs still up-to-date?
/**
 * Filter items in various areas of our app. It stores items like (for example activities, activity areas or members),
 * and provides methods like {@link isVisible} to check if related items (for example shifts, bookings, etc.) should be
 * visible.
 *
 * Most of this information gets stored in the indexedDBs (see {@link PIndexedDBService}).
 *
 * Note that it is possible to show/hide/toggle trashed activities, even though they are currently never shown in the
 * sidebar. So this will have an effect on the visibility of items like shifts, bookings etc, but not on methods of this
 * service that to tasks like counting hidden items (see {@link filterOutTrashedActivities}).
 */
@Injectable( { providedIn: 'root' } )
export class FilterService extends DataInput
	implements PServiceWithIndexedDBInterface, FilterServiceInterface {

	constructor(
		public api : SchedulingApiService,
		private pIndexedDBService : PIndexedDBService,
		public override zone : NgZone,
		private clientRoutingService : ClientRoutingService,
		private reportFilterService : ReportFilterService,
		public schedulingFilterService : SchedulingFilterService,
		private localizePipe ?: LocalizePipe,
		private toastsService ?: ToastsService,
	) {
		super(zone);

		this.reportFilterServiceSubscription = this.reportFilterService.onChange.subscribe(() => { this.changed(null); });
		this.schedulingFilterServiceSubscription = this.schedulingFilterService.onChange.subscribe(() => { this.changed(null); });

		this.hiddenItems = {
			members: new IndexedDBListOfDataWrappers(this.api, this.pIndexedDBService, filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_MEMBERS_KEY, this._indexedDBKeyPrefix)),
			activities: new IndexedDBListOfDataWrappers(
				this.api,
				this.pIndexedDBService,
				filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_SHIFT_MODELS_KEY, this._indexedDBKeyPrefix),
			),
			activityAreas: new IndexedDBListOfDataWrappers(
				this.api,
				this.pIndexedDBService,
				filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_ACTIVITY_AREAS_KEY, this._indexedDBKeyPrefix),
			),
			publicHolidaysLabels: null,
			schoolHolidaysLabels: null,
		};

		/**
		 * Guess what happens when an item gets removed from api.data through an api.load? Throw.
		 * So here we make sure every api.data load refills the arrays with valid data.
		 */
		this.api.onDataLoaded.subscribe(() => {
			this.hiddenItems['members'] = new IndexedDBListOfDataWrappers(this.api, this.pIndexedDBService, filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_MEMBERS_KEY, this._indexedDBKeyPrefix));
			this.hiddenItems['activities'] = new IndexedDBListOfDataWrappers(this.api, this.pIndexedDBService, filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_SHIFT_MODELS_KEY, this._indexedDBKeyPrefix));
			this.hiddenItems['activityAreas'] = new IndexedDBListOfDataWrappers(this.api, this.pIndexedDBService, filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_ACTIVITY_AREAS_KEY, this._indexedDBKeyPrefix));
			void this.readIndexedDBAndInitValues();
		});
	}

	private _indexedDBKeyPrefix : FilterServiceIndexedDBKeyPrefixDataType = 'SchedulingFilterService';

	/**
	 * Set the prefix for the indexedDB keys for this instance of the service
	 */
	public set indexedDBKeyPrefix(value : FilterServiceIndexedDBKeyPrefixDataType) {
		this._indexedDBKeyPrefix = value;
	}

	public indexedDBHasBeenReadObservable = new Subject<void>();

	private async readIndexedDBAndInitValues() : Promise<void> {
		await this.readIndexedDB();
		this.initValues();
		this.indexedDBHasBeenReadObservable.next();
	}

	/**
	 * Are any filters active that can be controlled by the sidebar?
	 */
	public get hasSideBarFilters() : boolean {
		if (this.hiddenItems.members.length > 0) return true;
		if (this.hiddenItems.activities.length > 0) return true;
		if (this.hiddenItems.activityAreas.length > 0) return true;
		return false;
	}

	/**
	 * returns true if this filter and all related filters are set to 'show all'
	 */
	public get isSetToShowAll() : boolean {
		if (!this.schedulingFilterService.isSetToShowAll) return false;
		if (!this.reportFilterService.isSetToShowAll) return false;

		if (this.isOnlyEarlyBirdAssignmentProcesses) return false;
		if (this.isOnlyWishPickerAssignmentProcesses) return false;

		if (this.hasSideBarFilters) return false;

		return true;
	}

	/**
	 * Contains all items that need to be hidden
	 */
	// TODO: PLANO-174196 Turn `hiddenItems` into a private property
	public hiddenItems : {
		members : IndexedDBListOfDataWrappers<SchedulingApiMember>,
		activities : IndexedDBListOfDataWrappers<SchedulingApiShiftModel>,
		activityAreas : IndexedDBListOfDataWrappers<SchedulingApiActivityArea>,
		publicHolidaysLabels : {positiveFilter : boolean, items : ApiListWrapper<SchedulingApiHolidaysLabel>, filterNoLabel : boolean} | null,
		schoolHolidaysLabels : {positiveFilter : boolean, items : ApiListWrapper<SchedulingApiHolidaysLabel>, filterNoLabel : boolean} | null,
	} = { members: null!, activities: null!, activityAreas: null!, publicHolidaysLabels : null, schoolHolidaysLabels : null };

	// NOTE: Quick n dirty…
	public isOnlyWishPickerAssignmentProcesses : boolean | null = null;

	// NOTE: Quick n dirty…
	public isOnlyEarlyBirdAssignmentProcesses : boolean | null = null;

	private reportFilterServiceSubscription : Subscription | null = null;
	private schedulingFilterServiceSubscription : Subscription | null = null;

	public dropdownLabelsIndexedDBKeyPrefixType : PDropdownLabelsFilterIndexedDBKeyPrefixFilterType | null = null;

	/**
	 * Read values from indexedDB if available
	 * This method is async, because in order to run this method the api must be loaded.
	 */
	public async readIndexedDB() : Promise<void> {
		// For some operations in this method we want to access api data. So we need to make sure the api is either loaded
		// or we have waited till the next load.
		await this.api.onLoad();

		// This method might run after a browser refresh. In the following code we want to ask the users database for
		// values, so lets make sure that the users database connection is established.
		await this.pIndexedDBService.waitUntilDatabaseIsReady('user');

		if (this.pIndexedDBService.has(filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_MEMBERS_KEY, this._indexedDBKeyPrefix))) {
			const indexedDBArray = this.hiddenItems['members'].indexedDBArray;
			await waitForValueNotUndefined(this.zone, () => this.api.data.aiMembers.isAvailable);
			const members = this.api.data.members.filterBy(member => indexedDBArray.includes(member.id.rawData));
			this.hiddenItems['members'].pushFromListOfItems(members);
		}

		if (this.pIndexedDBService.has(filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_SHIFT_MODELS_KEY, this._indexedDBKeyPrefix))) {
			const indexedDBArray = this.hiddenItems['activities'].indexedDBArray;
			await waitForValueNotUndefined(this.zone, () => this.api.data.aiShiftModels.isAvailable);

			const shiftModels = this.api.data.shiftModels.filterBy(shiftModel => indexedDBArray.includes(shiftModel.id.rawData));
			this.hiddenItems['activities'].pushFromListOfItems(shiftModels);
		}

		if (this.pIndexedDBService.has(filterServiceIndexedDBKeyWithPrefix(FILTER_SERVICE_INDEXED_DB_HIDDEN_ACTIVITY_AREAS_KEY, this._indexedDBKeyPrefix))) {
			const indexedDBArray = this.hiddenItems['activityAreas'].indexedDBArray;
			await waitForValueNotUndefined(this.zone, () => this.api.data.aiActivityAreas.isAvailable);
			const activityAreas = this.api.data.activityAreas.filterBy(activityArea => indexedDBArray.includes(activityArea.id.rawData));
			this.hiddenItems['activityAreas'].pushFromListOfItems(activityAreas);
		}

		await this.readDropdownLabelIndexedDB();

		this.reportFilterService.readIndexedDB();
		this.schedulingFilterService.readIndexedDB();
	}

	private async readDropdownLabelIndexedDB() : Promise<void> {
		if (this.dropdownLabelsIndexedDBKeyPrefixType === null) return;

		const processPrefix = async (prefix : PDropdownLabelsFilterIndexedDBKeyPrefixDataType) : Promise<void> => {
			const labelIdsKey = pDropdownLabelsFilterIndexedDBKeyWithPrefix(P_DROPDOWN_LABELS_FILTER_CHECKED_LABEL_IDS, prefix);
			const positiveFilterKey = pDropdownLabelsFilterIndexedDBKeyWithPrefix(P_DROPDOWN_LABELS_FILTER_POSITIVE_FILTER, prefix);
			const filterNoLabelKey = pDropdownLabelsFilterIndexedDBKeyWithPrefix(P_DROPDOWN_LABELS_FILTER_FILTER_NO_LABEL, prefix);
			const indexedDBList = new IndexedDBListOfDataWrappers<SchedulingApiHolidaysLabel>(this.api, this.pIndexedDBService, labelIdsKey);
			for (const idAsNumber of indexedDBList.indexedDBArray) {
				await waitUntil(this.zone, () => !!this.api.data.aiHolidays.isAvailable && !!this.api.data.holidays.aiHolidayItems.isAvailable);
				const labelToAdd = this.api.data.holidays.labels.find((label) => label.id.equals(Id.create(idAsNumber)));
				if (labelToAdd !== null)
					indexedDBList.push(labelToAdd);
			}

			const positiveFilter = this.pIndexedDBService.get(positiveFilterKey);

			const filterNoLabel = this.pIndexedDBService.get(filterNoLabelKey);

			this.hiddenItems[prefix.includes('Public') ? 'publicHolidaysLabels' : 'schoolHolidaysLabels'] = {
				items: indexedDBList,
				positiveFilter: positiveFilter !== 'false',
				filterNoLabel: filterNoLabel === 'true',
			};
		};
		await processPrefix(`${this.dropdownLabelsIndexedDBKeyPrefixType}HolidaysTypeSchoolHolidays`);
		await processPrefix(`${this.dropdownLabelsIndexedDBKeyPrefixType}HolidaysTypePublicHolidays`);
	}

	/**
	 * Init all necessary values for this class
	 */
	public initValues() : void {
		this.reportFilterService.initValues();
		this.schedulingFilterService.initValues();
	}

	/**
	 * Toggle visibility settings of a shiftModel, member or activity area
	 * @param item The item to toggle.
	 */
	public toggleItem(
		item : (
			SchedulingApiShiftModel |
			SchedulingApiMember |
			SchedulingApiActivityArea
		),
	) {
		if (this.isVisible(item)) {
			this.hide(item);
		} else {
			this.show(item);
		}
	}

	/**
	 * Sets all provided members to visible if all where hidden.
	 * Sets all provided members to hidden if some where visible and some not.
	 * @param members The members to toggle
	 */
	public toggleMembers(members : SchedulingApiMembers) {
		if (this.isVisible(members)) {
			this.hide(members);
		} else {
			this.show(members);
		}
	}

	/**
	 * Toggles the visibility/filter setting for the provided activity area.
	 * @param activityArea The activity area to toggle
	 */
	public toggleActivityArea(activityArea : SchedulingApiActivityArea) {
		if (this.isVisible(activityArea)) {
			this.hide(activityArea);
		} else {
			this.show(activityArea);
		}
	}

	/**
	 * Sets the given activity areas to visible if any activity area or any related activity is hidden.
	 * Sets the given activity areas to hidden if all activity areas and all related activities are visible.
	 * @param activityAreas The activity areas to toggle
	 */
	public toggleActivityAreas(activityAreas : SchedulingApiActivityAreas) {
		const allActivityAreasAreVisible = this.isVisible(activityAreas);
		const allRelatedActivitiesAreVisible = activityAreas.every(activityArea => {
			return activityArea.relatedActivities.every(activity => this.isVisible(activity));
		});
		if (allActivityAreasAreVisible && allRelatedActivitiesAreVisible) {
			this.hide(activityAreas);
		} else {
			this.show(activityAreas);
		}
	}

	/**
	 * Sets given item or items to 'hidden'
	 * @param input The item or list of items to hide
	 */
	public hide(input : SchedulingApiShiftModel | SchedulingApiMember | SchedulingApiActivityArea | FilterLists) {
		switch (input.constructor) {
			case SchedulingApiShiftModel :
				this.hideActivity(input as SchedulingApiShiftModel);
				break;
			case SchedulingApiActivityArea :
				this.hideActivityArea(input as SchedulingApiActivityArea);
				break;
			case SchedulingApiMember :
				this.hideMember(input as SchedulingApiMember);
				break;
			case SchedulingApiShiftModels :
				this.hideActivities(input as SchedulingApiShiftModels, true);
				break;
			case SchedulingApiActivityAreas :
				this.hideActivityAreas(input as SchedulingApiActivityAreas);
				break;
			case SchedulingApiMembers :
				this.hideMembers(input as SchedulingApiMembers);
				break;
			default :
				throw new Error('wrong item type');
		}

		this.changed(null);
	}

	/**
	 * Checks if given shiftModel, member, option, members or shiftModels is visible.
	 * @param input The item or list of items to check
	 * @returns
	 * 	If you check a sigle item it returns true if that item is visible.
	 *  If you check a list of items, the method returns true if ALL of them are visible.
	 */
	public isVisible(input : FilterItem) : boolean {
		if (
			input instanceof SchedulingApiActivityAreas ||
			input instanceof SchedulingApiShiftModels ||
			input instanceof SchedulingApiMembers ||
			input instanceof SchedulingApiLeaves
		) {
			// If a list is provided, we check if every item is visible.
			return input.every(item => this.isVisible(item));
		}

		if (input instanceof SchedulingApiActivityArea) return this.isVisibleActivityArea(input);
		if (input instanceof SchedulingApiShift) return this.isVisibleShift(input);
		if (input instanceof SchedulingApiShiftModel) return this.isVisibleActivity(input);
		if (input instanceof SchedulingApiMember) return this.isVisibleMember(input);
		if (input instanceof SchedulingApiLeave) return this.isVisibleLeave(input);
		if (input instanceof SchedulingApiHolidaysHolidayItem) return this.isVisibleHolidayOrBirthday(input);
		if (input instanceof SchedulingApiBirthday) return this.isVisibleHolidayOrBirthday(input);
		if (input instanceof SchedulingApiBooking) return this.isVisibleBooking(input);
		if (input instanceof SchedulingApiWorkingTime) return this.isVisibleWorkingTime(input);
		if (input instanceof SchedulingApiTodaysShiftDescription) return this.isVisibleTodaysShiftDescription(input);
		throw new Error('unexpected instance of input');
	}

	/**
	 * Check if the provided item or items are invisible.
	 * @param input The item or items to check
	 * @returns
	 * 	true if the provided item is, or all provided items are invisible
	 * 	false
	 * 		- if the provided item is visible, or
	 * 		- any item of the provided items is visible or
	 * 		- the provided list is empty – a non-existent item cant be invisible
	 */
	public isInvisible(input : FilterItem) : boolean {
		if (input instanceof ApiObjectWrapper || input instanceof SchedulingApiBirthday) {
			return !this.isVisible(input);
		} else {
			if (input.length === 0) return false;
			return input.every(item => !this.isVisible(item));
		}
	}

	/**
	 * Check if any item of the provided item in the list is visible.
	 * @param input The list of items to check
	 */
	public someAreVisible(input : FilterLists) : boolean {
		return input.some(item => this.isVisible(item));
	}

	/**
	 * Checks if the filter is set to hide every of these members.
	 * @param members The members to check
	 * @returns true if the filter is set to hide every of these members and false if any of them is set to be visible, or if the list is empty
	 */
	public isHideAllMembers(members : SchedulingApiMembers) : boolean {
		if (members.length === 0) return false;
		return members.length === this.hiddenItemsCount(members);
	}

	private filterOutTrashedActivities(activities : SchedulingApiShiftModels) : SchedulingApiShiftModels;
	private filterOutTrashedActivities(activities : SchedulingApiShiftModel[]) : SchedulingApiShiftModel[];

	// TODO: PLANO-114999, PLANO-188482 Remove this method
	/**
	 * Remove the trashed activities.
	 * @param activities The activities to filter
	 */
	private filterOutTrashedActivities(activities : SchedulingApiShiftModels | SchedulingApiShiftModel[]) : SchedulingApiShiftModels | SchedulingApiShiftModel[] {
		if (activities instanceof SchedulingApiShiftModels) {
			return activities.filterBy(activity => !activity.trashed);
		} else {
			return activities.filter(activity => !activity.trashed);
		}
	}

	/**
	 * Checks if the filter is set to hide every of these activities.
	 * Note that this is not about the visibility of the activities - so this will not return true if the related
	 * activity areas are hidden.
	 * @param activities The activities to check
	 * @returns true if filter is set to hide every of these activities and false if any of them is set to be visible, or if the list is empty
	 */
	public isHideAllActivities(activities : SchedulingApiShiftModels) : boolean {
		const activitiesToCheck = this.filterOutTrashedActivities(activities);
		if (activitiesToCheck.length === 0) return false;
		return activitiesToCheck.length === this.hiddenItemsCount(activities);
	}

	/**
	 * Checks if the filter is set to hide every of these activity areas.
	 * @param activityAreas The activity areas to check
	 * @returns true if filter is set to hide every of these activity areas and false if any of them is set to be visible, or if the list is empty
	 */
	public isHideAllActivityAreas(activityAreas : SchedulingApiActivityAreas) : boolean {
		if (activityAreas.length === 0) return false;
		return activityAreas.every((activityArea) => this.hiddenItems['activityAreas'].contains(activityArea));
	}

	/**
	 * Set the filter to show only the shifts of the given member - hide all other shifts.
	 * @param id The id of the member to show
	 * @param newVisibleState The new visible state if that member
	 */
	public showOnlyMember(id : Id, newVisibleState : boolean) {
		if (newVisibleState) {
			this.hide(this.api.data.members);
			this.showMember(id);
			this.schedulingFilterService.showItemsWithEmptyMemberSlot = false;
		} else {
			this.showMembers();
			this.schedulingFilterService.showItemsWithEmptyMemberSlot = true;
		}

		this.changed(null);
	}

	// NOTE: Quick n dirty…
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public showOnlyWishPickerAssignmentProcesses(value : boolean) : void {
		this.isOnlyWishPickerAssignmentProcesses = value;
		this.changed(null);
	}

	// NOTE: Quick n dirty…
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public showOnlyEarlyBirdAssignmentProcesses(value : boolean) : void {
		this.isOnlyEarlyBirdAssignmentProcesses = value;
		this.changed(null);
	}

	/**
	 * @see PServiceInterface#unload
	 * @param [loggingOut=false] Wether the user is logging out
	 */
	public unload(loggingOut : boolean = false) {
		this.reportFilterServiceSubscription?.unsubscribe();
		this.schedulingFilterServiceSubscription?.unsubscribe();
		this.unloadFilters(loggingOut);
		this.changed(null);
	}

	/**
	 * Reset all activity filters
	 */
	public unloadActivities() {
		this.hiddenItems['activities'].clear();
		this.changed(null);
	}

	/**
	 * Reset all activity area filters
	 */
	public unloadActivityAreas() {
		this.hiddenItems['activityAreas'].clear();
		this.changed(null);
	}

	/**
	 * Unload all filter settings that can be set in the main sidebar.
	 */
	public unloadSidebarFilters() {
		this.hiddenItems['activities'].clear();
		this.hiddenItems['members'].clear();
		this.hiddenItems['activityAreas'].clear();
	}

	/**
	 * Unload all filter settings this filter contains.
	 * @param [loggingOut=false] If true, the user is logging out and we should not clear the sidebar filters
	 */
	public unloadFilters(loggingOut : boolean = false) {
		if (!loggingOut) this.unloadSidebarFilters();
		this.isOnlyEarlyBirdAssignmentProcesses = false;
		this.isOnlyWishPickerAssignmentProcesses = false;
		this.reportFilterService.unload();
		this.schedulingFilterService.unload();
	}

	/**
	 * Sets given filterItem to 'visible'
	 * If you know the instanceof filterItem, you should prefer showMember() or showActivity()
	 * @param input The item or list of items to show
	 */
	private show(
		input : SchedulingApiShiftModel | SchedulingApiMember | SchedulingApiActivityArea | Exclude<FilterLists, SchedulingApiShiftModels>,
	) {
		switch (input.constructor) {
			case SchedulingApiShiftModel :
				this.showActivity(input as SchedulingApiShiftModel);
				break;
			case SchedulingApiActivityArea :
				this.showActivityArea(input as SchedulingApiActivityArea);
				break;
			case SchedulingApiMember :
				this.showMember(input as SchedulingApiMember);
				break;
			case SchedulingApiActivityAreas :
				this.showActivityAreas(input as SchedulingApiActivityAreas);
				break;
			case SchedulingApiMembers :
				this.showMembers(input as SchedulingApiMembers);
				break;
			default :
				throw new Error('wrong item type');
		}

		this.changed(null);
	}

	/**
	 * Check if this member is visible
	 * @param input The member to check
	 */
	private isVisibleMember(input : SchedulingApiMember | Id) : boolean {
		let member : SchedulingApiMember;
		if (input instanceof SchedulingApiMember) {
			member = input;
		} else {
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			assume(this.api.isLoaded(), 'api.isLoaded()', 'Api should be loaded when you ask for filter settings for a member id');
			member = notNull(this.api.data.members.get(input));
		}

		return !this.hiddenItems['members'].contains(member);
	}

	/**
	 * If the currently loaded leave is the same as the input, it means we are on a detail page of that leave, meaning it should be hidden in the calendar
	 *
	 * @param leave The leave to check
	 *
	 * TODO: PLANO-188763 Remove this method
	 */
	private onDetailPageOfLeave( leave : SchedulingApiLeave ) : boolean {
		return this.api.currentlyDetailedLoaded === leave || leave.isNewItem;
	}

	// TODO: PLANO-188763 The filter service should not care about which page the user is looking at
	/**
	 * Is the user currently on a page with a calendar-view?
	 */
	private get isPageWithCalendarView() : boolean {
		return (
			this.clientRoutingService.currentPage === CurrentPageEnum.SCHEDULING ||
			this.clientRoutingService.currentPage === CurrentPageEnum.BOOKING ||
			this.clientRoutingService.currentPage === CurrentPageEnum.LEAVE ||
			this.clientRoutingService.currentPage === CurrentPageEnum.SHIFT_EXCHANGE
		);
	}

	/**
	 * Check if this leave is visible
	 * @param input the leave to check for visibility
	 */
	private isVisibleLeave(input : SchedulingApiLeave) : boolean {

		if (this.onDetailPageOfLeave(input)) return false;

		// If there is nothing hidden by any filter, then no further checks needs to be done
		if (this.isSetToShowAll) return true;

		// member of the leave is hidden?
		if (this.hiddenItems['members'].contains(input.memberId)) return false;

		// TODO: PLANO-188763 The filter service should not care about which page the user is looking at
		if (this.isPageWithCalendarView) {
			// NOTE: I stumbled upon this and I am not sure if this line
			// should apply to this.pRoutingService.currentPage === CurrentPageEnum.BOOKING
			if (!this.schedulingFilterService.isVisible(input)) return false;

			const leaveMember = this.api.data.members.get(input.memberId)!;

			// Are all shift-models for which the member is assignable hidden?
			// Ignore this criteria for admins as they are often not assignable to specific shift-models.
			if (leaveMember.role !== SchedulingApiPermissionGroupRole.CLIENT_OWNER) {
				if (leaveMember.assignableShiftModels.length === 0) {
					return true;
				}

				const assignableActivities = leaveMember.assignableShiftModels
					.iterable()
					.flatMap((assignableActivity) => assignableActivity.shiftModel);

				return assignableActivities.some(activity => this.isVisible(activity));
			}

		}

		// TODO: PLANO-188763 The filter service should not care about which page the user is looking at
		// eslint-disable-next-line sonarjs/no-collapsible-if -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.clientRoutingService.currentPage === CurrentPageEnum.REPORT) {
			if (!this.reportFilterService.isVisible(input)) return false;
		}

		// passed all tests
		return true;
	}

	/**
	 * Check if this holiday or birthday is visible
	 * @param input The holiday or birthday to check
	 */
	private isVisibleHolidayOrBirthday( input : SchedulingApiHolidaysHolidayItem | SchedulingApiBirthday ) : boolean {

		// TODO: PLANO-188763 The filter service should not care about which page the user is looking at
		// eslint-disable-next-line sonarjs/no-collapsible-if -- TODO: PLANO-188763 Remove this block if possible
		if (this.isPageWithCalendarView) {
			// NOTE: I stumbled upon this and I am not sure if this line
			// should apply to this.pRoutingService.currentPage === CurrentPageEnum.BOOKING
			if (!this.schedulingFilterService.isVisible(input)) return false;
		}

		if (input instanceof SchedulingApiHolidaysHolidayItem) {

			const labelsToCompare = input.type === SchedulingApiHolidayItemType.PUBLIC_HOLIDAY ? this.hiddenItems['publicHolidaysLabels'] : this.hiddenItems['schoolHolidaysLabels'];

			if (labelsToCompare === null) return true;

			if (labelsToCompare.items.length === 0 && !labelsToCompare.filterNoLabel) return true;

			if (input.labelIds.length === 0) {
				if (!labelsToCompare.filterNoLabel) return !labelsToCompare.positiveFilter;
				if (labelsToCompare.positiveFilter) return true;
				else return false;
			}

			const containsSomeLabels = labelsToCompare.items.some(label => input.labelIds.contains(label.id));

			if (labelsToCompare.positiveFilter) {
				return containsSomeLabels;
			} else {
				return !containsSomeLabels;
			}
		}

		return true;
	}

	/**
	 * Check if this Booking is visible
	 * @param input The booking to check
	 */
	private isVisibleBooking( input : SchedulingApiBooking ) : boolean {
		return this.isVisible(input.model);
	}

	/**
	 * Check if this WorkingTime is visible
	 * @param input The workingTime to check
	 */
	private isVisibleWorkingTime( input : SchedulingApiWorkingTime ) : boolean {
		if (!this.isVisible(input.model)) return false;
		if (!this.isVisibleMember(input.memberId)) return false;

		// TODO: PLANO-188763 The filter service should not care about which page the user is looking at
		if (this.clientRoutingService.currentPage === CurrentPageEnum.REPORT && !this.reportFilterService.isVisible(input)) return false;
		return true;
	}

	/**
	 * Check if this todaysShiftDescription is visible
	 * @param input The todaysShiftDescription to check
	 */
	private isVisibleTodaysShiftDescription(input : SchedulingApiTodaysShiftDescription) : boolean {
		return this.isVisibleActivity(input.id.shiftModelId);
	}

	/**
	 * Check if this shift is visible
	 * @param shift The shift to check
	 */
	private isVisibleShift(shift : SchedulingApiShift) : boolean {
		if (!this.schedulingFilterService.isVisible(shift)) return false;

		// don’t show this shift if the related shiftmodel is not visible
		if (!this.isVisible(shift.model)) return false;

		// don’t show this shift if all related members are hidden
		if (
			shift.aiAssignedMemberIds.isAvailable && shift.assignedMemberIds.length > 0 && this.isInvisible(shift.assignedMembers) &&

			// If a shift has Hans assigned and 1 free slot and hans is hidden,
			// then the shift should still show up [PLANO-20250]
			(!this.schedulingFilterService.showItemsWithEmptyMemberSlot || !shift.emptyMemberSlots)
		) return false;

		return true;
	}

	/**
	 * Check if this activity is visible
	 * @param input The activity or the id of the activity to check
	 */
	private isVisibleActivity(input : SchedulingApiShiftModel | Id) : boolean {
		if (this.hiddenItems['activities'].contains(input)) return false;

		// If the activity is not hidden it might still be filtered through the activity area.
		const activity = input instanceof SchedulingApiShiftModel ? input : this.api.data.shiftModels.get(input)!;
		return this.isVisibleActivityArea(activity.activityArea);
	}

	/**
	 * Check if this activity area is visible
	 * @param input The activity area or id of the activity area to check
	 */
	private isVisibleActivityArea(input : SchedulingApiActivityArea | Id) : boolean {
		return !this.hiddenItems['activityAreas'].contains(input);
	}

	/**
	 * Sets all [given] activities to 'visible'
	 * Clears all if you don’t pass activities.
	 * Clears activity areas if this activity is part of a hidden activity area.
	 * @param activities The activities to show
	 */
	private showActivities(activities : SchedulingApiShiftModels | null = null) {
		if (activities === null) {
			this.hiddenItems['activities'].clear();
		} else {
			this.hiddenItems['activities'].removeFromListOfItems(activities);
		}
	}

	/**
	 * Sets all [given] activity areas to 'visible'
	 * Clears all if you don’t pass activityAreas
	 * @param activityAreas The activity areas to show
	 */
	private showActivityAreas(activityAreas ?: SchedulingApiActivityAreas) {
		if (activityAreas === undefined) {
			this.hiddenItems['activityAreas'].clear();
			this.hiddenItems['activities'].clear();
		} else {
			this.hiddenItems['activityAreas'].removeFromListOfItems(activityAreas);

			// Show all related activities if they are hidden
			for (const activityArea of activityAreas.iterable()) {
				this.hiddenItems['activities'].removeFromListOfItems(activityArea.relatedActivities);
			}
		}
	}

	/**
	 * Sets all members to 'visible'
	 * Clears all if you don’t pass members
	 * @param members The members to show
	 */
	private showMembers(members ?: SchedulingApiMembers) {
		if (members === undefined) {
			this.schedulingFilterService.showItemsWithEmptyMemberSlot = true;
			this.hiddenItems['members'].clear();
		} else {
			this.hiddenItems['members'].removeFromListOfItems(members);
			if (!this.schedulingFilterService.showItemsWithEmptyMemberSlot && this.api.data.members === members) {
				this.schedulingFilterService.showItemsWithEmptyMemberSlot = true;
			}
		}
		this.changed(null);
	}

	/**
	 * Sets provided activity to 'visible'
	 * @param item The activity or id of the activity to show
	 */
	private showActivity(item : SchedulingApiShiftModel | Id) {
		const activity = item instanceof SchedulingApiShiftModel ? item : this.api.data.shiftModels.get(item)!;

		/*
		 * Imagine you have:
		 * Activity area "Courses"
		 *   Activity "Beginners course"
		 *   Activity "Intermediate course"
		 *   Activity "Advanced course"
		 *
		 * If the whole activity area e.g. "Courses" is set to be hidden and the user decides to show one of the activity
		 * area's activity e.g. "Beginners course", then the following should happen:
		 * - Activity area "Courses" should be removed from the array of hidden items
		 * - All other activities then the provided one (in this case thats "Intermediate course" and "Advanced course") should be added to the array of hidden items
		 */
		if (!this.isVisible(activity.activityArea)) {
			this.show(activity.activityArea);
			this.hideActivities(activity.activityArea.relatedActivities, false);
		}

		if (this.hiddenItems['activities'].contains(activity)) {
			this.hiddenItems['activities'].removeItem(activity);
		}
	}

	/**
	 * Sets provided activity area to 'visible'
	 * @param activityArea The activity area to show
	 */
	private showActivityArea(activityArea : SchedulingApiActivityArea) {
		if (this.hiddenItems['activityAreas'].contains(activityArea)) {
			this.hiddenItems['activityAreas'].removeItem(activityArea);
		}

		// Show all related activities if they are hidden
		this.showActivities(activityArea.relatedActivities);
	}

	/**
	 * Sets provided member to 'hidden'
	 * @param item The activity or id of the activity to hide
	 */
	private hideActivity(item : SchedulingApiShiftModel | Id) {
		const activity = item instanceof SchedulingApiShiftModel ? item : this.api.data.shiftModels.get(item)!;

		if (!this.isVisible(activity.activityArea)) {
			this.show(activity.activityArea);
		}

		this.toastsService?.addToast({
			title: this.localizePipe?.transform('Tätigkeit ausgeblendet') ?? null,
			content: this.localizePipe?.transform('… und die dazugehörigen Schichten, Tauschbörsen-Einträge, Arbeitseinsätze in der Auswertung, Buchungen etc.') ?? '',
			theme: enumsObject.PThemeEnum.INFO,
			visibilityDuration: 'long',
		});
		if (!this.hiddenItems['activities'].contains(activity)) {
			this.hiddenItems['activities'].push(activity);
		}
		this.deselectRelatedItems(activity);
	}

	private hideActivities(
		activities : SchedulingApiShiftModels,
		showToast : boolean,
	) {
		if (showToast) {
			this.toastsService?.addToast({
				title: this.localizePipe?.transform('Tätigkeit ausgeblendet') ?? null,
				content: this.localizePipe?.transform('… und die dazugehörigen Schichten, Tauschbörsen-Einträge, Arbeitseinsätze in der Auswertung, Buchungen etc.') ?? '',
				theme: enumsObject.PThemeEnum.INFO,
				visibilityDuration: 'long',
			});
		}

		this.hiddenItems['activities'].pushFromListOfItems(activities);

		for (const shiftModel of activities.iterable())
			this.deselectRelatedItems(shiftModel);
	}

	/**
	 * Sets provided activity areas to 'hidden'.
	 * Deselects all related shifts.
	 * @param activityAreas The activity areas to hide
	 */
	private hideActivityAreas(activityAreas : SchedulingApiActivityAreas) {
		this.toastsService?.addToast({
			title: this.localizePipe?.transform('Tätigkeitsbereich ausgeblendet') ?? null,
			content: this.localizePipe?.transform('<p>… und die dazugehörigen Tätigkeiten, Schichten, Arbeitseinsätze in der Auswertung, etc.</p> Auch künftig angelegte Tätigkeiten in diesem Bereich werden automatisch ausgeblendet.') ?? '',
			theme: enumsObject.PThemeEnum.INFO,
			visibilityDuration: 'long',
		});

		this.hiddenItems['activityAreas'].pushFromListOfItems(activityAreas);

		for (const activityArea of activityAreas.iterable())
			for (const relatedActivity of activityArea.relatedActivities.iterable())
				this.deselectRelatedItems(relatedActivity);
	}

	/**
	 * Sets provided activity area to 'hidden'
	 * @param activityArea The activity area to hide
	 */
	private hideActivityArea(activityArea : SchedulingApiActivityArea) {
		this.toastsService?.addToast({
			title: this.localizePipe?.transform('Tätigkeitsbereich ausgeblendet') ?? null,
			content: this.localizePipe?.transform('<p>… und die dazugehörigen Tätigkeiten, Schichten, Arbeitseinsätze in der Auswertung, etc.</p> Auch künftig angelegte Tätigkeiten in diesem Bereich werden automatisch ausgeblendet.') ?? '',
			theme: enumsObject.PThemeEnum.INFO,
			visibilityDuration: 'long',
		});

		this.showActivities(activityArea.relatedActivities);
		this.hiddenItems['activityAreas'].push(activityArea);

		for (const shiftModel of activityArea.relatedActivities.iterable())
			this.deselectRelatedItems(shiftModel);
	}

	private hideMembers(members : SchedulingApiMembers) {
		this.toastsService?.addToast({
			title: this.localizePipe?.transform('User ausgeblendet') ?? null,
			content: this.localizePipe?.transform('… und die dazugehörigen Schichten, Tauschbörsen-Einträge, Arbeitseinsätze in der Auswertung, Buchungen etc.') ?? '',
			theme: enumsObject.PThemeEnum.INFO,
			visibilityDuration: 'long',
		});

		this.hiddenItems['members'].pushFromListOfItems(members);

		for (const member of members.iterable())
			this.deselectRelatedItems(member);
	}

	/**
	 * Sets provided member to 'visible'
	 * @param item The member or id of the member to show
	 */
	private showMember(item : SchedulingApiMember | Id) : void {
		let member : SchedulingApiMember;
		if (item instanceof SchedulingApiMember) {
			member = item;
		} else {
			member = notNull(this.api.data.members.get(item));
		}

		if (this.hiddenItems['members'].contains(member)) {
			this.hiddenItems['members'].removeItem(member);
		}
	}

	/**
	 * Sets provided member to 'hidden'
	 * @param item The member or id of the member to hide
	 */
	private hideMember(item : SchedulingApiMember | Id) : void {
		const member = item instanceof SchedulingApiMember ? item : this.api.data.members.get(item)!;

		this.toastsService?.addToast({
			title: this.localizePipe?.transform('User ausgeblendet') ?? null,
			content: this.localizePipe?.transform('… und die dazugehörigen Schichten, Tauschbörsen-Einträge, Arbeitseinsätze in der Auswertung, Buchungen etc.') ?? '',
			theme: enumsObject.PThemeEnum.INFO,
			visibilityDuration: 'long',
		});
		if (!this.hiddenItems['members'].contains(member)) {
			this.hiddenItems['members'].push(member);
		}
		this.deselectRelatedItems(member);
	}

	private deselectRelatedItems(input : SchedulingApiMember | SchedulingApiShiftModel) : void {
		const relatedItems = this.api.data.shifts.getItemsRelatedTo(input);

		// It is possible that these shifts have not been hidden because they are possibly related to another not-filtered
		// member. So we must deselect only if they are invisible.
		const invisibleRelatedItems = relatedItems.filterBy(item => !this.isVisible(item));
		for (const relatedItem of invisibleRelatedItems.iterable()) {
			relatedItem.selected = false;
		}
	}

	/**
	 * When we want to count hidden activities, we can't just count the activities in the hiddenItems['activityArea']
	 * list - we have to count them + the ones that are related to the hidden activity areas.
	 */
	private get hiddenActivities() : SchedulingApiShiftModel[] {
		return [
			...this.hiddenItems['activities'].iterable(),
			...this.hiddenItems['activityAreas'].iterable()
				.flatMap(activityArea => activityArea.relatedActivities.iterable()),
		];
	}

	// TODO: PLANO-188482 Make sure these docs are still up to date after trashed items are taken into account
	/**
	 * Counts hidden items.
	 *
	 * This method ignores all trashed items.
	 *
	 * @param items The items that should be counted. Can be a string to describe which items should be counted or a list.
	 * @returns The number of hidden items. It depends on the items that should have been counted.
	 * - 'members' The amount of members that are set to be hidden
	 * - 'activities' The amount of activities that are set to be hidden. This also counts activities which have activity
	 * areas which are set to be hidden.
	 * - FilterList The amount of items inside the provided list, which are set to be hidden.
	 */
	public hiddenItemsCount(
		items : FilterLists | 'members' | 'activities' | 'activityAreas',
	) : number {
		switch (items) {
			case 'activities' :
				return this.filterOutTrashedActivities(this.hiddenActivities).length;
			case 'members' :
				return this.hiddenItems['members'].length;
			case 'activityAreas' :
				return this.hiddenItems['activityAreas'].length;
			default :
				if (items instanceof SchedulingApiShiftModels) {
					const hiddenItemsToCheck = this.filterOutTrashedActivities(this.hiddenActivities);
					const inputItemsToCheck = this.filterOutTrashedActivities(items);
					return hiddenItemsToCheck.filter(item => !!inputItemsToCheck.get(item.id)).length;
				} else if (items instanceof SchedulingApiMembers) {
					return this.countHowManyItemsAreHidden(this.hiddenItems['members'], items);
				} else if (items instanceof SchedulingApiActivityAreas) {
					return this.countHowManyItemsAreHidden(this.hiddenItems['activityAreas'], items);
				}
				return 0;
		}
	}

	/**
	 * To count items inside a IndexedDBListOfDataWrappers, we do not want to change any values.
	 *
	 * But if we would use {@link IndexedDBListOfDataWrappers#filterBy} for that, it would trigger
	 * {@link IndexedDBListOfDataWrappers#push} method which at some point would trigger
	 * {@link IndexedDBListOfDataWrappers#onItemAdded} which is overwritten to write the pushed item/value to the
	 * indexedDB. This is not what we want.
	 * Thus, we turn it into an array and use {@link Array#filter} to filter the items.
	 *
	 * @param indexedDBList The list of hidden items to compare with.
	 * @param items The items to check against the hidden items.
	 */
	private countHowManyItemsAreHidden<T extends SchedulingApiActivityArea | SchedulingApiMember>(
		indexedDBList : IndexedDBListOfDataWrappers<T>,
		items : T extends SchedulingApiActivityArea ? SchedulingApiActivityAreas : SchedulingApiMembers,
	) : number {
		return [...indexedDBList.iterable()].filter(item => !!items.get(item.id)).length;
	}
}
