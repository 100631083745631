import { Injectable, Injector, NgZone } from '@angular/core';
import { ReportFilterServiceIndexedDBKeyDataType } from '@plano/client/report/report-filter.service.indexeddb.type';
import { TransactionFilterServiceIndexedDBKeyDataType } from '@plano/client/sales/transactions/transactions.service.indexeddb.type';
import { CourseFilterServiceIndexedDBKeyDataType } from '@plano/client/scheduling/course-filter.service.indexeddb.type';
import { SchedulingApiBasedPagesIndexedDBKeyDataType } from '@plano/client/scheduling/scheduling-api-based-pages.service.indexeddb.type';
import { SchedulingFilterServiceIndexedDBKeyDataType } from '@plano/client/scheduling/scheduling-filter.service.indexeddb.type';
import { SchedulingServiceIndexedDBKeyDataType } from '@plano/client/scheduling/scheduling.service.indexeddb.type';
import { BookingsIndexedDBKeyDataType } from '@plano/client/scheduling/shared/p-bookings/bookings.indexeddb.type';
import { PAllDayItemsListIndexedDBKeyDataType } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-calendar/p-all-day-items-list/p-all-day-items-list.indexeddb.type';
import { CookieConsentSettingIndexedDBKeyDataType } from '@plano/client/shared/component/p-cookie-consent-manager/cookie-consent-setting.indexeddb.type';
import { NotificationConfigIndexedDBKeyDataType } from '@plano/client/shared/component/p-notifications-config-form/notifications-config.indexeddb.type';
import { FilterServiceIndexedDBKeyDataType } from '@plano/client/shared/filter.service.indexeddb.type';
import { PDropdownLabelsFilterIndexedDBKeyDataType } from '@plano/client/shared/p-dropdown-labels-filter/p-dropdown-labels-filter.indexeddb.type';
import { PShiftExchangeListServiceIndexedDBKeyDataType } from '@plano/client/shared/p-shift-exchange/p-shift-exchange-list/p-shift-exchange-list.service.indexeddb.type';
import { CollapsedActivityAreasServiceIndexedDBKeyDataType } from '@plano/client/shared/p-sidebar/main-sidebar/collapsed-activity-area.service.indexeddb.type';
import { LaunchDarklyIndexedDBKeyDataType } from '@plano/client/shared/p-sidebar/main-sidebar/p-sidebar-desk/sidebar-desk.indexeddb.type';
import { SidebarMembersIndexedDBKeyDataType } from '@plano/client/shared/p-sidebar/main-sidebar/p-sidebar-members/sidebar-members.indexeddb.type';
import { PSidebarServiceIndexedDBKeyDataType } from '@plano/client/shared/p-sidebar/p-sidebar.indexeddb.type';
import { ShiftExchangesServiceIndexedDBKeyDataType } from '@plano/client/shift-exchanges/shift-exchanges.service.indexeddb.type';
import { DuplicateWorkRuleIndexedDBKeyDataType } from '@plano/client/work-models/work-model/detail-form/duplicate-work-rule-filter.indexeddb.type';
import { WorkRuleIndexedDBKeyDataType } from '@plano/client/work-models/work-model/detail-form/work-rule-filter.indexeddb.type';
import { MeService } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { IndexedDBDatabase } from '@plano/shared/core/indexed-db/indexed-db-database-class';
import { drPlanoGeneralMigrationScripts } from '@plano/shared/core/indexed-db/migration/dr-plano-general-migration-scripts';
import { drPlanoUserMigrationScripts } from '@plano/shared/core/indexed-db/migration/dr-plano-user-migration-scripts';
import { PServiceInterface } from '@plano/shared/core/interfaces/p-service.interface';
import { LogService } from '@plano/shared/core/log.service';
import { AuthenticationIndexedDBKeyDataType } from '@plano/shared/core/me/authentication.indexeddb.type';
import { PPushNotificationsServiceIndexedDBKeyDataType } from '@plano/shared/core/p-push-notifications.service.indexeddb.type';
import { waitForValueNotUndefined, waitUntil } from '@plano/shared/core/utils/async-await-utils';
import { assume } from '@plano/shared/core/utils/null-type-utils';
import { Subject, Subscription } from 'rxjs';

/**
 * The type for the keys in the indexedDB.
 */
export type PIndexedDBKeyDataType = (
	LaunchDarklyIndexedDBKeyDataType |
	SidebarMembersIndexedDBKeyDataType |
	BookingsIndexedDBKeyDataType |
	PPushNotificationsServiceIndexedDBKeyDataType |
	ShiftExchangesServiceIndexedDBKeyDataType |
	PSidebarServiceIndexedDBKeyDataType |
	ReportFilterServiceIndexedDBKeyDataType |
	PShiftExchangeListServiceIndexedDBKeyDataType |
	FilterServiceIndexedDBKeyDataType |
	SchedulingApiBasedPagesIndexedDBKeyDataType |
	CollapsedActivityAreasServiceIndexedDBKeyDataType |
	SchedulingServiceIndexedDBKeyDataType |
	SchedulingFilterServiceIndexedDBKeyDataType |
	CourseFilterServiceIndexedDBKeyDataType |
	NotificationConfigIndexedDBKeyDataType |
	TransactionFilterServiceIndexedDBKeyDataType |
	PAllDayItemsListIndexedDBKeyDataType |
	AuthenticationIndexedDBKeyDataType |
	PDropdownLabelsFilterIndexedDBKeyDataType |
	WorkRuleIndexedDBKeyDataType |
	DuplicateWorkRuleIndexedDBKeyDataType |
	CookieConsentSettingIndexedDBKeyDataType
);

/**
 * Service to handle data in indexedDB.
 * Every Service that injects PIndexedDBService needs to implement PServiceWithIndexedDBInterface
 */
export interface PServiceWithIndexedDBInterface extends PServiceInterface {

	/**
	 * Read values from indexedDB if available
	 * Make sure you don’t run initValues() before readIndexedDB()
	 *
	 * Example:
	 *   public async readIndexedDB() : void {
	 *     if (this.pIndexedDBService.has({database: 'user', store: 'user-data', name: 'someProperty', prefix: 'SomeService'})) {
	 *       this.someProperty = this.pIndexedDBService.get({database: 'user', store: 'user-data', name: 'someProperty', prefix: 'SomeService'}) === 'true';
	 *     }
	 *   }
	 */
	readIndexedDB() : void;
}

/**
 * Service that handles the indexedDB.
 */
@Injectable( { providedIn: 'root' } )
export class PIndexedDBService {

	constructor(
		private injector : Injector,
		private console : LogService,

	) {
		void this.setupGeneralDatabase();
		void this.setMeServiceListeners();
	}

	/**
	 * Map holding all active databases.
	 */
	private databasesMap = new Map<string, IndexedDBDatabase>();

	private subscriptions : Subscription[] = [];

	public openedUserDatabase : Subject<void> = new Subject<void>();

	private async setupGeneralDatabase() : Promise<void> {
		const generalDatabase = new IndexedDBDatabase(this.injector, 'dr-plano-general', drPlanoGeneralMigrationScripts);
		await generalDatabase.startDatabase();
		this.databasesMap.set(generalDatabase.name, generalDatabase);
	}

	private async setMeServiceListeners() : Promise<void> {

		// The services get initialized at the same time by the app component, so we can't be sure that the MeService is already available.
		// In such cases, the meService would be an empty object, so we need to wait until the MeService is available.
		await waitUntil(this.injector.get(NgZone), () => this.injector.get(MeService, null) !== null);

		const meService = this.injector.get(MeService);

		this.setupAfterLoginSubscriber(meService);
		this.setupAfterLogoutSubscriber(meService);
	}

	/**
	 * Open a new database for the logged in user after each login.
	 * @param meService The MeService.
	 */
	private setupAfterLoginSubscriber(meService : MeService) {
		this.subscriptions.push(meService.afterLogin.subscribe(() => {
			void this.openUserDatabase(meService);
		}));
	}

	private setupAfterLogoutSubscriber(meService : MeService) {
		this.subscriptions.push(meService.afterLogout.subscribe((userId : Id) => {
			const userDatabase = this.databasesMap.get(`dr-plano-user-${userId.toString()}`);

			if (userDatabase === undefined) {
				this.console.error(`Database dr-plano-user-${userId.toString()} not found.`);
				return;
			}

			userDatabase.closeDatabase();
			this.databasesMap.delete(userDatabase.name);
		}));
	}

	private async openUserDatabase(meService : MeService) {
		const userId = meService.data.id;
		const userDatabase = new IndexedDBDatabase(this.injector, `dr-plano-user-${userId.toString()}`, drPlanoUserMigrationScripts);
		await userDatabase.startDatabase();
		this.databasesMap.set(userDatabase.name, userDatabase);
		this.openedUserDatabase.next();
	}

	private getDatabaseName(database : PIndexedDBKeyDataType['database']) : string {
		if (database === 'dr-plano-general') {
			return 'dr-plano-general';
		} else {
			const userId = this.injector.get(MeService).data.id;
			return `dr-plano-user-${userId.toString()}`;
		}
	}

	/**
	 * Get the value from the database. Returns null if the key couldn't be found.
	 * @param key - The key to get.
	 * @returns The value from the database. Returns null if the key couldn't be found. Returns undefined if the database is not yet opened, and so the value couldn't be determined.
	 */
	public get(key : PIndexedDBKeyDataType) : string | null | undefined {
		const database = this.databasesMap.get(this.getDatabaseName(key.database));
		if (database === undefined) {
			// return undefined here, as we can't be sure if the database is still opening or doesn't exist at all.
			return undefined;
		}
		return database.get(key);
	}

	/**
	 * Set the value in the database and update the local cache.
	 *
	 * This method is async, but it doesn't return a promise to avoid
	 * change detection issues, so it wraps the async code in a zone.runOutsideAngular, otherwise, we would get loops if
	 * this method gets called, for example, in a method that is used in the template.
	 *
	 * If you need to wait for the value to be set, you should pass a callback to the set method.
	 *
	 * @param key - The key to set.
	 * @param value - The value to set.
	 * @param [afterIndexedDBUpdatedCallback=null] - The method to call after the indexedDB has been updated.
	 */
	public set(key : PIndexedDBKeyDataType, value : string | boolean | number, afterIndexedDBUpdatedCallback : (() => void) | null = null) : void {
		const database = this.databasesMap.get(this.getDatabaseName(key.database));
		assume(database !== undefined, 'database', `${key.database} database is not ready yet. You should await .databaseReady before you do this.`);
		database.set(key, value, afterIndexedDBUpdatedCallback);
	}

	/**
	 * Check if the key exists in the database.
	 * @param key - The key to check.
	 * @returns True if the key exists in the database, false if it doesn't. Returns undefined if the database is not yet opened, and so the value couldn't be determined.
	 */
	public has(key : PIndexedDBKeyDataType) : boolean | undefined {
		const database = this.databasesMap.get(this.getDatabaseName(key.database));
		if (database === undefined) {
			return undefined;
		}
		return database.has(key);
	}

	/**
	 * Delete the value from the database and update the local cache.
	 *
	 * This method is async, but it doesn't return a promise to avoid
	 * change detection issues, so it wraps the async code in a zone.runOutsideAngular, otherwise, we would get loops if
	 * this method gets called, for example, in a method that is used in the template.
	 *
	 * If you need to wait for the deletion to be finished, you should pass a callback to the delete method.
	 *
	 * @param key - The key to delete.
	 * @param [afterIndexedDBUpdatedCallback=null] - The method to call after the indexedDB has been updated.
	 */
	public delete(key : PIndexedDBKeyDataType, afterIndexedDBUpdatedCallback : (() => void) | null = null) : void {
		const database = this.databasesMap.get(this.getDatabaseName(key.database));
		assume(database !== undefined, 'database', `${key.database} database is not ready yet. You should await .databaseReady before you do this.`);
		database.delete(key, afterIndexedDBUpdatedCallback);
	}

	/**
	 * Does the required database exist?
	 * @param database The database that should be checked.
	 */
	public databaseExists(database : PIndexedDBKeyDataType['database']) {
		return this.databasesMap.get(this.getDatabaseName(database));
	}

	/**
	 * Promise that resolves as soon as the database is ready.
	 * @param database The database that should be checked.
	 */
	public async waitUntilDatabaseIsReady(database : PIndexedDBKeyDataType['database']) {
		return waitForValueNotUndefined(this.injector.get(NgZone), () => this.databaseExists(database));
	}
}
