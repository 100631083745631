/**
 * All the possible evaluation item types for a work rule
 */
export enum WorkRuleEvaluationItemType {
	TIME_PERIOD,
	WORK_SESSION,
	LEAVE,
	PAY_DIFFERENTIALS,
	PAUSE,
	REST_PERIOD,
	PUBLIC_HOLIDAYS,
	SCHOOL_HOLIDAYS,
	SHIFT_PREFERENCE,
}
