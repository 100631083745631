import { AfterViewInit, Directive, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PRouterService } from '@plano/shared/core/router.service';
import { Subscription } from 'rxjs';

/**
 * Add the query params with the current url storage
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	selector: 'a[routerLink]',
	providers: [RouterModule],
	standalone: true,
})
export class RouterStorageDirective implements AfterViewInit, OnDestroy {
	constructor(
		private pRouterService : PRouterService,
		private anchorElementRef : ElementRef<HTMLAnchorElement>,
		private zone : NgZone,
	) {
		this.storageChangedSub = this.pRouterService.storedUrls.storedRoutesChanged.subscribe(() => {
			this.updateElementHref();
		});
	}

	private storageChangedSub : Subscription | null = null;

	/**
	 * Method to update the inner href of anchor elements.
	 *
	 * It does so by adding the storedUrls as a queryParam of the url, allowing us
	 * to provide much better "nav back" interactions when the user opens something
	 * on a new tab.
	 */
	private updateElementHref() : void {
		this.zone.runOutsideAngular(() => {
			window.requestAnimationFrame(() => {

				// have it maximum 7 steps back, so the url doesn't get really huge
				const storageUrls = this.pRouterService.storedUrls.getStorageCopy().slice(-7).map(storedUrl => {
					if (!storedUrl.queryParams) return storedUrl;

					// if there are urlsStorage on the storedUrls we can remove them, as the information would be duplicated
					const tempObject = {...storedUrl};
					tempObject.queryParams = {...storedUrl.queryParams, urlsStorage: '[]'};
					return tempObject;
				});
				const encodedUrls = encodeURIComponent(JSON.stringify(storageUrls));
				const actualHref = this.anchorElementRef.nativeElement.href;
				if (actualHref) {
					let refWithoutFragment = '';
					let fragment : string | null = null;
					if (actualHref.includes('#')) {
						const fragmentIndex = actualHref.indexOf('#');
						refWithoutFragment = actualHref.slice(0, fragmentIndex);
						fragment = actualHref.slice(fragmentIndex);
					} else refWithoutFragment = actualHref;

					// if there are already urlsStorage on the url, replace it so we don't store unnecessary information
					if (actualHref.includes('urlsStorage')) {
						const newUrlStorageQueryParamString = `urlsStorage=${encodedUrls}`;
						// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
						this.anchorElementRef.nativeElement.href = `${actualHref.replace(/urlsStorage=[^&]*/,newUrlStorageQueryParamString)}${fragment ?? ''}`;
					} else {
						// if not, we need to find the correct place in the url to place the new queryParam
						// if there is a fragment, keep it after the queryParams

						// find the separator character, meaning if this is the first queryParam or not
						const separatorChar = actualHref.includes('?') ? '&' : '?';
						this.anchorElementRef.nativeElement.href = (`${refWithoutFragment}${separatorChar}urlsStorage=${encodedUrls}${fragment ?? ''}`);
					}
				}
			});
		});

	}

	public ngAfterViewInit() : void {
		this.updateElementHref();
	}

	public ngOnDestroy() : void {
		this.storageChangedSub?.unsubscribe();
	}
}
