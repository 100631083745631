/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, FormControl, FormControlState, FormGroup, ValidatorFn } from '@angular/forms';
import { ApiListWrapper, ApiObjectWrapper } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidatorFn, PValidatorObject } from '@plano/shared/core/validators.types';
import { Subscription } from 'rxjs';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PFormControlSignatureObjectValidatorOrOptsType = PValidatorObject[] | null;

// eslint-disable-next-line jsdoc/require-jsdoc, @typescript-eslint/no-explicit-any -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PFormControlSignatureObject<TValue = any> = {
	labelText ?: string;
	formState : TValue | FormControlState<TValue>;

	/**
	 * An array of PValidatorObjects which contain the information on how to validate this FormControl
	 * @deprecated Instead provide an attributeInfo. PFormControl will take care of
	 * - attributeInfo.validators()
	 * - validators for attributeInfo.type()
	 * - validators that get appended to the formControl by the component
	 */
	validatorObjects ?: PValidatorObject[];
	asyncValidator ?: PValidatorObject<'async'> | PValidatorObject<'async'>[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
	attributeInfo ?: ApiAttributeInfo<any, TValue>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	subscribe ?: ((value : any) => void),
};

const turnIntoAngularCompatibleValidatorArray = (
	input : (PValidatorObject | PValidatorFn)[] | null,
) : ValidatorFn[] | null => {
	if (input === null) return null;

	const pValidatorOrOptions : ValidatorFn[] | null = [];
	for (const validator of input) {
		pValidatorOrOptions.push(validator instanceof PValidatorObject ? validator.fn : validator);
	}
	return pValidatorOrOptions.length > 0 ? pValidatorOrOptions : null;
};

const turnIntoAngularCompatibleAsyncValidatorArray = (
	input : PValidatorObject<'async'>[] | null,
) : AsyncValidatorFn[] | null => {
	if (input === null) return null;

	const pValidatorOrOptions : AsyncValidatorFn[] | null = [];
	for (const validator of input) {
		pValidatorOrOptions.push(validator instanceof PValidatorObject ? validator.fn : validator);
	}
	return pValidatorOrOptions.length > 0 ? pValidatorOrOptions : null;
};

/**
 * Same as FormGroup but always has one child which holds the id of the related AI
 * @example When you have a FormArray holding a lot of FormGroups and these FormGroups are automatically generated
 * by AttributeInfos. Than this Class makes it easier to get the right entry by `array.controls.find(item.ai.id === ai.id)`
 */
export class PFormGroupBasedOnAI<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	TControl extends { [K in keyof TControl] : AbstractControl } = any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
	TAttributeInfo extends ApiAttributeInfo<any, ApiObjectWrapper<any>> = ApiAttributeInfo<any, ApiObjectWrapper<any>>,
> extends FormGroup<TControl> {
	constructor(
		ai : TAttributeInfo,
		controls : TControl,
		validatorOrOpts : ValidatorFn | ValidatorFn[] | AbstractControlOptions | null = null,
		asyncValidator : AsyncValidatorFn | AsyncValidatorFn[] | null = null,
	) {
		super(controls, validatorOrOpts, asyncValidator);
		this.ai = ai;
	}

	public ai : TAttributeInfo;
}

/**
 * Same as FormGroup but always has one child which holds the id of the related AI
 * @example When you have a FormArray holding a lot of FormGroups and these FormGroups are automatically generated
 * by AttributeInfos. Than this Class makes it easier to get the right entry by `array.controls.find(item.ai.id === ai.id)`
 */
export class PFormArrayBasedOnAI<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	TControl extends AbstractControl = any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
	TAttributeInfo extends ApiAttributeInfo<any, ApiListWrapper<any>> = ApiAttributeInfo<any, ApiListWrapper<any>>,
> extends FormArray<TControl> {
	constructor(
		ai : TAttributeInfo,
		controls : Array<TControl>,
		validatorOrOpts : ValidatorFn | ValidatorFn[] | AbstractControlOptions | null = null,
		asyncValidator : AsyncValidatorFn | AsyncValidatorFn[] | null = null,
	) {
		super(controls, validatorOrOpts, asyncValidator);
		this.ai = ai;
	}

	public ai : TAttributeInfo;
}

// eslint-disable-next-line jsdoc/require-jsdoc, @typescript-eslint/no-explicit-any -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PFormControl<TValue = any> extends FormControl<TValue | null> {
	constructor(
		input : PFormControlSignatureObject<TValue>,
	) {
		super(
			input.formState,
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			turnIntoAngularCompatibleValidatorArray(input.validatorObjects ?? []),
			turnIntoAngularCompatibleAsyncValidatorArray(
				input.asyncValidator ? (Array.isArray(input.asyncValidator) ? input.asyncValidator : [input.asyncValidator]) : [],
			),
		);

		this.attributeInfo = input.attributeInfo;
		this.labelText = input.labelText;
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		this.fixedValidatorFns = input.validatorObjects ?? [];
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		this.fixedAsyncValidatorFns = input.asyncValidator ?? [];

		this.updateValidators();

		const subscribe = input.subscribe;
		if (subscribe !== undefined) {
			this.subscriptions.push(this.valueChanges.subscribe((value : unknown) => {
				subscribe(value);
			}));
		}
	}

	/**
	 * @see AbstractControl#addValidators
	 * @deprecated This method is for internal (PFormControl/AI) use. It just calls FormControl’s addValidators().
	 */
	public override addValidators(validators : ValidatorFn | ValidatorFn[]) : void {
		super.addValidators(validators);
	}

	/**
	 * @see AbstractControl#setValidators
	 * @deprecated This method is for internal (PFormControl/AI) use. It just calls FormControl’s addValidators().
	 */
	public override setValidators(validators : ValidatorFn | ValidatorFn[] | null) : void {
		super.setValidators(validators);
	}

	private subscriptions : Subscription[] = [];

	/**
	 * Unsubscribe all possible subscriptions here
	 */
	public unsubscribe() : void {
		for (const subscription of this.subscriptions) subscription.unsubscribe();
	}

	/**
	 * Get all basic validators that should ALWAYS apply to the provided type
	 */
	public getValidatorsForType(type : PApiType) : PValidatorObject[] {
		const BACKEND_MAX_INTEGER = 2147483647;
		const BACKEND_MAX_LONG = 92233720368547; // This is not the correct max value. But should be good enough for now ;)
		const validatorsService = new ValidatorsService();

		switch (type) {
			case PApiType.Date:
			case PApiType.DateExclusiveEnd:
			case PApiType.DateTime:
			case PApiType.LocalTime:
			case PApiType.Duration:
				return [
					validatorsService.min(-BACKEND_MAX_LONG, true, type),
					validatorsService.max(BACKEND_MAX_LONG, true, type),
				];

			case PApiType.Enum:
			case PApiType.Id:
			case PApiType.ShiftId:
			case PApiType.ShiftSelector:
			case PApiType.LabelId:
			case PApiType.any:
			case PApiType.boolean:
			case PApiType.Image:
			case PApiType.Pdf:
			case PApiType.ApiList:
			case PApiType.ApiObject:
			case PApiType.Color:
			case PApiType.Emoji:
				return [];

			case PApiType.string:
				return [ validatorsService.maxLength(100000, type) ];

			case PApiType.Search:
				return [ validatorsService.maxLength(1000, type) ];

			case PApiType.number:
				return [
					validatorsService.number(type),
					validatorsService.min(-BACKEND_MAX_INTEGER, true, type),
					validatorsService.max(BACKEND_MAX_INTEGER, true, type),
				];

			case PApiType.Email:
				return [ validatorsService.email(), validatorsService.maxLength(1000, type) ];

			case PApiType.Url:
				return [ validatorsService.url(), validatorsService.maxLength(5000, type) ];

			case PApiType.Iban:
				return [ validatorsService.iban() ];

			case PApiType.Bic:
				return [ validatorsService.bic() ];

			case PApiType.PostalCode:
				return [ validatorsService.plz() ];

			case PApiType.Tel:
				return [ validatorsService.phone(), validatorsService.maxLength(50, type) ];

			case PApiType.Password:
				return [ validatorsService.password(), validatorsService.maxLength(128, type) ];

			case PApiType.ClientCurrency:
			case PApiType.Euro:
				return [
					validatorsService.currency(type),
					validatorsService.min(-BACKEND_MAX_INTEGER, true, type),
					validatorsService.max(BACKEND_MAX_INTEGER, true, type),
				];

			case PApiType.Integer:
				return [
					validatorsService.maxDecimalPlacesCount(0, type),
					validatorsService.min(-BACKEND_MAX_INTEGER, true, type),
					validatorsService.max(BACKEND_MAX_INTEGER, true, type),
				];

			case PApiType.Minutes:
			case PApiType.Hours:
			case PApiType.Days:
			case PApiType.Years:
			case PApiType.Months:
				return [
					validatorsService.min(0, true, type),
					validatorsService.max(BACKEND_MAX_INTEGER, true, type),
					validatorsService.maxDecimalPlacesCount(0, type),
				];

			case PApiType.Percent:
				return [
					validatorsService.min(0, true, type),
					validatorsService.max(BACKEND_MAX_INTEGER, true, type),
				];

		}
	}

	/**
	 * Get all async basic validators that should ALWAYS apply to the provided type
	 */
	public getAsyncValidatorsForType(type : PApiType) : PValidatorObject<'async'>[] {
		switch (type) {
			case PApiType.Date:
			case PApiType.DateExclusiveEnd:
			case PApiType.DateTime:
			case PApiType.LocalTime:
			case PApiType.Duration:
			case PApiType.Enum:
			case PApiType.Id:
			case PApiType.ShiftId:
			case PApiType.LabelId:
			case PApiType.ShiftSelector:
			case PApiType.any:
			case PApiType.boolean:
			case PApiType.Image:
			case PApiType.string:
			case PApiType.ApiList:
			case PApiType.ApiObject:
			case PApiType.Color:
			case PApiType.Search:
			case PApiType.number:
			case PApiType.Url:
			case PApiType.Iban:
			case PApiType.Bic:
			case PApiType.PostalCode:
			case PApiType.Tel:
			case PApiType.Password:
			case PApiType.ClientCurrency:
			case PApiType.Euro:
			case PApiType.Integer:
			case PApiType.Minutes:
			case PApiType.Hours:
			case PApiType.Days:
			case PApiType.Years:
			case PApiType.Months:
			case PApiType.Percent:
			case PApiType.Pdf:
			case PApiType.Email:
			case PApiType.Emoji:
				return [];
		}
	}

	/**
	 * Validators that gets set by a custom form component.
	 *
	 * Example: <p-input> can have it’s own Validators.
	 * These can be validators that validate the components value (Not the model or formControl.value!)
	 * They can also validate against locale specific syntaxes.
	 * The p-input needs to take care that the formControl.componentValidators() gets filled correctly.
	 */
	public componentValidators : PValidatorFn | null = () => null;

	private get attributeInfoValidatorObjects() : PValidatorObject[] {
		return this.attributeInfo?.validations
			.map((item) => item())
			.filter((item) : item is PValidatorObject => {
				// Remove "null" items.
				// Sometimes a AttributeInfo.validators item returns a PValidatorObject, sometimes null.
				// Null means that the validator is inactive.
				if (item === null) return false;
				return true;
			}) ?? [];
	}

	private get attributeInfoAsyncValidatorsFn() : PValidatorObject<'async'>[] {
		return this.attributeInfo?.asyncValidations
			.map((item) => item())
			.filter((item) : item is PValidatorObject<'async'> => {
				// Remove "null" items.
				// Sometimes a AttributeInfo.validators item returns a PValidatorObject, sometimes null.
				// Null means that the validator is inactive.
				if (item === null) return false;
				return true;
			}) ?? [];
	}

	/**
	 * Get the validators that can possibly change.
	 */
	private getAttributeInfoValidators() : PValidatorObject[] {
		// Get the validators that can possibly change.
		return this.attributeInfoValidatorObjects;
	}

	/**
	 * Get the validators that can possibly change.
	 */
	private getAttributeInfoAsyncValidators() : PValidatorObject<'async'>[] {
		// Get the validators that can possibly change.
		return this.attributeInfoAsyncValidatorsFn;
	}

	/**
	 * Get the validators that will always be the same, as long as this formControl exists.
	 * @deprecated
	 */
	private getFixedValidatorFns() : PValidatorObject[] {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.fixedValidatorFns ?? [];
	}

	/**
	 * Get the validators that will always be the same, as long as this formControl exists.
	 * @deprecated
	 */
	private getFixedAsyncValidatorFns() : PValidatorObject<'async'>[] {
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (Array.isArray(this.fixedAsyncValidatorFns)) return this.fixedAsyncValidatorFns;
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.fixedAsyncValidatorFns ? [this.fixedAsyncValidatorFns] : [];
	}

	/** Basic validators that should ALWAYS apply to the provided type */
	private getTypeValidators() : PValidatorObject[] {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.attributeInfo?.type ? this.getValidatorsForType(this.attributeInfo.type) : [];
	}

	/** Basic async validators that should ALWAYS apply to the provided type */
	private getTypeAsyncValidators() : PValidatorObject<'async'>[] {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.attributeInfo?.type ? this.getAsyncValidatorsForType(this.attributeInfo.type) : [];
	}

	/** @see FormGroup#componentValidators */
	private getComponentValidator() : PValidatorFn {
		const result : PValidatorFn = this.componentValidators ?? (() : null => null);
		return result;
	}

	private allValidators() : (PValidatorObject | PValidatorFn)[] {

		/**
		 * Get the validators delivered by the api. They can possibly change.
		 */
		const attributeInfoValidators = this.getAttributeInfoValidators();

		/** @deprecated */
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const fixedValidatorFns = this.getFixedValidatorFns();

		/**
		 * Basic validators that should ALWAYS apply to the provided type
		 */
		const typeValidators : PValidatorObject[] = this.getTypeValidators();

		/**
		 * Validators that gets set by a custom form component (e.g. p-input).
		 * They usually test the frontend value. Like locale specific separator for the entered number.
		 */
		const componentValidators = [this.getComponentValidator()];

		// Its important to have the strictest validators at the bottom of the validators array.
		// Reason:
		// 					For [ min(10), min(2) ] and value === 1 you get error { min: 2 … }
		// 					For [ min(10), min(2) ] and value === 2 you get error { min: 10 … }
		// 					For [ min(2), min(10) ] you will get { min: 10 … } in both above cases
		// eslint-disable-next-line sonarjs/prefer-immediate-return -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const sortedValidators = [
			...typeValidators,
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			...fixedValidatorFns,
			...attributeInfoValidators,
			...componentValidators,
		];

		return sortedValidators;
	}

	private allAsyncValidators() : PValidatorObject<'async'>[] {

		/**
		 * Get the validators delivered by the api. They can possibly change.
		 */
		const attributeInfoValidators = this.getAttributeInfoAsyncValidators();

		/**
		 * Here we store Async Validators that are fix to this FormControl.
		 * They can be combined with additional validators from AttributeInfo later.
		 */
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const fixedAsyncValidatorFns = this.getFixedAsyncValidatorFns();

		/**
		 * Basic validators that should ALWAYS apply to the provided type
		 */
		const typeValidators : PValidatorObject<'async'>[] = this.getTypeAsyncValidators();

		// Its important to have the strictest validators at the bottom of the validators array.
		// Reason:
		// 					For [ min(10), min(2) ] and value === 1 you get error { min: 2 … }
		// 					For [ min(10), min(2) ] and value === 2 you get error { min: 10 … }
		// 					For [ min(2), min(10) ] you will get { min: 10 … } in both above cases
		// eslint-disable-next-line sonarjs/prefer-immediate-return -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const sortedValidators = [
			...typeValidators,
			...attributeInfoValidators,
			...fixedAsyncValidatorFns,
		];

		return sortedValidators;
	}

	private getComparedValue(alreadyExistingObject : PValidatorObject) : string | number | boolean | null {
		const comparedConst = (
			alreadyExistingObject.comparedConst instanceof Function ?
				alreadyExistingObject.comparedConst() :
				alreadyExistingObject.comparedConst
		);

		if (comparedConst !== undefined) {
			return comparedConst;
		} else if (alreadyExistingObject.comparedAttributeName !== undefined) {

			// Check all parents if the comparedNode can be found
			let parent = this.parent;
			while (parent) {
				const comparedControl = parent.get(alreadyExistingObject.comparedAttributeName);
				if (comparedControl) return comparedControl.value;
				parent = parent.parent;
			}

		}

		return null;
	}

	private addTheHighestMinObject(attributeInfoValidatorObject : PValidatorObject) : boolean {
		assumeNonNull(attributeInfoValidatorObject.name);

		// Only the highest min should be stored at the end.
		const alreadyExistingObject = this.validatorObjects[attributeInfoValidatorObject.name];
		if (alreadyExistingObject !== undefined) {
			const comparedValue = this.getComparedValue(alreadyExistingObject) as number | null;

			const currentValidatorComparedConst = attributeInfoValidatorObject.comparedConst as number | null;
			if (currentValidatorComparedConst === null) return false;
			if (comparedValue === null) return false;
			if (comparedValue > currentValidatorComparedConst) return false;
		}
		this.validatorObjects[attributeInfoValidatorObject.name] = attributeInfoValidatorObject;
		return true;
	}

	private addTheLowestMaxObject(attributeInfoValidatorObject : PValidatorObject) : boolean {
		assumeNonNull(attributeInfoValidatorObject.name);

		// Only the lowest max should be stored at the end.
		const alreadyExistingObject = this.validatorObjects[attributeInfoValidatorObject.name];
		if (alreadyExistingObject !== undefined) {
			const comparedValue = this.getComparedValue(alreadyExistingObject) as number | null;

			const currentValidatorComparedConst = attributeInfoValidatorObject.comparedConst as number | null;
			if (currentValidatorComparedConst === null) return false;
			if (comparedValue === null) return false;
			if (comparedValue < currentValidatorComparedConst) return false;
		}

		this.validatorObjects[attributeInfoValidatorObject.name] = attributeInfoValidatorObject;
		return true;
	}

	/**
	 * Stores the current related ValidatorObjects.
	 */
	// eslint-disable-next-line complexity -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	private updateValidatorObjects() : void {
		this.validatorObjects = {};

		for (const attributeInfoValidatorObject of this.attributeInfoValidatorObjects) {
			if (!(attributeInfoValidatorObject instanceof PValidatorObject)) continue;
			switch (attributeInfoValidatorObject.name) {
				case null:
					// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					console.log('Case null is unexpected for attributeInfoValidatorObject.name here.');
					break;

				case PPossibleErrorNames.MIN:
				case PPossibleErrorNames.MIN_LENGTH:
				case PPossibleErrorNames.GREATER_THAN:
					if (!this.addTheHighestMinObject(attributeInfoValidatorObject)) continue;
					break;
				case PPossibleErrorNames.MAX:
				case PPossibleErrorNames.MAX_LENGTH:
				case PPossibleErrorNames.LESS_THAN:
				case PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT:
					if (!this.addTheLowestMaxObject(attributeInfoValidatorObject)) continue;
					break;
				case PPossibleErrorNames.BIC:
				case PPossibleErrorNames.CURRENCY:
				case PPossibleErrorNames.EMAIL:
				case PPossibleErrorNames.EMAIL_INVALID:
				case PPossibleErrorNames.EMAIL_USED:
				case PPossibleErrorNames.EMAIL_WITHOUT_AT:
				case PPossibleErrorNames.EMAIL_HAS_ERROR:
				case PPossibleErrorNames.ENSURE_NULL:
				case PPossibleErrorNames.FIRST_FEE_PERIOD_START_IS_NULL:
				case PPossibleErrorNames.FREE_PRICE_CHOICE_OR_PREDEFINED_PRICES_NOT_SET:
				case PPossibleErrorNames.DUPLICATE_PREDEFINED_PRICES:
				case PPossibleErrorNames.ONE_WEEK_DAY_IS_SELECTED:
				case PPossibleErrorNames.FLOAT:
				case PPossibleErrorNames.IBAN:
				case PPossibleErrorNames.ID_DEFINED:
				case PPossibleErrorNames.MAX_FILE_SIZE:
				case PPossibleErrorNames.PDF_MAX_PAGES:
				case PPossibleErrorNames.PDF_PAGE_DIMENSION:
				case PPossibleErrorNames.IMAGE_MAX_HEIGHT:
				case PPossibleErrorNames.IMAGE_MAX_WIDTH:
				case PPossibleErrorNames.IMAGE_MIN_HEIGHT:
				case PPossibleErrorNames.IMAGE_MIN_WIDTH:
				case PPossibleErrorNames.IMAGE_RATIO:
				case PPossibleErrorNames.INTEGER:
				case PPossibleErrorNames.LETTERS_REQUIRED:
				case PPossibleErrorNames.NOT_UNDEFINED:
				case PPossibleErrorNames.NUMBERS_REQUIRED:
				case PPossibleErrorNames.NUMBER_NAN:
				case PPossibleErrorNames.OCCUPIED:
				case PPossibleErrorNames.PASSWORD:
				case PPossibleErrorNames.PASSWORD_UNCONFIRMED:
				case PPossibleErrorNames.PATTERN:
				case PPossibleErrorNames.PHONE:
				case PPossibleErrorNames.PLZ:
				case PPossibleErrorNames.REQUIRED:
				case PPossibleErrorNames.UPPERCASE:
				case PPossibleErrorNames.UPPERCASE_REQUIRED:
				case PPossibleErrorNames.URL:
				case PPossibleErrorNames.URL_INCOMPLETE:
				case PPossibleErrorNames.URL_PROTOCOL_MISSING:
				case PPossibleErrorNames.WHITESPACE:
				case PPossibleErrorNames.DOMAIN:
				case PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME:
				case PPossibleErrorNames.DUPLICATE_ACTIVITY_AREA_NAME :
				case PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD:
				case PPossibleErrorNames.DUPLICATE_LABELS:
				case PPossibleErrorNames.DUPLICATE_PERMISSION_GROUP_NAME:
				case PPossibleErrorNames.NULL_VALIDATOR:
				case PPossibleErrorNames.NOT_EQUALS:
				case PPossibleErrorNames.DIFFERENT_UNIT:
				case PPossibleErrorNames.INVALID_FILTER:
				case PPossibleErrorNames.OVERLAPPING_VERSIONS:
					this.validatorObjects[attributeInfoValidatorObject.name] = attributeInfoValidatorObject;

			}
		}

		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.fixedValidatorFns, 'this.fixedValidatorFns');
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		for (const validator of this.fixedValidatorFns) {
			if (validator.name === null) continue;
			if (!(validator instanceof PValidatorObject)) continue;
			this.validatorObjects[validator.name] = validator;
		}
	}

	// NOTE: This would more or less be the same as .validatorObjects
	// /**
	//  * Api provides information about the limits in its ValidatorObjects.
	//  * This is a method to extract the limits.
	//  */
	// public get limits() : {
	// 	[PPossibleErrorNames.MIN] ?: number,
	// 	[PPossibleErrorNames.MAX] ?: number,
	// }[] {
	// 	const result : PFormControl['limits'] = [];
	// 	for (const validator of this.validatorObjects) {

	// 		// validator kann folgendes sein:

	// 		//      - ein objekt
	// 		if (validator instanceof PValidatorObject) {
	// 			limits[validator.name] = validator.comparedConst;
	// 		}

	// 		//      - eine funktion die ein objekt zurück gibt
	// 		const returnOfValidator = validator({value : this.value});
	// 		if (returnOfValidator instanceof PValidatorObject) {
	// 			result[returnOfValidator.name] = returnOfValidator.comparedConst;
	// 		}

	// 	}
	// 	return result;
	// }

	/**
	 * Refresh the set of Validators and Async that should be applied to this FormControl.
	 *
	 * Background: 	Sometimes the array of validators and async validators that should be applied to a attribute change
	 * 							inside AttributeInfo.
	 * 							Since we can not subscribe changes on the set of AttributeInfo.validators, we need to call this
	 * 							method every time a potential change could happen.
	 */
	public updateValidators() : void {

		// dont do any validation when the attribute is not visible
		if (this.attributeInfo && !this.attributeInfo.isAvailable) {
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			this.setValidators(null);
			this.setAsyncValidators([]);
			return;
		}

		this.updateValidatorObjects();

		// Turn them into a format Angular understands.
		const allValidators = this.allValidators();

		const filteredValidatorObjects : typeof allValidators = [];

		// filter validators to only have the correct one calculated by updateValidatorObjects
		// we can remove the validator objects here because they have been calculated in updateValidatorObjects
		for (const validatorObjectInAllValidators of allValidators) {
			// If the validatorObject is already in the validatorObjects, we don’t need to add it. It will be added in the
			// next for loop.
			if (validatorObjectInAllValidators.name && validatorObjectInAllValidators.name in this.validatorObjects) continue;
			filteredValidatorObjects.push(validatorObjectInAllValidators);
		}

		for (const validatorObjectInValidatorObjects of Object.values(this.validatorObjects)) {
			filteredValidatorObjects.push(validatorObjectInValidatorObjects);
		}

		const angularCompatibleValidatorArray = turnIntoAngularCompatibleValidatorArray(filteredValidatorObjects);

		// Set them to the formControl
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		this.setValidators(angularCompatibleValidatorArray);

		const asyncValidators = this.allAsyncValidators();
		const angularCompatibleAsyncValidatorArray = turnIntoAngularCompatibleAsyncValidatorArray(asyncValidators);

		this.asyncValidatorObjects = {};

		for (const asyncValidatorObject of this.allAsyncValidators()) {
			// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (asyncValidatorObject.name)
				this.asyncValidatorObjects[asyncValidatorObject.name] = asyncValidatorObject;
		}
		this.setAsyncValidators(angularCompatibleAsyncValidatorArray);
	}

	public attributeInfo : PFormControlSignatureObject['attributeInfo'];

	/**
	 * Here we store Validators that are fix to this FormControl.
	 * They can be combined with additional validators from AttributeInfo later.
	 *
	 * @deprecated provide an attributeInfo. PFormControl will take care of
	 * - attributeInfo.validators()
	 * - validators for attributeInfo.type()
	 * - validators that get appended to the formControl by the component
	 */
	private fixedValidatorFns : PFormControlSignatureObject['validatorObjects'] = [];

	/**
	 * Here we store Async Validators that are fix to this FormControl.
	 * They can be combined with additional validators from AttributeInfo later.
	 *
	 * @deprecated provide an attributeInfo. PFormControl will take care of
	 * - attributeInfo.asyncValidators()
	 * - async validators for attributeInfo.type()
	 * - async validators that get appended to the formControl by the component
	 */
	private fixedAsyncValidatorFns : PFormControlSignatureObject['asyncValidator'] = [];

	public labelText : PFormControlSignatureObject['labelText'];

	/**
	 * The PValidatorObject’s of the validators that will be applied to this formControl.
	 *
	 * This is necessary to transport some information about the validators. Like "What will max() be compared against?"
	 * We can access the compared const through the error name like this:
	 * formControl.validatorObjects?.[PPossibleErrorNames.THE_ERROR_WE_WANT]?.comparedConst
	 */
	public validatorObjects ! : {
		[K in PPossibleErrorNames] ?: PValidatorObject
	};

	/**
	 * The asyncValidatorObjects are more or less equal to the validatorObjects.
	 * An example: The async validators for an Image will check if the image is 1800px wide. We want to show this
	 * information to the users. But we can not get to the information as long as it is capsulated in an AsyncValidator function.
	 * But since we have PValidatorObject<'async'>s i can read it like this:
	 * formControl.asyncValidatorObjects?.[PPossibleErrorNames.IMAGE_MAX_WIDTH]?.comparedConst
	 */
	public asyncValidatorObjects ! : { [K in PPossibleErrorNames] ?: PValidatorObject<'async'> };
}
