<div class="modal-header ps-3 position-relative d-flex justify-content-between align-items-center w-100">
	<div class="me-auto w-100" *ngIf="headline || item">
		<h3 *ngIf="headline"
			[class.text-white]="textWhite"
			[class.text-danger]="hasDanger"
		>{{ headline }}</h3>
		<ng-template [ngIf]="item">
			<span class="font-tabular-nums nowrap mt-2" [class.text-white]="textWhite" *ngIf="start">{{ start | pDate:'shortDate' }} | {{ start | pDate:'shortTime' }}</span>
		</ng-template>
	</div>
	<ng-content />
	<button type="button" class="btn large p-0" title="Schließen" i18n-title (click)="onClose.emit($event)">
		<fa-icon [pCloseOnEsc]="modalService.topModalKeyboard ?? true" pCloseOnEscType="modal" aria-hidden="true" [theme]="textWhite ? PTextColorEnum.WHITE : null" [icon]="enums.PlanoFaIconContextPool.DISMISS" />
	</button>
</div>
