<a
	[routerLink]="forceNavBackLink ?? navBackInfo.url"
	[fragment]="fragmentForNavBack ?? (routeId && routeScrollToFragment ? routeScrollToFragment : undefined!)"
	[relativeTo]="navBackInfo.navigationExtras.relativeTo ?? null"
	[queryParams]="queryParams"
	[queryParamsHandling]="navBackInfo.navigationExtras.queryParamsHandling ?? null"
	[preserveFragment]="!!navBackInfo.navigationExtras.preserveFragment"
	[skipLocationChange]="!!navBackInfo.navigationExtras.skipLocationChange"
	[replaceUrl]="!!navBackInfo.navigationExtras.replaceUrl"
	[state]="navBackInfo.navigationExtras.state!"
	(click)="onNavigation($event)"
	role="button"
	class="btn btn-sm btn-secondary me-auto d-flex gap-1 align-items-baseline">
	<fa-icon
		[fixedWidth]="true"
		[icon]="icon"
		[theme]="hasTextDanger ? enums.PThemeEnum.DANGER : null"
	/>
	{{ textElement.textContent }}
</a>

<span hidden #textElement>
	@if(text === null) {
		<ng-container i18n>Zurück</ng-container>
	} @else {
		{{ text }}
	}
</span>
