/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "testing/ai_switch_showcase".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	ALL_TYPES = 1;
	IS_NULL = 2;
	VALIDATIONS = 3;
	ALL_TYPES_BOOLEAN = 1;
	ALL_TYPES_NUMBER = 2;
	ALL_TYPES_INTEGER = 3;
	ALL_TYPES_CLIENT_CURRENCY = 4;
	ALL_TYPES_STRING = 5;
	ALL_TYPES_TEL = 6;
	ALL_TYPES_EMAIL = 7;
	ALL_TYPES_PASSWORD = 8;
	ALL_TYPES_POSTAL_CODE = 9;
	ALL_TYPES_SEARCH = 10;
	ALL_TYPES_URL = 11;
	ALL_TYPES_IBAN = 12;
	ALL_TYPES_BIC = 13;
	ALL_TYPES_DATE_TIME = 14;
	ALL_TYPES_DATE_WITHOUT_TIME = 15;
	ALL_TYPES_DATE_EXCLUSIVE_END = 16;
	ALL_TYPES_MINUTES = 17;
	ALL_TYPES_HOURS = 18;
	ALL_TYPES_DAYS = 19;
	ALL_TYPES_MONTHS = 20;
	ALL_TYPES_YEARS = 21;
	ALL_TYPES_DURATION = 22;
	ALL_TYPES_LOCAL_TIME = 23;
	ALL_TYPES_ENUM = 24;
	ALL_TYPES_IMAGE = 25;
	ALL_TYPES_PDF = 26;
	ALL_TYPES_COLOR = 27;
	ALL_TYPES_PERCENT = 28;
	IS_NULL_BOOLEAN = 1;
	IS_NULL_NUMBER = 2;
	IS_NULL_INTEGER = 3;
	IS_NULL_CLIENT_CURRENCY = 4;
	IS_NULL_STRING = 5;
	IS_NULL_TEL = 6;
	IS_NULL_EMAIL = 7;
	IS_NULL_PASSWORD = 8;
	IS_NULL_POSTAL_CODE = 9;
	IS_NULL_SEARCH = 10;
	IS_NULL_URL = 11;
	IS_NULL_IBAN = 12;
	IS_NULL_BIC = 13;
	IS_NULL_DATE_TIME = 14;
	IS_NULL_DATE_WITHOUT_TIME = 15;
	IS_NULL_DATE_EXCLUSIVE_END = 16;
	IS_NULL_MINUTES = 17;
	IS_NULL_HOURS = 18;
	IS_NULL_DAYS = 19;
	IS_NULL_MONTHS = 20;
	IS_NULL_YEARS = 21;
	IS_NULL_DURATION = 22;
	IS_NULL_LOCAL_TIME = 23;
	IS_NULL_ENUM = 24;
	IS_NULL_IMAGE = 25;
	IS_NULL_PDF = 26;
	IS_NULL_COLOR = 27;
	IS_NULL_PERCENT = 28;
	VALIDATIONS_TEL = 1;
	VALIDATIONS_EMAIL = 2;
	VALIDATIONS_PASSWORD = 3;
	VALIDATIONS_POSTAL_CODE = 4;
	VALIDATIONS_URL = 5;
	VALIDATIONS_IBAN = 6;
	VALIDATIONS_BIC = 7;
	VALIDATIONS_CLIENT_CURRENCY = 8;
	VALIDATIONS_INTEGER = 9;
	VALIDATIONS_REQUIRED = 10;
	VALIDATIONS_MIN = 11;
	VALIDATIONS_MAX = 12;
	VALIDATIONS_MAX_DECIMAL_PLACES = 13;
	VALIDATIONS_MAX_LENGTH = 14;
	VALIDATIONS_TEL_FAILS = 1;
	VALIDATIONS_TEL_FAILS_BAD_VALUE = 1;
	VALIDATIONS_TEL_FAILS_TOO_SHORT = 2;
	VALIDATIONS_TEL_FAILS_WHITESPACE = 3;
	VALIDATIONS_EMAIL_FAILS = 1;
	VALIDATIONS_EMAIL_FAILS_NO_AT = 1;
	VALIDATIONS_EMAIL_FAILS_WRONG_FORMAT = 2;
	VALIDATIONS_PASSWORD_FAILS = 1;
	VALIDATIONS_PASSWORD_FAILS_TOO_SHORT = 1;
	VALIDATIONS_PASSWORD_FAILS_NEEDS_DIGITS = 2;
	VALIDATIONS_POSTAL_CODE_FAILS = 1;
	VALIDATIONS_POSTAL_CODE_FAILS_TOO_LONG = 1;
	VALIDATIONS_POSTAL_CODE_FAILS_TOO_SHORT = 2;
	VALIDATIONS_URL_VALID = 1;
	VALIDATIONS_URL_FAILS = 2;
	VALIDATIONS_URL_VALID_WITH_WWW = 1;
	VALIDATIONS_URL_VALID_WITHOUT_WWW = 2;
	VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_INVALID_DOMAIN_FORMAT = 1;
	VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_VALID_DOMAIN_FORMAT = 2;
	VALIDATIONS_URL_FAILS_URL_INCOMPLETE = 3;
	VALIDATIONS_URL_FAILS_WRONG_FORMAT = 4;
	VALIDATIONS_URL_FAILS_STARTING_WWW = 5;
	VALIDATIONS_URL_FAILS_WHITESPACE = 6;
	VALIDATIONS_IBAN_FAILS = 1;
	VALIDATIONS_IBAN_FAILS_BAD_VALUE = 1;
	VALIDATIONS_BIC_FAILS = 1;
	VALIDATIONS_BIC_FAILS_BAD_VALUE = 1;
	VALIDATIONS_CLIENT_CURRENCY_FAILS = 1;
	VALIDATIONS_CLIENT_CURRENCY_FAILS_TOO_MANY_DIGITS = 1;
	VALIDATIONS_INTEGER_FAILS = 1;
	VALIDATIONS_INTEGER_FAILS_HAS_DECIMAL_PLACES = 1;
	VALIDATIONS_REQUIRED_VALID = 1;
	VALIDATIONS_REQUIRED_FAILS = 2;
	VALIDATIONS_REQUIRED_VALID_NUMBER = 1;
	VALIDATIONS_REQUIRED_VALID_INTEGER = 2;
	VALIDATIONS_REQUIRED_VALID_CLIENT_CURRENCY = 3;
	VALIDATIONS_REQUIRED_VALID_STRING = 4;
	VALIDATIONS_REQUIRED_VALID_TEL = 5;
	VALIDATIONS_REQUIRED_VALID_EMAIL = 6;
	VALIDATIONS_REQUIRED_VALID_PASSWORD = 7;
	VALIDATIONS_REQUIRED_VALID_POSTAL_CODE = 8;
	VALIDATIONS_REQUIRED_VALID_SEARCH = 9;
	VALIDATIONS_REQUIRED_VALID_URL = 10;
	VALIDATIONS_REQUIRED_VALID_IBAN = 11;
	VALIDATIONS_REQUIRED_VALID_BIC = 12;
	VALIDATIONS_REQUIRED_VALID_DATE_TIME = 13;
	VALIDATIONS_REQUIRED_VALID_DATE_WITHOUT_TIME = 14;
	VALIDATIONS_REQUIRED_VALID_DATE_EXCLUSIVE_END = 15;
	VALIDATIONS_REQUIRED_VALID_MINUTES = 16;
	VALIDATIONS_REQUIRED_VALID_HOURS = 17;
	VALIDATIONS_REQUIRED_VALID_DAYS = 18;
	VALIDATIONS_REQUIRED_VALID_MONTHS = 19;
	VALIDATIONS_REQUIRED_VALID_YEARS = 20;
	VALIDATIONS_REQUIRED_VALID_DURATION = 21;
	VALIDATIONS_REQUIRED_VALID_LOCAL_TIME = 22;
	VALIDATIONS_REQUIRED_VALID_ENUM = 23;
	VALIDATIONS_REQUIRED_VALID_COLOR = 24;
	VALIDATIONS_REQUIRED_VALID_PERCENT = 25;
	VALIDATIONS_REQUIRED_FAILS_NUMBER = 1;
	VALIDATIONS_REQUIRED_FAILS_INTEGER = 2;
	VALIDATIONS_REQUIRED_FAILS_CLIENT_CURRENCY = 3;
	VALIDATIONS_REQUIRED_FAILS_STRING = 4;
	VALIDATIONS_REQUIRED_FAILS_TEL = 5;
	VALIDATIONS_REQUIRED_FAILS_EMAIL = 6;
	VALIDATIONS_REQUIRED_FAILS_PASSWORD = 7;
	VALIDATIONS_REQUIRED_FAILS_POSTAL_CODE = 8;
	VALIDATIONS_REQUIRED_FAILS_SEARCH = 9;
	VALIDATIONS_REQUIRED_FAILS_URL = 10;
	VALIDATIONS_REQUIRED_FAILS_IBAN = 11;
	VALIDATIONS_REQUIRED_FAILS_BIC = 12;
	VALIDATIONS_REQUIRED_FAILS_DATE_TIME = 13;
	VALIDATIONS_REQUIRED_FAILS_DATE_WITHOUT_TIME = 14;
	VALIDATIONS_REQUIRED_FAILS_DATE_EXCLUSIVE_END = 15;
	VALIDATIONS_REQUIRED_FAILS_MINUTES = 16;
	VALIDATIONS_REQUIRED_FAILS_HOURS = 17;
	VALIDATIONS_REQUIRED_FAILS_DAYS = 18;
	VALIDATIONS_REQUIRED_FAILS_MONTHS = 19;
	VALIDATIONS_REQUIRED_FAILS_YEARS = 20;
	VALIDATIONS_REQUIRED_FAILS_DURATION = 21;
	VALIDATIONS_REQUIRED_FAILS_LOCAL_TIME = 22;
	VALIDATIONS_REQUIRED_FAILS_ENUM = 23;
	VALIDATIONS_REQUIRED_FAILS_IMAGE = 24;
	VALIDATIONS_REQUIRED_FAILS_PDF = 25;
	VALIDATIONS_REQUIRED_FAILS_PERCENT = 26;
	VALIDATIONS_MIN_VALID = 1;
	VALIDATIONS_MIN_FAILS = 2;
	VALIDATIONS_MIN_VALID_COMPARE_CONST = 1;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_IS_EQUAL = 1;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL = 2;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL = 3;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_IS_EQUAL = 4;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL = 5;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL = 6;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL = 7;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL = 8;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL = 9;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL = 10;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL = 11;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL = 12;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_IS_EQUAL = 13;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_THIS_IS_NULL = 14;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL = 15;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL = 16;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL = 17;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL = 18;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_IS_EQUAL = 19;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL = 20;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL = 21;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_IS_EQUAL = 22;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL = 23;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL = 24;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_IS_EQUAL = 25;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL = 26;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL = 27;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_IS_EQUAL = 28;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL = 29;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL = 30;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_IS_EQUAL = 31;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL = 32;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL = 33;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_IS_EQUAL = 34;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL = 35;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL = 36;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL = 37;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL = 38;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL = 39;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_IS_EQUAL = 40;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL = 41;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL = 42;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST = 1;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE = 2;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED = 1;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED = 2;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED = 3;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED = 4;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED = 5;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED = 6;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED = 7;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED = 8;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED = 9;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED = 10;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED = 11;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED = 12;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED = 13;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED = 14;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED = 15;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED = 16;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED = 17;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED = 18;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED = 19;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED = 20;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED = 21;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED = 22;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED = 23;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED = 24;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED_MINIMUM_IS_0 = 25;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED = 26;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED = 27;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED = 28;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED = 29;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_B = 1;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_A = 2;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_B = 3;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_A = 4;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B = 5;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A = 6;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_B = 7;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_A = 8;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B = 9;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A = 10;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B = 11;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A = 12;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_B = 13;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_A = 14;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_B = 15;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_A = 16;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_B = 17;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_A = 18;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_B = 19;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_A = 20;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_B = 21;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_A = 22;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_B = 23;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_A = 24;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_B = 25;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_A = 26;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_B = 27;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_A = 28;
	VALIDATIONS_MAX_VALID = 1;
	VALIDATIONS_MAX_FAILS = 2;
	VALIDATIONS_MAX_VALID_COMPARE_CONST = 1;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_IS_EQUAL = 1;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL = 2;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL = 3;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_IS_EQUAL = 4;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL = 5;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL = 6;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL = 7;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL = 8;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL = 9;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL = 10;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL = 11;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL = 12;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_IS_EQUAL = 13;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_THIS_IS_NULL = 14;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL = 15;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL = 16;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL = 17;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL = 18;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_IS_EQUAL = 19;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL = 20;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL = 21;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_IS_EQUAL = 22;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL = 23;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL = 24;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_IS_EQUAL = 25;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL = 26;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL = 27;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_IS_EQUAL = 28;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL = 29;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL = 30;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_IS_EQUAL = 31;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL = 32;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL = 33;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_IS_EQUAL = 34;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL = 35;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL = 36;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL = 37;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL = 38;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL = 39;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_IS_EQUAL = 40;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL = 41;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL = 42;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST = 1;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE = 2;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED = 1;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED = 2;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED = 3;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED = 4;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED = 5;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED = 6;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED = 7;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED = 8;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED = 9;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED = 10;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED = 11;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED = 12;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED = 13;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED = 14;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED = 15;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED = 16;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED = 17;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED = 18;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED = 19;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED = 20;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED = 21;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED = 22;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED = 23;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED = 24;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED = 25;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED = 26;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED = 27;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED = 28;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_B = 1;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_A = 2;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_B = 3;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_A = 4;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B = 5;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A = 6;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_B = 7;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_A = 8;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B = 9;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A = 10;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B = 11;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A = 12;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_B = 13;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_A = 14;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_B = 15;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_A = 16;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_B = 17;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_A = 18;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_B = 19;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_A = 20;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_B = 21;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_A = 22;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_B = 23;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_A = 24;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_B = 25;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_A = 26;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_B = 27;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_A = 28;
	VALIDATIONS_MAX_DECIMAL_PLACES_FAILS = 1;
	VALIDATIONS_MAX_DECIMAL_PLACES_FAILS_TOO_MANY_DECIMAL_PLACES = 1;
	VALIDATIONS_MAX_LENGTH_FAILS = 1;
	VALIDATIONS_MAX_LENGTH_FAILS_TOO_LONG = 1;
}


@Injectable({
  providedIn: 'root',
})
export class AiSwitchShowcaseApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'testing/ai_switch_showcase');
	}

	consts = new Consts();

	protected version() : string {
		return 'fb3165fcbbdc549ec771a1ada6777360,94f6096f401c36bd653c148f782a659c';
	}

	private dataWrapper = new AiSwitchShowcaseApiRoot(this);

	get data() : AiSwitchShowcaseApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : AiSwitchShowcaseApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new AiSwitchShowcaseApiRoot(this);
	}
}

		 
export class AiSwitchShowcaseApiRoot extends ApiObjectWrapper<AiSwitchShowcaseApiRoot>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, AiSwitchShowcaseApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiRoot, AiSwitchShowcaseApiRoot> = new ApiAttributeInfo<AiSwitchShowcaseApiRoot, AiSwitchShowcaseApiRoot>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private allTypesWrapper : AiSwitchShowcaseApiAllTypes = new AiSwitchShowcaseApiAllTypes(this.api,
		this as unknown as AiSwitchShowcaseApiRoot);
	public aiAllTypes = this.allTypesWrapper.aiThis;

	/**
	 * A list of all available types.
	 */
	get allTypes() : AiSwitchShowcaseApiAllTypes {
		this.getterDebugValidations(this.aiAllTypes, false);
		return this.allTypesWrapper;
	}

	set allTypesTestSetter(v : AiSwitchShowcaseApiAllTypes) {
        this.setterImpl(1, v.rawData, 'allTypes', true, null, function(this : AiSwitchShowcaseApiRoot) {this.allTypesWrapper = v;});
	}

	private isNullWrapper : AiSwitchShowcaseApiIsNull = new AiSwitchShowcaseApiIsNull(this.api,
		this as unknown as AiSwitchShowcaseApiRoot);
	public aiIsNull = this.isNullWrapper.aiThis;

	/**
	 * A list of all available types having the appropriate "null" value.
	 */
	get isNull() : AiSwitchShowcaseApiIsNull {
		this.getterDebugValidations(this.aiIsNull, false);
		return this.isNullWrapper;
	}

	set isNullTestSetter(v : AiSwitchShowcaseApiIsNull) {
        this.setterImpl(2, v.rawData, 'isNull', true, null, function(this : AiSwitchShowcaseApiRoot) {this.isNullWrapper = v;});
	}

	private validationsWrapper : AiSwitchShowcaseApiValidations = new AiSwitchShowcaseApiValidations(this.api,
		this as unknown as AiSwitchShowcaseApiRoot);
	public aiValidations = this.validationsWrapper.aiThis;

	/**
	 * attributes concerning validation.
	 */
	get validations() : AiSwitchShowcaseApiValidations {
		this.getterDebugValidations(this.aiValidations, false);
		return this.validationsWrapper;
	}

	set validationsTestSetter(v : AiSwitchShowcaseApiValidations) {
        this.setterImpl(3, v.rawData, 'validations', true, null, function(this : AiSwitchShowcaseApiRoot) {this.validationsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.allTypesWrapper._fixIds(_idReplacements);
		this.isNullWrapper._fixIds(_idReplacements);
		this.validationsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.allTypesWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.isNullWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.validationsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiAllTypes extends ApiObjectWrapper<AiSwitchShowcaseApiAllTypes>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiAllTypes> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiAllTypes as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiAllTypes> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiAllTypes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'allTypes',
			nodeName: 'ALL_TYPES',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiAllTypes, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiBoolean : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, boolean> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, boolean>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'boolean',
			nodeName: 'ALL_TYPES_BOOLEAN',
			type: PApiType.boolean,
			defaultValue: function(this : AiSwitchShowcaseApiAllTypes, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, number> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'number',
			nodeName: 'ALL_TYPES_NUMBER',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiAllTypes) {
				return [
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiAllTypes) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'integer',
			nodeName: 'ALL_TYPES_INTEGER',
			type: PApiType.Integer,
			rawDataIndex: 3,
		});
	aiClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'clientCurrency',
			nodeName: 'ALL_TYPES_CLIENT_CURRENCY',
			type: PApiType.ClientCurrency,
			rawDataIndex: 4,
		});
	aiString : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, string> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'string',
			nodeName: 'ALL_TYPES_STRING',
			type: PApiType.string,
			rawDataIndex: 5,
		});
	aiTel : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'tel',
			nodeName: 'ALL_TYPES_TEL',
			type: PApiType.Tel,
			rawDataIndex: 6,
		});
	aiEmail : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'email',
			nodeName: 'ALL_TYPES_EMAIL',
			type: PApiType.Email,
			rawDataIndex: 7,
		});
	aiPassword : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'password',
			nodeName: 'ALL_TYPES_PASSWORD',
			type: PApiType.Password,
			rawDataIndex: 8,
		});
	aiPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'postalCode',
			nodeName: 'ALL_TYPES_POSTAL_CODE',
			type: PApiType.PostalCode,
			rawDataIndex: 9,
		});
	aiSearch : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'search',
			nodeName: 'ALL_TYPES_SEARCH',
			type: PApiType.Search,
			rawDataIndex: 10,
		});
	aiUrl : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'url',
			nodeName: 'ALL_TYPES_URL',
			type: PApiType.Url,
			rawDataIndex: 11,
		});
	aiIban : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'iban',
			nodeName: 'ALL_TYPES_IBAN',
			type: PApiType.Iban,
			rawDataIndex: 12,
		});
	aiBic : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'bic',
			nodeName: 'ALL_TYPES_BIC',
			type: PApiType.Bic,
			rawDataIndex: 13,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'dateTime',
			nodeName: 'ALL_TYPES_DATE_TIME',
			type: PApiType.DateTime,
			rawDataIndex: 14,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'date',
			nodeName: 'ALL_TYPES_DATE_WITHOUT_TIME',
			type: PApiType.Date,
			rawDataIndex: 15,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'dateExclusiveEnd',
			nodeName: 'ALL_TYPES_DATE_EXCLUSIVE_END',
			type: PApiType.DateExclusiveEnd,
			rawDataIndex: 16,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'minutes',
			nodeName: 'ALL_TYPES_MINUTES',
			type: PApiType.Minutes,
			rawDataIndex: 17,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'hours',
			nodeName: 'ALL_TYPES_HOURS',
			type: PApiType.Hours,
			rawDataIndex: 18,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'days',
			nodeName: 'ALL_TYPES_DAYS',
			type: PApiType.Days,
			rawDataIndex: 19,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'months',
			nodeName: 'ALL_TYPES_MONTHS',
			type: PApiType.Months,
			rawDataIndex: 20,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'years',
			nodeName: 'ALL_TYPES_YEARS',
			type: PApiType.Years,
			rawDataIndex: 21,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'duration',
			nodeName: 'ALL_TYPES_DURATION',
			type: PApiType.Duration,
			rawDataIndex: 22,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'localTime',
			nodeName: 'ALL_TYPES_LOCAL_TIME',
			type: PApiType.LocalTime,
			rawDataIndex: 23,
		});
	aiEnum : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'enum',
			nodeName: 'ALL_TYPES_ENUM',
			type: PApiType.Enum,
			rawDataIndex: 24,
		});
	aiImage : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Image> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Image>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'image',
			nodeName: 'ALL_TYPES_IMAGE',
			type: PApiType.Image,
			rawDataIndex: 25,
		});
	aiPdf : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Pdf> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Pdf>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'pdf',
			nodeName: 'ALL_TYPES_PDF',
			type: PApiType.Pdf,
			rawDataIndex: 26,
		});
	aiColor : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Color> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Color>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'color',
			nodeName: 'ALL_TYPES_COLOR',
			type: PApiType.Color,
			rawDataIndex: 27,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'percent',
			nodeName: 'ALL_TYPES_PERCENT',
			type: PApiType.Percent,
			rawDataIndex: 28,
		});

	/**
	 * 
	 *
	 * @type {boolean}
	 */
	get boolean() : boolean {
		this.getterDebugValidations(this.aiBoolean, false);
		return this.data[1];
	}

	set boolean(v : boolean) {
        this.setterImpl(1, v, 'boolean', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[2];
	}

	set number(v : number) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[3];
	}

	set integer(v : Integer) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.data[4];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(4, v, 'clientCurrency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {string}
	 */
	get string() : string {
		this.getterDebugValidations(this.aiString, false);
		return this.data[5];
	}

	set string(v : string) {
        this.setterImpl(5, v, 'string', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Tel}
	 */
	get tel() : Tel {
		this.getterDebugValidations(this.aiTel, false);
		return this.data[6];
	}

	set tel(v : Tel) {
        this.setterImpl(6, v, 'tel', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Email}
	 */
	get email() : Email {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[7];
	}

	set email(v : Email) {
        this.setterImpl(7, v, 'email', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Password}
	 */
	get password() : Password {
		this.getterDebugValidations(this.aiPassword, false);
		return this.data[8];
	}

	set password(v : Password) {
        this.setterImpl(8, v, 'password', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {PostalCode}
	 */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.aiPostalCode, false);
		return this.data[9];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(9, v, 'postalCode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Search}
	 */
	get search() : Search {
		this.getterDebugValidations(this.aiSearch, false);
		return this.data[10];
	}

	set search(v : Search) {
        this.setterImpl(10, v, 'search', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get url() : Url {
		this.getterDebugValidations(this.aiUrl, false);
		return this.data[11];
	}

	set url(v : Url) {
        this.setterImpl(11, v, 'url', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Iban}
	 */
	get iban() : Iban {
		this.getterDebugValidations(this.aiIban, false);
		return this.data[12];
	}

	set iban(v : Iban) {
        this.setterImpl(12, v, 'iban', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Bic}
	 */
	get bic() : Bic {
		this.getterDebugValidations(this.aiBic, false);
		return this.data[13];
	}

	set bic(v : Bic) {
        this.setterImpl(13, v, 'bic', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[14];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(14, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[15];
	}

	set date(v : Date) {
        this.setterImpl(15, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[16];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(16, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[17];
	}

	set minutes(v : Minutes) {
        this.setterImpl(17, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[18];
	}

	set hours(v : Hours) {
        this.setterImpl(18, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[19];
	}

	set days(v : Days) {
        this.setterImpl(19, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[20];
	}

	set months(v : Months) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[21];
	}

	set years(v : Years) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[22];
	}

	set duration(v : Duration) {
        this.setterImpl(22, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[23];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(23, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
	 */
	get enum() : AiSwitchShowcaseApiCountry {
		this.getterDebugValidations(this.aiEnum, false);
		return this.data[24];
	}

	set enum(v : AiSwitchShowcaseApiCountry) {
        this.setterImpl(24, v, 'enum', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Image}
	 */
	get image() : Image | null {
		this.getterDebugValidations(this.aiImage, false);
		const path = this.data[25] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set image(v : Image | null) {
        this.setterImpl(25, v, 'image', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Pdf}
	 */
	get pdf() : Pdf | null {
		this.getterDebugValidations(this.aiPdf, false);
		const path = this.data[26] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set pdf(v : Pdf | null) {
        this.setterImpl(26, v, 'pdf', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Color}
	 */
	get color() : Color | null {
		this.getterDebugValidations(this.aiColor, false);
		return this.data[27];
	}

	set color(v : Color | null) {
        this.setterImpl(27, v, 'color', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[28];
	}

	set percent(v : Percent) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}

export enum AiSwitchShowcaseApiCountry {
	GERMANY = 1,
	AUSTRIA = 2,
	SWITZERLAND = 3,
	ITALY = 4,
	ROMANIA = 5,
	NETHERLANDS = 6,
	BELGIUM = 7,
	UNITED_KINGDOM = 8,
	CZECH_REPUBLIC = 9,
	SWEDEN = 10,
	LUXEMBOURG = 11,
}
		 
export class AiSwitchShowcaseApiIsNull extends ApiObjectWrapper<AiSwitchShowcaseApiIsNull>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiIsNull> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiIsNull as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiIsNull> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiIsNull>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'isNull',
			nodeName: 'IS_NULL',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiIsNull, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	aiBoolean : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, boolean> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, boolean>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'boolean',
			nodeName: 'IS_NULL_BOOLEAN',
			type: PApiType.boolean,
			rawDataIndex: 1,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, number> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'number',
			nodeName: 'IS_NULL_NUMBER',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiIsNull) {
				return [
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiIsNull) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'integer',
			nodeName: 'IS_NULL_INTEGER',
			type: PApiType.Integer,
			rawDataIndex: 3,
		});
	aiClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'clientCurrency',
			nodeName: 'IS_NULL_CLIENT_CURRENCY',
			type: PApiType.ClientCurrency,
			rawDataIndex: 4,
		});
	aiString : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, string> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'string',
			nodeName: 'IS_NULL_STRING',
			type: PApiType.string,
			rawDataIndex: 5,
		});
	aiTel : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'tel',
			nodeName: 'IS_NULL_TEL',
			type: PApiType.Tel,
			rawDataIndex: 6,
		});
	aiEmail : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'email',
			nodeName: 'IS_NULL_EMAIL',
			type: PApiType.Email,
			rawDataIndex: 7,
		});
	aiPassword : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'password',
			nodeName: 'IS_NULL_PASSWORD',
			type: PApiType.Password,
			rawDataIndex: 8,
		});
	aiPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'postalCode',
			nodeName: 'IS_NULL_POSTAL_CODE',
			type: PApiType.PostalCode,
			rawDataIndex: 9,
		});
	aiSearch : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'search',
			nodeName: 'IS_NULL_SEARCH',
			type: PApiType.Search,
			rawDataIndex: 10,
		});
	aiUrl : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'url',
			nodeName: 'IS_NULL_URL',
			type: PApiType.Url,
			rawDataIndex: 11,
		});
	aiIban : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'iban',
			nodeName: 'IS_NULL_IBAN',
			type: PApiType.Iban,
			rawDataIndex: 12,
		});
	aiBic : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'bic',
			nodeName: 'IS_NULL_BIC',
			type: PApiType.Bic,
			rawDataIndex: 13,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'dateTime',
			nodeName: 'IS_NULL_DATE_TIME',
			type: PApiType.DateTime,
			rawDataIndex: 14,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'date',
			nodeName: 'IS_NULL_DATE_WITHOUT_TIME',
			type: PApiType.Date,
			rawDataIndex: 15,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'dateExclusiveEnd',
			nodeName: 'IS_NULL_DATE_EXCLUSIVE_END',
			type: PApiType.DateExclusiveEnd,
			rawDataIndex: 16,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'minutes',
			nodeName: 'IS_NULL_MINUTES',
			type: PApiType.Minutes,
			rawDataIndex: 17,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'hours',
			nodeName: 'IS_NULL_HOURS',
			type: PApiType.Hours,
			rawDataIndex: 18,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'days',
			nodeName: 'IS_NULL_DAYS',
			type: PApiType.Days,
			rawDataIndex: 19,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'months',
			nodeName: 'IS_NULL_MONTHS',
			type: PApiType.Months,
			rawDataIndex: 20,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'years',
			nodeName: 'IS_NULL_YEARS',
			type: PApiType.Years,
			rawDataIndex: 21,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'duration',
			nodeName: 'IS_NULL_DURATION',
			type: PApiType.Duration,
			rawDataIndex: 22,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'localTime',
			nodeName: 'IS_NULL_LOCAL_TIME',
			type: PApiType.LocalTime,
			rawDataIndex: 23,
		});
	aiEnum : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'enum',
			nodeName: 'IS_NULL_ENUM',
			type: PApiType.Enum,
			rawDataIndex: 24,
		});
	aiImage : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Image> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Image>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'image',
			nodeName: 'IS_NULL_IMAGE',
			type: PApiType.Image,
			rawDataIndex: 25,
		});
	aiPdf : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Pdf> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Pdf>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'pdf',
			nodeName: 'IS_NULL_PDF',
			type: PApiType.Pdf,
			rawDataIndex: 26,
		});
	aiColor : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Color> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Color>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'color',
			nodeName: 'IS_NULL_COLOR',
			type: PApiType.Color,
			rawDataIndex: 27,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'percent',
			nodeName: 'IS_NULL_PERCENT',
			type: PApiType.Percent,
			rawDataIndex: 28,
		});

	/**
	 * 
	 *
	 * @type {boolean}
	 */
	get boolean() : boolean | null {
		this.getterDebugValidations(this.aiBoolean, false);
		return this.data[1];
	}

	set boolean(v : boolean | null) {
        this.setterImpl(1, v, 'boolean', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number | null {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[2];
	}

	set number(v : number | null) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer | null {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[3];
	}

	set integer(v : Integer | null) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrency() : ClientCurrency | null {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.data[4];
	}

	set clientCurrency(v : ClientCurrency | null) {
        this.setterImpl(4, v, 'clientCurrency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {string}
	 */
	get string() : string | null {
		this.getterDebugValidations(this.aiString, false);
		return this.data[5];
	}

	set string(v : string | null) {
        this.setterImpl(5, v, 'string', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Tel}
	 */
	get tel() : Tel | null {
		this.getterDebugValidations(this.aiTel, false);
		return this.data[6];
	}

	set tel(v : Tel | null) {
        this.setterImpl(6, v, 'tel', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Email}
	 */
	get email() : Email | null {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[7];
	}

	set email(v : Email | null) {
        this.setterImpl(7, v, 'email', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Password}
	 */
	get password() : Password | null {
		this.getterDebugValidations(this.aiPassword, false);
		return this.data[8];
	}

	set password(v : Password | null) {
        this.setterImpl(8, v, 'password', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {PostalCode}
	 */
	get postalCode() : PostalCode | null {
		this.getterDebugValidations(this.aiPostalCode, false);
		return this.data[9];
	}

	set postalCode(v : PostalCode | null) {
        this.setterImpl(9, v, 'postalCode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Search}
	 */
	get search() : Search | null {
		this.getterDebugValidations(this.aiSearch, false);
		return this.data[10];
	}

	set search(v : Search | null) {
        this.setterImpl(10, v, 'search', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get url() : Url | null {
		this.getterDebugValidations(this.aiUrl, false);
		return this.data[11];
	}

	set url(v : Url | null) {
        this.setterImpl(11, v, 'url', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Iban}
	 */
	get iban() : Iban | null {
		this.getterDebugValidations(this.aiIban, false);
		return this.data[12];
	}

	set iban(v : Iban | null) {
        this.setterImpl(12, v, 'iban', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Bic}
	 */
	get bic() : Bic | null {
		this.getterDebugValidations(this.aiBic, false);
		return this.data[13];
	}

	set bic(v : Bic | null) {
        this.setterImpl(13, v, 'bic', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime | null {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[14];
	}

	set dateTime(v : DateTime | null) {
        this.setterImpl(14, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date | null {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[15];
	}

	set date(v : Date | null) {
        this.setterImpl(15, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[16];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd | null) {
        this.setterImpl(16, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes | null {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[17];
	}

	set minutes(v : Minutes | null) {
        this.setterImpl(17, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours | null {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[18];
	}

	set hours(v : Hours | null) {
        this.setterImpl(18, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days | null {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[19];
	}

	set days(v : Days | null) {
        this.setterImpl(19, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months | null {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[20];
	}

	set months(v : Months | null) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years | null {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[21];
	}

	set years(v : Years | null) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration | null {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[22];
	}

	set duration(v : Duration | null) {
        this.setterImpl(22, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime | null {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[23];
	}

	set localTime(v : LocalTime | null) {
        this.setterImpl(23, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
	 */
	get enum() : AiSwitchShowcaseApiCountry | null {
		this.getterDebugValidations(this.aiEnum, false);
		return this.data[24];
	}

	set enum(v : AiSwitchShowcaseApiCountry | null) {
        this.setterImpl(24, v, 'enum', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Image}
	 */
	get image() : Image | null {
		this.getterDebugValidations(this.aiImage, false);
		const path = this.data[25] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set image(v : Image | null) {
        this.setterImpl(25, v, 'image', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Pdf}
	 */
	get pdf() : Pdf | null {
		this.getterDebugValidations(this.aiPdf, false);
		const path = this.data[26] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set pdf(v : Pdf | null) {
        this.setterImpl(26, v, 'pdf', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Color}
	 */
	get color() : Color | null {
		this.getterDebugValidations(this.aiColor, false);
		return this.data[27];
	}

	set color(v : Color | null) {
        this.setterImpl(27, v, 'color', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent | null {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[28];
	}

	set percent(v : Percent | null) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '3';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '3', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidations extends ApiObjectWrapper<AiSwitchShowcaseApiValidations>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidations> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidations as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidations, AiSwitchShowcaseApiValidations> = new ApiAttributeInfo<AiSwitchShowcaseApiValidations, AiSwitchShowcaseApiValidations>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidations,
			name: 'validations',
			nodeName: 'VALIDATIONS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidations, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 3,
		});

	private telWrapper : AiSwitchShowcaseApiValidationsTel = new AiSwitchShowcaseApiValidationsTel(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiTel = this.telWrapper.aiThis;

	/**
	 * 
	 */
	get tel() : AiSwitchShowcaseApiValidationsTel {
		this.getterDebugValidations(this.aiTel, false);
		return this.telWrapper;
	}

	set telTestSetter(v : AiSwitchShowcaseApiValidationsTel) {
        this.setterImpl(1, v.rawData, 'tel', true, null, function(this : AiSwitchShowcaseApiValidations) {this.telWrapper = v;});
	}

	private emailWrapper : AiSwitchShowcaseApiValidationsEmail = new AiSwitchShowcaseApiValidationsEmail(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiEmail = this.emailWrapper.aiThis;

	/**
	 * 
	 */
	get email() : AiSwitchShowcaseApiValidationsEmail {
		this.getterDebugValidations(this.aiEmail, false);
		return this.emailWrapper;
	}

	set emailTestSetter(v : AiSwitchShowcaseApiValidationsEmail) {
        this.setterImpl(2, v.rawData, 'email', true, null, function(this : AiSwitchShowcaseApiValidations) {this.emailWrapper = v;});
	}

	private passwordWrapper : AiSwitchShowcaseApiValidationsPassword = new AiSwitchShowcaseApiValidationsPassword(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiPassword = this.passwordWrapper.aiThis;

	/**
	 * 
	 */
	get password() : AiSwitchShowcaseApiValidationsPassword {
		this.getterDebugValidations(this.aiPassword, false);
		return this.passwordWrapper;
	}

	set passwordTestSetter(v : AiSwitchShowcaseApiValidationsPassword) {
        this.setterImpl(3, v.rawData, 'password', true, null, function(this : AiSwitchShowcaseApiValidations) {this.passwordWrapper = v;});
	}

	private postalCodeWrapper : AiSwitchShowcaseApiValidationsPostalCode = new AiSwitchShowcaseApiValidationsPostalCode(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiPostalCode = this.postalCodeWrapper.aiThis;

	/**
	 * 
	 */
	get postalCode() : AiSwitchShowcaseApiValidationsPostalCode {
		this.getterDebugValidations(this.aiPostalCode, false);
		return this.postalCodeWrapper;
	}

	set postalCodeTestSetter(v : AiSwitchShowcaseApiValidationsPostalCode) {
        this.setterImpl(4, v.rawData, 'postalCode', true, null, function(this : AiSwitchShowcaseApiValidations) {this.postalCodeWrapper = v;});
	}

	private urlWrapper : AiSwitchShowcaseApiValidationsUrl = new AiSwitchShowcaseApiValidationsUrl(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiUrl = this.urlWrapper.aiThis;

	/**
	 * 
	 */
	get url() : AiSwitchShowcaseApiValidationsUrl {
		this.getterDebugValidations(this.aiUrl, false);
		return this.urlWrapper;
	}

	set urlTestSetter(v : AiSwitchShowcaseApiValidationsUrl) {
        this.setterImpl(5, v.rawData, 'url', true, null, function(this : AiSwitchShowcaseApiValidations) {this.urlWrapper = v;});
	}

	private ibanWrapper : AiSwitchShowcaseApiValidationsIban = new AiSwitchShowcaseApiValidationsIban(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiIban = this.ibanWrapper.aiThis;

	/**
	 * 
	 */
	get iban() : AiSwitchShowcaseApiValidationsIban {
		this.getterDebugValidations(this.aiIban, false);
		return this.ibanWrapper;
	}

	set ibanTestSetter(v : AiSwitchShowcaseApiValidationsIban) {
        this.setterImpl(6, v.rawData, 'iban', true, null, function(this : AiSwitchShowcaseApiValidations) {this.ibanWrapper = v;});
	}

	private bicWrapper : AiSwitchShowcaseApiValidationsBic = new AiSwitchShowcaseApiValidationsBic(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiBic = this.bicWrapper.aiThis;

	/**
	 * 
	 */
	get bic() : AiSwitchShowcaseApiValidationsBic {
		this.getterDebugValidations(this.aiBic, false);
		return this.bicWrapper;
	}

	set bicTestSetter(v : AiSwitchShowcaseApiValidationsBic) {
        this.setterImpl(7, v.rawData, 'bic', true, null, function(this : AiSwitchShowcaseApiValidations) {this.bicWrapper = v;});
	}

	private clientCurrencyWrapper : AiSwitchShowcaseApiValidationsClientCurrency = new AiSwitchShowcaseApiValidationsClientCurrency(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiClientCurrency = this.clientCurrencyWrapper.aiThis;

	/**
	 * 
	 */
	get clientCurrency() : AiSwitchShowcaseApiValidationsClientCurrency {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.clientCurrencyWrapper;
	}

	set clientCurrencyTestSetter(v : AiSwitchShowcaseApiValidationsClientCurrency) {
        this.setterImpl(8, v.rawData, 'clientCurrency', true, null, function(this : AiSwitchShowcaseApiValidations) {this.clientCurrencyWrapper = v;});
	}

	private integerWrapper : AiSwitchShowcaseApiValidationsInteger = new AiSwitchShowcaseApiValidationsInteger(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiInteger = this.integerWrapper.aiThis;

	/**
	 * 
	 */
	get integer() : AiSwitchShowcaseApiValidationsInteger {
		this.getterDebugValidations(this.aiInteger, false);
		return this.integerWrapper;
	}

	set integerTestSetter(v : AiSwitchShowcaseApiValidationsInteger) {
        this.setterImpl(9, v.rawData, 'integer', true, null, function(this : AiSwitchShowcaseApiValidations) {this.integerWrapper = v;});
	}

	private requiredWrapper : AiSwitchShowcaseApiValidationsRequired = new AiSwitchShowcaseApiValidationsRequired(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiRequired = this.requiredWrapper.aiThis;

	/**
	 * 
	 */
	get required() : AiSwitchShowcaseApiValidationsRequired {
		this.getterDebugValidations(this.aiRequired, false);
		return this.requiredWrapper;
	}

	set requiredTestSetter(v : AiSwitchShowcaseApiValidationsRequired) {
        this.setterImpl(10, v.rawData, 'required', true, null, function(this : AiSwitchShowcaseApiValidations) {this.requiredWrapper = v;});
	}

	private minWrapper : AiSwitchShowcaseApiValidationsMin = new AiSwitchShowcaseApiValidationsMin(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiMin = this.minWrapper.aiThis;

	/**
	 * 
	 */
	get min() : AiSwitchShowcaseApiValidationsMin {
		this.getterDebugValidations(this.aiMin, false);
		return this.minWrapper;
	}

	set minTestSetter(v : AiSwitchShowcaseApiValidationsMin) {
        this.setterImpl(11, v.rawData, 'min', true, null, function(this : AiSwitchShowcaseApiValidations) {this.minWrapper = v;});
	}

	private maxWrapper : AiSwitchShowcaseApiValidationsMax = new AiSwitchShowcaseApiValidationsMax(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiMax = this.maxWrapper.aiThis;

	/**
	 * 
	 */
	get max() : AiSwitchShowcaseApiValidationsMax {
		this.getterDebugValidations(this.aiMax, false);
		return this.maxWrapper;
	}

	set maxTestSetter(v : AiSwitchShowcaseApiValidationsMax) {
        this.setterImpl(12, v.rawData, 'max', true, null, function(this : AiSwitchShowcaseApiValidations) {this.maxWrapper = v;});
	}

	private maxDecimalPlacesWrapper : AiSwitchShowcaseApiValidationsMaxDecimalPlaces = new AiSwitchShowcaseApiValidationsMaxDecimalPlaces(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiMaxDecimalPlaces = this.maxDecimalPlacesWrapper.aiThis;

	/**
	 * 
	 */
	get maxDecimalPlaces() : AiSwitchShowcaseApiValidationsMaxDecimalPlaces {
		this.getterDebugValidations(this.aiMaxDecimalPlaces, false);
		return this.maxDecimalPlacesWrapper;
	}

	set maxDecimalPlacesTestSetter(v : AiSwitchShowcaseApiValidationsMaxDecimalPlaces) {
        this.setterImpl(13, v.rawData, 'maxDecimalPlaces', true, null, function(this : AiSwitchShowcaseApiValidations) {this.maxDecimalPlacesWrapper = v;});
	}

	private maxLengthWrapper : AiSwitchShowcaseApiValidationsMaxLength = new AiSwitchShowcaseApiValidationsMaxLength(this.api,
		this as unknown as AiSwitchShowcaseApiValidations);
	public aiMaxLength = this.maxLengthWrapper.aiThis;

	/**
	 * 
	 */
	get maxLength() : AiSwitchShowcaseApiValidationsMaxLength {
		this.getterDebugValidations(this.aiMaxLength, false);
		return this.maxLengthWrapper;
	}

	set maxLengthTestSetter(v : AiSwitchShowcaseApiValidationsMaxLength) {
        this.setterImpl(14, v.rawData, 'maxLength', true, null, function(this : AiSwitchShowcaseApiValidations) {this.maxLengthWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.telWrapper._fixIds(_idReplacements);
		this.emailWrapper._fixIds(_idReplacements);
		this.passwordWrapper._fixIds(_idReplacements);
		this.postalCodeWrapper._fixIds(_idReplacements);
		this.urlWrapper._fixIds(_idReplacements);
		this.ibanWrapper._fixIds(_idReplacements);
		this.bicWrapper._fixIds(_idReplacements);
		this.clientCurrencyWrapper._fixIds(_idReplacements);
		this.integerWrapper._fixIds(_idReplacements);
		this.requiredWrapper._fixIds(_idReplacements);
		this.minWrapper._fixIds(_idReplacements);
		this.maxWrapper._fixIds(_idReplacements);
		this.maxDecimalPlacesWrapper._fixIds(_idReplacements);
		this.maxLengthWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.telWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.emailWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.passwordWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		this.postalCodeWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
		this.urlWrapper._updateRawData(data && data[5] !== undefined ? data[5] : null, generateMissingData);
		this.ibanWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
		this.bicWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.clientCurrencyWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		this.integerWrapper._updateRawData(data && data[9] !== undefined ? data[9] : null, generateMissingData);
		this.requiredWrapper._updateRawData(data && data[10] !== undefined ? data[10] : null, generateMissingData);
		this.minWrapper._updateRawData(data && data[11] !== undefined ? data[11] : null, generateMissingData);
		this.maxWrapper._updateRawData(data && data[12] !== undefined ? data[12] : null, generateMissingData);
		this.maxDecimalPlacesWrapper._updateRawData(data && data[13] !== undefined ? data[13] : null, generateMissingData);
		this.maxLengthWrapper._updateRawData(data && data[14] !== undefined ? data[14] : null, generateMissingData);
	}

	protected get dni() : string {
		return '4';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '4', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsTel extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsTel>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsTel> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsTel as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTel, AiSwitchShowcaseApiValidationsTel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTel, AiSwitchShowcaseApiValidationsTel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTel,
			name: 'tel',
			nodeName: 'VALIDATIONS_TEL',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsTel, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsTelFails = new AiSwitchShowcaseApiValidationsTelFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsTel);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsTelFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsTelFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsTel) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '61';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '61', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsTelFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsTelFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsTel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsTelFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsTelFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, AiSwitchShowcaseApiValidationsTelFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, AiSwitchShowcaseApiValidationsTelFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_TEL_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsTelFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiBadValue : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'badValue',
			nodeName: 'VALIDATIONS_TEL_FAILS_BAD_VALUE',
			type: PApiType.Tel,
			rawDataIndex: 1,
		});
	aiTooShort : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'tooShort',
			nodeName: 'VALIDATIONS_TEL_FAILS_TOO_SHORT',
			type: PApiType.Tel,
			rawDataIndex: 2,
		});
	aiWhitespace : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'whitespace',
			nodeName: 'VALIDATIONS_TEL_FAILS_WHITESPACE',
			type: PApiType.Tel,
			rawDataIndex: 3,
		});

	/**
	 * 
	 *
	 * @type {Tel}
	 */
	get badValue() : Tel {
		this.getterDebugValidations(this.aiBadValue, false);
		return this.data[1];
	}

	set badValue(v : Tel) {
        this.setterImpl(1, v, 'badValue', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Tel}
	 */
	get tooShort() : Tel {
		this.getterDebugValidations(this.aiTooShort, false);
		return this.data[2];
	}

	set tooShort(v : Tel) {
        this.setterImpl(2, v, 'tooShort', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Tel}
	 */
	get whitespace() : Tel {
		this.getterDebugValidations(this.aiWhitespace, false);
		return this.data[3];
	}

	set whitespace(v : Tel) {
        this.setterImpl(3, v, 'whitespace', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '75';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '75', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsEmail extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsEmail>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsEmail> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsEmail as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmail, AiSwitchShowcaseApiValidationsEmail> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmail, AiSwitchShowcaseApiValidationsEmail>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmail,
			name: 'email',
			nodeName: 'VALIDATIONS_EMAIL',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsEmail, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsEmailFails = new AiSwitchShowcaseApiValidationsEmailFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsEmail);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsEmailFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsEmailFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsEmail) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '62';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '62', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsEmailFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsEmailFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsEmail | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsEmailFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsEmailFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, AiSwitchShowcaseApiValidationsEmailFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, AiSwitchShowcaseApiValidationsEmailFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmailFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_EMAIL_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsEmailFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiNoAt : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmailFails,
			name: 'noAt',
			nodeName: 'VALIDATIONS_EMAIL_FAILS_NO_AT',
			type: PApiType.Email,
			rawDataIndex: 1,
		});
	aiWrongFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmailFails,
			name: 'wrongFormat',
			nodeName: 'VALIDATIONS_EMAIL_FAILS_WRONG_FORMAT',
			type: PApiType.Email,
			rawDataIndex: 2,
		});

	/**
	 * 
	 *
	 * @type {Email}
	 */
	get noAt() : Email {
		this.getterDebugValidations(this.aiNoAt, false);
		return this.data[1];
	}

	set noAt(v : Email) {
        this.setterImpl(1, v, 'noAt', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Email}
	 */
	get wrongFormat() : Email {
		this.getterDebugValidations(this.aiWrongFormat, false);
		return this.data[2];
	}

	set wrongFormat(v : Email) {
        this.setterImpl(2, v, 'wrongFormat', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '79';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '79', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPassword extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPassword>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPassword> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPassword as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPassword, AiSwitchShowcaseApiValidationsPassword> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPassword, AiSwitchShowcaseApiValidationsPassword>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPassword,
			name: 'password',
			nodeName: 'VALIDATIONS_PASSWORD',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPassword, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 3,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsPasswordFails = new AiSwitchShowcaseApiValidationsPasswordFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsPassword);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsPasswordFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsPasswordFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsPassword) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '63';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '63', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPasswordFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPasswordFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsPassword | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPasswordFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPasswordFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, AiSwitchShowcaseApiValidationsPasswordFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, AiSwitchShowcaseApiValidationsPasswordFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPasswordFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_PASSWORD_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPasswordFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiTooShort : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPasswordFails,
			name: 'tooShort',
			nodeName: 'VALIDATIONS_PASSWORD_FAILS_TOO_SHORT',
			type: PApiType.Password,
			rawDataIndex: 1,
		});
	aiNeedsDigits : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPasswordFails,
			name: 'needsDigits',
			nodeName: 'VALIDATIONS_PASSWORD_FAILS_NEEDS_DIGITS',
			type: PApiType.Password,
			rawDataIndex: 2,
		});

	/**
	 * 
	 *
	 * @type {Password}
	 */
	get tooShort() : Password {
		this.getterDebugValidations(this.aiTooShort, false);
		return this.data[1];
	}

	set tooShort(v : Password) {
        this.setterImpl(1, v, 'tooShort', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Password}
	 */
	get needsDigits() : Password {
		this.getterDebugValidations(this.aiNeedsDigits, false);
		return this.data[2];
	}

	set needsDigits(v : Password) {
        this.setterImpl(2, v, 'needsDigits', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '82';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '82', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPostalCode extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPostalCode>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPostalCode> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPostalCode as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCode, AiSwitchShowcaseApiValidationsPostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCode, AiSwitchShowcaseApiValidationsPostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCode,
			name: 'postalCode',
			nodeName: 'VALIDATIONS_POSTAL_CODE',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPostalCode, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 4,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsPostalCodeFails = new AiSwitchShowcaseApiValidationsPostalCodeFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsPostalCode);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsPostalCodeFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsPostalCodeFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsPostalCode) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '64';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '64', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPostalCodeFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPostalCodeFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsPostalCode | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPostalCodeFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPostalCodeFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, AiSwitchShowcaseApiValidationsPostalCodeFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, AiSwitchShowcaseApiValidationsPostalCodeFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCodeFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_POSTAL_CODE_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPostalCodeFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiTooLong : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCodeFails,
			name: 'tooLong',
			nodeName: 'VALIDATIONS_POSTAL_CODE_FAILS_TOO_LONG',
			type: PApiType.PostalCode,
			rawDataIndex: 1,
		});
	aiTooShort : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCodeFails,
			name: 'tooShort',
			nodeName: 'VALIDATIONS_POSTAL_CODE_FAILS_TOO_SHORT',
			type: PApiType.PostalCode,
			rawDataIndex: 2,
		});

	/**
	 * 
	 *
	 * @type {PostalCode}
	 */
	get tooLong() : PostalCode {
		this.getterDebugValidations(this.aiTooLong, false);
		return this.data[1];
	}

	set tooLong(v : PostalCode) {
        this.setterImpl(1, v, 'tooLong', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {PostalCode}
	 */
	get tooShort() : PostalCode {
		this.getterDebugValidations(this.aiTooShort, false);
		return this.data[2];
	}

	set tooShort(v : PostalCode) {
        this.setterImpl(2, v, 'tooShort', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '85';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '85', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsUrl extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsUrl>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsUrl> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsUrl as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrl, AiSwitchShowcaseApiValidationsUrl> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrl, AiSwitchShowcaseApiValidationsUrl>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrl,
			name: 'url',
			nodeName: 'VALIDATIONS_URL',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsUrl, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 5,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsUrlValid = new AiSwitchShowcaseApiValidationsUrlValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsUrl);
	public aiValid = this.validWrapper.aiThis;

	/**
	 * 
	 */
	get valid() : AiSwitchShowcaseApiValidationsUrlValid {
		this.getterDebugValidations(this.aiValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsUrlValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, function(this : AiSwitchShowcaseApiValidationsUrl) {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsUrlFails = new AiSwitchShowcaseApiValidationsUrlFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsUrl);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsUrlFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsUrlFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsUrl) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '65';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '65', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsUrlValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsUrlValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsUrl | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsUrlValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsUrlValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, AiSwitchShowcaseApiValidationsUrlValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, AiSwitchShowcaseApiValidationsUrlValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_URL_VALID',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsUrlValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiWithWww : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlValid,
			name: 'withWww',
			nodeName: 'VALIDATIONS_URL_VALID_WITH_WWW',
			type: PApiType.Url,
			rawDataIndex: 1,
		});
	aiWithoutWww : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlValid,
			name: 'withoutWww',
			nodeName: 'VALIDATIONS_URL_VALID_WITHOUT_WWW',
			type: PApiType.Url,
			rawDataIndex: 2,
		});

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get withWww() : Url {
		this.getterDebugValidations(this.aiWithWww, false);
		return this.data[1];
	}

	set withWww(v : Url) {
        this.setterImpl(1, v, 'withWww', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get withoutWww() : Url {
		this.getterDebugValidations(this.aiWithoutWww, false);
		return this.data[2];
	}

	set withoutWww(v : Url) {
        this.setterImpl(2, v, 'withoutWww', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '88';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '88', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsUrlFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsUrlFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsUrl | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsUrlFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsUrlFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, AiSwitchShowcaseApiValidationsUrlFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, AiSwitchShowcaseApiValidationsUrlFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_URL_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsUrlFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	aiProtocolMissingInvalidDomainFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'protocolMissingInvalidDomainFormat',
			nodeName: 'VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_INVALID_DOMAIN_FORMAT',
			type: PApiType.Url,
			rawDataIndex: 1,
		});
	aiProtocolMissingValidDomainFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'protocolMissingValidDomainFormat',
			nodeName: 'VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_VALID_DOMAIN_FORMAT',
			type: PApiType.Url,
			rawDataIndex: 2,
		});
	aiUrlIncomplete : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'urlIncomplete',
			nodeName: 'VALIDATIONS_URL_FAILS_URL_INCOMPLETE',
			type: PApiType.Url,
			rawDataIndex: 3,
		});
	aiWrongFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'wrongFormat',
			nodeName: 'VALIDATIONS_URL_FAILS_WRONG_FORMAT',
			type: PApiType.Url,
			rawDataIndex: 4,
		});
	aiStartingWww : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'startingWww',
			nodeName: 'VALIDATIONS_URL_FAILS_STARTING_WWW',
			type: PApiType.Url,
			rawDataIndex: 5,
		});
	aiWhitespace : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'whitespace',
			nodeName: 'VALIDATIONS_URL_FAILS_WHITESPACE',
			type: PApiType.Url,
			rawDataIndex: 6,
		});

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get protocolMissingInvalidDomainFormat() : Url {
		this.getterDebugValidations(this.aiProtocolMissingInvalidDomainFormat, false);
		return this.data[1];
	}

	set protocolMissingInvalidDomainFormat(v : Url) {
        this.setterImpl(1, v, 'protocolMissingInvalidDomainFormat', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get protocolMissingValidDomainFormat() : Url {
		this.getterDebugValidations(this.aiProtocolMissingValidDomainFormat, false);
		return this.data[2];
	}

	set protocolMissingValidDomainFormat(v : Url) {
        this.setterImpl(2, v, 'protocolMissingValidDomainFormat', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get urlIncomplete() : Url {
		this.getterDebugValidations(this.aiUrlIncomplete, false);
		return this.data[3];
	}

	set urlIncomplete(v : Url) {
        this.setterImpl(3, v, 'urlIncomplete', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get wrongFormat() : Url {
		this.getterDebugValidations(this.aiWrongFormat, false);
		return this.data[4];
	}

	set wrongFormat(v : Url) {
        this.setterImpl(4, v, 'wrongFormat', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get startingWww() : Url {
		this.getterDebugValidations(this.aiStartingWww, false);
		return this.data[5];
	}

	set startingWww(v : Url) {
        this.setterImpl(5, v, 'startingWww', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get whitespace() : Url {
		this.getterDebugValidations(this.aiWhitespace, false);
		return this.data[6];
	}

	set whitespace(v : Url) {
        this.setterImpl(6, v, 'whitespace', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '89';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '89', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsIban extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsIban>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsIban> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsIban as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIban, AiSwitchShowcaseApiValidationsIban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIban, AiSwitchShowcaseApiValidationsIban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIban,
			name: 'iban',
			nodeName: 'VALIDATIONS_IBAN',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsIban, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 6,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsIbanFails = new AiSwitchShowcaseApiValidationsIbanFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsIban);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsIbanFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsIbanFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsIban) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '66';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '66', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsIbanFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsIbanFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsIban | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsIbanFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsIbanFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, AiSwitchShowcaseApiValidationsIbanFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, AiSwitchShowcaseApiValidationsIbanFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIbanFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_IBAN_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsIbanFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiBadValue : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIbanFails,
			name: 'badValue',
			nodeName: 'VALIDATIONS_IBAN_FAILS_BAD_VALUE',
			type: PApiType.Iban,
			rawDataIndex: 1,
		});

	/**
	 * 
	 *
	 * @type {Iban}
	 */
	get badValue() : Iban {
		this.getterDebugValidations(this.aiBadValue, false);
		return this.data[1];
	}

	set badValue(v : Iban) {
        this.setterImpl(1, v, 'badValue', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '98';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '98', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsBic extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsBic>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsBic> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsBic as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsBic, AiSwitchShowcaseApiValidationsBic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsBic, AiSwitchShowcaseApiValidationsBic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsBic,
			name: 'bic',
			nodeName: 'VALIDATIONS_BIC',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsBic, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 7,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsBicFails = new AiSwitchShowcaseApiValidationsBicFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsBic);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsBicFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsBicFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsBic) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '67';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '67', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsBicFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsBicFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsBic | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsBicFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsBicFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, AiSwitchShowcaseApiValidationsBicFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, AiSwitchShowcaseApiValidationsBicFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsBicFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_BIC_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsBicFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiBadValue : ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsBicFails,
			name: 'badValue',
			nodeName: 'VALIDATIONS_BIC_FAILS_BAD_VALUE',
			type: PApiType.Bic,
			rawDataIndex: 1,
		});

	/**
	 * 
	 *
	 * @type {Bic}
	 */
	get badValue() : Bic {
		this.getterDebugValidations(this.aiBadValue, false);
		return this.data[1];
	}

	set badValue(v : Bic) {
        this.setterImpl(1, v, 'badValue', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '100';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '100', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsClientCurrency extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsClientCurrency>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsClientCurrency> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsClientCurrency as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrency, AiSwitchShowcaseApiValidationsClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrency, AiSwitchShowcaseApiValidationsClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsClientCurrency,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_CLIENT_CURRENCY',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsClientCurrency, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 8,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsClientCurrencyFails = new AiSwitchShowcaseApiValidationsClientCurrencyFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsClientCurrency);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsClientCurrencyFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsClientCurrencyFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsClientCurrency) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '68';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '68', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsClientCurrencyFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsClientCurrencyFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsClientCurrency | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsClientCurrencyFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsClientCurrencyFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, AiSwitchShowcaseApiValidationsClientCurrencyFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, AiSwitchShowcaseApiValidationsClientCurrencyFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsClientCurrencyFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_CLIENT_CURRENCY_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsClientCurrencyFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiTooManyDigits : ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsClientCurrencyFails,
			name: 'tooManyDigits',
			nodeName: 'VALIDATIONS_CLIENT_CURRENCY_FAILS_TOO_MANY_DIGITS',
			type: PApiType.ClientCurrency,
			rawDataIndex: 1,
		});

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get tooManyDigits() : ClientCurrency {
		this.getterDebugValidations(this.aiTooManyDigits, false);
		return this.data[1];
	}

	set tooManyDigits(v : ClientCurrency) {
        this.setterImpl(1, v, 'tooManyDigits', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '102';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '102', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsInteger extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsInteger>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsInteger> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsInteger as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsInteger, AiSwitchShowcaseApiValidationsInteger> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsInteger, AiSwitchShowcaseApiValidationsInteger>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsInteger,
			name: 'integer',
			nodeName: 'VALIDATIONS_INTEGER',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsInteger, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 9,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsIntegerFails = new AiSwitchShowcaseApiValidationsIntegerFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsInteger);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsIntegerFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsIntegerFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsInteger) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '69';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '69', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsIntegerFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsIntegerFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsInteger | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsIntegerFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsIntegerFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, AiSwitchShowcaseApiValidationsIntegerFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, AiSwitchShowcaseApiValidationsIntegerFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIntegerFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_INTEGER_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsIntegerFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiHasDecimalPlaces : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIntegerFails,
			name: 'hasDecimalPlaces',
			nodeName: 'VALIDATIONS_INTEGER_FAILS_HAS_DECIMAL_PLACES',
			type: PApiType.Hours,
			rawDataIndex: 1,
		});

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hasDecimalPlaces() : Hours {
		this.getterDebugValidations(this.aiHasDecimalPlaces, false);
		return this.data[1];
	}

	set hasDecimalPlaces(v : Hours) {
        this.setterImpl(1, v, 'hasDecimalPlaces', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '104';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '104', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsRequired extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsRequired>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsRequired> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsRequired as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequired, AiSwitchShowcaseApiValidationsRequired> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequired, AiSwitchShowcaseApiValidationsRequired>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequired,
			name: 'required',
			nodeName: 'VALIDATIONS_REQUIRED',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsRequired, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 10,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsRequiredValid = new AiSwitchShowcaseApiValidationsRequiredValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsRequired);
	public aiValid = this.validWrapper.aiThis;

	/**
	 * 
	 */
	get valid() : AiSwitchShowcaseApiValidationsRequiredValid {
		this.getterDebugValidations(this.aiValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsRequiredValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, function(this : AiSwitchShowcaseApiValidationsRequired) {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsRequiredFails = new AiSwitchShowcaseApiValidationsRequiredFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsRequired);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsRequiredFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsRequiredFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsRequired) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '70';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '70', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsRequiredValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsRequiredValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsRequired | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsRequiredValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsRequiredValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiValidationsRequiredValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiValidationsRequiredValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_REQUIRED_VALID',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsRequiredValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'number',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_NUMBER',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.number, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'integer',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_INTEGER',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_CLIENT_CURRENCY',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiString : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, string> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'string',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_STRING',
			type: PApiType.string,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiTel : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'tel',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_TEL',
			type: PApiType.Tel,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Tel, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiEmail : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'email',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_EMAIL',
			type: PApiType.Email,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiPassword : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'password',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_PASSWORD',
			type: PApiType.Password,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Password, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'postalCode',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_POSTAL_CODE',
			type: PApiType.PostalCode,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.PostalCode, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiSearch : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'search',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_SEARCH',
			type: PApiType.Search,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Search, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiUrl : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'url',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_URL',
			type: PApiType.Url,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Url, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiIban : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'iban',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_IBAN',
			type: PApiType.Iban,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Iban, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiBic : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'bic',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_BIC',
			type: PApiType.Bic,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Bic, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DATE_TIME',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.DateTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'date',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DATE_WITHOUT_TIME',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DATE_EXCLUSIVE_END',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'minutes',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_MINUTES',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Minutes, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'hours',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_HOURS',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Hours, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'days',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DAYS',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Days, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'months',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_MONTHS',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Months, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'years',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_YEARS',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Years, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'duration',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DURATION',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Duration, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'localTime',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_LOCAL_TIME',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiEnum : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'enum',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_ENUM',
			type: PApiType.Enum,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiColor : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Color> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Color>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'color',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_COLOR',
			type: PApiType.Color,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Color, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'percent',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_PERCENT',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api.validators.required(PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[1];
	}

	set number(v : number) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[2];
	}

	set integer(v : Integer) {
        this.setterImpl(2, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.data[3];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(3, v, 'clientCurrency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {string}
	 */
	get string() : string {
		this.getterDebugValidations(this.aiString, false);
		return this.data[4];
	}

	set string(v : string) {
        this.setterImpl(4, v, 'string', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Tel}
	 */
	get tel() : Tel {
		this.getterDebugValidations(this.aiTel, false);
		return this.data[5];
	}

	set tel(v : Tel) {
        this.setterImpl(5, v, 'tel', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Email}
	 */
	get email() : Email {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[6];
	}

	set email(v : Email) {
        this.setterImpl(6, v, 'email', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Password}
	 */
	get password() : Password {
		this.getterDebugValidations(this.aiPassword, false);
		return this.data[7];
	}

	set password(v : Password) {
        this.setterImpl(7, v, 'password', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {PostalCode}
	 */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.aiPostalCode, false);
		return this.data[8];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(8, v, 'postalCode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Search}
	 */
	get search() : Search {
		this.getterDebugValidations(this.aiSearch, false);
		return this.data[9];
	}

	set search(v : Search) {
        this.setterImpl(9, v, 'search', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get url() : Url {
		this.getterDebugValidations(this.aiUrl, false);
		return this.data[10];
	}

	set url(v : Url) {
        this.setterImpl(10, v, 'url', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Iban}
	 */
	get iban() : Iban {
		this.getterDebugValidations(this.aiIban, false);
		return this.data[11];
	}

	set iban(v : Iban) {
        this.setterImpl(11, v, 'iban', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Bic}
	 */
	get bic() : Bic {
		this.getterDebugValidations(this.aiBic, false);
		return this.data[12];
	}

	set bic(v : Bic) {
        this.setterImpl(12, v, 'bic', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[13];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(13, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[14];
	}

	set date(v : Date) {
        this.setterImpl(14, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[15];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(15, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[16];
	}

	set minutes(v : Minutes) {
        this.setterImpl(16, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[17];
	}

	set hours(v : Hours) {
        this.setterImpl(17, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[18];
	}

	set days(v : Days) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[19];
	}

	set months(v : Months) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[20];
	}

	set years(v : Years) {
        this.setterImpl(20, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[21];
	}

	set duration(v : Duration) {
        this.setterImpl(21, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[22];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(22, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
	 */
	get enum() : AiSwitchShowcaseApiCountry {
		this.getterDebugValidations(this.aiEnum, false);
		return this.data[23];
	}

	set enum(v : AiSwitchShowcaseApiCountry) {
        this.setterImpl(23, v, 'enum', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Color}
	 */
	get color() : Color {
		this.getterDebugValidations(this.aiColor, false);
		return this.data[24];
	}

	set color(v : Color) {
        this.setterImpl(24, v, 'color', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[25];
	}

	set percent(v : Percent) {
        this.setterImpl(25, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '106';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '106', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsRequiredFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsRequiredFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsRequired | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsRequiredFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsRequiredFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiValidationsRequiredFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiValidationsRequiredFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsRequiredFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'number',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_NUMBER',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.number, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(2, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'integer',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_INTEGER',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Integer, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_CLIENT_CURRENCY',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiString : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, string> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'string',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_STRING',
			type: PApiType.string,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiTel : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'tel',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_TEL',
			type: PApiType.Tel,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Tel, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiEmail : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'email',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_EMAIL',
			type: PApiType.Email,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiPassword : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'password',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_PASSWORD',
			type: PApiType.Password,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Password, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'postalCode',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_POSTAL_CODE',
			type: PApiType.PostalCode,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.PostalCode, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiSearch : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'search',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_SEARCH',
			type: PApiType.Search,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Search, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiUrl : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'url',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_URL',
			type: PApiType.Url,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Url, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiIban : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'iban',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_IBAN',
			type: PApiType.Iban,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Iban, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiBic : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'bic',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_BIC',
			type: PApiType.Bic,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Bic, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DATE_TIME',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.DateTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'date',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DATE_WITHOUT_TIME',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DATE_EXCLUSIVE_END',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.DateExclusiveEnd, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'minutes',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_MINUTES',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Minutes, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'hours',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_HOURS',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Hours, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'days',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DAYS',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Days, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'months',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_MONTHS',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Months, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'years',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_YEARS',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Years, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'duration',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DURATION',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Duration, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'localTime',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_LOCAL_TIME',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiEnum : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'enum',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_ENUM',
			type: PApiType.Enum,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiImage : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Image> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Image>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'image',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_IMAGE',
			type: PApiType.Image,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Image, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiPdf : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Pdf> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Pdf>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'pdf',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_PDF',
			type: PApiType.Pdf,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Pdf, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'percent',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_PERCENT',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api.validators.required(PApiType.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number | null {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[1];
	}

	set number(v : number | null) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer | null {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[2];
	}

	set integer(v : Integer | null) {
        this.setterImpl(2, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrency() : ClientCurrency | null {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.data[3];
	}

	set clientCurrency(v : ClientCurrency | null) {
        this.setterImpl(3, v, 'clientCurrency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {string}
	 */
	get string() : string {
		this.getterDebugValidations(this.aiString, false);
		return this.data[4];
	}

	set string(v : string) {
        this.setterImpl(4, v, 'string', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Tel}
	 */
	get tel() : Tel {
		this.getterDebugValidations(this.aiTel, false);
		return this.data[5];
	}

	set tel(v : Tel) {
        this.setterImpl(5, v, 'tel', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Email}
	 */
	get email() : Email {
		this.getterDebugValidations(this.aiEmail, false);
		return this.data[6];
	}

	set email(v : Email) {
        this.setterImpl(6, v, 'email', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Password}
	 */
	get password() : Password {
		this.getterDebugValidations(this.aiPassword, false);
		return this.data[7];
	}

	set password(v : Password) {
        this.setterImpl(7, v, 'password', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {PostalCode}
	 */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.aiPostalCode, false);
		return this.data[8];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(8, v, 'postalCode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Search}
	 */
	get search() : Search {
		this.getterDebugValidations(this.aiSearch, false);
		return this.data[9];
	}

	set search(v : Search) {
        this.setterImpl(9, v, 'search', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Url}
	 */
	get url() : Url {
		this.getterDebugValidations(this.aiUrl, false);
		return this.data[10];
	}

	set url(v : Url) {
        this.setterImpl(10, v, 'url', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Iban}
	 */
	get iban() : Iban {
		this.getterDebugValidations(this.aiIban, false);
		return this.data[11];
	}

	set iban(v : Iban) {
        this.setterImpl(11, v, 'iban', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Bic}
	 */
	get bic() : Bic {
		this.getterDebugValidations(this.aiBic, false);
		return this.data[12];
	}

	set bic(v : Bic) {
        this.setterImpl(12, v, 'bic', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime | null {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[13];
	}

	set dateTime(v : DateTime | null) {
        this.setterImpl(13, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date | null {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[14];
	}

	set date(v : Date | null) {
        this.setterImpl(14, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[15];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd | null) {
        this.setterImpl(15, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes | null {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[16];
	}

	set minutes(v : Minutes | null) {
        this.setterImpl(16, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours | null {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[17];
	}

	set hours(v : Hours | null) {
        this.setterImpl(17, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days | null {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[18];
	}

	set days(v : Days | null) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months | null {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[19];
	}

	set months(v : Months | null) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years | null {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[20];
	}

	set years(v : Years | null) {
        this.setterImpl(20, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration | null {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[21];
	}

	set duration(v : Duration | null) {
        this.setterImpl(21, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime | null {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[22];
	}

	set localTime(v : LocalTime | null) {
        this.setterImpl(22, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
	 */
	get enum() : AiSwitchShowcaseApiCountry | null {
		this.getterDebugValidations(this.aiEnum, false);
		return this.data[23];
	}

	set enum(v : AiSwitchShowcaseApiCountry | null) {
        this.setterImpl(23, v, 'enum', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Image}
	 */
	get image() : Image | null {
		this.getterDebugValidations(this.aiImage, false);
		const path = this.data[24] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set image(v : Image | null) {
        this.setterImpl(24, v, 'image', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Pdf}
	 */
	get pdf() : Pdf | null {
		this.getterDebugValidations(this.aiPdf, false);
		const path = this.data[25] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set pdf(v : Pdf | null) {
        this.setterImpl(25, v, 'pdf', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent | null {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[26];
	}

	set percent(v : Percent | null) {
        this.setterImpl(26, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '107';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '107', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMin extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMin>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMin> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMin as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMin, AiSwitchShowcaseApiValidationsMin> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMin, AiSwitchShowcaseApiValidationsMin>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMin,
			name: 'min',
			nodeName: 'VALIDATIONS_MIN',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMin, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 11,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsMinValid = new AiSwitchShowcaseApiValidationsMinValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMin);
	public aiValid = this.validWrapper.aiThis;

	/**
	 * 
	 */
	get valid() : AiSwitchShowcaseApiValidationsMinValid {
		this.getterDebugValidations(this.aiValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsMinValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, function(this : AiSwitchShowcaseApiValidationsMin) {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsMinFails = new AiSwitchShowcaseApiValidationsMinFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMin);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsMinFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMinFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsMin) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '71';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '71', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMin | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValid, AiSwitchShowcaseApiValidationsMinValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValid, AiSwitchShowcaseApiValidationsMinValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_MIN_VALID',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMinValidCompareConst = new AiSwitchShowcaseApiValidationsMinValidCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMinValid);
	public aiCompareConst = this.compareConstWrapper.aiThis;

	/**
	 * 
	 */
	get compareConst() : AiSwitchShowcaseApiValidationsMinValidCompareConst {
		this.getterDebugValidations(this.aiCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, function(this : AiSwitchShowcaseApiValidationsMinValid) {this.compareConstWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '159';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '159', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinValidCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinValidCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMinValid | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinValidCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinValidCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, AiSwitchShowcaseApiValidationsMinValidCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, AiSwitchShowcaseApiValidationsMinValidCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiNumberIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'numberIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_IS_EQUAL',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiNumberThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'numberThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiNumberOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'numberOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiIntegerIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'integerIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_IS_EQUAL',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiIntegerThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'integerThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiIntegerOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'integerOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiClientCurrencyIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'clientCurrencyIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiClientCurrencyThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'clientCurrencyThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiClientCurrencyOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'clientCurrencyOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiDateTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateTimeIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(1577833200000, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiDateTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateTimeThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(1577833200000, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiDateTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiDateIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_IS_EQUAL',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(1577833200000, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiDateThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_THIS_IS_NULL',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(1577833200000, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiDateOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiDateExclusiveEndIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateExclusiveEndIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(1577833200000, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiDateExclusiveEndThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateExclusiveEndThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(1577833200000, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	aiDateExclusiveEndOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateExclusiveEndOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMinutesIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'minutesIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_IS_EQUAL',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiMinutesThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'minutesThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiMinutesOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'minutesOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiHoursIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'hoursIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_IS_EQUAL',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiHoursThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'hoursThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiHoursOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'hoursOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiDaysIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'daysIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_IS_EQUAL',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiDaysThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'daysThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiDaysOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'daysOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	aiMonthsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'monthsIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_IS_EQUAL',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});
	aiMonthsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'monthsThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});
	aiMonthsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'monthsOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 30,
		});
	aiYearsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'yearsIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_IS_EQUAL',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 31,
		});
	aiYearsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'yearsThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 32,
		});
	aiYearsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'yearsOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 33,
		});
	aiDurationIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'durationIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_IS_EQUAL',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 34,
		});
	aiDurationThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'durationThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 35,
		});
	aiDurationOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'durationOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 36,
		});
	aiLocalTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'localTimeIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(3600000, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 37,
		});
	aiLocalTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'localTimeThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(3600000, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 38,
		});
	aiLocalTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'localTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 39,
		});
	aiPercentIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'percentIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_IS_EQUAL',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(0.5, true, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 40,
		});
	aiPercentThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'percentThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(0.7, true, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 41,
		});
	aiPercentOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'percentOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 42,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberIsEqual() : number {
		this.getterDebugValidations(this.aiNumberIsEqual, false);
		return this.data[1];
	}

	set numberIsEqual(v : number) {
        this.setterImpl(1, v, 'numberIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberThisIsNull() : number | null {
		this.getterDebugValidations(this.aiNumberThisIsNull, false);
		return this.data[2];
	}

	set numberThisIsNull(v : number | null) {
        this.setterImpl(2, v, 'numberThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberOtherIsNull() : number {
		this.getterDebugValidations(this.aiNumberOtherIsNull, false);
		return this.data[3];
	}

	set numberOtherIsNull(v : number) {
        this.setterImpl(3, v, 'numberOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerIsEqual() : Integer {
		this.getterDebugValidations(this.aiIntegerIsEqual, false);
		return this.data[4];
	}

	set integerIsEqual(v : Integer) {
        this.setterImpl(4, v, 'integerIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerThisIsNull() : Integer | null {
		this.getterDebugValidations(this.aiIntegerThisIsNull, false);
		return this.data[5];
	}

	set integerThisIsNull(v : Integer | null) {
        this.setterImpl(5, v, 'integerThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerOtherIsNull() : Integer {
		this.getterDebugValidations(this.aiIntegerOtherIsNull, false);
		return this.data[6];
	}

	set integerOtherIsNull(v : Integer) {
        this.setterImpl(6, v, 'integerOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyIsEqual() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrencyIsEqual, false);
		return this.data[7];
	}

	set clientCurrencyIsEqual(v : ClientCurrency) {
        this.setterImpl(7, v, 'clientCurrencyIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyThisIsNull() : ClientCurrency | null {
		this.getterDebugValidations(this.aiClientCurrencyThisIsNull, false);
		return this.data[8];
	}

	set clientCurrencyThisIsNull(v : ClientCurrency | null) {
        this.setterImpl(8, v, 'clientCurrencyThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyOtherIsNull() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrencyOtherIsNull, false);
		return this.data[9];
	}

	set clientCurrencyOtherIsNull(v : ClientCurrency) {
        this.setterImpl(9, v, 'clientCurrencyOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeIsEqual() : DateTime {
		this.getterDebugValidations(this.aiDateTimeIsEqual, false);
		return this.data[10];
	}

	set dateTimeIsEqual(v : DateTime) {
        this.setterImpl(10, v, 'dateTimeIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeThisIsNull() : DateTime | null {
		this.getterDebugValidations(this.aiDateTimeThisIsNull, false);
		return this.data[11];
	}

	set dateTimeThisIsNull(v : DateTime | null) {
        this.setterImpl(11, v, 'dateTimeThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeOtherIsNull() : DateTime {
		this.getterDebugValidations(this.aiDateTimeOtherIsNull, false);
		return this.data[12];
	}

	set dateTimeOtherIsNull(v : DateTime) {
        this.setterImpl(12, v, 'dateTimeOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateIsEqual() : Date {
		this.getterDebugValidations(this.aiDateIsEqual, false);
		return this.data[13];
	}

	set dateIsEqual(v : Date) {
        this.setterImpl(13, v, 'dateIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateThisIsNull() : Date | null {
		this.getterDebugValidations(this.aiDateThisIsNull, false);
		return this.data[14];
	}

	set dateThisIsNull(v : Date | null) {
        this.setterImpl(14, v, 'dateThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateOtherIsNull() : Date {
		this.getterDebugValidations(this.aiDateOtherIsNull, false);
		return this.data[15];
	}

	set dateOtherIsNull(v : Date) {
        this.setterImpl(15, v, 'dateOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndIsEqual() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndIsEqual, false);
		return this.data[16];
	}

	set dateExclusiveEndIsEqual(v : DateExclusiveEnd) {
        this.setterImpl(16, v, 'dateExclusiveEndIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndThisIsNull() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiDateExclusiveEndThisIsNull, false);
		return this.data[17];
	}

	set dateExclusiveEndThisIsNull(v : DateExclusiveEnd | null) {
        this.setterImpl(17, v, 'dateExclusiveEndThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndOtherIsNull() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndOtherIsNull, false);
		return this.data[18];
	}

	set dateExclusiveEndOtherIsNull(v : DateExclusiveEnd) {
        this.setterImpl(18, v, 'dateExclusiveEndOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesIsEqual() : Minutes {
		this.getterDebugValidations(this.aiMinutesIsEqual, false);
		return this.data[19];
	}

	set minutesIsEqual(v : Minutes) {
        this.setterImpl(19, v, 'minutesIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesThisIsNull() : Minutes | null {
		this.getterDebugValidations(this.aiMinutesThisIsNull, false);
		return this.data[20];
	}

	set minutesThisIsNull(v : Minutes | null) {
        this.setterImpl(20, v, 'minutesThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesOtherIsNull() : Minutes {
		this.getterDebugValidations(this.aiMinutesOtherIsNull, false);
		return this.data[21];
	}

	set minutesOtherIsNull(v : Minutes) {
        this.setterImpl(21, v, 'minutesOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursIsEqual() : Hours {
		this.getterDebugValidations(this.aiHoursIsEqual, false);
		return this.data[22];
	}

	set hoursIsEqual(v : Hours) {
        this.setterImpl(22, v, 'hoursIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursThisIsNull() : Hours | null {
		this.getterDebugValidations(this.aiHoursThisIsNull, false);
		return this.data[23];
	}

	set hoursThisIsNull(v : Hours | null) {
        this.setterImpl(23, v, 'hoursThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursOtherIsNull() : Hours {
		this.getterDebugValidations(this.aiHoursOtherIsNull, false);
		return this.data[24];
	}

	set hoursOtherIsNull(v : Hours) {
        this.setterImpl(24, v, 'hoursOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysIsEqual() : Days {
		this.getterDebugValidations(this.aiDaysIsEqual, false);
		return this.data[25];
	}

	set daysIsEqual(v : Days) {
        this.setterImpl(25, v, 'daysIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysThisIsNull() : Days | null {
		this.getterDebugValidations(this.aiDaysThisIsNull, false);
		return this.data[26];
	}

	set daysThisIsNull(v : Days | null) {
        this.setterImpl(26, v, 'daysThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysOtherIsNull() : Days {
		this.getterDebugValidations(this.aiDaysOtherIsNull, false);
		return this.data[27];
	}

	set daysOtherIsNull(v : Days) {
        this.setterImpl(27, v, 'daysOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsIsEqual() : Months {
		this.getterDebugValidations(this.aiMonthsIsEqual, false);
		return this.data[28];
	}

	set monthsIsEqual(v : Months) {
        this.setterImpl(28, v, 'monthsIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsThisIsNull() : Months | null {
		this.getterDebugValidations(this.aiMonthsThisIsNull, false);
		return this.data[29];
	}

	set monthsThisIsNull(v : Months | null) {
        this.setterImpl(29, v, 'monthsThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsOtherIsNull() : Months {
		this.getterDebugValidations(this.aiMonthsOtherIsNull, false);
		return this.data[30];
	}

	set monthsOtherIsNull(v : Months) {
        this.setterImpl(30, v, 'monthsOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsIsEqual() : Years {
		this.getterDebugValidations(this.aiYearsIsEqual, false);
		return this.data[31];
	}

	set yearsIsEqual(v : Years) {
        this.setterImpl(31, v, 'yearsIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsThisIsNull() : Years | null {
		this.getterDebugValidations(this.aiYearsThisIsNull, false);
		return this.data[32];
	}

	set yearsThisIsNull(v : Years | null) {
        this.setterImpl(32, v, 'yearsThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsOtherIsNull() : Years {
		this.getterDebugValidations(this.aiYearsOtherIsNull, false);
		return this.data[33];
	}

	set yearsOtherIsNull(v : Years) {
        this.setterImpl(33, v, 'yearsOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationIsEqual() : Duration {
		this.getterDebugValidations(this.aiDurationIsEqual, false);
		return this.data[34];
	}

	set durationIsEqual(v : Duration) {
        this.setterImpl(34, v, 'durationIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationThisIsNull() : Duration | null {
		this.getterDebugValidations(this.aiDurationThisIsNull, false);
		return this.data[35];
	}

	set durationThisIsNull(v : Duration | null) {
        this.setterImpl(35, v, 'durationThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationOtherIsNull() : Duration {
		this.getterDebugValidations(this.aiDurationOtherIsNull, false);
		return this.data[36];
	}

	set durationOtherIsNull(v : Duration) {
        this.setterImpl(36, v, 'durationOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeIsEqual() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeIsEqual, false);
		return this.data[37];
	}

	set localTimeIsEqual(v : LocalTime) {
        this.setterImpl(37, v, 'localTimeIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeThisIsNull() : LocalTime | null {
		this.getterDebugValidations(this.aiLocalTimeThisIsNull, false);
		return this.data[38];
	}

	set localTimeThisIsNull(v : LocalTime | null) {
        this.setterImpl(38, v, 'localTimeThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeOtherIsNull() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeOtherIsNull, false);
		return this.data[39];
	}

	set localTimeOtherIsNull(v : LocalTime) {
        this.setterImpl(39, v, 'localTimeOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percentIsEqual() : Percent {
		this.getterDebugValidations(this.aiPercentIsEqual, false);
		return this.data[40];
	}

	set percentIsEqual(v : Percent) {
        this.setterImpl(40, v, 'percentIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percentThisIsNull() : Percent | null {
		this.getterDebugValidations(this.aiPercentThisIsNull, false);
		return this.data[41];
	}

	set percentThisIsNull(v : Percent | null) {
        this.setterImpl(41, v, 'percentThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percentOtherIsNull() : Percent {
		this.getterDebugValidations(this.aiPercentOtherIsNull, false);
		return this.data[42];
	}

	set percentOtherIsNull(v : Percent) {
        this.setterImpl(42, v, 'percentOtherIsNull', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '161';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '161', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMin | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFails, AiSwitchShowcaseApiValidationsMinFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFails, AiSwitchShowcaseApiValidationsMinFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MIN_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMinFailsCompareConst = new AiSwitchShowcaseApiValidationsMinFailsCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMinFails);
	public aiCompareConst = this.compareConstWrapper.aiThis;

	/**
	 * 
	 */
	get compareConst() : AiSwitchShowcaseApiValidationsMinFailsCompareConst {
		this.getterDebugValidations(this.aiCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, function(this : AiSwitchShowcaseApiValidationsMinFails) {this.compareConstWrapper = v;});
	}

	private compareNodeWrapper : AiSwitchShowcaseApiValidationsMinFailsCompareNode = new AiSwitchShowcaseApiValidationsMinFailsCompareNode(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMinFails);
	public aiCompareNode = this.compareNodeWrapper.aiThis;

	/**
	 * 
	 */
	get compareNode() : AiSwitchShowcaseApiValidationsMinFailsCompareNode {
		this.getterDebugValidations(this.aiCompareNode, false);
		return this.compareNodeWrapper;
	}

	set compareNodeTestSetter(v : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
        this.setterImpl(2, v.rawData, 'compareNode', true, null, function(this : AiSwitchShowcaseApiValidationsMinFails) {this.compareNodeWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
		this.compareNodeWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.compareNodeWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '160';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '160', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinFailsCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinFailsCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMinFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinFailsCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinFailsCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, AiSwitchShowcaseApiValidationsMinFailsCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, AiSwitchShowcaseApiValidationsMinFailsCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'number',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7.5, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(3, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiNumberEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'numberEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(5, false, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'integer',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiIntegerEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'integerEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, false, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiClientCurrencyEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'clientCurrencyEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, false, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(1605135600000, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiDateTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(1605135600000, false, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'date',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(1605135600000, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiDateEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(1605135600000, false, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(1605135600000, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiDateExclusiveEndEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateExclusiveEndEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(1605135600000, false, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiMinutesEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'minutesEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, false, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'hours',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiHoursEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'hoursEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, false, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'days',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	aiDaysEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'daysEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, false, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'months',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiMonthsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'monthsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(5, false, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'years',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(7, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiYearsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'yearsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(5, false, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'duration',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(604800000, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiDurationEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'durationEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(60000, false, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiDurationEqualNotAllowedOtherIs0 : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'durationEqualNotAllowedOtherIs0',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED_MINIMUM_IS_0',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(0, false, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(3600000, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiLocalTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'localTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(3600000, false, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'percent',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(0.7, true, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});
	aiPercentEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'percentEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api.validators.min(0.5, false, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[1];
	}

	set number(v : number) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberEqualNotAllowed() : number {
		this.getterDebugValidations(this.aiNumberEqualNotAllowed, false);
		return this.data[2];
	}

	set numberEqualNotAllowed(v : number) {
        this.setterImpl(2, v, 'numberEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[3];
	}

	set integer(v : Integer) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerEqualNotAllowed() : Integer {
		this.getterDebugValidations(this.aiIntegerEqualNotAllowed, false);
		return this.data[4];
	}

	set integerEqualNotAllowed(v : Integer) {
        this.setterImpl(4, v, 'integerEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.data[5];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(5, v, 'clientCurrency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyEqualNotAllowed() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrencyEqualNotAllowed, false);
		return this.data[6];
	}

	set clientCurrencyEqualNotAllowed(v : ClientCurrency) {
        this.setterImpl(6, v, 'clientCurrencyEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[7];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(7, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeEqualNotAllowed() : DateTime {
		this.getterDebugValidations(this.aiDateTimeEqualNotAllowed, false);
		return this.data[8];
	}

	set dateTimeEqualNotAllowed(v : DateTime) {
        this.setterImpl(8, v, 'dateTimeEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[9];
	}

	set date(v : Date) {
        this.setterImpl(9, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateEqualNotAllowed() : Date {
		this.getterDebugValidations(this.aiDateEqualNotAllowed, false);
		return this.data[10];
	}

	set dateEqualNotAllowed(v : Date) {
        this.setterImpl(10, v, 'dateEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[11];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndEqualNotAllowed() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndEqualNotAllowed, false);
		return this.data[12];
	}

	set dateExclusiveEndEqualNotAllowed(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEndEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[13];
	}

	set minutes(v : Minutes) {
        this.setterImpl(13, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesEqualNotAllowed() : Minutes {
		this.getterDebugValidations(this.aiMinutesEqualNotAllowed, false);
		return this.data[14];
	}

	set minutesEqualNotAllowed(v : Minutes) {
        this.setterImpl(14, v, 'minutesEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[15];
	}

	set hours(v : Hours) {
        this.setterImpl(15, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursEqualNotAllowed() : Hours {
		this.getterDebugValidations(this.aiHoursEqualNotAllowed, false);
		return this.data[16];
	}

	set hoursEqualNotAllowed(v : Hours) {
        this.setterImpl(16, v, 'hoursEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[17];
	}

	set days(v : Days) {
        this.setterImpl(17, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysEqualNotAllowed() : Days {
		this.getterDebugValidations(this.aiDaysEqualNotAllowed, false);
		return this.data[18];
	}

	set daysEqualNotAllowed(v : Days) {
        this.setterImpl(18, v, 'daysEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[19];
	}

	set months(v : Months) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsEqualNotAllowed() : Months {
		this.getterDebugValidations(this.aiMonthsEqualNotAllowed, false);
		return this.data[20];
	}

	set monthsEqualNotAllowed(v : Months) {
        this.setterImpl(20, v, 'monthsEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[21];
	}

	set years(v : Years) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsEqualNotAllowed() : Years {
		this.getterDebugValidations(this.aiYearsEqualNotAllowed, false);
		return this.data[22];
	}

	set yearsEqualNotAllowed(v : Years) {
        this.setterImpl(22, v, 'yearsEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[23];
	}

	set duration(v : Duration) {
        this.setterImpl(23, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationEqualNotAllowed() : Duration {
		this.getterDebugValidations(this.aiDurationEqualNotAllowed, false);
		return this.data[24];
	}

	set durationEqualNotAllowed(v : Duration) {
        this.setterImpl(24, v, 'durationEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationEqualNotAllowedOtherIs0() : Duration {
		this.getterDebugValidations(this.aiDurationEqualNotAllowedOtherIs0, false);
		return this.data[25];
	}

	set durationEqualNotAllowedOtherIs0(v : Duration) {
        this.setterImpl(25, v, 'durationEqualNotAllowedOtherIs0', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[26];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(26, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeEqualNotAllowed() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeEqualNotAllowed, false);
		return this.data[27];
	}

	set localTimeEqualNotAllowed(v : LocalTime) {
        this.setterImpl(27, v, 'localTimeEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[28];
	}

	set percent(v : Percent) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percentEqualNotAllowed() : Percent {
		this.getterDebugValidations(this.aiPercentEqualNotAllowed, false);
		return this.data[29];
	}

	set percentEqualNotAllowed(v : Percent) {
        this.setterImpl(29, v, 'percentEqualNotAllowed', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '204';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '204', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinFailsCompareNode extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinFailsCompareNode>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMinFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinFailsCompareNode> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinFailsCompareNode as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, AiSwitchShowcaseApiValidationsMinFailsCompareNode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, AiSwitchShowcaseApiValidationsMinFailsCompareNode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'compareNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	aiNumberComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'numberComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_B',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'number',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_A',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.numberComparedNode, true, PApiType.number, this.aiNumberComparedNode.id, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(0, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiIntegerComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'integerComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_B',
			type: PApiType.Integer,
			rawDataIndex: 3,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'integer',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_A',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.integerComparedNode, true, PApiType.Integer, this.aiIntegerComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiClientCurrencyComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'clientCurrencyComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B',
			type: PApiType.ClientCurrency,
			rawDataIndex: 5,
		});
	aiClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.clientCurrencyComparedNode, true, PApiType.ClientCurrency, this.aiClientCurrencyComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiDateTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateTimeComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_B',
			type: PApiType.DateTime,
			rawDataIndex: 7,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_A',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.dateTimeComparedNode, true, PApiType.DateTime, this.aiDateTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiDateComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B',
			type: PApiType.Date,
			rawDataIndex: 9,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'date',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.dateComparedNode, true, PApiType.Date, this.aiDateComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiDateExclusiveEndComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateExclusiveEndComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B',
			type: PApiType.DateExclusiveEnd,
			rawDataIndex: 11,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.dateExclusiveEndComparedNode, true, PApiType.DateExclusiveEnd, this.aiDateExclusiveEndComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiMinutesComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'minutesComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_B',
			type: PApiType.Minutes,
			rawDataIndex: 13,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_A',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.minutesComparedNode, true, PApiType.Minutes, this.aiMinutesComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiHoursComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'hoursComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_B',
			type: PApiType.Hours,
			rawDataIndex: 15,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'hours',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_A',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.hoursComparedNode, true, PApiType.Hours, this.aiHoursComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiDaysComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'daysComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_B',
			type: PApiType.Days,
			rawDataIndex: 17,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'days',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_A',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.daysComparedNode, true, PApiType.Days, this.aiDaysComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMonthsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'monthsComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_B',
			type: PApiType.Months,
			rawDataIndex: 19,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'months',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_A',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.monthsComparedNode, true, PApiType.Months, this.aiMonthsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiYearsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'yearsComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_B',
			type: PApiType.Years,
			rawDataIndex: 21,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'years',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_A',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.yearsComparedNode, true, PApiType.Years, this.aiYearsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiDurationComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'durationComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_B',
			type: PApiType.Duration,
			rawDataIndex: 23,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'duration',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_A',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.durationComparedNode, true, PApiType.Duration, this.aiDurationComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiLocalTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'localTimeComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_B',
			type: PApiType.LocalTime,
			rawDataIndex: 25,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_A',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.localTimeComparedNode, true, PApiType.LocalTime, this.aiLocalTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiPercentComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'percentComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_B',
			type: PApiType.Percent,
			rawDataIndex: 27,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'percent',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_A',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api.validators.min(() => this.percentComparedNode, true, PApiType.Percent, this.aiPercentComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberComparedNode() : number {
		this.getterDebugValidations(this.aiNumberComparedNode, false);
		return this.data[1];
	}

	set numberComparedNode(v : number) {
        this.setterImpl(1, v, 'numberComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[2];
	}

	set number(v : number) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerComparedNode() : Integer {
		this.getterDebugValidations(this.aiIntegerComparedNode, false);
		return this.data[3];
	}

	set integerComparedNode(v : Integer) {
        this.setterImpl(3, v, 'integerComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[4];
	}

	set integer(v : Integer) {
        this.setterImpl(4, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyComparedNode() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrencyComparedNode, false);
		return this.data[5];
	}

	set clientCurrencyComparedNode(v : ClientCurrency) {
        this.setterImpl(5, v, 'clientCurrencyComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.data[6];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(6, v, 'clientCurrency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeComparedNode() : DateTime {
		this.getterDebugValidations(this.aiDateTimeComparedNode, false);
		return this.data[7];
	}

	set dateTimeComparedNode(v : DateTime) {
        this.setterImpl(7, v, 'dateTimeComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[8];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(8, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateComparedNode() : Date {
		this.getterDebugValidations(this.aiDateComparedNode, false);
		return this.data[9];
	}

	set dateComparedNode(v : Date) {
        this.setterImpl(9, v, 'dateComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[10];
	}

	set date(v : Date) {
        this.setterImpl(10, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndComparedNode() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndComparedNode, false);
		return this.data[11];
	}

	set dateExclusiveEndComparedNode(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEndComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[12];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesComparedNode() : Minutes {
		this.getterDebugValidations(this.aiMinutesComparedNode, false);
		return this.data[13];
	}

	set minutesComparedNode(v : Minutes) {
        this.setterImpl(13, v, 'minutesComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[14];
	}

	set minutes(v : Minutes) {
        this.setterImpl(14, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursComparedNode() : Hours {
		this.getterDebugValidations(this.aiHoursComparedNode, false);
		return this.data[15];
	}

	set hoursComparedNode(v : Hours) {
        this.setterImpl(15, v, 'hoursComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[16];
	}

	set hours(v : Hours) {
        this.setterImpl(16, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysComparedNode() : Days {
		this.getterDebugValidations(this.aiDaysComparedNode, false);
		return this.data[17];
	}

	set daysComparedNode(v : Days) {
        this.setterImpl(17, v, 'daysComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[18];
	}

	set days(v : Days) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsComparedNode() : Months {
		this.getterDebugValidations(this.aiMonthsComparedNode, false);
		return this.data[19];
	}

	set monthsComparedNode(v : Months) {
        this.setterImpl(19, v, 'monthsComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[20];
	}

	set months(v : Months) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsComparedNode() : Years {
		this.getterDebugValidations(this.aiYearsComparedNode, false);
		return this.data[21];
	}

	set yearsComparedNode(v : Years) {
        this.setterImpl(21, v, 'yearsComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[22];
	}

	set years(v : Years) {
        this.setterImpl(22, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationComparedNode() : Duration {
		this.getterDebugValidations(this.aiDurationComparedNode, false);
		return this.data[23];
	}

	set durationComparedNode(v : Duration) {
        this.setterImpl(23, v, 'durationComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[24];
	}

	set duration(v : Duration) {
        this.setterImpl(24, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeComparedNode() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeComparedNode, false);
		return this.data[25];
	}

	set localTimeComparedNode(v : LocalTime) {
        this.setterImpl(25, v, 'localTimeComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[26];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(26, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percentComparedNode() : Percent {
		this.getterDebugValidations(this.aiPercentComparedNode, false);
		return this.data[27];
	}

	set percentComparedNode(v : Percent) {
        this.setterImpl(27, v, 'percentComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[28];
	}

	set percent(v : Percent) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '205';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '205', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMax extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMax>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMax> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMax as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMax, AiSwitchShowcaseApiValidationsMax> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMax, AiSwitchShowcaseApiValidationsMax>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMax,
			name: 'max',
			nodeName: 'VALIDATIONS_MAX',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMax, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 12,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsMaxValid = new AiSwitchShowcaseApiValidationsMaxValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMax);
	public aiValid = this.validWrapper.aiThis;

	/**
	 * 
	 */
	get valid() : AiSwitchShowcaseApiValidationsMaxValid {
		this.getterDebugValidations(this.aiValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsMaxValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, function(this : AiSwitchShowcaseApiValidationsMax) {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsMaxFails = new AiSwitchShowcaseApiValidationsMaxFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMax);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsMaxFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMaxFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsMax) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '72';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '72', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMax | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValid, AiSwitchShowcaseApiValidationsMaxValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValid, AiSwitchShowcaseApiValidationsMaxValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_MAX_VALID',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMaxValidCompareConst = new AiSwitchShowcaseApiValidationsMaxValidCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxValid);
	public aiCompareConst = this.compareConstWrapper.aiThis;

	/**
	 * 
	 */
	get compareConst() : AiSwitchShowcaseApiValidationsMaxValidCompareConst {
		this.getterDebugValidations(this.aiCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, function(this : AiSwitchShowcaseApiValidationsMaxValid) {this.compareConstWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '263';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '263', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxValidCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxValidCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxValid | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxValidCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxValidCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, AiSwitchShowcaseApiValidationsMaxValidCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, AiSwitchShowcaseApiValidationsMaxValidCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiNumberIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'numberIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_IS_EQUAL',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiNumberThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'numberThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiNumberOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'numberOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiIntegerIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'integerIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_IS_EQUAL',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiIntegerThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'integerThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiIntegerOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'integerOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiClientCurrencyIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'clientCurrencyIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiClientCurrencyThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'clientCurrencyThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.min(5, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiClientCurrencyOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'clientCurrencyOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.min(null, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiDateTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateTimeIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(1577919600000, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiDateTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateTimeThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(1577919600000, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiDateTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiDateIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_IS_EQUAL',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(1577919600000, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiDateThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_THIS_IS_NULL',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(1577919600000, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiDateOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiDateExclusiveEndIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateExclusiveEndIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(1577919600000, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiDateExclusiveEndThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateExclusiveEndThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(1577919600000, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	aiDateExclusiveEndOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateExclusiveEndOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMinutesIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'minutesIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_IS_EQUAL',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiMinutesThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'minutesThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiMinutesOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'minutesOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiHoursIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'hoursIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_IS_EQUAL',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiHoursThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'hoursThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiHoursOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'hoursOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiDaysIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'daysIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_IS_EQUAL',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiDaysThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'daysThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiDaysOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'daysOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	aiMonthsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'monthsIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_IS_EQUAL',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});
	aiMonthsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'monthsThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});
	aiMonthsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'monthsOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 30,
		});
	aiYearsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'yearsIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_IS_EQUAL',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 31,
		});
	aiYearsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'yearsThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 32,
		});
	aiYearsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'yearsOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 33,
		});
	aiDurationIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'durationIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_IS_EQUAL',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 34,
		});
	aiDurationThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'durationThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(5, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 35,
		});
	aiDurationOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'durationOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 36,
		});
	aiLocalTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'localTimeIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(3600000, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 37,
		});
	aiLocalTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'localTimeThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(3600000, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 38,
		});
	aiLocalTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'localTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 39,
		});
	aiPercentIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'percentIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_IS_EQUAL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(0.5, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 40,
		});
	aiPercentThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'percentThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(0.5, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 41,
		});
	aiPercentOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'percentOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api.validators.max(null, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 42,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberIsEqual() : number {
		this.getterDebugValidations(this.aiNumberIsEqual, false);
		return this.data[1];
	}

	set numberIsEqual(v : number) {
        this.setterImpl(1, v, 'numberIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberThisIsNull() : number | null {
		this.getterDebugValidations(this.aiNumberThisIsNull, false);
		return this.data[2];
	}

	set numberThisIsNull(v : number | null) {
        this.setterImpl(2, v, 'numberThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberOtherIsNull() : number {
		this.getterDebugValidations(this.aiNumberOtherIsNull, false);
		return this.data[3];
	}

	set numberOtherIsNull(v : number) {
        this.setterImpl(3, v, 'numberOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerIsEqual() : Integer {
		this.getterDebugValidations(this.aiIntegerIsEqual, false);
		return this.data[4];
	}

	set integerIsEqual(v : Integer) {
        this.setterImpl(4, v, 'integerIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerThisIsNull() : Integer | null {
		this.getterDebugValidations(this.aiIntegerThisIsNull, false);
		return this.data[5];
	}

	set integerThisIsNull(v : Integer | null) {
        this.setterImpl(5, v, 'integerThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerOtherIsNull() : Integer {
		this.getterDebugValidations(this.aiIntegerOtherIsNull, false);
		return this.data[6];
	}

	set integerOtherIsNull(v : Integer) {
        this.setterImpl(6, v, 'integerOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyIsEqual() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrencyIsEqual, false);
		return this.data[7];
	}

	set clientCurrencyIsEqual(v : ClientCurrency) {
        this.setterImpl(7, v, 'clientCurrencyIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyThisIsNull() : ClientCurrency | null {
		this.getterDebugValidations(this.aiClientCurrencyThisIsNull, false);
		return this.data[8];
	}

	set clientCurrencyThisIsNull(v : ClientCurrency | null) {
        this.setterImpl(8, v, 'clientCurrencyThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyOtherIsNull() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrencyOtherIsNull, false);
		return this.data[9];
	}

	set clientCurrencyOtherIsNull(v : ClientCurrency) {
        this.setterImpl(9, v, 'clientCurrencyOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeIsEqual() : DateTime {
		this.getterDebugValidations(this.aiDateTimeIsEqual, false);
		return this.data[10];
	}

	set dateTimeIsEqual(v : DateTime) {
        this.setterImpl(10, v, 'dateTimeIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeThisIsNull() : DateTime | null {
		this.getterDebugValidations(this.aiDateTimeThisIsNull, false);
		return this.data[11];
	}

	set dateTimeThisIsNull(v : DateTime | null) {
        this.setterImpl(11, v, 'dateTimeThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeOtherIsNull() : DateTime {
		this.getterDebugValidations(this.aiDateTimeOtherIsNull, false);
		return this.data[12];
	}

	set dateTimeOtherIsNull(v : DateTime) {
        this.setterImpl(12, v, 'dateTimeOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateIsEqual() : Date {
		this.getterDebugValidations(this.aiDateIsEqual, false);
		return this.data[13];
	}

	set dateIsEqual(v : Date) {
        this.setterImpl(13, v, 'dateIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateThisIsNull() : Date | null {
		this.getterDebugValidations(this.aiDateThisIsNull, false);
		return this.data[14];
	}

	set dateThisIsNull(v : Date | null) {
        this.setterImpl(14, v, 'dateThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateOtherIsNull() : Date {
		this.getterDebugValidations(this.aiDateOtherIsNull, false);
		return this.data[15];
	}

	set dateOtherIsNull(v : Date) {
        this.setterImpl(15, v, 'dateOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndIsEqual() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndIsEqual, false);
		return this.data[16];
	}

	set dateExclusiveEndIsEqual(v : DateExclusiveEnd) {
        this.setterImpl(16, v, 'dateExclusiveEndIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndThisIsNull() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.aiDateExclusiveEndThisIsNull, false);
		return this.data[17];
	}

	set dateExclusiveEndThisIsNull(v : DateExclusiveEnd | null) {
        this.setterImpl(17, v, 'dateExclusiveEndThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndOtherIsNull() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndOtherIsNull, false);
		return this.data[18];
	}

	set dateExclusiveEndOtherIsNull(v : DateExclusiveEnd) {
        this.setterImpl(18, v, 'dateExclusiveEndOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesIsEqual() : Minutes {
		this.getterDebugValidations(this.aiMinutesIsEqual, false);
		return this.data[19];
	}

	set minutesIsEqual(v : Minutes) {
        this.setterImpl(19, v, 'minutesIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesThisIsNull() : Minutes | null {
		this.getterDebugValidations(this.aiMinutesThisIsNull, false);
		return this.data[20];
	}

	set minutesThisIsNull(v : Minutes | null) {
        this.setterImpl(20, v, 'minutesThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesOtherIsNull() : Minutes {
		this.getterDebugValidations(this.aiMinutesOtherIsNull, false);
		return this.data[21];
	}

	set minutesOtherIsNull(v : Minutes) {
        this.setterImpl(21, v, 'minutesOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursIsEqual() : Hours {
		this.getterDebugValidations(this.aiHoursIsEqual, false);
		return this.data[22];
	}

	set hoursIsEqual(v : Hours) {
        this.setterImpl(22, v, 'hoursIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursThisIsNull() : Hours | null {
		this.getterDebugValidations(this.aiHoursThisIsNull, false);
		return this.data[23];
	}

	set hoursThisIsNull(v : Hours | null) {
        this.setterImpl(23, v, 'hoursThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursOtherIsNull() : Hours {
		this.getterDebugValidations(this.aiHoursOtherIsNull, false);
		return this.data[24];
	}

	set hoursOtherIsNull(v : Hours) {
        this.setterImpl(24, v, 'hoursOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysIsEqual() : Days {
		this.getterDebugValidations(this.aiDaysIsEqual, false);
		return this.data[25];
	}

	set daysIsEqual(v : Days) {
        this.setterImpl(25, v, 'daysIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysThisIsNull() : Days | null {
		this.getterDebugValidations(this.aiDaysThisIsNull, false);
		return this.data[26];
	}

	set daysThisIsNull(v : Days | null) {
        this.setterImpl(26, v, 'daysThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysOtherIsNull() : Days {
		this.getterDebugValidations(this.aiDaysOtherIsNull, false);
		return this.data[27];
	}

	set daysOtherIsNull(v : Days) {
        this.setterImpl(27, v, 'daysOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsIsEqual() : Months {
		this.getterDebugValidations(this.aiMonthsIsEqual, false);
		return this.data[28];
	}

	set monthsIsEqual(v : Months) {
        this.setterImpl(28, v, 'monthsIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsThisIsNull() : Months | null {
		this.getterDebugValidations(this.aiMonthsThisIsNull, false);
		return this.data[29];
	}

	set monthsThisIsNull(v : Months | null) {
        this.setterImpl(29, v, 'monthsThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsOtherIsNull() : Months {
		this.getterDebugValidations(this.aiMonthsOtherIsNull, false);
		return this.data[30];
	}

	set monthsOtherIsNull(v : Months) {
        this.setterImpl(30, v, 'monthsOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsIsEqual() : Years {
		this.getterDebugValidations(this.aiYearsIsEqual, false);
		return this.data[31];
	}

	set yearsIsEqual(v : Years) {
        this.setterImpl(31, v, 'yearsIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsThisIsNull() : Years | null {
		this.getterDebugValidations(this.aiYearsThisIsNull, false);
		return this.data[32];
	}

	set yearsThisIsNull(v : Years | null) {
        this.setterImpl(32, v, 'yearsThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsOtherIsNull() : Years {
		this.getterDebugValidations(this.aiYearsOtherIsNull, false);
		return this.data[33];
	}

	set yearsOtherIsNull(v : Years) {
        this.setterImpl(33, v, 'yearsOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationIsEqual() : Duration {
		this.getterDebugValidations(this.aiDurationIsEqual, false);
		return this.data[34];
	}

	set durationIsEqual(v : Duration) {
        this.setterImpl(34, v, 'durationIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationThisIsNull() : Duration | null {
		this.getterDebugValidations(this.aiDurationThisIsNull, false);
		return this.data[35];
	}

	set durationThisIsNull(v : Duration | null) {
        this.setterImpl(35, v, 'durationThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationOtherIsNull() : Duration {
		this.getterDebugValidations(this.aiDurationOtherIsNull, false);
		return this.data[36];
	}

	set durationOtherIsNull(v : Duration) {
        this.setterImpl(36, v, 'durationOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeIsEqual() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeIsEqual, false);
		return this.data[37];
	}

	set localTimeIsEqual(v : LocalTime) {
        this.setterImpl(37, v, 'localTimeIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeThisIsNull() : LocalTime | null {
		this.getterDebugValidations(this.aiLocalTimeThisIsNull, false);
		return this.data[38];
	}

	set localTimeThisIsNull(v : LocalTime | null) {
        this.setterImpl(38, v, 'localTimeThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeOtherIsNull() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeOtherIsNull, false);
		return this.data[39];
	}

	set localTimeOtherIsNull(v : LocalTime) {
        this.setterImpl(39, v, 'localTimeOtherIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get percentIsEqual() : LocalTime {
		this.getterDebugValidations(this.aiPercentIsEqual, false);
		return this.data[40];
	}

	set percentIsEqual(v : LocalTime) {
        this.setterImpl(40, v, 'percentIsEqual', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get percentThisIsNull() : LocalTime | null {
		this.getterDebugValidations(this.aiPercentThisIsNull, false);
		return this.data[41];
	}

	set percentThisIsNull(v : LocalTime | null) {
        this.setterImpl(41, v, 'percentThisIsNull', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get percentOtherIsNull() : LocalTime {
		this.getterDebugValidations(this.aiPercentOtherIsNull, false);
		return this.data[42];
	}

	set percentOtherIsNull(v : LocalTime) {
        this.setterImpl(42, v, 'percentOtherIsNull', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '265';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '265', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMax | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFails, AiSwitchShowcaseApiValidationsMaxFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFails, AiSwitchShowcaseApiValidationsMaxFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MAX_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMaxFailsCompareConst = new AiSwitchShowcaseApiValidationsMaxFailsCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxFails);
	public aiCompareConst = this.compareConstWrapper.aiThis;

	/**
	 * 
	 */
	get compareConst() : AiSwitchShowcaseApiValidationsMaxFailsCompareConst {
		this.getterDebugValidations(this.aiCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, function(this : AiSwitchShowcaseApiValidationsMaxFails) {this.compareConstWrapper = v;});
	}

	private compareNodeWrapper : AiSwitchShowcaseApiValidationsMaxFailsCompareNode = new AiSwitchShowcaseApiValidationsMaxFailsCompareNode(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxFails);
	public aiCompareNode = this.compareNodeWrapper.aiThis;

	/**
	 * 
	 */
	get compareNode() : AiSwitchShowcaseApiValidationsMaxFailsCompareNode {
		this.getterDebugValidations(this.aiCompareNode, false);
		return this.compareNodeWrapper;
	}

	set compareNodeTestSetter(v : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
        this.setterImpl(2, v.rawData, 'compareNode', true, null, function(this : AiSwitchShowcaseApiValidationsMaxFails) {this.compareNodeWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
		this.compareNodeWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.compareNodeWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '264';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '264', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxFailsCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxFailsCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxFailsCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxFailsCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, AiSwitchShowcaseApiValidationsMaxFailsCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, AiSwitchShowcaseApiValidationsMaxFailsCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'number',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7.5, true, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(3, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiNumberEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'numberEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7.5, false, PApiType.number, undefined, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'integer',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, true, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiIntegerEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'integerEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, false, PApiType.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, true, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiClientCurrencyEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'clientCurrencyEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, false, PApiType.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(1605135600000, true, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiDateTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(1605135600000, false, PApiType.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'date',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(1605135600000, true, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiDateEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(1605135600000, false, PApiType.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(1605135600000, true, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	aiDateExclusiveEndEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateExclusiveEndEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(1605135600000, false, PApiType.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, true, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	aiMinutesEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'minutesEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, false, PApiType.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'hours',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, true, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	aiHoursEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'hoursEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, false, PApiType.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'days',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, true, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	aiDaysEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'daysEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, false, PApiType.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'months',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, true, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	aiMonthsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'monthsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, false, PApiType.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'years',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, true, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	aiYearsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'yearsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(7, false, PApiType.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'duration',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max((5 * 60 * 60 * 1000) + (30 * 60 * 1000), true, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	aiDurationEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'durationEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(60000, false, PApiType.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(3600000, true, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	aiLocalTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'localTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(3600000, false, PApiType.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'percent',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(0.5, true, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	aiPercentEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'percentEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api.validators.max(0.7, false, PApiType.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[1];
	}

	set number(v : number) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberEqualNotAllowed() : number {
		this.getterDebugValidations(this.aiNumberEqualNotAllowed, false);
		return this.data[2];
	}

	set numberEqualNotAllowed(v : number) {
        this.setterImpl(2, v, 'numberEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[3];
	}

	set integer(v : Integer) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerEqualNotAllowed() : Integer {
		this.getterDebugValidations(this.aiIntegerEqualNotAllowed, false);
		return this.data[4];
	}

	set integerEqualNotAllowed(v : Integer) {
        this.setterImpl(4, v, 'integerEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrency, false);
		return this.data[5];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(5, v, 'clientCurrency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get clientCurrencyEqualNotAllowed() : ClientCurrency {
		this.getterDebugValidations(this.aiClientCurrencyEqualNotAllowed, false);
		return this.data[6];
	}

	set clientCurrencyEqualNotAllowed(v : ClientCurrency) {
        this.setterImpl(6, v, 'clientCurrencyEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[7];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(7, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeEqualNotAllowed() : DateTime {
		this.getterDebugValidations(this.aiDateTimeEqualNotAllowed, false);
		return this.data[8];
	}

	set dateTimeEqualNotAllowed(v : DateTime) {
        this.setterImpl(8, v, 'dateTimeEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[9];
	}

	set date(v : Date) {
        this.setterImpl(9, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateEqualNotAllowed() : Date {
		this.getterDebugValidations(this.aiDateEqualNotAllowed, false);
		return this.data[10];
	}

	set dateEqualNotAllowed(v : Date) {
        this.setterImpl(10, v, 'dateEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[11];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndEqualNotAllowed() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndEqualNotAllowed, false);
		return this.data[12];
	}

	set dateExclusiveEndEqualNotAllowed(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEndEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[13];
	}

	set minutes(v : Minutes) {
        this.setterImpl(13, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesEqualNotAllowed() : Minutes {
		this.getterDebugValidations(this.aiMinutesEqualNotAllowed, false);
		return this.data[14];
	}

	set minutesEqualNotAllowed(v : Minutes) {
        this.setterImpl(14, v, 'minutesEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[15];
	}

	set hours(v : Hours) {
        this.setterImpl(15, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursEqualNotAllowed() : Hours {
		this.getterDebugValidations(this.aiHoursEqualNotAllowed, false);
		return this.data[16];
	}

	set hoursEqualNotAllowed(v : Hours) {
        this.setterImpl(16, v, 'hoursEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[17];
	}

	set days(v : Days) {
        this.setterImpl(17, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysEqualNotAllowed() : Days {
		this.getterDebugValidations(this.aiDaysEqualNotAllowed, false);
		return this.data[18];
	}

	set daysEqualNotAllowed(v : Days) {
        this.setterImpl(18, v, 'daysEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[19];
	}

	set months(v : Months) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsEqualNotAllowed() : Months {
		this.getterDebugValidations(this.aiMonthsEqualNotAllowed, false);
		return this.data[20];
	}

	set monthsEqualNotAllowed(v : Months) {
        this.setterImpl(20, v, 'monthsEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[21];
	}

	set years(v : Years) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsEqualNotAllowed() : Years {
		this.getterDebugValidations(this.aiYearsEqualNotAllowed, false);
		return this.data[22];
	}

	set yearsEqualNotAllowed(v : Years) {
        this.setterImpl(22, v, 'yearsEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[23];
	}

	set duration(v : Duration) {
        this.setterImpl(23, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationEqualNotAllowed() : Duration {
		this.getterDebugValidations(this.aiDurationEqualNotAllowed, false);
		return this.data[24];
	}

	set durationEqualNotAllowed(v : Duration) {
        this.setterImpl(24, v, 'durationEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[25];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(25, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeEqualNotAllowed() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeEqualNotAllowed, false);
		return this.data[26];
	}

	set localTimeEqualNotAllowed(v : LocalTime) {
        this.setterImpl(26, v, 'localTimeEqualNotAllowed', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[27];
	}

	set percent(v : Percent) {
        this.setterImpl(27, v, 'percent', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percentEqualNotAllowed() : Percent {
		this.getterDebugValidations(this.aiPercentEqualNotAllowed, false);
		return this.data[28];
	}

	set percentEqualNotAllowed(v : Percent) {
        this.setterImpl(28, v, 'percentEqualNotAllowed', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '308';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '308', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxFailsCompareNode extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxFailsCompareNode>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxFailsCompareNode> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxFailsCompareNode as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, AiSwitchShowcaseApiValidationsMaxFailsCompareNode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, AiSwitchShowcaseApiValidationsMaxFailsCompareNode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'compareNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	aiNumberComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'numberComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_B',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.maxDecimalPlacesCount(1, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'number',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_A',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.numberComparedNode, true, PApiType.number, this.aiNumberComparedNode.id, undefined);						return null;
					},
					() => {
return this.api.validators.maxDecimalPlacesCount(3, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiIntegerComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'integerComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_B',
			type: PApiType.Integer,
			rawDataIndex: 3,
		});
	aiInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'integer',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_A',
			type: PApiType.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.integerComparedNode, true, PApiType.Integer, this.aiIntegerComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	aiCurrencyComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'currencyComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B',
			type: PApiType.ClientCurrency,
			rawDataIndex: 5,
		});
	aiCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'currency',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A',
			type: PApiType.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.currencyComparedNode, true, PApiType.ClientCurrency, this.aiCurrencyComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiDateTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateTimeComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_B',
			type: PApiType.DateTime,
			rawDataIndex: 7,
		});
	aiDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_A',
			type: PApiType.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.dateTimeComparedNode, true, PApiType.DateTime, this.aiDateTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	aiDateComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B',
			type: PApiType.Date,
			rawDataIndex: 9,
		});
	aiDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'date',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A',
			type: PApiType.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.dateComparedNode, true, PApiType.Date, this.aiDateComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiDateExclusiveEndComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateExclusiveEndComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B',
			type: PApiType.DateExclusiveEnd,
			rawDataIndex: 11,
		});
	aiDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A',
			type: PApiType.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.dateExclusiveEndComparedNode, true, PApiType.DateExclusiveEnd, this.aiDateExclusiveEndComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	aiMinutesComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'minutesComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_B',
			type: PApiType.Minutes,
			rawDataIndex: 13,
		});
	aiMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_A',
			type: PApiType.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.minutesComparedNode, true, PApiType.Minutes, this.aiMinutesComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	aiHoursComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'hoursComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_B',
			type: PApiType.Hours,
			rawDataIndex: 15,
		});
	aiHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'hours',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_A',
			type: PApiType.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.hoursComparedNode, true, PApiType.Hours, this.aiHoursComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	aiDaysComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'daysComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_B',
			type: PApiType.Days,
			rawDataIndex: 17,
		});
	aiDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'days',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_A',
			type: PApiType.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.daysComparedNode, true, PApiType.Days, this.aiDaysComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	aiMonthsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'monthsComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_B',
			type: PApiType.Months,
			rawDataIndex: 19,
		});
	aiMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'months',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_A',
			type: PApiType.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.monthsComparedNode, true, PApiType.Months, this.aiMonthsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	aiYearsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'yearsComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_B',
			type: PApiType.Years,
			rawDataIndex: 21,
		});
	aiYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'years',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_A',
			type: PApiType.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.yearsComparedNode, true, PApiType.Years, this.aiYearsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	aiDurationComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'durationComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_B',
			type: PApiType.Duration,
			rawDataIndex: 23,
		});
	aiDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'duration',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_A',
			type: PApiType.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.durationComparedNode, true, PApiType.Duration, this.aiDurationComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	aiLocalTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'localTimeComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_B',
			type: PApiType.LocalTime,
			rawDataIndex: 25,
		});
	aiLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_A',
			type: PApiType.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.localTimeComparedNode, true, PApiType.LocalTime, this.aiLocalTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	aiPercentComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'percentComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_B',
			type: PApiType.Percent,
			rawDataIndex: 27,
		});
	aiPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'percent',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_A',
			type: PApiType.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api.validators.max(() => this.percentComparedNode, true, PApiType.Percent, this.aiPercentComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get numberComparedNode() : number {
		this.getterDebugValidations(this.aiNumberComparedNode, false);
		return this.data[1];
	}

	set numberComparedNode(v : number) {
        this.setterImpl(1, v, 'numberComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {number}
	 */
	get number() : number {
		this.getterDebugValidations(this.aiNumber, false);
		return this.data[2];
	}

	set number(v : number) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integerComparedNode() : Integer {
		this.getterDebugValidations(this.aiIntegerComparedNode, false);
		return this.data[3];
	}

	set integerComparedNode(v : Integer) {
        this.setterImpl(3, v, 'integerComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Integer}
	 */
	get integer() : Integer {
		this.getterDebugValidations(this.aiInteger, false);
		return this.data[4];
	}

	set integer(v : Integer) {
        this.setterImpl(4, v, 'integer', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get currencyComparedNode() : ClientCurrency {
		this.getterDebugValidations(this.aiCurrencyComparedNode, false);
		return this.data[5];
	}

	set currencyComparedNode(v : ClientCurrency) {
        this.setterImpl(5, v, 'currencyComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {ClientCurrency}
	 */
	get currency() : ClientCurrency {
		this.getterDebugValidations(this.aiCurrency, false);
		return this.data[6];
	}

	set currency(v : ClientCurrency) {
        this.setterImpl(6, v, 'currency', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTimeComparedNode() : DateTime {
		this.getterDebugValidations(this.aiDateTimeComparedNode, false);
		return this.data[7];
	}

	set dateTimeComparedNode(v : DateTime) {
        this.setterImpl(7, v, 'dateTimeComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateTime}
	 */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.aiDateTime, false);
		return this.data[8];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(8, v, 'dateTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get dateComparedNode() : Date {
		this.getterDebugValidations(this.aiDateComparedNode, false);
		return this.data[9];
	}

	set dateComparedNode(v : Date) {
        this.setterImpl(9, v, 'dateComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Date}
	 */
	get date() : Date {
		this.getterDebugValidations(this.aiDate, false);
		return this.data[10];
	}

	set date(v : Date) {
        this.setterImpl(10, v, 'date', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEndComparedNode() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEndComparedNode, false);
		return this.data[11];
	}

	set dateExclusiveEndComparedNode(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEndComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {DateExclusiveEnd}
	 */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.aiDateExclusiveEnd, false);
		return this.data[12];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutesComparedNode() : Minutes {
		this.getterDebugValidations(this.aiMinutesComparedNode, false);
		return this.data[13];
	}

	set minutesComparedNode(v : Minutes) {
        this.setterImpl(13, v, 'minutesComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Minutes}
	 */
	get minutes() : Minutes {
		this.getterDebugValidations(this.aiMinutes, false);
		return this.data[14];
	}

	set minutes(v : Minutes) {
        this.setterImpl(14, v, 'minutes', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hoursComparedNode() : Hours {
		this.getterDebugValidations(this.aiHoursComparedNode, false);
		return this.data[15];
	}

	set hoursComparedNode(v : Hours) {
        this.setterImpl(15, v, 'hoursComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Hours}
	 */
	get hours() : Hours {
		this.getterDebugValidations(this.aiHours, false);
		return this.data[16];
	}

	set hours(v : Hours) {
        this.setterImpl(16, v, 'hours', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get daysComparedNode() : Days {
		this.getterDebugValidations(this.aiDaysComparedNode, false);
		return this.data[17];
	}

	set daysComparedNode(v : Days) {
        this.setterImpl(17, v, 'daysComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Days}
	 */
	get days() : Days {
		this.getterDebugValidations(this.aiDays, false);
		return this.data[18];
	}

	set days(v : Days) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get monthsComparedNode() : Months {
		this.getterDebugValidations(this.aiMonthsComparedNode, false);
		return this.data[19];
	}

	set monthsComparedNode(v : Months) {
        this.setterImpl(19, v, 'monthsComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Months}
	 */
	get months() : Months {
		this.getterDebugValidations(this.aiMonths, false);
		return this.data[20];
	}

	set months(v : Months) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get yearsComparedNode() : Years {
		this.getterDebugValidations(this.aiYearsComparedNode, false);
		return this.data[21];
	}

	set yearsComparedNode(v : Years) {
        this.setterImpl(21, v, 'yearsComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Years}
	 */
	get years() : Years {
		this.getterDebugValidations(this.aiYears, false);
		return this.data[22];
	}

	set years(v : Years) {
        this.setterImpl(22, v, 'years', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get durationComparedNode() : Duration {
		this.getterDebugValidations(this.aiDurationComparedNode, false);
		return this.data[23];
	}

	set durationComparedNode(v : Duration) {
        this.setterImpl(23, v, 'durationComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Duration}
	 */
	get duration() : Duration {
		this.getterDebugValidations(this.aiDuration, false);
		return this.data[24];
	}

	set duration(v : Duration) {
        this.setterImpl(24, v, 'duration', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTimeComparedNode() : LocalTime {
		this.getterDebugValidations(this.aiLocalTimeComparedNode, false);
		return this.data[25];
	}

	set localTimeComparedNode(v : LocalTime) {
        this.setterImpl(25, v, 'localTimeComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {LocalTime}
	 */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.aiLocalTime, false);
		return this.data[26];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(26, v, 'localTime', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percentComparedNode() : Percent {
		this.getterDebugValidations(this.aiPercentComparedNode, false);
		return this.data[27];
	}

	set percentComparedNode(v : Percent) {
        this.setterImpl(27, v, 'percentComparedNode', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {Percent}
	 */
	get percent() : Percent {
		this.getterDebugValidations(this.aiPercent, false);
		return this.data[28];
	}

	set percent(v : Percent) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '309';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '309', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxDecimalPlaces extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxDecimalPlaces>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxDecimalPlaces> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxDecimalPlaces as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlaces, AiSwitchShowcaseApiValidationsMaxDecimalPlaces> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlaces, AiSwitchShowcaseApiValidationsMaxDecimalPlaces>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxDecimalPlaces,
			name: 'maxDecimalPlaces',
			nodeName: 'VALIDATIONS_MAX_DECIMAL_PLACES',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlaces, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 13,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails = new AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxDecimalPlaces);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlaces) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '73';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '73', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxDecimalPlaces | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MAX_DECIMAL_PLACES_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiTooManyDecimalPlaces : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails,
			name: 'tooManyDecimalPlaces',
			nodeName: 'VALIDATIONS_MAX_DECIMAL_PLACES_FAILS_TOO_MANY_DECIMAL_PLACES',
			type: PApiType.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails) {
				return [
					() => {
return this.api.validators.maxDecimalPlacesCount(3, PApiType.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});

	/**
	 * 
	 *
	 * @type {number}
	 */
	get tooManyDecimalPlaces() : number {
		this.getterDebugValidations(this.aiTooManyDecimalPlaces, false);
		return this.data[1];
	}

	set tooManyDecimalPlaces(v : number) {
        this.setterImpl(1, v, 'tooManyDecimalPlaces', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '366';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '366', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxLength extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxLength>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxLength> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxLength as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLength, AiSwitchShowcaseApiValidationsMaxLength> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLength, AiSwitchShowcaseApiValidationsMaxLength>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxLength,
			name: 'maxLength',
			nodeName: 'VALIDATIONS_MAX_LENGTH',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxLength, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 14,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsMaxLengthFails = new AiSwitchShowcaseApiValidationsMaxLengthFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxLength);
	public aiFails = this.failsWrapper.aiThis;

	/**
	 * 
	 */
	get fails() : AiSwitchShowcaseApiValidationsMaxLengthFails {
		this.getterDebugValidations(this.aiFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMaxLengthFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, function(this : AiSwitchShowcaseApiValidationsMaxLength) {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '74';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '74', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxLengthFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxLengthFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxLength | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxLengthFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxLengthFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, AiSwitchShowcaseApiValidationsMaxLengthFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, AiSwitchShowcaseApiValidationsMaxLengthFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxLengthFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MAX_LENGTH_FAILS',
			type: PApiType.ApiObject,
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxLengthFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	aiTooLong : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, string> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxLengthFails,
			name: 'tooLong',
			nodeName: 'VALIDATIONS_MAX_LENGTH_FAILS_TOO_LONG',
			type: PApiType.string,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxLengthFails) {
				return [
					() => {
						return this.api.validators.maxLength(3, PApiType.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxLengthFails) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});

	/**
	 * 
	 *
	 * @type {string}
	 */
	get tooLong() : string {
		this.getterDebugValidations(this.aiTooLong, false);
		return this.data[1];
	}

	set tooLong(v : string) {
        this.setterImpl(1, v, 'tooLong', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '368';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '368', { success: success, error: error, searchParams: searchParams});
	}
}



