/* eslint-disable @typescript-eslint/ban-types -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { ClientCurrency, DateTime, Integer, PApiType } from './generated-types.ag';
import { NOT_CHANGED } from './object-diff/object-diff';

/**
 * @param savedData Raw-data being sent to backend to be saved. Is `NOT_CHANGED` when no backend call happened.
 */
export type ApiSaveSuccessHandler = (response : HttpResponse<unknown> | null, savedData : any[] | typeof NOT_CHANGED) => void;
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type ApiLoadSuccessHandler = (response : HttpResponse<unknown>) => void;
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type ApiPostSuccessHandler = (response : HttpResponse<unknown>) => void;
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type ApiErrorHandler = (response : HttpErrorResponse) => void;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ApiLoadArgs {

	/**
	 * Success handler.
	 */
	public success ?: ApiLoadSuccessHandler | null;

	/**
	 * Error handler.
	 */
	public error ?: ApiErrorHandler | null;

	/**
	 * Parameters to be passed to the api. These api request will be
	 * "<api_name>?<search_param_1>=<search_param_1_value>&<search_param_2>=<search_param_2_value>&...".
	 */
	public searchParams ?: HttpParams | null;
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ApiSaveArgs {

	/**
	 * Success handler. Note that when no save was done because no changes were found, this
	 * method is still called but `response` will be `null` and `savedData` `NOT_CHANGED`.
	 */
	public success ?: ApiSaveSuccessHandler | null;

	/**
	 * Error handler.
	 */
	public error ?: ApiErrorHandler | null;

	/**
	 * Here you can pass additional queryParams for the save() call which will be added to the query-params of last load() call.
	 * These params will only be used for this save() call and then discarded.
	 */
	public additionalSearchParams ?: HttpParams | null;

	/**
	 * Executes save even if the data to save is empty.
	 */
	public saveEmptyData ?: boolean | null;

	/**
	 * Only relevant when saveEmptyData === true. When no data is send set this to true to still send root meta.
	 */
	public sendRootMetaOnEmptyData ?: boolean | null;

	/**
	 * Save data in sync mode?
	 */
	public sync ?: boolean | null;

	/**
	 * Optionally defines a data path which should be saved.
	 * This is an array of indices from the data array. So, if [1, 2] is passed here, it means that only
	 * the diff for the index 1 in root array and in that array the index 2 should be calculated.
	 */
	public onlySavePath ?: Array<number> | null;
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ApiPostArgs {

	/**
	 * Success handler.
	 */
	public success ?: ApiPostSuccessHandler | null;

	/**
	 * Error handler.
	 */
	public error ?: ApiErrorHandler | null;

	/**
	 * Here you can pass query-params to be passed during the POST call.
	 */
	public searchParams ?: HttpParams | null;
}

/**
 * Enum indicating whether an Online-Cancellation is possible or not.
 * NOTE: Be careful! This enum also exists in backend/java (booking.java).
 */
export enum OnlineCancellationPossible {
	NO = 'NO',
	CAN_WITHDRAW = 'CAN_WITHDRAW',
	CAN_CANCEL = 'CAN_CANCEL',
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PaymentDetailsRequestType = {
	details : {
		payload ?: string;
		redirectResult ?: string;
		// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		MD ?: string;
		// eslint-disable-next-line @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		PaRes ?: string;
	};
} | null;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface BookableJson {
	clientId : Integer,
	type : string,
	paymentMethodId ?: any,
	paymentsDetailsRequest ?: PaymentDetailsRequestType,
	id ?: any,
	bookingPluginUrl ?: any,
	participantCount ?: any,
	participants ?: any,
	overallTariffId ?: any,
	price ?: any,
	initialBalance ?: any,
	redeemedAmount ?: any,
	currentlyPaid ?: any,
	firstName : any,
	lastName : any,
	shiftModelId ?: any,
	paymentsRequestJson ?: any,
	cardBin ?: any,
	email : string,
	dateOfBirth ?: any,
	ageMin ?: Integer,
	ageMax ?: Integer,

	/**
	 * Returns info weather the withdrawal is still possible or the cancellation is possible on none of them.
	 */
	onlineCancellationPossible ?: OnlineCancellationPossible,

	/**
	 * Returns deadline or courseStart depending which one is first.
	 * 0 will be returned if the no cancellation is possible (when it was never possible or when the deadline has passed).
	 * NOTE: Related thread https://drplano.slack.com/archives/C6NAFGQSF/p1634205349002100
	 */
	onlineCancellationPossibleUntil ?: number,

	cancellationFeePeriodFeeFix ?: number,
	cancellationFeePeriodFeePercentage ?: number,
	city ?: string,
	postalCode ?: string,
	streetAndHouseNumber ?: any,
	phoneMobile ?: string,
	phoneLandline ?: string,
	state ?: string,
	status ?: string,
	bookingNumber ?: number,
	cancellationFee ?: number,
}

/**
 * Possible errors when redeeming a gift-card.
 */
export enum GiftCardRedemptionError {
	CODE_NOT_FOUND = 'CODE_NOT_FOUND',
	NO_CREDIT_LEFT = 'NO_CREDIT_LEFT',
	EXPIRED = 'EXPIRED',
	CANCELED = 'CANCELED',
	NOT_PAID = 'NOT_PAID',
	WRONG_VAT_PERCENT = 'WRONG_VAT_PERCENT',
	MIN_SHOPPING_CARD_VALUE = 'MIN_SHOPPING_CARD_VALUE',
	OTHER_REASONS = 'OTHER_REASONS',
}
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface GiftCardRedemptionData {
	giftCardCode : string | null;
	redeemedAmount : ClientCurrency | null;
	remainingGiftCardValue : ClientCurrency | null;
	error : GiftCardRedemptionError | null;
	expirationDate : DateTime | null;
	minShoppingCardValue : ClientCurrency | null;
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface SortOptions {

	/** boolean for if the the sorting should occur inside the list or in a copy (default: false) */
	inPlace ?: boolean,

	/** boolean to reverse order after applying sorting (default: false) */
	reverse ?: boolean,

	/** remove the destroyed items if any (default: false) */
	removeDestroyedItems ?: boolean,
}

/**
 * Get the components keys as an object type and remove the functions
 */
type MarkFunctionsSimpleChanges<Component> = {
	[Key in keyof Component] : Component[Key] extends Function ? never : Key;
};

/**
 * Get only the component key
 */
type ExcludedPropertyNames<Component> = MarkFunctionsSimpleChanges<Component>[keyof Component];

/**
 * Use Pick to get the right type for each key
 */
type ComponentProperties<T> = Pick<T, ExcludedPropertyNames<T>>;

/**
 * The change object for a single attribute.
 */
export type PSimpleChange<ValueType> = {
	previousValue : ValueType;
	currentValue : ValueType;
	firstChange : boolean;
	isFirstChange() : boolean;
};

/**
 * Type to be used in ngOnChanges
 * TODO: filter to use only properties marked as input?
 */
export type PSimpleChanges<Component, Properties = ComponentProperties<Component>> = {
	[Key in keyof Properties] ?: PSimpleChange<Properties[Key]>
};

/**
 * Possible types for the PApiTextType. This wraps the PApiType which are internally represented as strings.
 */
export type PApiTextType = PApiType.Bic | PApiType.Email | PApiType.Password | PApiType.Iban | PApiType.PostalCode | PApiType.Search | PApiType.Url | PApiType.Tel | PApiType.string;
