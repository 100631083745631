/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { ApiBase } from '@plano/shared/api/base/api-base/api-base';
import { Meta } from '@plano/shared/api/base/meta';
import { NOT_AVAILABLE, NOT_CHANGED, ObjectDiff } from '@plano/shared/api/base/object-diff/object-diff';

/**
 * Data Stacks. Note that the order is important as the stack with highest ordinal which has data is the one
 * which is revealed outside of api. See method getTop().
 */
export enum Stack {

	/**
	 * Source of the data which is used to calculate diffs to be saved.
	 */
	SRC,

	/**
	 * Current client data. All backend operations are done on this level.
	 * Consequently, COPY is just a temporary copy which is ignored for any backend operations.
	 */
	CURRENT,

	/**
	 * Current copied data.
	 */
	COPY,

	/**
	 * Number of stacks.
	 */
	STACK_COUNT,
}

/**
 * This internal class manages the api data. Note that this class does not supported
 * simultaneous backend operations (See "PREVENT OVERLAPPING SAVES" in api -> common.txt).
 */
export class ApiDataStack {
	constructor(
		private api : ApiBase,
		private onDataObjectChanged : (change : string) => void,
	) {
		// initialize stack
		this.stack = Array(Stack.STACK_COUNT);
		this.clear(false);
	}

	/**
	 * The api data-version when the `SRC` stack was remembered.
	 * This is a performance improvement, to avoid unnecessary diff calculations as this can be very slow for large data sets.
	 *
	 * TODO: Get rid of diff calculations completely? Alternatively, build up the diff whenever API data changes?
	 */
	private srcApiDataVersion = 0;

	/**
	 * Data stack. If a stack level contains null then no data is available for that level.
	 */
	public stack : (any[] | null)[];

	/**
	 * Clear all available api data.
	 */
	public clear(notifyChange : boolean = true) : void {
		for (let i = 0; i < (Stack.STACK_COUNT as number); ++i)
			this.stack[i] = null;

		if (notifyChange)
			this.onDataObjectChanged('clear');
	}

	/**
	 * Returns the diff between backend data and data queued for save.
	 */
	public getDataToSave(onlySavePath : Array<number> | null) : any[] | typeof NOT_CHANGED {
		return ObjectDiff.diff(this.stack[Stack.SRC], this.getTop()!, onlySavePath);
	}

	/**
	 * @returns Is any api data available?
	 */
	public isDataLoaded() : boolean {
		return !!this.stack[Stack.CURRENT];
	}

	/**
	 * Callback being called when backend responds with an error code.
	 */
	public onBackendError() : void {
		// On error don’t change the data. There are two cases:
		// - Server is not reachable: Then the changes are not lost. So the user should still be
		//		able to retrigger the save. I think this cases is not working properly at the moment.
		//		See "V1" in https://drplano.atlassian.net/browse/PLANO-14194
		// - Backend error: Currently some automatic tests intentionally trigger some errors and
		// 		after that there should be still a state to work with.
		// 		See e.g. shift-exchange tests.
		// 		For normal app there is anyway an error modal to enforce reload of page. So the invalid state
		//		is discarded.
	}

	/**
	 * Callback being called when a save operation is initiated.
	 */
	public onSaveOperation(dataToSave : any[] | typeof NOT_CHANGED) : void {
		// merge data being saved with current SRC to get new SRC
		ObjectDiff.merge(this.stack[Stack.SRC]!, dataToSave);
		this.srcApiDataVersion = this.api.dataVersion;
	}

	/**
	 * @param data Response from backend.
	 */
	private onBackendResponse(data : any[] | null) : void {
		this.replaceNotAvailableWithUndefined(data);

		// ensure changes on data-stacks are not lost
		let modifiedBackendResponse = this.updateDataStack(data, Stack.CURRENT);

		if (this.stack[Stack.COPY]) {
			const modified = this.updateDataStack(data, Stack.COPY);
			modifiedBackendResponse = modifiedBackendResponse || modified;
		}

		// Backend response becomes new source
		this.stack[Stack.SRC] = this.copy(data);
		this.onDataObjectChanged('onBackendResponse');

		// remember the final SRC data-version.
		// In case, we did not modify the backend response, then "this.api.dataVersion" is
		// the version of our new SRC
		this.srcApiDataVersion = modifiedBackendResponse ? 0 : this.api.dataVersion;
	}

	/**
	 * As json does not support `undefined` backend sends NOT_AVAILABLE when some data is not available (because of `attributeInfo.show` logic).
	 * But, we want api to save `undefined` instead.
	 */
	private replaceNotAvailableWithUndefined(data : any[] | null) : void {
		if (data === null)
			return;

		for (let i = 1; i < data.length; ++i) {
			if (Array.isArray(data[i]))
				this.replaceNotAvailableWithUndefined(data[i]);
			else if (data[i] === NOT_AVAILABLE)
				data[i] = undefined;
		}
	}

	/**
	 * See `Meta.updateNewItemDbIds()`
	 * @param data Response from backend.
	 */
	private updateNewItemDbIds(data : any[] | null) : void {
		if (data === null)
			return;

		Meta.updateNewItemDbIds(this.stack[Stack.CURRENT]!, data);

		if (this.stack[Stack.COPY])
			Meta.updateNewItemDbIds(this.stack[Stack.COPY], data);

		this.onDataObjectChanged('onBackendResponse');
	}

	/**
	 * Updates data-stack defined by `stackIndex` with the new data `backendResponse`.
	 * This method also checks if any unsaved data are available in `SRC` data-stack
	 * and if so, merges them into the returned `backendResponse`.
	 * @returns `true` if any unsaved changed were merged in the backend response.
	 */
	private updateDataStack(backendResponse : any, stackIndex : Stack) : boolean {
		// If there is some old value ensure changes are not lost.
		// As diff calculation is very slow, we do this only if the data-version of the SRC stack
		// is different than the current data-version.
		if (this.stack[stackIndex] && this.srcApiDataVersion !== this.api.dataVersion) {
			const diff = ObjectDiff.diff(this.stack[Stack.SRC], this.stack[stackIndex]!);

			// take backend-response plus diffs done on the data
			this.stack[stackIndex] = this.copy(backendResponse);
			ObjectDiff.merge(this.stack[stackIndex]!, diff);

			return diff !== NOT_CHANGED;
		} else {
			// otherwise just copy backend response
			this.stack[stackIndex] = this.copy(backendResponse);

			return false;
		}
	}

	/**
	 * Callback being called when backend response of a load operation arrives.
	 * @param data The raw-data send by backend.
	 */
	public onLoadResponse(data : any[] | null) : void {
		this.onBackendResponse(data);
	}

	/**
	 * Callback being called when backend response of a save operation arrives.
	 * @param data Backend api response.
	 * @param isResponseForLastApiCall If this the response of the last api-call? If not, we are awaiting another response.
	 * @returns Was the api-data visible to others updated by `data`?
	 */
	public onSaveResponse(data : any[] | null, isResponseForLastApiCall : boolean) : boolean {
		// If this response is not for the last load() or save() call we can ignore it
		// or otherwise it will "shadow" changes done after the save() call. See
		// "consecutive-save-call-changes-are-not-shadowed" test.
		// We only need to ensure that new-items are updated with incoming db-ids
		// or otherwise wrapper items will get invalid because they cannot be associated
		// anymore with the correct item. See
		// "new-item-wrapper-is-not-lost-when-calling-load-after-item-creation" test.
		if (isResponseForLastApiCall) {
			this.onBackendResponse(data);
			return true;
		} else {
			this.updateNewItemDbIds(data);
			return false;
		}
	}

	/**
	 * @returns Does a data-copy exists?
	 */
	public hasCopy() : boolean {
		return this.stack[Stack.COPY] !== null;
	}

	/**
	 * Creates a data-copy.
	 */
	public createCopy() : void {
		// Only one copy is supported
		if (this.stack[Stack.COPY])

			// NOTE: https://sentry.io/organizations/dr-plano/issues/3260744249/
			throw new Error('You cannot create a data copy when already one is available. [PLANO-FE-4M9]');

		// create copy
		this.stack[Stack.COPY] = this.copy(this.getTop());

		this.onDataObjectChanged('createCopy');
	}

	/**
	 * Dismiss the data-copy.
	 */
	public dismissCopy() : void {
		// no copy available to dismiss?
		if (this.stack[Stack.COPY] === null)
			throw new Error('No data copy available. Forgot to call createCopy()?');

		this.stack[Stack.COPY] = null;

		this.onDataObjectChanged('dismissCopy');
	}

	/**
	 * Merge the data-copy.
	 */
	public mergeCopy() : void {
		// no copy available to merge?
		if (this.stack[Stack.COPY] === null)
			throw new Error('No data copy available. Forgot to call createCopy()?');

		// find diff of copied data
		this.stack[Stack.CURRENT] = this.stack[Stack.COPY];
		this.stack[Stack.COPY] = null;

		this.onDataObjectChanged('mergeCopy');
	}

	/**
	 * @returns Does the data-copy have any changes?
	 */
	public hasCopyChanged() : boolean {
		// no copy available to merge?
		const copy = this.stack[Stack.COPY];

		if (copy === null)
			throw new Error('No data copy available. Forgot to call createCopy()?');

		// find diff of copied data
		const diff = ObjectDiff.diff(this.stack[Stack.SRC], copy);
		return diff !== NOT_CHANGED;
	}

	/**
	 * @returns Has there been any changes between what api has sent and the current data?
	 */
	public hasTopChanged() : boolean {
		// find diff of TOP data
		const top = this.getTop();

		if (!top)
			return false;

		const diff = ObjectDiff.diff(this.stack[Stack.SRC], top);
		return diff !== NOT_CHANGED;
	}

	/**
	 * @returns The data which has originally been sent by the api.
	 */
	public getDataSource() : any[] | null {
		return this.stack[Stack.SRC];
	}

	/**
	 * @returns The current api data. This ignores any data copy.
	 */
	public getCurrent() : any[] | null {
		return this.stack[Stack.CURRENT];
	}

	/**
	 * Returns the top level data of the stack. This is the data the user will work with,
	 * i.e. the wrappers are bound to this data.
	 */
	public getTop() : any[] | null {
		// find the topmost stack level with data
		for (let i = Stack.STACK_COUNT - 1; i >= 0; --i) {
			if (this.stack[i] !== null)
				return this.stack[i];
		}

		return null;
	}

	/**
	 * Create a deep copy
	 */
	private copy(data : any[] | null) : any[] | null {
		return structuredClone(data);
	}
}
