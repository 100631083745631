import { SchedulingApiActivityAreaBase, SchedulingApiActivityAreasBase } from '@plano/shared/api';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

/** @see SchedulingApiActivityAreaBase */
export class SchedulingApiActivityArea extends SchedulingApiActivityAreaBase {
	/**
	 * Validator to make sure that there are no activity areas with the same name
	 */
	public checkNoDuplicateNames() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_ACTIVITY_AREA_NAME, fn: (_control) => {
			if (this.parent) {
				for (const activityArea of this.parent.iterable()) {
					// It is ok to have the same name in a trashed activity area
					if (activityArea.trashed)
						continue;

					if (activityArea === this)
						continue;

					if (this.aiName.value === null || activityArea.aiName.value === null) continue;

					if (activityArea.name.toLowerCase() === this.aiName.value.toLowerCase()) {
						// We found a duplicate
						return { [PPossibleErrorNames.DUPLICATE_ACTIVITY_AREA_NAME]: {
							name: PPossibleErrorNames.DUPLICATE_ACTIVITY_AREA_NAME,
							type: null,
						}};
					}
				}
			}

			return null;
		}});
	}

	/**
	 * Get the related activities.
	 */
	public get relatedActivities() {
		return this.api.data.shiftModels.filterBy(item => {
			return item.aiActivityAreaId.value?.equals(this.id) ?? false;
		});
	}

	/**
	 * Check if there are any shifts selected which are related to this activity.
	 */
	public get relatedShiftsSelected() : boolean {
		return this.api.data.shifts.selectedItems.some(item => item.relatesTo(this));
	}

	/**
	 * Check if this item fits to the given search term.
	 * @param searchTerm The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(searchTerm : string) : boolean {
		const termLow = searchTerm.toLowerCase();
		const TERMS = termLow.split(' ');

		for (const TERM of TERMS) {
			if (!TERM) continue;
			const itemLow = this.name.toLowerCase();

			// If this term is found in the name of the activity area, the term is considered matching.
			if (itemLow.includes(TERM)) continue;

			// If the emoji of the activity area fits the term, the term is considered matching.
			if (this.emoji === TERM) continue;

			// If any of the activities related to this activity area fits the term, the term is considered matching.
			if (this.relatedActivities.search(TERM).length > 0) continue;

			// If the previous conditions did not match, then the current TERM does not fit to the content.
			// As soon as one of the terms does not fit, the content is not considered matching.
			return false;
		}

		return true;
	}
}

/** @see SchedulingApiActivityAreasBase */
export class SchedulingApiActivityAreas extends SchedulingApiActivityAreasBase {
	/**
	 * Search these activity areas by a given string.
	 * @param term The string to search for. Can be a string that a user typed into an input element.
	 */
	public search(term : Parameters<SchedulingApiActivityArea['fitsSearch']>[0]) : this {
		if (term === '') return this;
		return this.filterBy(item => item.fitsSearch(term));
	}
}
