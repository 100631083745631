/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "register_test_account".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	OWNER_GENDER = 1;
	OWNER_FIRST_NAME = 2;
	OWNER_LAST_NAME = 3;
	OWNER_EMAIL = 4;
	OWNER_PHONE = 5;
	OWNER_PASSWORD = 6;
	COMPANY = 7;
	LOCATION_HOMEPAGE = 8;
	COUNTRY = 9;
	HOW_DO_YOU_KNOW = 10;
	HOW_DO_YOU_KNOW_TEXT = 11;
}


@Injectable({
  providedIn: 'root',
})
export class RegisterTestAccountApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'register_test_account');
	}

	consts = new Consts();

	protected version() : string {
		return '1416c932dcbadffc7a57626800392fb2,6873437c1b118fceb45e99aab236951d';
	}

	private dataWrapper = new RegisterTestAccountApiRoot(this);

	get data() : RegisterTestAccountApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : RegisterTestAccountApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new RegisterTestAccountApiRoot(this);
	}
}

		 
export class RegisterTestAccountApiRoot extends ApiObjectWrapper<RegisterTestAccountApiRoot>
{
	constructor(override readonly api : RegisterTestAccountApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<RegisterTestAccountApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, RegisterTestAccountApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiRoot> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiRoot>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			defaultValue: function(this : RegisterTestAccountApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	aiOwnerGender : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiGender> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiGender>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerGender',
			nodeName: 'OWNER_GENDER',
			type: PApiType.Enum,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	aiOwnerFirstName : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerFirstName',
			nodeName: 'OWNER_FIRST_NAME',
			type: PApiType.string,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	aiOwnerLastName : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerLastName',
			nodeName: 'OWNER_LAST_NAME',
			type: PApiType.string,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	aiOwnerEmail : ApiAttributeInfo<RegisterTestAccountApiRoot, Email> = new ApiAttributeInfo<RegisterTestAccountApiRoot, Email>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerEmail',
			nodeName: 'OWNER_EMAIL',
			type: PApiType.Email,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
						this.api.asyncValidators.emailValidAsync.bind(this.api.asyncValidators, true
						 ),
				];
			}
			,
			rawDataIndex: 4,
		});
	aiOwnerPhone : ApiAttributeInfo<RegisterTestAccountApiRoot, Tel> = new ApiAttributeInfo<RegisterTestAccountApiRoot, Tel>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerPhone',
			nodeName: 'OWNER_PHONE',
			type: PApiType.Tel,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Tel, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	aiOwnerPassword : ApiAttributeInfo<RegisterTestAccountApiRoot, Password> = new ApiAttributeInfo<RegisterTestAccountApiRoot, Password>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerPassword',
			nodeName: 'OWNER_PASSWORD',
			type: PApiType.Password,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Password, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	aiCompany : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'company',
			nodeName: 'COMPANY',
			type: PApiType.string,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	aiLocationHomepage : ApiAttributeInfo<RegisterTestAccountApiRoot, Url> = new ApiAttributeInfo<RegisterTestAccountApiRoot, Url>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'locationHomepage',
			nodeName: 'LOCATION_HOMEPAGE',
			type: PApiType.Url,
			rawDataIndex: 8,
		});
	aiCountry : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiCountry> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiCountry>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'country',
			nodeName: 'COUNTRY',
			type: PApiType.Enum,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	aiHowDoYouKnow : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiHowDoYouKnow> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiHowDoYouKnow>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'howDoYouKnow',
			nodeName: 'HOW_DO_YOU_KNOW',
			type: PApiType.Enum,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api.validators.required(PApiType.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	aiHowDoYouKnowText : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'howDoYouKnowText',
			nodeName: 'HOW_DO_YOU_KNOW_TEXT',
			type: PApiType.string,
			isAvailableByBusinessLogic: function(this : RegisterTestAccountApiRoot) {
				return ((this.howDoYouKnow === RegisterTestAccountApiHowDoYouKnow.OTHER));
			},
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
		if(((this.howDoYouKnow === RegisterTestAccountApiHowDoYouKnow.OTHER)))
		{
			return this.api.validators.required(PApiType.string, undefined);
		}
		else
		{
			return this.api.validators.ensureNull(PApiType.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});

	/**
	 * The gender of the user.
	 *
	 * @type {RegisterTestAccountApiGender}
	 */
	get ownerGender() : RegisterTestAccountApiGender {
		this.getterDebugValidations(this.aiOwnerGender, false);
		return this.data[1];
	}

	set ownerGender(v : RegisterTestAccountApiGender) {
        this.setterImpl(1, v, 'ownerGender', false, null, null);
	}

	/**
	 * Owner's first-name
	 *
	 * @type {string}
	 */
	get ownerFirstName() : string {
		this.getterDebugValidations(this.aiOwnerFirstName, false);
		return this.data[2];
	}

	set ownerFirstName(v : string) {
        this.setterImpl(2, v, 'ownerFirstName', false, null, null);
	}

	/**
	 * Owner's last-name
	 *
	 * @type {string}
	 */
	get ownerLastName() : string {
		this.getterDebugValidations(this.aiOwnerLastName, false);
		return this.data[3];
	}

	set ownerLastName(v : string) {
        this.setterImpl(3, v, 'ownerLastName', false, null, null);
	}

	/**
	 * Owner's email address
	 *
	 * @type {Email}
	 */
	get ownerEmail() : Email {
		this.getterDebugValidations(this.aiOwnerEmail, false);
		return this.data[4];
	}

	set ownerEmail(v : Email) {
        this.setterImpl(4, v, 'ownerEmail', false, null, null);
	}

	/**
	 * Owner's email phone number
	 *
	 * @type {Tel}
	 */
	get ownerPhone() : Tel {
		this.getterDebugValidations(this.aiOwnerPhone, false);
		return this.data[5];
	}

	set ownerPhone(v : Tel) {
        this.setterImpl(5, v, 'ownerPhone', false, null, null);
	}

	/**
	 * Owner's password
	 *
	 * @type {Password}
	 */
	get ownerPassword() : Password {
		this.getterDebugValidations(this.aiOwnerPassword, false);
		return this.data[6];
	}

	set ownerPassword(v : Password) {
        this.setterImpl(6, v, 'ownerPassword', false, null, null);
	}

	/**
	 * Company name
	 *
	 * @type {string}
	 */
	get company() : string {
		this.getterDebugValidations(this.aiCompany, false);
		return this.data[7];
	}

	set company(v : string) {
        this.setterImpl(7, v, 'company', false, null, null);
	}

	/**
	 * Company's homepage
	 *
	 * @type {Url}
	 */
	get locationHomepage() : Url | null {
		this.getterDebugValidations(this.aiLocationHomepage, false);
		return this.data[8];
	}

	set locationHomepage(v : Url | null) {
        this.setterImpl(8, v, 'locationHomepage', false, null, null);
	}

	/**
	 * Account country
	 *
	 * @type {RegisterTestAccountApiCountry}
	 */
	get country() : RegisterTestAccountApiCountry {
		this.getterDebugValidations(this.aiCountry, false);
		return this.data[9];
	}

	set country(v : RegisterTestAccountApiCountry) {
        this.setterImpl(9, v, 'country', false, null, null);
	}

	/**
	 * 
	 *
	 * @type {RegisterTestAccountApiHowDoYouKnow}
	 */
	get howDoYouKnow() : RegisterTestAccountApiHowDoYouKnow {
		this.getterDebugValidations(this.aiHowDoYouKnow, false);
		return this.data[10];
	}

	set howDoYouKnow(v : RegisterTestAccountApiHowDoYouKnow) {
        this.setterImpl(10, v, 'howDoYouKnow', false, null, null);
	}

	/**
	 * only set when HOW_DO_YOU_KNOW==OTHER
	 *
	 * @type {string}
	 */
	get howDoYouKnowText() : string | null {
		this.getterDebugValidations(this.aiHowDoYouKnowText, false);
		// This attribute has a "isAvailable" condition (When we reach this line then "isAvailable" is true). If no data is available ensure that it is initialized by its default value.
		if(this.data[11] === undefined) {
			this.data[11] = this.aiHowDoYouKnowText.defaultValue;
			
		}
		return this.data[11];
	}

	set howDoYouKnowText(v : string | null) {
        this.setterImpl(11, v, 'howDoYouKnowText', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : RegisterTestAccountApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

export enum RegisterTestAccountApiGender {
	MALE = 1,
	FEMALE = 2,
	DIVERSE = 3,
}
export enum RegisterTestAccountApiCountry {
	GERMANY = 1,
	AUSTRIA = 2,
	SWITZERLAND = 3,
	ITALY = 4,
	ROMANIA = 5,
	NETHERLANDS = 6,
	BELGIUM = 7,
	UNITED_KINGDOM = 8,
	CZECH_REPUBLIC = 9,
	SWEDEN = 10,
	LUXEMBOURG = 11,
}
export enum RegisterTestAccountApiHowDoYouKnow {
	RECOMMENDATION = 1,
	INTERNET_SEARCH = 2,
	ASKED_BY_PLANO = 3,
	SOCIAL_MEDIA_FACEBOOK = 4,
	FAIR = 5,
	OTHER = 6,
}


