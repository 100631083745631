/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Injectable, NgZone } from '@angular/core';
import { FilterServiceInterface } from '@plano/client/shared/filter.service';
import { SchedulingApiHolidayItemType, SchedulingApiHolidaysHolidayItem, SchedulingApiLeave, SchedulingApiMember, SchedulingApiShift } from '@plano/shared/api';
import { DataInput } from '@plano/shared/core/data/data-input';
import { PIndexedDBService, PServiceWithIndexedDBInterface } from '@plano/shared/core/indexed-db/p-indexed-db.service';
import { MeService } from '@plano/shared/core/me/me.service';
import { SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_BIRTHDAYS_KEY, SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY, SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_PUBLIC_HOLIDAYS_KEY, SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SCHOOL_HOLIDAYS_KEY, SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_ASSIGNED_TO_KEY, SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_NOT_ASSIGNED_TO_KEY, SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_WITHOUT_NEED_FOR_MORE_ASSIGNMENTS_KEY, SchedulingFilterServiceIndexedDBKeyPrefixDataType, schedulingFilterServiceIndexedDBKeyWithPrefix } from './scheduling-filter.service.indexeddb.type';
import { SchedulingApiBirthday } from './shared/api/scheduling-api-birthday.service';

@Injectable( { providedIn : 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingFilterService extends DataInput
	implements PServiceWithIndexedDBInterface, FilterServiceInterface {

	constructor(
		private meService : MeService,
		private pIndexedDBService : PIndexedDBService,
		protected override zone : NgZone,
	) {
		super(zone);
	}

	private _indexedDBKeyPrefix : SchedulingFilterServiceIndexedDBKeyPrefixDataType = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public set indexedDBKeyPrefix(value : SchedulingFilterServiceIndexedDBKeyPrefixDataType) {
		this._indexedDBKeyPrefix = value;
	}

	/**
	 * Should all Leaves be hidden?
	 */
	public get hideAllLeaves() : boolean { return this._hideAllLeaves!; }

	public set hideAllLeaves(value : boolean | null) {
		if (value === null)
			this.pIndexedDBService.delete(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY, this._indexedDBKeyPrefix));
		else
			this.pIndexedDBService.set(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY, this._indexedDBKeyPrefix), value);
		this._hideAllLeaves = value;
		this.changed('hideAllLeaves');
	}

	/**
	 * Should all Public Holidays be hidden?
	 */
	public get hideAllPublicHolidays() : boolean { return this._hideAllPublicHolidays!; }

	public set hideAllPublicHolidays(value : boolean | null) {
		if (value === null)
			this.pIndexedDBService.delete(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_PUBLIC_HOLIDAYS_KEY, this._indexedDBKeyPrefix));
		else
			this.pIndexedDBService.set(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_PUBLIC_HOLIDAYS_KEY, this._indexedDBKeyPrefix), value);
		this._hideAllPublicHolidays = value;
		this.changed('hideAllPublicHolidays');
	}

	/**
	 * Should all School Holidays be hidden?
	 */
	public get hideAllSchoolHolidays() : boolean { return this._hideAllSchoolHolidays!; }

	public set hideAllSchoolHolidays(value : boolean | null) {
		if (value === null)
			this.pIndexedDBService.delete(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SCHOOL_HOLIDAYS_KEY, this._indexedDBKeyPrefix));
		else
			this.pIndexedDBService.set(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SCHOOL_HOLIDAYS_KEY, this._indexedDBKeyPrefix), value);
		this._hideAllSchoolHolidays = value;
		this.changed('hideAllSchoolHolidays');
	}

	/**
	 * Should all Holidays be hidden?
	 */
	public get hideAllBirthdays() : boolean { return this._hideAllBirthdays!; }

	public set hideAllBirthdays(value : boolean | null) {
		if (value === null)
			this.pIndexedDBService.delete(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_BIRTHDAYS_KEY, this._indexedDBKeyPrefix));
		else
			this.pIndexedDBService.set(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_BIRTHDAYS_KEY, this._indexedDBKeyPrefix), value);
		this._hideAllBirthdays = value;
		this.changed('hideAllBirthdays');
	}

	/**
	 * Should all Shifts that are not assigned to me be hidden?
	 */
	public get hideAllShiftsNotAssignedToMe() : boolean { return this._hideAllShiftsNotAssignedToMe!; }

	public set hideAllShiftsNotAssignedToMe(value : boolean | null) {
		if (value === null)
			this.pIndexedDBService.delete(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_NOT_ASSIGNED_TO_KEY, this._indexedDBKeyPrefix));
		else
			this.pIndexedDBService.set(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_NOT_ASSIGNED_TO_KEY, this._indexedDBKeyPrefix), value);
		this._hideAllShiftsNotAssignedToMe = value;
		this.changed('hideAllShiftsNotAssignedToMe');
	}

	/**
	 * Should all Shifts from me be hidden?
	 */
	public get hideAllShiftsAssignedToMe() : boolean { return this._hideAllShiftsAssignedToMe!; }

	public set hideAllShiftsAssignedToMe(value : boolean | null) {
		if (value === null)
			this.pIndexedDBService.delete(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_ASSIGNED_TO_KEY, this._indexedDBKeyPrefix));
		else
			this.pIndexedDBService.set(schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_ASSIGNED_TO_KEY, this._indexedDBKeyPrefix), value);
		this._hideAllShiftsAssignedToMe = value;
		this.changed('hideAllShiftsAssignedToMe');
	}

	/**
	 * Should all Shifts with no one be hidden?
	 */
	public get hideAllShiftsWithoutNeedForMoreAssignments() : boolean { return this._hideAllShiftsWithoutNeedForMoreAssignments!; }

	public set hideAllShiftsWithoutNeedForMoreAssignments(value : boolean | null) {
		if (value === null)
			this.pIndexedDBService.delete(schedulingFilterServiceIndexedDBKeyWithPrefix(
				SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_WITHOUT_NEED_FOR_MORE_ASSIGNMENTS_KEY,
				this._indexedDBKeyPrefix,
			));
		else
			this.pIndexedDBService.set(schedulingFilterServiceIndexedDBKeyWithPrefix(
				SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_WITHOUT_NEED_FOR_MORE_ASSIGNMENTS_KEY,
				this._indexedDBKeyPrefix,
			), value);
		this._hideAllShiftsWithoutNeedForMoreAssignments = value;
		this.changed('hideAllShiftsWithoutNeedForMoreAssignments');
	}

	/**
	 * Should shifts with unassigned slots be visible or not?
	 */
	public showItemsWithEmptyMemberSlot : boolean | null = null;

	private _hideAllLeaves : boolean | null = false;
	private _hideAllPublicHolidays : boolean | null = false;
	private _hideAllSchoolHolidays : boolean | null = false;
	private _hideAllBirthdays : boolean | null = false;
	private _hideAllShiftsNotAssignedToMe : boolean | null = false;
	private _hideAllShiftsAssignedToMe : boolean | null = false;
	private _hideAllShiftsWithoutNeedForMoreAssignments : boolean | null = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get isSetToShowAll() : boolean {
		if (!this.showItemsWithEmptyMemberSlot) return false;
		if (this.hideAllLeaves) return false;
		if (this.hideAllPublicHolidays) return false;
		if (this.hideAllSchoolHolidays) return false;
		if (this.hideAllBirthdays) return false;
		if (this.hideAllShiftsNotAssignedToMe) return false;
		if (this.hideAllShiftsAssignedToMe) return false;
		if (this.hideAllShiftsWithoutNeedForMoreAssignments) return false;
		return true;
	}

	/**
	 * Checks if given shiftModel, member, option, members or shiftModels is visible.
	 */
	public isVisible(
		input :

		// SchedulingApiShiftModel |
		SchedulingApiMember |

		// SchedulingApiMembers |
		// SchedulingApiShiftModels |
		SchedulingApiShift |
		SchedulingApiLeave |

		// SchedulingApiLeaves |
		SchedulingApiHolidaysHolidayItem |
		SchedulingApiBirthday,

		// SchedulingApiBooking |
		// SchedulingApiWorkingTime |
		// SchedulingApiTodaysShiftDescription,
	) : boolean {
		if (input instanceof SchedulingApiShift) {
			return this.isVisibleShift(input);
		}

		// if (input instanceof SchedulingApiShiftModel) {
		// 	return this.isVisibleShiftModel(input);
		// }
		// if (input instanceof SchedulingApiShiftModels) {
		// 	return this.isVisibleShiftModels(input);
		// }
		if (input instanceof SchedulingApiMember) {
			return this.isVisibleBirthday(/* input*/);
		}

		// if (input instanceof SchedulingApiMembers) {
		// 	return this.isVisibleMembers(input);
		// }
		if (input instanceof SchedulingApiLeave) {
			return this.isVisibleLeave();
		}

		// if (input instanceof SchedulingApiLeaves) {
		// 	return this.isVisibleLeaves(input);
		// }
		if (input instanceof SchedulingApiHolidaysHolidayItem) {
			if (input.type === SchedulingApiHolidayItemType.PUBLIC_HOLIDAY)
				return this.isVisiblePublicHoliday(/* input*/);
			else return this.isVisibleSchoolHoliday(/* input*/);
		}
		if (input instanceof SchedulingApiBirthday) {
			return this.isVisibleBirthday(/* input*/);
		}

		// if (input instanceof SchedulingApiBooking) {
		// 	return this.isVisibleBooking(input);
		// }
		// if (input instanceof SchedulingApiWorkingTime) {
		// 	return this.isVisibleWorkingTime(input);
		// }
		// if (input instanceof SchedulingApiTodaysShiftDescription) {
		// 	return this.isVisibleTodaysShiftDescription(input);
		// }
		throw new Error('unexpected instance of input');
	}

	/**
	 * Check if this shift is visible
	 */
	private isVisibleShift(shift : SchedulingApiShift) : boolean {

		if (this.hideAllShiftsWithoutNeedForMoreAssignments) {
			if (!shift.aiAssignedMemberIds.isAvailable) return false;
			const shiftStillHasSlots = shift.neededMembersCount > shift.assignedMemberIds.length;
			if (!shiftStillHasSlots) return false;
		}

		// don’t show this shift if me is not assigned but user has set "hideAllShiftsNotAssignedToMe"
		if (this.hideAllShiftsNotAssignedToMe) {
			if (!shift.aiAssignedMemberIds.isAvailable) return false;
			const relatesToMe = shift.assignedMemberIds.contains(this.meService.data.id);
			if (!relatesToMe) return false;
		}

		// don’t show this shift if its assigned to me and hideAllShiftsAssignedToMe is set
		if (this.hideAllShiftsAssignedToMe) {
			if (!shift.aiAssignedMemberIds.isAvailable) return false;
			const ids = shift.assignedMemberIds;
			if (ids.contains(this.meService.data.id)) return false;
		}

		// If shifts with empty slots should be hidden AND shift has empty slots -> hide
		if (!this.showItemsWithEmptyMemberSlot && shift.emptyMemberSlots) return false;

		return true;
	}

	/**
	 * Check if this leave is visible
	 */
	private isVisibleLeave() : boolean {
		return !this.hideAllLeaves;
	}

	/**
	 * Check if this public holiday is visible
	 */
	private isVisiblePublicHoliday() : boolean {
		return !this.hideAllPublicHolidays;
	}

	/**
	 * Check if this School holiday is visible
	 */
	private isVisibleSchoolHoliday() : boolean {
		return !this.hideAllSchoolHolidays;
	}

	/**
	 * Check if this birthday is visible
	 */
	private isVisibleBirthday() : boolean {
		return !this.hideAllBirthdays;
	}

	/**
	 * Init all necessary values for this class
	 */
	public initValues() : void {
		if (this._hideAllLeaves === null) this.hideAllLeaves = false;
		if (this._hideAllPublicHolidays === null) this.hideAllPublicHolidays = false;
		if (this._hideAllSchoolHolidays === null) this.hideAllSchoolHolidays = false;
		if (this._hideAllBirthdays === null) this.hideAllBirthdays = false;
		if (this._hideAllShiftsNotAssignedToMe === null) this.hideAllShiftsNotAssignedToMe = false;
		if (this._hideAllShiftsAssignedToMe === null) this.hideAllShiftsAssignedToMe = false;
		if (this._hideAllShiftsWithoutNeedForMoreAssignments === null) this.hideAllShiftsWithoutNeedForMoreAssignments = false;
		if (this.showItemsWithEmptyMemberSlot === null) this.showItemsWithEmptyMemberSlot = true;

		this.changed(null);
	}

	/** @see PServiceInterface#unload */
	public unload() : void {
		this.unloadFilters();
	}

	/**
	 * Reset all filters to default
	 */
	public unloadFilters() : void {
		this._hideAllLeaves = null;
		this._hideAllPublicHolidays = null;
		this._hideAllSchoolHolidays = null;
		this._hideAllBirthdays = null;
		this._hideAllShiftsNotAssignedToMe = null;
		this._hideAllShiftsAssignedToMe = null;
		this._hideAllShiftsWithoutNeedForMoreAssignments = null;
		this.showItemsWithEmptyMemberSlot = null;
	}

	/**
	 * Read values from indexedDB if available
	 */
	public readIndexedDB() : void {
		const hideAllLeavesIndexedDB = this.pIndexedDBService.get(
			schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY, this._indexedDBKeyPrefix),
		);
		if (hideAllLeavesIndexedDB !== null) {
			this.hideAllLeaves = hideAllLeavesIndexedDB === 'true';
		}
		const hideAllPublicHolidaysIndexedDB = this.pIndexedDBService.get(
			schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_PUBLIC_HOLIDAYS_KEY, this._indexedDBKeyPrefix),
		);
		if (hideAllPublicHolidaysIndexedDB !== null) {
			this.hideAllPublicHolidays = hideAllPublicHolidaysIndexedDB === 'true';
		}
		const hideAllSchoolHolidaysIndexedDB = this.pIndexedDBService.get(
			schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SCHOOL_HOLIDAYS_KEY, this._indexedDBKeyPrefix),
		);
		if (hideAllSchoolHolidaysIndexedDB !== null) {
			this.hideAllSchoolHolidays = hideAllSchoolHolidaysIndexedDB === 'true';
		}
		const hideAllBirthdaysIndexedDB = this.pIndexedDBService.get(
			schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_BIRTHDAYS_KEY, this._indexedDBKeyPrefix),
		);
		if (hideAllBirthdaysIndexedDB !== null) {
			this.hideAllBirthdays = hideAllBirthdaysIndexedDB === 'true';
		}
		const hideAllShiftsNotAssignedToMeIndexedDB = this.pIndexedDBService.get(
			schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_NOT_ASSIGNED_TO_KEY, this._indexedDBKeyPrefix),
		);
		if (hideAllShiftsNotAssignedToMeIndexedDB !== null) {
			this.hideAllShiftsNotAssignedToMe = hideAllShiftsNotAssignedToMeIndexedDB === 'true';
		}
		const hideAllShiftsAssignedToMeIndexedDB = this.pIndexedDBService.get(
			schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_ASSIGNED_TO_KEY, this._indexedDBKeyPrefix),
		);
		if (hideAllShiftsAssignedToMeIndexedDB !== null) {
			this.hideAllShiftsAssignedToMe = hideAllShiftsAssignedToMeIndexedDB === 'true';
		}
		const hideAllShiftsWithoutNeedForMoreAssignmentsIndexedDB = this.pIndexedDBService.get(
			schedulingFilterServiceIndexedDBKeyWithPrefix(SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_WITHOUT_NEED_FOR_MORE_ASSIGNMENTS_KEY, this._indexedDBKeyPrefix),
		);
		if (hideAllShiftsWithoutNeedForMoreAssignmentsIndexedDB !== null) {
			this.hideAllShiftsWithoutNeedForMoreAssignments = hideAllShiftsWithoutNeedForMoreAssignmentsIndexedDB === 'true';
		}
	}
}
