import { USER_STORE_NAME } from '@plano/shared/core/indexed-db/indexed-db.types';

// TODO: PLANO-184431 remove`| null`
export const schedulingFilterServiceIndexedDBKeyPrefixDataTypeArray = ['SchedulingFilterService', 'PShiftSelection', 'PShiftExchanges', 'POfferPicker', 'PLeaveDaysCalendar', 'PHolidays', null] as const;
export type SchedulingFilterServiceIndexedDBKeyPrefixDataType = typeof schedulingFilterServiceIndexedDBKeyPrefixDataTypeArray[number];

export type SchedulingFilterServiceIndexedDBKeyDataType = {
	database : 'user';
	store : typeof USER_STORE_NAME;
	prefix : SchedulingFilterServiceIndexedDBKeyPrefixDataType,
	name : typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY.name |
		typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_PUBLIC_HOLIDAYS_KEY.name |
		typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SCHOOL_HOLIDAYS_KEY.name |
		typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_BIRTHDAYS_KEY.name |
		typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_ASSIGNED_TO_KEY.name |
		typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_NOT_ASSIGNED_TO_KEY.name |
		typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_WITHOUT_NEED_FOR_MORE_ASSIGNMENTS_KEY.name;
};

export const SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hideAllLeaves',
} as const;

export const SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_PUBLIC_HOLIDAYS_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hideAllPublicHolidays',
} as const;

export const SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SCHOOL_HOLIDAYS_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hideAllSchoolHolidays',
} as const;

export const SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_BIRTHDAYS_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hideAllBirthdays',
} as const;

export const SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_NOT_ASSIGNED_TO_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hideAllShiftsImNotAssignedTo',
} as const;

export const SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_ASSIGNED_TO_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hideAllShiftsImAssignedTo',
} as const;

export const SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_WITHOUT_NEED_FOR_MORE_ASSIGNMENTS_KEY = {
	database: 'user',
	store: USER_STORE_NAME,
	name: 'hideAllShiftsWithoutNeedForMoreAssignments',
} as const;

/**
 * This is a special case. In these keys, we have multiple with the same name, that can be grouped in different
 * prefixes. We usually set the which prefix should be used centrally before calling this method, allowing us to
 * avoid having a lot of very similar keys.
 *
 * @param key The key to be used in the indexedDB without the prefix
 * @param prefix The prefix which separates the different keys
 * @returns The key with the prefix
 */
export const schedulingFilterServiceIndexedDBKeyWithPrefix =
	(key : SchedulingFilterServiceIndexedDBKeys, prefix : SchedulingFilterServiceIndexedDBKeyPrefixDataType) : SchedulingFilterServiceIndexedDBKeyDataType => {
		return {...key, prefix};
	};

type SchedulingFilterServiceIndexedDBKeys =
    typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_LEAVES_KEY |
    typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_PUBLIC_HOLIDAYS_KEY |
	typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SCHOOL_HOLIDAYS_KEY |
    typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_BIRTHDAYS_KEY |
    typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_ASSIGNED_TO_KEY |
	typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_IM_NOT_ASSIGNED_TO_KEY |
	typeof SCHEDULING_FILTER_SERVICE_INDEXED_DB_HIDE_ALL_SHIFTS_WITHOUT_NEED_FOR_MORE_ASSIGNMENTS_KEY;
