/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/* eslint-disable @typescript-eslint/ban-types -- This disable-line description has been added when we enabled 'eslint-comments/require-description', Solve the remaining cases please. */
import { CurrencyPipe } from '@angular/common';
import { Optional, Pipe, PipeTransform } from '@angular/core';
import { SchedulingApiPaymentMethodType, SchedulingApiTransactionPaymentMethodType } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ClientCurrency, Euro, PApiType, PSupportedCurrencyCodes, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { PLocaleNumbersService } from '@plano/shared/core/locale-numbers.service';
import { LogService } from '@plano/shared/core/log.service';
import { PCurrencyIconName } from '@plano/shared/core/p-common/fa-icon/p-currency-icon-name.enum';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/**
 * The possible formats for the currency
 */
export const pCurrencyPipeFormats = ['code', 'symbol', 'symbol-narrow', '', true, false] as const;

/**
 * The possible formats for the currency as type
 */
export type PCurrencyPipeFormats = typeof pCurrencyPipeFormats[number];

/**
 * A pipe to turn a number into a currency string that fits the local currency syntax.
 */
// Note that the name must be `pCurrency` to make it possible to ban the usage of angular’s `currency` pipe.
// Otherwise we can not be sure which pipe is in use. It would depend only on the module structure.
@Pipe({
	name: 'pCurrency',
	// eslint-disable-next-line @angular-eslint/no-pipe-impure -- as we can pass objects as variables we need the pipe to be impure (https://github.com/angular/angular/issues/16595)
	pure: false,
	standalone: true,
})
export class PCurrencyPipe extends PBaseClass implements PipeTransform {

	constructor(console : LogService | null, currencyPipe : null);
	constructor(console : LogService | null, currencyPipe : CurrencyPipe, locale : PSupportedLocaleIds);

	/**
	 * @param console LogService instance
	 * @param currencyPipe CurrencyPipe instance.
	 * @param locale If a CurrencyPipe was passed, you must pass the same locale again here,
	 * as we can not read what you passed to the CurrencyPipe.
	 */
	constructor(
		private console : LogService | null,
		private currencyPipe : CurrencyPipe | null,
		@Optional() private locale ?: PSupportedLocaleIds,
	) {
		super();
	}

	// If you pass a string or number, currency code is required.
	// If input is not null, result can not be null.
	public transform(
		value : ClientCurrency | Euro,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
		currencyCode : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro> | null,
		display ?: PCurrencyPipeFormats,
		digitsInfo ?: string,
		locale ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string;

	// If you pass a string or number, currency code is required.
	// If input can be null or a string, result can be null.
	public transform(
		value : string | ClientCurrency | Euro | null,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
		currencyCode : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro> | null,
		display ?: PCurrencyPipeFormats,
		digitsInfo ?: string,
		locale ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string | null;

	// If you pass a AI, currency code is not required.
	public transform(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
		value : ApiAttributeInfo<any, ClientCurrency | Euro>,

		currencyCode ?: PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | null,

		display ?: PCurrencyPipeFormats,
		digitsInfo ?: string,
		locale ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string;

	/**
	 * Turn a number into a country-specific currency format.
	 * @param value amount of money as float or attributeInfo of type ClientCurrency or Euro
	 * @param currencyCode the code of the currency, or null if the currency should be determined by locale/Config
	 * @param display The format for the currency indicator. One of the following:
	 * - `code`: Show the code (such as USD).
	 * - `symbol` (default): Show the symbol (such as $).
	 * - `symbol-narrow`: Use the narrow symbol for locales that have two symbols for their currency. For example, the
	 * 		Canadian dollar CAD has the symbol CA$ and the symbol-narrow $. If the locale has no narrow symbol, uses the
	 * 		standard symbol for the locale.
	 * - `''`: UEmpty string will suppress the currency & symbol.
	 * - Boolean (marked deprecated in v5): true for symbol and false for code.
	 *
	 * 	Optional. Default is 'symbol'.
	 *
	 * @param digitsInfo Decimal representation options, specified by a string in the following format:
	 * - `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`.
	 * - `minIntegerDigits`: The minimum number of integer digits before the decimal point. Default is 1.
	 * - `minFractionDigits`: The minimum number of digits after the decimal point. Default is 2.
	 * - `maxFractionDigits`: The maximum number of digits after the decimal point. Default is 2. If not provided, the number
	 * 		will be formatted with the proper amount of digits, depending on what the ISO 4217 specifies. For example, the
	 * 		Canadian dollar has 2 digits, whereas the Chilean peso has none.
	 *
	 * Optional. Default is undefined.
	 *
	 * @param localeInput information about the country
	 * @param trimUnnecessaryZeros removes 00 from 2,00 €
	 * @param alwaysShowPrependingSign Shows prepending sign no matter if positive or negative amount.
	 * 		Turns 2€ and -3,50€ into +2€ and -3,50€
	 *
	 * @returns formatted string or null if the input could not be formatted
	 */
	public transform(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
		value : string | ClientCurrency | Euro | null | ApiAttributeInfo<any, ClientCurrency | Euro>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
		currencyCode : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro> | null = null,
		display ?: PCurrencyPipeFormats,
		digitsInfo ?: string,
		localeInput ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string | null {
		if (!this.currencyPipe) throw new Error('currencyPipe is not available');
		if (value === null) return null;

		if (typeof value === 'string') {
			value = PLocaleNumbersService.turnLocaleNumberIntoNumber(localeInput ?? this.locale ?? Config.LOCALE_ID, value, 'currency');
		}

		const locale = localeInput ?? this.locale;

		let code = (() => {
			if (currencyCode !== null) {
				return this.handleCurrencyCode(currencyCode);
			}
			if (value instanceof ApiAttributeInfo && value.type === PApiType.Euro) return 'EUR';
			// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			if (locale) return Config.getCurrencyCode(locale);
			return Config.CURRENCY_CODE;
		})();
		if (code === null) {
			code = PSupportedCurrencyCodes.EUR;
			if (Config.DEBUG) this.console?.error('currencyCode could not be determined');
		}

		if (value instanceof ApiAttributeInfo) {
			if (value.isAvailable) value = value.value;
			else value = null;
		}

		let result : string | null = null;
		if (alwaysShowPrependingSign && value !== null && value > 0) {
			// If the currency sign is at the front of the string, we have to place the prepending sign between currency
			// symbol and value.
			const invertedResult = this.currencyPipe.transform(-value, code.toString(), display, digitsInfo, locale);
			if (!invertedResult) throw new Error('invertedResult is not defined');
			// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			result = invertedResult.replace('-', `+`);
		} else {
			result = this.currencyPipe.transform(value, code.toString(), display, digitsInfo, locale);
		}

		if (value === null) {
			result = this.currencyPipe.transform(0, code.toString(), display, '1.0', locale);
			result = result!.replace('0', '–');
			return result;
		}

		result = this.trimObsoleteChars(result, trimUnnecessaryZeros, locale);

		if (result && PLocaleNumbersService.thousandsSeparators(locale ?? Config.LOCALE_ID, 'currency').includes('’'))
			result = result.replaceAll('’', '\'');

		return result;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
	private handleCurrencyCode(currencyCode : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro>) : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | null {
		if (currencyCode instanceof ApiAttributeInfo)
			return currencyCode.type === PApiType.Euro ? PSupportedCurrencyCodes.EUR : Config.CURRENCY_CODE;
		return currencyCode;
	}

	private trimObsoleteChars(
		input : string | null,
		trimUnnecessaryZeros ?: boolean,
		locale ?: PSupportedLocaleIds,
	) : string | null {
		if (!input) return input;

		let result = input;

		// Remove all unnecessary whitespace
		result = result.trim();

		// Remove trailing `,00` if trimUnnecessaryZeros is true
		if (result && trimUnnecessaryZeros) {
			const decimalSeparator = PLocaleNumbersService.decimalSeparator(locale ?? Config.LOCALE_ID, 'currency');
			const decimalPart = result.split(decimalSeparator).at(1);
			if (decimalPart === undefined) return result;
			for (const decimalPartCharacter of Array.from(decimalPart)) {
				if (decimalPartCharacter.match(/\d/u) === null) break;
				if (decimalPartCharacter === '0') continue;

				// If the decimal part contains a character that is a number but not 0, we can stop here
				return result;
			}
			result = result.split(decimalSeparator).at(0) + decimalPart.replaceAll('0', '');
		}

		return result;
	}

	/**
	 * Get a fontawesome icon name for provided currency
	 */
	public getCurrencyIcon(currencyCode ?: PSupportedCurrencyCodes | null) : PlanoFaIconPoolKeys | PCurrencyIconName {
		let code = currencyCode ?? Config.CURRENCY_CODE;
		if (code === null) {
			this.console?.error(`Currency code should be defined here. Currency code is »null«`);
			code = PSupportedCurrencyCodes.EUR;
		}
		switch (code) {
			case PSupportedCurrencyCodes.EUR : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.EUR] :
				return this.enums.PlanoFaIconContextPool.EUR;
			case PSupportedCurrencyCodes.GBP : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.GBP] :
				return this.enums.PlanoFaIconContextPool.GBP;
			case PSupportedCurrencyCodes.CHF : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.CHF] :
				return 'fa-chf-sign fa-solid';
			case PSupportedCurrencyCodes.CZK : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.CZK] :
				return PCurrencyIconName.CZK;
			case PSupportedCurrencyCodes.SEK : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.SEK] :
				return PCurrencyIconName.SEK;
			case PSupportedCurrencyCodes.RON : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.RON] :
				return PCurrencyIconName.RON;
			default :
				if (Config.DEBUG) {
					const missingCode : never = code;
					this.console?.error(`Currency not supported yet: »${missingCode}«`);
				}
				return 'fa-coins fa-duotone';
		}
	}

	/**
	 * Get a fontawesome icon name for provided payment method
	 */
	public getPaymentMethodIcon(
		paymentMethodType : SchedulingApiTransactionPaymentMethodType | SchedulingApiPaymentMethodType | null,
		name : string | null,
	) : PlanoFaIconPoolKeys | null {
		switch (paymentMethodType) {
			case SchedulingApiPaymentMethodType.ONLINE_PAYMENT:
			case SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT:
				return this.enums.PlanoFaIconContextPool.ONLINE_PAYMENT;
			case SchedulingApiTransactionPaymentMethodType.POS:
				return this.enums.PlanoFaIconContextPool.PAY_BY_CASH;
			case SchedulingApiTransactionPaymentMethodType.GIFT_CARD:
				return this.enums.PlanoFaIconContextPool.GIFT_CARD;
			case SchedulingApiTransactionPaymentMethodType.MARKETING_GIFT_CARD:
				return this.enums.PlanoFaIconContextPool.MARKETING;
			case SchedulingApiPaymentMethodType.MISC:
			case SchedulingApiTransactionPaymentMethodType.MISC:
			case null:
				break;
		}

		if (!name) return null;
		return this.determinePaymentMethodIconByName(name);
	}

	private isSomeKindOfCashRegisterPayment(name : string) : boolean {
		if (
			name.includes('kasse') ||
			name.includes('vor ort') ||
			name.includes('vorort') ||
			name.includes('vor-ort') ||
			name.includes('on-site') ||
			name.includes('bar') ||
			name.includes('theke') ||
			name.includes('halle') ||
			name.includes('counter') ||
			name.includes('wettkampftag')
		) return true;
		return false;
	}

	/* eslint sonarjs/cognitive-complexity: ["error", 22] -- FIXME: Remove this before you work here. */
	private determinePaymentMethodIconByName(name : string) : PlanoFaIconPoolKeys {
		// Write the phrases in lower case as all payment methods are set to lower case before the matching starts.
		const NAME = name.toLowerCase();
		if (this.isSomeKindOfCashRegisterPayment(NAME)) return 'fa-cash-register fa-duotone';
		if (NAME.includes('er karte') || NAME.includes('abo') || NAME.includes('dauerkarte') || NAME.includes('mitglied')) return 'fa-address-card fa-duotone';
		if (NAME.includes('überweisung')) return 'fa-money-check fa-solid';
		if (NAME.includes('bezahlung')) return 'fa-wallet fa-duotone';
		if (NAME.includes('lastschrift') || NAME.includes('einzug') || NAME.includes('sepa')) return 'fa-university fa-duotone';
		if (NAME.includes('gutschein') || NAME.includes('giftCard') || NAME.includes('gift')) return 'fa-gift fa-duotone';
		if (NAME.includes('rechnung') || NAME.includes('invoice') || NAME.includes('bill')) return 'fa-file-alt fa-duotone';
		if (NAME.includes('urban')) return 'fa-barcode fa-duotone';
		if (NAME.includes('mastercard')) return 'fa-cc-mastercard fa-brands';
		if (NAME.includes('visa')) return 'fa-cc-visa fa-brands';
		if (NAME.includes('google')) return 'fa-google-pay fa-brands';
		if (NAME.includes('apple')) return 'fa-apple-pay fa-brands';
		if (NAME.includes('amazon')) return 'fa-amazon-pay fa-brands';
		if (NAME.includes('ideal')) return 'fa-ideal fa-brands';
		if (NAME.includes('kreditkarte') || NAME.includes('credit card')) return 'fa-credit-card fa-duotone';
		if (NAME.includes('bitcoin')) return 'fa-bitcoin fa-brands';
		if (NAME.includes('paypal')) return this.enums.PlanoFaIconContextPool.BRAND_PAYPAL;
		return 'fa-piggy-bank fa-duotone';
	}
}
