/* eslint-disable max-classes-per-file -- api file */
import { FilterEvaluationTypeMatrix } from '@plano/client/work-models/work-model/detail-form/work-rule-modal-box/wizard-steps/work-rule-filters/filter-evaluation-type-matrix';
import { WorkRuleFilters } from '@plano/client/work-models/work-model/detail-form/work-rule-modal-box/wizard-steps/work-rule-filters/filters/work-rule-filter/work-rule-filter.component';
import { WorkRuleEvaluationItemType } from '@plano/client/work-models/work-model/detail-form/work-rule-modal-box/work-rule.utils';
import { SchedulingApiHolidayItemType, SchedulingApiWorkModelBase, SchedulingApiWorkModelWorkRuleBase, SchedulingApiWorkModelWorkRuleVersionBase, SchedulingApiWorkModelWorkRuleVersionCheckElementBase, SchedulingApiWorkModelWorkRuleVersionCheckScopeBase, SchedulingApiWorkModelWorkRuleVersionFilterListBase, SchedulingApiWorkModelWorkRuleVersionsBase, SchedulingApiWorkModelWorkRuleVersionThresholdBase, SchedulingApiWorkRuleCheckElementType, SchedulingApiWorkRuleCheckScopeType, SchedulingApiWorkRuleThresholdType } from '@plano/shared/api';
import { DateExclusiveEnd, PApiType, Percent } from '@plano/shared/api/base/generated-types.ag';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { PMath } from '@plano/shared/core/utils/math-utils';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { stringMatchesSearchTerm } from '@plano/shared/core/utils/search-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- already extends another class
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

/**
 * Possible units for the work rule check elements.
 */
export enum WorkRuleCheckElementTypeUnit {
	COUNT = 0,
	HOURS = 1,
	DAYS = 2,
	EARNINGS = 3,
}

/**
 * Class to add custom implementations to the generated API class {@link SchedulingApiWorkModelWorkRuleVersionBase}.
 */
export class SchedulingApiWorkModelWorkRuleVersions extends SchedulingApiWorkModelWorkRuleVersionsBase {
	/**
	 * Checks if there multiple versions with overlapping active periods.
	 */
	public checkOverlappingActivePeriods() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.OVERLAPPING_VERSIONS, fn: (_control) => {
			for (const version1 of this.iterable()) {
				for (const version2 of this.iterable()) {
					if (version1 === version2) {
						continue;
					}

					// skip validation check if any of the active periods is undefined
					if (version1.activePeriodStart === undefined ||
						version1.activePeriodEnd === undefined ||
						version2.activePeriodStart === undefined ||
						version2.activePeriodEnd === undefined
					) {
						continue;
					}

					// To simplify the check, we convert "null" values to NEGATIVE_INFINITY/POSITIVE_INFINITY.
					const start1 = version1.activePeriodStart ?? Number.NEGATIVE_INFINITY;
					const end1 = version1.activePeriodEnd ?? Number.POSITIVE_INFINITY;

					const start2 = version2.activePeriodStart ?? Number.NEGATIVE_INFINITY;
					const end2 = version2.activePeriodEnd ?? Number.POSITIVE_INFINITY;

					// The active periods are overlapping?
					if (start1 < end2 && start2 < end1) {
						return { [PPossibleErrorNames.OVERLAPPING_VERSIONS]: {
							name: PPossibleErrorNames.OVERLAPPING_VERSIONS,
							type: PApiType.ApiList,
						}};
					}
				}
			}

			return null;
		}});
	}
}

/**
 * Enum to define the time range (past, present, future) of a work rule version.
 */
export enum SchedulingApiWorkModelWorkRuleVersionTensesEnum {
	PAST = 'PAST',
	PRESENT = 'PRESENT',
	FUTURE = 'FUTURE',
}

/**
 * Class to add custom implementations to the generated API class {@link SchedulingApiWorkModelWorkRuleVersionBase}.
 */
export class SchedulingApiWorkModelWorkRuleVersion extends SchedulingApiWorkModelWorkRuleVersionBase {
	/**
	 * The active period of this work rule version as formatted string.
	 */
	public get activePeriodFormatted() : string {
		return SchedulingApiWorkModelWorkRuleVersion.getActivePeriodFormatted(this.api.localizePipe, this.api.datePipe, this.activePeriodStart, this.activePeriodEnd);
	}

	/**
	 * Transforms a date into a formatted string, based on the date pipe.
	 * @param localize The localize pipe to translate strings.
	 * @param datePipe The date pipe to format dates.
	 * @param date The date to format.
	 */
	private static getDateFormatted(localize : LocalizePipe, datePipe : PDatePipe, date : Date | null | number | undefined) : string {
		switch (date) {
			case null:
				return localize.transform('unbegrenzt');

			case undefined:
				return datePipe.transform(date, 'shortDate');
			default:
				if (date instanceof Date) {
					return datePipe.transform(date.valueOf(), 'shortDate');
				} else {
					return datePipe.transform(date, 'shortDate');
				}
		}
	}

	/**
	 * Method to create a string representation of the active period of a work rule version.
	 * @param localize The localize pipe to translate strings.
	 * @param datePipe The date pipe to format dates.
	 * @param start The start of the active period.
	 * @param end The exclusive end of the active period.
	 * @param [markDates=false] If true, the dates will be wrapped with mark tags.
	 * @returns the active period of a work rule version as formatted string.
	 */
	public static getActivePeriodFormatted(
		localize : LocalizePipe,
		datePipe : PDatePipe,
		start : Date | number | null | undefined,
		end : DateExclusiveEnd | number | null | undefined,
		markDates : boolean = false,
	) : string {
		const paramForLocalize = {
			start: SchedulingApiWorkModelWorkRuleVersion.getDateFormatted(localize, datePipe, start),
			end: SchedulingApiWorkModelWorkRuleVersion.getDateFormatted(localize, datePipe, end ? end - 1 : end),
		};
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- need to set a class with quotation marks
		const sourceString : PDictionarySourceString = markDates ? '<mark>${start}</mark> bis <mark>${end}</mark>' : '${start} <span class="text-muted">bis</span> ${end}';

		return localize.transform({sourceString, params: paramForLocalize});
	}

	/**
	 * Get the tense (past, present, future) of the active period of the work rule version.
	 */
	public get tense() : SchedulingApiWorkModelWorkRuleVersionTensesEnum {
		const today = this.api.pMoment.m().startOf('day').valueOf();

		// if one of the dates is undefined, we assume the tense is past, so the led is turned off
		if (this.activePeriodStart === undefined || this.activePeriodEnd === undefined) {
			return SchedulingApiWorkModelWorkRuleVersionTensesEnum.PAST;
		}

		const start = this.activePeriodStart ?? Number.NEGATIVE_INFINITY;
		const end = this.activePeriodEnd ?? Number.POSITIVE_INFINITY;
		if (start <= today && today < end) {
			return SchedulingApiWorkModelWorkRuleVersionTensesEnum.PRESENT;
		} else if (today < start) {
			return SchedulingApiWorkModelWorkRuleVersionTensesEnum.FUTURE;
		} else {
			return SchedulingApiWorkModelWorkRuleVersionTensesEnum.PAST;
		}
	}

	/**
	 * Get the correct led theme, based on the active period of the work rule version, and on the current date.
	 * If the active period is in the future, the theme is 'info'.
	 * If the active period includes today, the theme is 'success'.
	 * If the active period is in the past, null is returned, meaning the led should be turned off.
	 */
	public get ledTheme() : typeof enumsObject.PThemeEnum.INFO | typeof enumsObject.PThemeEnum.SUCCESS | null {
		switch (this.tense) {
			case SchedulingApiWorkModelWorkRuleVersionTensesEnum.FUTURE:
				return enumsObject.PThemeEnum.INFO;
			case SchedulingApiWorkModelWorkRuleVersionTensesEnum.PRESENT:
				return enumsObject.PThemeEnum.SUCCESS;
			case SchedulingApiWorkModelWorkRuleVersionTensesEnum.PAST:
				return null;
		}
	}

	/**
	 * TODO: PLANO-186108
	 */
	public get matches() : Percent | null {
		// TODO: PLANO-186108 Implement this method
		return 1;
	}
}

/**
 * Class to add custom implementations to the generated API class {@link SchedulingApiWorkModelWorkRuleVersionThresholdBase}.
 */
export class SchedulingApiWorkModelWorkRuleVersionThreshold extends SchedulingApiWorkModelWorkRuleVersionThresholdBase {
	/**
	 * The type of the API `threshold` attribute.
	 */
	public get thresholdApiType() : PApiType.number | PApiType.ClientCurrency {
		const version = this.parent;

		// use a custom api-type based on the unit of the first check element?
		// As all check elements should have the same unit, we can use the first one.
		if (version !== null) {
			const firstCheckElement = version.workRuleCheckElements.get(0);

			if (firstCheckElement !== null && firstCheckElement.unit === WorkRuleCheckElementTypeUnit.EARNINGS) {
				return PApiType.ClientCurrency;
			}
		}

		// Default api-type is number
		return PApiType.number;
	}

	/**
	 * @param base The base around which the whole warning intervals are grouped. See `startPercentage` and `endPercentage` for more details.
	 * @param startPercentage The start of the interval will be calculated as `base [+ / -] threshold * startPercentage`. The operator [+ / -] is defined by `startPositive`.
	 * @param startPositive Defining the [+ / -] operator for `startPercentage`.
	 * @param endPercentage The end of the interval will be calculated as `base [+ / -] threshold * endPercentage`. The operator [+ / -] is defined by `endPositive`.
	 * @param endPositive Defining the `[+ / -]` operator for `endPercentage`.
	 * @returns a warning-interval formatted as string.
	 */
	public getIntervalFormatted(
		base : number | null,
		startPercentage : Percent | null,
		startPositive : boolean,
		endPercentage : Percent | null,
		endPositive : boolean,
	) : string {
		const start = this.getIntervalValue(base, startPercentage, startPositive);
		const end = this.getIntervalValue(base, endPercentage, endPositive);

		if (start === null && end !== null) {
			return `Bis ${this.api.decimalPipe.transform(PMath.roundToDecimalPlaces(end, 2))}`;
		} else if (start !== null && end === null) {
			return `Ab ${this.api.decimalPipe.transform(PMath.roundToDecimalPlaces(start, 2))}`;
		} else if (start !== null && end !== null) {
			return `${this.api.decimalPipe.transform(PMath.roundToDecimalPlaces(start, 2))} bis ${this.api.decimalPipe.transform(PMath.roundToDecimalPlaces(end, 2))}`;
		} else {
			return '';
		}
	}

	private getIntervalValue(base : Percent | null, percentage : Percent | null, positive : boolean) : number | null {
		if (percentage === null || base === null)
			return null;

		const absoluteDeviation = this.threshold * percentage;
		return positive ? base + absoluteDeviation : base - absoluteDeviation;
	}

	/**
	 * Get the label for the provided threshold type.
	 * @param thresholdType The threshold type to get the label for.
	 */
	public static getLabel(thresholdType : SchedulingApiWorkRuleThresholdType) : PDictionarySourceString {
		switch (thresholdType) {
			case SchedulingApiWorkRuleThresholdType.MINIMUM:
				return 'Minimum';
			case SchedulingApiWorkRuleThresholdType.MAXIMUM:
				return 'Maximum';
			case SchedulingApiWorkRuleThresholdType.BALANCE:
				return 'Guthaben';
			case SchedulingApiWorkRuleThresholdType.TARGET:
				return 'Soll';
		}
	}

	/**
	 * Get the label for the threshold type.
	 */
	public get label() : PDictionarySourceString {
		return SchedulingApiWorkModelWorkRuleVersionThreshold.getLabel(this.thresholdType);
	}

	/**
	 * Get the icon for the threshold type.
	 * @param thresholdType The threshold type to get the icon for.
	 */
	public static getIcon(thresholdType : SchedulingApiWorkRuleThresholdType) : PlanoFaIconPoolKeys {
		switch (thresholdType) {
			case SchedulingApiWorkRuleThresholdType.MINIMUM:
				return 'fa-arrow-left-to-line fa-duotone';
			case SchedulingApiWorkRuleThresholdType.MAXIMUM:
				return 'fa-arrow-right-to-line fa-duotone';
			case SchedulingApiWorkRuleThresholdType.BALANCE:
				return 'fa-sack fa-duotone';
			case SchedulingApiWorkRuleThresholdType.TARGET:
				return 'fa-bullseye-pointer fa-duotone';
		}
	}

	/**
	 * Get the icon for the threshold type.
	 */
	public get icon() : PlanoFaIconPoolKeys {
		return SchedulingApiWorkModelWorkRuleVersionThreshold.getIcon(this.thresholdType);
	}
}

/**
 * Class to add custom implementations to the generated API class {@link SchedulingApiWorkModelBase}.
 */
export class SchedulingApiWorkModel extends SchedulingApiWorkModelBase {

	/**
	 * How many users are assigned to this work model?
	 */
	public get amountOfAssignedUsers() : number | null {
		// TODO: PLANO-186108 Implement this method
		// TODO: PLANO-186108 Filter out/ignore the deleted users
		return 0;
	}

	/**
	 * How many work rules are defined for this work model?
	 * @returns the amount of work rules or null if the data is not available.
	 */
	public get amountOfWorkRules() : number | null {
		if (!this.aiWorkRules.isAvailable) return null;
		return this.workRules.length;
	}

	/**
	 * The amount of pay differentials for this work model.
	 */
	public get amountOfPayDifferentials() : number {
		// TODO: PLANO-186108 Implement this method
		return 0;
	}

	/**
	 * The % of how many rules assigned to users via a work model have the same threshold as defined in the work model
	 * (not overwritten on the users' side).
	 * @returns
	 * 	the percentage of matches or null if
	 * 	- the data is not available.
	 *  - the work model has no users assigned.
	 *  - the work model has no work rules defined.
	 */
	public get matches() : Percent | null {
		if (!this.aiWorkRules.isAvailable) return null;

		// The WorkModel has no users assigned
		if (
			this.amountOfAssignedUsers === null ||
			this.amountOfAssignedUsers === 0
		) return null;

		// The WorkModel has no work rules defined
		if (
			this.amountOfWorkRules === null ||
			this.amountOfWorkRules === 0
		) return null;

		// TODO: PLANO-186108 Implement this method
		return 1;
	}

	/**
	 * Check if this item fits to the given search term.
	 * @param searchTerm The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(searchTerm : string) : boolean {
		return stringMatchesSearchTerm(this.name, searchTerm);
	}
}

/**
 * Class to add custom implementations to the generated API class {@link SchedulingApiWorkModelWorkRuleBase}.
 */
export class SchedulingApiWorkModelWorkRule extends SchedulingApiWorkModelWorkRuleBase {

	/**
	 * Check if this item fits to the given search term.
	 * @param searchTerm The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(searchTerm : string) : boolean {
		return stringMatchesSearchTerm(this.name, searchTerm);
	}
}

/**
 * Wrapper for the SchedulingApiWorkModelWorkRuleVersionFilterListBase class to provide additional methods.
 */
export class SchedulingApiWorkModelWorkRuleVersionFilterList extends SchedulingApiWorkModelWorkRuleVersionFilterListBase {

	/**
	 * Matrix to determine the correct filter evaluation type based on the evaluation item type.
	 * These values match the ones in https://docs.google.com/spreadsheets/d/e/2PACX-1vRwgVqKVWkqbWUSF6TotTiufR9cz_nxiRAN1Wi3gWEQ0dg55_4Y5EyTFHloW5ah1AxRTz9--mvSUbYB/pubhtml
	 */
	public filterEvaluationTypeMatrix = new FilterEvaluationTypeMatrix(this);

	/**
	 * Returns the check-scope or check-element to which this filter-list belongs.
	 */
	public get workRuleVersionCheckObject() : SchedulingApiWorkModelWorkRuleVersionCheckScope | SchedulingApiWorkModelWorkRuleVersionCheckElement | null {
		// When the element gets destroyed the parent is removed, so we need to check if the parent is available, otherwise we get a throw when removing the element.
		if (!this.parent?.parent) return null;

		if (this.checkElementId !== null) return this.parent.parent.workRuleCheckElements.get(this.checkElementId)!;
		return this.parent.parent.workRuleCheckScope;
	}

	/**
	 * Get the corresponding hint message for the filter, based on the validation value.
	 * @param filters The filters to validate
	 * @param [holidayType=null] The holiday-type to validate the filters for. In most cases it isn't relevant, but for holiday-item-label filters it is.
	 */
	public getCannotSetHintNotSupportedFilter(filters : WorkRuleFilters, holidayType : SchedulingApiHolidayItemType | null = null) : PDictionarySourceString | null {
		if (!this.workRuleVersionCheckObject) return null;
		const evaluationItemType = this.workRuleVersionCheckObject.evaluationItemType;
		const matrixValue = this.filterEvaluationTypeMatrix.get(evaluationItemType, filters, holidayType);
		if (matrixValue === null) {
			if (this.workRuleVersionCheckObject instanceof SchedulingApiWorkModelWorkRuleVersionCheckElement) {
				return 'Dieser Filter passt nicht zum ausgewählten Prüfelement.';
			} else if (this.workRuleVersionCheckObject instanceof SchedulingApiWorkModelWorkRuleVersionCheckScope) {
				return 'Dieser Filter passt nicht zum ausgewählten Prüfbereich.';
			}
		}
		if (filters.length > 0) {
			return 'Dieser Filter ist schon vorhanden und kann nicht nochmal hinzugefügt werden.';
		}
		return null;
	}

	/**
	 * The method to validate the filters for the work rule version. It ensures that only supported filters are used.
	 * @param filters The filters to validate
	 * @param [holidayType=null] The holiday-type to validate the filters for. In most cases it isn't relevant, but for holiday-item-label filters it is.
	 */
	public validateOnlySupportedFiltersAreUsed(filters : WorkRuleFilters, holidayType : SchedulingApiHolidayItemType | null = null) : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.INVALID_FILTER, fn: (_control) => {
			if (!this.workRuleVersionCheckObject) return null;
			const evaluationItemType = this.workRuleVersionCheckObject.evaluationItemType;
			const matrixValue = this.filterEvaluationTypeMatrix.get(evaluationItemType, filters, holidayType);
			if (matrixValue === null) {
				return { [PPossibleErrorNames.INVALID_FILTER]: {
					name: PPossibleErrorNames.INVALID_FILTER,
					type: PApiType.ApiObject,
					errorText: this.getCannotSetHintNotSupportedFilter(filters, holidayType)!,
				}};
			}

			return null;
		}});
	}
}

/**
 * Wrapper for the SchedulingApiWorkModelWorkRuleVersionCheckElementBase class to provide additional methods.
 */
export class SchedulingApiWorkModelWorkRuleVersionCheckElement extends SchedulingApiWorkModelWorkRuleVersionCheckElementBase {
	/**
	 * Get the evaluation item type for this check element based on the type.
	 */
	public get evaluationItemType() : WorkRuleEvaluationItemType {
		switch (this.type) {
			case SchedulingApiWorkRuleCheckElementType.LEAVE_COUNT:
			case SchedulingApiWorkRuleCheckElementType.LEAVE_DAYS:
			case SchedulingApiWorkRuleCheckElementType.LEAVE_EARNINGS:
			case SchedulingApiWorkRuleCheckElementType.LEAVE_HOURS:
				return WorkRuleEvaluationItemType.LEAVE;
			case SchedulingApiWorkRuleCheckElementType.PAUSE_COUNT:
			case SchedulingApiWorkRuleCheckElementType.PAUSE_HOURS:
				return WorkRuleEvaluationItemType.PAUSE;
			case SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_COUNT:
			case SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_EARNINGS:
			case SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_HOURS:
				return WorkRuleEvaluationItemType.PAY_DIFFERENTIALS;
			case SchedulingApiWorkRuleCheckElementType.REST_PERIOD_COUNT:
			case SchedulingApiWorkRuleCheckElementType.REST_PERIOD_HOURS:
				return WorkRuleEvaluationItemType.REST_PERIOD;
			case SchedulingApiWorkRuleCheckElementType.SHIFT_PREFERENCE_COUNT:
			case SchedulingApiWorkRuleCheckElementType.SHIFT_PREFERENCE_HOURS:
				return WorkRuleEvaluationItemType.SHIFT_PREFERENCE;
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_COUNT:
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_HOURS:
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_EARNINGS:
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_DAYS:
				return WorkRuleEvaluationItemType.WORK_SESSION;
		}
	}

	/**
	 * Get the label for the provided check element group
	 * @param group The group to get the label for.
	 */
	public static getCheckElementGroupLabel(group : WorkRuleEvaluationItemType) : PDictionarySourceString {
		switch (group) {
			case WorkRuleEvaluationItemType.WORK_SESSION:
				return 'Arbeitseinsätze';
			case WorkRuleEvaluationItemType.LEAVE:
				return 'Abwesenheit';
			case WorkRuleEvaluationItemType.PAUSE:
				return 'Pausen';
			case WorkRuleEvaluationItemType.REST_PERIOD:
				return 'Ruhezeiten';
			case WorkRuleEvaluationItemType.SHIFT_PREFERENCE:
				return 'Schichtwünsche';
			case WorkRuleEvaluationItemType.PAY_DIFFERENTIALS:
				return 'Zuschläge';
			default: throw new Error('Not a supported group yet!');
		}
	}

	/**
	 * Get the icon for the provided check element group
	 * @param group The group to get the icon for.
	 */
	public static getCheckElementGroupIcon(group : WorkRuleEvaluationItemType) : PlanoFaIconPoolKeys {
		switch (group) {
			case WorkRuleEvaluationItemType.WORK_SESSION:
				return 'fa-screwdriver-wrench fa-duotone';
			case WorkRuleEvaluationItemType.LEAVE:
				return 'fa-ghost fa-duotone';
			case WorkRuleEvaluationItemType.PAUSE:
				return 'fa-mug-hot fa-duotone';
			case WorkRuleEvaluationItemType.REST_PERIOD:
				return 'fa-snooze fa-duotone';
			case WorkRuleEvaluationItemType.SHIFT_PREFERENCE:
				return 'fa-heart fa-duotone';
			case WorkRuleEvaluationItemType.PAY_DIFFERENTIALS:
				return 'fa-piggy-bank fa-duotone';
			default: throw new Error('Not a supported group yet!');
		}
	}

	/**
	 * Get the icon for the check element group
	 */
	public get groupIcon() : PlanoFaIconPoolKeys {
		return SchedulingApiWorkModelWorkRuleVersionCheckElement.getCheckElementGroupIcon(this.evaluationItemType);
	}

	/**
	 * Get the label for the check element group
	 */
	public get groupLabel() : PDictionarySourceString {
		return SchedulingApiWorkModelWorkRuleVersionCheckElement.getCheckElementGroupLabel(this.evaluationItemType);
	}

	/**
	 * Get the unit of the check element based on the type.
	 */
	public get unit() : WorkRuleCheckElementTypeUnit {
		switch (this.type) {
			case SchedulingApiWorkRuleCheckElementType.LEAVE_COUNT:
			case SchedulingApiWorkRuleCheckElementType.PAUSE_COUNT:
			case SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_COUNT:
			case SchedulingApiWorkRuleCheckElementType.REST_PERIOD_COUNT:
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_COUNT:
			case SchedulingApiWorkRuleCheckElementType.SHIFT_PREFERENCE_COUNT:
				return WorkRuleCheckElementTypeUnit.COUNT;
			case SchedulingApiWorkRuleCheckElementType.LEAVE_DAYS:
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_DAYS:
				return WorkRuleCheckElementTypeUnit.DAYS;
			case SchedulingApiWorkRuleCheckElementType.LEAVE_HOURS:
			case SchedulingApiWorkRuleCheckElementType.PAUSE_HOURS:
			case SchedulingApiWorkRuleCheckElementType.REST_PERIOD_HOURS:
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_HOURS:
			case SchedulingApiWorkRuleCheckElementType.SHIFT_PREFERENCE_HOURS:
			case SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_HOURS:
				return WorkRuleCheckElementTypeUnit.HOURS;
			case SchedulingApiWorkRuleCheckElementType.LEAVE_EARNINGS:
			case SchedulingApiWorkRuleCheckElementType.PAY_DIFFERENTIALS_EARNINGS:
			case SchedulingApiWorkRuleCheckElementType.WORK_SESSION_EARNINGS:
				return WorkRuleCheckElementTypeUnit.EARNINGS;
		}
	}

	/**
	 * Get the label for the provided unit
	 * @param unit The unit to get the label for.
	 */
	public static getUnitLabel(unit : WorkRuleCheckElementTypeUnit) : PDictionarySourceString {
		switch (unit) {
			case WorkRuleCheckElementTypeUnit.COUNT:
				return 'Anzahl';
			case WorkRuleCheckElementTypeUnit.DAYS:
				return 'Tage';
			case WorkRuleCheckElementTypeUnit.HOURS:
				return 'Stunden';
			case WorkRuleCheckElementTypeUnit.EARNINGS:
				return 'Verdienst';
		}
	}

	/**
	 * Get the label for the unit
	 */
	public get unitLabel() : PDictionarySourceString {
		return SchedulingApiWorkModelWorkRuleVersionCheckElement.getUnitLabel(this.unit);
	}

	/**
	 * Ensure that all check elements have the same unit.
	 */
	public ensureCheckElementsHaveSameUnit() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DIFFERENT_UNIT, fn: (_control) => {
			const allUnits = new Set<WorkRuleCheckElementTypeUnit>();

			const versions = this.parent?.parent?.parent;

			if (!versions) return null;

			for (const version of versions.iterable()) {
				for (const checkElement of version.workRuleCheckElements.iterable()) {
					if (checkElement.aiType.value !== null)
						allUnits.add(checkElement.unit);
				}
			}

			if (allUnits.size > 1) {
				let listOfUnits = '';
				const arrayOfUnits = Array.from(allUnits);
				for (const [index, unit] of arrayOfUnits.entries()) {
					if (index === arrayOfUnits.length - 1 && arrayOfUnits.length > 1) {
						listOfUnits = `${listOfUnits} ${this.api.localizePipe.transform('und')} `;
					} else if (index > 0 && arrayOfUnits.length > 1) {
						listOfUnits = `${listOfUnits}, `;
					}
					switch (unit) {
						case WorkRuleCheckElementTypeUnit.COUNT:
							listOfUnits = `${listOfUnits}${this.api.localizePipe.transform('Anzahl')}`;
							break;
						case WorkRuleCheckElementTypeUnit.DAYS:
							listOfUnits = `${listOfUnits}${this.api.localizePipe.transform('Tage')}`;
							break;
						case WorkRuleCheckElementTypeUnit.HOURS:
							listOfUnits = `${listOfUnits}${this.api.localizePipe.transform('Stunden')}`;
							break;
						case WorkRuleCheckElementTypeUnit.EARNINGS:
							listOfUnits = `${listOfUnits}${this.api.localizePipe.transform('Verdienst')}`;
							break;
					}
				}
				listOfUnits = `${listOfUnits}.`;

				const onlyOneVersion = versions.filterBy(version => version.workRuleCheckElements.some(checkElement => checkElement.aiType.value !== null)).length === 1;

				return { [PPossibleErrorNames.DIFFERENT_UNIT]: {
					name: PPossibleErrorNames.DIFFERENT_UNIT,
					type: PApiType.string,
					listOfUnits: listOfUnits,
					errorText: onlyOneVersion ?
						'Du kannst nur Prüfelemente mit derselben Einheit kombinieren. Aktuell hast du in dieser Regelvariante unterschiedliche Einheiten gemischt: ${listOfUnits}' :
						'Du kannst nur Prüfelemente mit derselben Einheit kombinieren. Aktuell hast du in dieser und anderen Regelvariante unterschiedliche Einheiten gemischt: ${listOfUnits}',
				}};
			}
			return null;
		}});
	}
}

/**
 * Wrapper for the SchedulingApiWorkModelWorkRuleVersionCheckScopeBase class to provide additional methods.
 */
export class SchedulingApiWorkModelWorkRuleVersionCheckScope extends SchedulingApiWorkModelWorkRuleVersionCheckScopeBase {
	/**
	 * Get the evaluation item type for this check scope based on the type.
	 */
	public get evaluationItemType() : WorkRuleEvaluationItemType {
		switch (this.type) {
			case SchedulingApiWorkRuleCheckScopeType.DAYS:
			case SchedulingApiWorkRuleCheckScopeType.WEEKS:
			case SchedulingApiWorkRuleCheckScopeType.INDIVIDUAL_WEEKDAYS:
			case SchedulingApiWorkRuleCheckScopeType.MONTHS:
				return WorkRuleEvaluationItemType.TIME_PERIOD;
			case SchedulingApiWorkRuleCheckScopeType.WORK_SESSIONS:
				return WorkRuleEvaluationItemType.WORK_SESSION;
			case SchedulingApiWorkRuleCheckScopeType.PUBLIC_HOLIDAYS:
				return WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS;
			case SchedulingApiWorkRuleCheckScopeType.SCHOOL_HOLIDAYS:
				return WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS;
		}
	}
}
