<fa-icon
	[pTooltip]="tooltipRef"
	[pTooltipPlacement]="placement"
	[pTooltipTheme]="theme ?? enums.PThemeEnum.DARK"
	[pTooltipTrigger]="pTooltipTrigger"
	[pTooltipShow]="pTooltipShow"
	[pTooltipAppendToBody]="pTooltipAppendToBody"
	[pTooltipFloatUIElementClass]="pTooltipFloatUIElementClass"
	[pTooltipMaxWidth]="pTooltipMaxWidth"
	[pTooltipMinWidth]="pTooltipMinWidth"
	[pTooltipAlwaysOpened]="pTooltipAlwaysOpened"
	[class]="'clickable ' + (spacing === 'default' ? (Config.IS_MOBILE ? 'btn btn-sm m-0' : 'px-2') : '')"
	[class.small]="size === 'small'"
	[icon]="icon"
	[theme]="themeForIcon"
	[pTooltipPositionedFixed]="false"
	(click)="onClickIcon($event)"
/>

<ng-template #tooltipRef>
	<div class="card-body p-2 pt-1 text-wrap">
		<h6 *ngIf="pTooltipHeadline !== null">{{pTooltipHeadline}}</h6>
		<ng-container *ngTemplateOutlet="innerTemplate" />
	</div>
</ng-template>

<ng-template
	#ngContentRef="pModalTemplateExportAsRef"
	[pModalId]="null"
	pModalTemplate
	[theme]="theme ?? enums.PThemeEnum.DARK"
	let-c="close"
	let-d="dismiss"
>
	<p-modal-content
		(onClose)="c($event)"
		(onDismiss)="d($event)"
		closeBtnLabel="Ok" i18n-closeBtnLabel
		[hideDismissBtn]="true"
		[modalTitle]="pTooltipHeadline">
		<p-modal-content-body>
			<ng-container *ngTemplateOutlet="innerTemplate" />
		</p-modal-content-body>
	</p-modal-content>
</ng-template>
