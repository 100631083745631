/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { ApiLists, ApiListWrapper, SchedulingApiActivityArea, SchedulingApiMember, SchedulingApiMembers, SchedulingApiServiceBase, SchedulingApiShiftModelAssignableMember, SchedulingApiShiftModelAssignableMembersBase, SchedulingApiShiftModelBase, SchedulingApiShiftModelCancellationPolicy, SchedulingApiShiftModelsBase, SchedulingApiShiftRepetitionType } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { notNull } from '@plano/shared/core/utils/null-type-utils';
import { stringCompare } from '@plano/shared/core/utils/sorting-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModel extends SchedulingApiShiftModelBase {
	/**
	 * The activity-area this shift-model is assigned to.
	 */
	public get activityArea() : SchedulingApiActivityArea {
		return notNull(this.api.data.activityAreas.get(this.activityAreaId));
	}

	/**
	 * The name of the activity-area this shift-model is assigned to.
	 */
	public get activityAreaName() : string {
		return this.activityArea.name;
	}

	/**
	 * Check if this shiftModel fits to the given search term.
	 * @param searchTerm The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(searchTerm : string | null) : boolean {
		if (searchTerm === null) return true;
		if (searchTerm === '') return true;
		for (const termItem of searchTerm.split(' ')) {
			const termLow = termItem.toLowerCase();

			if (this.name.toLowerCase().includes(termLow)) continue;
			if (this.aiCourseTitle.isAvailable && this.courseTitle?.toLowerCase().includes(termLow)) continue;
			if (this.activityArea.name.toLowerCase().includes(termLow)) continue;
			if (this.activityArea.emoji === termLow) continue;

			return false;
		}
		return true;
	}

	/**
	 * Does this item belong to an interval?
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get hasRepetition() : boolean | null {
		if (this.repetition.aiType.value === null) return false;
		return this.repetition.type !== SchedulingApiShiftRepetitionType.NONE;
	}

	public set hasRepetition(value : boolean | null) {
		if (!value) {
			this.clearRepetitionType();
		} else if (
			this.repetition.aiType.value === SchedulingApiShiftRepetitionType.NONE ||
			this.repetition.aiType.value === null
		) {
			if (
				this.repetition.packetRepetition.aiType.value === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS
			) {
				this.initRepetitionTypeMonth();
			} else {
				this.initRepetitionTypeWeek();
			}
		}
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private initRepetitionTypeMonth() : void {
		if (this.repetition.aiType.value !== SchedulingApiShiftRepetitionType.EVERY_X_MONTHS) {
			this.repetition.aiType.value = SchedulingApiShiftRepetitionType.EVERY_X_MONTHS;
		}
		this.repetition.aiX.value = 1;
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private initRepetitionTypeWeek() : void {
		if (this.repetition.aiType.value !== SchedulingApiShiftRepetitionType.EVERY_X_WEEKS) {
			this.repetition.aiType.value = SchedulingApiShiftRepetitionType.EVERY_X_WEEKS;
		}
		this.repetition.aiX.value = 1;
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private clearRepetitionType() : void {
		this.repetition.aiType.value = SchedulingApiShiftRepetitionType.NONE;
	}

	/**
	 * Should the shifts of this model belong to a packet per default?
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get isPacket() : boolean | null {
		if (!this.repetition.rawData) return null;
		return this.repetition.packetRepetition.type !== SchedulingApiShiftRepetitionType.NONE;
	}

	public set isPacket(input : boolean | null) {
		if (!input) {
			this.repetition.packetRepetition.aiType.value = SchedulingApiShiftRepetitionType.NONE;
		} else if (
			this.repetition.packetRepetition.aiType.value === SchedulingApiShiftRepetitionType.NONE ||
			this.repetition.packetRepetition.aiType.value === null
		) {
			this.repetition.packetRepetition.aiType.value = SchedulingApiShiftRepetitionType.EVERY_X_WEEKS;
			this.repetition.packetRepetition.aiX.value = 1;
		}
	}

	/**
	 * Check if there are any shifts selected which are related to this activity.
	 */
	public get relatedShiftsSelected() : boolean {
		return this.api.data.shifts.selectedItems.some(item => item.relatesTo(this));
	}

	/** @see ApiDataWrapperBase#_updateRawData */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public override _updateRawData(data : any[], generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		// A new shift-model must have a cancellation-policy. So, we create it.
		if (this.isNewItem && this.cancellationPolicies.length === 0) {
			const cancellationPolicy = this.cancellationPolicies.createNewItem();
			this.currentCancellationPolicyId = cancellationPolicy.id;
		}
	}

	public override copy() : SchedulingApiShiftModel {
		// copy
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const copy = super.copy((data : any[]) => {
			// don’t use ids of shift-model assignable members for id replacement as they use member ids
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const dontAddToIdReplacementList : any[] = [];

			const assignableMembersRawData = data[this.api.consts.SHIFT_MODEL_ASSIGNABLE_MEMBERS];
			for (let i = 1; i < assignableMembersRawData.length; ++i)
				dontAddToIdReplacementList.push(assignableMembersRawData[i]);

			return dontAddToIdReplacementList;
		});

		// When copying a shift-model we don’t need the trashed tariffs/payment-methods anymore
		// as they are only referenced by the bookings of the source shift-model.
		for (let i = copy.coursePaymentMethods.length - 1; i >= 0; --i) {
			const coursePaymentMethod = copy.coursePaymentMethods.get(i);
			if (!coursePaymentMethod) throw new Error('Could not get paymentMethod');
			if (coursePaymentMethod.trashed)
				copy.coursePaymentMethods.remove(i);
		}

		for (let i = copy.courseTariffs.length - 1; i >= 0; --i) {
			const courseTariff = copy.courseTariffs.get(i);
			if (!courseTariff) throw new Error('Could not get tariff');
			if (courseTariff.trashed)
				copy.courseTariffs.remove(i);
		}

		return copy;
	}

	/** Give the user some hints about what has been copied and what not
	 * This method is separated from the copy method as we use the copy method of
	 * shiftmodels when creating shifts but there we don't want to show the copy hints.
	 * For that reason this method needs to be called separately when copying an
	 * existing shiftmodel.
	 *
	 * @param originalShiftModel shiftmodel from which to copy the information
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	*/
	public showCopyHints(originalShiftModel : SchedulingApiShiftModelBase) : void {
		let title : string | null = null;
		title = this.api.localizePipe.transform('Daten erfolgreich kopiert');
		const description = this.api.localizePipe.transform({
			sourceString: 'Das Formular wurde mit den Daten aus der Tätigkeit »${name}« vorausgefüllt.',
			params: {name: originalShiftModel.name},
		});
		this.api.toasts.addToast({
			title: title,
			content: description,
			visibilityDuration: 'infinite',
			theme: enumsObject.PThemeEnum.INFO,
		});

		if (originalShiftModel.isCourse) {
			this.api.toasts.addToast({
				content: originalShiftModel.marketingGiftCardSettings.activated ?
					this.api.localizePipe.transform('Prüfe bitte die <mark>Buchungseinstellungen</mark> sowie die Einstellung für <mark>Marketing-Gutscheine</mark>, da nicht alles übernommen werden konnte.') :
					this.api.localizePipe.transform('Prüfe bitte die <mark>Buchungseinstellungen</mark>, da nicht alles übernommen werden konnte.'),
				visibilityDuration: 'infinite',
				theme: enumsObject.PThemeEnum.WARNING,
			});
		}

	}

	/**
	 * Run this if user decides against "Only whole course is bookable, not single slots".
	 * In case there are tariffs that dont fit to that decision, they will be removed.
	 */
	public clearMisfittingTariffs() : void {
		const fees = this.courseTariffs.iterable().flatMap(item => {
			return item.fees.iterable().map(fee => fee);
		});
		if (fees.some(fee => fee.perXParticipants > 1)) {
			this.courseTariffs.clear();
			this.api.toasts.addToast({
				content: this.api.localizePipe.transform('Die kopierten Tarife eigneten sich nicht für deine gewählte Art der Platzbuchung. Sie wurden entfernt. Bitte lege neue Tarife an.'),
				visibilityDuration: 'infinite',
				theme: enumsObject.PThemeEnum.WARNING,
			});
		}
	}

	/**
	 * @returns The current cancellation policy which should be shown in the shift-model and which will be used
	 * for future bookings.
	 */
	public get currentCancellationPolicy() : SchedulingApiShiftModelCancellationPolicy | null {
		return this.cancellationPolicies.get(this.currentCancellationPolicyId);
	}

	/**
	 * A readonly getter for Members that are assigned.
	 * This method is static to make it available to other classes that are related to {@link SchedulingApiShiftModel}
	 * like {@link SchedulingApiShift}.
	 * @returns a new copy of SchedulingApiMembers.
	 */
	public static assignedMembers(
		api : SchedulingApiServiceBase,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
		aiAssignedMemberIds : ApiAttributeInfo<any, ApiListWrapper<Id>>,
	) : SchedulingApiMembers {
		// TODO: PLANO-156519
		if (!aiAssignedMemberIds.isAvailable) return new SchedulingApiMembers(api, null, true);

		const members = api.data.members.filterBy(item => aiAssignedMemberIds.value!.contains(item.id));

		// FIXME: PLANO--7458 (https://drplano.atlassian.net/browse/PLANO-7458)
		members.push = () => {
			throw new Error('assignedMembers is readonly');
		};
		members.remove = () => {
			throw new Error('assignedMembers is readonly');
		};

		return members;
	}

	private _assignedMembers = new Data<SchedulingApiMembers>(this.api);

	/** @see SchedulingApiShiftModel.assignedMembers */
	public get assignedMembers() : SchedulingApiMembers {
		return this._assignedMembers.get(() => {
			return SchedulingApiShiftModel.assignedMembers(this.api, this.aiAssignedMemberIds);
		});
	}

	/**
	 * Check if there are other shiftModels with the same prefix
	 */
	public validatePrefixOccupied() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.OCCUPIED, fn: (control) => {
			if (!control.value) return null;
			if (!this.api.data.shiftModels.prefixIsAlreadyOccupied(control.value, this)) return null;
			const activityWithSamePrefix = this.api.data.shiftModels.find(
				shiftModel => shiftModel.courseCodePrefix?.toLowerCase() === control.value?.toLowerCase() && !shiftModel.id.equals(this.id),
			)!;
			return { [PPossibleErrorNames.OCCUPIED] : {
				name: PPossibleErrorNames.OCCUPIED,
				type: PApiType.string,
				errorText: () => {
					if (activityWithSamePrefix.trashed)
						return 'Dieses Präfix wird bereits verwendet für die gelöschte Tätigkeit »${activityName}«. Gelöschte Tätigkeiten lassen sich nicht mehr ändern.';
					// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
					return 'Dieses Präfix wird bereits verwendet für <a href="${activityURL}" target="_blank" rel="noopener">»${activityName}«</a>.';
				},
				activityName : activityWithSamePrefix.name,
				activityURL : `${Config.FRONTEND_URL_LOCALIZED}/client/shiftmodel/${activityWithSamePrefix.id.toString()}/bookingsettings#course-code-prefix`,
			} };
		}});
	}

	/**
	 * Check if there are no payment methods with the same type, when the type is online payment
	 */
	public validatePrefixPattern() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.PATTERN, fn: (control) => {
			if (!control.value) return null;

			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const PATTERN_ERROR = this.api.validators.pattern(/^[\da-z]*$/i).fn(control);
			if (PATTERN_ERROR === null) return null;

			return {
				[PPossibleErrorNames.PATTERN] : {
					...PATTERN_ERROR[PPossibleErrorNames.PATTERN],
					errorText: 'Nur Buchstaben und Zahlen sind erlaubt.',
				},
			};
		}});
	}

	/**
	 * Are there any members available that can be assigned?
	 * Owners can always assign at least themselves, so this is always true for them.
	 * Members with the write permission can assign members, if an admin has already set some members as assignable.
	 * So there is nothing to do for a member if there are no assignable members.
	 *
	 * Note that there might be more restrictions set on SHIFT_MODEL_ASSIGNED_MEMBER_IDS
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get assignableMembersAvailableForAssignment() : boolean {
		return this.api.pPermissionsService.isOwner === true || this.assignableMembers.length > 0;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModelAssignableMembers extends SchedulingApiShiftModelAssignableMembersBase {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getByMemberId(id : Id) : SchedulingApiShiftModelAssignableMember | null {
		return this.findBy(item => item.memberId.equals(id));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public getByMember(item : SchedulingApiMember) : SchedulingApiShiftModelAssignableMember | null {
		return this.getByMemberId(item.id);
	}

	/**
	 * Check if there is at least one un-trashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => {
			const member = this.api.data.members.get(item.memberId);
			return !member!.trashed;
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get members() : SchedulingApiMembers {
		return this.api.data.members.filterBy(member => this.some(assignableMember => assignableMember.memberId.equals(member.id)));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public containsMember(item : SchedulingApiMember) : boolean {
		return !!this.getByMember(item);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public addNewMember(member : SchedulingApiMember, earning ?: number) : void {
		// NOTE: duplicate! This method exists here:
		// SchedulingApiAssignableShiftModels
		// SchedulingApiShiftModelAssignableMembers

		if (this.containsMember(member)) return;

		const tempAssignableMember = this.createNewItem();
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		tempAssignableMember.hourlyEarnings = earning ? earning : 0;
		tempAssignableMember.memberId = member.id;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public removeMember(item : SchedulingApiMember) : void {
		const assignableMember = this.getByMember(item);
		if (assignableMember) {
			this.removeItem(assignableMember);
		}
	}

}

/** An activity on which a shift will be based. A shiftModel can be seen as a template for shift creation. */
export class SchedulingApiShiftModels extends SchedulingApiShiftModelsBase {

	/**
	 * Search these activities by a given string.
	 * @param term The string to search for. Can be a string that a user typed into an input element.
	 */
	public search(term : Parameters<SchedulingApiShiftModel['fitsSearch']>[0]) : this {
		if (term === '') return this;
		return this.filterBy(item => item.fitsSearch(term));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get trashedItemsAmount() : number {
		let result = 0;
		for (const shiftModel of this.iterable()) {
			if (shiftModel.trashed) {
				result += 1;
			}
		}
		return result;
	}

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => !item.trashed);
	}

	public override removeItem(shiftModel : SchedulingApiShiftModel) : void {
		super.removeItem(shiftModel);
	}

	private _groupByActivityArea = new Data<ApiLists<SchedulingApiShiftModels>>(this.api);

	/**
	 * @returns Returns a list of lists where each inner list contains the shift-models with the same activity area.
	 * @deprecated Build your own Map<SchedulingApiActivityArea, SchedulingApiShiftModels> instead.
	 * TODO: PLANO-187638 Can this be removed?
	 */
	public get groupByActivityArea() : ApiLists<SchedulingApiShiftModels> {
		return this._groupByActivityArea.get(() => {
			// group by activity-area
			const groups = this.groupedBy((a, b) => a.activityAreaId.rawData - b.activityAreaId.rawData, false, false);

			// Sort groups alphabetically by activity-area name
			return groups.sort((a, b) => {
				const activityAreaName1 = a.get(0)!.activityAreaName;
				const activityAreaName2 = b.get(0)!.activityAreaName;
				return stringCompare(activityAreaName1, activityAreaName2);
			});
		});
	}

	private _activityAreas = new Data<SchedulingApiActivityArea[]>(this.api);

	/**
	 * @returns A list of all activity areas.
	 */
	public get activityAreas() : SchedulingApiActivityArea[] {
		return this._activityAreas.get(() => {
			const result : SchedulingApiActivityArea[] = [];
			for (const shiftModel of this.iterable()) {
				const activityArea = shiftModel.activityArea;

				if (!result.includes(activityArea)) {
					result.push(activityArea);
				}
			}
			return result;
		});
	}

	/**
	 * @returns Returns a list of all courseGroups
	 */
	public get courseGroups() : string[] {
		const result : string[] = [];
		for (const shiftModel of this.iterable()) {
			if (shiftModel.courseGroup === null) continue;
			const notIncluded = !result.includes(shiftModel.courseGroup);
			if (notIncluded) {
				result.push(shiftModel.courseGroup);
			}
		}
		return result;
	}

	/**
	 * Check if prefix is already used by another shiftModel
	 */
	public prefixIsAlreadyOccupied(
			input : string,
			shiftModel : SchedulingApiShiftModelBase,
	) : boolean {
		for (const item of this.api.data.shiftModels.iterable()) {
			if (item.courseCodePrefix !== input.toUpperCase()) continue;
			if (item.isNewItem) continue;
			if (item.id.equals(shiftModel.id)) continue;
			return true;
		}
		return false;
	}

	/**
	 * Generate default value for course prefix
	 */
	public getDefaultPrefix(
		shiftModel : SchedulingApiShiftModelBase,
	) : string {
		let suggestion : string;
		suggestion = shiftModel.name ? `${shiftModel.name.slice(0, 1).toUpperCase()}K` : 'AA';

		const GET_RANDOM_LETTERS = (length : number) : string => {
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

			let letters = '';
			for ( let i = 0; i < length; i++ ) {
				letters += characters.charAt(Math.floor(Math.random() * characters.length));
			}
			return letters;
		};

		while (this.prefixIsAlreadyOccupied(suggestion, shiftModel)) {
			suggestion = `${suggestion}${GET_RANDOM_LETTERS(1)}`;
		}

		let result = suggestion;
		let index = 1;
		while (this.prefixIsAlreadyOccupied(result, shiftModel) && index < 10) {
			// e.g. SH[1…9]
			result = suggestion.slice(0, 2) + index.toString();
			++index;
		}

		if (this.prefixIsAlreadyOccupied(result, shiftModel)) {
			// e.g. S
			while (this.prefixIsAlreadyOccupied(result, shiftModel) && index < 100) {
				// e.g. SH[10…99]
				result = suggestion.slice(0, 1) + index.toString();
				++index;
			}
		}
		return result;
	}
}
