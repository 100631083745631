<div
	pModalTemplate
	[pModalId]="null"
	[theme]="theme"
>
	<p-modal-content
		[modalTitle]="modalContentOptions.modalTitle"
		[closeBtnLabel]="modalContentOptions.closeBtnLabel ?? null"
		[closeBtnIcon]="modalContentOptions.closeBtnIcon ?? null"
		[closeBtnTheme]="modalContentOptions.closeBtnTheme ?? null"
		[dismissBtnLabel]="modalContentOptions.dismissBtnLabel ?? null"
		[hideDismissBtn]="!!modalContentOptions.hideDismissBtn"
		(onDismiss)="onDismiss()"
		(onClose)="onClose()"
	>
		<p-modal-content-body>
			<h4 *ngIf="modalContentOptions.icon" class="text-center mt-1 mb-3">
				<fa-icon [icon]="modalContentOptions.icon" />
			</h4>
			<p *ngIf="modalContentOptions.description && isString(modalContentOptions.description)"
				[innerHTML]="modalContentOptions.description | pSafeHtml"></p>
			<ng-container *ngTemplateOutlet="modalContentDescriptionTemplate" />
		</p-modal-content-body>
	</p-modal-content>

</div>
