// cSpell:ignore test_WMKYHWHZGBBGZOTVYRP4TUDI2APN3BQB, live_4L5USSNWRZEWRII5NWDYQMINTENQ7NN3
/**
 * This file can be replaced during build by using the `fileReplacements` array.
 * `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
 * The list of file replacements can be found in `angular.json`.
 */

import { Environment } from './environment.type';

const staging = location.hostname.includes('staging');
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const ENVIRONMENT : Environment = {
	BACKEND_URL_VALUE: staging ? 'https://stagingbackend.dr-plano.com' : 'https://backend.dr-plano.com',
	FRONTEND_URL_VALUE: staging ? 'https://staging.dr-plano.com' : 'https://www.dr-plano.com',
	API_FILE_BASE_URL: staging ? 'https://s3.eu-central-1.amazonaws.com/files.dr-plano.com/staging/' : 'https://s3.eu-central-1.amazonaws.com/files.dr-plano.com/prod/',
	DEBUG_VALUE: false,
	APPLICATION_MODE: 'PROD',
	IS_STAGING: staging,
	ADYEN_MODE: staging ? 'test' : 'live',
	ADYEN_CLIENT_KEY: staging ? 'test_WMKYHWHZGBBGZOTVYRP4TUDI2APN3BQB' : 'live_4L5USSNWRZEWRII5NWDYQMINTENQ7NN3',
	ADYEN_MERCHANT: staging ? 'ClimbTime_STAGING_TEST' : 'DrPlanoBooking',
	LAUNCH_DARKLY_CLIENT_ID: staging ? '621e5b1262ca7014ac2304ff' : '621dfdae4c358a152839e8c2',
	RECAPTCHA_V3_SITE_KEY: '6LdvJpAgAAAAAIxexI3gBHnM6LtDxOvIDOo-V_Aj', // cSpell:disable-line
	hmr: false,
};
