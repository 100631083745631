/* eslint-disable @typescript-eslint/naming-convention -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

/** A millisecond value for short animations */
export const ANIMATION_SPEED_FAST = 200;

/** A millisecond value for medium animations */
export const ANIMATION_SPEED_MEDIUM = 300;

/** A millisecond value for slow animations */
export const ANIMATION_SPEED_SLOW = 400;

type AnimationSpeedType = `${number}ms`;
const FAST : AnimationSpeedType = `${ANIMATION_SPEED_FAST}ms`;
const MEDIUM : AnimationSpeedType = `${ANIMATION_SPEED_MEDIUM}ms`;
const SLOW : AnimationSpeedType = `${ANIMATION_SPEED_SLOW}ms`;

const FAST_EASE_OUT = `${FAST} ease-out`;
const MEDIUM_EASE_OUT = `${MEDIUM} ease-out`;
const SLOW_EASE_OUT = `${SLOW} ease-out`;

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SLIDE_ON_NGIF_TRIGGER = trigger('slideVertical', [
	state('void', style({
		opacity: '0',
		height: '0px',
	})),
	state('*', style({
		height: '*',
	})),

	transition('void => *', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
	transition('* => void', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SLIDE_HORIZONTAL_ON_NGIF_TRIGGER = trigger('slideHorizontal', [
	state('void', style({
		opacity: '0',
		width: '0px',
	})),
	state('*', style({
		width: '*',
	})),

	transition('void => *', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
	transition('* => void', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SLIDE_FROM_RIGHT_ON_NGIF_TRIGGER = trigger('slideFromRight', [
	state('void', style({
		translate: '100%',
	})),
	state('*', style({
		translate: '0',
	})),

	transition('void => *', [
		animate(FAST_EASE_OUT),
	]),
	transition('* => void', [
		animate(FAST_EASE_OUT),
	]),
]);

/**
 * Slides the element to the left when it is hidden.
 */
export const SLIDE_TO_LEFT_ON_HIDE = trigger('slideToLeft', [
	state('void', style({
		translate: '-100%',
	})),
	state('*', style({
		translate: '0',
	})),

	transition('* => void', [
		animate('500ms 150ms cubic-bezier(0.55, 0.06, 0.68, 0.19)', keyframes([
			style({ translate: '0%', offset: 0 }),
			style({ translate: '20%', offset: 0.4 }),
			style({ translate: '20%', offset: 0.5 }),
			style({ translate: '-100%', offset: 1 }),
		])),
	]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SLIDE_IN_HORIZONTAL_ON_NGIF_TRIGGER = trigger('slideInHorizontal', [
	state('void', style({
		opacity: '0',
		width: '0px',
	})),
	state('*', style({
		width: '*',
	})),

	transition('void => *', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
]);
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SLIDE_OUT_HORIZONTAL_ON_NGIF_TRIGGER = trigger('slideOutHorizontal', [
	state('void', style({
		opacity: '0',
		width: '0px',
	})),
	state('*', style({
		width: '*',
	})),

	transition('* => void', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SLIDE_RTL_ON_NGIF_TRIGGER = trigger('slideRtl', [
	state('void', style({
		boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
		translate: '110%',
	})),
	state('*', style({
		transform: '*',
	})),

	transition('void => *', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
	transition('* => void', [
		style({ overflow: 'hidden' }),
		animate(MEDIUM_EASE_OUT),
	]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const FLEX_GROW_ON_NGIF_TRIGGER = trigger('flexGrowHorizontal', [
	state('void', style({
		opacity: '0',
		'flex-grow': '0',
	})),
	state('*', style({
		'flex-grow': '1',
	})),

	transition('void => *', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
	transition('* => void', [
		style({ overflow: 'hidden' }),
		animate(FAST_EASE_OUT),
	]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SLIDE_WIDTH_100_ON_BOOLEAN_TRIGGER = trigger('slideWidth100', [
	state('false, void', style({ width: '0' })),
	state('true', style({ width: '100%' })),
	transition( 'true <=> false', [animate(FAST_EASE_OUT)]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const FLEX_GROW_ON_BOOLEAN_TRIGGER = trigger('flexGrow', [
	state('false, void', style({ 'flex-grow': '0' })),
	state('true', style({ 'flex-grow': '1' })),

	// NOTE: This 1ms thingy is a hack for p-tabs. p-tabs inside main-sidebar was jumping
	// around between two animations sometimes.
	// Test p-taps in main-sidebar if you want to remove the 1ms thing.
	transition( 'true => false', [animate('200ms 1ms ease-out')]),
	transition( 'false => true', [animate(FAST_EASE_OUT)]),
]);

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const FADE_SHOW_ON_NGIF_TRIGGER = trigger('fadeShow', [
	state('void', style({
		opacity: '0',
	})),
	state('*', style({
		opacity: '1',
	})),
	transition('void => *', [
		animate(FAST_EASE_OUT),
	]),
	transition('* => void', [
		animate(FAST_EASE_OUT),
	]),
]);

/**
 * It 'pops in'.
 * Note that this does not work on elements with display: inline.
 */
export const POP_SHOW_EFFECT_ON_NGIF_TRIGGER = trigger('popShow', [
	state('void', style({
		transform: 'scale(0)',
		opacity: '0',
	})),
	state('*', style({
		transform: 'scale(1)',
		opacity: '1',
	})),
	transition('void => *', [
		animate('400ms ease-out', keyframes([
			style({ transform: 'scale(0)', offset: 0 }),
			style({ transform: 'scale(1.04)', opacity: '1', offset: 0.5 }),
			style({ transform: 'scale(1)', offset: 1 }),
		])),
	]),
	transition('* => void', [
		style({ overflow: 'hidden' }),
		animate('300ms ease-in', keyframes([
			style({ transform: 'scale(1)', offset: 0 }),
			style({ transform: 'scale(1.03)', offset: 0.5 }),
			style({ transform: 'scale(0)', opacity: '0', offset: 1 }),
		])),
	]),
]);

/**
 * Turns and pops the dismiss icon.
 * Can be used e.g. when the dismiss icon got triggered by a indirect action like a keyboard shortcut.
 */
export const DISMISS_ICON_EFFECT_ANIMATION = [
	animate(SLOW_EASE_OUT, keyframes([
		style({ transform: 'scale(1)', offset: 0 }),
		style({ transform: 'scale(1.7) rotate(90deg)', offset: 0.5 }),
		style({ transform: 'scale(1) rotate(90deg)', offset: 1 }),
	])),
];

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const SHAKE_SIDEWAYS_ON_BOOLEAN_TRIGGER = trigger('shakeSideways', [
	state('false, void', style({
		translate: '0',
	})),
	state('true', style({
		translate: '0',
	})),
	transition('false <=> true', [
		animate('500ms linear', keyframes([
			style({ translate: '0px', offset: 0 }),
			style({ translate: '-5px', offset: 0.10 }),
			style({ translate: '7px', offset: 0.20 }),
			style({ translate: '-10px', offset: 0.30 }),
			style({ translate: '10px', offset: 0.40 }),
			style({ translate: '-15px', offset: 0.50 }),
			style({ translate: '13px', offset: 0.60 }),
			style({ translate: '-10px', offset: 0.70 }),
			style({ translate: '7px', offset: 0.80 }),
			style({ translate: '-5px', offset: 0.90 }),
			style({ translate: '0px', offset: 1 }),
		])),
	]),
]);
