import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { PCalloutTheme, PCalloutThemeEnum } from '@plano/client/shared/bootstrap.utils';
import { PBaseClass } from '@plano/shared/base';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';
import { PlanoFaIconPoolKeys } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/**
 * A type for callouts, that defines mainly the the color of a callout component
 */
@Component({
	selector: 'p-callout',
	templateUrl: './p-callout.component.html',
	styleUrls: ['./p-callout.component.scss'],
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled require-jsdoc for OnPush strategy
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [SLIDE_ON_NGIF_TRIGGER],
	standalone: true,
	imports: [
		PCommonModule,
		FaIconComponent,
	],
})
export class PCalloutComponent extends PBaseClass {

	/**
	 * Icon to show inside the box.
	 * Can be set to null if you dont want any icon.
	 */
	@Input('icon') private _icon ?: PCalloutComponent['icon'] | null;

	/**
	 * Some visual style for the overall look of this ui element.
	 */
	@Input() public theme : PCalloutTheme = this.enums.PThemeEnum.WARNING;

	/**
	 * Gets emitted when the user clicks some kind of close-button. E.g. a `×` on the top right.
	 */
	@Output() private dismiss = new EventEmitter<undefined>();

	/**
	 * Should it be possible to dismiss this callout?
	 * If set to true there will be a dismiss button which makes the callout disappear.
	 */
	@HostBinding('class.alert-dismissible')
	@Input() public dismissable : boolean | null = null;

	@HostBinding('attr.role') private _role = 'alert' as const;
	@HostBinding('class.alert')
	protected readonly _alwaysTrue = true;

	@HostBinding('class.d-none')
	@HostBinding('hidden') private get _isHidden() : boolean {
		return !this.visible;
	}

	@HostBinding('class.alert-danger') private get _alertDanger() : boolean { return this.theme === 'danger'; }
	@HostBinding('class.alert-sepia') private get _alertSepia() : boolean { return this.theme === 'sepia'; }
	@HostBinding('class.alert-pistachio') private get _alertPistachio() : boolean { return this.theme === 'pistachio'; }
	@HostBinding('class.alert-dark') private get _alertDark() : boolean { return this.theme === 'dark'; }
	@HostBinding('class.alert-info') private get _alertInfo() : boolean { return this.theme === this.enums.PThemeEnum.INFO; }
	@HostBinding('class.alert-light') private get _alertLight() : boolean { return this.theme === 'light'; }
	@HostBinding('class.alert-plain') private get _alertPlain() : boolean { return this.theme === PCalloutThemeEnum.PLAIN; }
	@HostBinding('class.alert-primary') private get _alertPrimary() : boolean { return this.theme === 'primary'; }
	@HostBinding('class.alert-purple') private get _alertPurple() : boolean { return this.theme === 'purple'; }
	@HostBinding('class.alert-secondary') private get _alertSecondary() : boolean {
		return this.theme === 'secondary';
	}
	@HostBinding('class.alert-success') private get _alertSuccess() : boolean { return this.theme === 'success'; }
	@HostBinding('class.alert-warning') private get _alertWarning() : boolean { return this.theme === 'warning'; }

	public visible : boolean = true;

	/**
	 * Icon to show inside the box.
	 */
	public get icon() : PlanoFaIconPoolKeys | null {
		if (this._icon !== undefined) return this._icon;
		if (this.theme === 'warning') return 'fa-exclamation-triangle fa-solid';
		if (this.theme === 'info') return 'fa-square-info fa-duotone';
		if (this.theme === 'danger') return 'fa-fire fa-duotone';
		if (this.theme === 'success') return 'fa-circle-check fa-duotone';
		return null;
	}

	/**
	 * when user clicked close
	 */
	public onClose() : void {
		this.visible = false;
		this.dismiss.emit();
	}
}
