import { SchedulingApiMemberBase, SchedulingApiPermissionGroupRole, SchedulingApiPermissionGroups } from '@plano/shared/api';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { notNull } from '@plano/shared/core/utils/null-type-utils';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

/** @see SchedulingApiMemberBase */
export class SchedulingApiMember extends SchedulingApiMemberBase {

	public isHovered : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get permissionGroups() : SchedulingApiPermissionGroups {
		return this.api.data.permissionGroups.filterBy(item => this.permissionGroupIds.contains(item.id));
	}

	/**
	 * Check if this member fits to the given search term.
	 * @param searchTerm The string to search for. Can be a string that a user typed into an input element.
	 * TODO: PLANO-187712 Implement central solution for all such methods
	 */
	public fitsSearch(searchTerm : string | null) : boolean {
		if (searchTerm === null) return true;
		if (searchTerm === '') return true;
		for (const termItem of searchTerm.split(' ')) {
			const termLow = termItem.toLowerCase();
			const firstNameLow = this.firstName.toLowerCase();
			const lastNameLow = this.lastName.toLowerCase();
			if (firstNameLow.includes(termLow)) continue;
			if (lastNameLow.includes(termLow)) continue;
			return false;
		}
		return true;
	}

	/**
	 * Check if there are any shifts selected which are related to this member.
	 */
	public get relatedShiftsSelected() : boolean {
		return this.api.data.shifts.selectedItems.some(item => item.relatesTo(this));
	}

	/**
	 * @returns Returns from the assigned permission groups the role with the highest power.
	 */
	public get role() : SchedulingApiPermissionGroupRole {
		let role : SchedulingApiPermissionGroupRole = SchedulingApiPermissionGroupRole.CLIENT_DEFAULT;

		for (const permissionGroupId of this.permissionGroupIds.iterable()) {
			const permissionGroup = notNull(this.api.data.permissionGroups.get(permissionGroupId));
			if (permissionGroup.role > role) role = permissionGroup.role;
		}

		return role;
	}

	/**
	 * Validator to check if the amount of admins satisfies the minimum required amount.
	 */
	public minAmountOfAdmins() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.MIN, fn: (_control) => {
			const accountHasAnotherAdmin = this.api.data.members
				.filterBy(member => !member.trashed)
				.some(member => {
					return member.role === SchedulingApiPermissionGroupRole.CLIENT_OWNER;
				});
			if (!accountHasAnotherAdmin) {
				return { [PPossibleErrorNames.MIN]: {
					name: PPossibleErrorNames.MIN,
					type: PApiType.ApiList,
					errorText: 'Die Änderungen der Rechtegruppen, die du beabsichtigt, würde diesem User die Adminrechte entziehen. Das ist aktuell nicht möglich, da es keinen weiteren User mit Admin-Status gibt. Bitte ändere das, bevor du diese Aktion ausführst.',
				}};
			}
			return null;
		}});
	}
}
