import { ClientsApiClientBase, ClientsApiCountry, ClientsApiType } from '@plano/shared/api';
import { PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ClientsApiClient extends ClientsApiClientBase {

	/**
     * Serializes `type` with some additional information.
     */
	public get typeAsString() : string | undefined {
		if (!this.aiType.isAvailable) return undefined;
		switch (this.type) {
			case ClientsApiType.TEST_NEED_VERIFICATION:
				return '❓ Nicht verifiziert';
			case ClientsApiType.TEST:
				return `🧪 Test Account (Deadline: ${ this.api.datePipe.transform(this.testAccountDeadline! - 1, 'short') })`;
			case ClientsApiType.TEST_EXPIRED:
				return `🔴 Test Account abgelaufen (Deadline: ${ this.api.datePipe.transform(this.testAccountDeadline! - 1, 'short') })`;
			case ClientsApiType.PAID:
				return `🎉 Echtkunde (seit: ${ this.api.datePipe.transform(this.paidAccountDate, 'short') })`;
			case ClientsApiType.TERMINATED:
				return `❌ Gekündigt (seit: ${ this.api.datePipe.transform(this.terminationDate, 'shortDate') })`;
		}
	}

	/**
	 * Get the locale id from the client's country
	 */
	public get localeId() : PSupportedLocaleIds | undefined {
		if (this.aiLocationCountry.isAvailable === undefined) return undefined;
		switch (this.locationCountry) {
			case ClientsApiCountry.AUSTRIA :
				return PSupportedLocaleIds.de_AT;
			case ClientsApiCountry.GERMANY:
				return PSupportedLocaleIds.de_DE;
			case ClientsApiCountry.BELGIUM:
				return PSupportedLocaleIds.en_BE;
			case ClientsApiCountry.ITALY:
				return PSupportedLocaleIds.en_IT;
			case ClientsApiCountry.ROMANIA:
				return PSupportedLocaleIds.en_RO;
			case ClientsApiCountry.NETHERLANDS:
				return PSupportedLocaleIds.en_NL;
			case ClientsApiCountry.LUXEMBOURG:
				return PSupportedLocaleIds.en_LU;

			case ClientsApiCountry.SWITZERLAND:
				return PSupportedLocaleIds.de_CH;

			case ClientsApiCountry.UNITED_KINGDOM:
				return PSupportedLocaleIds.en_GB;

			case ClientsApiCountry.CZECH_REPUBLIC:
				return PSupportedLocaleIds.en_CZ;

			case ClientsApiCountry.SWEDEN:
				return PSupportedLocaleIds.en_SE;
		}
	}
}
