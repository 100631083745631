/* eslint max-lines: ["error", 130] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */

/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
// NOTE: 	[PLANO-62957] It looks like sorting has effect on routing.
//				Routing for /client breaks when i let eslint sort this file.
// 				Seems to me like something is wrong with the AppRoutingModule or ClientRoutingModule

import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, TitleStrategy } from '@angular/router';
import { GlobalErrorHandler } from '@plano/global-error-handler/global-error-handler';
import { getPopoverConfig, getTooltipConfig } from '@plano/ngx-bootstrap.config';
import { PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { CoreModule } from '@plano/shared/core/core.module';

import { PCookieConsentManagerComponent } from '@plano/client/shared/component/p-cookie-consent-manager/cookie-consent-manager.component';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This is the only place where it is OK to import Sentry.
import * as Sentry from '@sentry/angular-ivy';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { NgProgressModule } from 'ngx-progressbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Config } from './shared/core/config';
import { TemplatePageTitleStrategy } from './template-page-title-strategy.service';

@NgModule({
	bootstrap: [AppComponent],
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		PopoverModule.forRoot(),
		AppRoutingModule,
		RecaptchaV3Module,
		BrowserAnimationsModule.withConfig({
			disableAnimations: Config.APPLICATION_MODE === 'GHERKIN',
		}),
		CoreModule,
		NgProgressModule.withConfig({
			direction: 'ltr+',
			speed: 200,
			trickleSpeed: 300,
			debounceTime: 200, // Seems to have no effect.
			meteor: true,
			spinner: false,
			thick: false,
			fixed: true,
			min: 8,
			max: 100,
			ease: 'linear',
			color: '#525050',
		}),
		PCookieConsentManagerComponent,
	],
	providers:
	[
		provideClientHydration(),
		{provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
		{
			provide: TooltipConfig,
			useFactory: getTooltipConfig,
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: Config.RECAPTCHA_V3_SITE_KEY,
		},
		{
			provide: PopoverConfig,
			useFactory: getPopoverConfig,
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		{
			provide: APP_BASE_HREF,
			useFactory: (s : PlatformLocation) => s.getBaseHrefFromDOM(),
			deps: [PlatformLocation],
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (locale : PSupportedLocaleIds) => {

				// FIXME: PLANO-20714
				Config.LOCALE_ID = locale;
			},
			deps: [LOCALE_ID, Sentry.TraceService],
		},

		// { provide: HAMMER_GESTURE_CONFIG, useClass: PHammerConfig },
		// { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
		// CanDeactivateGuard
	],
})
export class AppModule {}
