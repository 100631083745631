import { Component, Input } from '@angular/core';
import { PBadgeComponent } from '@plano/client/shared/component/p-badge/p-badge.component';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PBaseClass } from '@plano/shared/base';
import { Config } from '@plano/shared/core/config';
import { PCropOnOverflowDirective } from '@plano/shared/core/directive/crop-on-overflow.directive';
import { PTooltipDirective } from '@plano/shared/core/directive/tooltip.directive';
import { MeService } from '@plano/shared/core/me/me.service';
import { FaIconComponent } from '@plano/shared/core/p-common/fa-icon/fa-icon.component';
import { PCommonModule } from '@plano/shared/core/p-common/p-common.module';

/**
 * A component which shows debug information when we are logged in with the master password.
 * The component should not be visible to our clients.
 */
@Component({
	selector: 'p-debug',
	templateUrl: './p-debug.component.html',
	styleUrls: ['./p-debug.component.scss'],
	standalone: true,
	imports: [
		PCommonModule,
		PCropOnOverflowDirective,
		PTooltipDirective,
		FaIconComponent,
		PBadgeComponent,
	],
})
export class PDebugComponent extends PBaseClass {
	/**
	 * The attribute whose value should be visualized.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Have to take any instead of unknown here, because »Type 'unknown' does not satisfy the constraint 'ApiDataWrapperBase'«
	@Input({required: true}) public ai! : ApiAttributeInfo<any, unknown>;

	/**
	 * The label to be shown.
	 */
	@Input({required: true}) public label! : string;

	constructor(public me : MeService) {
		super();
	}

	public Config = Config;
}
