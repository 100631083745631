import { DataBase } from './data-base';
import { DataInputBase } from './data-input-base';

/**
 * This class provides you the possibility to execute an expensive calculation only once and cache the result.
 * The cache will only be reset when the dependencies change in which case the result is recalculated.
 *
 * Dependencies are defines by the {@link DataInputBase} objects passed to the constructor.
 *
 * @example
 * // Code without any caching:
 * get foo() : type {
 * 	// Do your calculation and return the result
 * }
 *
 * // Previous code done with caching:
 * private _foo = new Data<type>(dependency);
 * get foo() : type {
 * 	return this._foo.get(() => {
 * 		// Do your calculation and return the result
 * 	});
 * }
 */
export class Data<T> extends DataBase<T> {
	constructor(input1 : DataInputBase, input2 : DataInputBase | null = null, input3 : DataInputBase | null = null) {
		super(input1, input2, input3);
	}

	private calculateFn : (() => T) | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public get(calculateFn : () => T) : T {
		this.calculateFn = calculateFn;
		if (this.inputsChanged()) {
			this.cachedValue = calculateFn();
		}

		return this.cachedValue;
	}

	/**
	 * Get the current cached value without recalculating it
	 */
	public get currentCachedValue() : T | undefined {
		return this.cachedValue;
	}

	/**
	 * Avoid using this method if possible, you should try to add the dependencies
	 * in the Data class.
	 */
	public forceUpdate() : void {
		if (this.calculateFn)
			this.cachedValue = this.calculateFn();
	}
}
