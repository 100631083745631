import { HolidayItemsApiHolidayItemBase } from '@plano/shared/api';

/**
 * Extending the generated API class `HolidayItemsApiHolidayItemBase`.
 */
export class HolidayItemsApiHolidayItem extends HolidayItemsApiHolidayItemBase {

	/**
	 * Is this item selected to be imported?
	 */
	public selected = false;
}
