/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Pipe, PipeTransform } from '@angular/core';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { PLocaleNumbersService } from '@plano/shared/core/locale-numbers.service';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PMath } from '@plano/shared/core/utils/math-utils';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum SupportedDurationTimePipeUnits {
	DAYS = 'days',
	HOURS = 'hours',
	MINUTES = 'minutes',
	SECONDS = 'seconds',
}

/**
 * Which is larger, which is smaller?
 */
const supportedUnitsSize = {
	[SupportedDurationTimePipeUnits.DAYS] : 4,
	[SupportedDurationTimePipeUnits.HOURS] : 3,
	[SupportedDurationTimePipeUnits.MINUTES] : 2,
	[SupportedDurationTimePipeUnits.SECONDS] : 1,
};

type DurationAsUnits = {
	[SupportedDurationTimePipeUnits.DAYS] : number,
	[SupportedDurationTimePipeUnits.HOURS] : number,
	[SupportedDurationTimePipeUnits.MINUTES] : number,
	[SupportedDurationTimePipeUnits.SECONDS] : number,
};

/**
 * A pipe that turns milliseconds into a human readable format like:
 * @examples
 *   `123456789` ➡ `1 Tag 10 h. 17 Min. 36 Sek.`
 *   `123456789` ➡ `1 Tag 10 h`
 */
@Pipe({
	name: 'pDurationTime',
	standalone: true,
})
export class PDurationTimePipe implements PipeTransform {
	constructor(
		private pMoment : PMomentService,
		private localize : LocalizePipe,
		private console ?: LogService,
	) {}

	private getDays(duration : number) : number {
		// You would get 0 days, if you have a duration of exactly 1 month (31 Days).
		// Therefore we use a little trick in the next line ;)
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return PMath.cutToDecimalPlaces(this.pMoment.duration(duration).asDays(), 0);
	}

	private getDurationAsUnits(duration : number) : DurationAsUnits {
		return {
			days : this.getDays(duration),
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			hours : this.pMoment.duration(duration).hours(),
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			minutes : this.pMoment.duration(duration).minutes(),
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			seconds : this.pMoment.duration(duration).seconds(),
		};
	}

	private isBetween(
		value : SupportedDurationTimePipeUnits,
		lowestUnit : SupportedDurationTimePipeUnits,
		highestUnit : SupportedDurationTimePipeUnits,
	) : boolean {
		if (supportedUnitsSize[value] > supportedUnitsSize[highestUnit]) return false;
		if (supportedUnitsSize[value] < supportedUnitsSize[lowestUnit]) return false;
		return true;
	}

	/**
	 * Get the highest/biggest unit that is represented in this duration.
	 * @examples
	 * - In a duration of 1 day, 10 hours and 15 minutes, the highest/biggest unit is days.
	 * - In a duration of 3 hours and 30 minutes, the highest/biggest unit is hours.
	 * @param durationAsUnits The duration as an object
	 * @returns the highest represented unit
	 */
	private getHighestUnit(
		durationAsUnits : DurationAsUnits,
	) : SupportedDurationTimePipeUnits {
		if (durationAsUnits[SupportedDurationTimePipeUnits.DAYS]) return SupportedDurationTimePipeUnits.DAYS;
		if (durationAsUnits[SupportedDurationTimePipeUnits.HOURS]) return SupportedDurationTimePipeUnits.HOURS;
		if (durationAsUnits[SupportedDurationTimePipeUnits.MINUTES]) return SupportedDurationTimePipeUnits.MINUTES;
		if (durationAsUnits[SupportedDurationTimePipeUnits.SECONDS]) return SupportedDurationTimePipeUnits.SECONDS;

		// The seconds value should be a number. If it is undefined/null/anything else, than we assume something is wrong here.
		// Related: https://dr-plano.sentry.io/issues/4552699374
		// TODO: PLANO-179174
		if (durationAsUnits[SupportedDurationTimePipeUnits.SECONDS] !== 0) {
			this.console?.error(`Unhandled case: ${JSON.stringify(durationAsUnits)} [PRODUCTION-503]`); return null!;
		}

		return SupportedDurationTimePipeUnits.SECONDS;
	}

	private isLowestNecessaryUnit(
		value : SupportedDurationTimePipeUnits,
		durationAsUnits : DurationAsUnits,
		cutUnitsBelow : SupportedDurationTimePipeUnits,
	) : boolean {
		if (supportedUnitsSize[value] < supportedUnitsSize[cutUnitsBelow]) return false;
		if (!durationAsUnits[value]) return false;
		return true;
	}

	private getLowestUnit(
		durationAsUnits : DurationAsUnits,
		cutUnitsBelow : SupportedDurationTimePipeUnits,
		shortestFormatting : boolean = false,
	) : SupportedDurationTimePipeUnits {
		if (!shortestFormatting) return cutUnitsBelow;
		if (this.isLowestNecessaryUnit(SupportedDurationTimePipeUnits.SECONDS, durationAsUnits, cutUnitsBelow)) return SupportedDurationTimePipeUnits.SECONDS;
		if (this.isLowestNecessaryUnit(SupportedDurationTimePipeUnits.MINUTES, durationAsUnits, cutUnitsBelow)) return SupportedDurationTimePipeUnits.MINUTES;
		if (this.isLowestNecessaryUnit(SupportedDurationTimePipeUnits.HOURS, durationAsUnits, cutUnitsBelow)) return SupportedDurationTimePipeUnits.HOURS;
		return SupportedDurationTimePipeUnits.DAYS;
	}

	private getUnitsToAdd(
		durationAsUnits : DurationAsUnits,
		cutUnitsBelow : SupportedDurationTimePipeUnits,
		shortestFormatting : boolean = false,
	) : SupportedDurationTimePipeUnits[] {
		const highestUnit = this.getHighestUnit(durationAsUnits);
		const lowestUnit = this.getLowestUnit(durationAsUnits, cutUnitsBelow, shortestFormatting);

		const result = [];
		if (this.isBetween(SupportedDurationTimePipeUnits.DAYS, lowestUnit, highestUnit)) result.push(SupportedDurationTimePipeUnits.DAYS);
		if (this.isBetween(SupportedDurationTimePipeUnits.HOURS, lowestUnit, highestUnit)) result.push(SupportedDurationTimePipeUnits.HOURS);
		if (this.isBetween(SupportedDurationTimePipeUnits.MINUTES, lowestUnit, highestUnit)) result.push(SupportedDurationTimePipeUnits.MINUTES);
		if (this.isBetween(SupportedDurationTimePipeUnits.SECONDS, lowestUnit, highestUnit)) result.push(SupportedDurationTimePipeUnits.SECONDS);
		return result;
	}

	private getStringToAdd(
		unit : SupportedDurationTimePipeUnits,
		padValue : boolean,
		durationAsUnits : DurationAsUnits,
		htmlCode : string,
		textSingular : PDictionarySourceString,
		textPlural : PDictionarySourceString,
		useSubTags : boolean = false,
	) : string {
		if (durationAsUnits[unit] === 0) return '';

		// function to add one leading zero if value is smaller equal 9
		const pad = (value : number) : string | number => (padValue && value <= 9 && value > 0 ? `0${value}` : value);

		if (htmlCode || durationAsUnits[unit] > 0) {
			return `${pad(durationAsUnits[unit])} ${useSubTags ? '<sub>' : ''}${this.localize.transform(durationAsUnits[unit] === 1 ? textSingular : textPlural, true)}${useSubTags ? '</sub>' : ''} `;
		}
		return '';
	}

	/**
	 * Format a duration like 123456789 to »1 Day 10 Hrs. 17 Min«
	 * @param duration The duration to be transformed
	 * @param cutUnitsBelow Cut the duration information which is below a certain unit. Use this to e.g. remove the seconds
	 * 	information if this is not relevant.
	 * @param shortestFormatting Keep the length of the output strong as short as possible.
	 * @param [useSubTags=false] Use sub tags for each unit. This makes the output more readable.
	 * @returns The formatted duration or an empty string if the duration could not be formatted
	 */
	public transform(
		duration : number | null,
		cutUnitsBelow : SupportedDurationTimePipeUnits | false = SupportedDurationTimePipeUnits.MINUTES,
		shortestFormatting : boolean = false,
		useSubTags : boolean = false,
	) : string {
		if (duration === null) return '';
		if (duration === 0) return `0 ${useSubTags ? '<sub>' : ''}${this.localize.transform('Min.')}${useSubTags ? '</sub>' : ''}`;

		const durationAsUnits = this.getDurationAsUnits(duration);
		const unitsToAdd = this.getUnitsToAdd(durationAsUnits, cutUnitsBelow === false ? SupportedDurationTimePipeUnits.MINUTES : cutUnitsBelow, shortestFormatting);
		let htmlCode = '';

		if (unitsToAdd.includes(SupportedDurationTimePipeUnits.DAYS)) htmlCode += this.getStringToAdd(
			SupportedDurationTimePipeUnits.DAYS,
			false,
			durationAsUnits,
			htmlCode,
			'Tag',
			'Tage',
			useSubTags,
		);
		if (unitsToAdd.includes(SupportedDurationTimePipeUnits.HOURS)) htmlCode += this.getStringToAdd(
			SupportedDurationTimePipeUnits.HOURS,
			false,
			durationAsUnits,
			htmlCode,
			'Std.',
			'Std.',
			useSubTags,
		);
		if (unitsToAdd.includes(SupportedDurationTimePipeUnits.MINUTES)) htmlCode += this.getStringToAdd(
			SupportedDurationTimePipeUnits.MINUTES,
			unitsToAdd.includes(SupportedDurationTimePipeUnits.HOURS),
			durationAsUnits,
			htmlCode,
			'Min.',
			'Min.',
			useSubTags,
		);
		if (unitsToAdd.includes(SupportedDurationTimePipeUnits.SECONDS)) htmlCode += this.getStringToAdd(
			SupportedDurationTimePipeUnits.SECONDS,
			unitsToAdd.includes(SupportedDurationTimePipeUnits.MINUTES),
			durationAsUnits,
			htmlCode,
			'Sek.',
			'Sek.',
			useSubTags,
		);

		if (htmlCode.length === 0) htmlCode = '–';
		return htmlCode.trim();
	}
}

@Pipe({
	name: 'pDurationHighestGroupHours',
	standalone: true,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PDurationHighestGroupHoursPipe implements PipeTransform {
	constructor(
		private pMoment : PMomentService,
		private localize : LocalizePipe,
	) {}

	private formatted(
		input : number,
		unitLabel : string,
	) : string {
		// function to add one leading zero if value is smaller equal 9
		const pad = (value : number) : string | number => (value <= 9 ? `0${value}` : value);

		if (input > 0) {
			return `${pad(input)} ${unitLabel} `;
		}
		return '';
	}

	/**
	 * Format a duration (ms) to human readable hours.
	 * If the duration is multiple days, it will still stick to hours.
	 * Like two days will be displayed as 48 Hrs. 0 Min.
	 */
	public transform(duration : number | null, alwaysShowSeconds : boolean = true) : string {

		if (duration === null) return '';

		if (duration === 0) return this.formatted(0, this.localize.transform('h'));

		let htmlCode = '';

		// hours
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const hours = Math.trunc(this.pMoment.duration(duration).asHours());
		htmlCode += this.formatted(hours, this.localize.transform('h'));

		// minutes
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const minutes = this.pMoment.duration(duration).minutes();
		htmlCode += this.formatted(minutes, this.localize.transform('min'));

		// seconds
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const seconds = this.pMoment.duration(duration).seconds();
		if (
			alwaysShowSeconds ||
			// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			this.pMoment.duration(duration).asSeconds() < 60
		) {
			htmlCode += ' ';
			if (htmlCode || seconds > 0) htmlCode += this.formatted(seconds, this.localize.transform('S'));
		}

		if (htmlCode.length === 0) htmlCode = '–';
		return htmlCode;
	}
}

/**
 * Pipe to format a duration (ms) to human readable unit, based on the unit label provided.
 */
@Pipe({
	name: 'pDurationOnlyAsSingleUnitsPipe',
	standalone: true,
})
export class PDurationOnlyAsSingleUnitsPipe implements PipeTransform {
	constructor(
		private localize : LocalizePipe,
	) {}

	private formatted(
		input : string,
		unitLabel : string,
	) : string {
		return `${input} ${unitLabel}`;
	}

	private getUnitLabel(unit : PApiType.Minutes | PApiType.Hours | PApiType.Days) : PDictionarySourceString {
		switch (unit) {
			case PApiType.Minutes:
				return 'min';
			case PApiType.Hours: return 'h';
			case PApiType.Days: return 'Tage';
		}
	}

	/**
	 * Format a duration (ms) to human readable unit, based on the unit label provided.
	 * @param duration The duration to be transformed
	 * @param unit The unit to be used for the transformation
	 */
	public transform(
		duration : number | null,
		unit : PApiType.Minutes | PApiType.Hours | PApiType.Days,
	) : string {

		if (duration === null) return '';

		if (duration === 0) return this.formatted('0', this.localize.transform(this.getUnitLabel(unit)));

		let units : number | null = null;

		switch (unit) {
			case PApiType.Minutes: units = PMomentService.d(duration).asMinutes(); break;
			case PApiType.Hours: units = PMomentService.d(duration).asHours(); break;
			case PApiType.Days: units = PMomentService.d(duration).asDays(); break;
		}

		// roundedUnits is used to round the number to 2 decimal places
		const roundedUnits = Math.round(units * 100) / 100;

		return this.formatted(PLocaleNumbersService.turnNumberIntoLocaleNumber(Config.LOCALE_ID, roundedUnits)!, this.localize.transform(this.getUnitLabel(unit)));
	}
}
