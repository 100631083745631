/* eslint-disable no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PSeverity } from '@plano/global-error-handler/error-utils';
import { PErrorModalContentComponent } from '@plano/shared/core/component/error-modal-content/error-modal-content.component';
import { isPrimitive } from '@plano/shared/core/utils/typescript-utils';
import { Primitive } from '@plano/shared/core/utils/typescript-utils-types';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import { Config } from './config';

/**
 * Overwrites console
 * One problem that gets solved here: When a component has e.g. a console.debug(…) and it gets used hundreds of times
 * then the console gets spammed and the app gets slow. Use LogService and the message gets logged one time.
 */
@Injectable({
	providedIn: 'root',
})
export class LogService {
	constructor(
		private pSentryService ?: PSentryService,
		private injector ?: Injector,
	) {
	}

	private spamBlockerIsActive = false;

	/**
	 * A debug (also known as 'verbose') message that gets logged to the console.
	 * Beware that the devtools are per default set to not show debug/verbose messages, so you don’t get spammed.
	 * It will **not** be reported to sentry.io. If you need something to be reported to sentry.io, use {@link error}.
	 * @param firstArg whatever should be logged.
	 * @param otherArgs more information about the error.
	 */
	public debug(firstArg : unknown, ...otherArgs : unknown[]) : void {
		const otherArgsAsPrimitives = this.toPrimitiveArray(otherArgs);
		this.logItToConsole(console.debug, '🐛', firstArg, '#495057', ...otherArgsAsPrimitives);
	}

	private toPrimitiveArray(input : unknown[]) : Primitive[] {
		return input.map((_key, value) => {
			return isPrimitive(value) ? JSON.stringify(value) : value;
		});
	}

	/**
	 * An info that gets logged to the console.
	 * It will **not** be reported to sentry.io. If you need something to be reported to sentry.io, use {@link error}.
	 * @param firstArg whatever should be logged.
	 * @param otherArgs more information about the error.
	 */
	public info(firstArg : unknown, ...otherArgs : unknown[]) : void {
		this.logItToConsole(console.info, '🚧', JSON.stringify(firstArg), '#495057', ...otherArgs);
	}

	/**
	 * A message that gets logged to the console.
	 * It will **not** be reported to sentry.io. If you need something to be reported to sentry.io, use {@link error}.
	 * @param firstArg whatever should be logged.
	 * @param otherArgs more information about the error.
	 */
	public log(firstArg : unknown, ...otherArgs : unknown[]) : void {
		this.logItToConsole(console.log, 'ℹ️', firstArg, '#2c93a5', ...otherArgs);
	}

	/**
	 * A warning that gets logged to the console.
	 * It will **not** be reported to sentry.io. If you need something to be reported to sentry.io, use {@link error}.
	 * @param firstArg whatever should be logged.
	 * @param otherArgs more information about the error.
	 */
	public warn(firstArg : unknown, ...otherArgs : unknown[]) : void {
		if (this.sameArgsAsBefore(firstArg, ...otherArgs)) return;
		this.logItToConsole(console.warn, '⚠️', firstArg, '#e28e33', ...otherArgs);
	}

	/**
	 * A deprecation message that gets logged to the console.
	 * It will **not** be reported to sentry.io. If you need something to be reported to sentry.io, use {@link error}.
	 * @param firstArg whatever should be logged.
	 * @param otherArgs more information about the error.
	 */
	public deprecated(firstArg : unknown, ...otherArgs : unknown[]) : void {
		if (this.sameArgsAsBefore(firstArg, ...otherArgs)) return;
		this.logItToConsole(console.debug, '♻️', firstArg, '#f3f', ...otherArgs);
	}

	/**
	 * An error that gets logged to the console and also sent to sentry.io.
	 * In DEBUG mode it will also trigger the error-modal with a silently different message than the usual error-modal,
	 * which opens when an error gets thrown.
	 * @param firstArg whatever should be logged. This will also be the message that gets sent to sentry.io
	 * @param otherArgs more information about the error.
	 */
	public error(firstArg : unknown, ...otherArgs : unknown[]) : void {
		if (this.sameArgsAsBefore(firstArg, ...otherArgs)) return;

		void this.reportLogError(console.error, '🚨', firstArg, '#d12733', ...otherArgs);
	}

	private async reportLogError(
		fn : (msg ?: Primitive, ...optionalParams : Primitive[]) => void,
		icon : string,
		firstArg : unknown,
		color : string = '#ccc',
		...otherArgs : unknown[]
	) : Promise<void> {
		if (this.spamBlockerIsActive) {
			console.warn('Error reporting has been blocked', firstArg, ...otherArgs);
			return;
		}

		this.spamBlockerIsActive = true;
		window.setTimeout(() => {
			this.spamBlockerIsActive = false;
		}, 1000);

		if (Config.DEBUG && Config.APPLICATION_MODE !== 'KARMA') {
			// In debug mode a console.error(…) gets handled like an error in the UI
			console.error('You are in DEBUG mode. This error is triggering the error-modal only in DEBUG mode.', firstArg, ...otherArgs);

			const modal = this.injector?.get<NgbModal>(NgbModal);
			if (!modal) throw new Error(`Could not get modal service for console.error(${JSON.stringify(firstArg)})`);
			const errorModal = modal.open(PErrorModalContentComponent, {
				// size: this.enums.BootstrapSize.LG
				backdrop: 'static',
				backdropClass: 'not-clickable bg-dark',
				keyboard: false,
			}).componentInstance as PErrorModalContentComponent;
			void errorModal.initModal(new Error(JSON.stringify(firstArg)), PSeverity.WARNING);
		} else {
			// NOTE: If app is not in debug mode then its probably one of our real users online.
			// don’t bother him with error-modals, but create a sentry entry with some data about this error.
			this.logItToConsole(fn, icon, firstArg, color, ...otherArgs);
		}

		this.logItToConsole(fn, icon, 'This error will be reported to sentry.io', color);
		const otherArgsAsStrings = otherArgs.map(item => JSON.stringify(item));
		await this.pSentryService?.captureMessage(JSON.stringify(firstArg), {
			level: PSeverity.WARNING,
			fingerprint: [JSON.stringify(firstArg), ...otherArgsAsStrings],
			extra: {
				error: firstArg,
			},
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public group(firstArg : unknown) : void {
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		console.group(`%c${JSON.stringify(firstArg)}`, 'font-weight:normal;font-family:"FiraMono", monospace;font-size:0.8rem;padding: 4px 0');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public groupCollapsed(firstArg : unknown) : void {
		// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		console.groupCollapsed(`%c${JSON.stringify(firstArg)}`, 'font-weight:normal;font-family:"FiraMono", monospace;font-size:0.8rem;padding: 4px 0');
	}

	/**
	 * Log a message to the console
	 *
	 * @param fn The console function to use
	 * @param icon An icon to be attached to the log
	 * @param firstArg The error message to be logged
	 * @param color A color to be used for the log
	 * @param otherArgs More information about the error to be logged
	 */
	private logItToConsole(
		fn : (msg ?: Primitive, ...optionalParams : Primitive[]) => void,
		icon : string,
		firstArg : unknown,
		color : string = '#ccc',
		...otherArgs : unknown[]
	) : void {
		const firstArgAsString = typeof firstArg === 'string' ? firstArg : JSON.stringify(firstArg);
		if (fn === console.debug) {
			const params : unknown[] = [`${icon} ${firstArgAsString}`];
			if (typeof firstArg !== 'string') params.push(firstArg);
			params.push(...otherArgs);
			console.debug(...params);
			return;
		}

		console.groupCollapsed(
			`%c${icon} ${firstArgAsString}`,
			`color:${color};font-weight:normal;font-family:"FiraMono", monospace;font-size:0.8rem;padding: 4px 0`,
		);
		fn('args:', ...this.toPrimitiveArray(otherArgs));
		console.trace();
		console.groupEnd();
	}

	private argsCache : {
		firstArg : string;
		otherArgs : string;
	}[] = [];

	/**
	 * Here we prevent the debug error modal to spam.
	 *
	 * @param firstArg The error message
	 * @param otherArgs More information about the error
	 */
	private sameArgsAsBefore(
		firstArg : unknown,
		...otherArgs : unknown[]
	) : boolean {
		const firstArgString = JSON.stringify(firstArg);
		const otherArgsString = JSON.stringify(this.toPrimitiveArray(otherArgs));
		const matches = this.argsCache.filter(item => {
			return item.firstArg === firstArgString && item.otherArgs === otherArgsString;
		});
		if (matches.length >= 3) return true;

		this.argsCache.push({
			firstArg: firstArgString,
			otherArgs: otherArgsString,
		});

		return false;
	}

}
