/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { PSupportedLanguageCodes, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { NonEmptyString, notEmptyString } from '@plano/shared/core/utils/typescript-utils-types';
import { PDictionarySourceString, PDictionaryTranslation, getDictionaryEntry } from './localize.dictionary';

/**
 * Parameters needed for translating a source string.
 */
export interface LocalizePipeParamsType { [key : string] : string; }

/**
 * Combination of a source string and params needed for its translation.
 */
export type PDictionarySourceStringAndParams = {
	sourceString : PDictionarySourceString;

	/**
	 * Parameters needed to translate `sourceString`.
	 *
	 * Example:
	 * If `sourceString` is 'Hallo ${thing}'
	 * and the param is { thing: 'Welt' },
	 * the output will be 'Hallo Welt'
	 */
	params : LocalizePipeParamsType | false;
};

/**
 * Parameter to be passed `transform()`. Either it is directly the source string or a combination of source string and the needed
 * parameters for translation.
 */
export type PDictionarySource = PDictionarySourceStringAndParams | PDictionarySourceString;

/**
 * A Pipe to translate strings in our App.
 *
 * If possible, it is preferred to use Angular's i18n markers/Weblate to translate strings.
 *
 * This pipe was invented while we where waiting for Angular 9's new i18n features.
 * Angular brought some features but not the possibility to have variables in these strings.
 *
 * Example usage:
 *   this.localize.transform(
 *     'Hello ${firstName} ${lastName} @ ${company}',
 *     {
 *       firstName: 'Nils',
 *       lastName: 'Neumann',
 *       company: 'Dr.&nbsp;Plano'
 *     }
 *   );
 *
 * TODO: PLANO-46679 Remove this Service if possible in the favor of Angular's i18n features.
 */
@Pipe({
	// eslint-disable-next-line @angular-eslint/pipe-prefix -- This is a heavily used pipe. We want to keep it short.
	name: 'localize',
	standalone: true,
})
export class LocalizePipe implements PipeTransform {
	constructor(
		private console : LogService,
		@Inject(LOCALE_ID) locale : PSupportedLocaleIds,
	) {
		this.languageCode = Config.getLanguageCode(locale);
	}

	private languageCode : PSupportedLanguageCodes;

	/**
	 * Use this only for Tests
	 */
	public languageTestSetter(language : PSupportedLanguageCodes | null) : void {
		if (language !== null) this.languageCode = language;
		this.console.debug(`this.languageCode: ${this.languageCode}`);
	}

	/**
	 * Use this only for Tests
	 */
	public languageTestGetter() : PSupportedLanguageCodes {
		return this.languageCode;
	}

	/**
	 * Takes a string, looks it up in our dictionary, returns the string in the users language.
	 * @param input The string to translate.
	 */
	private translate<SourceString extends PDictionarySourceString>(
		input : SourceString,
	) : PDictionaryTranslation<SourceString, PSupportedLanguageCodes.en> {
		// Get the translated string from the dictionary.
		const TRANSLATED = getDictionaryEntry(input, this.languageCode as PSupportedLanguageCodes.en);

		// Is the returned string null? Then someone probably asked for a translation of a string that has no entry.
		// This should never happen. The type system should have prevented this.
		// But if it happens, we don’t want the app to crash. We just show the user the untranslated string, and log an
		// error.
		if (TRANSLATED === null) {
			this.console.error(`»${input}« in localize.dictionary.ts has no translation entry.`);
			return input as PDictionaryTranslation<SourceString, PSupportedLanguageCodes.en>;
		}

		return TRANSLATED;
	}

	/**
	 * Take the provided params and put them into the sentence where the param-fitting marker is.
	 * Example: It takes 'Hallo ${thing}' and { thing: 'Welt' } and makes 'Hallo Welt'.
	 */
	private replaceMarkersWithProvidedParams<SourceString extends PDictionarySourceString>(
		translatedInputString : PDictionarySourceString | PDictionaryTranslation<SourceString, PSupportedLanguageCodes.en>,
		params : LocalizePipeParamsType | false,
		logVarDiffs : boolean,
	) : NonEmptyString {
		if (params === false) return translatedInputString;

		let result : NonEmptyString = translatedInputString;
		for (const KEY of Object.keys(params)) {

			// Param has been provided, but marker is missing in string? This seems like some developer did a mistake.
			if (logVarDiffs && !translatedInputString.includes(`\$\{${KEY}\}`)) this.console.error(`Can’t find »\$\{${KEY}\}« in »${translatedInputString}«`);

			// Take the provided param and put it into the string.
			const VALUE = params[KEY] as typeof params[typeof KEY] | undefined;

			if (typeof VALUE !== 'string' || VALUE === '') {
				if (translatedInputString.includes(`\$\{${KEY}\}`)) {
					let reason : string;
					if (typeof VALUE === 'string') {
						reason = 'empty';
					} else {
						reason = `of type ${typeof VALUE}`;
					}
					this.console.error(`»${KEY}« is part of text »${translatedInputString}«, but the value of the marker is ${reason}.`);
				}
				continue;
			}

			const regexString = `\\\$\\\{${KEY}\\\}`; // String will be e.g. '\$\{firstName\}'
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			const regex = new RegExp(regexString, 'g');
			// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			result = notEmptyString(result.replaceAll(new RegExp(regex, 'g'), VALUE));
		}
		return result;
	}

	private validateIO(input : PDictionarySourceString, output : string, params : LocalizePipeParamsType | false | null = null) : void {
		// If params is set to false instead of an object, then its intended that the variable markers don’t get replaced
		if (params === false) return;

		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const HAD_NO_VARIABLE_MARKERS = !input.match(/\${[^{}]*}/g);
		if (HAD_NO_VARIABLE_MARKERS) return;

		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const MISSING_VARIABLE_MARKERS = output.match(/\${[^{}]*}/g);
		if (MISSING_VARIABLE_MARKERS && Config.DEBUG) {
			for (const MARKER of MISSING_VARIABLE_MARKERS) this.console.error(`Missing variable to replace »${MARKER}« in »${input}«. Output: »${output}«, Params: ${JSON.stringify(params)}`);
		}
	}

	/**
	 * Translates segments
	 *
	 * @example
	 * this.localize.transform('Shift');
	 * @example
	 * this.localize.transform('Hallo ${thing}', { thing: universe.planets[0].name });
	 * @example
	 * <p>{{ item.title | localize }}</p>
	 * @param input the string to translate
	 * @param logVarDiffs if true or not provided (null), logs if a variable is missing in the translation. Default is true.
	 */
	public transform(
		input : PDictionarySource,
		logVarDiffs : boolean = true,
	) : NonEmptyString {
		const sourceString = typeof input === 'string' ? input : input.sourceString ;
		const params = typeof input === 'string' ? null : input.params;

		let stringTranslation : (
			PDictionarySourceString | PDictionaryTranslation<PDictionarySourceString, PSupportedLanguageCodes.en>
		);

		if (this.languageCode === PSupportedLanguageCodes.de) {
			// If target is german, then nothing needs to be translated.
			stringTranslation = sourceString;
		} else {
			// Example: Translate 'Hallo ${thing}' into 'Hello ${thing}'
			stringTranslation = this.translate(sourceString);
		}

		let result : NonEmptyString;

		// Example: Take { thing: 'World' } and put the value of `thing` into 'Hallo ${thing}' so it makes 'Hello World'
		if (params !== null && params !== false) {
			result = this.replaceMarkersWithProvidedParams(stringTranslation, params, logVarDiffs);
		} else {
			result = stringTranslation;
		}

		// Check if there seems to be anything wrong in the result.
		// TODO: This is probably dead code but i‘m not sure how to safely prove this.
		this.validateIO(sourceString, result, params);

		return result;
	}
}
