/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
import { Directive, HostBinding, Input, TemplateRef } from '@angular/core';
import { VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PBackgroundColor } from '@plano/client/shared/bootstrap.utils';
import { ModalDismissParam, ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { NonUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export interface PModalTemplateContext {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This needs to be any because we can never know the type.
	$implicit : any;
	close : (value : unknown) => void;
	dismiss : (value : ModalDismissParam) => void;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	formControl ?: PFormControl | null;
	errors ?: VisibleErrorsType;
	theme : PBackgroundColor | null;
	size : NonUndefined<ModalServiceOptions['size']> | null;
}

/**
 * You can use this directive to improve the type checks in template files, and to provide values to both the child
 * component as well as the modal.open() function.
 *
 * Note that you usually want to use {@link PModalTemplateDirective} instead. This directive is used when you want to
 * render the modal content directly to the dom in your component, and not only provide it as a {@link TemplateRef}.
 *
 * To learn how to use modals, read the docs of {@link ModalService#openModal}.
 *
 * @example
 * 	<div
 * 		#myModalContent="pModalTemplateExportAsRef"
 * 		pModalTemplate
 * 	>
 * 		<p-modal-content
 * 			(onDismiss)="onDismiss()"
 */
@Directive({
	selector: 'div[pModalTemplate]',
	exportAs: 'pModalTemplateExportAsRef',
	standalone: true,
})
export class PModalContentWrapperDirective {

	/**
	 * The modal content needs to be scrollable / the header and footer should be fixed.
	 * We add the .modal-content class here to make sure that this is kept even with the additional div between the
	 * original modal wrapper and the content.
	 */
	// eslint-disable-next-line no-restricted-syntax -- Needed here to not make _alwaysTrue a required param for the options in `modalTemplate.template.createEmbeddedView(options)`
	@HostBinding('class.modal-content')
	private readonly _alwaysTrue ?: true = true as const;

	/**
	 * Id for the modal template, or multiple ids for the modal template, if the same
	 * template is used for multiple version of the modal.
	 */
	@Input({required: true}) public pModalId ?: string | string [] | null;

	/**
	 * The function to be called when the modal gets closed.
	 */
	@Input() public close ?: PModalTemplateContext['close'];

	/**
	 * The function to be called when the modal gets dismissed.
	 */
	@Input() public dismiss ?: PModalTemplateContext['dismiss'];

	/**
	 * The formControl that is associated with the modal content.
	 */
	@Input() public formControl ?: PModalTemplateContext['formControl'] = null;

	/**
	 * The errors that are associated with the modal content.
	 */
	@Input() public errors ?: PModalTemplateContext['errors'];

	/**
	 * The theme for the modal.
	 */
	@Input() public theme ?: PModalTemplateContext['theme'] = null;

	/**
	 * The size of the modal.
	 */
	@Input() public modalSize ?: NonUndefined<ModalServiceOptions['size']> | null = null;

	/**
	 * When the modal with this content opens, the index of the modal in the modal stack is stored here.
	 * If the modal is not opened, this is null.
	 */
	public openModalStackIndex ?: number | null = null;
}

/**
 * You can use this directive to improve the type checks in template files, and to provide values to both the child
 * component as well as the modal.open() function.
 *
 * To learn how to use modals, read the docs of {@link ModalService#openModal}.
 *
 * @example
 * 	<ng-template
 * 		#myModalContent="pModalTemplateExportAsRef"
 * 		pModalTemplate
 * 		let-c="close"
 * 		let-d="dismiss"
 * 	>
 * 		<p-modal-content
 * 			(onDismiss)="d('clicked dismiss')" // ERROR: Argument of type '"clicked dismiss"' is not assignable to parameter of type 'ModalDismissParam'
 * 			(onClose)="c($event);"
 */
@Directive({
	selector: 'ng-template[pModalTemplate]',
	exportAs: 'pModalTemplateExportAsRef',
	standalone: true,
})
export class PModalTemplateDirective extends PModalContentWrapperDirective {
	/**
	 * Usually, when you use a template like
	 * 	<ng-container *ngTemplateOutlet="modalTemplate!.template; context: { $implicit: value, … }"
	 * , you can always pass context as $implicit.
	 *
	 * @example
	 * In a case like the following, the let-pdfSrc would be the $implicit value:
	 * <ng-template
	 * 	#myPDFModal="pModalTemplateExportAsRef"
	 * 	[pModalId]="…"
	 * 	pModalTemplate
	 * 	let-pdfSrc
	 * 	let-formControl="…"
	 * 	let-c="…"
	 * 	let-d="…"
	 * 	let-errors="…"
	 * >
	 */
	@Input() public $implicit ?: PModalTemplateContext['$implicit'];

	constructor(
		public template : TemplateRef<PModalTemplateDirective>,
	) {
		super();
	}

	/** Overwrite the types */
	public static ngTemplateContextGuard(
    // eslint-disable-next-line unicorn/prevent-abbreviations -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
    _dir : PModalTemplateDirective,
    // eslint-disable-next-line unicorn/prevent-abbreviations -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
    _ctx : PModalTemplateContext,
	) : _ctx is PModalTemplateContext {
		return true;
	}
}
