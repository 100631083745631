<div class="{{theme ? 'modal-'+theme : ''}} d-flex flex-column h-100 wrapper-modal">
	<div
		*ngIf="showModalHeader"
		class="modal-header modal-header-content"
	>
		@if (isString(modalTitle)) {
			<div class="me-auto w-100 h-fit-content">
				<h3 class="m-0 d-flex gap-2"
					[class.text-white]="textWhite"
				><fa-icon
					*ngIf="!!icon"
					[icon]="icon"
					[theme]="iconTheme"
				/><span [innerHTML]="modalTitle | pSafeHtml"></span></h3>
			</div>
			@if (modalOptionsTemplate) {
				<ng-container *ngTemplateOutlet="modalOptionsTemplate" />
			}
			<button type="button" class="btn btn-frameless large p-0 close" title="Schließen" i18n-title
				[class.text-white]="textWhite"
				(click)="modalService.topModalRef?.dismiss()"
			>
				<fa-icon
					[pCloseOnEsc]="modalService.topModalKeyboard ?? true"
					pCloseOnEscType="modal"
					aria-hidden="true"
					[theme]="theme===enums.PThemeEnum.DARK ? PTextColorEnum.WHITE : null"
					[icon]="enums.PlanoFaIconContextPool.DISMISS"
				/>
			</button>
		} @else if (!!modalTitle) {
			<ng-container *ngTemplateOutlet="modalTitle" />
		}
	</div>

	<div class="modal-body position-relative"
		[skipDeprecationCheck]="true"
		[class.p-0]="size === 'frameless'"
		[class.text-white]="textWhite"
		[style.height]="modalBodyHeight"
	>
		<div class="modal-body-content">
			<ng-content select="p-modal-content-body" />
		</div>
	</div>
	<div #footer [class.d-none]="!showCustomFooter" class="footer-wrapper">
		<ng-content select="p-modal-content-footer" />
	</div>

	@if (!hideInternalFooter) {
		<div class="modal-footer d-flex justify-content-between align-items-center">
			<div class="modal-footer-content d-flex justify-content-end gap-2">
				<ng-container *ngTemplateOutlet="dismissBtnRef" />
				<div
					*ngIf="!hideCloseBtn"
					class="ms-auto"
				>
					<div
						[pTooltip]="closeBtnTooltip"
					>
						<ng-container *ngTemplateOutlet="closeBtnRef" />
					</div>
				</div>
			</div>
		</div>
	}
</div>

<ng-template #dismissBtnRef>
	@if (!hideDismissBtn) {
		<p-button
			btnClasses="modal-dismiss-button"

			[theme]="dismissBtnTheme"
			(triggerClick)="modalService.topModalRef?.dismiss()"
			[icon]="enums.PlanoFaIconContextPool.UNDO"
		>{{ dismissBtnLabel }}</p-button>
	}
</ng-template>

<ng-template #closeBtnRef>
	<p-button
		btnClasses="modal-close-button"

		[pCloseOnEsc]="!showModalHeader && modalService.topModalKeyboard ?? true"
		pCloseOnEscType="modal"
		[animate]="false"

		[theme]="closeBtnTheme"
		(triggerClick)="onClose.emit($event);"
		[disabled]="closeBtnDisabled"
		[icon]="closeBtnIcon"
		[badgeOrIconPosition]="closeBtnIconPosition"
	>{{ closeBtnLabelAsString }}</p-button>
</ng-template>
