import { SchedulingApiAccountHolderState, SchedulingApiAdyenAccountBase } from '@plano/shared/api';
import { ApiDataCopyAttribute } from '@plano/shared/api/base/api-data-copy-attribute/api-data-copy-attribute';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';

// eslint-disable-next-line jsdoc/require-jsdoc -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAdyenAccount extends SchedulingApiAdyenAccountBase {
	// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public adyenTermsOfServiceAccepted = new ApiDataCopyAttribute<boolean>(false);
	// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public adyenContractAccepted = new ApiDataCopyAttribute<boolean>(false);

	/**
	 *	true if all checkboxes are accepted
	 */
	public get allCheckboxesAccepted() : boolean {
		if (this.aiAdyenTermsOfServiceAccepted.isAvailable && this.aiAdyenContractAccepted.isAvailable) {
			return this.aiAdyenTermsOfServiceAccepted.value! && this.aiAdyenContractAccepted.value!;
		} else return false;
	}

	/**
	 *	true if this account is closed or suspended
	 */
	public get isClosedOrSuspended() : boolean {
		return this.accountHolderState === SchedulingApiAccountHolderState.CLOSED ||
		this.accountHolderState === SchedulingApiAccountHolderState.SUSPENDED;
	}

	// FIXME: PLANO-161893
	// eslint-disable-next-line no-restricted-syntax -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public aiAdyenTermsOfServiceAccepted = new ApiAttributeInfo<SchedulingApiAdyenAccount, boolean>({
		apiObjWrapper: this,
		name: 'adyenTermsOfServiceAccepted',
		nodeName: 'ADYEN_ACCOUNT_ADYEN_TERMS_OF_SERVICE_ACCEPTED',
		type: PApiType.boolean,
		hasPermissionToSet: () => {
			return !!this.api.pPermissionsService.isOwner;
		},
		canSetByBusinessLogic: () => {
			return !this.api.me.isTestAccount &&
			this.accountHolderState === SchedulingApiAccountHolderState.NOT_INITIALIZED;
		},
	});

	// FIXME: PLANO-161893
	// eslint-disable-next-line no-restricted-syntax -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public aiAdyenContractAccepted = new ApiAttributeInfo<SchedulingApiAdyenAccount, boolean>({
		apiObjWrapper: this,
		name: 'adyenContractAccepted',
		nodeName: 'ADYEN_ACCOUNT_ADYEN_CONTRACT_ACCEPTED',
		type: PApiType.boolean,
		hasPermissionToSet: () => {
			return !!this.api.pPermissionsService.isOwner;
		},
		canSetByBusinessLogic: () => {
			return !this.api.me.isTestAccount &&
			this.accountHolderState === SchedulingApiAccountHolderState.NOT_INITIALIZED;
		},
	});
}
