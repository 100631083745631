/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "export_transactions".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	TRANSACTION_IDS = 1;
	SHIFT_MODEL_IDS = 2;
}


@Injectable({
  providedIn: 'root',
})
export class ExportTransactionsApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_transactions');
	}

	consts = new Consts();

	protected version() : string {
		return '6f64e5e798d1b84eccb1fbb9ea55b16b,2f2daae3c679669a247f0e4cbe3392db';
	}

	private dataWrapper = new ExportTransactionsApiRoot(this);

	get data() : ExportTransactionsApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportTransactionsApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportTransactionsApiRoot(this);
	}
}

		 
export class ExportTransactionsApiRoot extends ApiObjectWrapper<ExportTransactionsApiRoot>
{
	constructor(override readonly api : ExportTransactionsApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportTransactionsApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportTransactionsApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportTransactionsApiRoot, ExportTransactionsApiRoot> = new ApiAttributeInfo<ExportTransactionsApiRoot, ExportTransactionsApiRoot>({
			apiObjWrapper: this as any as ExportTransactionsApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : ExportTransactionsApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.schedulingApi.currentlyDetailedLoaded instanceof SchedulingApiBooking &&
						this.api.pPermissionsService.hasManagerPermissionForShiftModel(this.api.schedulingApi.currentlyDetailedLoaded.shiftModelId)));
			},
			hasPermissionToSet: function(this : ExportTransactionsApiRoot) {
	return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER))||(this.api.schedulingApi.currentlyDetailedLoaded instanceof SchedulingApiBooking &&
						this.api.pPermissionsService.hasManagerPermissionForShiftModel(this.api.schedulingApi.currentlyDetailedLoaded.shiftModelId)));
			},
			defaultValue: function(this : ExportTransactionsApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private transactionIdsWrapper : ExportTransactionsApiTransactionIds = new ExportTransactionsApiTransactionIds(this.api,
		this as unknown as ExportTransactionsApiRoot, false, false);
	public aiTransactionIds = this.transactionIdsWrapper.aiThis;

	/**
	 * Id list of the transactions to be exported.
	 */
	get transactionIds() : ExportTransactionsApiTransactionIds {
		this.getterDebugValidations(this.aiTransactionIds, false);
		return this.transactionIdsWrapper;
	}

	set transactionIdsTestSetter(v : ExportTransactionsApiTransactionIds) {
        this.setterImpl(1, v.rawData, 'transactionIds', true, null, function(this : ExportTransactionsApiRoot) {this.transactionIdsWrapper = v;});
	}

	private shiftModelIdsWrapper : ExportTransactionsApiShiftModelIds = new ExportTransactionsApiShiftModelIds(this.api,
		this as unknown as ExportTransactionsApiRoot, false, false);
	public aiShiftModelIds = this.shiftModelIdsWrapper.aiThis;

	/**
	 * Id list of the shift model to be exported.
	 */
	get shiftModelIds() : ExportTransactionsApiShiftModelIds {
		this.getterDebugValidations(this.aiShiftModelIds, false);
		return this.shiftModelIdsWrapper;
	}

	set shiftModelIdsTestSetter(v : ExportTransactionsApiShiftModelIds) {
        this.setterImpl(2, v.rawData, 'shiftModelIds', true, null, function(this : ExportTransactionsApiRoot) {this.shiftModelIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.transactionIdsWrapper._fixIds(_idReplacements);
		this.shiftModelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.transactionIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.shiftModelIdsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportTransactionsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportTransactionsApiTransactionIds extends ApiListWrapper<ExportTransactionsApiTransactionId>
{
	constructor(override readonly api : ExportTransactionsApiService,
		override readonly parent : ExportTransactionsApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'transactionIds');
	}

	override wrapRawData(itemRawData : any) : ExportTransactionsApiTransactionId {
		return new ExportTransactionsApiTransactionId(this.api, this as unknown as ExportTransactionsApiTransactionIds, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ExportTransactionsApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportTransactionsApiTransactionIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : ExportTransactionsApiTransactionId) => void) | null = null, _backendId : Id | null = null) : ExportTransactionsApiTransactionId {
		const newItem = new ExportTransactionsApiTransactionId(this.api, this as unknown as ExportTransactionsApiTransactionIds, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		this.api.changed('transactionIds');

		return newItem;
	}

	override aiThis : ApiAttributeInfo<ExportTransactionsApiTransactionIds, ExportTransactionsApiTransactionIds> = new ApiAttributeInfo<ExportTransactionsApiTransactionIds, ExportTransactionsApiTransactionIds>({
			apiObjWrapper: this as any as ExportTransactionsApiTransactionIds,
			name: 'transactionIds',
			nodeName: 'TRANSACTION_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportTransactionsApiTransactionIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.ApiObject,
			rawDataIndex: 1,
		});
	aiTransactionId : ApiAttributeInfo<ExportTransactionsApiTransactionIds, ExportTransactionsApiTransactionId> = new ApiAttributeInfo<ExportTransactionsApiTransactionIds, ExportTransactionsApiTransactionId>({
			apiObjWrapper: this as any as ExportTransactionsApiTransactionIds,
			name: 'transactionId',
			nodeName: 'TRANSACTION_ID',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportTransactionsApiTransactionIds, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class ExportTransactionsApiTransactionId extends ApiObjectWrapper<ExportTransactionsApiTransactionId>
{
	constructor(override readonly api : ExportTransactionsApiService,
		override readonly parent : ExportTransactionsApiTransactionIds | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportTransactionsApiTransactionId> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ExportTransactionsApiTransactionId as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportTransactionsApiTransactionId, ExportTransactionsApiTransactionId> = new ApiAttributeInfo<ExportTransactionsApiTransactionId, ExportTransactionsApiTransactionId>({
			apiObjWrapper: this as any as ExportTransactionsApiTransactionId,
			name: 'transactionId',
			nodeName: 'TRANSACTION_ID',
			type: PApiType.ApiObject,
			defaultValue: function(this : ExportTransactionsApiTransactionId, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('TRANSACTION_ID should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '4';
	}

	static async loadDetailed(	api : ExportTransactionsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '4', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportTransactionsApiShiftModelIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportTransactionsApiService,
		override readonly parent : ExportTransactionsApiRoot | null,
		override readonly isView : boolean,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, isView, removeDestroyedItems, 'shiftModelIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportTransactionsApiRoot | null, isView : boolean, removeDestroyedItems : boolean) : this {
		return new ExportTransactionsApiShiftModelIds(this.api, _parent, isView, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '3';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override aiThis : ApiAttributeInfo<ExportTransactionsApiShiftModelIds, ExportTransactionsApiShiftModelIds> = new ApiAttributeInfo<ExportTransactionsApiShiftModelIds, ExportTransactionsApiShiftModelIds>({
			apiObjWrapper: this as any as ExportTransactionsApiShiftModelIds,
			name: 'shiftModelIds',
			nodeName: 'SHIFT_MODEL_IDS',
			type: PApiType.ApiList,
			defaultValue: function(this : ExportTransactionsApiShiftModelIds, _nodeId : string) {return Meta.createNewList();},
			listItemType: PApiType.Id,
			rawDataIndex: 2,
		});
	aiShiftModelId : ApiAttributeInfo<ExportTransactionsApiShiftModelIds, Id> = new ApiAttributeInfo<ExportTransactionsApiShiftModelIds, Id>({
			apiObjWrapper: this as any as ExportTransactionsApiShiftModelIds,
			name: 'shiftModelId',
			nodeName: 'SHIFT_MODEL_ID',
			type: PApiType.Id,
			
		});
}

		

