/* eslint-disable no-restricted-syntax -- Added this to be allowed to use "new ApiAttributeInfo" */
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiType } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, LabelId, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color, Emoji } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { notNull } from '@plano/shared/core/utils/null-type-utils';



/**
 * This service enables access to the api "export_marketing_gift_card_statistics_excel".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
}


@Injectable({
  providedIn: 'root',
})
export class ExportMarketingGiftCardStatisticsExcelApiService extends ApiBase
{
	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_marketing_gift_card_statistics_excel');
	}

	consts = new Consts();

	protected version() : string {
		return '0abcdfd69625ed48ee5cee5a253f2d04,d33fb57309011aa3bf7991ca4988b58b';
	}

	private dataWrapper = new ExportMarketingGiftCardStatisticsExcelApiRoot(this);

	get data() : ExportMarketingGiftCardStatisticsExcelApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportMarketingGiftCardStatisticsExcelApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportMarketingGiftCardStatisticsExcelApiRoot(this);
	}
}

		 
export class ExportMarketingGiftCardStatisticsExcelApiRoot extends ApiObjectWrapper<ExportMarketingGiftCardStatisticsExcelApiRoot>
{
	constructor(override readonly api : ExportMarketingGiftCardStatisticsExcelApiService,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		params : ApiObjectWrapperNewItemParams<ExportMarketingGiftCardStatisticsExcelApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportMarketingGiftCardStatisticsExcelApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override aiThis : ApiAttributeInfo<ExportMarketingGiftCardStatisticsExcelApiRoot, ExportMarketingGiftCardStatisticsExcelApiRoot> = new ApiAttributeInfo<ExportMarketingGiftCardStatisticsExcelApiRoot, ExportMarketingGiftCardStatisticsExcelApiRoot>({
			apiObjWrapper: this as any as ExportMarketingGiftCardStatisticsExcelApiRoot,
			name: '',
			nodeName: 'ROOT',
			type: PApiType.ApiObject,
			hasPermissionToGet: function(this : ExportMarketingGiftCardStatisticsExcelApiRoot) {
				return ((this.api.pPermissionsService.userIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			hasPermissionToSet: () => false,
			defaultValue: function(this : ExportMarketingGiftCardStatisticsExcelApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportMarketingGiftCardStatisticsExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}



