import { WorkRuleFilters } from '@plano/client/work-models/work-model/detail-form/work-rule-modal-box/wizard-steps/work-rule-filters/filters/work-rule-filter/work-rule-filter.component';
import { WorkRuleEvaluationItemType } from '@plano/client/work-models/work-model/detail-form/work-rule-modal-box/work-rule.utils';
import { SchedulingApiHolidayItemType, SchedulingApiWorkModelWorkRuleVersionFilterList, SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters } from '@plano/shared/api';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';

/**
 * The filters can be applied either by time intersection or by attribute,
 * depending on the evaluation item type and the filter.
 * This enum represents the possible values for the filter calculation.
 */
export enum WorkRuleFilterCalculationEnum {
	TIME_INTERSECTION,
	ATTRIBUTE,
}

/**
 * The possible values for the filter calculation.
 * If it is null, it means that the filter is not relevant for the evaluation item type, so it should be disabled.
 */
export type WorkRuleFilterCalculation = WorkRuleFilterCalculationEnum | null;

/**
 * A matrix that stores the filter calculation for each evaluation item type and filters.
 */
export class FilterEvaluationTypeMatrix {

	constructor(
		private filterList : SchedulingApiWorkModelWorkRuleVersionFilterList,
	) {
		this.fillFilterEvaluationTypeMatrix();
	}

	private filterEvaluationTypeMatrix = new Map<WorkRuleEvaluationItemType, Map<string, WorkRuleFilterCalculation>>();

	/**
	 * Fill the filter evaluation type matrix with the correct filter calculation types.
	 * The implementation is based on the following table:
	 * https://drplano.atlassian.net/wiki/spaces/KONZEPTECK/pages/3015802886/Work+models+and+rules+concept+2024#Implementation-(Filters-and-check-elements)
	 */
	private fillFilterEvaluationTypeMatrix() : void {
		this.fillFilterEvaluationTypeMatrixTimePeriodColumn();
		this.fillFilterEvaluationTypeMatrixWorkSessionColumn();
		this.fillFilterEvaluationTypeMatrixLeaveColumn();
		this.fillFilterEvaluationTypeMatrixPayDifferentialsColumn();
		this.fillFilterEvaluationTypeMatrixPauseColumn();
		this.fillFilterEvaluationTypeMatrixRestPeriodColumn();
		this.fillFilterEvaluationTypeMatrixPublicHolidaysColumn();
		this.fillFilterEvaluationTypeMatrixSchoolHolidaysColumn();
		this.fillFilterEvaluationTypeMatrixShiftPreferenceColumn();
	}

	private fillFilterEvaluationTypeMatrixTimePeriodColumn() : void {
		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.usersAgeFilters, null);

		this.set(
			WorkRuleEvaluationItemType.TIME_PERIOD,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.TIME_PERIOD,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.activityFilters, null);

		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.TIME_PERIOD, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private fillFilterEvaluationTypeMatrixWorkSessionColumn() : void {
		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.usersAgeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(
			WorkRuleEvaluationItemType.WORK_SESSION,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.WORK_SESSION,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.activityFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.WORK_SESSION, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private fillFilterEvaluationTypeMatrixLeaveColumn() : void {
		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.usersAgeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(
			WorkRuleEvaluationItemType.LEAVE,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.LEAVE,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.activityFilters, null);

		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.LEAVE, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private fillFilterEvaluationTypeMatrixPayDifferentialsColumn() : void {
		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.usersAgeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(
			WorkRuleEvaluationItemType.PAY_DIFFERENTIALS,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.PAY_DIFFERENTIALS,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.activityFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PAY_DIFFERENTIALS, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private fillFilterEvaluationTypeMatrixPauseColumn() : void {
		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.itemCategoryFilters, null);

		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.leaveDayPaymentTypeFilters, null);

		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.leaveDayTypeFilters, null);

		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.usersAgeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(
			WorkRuleEvaluationItemType.PAUSE,
			this.filterList.holidayItemLabelFilters,
			null,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.PAUSE,
			this.filterList.holidayItemLabelFilters,
			null,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.activityFilters, null);

		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.localTimeFilters, null);

		this.set(WorkRuleEvaluationItemType.PAUSE, this.filterList.weekdayFilters, null);
	}

	private fillFilterEvaluationTypeMatrixRestPeriodColumn() : void {
		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.usersAgeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(
			WorkRuleEvaluationItemType.REST_PERIOD,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.REST_PERIOD,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.activityFilters, null);

		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.REST_PERIOD, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private fillFilterEvaluationTypeMatrixPublicHolidaysColumn() : void {
		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.usersAgeFilters, null);

		this.set(
			WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.ATTRIBUTE,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.activityFilters, null);

		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.PUBLIC_HOLIDAYS, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private fillFilterEvaluationTypeMatrixSchoolHolidaysColumn() : void {
		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.usersAgeFilters, null);

		this.set(
			WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.ATTRIBUTE,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.activityFilters, null);

		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SCHOOL_HOLIDAYS, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private fillFilterEvaluationTypeMatrixShiftPreferenceColumn() : void {
		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.itemCategoryFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.leaveDayPaymentTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.leaveDayTypeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.usersAgeFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(
			WorkRuleEvaluationItemType.SHIFT_PREFERENCE,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.PUBLIC_HOLIDAY,
		);

		this.set(
			WorkRuleEvaluationItemType.SHIFT_PREFERENCE,
			this.filterList.holidayItemLabelFilters,
			WorkRuleFilterCalculationEnum.TIME_INTERSECTION,
			SchedulingApiHolidayItemType.SCHOOL_HOLIDAY,
		);

		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.shiftPreferenceFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.activityFilters, WorkRuleFilterCalculationEnum.ATTRIBUTE);

		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.localTimeFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);

		this.set(WorkRuleEvaluationItemType.SHIFT_PREFERENCE, this.filterList.weekdayFilters, WorkRuleFilterCalculationEnum.TIME_INTERSECTION);
	}

	private getFiltersKey(filters : WorkRuleFilters, holidayType : SchedulingApiHolidayItemType | null) : string {
		let filtersKey : string = filters.aiThis.nodeName;
		if (filters instanceof SchedulingApiWorkModelWorkRuleVersionFilterListHolidayItemLabelFilters) {
			assumeNonNull(holidayType);
			filtersKey = `${filtersKey}_${holidayType === SchedulingApiHolidayItemType.PUBLIC_HOLIDAY ? 'publicHolidays' : 'schoolHolidays'}`;
		}
		return filtersKey;
	}

	/**
	 * Get a value from the matrix giving the evaluation item type and the filters to be checked.
	 * @param evaluationItemType The evaluation item type to check.
	 * @param filters The filters to check.
	 * @param holidayType The holiday type to check, by default it is null, as it isn't relevant for all filters, but if it is relevant it should be passed.
	 * @returns The value from the matrix, if it is not found yet, it returns undefined.
	 */
	public get(evaluationItemType : WorkRuleEvaluationItemType, filters : WorkRuleFilters, holidayType : SchedulingApiHolidayItemType | null = null) : WorkRuleFilterCalculation | undefined {
		const filtersKey : string = this.getFiltersKey(filters, holidayType);
		return this.filterEvaluationTypeMatrix.get(evaluationItemType)?.get(filtersKey);
	}

	/**
	 * Set a value in the matrix giving the evaluation item type and the filters to be checked.
	 * @param evaluationItemType The evaluation item type to check.
	 * @param filters The filters to check.
	 * @param value The value to set.
	 * @param holidayType The holiday type to check, by default it is null, as it isn't relevant for all filters, but if it is relevant it should be passed.
	 */
	private set(evaluationItemType : WorkRuleEvaluationItemType, filters : WorkRuleFilters, value : WorkRuleFilterCalculation, holidayType : SchedulingApiHolidayItemType | null = null) : void {
		const filtersKey : string = this.getFiltersKey(filters, holidayType);
		if (!this.filterEvaluationTypeMatrix.has(evaluationItemType)) {
			this.filterEvaluationTypeMatrix.set(evaluationItemType, new Map());
		}
		this.filterEvaluationTypeMatrix.get(evaluationItemType)!.set(filtersKey, value);
	}
}
