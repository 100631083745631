/* eslint no-autofix/@angular-eslint/prefer-standalone: "off" -- FIXME: Remove this before you work here. */
import { AfterContentChecked, AfterContentInit, Directive, DoCheck, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { ApiDataWrapperBase } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeInfoArgsBase, ApiAttributeInfoBase } from '@plano/shared/api/base/attribute-info/api-attribute-info-base';
import { PBaseClass } from '@plano/shared/base';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PPossibleErrorNames } from '@plano/shared/core/validators.types';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentChildInterface } from '@plano/shared/p-forms/p-form-control.interface';
import * as _ from 'underscore';
import { AttributeInfoComponentBaseDirectiveInterface } from './attribute-info-component-base.interface';

/**
 * A Directive for components that need to handle basic attributes of AI like show and canEdit.
 * This Directive works for ApiAttributeInfo as well as the sub-items ApiAttributeValueInfo.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	selector: '[attributeInfo]',
	providers: [ {provide: Boolean, useValue: true} ],
})
export class AttributeInfoBaseComponentDirective<
	WrapperType extends ApiDataWrapperBase = ApiDataWrapperBase,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	ArgsType extends ApiAttributeInfoArgsBase<WrapperType> = any,
> extends PBaseClass implements AttributeInfoComponentBaseDirectiveInterface<WrapperType>, AfterContentInit, OnInit {
	/**
	 * Instance of an attributeInfoBase, these attribute info handle only basic attributes of AI.
	 */
	@Input() public attributeInfo ?: ApiAttributeInfoBase<WrapperType, ArgsType> | null;

	/**
	 * Should this content be visible?
	 * If yes, set it to true
	 * If no set it to false
	 * If the decision can not be made yet (e.g. because api is not loaded yet), set it to null
	 * If the component should calculate it, dont set anything.
	 */
	@Input('show') protected showInput : AttributeInfoComponentBaseDirectiveInterface<WrapperType>['show'] | null = null;

	/**
	 * Can the user change this input?
	 * used to override the default canSet from the provided attributeInfo, if any.
	 */
	@Input('canSet') public canSetInput : AttributeInfoComponentBaseDirectiveInterface<WrapperType>['canSet'] | null = null;

	/**
	 * Hint to why the user can not edit this input.
	 * used to override the default cannotSetHint from the provided attributeInfo, if any.
	 */
	@Input('cannotSetHint') protected _cannotSetHint ?: PFormControlComponentChildInterface['cannotSetHint'];

	/** @see ValidationHintComponent#checkTouched */
	@Input('checkTouched') protected _checkTouched : boolean | null = null;

	/** @see ValidationHintComponent#checkTouched */
	protected get checkTouched() : boolean | null {
		return this._checkTouched;
	}
	protected set checkTouched(value : boolean | null) {
		this._checkTouched = value;
	}

	constructor(
		@Inject(Boolean) protected attributeInfoRequired : boolean = true,
		protected console ?: LogService,
	) {
		super();
	}

	public ngOnInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.validateAI();

		// Make sure lifecycle does not get overwritten in sub-classes.
		// More Info: https://github.com/microsoft/TypeScript/issues/21388#issuecomment-785184392
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	private validateAI() : void {
		if (!this.attributeInfoRequired) return;
		if (this.attributeInfo !== undefined) return;
		if (this.show !== undefined && this.canSet !== undefined) return;
		if (this.console) {
			this.console.error(`attributeInfo (or show & canSet) is required (${this.constructor.name})`);
		} else {
			throw new Error(`attributeInfo (or show & canSet) is required (${this.constructor.name})`);
		}
	}

	public ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (this.attributeInfo !== null) return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
		if (this.show !== undefined && this.canSet !== undefined) return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
		if (this.attributeInfoRequired) this.console?.deprecated(`${this.constructor.name}: bind either [attributeInfo]="…" or [show]="…" and [canSet]="…"`);

		// Make sure lifecycle does not get overwritten in sub-classes.
		// More Info: https://github.com/microsoft/TypeScript/issues/21388#issuecomment-785184392
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	/** Use/override {@link show} instead */
	private visible ! : never;

	/** Use/override {@link show} instead */
	private hidden ! : never;

	/**
	 * Should the content of this Component be visible?
	 * TODO: PLANO-188807 Add `@HostBinding('hidden')` here.
	 */
	public get show() : AttributeInfoComponentBaseDirectiveInterface<WrapperType>['show'] {
		if (this.showInput !== null) return this.showInput;
		if (this.attributeInfo) return this.attributeInfo.isAvailable;
		if (!this.attributeInfoRequired) return true;
		return undefined;
	}

	/**
	 * Should the user get UI elements to edit this components content?
	 */
	public get canSet() : AttributeInfoComponentBaseDirectiveInterface<WrapperType>['canSet'] {
		if (this.canSetInput !== null) return this.canSetInput;
		if (this.attributeInfo) return this.attributeInfo.canSet;
		if (!this.attributeInfoRequired) return true;
		throw new Error('canSet should be defined here');
	}

	/**
	 * A text that describes: »Why is this disabled?«
	 */
	public get cannotSetHint() : PDictionarySource | null {
		if (this._cannotSetHint !== undefined) return this._cannotSetHint;
		if (!this.attributeInfo) return null;
		return this.attributeInfo.cannotSetHint;
	}

}

/**
 * A Base for directives for components that need to handle basic attributes of AI like show and canEdit.
 * This works only for ApiAttributeInfo and not for the sub-items ApiAttributeValueInfo.
 */
@Directive({
	providers: [ {provide: Boolean, useValue: true} ],
})
/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME: This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
export class AttributeInfoComponentDirective<WrapperType extends ApiDataWrapperBase = any,
	ValueType = unknown> extends AttributeInfoBaseComponentDirective {
	@Input() public override attributeInfo ?: ApiAttributeInfo<WrapperType, ValueType> | null;
}

/**
 * A Directive that will create a formControl given an attributeInfo, if the AI is not a sub-value AI.
 */
@Directive({
	providers: [ {provide: Boolean, useValue: true} ],
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
export class PAbstractControlComponentBaseDirective<T extends ApiDataWrapperBase = any, ValueType = unknown>
	extends AttributeInfoComponentDirective<T>
	implements AfterContentInit, AfterContentChecked, DoCheck, OnDestroy {

	/**
	 * The FormGroup on which a new FormControl for this AttributeInfo should be applied to.
	 *
	 * Background: You can choose between
	 * - creating a formControl and bind it like [formControl]="myFormControl",
	 * or
	 * - set [group]="myFormGroup" and [attributeInfo]="myAttributeInfo"
	 * If you choose the second option, the component itself handles the lifecycle of the formControl.
	 * This is a newer approach. It will probably replace the whole ControlValueAccessor approach later.
	 */
	@Input() public group ?: FormGroup;

	/**
	 * The form control disabled state.
	 */
	public get disabled() : boolean {
		return !!this.explicitDisabledInput;
	}
	@Input() public set disabled(input : boolean) {
		this.explicitDisabledInput = input;
	}

	/** Set this if you want to overwrite the internal required logic */
	@Input('required') private _required : boolean = false;

	@HostBinding('class.form-control-less') private get _hasNoFormControl() : boolean {
		return !this.control;
	}

	constructor(

		/*
		 * In an component that extends PAbstractControlComponentBaseDirective you can set attributeInfoRequired = false
		 * This way we can implement attributeInfo step by step into every of our components.
		 * NOTE:  Not sure if it is the right way to implement it everywhere. Maybe we should reduce
		 *        implementation to PAISwitchComponent
		*/
		@Inject(Boolean) protected override attributeInfoRequired : boolean = true,
		protected pFormsService : PFormsService,
		protected override console ?: LogService,
	) {
		super(attributeInfoRequired, console);
	}

	public control : PFormControl | FormArray | FormGroup | null = null;

	private explicitDisabledInput : boolean | null = null;

	private attributeInfoId : string | null = null;

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (!!this.attributeInfo && !!this.group) {
			// Does this case currently exist in our code? If not, we can skip PLANO-79682 for now.
			// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
			assumeDefinedToGetStrictNullChecksRunning(this.console, 'console');

			this.attributeInfoId = this.attributeInfo.id;

			this.updateExistenceOfFormControl();
		}
		return super.ngAfterContentInit();
	}

	public ngAfterContentChecked() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		// Update the Validators.
		// NOTE: This updates the Validators, but it does not re-run the validators.
		if (this.control && this.control instanceof PFormControl) this.control.updateValidators();

		// We need to re-run the validators. But re-run them here every time, would cause an endless loop of re-runs.
		// So first we check if it is necessary.
		const newErrors = this.control?.disabled ? null : this.control?.validator?.(this.control);
		const oldErrors = {
			...this.control?.errors,
		};

		// Don’t care about async validators here.
		delete oldErrors[PPossibleErrorNames.EMAIL_USED];
		delete oldErrors[PPossibleErrorNames.EMAIL_INVALID];

		// Added pdf async validators
		delete oldErrors[PPossibleErrorNames.PDF_MAX_PAGES];
		delete oldErrors[PPossibleErrorNames.PDF_PAGE_DIMENSION];

		const ERRORS_STILL_THE_SAME = (
			!(newErrors && Object.keys(newErrors).length > 0) && Object.keys(oldErrors).length === 0 ||
			_.isEqual(newErrors, oldErrors)
		);
		if (!(ERRORS_STILL_THE_SAME)) {
			// we need to reset the errors before calculating them again,
			// because angular caches the error for the controls when the control hasn't changed
			this.control?.setErrors(null);
			this.control?.updateValueAndValidity();
		}

		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	private _prevIsAvailable : AttributeInfoBaseComponentDirective['show'];

	/**
	 * Imagine: If the component is hidden, and the control is not invalid, the group would be invalid, and the
	 * user could not do anything to solve the invalid state. This method should prevent the described case.
	 * @returns has changed
	 */
	private refreshIsAvailable() : boolean {
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.attributeInfo, 'attributeInfo');
		const newIsAvailable = this.attributeInfo.isAvailable;
		if (newIsAvailable === this._prevIsAvailable) return false;

		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.control, 'formControl');
		if (newIsAvailable) {
			if (this.control.disabled) {
				this.control.updateValueAndValidity();
			}
		} else {
			if (this.control.enabled) {
				// I added {emitEvent: false} to fix PLANO-74808
				this.control.setErrors(null);
			}
		}

		this._prevIsAvailable = newIsAvailable;
		return true;
	}

	/**
	 * @returns has changed
	 */
	public refreshValue() : boolean {
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.attributeInfo, 'attributeInfo');
		const newValue = this.attributeInfo.value;
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.control, 'formControl');
		if (newValue === this.control.value) return false;
		this.control.setValue(newValue, {emitEvent: false});
		return true;
	}

	private _prevCanSet : AttributeInfoBaseComponentDirective['canSet'];

	private refreshCanSet() : boolean {
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.attributeInfo, 'attributeInfo');
		const newCanSet = this.attributeInfo.canSet && (this.explicitDisabledInput === null ? true : !this.explicitDisabledInput);
		if (newCanSet === this._prevCanSet) return false;

		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.control, 'formControl');
		if (newCanSet) {
			if (this.control.disabled) {
				this.control.enable({onlySelf: true});
			}
		} else {
			if (this.control.enabled) {
				this.control.disable({onlySelf: true});
			}
		}

		this._prevCanSet = newCanSet;
		return true;
	}

	/**
	 * If a formControl should be hidden in UI, it should not leave any errors in the formGroup.
	 */
	private refreshCanSetAndShow() : void {
		if (!this.control) return;
		if (!this.attributeInfo) return;

		this.refreshIsAvailable();
		this.refreshCanSet();
	}

	public ngDoCheck() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.refreshCanSetAndShow();
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	public ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.refreshCanSetAndShow();
		if (this.attributeInfo && this.group) this.removeFormControl();
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	public _onChange : (value : any) => void = () => {};

	private createFormControl() : void {
		if (this.control) return;
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.group, 'group');
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.attributeInfo, 'attributeInfo');
		// eslint-disable-next-line deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const NEW_FORM_CONTROL = this.pFormsService.getByAI(this.group, this.attributeInfo);
		NEW_FORM_CONTROL.registerOnChange((newValue : ValueType) => {
			this._onChange(newValue);
		});
		this.control = NEW_FORM_CONTROL;
	}

	private removeFormControl() : void {
		// eslint-disable-next-line deprecation/deprecation, ban/ban -- FIXME: Remove this before you work here.
		assumeDefinedToGetStrictNullChecksRunning(this.group, 'group');

		const attributeInfoId = this.attributeInfoId ?? this.attributeInfo?.id;
		if (typeof attributeInfoId !== 'string') {
			throw new TypeError('attributeInfoId is not defined');
		}
		const CONTROL = this.group.controls[attributeInfoId] as PFormControl | null;
		if (!CONTROL) return;
		CONTROL.unsubscribe();
		if (this.control)
			this.pFormsService.removeFormControlFromCache(this.control);
		if (this.group.get(attributeInfoId)) {
			this.pFormsService.removeFormControlFromCache(this.group.get(attributeInfoId)!);
		}
		this.control = null;
		this.group.removeControl(attributeInfoId);
		this.group.updateValueAndValidity();
	}

	private updateExistenceOfFormControl() : void {
		if (this.attributeInfo?.isAvailable) {
			this.createFormControl();
		} else {
			this.removeFormControl();
		}
	}

	/**
	 * Is this valid?
	 * @returns
	 * 	boolean The valid state
	 * 	null If this component is disabled, because if a user can‘t do anything about it, the user should not see
	 * 	an error.
	 */
	protected get isValid() : boolean | null {
		if (this.disabled) return null;
		return !this.control?.invalid;
	}

	/**
	 * Should this field be marked as required?
	 * This can be set as Input() but if there is a formControl binding,
	 * then it takes the info from the formControl’s validators.
	 */
	public get hasRequiredError() : boolean {
		if (this._required) return this._required;
		return this.hasFormControlRequiredError();
	}

	/**
	 * Should this be marked as warning in ui?
	 * E.g. id a async validator is running.
	 */
	public get hasWarning() : boolean {
		return this.control?.pending ?? false;
	}

	/**
	 * Returns true if this is not valid.
	 * If this is true, then it should be bordered red in the ui and a
	 * error message should be shown that describes the error.
	 */
	public get hasDanger() : boolean {
		// If its initially required, it should not be marked as danger like a invalid field.
		if (this.control?.touched === false && this.hasRequiredError) return false;

		// Initially invalid fields should always be marked as danger.
		if (this.control?.touched === false && !this.control.dirty && this.control.invalid) return true;

		// TODO: PLANO-168004 All Form Elements (input, textarea, etc.) > Show errors on blur instead while typing
		// if (this.checkTouched === true && this.control?.touched === false) return false;

		if (this.hasWarning) return false;
		return this.isValid === false;
	}

	/**
	 * Should this be marked as required in ui? E.g. red underline.
	 */
	private hasFormControlRequiredError() : boolean {
		if (!this.control) return false;

		const validator = this.control.validator?.(this.control);
		if (!validator) return false;
		return !!validator[PPossibleErrorNames.REQUIRED] || !!validator[PPossibleErrorNames.ID_DEFINED] || !!validator[PPossibleErrorNames.NOT_UNDEFINED];
	}

	/**
	 * Is it required to fill this control with a value?
	 */
	public get isFormControlRequired() : boolean {
		if (!this.control) return false;
		if (!(this.control instanceof PFormControl)) return false;
		return !!this.control.validatorObjects.required;
	}
}
