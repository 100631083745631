/* eslint-disable no-console -- It is not possible to import and use the LogService here - it would lead to circular dependencies */
import { APP_BASE_HREF } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationRef, ChangeDetectorRef, ErrorHandler, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PSeverity, errorMatchesRegEx, throwIgnoreList } from '@plano/global-error-handler/error-utils';
import { PErrorModalContentComponent } from '@plano/shared/core/component/error-modal-content/error-modal-content.component';
import { Config } from '@plano/shared/core/config';
import { MeService } from '@plano/shared/core/me/me.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { SomeObjectWithPromiseAndRejection, errorTypeUtils } from '@plano/shared/core/utils/error-type-utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Can’t extend PBaseClass here.
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PSentryService } from '@plano/shared/sentry/sentry.service';

/**
 * The class that defines how Errors should be handled.
 *
 * NOTE:  The global-error-handler.ts must be totally stable. I dont want anything nearly dangerous in
 *        this ts file. Nothing should break the logic that is responsible to handle an error and gives proper
 *        feedback or actions to the user. For that reason, if you have to decide to add a new service injectable or
 *        write a vanilla js alternative, please go for vanilla js.
 */
@Injectable( { providedIn: 'root' } )
export class GlobalErrorHandler extends ErrorHandler {
	constructor(
		private injector : Injector,
		private localize : LocalizePipe,
		private pSentryService : PSentryService,
		@Inject(APP_BASE_HREF) private baseHref : string,
		private toasts : ToastsService,
	) {
		super();
	}

	/**
	 * Is an error modal open?
	 * Once one is open, the user needs to reload the page. So all following errors can be ignored.
	 */
	private errorModalIsOpen : boolean = false;

	private getErrorObjectFromThrowInput(
		input : Error | HttpErrorResponse | SomeObjectWithPromiseAndRejection | null,
	) : Error | HttpErrorResponse {
		const error = (() => {
			if (errorTypeUtils.isTypeSomeObjectWithPromiseAndRejection(input)) {
				// The httpErrorCode switch can get confused, if the rejection is just a number.
				// So in this case we want to return the whole object as it is.
				const rejectionIsANumber = !Number.isNaN(+(input.rejection as number));
				if (rejectionIsANumber) (input as Exclude<typeof input, SomeObjectWithPromiseAndRejection>);

				if (
					typeof input.rejection !== 'object' ||
					(
						!errorTypeUtils.isTypeHttpErrorResponse(input.rejection as Record<string, unknown>) &&
						!errorTypeUtils.isTypeError(input.rejection as Record<string, unknown>)
					)
				) {
					const typeError = new TypeError(`Unexpected type in rejection: ${typeof input.rejection}, ${JSON.stringify(input.rejection)}`);
					if (Config.DEBUG) {
						throw typeError;
					} else {
						return typeError;
					}
				}

				// In case the rejection is not just a number, we want to return the rejection as error.
				return input.rejection as Error | HttpErrorResponse;
			}
			return input;
		})();

		if (error === null) throw new Error('error should never be null – could not extract error object from input');

		return error;
	}

	/**
	 * This function gets called when something throws and sends a provided error to our error tracking tool.
	 * @param input The error that was thrown.
	 */
	public override async handleError(
		input : Error | HttpErrorResponse | SomeObjectWithPromiseAndRejection | null,
	) : Promise<void> {
		if (this.errorModalIsOpen) return;

		this.detachChangeDetection(input);

		// inject dependencies dynamically to prevent cyclic dependencies
		const applicationRef = this.injector.get<ApplicationRef>(ApplicationRef);
		const modalService = this.injector.get<ModalService>(ModalService);

		const error = this.getErrorObjectFromThrowInput(input);

		const httpErrorCode = this.getHttpErrorCode(error);

		switch (httpErrorCode) {
			case 0:
				this.reportDebugErrorToSentry(error, PSeverity.WARNING);
				await modalService.danger(
					{
						icon: enumsObject.PlanoFaIconContextPool.SOMETHING_WENT_WRONG,
						// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
						description: this.localize.transform('Dr.&nbsp;Plano ist aktuell nicht erreichbar. Kontrolliere bitte deine Internet-Verbindung. Checke gerne auch den <a href="https://status.dr-plano.com/" target="_blank" rel="noopener noreferrer" class="nowrap">Systemstatus von Dr.&nbsp;Plano</a>&nbsp;→'),
						modalTitle: '&nbsp;',
						hideDismissBtn: true,
						closeBtnLabel: 'OK',
					},
					enumsObject.BootstrapSize.SM,
				).result;
				applicationRef.tick();
				break;
			case 429:
				this.reportDebugErrorToSentry(error, PSeverity.INFO);
				await modalService.danger(
					{
						icon: 'fa-lock fa-duotone',
						description: this.localize.transform('Du hast zu viele Anfragen an unseren Server gerichtet, weshalb du vorübergehend blockiert wurdest.'),
						modalTitle: null,
						hideDismissBtn: true,
						closeBtnLabel: 'OK',
					},
				).result;
				applicationRef.tick();
				break;
			case 434:
				this.reportDebugErrorToSentry(error, PSeverity.INFO);
				await modalService.confirm(
					{
						icon: 'fa-arrow-rotate-right fa-duotone',
						description: `<p>${this.localize.transform('Gute Nachricht: Soeben sind einige Verbesserungen online gegangen. Lade bitte Dr.&nbsp;Plano neu, damit du mit der verbesserten Version weiterarbeiten kannst.')}</p>`,
						modalTitle: null,
						hideDismissBtn: true,
						closeBtnLabel: this.localize.transform('Neu laden'),
					},
				).result;
				window.location.reload();
				applicationRef.tick();
				break;
			case 460:
				this.reportDebugErrorToSentry(error, PSeverity.INFO);
				await modalService.danger(
					{
						icon: 'fa-lock fa-duotone',
						description: `${this.localize.transform('Deine gewünschte Aktion wurde aus Sicherheitsgründen blockiert. Für eine Freigabe melde dich bitte bei unserem Kundenservice.')}<p class="mt-2"><a href="${this.localize.transform('https://tawk.to/chat/599aa06c1b1bed47ceb05bde/default')}" target="_blank">${this.localize.transform('Kundenservice kontaktieren')}</a></p>`,
						modalTitle: null,
						hideDismissBtn: true,
						closeBtnLabel: 'OK',
					},
				).result;
				applicationRef.tick();
				break;
			case 461:
				this.toasts.addToast({
					content: this.localize.transform('Du kannst nichts editieren, da dieser Account nur Leserecht hat.'),
					theme: enumsObject.PThemeEnum.DANGER,
				});
				break;
			case 503:
				this.reportDebugErrorToSentry(error, PSeverity.WARNING);
				await modalService.danger(
					{
						icon: enumsObject.PlanoFaIconContextPool.SOMETHING_WENT_WRONG,
						// eslint-disable-next-line literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
						description: this.localize.transform('Aufgrund von sehr hoher Serverauslastung kann deine Anfrage momentan nicht bearbeitet werden. Bitte versuche es gleich nochmal. Danke! Checke bei Interesse den <a href="https://status.dr-plano.com/" target="_blank" rel="noopener noreferrer" class="nowrap">Systemstatus von Dr.&nbsp;Plano</a> →'),
						modalTitle: null,
						hideDismissBtn: true,
						closeBtnLabel: 'OK',
					},
				).result;
				applicationRef.tick();
				break;
			default:
				this.handleNonHTTPError(error);
		}

		// Rethrow the error otherwise it gets swallowed
		// At least in the admin area we had swallowed errors in the past
		throw error;
	}

	/**
	 * Send an error to Sentry.
	 * @param error The error that should be sent to the server.
	 * @param severity The severity level of the error.
	 */
	private reportDebugErrorToSentry(
		error : Error | HttpErrorResponse,
		severity : PSeverity,
	) : void {
		const me = this.injector.get<MeService>(MeService);
		void this.pSentryService.handleError(error, me, undefined, {
			level: severity,
			extra: {error},
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	private detachChangeDetection(input : any) : void {
		const debugContext = input['ngDebugContext'];
		const changeDetectorRef = debugContext?.injector.get(ChangeDetectorRef);
		if (changeDetectorRef) changeDetectorRef.detach();
	}

	private handleNonHTTPError(error : Error | HttpErrorResponse) : void {
		// Don’t show error modal if its on the throw ignore list
		if (this.errorShouldBeIgnored(error)) return;

		// We want to avoid error modals when user is not in client area because many errors might be risen because
		// of old browser versions. These errors we want to report but user should be able to continue browsing.
		if (this.isInSomePublicArea) {
			// Only report error without opening popup
			this.reportDebugErrorToSentry(error, PSeverity.WARNING);
		} else {
			// Show error modal

			// NOTE: Modals in Error handler need to run in a Zone.
			// Otherwise its not sure that they will be displayed correctly.
			// Source: https://github.com/flauc/angular2-notifications/issues/53
			const zone = this.injector.get(NgZone);
			zone.runOutsideAngular(() => {
				window.setTimeout(() => {
					zone.run(() => {
						if (this.errorModalIsOpen) return;

						this.showErrorModal(error);
						this.errorModalIsOpen = true;

						super.handleError(error);
					});
				}, 600);
			});
		}
	}

	private errorShouldBeIgnored(error : Error | HttpErrorResponse) : boolean {
		const result = throwIgnoreList.some(regex => errorMatchesRegEx(error, regex));
		if (result) console.error('Blocked error handler, because it is on the ignore list.', error);
		return result;
	}

	/**
	 * We want to avoid error modals when user is not in client area because many errors might be risen because
	 * of old browser versions. These errors we want to be reported about but user should be able to continue browsing.
	 */
	private get isInSomePublicArea() : boolean {
		return (
			!window.location.pathname.startsWith(`${this.baseHref}client/`) &&
			!window.location.pathname.startsWith(`${this.baseHref}mobile-login/`) &&
			!window.location.pathname.startsWith(`${this.baseHref}admin/`) &&
			!window.location.pathname.startsWith(`${this.baseHref}storybook/`)
		);
	}

	/**
	 * Show the error modal to the user, that provides the possibility to leave a message.
	 * @param error The error that should be sent to the server.
	 */
	private showErrorModal(error : Error | HttpErrorResponse) : void {
		const modal = this.injector.get<NgbModal>(NgbModal);
		const errorModal = modal.open(PErrorModalContentComponent, {
			backdrop: 'static',
			backdropClass: 'not-clickable bg-dark',
			keyboard: false,
		}).componentInstance as PErrorModalContentComponent;
		void errorModal.initModal(error);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
	private getHttpErrorCode(error : any) : number | null {
		if (!error) return null;
		const status = errorTypeUtils.isTypeHttpErrorResponse(error) ? error.status : null;

		// If some chunk can not be loaded, we want to handle it the same way like backend is not reachable.
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (status === null && error.message && /Loading chunk \w+ failed/.test(error.message)) return 0;

		return status;
	}
}
